import React, { useContext } from 'react'
import {
  Grid,
  Typography,
  createStyles,
  WithStyles,
  withStyles,
  Button
} from '@material-ui/core'
import PlaceholderImg from './PlaceholderImg'
import { getTextFromLanguage } from '../../../../../../helper/languageHelper'
import Imgix from 'react-imgix'
import { Link } from 'gatsby'
import { SelectedLanguageContext } from '../LanguageContext'
import { ThemeContext } from '../ThemeContext'

interface Props extends WithStyles<typeof styles> {
  element: any
  view: any
  dialog: boolean
  urlPrefix: string
}

const styles = () =>
  createStyles({
    card: {
      '&:hover': {
        boxShadow:
          ' 0 3px 6px rgba(140, 140, 140, 0.16), 0 3px 6px rgba(140, 140, 140, 0.23)'
      }
    }
  })

const Collections: React.FC<Props> = props => {
  const { element, dialog, classes, view, urlPrefix } = props

  const theme = useContext(ThemeContext)

  if (!element && !element.products) {
    return null
  }

  let styles: any = null
  try {
    if (element?.contentStyle?.length > 0) {
      styles = element.contentStyle[0]
    }
  } catch (error) {
    console.log(error)
  }

  const selectedLanguage = useContext(SelectedLanguageContext)

  let breakpoint = 3

  if (view && view.mode && view.mode === 'mobile') {
    breakpoint = 12
  } else if (element?.collectionIds?.length === 1) {
    breakpoint = 8
  } else {
    breakpoint = 3
  }

  if (!dialog) {
    return (
      <>
        {element.collectionTitle ? (
          <Grid
            style={{ padding: '20px 0px 50px 0px' }}
            container={true}
            justify="center"
          >
            <Typography
              style={{
                fontFamily:
                  theme?.theme?.h1?.style?.fontFamily || 'Roboto, sans-serif'
              }}
              component="h2"
              variant="h1"
            >
              {getTextFromLanguage(
                element?.collectionTitle?.text,
                selectedLanguage
              ) || null}
            </Typography>
          </Grid>
        ) : null}
        <Grid justify="center" container={true}>
          {element.collections.map((collection: any, index: number) => {
            return (
              <Grid
                key={index}
                item={true}
                xs={view?.mode ? breakpoint : 12}
                sm={view?.mode ? breakpoint : 6}
                md={view?.mode ? breakpoint : 4}
                lg={view?.mode ? breakpoint : 3}
                xl={view?.mode ? breakpoint : 3}
                style={{
                  padding: 8
                }}
              >
                <Link
                  to={
                    collection.slugs
                      ? `/${`${urlPrefix}` +
                          getTextFromLanguage(
                            collection.slugs,
                            selectedLanguage
                          )}`
                      : '/'
                  }
                  style={{
                    position: 'relative',
                    color: '#000',
                    textDecoration: 'none'
                  }}
                >
                  <Grid
                    container={true}
                    className={classes.card}
                    style={{
                      borderRadius: 4,
                      minHeight: 270
                    }}
                  >
                    <Grid item={true} xs={12} style={{ height: '250px' }}>
                      {element &&
                      element?.collections &&
                      collection?.thumbnail ? (
                        <Imgix
                          height={270}
                          htmlAttributes={{
                            alt: '',
                            style: {
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              display: 'block'
                            }
                          }}
                          src={collection?.thumbnail}
                          imgixParams={{
                            auto: 'compress'
                          }}
                        />
                      ) : (
                        <PlaceholderImg width={'100%'} height={'100%'} />
                      )}
                    </Grid>
                    <Grid item={true} xs={12} style={{ padding: '4px 16px' }}>
                      <Typography>
                        {getTextFromLanguage(
                          collection?.title?.text,
                          selectedLanguage
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item={true}
                      xs={12}
                      style={{
                        padding: '4px 16px'
                      }}
                    >
                      <Grid
                        container={true}
                        justify={
                          styles && styles.buttonAlign
                            ? styles.buttonAlign
                            : 'flex-start'
                        }
                      >
                        <Button variant="text">
                          {getTextFromLanguage(
                            element?.buttonText?.text,
                            selectedLanguage
                          ) || `Shop the collection`}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
            )
          })}
        </Grid>
      </>
    )
  } else {
    return (
      <>
        {element.collectionTitle ? (
          <Grid
            style={{ padding: '20px 0px 50px 0px' }}
            container={true}
            justify="center"
          >
            <Typography component="h2" variant="h1">
              {getTextFromLanguage(
                element?.collectionTitle?.text,
                selectedLanguage
              ) || null}
            </Typography>
          </Grid>
        ) : null}
        <Grid container={true}>
          {element.collectionIds.map((id: string) => {
            if (element.collectionIds.length > 1) {
              return (
                <Grid key={id} item={true} xs={3}>
                  <PlaceholderImg width={270} height={270} />
                </Grid>
              )
            } else {
              return (
                <Grid item={true} xs={3}>
                  <PlaceholderImg width={270} height={270} />
                </Grid>
              )
            }
          })}
        </Grid>
      </>
    )
  }
}

export default withStyles(styles)(Collections)
