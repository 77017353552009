import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import { navigate } from '@reach/router';
import { ADMIN_SHOP_PRODUCT_ATTRIBUTES } from '../../gql/admin-queries';
import { ADMIN_CREATE_PRODUCT_ATTRIBUTE } from '../../gql/admin-mutations';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing,
    marginRight: theme.spacing
  }
});

class AddAttribute extends Component {
  state = {
    name: ''
  };

  render() {
    const { shopId } = this.props;
    return (
      <div>
        <Mutation
          mutation={ADMIN_CREATE_PRODUCT_ATTRIBUTE}
          variables={{ shopId }}
          update={(cache, { data: { createProductAttribute } }) => {
            if (createProductAttribute.id) {
              navigate(
                `/admin/${shopId}/inventory/attributes/${createProductAttribute.id}`
              );
              const data = cache.readQuery({
                query: ADMIN_SHOP_PRODUCT_ATTRIBUTES,
                variables: { id: shopId }
              });
              data.shop.attributes.edges.unshift({
                node: {
                  ...createProductAttribute
                },
                __typename: 'ShopProductAttributeEdge'
              });
              cache.writeQuery({
                query: ADMIN_SHOP_PRODUCT_ATTRIBUTES,
                variables: { id: shopId },
                data
              });
            }
          }}
        >
          {addProductAttribute => (
            <Button
              data-cy="Attribute-Add-New-btn"
              color="primary"
              onClick={addProductAttribute}
              variant="contained"
            >
              New Attribute
            </Button>
          )}
        </Mutation>
      </div>
    );
  }
}

export default withStyles(styles)(AddAttribute);
