import React, { useImperativeHandle, useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
const style = {
  cursor: 'move'
};

const DraggableCard = React.forwardRef((props, ref) => {
  const { isdragging, connectdragsource, connectdroptarget, dragdrop } = props;
  const elementRef = useRef(null);
  connectdragsource(elementRef);
  connectdroptarget(elementRef);
  const opacity = isdragging === 'true' ? 0 : 1;
  let styleComp = props.style ? Object.assign(props.style, style) : style;
  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current
  }));
  return (
    <div
      ref={elementRef}
      {...props}
      style={Object.assign({}, styleComp, { opacity })}
    >
      {props.children}
    </div>
  );
});
export default DropTarget(
  'card',
  {
    hover(props, monitor, component) {
      if (!component || !props.dragdrop) {
        return null;
      }
      if (props.dragdrop.dragType !== monitor.getItem().dragType) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.dragdrop.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Get pixels to the left
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      const upwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY;
      const downwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
      const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX;
      const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX;

      if (upwards && (leftwards || rightwards)) {
        return;
      }

      if (downwards && (leftwards || rightwards)) {
        return;
      }

      if (monitor.getItem().sortOrder === props.dragdrop.sortOrder) {
        return;
      }

      // Time to actually perform the action
      props.dragdrop.moveCard({
        pageId: props.dragdrop.pageId,
        rowId: props.dragdrop.rowId, // only for column and columnRow type
        type: props.dragdrop.dragType,
        drag: {
          id: monitor.getItem().id,
          index: dragIndex,
          sortOrder: monitor.getItem().sortOrder
        },
        hover: {
          id: props.dragdrop.id,
          index: hoverIndex,
          sortOrder: props.dragdrop.sortOrder
        }
      });
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
      monitor.getItem().sortOrder = props.dragdrop.sortOrder;
    }
  },
  connect => ({
    connectdroptarget: connect.dropTarget()
  })
)(
  DragSource(
    'card',
    {
      beginDrag: props => ({
        id: props.dragdrop.id,
        index: props.dragdrop.index,
        dragType: props.dragdrop.dragType,
        pageId: props.dragdrop.pageId,
        rowId: props.dragdrop.rowId, // only for column and columnRow type
        sortOrder: props.dragdrop.sortOrder
      })
    },
    (connect, monitor) => ({
      connectdragsource: connect.dragSource(),
      isdragging: monitor.isDragging() ? 'true' : 'false'
    })
  )(DraggableCard)
);
