import { Link } from 'gatsby'
import React, { useState } from 'react'
import {
  AppBar,
  withStyles,
  IconButton,
  Grid,
  Theme,
  createStyles,
  WithStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Close, Menu } from '@material-ui/icons'
import HeaderRender from './HeaderRender'

interface Props extends WithStyles<typeof styles> {
  currentUser: any
  isEditMode?: boolean
  language: string
  menus: any
  shop: any
  urlPrefix: string | null
  teamstore: any
  defaultLanguage?: string
  slugs: any[]
  languages?: any[]
}

const styles = (themeStyle: Theme) =>
  createStyles({
    mobile: {
      display: 'none',
      height: 60,
      width: '100%',
      [themeStyle.breakpoints.down('sm')]: {
        display: 'flex'
      }
    },
    responsive: {
      display: 'block',
      [themeStyle.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    mobileMenuButton: {
      display: 'none',
      [themeStyle.breakpoints.down('sm')]: {
        display: 'block',
        margin: '0px',
        height: '68px',
        width: '150px'
      }
    },
    headercontainer: {
      width: '100%',
      position: 'relative',
      [themeStyle.breakpoints.down('sm')]: {
        paddingRight: 16,
        paddingLeft: 16,
        paddingTop: 8
      }
    }
  })

const Header: React.FunctionComponent<Props> = props => {
  const {
    classes,
    isEditMode,
    language,
    menus,
    urlPrefix,
    currentUser,
    slugs,
    shop,
    teamstore
  } = props

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const primaryColor = theme.palette.primary.main

  const [isSideOpen, setIsSideOpen] = useState<boolean>(false)

  const toggleSide = (value: boolean): void => {
    setIsSideOpen(value)
  }

  return (
    <>
      {menus
        ? menus.map((menu: any, index: number) => {
            switch (index) {
              case 0:
                if (1 === 1) {
                  return (
                    <AppBar
                      key={'header-app-' + index}
                      style={{
                        position:
                          process.env.GATSBY_BRIKL_IS_DASHBOARD === 'yes'
                            ? 'relative'
                            : 'fixed',
                        backgroundColor:
                          matches && menus.length < 2
                            ? menu.contentMenu.backgroundColor
                            : null,
                        // backgroundColor:
                        //   shop.id === 'vermarc'
                        //     ? primaryColor
                        //     : menu.contentMenu.backgroundColor,
                        height: matches ? '85px' : 'auto'
                      }}
                    >
                      <Grid
                        container={true}
                        justify="space-between"
                        alignItems="center"
                        className={classes.headercontainer}
                      >
                        {matches ? (
                          <Link to="/" className={classes.mobileMenuButton}>
                            <img
                              src={
                                process.env.GATSBY_BRIKL_SALESCHANNEL_ID
                                  ? teamstore.logo
                                  : shop && shop.brandSettings
                                  ? shop.brandSettings.logo
                                  : null
                              }
                              alt={'logo'}
                              style={{
                                height: '100%',
                                width: '100%',
                                filter:
                                  shop.id === 'xceed' &&
                                  shop &&
                                  shop.brandSettings &&
                                  !process.env.GATSBY_BRIKL_SALESCHANNEL_ID
                                    ? 'invert(1)'
                                    : 'invert(0)',
                                objectFit: 'contain'
                              }}
                            />
                          </Link>
                        ) : null}
                        {matches && isSideOpen ? (
                          <IconButton
                            style={{
                              minWidth: 60,
                              width: 60,
                              height: 60,
                              minHeight: 60,
                              position: 'fixed',
                              top: 12,
                              right: 16
                            }}
                            color="inherit"
                            aria-label="Close"
                            onClick={() => toggleSide(false)}
                            className={classes.mobileMenuButton}
                          >
                            <Close
                              style={{
                                fontSize: 28,
                                color:
                                  shop.id === 'vermarc'
                                    ? 'rgb(000, 000, 000)'
                                    : menu.contentMenu.linkColor
                                    ? menu.contentMenu.linkColor
                                    : 'rgb(000, 000, 000)'
                              }}
                            />
                          </IconButton>
                        ) : null}

                        {matches ? (
                          <div>
                            {isSideOpen ? (
                              <Grid
                                container={true}
                                justify={matches ? 'flex-end' : 'center'}
                                alignItems={
                                  matches ? 'flex-start' : 'flex-start'
                                }
                                style={{
                                  position: 'fixed',
                                  top: 85,
                                  left: 0,
                                  width: '100%',
                                  overflow: 'auto',
                                  height: 'calc(100vh - 85px)',
                                  background: '#fff',
                                  paddingBottom: '24px,'
                                }}
                              >
                                <HeaderRender
                                  teamstore={teamstore}
                                  slugs={slugs}
                                  currentUser={currentUser}
                                  menus={menus}
                                  isEditMode={isEditMode}
                                  language={language}
                                  shop={shop}
                                  urlPrefix={urlPrefix}
                                />
                              </Grid>
                            ) : (
                              <IconButton
                                style={{
                                  minWidth: 60,
                                  width: 60,
                                  height: 60,
                                  minHeight: 60,
                                  position: 'absolute',
                                  top: 12,
                                  right: 16
                                }}
                                color="inherit"
                                aria-label="Menu"
                                onClick={() => toggleSide(true)}
                                className={classes.mobileMenuButton}
                              >
                                <Menu
                                  style={{
                                    fontSize: 28,
                                    color:
                                      shop.id === 'vermarc'
                                        ? 'rgb(000, 000, 000)'
                                        : menu.contentMenu.linkColor
                                        ? menu.contentMenu.linkColor
                                        : 'rgb(000, 000, 000)'
                                  }}
                                />
                              </IconButton>
                            )}
                          </div>
                        ) : (
                          <Grid container={true} className={classes.responsive}>
                            <HeaderRender
                              teamstore={teamstore}
                              slugs={slugs}
                              currentUser={currentUser}
                              menus={menus}
                              isEditMode={isEditMode}
                              language={language}
                              shop={shop}
                              urlPrefix={urlPrefix}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </AppBar>
                  )
                }
            }
          })
        : null}
    </>
  )
}

export default withStyles(styles, { withTheme: true })(Header)
