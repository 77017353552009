import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import NativeSelect from '@material-ui/core/NativeSelect'
import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase'
import { Mutation } from 'react-apollo'
import { UPDATE_USER_SETTINGS } from '../../gql/mutations'
import { languages, SHOP_LANGUAGE_WITHOUT_PREFIX } from '../../helper/config'
import { navigate } from '@reach/router'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { setLanguage } from '../../helper/storage'

const invertHex = hexnum => {
  try {
    alert(hexnum)
    hexnum = hexnum.toUpperCase()
    var splitnum = hexnum.split('')
    var resultnum = ''
    var simplenum = 'FEDCBA9876'.split('')
    var complexnum = []
    complexnum.A = '5'
    complexnum.B = '4'
    complexnum.C = '3'
    complexnum.D = '2'
    complexnum.E = '1'
    complexnum.F = '0'

    for (i = 0; i < 6; i++) {
      if (!isNaN(splitnum[i])) {
        resultnum += simplenum[splitnum[i]]
      } else if (complexnum[splitnum[i]]) {
        resultnum += complexnum[splitnum[i]]
      } else {
        return false
      }
    }

    return resultnum
  } catch (error) {
    return hexnum
  }
}
var store = require('store')
const LanguageSwitch: React.FunctionComponent<any> = props => {
  const languages = props.languages || []
  const linkColor = props.linkColor
  const styling = props.styling
  const handleSwitch = async (e: any, updateUserSettings: any) => {
    const selectedLanguage = e.target.value
    if (selectedLanguage !== props.currentLanguage) {
      try {
        setLanguage(e.target.value)
      } catch (error) {}
      try {
        await updateUserSettings({
          variables: {
            input: {
              settings: {
                defaultLanguage: e.target.value
              }
            }
          }
        })
      } catch (error) {}

      try {
        const newSlug = props.slugs.find(s => s.langCode === selectedLanguage)
        let suffix = ''
        try {
          if (window.location.pathname.indexOf('/app') !== -1) {
            suffix = window.location.pathname.split('/app')[1]
          }
        } catch (error) {}

        window.open(
          `
          ${`${
            SHOP_LANGUAGE_WITHOUT_PREFIX === selectedLanguage ||
            (props.defaultLanguage === selectedLanguage &&
              newSlug &&
              newSlug.content === '/')
              ? ''
              : '/' + selectedLanguage.substring(0, 2)
          }/${newSlug.content}${suffix}`.replace('//', '/')}${
            window.location.search ? window.location.search : ''
          }          
          `,
          '_self'
        )
        // window.location.reload();
      } catch (error) {
        console.error('langaugeSwitch.handleSwitch.error', error)
      }
    }
    // console.log('***', SHOP_DEFAULT_LANGUAGE, e.target.value)
  }

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const inputStyles = {
    color: linkColor ? linkColor : '#000'
  }
  const optionStyles = {
    background: '#fff',
    color: '#000',
    textTransform: 'uppercase',
    fontSize: styling && styling.fontSize ? styling.fontSize : 'inherit'
  }

  const useStyles = makeStyles(theme => ({
    icon: {
      color: linkColor ? linkColor : '#fff !important',
      [theme.breakpoints.down('sm')]: {
        color: '#000'
      }
    }
  }))

  const BootstrapInput = withStyles(theme => ({
    input: {
      borderRadius: 4,
      color: linkColor ? linkColor : '#fff !important',
      position: 'relative',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        color: '#000 !important',
        fontSize: '19px !important',
        textTransform: 'uppercase'
      }
    },
    root: {
      'label + &': {
        marginTop: theme.spacing(3)
      },
      color: linkColor ? linkColor : '#fff !important',
      marginRight: 0,
      marginTop: 0,
      paddingTop: 0,
      paddingBottom: 0,
      maxWidth: '100%',
      lineHeight: 'inherit'
    }
  }))(InputBase)

  const classes = useStyles()

  return (
    <Mutation mutation={UPDATE_USER_SETTINGS}>
      {updateUserSettings => {
        return (
          <Grid item style={{ padding: '8px 16px' }}>
            {!matches ? (
              <NativeSelect
                value={props.currentLanguage}
                onChange={e => handleSwitch(e, updateUserSettings)}
                classes={{ icon: classes.icon }}
                input={
                  <BootstrapInput
                    style={inputStyles}
                    name="age"
                    id="age-customized-native-simple"
                  />
                }
              >
                {languages.map(language => (
                  <option style={optionStyles} key={language} value={language}>
                    {language.substring(0, 2)}
                  </option>
                ))}
              </NativeSelect>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '61px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <NativeSelect
                  value={props.currentLanguage}
                  onChange={e => handleSwitch(e, updateUserSettings)}
                  style={{ fontSize: '19px !important', width: 200 }}
                  input={
                    <BootstrapInput
                      style={inputStyles}
                      name="age"
                      id="age-customized-native-simple"
                    />
                  }
                >
                  {languages.map(language => (
                    <option
                      style={optionStyles}
                      key={language}
                      value={language}
                    >
                      {language.substring(0, 2)}
                    </option>
                  ))}
                </NativeSelect>
              </div>
            )}
          </Grid>
        )
      }}
    </Mutation>
  )
}

export default LanguageSwitch
