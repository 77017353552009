import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import _get from 'lodash/get'
import { Link } from 'gatsby'
import { Divider } from '@material-ui/core'
import { normalizeTextTitle } from '../../../admin/components/content/utils'
// src\components\admin\components\content\utils.ts
// src\components\store\collection\gallery\CollectionCard.js
import Imgix from 'react-imgix'
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../../brikl-config'
import { secondary, primary } from '../../../common/Theme'
import { Localization } from '../../../../lang/localization'

const styles = theme => ({
  card: {
    position: 'relative',
    minWidth: '100%',
    padding: '0px',
    '&:hover': {
      boxShadow:
        ' 0 3px 6px rgba(140, 140, 140, 0.16), 0 3px 6px rgba(140, 140, 140, 0.23)'
    },
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      border: '1px solid #e5e5e5'
    }
  },
  hoverEffect: {
    width: '100%',
    height: '100%',
    color: 'transparent',
    '&:hover': {
      opacity: 0.8,
      color: '#FFFFFF',
      cursor: 'pointer'
    }
  },
  media: {
    height: 160
  }
})

class CollectionCard extends React.Component {
  render() {
    const {
      classes,
      collection,
      langCode,
      urlPrefix,
      numberOfCards,
      buttonText,
      customUrl,
      salesChannel
    } = this.props

    var title = ''
    var slug = ''
    var url = ''
    var button = ''

    let xs, sm, md, lg, xl

    if (numberOfCards) {
      switch (numberOfCards) {
        case 1:
          xs = 12
          sm = 12
          md = 12
          lg = 12
          xl = 12
          break
        case 2:
          xs = 6
          sm = 6
          md = 6
          lg = 6
          xl = 6
          break
        case 3:
          xs = 6
          sm = 6
          md = 6
          lg = 4
          xl = 4
          break
        case 4:
          xs = 6
          sm = 6
          md = 4
          lg = 3
          xl = 3
          break
        default:
          xs = 6
          sm = 6
          md = 4
          lg = 3
          xl = 3
      }
    } else {
      xs = 12
      sm = 6
      md = 4
      lg = 3
      xl = 3
    }

    let CardTitle
    try {
      title = getTextFromLanguage(_get(collection, 'title.text', []), langCode)
      CardTitle = normalizeTextTitle(title)
      button = getTextFromLanguage(buttonText.text, langCode)
    } catch (error) {}

    try {
      if (customUrl) {
        slug = customUrl
      } else {
        if (process.env.GATSBY_BRIKL_SALESCHANNEL_ID) {
          slug = `/${urlPrefix}${getTextFromLanguage(
            collection.slugs,
            langCode
          )}`
        } else {
          if (salesChannel) {
            slug = `/${urlPrefix}${getTextFromLanguage(
              salesChannel.slugs,
              langCode
            )}/collection?c=${collection.id}`
          } else {
            if (collection && collection.slugs) {
              slug = `/${urlPrefix}${getTextFromLanguage(
                collection.slugs,
                langCode
              )}`
            }
          }
        }
      }
    } catch (error) {
      console.error('ProductCard.slugs.error', error)
    }

    return (
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} style={{ padding: 8 }}>
        <Card
          className={classes.card}
          elevation={0}
          data-cy={'CARD-' + CardTitle}
        >
          <div className={classes.hoverEffect}>
            <Link
              to={slug}
              style={{
                textDecoration: 'none',
                color: '#000' //secondary
              }}
            >
              {collection &&
              collection.thumbnail &&
              collection.thumbnail.indexOf(MEDIA_CDN_URL) !== -1 ? (
                <Imgix
                  width={230}
                  height={230}
                  htmlAttributes={{
                    alt: title,
                    title: title,
                    className: classes.media,
                    style: {
                      width: '100%',
                      objectFit: 'contain',
                      objectPosition: 'top',
                      display: 'block',
                      borderRadius: 5
                    }
                  }}
                  src={collection.thumbnail.replace(MEDIA_CDN_URL, IMGIX_URL)}
                  imgixParams={{
                    auto: 'compress'
                  }}
                />
              ) : (
                <CardMedia
                  style={{
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'top',
                    display: 'block'
                  }}
                  className={classes.media}
                  image={
                    collection && collection.thumbnail
                      ? collection.thumbnail
                      : null
                  }
                  title={title}
                />
              )}

              <CardContent style={{ borderTop: '1px solid #e5e5e5' }}>
                <Typography
                  gutterBottom
                  variant="h4"
                  style={{ color: '#000', textTransform: 'uppercase' }}
                >
                  {title}
                </Typography>
                <Grid container>
                  <div style={{ textAlign: 'left' }} />
                  <Typography
                    data-cy="Card-learn-more"
                    component="p"
                    variant="body1"
                    style={{
                      letterSpacing: '0.1em',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      fontSize: 12
                    }}
                  >
                    {button
                      ? button
                      : `${Localization.get('shop_the_collection')}`}
                  </Typography>
                </Grid>
              </CardContent>
            </Link>
          </div>
        </Card>
      </Grid>
    )
  }
}
CollectionCard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CollectionCard)
