import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { split, ApolloLink } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import fetch from 'isomorphic-fetch'
import {
  API_URL,
  DEFAULT_CURRENCY,
  PUBLIC_CDN_URL,
  DEFAULT_LANGUAGE,
  SHOP_ID
} from '../helper/config'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from './fragmentTypes.json'
import { getBearer } from '../helper/getToken'
import { WebSocketLink } from 'apollo-link-ws'
import {
  getCurrency,
  getLanguage,
  getCountry,
  getShopId
} from '../helper/storage'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: object => {
    if (object) {
      switch (object.__typename) {
        default:
          return defaultDataIdFromObject(object) // fall back to default handling
      }
    } else {
      return null
    }
  }
})

const authLink = new setContext(async (_, sth) => {
  var headers = sth.headers

  const token = await getBearer(SHOP_ID)
  try {
    if (
      process.env.GATSBY_BRIKL_IS_DASHBOARD === 'yes' &&
      process.env.GATSBY_API_STAGE !== 'production'
    ) {
      alert(
        'You are using the wrong apollo client. Admin should use the admin-apollo-config.js'
      )
    }
  } catch (e) {}
  let shopId
  try {
    if (process.env.GATSBY_BRIKL_IS_DASHBOARD === 'yes') {
      shopId = await getShopId()
    } else {
      shopId = SHOP_ID
    }
  } catch (error) {}
  return {
    headers: {
      ...headers,
      ...(window.Cypress &&
        window.Cypress.env('commithash') && {
          'x-brikl-commit-hash': window.Cypress.env('commithash')
        }),
      ...(process.env.GATSBY_COMMIT_HASH && {
        'x-brikl-commit-hash': process.env.GATSBY_COMMIT_HASH
      }),
      'x-brikl-shop-id': shopId,
      authorization: token ? token : null
    }
  }
})

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    try {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        })
      if (networkError) {
        console.log('Network Error:', networkError)
      }
    } catch (error) {
      console.error(`[onError error]: ${error}`)
    }
  }
)

const httpLink = createHttpLink({
  uri:
    API_URL +
    `?lang=${
      typeof window !== `undefined` && getLanguage()
        ? getLanguage()
        : DEFAULT_LANGUAGE
    }&cur=${
      typeof window !== `undefined` && getCurrency()
        ? getCurrency()
        : DEFAULT_CURRENCY
    }&country=${
      typeof window !== `undefined` && getCountry() ? getCountry() : null
    }`,
  fetch
})

// TODO: What is the idea behind this?
const httpLinks = split(
  // split based on operation or query attributes
  ({ query }) => {
    const mainDefinition = getMainDefinition(query)
    if (
      mainDefinition &&
      mainDefinition.name &&
      mainDefinition.name.value &&
      (mainDefinition.name.value === 'shopConfiguration' ||
        mainDefinition.name.value === 'productConfiguration')
    ) {
      return true
    } else {
      return false
    }
  },
  httpLink,
  httpLink
)

const client = new ApolloClient({
  cache,
  ssrMode: !typeof window !== `undefined`,
  link: authLink.concat(errorLink.concat(httpLinks)),
  name: SHOP_ID,
  version: 'v1'
})

export default client
