import React from 'react';
import { withStyles, Grid, createStyles, WithStyles } from '@material-ui/core';
import {
  recursiveGetSubItems,
  getElementsFirstLevel,
  getItemStyling
} from '../../../helper/menuHelper';
import { remapAlignmentForLayout } from '../../admin/components/content/utils';

import FooterItem from './FooterItem';

const styles = () =>
  createStyles({
    container: {
      display: 'flex'
    }
  });

interface Props extends WithStyles<typeof styles> {
  shop: any;
  isEditMode?: boolean;
  language: string;
  menu: any;
  urlPrefix: string;
  teamstore: any;
}

const Footer: React.FunctionComponent<Props> = props => {
  const {
    isEditMode,
    language,
    menu,
    shop,
    urlPrefix,
    teamstore
  } = props;
  if (!menu) {
    return null;
  }

  let elementsFirstLevel = [];
  try {
    elementsFirstLevel =
      menu && menu.navigationLinks
        ? getElementsFirstLevel(menu.navigationLinks)
        : [];
  } catch (e) {
    console.error(e);
  }
  return (
    <div
      data-testid={'footer'}
      style={{
        background: menu.backgroundColor || '#ffffff',
        color: menu.linkColor || 'black',
        fontSize: menu.styling ? menu.styling.fontSize : null,
        height:
          menu.styling && menu.styling.height ? menu.styling.height : 'auto',
        width: isEditMode ? '100%' : '100vw'
      }}
    >
      <Grid
        container={true}
        style={{
          margin: 'auto',
          maxWidth: 1000,
          padding: '8px 24px',
          position: 'relative'
        }}
        alignItems={remapAlignmentForLayout(menu.verticalAlign)}
        justify={remapAlignmentForLayout(menu.alignment)}
      >
        {elementsFirstLevel.map((item, index) => (
          <FooterItem
            teamstore={teamstore}
            key={index}
            shop={shop}
            item={item}
            language={language}
            subElements={recursiveGetSubItems(menu.navigationLinks, item.id, 1)}
            urlPrefix={urlPrefix}
            linkColor={menu.linkColor || 'black'}
            styling={getItemStyling(menu.styling, item.styling)}
          />
        ))}
      </Grid>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Footer);
