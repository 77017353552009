import React, { Component } from 'react';
import { Query } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import { ADMIN_SHOP_CUSTOM_PRODUCT_DESIGNS } from '../../gql/admin-queries';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ProductDesign from "../../../home/ProductDesign";

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%',
    padding: '0'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242',
    flex: 1
  }
});
class DesignroomDesignsList extends Component {
  render() {
    const { classes, styles, shopId } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px', padding: 28 }}
        >
          <Typography component="h1" className={classes.h1}>
            Designroom - Designs
          </Typography>
        </Grid>

        <Grid container>
          <Query query={ADMIN_SHOP_CUSTOM_PRODUCT_DESIGNS} variables={{ ownedByOrganisationId: shopId + '-ADMIN' }}>
            {({ loading, error, data }) => {
              if (loading || error || !data.customerProductDesigns) {
                return null
              }
              return data.customerProductDesigns.edges.map(pd => (
                <ProductDesign
                  isAdmin={true}
                  item={pd.node}
                  urlPrefix={""}
                />
              ))
            }}
          </Query>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(DesignroomDesignsList);
