import React, { Component } from 'react';

export default class LayoutIcon extends React.Component {
  render() {
    return (
      <svg
        width="38"
        height="32"
        viewBox="0 0 38 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.9717 1.32001L36.8469 8.96961L32.9773 13.5168L28.4581 11.5176V31H8.98128V11.2152L4.83168 13.276L1.11328 8.97521L12.2069 1.32001C19.7333 16.16 25.9717 1.32001 25.9717 1.32001Z"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="3 4"
        />
      </svg>
    );
  }
}
