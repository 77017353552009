import { graphql } from 'gatsby'
import gql from 'graphql-tag'
export const LeadFragment = gql`
  fragment leadFragment on Lead {
    id
  }
`
export const ProductDesignTemplateFragment = gql`
  fragment productDesignTemplateFragment on ProductDesignTemplate {
    id
    productId
    no
    title {
      id
      text {
        langCode
        content
      }
    }
    lastTemplateRevision
    templateFileSrc
    logobaseFileSrc
    previewImageSrc
    colorZoneFileSrc
    logos {
      id
    }
    colors {
      colorId

      customColorHex
      id
      name
      title {
        id
        text {
          langCode
          content
        }
      }
      color {
        id
        hex
        code
        name
      }
      coordinates {
        x
        y
      }
    }
  }
`
export const PageTagFragment = gql`
  fragment pageTagFragment on PageTag {
    id
    slugs {
      langCode
      content
    }
    title {
      id
      text {
        langCode
        content
      }
    }
    description {
      id
      text {
        langCode
        content
      }
    }
  }
`
export const Board = {
  fragments: {
    elementDesignDataDetails: gql`
      fragment elementDesignDataDetails on BoardElementDesign {
        id
        boardProductId
        baseElementId
        baseElementCode
        baseElementName
        baseElementFullCode
        baseElementELM
        customDesignName
        colorId
        colorRGB {
          r
          g
          b
        }
        fillType
        aopName
        aopUrl
        aopTransform {
          aopScale
          aopOffsetX
          aopOffsetY
          aopRotate
        }
        colorType
        selectedFabricId
      }
    `,
    decalValues: gql`
      fragment decalValues on DecalAttributes {
        decalName
        objectName
        attachPosition {
          x
          y
          z
        }
        objectType
        orientation {
          x
          y
          z
          _order
        }
        size {
          x
          y
          z
        }
        colorRGB {
          r
          g
          b
        }
      }
    `,
    decalAttr: gql`
      fragment decalAttr on Decal {
        decalName
        objectName
        attachPosition
        decalRotate
        decalScale
        objectType
        orientation
        size
        rawData
        colorRGB {
          r
          g
          b
        }
      }
    `,
    logoFields: gql`
      fragment logoFields on BoardItemLogo {
        baseLogoId
        baseLogo {
          name
          ownedByOrganisationId
        }
        ownedByOrganisationId # TODO this might be temporary
        logoDesign {
          name
          printingTechniqueId
          src
          width
          height
          colorHex
          originalWidth
          originalHeight
          threejsWidth
          threejsHeight
        }
      }
    `,
    textFields: gql`
      fragment textFields on BoardItemText {
        textDesign {
          content
          fontFamily
          colorType
          colorHex
          colorId
          printingTechniqueId
          width
          height
          originalWidth
          originalHeight
          threejsWidth
          threejsHeight
        }
      }
    `
  }
}
// export const fixedImage400HeightWidth = graphql`
//   fragment fixedImage400HeightWidth on File {
//     childImageSharp {
//       fixed(height: 400, width: 400) {
//         ...GatsbyImageSharpFixed
//       }
//     }
//   }
// `;
// export const fluidImage400 = graphql`
//   fragment fluidImage400 on File {
//     childImageSharp {
//       fluid(maxWidth: 400) {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// `;
// export const fluidImage = graphql`
//   fragment fluidImage on File {
//     childImageSharp {
//       fluid(maxWidth: 1000) {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// `;
// export const fluidImageWide = graphql`
//   fragment fluidImageWide on File {
//     childImageSharp {
//       fluid(maxWidth: 2400) {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// `;
export const TextFragment = gql`
  fragment textContent on Text {
    id
    text {
      content
      langCode
    }
  }
`
export const ContentPageFragment = gql`
  fragment contentPageFragment on ContentPage {
    id
    name
    status
    tags {
      id
      tagId
      pageId
      tag {
        ...pageTagFragment
      }
    }
    slugs {
      langCode
      content
    }
    title {
      id
      text {
        langCode
        content
      }
    }
    description {
      id
      text {
        langCode
        content
      }
    }
  }
  ${PageTagFragment}
`

export const ThemeFragment = gql`
  fragment themeFragment on ContentTheme {
    id
    h1 {
      id
      style {
        fontSize
        fontFamily
        textTransform
        fontWeight
        letterSpacing
      }
    }
    h2 {
      id
      style {
        fontSize
        fontFamily
        textTransform
        fontWeight
        letterSpacing
      }
    }
    h3 {
      id
      style {
        fontSize
        fontFamily
        textTransform
        fontWeight
        letterSpacing
      }
    }
    h4 {
      id
      style {
        fontSize
        fontFamily
        textTransform
        fontWeight
        letterSpacing
      }
    }
    h5 {
      id
      style {
        fontSize
        fontFamily
        textTransform
        fontWeight
        letterSpacing
      }
    }
    p {
      id
      style {
        fontSize
        fontFamily
        textTransform
        fontWeight
        letterSpacing
      }
    }
    label {
      id
      style {
        fontSize
        fontFamily
        textTransform
        fontWeight
      }
    }
    input {
      id
      type
      style {
        fontSize
        fontFamily
        textTransform
        fontWeight
        borderColor
        borderThickness
        backgroundColor
      }
    }
    button {
      id
      type
      style {
        fontSize
        fontFamily
        textTransform
        fontWeight
        borderColor
        borderThickness
        backgroundColor
        color
      }
    }
    card {
      id
      buttonStyle {
        id
        type
        style {
          fontSize
          fontFamily
          textTransform
          fontWeight
          borderColor
          borderThickness
          backgroundColor
          color
        }
      }
      cardStyle {
        id
        type
        style {
          fontSize
          fontFamily
          textTransform
          fontWeight
          borderColor
          borderThickness
          backgroundColor
          color
        }
      }
      titleStyle {
        id
        style {
          fontSize
          fontFamily
          textTransform
          fontWeight
        }
      }
    }
  }
`
export const MenuFragment = gql`
  fragment menuFragment on ContentMenuLocation {
    id
    menuLocation
    sortOrder
    salesChannelId
    contentMenu {
      id
      alignment
      verticalAlign
      backgroundColor
      linkColor
      styling {
        colorOnHover
        colorOnActive
        fontFamily
        fontSize
        height
        logoWidth
      }
      navigationLinks {
        id
        pageId
        page {
          id
          name
          title {
            id
            text {
              langCode
              content
            }
          }
          slugs {
            langCode
            content
          }
        }
        parentId
        sortOrder
        alignment
        moduleId
        module {
          id
          label
          contentDetail {
            id
            type
            ... on ContentDetailSocialLinks {
              socialLinks {
                externalLink
                icon
              }
            }
            ... on ContentDetailPaymentOptions {
              paymentOptions {
                name
                icon
              }
            }
            ... on ContentDetailShopLogo {
              color
              isAlternative
              isLink
              width
              height
              maxWidth
              maxHeight
            }
          }
        }
        collectionId
        collection {
          id
          no
          title {
            id
            text {
              langCode
              content
            }
          }
          slugs {
            langCode
            content
          }
        }
        productId
        product {
          id
          no
          title {
            id
            text {
              langCode
              content
            }
          }
          slugs {
            langCode
            content
          }
        }
        linkTitle {
          id
          text {
            langCode
            content
          }
        }
        externalLinks {
          langCode
          content
        }
        styling {
          colorOnHover
          colorOnActive
          fontFamily
          fontSize
          height
          logoWidth
        }
      }
    }
  }
`

export const DiscountRuleObjectFragment = gql`
  fragment discountRuleObjectFragment on DiscountRuleObject {
    id
    objectId
    type
    ... on DiscountRuleProduct {
      product {
        id
      }
    }
    ... on DiscountRuleProductPackage {
      productPackage {
        id
      }
    }
    ... on DiscountRuleCollection {
      collection {
        id
      }
    }
    ... on DiscountRuleSalesChannel {
      salesChannel {
        id
      }
    }
    ... on DiscountRuleCustomer {
      customer {
        id
      }
    }
  }
`
export const DiscountRuleFragment = gql`
  fragment discountRuleFragment on DiscountRule {
    id
    discountId
    operator
    discountRuleValue
    discountRuleCurrency
    discountRuleObjects {
      id
      type
      objectId
      ...discountRuleObjectFragment
    }
    discountRuleObjectType
    discountRuleCondition
  }
  ${DiscountRuleObjectFragment}
`
export const DiscountFragment = gql`
  fragment discountFragment on Discount {
    id
    accountingId
    title {
      id
      text {
        langCode
        content
      }
    }
    priority
    enabled
    hasCoupon
    enablesFreeShipping
    canBeCombinedWithOtherDiscounts
    discountType
    productsGetForFree
    applyOnce
    applyTo
    freeProducts {
      id
      media {
        id
        sortOrder
        isThumbnail
        isBanner
        image
      }
      title {
        id
        text {
          content
          langCode
        }
      }
    }
    discountValue
    discountCurrency
    availableFrom
    availableUntil
    discountRules {
      ...discountRuleFragment
    }
    internalNote
    coupons(first: $first) {
      edges {
        node {
          id
          enabled
          couponCode
          internalNote
          currentUsageOverall
          maxUsagePerCustomer
          maxUsageOverall
        }
      }
    }
  }
  ${DiscountRuleFragment}
`
export const ProductOptionValueFragment = gql`
  fragment productOptionValueFragment on ProductOptionValue {
    id
    isEnabled
    sku
    inventory
    barcode
    sortOrder
    optionValues
    variantIds
    optionValueIds
    createdAt
    updatedAt
    modifiers {
      id
      type
      stringModifier
      priceChange {
        currency
        value
      }
    }
    media {
      id
      sortOrder
      isThumbnail
      isBanner
      image
    }
  }
`
export const PricingDetailFragment = gql`
  fragment pricingDetail on PricingRule {
    id
    linkedType
    minOrderQuantity
    calculationType
    formula {
      priority
      price {
        roundUp
        currency
        value
      }
      qty
      sqm
    }
  }
`
export const PrintingDetailFragment = gql`
  fragment printingTechniqueDetail on PrintingTechnique {
    id
    procedures
    previewImage
    customName {
      id
      ...textContent
    }
    description {
      id
      ...textContent
    }
    component {
      id
      ...textContent
    }
    thickness {
      id
      ...textContent
    }
    washability {
      id
      ...textContent
    }
    colorOpacity
    colorIds
    colors {
      id
      name
      colorRGB {
        r
        g
        b
      }
      hex
    }
    fixCustomerCost {
      ...pricingDetail
    }
    printingCost {
      ...pricingDetail
    }
  }
`
