import React from 'react'
import { Grid, Card, Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import Price from '../../../../../../store/product/Price'
import Imgix from 'react-imgix'

interface Props {
  thumbnail: any
  name: any
  price: any
  slug: any
  description?: any
  priceData?: any
  productId?: string
  dialog?: boolean
}

const Elevated: React.FC<Props> = props => {
  const {
    thumbnail,
    name,
    price,
    description,
    slug,
    priceData,
    productId,
    dialog
  } = props
  return (
    <Card
      style={{
        maxWidth: '250px',
        maxHeight: '400px',
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.15)',
        borderRadius: 0,
        backgroundColor: '#FFFFFF',
        margin: '0 auto'
      }}
    >
      {thumbnail ? (
        <Imgix
          height={400}
          htmlAttributes={{
            alt: '',
            style: {
              width: '100%',
              height: '280px',
              objectFit: 'cover'
            }
          }}
          src={thumbnail || null}
          imgixParams={{
            auto: 'compress'
          }}
        />
      ) : (
        <img
          src={
            'https://images.unsplash.com/reserve/LJIZlzHgQ7WPSh5KVTCB_Typewriter.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60'
          }
          style={{ objectFit: 'cover', width: '100%', height: '280px' }}
        />
      )}
      <Grid
        container={true}
        justify="space-between"
        direction="column"
        style={{ padding: '10px' }}
      >
        <Grid container={true} direction="row" justify="space-between">
          <Grid item={true} xs={6}>
            <Typography noWrap style={{ fontWeight: 'bold', width: '100%' }}>
              {name?.substring(0, 16) + '...' || `Name cannot be found!`}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography
              style={{
                fontWeight: 'bold',
                color: '#898989'
              }}
            >
              <Price
                defaultCurrency={priceData?.defaultCurrency}
                productId={productId}
                currencyRates={priceData?.currencyRates}
                taxSettings={priceData?.taxSettings}
                price={price}
                discounts={priceData?.discounts}
              />
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid item={true}>
          <Typography
            style={{
              fontSize: '11px',
              color: '#B8B8B8'
            }}
          >
            {description || `Description`}
          </Typography>
        </Grid> */}

        <Grid style={{ paddingTop: 15 }} item={true}>
          {dialog ? (
            <Typography
              style={{
                fontSize: '12px',
                color: 'black'
              }}
            >
              {`See more >`}
            </Typography>
          ) : (
            <Link
              style={{
                position: 'relative',
                color: '#000',
                textDecoration: 'none'
              }}
              to={slug || ''}
            >
              <Typography
                style={{
                  fontSize: '12px',
                  color: 'black'
                }}
              >
                {`See more >`}
              </Typography>
            </Link>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

export default Elevated
