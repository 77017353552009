import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import { Divider, Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StylingColumn from './StylingColumn';
import StylingColumnRow from './StylingColumnRow';

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    border: '1px solid #0000001f'
  },
  input: {
    paddingLeft: 8,
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  }
});

class Styling extends React.Component {
  state = {
    expanded: null
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleChangeUnsavedData = data => {
    this.props.Template.setState({
      unsavedData: data
    });
  };

  render() {
    const { classes, Template, pageId, shopId, languages } = this.props;
    const { expanded } = this.state;

    return (
      <List style={{ padding: '0px' }}>
        {/* <ExpansionPanel
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
          expanded={expanded === 'panel1'}
          onChange={this.handleChange('panel1')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon style={{ padding: '0px' }} />}
            classes={{ expanded: classes.expansionPanelExpanded }}
          >
            <Typography variant="subtitle1" className={classes.typo}>
              Brand Color
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: classes.expansionPanelDetails }}
          >
            <Divider />
            <List style={{ flex: 1 }}>
              <ListItem>
                <Grid container direction="column">
                  <InputLabel shrink className={classes.FormLabel}>
                    Primary Color
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <InputBase
                      className={classes.input}
                      placeholder="#FFFFFF"
                      value="#ff0066"
                    />
                  </Paper>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container direction="column">
                  <InputLabel shrink className={classes.FormLabel}>
                    Secondary Color
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <InputBase
                      className={classes.input}
                      placeholder="#FFFFFF"
                      value="#000000"
                    />
                  </Paper>
                </Grid>
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel> */}

        {Template.state.selectedCol ? (
          <Fragment>
            <StylingColumn
              languages={languages}
              shopId={shopId}
              pageId={pageId}
              selectedCol={Template.state.selectedCol}
              onChangeUnsavedData={this.handleChangeUnsavedData}
            />
          </Fragment>
        ) : null}

        {Template.state.selectedColRow ? (
          <Fragment>
            <StylingColumnRow
              languages={languages}
              shopId={shopId}
              pageId={pageId}
              selectedColRow={Template.state.selectedColRow}
              onChangeUnsavedData={this.handleChangeUnsavedData}
              Template={Template}
            />
          </Fragment>
        ) : null}
      </List>
    );
  }
}

Styling.propTypes = {
  classes: PropTypes.object.isRequired,
  pageId: PropTypes.string.isRequired
};

export default withStyles(styles)(Styling);
