import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, createStyles, Grid, Button } from '@material-ui/core'
import { Query } from 'react-apollo'
import { Link } from 'gatsby'
import { GET_CUSTOMER_CART } from '../../../gql/queries'
import { OrganisationContext } from '../../user/context/OrganisationContext'
import { Localization } from '../../../lang/localization'
import { getCurrency } from '../../../helper/storage'

const styles = theme =>
  createStyles({
    root: {
      position: 'fixed !important',
      top: '60px !important',
      left: '92% !important',
      height: '52px'
    },
    headerNav: {
      minHeight: '50px',
      margin: '0',
      textTransform: 'uppercase',
      fontWeight: '500',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'inline-flex',
      textDecoration: 'none',
      position: 'relative',
      paddingRight: '20px',
      [theme.breakpoints.down('sm')]: {
        color: '#000 !important',
        fontSize: '19px !important',
        width: '100%'
      }
    },
    cartItem: {
      position: 'absolute',
      padding: '1px',
      textAlign: 'center',
      width: '18px',
      height: '18px',
      borderRadius: '100%',
      zIndex: '10',
      top: 8,
      right: '0',
      marginLeft: 2,
      lineHeight: '16px',
      [theme.breakpoints.down('sm')]: {
        color: '#000 !important',
        fontSize: '0.9rem  !important',
        right: '40%'
      },
      [theme.breakpoints.down('xs')]: {
        color: '#000 !important',
        fontSize: '0.9rem  !important',
        right: '30%'
      }
    },
    mobileHeaderCart: {
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  })

const CartLink = ({ styling, classes, urlPrefix, cartItems, linkColor }) => {
  return (
    <Button
      style={{
        fontSize: styling && styling.fontSize ? styling.fontSize : null,
        color: linkColor
      }}
      component={Link}
      data-cy="header-btn-mycart"
      className={classes.headerNav}
      to={`${urlPrefix}app/cart`}
    >
      {Localization.get('my_cart')}
      <span
        style={{
          fontSize: styling && styling.fontSize ? styling.fontSize : 'inherit',
          color: linkColor
        }}
        className={classes.cartItem}
      >
        ({cartItems.length})
      </span>
    </Button>
  )
}

class CartHeader extends React.Component {
  render() {
    const { classes, urlPrefix, linkColor, styling } = this.props

    return (
      <Grid item className={classes.mobileHeaderCart}>
        <Grid container className={classes.designContainer}>
          <OrganisationContext.Consumer>
            {currentOrganisation =>
              currentOrganisation && currentOrganisation.id ? (
                <Query
                  query={GET_CUSTOMER_CART}
                  variables={{
                    id: currentOrganisation.id,
                    currency: getCurrency() || 'THB'
                  }}
                >
                  {({ loading, error, data }) => {
                    if (error) return <div />
                    let cartItems = [{}, {}]
                    try {
                      cartItems = data.customerCart.cart.cartItems || []
                      // if (data.customerBoardProducts.edges) {
                      //   customerBoardProducts = _sortBy(
                      //     data.customerBoardProducts.edges,
                      //     function (el) {
                      //       return el.node.createdAt;
                      //     }
                      //   ).reverse();
                      //   // customerBoardProducts filter by deleted
                      // }
                    } catch (error) {}
                    return (
                      <CartLink
                        linkColor={linkColor}
                        styling={styling}
                        classes={classes}
                        urlPrefix={urlPrefix}
                        cartItems={cartItems}
                      />
                    )
                  }}
                </Query>
              ) : (
                <CartLink
                  linkColor={linkColor}
                  styling={styling}
                  classes={classes}
                  urlPrefix={urlPrefix}
                  cartItems={[]}
                />
              )
            }
          </OrganisationContext.Consumer>
        </Grid>
      </Grid>
    )
  }
}

CartHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CartHeader)
