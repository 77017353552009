import React from 'react'
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableCell,
  TableRow
} from '@material-ui/core'
import { getTextFromLanguage } from '../../../../helper/languageHelper'

const ProductDiscounts = ({ defaultLanguage, product, shopId }) => {
  return (
    <Grid container style={{ marginTop: 24 }}>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <Typography data-cy="Product-Discounts-header" variant="subtitle1">
          Assigned discounts
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
        <Paper elevation={0} style={{ padding: 24 }}>
          <Grid container alignItems="flex-end" spacing={2}>
            {product.discounts ? (
              <Table>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Enabled</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Coupon?</TableCell>
                </TableRow>
                {product.discounts.map(d => {
                  return (
                    <TableRow
                      style={{
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        window.open(
                          '/admin/' +
                            shopId +
                            '/settings/discounts/' +
                            d.discount.id
                        )
                      }}
                      key={'d-' + d.discount.id}
                    >
                      <TableCell>
                        {getTextFromLanguage(
                          d.discount.title.text,
                          defaultLanguage
                        )}
                      </TableCell>
                      <TableCell>
                        {d.discount.enabled === true ? 'Enabled' : 'Disabled'}
                      </TableCell>
                      <TableCell>{d.discount.discountType}</TableCell>
                      <TableCell>
                        {d.discount.availableFrom
                          ? new Date(d.discount.availableFrom).toGMTString()
                          : ''}
                      </TableCell>
                      <TableCell>
                        {d.discount.availableUntil
                          ? new Date(d.discount.availableUntil).toGMTString()
                          : ''}
                      </TableCell>
                      <TableCell>
                        {d.discount.hasCoupon === true ? 'Yes' : 'No'}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            ) : (
              'No discounts'
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ProductDiscounts
