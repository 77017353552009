import React, { useState } from 'react'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { Grid } from '@material-ui/core'

interface Props {
  defaultValue: string
  fieldName: string
  level: 'root' | 'style'
  styleId: string
  contentId: string
  handleUpdate: any
}

const AlignControl: React.FC<Props> = props => {
  const {
    defaultValue,
    fieldName,
    level,
    styleId,
    contentId,
    handleUpdate
  } = props
  const [alignment, setAlignment] = useState(defaultValue ? defaultValue : null)

  const handleChange = (event: any, newAlignment: any) => {
    setAlignment(newAlignment)
    handleUpdate(contentId, fieldName, newAlignment, level, styleId)
  }

  const children = [
    <ToggleButton key={1} value="flex-start">
      <FormatAlignLeftIcon />
    </ToggleButton>,
    <ToggleButton key={2} value="center">
      <FormatAlignCenterIcon />
    </ToggleButton>,
    <ToggleButton key={3} value="flex-end">
      <FormatAlignRightIcon />
    </ToggleButton>
  ]

  return (
    <Grid
      container={true}
      justify="center"
      style={{ padding: 10, width: '100%' }}
    >
      <ToggleButtonGroup
        size="large"
        value={alignment}
        exclusive
        onChange={handleChange}
      >
        {children}
      </ToggleButtonGroup>
    </Grid>
  )
}

export default AlignControl
