import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button, Paper } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Mutation } from 'react-apollo'
import { CONTENT_CREATE_PAGE } from '../../gql/admin-mutations'
import { GET_PAGES } from '../../gql/admin-queries'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import { navigate } from '@reach/router'
import _remove from 'lodash/remove'
import { normalizeTextTitle } from './utils'
import _get from 'lodash/get'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import PageSettings from './PageSettings'
import PagesSetHomepage from './PagesSetHomepage'
import Input from '../../../common/Input'
import client from '../../gql/admin-apollo-config'

const uuidv4 = require('uuid/v4')

const styles = theme => ({
  component: {
    border: '1px solid #0000001f',
    width: 'calc(100% - 88px)',
    borderRadius: '6px',
    margin: '8px 0px',
    background: '#FFFFFF'
  },
  ListItemText: { position: 'relative' },
  typo: {
    color: '#000000'
  },
  button: {
    color: '#B23566',
    border: '1px solid #B23566',
    position: 'absolute',
    top: '0%',
    right: 0,
    marginTop: '-64px'
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45
  },
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%',
    height: '100%'
  },
  cell: {
    padding: '0px 16px !important'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  }
})

class PagesList extends React.Component {
  state = {
    selectedPage: this.props.selectedPage,
    open: false,
    title: '',
    pageName: ''
  }

  handleClickOpen = () => {
    this.setState({ open: true, title: '' })
  }

  handleClose = () => {
    this.setState({ open: false, title: '' })
  }

  createPage = () => {
    client
      .mutate({
        mutation: CONTENT_CREATE_PAGE,
        variables: {
          input: {
            status: 'DRAFT',
            slugs: [
              {
                langCode: 'en_UK',
                content: this.state.title.toLowerCase()
              },
              {
                langCode: 'nl_NL',
                content: this.state.title.toLowerCase()
              },
              {
                langCode: 'fr_FR',
                content: this.state.title.toLowerCase()
              }
            ],
            name: this.state.title,
            title: {
              id: uuidv4(),
              text: {
                content: this.state.title,
                langCode: 'en_UK'
              }
            },
            description: {
              id: uuidv4(),
              text: {
                content: this.state.title,
                langCode: 'en_UK'
              }
            }
          }
        },
        update: (cache, { data: { createContentPage } }) => {
          if (createContentPage.id) {
            const { shop } = cache.readQuery({
              query: GET_PAGES,
              variables: { id: this.props.shopId }
            })

            shop.pages.edges.push({
              node: createContentPage,
              __typename: 'ShopPagesEdge'
            })

            cache.writeQuery({
              query: GET_PAGES,
              variables: { id: this.props.shopId },
              data: {
                shop: shop
              }
            })
          }
        }
      })
      .then(this.setState({ open: false, title: '' }))
  }

  render() {
    const {
      classes,
      defaultLanguage,
      pageId,
      Template,
      contentSettings,
      pages,
      shopId
    } = this.props

    var pageArray = pages

    // for future ref -> let pages = Template.pages
    // console.log('page render: ', pages)
    let PageName

    return (
      <div className={classes.root}>
        <Grid item xs={12}>
          {pageArray.edges.length > 0 ? (
            <Paper elevation={0}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cell}>Name</TableCell>
                    <TableCell className={classes.cell}>Tag</TableCell>
                    <TableCell className={classes.cell}>Status</TableCell>
                    <TableCell className={classes.cell}>Set Homepage</TableCell>
                    <TableCell className={classes.cell}>Options</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {pageArray.edges.map(pages => {
                    var pageTag = []

                    for (let i = 0; i < pages.node.tags.length; i++) {
                      var element = pages.node.tags[i].tag.title.text
                      pageTag.push(
                        getTextFromLanguage(element, defaultLanguage)
                      )
                    }

                    PageName = normalizeTextTitle(pages.node.name)

                    return (
                      <TableRow
                        key={pages.node.id}
                        hover
                        style={{ textDecoration: 'none' }}
                      >
                        <TableCell
                          data-cy={'pages-name-' + PageName}
                          className={classes.cell}
                          style={{ cursor: 'pointer' }}
                          component="th"
                          scope="row"
                          onClick={() => {
                            navigate(
                              '/admin/' +
                                shopId +
                                '/pagebuilder/pages/' +
                                pages.node.id +
                                '/content'
                            )
                          }}
                        >
                          {pages.node.name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {pageTag}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          component="th"
                          scope="row"
                        >
                          {pages.node.status}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <PagesSetHomepage
                            shopId={shopId}
                            pageId={pages.node.id}
                            indexPageId={
                              contentSettings && contentSettings.indexPageId
                                ? contentSettings.indexPageId
                                : ''
                            }
                          />
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <PageSettings
                            pages={pages}
                            pageArray={pages}
                            Template={Template}
                            pageId={pages.node.id}
                            shopId={shopId}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          ) : null}
          <Grid container justify="center" alignItems="center">
            <Button
              data-cy="pages-new-page-btn"
              onClick={this.handleClickOpen}
              variant="outlined"
              className={classes.button}
            >
              New Page
            </Button>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
              style={{ zIndex: 99999 }}
            >
              <DialogTitle
                data-cy="pages-add-new-page-header"
                id="form-dialog-title"
              >
                Add New Page
              </DialogTitle>
              <DialogContent>
                <TextField
                  data-cy="new-page-title-input"
                  autoFocus
                  margin="dense"
                  onKeyPress={e => {
                    if (e.keyCode === 13 || e.key === 'Enter') {
                      this.createPage()
                      e.preventDefault()
                    }
                  }}
                  id="name"
                  label="Page Title"
                  type="text"
                  value={this.state.title}
                  onChange={e => {
                    this.setState({
                      title: e.target.value ? e.target.value : ''
                    })
                  }}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  data-cy="new-page-title-cancel-btn"
                  onClick={this.handleClose}
                  style={{ color: 'grey' }}
                >
                  Cancel
                </Button>
                <Button
                  data-cy="add-new-page-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => this.createPage()}
                >
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </div>
    )
  }
}

PagesList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PagesList)
