import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks';
import {CircularProgress, Typography, Grid, WithStyles, withStyles, Button, createStyles} from '@material-ui/core'

import GlobalStyles from '../../../globalStyles'
import {ADMIN_GET_DEPLOYMENTS} from '../../gql/admin-queries'
import {ADMIN_TRIGGER_DEPLOYMENT} from '../../gql/admin-mutations'
import DeploymentList from './DeploymentList'


const styles = () => createStyles({
  cell: {},
  container: {
    padding: '8px 0px'
  }
})


interface Props extends WithStyles<typeof styles> {
  shopId: string
  shop: any
}


const DeploymentPage: React.FunctionComponent<Props> = props => {
  const {shopId, shop} = props

  const { data, loading, error } = useQuery(ADMIN_GET_DEPLOYMENTS, { variables: { id: shopId } })


  const [triggerDeployment, mutationResult] = useMutation(
    ADMIN_TRIGGER_DEPLOYMENT, {
      variables: {
        siteId: shop.netlifyConfiguration.siteId,
        title: 'Test'
      },
      refetchQueries: [{
        query: ADMIN_GET_DEPLOYMENTS,
        variables: {id: shopId}
      }]
    }
  );

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <h2>{`Error: ${error.message}`}</h2>;
  }

  return (
    <Grid container={true}>
      <Grid
        container={true}
        style={{ maxWidth: '1000px', margin: 'auto', padding: 24 }}
      >
        <GlobalStyles />        
      
        <Grid item={true} xs={6} style={{ marginTop: 24 }}>
          <Typography variant="h1">Deployment</Typography>
        </Grid>
        <Grid item={true} xs={6} style={{ marginTop: 24 }}>
          <Button
            style={{ float: 'right' }}
            variant="outlined"
            color="primary"
            disabled={mutationResult.loading}
            onClick={() => triggerDeployment()}
          >
            {mutationResult.loading  ? 'Loading' : 'Deploy now'}
          </Button>
        </Grid>
        
        
        <Grid item={true} xs={12} style={{ marginTop: 24 }}>
          <Typography variant="body1">
            Deployment status
          </Typography>
          {
            shop.netlifyConfiguration ?
            <img
            src={`https://api.netlify.com/api/v1/badges/${shop.netlifyConfiguration.siteId}/deploy-status`}
            alt="deploy-status"
            style={{ marginTop: 10 }}
          />
          :'No ID'
          }         
        
        </Grid>
        <DeploymentList data={data.shop.deployments.edges} siteId={shop.netlifyConfiguration.siteId} shopId={shopId} />
      </Grid>
      
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(DeploymentPage)
