import React from 'react';
import { getStyleAlignmentItem } from '../../../helper/menuHelper';
import { Grid, makeStyles, createStyles, Theme } from '@material-ui/core';
import SearchInput from './SearchInput';
import SocialLinks from './SocialLinks';
import PaymentOptions from './PaymentOptions';
import CartHeader from '../cart/CartHeader';
import LanguageSwitch from '../LanguageSwitch';
import SetCountryContainer from './SetCountryContainer';
import Contacts from './Contacts';
import ShopLogo from './ShopLogo';
import TeamstoreLogo from './TeamstoreLogo';
import TermsLink from './TermsLink';
import CurrencySwitch from './CurrencySwitchContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MobileModuleRender: {
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  })
)

interface Props {
  isEditMode?: boolean;
  alignment?: string;
  shop: any;
  contentDetail: any;
  styling: any;
  urlPrefix?: string;
  teamstore?: any;
  shopId?: any;
  slugs?: any[];
  languages?: any[];
  language?: string;
  linkColor?: string;
}

const ModuleRender: React.FunctionComponent<Props> = props => {
  const {
    isEditMode,
    alignment,
    contentDetail,
    shop,
    shopId,
    teamstore,
    urlPrefix,
    linkColor,
    language,
    slugs,
    styling
  } = props

  const classes = useStyles()
  const renderModule = styling => {
    if (!contentDetail) {
      return null
    }
    switch (contentDetail.type) {
      case 'SEARCH_INPUT':
        return (
          <SearchInput
            urlPrefix={urlPrefix}
            linkColor={linkColor}
            styling={styling}
          />
        )
      case 'SOCIAL_LINKS':
        return (
          <SocialLinks
            linkColor={linkColor}
            data={shop ? shop.socialLinks : []}
          />
        );
      case 'PAYMENT_OPTIONS':
        return (
          <PaymentOptions
            data={shop ? shop.paymentOptions : []}
            size={12}
            isFooter={true}
            linkColor={linkColor}
          />
        );
      case 'CART_COUNTER':
        return (
          <CartHeader
            styling={styling}
            linkColor={linkColor}
            urlPrefix={urlPrefix}
          />
        )
      case 'LANGUAGE_SWITCH':
        return (
          <LanguageSwitch
            shopId={shopId}
            defaultLanguage={shop ? shop.defaultLanguage : null}
            languages={shop ? shop.languages : []}
            urlPrefix={urlPrefix}
            slugs={slugs}
            styling={styling}
            linkColor={linkColor}
            currentLanguage={language}
            isEditMode={isEditMode}
          />
        )
      case 'COUNTRY_SWITCH':
        return <SetCountryContainer />
      case 'CONTACTS':
        return (
          <Contacts
            linkColor={linkColor}
            data={shop ? shop.businessAddress : {}}
          />
        )
      case 'SHOP_LOGO':
        return (
          <ShopLogo
            teamstoreLogo={
              process.env.GATSBY_BRIKL_SALESCHANNEL_ID
                ? null
                : shop &&
                  shop.id === 'vermarc' &&
                  teamstore &&
                  teamstore.teamstore
                ? teamstore.teamstore.logo
                : null
            }
            teamstoreUrl={
              process.env.GATSBY_BRIKL_SALESCHANNEL_ID
                ? null
                : shop &&
                  shop.id === 'vermarc' &&
                  teamstore &&
                  teamstore.teamstore &&
                  teamstore.teamstore.slugs &&
                  teamstore.teamstore.slugs.find(s => s.langCode === language)
                ? teamstore.teamstore.slugs.find(s => s.langCode === language)
                    .content
                : null
            }
            defaultLanguage={shop.defaultLanguage}
            urlPrefix={urlPrefix}
            currentLanguage={language}
            shopLogo={
              shop && shop.brandSettings ? shop.brandSettings.logo : null
            }
            contentDetail={contentDetail}
            styling={styling}
          />
        )
      case 'TEAMSTORE_LOGO':
        return (
          <TeamstoreLogo
            defaultLanguage={shop.defaultLanguage}
            urlPrefix={urlPrefix}
            currentLanguage={language}
            teamstoreLogo={teamstore ? teamstore.logo : null}
            contentDetail={contentDetail}
          />
        )
      case 'TERMS_LINK':
        return <TermsLink urlPrefix={urlPrefix} currentLanguage={language} />
      case 'CURRENCY_SWITCH':
        return (
          <CurrencySwitch
            styling={styling}
            currencies={shop && shop.currencies ? shop.currencies : []}
            linkColor={linkColor}
            isEditMode={isEditMode}
          />
        )
      default:
        return null
    }
  }

  return (
    <Grid
      item={true}
      style={{
        ...getStyleAlignmentItem(alignment, true),
        padding: '0px'
      }}
      className={classes.MobileModuleRender}
      data-testid="module-item"
    >
      {renderModule(styling)}
    </Grid>
  )
}

export default ModuleRender
