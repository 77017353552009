import React, { Component } from 'react'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { List, Button } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Auth from '@aws-amplify/auth'
import { ChevronRight, ChevronLeft } from '@material-ui/icons'
import { isBriklUser } from '../../../../helper/userHelper'

var jwtDecode = require('jwt-decode')

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '180px',
    paddingTop: '0px',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)'
  },
  linkContainer: {
    color: '#000000',
    padding: 0
  },
  linkText: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: 300
  },
  logo: {
    maxWidth: 150,
    height: '35px',
    marginTop: '24px',
    alignSelf: 'center'
  },
  searchIcon: {
    width: 45,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white'
  },
  inputRoot: {
    color: 'white',
    width: '100%'
  },
  menuButton: {
    marginRight: 20,
    color: '#000000',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  containerMenu: {
    background: '#ffffff',
    height: '100vh',
    overflowY: 'auto',
    width: '180px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  containerMenuMobile: {
    background: '#ffffff',
    height: '100vh',
    overflowY: 'auto',
    width: '0px',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 4,
    display: 'none',
    borderRight: '1px solid #E5E5E5',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '180px'
    }
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  buttonCloseDrawer: {
    position: 'fixed',
    top: 50,
    left: 158,
    zIndex: 10000,
    background: '#FFF',
    '&:hover': {
      background: '#FFF'
    },
    border: '1px solid #E5E5E5',
    borderRadius: '100%',
    minWidth: 40,
    minHeight: 40
  }
})
const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          width: '100%',
          height: '100%',
          display: 'block',
          textDecoration: 'none',
          color: '#000'
          // textShadow: isCurrent ? "1px #eee" : "none"
        }
      }
    }}
  />
)
class ReportingMenu extends Component {
  state = {
    show: false,
    isBriklUser: false
  }

  async componentDidMount() {
    try {
      const session = await Auth.currentSession()
      if (session) {
        const decoded = jwtDecode(session.idToken.jwtToken)
        this.setState({
          isBriklUser: isBriklUser(decoded['email'])
        })
      }
    } catch (error) {}
  }

  handleClick = () => {
    this.setState(state => ({ show: !state.show }))
  }

  render() {
    const { classes, theme, shopId } = this.props
    const { show, isBriklUser } = this.state
    var menuContent = (
      <div>
        <div className={classes.containerMenu}>
          <List style={{ width: '180px' }}>
            <ListItem classes={{ primary: classes.linkContainer }} button>
              <NavLink
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/reporting/general'}
              >
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Report Home"
                />
              </NavLink>
            </ListItem>

            <ListItem classes={{ primary: classes.linkContainer }} button>
              <NavLink
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/reporting/customers'}
              >
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Customer Report"
                />
              </NavLink>
            </ListItem>
            <ListItem classes={{ primary: classes.linkContainer }} button>
              <NavLink
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/reporting/orders'}
              >
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Order Report"
                />
              </NavLink>
            </ListItem>
            <ListItem classes={{ primary: classes.linkContainer }} button>
              <NavLink
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/reporting/teamstore'}
              >
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Teamstore Report"
                />
              </NavLink>
            </ListItem>
            <ListItem classes={{ primary: classes.linkContainer }} button>
              <NavLink
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/reporting/order-items'}
              >
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Order Item Report"
                />
              </NavLink>
            </ListItem>
            <ListItem classes={{ primary: classes.linkContainer }} button>
              <NavLink
                style={{ textDecoration: 'none' }}
                to={'/admin/' + shopId + '/reporting/coupons'}
              >
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Coupon Report"
                />
              </NavLink>
            </ListItem>
            {isBriklUser ? (
              <ListItem classes={{ primary: classes.linkContainer }} button>
                <NavLink
                  style={{ textDecoration: 'none' }}
                  to={'/admin/' + shopId + '/reporting/accounting'}
                >
                  <ListItemText
                    classes={{ primary: classes.linkText }}
                    primary="Accounting Report"
                  />
                </NavLink>
              </ListItem>
            ) : null}
          </List>
        </div>

        <div className={classes.mobile}>
          <Button
            variant="contained"
            style={{
              position: 'fixed',
              top: 70,
              left: 13,
              margin: 'auto',
              minWidth: 35,
              minHeight: 35,
              width: 35,
              height: 35,
              borderRadius: '100%',
              backgroundColor: '#fff',
              border: '1px solid #E5E5E%'
            }}
            onClick={this.handleClick}
          >
            <ChevronRight />
          </Button>
          {show ? (
            <div className={classes.containerMenuMobile}>
              <List style={{ width: '180px', position: 'relative' }}>
                <Button
                  className={classes.buttonCloseDrawer}
                  onClick={this.handleClick}
                >
                  <ChevronLeft />
                </Button>
                <ListItem classes={{ primary: classes.linkContainer }} button>
                  <NavLink
                    style={{ textDecoration: 'none' }}
                    onClick={this.handleClick}
                    to={'/admin/' + shopId + '/reporting/general'}
                  >
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Report Home"
                    />
                  </NavLink>
                </ListItem>

                <ListItem classes={{ primary: classes.linkContainer }} button>
                  <NavLink
                    style={{ textDecoration: 'none' }}
                    onClick={this.handleClick}
                    to={'/admin/' + shopId + '/reporting/customers'}
                  >
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Customer Report"
                    />
                  </NavLink>
                </ListItem>
                <ListItem classes={{ primary: classes.linkContainer }} button>
                  <NavLink
                    style={{ textDecoration: 'none' }}
                    onClick={this.handleClick}
                    to={'/admin/' + shopId + '/reporting/orders'}
                  >
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Order Report"
                    />
                  </NavLink>
                </ListItem>
                <ListItem classes={{ primary: classes.linkContainer }} button>
                  <NavLink
                    style={{ textDecoration: 'none' }}
                    onClick={this.handleClick}
                    to={'/admin/' + shopId + '/reporting/teamstore'}
                  >
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Teamstore Report"
                    />
                  </NavLink>
                </ListItem>
                <ListItem classes={{ primary: classes.linkContainer }} button>
                  <NavLink
                    style={{ textDecoration: 'none' }}
                    onClick={this.handleClick}
                    to={'/admin/' + shopId + '/reporting/order-items'}
                  >
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Order Items Report"
                    />
                  </NavLink>
                </ListItem>
                <ListItem classes={{ primary: classes.linkContainer }} button>
                  <NavLink
                    style={{ textDecoration: 'none' }}
                    onClick={this.handleClick}
                    to={'/admin/' + shopId + '/reporting/coupons'}
                  >
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Coupon Report"
                    />
                  </NavLink>
                </ListItem>
              </List>
            </div>
          ) : null}
        </div>
      </div>
    )

    return <div className={classes.root}>{menuContent}</div>
  }
}
ReportingMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(ReportingMenu)
