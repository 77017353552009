const toDataURL = (url: string) => {        
    return fetch(url).then((response) => {
        return response.blob();
    }).then(blob => {
        return URL.createObjectURL(blob);
    });
}

export const download = async (url: string) => {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    const filename = url.substring(url.lastIndexOf('/')+1);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}