import { findColorZoneinTemplates } from '../tools/Functions/load'
var DesignRoot = null

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot
}

export function mutate_colorzone_update(color) {
  // hex
  // CALL AT : change_color.changeColorPickerSelected

  var _SELECT_COLOR_ZONE = DesignRoot._SELECT_COLOR_ZONE // CURRENT ZONE COLOR
  var _COLOR_REF = DesignRoot.FillStep._COLOR_REF
  var defaultZoneColorObject = DesignRoot.defaultZoneColorObject
  let zoneColors = DesignRoot.zoneColors || []

  logger.log(
    'mutate_colorzone_update',
    color,
    _COLOR_REF,
    defaultZoneColorObject,
    _SELECT_COLOR_ZONE
  )

  logger.log('currentChangeColor', DesignRoot.currentChangeColor)

  for (var i = 0; i < DesignRoot.currentChangeColor.length; i++) {
    color = DesignRoot.currentChangeColor[i].toLowerCase()
    _SELECT_COLOR_ZONE = DesignRoot.defaultZoneColor[i]

    logger.log(color, _SELECT_COLOR_ZONE)

    if (_COLOR_REF[color] && defaultZoneColorObject[_SELECT_COLOR_ZONE]) {
      // CHECK IF COLOR MATCH WITH CMS COLOR

      logger.log(
        'COLOR CHECK PASS : MUTATE READY',
        color,
        _SELECT_COLOR_ZONE,
        _COLOR_REF[color],
        defaultZoneColorObject[_SELECT_COLOR_ZONE]
      )
      // STATEMENT HERE

      var colorId = _COLOR_REF[color].id
      var zoneId = defaultZoneColorObject[_SELECT_COLOR_ZONE].id

      logger.log('colorId', colorId)
      logger.log('zoneId', zoneId)

      const existingZoneColor = zoneColors.find(o => o.zoneId === zoneId)
      if (existingZoneColor) {
        existingZoneColor.colorId = colorId
      } else {
        zoneColors.push({
          ...findColorZoneinTemplates(
            DesignRoot.props?.productDesign?.product?.designTemplates,
            zoneId
          ),
          ...existingZoneColor,
          colorId,
          zoneId
        })
      }
      DesignRoot.zoneColors = zoneColors
      logger.log('zoneColors', zoneColors, DesignRoot.zoneColors)
    } else if (
      color !== _SELECT_COLOR_ZONE &&
      defaultZoneColorObject[_SELECT_COLOR_ZONE]
    ) {
      logger.log(
        'CUSTOM COLOR',
        color,
        _SELECT_COLOR_ZONE,
        defaultZoneColorObject[_SELECT_COLOR_ZONE]
      )

      var colorId = null
      var zoneId = defaultZoneColorObject[_SELECT_COLOR_ZONE].id

      logger.log('colorId', colorId)
      logger.log('zoneId', zoneId)

      const existingZoneColor = zoneColors.find(o => o.zoneId === zoneId)
      if (existingZoneColor) {
        existingZoneColor.colorId = colorId
        existingZoneColor.colorHex = color
      } else {
        zoneColors.push({
          ...findColorZoneinTemplates(
            DesignRoot.props?.productDesign?.product?.designTemplates,
            zoneId
          ),
          ...existingZoneColor,
          colorHex: color,
          colorId,
          zoneId
        })
      }
      DesignRoot.zoneColors = zoneColors
      logger.log('zoneColors', zoneColors, DesignRoot.zoneColors)
    } else {
      logger.log('COLOR CHECK FAIL')
    }
  } // END FOR
}
