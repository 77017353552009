import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Notification from 'react-notification';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  ADMIN_SHOP_FABRICS,
  ADMIN_GET_PRODUCT,
  ADMIN_GET_ORG_BOARDPRODUCTS
} from '../../gql/admin-queries';
import {
  ADMIN_UPDATE_PRODUCT,
  ADMIN_UPLOAD_FILE
} from '../../gql/admin-mutations';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import _sortBy from 'lodash/sortBy';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { API_STAGE } from '../../../../../brikl-config';
import { I18n } from '@aws-amplify/core';
import Helmet from 'react-helmet';
import Input from '../../../common/Input';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BoardProductPreview from '../../../common/BoardProductPreview';
import _get from 'lodash/get';
import ProductElement from './ProductElement';
import './ProductFileDrop.css';
import { DEFAULT_CURRENCY } from '../../../../helper/config';
import { navigate } from 'gatsby';
import { OrganisationContext } from '../../../user/context/OrganisationContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomProductDetail from './CustomProductDetail';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import ProductDescription from './ProductDescription';
import ProductTitle from './ProductTitle';
import FileDrop from 'react-file-drop';
const axios = require('axios');
const shortid = require('shortid');
const uuidv4 = require('uuid/v4');
var jwtDecode = require('jwt-decode');

class ProductAttributeMedia extends Component {
  state = {
    isActive: false,
    message: '',
    isBriklSuperAdmin: false,
    open: false,
    value: 'Yes'
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
  uploadProductAttributeMediaFiles = async (
    files,
    uploadFile,
    productAttribute,
    updateProductAttribute
  ) => {
    try {
      const {} = this.props;
      this.setState({
        // uploading: files.length.toString()
        uploading: true
      });
      for (var i = 0; i < files.length; i++) {
        const myFile = files[i];
        if (myFile.size < 20000000) {
          const fileName =
            myFile.name.replace(/\.[^/.]+$/, '').replace(/\s/g, '-') +
            '-' +
            shortid.generate() +
            '.' +
            myFile.name.split('.').pop();
          const signedUrlResult = await uploadFile({
            variables: {
              type: 'PRODUCT_ATTRIBUTE_MEDIA',
              fileName,
              prefixVariables: ['productAttributeId'],
              input: {
                productAttributeId: productAttribute.id
              }
            }
          });
          const signedUrl = signedUrlResult.data.uploadFile.url;
          var options = {
            headers: {
              'Content-Type': signedUrlResult.data.uploadFile.contentType
            }
          };
          if (signedUrlResult.data.uploadFile.acl) {
            options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl;
          }
          if (signedUrlResult.data.uploadFile.tagging) {
            options.headers['x-amz-tagging'] =
              signedUrlResult.data.uploadFile.tagging;
          }
          const uploadResult = await axios.put(signedUrl, myFile, options);
          // refetch();
          this.setState({
            uploading: false
          });

          await updateProductAttribute({
            optimisticResponse: {
              __typename: 'Mutation',
              updateProductAttribute: {
                ...productAttribute,
                image: fileName
              }
            },
            variables: {
              id: productAttribute.id,
              input: {
                image: fileName
              }
            }
          });
          console.log('uploadResult', uploadResult);
        } else {
          this.setState({
            uploading: false
          });
          alert('Only files up to 20MB supported.');
        }
      }
    } catch (error) {
      console.error('uploaderror', error);
      this.setState({
        uploading: false
      });
    }
  };

  render() {
    const {
      blockEdit,
      classes,
      productAttributeId,
      updateProductAttribute,
      productAttribute
    } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Grid
            container
            style={{ padding: '24px 24px 24px 0px' }}
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <Typography
                data-cy="Attribute-Images-header"
                variant="subtitle1"
                style={{ marginRight: 16 }}
              >
                Product attribute image
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" />
            </Grid>
            <Grid item xs={12}>
              <div />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Paper style={{ padding: 24 }} elevation={0}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                style={{
                  padding: '0px 8px 0px 0px !important'
                }}
              >
                {this.state.uploading === true ? (
                  <span
                    style={{
                      fontSize: '3em'
                    }}
                  >
                    Uploading...
                  </span>
                ) : null}

                <Mutation
                  update={(store, { data: { uploadFile } }) => {}}
                  mutation={ADMIN_UPLOAD_FILE}
                >
                  {uploadFile => (
                    <Grid
                      container
                      style={{
                        border: '1px dashed #E5E5E5',
                        background: '#f5f5f5',
                        padding: 0,
                        borderRadius: '5px'
                      }}
                    >
                      {/* {JSON.stringify(product)} */}
                      <input
                        style={{
                          display: 'none'
                        }}
                        accept=".png,.jpg,.jpeg"
                        id="productMediaUploadInput"
                        type="file"
                        onChange={e => {
                          this.uploadProductAttributeMediaFiles(
                            e.target.files,
                            uploadFile,
                            productAttribute,
                            updateProductAttribute
                          );
                        }}
                      />
                      <Grid
                        item
                        xs={12}
                        data-cy="Attribute-media-drag-and-drop-container"
                        style={{
                          padding: '0'
                        }}
                      >
                        <FileDrop
                          onDrop={(files, event) => {
                            console.log('handleDrop!', files, event);
                            this.uploadProductAttributeMediaFiles(
                              files,
                              uploadFile,
                              productAttribute,
                              updateProductAttribute
                            );
                          }}
                        >
                          <Grid container direction="column">
                            <Grid item>
                              <span>
                                {I18n.get('Drag your file here ')}
                                <br />( .png or .jpg )
                              </span>
                            </Grid>
                          </Grid>
                        </FileDrop>
                      </Grid>
                    </Grid>
                  )}
                </Mutation>
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                style={{
                  border: '1px solid #E5E5E5',
                  padding: '8px 8px 4px 8px',
                  borderRadius: '5px'
                }}
              >
                <Grid container alignItems="center" direction="row" spacing={1}>
                  {productAttribute.image ? (
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      key={'style-1'}
                      style={{
                        position: 'relative'
                      }}
                    >
                      <img
                        src={productAttribute.image}
                        alt="Storefront"
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'inline',
                          objectFit: 'contain',
                          borderRadius: '6px',
                          border: '1px solid #ccc'
                        }}
                      />
                      <Button
                        color="secondary"
                        variant="contained"
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          minHeight: 30,
                          minWidth: 30,
                          height: 30,
                          width: 30,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: 0,
                          borderRadius: '100%'
                        }}
                        onClick={() => {
                          updateProductAttribute({
                            optimisticResponse: {
                              __typename: 'Mutation',
                              updateProductAttribute: {
                                ...productAttribute,
                                image: null
                              }
                            },
                            variables: {
                              id: productAttributeId,
                              input: {
                                image: null
                              }
                            }
                          });
                        }}
                      >
                        <Delete style={{ fontSize: 18 }} />
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default ProductAttributeMedia;
