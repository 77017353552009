import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  MuiThemeProvider,
  Typography,
  Grid,
  Paper,
  TextField,
  InputLabel
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ADMIN_UPDATE_CONTENT_THEME } from '../../../gql/admin-mutations'
import Input from '../../../../common/Input'
import { theme } from '../../../../common/Theme'
import client from '../../../gql/admin-apollo-config'
import uuid from 'uuid'
import SimpleSnackbar from '../../content/SimpleSnackbar'

import { FONTS, TEXT_TRANSFORM, FONT_WEIGHT } from '../../content/config'

const styles = theme => ({
  slider: {
    padding: '22px 0px'
  },
  select: {
    border: '1px solid #ced4da',
    borderRadius: 4
  }
})

const WAIT_INTERVAL = 1000

class ThemeInputField extends Component {
  state = {
    notification: false
  }

  timer = null

  updateLabel(fieldName, value) {
    var { variant, shop, themeId } = this.props

    this.value = value
    this.fieldName = fieldName

    const self = this

    clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      client
        .mutate({
          mutation: ADMIN_UPDATE_CONTENT_THEME,
          variables: {
            id: themeId,
            input: {
              label: {
                id: uuid.v4(),
                style: {
                  [self.fieldName]: self.value
                }
              }
            }
          }
        })
        .then(
          result => {
            this.setState({
              notification: true
            })
          },
          error => {
            //error
          }
        )
    }, WAIT_INTERVAL)
  }

  updateInput(fieldName, value) {
    var { variant, shop, themeId } = this.props

    this.value = value
    this.fieldName = fieldName

    const self = this

    clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      client
        .mutate({
          mutation: ADMIN_UPDATE_CONTENT_THEME,
          variables: {
            id: themeId,
            input: {
              input: {
                id: uuid.v4(),
                style: {
                  [self.fieldName]: self.value
                }
              }
            }
          }
        })
        .then(
          result => {
            this.setState({
              notification: true
            })
          },
          error => {
            //error
          }
        )
    }, WAIT_INTERVAL)
  }

  updateType(fieldName, value) {
    var { variant, shop, themeId } = this.props

    this.value = value
    this.fieldName = fieldName

    const self = this

    clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      client
        .mutate({
          mutation: ADMIN_UPDATE_CONTENT_THEME,
          variables: {
            id: themeId,
            input: {
              input: {
                id: uuid.v4(),
                [self.fieldName]: self.value
              }
            }
          }
        })
        .then(
          result => {
            this.setState({
              notification: true
            })
          },
          error => {
            //error
          }
        )
    }, WAIT_INTERVAL)
  }

  render() {
    const { classes, shop, themeId } = this.props

    let themeData = ''

    if (shop.contentSettings !== null && shop.contentSettings.theme !== null) {
      themeData = shop.contentSettings.theme
    } else {
      themeData = ''
    }

    return (
      <Grid key={themeId} item xs={12} style={{ marginTop: 24 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <MuiThemeProvider theme={theme}>
              <Grid
                container
                style={{ padding: '24px 24px 24px 0px' }}
                spacing={1}
                alignItems="center"
              >
                <Input label="Label" placeholder="this is the placeholder" />
              </Grid>
            </MuiThemeProvider>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Paper style={{ padding: 24, borderRadius: 15 }} elevation={0}>
              <Grid container className={classes.container} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Label</Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Font Size
                  </InputLabel>
                  <TextField
                    placeholder="19px"
                    defaultValue={
                      themeData.label && themeData.label.style
                        ? themeData.label.style.fontSize
                        : null
                    }
                    onChange={e => this.updateLabel('fontSize', e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Font Family
                  </InputLabel>
                  <TextField
                    placeholder="Roboto"
                    defaultValue={
                      themeData.label && themeData.label.style
                        ? themeData.label.style.fontFamily
                        : null
                    }
                    onChange={e =>
                      this.updateLabel('fontFamily', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Text Transform
                  </InputLabel>
                  <TextField
                    placeholder="Uppercase, Lowercase, Capitalized"
                    defaultValue={
                      themeData.label && themeData.label.style
                        ? themeData.label.style.textTransform
                        : null
                    }
                    onChange={e =>
                      this.updateLabel('textTransform', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Font Weight
                  </InputLabel>
                  <TextField
                    placeholder="light, normal, semi-bold, bold"
                    defaultValue={
                      themeData.label && themeData.label.style
                        ? themeData.label.style.fontWeight
                        : null
                    }
                    onChange={e =>
                      this.updateLabel('fontWeight', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Input</Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Font Size
                  </InputLabel>
                  <TextField
                    placeholder="19px"
                    defaultValue={
                      themeData.input && themeData.input.style
                        ? themeData.input.style.fontSize
                        : null
                    }
                    onChange={e => this.updateInput('fontSize', e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Font Family
                  </InputLabel>
                  <TextField
                    placeholder="Roboto"
                    defaultValue={
                      themeData.input && themeData.input.style
                        ? themeData.input.style.fontFamily
                        : null
                    }
                    onChange={e =>
                      this.updateLabel('fontFamily', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Text Transform
                  </InputLabel>
                  <TextField
                    placeholder="Uppercase, Lowercase, Capitalized"
                    defaultValue={
                      themeData.input && themeData.input.style
                        ? themeData.input.style.textTransform
                        : null
                    }
                    onChange={e =>
                      this.updateLabel('textTransform', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Font Weight
                  </InputLabel>
                  <TextField
                    placeholder="light, normal, semi-bold, bold"
                    defaultValue={
                      themeData.input && themeData.input.style
                        ? themeData.input.style.fontWeight
                        : null
                    }
                    onChange={e =>
                      this.updateLabel('fontWeight', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Border Color
                  </InputLabel>
                  <TextField
                    placeholder="Eg.: #ffffff"
                    defaultValue={
                      themeData.input && themeData.input.style
                        ? themeData.input.style.borderColor
                        : null
                    }
                    onChange={e =>
                      this.updateLabel('borderColor', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Border Thickness
                  </InputLabel>
                  <TextField
                    placeholder="Eg.: 1px"
                    defaultValue={
                      themeData.input && themeData.input.style
                        ? themeData.input.style.borderThickness
                        : null
                    }
                    onChange={e =>
                      this.updateLabel('borderThickness', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Background Color
                  </InputLabel>
                  <TextField
                    placeholder="eg.: #000000"
                    defaultValue={
                      themeData.input && themeData.input.style
                        ? themeData.input.style.backgroundColor
                        : null
                    }
                    onChange={e =>
                      this.updateLabel('backgroundColor', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Type
                  </InputLabel>
                  <TextField
                    placeholder="Normal or outlined"
                    defaultValue={
                      themeData.input && themeData.input.type
                        ? themeData.input.type
                        : null
                    }
                    onChange={e => this.updateType('type', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <SimpleSnackbar
          open={this.state.notification}
          message="Theme updated succesfully!"
          verticalAlign="bottom"
          horizontalAlign="right"
        />
      </Grid>
    )
  }
}

ThemeInputField.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ThemeInputField)
