import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import jwtDecode from 'jwt-decode'
import Amplify from '@aws-amplify/core'
import { navigate } from '@reach/router'
import { ApolloConsumer } from 'react-apollo'
class AdminShops extends React.Component {
  state = {
    shops: null
  }
  async componentDidMount() {
    try {
      const session = await Amplify.Auth.currentSession()
      const decoded = jwtDecode(session.idToken.jwtToken)
      if (decoded['cognito:groups'].length === 1) {
        navigate(
          '/admin/' +
            decoded['cognito:groups'][0].replace(':admin', '') +
            '/inventory/products'
        )
      } else {
        if (decoded['cognito:groups'] && decoded['cognito:groups'].length > 0) {
          this.setState({
            shops: decoded['cognito:groups'].map(group =>
              group.replace(':admin', '')
            )
          })
        } else {
          navigate('/admin/login')
        }
      }
    } catch (error) {
      navigate('/admin/login')
    }
  }
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <div>
            <Typography data-cy="Shops-header" variant="h1">
              Shops
            </Typography>
            <Grid container>
              {this.state.shops
                ? this.state.shops.sort().map(shop => {
                    return (
                      <Grid
                        onClick={async () => {
                          await client.resetStore()
                          navigate('/admin/' + shop + '/inventory/products')
                        }}
                        style={{
                          cursor: 'pointer',
                          margin: '1rem',
                          padding: '2rem',
                          border: '1px solid #eee'
                        }}
                        item
                        key={'shopid-' + shop}
                        data-cy={'Shop-' + shop}
                      >
                        {shop}
                      </Grid>
                    )
                  })
                : null}
            </Grid>
          </div>
        )}
      </ApolloConsumer>
    )
  }
}
export default AdminShops
