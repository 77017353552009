import { Link } from 'gatsby'
import React from 'react'
import {
  withStyles,
  List,
  ListItem,
  WithStyles,
  Typography,
  Grid
} from '@material-ui/core'

import {
  getLinkText,
  getLinkUrl,
  prefixSubItems,
  isEmail
} from '../../../helper/menuHelper'
import ModuleRender from '../module/ModuleRender'

const styles = theme => ({
  ColumnTitle: {
    fontSize: '16px'
  },
  linkColor: {
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      fontWeight: 300,
      padding: 12,
      width: '100%'
    }
  },
  list: {
    fontSize: '14px',
    fontWeight: 'normal',
    padding: 8,
    paddingBottom: 0,
    paddingLeft: 0,
    textDecoration: 'none'
  },
  listContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
})

interface Props extends WithStyles<typeof styles> {
  item: any
  linkColor: string
  shop: any
  subElements: any[]
  language: string
  languages: [string]
  styling: any
  urlPrefix: string
  teamstore: any
}

const FooterItem: React.FunctionComponent<Props> = props => {
  const {
    classes,
    item,
    language,
    languages,
    linkColor,
    shop,
    styling,
    subElements,
    urlPrefix,
    teamstore
  } = props

  const renderLinkOrText = () => {
    const linkUrl = getLinkUrl(item, urlPrefix, language)
    const linkText = getLinkText(item, language)

    return linkUrl ? (
      <Link
        style={{
          color: linkColor || 'inherit',
          textDecoration: 'none !important'
        }}
        to={linkUrl}
        className={classes.linkColor}
      >
        {linkText}
      </Link>
    ) : (
      linkText
    )
  }

  return (
    <Grid
      item={true}
      className={classes.listContainer}
      data-testid={`footer-item-${item.id}`}
    >
      <List component="nav">
        {!item.moduleId ? (
          <Typography
            variant="h4"
            style={{ marginTop: 24, marginBottom: 24, textDecoration: 'none' }}
          >
            {renderLinkOrText()}
          </Typography>
        ) : (
          <ModuleRender
            teamstore={teamstore}
            languages={languages}
            language={language}
            shop={shop}
            contentDetail={item.module ? item.module.contentDetail : null}
            urlPrefix={urlPrefix}
            alignment={item.alignment}
            styling={styling}
            linkColor={linkColor}
          />
        )}

        {subElements.map(subItem => (
          <div key={subItem.id} style={{ width: '100%' }}>
            <ListItem
              component={
                getLinkUrl(subItem, urlPrefix, language) !== '' ? 'a' : 'span'
              }
              href={
                getLinkUrl(subItem, urlPrefix, language) !== ''
                  ? getLinkUrl(subItem, urlPrefix, language)
                  : null
              }
              className={classes.list}
              style={{
                color: linkColor,
                fontSize: styling.fontSize,
                opacity: 0.8,
                textDecoration: 'none'
              }}
            >
              {prefixSubItems(subItem._level - 1)}{' '}
              {!subItem.moduleId ? (
                isEmail(getLinkText(subItem, language)) ? (
                  <a
                    style={{
                      color: linkColor,
                      fontSize: styling.fontSize,
                      textDecoration: 'none'
                    }}
                    href={`mailto:${getLinkText(subItem, language)}`}
                  >
                    {getLinkText(subItem, language)}
                  </a>
                ) : (
                  getLinkText(subItem, language)
                )
              ) : (
                <ModuleRender
                  teamstore={teamstore}
                  languages={languages}
                  language={language}
                  linkColor={linkColor}
                  shop={shop}
                  contentDetail={
                    subItem.module ? subItem.module.contentDetail : null
                  }
                  urlPrefix={urlPrefix}
                  alignment={subItem.alignment}
                  styling={styling}
                />
              )}
            </ListItem>
          </div>
        ))}
      </List>
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(FooterItem)
