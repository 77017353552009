import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';

const PageName = ({ classes, page, pageId, updateContentPage }) => {
  return (
    <Grid container>
          <Grid
            container
            key={'style-titleText-pageName'}
            direction="row"
            alignItems="flex-end"
            spacing={1}
            className={classes.container}
          >
            <Grid item xs={12}>
              <TextField
                label="Internal ID"
                placeholder="Enter page name"
                multiline={true}
                rowsMax="4"
                value={page.name}
                onChange={e => {
                  updateContentPage({
                    variables: {
                      id: pageId,
                      input: {
                        name: e.target.value
                      }
                    }
                  });
                }}
              />
            </Grid>
          </Grid>
    </Grid>
  );
};

export default PageName;
