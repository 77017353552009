import React, { useState, useEffect } from 'react'
const { withScriptjs } = require('react-google-maps')
const {
  StandaloneSearchBox
} = require('react-google-maps/lib/components/places/StandaloneSearchBox')

const MapsInput: any = withScriptjs((props: any) => {
  const { contentId, handleUpdateMapsContent, defaultValue } = props
  const [places, setPlaces] = useState([])

  let refs: any = {}

  const onSearchBoxMounted = (ref: any) => {
    refs.searchBox = ref
  }

  const onPlacesChanged = () => {
    setPlaces(refs.searchBox.getPlaces())
  }

  useEffect(() => {
    if (places) {
      handleUpdateMapsContent(
        contentId,
        places[0]?.geometry?.location.lat(),
        places[0]?.geometry?.location.lng(),
        places[0]?.formatted_address
      )
    }
  }, [places])

  return (
    <div data-standalone-searchbox="">
      <StandaloneSearchBox
        ref={onSearchBoxMounted}
        onPlacesChanged={onPlacesChanged}
      >
        <input
          type="text"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`
          }}
        />
      </StandaloneSearchBox>
      <ol>
        {!defaultValue ? (
          places.map(
            ({ place_id, formatted_address, geometry: { location } }) => (
              <li key={place_id}>
                {formatted_address}
                {' at '}({location.lat()}, {location.lng()})
              </li>
            )
          )
        ) : (
          <div>
            <p style={{ fontWeight: 'bold', fontSize: 18 }}>Current address:</p>
            <p>{defaultValue}</p>
          </div>
        )}
      </ol>
    </div>
  )
})

export default MapsInput
