import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  MuiThemeProvider,
  Typography,
  Grid,
  Paper,
  TextField
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ADMIN_UPDATE_CONTENT_THEME } from '../../../gql/admin-mutations'
import Input from '../../../../common/Input'
import { theme } from '../../../../common/Theme'
import client from '../../../gql/admin-apollo-config'
import uuid from 'uuid'
import SimpleSnackbar from '../../content/SimpleSnackbar'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import Slider from '@material-ui/core/Slider'

import { FONTS, TEXT_TRANSFORM, FONT_WEIGHT } from '../../content/config'

const styles = theme => ({
  slider: {
    padding: '22px 0px'
  },
  select: {
    border: '1px solid #ced4da',
    borderRadius: 4
  }
})

const WAIT_INTERVAL = 1000

class ThemeHeading extends Component {
  state = {
    notification: false
  }

  timer = null

  updateField(fieldName, value) {
    var { variant, shop, themeId } = this.props

    if (variant === 'body1') {
      variant = 'p'
    }

    this.value = value
    this.fieldName = fieldName

    const self = this

    clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      client
        .mutate({
          mutation: ADMIN_UPDATE_CONTENT_THEME,
          variables: {
            id: themeId,
            input: {
              [variant]: {
                id: uuid.v4(),
                style: {
                  [self.fieldName]: self.value
                }
              }
            }
          }
        })
        .then(
          result => {
            this.setState({
              notification: true
            })
          },
          error => {
            //error
          }
        )
    }, WAIT_INTERVAL)
  }

  render() {
    var { classes, title, variant, shop, themeId } = this.props

    if (variant === 'body1') {
      variant = 'p'
    }

    let themeData = ''

    if (shop.contentSettings !== null && shop.contentSettings.theme !== null) {
      themeData = shop.contentSettings.theme
    } else {
      themeData = ''
    }

    return (
      <Grid key={themeId} item xs={12} style={{ marginTop: 24 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <MuiThemeProvider theme={theme}>
              <Grid
                container
                alignItems="center"
                justify="center"
                justify="center"
              >
                <Typography
                  style={{
                    fontSize:
                      themeData[variant] && themeData[variant].style
                        ? themeData[variant].style.fontSize
                        : null,
                    fontFamily:
                      themeData[variant] && themeData[variant].style
                        ? themeData[variant].style.fontFamily
                        : null,
                    textTransform:
                      themeData[variant] && themeData[variant].style
                        ? themeData[variant].style.textTransform
                        : null,
                    fontWeight:
                      themeData[variant] && themeData[variant].style
                        ? themeData[variant].style.fontWeight
                        : null,
                    letterSpacing:
                      themeData[variant] && themeData[variant].style
                        ? themeData[variant].style.letterSpacing
                        : null
                  }}
                  variant={variant}
                >
                  {title}
                </Typography>
              </Grid>
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Paper style={{ padding: 24, borderRadius: 15 }} elevation={0}>
              <Grid container className={classes.container} spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Font Size
                  </InputLabel>
                  <TextField
                    placeholder="45px"
                    defaultValue={
                      themeData[variant] && themeData[variant].style
                        ? themeData[variant].style.fontSize
                        : null
                    }
                    onChange={e => this.updateField('fontSize', e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Letter Spacing
                  </InputLabel>
                  <TextField
                    placeholder="normal"
                    defaultValue={
                      themeData[variant] && themeData[variant].style
                        ? themeData[variant].style.letterSpacing
                        : null
                    }
                    onChange={e =>
                      this.updateField('letterSpacing', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Font family
                  </InputLabel>
                  <NativeSelect
                    classes={{ root: classes.select }}
                    native="true"
                    value={
                      themeData[variant] && themeData[variant].style
                        ? themeData[variant].style.fontFamily
                        : 'Roboto'
                    }
                    onChange={e =>
                      this.updateField('fontFamily', e.target.value)
                    }
                  >
                    {FONTS.map(font => (
                      <option key={font} value={font}>
                        {font}
                      </option>
                    ))}
                  </NativeSelect>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="textTransform-native-helper">
                    Text transform
                  </InputLabel>
                  <NativeSelect
                    classes={{ root: classes.select }}
                    native="true"
                    value={
                      themeData[variant] && themeData[variant].style
                        ? themeData[variant].style.textTransform
                        : 'none'
                    }
                    onChange={e =>
                      this.updateField('textTransform', e.target.value)
                    }
                  >
                    {TEXT_TRANSFORM.map(textTransform => (
                      <option key={textTransform} value={textTransform}>
                        {textTransform}
                      </option>
                    ))}
                  </NativeSelect>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="textTransform-native-helper">
                    Font weight
                  </InputLabel>
                  <NativeSelect
                    classes={{ root: classes.select }}
                    native="true"
                    value={
                      themeData[variant] && themeData[variant].style
                        ? themeData[variant].style.fontWeight
                        : 'none'
                    }
                    onChange={e =>
                      this.updateField('fontWeight', e.target.value)
                    }
                  >
                    {FONT_WEIGHT.map(fontWeight => (
                      <option key={fontWeight.tag} value={fontWeight.value}>
                        {fontWeight.label}
                      </option>
                    ))}
                  </NativeSelect>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <SimpleSnackbar
          open={this.state.notification}
          message="Theme updated succesfully!"
          verticalAlign="bottom"
          horizontalAlign="right"
        />
      </Grid>
    )
  }
}

ThemeHeading.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ThemeHeading)
