import React from 'react'
interface Props {
  width?: any
  height?: any
}

const PlaceholderImg: React.FC<Props> = props => {
  const { width, height } = props
  return (
    <div style={{ textAlign: 'center', padding: 5 }}>
      <svg
        width={width ? width : 250}
        height={height ? height : 250}
        viewBox="0 0 469 469"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width={width ? width : 469}
          height={height ? height : 469}
          fill="#C4C4C4"
        />
      </svg>
    </div>
  )
}

export default PlaceholderImg
