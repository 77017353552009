import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { ADMIN_DELETE_DISCOUNT_RULE } from '../../gql/admin-mutations';
import { ADMIN_GET_DISCOUNT } from '../../gql/admin-queries';
import { Mutation } from 'react-apollo';
const omitDeep = require('omit-deep');
const styles = {
  root: {}
};
const DiscountRuleDelete = ({
  classes,
  discountRule,
  discountRuleIndex,
  discount,
  discountId,
  updateDiscount
}) => {
  return !discountRule ? null : (
    <Mutation
      mutation={ADMIN_DELETE_DISCOUNT_RULE}
      variables={{
        discountId,
        id: discountRule.id
      }}
      update={(cache, { data: { deleteDiscountRule } }) => {
        const data = cache.readQuery({
          query: ADMIN_GET_DISCOUNT,
          variables: { id: discountId }
        });
        data.discount.discountRules = data.discount.discountRules.filter(
          (removeStyle1, removePIindex) => {
            if (discountRuleIndex === removePIindex) {
              return false;
            } else {
              return true;
            }
          }
        );
        cache.writeQuery({
          query: ADMIN_GET_DISCOUNT,
          variables: { id: discountId },
          data
        });
      }}
    >
      {(deleteDiscountRule, { data, loading, error }) => {
        return (
          <Button
            color="primary"
            variant="contained"
            style={{
              marginLeft: 'auto'
            }}
            variant="contained"
            onClick={async () => {
              deleteDiscountRule();
            }}
          >
            Delete
          </Button>
        );
      }}
    </Mutation>
  );
};
DiscountRuleDelete.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DiscountRuleDelete);
