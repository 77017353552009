import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import client from '../../gql/admin-apollo-config';
import { Grid, Paper } from '@material-ui/core';
import TemplateTabs from './TemplateTabs';
import ContentIndex from './ContentIndex';
import { Query } from 'react-apollo';
import {
  GET_PAGES,
  GET_PAGE_CONTENT,
  ADMIN_GET_CONTENT_THEME
} from '../../gql/admin-queries';
import { CONTENT_UPDATE_ROW } from '../../gql/admin-mutations';
import CircularProgress from '@material-ui/core/CircularProgress';
import { navigate } from '@reach/router';
import _get from 'lodash/get';
import ContentAppBar from './ContentAppBar';
import iphone from './iphone-frame.png';
const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
    width: 'calc(100vw - 240px)',
    position: 'absolute',
    top: 0,
    left: '240px',
    minHeight: '100vh'
  },
  drawer: {
    float: 'right',
    width: drawerWidth,
    flexShrink: 0
  },
  appBar: {
    marginLeft: drawerWidth,
    display: 'block',
    width: `calc(100% - ${drawerWidth + 240}px)`,
    backgroundColor: 'white',
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
  menuButton: {
    marginRight: 20
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'relative',
    height: '100vh',
    background: '#ffffff'
  },
  content: {
    width: 'calc(100vw - 480px)',
    height: 'calc(100vh - 70px)',
    padding: '0px 24px 24px 24px',
    background: '#f2f2f2',
    marginTop: '66px',
    float: 'left',
    overflowY: 'auto'
  },
  container: {
    flex: 1,
    overflow: 'hidden',
    minHeight: 'calc(100vh - 48px)',
    borderRadius: 0,
    display: 'block'
  },
  row: {
    position: 'relative',
    minHeight: '50px',
    border: '3px dotted #6B90F2',
    borderRadius: '6px'
  },
  elevation: {
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  }
});

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

class Template extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileOpen: false,
      show: true,
      currentTab: 0,
      selectedPage: 'page1',
      selectedRow: '',
      selectedRowObj: '',
      selectedCol: '',
      selectedRowPersist: '',
      selectedElement: '',
      currentLanguage: this.props.defaultLanguage,
      viewMode: 'desktop',
      unsavedData: null
    };
    this.pages = null;
    this.selectedPageId = null;
    this.ContentIndex = null;
  }

  async UNSAFE_componentWillMount() {
    const theme = await client.query({
      query: ADMIN_GET_CONTENT_THEME,
      variables: { id: this.props.shopId }
    });
    if (
      theme.data.shop.contentSettings &&
      theme.data.shop.contentSettings.theme
    ) {
      this.setState({ themeId: theme.data.shop.contentSettings.themeId });
    }
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  closeDrawer = () => {
    this.setState(state => ({ show: false }));
  };

  render() {
    const {
      classes,
      theme,
      client,
      pageContext,
      shopId,
      languages,
      shopUrl,
      defaultLanguage
    } = this.props;
    var selectedPageId = this.props.pageId;
    this.pages = [];
    let slugs = [];
    const drawer = (
      <Query query={GET_PAGE_CONTENT} variables={{ id: selectedPageId }}>
        {({ loading, error, data }) => {
          if (loading) return <CircularProgress />;
          if (error) return <span>{error.message}</span>;
          try {
            slugs = data.page.slugs;
          } catch (error) {}
          return (
            <div
              style={{
                width: '100%',
                height: 'calc(100vh - 70px)'
              }}
            >
              <TemplateTabs
                shopUrl={shopUrl}
                languages={languages}
                shopId={shopId}
                pageId={selectedPageId}
                pages={this.pages}
                changePage={this.changePage}
                Template={this}
              />
            </div>
          );
        }}
      </Query>
    );

    const selectPage = (
      <Query query={GET_PAGES} variables={{ id: shopId }}>
        {({ loading, error, data }) => {
          const { client } = this.props;
          var widthStyle;
          let iphoneStyle = {
            position: 'absolute',
            margin: '0 auto',
            left: '240px',
            right: 0,
            zIndex: 9999,
            width: '720px',
            height: 'auto',
            background: '#fff'
          };
          if (this.state.viewMode === 'mobile') {
            widthStyle = {
              width: '320px',
              margin: '0 auto'
            };
          }
          if (loading) return <CircularProgress />;
          if (error) return <span>{error.message}</span>;
          return (
            <div>
              {/* {this.state.viewMode==='mobile'?<img style={iphoneStyle} src={iphone} alt="Iphone" />:null} */}
              <Paper style={widthStyle} className={classes.container}>
                <ContentIndex
                  shopId={shopId}
                  client={client}
                  Template={this}
                  pageId={selectedPageId}
                  pageContext={pageContext}
                  themeId={this.state.themeId}
                />
              </Paper>
            </div>
          );
        }}
      </Query>
    );

    return (
      <div className={classes.root}>
        <Grid container>
          <ContentAppBar
            shopUrl={shopUrl}
            languages={languages}
            shopId={shopId}
            apolloClient={this.props.client}
            pageId={selectedPageId}
            slugs={slugs}
            Template={this}
          />

          <main className={classes.content}>{selectPage}</main>
          <nav className={classes.drawer}>{drawer}</nav>
        </Grid>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Template);
