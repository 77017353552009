import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { normalizeTextTitle } from './utils';
const styles = theme => ({});

class SelectCollections extends React.Component {
  state = {
    isChecked: false
  };

  render() {
    const {
      classes,
      collection,
      Template,
      onChange,
      collectionSET
    } = this.props;

    var title = '';

    var checked = false;

    for (var i = 0; i < collectionSET.length; i++) {
      if (collectionSET[i] === collection.id) {
        checked = true;
        break;
      }
    }
    let SelectedCollectionTitle;
    try {
      title = getTextFromLanguage(
        _get(collection, 'title.text', []),
        Template.state.currentLanguage
      );
      SelectedCollectionTitle = normalizeTextTitle(title);
    } catch (error) {}

    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              value={collection.id}
              onChange={event => onChange(event.target.value, !checked)}
              checked={checked}
            />
          }
          label={title}
          // data-cy={'Selected-Collection-' + title}
          data-cy={'Selected-Collection-' + SelectedCollectionTitle}
        />
      </div>
    );
  }
}

SelectCollections.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(SelectCollections);
