import React, { useState, useEffect } from 'react';
import {
  createStyles,
  withStyles,
  WithStyles,
  makeStyles,
  Theme,
  Button,
  Snackbar,
  IconButton
} from '@material-ui/core';

const styles = createStyles({});

interface Props extends WithStyles<typeof styles> {
  message: string;
  open: boolean;
  verticalAlign: string;
  horizontalAlign: string;
}

let prevOpen = null;
const SimpleSnackbar: React.FunctionComponent<Props> = props => {
  const { open, message, classes, verticalAlign, horizontalAlign } = props;
  const [openNotification, setOpenNotification] = useState(open);

  useEffect(() => {
    if (open === prevOpen) {
      return;
    }
    prevOpen = open;
    setOpenNotification(open);
  });

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotification(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: verticalAlign || 'bottom',
          horizontal: horizontalAlign || 'right'
        }}
        open={openNotification}
        autoHideDuration={1000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">{message}</span>}
      />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SimpleSnackbar);
