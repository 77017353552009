import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_GET_PRODUCT_ELEMENT } from '../../gql/admin-queries';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ADMIN_UPDATE_PRODUCT_ELEMENT } from '../../gql/admin-mutations';
import _get from 'lodash/get';
import Input from '../../../common/Input';
import Select from '../../../common/Select';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing,
    minWidth: 120,
    maxWidth: 300
  },
  noLabel: {
    marginTop:theme.spacing(3)
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

class ProductElement extends Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const classes = {};
    const {
      productElement,
      elementId,
      productId,
      elementRevision,
      code,
      fabrics,
      fabricGroups
    } = this.props;

    return (
      <Grid container>
        <Query
          query={ADMIN_GET_PRODUCT_ELEMENT}
          skip={true}
          variables={{
            productId: productId,
            createdAt_elementRevision_id:
              productElement.createdAt + '#' + elementRevision + '#' + elementId
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Loading...</div>;
            if (error) return <div>Error :(</div>;
            return (
              <Grid container>
                <Mutation
                  mutation={ADMIN_UPDATE_PRODUCT_ELEMENT}
                  update={(cache, { data: { updateProductElement } }) => {}}
                >
                  {(updateProductElement, { data, loading, error }) => {
                    return (
                      <Grid container direction="column">
                        {/* Full Code:{' '}
                        <Input
                          onChange={e => {
                            updateProductElement({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                updateProduct: {
                                  ...productElement,
                                  fullCode: e.target.value
                                }
                              },
                              variables: {
                                productId: productId,
                                elementRevision_code_id: elementRevision+'_'+code+'_'+elementId,
                                input: {
                                  fullCode: e.target.value
                                }
                              }
                            });
                          }}
                          placeholder="Full Code"
                          type="text"
                          value={productElement.fullCode}
                          id="fullCode"
                        />
                        <br /> */}
                        {/* Default name:{' '}
                        <Input
                          onChange={e => {
                            updateProductElement({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                updateProduct: {
                                  ...productElement,
                                  defaultName: e.target.value
                                }
                              },
                              variables: {
                                productId: productId,
                                elementRevision_code_id: elementRevision+'_'+code+'_'+elementId,
                                input: {
                                  defaultName: e.target.value
                                }
                              }
                            });
                          }}
                          placeholder="Default name"
                          type="text"
                          value={productElement.defaultName}
                          id="defaultName"
                        />
                        <br /> */}
                        {/* Code: {productElement.code} */}

                        <Grid container>
                          <Grid item xs={6}>
                            Fullcode: {productElement.fullCode}
                          </Grid>
                          <Grid item xs={6}>
                            Default name: {productElement.defaultName}
                          </Grid>
                          {/* ID: {productElement.id} */}
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          alignItems="flex-end"
                          style={{
                            marginTop: '4px',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            padding: '8px'
                          }}
                        >
                          <Grid item xs={4}>
                            <Input
                              onChange={e => {
                                updateProductElement({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateProduct: {
                                      ...productElement,
                                      name: e.target.value
                                    }
                                  },
                                  variables: {
                                    productId: productId,
                                    createdAt_elementRevision_id:
                                      productElement.createdAt +
                                      '#' +
                                      elementRevision +
                                      '#' +
                                      elementId,
                                    input: {
                                      name: e.target.value
                                    }
                                  }
                                });
                              }}
                              label="Name"
                              placeholder="Name"
                              type="text"
                              value={productElement.name}
                              id="name"
                            />
                          </Grid>
                          {/* Width:{' '}
                        <Input
                          onChange={e => {
                            updateProductElement({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                updateProduct: {
                                  ...productElement,
                                  width: parseFloat(e.target.value)
                                }
                              },
                              variables: {
                                productId: productId,
                                createdAt_elementRevision_id:
                                  productElement.createdAt +
                                  '#' +
                                  elementRevision +
                                  '#' +
                                  elementId,
                                input: {
                                  width: parseFloat(e.target.value)
                                }
                              }
                            });
                          }}
                          placeholder="Width"
                          type="text"
                          value={productElement.width}
                          id="width"
                        />
                        <br />
                        Height:{' '}
                        <Input
                          onChange={e => {
                            updateProductElement({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                updateProduct: {
                                  ...productElement,
                                  height: parseFloat(e.target.value)
                                }
                              },
                              variables: {
                                productId: productId,
                                createdAt_elementRevision_id:
                                  productElement.createdAt +
                                  '#' +
                                  elementRevision +
                                  '#' +
                                  elementId,
                                input: {
                                  height: parseFloat(e.target.value)
                                }
                              }
                            });
                          }}
                          placeholder="Height"
                          type="text"
                          value={productElement.height}
                          id="height"
                        />
                        <br /> */}
                          <Grid item xs={4}>
                            <Input
                              onChange={e => {
                                updateProductElement({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateProduct: {
                                      ...productElement,
                                      sqm: parseFloat(e.target.value)
                                    }
                                  },
                                  variables: {
                                    productId: productId,
                                    createdAt_elementRevision_id:
                                      productElement.createdAt +
                                      '#' +
                                      elementRevision +
                                      '#' +
                                      elementId,
                                    input: {
                                      sqm: parseFloat(e.target.value)
                                    }
                                  }
                                });
                              }}
                              label="SQM"
                              placeholder="SQM"
                              type="text"
                              value={productElement.sqm}
                              id="sqm"
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={this.handleClickOpen}
                            >
                              Assig Fabric group
                            </Button>
                          </Grid>
                        </Grid>
                        <Dialog
                          open={this.state.open}
                          onClose={this.handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {'Select fabric'}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Fabric group
                              <Select
                                className={''}
                                style={{
                                  background: 'none',
                                  color: '#000',
                                  padding: 0,
                                  minWidth: 0
                                }}
                                label="Default fabric"
                                value={productElement.fabricGroupId}
                                onChange={e => {
                                  updateProductElement({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateProduct: {
                                        ...productElement,
                                        fabricGroupId: e.target.value
                                      }
                                    },
                                    variables: {
                                      productId: productId,
                                      createdAt_elementRevision_id:
                                        productElement.createdAt +
                                        '#' +
                                        elementRevision +
                                        '#' +
                                        elementId,
                                      input: {
                                        fabricGroupId: e.target.value
                                      }
                                    }
                                  });
                                }}
                              >
                                <option
                                  value={''}
                                  key={'style-default-fabric-group-00'}
                                >
                                  {' '}
                                </option>
                                {fabricGroups.map((fabricGroupNode, index) => {
                                  const fabricGroup = fabricGroupNode.node;
                                  return (
                                    <option
                                      value={fabricGroup.id}
                                      key={
                                        'style-default-fabric-group-' + index
                                      }
                                    >
                                      {fabricGroup.name}
                                    </option>
                                  );
                                })}
                              </Select>
                              <h4>Fabrics</h4>
                              {fabrics.map((fabricNode, index) => {
                                const fabric = fabricNode.node;
                                return (
                                  <div
                                    value={fabric.id}
                                    key={'style-fabrics-' + index}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={_get(
                                        productElement,
                                        'fabricCompositionIds',
                                        []
                                      ).includes(fabric.id)}
                                      onChange={e => {
                                        const newFabricCompositionIds =
                                          e.target.checked === true
                                            ? [
                                                ...productElement.fabricCompositionIds,
                                                fabric.id
                                              ]
                                            : [
                                                ...productElement.fabricCompositionIds.filter(
                                                  e => e !== fabric.id
                                                )
                                              ];
                                        updateProductElement({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            updateProduct: {
                                              ...productElement,
                                              fabricCompositionIds: newFabricCompositionIds
                                            }
                                          },
                                          variables: {
                                            productId: productId,
                                            createdAt_elementRevision_id:
                                              productElement.createdAt +
                                              '#' +
                                              elementRevision +
                                              '#' +
                                              elementId,
                                            input: {
                                              fabricCompositionIds: newFabricCompositionIds
                                            }
                                          }
                                        });
                                      }}
                                    />
                                    {fabric.name} ({fabric.code}){' '}
                                    {fabric.id ===
                                    productElement.defaultFabricId ? (
                                      'Default'
                                    ) : _get(
                                        productElement,
                                        'fabricCompositionIds',
                                        []
                                      ).includes(fabric.id) ? (
                                      <button
                                        onClick={() => {
                                          updateProductElement({
                                            optimisticResponse: {
                                              __typename: 'Mutation',
                                              updateProduct: {
                                                ...productElement,
                                                defaultFabricId: fabric.id
                                              }
                                            },
                                            variables: {
                                              productId: productId,
                                              createdAt_elementRevision_id:
                                                productElement.createdAt +
                                                '#' +
                                                elementRevision +
                                                '#' +
                                                elementId,
                                              input: {
                                                defaultFabricId: fabric.id
                                              }
                                            }
                                          });
                                        }}
                                      >
                                        Make default
                                      </button>
                                    ) : null}
                                  </div>
                                );
                              })}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                              Cancel
                            </Button>
                            <Button
                              onClick={this.handleClose}
                              color="primary"
                              autoFocus
                            >
                              Save
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    );
                  }}
                </Mutation>
              </Grid>
            );
          }}
        </Query>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ProductElement);
