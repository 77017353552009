import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CardMedia from '@material-ui/core/CardMedia'
import { IMGIX_URL } from '../../../../../brikl-config'
import { Link } from 'gatsby'

const styles = {
  card: {
    padding: '0px',
    height: '100%'
  },
  media: {
    height: '215px'
  }
}

class SingleCard extends React.Component {
  render() {
    const {
      classes,
      data,
      title,
      description,
      callToAction,
      buttonFontSize,
      titleFontSize,
      descriptionFontSize,
      borderRadius,
      border,
      width,
      height = '380px',
      titleColor,
      titleBackgroundColor,
      descriptionColor,
      buttonTextColor,
      buttonAlign,
      titleAlign,
      descriptionAlign,
      buttonBorder,
      thumbnailImg,
      disabled,
      shopId,
      urlPrefix,
      langCode,
      page,
      collection,
      teamstore,
      product
    } = this.props

    let slug
    try {
      slug =
        page && page.id
          ? page.slugs.find(s => s.langCode === langCode).content
          : collection && collection.id
          ? collection.slugs.find(s => s.langCode === langCode).content
          : teamstore && teamstore.id
          ? teamstore.slugs.find(s => s.langCode === langCode).content
          : product && product.id
          ? product.slugs.find(s => s.langCode === langCode).content
          : null
    } catch (error) {}

    return (
      <Grid
        style={{ height: '100%', padding: 16 }}
        item
        xs={12}
        sm={12}
        md={12}
      >
        <Card
          elevation={0}
          style={{
            boxShadow: data.shadow !== null ? data.shadow : 'none',
            border: border !== null ? border : '0px',
            borderRadius: borderRadius !== null ? borderRadius : '4px',
            backgroundColor:
              data.backgroundColor !== null ? data.backgroundColor : 'white'
          }}
          className={classes.card}
        >
          {thumbnailImg !== null && thumbnailImg.indexOf('undefined') === -1 ? (
            <CardMedia
              data-cy="Card-Image-Area"
              className={classes.media}
              image={
                thumbnailImg.indexOf('https://') !== -1
                  ? thumbnailImg
                  : `${IMGIX_URL}/${shopId}/media/${thumbnailImg}`
              }
              title={title}
            />
          ) : null}
          <CardContent
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: `${height}`
            }}
            data-cy="Card-CardContent"
          >
            <Grid
              container
              alignItems="center"
              // justify="flex-start"
              style={{ width: `${width}` }}
            >
              <Grid item xs={12}>
                {title !== null ? (
                  <Grid
                    container
                    justify={titleAlign ? titleAlign : 'flex-start'}
                  >
                    <Typography
                      style={{
                        fontSize: `${titleFontSize}`,
                        color: `${titleColor}`
                      }}
                      data-cy="Card-title"
                      gutterBottom
                    >
                      {title}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {description !== null ? (
                  <Grid
                    container
                    justify={descriptionAlign ? descriptionAlign : 'flex-start'}
                    style={{ marginTop: 8 }}
                  >
                    <Typography
                      style={{
                        fontSize: `${descriptionFontSize}`,
                        color: `${descriptionColor}`
                      }}
                      data-cy="Card-description"
                    >
                      {description}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {callToAction !== '' ? (
                  <Grid
                    container
                    justify={buttonAlign ? buttonAlign : 'flex-start'}
                  >
                    <Button
                      disabled={disabled}
                      component={Link}
                      to={`${urlPrefix}/${slug}`}
                      style={{
                        marginTop: 8,
                        color: `${buttonTextColor}`,
                        backgroundColor: 'transparent',
                        border: `${buttonBorder}`,
                        borderRadius: '5px',
                        fontSize: `${buttonFontSize}`,
                        textDecoration: 'none'
                      }}
                      data-cy="Card-Button-Text"
                    >
                      {callToAction}
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }
}
SingleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default withStyles(styles)(SingleCard)
