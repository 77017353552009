import React from 'react'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import ListItem from '@material-ui/core/ListItem'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography } from '@material-ui/core'
import TemplateTabs from './TemplateTabs'
import ContentIndex from './ContentIndex'
import { Query, Mutation } from 'react-apollo'
import { GET_PAGES, ADMIN_GET_CONTENT_MENU } from '../../gql/admin-queries'
import { ADMIN_UPDATE_SHOP_CONTENT_SETTINGS } from '../../gql/admin-mutations'
import CircularProgress from '@material-ui/core/CircularProgress'
import { navigate } from '@reach/router'
import _get from 'lodash/get'
import ContentAppBar from './ContentAppBar'
import iphone from './iphone-frame.png'
import PagesList from './PagesList'
const drawerWidth = 240

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%',
    display: 'block'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    display: 'block',
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: 'white',
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'relative',
    height: '100vh',
    background: '#ffffff'
  },
  content: {
    flexGrow: 1,
    background: '#f2f2f2',
    height: 'calc(100vh - 70px)',
    overflowY: 'auto',
    padding: '24px',
    marginTop: '70px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '70px'
    }
  },
  container: {
    flex: 1,
    minHeight: 'calc(100vh - 48px)',
    borderRadius: 0
    // backgroundColor: '#f2f2f2'
  },
  row: {
    position: 'relative',
    minHeight: '50px',
    border: '3px dotted #6B90F2',
    borderRadius: '6px'
  },
  elevation: {
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242',
    flex: 1
  }
})

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />
}

class PagesListContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileOpen: false,
      show: true,
      currentTab: 0,
      selectedPage: 'page1',
      selectedRow: '',
      selectedRowObj: '',
      selectedCol: '',
      selectedRowPersist: '',
      selectedElement: '',
      currentLanguage: 'en_UK',
      viewMode: 'desktop',
      unsavedData: null
    }
    this.pages = null
    this.selectedPageId = null
    this.ContentIndex = null
  }

  changePage(id) {
    navigate(
      '/admin/' + this.props.shopId + '/pagebuilder/pages/' + id + '/content'
    )
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  }

  closeDrawer = () => {
    this.setState(state => ({ show: false }))
  }

  render() {
    const { classes, client, shopId, shopUrl } = this.props
    var selectedPageId = this.props.pageId
    const drawer = (
      <Query query={GET_PAGES} variables={{ id: shopId }}>
        {({ loading, error, data }) => {
          if (loading) return <CircularProgress />
          if (error) return <span>{error.message}</span>

          if (this.pages === null) {
            this.pages = data.shop.pages
          }
          if (!this.selectedPageId && data.shop.pages) {
            this.selectedPageId = _get(
              data,
              'shop.pages.edges[0].node.id',
              null
            )
          }
          //  return <PagesList pages={this.pages} Template={this} />;

          return (
            <Query query={ADMIN_GET_CONTENT_MENU} variables={{ id: shopId }}>
              {({ loading, error, data }) => {
                if (loading) return <CircularProgress />
                if (error) return <span>{error.message}</span>

                return (
                  <PagesList
                    shopUrl={shopUrl}
                    shopId={shopId}
                    contentSettings={
                      data && data.shop ? data.shop.contentSettings : null
                    }
                    pages={this.pages}
                    Template={this}
                    client={client}
                  />
                )
              }}
            </Query>
          )
        }}
      </Query>
    )

    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Typography
            component="h1"
            className={classes.h1}
            data-cy="Header-Pages"
          >
            Pages
          </Typography>
        </Grid>
        <Grid
          container
          style={{ position: 'relative', width: '100%', padding: 0 }}
        >
          {drawer}
        </Grid>
      </div>
    )
  }
}

PagesListContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(PagesListContainer)
