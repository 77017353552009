import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Divider, Typography } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import NativeSelect from '@material-ui/core/NativeSelect'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import Slider from '@material-ui/core/Slider'
import ColorPicker from './ColorPicker'
import { FONTS, TYPO_VARIANTS, TEXT_TRANSFORM } from './config'

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase)

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    border: '1px solid #0000001f'
  },
  input: {
    paddingLeft: 8,
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  },
  slider: {
    padding: '22px 0px'
  },
  color: {
    width: '50px',
    height: '23px',
    borderRadius: '4px'
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer'
  },
  popover: {
    position: 'absolute',
    zIndex: '10',
    left: '3px'
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  }
})

class UpdateTypography extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      hex: '#ffffff'
    },
    themeData: null
  }

  handleTriggerChangeFontSize = value => {
    const { themeData } = this.props

    if (!themeData[value]) {
      return
    }

    const typoVariant = themeData[value].style

    this.props.handleChangeField(parseInt(typoVariant.fontSize), 'fontSize')
    this.props.handleChangeField(typoVariant.fontFamily, 'fontFamily')
    this.props.handleChangeField(parseInt(typoVariant.fontWeight), 'fontWeight')
    this.props.handleChangeField(typoVariant.textTransform, 'textTransform')
    this.props.handleChangeField(
      parseInt(typoVariant.letterSpacing),
      'letterSpacing'
    )
  }

  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      fields,
      handleChangeField
    } = this.props

    let newFontFamily =
      fields.fontFamily && fields.fontFamily.indexOf(',')
        ? fields.fontFamily.split(',')[0]
        : fields.fontFamily
    let cleanFont

    if (newFontFamily) {
      cleanFont =
        newFontFamily.indexOf("'") !== -1 ? newFontFamily.split("'")[1] : null
    }

    return (
      <ExpansionPanel
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
        expanded={expanded === 'panel2'}
        onChange={handleExpandedChange('panel2')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ expanded: classes.expansionPanelExpanded }}
        >
          <Typography
            variant="subtitle1"
            className={classes.typo}
            data-cy="Typography-Settings"
          >
            Typography
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{ root: classes.expansionPanelDetails }}
        >
          <Divider />
          <List>
            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="Text-Variant-Label"
                  shrink
                  className={classes.FormLabel}
                >
                  Variant
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <NativeSelect
                    data-cy="Text-Variant-Select"
                    native="true"
                    value={fields.typoVariant}
                    className={classes.input}
                    onChange={e => {
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'typoVariant'
                      )
                      this.handleTriggerChangeFontSize(e.target.value)
                    }}
                    input={
                      <SelectInput
                        name="variant"
                        id="age-customized-native-simple"
                      />
                    }
                  >
                    {TYPO_VARIANTS.map(item => (
                      <option key={item.tag} value={item.tag}>
                        {item.label}
                      </option>
                    ))}
                  </NativeSelect>
                </Paper>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="Text-Font-Family-Label"
                  shrink
                  className={classes.FormLabel}
                >
                  Font Family
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <NativeSelect
                    data-cy="Text-Font-Family-Select"
                    native="true"
                    className={classes.input}
                    value={newFontFamily ? cleanFont : newFontFamily}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'fontFamily'
                      )
                    }
                    input={
                      <SelectInput
                        name="font"
                        id="age-customized-native-simple"
                      />
                    }
                  >
                    {FONTS.map(font => (
                      <option key={font} value={font}>
                        {font}
                      </option>
                    ))}
                  </NativeSelect>
                </Paper>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="Text-Font-Size-Label"
                  style={{ marginBottom: 10 }}
                  shrink
                  className={classes.FormLabel}
                >
                  Font size - {fields.fontSize}
                </InputLabel>
                <Grid container={true} justify="space-between">
                  <Slider
                    data-cy="Text-Font-Size-Slider"
                    className={styles.slider}
                    value={parseInt(fields.fontSize)}
                    aria-labelledby="slider-label"
                    step={1}
                    min={0}
                    max={100}
                    onChange={(e, value) =>
                      handleChangeField(value, 'fontSize')
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="Text-Color-Label"
                  shrink
                  className={classes.FormLabel}
                >
                  Color
                </InputLabel>
                <ColorPicker
                  dataCy="Text-Color-ColorPicker"
                  color={fields.color}
                  fieldName={'color'}
                  onChange={handleChangeField}
                />
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="Text-Font-Weight-Label"
                  style={{ marginBottom: 10 }}
                  shrink
                  className={classes.FormLabel}
                >
                  Font weight - {fields.fontWeight}
                </InputLabel>
                <Grid container={true} justify="space-between">
                  <Slider
                    data-cy="Text-Font-Weight-Slider"
                    className={styles.slider}
                    value={parseInt(fields.fontWeight)}
                    aria-labelledby="slider-label"
                    step={100}
                    min={0}
                    max={900}
                    onChange={(e, value) =>
                      handleChangeField(value, 'fontWeight')
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="Text-Letter-Spacing-Label"
                  style={{ marginBottom: 10 }}
                  shrink
                  className={classes.FormLabel}
                >
                  Letter spacing - {fields.letterSpacing}
                </InputLabel>
                <Grid container={true} justify="space-between">
                  <Slider
                    data-cy="Text-Letter-Spacing-Slider"
                    className={styles.slider}
                    value={parseInt(fields.letterSpacing)}
                    aria-labelledby="slider-label"
                    step={1}
                    min={0}
                    max={10}
                    onChange={(e, value) =>
                      handleChangeField(value, 'letterSpacing')
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="Text-Text-Transform-Label"
                  shrink
                  className={classes.FormLabel}
                >
                  Text transform
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <NativeSelect
                    data-cy="Text-Text-Transform-Select"
                    native="true"
                    value={fields.textTransform}
                    className={classes.input}
                    onChange={e => {
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'textTransform'
                      )
                    }}
                    input={
                      <SelectInput
                        name="variant"
                        id="textTransform-customized-native-simple"
                      />
                    }
                  >
                    {TEXT_TRANSFORM.map(textTransform => (
                      <option key={textTransform} value={textTransform}>
                        {textTransform}
                      </option>
                    ))}
                  </NativeSelect>
                </Paper>
              </Grid>
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

UpdateTypography.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
}

export default withStyles(styles)(UpdateTypography)
