import React, { useState, useContext } from 'react'
import {
  Grid,
  Dialog,
  Paper,
  Typography,
  TextField,
  InputLabel,
  Button,
  NativeSelect,
  AppBar
} from '@material-ui/core'
import uuid from 'uuid'
import { SelectedLanguageContext, LanguagesContext } from '../LanguageContext'
import { reservedSlugs } from '../Elements/elements'
import { useMutation } from '@apollo/react-hooks'
import {
  ADMIN_UPDATE_CONTENT_MODEL_STATUS,
  ADMIN_UPDATE_CONTENT_MODEL
} from '../gql/mutations'
import { SerpPreview } from './SerpPreview'

interface Props {
  dialog: boolean
  data?: any
  title: string
  isUpdate: boolean
  selectedSalesChannel: string | null
  salesChannels: any
  modelId?: string
  modelValues?: any
  setDialog(value: boolean): void
  createPage?: any
  updatePage?: any
  shopUrl: string
}

const PageDialog: React.FunctionComponent<Props> = props => {
  const {
    dialog,
    setDialog,
    createPage,
    title,
    isUpdate,
    modelId,
    modelValues,
    updatePage,
    salesChannels,
    selectedSalesChannel,
    shopUrl
  } = props

  const selectedLanguage = useContext(SelectedLanguageContext)
  const languages = useContext(LanguagesContext)

  const [values, setValues] = useState({
    name: isUpdate ? modelValues?.name?.defaultValue : '',
    description: isUpdate ? modelValues?.description?.defaultValue : '',
    slug: isUpdate ? modelValues?.slugs : '',
    salesChannel: isUpdate ? modelValues?.salesChannelId : selectedSalesChannel,
    pageTitle: isUpdate ? modelValues?.pageTitle?.defaultValue : '',
    headerCustomCode: isUpdate ? modelValues?.headerCustomCode : '',
    footerCustomCode: isUpdate ? modelValues?.footerCustomCode : ''
  })

  const [updateContentSlug] = useMutation(ADMIN_UPDATE_CONTENT_MODEL_STATUS)

  const handleChange = (name: string) => (event: any) => {
    setValues({ ...values, [name]: event.target.value })
  }
  const [tabIndex, setTabIndex] = useState(0)

  const isReservedSlug = reservedSlugs.some(slug =>
    values?.slug?.includes(slug)
  )

  {
    /*
      TODO: 
      - change slug back to optional
      - populate slug if Title is populated
      - if slug is populated first don't let Title overwrite it
    */
  }

  return (
    <Dialog
      onClose={() => setDialog(false)}
      aria-labelledby="simple-dialog-title"
      open={dialog}
      maxWidth="xl"
      style={{ borderRadius: 20 }}
    >
      <AppBar position="static">
        <div>
          <Button
            style={{
              color: 'white',
              fontWeight: tabIndex === 0 ? 'bold' : null
            }}
            onClick={() => setTabIndex(0)}
          >
            General
          </Button>
        </div>
      </AppBar>
      <Paper style={{ width: '1000px', height: '580px', padding: 45 }}>
        <Grid direction="row" container={true}>
          <Grid
            style={{
              display: tabIndex === 0 ? 'inherit' : 'none'
            }}
            spacing={4}
            container={true}
          >
            <Grid xs={6} item={true}>
              <Typography
                style={{
                  fontSize: 20,
                  padding: '0px 0px 60px 0px',
                  letterSpacing: 1,
                  fontWeight: 500,
                  fontFamily: 'Poppins, sans-serif'
                }}
                variant="h1"
              >
                {title}
              </Typography>

              <InputLabel>Name</InputLabel>
              <TextField
                placeholder="Name of your page"
                style={{ paddingBottom: 20 }}
                value={values.name}
                onChange={handleChange('name')}
              />
              <InputLabel>Direct access URL</InputLabel>
              <Grid direction="row" container={true}>
                <Grid item={true} xs={7}>
                  <span style={{ position: 'relative', top: '12px' }}>
                    {shopUrl}
                  </span>
                </Grid>
                <Grid item={true} xs={5}>
                  <TextField
                    error={isReservedSlug}
                    helperText={
                      isReservedSlug
                        ? 'Sorry, this slug is reserved and cannot be used!'
                        : null
                    }
                    value={values.slug}
                    placeholder="slug"
                    style={{ paddingBottom: 20 }}
                    onChange={handleChange('slug')}
                  />
                </Grid>
              </Grid>
              <InputLabel
                style={{ paddingTop: 15 }}
                htmlFor="salesChannel-select-label"
              >
                Assign to Sales Channel
              </InputLabel>

              <NativeSelect
                onChange={handleChange('salesChannel')}
                value={values.salesChannel}
                id="salesChannel-select"
                style={{
                  border: '1px solid lightgrey',
                  borderRadius: 2,
                  marginRight: 60
                }}
              >
                <option>MYBRIKL</option>
                {salesChannels?.edges
                  ? salesChannels.edges.map((teamstore: any) => (
                      <option value={teamstore.node.id} key={teamstore.node.id}>
                        {teamstore.node.title.defaultValue}
                      </option>
                    ))
                  : null}
              </NativeSelect>
            </Grid>

            <Grid xs={6} item={true}></Grid>
          </Grid>
        </Grid>
        <Grid justify="space-between" container={true}>
          <Grid item={true}>
            <Button
              onClick={() => setDialog(false)}
              variant="text"
              color="secondary"
            >
              Close
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              onClick={async () => {
                if (!isUpdate) {
                  const salesChannelId =
                    values?.salesChannel || selectedSalesChannel || 'MYBRIKL'
                  await createPage({
                    variables: {
                      salesChannelId,
                      input: {
                        salesChannelId,
                        name: {
                          id: uuid.v4(),
                          text: languages.map((item: any) => {
                            return {
                              langCode: item,
                              content: values.name
                            }
                          })
                        },
                        description: {
                          id: uuid.v4(),
                          text: languages.map((item: any) => {
                            return {
                              langCode: item,
                              content: values.description
                            }
                          })
                        },
                        status: 'DRAFT',
                        slugs: languages.map((item: any) => {
                          return {
                            langCode: item,
                            content: values.slug
                              ? values.slug.toLocaleLowerCase()
                              : values.name.toLocaleLowerCase()
                          }
                        })
                      }
                    }
                  })
                } else {
                  await updateContentSlug({
                    variables: {
                      id: modelId,
                      input: {
                        slugs: languages.map((item: any) => {
                          return {
                            langCode: item,
                            content: values.slug
                              ? values.slug.toLocaleLowerCase()
                              : values.name.toLocaleLowerCase()
                          }
                        })
                      }
                    }
                  })
                  await updatePage({
                    variables: {
                      modelId: modelId,
                      revisionNo: 1,
                      input: {
                        // salesChannelId:
                        //   values?.salesChannel || selectedSalesChannel,
                        name: {
                          id: uuid.v4(),
                          text: [
                            {
                              langCode: selectedLanguage,
                              content: values.name
                            }
                          ]
                        },
                        description: {
                          id: uuid.v4(),
                          text: [
                            {
                              langCode: selectedLanguage,
                              content: values.description
                            }
                          ]
                        }
                      }
                    }
                  })
                }

                setDialog(false)
                setValues({
                  name: '',
                  description: '',
                  slug: ''
                })
              }}
              variant="outlined"
              color="primary"
              disabled={
                isReservedSlug || !values.slug || !values.name ? true : false
              }
            >
              {isUpdate ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  )
}

export default PageDialog
