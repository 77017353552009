import React, { useState } from 'react'
import {
  Grid,
  Dialog,
  Paper,
  Typography,
  List,
  ListItem,
  Button
} from '@material-ui/core'
import { categories, elements } from './elements'
import RenderElement from '../Editor/RenderElement'
import { getBreakpoints } from '../helpers/getBreakpoints'
import _sortBy from 'lodash/sortBy'
import { useMutation } from '@apollo/react-hooks'
import { getTextFromLanguage } from '../../../../../../helper/languageHelper'
import uuid from 'uuid'
import {
  ADMIN_CREATE_CONTENT_VIEW,
  ADMIN_DELETE_CONTENT_FIELD
} from '../gql/mutations'
import { GET_CONTENT_MODEL } from '../gql/queries'
import { generateSubIds } from '../../../../../admin/components/content/utils'

interface Props {
  newFieldId: string | null
  modelId: string | null
  handleSetNewFieldId(id: string | null): any
}

const ElementsDialog: React.FC<Props> = props => {
  const { newFieldId, modelId, handleSetNewFieldId } = props
  const [category, setCategory] = useState<string | null>('text')

  const [createContentViews] = useMutation(ADMIN_CREATE_CONTENT_VIEW, {
    refetchQueries: [{ query: GET_CONTENT_MODEL, variables: { id: modelId } }],
    onCompleted: () => handleSetNewFieldId(null)
  })

  const [deleteContentField] = useMutation(ADMIN_DELETE_CONTENT_FIELD, {
    refetchQueries: [{ query: GET_CONTENT_MODEL, variables: { id: modelId } }]
  })

  return (
    <Dialog maxWidth="xl" open={!!newFieldId} style={{ borderRadius: 20 }}>
      <Paper style={{ maxWidth: '1200px', maxHeight: '650px' }}>
        <Grid
          style={{ maxWidth: '1200px', maxHeight: '650px' }}
          container={true}
        >
          <Grid
            style={{
              height: '650px',
              padding: 15,
              boxShadow: '-4px 1px 20px 0px #8e8686'
            }}
            item={true}
            xs={3}
          >
            <Typography
              style={{
                fontSize: '1.55rem',
                letterSpacing: '1px',
                fontWeight: 'bold',
                textAlign: 'center',
                padding: '60px 10px'
              }}
              variant="h1"
            >
              Categories
            </Typography>
            <List component="nav" aria-label="element-category-list">
              {categories.map((cat, i: number) => (
                <ListItem
                  autoFocus={true}
                  onClick={() => setCategory(cat.value)}
                  style={{
                    maxWidth: 250,
                    marginBottom: 10,
                    marginTop: 10
                  }}
                  selected={category === cat.value}
                  button
                  key={i}
                >
                  <span
                    style={{
                      fontSize: '23px',
                      padding: '0px 0px 0px 20px'
                    }}
                  >
                    {cat.label}
                  </span>
                </ListItem>
              ))}
            </List>
            <Button
              style={{
                position: 'absolute',
                bottom: 25,
                left: 95,
                padding: '8px 35px'
              }}
              variant="contained"
              color="primary"
              onClick={async () => {
                await deleteContentField({
                  variables: {
                    revisionNo: 1,
                    id: newFieldId,
                    modelId: modelId
                  }
                })
                handleSetNewFieldId(null)
              }}
            >
              Close
            </Button>
          </Grid>
          <Grid
            style={{
              padding: 50,
              height: '650px',
              width: '1200px',
              overflowY: 'auto'
            }}
            item={true}
            xs={9}
          >
            <List>
              {category
                ? elements
                    .filter(elem => elem.categories.includes(category))
                    .map((e: any, i: number) => {
                      const columnsArray = []
                      for (let i = 0; i < e.columns; i++) {
                        columnsArray.push(i + 1)
                      }

                      const breakpoints = getBreakpoints(e.columns)

                      return (
                        <>
                          {e.data.contentHeader ? (
                            <Grid
                              container={true}
                              justify={e.data.contentHeader.alignment}
                            >
                              <Typography variant="h1">
                                {getTextFromLanguage(
                                  e.data.contentHeader.text.text,
                                  'en_UK'
                                )}
                              </Typography>
                            </Grid>
                          ) : null}

                          <Grid
                            justify="center"
                            alignItems="center"
                            container={true}
                            key={i}
                          >
                            <ListItem
                              onClick={() => {
                                createContentViews({
                                  variables: {
                                    ...(e.data.contentHeader && {
                                      contentHeader: {
                                        ...e.data.contentHeader,
                                        text: {
                                          ...e.data.contentHeader.text,
                                          id: uuid.v4()
                                        }
                                      }
                                    }),
                                    revisionNo: 1,
                                    fieldId: newFieldId,
                                    modelId: modelId,
                                    input: e.data.contentView.map(
                                      (view: any) => {
                                        return generateSubIds(view, [
                                          'contentText',
                                          'buttonText',
                                          'imageTitle',
                                          'imageButton',
                                          'contentText',
                                          'collectionTitle',
                                          'productTitle',
                                          'formTitle',
                                          'teamstoreTitle'
                                        ])
                                      }
                                    )
                                  }
                                })
                              }}
                              button
                            >
                              {columnsArray.map((c: number) => {
                                return (
                                  <Grid
                                    key={c}
                                    item={true}
                                    xs={breakpoints.xs}
                                    sm={breakpoints.sm}
                                    md={breakpoints.md}
                                    lg={breakpoints.lg}
                                    xl={breakpoints.xl}
                                  >
                                    {e.data.contentView
                                      .filter((o: any) => o.column === c)
                                      .map((elem: any, i: any) => {
                                        return (
                                          <RenderElement
                                            key={i}
                                            editing={false}
                                            element={elem}
                                            modelId={modelId}
                                            hoverElement={null}
                                            elementSelect={true}
                                            dialog={true}
                                          />
                                        )
                                      })}
                                  </Grid>
                                )
                              })}
                            </ListItem>
                          </Grid>
                        </>
                      )
                    })
                : null}
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  )
}

export default ElementsDialog
