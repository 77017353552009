import gql from 'graphql-tag';

export const ADMIN_REPORT_REQUEST_SUBSCRIPTION = gql`
  subscription reportRequest($shopId: String!, $id: uuid!) {
    events(where: { shopId: { _eq: $shopId }, id: { _eq: $id } }) {
      id
      shopId
      createdBy
      event
      eventType
      createdAt
      status
    }
  }
`;
