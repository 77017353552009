import React from 'react'
import { Typography } from '@material-ui/core'

interface Props {
  text: string | null
}

const Title: React.FC<Props> = props => {
  const { text } = props
  return (
    <Typography style={{ padding: '40px 0px' }} variant="h1">
      {text || 'Title'}
    </Typography>
  )
}

export default Title
