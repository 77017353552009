import { MTLLoader, OBJLoader } from 'three-obj-mtl-loader'
import preloadUV from './preloadUV.png'

let objLoader = new OBJLoader()
let mtlLoader = new MTLLoader()
objLoader.crossOrigin = 'anonymous'
mtlLoader.crossOrigin = 'anonymous'

export function setUpModel(scene, OBJ, MTL, setMaterialUVGroup, setMainObject) {
  mtlLoader.load(MTL, materials => {
    console.log('materials x', materials)

    materials = loadMTLProcess(materials, setMaterialUVGroup)
    materials.preload()
    objLoader.setMaterials(materials)

    objLoader.load(
      OBJ,
      object => {
        console.log('object', object, object.children)
        loadOBJProcess(object, scene, setMainObject)
      },
      xhr => {
        // called while loading is progressing
        console.log(`${(xhr.loaded / xhr.total) * 100}% loaded XX`)
      }
    )
  })
}

function loadMTLProcess(materials, setMaterialUVGroup) {
  const patternNameCheck = 'PrintPattern'
  const mapType = ['map_ka', 'map_kd']
  var materialsNameSET = []
  for (var key in materials.materialsInfo) {
    if (materials.materialsInfo.hasOwnProperty(key)) {
      var materialsInfo = materials.materialsInfo[key]
      for (let i = 0; i < mapType.length; i++) {
        if (
          materialsInfo[mapType[i]] &&
          materialsInfo[mapType[i]].indexOf(patternNameCheck) !== -1
        ) {
          materialsInfo[mapType[i]] = preloadUV
          materialsNameSET.push(materialsInfo.name)
        }
      }
    }
  }

  materialsNameSET = materialsNameSET.filter(function(item, pos, self) {
    return self.indexOf(item) == pos
  })

  setMaterialUVGroup(materialsNameSET)

  return materials
}

function loadOBJProcess(object, scene, setMainObject) {
  var boxScale = new THREE.Box3().setFromObject(object)

  var xLength = boxScale.max.x - boxScale.min.x
  var yLength = boxScale.max.y - boxScale.min.y

  console.log('boxScale', boxScale, xLength, yLength)

  if (xLength > yLength) {
    var modelScale = 0.35 / xLength
  } else {
    var modelScale = 0.35 / yLength
  }

  console.log(modelScale)

  for (let c = 0; c < object.children.length; c++) {
    const child = object.children[c]
    child.scale.set(modelScale, modelScale, modelScale)
  }

  var box = new THREE.Box3().setFromObject(object)
  box.getCenter(object.position) // Main3D re-sets the object position
  object.position.multiplyScalar(-1)

  scene.add(object)
  setMainObject(object)
}

export function remapUV(BASE64, materialUVGroup, mainObject) {
  if (BASE64 && materialUVGroup.length && mainObject !== null) {
    for (let c = 0; c < mainObject.children.length; c++) {
      const child = mainObject.children[c]

      if (child.material.constructor === Array) {
        for (var i = 0; i < child.material.length; i++) {
          mapBase64ToMaterial(BASE64, child.material[i], materialUVGroup)
        }
      } else {
        mapBase64ToMaterial(BASE64, child.material, materialUVGroup)
      }
    }
  }
}

function mapBase64ToMaterial(BASE64, material, materialUVGroup) {
  if (materialUVGroup.indexOf(material.name) !== -1) {
    if (BASE64 === 'BLANK') {
      material.transparent = false
    } else {
      material.map.image.src = BASE64
      material.map.needsUpdate = true
    }
  }
}
