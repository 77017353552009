import React, { useState } from 'react'
import { Slider, Grid, Input } from '@material-ui/core'
import { addPercent, addPx } from '../../../../content/utils'
interface Props {
  defaultValue: any
  range: number[]
  styleId: string
  contentId: string
  level: 'root' | 'style'
  fieldName: string
  suffix: 'px' | '%'
  handleUpdate: any
}

const SliderControl: React.FC<Props> = props => {
  const {
    defaultValue,
    range,
    styleId,
    contentId,
    level,
    fieldName,
    suffix,
    handleUpdate
  } = props
  const [value, setValue] = useState(defaultValue ? parseInt(defaultValue) : 1)

  const handleChange = (e: any, newValue: any) => {
    setValue(newValue)
  }

  const handleInputChange = (event: any) => {
    setValue(event.target.value === '' ? '' : event.target.value)
  }

  const handleBlur = () => {
    if (value < 0) {
      setValue(0)
    } else if (value > 100) {
      setValue(100)
    }
  }

  return (
    <Grid container={true} spacing={2} alignItems="center">
      <Grid item={true} xs>
        <Slider
          defaultValue={value}
          step={1}
          min={range ? range[0] : 1}
          max={range ? range[1] : 100}
          valueLabelDisplay="auto"
          onChange={handleChange}
          onChangeCommitted={() =>
            handleUpdate(
              contentId,
              fieldName,
              suffix === '%' ? addPercent(value) : addPx(value),
              level,
              styleId
            )
          }
        />
      </Grid>
      <Grid item={true}>
        <Input
          value={value}
          margin="dense"
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: range ? range[0] : 0,
            max: range ? range[1] : 100,
            type: 'number',
            'aria-labelledby': 'input-slider'
          }}
        />
      </Grid>
    </Grid>
  )
}

export default SliderControl
