import React, { Component } from 'react'
import {
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Select from '../../../common/Select'
import { Link } from 'gatsby'
import { Query, Mutation } from 'react-apollo'
import {
  ADMIN_SHOP_SALESCHANNELS,
  ADMIN_GET_PRODUCT
} from '../../gql/admin-queries'
import {
  ADMIN_CREATE_PRODUCT_SALESCHANNEL,
  ADMIN_DELETE_PRODUCT_SALESCHANNEL
} from '../../gql/admin-mutations'
import { getTextFromLanguage } from '../../../../helper/languageHelper'

class ProductSalesChannels extends Component {
  state = {
    selectedSalesChannelId: null,
    loading: false
  }
  render() {
    const { product, refetch, shopId, defaultLanguage } = this.props
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography
            data-cy="Product-Sales-Channels-header"
            variant="subtitle1"
          >
            Sales Channels &amp; Teamstores
          </Typography>
        </Grid>
        <Query query={ADMIN_SHOP_SALESCHANNELS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            var salesChannels = []
            try {
              salesChannels = shop.salesChannels.edges
              salesChannels = [
                {
                  node: {
                    id: 'MYBRIKL',
                    name: 'Main store (Mybrikl)',
                    title: {
                      id: 'MYBRIKL-SALESCHANNEL',
                      text: [],
                      __typename: 'Text'
                    },
                    __typename: 'SalesChannel'
                  }
                },
                ...salesChannels.sort((a, b) => {
                  if (a.node.name < b.node.name) {
                    return -1
                  }
                  if (a.node.name > b.node.name) {
                    return -1
                  }
                  if (
                    getTextFromLanguage(a.node.title.text, defaultLanguage) <
                    getTextFromLanguage(b.node.title.text, defaultLanguage)
                  ) {
                    return -1
                  }
                  if (
                    getTextFromLanguage(a.node.title.text, defaultLanguage) >
                    getTextFromLanguage(b.node.title.text, defaultLanguage)
                  ) {
                    return 1
                  }
                  return 0
                })
              ]
            } catch (error) {
              console.error('e', error)
            }

            return (
              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                <Paper elevation={0} style={{ padding: 24 }}>
                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item>
                      <Select
                        dataCy="Product-Sales-Channel-Select"
                        onChange={e => {
                          this.setState({
                            selectedSalesChannelId: e.target.value
                          })
                        }}
                        value={this.state.selectedSalesChannelId}
                        label="Sales Channels"
                      >
                        <option value={''}>Choose</option>
                        {salesChannels
                          ? salesChannels.map(pa => {
                              if (pa && pa.node && pa.node.title) {
                                return (
                                  <option value={pa.node.id}>
                                    {getTextFromLanguage(
                                      pa.node.title.text,
                                      defaultLanguage
                                    )
                                      ? getTextFromLanguage(
                                          pa.node.title.text,
                                          defaultLanguage
                                        )
                                      : pa.node.name}
                                  </option>
                                )
                              } else {
                                return null
                              }
                            })
                          : null}
                      </Select>
                    </Grid>
                    <Grid item>
                      <Mutation
                        mutation={ADMIN_CREATE_PRODUCT_SALESCHANNEL}
                        update={(
                          cache,
                          { data: { createProductSalesChannel } }
                        ) => {
                          if (createProductSalesChannel) {
                            const data = cache.readQuery({
                              query: ADMIN_GET_PRODUCT,
                              variables: { id: product.id }
                            })
                            const newSalesChannel = salesChannels.find(c => {
                              return (
                                c.node.id === this.state.selectedSalesChannelId
                              )
                            })
                            // console.log('newSalesChannel', newSalesChannel);
                            data.product.salesChannels.unshift({
                              productId: product.id,
                              salesChannelId: this.state.selectedSalesChannelId,
                              salesChannel: {
                                ...newSalesChannel.node
                              },
                              __typename: 'ProductSalesChannel'
                            })
                            cache.writeQuery({
                              query: ADMIN_GET_PRODUCT,
                              variables: { id: product.id },
                              data
                            })
                          }
                        }}
                      >
                        {(
                          createProductSalesChannel,
                          { data, loading, error }
                        ) => {
                          return (
                            <Button
                              data-cy="Product-Sales-Channel-Assign-btn"
                              onClick={async () => {
                                await createProductSalesChannel({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    createProductSalesChannel: {
                                      salesChannelId: this.state
                                        .selectedSalesChannelId,
                                      product: {
                                        ...product
                                      },
                                      productId: product.id,
                                      __typename: 'ProductSalesChannel'
                                    }
                                  },
                                  variables: {
                                    shopId: shopId,
                                    productId: product.id,
                                    salesChannelId: this.state
                                      .selectedSalesChannelId
                                  }
                                })
                              }}
                              variant="contained"
                              color="primary"
                              style={{ fontWeight: 400 }}
                            >
                              Assign
                            </Button>
                          )
                        }}
                      </Mutation>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 8 }}>
                    {this.state.loading === true ? (
                      <CircularProgress />
                    ) : product.salesChannels ? (
                      product.salesChannels.map(pc =>
                        !pc.salesChannelId ? null : (
                          <div
                            container
                            alignItems="center"
                            style={{ padding: '8px 0px', marginRight: '12px' }}
                          >
                            <Grid
                              item
                              data-cy={'Product-SC-Item-' + pc.salesChannelId}
                              style={{
                                background: '#62082b',
                                height: 36,
                                borderRadius: 3,
                                display: 'flex',
                                alignContent: 'center',
                                padding: 8,
                                marginRight: 8,
                                position: 'relative',
                                color: '#fff'
                              }}
                            >
                              {pc.salesChannel.title
                                ? getTextFromLanguage(
                                    pc.salesChannel.title.text,
                                    defaultLanguage
                                  )
                                  ? getTextFromLanguage(
                                      pc.salesChannel.title.text,
                                      defaultLanguage
                                    )
                                  : pc.salesChannel.name
                                  ? pc.salesChannel.name
                                  : pc.salesChannelId
                                : 'N/A'}
                              <Grid
                                item
                                style={{
                                  position: 'absolute',
                                  top: '-12px',
                                  left: '85%'
                                }}
                              >
                                <Mutation
                                  mutation={ADMIN_DELETE_PRODUCT_SALESCHANNEL}
                                  update={(
                                    cache,
                                    { data: { deleteProductSalesChannel } }
                                  ) => {
                                    if (deleteProductSalesChannel) {
                                      const data = cache.readQuery({
                                        query: ADMIN_GET_PRODUCT,
                                        variables: { id: product.id }
                                      })
                                      data.product.salesChannels = data.product.salesChannels.filter(
                                        c =>
                                          c.salesChannel.id !==
                                          pc.salesChannel.id
                                      )
                                      cache.writeQuery({
                                        query: ADMIN_GET_PRODUCT,
                                        variables: { id: product.id },
                                        data
                                      })
                                    }
                                  }}
                                >
                                  {(
                                    deleteProductSalesChannel,
                                    { data, loading, error }
                                  ) => {
                                    return (
                                      <Button
                                        data-cy={
                                          'Product-SC-Delete-Icon-' +
                                          pc.salesChannelId
                                        }
                                        onClick={async () => {
                                          if (pc) {
                                            await deleteProductSalesChannel({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                deleteProductSalesChannel: true
                                              },
                                              variables: {
                                                productId: product.id,
                                                salesChannelId:
                                                  pc.salesChannelId
                                              }
                                            })
                                          }
                                        }}
                                        color="primary"
                                        variant="contained"
                                        style={{
                                          minWidth: 25,
                                          width: 25,
                                          minHeight: 25,
                                          height: 25,
                                          borderRadius: '100%',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          padding: '0px'
                                        }}
                                      >
                                        <DeleteIcon
                                          style={{ fontSize: '20' }}
                                        />
                                      </Button>
                                    )
                                  }}
                                </Mutation>
                              </Grid>
                            </Grid>
                          </div>
                        )
                      )
                    ) : null}
                  </Grid>
                </Paper>
              </Grid>
            )
          }}
        </Query>
      </Grid>
    )
  }
}

export default ProductSalesChannels
