import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import {
  CONTENT_DELETE_COLUMN,
  CONTENT_UPDATE_COLUMN
} from '../../gql/admin-mutations';
import { GET_PAGE_CONTENT } from '../../gql/admin-queries';
import _remove from 'lodash/remove';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core';
import client from '../../gql/admin-apollo-config';
import _ from 'lodash';

const styles = theme => ({});

class DeleteColumn extends Component {
  render() {
    const { pageId, Template } = this.props;

    return (
      <Mutation
        mutation={CONTENT_DELETE_COLUMN}
        variables={{ pageId: pageId, id: Template.state.selectedCol.id }}
        update={(cache, { data: { deleteContentColumn } }) => {
          const { page } = cache.readQuery({
            query: GET_PAGE_CONTENT,
            variables: { id: pageId }
          });

          const cols = page.rows.map(rowData => {
            _remove(rowData.columns, function(n) {
              return n.id === Template.state.selectedCol.id;
            });
            return rowData;
          });

          cache.writeQuery({
            query: GET_PAGE_CONTENT,
            variables: { id: pageId },
            data: {
              page: {
                ...page,
                cols
              }
            }
          });

          // Update all sortOrders
          const sortOrder = Template.state.selectedCol.sortOrder;
          const row = page.rows.find(
            o => o.id === Template.state.selectedCol.rowId
          );
          row.columns = _.sortBy(row.columns, 'sortOrder');
          row.columns.forEach(col => {
            if (sortOrder < col.sortOrder) {
              client.mutate({
                mutation: CONTENT_UPDATE_COLUMN,
                variables: {
                  id: col.id,
                  pageId: pageId,
                  input: {
                    sortOrder: col.sortOrder - 1
                  }
                }
              });
            }
          });
        }}
        onCompleted={() => {
          Template.setState(
            {
              selectedCol: '',
              updateModule: true
            },
            this.setState({ open: false })
          );
        }}
      >
        {deleteContentColumn => (
          <Tooltip title="Delete">
            <IconButton
              data-cy="Delete-Content-Column"
              onClick={deleteContentColumn}
              aria-label="Delete"
              style={{ color: 'rgb(225, 0, 80)' }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DeleteColumn);
