import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme } from '@material-ui/core/styles';
import Input from '../../common/Input';

const styles = theme => ({
  title: {
    textAlign: 'left',
    fontSize: 14
  },
  wrap: {
    marginBottom: 20,
    width: '100%'
  },
  percent: {
    backgroundColor: '#171717',
    borderRadius: 5,
    color: '#FFFFFF',
    height: '40px',
    marginTop: 5
  },
  input: {
    width: 130,
    padding: 5,
    borderRadius: 5,
    border: '1px solid #171717',
    float: 'left',
    height: 30,
    textAlign: 'center'
  },
  inputoverride: {
    width: 130,
    height: 10,
    padding: 10,
    border: 'transparent'
  },
  inputwrap: {
    marginTop: 30,
    width: 160
  },
  text: {
    marginLeft: -20
  },
  track: {
    backgroundColor: '#000'
  },
  thumb: {
    backgroundColor: '#000'
  },
  inputHeight: {
    height: 14
  },
  rail:{
    backgroundColor: '#bbb'
  }
});

class SliderInput extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {
      classes,
      width,
      min,
      max,
      step,
      disabled,
      title,
      onChange,
      onChangeCommitted,
      value,
      valueNum,
      valueLabelDisplay,
      input = true
    } = this.props;

    if (value === null) {
      value = 1;
    }

    return (
      <Grid container className={classes.wrap}>
        <Grid container direction="row" justify="center" alignItems="center">
          {input === false ? (
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ height: 40, marginTop: 5 }}
              >
                <Typography variant="subtitle1" className={classes.title}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={8}>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ height: 40, marginTop: 5 }}
              >
                <Typography variant="subtitle1" className={classes.title}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          )}

          {input === false ? null : (
            <Grid item xs={4}>
              <Grid container direcction="row" alignItems="center">
                <Grid item xs={12}>
                  <Input
                    padding="4px"
                    value={value.toFixed(0) + '%'}
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} style={{ marginTop: 8, marginBottom: 8 }}>
            <Slider
              disabled={disabled}
              value={value}
              min={min}
              max={max}
              step={step}
              onChange={onChange}
              onChangeCommitted={onChangeCommitted}
              valueLabelDisplay={valueLabelDisplay}
              classes={{
                track: classes.track,
                thumb: classes.thumb,
                rail: classes.rail,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

SliderInput.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SliderInput);
