import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from 'react'
import {
  EditorState,
  convertFromRaw,
  ContentState,
  convertToRaw,
} from 'draft-js'
import { isJSON } from '../../../../components/content/utils'
import { FONTS } from '../../../content/config'
import { ThemeContext } from '../ThemeContext'
import { makeStyles } from '@material-ui/core/styles'
import SaveText from './SaveText'
require('./RichTextComponents/styles.css')

const getEditorComponent = () => {
  if (typeof window !== 'undefined') {
    return require('react-draft-wysiwyg').Editor
  }
  return null
}

const defaultValue = {
  blocks: [
    {
      key: '9pqm5',
      text: 'Title',
      type: 'header-one',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '57d73',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
}

const useStyles = (theme: any) =>
  makeStyles({
    editor: {
      lineHeight: 'normal',
      '& h1': {
        fontSize: theme?.theme?.h1?.style?.fontSize || '65px',
        fontFamily: theme?.theme?.h1?.style?.fontFamily || 'Arial',
        letterSpacing: theme?.theme?.h1?.style?.letterSpacing || 'normal',
        fontWeight: theme?.theme?.h1?.style?.fontWeight || 'regular',
        textTransform: theme?.theme?.h1?.style?.textTransform || 'none',
      },
      '& h2': {
        fontSize: theme?.theme?.h2?.style?.fontSize || '45px',
        fontFamily: theme?.theme?.h2?.style?.fontFamily || 'Arial',
        letterSpacing: theme?.theme?.h2?.style?.letterSpacing || 'normal',
        fontWeight: theme?.theme?.h2?.style?.fontWeight || 'regular',
        textTransform: theme?.theme?.h2?.style?.textTransform || 'none',
      },
      '& h3': {
        fontSize: theme?.theme?.h3?.style?.fontSize || '35px',
        fontFamily: theme?.theme?.h3?.style?.fontFamily || 'Arial',
        letterSpacing: theme?.theme?.h3?.style?.letterSpacing || 'normal',
        fontWeight: theme?.theme?.h3?.style?.fontWeight || 'regular',
        textTransform: theme?.theme?.h3?.style?.textTransform || 'none',
      },
      '& h4': {
        fontSize: theme?.theme?.h4?.style?.fontSize || '25px',
        fontFamily: theme?.theme?.h4?.style?.fontFamily || 'Arial',
        letterSpacing: theme?.theme?.h4?.style?.letterSpacing || 'normal',
        fontWeight: theme?.theme?.h4?.style?.fontWeight || 'regular',
        textTransform: theme?.theme?.h4?.style?.textTransform || 'none',
      },
      '& h5': {
        fontSize: theme?.theme?.h5?.style?.fontSize || '20px',
        fontFamily: theme?.theme?.h5?.style?.fontFamily || 'Arial',
        letterSpacing: theme?.theme?.h5?.style?.letterSpacing || 'normal',
        fontWeight: theme?.theme?.h5?.style?.fontWeight || 'regular',
        textTransform: theme?.theme?.h5?.style?.textTransform || 'none',
      },
    },
  })

interface Props {
  text: any
  isPreview?: boolean
  contentId?: any
  element?: any
  handleTextUpdate?: any
  isHover: boolean
}

const RichText: React.FC<Props> = props => {
  const {
    text,
    isPreview,
    contentId,
    element,
    handleTextUpdate,
    isHover,
  } = props

  const theme = useContext(ThemeContext)

  const classes = useStyles(theme)()

  const editorRef = useRef(null)

  const getInitialContent = useCallback(() => {
    if (text) {
      const content = isJSON(text)
        ? convertFromRaw(JSON.parse(text))
        : ContentState.createFromText(text)
      return EditorState.createWithContent(content)
    } else {
      return EditorState.createWithContent(convertFromRaw(defaultValue))
    }
  }, [text])

  const initialEditorState = getInitialContent()

  const [editorState, setEditorState] = useState(initialEditorState)

  const [raw, setRaw] = useState()

  useEffect(() => {
    setEditorState(initialEditorState)
  }, [text])

  const updateTextState = (editorState: any) => {
    setEditorState(editorState)
  }

  const EditorComponent = getEditorComponent()

  if (!EditorComponent) {
    return null
  }

  let hasH1,
    hasH2,
    hasH3 = false

  try {
    const currentContent = convertToRaw(editorState.getCurrentContent())
    hasH1 = currentContent?.blocks.find(b => b.type === 'header-one')
      ? true
      : false
    hasH2 = currentContent?.blocks.find(b => {
      return b.type === 'header-two'
    })
      ? true
      : false
    hasH3 = currentContent?.blocks.find(b => {
      return b.type === 'header-three'
    })
      ? true
      : false
  } catch (error) {}

  return (
    <>
      {hasH1 && isHover ? (
        <span
          style={{
            position: 'absolute',
            background: 'rgb(178, 53, 102)',
            color: 'white',
            padding: '2px',
          }}
        >
          H1 ✔
        </span>
      ) : null}
      {hasH2 && isHover ? (
        <span
          style={{
            position: 'absolute',
            background: 'rgb(178, 53, 102)',
            color: 'white',
            padding: '2px',
            left: '70px',
          }}
        >
          H2 ✔
        </span>
      ) : null}
      {hasH3 && isHover ? (
        <span
          style={{
            position: 'absolute',
            background: 'rgb(178, 53, 102)',
            color: 'white',
            padding: '2px',
            left: '120px',
          }}
        >
          H3 ✔
        </span>
      ) : null}
      <EditorComponent
        editorRef={(editor: any) => (editorRef.current = editor)}
        editorClassName={classes.editor}
        toolbarOnFocus
        stripPastedStyles={true}
        editorState={editorState}
        toolbarCustomButtons={[
          <SaveText
            contentId={contentId}
            element={element}
            editorState={editorState}
            handleTextUpdate={handleTextUpdate}
          />,
        ]}
        onEditorStateChange={(val: any) => {
          updateTextState(val)
          const content = editorState.getCurrentContent()

          const data = convertToRaw(content)
          setRaw(data)
        }}
        readOnly={isPreview ? true : false}
        onBlur={async () => {
          const content = editorState.getCurrentContent()

          const data = convertToRaw(content)

          await handleTextUpdate(
            contentId,
            'contentText',
            JSON.stringify(data),
            'root',
            element.contentText
          )
        }}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'embedded',
            'image',
            'link',
            'remove',
          ],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5'],
          },
          fontFamily: {
            options: FONTS,
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          textAlign: {
            options: ['left', 'center', 'right'],
          },
        }}
      />
    </>
  )
}

export default RichText
