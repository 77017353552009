import React from 'react'
import {
  Grid,
  Paper,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Button
} from '@material-ui/core'
import { TEXT_TRANSFORM, FONT_WEIGHT } from '../../../content/config'

interface Props {
  themeData: any
  themeId: string
  handleUpdate: any
}

const ThemeButton: React.FC<Props> = props => {
  const { themeId, themeData, handleUpdate } = props
  return (
    <Grid key={themeId} item xs={12} style={{ marginTop: 24 }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Grid
            container
            style={{ padding: '24px 24px 24px 0px' }}
            spacing={1}
            alignItems="center"
          >
            <Button
              style={{
                fontSize:
                  themeData.button && themeData.button.style
                    ? themeData.button.style.fontSize
                    : null,
                fontFamily:
                  themeData.button && themeData.button.style
                    ? themeData.button.style.fontFamily
                    : null,
                textTransform:
                  themeData.button && themeData.button.style
                    ? themeData.button.style.textTransform
                    : null,
                fontWeight:
                  themeData.button && themeData.button.style
                    ? themeData.button.style.fontWeight
                    : null,
                border:
                  themeData.button && themeData.button.style
                    ? themeData.button.style.border
                    : null,
                backgroundColor:
                  themeData.button && themeData.button.style
                    ? themeData.button.style.backgroundColor
                    : null
              }}
              color="primary"
              variant="contained"
            >
              Button
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Paper style={{ padding: 24, borderRadius: 15 }} elevation={0}>
            <Grid
              style={{
                border: '1px solid lightgrey',
                borderRadius: 7,
                padding: '40px 40px'
              }}
              container
              spacing={2}
            >
              <Grid item xs={6}>
                <InputLabel htmlFor="fontFamily-native-helper">
                  Font Size
                </InputLabel>
                <TextField
                  placeholder="45px"
                  defaultValue={
                    themeData.button && themeData.button.style
                      ? themeData.button.style.fontSize
                      : null
                  }
                  onBlur={(e: any) =>
                    handleUpdate(
                      'fontSize',
                      e.target.value,
                      '',
                      'button',
                      'style'
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="fontFamily-native-helper">
                  Font Family
                </InputLabel>
                <TextField
                  placeholder="Roboto"
                  defaultValue={
                    themeData.button && themeData.button.style
                      ? themeData.button.style.fontFamily
                      : null
                  }
                  onBlur={(e: any) =>
                    handleUpdate(
                      'fontFamily',
                      e.target.value,
                      '',
                      'button',
                      'style'
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="fontFamily-native-helper">
                  Text Transform
                </InputLabel>
                <Select
                  value={
                    themeData.button && themeData.button.style
                      ? themeData.button.style.textTransform
                      : null
                  }
                  onBlur={(e: any) =>
                    handleUpdate(
                      'textTransform',
                      e.target.value,
                      '',
                      'button',
                      'style'
                    )
                  }
                >
                  {TEXT_TRANSFORM.map(textTransform => (
                    <MenuItem key={textTransform} value={textTransform}>
                      {textTransform}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="fontFamily-native-helper">
                  Font Weight
                </InputLabel>
                <Select
                  value={
                    themeData.button && themeData.button.style
                      ? themeData.button.style.fontWeight
                      : null
                  }
                  onBlur={(e: any) =>
                    handleUpdate(
                      'fontWeight',
                      e.target.value,
                      '',
                      'button',
                      'style'
                    )
                  }
                >
                  {FONT_WEIGHT.map((fontWeight, i) => (
                    <MenuItem key={i} value={fontWeight.value}>
                      {fontWeight.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="fontFamily-native-helper">
                  Border Color
                </InputLabel>
                <TextField
                  placeholder="e.g.: #000000"
                  defaultValue={
                    themeData.button && themeData.button.style
                      ? themeData.button.style.borderColor
                      : null
                  }
                  onBlur={(e: any) =>
                    handleUpdate(
                      'borderColor',
                      e.target.value,
                      '',
                      'button',
                      'style'
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="fontFamily-native-helper">
                  Border Thickness
                </InputLabel>
                <TextField
                  placeholder="e.g.: 1px"
                  defaultValue={
                    themeData.button && themeData.button.style
                      ? themeData.button.style.borderThickness
                      : null
                  }
                  onBlur={(e: any) =>
                    handleUpdate(
                      'borderThickness',
                      e.target.value,
                      '',
                      'button',
                      'style'
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="fontFamily-native-helper">
                  Background Color
                </InputLabel>
                <TextField
                  placeholder="#000"
                  defaultValue={
                    themeData.button && themeData.button.style
                      ? themeData.button.style.backgroundColor
                      : null
                  }
                  onBlur={(e: any) =>
                    handleUpdate(
                      'backgroundColor',
                      e.target.value,
                      '',
                      'button',
                      'style'
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="fontFamily-native-helper">
                  Button Type
                </InputLabel>
                <TextField
                  placeholder="Normal, outlined or text"
                  defaultValue={
                    themeData.button && themeData.button.type
                      ? themeData.button.type
                      : null
                  }
                  onBlur={(e: any) =>
                    handleUpdate('type', e.target.value, '', 'button', 'root')
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ThemeButton
