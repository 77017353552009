import React, { useState, useContext, useEffect } from 'react'
import { Grid, List, ListItem, ListItemText } from '@material-ui/core'
import PagesOps from './PagesOps'
import { getTextFromLanguage } from '../../../../../../helper/languageHelper'
import { SelectedLanguageContext } from '../LanguageContext'
import { navigate } from '@reach/router'

interface Props {
  data: any
  selected: string
  shopId: string
  homePageId: string | null | undefined
  selectedSalesChannel: string | null
  salesChannels: any
  handleSetSelected(id: string): void
  shopUrl: string
}

const PagesList: React.FunctionComponent<Props> = props => {
  const {
    data,
    selected,
    shopId,
    handleSetSelected,
    homePageId,
    salesChannels,
    selectedSalesChannel,
    shopUrl
  } = props
  const [hover, setHover] = useState(null)
  const selectedLanguage = useContext(SelectedLanguageContext)

  const handleListItemClick = (event: any, id: string): void => {
    navigate(window.location.pathname + `?p=${id}`)
    handleSetSelected(id)
  }

  return (
    <Grid
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
      container={true}
      spacing={2}
      style={{ padding: 20, maxHeight: '500px', overflow: 'auto' }}
    >
      <List component="nav" aria-label="pages-list">
        {data
          ? data.map((pagesNode: any) => {
              return (
                <Grid
                  key={'pagelist' + pagesNode.id}
                  onMouseOver={() => setHover(pagesNode.id)}
                  onMouseLeave={() => setHover(null)}
                  container={true}
                  direction="row"
                  data-testid="page-title"
                >
                  <ListItem
                    selected={selected === pagesNode.id}
                    onClick={event => handleListItemClick(event, pagesNode.id)}
                    button
                    style={{ maxWidth: 150 }}
                    data-testid="page-title-button"
                  >
                    <ListItemText
                      style={{ paddingLeft: 5 }}
                      primary={pagesNode?.latestRevision?.name?.defaultValue}
                    />
                  </ListItem>
                  <Grid
                    style={{ alignSelf: 'center', marginLeft: 5 }}
                    item={true}
                  >
                    <PagesOps
                      isHover={hover === pagesNode.id}
                      shopUrl={shopUrl}
                      homePageId={homePageId}
                      shopId={shopId}
                      pageId={pagesNode.id}
                      slugs={getTextFromLanguage(
                        pagesNode.slugs,
                        selectedLanguage
                      )}
                      name={pagesNode.latestRevision.name}
                      description={pagesNode.latestRevision.description}
                      selectedSalesChannel={selectedSalesChannel}
                      salesChannels={salesChannels}
                    />
                  </Grid>
                </Grid>
              )
            })
          : null}
      </List>
    </Grid>
  )
}

export default PagesList
