import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import { navigate } from '@reach/router';
import { ADMIN_SHOP_DISCOUNTS } from '../../gql/admin-queries';
import { ADMIN_CREATE_DISCOUNT } from '../../gql/admin-mutations';

class AddDiscount extends Component {
  render() {
    const { shopId } = this.props;
    return (
      <Mutation
        mutation={ADMIN_CREATE_DISCOUNT}
        variables={{ shopId: shopId }}
        update={(cache, { data: { createDiscount } }) => {
          if (createDiscount.id) {
            navigate(
              `/admin/${shopId}/settings/discounts/${createDiscount.id}`
            );
          }
        }}
      >
        {addDiscount => (
          <Button
            color="primary"
            onClick={() => {
              addDiscount();
            }}
            variant="contained"
            size="small"
          >
            New discount +
          </Button>
        )}
      </Mutation>
    );
  }
}

export default AddDiscount;
