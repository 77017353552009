import React, { Component } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Query, Mutation } from 'react-apollo';
import {
  ADMIN_GET_DISCOUNT_RULE,
  ADMIN_GET_DISCOUNT,
  ADMIN_SHOP_CUSTOMERS,
  ADMIN_SHOP_COLLECTIONS
} from '../../gql/admin-queries';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import {
  ADMIN_CREATE_DISCOUNT_RULE,
  ADMIN_UPDATE_DISCOUNT_RULE
} from '../../gql/admin-mutations';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';
import uuid from 'uuid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '../../../common/Input';
import Select from '../../../common/Select';
import DiscountRuleItem from './DiscountRuleItem';
import omitDeep from 'omit-deep';

class DiscountRules extends Component {
  state = {};
  render() {
    const {
      discountId,
      classes,
      discount,
      updateDiscount,
      currencies,
      defaultLanguage,
      shopId
    } = this.props;
    if (!discount) return null;
    return (
      <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
        <Paper elevation={0} style={{ padding: 24 }}>
          {discount.discountRules
            ? discount.discountRules.map(
                (tempDiscountRule, discountRuleIndex) => {
                  return !tempDiscountRule ? null : (
                    <DiscountRuleItem
                      key={'dr' + discountRuleIndex}
                      shopId={shopId}
                      defaultLanguage={defaultLanguage}
                      currencies={currencies}
                      classes={classes}
                      discountId={discountId}
                      discount={discount}
                      discountRuleId={tempDiscountRule.id}
                      discountRuleIndex={discountRuleIndex}
                    />
                  );
                }
              )
            : null}
          <Mutation
            mutation={ADMIN_CREATE_DISCOUNT_RULE}
            update={(cache, { data: { createDiscountRule } }) => {
              if (createDiscountRule.id) {
                window.location.reload();
                // const data = cache.readQuery({
                //   query: ADMIN_GET_DISCOUNT,
                //   variables: { id: discountId }
                // });
                // data.discount.discountRules.unshift({
                //   ...createDiscountRule
                // });
                // cache.writeQuery({
                //   query: ADMIN_GET_DISCOUNT,
                //   variables: { id: discountId },
                //   data
                // });
              }
            }}
          >
            {(createDiscountRule, { data, loading, error }) => {
              return (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    createDiscountRule({
                      variables: {
                        discountId: discountId,
                        input: {
                          operator: 'AND'
                        }
                      }
                    });
                  }}
                >
                  Add rule
                </Button>
              );
            }}
          </Mutation>
        </Paper>
      </Grid>
    );
  }
}

export default DiscountRules;
