import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
// import Dropzone from 'react-dropzone';
import { shopId } from '../../../../../brikl-config';
import { ADMIN_GET_SHOP_SETTINGS } from '../../gql/admin-queries';
import { ADMIN_UPDATE_SHOP_SETTINGS } from '../../gql/admin-mutations';
import _get from 'lodash/get';
import Input from '../../../common/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import { countries as countryList } from '../../../../helper/countryList';
import _sortBy from 'lodash/sortBy';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '../../../common/Select';
import uuid from 'uuid';
import omitDeep from 'omit-deep';
import { SingleSelect } from 'react-select-material-ui';
import _cloneDeep from 'lodash/cloneDeep';
const countries = [
  {
    code: 'EUROPEAN_UNION',
    name: 'EU'
  },
  ...countryList
];

const suggestions = _sortBy(countries, ['name']).map(c => c.name);

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    marginTop: '24px !important',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  }
});

class Taxes extends Component {
  state = {
    countryCode: ''
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { classes, shopId } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ height: 60, marginBottom: 20 }}
        >
          <Typography
            style={{
              fontSize: 28,
              fontWeight: 400,
              color: '#424242'
            }}
          >
            Taxes
          </Typography>
          <div style={{ flex: 1 }} />
        </Grid>
        <Grid style={{}} container>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3} />
        </Grid>
        <Grid style={{}} container>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 24 }}>
                  Tax regions
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Define tax rates and link them to different regions or
                  countries
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            
              <Query query={ADMIN_GET_SHOP_SETTINGS} variables={{ id: shopId }}>
                {({ loading, error, data: { shop } }) => {
                  if (loading) return <CircularProgress />;
                  if (error) return <div>Error :(</div>;
                  if (!shop) return 'Loading...';
                  return (
                    <Mutation
                      update={(store, { data: { updateShop } }) => {}}
                      mutation={ADMIN_UPDATE_SHOP_SETTINGS}
                    >
                      {(updateShop, { data, loading, error }) => {
                        let selectOptions = [];
                        try {
                          selectOptions = suggestions.filter(s => {
                            try {
                              if (
                                shop.taxSettings &&
                                shop.taxSettings.taxRates
                              ) {
                                return !shop.taxSettings.taxRates
                                  .map(t => t.countryCode)
                                  .includes(
                                    countries.find(c => c.name === s).code
                                  )
                                  ? true
                                  : false;
                              } else {
                                return true;
                              }
                            } catch (error) {
                              return true;
                            }
                          });
                        } catch (error) {}
                        return (
                          <Grid container spacing={3}>
                           
                            <Grid item xs={12}>
                            <Paper elevation={0} style={{ padding: 24, paddingBottom: 8 }}>
                              <Grid item
                              xs={12}
                              style={{ marginBottom: 24 }}>
                              <Input
                                label={'Default tax rate in %'}
                                placeholder="Enter defaut tax rate in %"
                                onChange={e => {
                                  updateShop({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateShop: {
                                        ...shop,
                                        taxSettings: {
                                          ...shop.taxSettings,
                                          defaultTaxRate: e.target.value
                                            ? parseFloat(e.target.value)
                                            : null
                                        }
                                      }
                                    },
                                    variables: {
                                      id: shopId,
                                      input: {
                                        taxSettings: {
                                          defaultTaxRate: e.target.value
                                            ? parseFloat(e.target.value)
                                            : null
                                        }
                                      }
                                    }
                                  });
                                }}
                                id="outlined-logo"
                                value={
                                  shop.taxSettings
                                    ? shop.taxSettings.defaultTaxRate
                                    : 0
                                }
                                margin="normal"
                              />
                            </Grid>
                            

                            {shop &&
                            shop.taxSettings &&
                            shop.taxSettings.taxRates
                              ? shop.taxSettings.taxRates.map(
                                  (taxRate, index) => {
                                    return (
                                      <Grid
                                        item
                                        xs={12}
                                        key={taxRate.id}
                                        style={{ marginBottom: 24 }}
                                      >
                                        <Input
                                          label={`${
                                            countries.find(
                                              c =>
                                                c.code === taxRate.countryCode
                                            )
                                              ? countries.find(
                                                  c =>
                                                    c.code ===
                                                    taxRate.countryCode
                                                ).name
                                              : '-Undefined-'
                                          } - Tax rate in %`}
                                          placeholder="Enter Tax rate in %"
                                          onChange={e => {
                                            updateShop({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updateShop: {
                                                  ...shop,
                                                  taxSettings: {
                                                    ...shop.taxSettings,
                                                    taxRates: shop.taxSettings.taxRates.map(
                                                      tempTaxRate => {
                                                        return {
                                                          ...tempTaxRate,
                                                          percent:
                                                            tempTaxRate.id ===
                                                            taxRate.id
                                                              ? parseFloat(
                                                                  e.target.value
                                                                )
                                                              : tempTaxRate.percent
                                                        };
                                                      }
                                                    )
                                                  }
                                                }
                                              },
                                              variables: {
                                                id: shopId,
                                                input: {
                                                  taxSettings: {
                                                    taxRates: [
                                                      ...omitDeep(
                                                        shop.taxSettings
                                                          .taxRates,
                                                        ['__typename']
                                                      ).map(tempTaxRate => {
                                                        return {
                                                          ...tempTaxRate,
                                                          percent:
                                                            tempTaxRate.id ===
                                                            taxRate.id
                                                              ? parseFloat(
                                                                  e.target.value
                                                                )
                                                              : tempTaxRate.percent
                                                        };
                                                      })
                                                    ]
                                                  }
                                                }
                                              }
                                            });
                                          }}
                                          id="outlined-logo"
                                          value={taxRate.percent}
                                          margin="normal"
                                        />
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          style={{ marginTop: 8 }}
                                          onClick={() => {
                                            updateShop({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updateShop: {
                                                  ...shop,
                                                  taxSettings: {
                                                    ...shop.taxSettings,
                                                    taxRates: shop.taxSettings.taxRates.filter(
                                                      tempTaxRate => {
                                                        return tempTaxRate.countryCode !==
                                                          taxRate.countryCode
                                                          ? true
                                                          : false;
                                                      }
                                                    )
                                                  }
                                                }
                                              },
                                              variables: {
                                                id: shopId,
                                                input: {
                                                  taxSettings: {
                                                    taxRates: [
                                                      ..._cloneDeep(
                                                        shop.taxSettings
                                                          .taxRates
                                                      )
                                                        .map(e => {
                                                          return omitDeep(e, [
                                                            '__typename'
                                                          ]);
                                                        })
                                                        .filter(
                                                          tempTaxRate1 => {
                                                            return tempTaxRate1.countryCode !==
                                                              taxRate.countryCode
                                                              ? true
                                                              : false;
                                                          }
                                                        )
                                                    ]
                                                  }
                                                }
                                              }
                                            });
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </Grid>
                                    );
                                  }
                                )
                              : null}
                             
                      </Paper>
                      </Grid>
 <Grid item xs={12}>
 <Paper elevation={0} style={{ padding: 24 }}>

              <Grid container spacing={3}>
            <Grid item xs={12}>
                              <Select
                                label="Add tax rates by country"
                                labeColor="#969696"
                                options={selectOptions}
                                onChange={selectedValue => {
                                  const value = countries.find(
                                    c => c.name === selectedValue
                                  ).code;
                                  const newTaxRateId = uuid.v4();
                                  updateShop({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateShop: {
                                        ...shop,
                                        taxSettings: shop.taxSettings
                                          ? {
                                              ...shop.taxSettings,
                                              taxRates: shop.taxSettings
                                                .taxRates
                                                ? [
                                                    ...shop.taxSettings
                                                      .taxRates,
                                                    {
                                                      id: newTaxRateId,
                                                      countryCode: value,
                                                      __typename: 'TaxRate'
                                                    }
                                                  ]
                                                : [
                                                    {
                                                      id: newTaxRateId,
                                                      countryCode: value,
                                                      __typename: 'TaxRate'
                                                    }
                                                  ]
                                            }
                                          : {
                                              taxRates: [
                                                {
                                                  id: newTaxRateId,
                                                  countryCode: value,
                                                  __typename: 'TaxRate'
                                                }
                                              ]
                                            }
                                      }
                                    },
                                    variables: {
                                      id: shopId,
                                      input: {
                                        taxSettings: shop.taxSettings
                                          ? {
                                              taxRates: shop.taxSettings
                                                .taxRates
                                                ? [
                                                    ..._cloneDeep(
                                                      shop.taxSettings.taxRates
                                                    ).map(
                                                      (
                                                        tempVariantOption,
                                                        tempIndex
                                                      ) => {
                                                        return omitDeep(
                                                          tempVariantOption,
                                                          ['__typename']
                                                        );
                                                      }
                                                    ),
                                                    {
                                                      id: newTaxRateId,
                                                      countryCode: value
                                                    }
                                                  ]
                                                : [
                                                    {
                                                      id: newTaxRateId,
                                                      countryCode: value
                                                    }
                                                  ]
                                            }
                                          : {
                                              taxRates: [
                                                {
                                                  id: newTaxRateId,
                                                  countryCode: value
                                                }
                                              ]
                                            }
                                      }
                                    }
                                  });
                                }}
                              />
                            </Grid>
                            </Grid>       
            </Paper>
            </Grid>                

                          </Grid>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Query>
           

            
            
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 24, display: 'none' }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Tax calculation
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Manage how your store calculates and shows tax on your store.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Query
                query={ADMIN_GET_SHOP_SETTINGS}
                variables={{ id: shopId }}
              >
                {({ loading, error, data: { shop } }) => {
                  if (loading) return <CircularProgress />;
                  if (error) return <div>Error :(</div>;
                  return (
                    <Mutation
                      update={(store, { data: { updateShop } }) => {}}
                      mutation={ADMIN_UPDATE_SHOP_SETTINGS}
                    >
                      {(updateShop, { data, loading, error }) => {
                        return (
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Input
                                label="Shop name"
                                placeholder="Shop name"
                                value={shop.name}
                                margin="normal"
                              />
                            </Grid>
                          </Grid>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Query>
            </Paper>
          </Grid> */}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Taxes);
