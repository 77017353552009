import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { OrganisationContext } from '../../../user/context/OrganisationContext';
import ProductList from './ProductList';
import ProductDetail from './ProductDetail';

class Products extends Component {
  render() {
    const { shopId, defaultLanguage, shop, shopUrl } = this.props;
    return this.props.productId ? (
      <Grid container>
        <OrganisationContext.Consumer>
          {currentOrganisation => (
            <ProductDetail
              shopUrl={shopUrl}
              currencies={shop.currencies}
              defaultCurrency={shop.defaultCurrency}
              defaultLanguage={defaultLanguage}
              languages={shop.languages}
              shopId={shopId}
              urlPrefix={this.props.urlPrefix}
              currentOrganisation={currentOrganisation}
              shop={shop}
              productId={this.props.productId}
            />
          )}
        </OrganisationContext.Consumer>
      </Grid>
    ) : (
      <Grid container style={{ width: '100%', padding: 24 }}>
        <ProductList defaultLanguage={defaultLanguage} shopId={shopId} />
      </Grid>
    );
  }
}

export default Products;
