import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Query, Mutation } from 'react-apollo'
import {
  ADMIN_SHOP_PRODUCT_ATTRIBUTES,
  ADMIN_GET_PRODUCT
} from '../../gql/admin-queries'
import {
  ADMIN_UPDATE_PRODUCT,
  ADMIN_COPY_PRODUCT
} from '../../gql/admin-mutations'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import _sortBy from 'lodash/sortBy'
import Auth from '@aws-amplify/auth'
import Helmet from 'react-helmet'
// import Input from '../../../common/Input';
import TextField from '../../../common/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import _get from 'lodash/get'
import './ProductFileDrop.css'
import { OrganisationContext } from '../../../user/context/OrganisationContext'
import CircularProgress from '@material-ui/core/CircularProgress'
import CustomProductDetail from './CustomProductDetail'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import ProductDescription from './ProductDescription'
import ProductTitle from './ProductTitle'
import ProductMedia from './ProductMedia'
import ProductSlug from './ProductSlug'
import ProductDetailVariant from './ProductDetailVariant'
import ProductDetailAttribute from './ProductDetailAttribute'
import ProductCollections from './ProductCollections'
import ProductPrice from './ProductPrice'
import ProductInventory from './ProductInventory'
import ProductSalesChannels from './ProductSalesChannels'
import client from '../../../admin/gql/admin-apollo-config'
import SimpleSnackbar from '../content/SimpleSnackbar'
import ProductDetails from './ProductDetails'
import { InputLabel, Input } from '@material-ui/core'
import ProductCarelabelPosition from './ProductCarelabelPosition'
import ProductDiscounts from './ProductDiscounts'
// import ProductPublishDate from './ProductPublishDate'
import ProductSellFrom from './ProductSellFrom'
import { isBriklUser } from '../../../../helper/userHelper'
var jwtDecode = require('jwt-decode')

const styles = theme => ({
  progress: {},
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    marginTop: '24px !important',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  },
  rootCheckbox: {
    padding: '3px 8px',
    color: '#B23566',
    '&$checked': {
      color: '#B23566'
    }
  },
  checked: {},
  inputCell: {
    width: 230,
    border: 'none'
  },
  buttonCheckbox: {
    width: 100,
    border: '1px solid #d39aaf',
    borderRadius: '3px',
    color: '#B23566',
    '&:hover': {
      border: '1px solid #B23566',
      cursor: 'pointer',
      background: '#EAE1E4'
    }
  }
})

const WAIT_INTERVAL = 1500

class ProductDetail extends Component {
  state = {
    isActive: false,
    message: '',
    isBriklSuperAdmin: false,
    open: false,
    value: 'Yes',
    notification: false
  }

  handleChangehasPersonalization = (event, updateProduct, product) => {
    updateProduct({
      optimisticResponse: {
        __typename: 'Mutation',
        updateProduct: {
          ...product,
          hasPersonalization: event.target.value === 'yes' ? true : false
        }
      },
      variables: {
        id: product.id,
        input: {
          hasPersonalization: event.target.value === 'yes' ? true : false
        }
      }
    })
  }

  timer = null

  updateProductDetail = (productId, fieldName, value) => {
    // clearTimeout(this.timer)
    client.mutate({
      mutation: ADMIN_UPDATE_PRODUCT,
      variables: {
        id: productId,
        input: {
          [fieldName]: value
        }
      }
    })
  }

  updateMultilangDetail = (
    productId,
    fieldName,
    value,
    fieldId,
    language,
    product
  ) => {
    const { languages } = this.props

    client.mutate({
      mutation: ADMIN_UPDATE_PRODUCT,
      variables: {
        id: productId,
        input: {
          [fieldName]: {
            id: fieldId,
            text: [
              ...languages.map((tempLanguage, index) => {
                return {
                  langCode: tempLanguage,
                  content:
                    tempLanguage === language
                      ? value
                      : getTextFromLanguage(
                          product[fieldName].text,
                          tempLanguage
                        )
                }
              })
            ]
          }
        }
      }
    })

    // clearTimeout(this.timer)

    // this.timer = setTimeout(() => {
    //   client
    //     .mutate({
    //       mutation: ADMIN_UPDATE_PRODUCT,
    //       variables: {
    //         id: productId,
    //         input: {
    //           [fieldName]: {
    //             id: fieldId,
    //             text: [
    //               ...languages.map((tempLanguage, index) => {
    //                 return {
    //                   langCode: tempLanguage,
    //                   content:
    //                     tempLanguage === language
    //                       ? value
    //                       : getTextFromLanguage(
    //                           product[fieldName].text,
    //                           tempLanguage
    //                         )
    //                 }
    //               })
    //             ]
    //           }
    //         }
    //       }
    //     })
    //     .then(res => {
    //       this.setState({
    //         notification: true
    //       })
    //     })
    // }, WAIT_INTERVAL)
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  }

  async componentDidMount() {
    try {
      const session = await Auth.currentSession()
      if (session) {
        // console.log('session', session);
        var decoded = jwtDecode(session.idToken.jwtToken)
        if (decoded['cognito:groups'].includes('BRIKLSUPERADMIN') !== -1) {
          this.setState({
            isBriklSuperAdmin: true
          })
        }
        this.setState({
          isBriklUser: isBriklUser(decoded['email'])
        })
      }
    } catch (error) {}
  }
  render() {
    const {} = this.state

    const {
      classes,
      currencies,
      defaultCurrency,
      productId,
      currentOrganisation,
      urlPrefix,
      defaultLanguage,
      languages,
      shopId,
      shop,
      shopUrl
    } = this.props
    var blockEdit = true
    if (
      process.env.GATSBY_API_STAGE === 'local' ||
      process.env.GATSBY_API_STAGE === 'dev'
    ) {
      blockEdit = false
    }
    if (this.state.isBriklSuperAdmin === true) {
      blockEdit = false
    }
    const isBriklUser = this.state.isBriklUser

    return (
      <div className={classes.root}>
        <Typography>
          <Grid container>
            <Grid item xs={12}>
              <Query
                query={ADMIN_SHOP_PRODUCT_ATTRIBUTES}
                variables={{ id: shopId }}
              >
                {({ loading, error, data: { shop } }) => {
                  let sortedAttributes = null
                  try {
                    if (shop && shop.productAttributes) {
                      sortedAttributes = shop.productAttributes.edges.sort(
                        function(a, b) {
                          if (
                            getTextFromLanguage(
                              a.node.title.text,
                              defaultLanguage
                            ) <
                            getTextFromLanguage(
                              b.node.title.text,
                              defaultLanguage
                            )
                          ) {
                            return -1
                          }
                          if (
                            getTextFromLanguage(
                              a.node.title.text,
                              defaultLanguage
                            ) >
                            getTextFromLanguage(
                              b.node.title.text,
                              defaultLanguage
                            )
                          ) {
                            return 1
                          }
                          return 0
                        }
                      )
                    }
                  } catch (error) {
                    console.error('at.error', error)
                  }
                  return (
                    <Query
                      query={ADMIN_GET_PRODUCT}
                      variables={{ id: productId }}
                    >
                      {({ loading, error, data: { product }, refetch }) => {
                        if (loading)
                          return (
                            <CircularProgress className={classes.progress} />
                          )
                        if (error) return <div>Error :(</div>
                        if (!product) return null
                        var productTitle = ''
                        try {
                          productTitle = getTextFromLanguage(
                            product.title.text,
                            defaultLanguage
                          )
                        } catch (error) {}
                        return (
                          <Mutation
                            update={(store, { data: { updateProduct } }) => {
                              this.setState({
                                isActive: true,
                                message: 'Product updated'
                              })
                            }}
                            mutation={ADMIN_UPDATE_PRODUCT}
                            variables={{ id: productId }}
                          >
                            {(updateProduct, { data, loading, error }) => {
                              return (
                                <div>
                                  <Helmet>
                                    <title>{'Product - ' + productTitle}</title>
                                  </Helmet>
                                  <Grid
                                    container
                                    style={{
                                      height: 60,
                                      marginBottom: 20,
                                      width: 'calc(100% - 240px)',
                                      position: 'fixed',
                                      background: '#f0f0f0',
                                      top: 0,
                                      right: 0,
                                      zIndex: 10
                                    }}
                                  >
                                    <Grid
                                      container
                                      justify="flex-start"
                                      direction="row"
                                      alignItems="center"
                                      style={{
                                        height: 60,
                                        maxWidth: 1000,
                                        paddingRight: 24,
                                        paddingLeft: 24,
                                        margin: 'auto'
                                      }}
                                    >
                                      <Typography
                                        data-cy="New-Product-header"
                                        style={{
                                          fontSize: 28,
                                          fontWeight: 400,
                                          color: '#424242'
                                        }}
                                      >
                                        {product.no ? product.no : 'Product'}
                                      </Typography>

                                      <div style={{ flex: 1 }} />

                                      <Button
                                        data-cy="Product-Preview-btn"
                                        variant="contained"
                                        color="primary"
                                        style={{ marginRight: 16 }}
                                        onClick={() => {
                                          window.open(
                                            `${shopUrl}/preview?type=product&id=${product.id}&language=${defaultLanguage}`
                                          )
                                        }}
                                      >
                                        Preview
                                      </Button>
                                      <Grid
                                        container
                                        className={classes.buttonCheckbox}
                                        alignItems="center"
                                      >
                                        <Checkbox
                                          data-cy="Product-Active-Checkbox"
                                          type="checkbox"
                                          classes={{
                                            root: classes.rootCheckbox,
                                            checked: classes.checked
                                          }}
                                          checked={
                                            product.status === 'ACTIVE'
                                              ? true
                                              : false
                                          }
                                          onChange={e => {
                                            updateProduct({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updateProduct: {
                                                  ...product,
                                                  status: e.target.checked
                                                    ? 'ACTIVE'
                                                    : 'INACTIVE'
                                                }
                                              },
                                              variables: {
                                                id: productId,
                                                input: {
                                                  status: e.target.checked
                                                    ? 'ACTIVE'
                                                    : 'INACTIVE'
                                                }
                                              }
                                            })
                                          }}
                                        />
                                        ACTIVE
                                      </Grid>

                                      {product && product.isCustomProduct ? (
                                        <Grid item>
                                          <OrganisationContext.Consumer>
                                            {currentOrganisation => (
                                              <Button
                                                variant="text"
                                                onClick={async () => {
                                                  if (1 === 1) {
                                                    if (
                                                      product.designStudioVersion ===
                                                      'V2'
                                                    ) {
                                                      // create product design here
                                                      // const createProductDesignData = await client.mutate(
                                                      //   {
                                                      //     mutation: CREATE_PRODUCT_DESIGN,
                                                      //     variables: {
                                                      //       designStudioVersion:
                                                      //         product.designStudioVersion,
                                                      //       salesChannel: 'MYBRIKL',
                                                      //       shopId: shopId,
                                                      //       baseProductId: productId,
                                                      //       // copyFrom: copyFrom,
                                                      //       ownedByOrganisationId:
                                                      //         shopId + '-ADMIN'
                                                      //     }
                                                      //   }
                                                      // );
                                                      // window.open(
                                                      //   shopUrl +
                                                      //     `/${urlPrefix}be-creative/?s=${shopId}&p=${productId}&pd=${
                                                      //       createProductDesignData.data
                                                      //         .createProductDesign.id
                                                      //     }&o=${shopId + '-ADMIN'}`
                                                      // );
                                                      window.open(
                                                        shopUrl +
                                                          `/${urlPrefix}new-design/?p=${productId}&v=V2&sc=MYBRIKL`
                                                      )
                                                    } else {
                                                      window.open(
                                                        shopUrl +
                                                          `/${urlPrefix}new-design/?p=${productId}&v=V1&sc=MYBRIKL`
                                                      )
                                                    }
                                                  }
                                                }}
                                              >
                                                Open studio demo
                                              </Button>
                                            )}
                                          </OrganisationContext.Consumer>
                                        </Grid>
                                      ) : null}

                                      <Button
                                        data-cy="Product-Back-btn"
                                        style={{ marginLeft: 16 }}
                                        variant="outlined"
                                        color="primary"
                                        href={
                                          '/admin/' +
                                          shopId +
                                          '/inventory/products'
                                        }
                                      >
                                        Back to products
                                      </Button>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    container
                                    style={{
                                      marginTop: 65,
                                      width: 'calc(100% - 240px)'
                                    }}
                                  >
                                    {this.state.copyProduct === true ? (
                                      <Mutation
                                        update={(
                                          store,
                                          { data: { copyProduct } }
                                        ) => {
                                          if (copyProduct && copyProduct.id) {
                                            window.open(
                                              '/admin/' +
                                                shopId +
                                                '/inventory/products/' +
                                                copyProduct.id,
                                              '_self'
                                            )
                                          }
                                        }}
                                        mutation={ADMIN_COPY_PRODUCT}
                                      >
                                        {(
                                          copyProduct,
                                          { data, loading, error }
                                        ) => {
                                          return (
                                            <Paper
                                              style={{
                                                padding: '1em'
                                              }}
                                            >
                                              <div>
                                                Copy attributes
                                                <Checkbox
                                                  onChange={e => {
                                                    this.setState({
                                                      copyAttributes:
                                                        e.target.checked
                                                    })
                                                  }}
                                                  checked={
                                                    this.state.copyAttributes
                                                  }
                                                />
                                              </div>

                                              <div>
                                                Copy variants
                                                <Checkbox
                                                  onChange={e => {
                                                    this.setState({
                                                      copyVariants:
                                                        e.target.checked
                                                    })
                                                  }}
                                                  checked={
                                                    this.state.copyVariants
                                                  }
                                                />
                                              </div>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.copying}
                                                onClick={() => {
                                                  this.setState({
                                                    copying: true
                                                  })
                                                  copyProduct({
                                                    variables: {
                                                      productId: productId,
                                                      input: {
                                                        copyAttributes: this
                                                          .state.copyAttributes,
                                                        copyVariants: this.state
                                                          .copyVariants
                                                      }
                                                    }
                                                  })
                                                }}
                                              >
                                                {this.state.copying
                                                  ? 'Copy in progress...'
                                                  : 'Copy Product'}
                                              </Button>
                                            </Paper>
                                          )
                                        }}
                                      </Mutation>
                                    ) : (
                                      <Button
                                        data-cy="Product-Copy-btn"
                                        variant="contained"
                                        color="primary"
                                        style={{ marginRight: 16 }}
                                        onClick={() => {
                                          this.setState({
                                            copyProduct: true
                                          })
                                        }}
                                      >
                                        Copy
                                      </Button>
                                    )}
                                  </Grid>

                                  {/*
***************************
Product details starts
***************************
 */}

                                  <ProductDetails
                                    classes={classes}
                                    product={product}
                                    productId={productId}
                                    updateProductDetail={
                                      this.updateProductDetail
                                    }
                                  />

                                  <ProductTitle
                                    classes={classes}
                                    languages={languages}
                                    product={product}
                                    productId={productId}
                                    updateMultilangDetail={
                                      this.updateMultilangDetail
                                    }
                                    updateProductDetail={
                                      this.updateProductDetail
                                    }
                                  />

                                  <ProductDescription
                                    languages={languages}
                                    classes={classes}
                                    product={product}
                                    productId={productId}
                                    updateMultilangDetail={
                                      this.updateMultilangDetail
                                    }
                                  />

                                  <ProductCarelabelPosition
                                    product={product}
                                    classes={classes}
                                    productId={productId}
                                    updateProductDetail={
                                      this.updateProductDetail
                                    }
                                  />

                                  <ProductSlug
                                    defaultLanguage={defaultLanguage}
                                    languages={languages}
                                    classes={classes}
                                    product={product}
                                    productId={productId}
                                    updateProduct={updateProduct}
                                  />

                                  {/*
***************************
Product details ends
***************************
*/}
                                  {/* 
                                  <ProductPublishDate
                                    defaultLanguage={defaultLanguage}
                                    shopId={shopId}
                                    classes={classes}
                                    product={product}
                                    productId={productId}
                                    updateProduct={updateProduct}
                                  /> */}
                                  <ProductSellFrom
                                    defaultLanguage={defaultLanguage}
                                    shopId={shopId}
                                    classes={classes}
                                    product={product}
                                    productId={productId}
                                    updateProduct={updateProduct}
                                  />
                                  <ProductSalesChannels
                                    defaultLanguage={defaultLanguage}
                                    shopId={shopId}
                                    classes={classes}
                                    product={product}
                                    productId={productId}
                                    updateProduct={updateProduct}
                                  />
                                  <br />

                                  <ProductCollections
                                    defaultLanguage={defaultLanguage}
                                    shopId={shopId}
                                    blockEdit={blockEdit}
                                    updateProduct={updateProduct}
                                    productId={productId}
                                    product={product}
                                    classes={classes}
                                  />

                                  {/*
***************************
Product Images starts
***************************
*/}
                                  <ProductMedia
                                    sortedAttributes={sortedAttributes}
                                    defaultLanguage={defaultLanguage}
                                    shopId={shopId}
                                    blockEdit={blockEdit}
                                    updateProduct={updateProduct}
                                    productId={productId}
                                    product={product}
                                    classes={classes}
                                  />
                                  {/*
***************************
Product Images ends
***************************
*/}
                                  <ProductPrice
                                    currencies={currencies}
                                    defaultCurrency={defaultCurrency}
                                    defaultLanguage={defaultLanguage}
                                    shopId={shopId}
                                    refetch={refetch}
                                    blockEdit={blockEdit}
                                    productId={productId}
                                    product={product}
                                    classes={classes}
                                    updateProductDetail={
                                      this.updateProductDetail
                                    }
                                  />
                                  <ProductDiscounts
                                    currencies={currencies}
                                    defaultCurrency={defaultCurrency}
                                    defaultLanguage={defaultLanguage}
                                    shopId={shopId}
                                    refetch={refetch}
                                    blockEdit={blockEdit}
                                    productId={productId}
                                    product={product}
                                    classes={classes}
                                  />
                                  <ProductInventory
                                    defaultLanguage={defaultLanguage}
                                    shopId={shopId}
                                    refetch={refetch}
                                    blockEdit={blockEdit}
                                    updateProduct={updateProduct}
                                    productId={productId}
                                    product={product}
                                    classes={classes}
                                    updateProductDetail={
                                      this.updateProductDetail
                                    }
                                  />
                                  <ProductDetailVariant
                                    defaultLanguage={defaultLanguage}
                                    shopId={shopId}
                                    refetch={refetch}
                                    blockEdit={blockEdit}
                                    updateProduct={updateProduct}
                                    productId={productId}
                                    product={product}
                                    classes={classes}
                                  />
                                  <ProductDetailAttribute
                                    sortedAttributes={sortedAttributes}
                                    defaultLanguage={defaultLanguage}
                                    shopId={shopId}
                                    refetch={refetch}
                                    blockEdit={blockEdit}
                                    updateProduct={updateProduct}
                                    productId={productId}
                                    product={product}
                                    classes={classes}
                                  />

                                  {/*
***************************
Custom product settings all below
***************************
*/}
                                  <Grid container style={{ marginTop: 24 }}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={4}
                                      lg={3}
                                      xl={3}
                                    >
                                      Available for personalization
                                      <br />
                                      Enable Personalization if a name or number
                                      can be added by your customer to a
                                      fanished design
                                      <br />* See also team store settings
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={8}
                                      lg={9}
                                      xl={9}
                                    >
                                      <Paper
                                        elevation={0}
                                        style={{ padding: 24 }}
                                      >
                                        <RadioGroup
                                          style={{
                                            flexDirection: 'row'
                                          }}
                                          aria-label=""
                                          name="hasPersonalization"
                                          value={
                                            product.hasPersonalization
                                              ? 'yes'
                                              : 'no'
                                          }
                                          onChange={e => {
                                            this.handleChangehasPersonalization(
                                              e,
                                              updateProduct,
                                              product
                                            )
                                          }}
                                        >
                                          <FormControlLabel
                                            data-cy="Product-Personalization-Yes"
                                            value={'yes'}
                                            control={<Radio />}
                                            label="Yes, this product can be personalized."
                                          />
                                          <FormControlLabel
                                            data-cy="Product-Personalization-No"
                                            value={'no'}
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </Paper>
                                    </Grid>
                                  </Grid>

                                  <Grid container style={{ marginTop: 24 }}>
                                    <Grid xs={12} sm={12} md={4} lg={3} xl={3}>
                                      <Grid
                                        container
                                        style={{
                                          padding: '24px 24px 24px 0px'
                                        }}
                                        spacing={1}
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <Typography
                                            data-cy="Product-Is-Custom-header"
                                            variant="subtitle1"
                                            style={{ marginRight: 16 }}
                                          >
                                            Is this a custom product?
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography variant="body1">
                                            More customization options are going
                                            to be displayed
                                            <br />
                                            Customizable products are templates
                                            that can be completly custom
                                            designed.
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={8}
                                      lg={9}
                                      xl={9}
                                    >
                                      <Paper
                                        style={{ padding: 24 }}
                                        elevation={0}
                                      >
                                        <Grid
                                          container
                                          spacing={1}
                                          className={classes.container}
                                        >
                                          <RadioGroup
                                            style={{
                                              flexDirection: 'row'
                                            }}
                                            aria-label=""
                                            name="isCustomProduct"
                                            value={
                                              product.isCustomProduct
                                                ? 'Yes'
                                                : 'No'
                                            }
                                            onChange={e => {
                                              updateProduct({
                                                optimisticResponse: {
                                                  __typename: 'Mutation',
                                                  updateProduct: {
                                                    ...product,
                                                    designStudioVersion: product.designStudioVersion
                                                      ? product.designStudioVersion
                                                      : 'V2',
                                                    isCustomProduct:
                                                      e.target.value === 'Yes'
                                                        ? true
                                                        : false
                                                  }
                                                },
                                                variables: {
                                                  id: productId,
                                                  input: {
                                                    designStudioVersion: product.designStudioVersion
                                                      ? product.designStudioVersion
                                                      : 'V2',
                                                    isCustomProduct:
                                                      e.target.value === 'Yes'
                                                        ? true
                                                        : false
                                                  }
                                                }
                                              })
                                            }}
                                          >
                                            <FormControlLabel
                                              data-cy="Product-Is-Custom-Yes"
                                              value={'Yes'}
                                              control={<Radio />}
                                              label="Yes"
                                            />
                                            <FormControlLabel
                                              data-cy="Product-Is-Custom-No"
                                              value={'No'}
                                              control={<Radio />}
                                              label="No"
                                            />
                                          </RadioGroup>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                  </Grid>
                                  {product.isCustomProduct === true ? (
                                    <CustomProductDetail
                                      defaultLanguage={defaultLanguage}
                                      defaultCurrency={defaultCurrency}
                                      shopId={shopId}
                                      isBriklUser={isBriklUser}
                                      currentOrganisation={currentOrganisation}
                                      product={product}
                                      productId={productId}
                                      updateProduct={updateProduct}
                                      classes={classes}
                                      blockEdit={blockEdit}
                                      refetch={refetch}
                                      state={this.state}
                                    />
                                  ) : null}
                                </div>
                              )
                            }}
                          </Mutation>
                        )
                      }}
                    </Query>
                  )
                }}
              </Query>
            </Grid>
          </Grid>
        </Typography>
      </div>
    )
  }
}

ProductDetail.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProductDetail)
