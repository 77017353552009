import Auth from '@aws-amplify/auth'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { Inputs } from '../common/Theme'

const styles = theme => ({
  root: {
    background: '#E5E5E5',
    height: 'calc(100vh - 50px)',
    padding: '25px 15px',
    position: 'relative'
  },
  input: {
    background: '#f4f4f4 !important',
    margin: '16px 0px !important',
    width: '300px',
    padding: '0px !important'
  },
  button: {
    width: '300px',
    minHeight: '45px',
    margin: '24px 0px !important'
  },
  textInput: {
    padding: '13px 24px'
  },
  forgotPassword: {
    position: 'absolute',
    bottom: '-4px',
    right: 0,
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    ':hover': {
      color: '#ffffff !important'
    }
  },
  signup: {
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    ':hover': {
      color: '#ffffff !important'
    }
  }
})

class ForgotPassword extends React.Component {
  static defaultProps = {
    authData: {},
    authState: 'forgotPassword'
    // onAuthStateChange: (next, data) => { console.log(`SignIn:onAuthStateChange(${next}, ${JSON.stringify(data, null, 2)})`); }
  }

  constructor(props) {
    super(props)
    this.state = {
      authData: this.props.authData,
      authState: this.props.authState,
      modalShowing: false,
      loading: false,
      error: null,
      message: null,
      view: 'REQUESTNEWPASSWORD',
      email: '',
      user: null
    }
  }

  async setNewPassword() {
    this.setState({ loading: true })
    try {
      if (
        this.state.email !== '' &&
        this.state.code !== '' &&
        this.state.newPassword1 === this.state.newPassword2
      ) {
        Auth.forgotPasswordSubmit(
          this.state.email,
          this.state.code,
          this.state.newPassword1
        )
          .then(data => {
            this.setState({
              view: 'SUCCESS',
              error: null,
              message: null,
              loading: false
            })
          })
          .catch(err => {
            console.error(err)
            this.setState({ error: err.message, loading: false })
          })
      }
    } catch (err) {
      console.error(`Error: ${JSON.stringify(err, null, 2)}`)
      this.setState({ error: err.message, loading: false })
    }
  }

  async sendRequest() {
    this.setState({ loading: true })
    try {
      if (this.state.email !== '') {
        Auth.forgotPassword(this.state.email)
          .then(data => {
            this.setState({
              view: 'NEWPASSWORD',
              error: null,
              message: 'Confirmation code has been sent to your email',
              loading: false
            })
          })
          .catch(err => {
            console.error(err)
            this.setState({ error: err.message, loading: false })
          })
      }
    } catch (err) {
      console.error(`Error: ${JSON.stringify(err, null, 2)}`)
      this.setState({ error: err.message, loading: false })
    }
  }

  render() {
    const { classes } = this.props
    if (this.state.view === 'SUCCESS') {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.root}
        >
          Password changed successfully, login again:
          <a href={'/admin/login'}>Login</a>
        </Grid>
      )
    }
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.root}
      >
        <h3>Forgot password</h3>
        {this.state.error ? (
          <div>
            <div>{this.state.error}</div>
          </div>
        ) : null}
        {this.state.message ? (
          <div>
            <div>{this.state.message}</div>
          </div>
        ) : null}
        <Inputs
          data-html2canvas-ignore
          data-cy="email"
          ref="email"
          type="email"
          value={this.state.email}
          onChange={e => this.setState({ email: e.target.value })}
          placeholder="Your email"
          classes={{ root: classes.input, input: classes.textInput }}
        />
        <br />
        {this.state.view === 'REQUESTNEWPASSWORD' ? null : (
          <div>
            <Inputs
              data-html2canvas-ignore
              data-cy="code"
              ref="code"
              type="text"
              value={this.state.code}
              onChange={e => this.setState({ code: e.target.value })}
              placeholder="Your code received by email"
              classes={{ root: classes.input, input: classes.textInput }}
            />
            <br />
            <Inputs
              data-html2canvas-ignore
              data-cy="newPassword1"
              ref="newPassword1"
              type="password"
              value={this.state.newPassword1}
              onChange={e => this.setState({ newPassword1: e.target.value })}
              placeholder="Your new password"
              classes={{ root: classes.input, input: classes.textInput }}
            />
            <br />
            <Inputs
              data-html2canvas-ignore
              data-cy="newPassword2"
              ref="newPassword2"
              type="password"
              value={this.state.newPassword2}
              onChange={e => this.setState({ newPassword2: e.target.value })}
              placeholder="Repeat new password"
              classes={{ root: classes.input, input: classes.textInput }}
            />
          </div>
        )}

        {this.state.loading === true ? (
          <Button className={classes.button} title="Login" disabled={true}>
            Loading
          </Button>
        ) : this.state.view === 'REQUESTNEWPASSWORD' ? (
          <Button
            data-cy="resetPassword"
            title="Reset password"
            onClick={this.sendRequest.bind(this)}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Reset password
          </Button>
        ) : (
          <Button
            data-cy="setNewPassword"
            title="Set new password"
            onClick={this.setNewPassword.bind(this)}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Set new password
          </Button>
        )}
        <br />
        <a href={'/admin/login'}>Back to Login</a>
      </Grid>
    )
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ForgotPassword)
