import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Grid, Paper, Modal } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import Typography from '@material-ui/core/Typography'
import { ADMIN_SHOP_ORDERS, ADMIN_GET_ORDER } from '../../gql/admin-queries'
import { navigate } from '@reach/router'
import OrderDetail from './OrderDetail'

import _sortBy from 'lodash/sortBy'
import Techpack from '../techpack/Techpack'
import AdminSearchOrders from './AdminSearchOrders'
import OrderList from './OrderList'
const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1400px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    marginLeft: 20,
    fontSize: 30,
    padding: 25,
    fontWeight: 'medium',
    color: '#424242'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
})

class Orders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: [],
      expanded: null,
      showOrders: true,
      showCustom: false
    }
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  handleShowCustom = () => {
    this.setState({
      showCustom: true
    })
  }

  render() {
    const {
      classes,
      orderId,
      shopId,
      defaultLanguage,
      defaultCurrency
    } = this.props
    return (
      <Grid className={classes.root}>
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Typography
            style={{
              fontSize: 28,
              fontWeight: 'medium',
              color: '#424242'
            }}
            data-cy="Header-Orders"
          >
            Order{orderId ? '' : 's'}
          </Typography>
          {orderId ? (
            <Button
              style={{
                margin: '0 0 0 auto'
              }}
              onClick={() => {
                this.setState({
                  showCustom: false
                })
                window.open('/admin/' + shopId + '/orders', '_self')
              }}
              color="primary"
              variant="outlined"
            >
              Back to orders
            </Button>
          ) : null}
        </Grid>
        {this.state.showCustom === true && orderId ? (
          <Modal
            style={{
              maxWidth: '800px',
              width: '80%',
              height: '80%',
              margin: '0 auto',
              overflow: 'auto'
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.showCustom}
            onClose={() => {
              this.setState({
                showCustom: false
              })
            }}
          >
            <Grid item xs={12}>
              <Query
                query={ADMIN_GET_ORDER}
                variables={{ id: orderId, currency: defaultCurrency }}
              >
                {({ loading, error, data }) => {
                  if (loading) return null
                  if (data && data.order) {
                    return data.order.orderItems.map(item => {
                      if (item.productDesignId) {
                        return (
                          <Techpack
                            orderId={orderId}
                            orderType={item.orderType}
                            title={item.productTitle}
                            address={data.order.shippingAddress}
                            order={data.order}
                            item={item}
                          />
                        )
                      } else {
                        return null
                      }
                    })
                  } else {
                    return null
                  }
                }}
              </Query>
            </Grid>
          </Modal>
        ) : null}
        {orderId ? (
          <Grid container>
            <Grid item xs={12}>
              <Grid container direction="column">
                <Query
                  fetchPolicy={'no-cache'}
                  query={ADMIN_GET_ORDER}
                  variables={{ id: orderId, currency: 'THB' }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return ''
                    return (
                      <OrderDetail
                        defaultLanguage={defaultLanguage}
                        order={data ? data.order : null}
                        currency={'THB'}
                        handleShowCustom={this.handleShowCustom}
                      />
                    )
                  }}
                </Query>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Grid container direction="column">
                <Query
                  query={ADMIN_SHOP_ORDERS}
                  variables={{ id: shopId, first: 100 }}
                >
                  {({ loading, error, data, fetchMore }) => {
                    if (loading) return 'Loading...'
                    var shopOrders = []
                    try {
                      if (this.state.orders && this.state.orders.length) {
                        shopOrders = _sortBy(this.state.orders, 'createdAt')
                      } else {
                        shopOrders = _sortBy(
                          data.shop.orders.edges,
                          'createdAt'
                        )
                      }
                    } catch (error) {}
                    let totalToday = 0
                    let totalWeek = 0
                    try {
                      const today = new Date()
                      shopOrders.forEach(orderNode => {
                        if (orderNode.node.paymentStatus === 'PAID') {
                          const someDate = new Date(orderNode.node.createdAt)
                          if (
                            someDate.getDate() == today.getDate() &&
                            someDate.getMonth() == today.getMonth() &&
                            someDate.getFullYear() == today.getFullYear()
                          ) {
                            totalToday = totalToday + orderNode.node.total.value
                          }
                          // if (
                          //   someDate.getMonth() == today.getMonth() &&
                          //   someDate.getFullYear() == today.getFullYear()
                          // ) {
                          //   totalWeek =
                          //     totalWeek + orderNode.node.total.value;
                          // }
                        }
                      })
                    } catch (error) {}
                    return (
                      <>
                        <Paper style={{ padding: 16, marginBottom: 16 }}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography>
                                Total today:{' '}
                                {totalToday
                                  ? totalToday.toFixed(2).toLocaleString()
                                  : 0}{' '}
                                {defaultCurrency}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              margin: '1em 0 0 0'
                            }}
                          >
                            <AdminSearchOrders
                              shopId={shopId}
                              defaultLanguage={defaultLanguage}
                              onResult={orders => {
                                this.setState({
                                  orders
                                })
                              }}
                              onSelect={o => {
                                window.open(
                                  '/admin/' + shopId + '/orders/' + o.id,
                                  '_self'
                                )
                              }}
                            />
                          </Grid>
                        </Paper>
                        <Typography>
                          {this.state.orders && this.state.orders.length
                            ? `Found ${
                                this.state.orders.length > 49
                                  ? ' more than '
                                  : ''
                              }${this.state.orders.length} orders`
                            : ''}
                        </Typography>
                        <OrderList
                          shopId={shopId}
                          endCursor={
                            data ? data.shop.orders.pageInfo.endCursor : null
                          }
                          loading={loading}
                          error={error}
                          classes={classes}
                          shopOrders={shopOrders}
                          fetchMore={fetchMore}
                        />
                      </>
                    )
                  }}
                </Query>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(Orders)
