import React, { Component } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../../common/Select';
import { Link } from 'gatsby';
import { Query, Mutation } from 'react-apollo';
import {
  ADMIN_GET_COLLECTION,
  ADMIN_SHOP_PRODUCTS
} from '../../gql/admin-queries';
import TextField from '../../../common/TextField';
import {
  ADMIN_CREATE_COLLECTION_PRODUCT_PACKAGE,
  ADMIN_DELETE_COLLECTION_PRODUCT_PACKAGE,
  ADMIN_UPDATE_COLLECTION_PRODUCT_PACKAGE
} from '../../gql/admin-mutations';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import AdminSearchProductPackage from '../common/AdminSearchProductPackage';
class CollectionProductPackages extends Component {
  state = {
    selectedProductPackageId: null,
    productPackageSelected: null
  };
  render() {
    const {
      collection,
      defaultLanguage,
      refetch,
      classes,
      shopId
    } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography
            data-cy="Collection-ProductPackage-header"
            variant="subtitle1"
          >
            Product packages
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Paper elevation={0} style={{ padding: 24 }}>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid item xs={12}>
                <Mutation
                  mutation={ADMIN_CREATE_COLLECTION_PRODUCT_PACKAGE}
                  update={(
                    cache,
                    { data: { createCollectionProductPackage } }
                  ) => {
                    if (createCollectionProductPackage) {
                      const data = cache.readQuery({
                        query: ADMIN_GET_COLLECTION,
                        variables: { id: collection.id }
                      });
                      data.collection.productPackages.unshift({
                        productPackage: {
                          id: this.state.productPackageSelected.id,
                          __typename: 'ProductPackage'
                        },
                        __typename: 'CollectionProductPackage'
                      });
                      cache.writeQuery({
                        query: ADMIN_GET_COLLECTION,
                        variables: { id: collection.id },
                        data
                      });
                    }
                  }}
                >
                  {(
                    createCollectionProductPackage,
                    { data, loading, error }
                  ) => {
                    return (
                      <AdminSearchProductPackage
                        text={'Search for product package'}
                        classes={classes}
                        onSelect={productPackage => {
                          this.setState(
                            {
                              productPackageSelected: productPackage,
                              selectedProductPackageId: productPackage.id
                            },
                            () => {
                              createCollectionProductPackage({
                                optimisticResponse: {
                                  __typename: 'Mutation',
                                  createCollectionProductPackage: {
                                    collectionId: collection.id,
                                    productPackage: {
                                      ...productPackage
                                    },
                                    productPackageId: productPackage.id,
                                    __typename: 'CollectionProductPackage'
                                  }
                                },
                                variables: {
                                  shopId: shopId,
                                  collectionId: collection.id,
                                  productPackageId: productPackage.id
                                }
                              });
                            }
                          );
                        }}
                        excludeIds={collection.productPackages.map(
                          tp => tp.productPackage.id
                        )}
                      />
                    );
                  }}
                </Mutation>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: 24 }}>
              {collection.productPackages
                ? _orderBy(
                    collection.productPackages,
                    ['sortOrder'],
                    ['asc']
                  ).map(cp => (
                    <Mutation
                      mutation={ADMIN_UPDATE_COLLECTION_PRODUCT_PACKAGE}
                      update={(
                        cache,
                        { data: { updateCollectionProductPackage } }
                      ) => {
                        const data = cache.readQuery({
                          query: ADMIN_GET_COLLECTION,
                          variables: { id: collection.id }
                        });
                        data.collection.productPackages = data.collection.productPackages.map(
                          tempCp => {
                            return tempCp.product.id === cp.productPackage.id
                              ? updateCollectionProductPackage
                              : tempCp;
                          }
                        );
                        cache.writeQuery({
                          query: ADMIN_GET_COLLECTION,
                          variables: { id: collection.id },
                          data
                        });
                      }}
                    >
                      {(
                        updateCollectionProductPackage,
                        { data, loading, error }
                      ) => {
                        var src = null;
                        try {
                          src = _get(cp.productPackage, 'media', []).find(
                            m => m && m.isThumbnail === true
                          ).image;
                        } catch (error) {}
                        return (
                          <Grid
                            container
                            alignItems="center"
                            spacing={1}
                            style={{ marginBottom: 8 }}
                            key={'cp-' + cp.productPackage.id}
                            item
                          >
                            {/* {cp.featured === true ? (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  style={{ width: 139 }}
                                  onClick={async () => {
                                    const newFeaturedValue =
                                      cp.featured === true ? false : true;
                                    await updateCollectionProductPackage({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateCollectionProductPackage: {
                                          ...cp,
                                          productPackageId: cp.product.id,
                                          collectionId: collection.id,
                                          featured: newFeaturedValue
                                        }
                                      },
                                      variables: {
                                        productPackageId: cp.product.id,
                                        collectionId: collection.id,
                                        input: {
                                          featured: newFeaturedValue
                                        }
                                      }
                                    });
                                    window.location.reload();
                                  }}
                                >
                                  Don't feature
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ width: 139 }}
                                  onClick={async () => {
                                    const newFeaturedValue =
                                      cp.featured === true ? false : true;
                                    await updateCollectionProductPackage({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateCollectionProductPackage: {
                                          ...cp,
                                          productPackageId: cp.product.id,
                                          collectionId: collection.id,
                                          featured: newFeaturedValue
                                        }
                                      },
                                      variables: {
                                        productPackageId: cp.product.id,
                                        collectionId: collection.id,
                                        input: {
                                          featured: newFeaturedValue
                                        }
                                      }
                                    });
                                    window.location.reload();
                                  }}
                                >
                                  Set featured
                                </Button>
                              )} */}
                            <Grid item xs={2}>
                              <TextField
                                style={{ padding: '0px !important' }}
                                placeholder="Sort order"
                                value={cp.sortOrder}
                                onChange={async e => {
                                  updateCollectionProductPackage({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateCollectionProductPackage: {
                                        ...cp,
                                        productPackageId: cp.productPackage.id,
                                        collectionId: collection.id,
                                        sortOrder: e.target.value
                                          ? parseFloat(e.target.value)
                                          : null
                                      }
                                    },
                                    variables: {
                                      productPackageId: cp.productPackage.id,
                                      collectionId: collection.id,
                                      input: {
                                        sortOrder: e.target.value
                                          ? parseFloat(e.target.value)
                                          : null
                                      }
                                    }
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Grid container justify="center">
                                {src ? (
                                  <img
                                    width={20}
                                    height={20}
                                    style={{
                                      objectFit: 'contain',
                                      margin: '8px',
                                      width: '44px',
                                      height: '36px',
                                      border: '1px solid #eee',
                                      borderRadius: 4
                                    }}
                                    src={src}
                                    alt={'cp.productPackage.id'}
                                  />
                                ) : (
                                  <div style={{ width: '60px' }} />
                                )}
                              </Grid>
                            </Grid>
                            <Grid item xs={8}>
                              <a
                                target="_blank"
                                href={
                                  '/admin/' +
                                  shopId +
                                  '/inventory/productpackages/' +
                                  cp.productPackage.id
                                }
                                style={{
                                  textDecoration: 'none',
                                  color: '#000',
                                  display: 'flex',
                                  flex: 1
                                }}
                              >
                                <Grid
                                  container
                                  style={{
                                    background: '#62082b',
                                    height: 36,
                                    borderRadius: 3,
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '8px 8px',
                                    marginRight: 8,
                                    color: '#fff'
                                  }}
                                >
                                  {cp.productPackage.title
                                    ? getTextFromLanguage(
                                        cp.productPackage.title.text,
                                        defaultLanguage
                                      )
                                    : 'N/A'}
                                  &nbsp;({cp.productPackage.no})
                                </Grid>
                              </a>
                            </Grid>
                            <Grid item xs={1}>
                              <Mutation
                                mutation={
                                  ADMIN_DELETE_COLLECTION_PRODUCT_PACKAGE
                                }
                                update={(
                                  cache,
                                  { data: { deleteCollectionProductPackage } }
                                ) => {
                                  if (deleteCollectionProductPackage) {
                                    const data = cache.readQuery({
                                      query: ADMIN_GET_COLLECTION,
                                      variables: { id: collection.id }
                                    });
                                    data.collection.productPackages = data.collection.productPackages.filter(
                                      c =>
                                        c.productPackage.id !==
                                        cp.productPackage.id
                                    );
                                    cache.writeQuery({
                                      query: ADMIN_GET_COLLECTION,
                                      variables: { id: collection.id },
                                      data
                                    });
                                  }
                                }}
                              >
                                {(
                                  deleteCollectionProductPackage,
                                  { data, loading, error }
                                ) => {
                                  return (
                                    <Button
                                      onClick={async () => {
                                        if (cp) {
                                          await deleteCollectionProductPackage({
                                            optimisticResponse: {
                                              __typename: 'Mutation',
                                              deleteCollectionProductPackage: true
                                            },
                                            variables: {
                                              productPackageId:
                                                cp.productPackage.id,
                                              collectionId: collection.id
                                            }
                                          });
                                        }
                                      }}
                                      color="primary"
                                      variant="contained"
                                      style={{ minWidth: 0, width: '100%' }}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  );
                                }}
                              </Mutation>
                            </Grid>
                          </Grid>
                        );
                      }}
                    </Mutation>
                  ))
                : null}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default CollectionProductPackages;
