import Amplify from '@aws-amplify/core';
import { API_STAGE } from '../../../brikl-config';
import {
  ADMIN_COGNITO_IDENTITY_POOL_ID,
  ADMIN_COGNITO_USER_POOL_ID,
  ADMIN_COGNITO_WEBCLIENT_ID
} from '../../helper/config';

if (typeof document !== 'undefined') {
  var environment = API_STAGE;
  //production
  if (environment === 'production') {
  } else {
    window.LOG_LEVEL = 'DEBUG';
  }

  Amplify.configure({
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: ADMIN_COGNITO_IDENTITY_POOL_ID,

      // REQUIRED - Amazon Cognito Region
      region: 'ap-southeast-1',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: ADMIN_COGNITO_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: ADMIN_COGNITO_WEBCLIENT_ID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false

      // OPTIONAL - Configuration for cookie storage
      // cookieStorage: {
      // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //     domain: '.yourdomain.com',
      // // OPTIONAL - Cookie path
      //     path: '/',
      // // OPTIONAL - Cookie expiration in days
      //     expires: 365,
      // // OPTIONAL - Cookie secure flag
      //     secure: true
      // },

      // OPTIONAL - customized storage object
      // storage: new MyStorage(),

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  });
}
