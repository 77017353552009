import * as tools from '../tools/tools'
import { API_STAGE } from '~/brikl-config'
import _uniqBy from 'lodash/uniqBy'
import { save } from '../tools/Functions/save_load'

let SHOP_ID = tools.getQueryStringValue('s')

var DesignRoot: any = null
var svgOBJECT: any = null

var originalWidth = 0
var originalHeight = 0

var _DIM_CHECK = false
var _DIM_W = null
var _DIM_H = null
var _DIM_ELM = null

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot
  if (!SHOP_ID) {
    SHOP_ID = DesignRoot.SHOP_ID
  }
}

var allSVGLOAD = null
export async function getSVG(src) {
  // FOR GET DIMENSION INFO

  try {
    logger.log('getSVG', src)

    src = DesignRoot.tools._wrapTimeToSrc(src)

    window.fabric.loadSVGFromURL(
      src,
      function(objects, options) {
        DesignRoot._ARTBOARD_DIMENSION.width = options.width
        DesignRoot._ARTBOARD_DIMENSION.height = options.height

        logger.log('getSVG', objects, options, DesignRoot._ARTBOARD_DIMENSION)
        allSVGLOAD = {
          objects: objects,
          options: options
        }

        return true
      },
      null,
      { crossOrigin: 'anonymous' }
    )
  } catch (e) {
    // statements
    logger.error('svg_control.getSVG.error', e)
  }
}

export function addSVG(id, src, x, y, canvas, base_width, base_height) {
  try {
    // statements

    if (
      svgOBJECT === null ||
      DesignRoot.fabric_function.getObjectByType('SVG').length === 0
    ) {
      logger.log(
        'addSVG',
        id,
        src,
        x,
        y,
        canvas,
        SHOP_ID,
        base_width,
        base_height
      )

      // src = DesignRoot.tools._wrapTimeToSrc(src)

      logger.log('allSVGLOAD', allSVGLOAD)

      if (allSVGLOAD !== null) {
        var objects = allSVGLOAD.objects
        var options = allSVGLOAD.options

        logger.log('addSVG objects', objects, options)

        originalWidth = options.width
        originalHeight = options.height

        DesignRoot.svgW = originalWidth
        DesignRoot.svgH = originalHeight

        DesignRoot._ARTBOARD_DIMENSION.width = originalWidth
        DesignRoot._ARTBOARD_DIMENSION.height = originalHeight

        DesignRoot.fabric_function.adjust_template_scale(
          DesignRoot._ARTBOARD_DIMENSION
        )

        var scaleX = 1

        var initColor = 'transparent'

        var objectCaching = true

        DesignRoot.canvas.setDimensions({
          width: DesignRoot._ARTBOARD_DIMENSION.width * DesignRoot._CANVAS_SIZE,
          height:
            DesignRoot._ARTBOARD_DIMENSION.height * DesignRoot._CANVAS_SIZE
        })

        if (DesignRoot.isDashboard) {
          // RENDER SCENE 100%
          DesignRoot._CANVAS_RENDER.setDimensions({
            width: DesignRoot._ARTBOARD_DIMENSION.width,
            height: DesignRoot._ARTBOARD_DIMENSION.height
          })

          objectCaching = false
        }

        DesignRoot.canvas.requestRenderAll()

        for (var i = 0; i < objects.length; i++) {
          var fullID = objects[i].id ? objects[i].id : ''

          console.log('SVG INIT')
          console.log(
            DesignRoot._ACTIVE_COLOR_ZONE,
            DesignRoot._DEFAULT_TEMPLATE_ACTIVE,
            DesignRoot._BLANK_TEMPLATE_ACTIVE
          )

          if (
            DesignRoot._ACTIVE_COLOR_ZONE === true ||
            (DesignRoot._DEFAULT_TEMPLATE_ACTIVE === true &&
              DesignRoot._BLANK_TEMPLATE_ACTIVE === false)
          ) {
            initColor = 'transparent'
          } else {
            initColor = '#FFF'
          }

          // CHECK REF FROM SVG ELM

          if (fullID.indexOf('DIM_') !== -1) {
            _DIM_W = fullID.match(/(W\d*.\d*)/g)
            _DIM_H = fullID.match(/(H\d*.\d*)/g)

            if (_DIM_W !== null && _DIM_W.length) {
              _DIM_W = _DIM_W[0]
              _DIM_W = _DIM_W.replace('W', '')
              _DIM_W = parseFloat(_DIM_W)
            } else {
              _DIM_W = null
            }

            if (_DIM_H !== null && _DIM_H.length) {
              _DIM_H = _DIM_H[0]
              _DIM_H = _DIM_H.replace('H', '')
              _DIM_H = parseFloat(_DIM_H)
            } else {
              _DIM_H = null
            }

            if (_DIM_W === null && _DIM_H === null) {
              _DIM_CHECK = false
            } else {
              _DIM_CHECK = true
              _DIM_ELM = objects[i]
            }

            logger.log('DIM_W', _DIM_W, 'DIM_H', _DIM_H, _DIM_CHECK, _DIM_ELM)
            DesignRoot._PXTOCM = 0
          }

          // CHECK REF FROM SVG ELM

          var idSplit = fullID.split('-')
          var colorZoneIndex = null

          if (idSplit.length > 1) {
            var newId = 'SVG-' + idSplit[0] + '-' + idSplit[1]
            var index = idSplit[0] + '-' + idSplit[1]
          } else {
            var newId = 'SVG-' + idSplit[0] + '-' + i
            var index = idSplit[0] + '-' + i
          }

          var bounding = false
          var boundingType = null
          var evented = true
          var stroke = '#fff'
          var zoneGroup = null
          var zoneLimitItem = 0
          var zoneNumber = null
          var strokeWidth = 0
          var visible = true
          var _OBJECT_TYPE = 'SVG'

          // XCEED

          if (DesignRoot._SVG_BOUNDING === true) {
            var splitName = fullID.split('.')
            var boundingCheck = splitName[0]
            var type = splitName[1]
            var zoneGroup = splitName[2]
            var zoneLimitItem = splitName[3]
            var zoneNumber = splitName[4]
            var zoneAngle = splitName[5]

            if (splitName[6] !== undefined) {
              var zoneName = splitName[6].replace(/([A-Z])/g, ' $1').trim()
            } else {
              var zoneName = zoneGroup
            }

            if (
              (DesignRoot._DEFAULT_TEMPLATE_ACTIVE === true &&
                DesignRoot.productID ===
                  '05eecb8f-e7ce-414f-9ef5-71d3b9b65676') ||
              DesignRoot._ACTIVE_COLOR_ZONE === true ||
              (DesignRoot._DEFAULT_TEMPLATE_ACTIVE === true &&
                DesignRoot._BLANK_TEMPLATE_ACTIVE === false)
              //TODO fix sth sets blank template to active for MX Endurance here?
            ) {
              initColor = 'transparent'
            } else {
              initColor = '#FFF'
            }

            if (objects[i].strokeDashArray) {
              evented = false
              initColor = 'transparent'
              _OBJECT_TYPE = 'SVG_DASHED'
            }

            if (
              fullID.indexOf('BOUNDING') !== -1 &&
              fullID.indexOf('TEXT') !== -1
            ) {
              initColor = 'transparent'
              evented = false
              bounding = true
              stroke = '#f00'
              strokeWidth = 0
              boundingType = 'TEXT'
              _OBJECT_TYPE = 'SVG'
            } else if (
              fullID.indexOf('BOUNDING') !== -1 &&
              fullID.indexOf('LOGO') !== -1
            ) {
              initColor = 'transparent'
              evented = false
              bounding = true
              stroke = '#0f0'
              strokeWidth = 0
              boundingType = 'LOGO'
              _OBJECT_TYPE = 'SVG'
            } else if (
              fullID.indexOf('BOUNDING') !== -1 &&
              fullID.indexOf('FLAG') !== -1
            ) {
              DesignRoot._SHOW_AOP_FLAG = true
              initColor = 'transparent'
              evented = true
              bounding = true
              stroke = '#00f'
              strokeWidth = 0
              boundingType = 'FLAG'
              _OBJECT_TYPE = 'SVG'
            } else if (
              fullID.indexOf('BOUNDING') !== -1 &&
              fullID.indexOf('BOTH') !== -1
            ) {
              initColor = 'transparent'
              evented = false
              bounding = true
              stroke = '#00f'
              strokeWidth = 0
              boundingType = 'BOTH'
              _OBJECT_TYPE = 'SVG'
            }
          } else {
            if (fullID.indexOf('BOUNDING') !== -1) {
              evented = false
              visible = false
              strokeWidth = 0
            } else if (objects[i].strokeDashArray) {
              evented = false
              initColor = 'transparent'
              _OBJECT_TYPE = 'SVG_DASHED'
            }
          }

          // XCEED

          // SVG COLOR ZONE

          if (fullID.indexOf('COLORZONE') !== -1) {
            logger.log('COLORZONE SVG', fullID, objects[i], idSplit)
            strokeWidth = 0
            initColor = objects[i].fill
            _OBJECT_TYPE = 'SVG_COLORZONE'

            newId = 'SVG-' + idSplit[0] + '-' + idSplit[1] + '-' + i
            index = idSplit[0] + '-' + i
            colorZoneIndex = 'COLORZONE-' + idSplit[1]
          }

          // SVG COLOR ZONE

          objects[i].set({
            id: newId,
            strokeWidth: strokeWidth,
            stroke: stroke,
            _USER_DATA: {
              hasChange: false,
              index: index,
              fullID: fullID,
              colorZoneIndex: colorZoneIndex
            },
            _OBJECT_TYPE: _OBJECT_TYPE,
            fill: initColor,
            evented: evented,
            visible: visible,
            perPixelTargetFind: true,
            objectCaching: objectCaching,
            dirty: true,
            hasRotatingPoint: false
          })
          objects[i].selectable = false
          objects[i].hascontrols = false
          objects[i].hasBorders = false

          if (bounding === true) {
            objects[i]._BOUNDING_TYPE = boundingType
            objects[i]._ZONE_GROUP = zoneGroup
            objects[i]._ZONE_LIMIT_ITEM = zoneLimitItem
            objects[i]._ZONE_NUMBER = zoneNumber
            objects[i]._ZONE_ANGLE = zoneAngle
            objects[i]._ZONE_NAME = zoneName
            objects[i].strokeDashArray = [5, 10]
            DesignRoot._SVG_BOUNDING_SET.push(objects[i])
          } else {
            if (DesignRoot._APP_TYPE === '2d-3d') {
              addSVG_Border(objects[i], canvas)
            } else if (_OBJECT_TYPE === 'SVG') {
              addSVG_Border(objects[i], canvas)
            }
          }

          canvas.add(objects[i])
        }

        svgOBJECT = objects
        canvas.requestRenderAll()
        DesignRoot.fabric_function.reArrageItem()
        DesignRoot.fabric_function.mapSvgWith3DElm(
          DesignRoot._ARTBOARD_DIMENSION.width,
          DesignRoot._ARTBOARD_DIMENSION.height
        )

        if (DesignRoot._LOAD_DATA !== null) {
          var SVG = DesignRoot._LOAD_DATA.SVG
          loadSVG(canvas, SVG)
          DesignRoot.fabric_function.reArrageItem()
        }

        // LOAD BOUNDING LOGO

        logger.log(
          'LOAD BOUNDING LOGO',
          DesignRoot.onLoad,
          DesignRoot._LOAD_DATA
        )

        if (DesignRoot.onLoad === true || DesignRoot._LOAD_DATA !== null) {
          var allItem = DesignRoot._LOAD_DATA.allItem

          for (var i = 0; i < allItem.length; i++) {
            if (
              allItem[i].objectType === 'LOGO' &&
              (allItem[i].userData.defaultItem === true ||
                allItem[i].userData.boundingId !== null)
            ) {
              var src = allItem[i].userData.s3Url + '?t=' + new Date().getDay()
              allItem[i].userData.base64 = src

              var userData = allItem[i].userData

              var boundingId = userData.boundingId
              var boundingGroup = userData.boundingGroup

              var boundingData = DesignRoot.fabric_function.getObjectById(
                boundingId
              )

              userData.centerPoint = boundingData.getCenterPoint()
              userData.boundingId = boundingData.id
              userData.boundingGroup = boundingData._ZONE_GROUP

              var canvasData = {
                left: boundingData.left,
                top: boundingData.top,
                scaleX: boundingData.scaleX,
                scaleY: boundingData.scaleY,
                width: boundingData.width,
                height: boundingData.height,
                flipX: allItem[i].canvasData.flipX,
                flipY: allItem[i].canvasData.flipY,
                angle: allItem[i].canvasData.angle
              }

              DesignRoot.fabric_function.addItem(
                allItem[i].id || userData.id,
                allItem[i].name,
                src,
                boundingData.left,
                boundingData.top,
                userData,
                allItem[i].objectType,
                'ON-LOAD',
                canvasData,
                true
              )
            } else if (
              allItem[i].objectType === 'TEXT' &&
              DesignRoot._TEXT_LOAD === false &&
              (allItem[i].userData.defaultItem === true ||
                allItem[i].userData.boundingId !== null)
            ) {
              DesignRoot._TEXT_LOAD = true
            }
          }
        }

        // LOAD BOUNDING LOGO

        // STACK LOAD

        if (SHOP_ID === 'yuepai') {
          for (var i = 0; i < DesignRoot._STACK.length; i++) {
            addSTACK(
              DesignRoot._STACK[i].stackId,
              DesignRoot._STACK[i].stackSvg,
              x,
              y,
              canvas,
              base_width,
              base_height,
              DesignRoot._STACK[i].legendColor,
              DesignRoot._STACK[i].svgCalElm,
              DesignRoot._STACK[i].svgCalCm
            )
          }
        }

        // STACK LOAD

        // AFTER LOAD ALL TEMPLATE THEN FIT SCENE
        DesignRoot.fabric_function.fitInScene()

        if (_DIM_CHECK === true) {
          if (_DIM_W !== null) {
            var w = _DIM_ELM.width * _DIM_ELM.scaleX
            logger.log('CHECK DIMENSION', w, _DIM_ELM)
            var ref = _DIM_W / w
            DesignRoot._PXTOCM = ref
            DesignRoot._PXBASE = w
            DesignRoot._CMBASE = _DIM_W
            logger.log('PX TO CM', DesignRoot._PXTOCM)

            // DEBUG BOX DIMENSION

            // var box = new window.fabric.Rect({
            //   top: 0,
            //   left: 0,
            //   width: w,
            //   height: w,
            //   fill: 'transparent',
            //   stroke: 'red',
            //   originX: 'center',
            //   originY: 'center',
            // });
            // DesignRoot.canvas.add(box);

            // DEBUG BOX DIMENSION
          } else if (_DIM_H !== null) {
            var h = _DIM_ELM.height * _DIM_ELM.scaleX
            logger.log('CHECK DIMENSION', h)
            var ref = _DIM_H / h
            DesignRoot._PXTOCM = ref
            DesignRoot._PXBASE = h
            DesignRoot._CMBASE = _DIM_H
            logger.log('PX TO CM', DesignRoot._PXTOCM)
          }

          if (DesignRoot.isSaveEnabled) {
            logger.log('save _PXTOCM')
            save()
          }
        }

        tools.renderComponent('DesignRoot')
      }
    } // END IF
    else {
      // ALREADY HAVE SVG THEN ADJUST SCALE

      logger.log(
        'updateSVG',
        id,
        src,
        x,
        y,
        canvas,
        SHOP_ID,
        base_width,
        base_height,
        DesignRoot.svgW,
        DesignRoot.svgH
      )

      // var scaleX = base_width / DesignRoot.svgW;

      DesignRoot._ARTBOARD_DIMENSION.width = DesignRoot.svgW
      DesignRoot._ARTBOARD_DIMENSION.height = DesignRoot.svgH

      DesignRoot.fabric_function.adjust_template_scale(
        DesignRoot._ARTBOARD_DIMENSION
      )

      var initColor = 'transparent'

      if (DesignRoot._ACTIVE_COLOR_ZONE === false) {
        // var newHeight = DesignRoot.svgH * scaleX;
        // DesignRoot._ARTBOARD_DIMENSION.height = newHeight;

        initColor = '#FFF'

        // DesignRoot.canvas.setDimensions({
        //   width: base_width * DesignRoot._CANVAS_SIZE,
        //   height: newHeight * DesignRoot._CANVAS_SIZE
        // });

        // logger.log('newHeight', newHeight);

        // DesignRoot.imageLoad.width = base_width;
        // DesignRoot.imageLoad.height = newHeight;

        if (DesignRoot.isDashboard) {
          // RENDER SCENE 100%
          // DesignRoot._CANVAS_RENDER.setDimensions({
          //   width: base_width,
          //   height: newHeight
          // });
        }

        // DesignRoot.canvas.requestRenderAll();

        var allSVG = DesignRoot.fabric_function.getObjectByType('SVG')

        for (var i = 0; i < allSVG.length; i++) {
          allSVG[i].set({
            fill: initColor
          })
          allSVG[i]._USER_DATA.hasChange = false
          allSVG[i]._USER_DATA.fillType = 'COLOR'
        }

        DesignRoot.canvas.requestRenderAll()
      } else {
        var initColor = 'transparent'
        var allSVG = DesignRoot.fabric_function.getObjectByType('SVG')
        for (var i = 0; i < allSVG.length; i++) {
          allSVG[i].set({
            fill: initColor
          })
          allSVG[i]._USER_DATA.hasChange = false
          allSVG[i]._USER_DATA.fillType = 'COLOR'
        }

        DesignRoot.canvas.requestRenderAll()
      }

      // ALREADY HAVE SVG THEN ADJUST SCALE

      DesignRoot.fabric_function.fitInScene()
      DesignRoot.fabric_function.reArrageItem()
      DesignRoot._TRIGGER_RENDER = true
    }
  } catch (e) {
    // statements
    logger.error('svg_control.addSVG.error', e)
  }
}

export function addSTACK(
  id,
  src,
  x,
  y,
  canvas,
  base_width,
  base_height,
  legendColor,
  svgCalElm,
  svgCalCm
) {
  try {
    // statements

    logger.log('addSTACK', id, src, x, y, canvas)

    window.fabric.loadSVGFromURL(
      src,
      function(objects, options) {
        logger.log('objects', objects)

        var scaleX = base_width / options.width

        for (var i = 0; i < objects.length; i++) {
          var fullID = objects[i].id
          var idSplit = fullID.split('-')
          var newId = 'STACK-' + id + '-' + idSplit[0] + '-' + idSplit[1]
          var index = idSplit[0] + '-' + idSplit[1]

          objects[i].set({
            id: newId,
            scaleX: scaleX,
            scaleY: scaleX,
            top: objects[i].top * scaleX,
            left: objects[i].left * scaleX,
            strokeWidth: 2,
            _USER_DATA: {
              hasChange: false,
              size: id,
              index: index,
              fullID: fullID,
              legendColor: legendColor,
              svgCalElm: svgCalElm,
              svgCalCm: svgCalCm
            },
            _OBJECT_TYPE: 'STACK',
            fill: 'transparent',
            stroke: legendColor,
            strokeDashArray: [10, 10],
            visible: false
          })
          objects[i].selectable = false
          objects[i].hascontrols = false
          objects[i].hasBorders = false
          canvas.add(objects[i])
          canvas.sendToBack(objects[i])
        }

        canvas.requestRenderAll()
      },
      null,
      { crossOrigin: 'anonymous' }
    )
  } catch (e) {
    // statements
    logger.error('svg_control.addSTACK.error', e)
  }
}

export function loadSVG(canvas, SVG) {
  try {
    // statements

    logger.log('loadSVG', DesignRoot, canvas, SVG)

    for (var key in SVG) {
      if (SVG.hasOwnProperty(key)) {
        var svgData = DesignRoot.fabric_function.getObjectById(key)

        logger.log('svgData', svgData)

        if (svgData !== null) {
          if (SVG[key].fillType === 'COLOR') {
            DesignRoot.change_color.changeColorPickerSelectedSVGELM(
              SVG[key].color,
              svgData
            )
          } else if (SVG[key].fillType === 'AOP') {
            if (SVG[key].aopSrc.indexOf('s3-') !== -1) {
              DesignRoot._S3_AOP_SRC[key] = SVG[key].aopSrc

              DesignRoot.AopStep.aopList.push({
                file_name: SVG[key].aopSrc,
                file_size: null,
                file_type: null,
                FILE_DATA: [],
                base64: SVG[key].aopSrc
              })

              DesignRoot.AopStep.setState({ render: true })
            }

            DesignRoot.change_color.changeAOPSelectedSVGELM(
              SVG[key].aopSrc,
              svgData,
              SVG[key].transform
            )
          }
        }
      }
    } // END FOR
  } catch (e) {
    // statements
    logger.error('svg_control.addSTACK.error', e)
  }
}

export function addSVG_Border(object, canvas) {
  try {
    var objectBorder = window.fabric.util.object.clone(object)

    var strokeWidth = 0.1
    if (DesignRoot.isDashboard) {
      strokeWidth = 1

      if (objectBorder._OBJECT_TYPE === 'SVG') {
        // svgHover(object)
      }
    }

    objectBorder.set({
      id: objectBorder.id + '_BORDER',
      _OBJECT_TYPE: objectBorder._OBJECT_TYPE + '_BORDER',
      fill: 'transparent',
      stroke: '#b8b8b8',
      evented: false,
      strokeWidth: strokeWidth,
      excludeFromExport: false
    })
    canvas.add(objectBorder)
  } catch (e) {
    logger.error('svg_control.addSVG_Border.error', e)
  }
}

export function svgHover(object) {
  try {
    if (DesignRoot._APP_TYPE !== '2d-3d') {
      return
    }

    object.on({
      mouseover: function(evt) {
        // logger.log('mouseover', evt);

        if (DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK') {
          return
        }

        var target = evt.target

        if (
          target &&
          target._OBJECT_TYPE !== undefined &&
          target._OBJECT_TYPE === 'SVG'
        ) {
          var borderObject = DesignRoot.fabric_function.getObjectById(
            target.id + '_BORDER'
          )
          borderObject.set({
            stroke: '#00f',
            strokeWidth: 5
          })

          DesignRoot.canvas.requestRenderAll()
        }
      }
    })

    object.on({
      mouseout: function(evt) {
        // logger.log('mouseout', evt);

        if (DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK') {
          return
        }

        var target = evt.target

        if (
          target &&
          target._OBJECT_TYPE !== undefined &&
          target._OBJECT_TYPE === 'SVG'
        ) {
          var borderObject = DesignRoot.fabric_function.getObjectById(
            target.id + '_BORDER'
          )
          borderObject.set({
            stroke: '#b8b8b8',
            strokeWidth: 1
          })

          DesignRoot.canvas.requestRenderAll()
        }
      }
    })
  } catch (e) {
    logger.error('svg_control.svgHover.error', e)
  }
}

export function svg_border_default_set(sync = false) {
  try {
    if (
      DesignRoot._APP_TYPE !== '2d-3d' ||
      DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK'
    ) {
      return
    }

    var allSvgBorder = DesignRoot.fabric_function.getObjectByType('SVG_BORDER')

    var checkRender = false
    for (var i = 0; i < allSvgBorder.length; i++) {
      if (allSvgBorder[i].strokeWidth !== 1) {
        checkRender = true

        allSvgBorder[i].set({
          stroke: '#b8b8b8',
          strokeWidth: 1
        })
      }
    }

    if (checkRender === true) {
      if (!sync) {
        DesignRoot.canvas.requestRenderAll()
      } else {
        DesignRoot.canvas.renderAll()
      }
    }
  } catch (e) {
    logger.error('svg_control.svg_border_default_set.error', e)
  }
}

// SVG COLOR ZONE 11/2019

export function load_svg_colorzone() {
  try {
    clear_svg_colorzone()

    if (
      DesignRoot._COLOR_ZONE_SVG_ACTIVE === false ||
      DesignRoot._COLOR_ZONE_SVG_SELECTED === null
    ) {
      return
    }

    logger.log(
      'load_svg_colorzone',
      DesignRoot._COLOR_ZONE_SVG,
      DesignRoot._COLOR_ZONE_SVG_ACTIVE
    )

    logger.log(
      'check load color zone',
      DesignRoot.onLoad,
      DesignRoot.loadData,
      DesignRoot.loadData.zoneColors
    )

    var colorByZoneId: any = []
    if (
      DesignRoot.onLoad === true &&
      DesignRoot.loadData.zoneColors !== null &&
      DesignRoot.loadData.zoneColors.length
    ) {
      for (var i = 0; i < DesignRoot.loadData.zoneColors.length; i++) {
        var { colorId, zoneId, colorHex } = DesignRoot.loadData.zoneColors[i]
        colorByZoneId[zoneId] = DesignRoot.loadData.zoneColors[i]
      }
    }

    var selected = DesignRoot._COLOR_ZONE_SVG_SELECTED

    var src = DesignRoot.tools._wrapTimeToSrc(
      DesignRoot._COLOR_ZONE_SVG[selected]
    )

    window.fabric.loadSVGFromURL(
      src,
      function(objects, options) {
        logger.log('load_svg_colorzone objects', objects, options, selected)

        var objectCaching = true

        if (DesignRoot._APP_TYPE === '2d-3d') {
          objectCaching = true
        }

        for (var i = 0; i < objects.length; i++) {
          if (objects[i].id.indexOf('COLORZONE-') !== -1) {
            var colorId = null

            const zoneId = objects[i].id.match(/([COLORZONE-]+[\d]+)/g)[0]
            const designTemplate = DesignRoot.props?.productDesign?.product?.designTemplates?.find(
              t => {
                return t?.colors?.find(c => {
                  return c && c.svgId === zoneId
                })
              }
            )
            const designTemplateColorId = designTemplate?.colors?.find(c => {
              return c & (c.svgId === zoneId)
            })?.id

            logger.log('zoneId', zoneId)

            var colorHex = objects[i].fill

            const gradientData: any = {}

            // LOAD

            if (colorByZoneId[zoneId]) {
              colorId = colorByZoneId[zoneId].colorId
              colorHex = colorByZoneId[zoneId].colorHex
              ;(gradientData.gradientType = colorByZoneId[zoneId].gradientType),
                (gradientData.gradientDirection =
                  colorByZoneId[zoneId].gradientDirection),
                (gradientData.gradientColors = colorByZoneId[zoneId]
                  .gradientColors
                  ? colorByZoneId[zoneId].gradientColors.map((r: any) => {
                      return {
                        colorId: r.colorId,
                        colorHex: r.colorHex,
                        colorPercentPosition: r.colorPercentPosition
                      }
                    })
                  : null)
            }

            // LOAD

            DesignRoot._COLOR_ZONE_SVG_RECORD.push({
              id: designTemplateColorId,
              colorId: colorId,
              zoneId: zoneId,
              colorHex: colorHex,
              ...gradientData
            })

            objects[i].set({
              id: 'SVG-' + zoneId + '-' + selected + '-' + i,
              // strokeWidth: 1,
              fill: colorHex,
              // stroke: colorHex,
              _USER_DATA: {
                hasChange: false,
                fullID: zoneId,
                colorZoneIndex: zoneId,
                resetFill: objects[i].fill
              },
              _OBJECT_TYPE: 'SVG_COLORZONE',
              perPixelTargetFind: true,
              selectable: false,
              hascontrols: false,
              hasBorders: false,
              objectCaching: objectCaching,
              dirty: true,
              hasRotatingPoint: false
            })

            if (gradientData.gradientColors) {
              objects[i]._USER_DATA.color =
                gradientData.gradientColors[0].colorHex
              objects[i]._USER_DATA.gradient = {
                gradientType: gradientData.gradientType,
                gradientDirection: gradientData.gradientDirection,
                colors: gradientData.gradientColors
              }

              let colorStops = {}
              gradientData.gradientColors.forEach((r: any) => {
                if (r.colorHex === 'transparent') {
                  colorStops[r.colorPercentPosition / 100] =
                    'rgba(255, 255, 255, 0)'
                } else {
                  colorStops[r.colorPercentPosition / 100] = r.colorHex
                }
              })

              objects[i].setGradient('fill', {
                type: gradientData.gradientType.toLowerCase(),
                ...DesignRoot.change_color.getGradientDirection(
                  gradientData.gradientDirection,
                  objects[i]
                ),
                colorStops: colorStops
              })
            }

            DesignRoot.canvas.add(objects[i])
          } // end if
          else if (objects[i].id.indexOf('LOGOZONE') !== -1) {
            objects[i].set({
              _OBJECT_TYPE: 'SVG_LOGOZONE',
              selectable: false,
              hascontrols: false,
              hasBorders: false,
              evented: false,
              objectCaching: objectCaching,
              dirty: true,
              hasRotatingPoint: false
            })

            DesignRoot.canvas.add(objects[i])
          } else if (objects[i].id.indexOf('VIEWZONE') !== -1) {
            logger.log('VIEWZONE')

            objects[i].set({
              _OBJECT_TYPE: 'SVG_VIEWZONE',
              selectable: false,
              hascontrols: false,
              hasBorders: false,
              evented: false,
              objectCaching: objectCaching,
              dirty: true,
              hasRotatingPoint: false
            })

            DesignRoot.canvas.add(objects[i])
          }
        }

        DesignRoot._COLOR_ZONE_SVG_RECORD = _uniqBy(
          DesignRoot._COLOR_ZONE_SVG_RECORD,
          'zoneId'
        )

        logger.log(
          'DesignRoot._COLOR_ZONE_SVG_RECORD',
          DesignRoot._COLOR_ZONE_SVG_RECORD
        )

        toggle_svg_logo()
        DesignRoot._TRIGGER_RENDER = true
        DesignRoot.fabric_function.reArrageItem()
        DesignRoot.Loading.activeLoading(false)
        DesignRoot.tools.renderComponent('DesignRoot')
        if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
          if (DesignRoot.MenuStep) {
            DesignRoot.MenuStep.setState({ update: true })
          }
        }
      },
      null,
      { crossOrigin: 'anonymous' }
    )
  } catch (e) {
    // statements
    logger.error('svg_control.load_svg_colorzone.error', e)
  }
}

export function clear_svg_colorzone() {
  try {
    // CHECK EXIST & REMOVE

    var allSVGColorZone = DesignRoot.fabric_function.getObjectByType(
      'SVG_COLORZONE'
    )

    logger.log('check exsit', allSVGColorZone)

    for (var i = 0; i < allSVGColorZone.length; i++) {
      DesignRoot.canvas.remove(allSVGColorZone[i])
    }

    var allSVGLogoZone = DesignRoot.fabric_function.getObjectByType(
      'SVG_LOGOZONE'
    )

    logger.log('check exsit', allSVGLogoZone)

    for (var i = 0; i < allSVGLogoZone.length; i++) {
      DesignRoot.canvas.remove(allSVGLogoZone[i])
    }

    var allSVGViewZone = DesignRoot.fabric_function.getObjectByType(
      'SVG_VIEWZONE'
    )

    logger.log('check exsit', allSVGViewZone)

    for (var i = 0; i < allSVGViewZone.length; i++) {
      DesignRoot.canvas.remove(allSVGViewZone[i])
    }

    DesignRoot._COLOR_ZONE_SVG_RECORD = []

    // CHECK EXIST & REMOVE
  } catch (e) {
    // statements
    logger.error('svg_control.clear_svg_colorzone.error', e)
  }
}

export function toggle_svg_logo() {
  try {
    var allSVGLogoZone = DesignRoot.fabric_function.getObjectByType(
      'SVG_LOGOZONE'
    )

    logger.log('toggle_svg_logo', allSVGLogoZone)

    for (var i = 0; i < allSVGLogoZone.length; i++) {
      allSVGLogoZone[i].visible = DesignRoot._LOGO_TOGGLE
    }

    DesignRoot._TRIGGER_RENDER = true
    DesignRoot.canvas.requestRenderAll()

    if (DesignRoot._CURRENT_LOGO_SRC !== null) {
      // CHANGE TO BASE64 FOR EXPORT REASON

      var image_url = DesignRoot.tools._wrapTimeToSrc(
        DesignRoot._CURRENT_LOGO_SRC
      )

      var load_overlay = new Image()
      load_overlay.crossOrigin = 'anonymous'
      load_overlay.onload = function() {
        logger.log(
          'CHECK OVER SIZE OVERLAY',
          load_overlay.width,
          load_overlay.height
        )
        if (load_overlay.width > 8000) {
          console.warn(
            'WARNING : OVER SIZE OVERLAY. THIS MIGHT BREAK THE APPLICATION OR REDUCE PERFORMANCE. PLEASE CONTACT ADMINISTRATOR.'
          )
        }

        var base64Img = DesignRoot.tools._urlToBase64(load_overlay)
        DesignRoot.fabric_function.addOverlay('template', base64Img, 0, 0)
        DesignRoot._TRIGGER_RENDER = true
      }
      load_overlay.src = image_url
    }

    DesignRoot.fabric_function.reArrageItem()
  } catch (e) {
    // statements
    logger.error('svg_control.toggle_svg_logo.error', e)
  }
}

// SVG COLOR ZONE 11/2019
