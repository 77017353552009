import React, {
  FunctionComponent,
  useState,
  useEffect,
  createContext,
  useContext
} from 'react'
import Component3D from './Component3D'
import Jimp from 'jimp'
import { colorHexToRgb } from '../tools/tools'
import {
  ProductDesignTemplate,
  ProductDesign,
  ZoneColor
} from '../../../gql/generated/index'
interface Props {
  designTemplate: ProductDesignTemplate
  productDesign: ProductDesign
  DesignRoot: any
}
interface ZoneColorExtended extends ZoneColor {
  designTemplateColorId: string
}

const autoDesign: FunctionComponent<Props> = props => {
  const { designTemplate, productDesign, DesignRoot } = props
  const [templateArray, setTemplateArray] = useState<Array<string>>([])
  const [base64Array, setBase64Array] = useState<Array<string>>([])
  const [templateColorRelations, setTemplateColorRelations] = useState<
    Array<any>
  >([])
  const [SVGArray, setSVGArray] = useState<Array<string>>([])
  const [templateSVGRelations, setTemplateSVGRelations] = useState<Array<any>>(
    []
  )
  const [templatePNGRelations, setTemplatePNGRelations] = useState<Array<any>>(
    []
  )

  DesignRoot.colorChangedArray = DesignRoot.colorChangedArray
    ? DesignRoot.colorChangedArray
    : []

  const parentProduct = productDesign.product

  const colorChangeEvent = (data: any) => {
    let originalZone = data.originalZone

    let changedIndex = DesignRoot.colorChangedArray.findIndex(
      (r: any) => r.zoneId == originalZone.id
    )

    if (changedIndex > -1) {
      DesignRoot.colorChangedArray[changedIndex].color = data.color
    } else {
      DesignRoot.colorChangedArray.push({
        zoneId: originalZone.id,
        color: data.color
      })
    }

    // SVG ColorZones
    templateSVGRelations.map(related => {
      const tmpIndex = templateArray.findIndex(
        (r: any) => r === related.childDesignTemplate.id
      )

      const doc = new DOMParser().parseFromString(
        SVGArray[tmpIndex],
        'image/svg+xml'
      )
      const docW = doc.documentElement.getAttribute('width')
      if (!docW) {
        const viewBox = doc.documentElement.getAttribute('viewBox')
        const [x, y, width, height] = viewBox.split(' ')
        doc.documentElement.setAttribute('width', width)
        doc.documentElement.setAttribute('height', height)
      }

      DesignRoot.colorChangedArray.forEach((item: any) => {
        related.relative.map((relatedMap: any) => {
          if (item.zoneId === relatedMap.parentZoneId) {
            let childZoneName = related.childDesignTemplate.colors.filter(
              (r: any) => r.id === relatedMap.childZoneId
            )[0].svgId
            const elements = doc.documentElement.querySelectorAll(
              `[id*=${childZoneName}]`
            )

            elements.forEach(pelem => {
              pelem.childNodes.forEach((elem: any) => {
                if (elem.tagName) {
                  elem.style.fill = item.color
                }
              })
            })
          }
        })
      })

      var s = new XMLSerializer().serializeToString(doc.documentElement)
      let base64String = 'data:image/svg+xml;base64,' + window.btoa(s)
      let newArr = [...base64Array]
      newArr[tmpIndex] = base64String
      setBase64Array(newArr)
    })

    // PNG ColorZones
    templatePNGRelations.map(related => {
      const tmpIndex = templateArray.findIndex(
        (r: any) => r === related.childDesignTemplate.id
      )

      let targetColors: Array<string> = []
      let replaceColors: Array<string> = []

      DesignRoot.colorChangedArray.forEach((item: any) => {
        related.relative.map((relatedMap: any) => {
          if (item.zoneId === relatedMap.parentZoneId) {
            let zoneColor = relatedMap.childDesignTemplate.colors.filter(
              (r: any) => r.id === relatedMap.childZoneId
            )[0].customColorHex

            targetColors.push(zoneColor)
            replaceColors.push(item.color)
          }
        })
      })

      Jimp.read(base64Array[tmpIndex]).then(async image => {
        const colorDistance = (c1, c2) =>
          Math.sqrt(
            Math.pow(c1.r - c2.r, 2) +
              Math.pow(c1.g - c2.g, 2) +
              Math.pow(c1.b - c2.b, 2)
          ) // Distance between two colors
        const threshold = 32 // Replace colors under this threshold. The smaller the number, the more specific it is.

        targetColors.forEach((color, index) => {
          let getTargetRgb = colorHexToRgb(color)
          let getReplaceRgb = colorHexToRgb(replaceColors[index])

          if (getTargetRgb && getReplaceRgb) {
            let [r1, g1, b1, a1] = getTargetRgb
            let targetRgbColor = { r: r1, g: g1, b: b1, a: 255 }

            let [r2, g2, b2, a2] = getReplaceRgb
            let replaceRgbColor = { r: r2, g: g2, b: b2, a: 255 }

            image.scan(
              0,
              0,
              image.bitmap.width,
              image.bitmap.height,
              (x, y, idx) => {
                const thisColor = {
                  r: image.bitmap.data[idx + 0],
                  g: image.bitmap.data[idx + 1],
                  b: image.bitmap.data[idx + 2],
                  a: image.bitmap.data[idx + 3]
                }

                if (colorDistance(targetRgbColor, thisColor) <= threshold) {
                  image.bitmap.data[idx + 0] = replaceRgbColor.r
                  image.bitmap.data[idx + 1] = replaceRgbColor.g
                  image.bitmap.data[idx + 2] = replaceRgbColor.b
                  image.bitmap.data[idx + 3] = replaceRgbColor.a
                }
              }
            )
          }
        })
        let base64String = await image.getBase64Async(Jimp.MIME_PNG)
        let newArr = [...base64Array]
        newArr[tmpIndex] = base64String
        setBase64Array(newArr)
      })
    })
  }

  useEffect(() => {
    const getDesignTemplate = parentProduct.designTemplates.filter(
      (r: any) =>
        r.id === designTemplate.templateId &&
        r.relatedTemplateColorZones.length > 0
    )[0]
    const templateColorRelations = getDesignTemplate
      ? getDesignTemplate.relatedTemplateColorZones
      : null
    if (templateColorRelations) {
      setTemplateColorRelations(templateColorRelations)

      let svgRelations = templateColorRelations.filter(
        (r: any) => r.childDesignTemplate.hasColorZoneFile
      )
      let relatedDesignTemplates: any = []
      svgRelations.map((svgR: any) => {
        let existingRelatedIndex = relatedDesignTemplates.findIndex(
          (r: any) =>
            r.childDesignTemplate &&
            r.childDesignTemplate.id === svgR.childDesignTemplate.id
        )
        if (existingRelatedIndex > -1) {
          relatedDesignTemplates[existingRelatedIndex].relative.push(svgR)
          relatedDesignTemplates[existingRelatedIndex].parents.push(
            svgR.parentZoneId
          )
        } else {
          relatedDesignTemplates.push({
            childDesignTemplate: svgR.childDesignTemplate,
            parents: [svgR.parentZoneId],
            relative: [svgR]
          })
        }
      })

      setTemplateSVGRelations(relatedDesignTemplates)

      let pngRelations = templateColorRelations.filter(
        (r: any) => !r.childDesignTemplate.hasColorZoneFile
      )
      relatedDesignTemplates = []
      pngRelations.map((pngR: any) => {
        let existingRelatedIndex = relatedDesignTemplates.findIndex(
          (r: any) =>
            r.childDesignTemplate &&
            r.childDesignTemplate.id === pngR.childDesignTemplate.id
        )
        if (existingRelatedIndex > -1) {
          relatedDesignTemplates[existingRelatedIndex].relative.push(pngR)
          relatedDesignTemplates[existingRelatedIndex].parents.push(
            pngR.parentZoneId
          )
        } else {
          relatedDesignTemplates.push({
            childDesignTemplate: pngR.childDesignTemplate,
            parents: [pngR.parentZoneId],
            relative: [pngR]
          })
        }
      })

      setTemplatePNGRelations(relatedDesignTemplates)

      templateColorRelations.map((related: any, key: number) => {
        let base64String = 'BLANK'
        try {
          if (related.childDesignTemplate.hasColorZoneFile) {
            // SVG colorzones
            let url =
              related.childDesignTemplate.colorZoneFileSrc +
              '?' +
              new Date().getTime()
            fetch(url)
              .then(r => r.text())
              .then(xmlString => {
                let doc = new DOMParser().parseFromString(
                  xmlString,
                  'image/svg+xml'
                )
                let docW = doc.documentElement.getAttribute('width')
                if (!docW) {
                  let viewBox = doc.documentElement.getAttribute('viewBox')
                  let [x, y, width, height] = viewBox.split(' ')
                  doc.documentElement.setAttribute('width', width)
                  doc.documentElement.setAttribute('height', height)
                }

                var s = new XMLSerializer().serializeToString(
                  doc.documentElement
                )
                base64String = 'data:image/svg+xml;base64,' + window.btoa(s)
                let tmpIndex = templateArray.findIndex(
                  (r: any) => r === related.childDesignTemplate.id
                )
                let newArr = [...base64Array]
                let newSVGArr = [...SVGArray]
                if (tmpIndex === -1) {
                  newArr.push(base64String)
                  newSVGArr.push(xmlString)

                  let newTemplateArr = [...templateArray]
                  newTemplateArr.push(related.childDesignTemplate.id)
                  setTemplateArray(newTemplateArr)
                } else {
                  newArr[tmpIndex] = base64String
                  newSVGArr[tmpIndex] = xmlString
                }

                setBase64Array(newArr)
                setSVGArray(newSVGArr)

                const colorData = DesignRoot.loadData.zoneColors.find(
                  (r: ZoneColorExtended) => {
                    return (
                      r.designTemplateColorId === related.parentZoneId ||
                      r.zoneId === related.parentZoneId
                    )
                  }
                )

                if (colorData) {
                  DesignRoot.onColorChange({
                    color: colorData.colorHex,
                    originalZone: { id: related.parentZoneId }
                  })
                } else {
                  console.error('Color not found')
                }
              })
          } else {
            // old colorzones
            let url = related.childDesignTemplate.templateFileSrc

            if (url) {
              Jimp.read(url)
                .then(async image => {
                  image.scaleToFit(512, 512)
                  let base64String = await image.getBase64Async(Jimp.MIME_PNG)
                  let tmpIndex = templateArray.findIndex(
                    (r: any) => r === related.childDesignTemplate.id
                  )
                  let newArr = [...base64Array]
                  if (tmpIndex === -1) {
                    newArr.push(base64String)

                    let newTemplateArr = [...templateArray]
                    newTemplateArr.push(related.childDesignTemplate.id)
                    setTemplateArray(newTemplateArr)
                  } else {
                    newArr[tmpIndex] = base64String
                  }
                  setBase64Array(newArr)
                  let newSVGArr = [...SVGArray]
                  newSVGArr.push('')
                  setSVGArray(newSVGArr)

                  let colorDataIndex = designTemplate.colorData.findIndex(
                    (r: any) => r.id === related.parentZoneId
                  )
                  DesignRoot.onColorChange({
                    color: DesignRoot.currentChangeColor[colorDataIndex],
                    originalZone: { id: related.parentZoneId }
                  })
                })
                .catch(err => {
                  console.log(err)
                })
            }
          }
        } catch (error) {
          console.error('Failed to autodesign', error)
        }
      })
    } else {
      setTemplateColorRelations([])
    }
  }, [designTemplate])

  useEffect(() => {
    DesignRoot.onColorChange = colorChangeEvent
  }, [templateSVGRelations, templatePNGRelations, SVGArray, base64Array])

  return (
    <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
      <div
        className="auto-design-three"
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'white'
        }}
      >
        {base64Array.length > 0 && templateColorRelations.length > 0 ? (
          <>
            {base64Array.map((base64Template, key) => {
              let related = templateColorRelations.filter(
                (r: any) => r.childDesignTemplate.id === templateArray[key]
              )[0]
              return (
                <Component3D
                  key={'c3d' + key}
                  OBJ={related.childProduct.objSrc}
                  MTL={related.childProduct.mtlSrc}
                  BASE64={base64Template} // Provide base64 or "BLANK" for make blank model
                  WIDTH={250}
                  HEIGHT={300}
                  style={{
                    padding: '5px',
                    border: '1px solid #ccc',
                    backgroundColor: '#f0f0f0'
                  }}
                />
              )
            })}
          </>
        ) : null}
      </div>
    </div>
  )
}

export default autoDesign
