import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
const uuidv4 = require('uuid/v4');

const ProductPackageDescription = ({
  classes,
  productPackage,
  productPackageId,
  updateProductPackage,
  defaultLanguage,
  languages
}) => {
  const descriptionId =
    productPackage.description && productPackage.description.id
      ? productPackage.description.id
      : uuidv4();
  return (
    <Grid container>
      {languages.map((language, index) => {
        const descriptionText = getTextFromLanguage(
          productPackage.description.text,
          language
        );
        return (
          <Grid
            container
            key={'style-descriptionText-' + index}
            direction="row"
            alignItems="flex-end"
            spacing={1}
            className={classes.container}
          >
            <Grid item xs={10}>
              <TextField
                dataCy={'ProductPackage-Description-' + language}
                label="Description"
                placeholder="Enter description"
                multiline={true}
                rowsMax="4"
                value={descriptionText}
                onChange={e => {
                  updateProductPackage({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      updateProductPackage: {
                        ...productPackage,
                        description: {
                          id: descriptionId,
                          text: [
                            ...languages.map((tempLanguage, index) => {
                              return {
                                langCode: tempLanguage,
                                content:
                                  tempLanguage === language
                                    ? e.target.value
                                    : getTextFromLanguage(
                                        productPackage.description.text,
                                        tempLanguage
                                      ),
                                __typename: 'TextContent'
                              };
                            })
                          ],
                          __typename: 'Text'
                        }
                      }
                    },
                    variables: {
                      id: productPackageId,
                      input: {
                        description: {
                          id: descriptionId,
                          text: [
                            ...languages.map((tempLanguage, index) => {
                              return {
                                langCode: tempLanguage,
                                content:
                                  tempLanguage === language
                                    ? e.target.value
                                    : getTextFromLanguage(
                                        productPackage.description.text,
                                        tempLanguage
                                      )
                              };
                            })
                          ]
                        }
                      }
                    }
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  height: 38,
                  width: '100%',
                  border: '1px solid #ddd',
                  borderRadius: '3px'
                }}
              >
                {language}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ProductPackageDescription;
