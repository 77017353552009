import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import _cloneDeep from 'lodash/cloneDeep';
const uuidv4 = require('uuid/v4');
const omitDeep = require('omit-deep');
const TeamstoreCategoryTitle = ({
  classes,
  languages,
  teamstore,
  teamstoreId,
  updateTeamstore,
  targetCategory
}) => {
  const titleId =
    targetCategory.title && targetCategory.title.id
      ? targetCategory.title.id
      : uuidv4();
  return (
    <Grid container>
      {languages.map((language, index) => {
        var title = '';
        try {
          title = getTextFromLanguage(targetCategory.title.text, language);
        } catch (error) {}
        return (
          <Grid
            container
            key={'style-title-' + index}
            direction="row"
            alignItems="flex-end"
            spacing={1}
            className={classes.container}
          >
            <Grid item xs={10}>
              <TextField
                label="Category Title"
                placeholder="Enter text"
                multiline={true}
                rowsMax="15"
                value={title}
                onChange={e => {
                  updateTeamstore({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      ...teamstore,
                      targetCategories: teamstore.targetCategories.map(
                        tempTargetCategory => {
                          return tempTargetCategory.id === targetCategory.id
                            ? {
                                ...tempTargetCategory,
                                title: {
                                  id: titleId,
                                  text: [
                                    ...languages.map((tempLanguage, index) => {
                                      return {
                                        langCode: tempLanguage,
                                        content:
                                          tempLanguage === language
                                            ? e.target.value
                                            : getTextFromLanguage(
                                                tempTargetCategory.title.text,
                                                tempLanguage
                                              ),
                                        __typename: 'TextContent'
                                      };
                                    })
                                  ],
                                  __typename: 'Text'
                                }
                              }
                            : tempTargetCategory;
                        }
                      )
                    },
                    variables: {
                      input: {
                        targetCategories: [
                          ..._cloneDeep(teamstore.targetCategories).map(tc =>
                            omitDeep(
                              tc.id === targetCategory.id
                                ? {
                                    ...tc,
                                    title: {
                                      id: titleId,
                                      text: [
                                        ...languages.map(
                                          (tempLanguage, index) => {
                                            return {
                                              langCode: tempLanguage,
                                              content:
                                                tempLanguage === language
                                                  ? e.target.value
                                                  : getTextFromLanguage(
                                                      tc.title.text,
                                                      tempLanguage
                                                    )
                                            };
                                          }
                                        )
                                      ]
                                    }
                                  }
                                : tc,
                              ['__typename']
                            )
                          )
                        ]
                      }
                    }
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  height: 38,
                  width: '100%',
                  border: '1px solid #ddd',
                  borderRadius: '3px'
                }}
              >
                <Typography variant="body1">{language}</Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TeamstoreCategoryTitle;
