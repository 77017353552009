import React from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Grid
} from '@material-ui/core';

const styles = () =>
  createStyles({
    fontSizeLink: {
      fontSize: 13
    }
  });

interface Props extends WithStyles<typeof styles> {
  urlPrefix?: string;
  currentLanguage?: string;
}

const TermsLink: React.FunctionComponent<Props> = props => {
  const { urlPrefix, classes } = props;

  return (
    <Grid container={true} alignItems="center" className={classes.fontSizeLink}>
      <p className={classes.fontSizeLink}>
        Use of this site means that you accept our{' '}
        <a
          style={{ color: '#356998' }}
          className={classes.fontSizeLink}
          href={
            process.env.GATSBY_BRIKL_SALESCHANNEL_ID ===
            '60679619-9db3-44e8-819d-f4979ab99e7e'
              ? 'https://www.vermarcsport.com/' + urlPrefix + 'terms'
              : '/' + urlPrefix + 'terms'
          }
        >
          terms and conditions
        </a>{' '}
        and{' '}
        <a
          style={{ color: '#356998' }}
          className={classes.fontSizeLink}
          href={
            process.env.GATSBY_BRIKL_SALESCHANNEL_ID ===
            '60679619-9db3-44e8-819d-f4979ab99e7e'
              ? 'https://www.vermarcsport.com/' + urlPrefix + 'privacy'
              : '/' + urlPrefix + 'privacy'
          }
        >
          privacy policy
        </a>
      </p>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(TermsLink);
