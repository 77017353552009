import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Radio,
  RadioGroup,
  FormControlLabel,
  createStyles,
  withStyles,
  WithStyles
} from '@material-ui/core'
import uuid from 'uuid'

import client from '../../../gql/admin-apollo-config'
import {
  ADMIN_UPDATE_SHOP_CONTENT_SETTINGS,
  CONTENT_CREATE_MENU_LOCATION
} from '../../../gql/admin-mutations'
import { ADMIN_GET_CONTENT_MENU } from '../../../gql/admin-queries'
import { removeKeysBeforeMutation } from '../../../../../helper/menuHelper'

const styles = () => createStyles({})

interface Props extends WithStyles<typeof styles> {
  data: any
}

const AddMenuContainer: React.FunctionComponent<Props> = props => {
  const { classes, data, teamstoreId, menus } = props
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [menuLocation, setMenuLocation] = useState(null)

  let previousMenus = menus || []
  const headersCount = previousMenus
    ? previousMenus.filter(o => o.menuLocation === 'HEADER').length
    : null
  const footersCount = previousMenus
    ? previousMenus.filter(o => o.menuLocation === 'FOOTER').length
    : null

  const handleSave = async (): Promise<any> => {
    const newItem = {
      contentMenu: {
        backgroundColor: '#FFFFFF',
        id: uuid.v4(),
        linkColor: '#000000',
        navigationLinks: [
          {
            collectionId: null,
            externalLinks: [
              {
                content: 'Home',
                langCode: 'en_UK'
              }
            ],
            id: uuid.v4(),
            linkTitle: {
              id: uuid.v4(),
              text: [
                {
                  content: 'Home',
                  langCode: 'en_UK'
                }
              ]
            },
            pageId: null,
            parentId: null,
            productId: null,
            sortOrder: 1
          }
        ]
      },
      menuLocation,
      sortOrder: 1,
      salesChannelId: teamstoreId || 'MYBRIKL'
    }

    newItem.sortOrder = previousMenus ? previousMenus.length + 1 : 1

    await client.mutate({
      mutation: CONTENT_CREATE_MENU_LOCATION,
      variables: {
        input: removeKeysBeforeMutation(newItem)
      },
      update: (cache, { data: { createContentMenuLocation } }) => {
        if (createContentMenuLocation && createContentMenuLocation.id) {
          window.location.reload()
        }
      }
    })

    handleClose()
  }

  const handleOpen = (): void => {
    setIsDialogOpen(true)
  }

  const handleClose = (): void => {
    setIsDialogOpen(false)
  }

  const handleChange = (event: any): void => {
    setMenuLocation(event.target.value)
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        color="secondary"
        disabled={headersCount >= 2 && footersCount >= 2}
      >
        Add Menu
      </Button>

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Menu</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <RadioGroup
              aria-label="Location"
              name="location"
              value={menuLocation}
              onChange={handleChange}
            >
              <FormControlLabel
                value="HEADER"
                control={<Radio />}
                label="Header"
                disabled={headersCount >= 2}
              />
              <FormControlLabel
                value="FOOTER"
                control={<Radio />}
                label="Footer"
                disabled={footersCount >= 2}
              />
            </RadioGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!menuLocation}
            onClick={handleSave}
            color="primary"
            autoFocus={true}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(AddMenuContainer)
