import React, { Component } from 'react'
import { Grid, Paper, Typography, Button } from '@material-ui/core'
import AddVariantOption from './AddVariantOption'
import DeleteIcon from '@material-ui/icons/Delete'
import Input from '../../../../common/Input'
import VariantOptionTitle from './VariantOptionTitle'
import _cloneDeep from 'lodash/cloneDeep'
const omitDeep = require('omit-deep')
class VariantOptions extends Component {
  render() {
    const {
      classes,
      languages,
      variantId,
      variant,
      defaultLanguage,
      updateVariant
    } = this.props
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid container>
          {variant.options
            ? variant.options
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((valueOption, index) => {
                  return (
                    <div
                      key={valueOption.id}
                      style={{
                        marginBottom: '30px'
                      }}
                    >
                      <Input
                        dataCy="Variant-Option-Value"
                        label="Value"
                        placeholder=""
                        value={valueOption.value}
                        onChange={e =>
                          updateVariant({
                            optimisticResponse: {
                              __typename: 'Mutation',
                              updateVariant: {
                                ...variant,
                                options: [
                                  ...variant.options.map(
                                    (tempVariantOption1, tempIndex1) => {
                                      console.log(
                                        'tempVariantOption1',
                                        tempVariantOption1
                                      )
                                      // return null
                                      return {
                                        ...tempVariantOption1,
                                        __typename: 'VariantOption',

                                        value:
                                          index === tempIndex1
                                            ? e.target.value
                                            : tempVariantOption1.value
                                      }
                                    }
                                  )
                                ]
                              }
                            },
                            variables: {
                              id: variantId,
                              input: {
                                options: [
                                  ...variant.options.map(
                                    (tempVariantOption, tempIndex) => {
                                      return {
                                        ...omitDeep(
                                          _cloneDeep(tempVariantOption),
                                          ['__typename']
                                        ),
                                        value:
                                          index === tempIndex
                                            ? e.target.value
                                            : tempVariantOption.value
                                      }
                                    }
                                  )
                                ]
                              }
                            }
                          })
                        }
                      />

                      <VariantOptionTitle
                        languages={languages}
                        classes={classes}
                        valueOption={valueOption}
                        updateVariant={updateVariant}
                        variant={variant}
                        variantId={variantId}
                      />
                      <br />
                      <Input
                        dataCy="Variant-Option-Sortorder"
                        label="Sort order"
                        placeholder=""
                        value={valueOption.sortOrder}
                        onChange={e =>
                          updateVariant({
                            optimisticResponse: {
                              __typename: 'Mutation',
                              updateVariant: {
                                ...variant,
                                options: [
                                  ...variant.options.map(
                                    (tempVariantOption1, tempIndex1) => {
                                      // return null
                                      return {
                                        ...tempVariantOption1,
                                        sortOrder:
                                          index === tempIndex1
                                            ? e.target.value
                                              ? parseFloat(e.target.value)
                                              : null
                                            : tempVariantOption1.sortOrder
                                      }
                                    }
                                  )
                                ]
                              }
                            },
                            variables: {
                              id: variantId,
                              input: {
                                options: [
                                  ...variant.options.map(
                                    (tempVariantOption, tempIndex) => {
                                      return {
                                        ...omitDeep(
                                          _cloneDeep(tempVariantOption),
                                          ['__typename']
                                        ),
                                        sortOrder:
                                          index === tempIndex
                                            ? e.target.value
                                              ? parseFloat(e.target.value)
                                              : null
                                            : tempVariantOption.sortOrder
                                      }
                                    }
                                  )
                                ]
                              }
                            }
                          })
                        }
                      />
                    </div>
                  )
                })
            : null}
        </Grid>
        <Grid item>
          <AddVariantOption
            defaultLanguage={defaultLanguage}
            updateVariant={updateVariant}
            variant={variant}
            variantId={variantId}
          />
        </Grid>
      </Grid>
    )
  }
}

export default VariantOptions
