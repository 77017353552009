import { API_STAGE } from '../../../../../brikl-config'
import * as tools from '../tools'
import { transformDataForSave, saveProductDesignRevisionData } from './save'

var DesignRoot = null

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot
}

export function save() {
  try {
    // statements
    logger.log(
      'save',
      DesignRoot.canvas,
      DesignRoot.currentChangeColor,
      DesignRoot.defaultZoneColor
    )
    logger.log(DesignRoot.productDataSet)

    var productConfig = DesignRoot.productDataSet.productConfig

    var mapColor = {}
    // DesignRoot.defaultZoneColor.forEach(function(element, index) {
    //   mapColor[element] = DesignRoot.currentChangeColor[index];
    // });

    var allItem = []
    DesignRoot.canvas.getObjects().forEach(function(object) {
      if (
        object._OBJECT_TYPE &&
        (object._OBJECT_TYPE === 'LOGO' ||
          object._OBJECT_TYPE === 'TEXT' ||
          object._OBJECT_TYPE === 'PATTERN')
      ) {
        if (object._OBJECT_TYPE === 'TEXT') {
          allItem.push({
            id: object.id,
            name: object.name,
            objectType: object._OBJECT_TYPE,
            canvasData: {
              top: object.top,
              left: object.left,
              width: object.width,
              height: object.height,
              scaleX: object.scaleX,
              scaleY: object.scaleY,
              angle: object.angle,
              flipX: object.flipX,
              flipY: object.flipY,
              lock: object.lockMovementX
            },
            userData: {
              content: object._USER_DATA.content,
              fontStyle: object._USER_DATA.fontStyle,
              lineSpacing: object._USER_DATA.lineSpacing,
              outlineColor: object._USER_DATA.outlineColor,
              outlineSize: object._USER_DATA.outlineSize,
              textColor: object._USER_DATA.textColor
            }
          })
        } else if (object._OBJECT_TYPE === 'LOGO') {
          if (
            DesignRoot._S3_LOGO_SRC[object.id] &&
            DesignRoot._S3_LOGO_SRC[object.id] !== undefined
          ) {
            var s3Url = DesignRoot._S3_LOGO_SRC[object.id]

            allItem.push({
              id: object.id,
              name: object.name,
              objectType: object._OBJECT_TYPE,
              canvasData: {
                top: object.top,
                left: object.left,
                width: object.width,
                height: object.height,
                scaleX: object.scaleX,
                scaleY: object.scaleY,
                angle: object.angle,
                flipX: object.flipX,
                flipY: object.flipY,
                lock: object.lockMovementX
              },
              userData: {
                s3Url: s3Url
              }
            })
          } // END IF _S3_LOGO_SRC
        } else if (object._OBJECT_TYPE === 'PATTERN') {
          if (
            DesignRoot._S3_LOGO_SRC[object.id] &&
            DesignRoot._S3_LOGO_SRC[object.id] !== undefined
          ) {
            var s3Url = DesignRoot._S3_LOGO_SRC[object.id]

            allItem.push({
              id: object.id,
              name: object.name,
              objectType: object._OBJECT_TYPE,
              canvasData: {
                top: object.top,
                left: object.left,
                width: object.width,
                height: object.height,
                scaleX: object.scaleX,
                scaleY: object.scaleY,
                angle: object.angle,
                flipX: object.flipX,
                flipY: object.flipY,
                lock: object.lockMovementX
              },
              userData: {
                s3Url: s3Url
              }
            })
          } // END IF _S3_LOGO_SRC
        }
      }
    })

    // DIRECT ELM CHANGE

    var directElmSave = {}
    for (
      var i = 0;
      i < DesignRoot.Main3D.storeMappingObjectNoTemplate.length;
      i++
    ) {
      if (
        DesignRoot.Main3D.storeMappingObjectNoTemplate[i].userData.hasChange ===
        true
      ) {
        var name = DesignRoot.Main3D.storeMappingObjectNoTemplate[i].name
        var color =
          DesignRoot.Main3D.storeMappingObjectNoTemplate[i].userData.color

        directElmSave[name] = color
        logger.log('DIRECT ELM SAVE', getColorId(name, color))
      }
    }

    // DIRECT ELM CHANGE

    // SVG & AOP & 2D SCENE CONFIG

    var svgSave = null
    var config2D = null

    if (DesignRoot._SVG_ACTIVE === true) {
      svgSave = {}
      var itemSET = DesignRoot.fabric_function.getObjectByType('SVG')
      logger.log('SVG', itemSET)

      for (var i = 0; i < itemSET.length; i++) {
        var svgData = itemSET[i]

        if (svgData._USER_DATA.hasChange === true) {
          if (
            svgData._USER_DATA.fillType === 'AOP' &&
            DesignRoot._S3_LOGO_SRC[svgData.id] &&
            DesignRoot._S3_LOGO_SRC[svgData.id] !== undefined
          ) {
            svgData._USER_DATA.aopSrc = DesignRoot._S3_LOGO_SRC[svgData.id]
          }

          svgSave[svgData.id] = svgData._USER_DATA
        }
      }
    }

    if (DesignRoot.isDashboard) {
      config2D = {
        // WILL HAVE PAN LATER
        _CANVAS_SIZE: DesignRoot._CANVAS_SIZE
      }
    }

    // SVG & AOP & 2D SCENE CONFIG

    var saveData = {
      id: productConfig.id,
      templateId: DesignRoot._ACTIVE_TEMPLATE_ID,
      mapColor: mapColor,
      allItem: allItem,
      directElm: directElmSave,
      logoActive: DesignRoot._LOGO_TOGGLE,
      seamActive: DesignRoot._SEAM_TOGGLE,
      SVG: svgSave,
      config2D: config2D,
      datetime: new Date().toLocaleString()
    }

    DesignRoot._SAVE_DATA = JSON.stringify(saveData, null, 4)

    logger.log('saveData', saveData, DesignRoot._S3_LOGO_SRC)
    logger.log(DesignRoot._SAVE_DATA)

    const designTemplate = DesignRoot.props.productDesign.product.designTemplates.find(
      o => o.id === DesignRoot._ACTIVE_TEMPLATE_ID
    )

    // OLD COLOR ZONE & NEW COLOR ZONE CONDITION

    logger.log('COLORZONE_SAVE')
    logger.log('_COLOR_ZONE_SVG_ACTIVE', DesignRoot._COLOR_ZONE_SVG_ACTIVE)
    logger.log('COLORZONE_OLD', DesignRoot.zoneColors)
    logger.log('COLORZONE_NEW', DesignRoot._COLOR_ZONE_SVG_RECORD)

    if (DesignRoot._COLOR_ZONE_SVG_ACTIVE === true) {
      var save_color_zone = DesignRoot._COLOR_ZONE_SVG_RECORD.length
        ? DesignRoot._COLOR_ZONE_SVG_RECORD
        : null
    } else {
      var save_color_zone = DesignRoot.zoneColors
    }

    // OLD COLOR ZONE & NEW COLOR ZONE CONDITION

    logger.log('SAVE PXTOCM', DesignRoot._PXTOCM)

    const input = transformDataForSave(
      DesignRoot._SAVE_DATA,
      save_color_zone,
      designTemplate,
      DesignRoot._PXTOCM
    )
    saveProductDesignRevisionData(
      DesignRoot.props.productDesign.id,
      1,
      input,
      DesignRoot.isDashboard
    )
  } catch (e) {
    // statements
    logger.error('template_control.save.error', e)
  }
}

export function getColorId(elmId, hex) {
  logger.log('getColorId', elmId, hex, DesignRoot.productDataSet.elements)

  var colorSET = getAllColorElement(elmId, DesignRoot.productDataSet.elements)
  var colorId = null

  for (var i = 0; i < colorSET.length; i++) {
    if (colorSET[i].hex.toLowerCase() === hex.toLowerCase()) {
      colorId = colorSET[i].id
    }
  }

  return colorId
}

export function getColorHex(elmId, colorId, elements) {
  logger.log('getColorHex', elmId, colorId, elements)

  var colorSET = getAllColorElement(elmId, elements)
  var colorHex = null

  for (var i = 0; i < colorSET.length; i++) {
    if (colorSET[i].id === colorId) {
      colorHex = colorSET[i].hex
    }
  }

  return colorHex
}

export function getAllColorElement(elmId, elements) {
  var colorSET = []

  if (elements) {
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].fullCode === elmId && elements[i].fabricCompositions) {
        for (var j = 0; j < elements[i].fabricCompositions.length; j++) {
          if (elements[i].fabricCompositions[j].colors) {
            for (
              var k = 0;
              k < elements[i].fabricCompositions[j].colors.length;
              k++
            ) {
              colorSET.push(elements[i].fabricCompositions[j].colors[k])
            }
          }
        }
      }
    } // END FOR
  }

  return colorSET
}
