import React from 'react'
import { Card, Grid, Typography } from '@material-ui/core'

const CartHeader: React.FC = () => {
  return (
    <Card style={{ padding: 20, marginBottom: 15 }}>
      <Grid
        container={true}
        style={{
          padding: '16px 0px',
          borderBottom: '1px solid #ccc'
        }}
      >
        <Grid item md={8} lg={8} xl={8}>
          <Typography variant="h6" component="span">
            Product
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Grid container justify="center">
            <Typography variant="h6" component="span">
              Quantity
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container justify="flex-end">
            <Typography variant="h6" component="span">
              Price
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Grid
          container
          style={{
            padding: '16px 0px 16px',
            borderBottom: '1px solid #ccc'
          }}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {'Your shopping basket is empty'}
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ padding: 20 }} container={true} direction="column">
        <Grid
          style={{ padding: '20px 0px 0px 0px' }}
          container={true}
          direction="row"
          justify="flex-end"
        >
          <Grid style={{ marginRight: 10, fontWeight: 'bold' }} item>
            <span>Shipping</span>
          </Grid>
          <Grid item>
            <span>0 $</span>
          </Grid>
        </Grid>
        <Grid
          container={true}
          style={{ padding: '20px 0px 0px 0px' }}
          direction="row"
          justify="flex-end"
        >
          <Grid style={{ marginRight: 10, fontWeight: 'bold' }} item>
            <span>Total</span>
          </Grid>
          <Grid item>
            <span>0 $</span>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CartHeader
