import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Notification from 'react-notification';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { GET_PAGE_CONTENT } from '../../gql/admin-queries';
import { ADMIN_UPDATE_CONTENT_PAGE } from '../../gql/admin-mutations';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import _sortBy from 'lodash/sortBy';
import Helmet from 'react-helmet';
import Checkbox from '@material-ui/core/Checkbox';
import _get from 'lodash/get';

import CircularProgress from '@material-ui/core/CircularProgress';
import { getTextFromLanguage } from '../../../../helper/languageHelper';

import PageDescription from './PageDescription';
import PageTitle from './PageTitle';
import PageMedia from './PageMedia';
import PageSlug from './PageSlug';
import PageTagAssignment from './PageTagAssignment';
import PageName from './PageName';
import { slugWithUrlPrefix } from '../content/utils';

const styles = theme => ({
  progress: {},
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    marginTop: '24px !important',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  },
  rootCheckbox: {
    padding: '8px',
    color: '#B23566',
    '&$checked': {
      color: '#B23566'
    }
  },
  checked: {},
  inputCell: {
    width: 230,
    border: 'none'
  }
});

class PageMetaData extends Component {
  state = {};

  render() {
    const {} = this.state;

    const {
      classes,
      pageId,
      languages,
      defaultLanguage,
      shopUrl,
      shopId
    } = this.props;

    return (
      <div className={classes.root}>
        <Typography>
          <Grid container>
            <Grid item xs={12}>
              <Query query={GET_PAGE_CONTENT} variables={{ id: pageId }}>
                {({ loading, error, data: { page } }) => {
                  if (loading)
                    return <CircularProgress className={classes.progress} />;
                  if (error) return <div>Error :(</div>;
                  if (!page) return null;
                  var contentPageTitle = '';
                  try {
                    contentPageTitle = getTextFromLanguage(
                      page.title.text,
                      defaultLanguage
                    );
                  } catch (error) {}
                  return (
                    <Mutation
                      update={(store, { data: { updateContentPage } }) => {
                        this.setState({
                          isActive: true,
                          message: 'Content page updated'
                        });
                      }}
                      mutation={ADMIN_UPDATE_CONTENT_PAGE}
                    >
                      {(updateContentPage, { data, loading, error }) => {
                        return (
                          <div>
                            <Helmet>
                              <title>{'Page - ' + contentPageTitle}</title>
                            </Helmet>
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              style={{ height: 60, marginBottom: 20 }}
                            >
                              <Typography
                                style={{
                                  fontSize: 28,
                                  fontWeight: 400,
                                  color: '#424242'
                                }}
                              >
                                {'Page - ' + contentPageTitle}
                              </Typography>

                              <div style={{ flex: 1 }} />
                              <Button
                                onClick={() => {
                                  window.open(
                                    `${shopUrl}/preview?type=page&id=${pageId}&language=${defaultLanguage}`
                                  );
                                }}
                              >
                                Preview page
                              </Button>
                              <Grid
                                container
                                style={{ width: 116 }}
                                alignItems="center"
                              >
                                <Checkbox
                                  type="checkbox"
                                  classes={{
                                    root: classes.rootCheckbox,
                                    checked: classes.checked
                                  }}
                                  checked={
                                    page.status === 'PUBLISHED' ? true : false
                                  }
                                  onChange={e => {
                                    updateContentPage({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateContentPage: {
                                          ...page,
                                          status: e.target.checked
                                            ? 'PUBLISHED'
                                            : 'DRAFT'
                                        }
                                      },
                                      variables: {
                                        id: pageId,
                                        input: {
                                          status: e.target.checked
                                            ? 'PUBLISHED'
                                            : 'DRAFT'
                                        }
                                      }
                                    });
                                  }}
                                />
                                PUBLISHED
                              </Grid>

                              <Button
                                style={{ marginLeft: 16 }}
                                variant="outlined"
                                color="primary"
                                href={'/admin/' + shopId + '/pagebuilder/pages'}
                              >
                                Back to pages
                              </Button>
                            </Grid>

                            <Grid container>
                              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      variant="subtitle1"
                                      style={{ marginRight: 16 }}
                                    >
                                      Page details
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="body1">
                                      Provide information on your page
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                <Paper
                                  style={{ padding: 24, borderRadius: 15 }}
                                  elevation={0}
                                >
                                  <PageName
                                    classes={classes}
                                    languages={languages}
                                    page={page}
                                    pageId={pageId}
                                    updateContentPage={updateContentPage}
                                  />
                                  <PageTitle
                                    classes={classes}
                                    languages={languages}
                                    page={page}
                                    pageId={pageId}
                                    updateContentPage={updateContentPage}
                                  />
                                  <PageSlug
                                    classes={classes}
                                    languages={languages}
                                    page={page}
                                    pageId={pageId}
                                    updateContentPage={updateContentPage}
                                  />
                                  <PageDescription
                                    classes={classes}
                                    languages={languages}
                                    page={page}
                                    pageId={pageId}
                                    updateContentPage={updateContentPage}
                                  />
                                  <PageTagAssignment
                                    shopId={shopId}
                                    defaultLanguage={defaultLanguage}
                                    classes={classes}
                                    page={page}
                                    pageId={pageId}
                                    updateContentPage={updateContentPage}
                                  />
                                </Paper>
                              </Grid>
                            </Grid>

                            {/* <PageMedia                              
                              updateContentPage={updateContentPage}
                              pageId={pageId}
                              page={page}
                              classes={classes}
                            />                           */}
                          </div>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Query>
            </Grid>
          </Grid>
        </Typography>
      </div>
    );
  }
}

PageMetaData.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PageMetaData);
