import React, { Component } from 'react'
import { Grid, Typography } from '@material-ui/core'
import Flag from '@material-ui/icons/Flag'
class ShippingCard extends Component {
  render() {
    const { countries, shippingFrom } = this.props
    if (shippingFrom === null) return null
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={5} sm={2} md={2} lg={2} xl={2}>
          <Grid
            container
            alignItems="center"
            justify="center"
            style={{
              width: 50,
              height: 50,
              borderRadius: '100%',
              background: '#B23566'
            }}
          >
            <Flag style={{ color: '#FFFFFF' }} />
          </Grid>
        </Grid>
        <Grid item xs={7} sm={9} md={10} lg={10} xl={10}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {shippingFrom && shippingFrom.countryCode
                  ? countries.find(c => c.code === shippingFrom.countryCode)
                    ? countries.find(c => c.code === shippingFrom.countryCode)
                        .name
                    : ''
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {shippingFrom.company}
                <br />
                {shippingFrom.address1}
                <br />
                {shippingFrom.city}, {shippingFrom.zip}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default ShippingCard
