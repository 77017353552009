import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Input from '../../../common/Input';

class PackageCard extends Component {
  render() {
    return (
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={5} sm={2} md={2} lg={2} xl={2}>
          <div
            style={{
              width: 50,
              height: 50,
              borderRadius: '100%',
              background: '#2f2f2f'
            }}
          />
        </Grid>
        <Grid item xs={7} sm={9} md={10} lg={10} xl={10}>
          <Grid container>
            <Grid item xs={12}>
              <Input label="Package name" placeholder="Sample Box" />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 24 }}>
              <Input label="Dimension" placeholder="35 x 26 x 5 cm, 0.0 kg" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default PackageCard;
