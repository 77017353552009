import React from 'react'
import { Select, MenuItem } from '@material-ui/core'

interface Props {
  languages: any
  selectedLanguage: string
  editing?: boolean | null | undefined
  handleSetLanguage: any
}

const SetLanguage: React.FC<Props> = props => {
  const { selectedLanguage, handleSetLanguage, languages, editing } = props
  return (
    <div
      style={
        !editing
          ? { width: '70px', position: 'relative', bottom: 42, left: 135 }
          : { width: '70px', position: 'relative', marginLeft: 20, top: 1 }
      }
    >
      <Select
        id="language-select"
        style={{
          textAlign: 'center',
          width: '80px',
          fontFamily: 'Poppins, sans-serif'
        }}
        value={selectedLanguage}
        onChange={e => handleSetLanguage(e.target.value)}
      >
        {languages.map((language: any, index: number) => (
          <MenuItem
            style={{ fontFamily: 'Poppins, sans-serif' }}
            key={index}
            value={language}
          >
            {language.split('_')[0].toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default SetLanguage
