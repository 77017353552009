import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query, Mutation } from 'react-apollo';
import {
  ADMIN_GET_LOGO_DESIGN_STEP,
  ADMIN_SHOP_PRINTING
} from '../../gql/admin-queries';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import { ADMIN_UPDATE_LOGO_DESIGN_STEP } from '../../gql/admin-mutations';
import _get from 'lodash/get';
class LogoStepSettings extends Component {
  state = {};
  render() {
    const classes = {};
    const { stepId, printingTechniques } = this.props;
    return (
      <div className={classes.root}>
        <Query query={ADMIN_GET_LOGO_DESIGN_STEP} variables={{ id: stepId }}>
          {({ loading, error, data: { logoDesignStep } }) => {
            if (loading) return <div>Loading...</div>;
            if (error) return <div>Error :(</div>;
            return (
              <div>
                <Mutation
                  mutation={ADMIN_UPDATE_LOGO_DESIGN_STEP}
                  update={(cache, { data: { updateLogoDesignStep } }) => {
                    if (updateLogoDesignStep.id) {
                      // const data = store.readQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: colorId }
                      // });
                      // data.product = updateColor;
                      // store.writeQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: colorId },
                      //   data
                      // });
                    }
                  }}
                >
                  {(updateLogoDesignStep, { data, loading, error }) => {
                    return (
                      <div>
                        <input
                          onChange={e => {
                            updateLogoDesignStep({
                              variables: {
                                id: stepId,
                                input: {
                                  stepSortOrder: parseInt(e.target.value, 10)
                                }
                              }
                            });
                          }}
                          placeholder="stepSortOrder"
                          type="text"
                          value={logoDesignStep.stepSortOrder}
                          id="internalCstepSortOrderode"
                        />
                        <br />
                        <input
                          onChange={e => {
                            updateLogoDesignStep({
                              variables: {
                                id: stepId,
                                input: {
                                  maxLogos: parseInt(e.target.value, 10)
                                }
                              }
                            });
                          }}
                          placeholder="maxLogos"
                          type="text"
                          value={logoDesignStep.maxLogos}
                          id="internalmaxLogos"
                        />
                        <br />
                        {logoDesignStep.logoPrintingTechniques.map(
                          (printingNode1, prIndex) => {
                            return (
                              <div key={'prt' + printingNode1.id}>
                                {printingNode1.id}{' '}
                                <button
                                  onClick={() => {
                                    updateLogoDesignStep({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateLogoDesignStep: {
                                          ...logoDesignStep,
                                          logoPrintingTechniqueIds: logoDesignStep.logoPrintingTechniqueIds.filter(
                                            tempPRT =>
                                              tempPRT !== printingNode1.id
                                          )
                                        }
                                      },
                                      variables: {
                                        id: stepId,
                                        input: {
                                          logoPrintingTechniqueIds: logoDesignStep.logoPrintingTechniqueIds.filter(
                                            tempPRT =>
                                              tempPRT !== printingNode1.id
                                          )
                                        }
                                      }
                                    });
                                  }}
                                >
                                  Delete
                                </button>
                                {printingNode1.id ===
                                logoDesignStep.defaultPrintingTechnique ? (
                                  'Default'
                                ) : (
                                  <button
                                    onClick={() => {
                                      updateLogoDesignStep({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateLogoDesignStep: {
                                            ...logoDesignStep,
                                            defaultPrintingTechnique:
                                              printingNode1.id
                                          }
                                        },
                                        variables: {
                                          id: stepId,
                                          input: {
                                            defaultPrintingTechnique:
                                              printingNode1.id
                                          }
                                        }
                                      });
                                    }}
                                  >
                                    Make default
                                  </button>
                                )}
                              </div>
                            );
                          }
                        )}
                        <div>
                          Printing techniques
                          {printingTechniques.map((printingNode, index) => {
                            const printingT = printingNode.node;
                            if (
                              logoDesignStep.logoPrintingTechniqueIds &&
                              logoDesignStep.logoPrintingTechniqueIds.includes(
                                printingT.id
                              )
                            ) {
                              return null;
                            } else {
                              return (
                                <div key={'print-' + printingT.id}>
                                  <button
                                    onClick={() => {
                                      updateLogoDesignStep({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateLogoDesignStep: {
                                            ...logoDesignStep,
                                            logoPrintingTechniqueIds: logoDesignStep.logoPrintingTechniqueIds
                                              ? [
                                                  ...logoDesignStep.logoPrintingTechniqueIds,
                                                  printingT.id
                                                ]
                                              : [printingT.id]
                                          }
                                        },
                                        variables: {
                                          id: stepId,
                                          input: {
                                            logoPrintingTechniqueIds: logoDesignStep.logoPrintingTechniqueIds
                                              ? [
                                                  ...logoDesignStep.logoPrintingTechniqueIds,
                                                  printingT.id
                                                ]
                                              : [printingT.id]
                                          }
                                        }
                                      });
                                    }}
                                  >
                                    Add{' '}
                                    {printingT.customName &&
                                    printingT.customName.text[0]
                                      ? printingT.customName.text[0].content
                                      : null}
                                  </button>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    );
                  }}
                </Mutation>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default LogoStepSettings;
