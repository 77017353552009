import React, { useState } from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  ListItem,
  Grid,
  InputLabel,
  List
} from '@material-ui/core'
import { Slider } from '@material-ui/core'
import { addPx } from '../../content/utils'

const styles = () =>
  createStyles({
    FormLabel: {
      fontSize: 18,
      marginBottom: 10
    },
    slider: {
      padding: '22px 0px'
    }
  })

interface Props extends WithStyles<typeof styles> {
  data: any
  onSave(id: string, contentDetail: any): void
}

const ModuleSettingsLogo: React.FunctionComponent<Props> = props => {
  const { classes, data, onSave } = props
  const [settings, setSettings] = useState(data.contentDetail)

  if (!settings.height) {
    setSettings({ ...settings, height: 30 })
  }

  const handleChangeField = (value: number, fieldName: string): void => {
    console.log(value, fieldName)
    setSettings({ ...settings, [fieldName]: value })
  }

  const handleSave = (): void => {
    if (!onSave) {
      return
    }
    console.log('save', data.id, settings)
    settings.height = addPx(settings.height)
    onSave(data.id, settings)
  }

  return (
    <List>
      <ListItem>
        <Grid container={true} direction="column">
          <InputLabel shrink={true} className={classes.FormLabel}>
            Height - {parseInt(settings.height, 10)} px
          </InputLabel>
          <Grid container={true} justify="space-between">
            <Slider
              className={classes.slider}
              value={parseInt(settings.height, 10)}
              aria-labelledby="slider-label"
              step={1}
              min={10}
              max={100}
              onChange={(e: any, value: number) =>
                handleChangeField(value, 'height')
              }
              onChangeCommitted={handleSave}
            />
          </Grid>
        </Grid>
      </ListItem>
    </List>
  )
}

export default withStyles(styles, { withTheme: true })(ModuleSettingsLogo)
