import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Notification from 'react-notification';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  ADMIN_SHOP_FABRICS,
  ADMIN_GET_PRODUCT,
  ADMIN_GET_ORG_BOARDPRODUCTS
} from '../../gql/admin-queries';
import {
  ADMIN_UPDATE_PRODUCT,
  ADMIN_UPLOAD_FILE
} from '../../gql/admin-mutations';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import _sortBy from 'lodash/sortBy';
import { API_STAGE } from '../../../../../brikl-config';
import { I18n } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

import _get from 'lodash/get';
import '../products/ProductFileDrop.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileDrop from 'react-file-drop';
const axios = require('axios');
const shortid = require('shortid');
const uuidv4 = require('uuid/v4');
var jwtDecode = require('jwt-decode');

class ShopLogo extends Component {
  state = {
    isActive: false,
    message: '',
    isBriklSuperAdmin: false,
    open: false,
    value: 'Yes'
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
  uploadShopLogo = async (files, uploadFile, shop, updateShop) => {
    try {
      const {} = this.props;
      this.setState({
        // uploading: files.length.toString()
        uploading: true
      });
      for (var i = 0; i < files.length; i++) {
        const myFile = files[i];
        if (myFile.size < 20000000) {
          const fileName =
            myFile.name.replace(/\.[^/.]+$/, '').replace(/\s/g, '-') +
            '-' +
            shortid.generate() +
            '.' +
            myFile.name.split('.').pop();
          const signedUrlResult = await uploadFile({
            variables: {
              type: 'SHOP_LOGO',
              fileName,
              input: {}
            }
          });
          const signedUrl = signedUrlResult.data.uploadFile.url;
          var options = {
            headers: {
              'Content-Type': signedUrlResult.data.uploadFile.contentType
            }
          };
          if (signedUrlResult.data.uploadFile.acl) {
            options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl;
          }
          if (signedUrlResult.data.uploadFile.tagging) {
            options.headers['x-amz-tagging'] =
              signedUrlResult.data.uploadFile.tagging;
          }
          const uploadResult = await axios.put(signedUrl, myFile, options);
          // refetch();
          this.setState({
            uploading: false
          });

          await updateShop({
            optimisticResponse: {
              __typename: 'Mutation',
              updateShop: {
                ...shop,
                brandSettings: {
                  ...shop.brandSettings,
                  logo: fileName
                }
              }
            },
            variables: {
              id: shop.id,
              input: {
                brandSettings: {
                  logo: fileName
                }
              }
            }
          });
        } else {
          this.setState({
            uploading: false
          });
          alert('Only files up to 20MB supported.');
        }
      }
    } catch (error) {
      console.error('uploaderror', error);
      this.setState({
        uploading: false
      });
    }
  };

  render() {
    const { blockEdit, classes, productId, updateShop, shop } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper style={{ padding: 24 }} elevation={0}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                style={{
                  padding: '0px !important',
                  marginLeft: '-8px'
                }}
              >
                {this.state.uploading === true ? <CircularProgress /> : null}

                <Mutation
                  update={(store, { data: { uploadFile } }) => {}}
                  mutation={ADMIN_UPLOAD_FILE}
                >
                  {uploadFile => (
                    <Grid
                      container
                      style={{
                        border: '1px dashed #E5E5E5',
                        background: '#f5f5f5',
                        padding: 0,
                        borderRadius: '5px'
                      }}
                    >
                      {/* {JSON.stringify(product)} */}
                      <input
                        style={{
                          display: 'none'
                        }}
                        accept=".png,.jpg,.jpeg"
                        id="productMediaUploadInput"
                        type="file"
                        onChange={e => {
                          this.uploadShopLogo(
                            e.target.files,
                            uploadFile,
                            shop,
                            updateShop
                          );
                        }}
                      />
                      <Grid
                        item
                        xs={12}
                        data-cy="product-media-drag-and-drop-container"
                        style={{
                          padding: '0'
                        }}
                      >
                        <FileDrop
                          onDrop={(files, event) => {
                            this.uploadShopLogo(
                              files,
                              uploadFile,
                              shop,
                              updateShop
                            );
                          }}
                        >
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="body1">
                                {I18n.get('Drag your file here ')}
                                <br />( .png or .jpg )
                              </Typography>
                            </Grid>
                          </Grid>
                        </FileDrop>
                      </Grid>
                    </Grid>
                  )}
                </Mutation>
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                style={{
                  // border: '1px solid #E5E5E5',
                  padding: '8px 8px 4px 8px',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Grid container alignItems="center" direction="row" spacing={1}>
                  {shop.brandSettings && shop.brandSettings.logo ? (
                    <div
                      style={{
                        height: 164,
                        width: 200,
                        padding: 16,
                        borderRadius: '6px',
                        border: '1px solid #ccc'
                      }}
                    >
                      <img
                        src={shop.brandSettings.logo}
                        alt="Storefront"
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'inline',
                          objectFit: 'contain'
                        }}
                      />
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default ShopLogo;
