import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query } from 'react-apollo';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ADMIN_SHOP_PRODUCT_PACKAGES } from '../../gql/admin-queries';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { navigate } from '@reach/router';
import ProductTable from '../UI/ProductTable';
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../../brikl-config';
import AddProductPackage from './AddProductPackage';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242'
  }
});

class ProductPackagesList extends Component {
  state = {
    filter: '',
    showActive: 'ACTIVE'
  };
  render() {
    const { classes, shopId, defaultLanguage } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Grid item style={{ marginRight: 16 }}>
            <Typography
              component="h1"
              className={classes.h1}
              data-cy="Header-Product-packages"
            >
              Product packages
            </Typography>
          </Grid>
          <div style={{ flex: 1 }} />
          <AddProductPackage shopId={shopId} />
        </Grid>
        <Query query={ADMIN_SHOP_PRODUCT_PACKAGES} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            if (loading)
              return <CircularProgress className={classes.progress} />;
            if (error) return <div>Error :(</div>;
            const filteredProducts = _get(shop, 'productPackages.edges', []);
            return (
              <>
                <ProductTable
                  productPackage={true}
                  filteredProducts={filteredProducts}
                  defaultLanguage={defaultLanguage}
                  shopId={shopId}
                />
              </>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withStyles(styles)(ProductPackagesList);
