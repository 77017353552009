import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    marginLeft: 20,
    fontSize: 30,
    padding: 25,
    fontWeight: 'medium',
    color: '#424242'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

class ReportCoupons extends Component {
  state = {};
  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.root}>
        <Grid
          container={true}
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Typography
            style={{
              fontSize: 28,
              fontWeight: 'medium',
              color: '#424242'
            }}
            data-cy="Header-Report-Home"
          >
            Report Home
          </Typography>
        </Grid>
        Coming soon
      </Grid>
    );
  }
}

export default withStyles(styles)(ReportCoupons);
