import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from '@reach/router';
import ButtonStyled from '../../Button';

const styles = theme => ({
  shops: {
    background: '#2f2f2f',
    height: 395,
    flex: 1,
    borderRadius: 0
  },
  container: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    textAlign: 'left'
  }
});

class Discovershop extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.state;
  }
  render() {
    const {
      classes,
      title,
      subTitle,
      buttonText,
      buttonUrl,
      disabled,
      urlPrefix
    } = this.props;
    return (
      <Grid item xs={12} className={classes.shops}>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.container}
          spacing={3}
        >
          <Grid item style={{ maxWidth: '400px', width: '100%', padding: 16 }}>
            <Typography
              variant="h4"
              component="h2"
              style={{
                color: '#fff',
                width: '100%',
                textAlign: 'left',
                textTransform: 'uppercase'
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item style={{ maxWidth: '400px', width: '100%', padding: 16 }}>
            <Typography
              variant="body1"
              component="p"
              style={{ color: '#fff', width: '100%', textAlign: 'left' }}
            >
              {subTitle}
            </Typography>
          </Grid>
          <Grid item style={{ maxWidth: '400px', width: '100%', padding: 16 }}>
            <Button
              disabled={disabled}
              component={Link}
              to={`${urlPrefix}premium-dealer`}
              style={{ color: 'white', border: '1px solid #fff' }}
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
Discovershop.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Discovershop);
