import React, { useState, useEffect, useRef } from 'react'

import { loadCSS } from 'fg-loadcss'

import { CirclePicker } from 'react-color'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Icon from '@material-ui/core/Icon'

import { colorHexToRgb } from '../tools/tools'

interface Props {
  colors: any[]
  enableGradient: Boolean
  handleChangeSVGElementColor(color: any): void
  DesignRoot: any
  selectionObjectId: string
}

const directionType = [
  'TOP',
  'TOPRIGHT',
  'RIGHT',
  'BOTTOMRIGHT',
  'BOTTOM',
  'BOTTOMLEFT',
  'LEFT',
  'TOPLEFT'
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gradientBox: {
      width: '80%',
      height: '40px',
      border: '1px solid #AAA',
      position: 'relative',
      backgroundImage:
        'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==")',
      backgroundRepeat: 'repeat',
      margin: '0 auto'
    },
    gradientBar: {
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    buttonPoint: {
      position: 'absolute',
      top: '-12px',
      cursor: 'pointer',
      lineHeight: '0 !important',
      textShadow: '1px 1px #A3A3A3'
    },
    buttonAdjust: {
      width: '100%',
      textAlign: 'center'
    }
  })
)

const FillStepCirclePicker: React.FunctionComponent<Props> = props => {
  const {
    handleChangeSVGElementColor,
    colors,
    enableGradient,
    DesignRoot,
    selectionObjectId
  } = props

  const classes = useStyles()

  const [colorSvg, setColorSvg] = useState<Array<string>>([''])
  const [mode, setMode] = useState<string>('color')
  const [buttonPoint, setButtonPoint] = useState<number>(0)
  const [colorLib, setColorLib] = useState<Array<any>>([''])
  const [colorsData, setColorsData] = useState<Array<any>>([])
  const [direction, setDirection] = useState<number>(0)

  const gradientBar = useRef(null)
  const gradientBox = useRef(null)

  const changeColorEvent = (e: any) => {
    let newColor = mode === 'color' ? [] : [...colorSvg]
    newColor[buttonPoint] = e.hex
    setColorSvg(newColor)
  }

  const rotateEvent = (e: any) => {
    let directionIndex =
      direction >= directionType.length - 1 ? 0 : direction + 1
    setDirection(directionIndex)
    let colorObj = {
      gradientType: 'LINEAR',
      gradientDirection: directionType[directionIndex],
      colors: colorsData
    }
    handleChangeSVGElementColor(colorObj)
  }

  useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
  }, [])

  useEffect(() => {
    if (selectionObjectId) {
      let userData = DesignRoot.Selection.state.selectionObject._USER_DATA
      if (
        enableGradient &&
        userData.gradient?.colors[0]?.colorHex !== '' &&
        userData.gradient?.colors[1]
      ) {
        // gradient mode
        const colorList = userData.gradient.colors.map((item: any) => {
          return item.colorHex
        })
        setColorSvg(colorList)
        setMode('gradient')
      } else {
        if (
          userData.gradient?.colors[0]?.colorHex &&
          userData.gradient.colors[0].colorHex !== ''
        ) {
          // color mode
          setColorSvg([userData.gradient.colors[0].colorHex])
        } else if (userData.color && userData.color !== '') {
          setColorSvg([userData.color])
        } else if (userData.resetFill && userData.resetFill !== '') {
          setColorSvg([userData.resetFill])
        } else if (DesignRoot.Selection.state.selectionObject.fill) {
          setColorSvg([DesignRoot.Selection.state.selectionObject.fill])
        } else {
          setColorSvg(['#ffffff'])
        }
        setMode('color')
      }
    }
  }, [selectionObjectId])

  useEffect(() => {
    if (mode === 'color') {
      setButtonPoint(0)
    }
  }, [mode])

  useEffect(() => {
    let newColors = [...colors]
    newColors.push('transparent')
    setColorLib(newColors)
  }, [colors])

  useEffect(() => {
    var colors: any[] = []
    const gradientRange = colorSvg.map((hex, index) => {
      const percent =
        colorSvg.length > 1 ? (index / (colorSvg.length - 1)) * 100 : 0

      colors.push({
        colorHex: hex,
        colorPercentPosition: percent
      })

      if (gradientBar && gradientBar.current) {
        gradientBar.current.getElementsByClassName('bt-point')[
          index
        ].style.color = hex
      }
      if (hex === 'transparent') {
        return `rgba(0,0,0,0) ${percent}%`
      }
      return `rgba(${colorHexToRgb(hex)?.join(',')}) ${percent}%`
    })

    setColorsData(colors)

    if (gradientBar && gradientBar.current) {
      let cssText = [
        `background: -moz-linear-gradient(left, ${gradientRange.join(',')});`,
        `background: -webkit-linear-gradient(left, ${gradientRange.join(
          ','
        )});`,
        `background: linear-gradient(to right, ${gradientRange.join(',')});`
      ].join('')
      gradientBar.current.style.cssText = cssText
    }

    let colorObj = {
      gradientType: 'LINEAR',
      gradientDirection: directionType[direction],
      colors: colors
    }
    handleChangeSVGElementColor(colorObj)
  }, [colorSvg])

  return (
    <>
      {enableGradient ? (
        <FormControl>
          <InputLabel htmlFor="color-mode">Mode</InputLabel>
          <Select
            value={mode}
            onChange={(e: any) => {
              setMode(e.target.value)
            }}
            inputProps={{
              name: 'mode',
              id: 'color-mode'
            }}
          >
            <MenuItem value={'color'}>color</MenuItem>
            <MenuItem value={'gradient'}>Gradient</MenuItem>
          </Select>
        </FormControl>
      ) : null}

      {mode == 'gradient' ? (
        <>
          <CirclePicker
            colors={colorLib}
            color={colorSvg[buttonPoint]}
            triangle="hide"
            onChange={changeColorEvent}
          />
          <div ref={gradientBox} className={classes.gradientBox}>
            <div ref={gradientBar} className={classes.gradientBar}>
              <Icon
                className={`fas fa-sort-down bt-point ${classes.buttonPoint}`}
                style={{ left: '-8px' }}
                onClick={e => {
                  setButtonPoint(0)
                }}
              ></Icon>
              <Icon
                className={`fas fa-sort-down bt-point ${classes.buttonPoint}`}
                style={{ left: 'calc(100% - 8px)' }}
                onClick={e => {
                  setButtonPoint(1)
                }}
              ></Icon>
            </div>
          </div>
          <div className={classes.buttonAdjust}>
            <Icon
              style={{ width: 'auto', cursor: 'pointer' }}
              className="fas fa-retweet"
              onClick={rotateEvent}
            ></Icon>
          </div>
        </>
      ) : (
        <CirclePicker
          colors={colors}
          color={colorSvg[0]}
          triangle="hide"
          onChange={changeColorEvent}
        />
      )}
    </>
  )
}

export default FillStepCirclePicker
