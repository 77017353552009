export const CONFIG = {
  showRichTextForVariants: ['p', 'h1', 'h2', 'h3', 'h4', 'h5']
}

export const PERMISSIONS = [
  'PRODUCT',
  'COLLECTION',
  'ORDER',
  'CONTENT',
  'CUSTOMER',
  'DESIGN',
  'SALESCHANNEL',
  'DISCOUNT',
  'SETTINGS',
  'REPORT'
]

export const PERMISSION_OPERATION = ['READ', 'CREATE', 'UPDATE', 'DELETE']

export const PERMISSION_SKIP_DELETE = ['ORDER']

export const IMAGE_FIT = ['cover', 'contain']

export const FIELD_NAMES = {
  firstName: 'First Name *',
  lastName: 'Last Name *',
  email: 'Email *',
  phone: 'Phone *',
  company: 'Company name',
  vatNumber: 'VAT No',
  address1: 'Street/Building *',
  zip: 'ZIP/Postcode *',
  city: 'City *',
  province: 'State/Province',
  countryCode: 'Country *'
}

export const FIELD_PLACEHOLDER = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  vatNumber: 'VAT No',
  company: 'Company name',
  address1: 'Street/Building',
  zip: 'ZIP/Postcode',
  city: 'City',
  province: 'State/Province',
  countryCode: 'Country'
}

export const FONTS = [
  'Roboto',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'BiomeStd-Light',
  'Nunito',
  'New Cycle',
  'Istok Web',
  'Exo2-Light',
  'Jaldi'
]

export const TEXT_TRANSFORM = ['none', 'uppercase', 'lowercase', 'capitalize']

export const FONT_WEIGHT = [
  { tag: 'light', label: 'light', value: 300 },
  { tag: 'regular', label: 'regular', value: 400 },
  { tag: 'bold', label: 'bold', value: 700 }
]

export const TYPO_VARIANTS = [
  { tag: 'h1', label: 'Heading 1', fontSize: '45' },
  { tag: 'h2', label: 'Heading 2', fontSize: '23' },
  { tag: 'h3', label: 'Heading 3', fontSize: '17' },
  { tag: 'h4', label: 'Heading 4', fontSize: '18' },
  { tag: 'h5', label: 'Heading 5', fontSize: '16' },
  { tag: 'p', label: 'Paragraph', fontSize: '14' }
]

export const BACKGROUND_SIZE = [
  'contain',
  'cover',
  'auto',
  'inherit',
  'initial',
  'unset'
]

export const BACKGROUND_REPEAT = [
  'inherit',
  'initial',
  'unset',
  'no-repeat',
  'repeat',
  'repeat-x',
  'repeat-y',
  'round',
  'space'
]

export const INPUT_TYPES = [
  'email',
  'phone',
  'password',
  'text',
  'card',
  'number',
  'date'
]

export const TEXT_ALIGN = ['left', 'center', 'right']

export const LINK_TYPE = ['EXTERNAL', 'INTERNAL']

export const BACKGROUND_IMAGE_VERTICAL_LOCATION = ['top', 'center', 'bottom']

export const COPYRIGHT_IMAGE_VERTICAL_LOCATION = ['top', 'middle', 'bottom']

export const COPYRIGHT_IMAGE_HORIZONTAL_LOCATION = ['left', 'center', 'right']

export const BORDER_TYPES = ['solid', 'dashed', 'dotted']

export const SOCIAL_MEDIA_TYPES = [
  'INSTAGRAM',
  'FACEBOOK',
  'TWITTER',
  'LINKEDIN'
]

export const MODULES = [
  { id: 1, type: 'SOCIAL_LINKS', label: 'Social Links' },
  { id: 2, type: 'SEARCH_INPUT', label: 'Search Input' },
  { id: 3, type: 'CART_COUNTER', label: 'Cart Counter' },
  { id: 4, type: 'LANGUAGE_SWITCH', label: 'Language Switch' },
  { id: 5, type: 'COUNTRY_SWITCH', label: 'Country Switch' },
  { id: 6, type: 'TERMS_LINK', label: 'Terms Link' },
  { id: 7, type: 'CONTACTS', label: 'Contacts' },
  { id: 8, type: 'SHOP_LOGO', label: 'Logo', options: { isLink: true } },
  {
    id: 9,
    type: 'SHOP_LOGO',
    label: 'Logo alternative',
    options: { isLink: true, isAlternative: true }
  },
  { id: 10, type: 'CURRENCY_SWITCH', label: 'Currency Switch' },
  { id: 11, type: 'TEAMSTORE_LOGO', label: 'Teamstore Logo' },
  { id: 12, type: 'PAYMENT_OPTIONS', label: 'Payment Options' }
]

export const REPORT_FIELDS = {
  REPORT_ORDERS: [
    'id',
    'appliedCoupons',
    'appliedDiscounts',
    'billingAddress',
    'hasCustomProduct',
    'langCode',
    'no',
    'notifyEmailTo',
    'paymentStatus',
    'paymentMethod',
    'ownedByOrganisationId',
    'salesChannelId',
    'salesChannelTitles',
    'shippingAddress',
    'shippingLabels',
    'shippingCost',
    'shippingMethodId',
    'status',
    'total',
    'total.currency',
    'userReferences'
  ],
  REPORT_ORDERS_DETAIL: [
    'id',
    'appliedCoupons',
    'appliedDiscounts',
    'orderType',
    'orderId',
    'productId',
    'productAccountingId',
    'productTitle',
    'productOptionValueTitle',
    'productOptionAccountingId',
    'productPackageId',
    'productPackageProductOptionValues',
    'customizationOptions',
    'salesChannelTitle',
    'salesChannelTargetCategoryId',
    'salesChannelTargetCategoryTitle',
    'salesChannelGroupId',
    'salesChannelGroupTitle',
    'total',
    'total.currency'
  ],
  REPORT_COUPONS: [
    'accountingId',
    'couponCode',
    'currentUsageOverall',
    'enabled',
    'maxUsageOverall',
    'maxUsagePerCustomer',
    'title'
  ],
  REPORT_CUSTOMERS: [
    'id',
    'accountingId',
    'lastOrderEmail',
    'lastOrderAtString',
    'lastBillingAddress',
    'lastShippingAddress',
    'numberOfOrders',
    'organisationType',
    'valueOfOrders',
    'vatNumber'
  ],
  REPORT_ORDERITEM: [
    'id',
    'appliedCoupons',
    'appliedDiscounts',
    'customizationOptions',
    'ownedByOrganisationId',
    'orderType',
    'orderId',
    'productId',
    'productAccountingId',
    'productTitle',
    'productOptionValueTitle',
    'productOptionAccountingId',
    'productPackageId',
    'productPackageProductOptionValues',
    'salesChannelTargetCategoryId',
    'salesChannelTargetCategoryTitle',
    'salesChannelGroupId',
    'salesChannelGroupTitle',
    'salesChannelId',
    'salesChannelTitle',
    'total',
    'total.currency',
    'total.taxPercent'
  ]
}
