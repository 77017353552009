import {Button, Tooltip} from '@material-ui/core'
import {Delete} from '@material-ui/icons'
import React from 'react'


interface Props {
  field: string,
  image?: string|null,
  handleDelete(value: string|null, field: string): void
}


const DeleteImage: React.FunctionComponent<Props> = (props) => {

  if (!props.image) {
    return null
  }

  const handleClick = () => {
    props.handleDelete(null, props.field)
  }

  return (
    <Tooltip title="Delete Image">
      <Button
        data-cy="Delete-Image-btn"
        onClick={handleClick}
        aria-label="Delete"
        color='primary'
        variant='contained'
        style={{borderRadius:'0px', width: '100%'}}
      >
        <Delete />
      </Button>
    </Tooltip>
  )
}

export default DeleteImage