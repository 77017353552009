import React, { Component } from 'react';

export default class FabricIcon extends React.Component {
  render() {
    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 106 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: '100%', height: '100%' }}
      >
        <path
          d="M88.1291 23.0415L18.7192 23.1747L18.8525 92.5846L88.2624 92.4514L88.1291 23.0415Z"
          fill="#C6C6C6"
        />
        <path
          d="M85.0918 2.59899L18.7609 23.0431L39.205 89.374L105.536 68.9299L85.0918 2.59899Z"
          fill="#4D4D4D"
        />
        <path
          d="M31.4019 32.49C33.1969 32.49 34.6519 31.0349 34.6519 29.24C34.6519 27.4451 33.1969 25.99 31.4019 25.99C29.607 25.99 28.1519 27.4451 28.1519 29.24C28.1519 31.0349 29.607 32.49 31.4019 32.49Z"
          fill="white"
        />
        <path
          d="M31.6819 29.06C33.9103 26.176 35.1257 22.6378 35.1399 18.9932C35.1541 15.3487 33.9663 11.8011 31.7604 8.89984C29.5545 5.9986 26.4537 3.90555 22.938 2.94482C19.4223 1.98408 15.6879 2.20924 12.3132 3.58545C8.93837 4.96166 6.11147 7.41213 4.27018 10.5574C2.42889 13.7027 1.67595 17.3673 2.12794 20.9837C2.57994 24.6002 4.21166 27.9667 6.77043 30.5621C9.3292 33.1574 12.6723 34.8367 16.2819 35.34"
          stroke="#939393"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="19"
          y="2"
          width="87"
          height="88"
        >
          <path
            d="M85.3726 2.59899L19.0417 23.0431L39.4858 89.374L105.817 68.9299L85.3726 2.59899Z"
            fill="#4D4D4D"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            d="M63.4027 60.72C63.3048 60.534 63.1509 60.3835 62.9627 60.29L25.7627 41.12C24.3535 40.4146 23.0142 39.5775 21.7627 38.62C20.9515 38.0001 20.2716 37.2251 19.7627 36.34C19.3753 35.6541 19.221 34.8611 19.3227 34.08C19.4621 33.2031 19.7531 32.3571 20.1827 31.58C20.5479 30.8428 21.0271 30.1678 21.6027 29.58C21.7827 29.4 21.9627 29.25 22.1427 29.09L15.7627 25.87C15.5127 26.16 15.2627 26.48 15.0127 26.82C13.2657 29.2244 12.1796 32.0449 11.8627 35C11.7322 36.7161 12.042 38.4371 12.7627 40C13.5718 41.6227 14.7253 43.0492 16.1427 44.18C17.8056 45.533 19.6093 46.7031 21.5227 47.67L59.4027 67.2C59.5944 67.2956 59.8117 67.3272 60.0227 67.29C60.2791 67.2294 60.5163 67.1057 60.7127 66.93C61.0156 66.6522 61.2811 66.3362 61.5027 65.99C61.8327 65.47 62.1427 64.99 62.4327 64.39C62.7197 63.8342 62.9768 63.2633 63.2027 62.68C63.3561 62.2981 63.4603 61.8982 63.5127 61.49C63.5518 61.2281 63.5135 60.9605 63.4027 60.72V60.72Z"
            fill="#939393"
          />
          <path
            d="M67.1327 52.4C67.0459 52.2595 66.9256 52.1427 66.7827 52.06L40.7627 38.62L26.7627 31.46C26.7627 31.7 26.6827 31.94 26.6727 32.17C26.5593 33.5454 26.8147 34.9262 27.4127 36.17C28.0389 37.4483 28.9445 38.5693 30.0627 39.45C31.3671 40.5119 32.7819 41.4305 34.2827 42.19L63.9827 57.49C64.1349 57.5633 64.306 57.5877 64.4727 57.56C64.6727 57.5118 64.858 57.4157 65.0127 57.28C65.2485 57.0593 65.4566 56.8108 65.6327 56.54C65.8927 56.13 66.1427 55.71 66.3627 55.28C66.5827 54.85 66.7927 54.4 66.9727 53.94C67.0923 53.6382 67.1729 53.3223 67.2127 53C67.2486 52.7966 67.2207 52.587 67.1327 52.4Z"
            fill="#939393"
          />
          <path
            d="M75.0927 90.07C74.9948 89.884 74.8409 89.7335 74.6527 89.64L37.4827 70.47C36.052 69.7753 34.6894 68.9483 33.4127 68C32.5756 67.3907 31.8742 66.6144 31.3527 65.72C30.9639 65.0347 30.8127 64.2401 30.9227 63.46C31.2422 61.7864 32.0332 60.239 33.2027 59C33.3827 58.81 33.5627 58.66 33.7427 58.49L27.4927 55.3C27.2189 55.6148 26.9683 55.949 26.7427 56.3C26.239 56.9705 25.7941 57.6831 25.4127 58.43C24.3809 60.279 23.7441 62.3221 23.5427 64.43C23.416 66.15 23.74 67.8734 24.4827 69.43C25.2941 71.054 26.4471 72.4833 27.8627 73.62C29.5248 74.938 31.3254 76.0714 33.2327 77L71.0927 96.52C71.2793 96.6201 71.4951 96.6519 71.7027 96.61C71.9614 96.5556 72.2001 96.431 72.3927 96.25C72.6977 95.978 72.9636 95.665 73.1827 95.32C73.5214 94.8005 73.8319 94.263 74.1127 93.71C74.4049 93.1552 74.6653 92.5843 74.8927 92C75.0461 91.6215 75.1503 91.225 75.2027 90.82C75.2322 90.5647 75.1943 90.3061 75.0927 90.07V90.07Z"
            fill="#939393"
          />
          <path
            d="M78.8127 81.74C78.7369 81.597 78.6179 81.4815 78.4727 81.41L50.7627 67.12L38.4127 60.79C38.363 61.0305 38.3329 61.2746 38.3227 61.52C38.2124 62.8942 38.4641 64.2733 39.0527 65.52C39.7111 66.7832 40.6357 67.8887 41.7627 68.76C43.0633 69.8243 44.4787 70.74 45.9827 71.49L75.7627 86.83C75.9095 86.9089 76.0794 86.9337 76.2427 86.9C76.4473 86.8562 76.6369 86.7597 76.7927 86.62C77.0258 86.3967 77.2337 86.1485 77.4127 85.88C77.6727 85.47 77.9227 85.05 78.1427 84.62C78.3722 84.1857 78.5759 83.7383 78.7527 83.28C78.8723 82.9816 78.953 82.669 78.9927 82.35C79.0007 82.1325 78.9375 81.9183 78.8127 81.74Z"
            fill="#939393"
          />
          <path
            d="M83.5327 118.3C83.4348 118.114 83.2809 117.964 83.0927 117.87L45.9227 98.7C44.4898 97.9937 43.127 97.1533 41.8527 96.19C41.0072 95.5853 40.2957 94.8125 39.7627 93.92C39.3754 93.2341 39.221 92.4411 39.3227 91.66C39.4622 90.7799 39.7531 89.9307 40.1827 89.15C40.5443 88.4106 41.0239 87.7351 41.6027 87.15L42.1127 86.68L35.8727 83.48C35.6327 83.77 35.3827 84.08 35.1427 84.4C33.4 86.7954 32.3016 89.5983 31.9527 92.54C31.826 94.26 32.15 95.9834 32.8927 97.54C33.6985 99.1648 34.8525 100.592 36.2727 101.72C37.9314 103.071 39.732 104.238 41.6427 105.2L79.4927 124.73C79.6855 124.822 79.9028 124.85 80.1127 124.81C80.3706 124.759 80.6095 124.638 80.8027 124.46C81.1056 124.182 81.3711 123.866 81.5927 123.52C81.9529 123.032 82.2867 122.524 82.5927 122C82.883 121.448 83.1433 120.88 83.3727 120.3C83.5165 119.915 83.6204 119.516 83.6827 119.11C83.7197 118.831 83.6671 118.547 83.5327 118.3V118.3Z"
            fill="#939393"
          />
          <path
            d="M87.2627 110C87.1832 109.854 87.0612 109.735 86.9127 109.66L65.4327 98.56L46.8627 89.06C46.8627 89.29 46.7927 89.52 46.7727 89.74C46.6612 91.1153 46.9166 92.4956 47.5127 93.74C48.147 95.0074 49.0516 96.1202 50.1627 97C51.4671 98.0619 52.8819 98.9805 54.3827 99.74L84.1227 115.07C84.2695 115.149 84.4394 115.174 84.6027 115.14C84.8051 115.098 84.992 115.001 85.1427 114.86C85.3835 114.648 85.5923 114.402 85.7627 114.13C86.0227 113.72 86.2727 113.3 86.4927 112.87C86.7204 112.431 86.924 111.981 87.1027 111.52C87.2209 111.22 87.3048 110.908 87.3527 110.59C87.3737 110.389 87.3428 110.186 87.2627 110V110Z"
            fill="#939393"
          />
          <path
            d="M54.5227 32.06L17.3427 12.89C15.9098 12.1838 14.547 11.3433 13.2727 10.38C12.4636 9.76187 11.784 8.99055 11.2727 8.11C10.8624 7.43176 10.6835 6.63868 10.7627 5.84999C10.9129 4.97263 11.2033 4.12513 11.6227 3.34C11.9843 2.60066 12.4639 1.92515 13.0427 1.34L13.6727 0.759995L7.42269 -2.44C7.14269 -2.12 6.86269 -1.78 6.58269 -1.44C6.08112 -0.764551 5.63627 -0.0487794 5.25269 0.699997C4.22411 2.54971 3.59074 4.59282 3.39269 6.7C3.26604 8.42004 3.59003 10.1434 4.33269 11.7C5.13853 13.3728 6.31089 14.8425 7.76269 16C9.42144 17.351 11.222 18.5178 13.1327 19.48L50.9627 39C51.1494 39.1001 51.3651 39.1319 51.5727 39.09C51.8307 39.0394 52.0695 38.9183 52.2627 38.74C52.5656 38.4622 52.8311 38.1462 53.0527 37.8C53.3936 37.2818 53.7042 36.7442 53.9827 36.19C54.273 35.6377 54.5333 35.0702 54.7627 34.49C54.9115 34.1066 55.0155 33.7073 55.0727 33.3C55.1118 33.0381 55.0735 32.7705 54.9627 32.53C54.8657 32.3333 54.7126 32.1698 54.5227 32.06V32.06Z"
            fill="#939393"
          />
          <path
            d="M58.6827 24.17C58.604 24.0267 58.486 23.9087 58.3427 23.83L29.1327 8.77001C28.3427 8.36001 27.6727 7.97001 27.0827 7.60001L18.2927 3.10001C18.2446 3.37805 18.2112 3.65844 18.1927 3.94001C18.0727 5.31469 18.3249 6.69632 18.9227 7.94001C19.5551 9.21993 20.4636 10.3436 21.5827 11.23C22.8768 12.2722 24.278 13.1739 25.7627 13.92L55.5427 29.25C55.6895 29.329 55.8594 29.3537 56.0227 29.32C56.2295 29.267 56.4169 29.156 56.5627 29C56.8034 28.7878 57.0123 28.5419 57.1827 28.27C57.4427 27.86 57.6927 27.44 57.9127 27.01C58.1327 26.58 58.3427 26.12 58.5327 25.66C58.643 25.3586 58.7234 25.0471 58.7727 24.73C58.7926 24.5387 58.7616 24.3455 58.6827 24.17V24.17Z"
            fill="#939393"
          />
          <path
            d="M63.4027 60.72C63.3048 60.534 63.1509 60.3835 62.9627 60.29L25.7627 41.12C24.3535 40.4146 23.0142 39.5775 21.7627 38.62C20.9515 38.0001 20.2716 37.2251 19.7627 36.34C19.3753 35.6541 19.221 34.8611 19.3227 34.08C19.4621 33.2031 19.7531 32.3571 20.1827 31.58C20.5479 30.8428 21.0271 30.1678 21.6027 29.58C21.7827 29.4 21.9627 29.25 22.1427 29.09L15.7627 25.87C15.5127 26.16 15.2627 26.48 15.0127 26.82C13.2657 29.2244 12.1796 32.0449 11.8627 35C11.7322 36.7161 12.042 38.4371 12.7627 40C13.5718 41.6227 14.7253 43.0492 16.1427 44.18C17.8056 45.533 19.6093 46.7031 21.5227 47.67L59.4027 67.2C59.5944 67.2956 59.8117 67.3272 60.0227 67.29C60.2791 67.2294 60.5163 67.1057 60.7127 66.93C61.0156 66.6522 61.2811 66.3362 61.5027 65.99C61.8327 65.47 62.1427 64.99 62.4327 64.39C62.7197 63.8342 62.9768 63.2633 63.2027 62.68C63.3561 62.2981 63.4603 61.8982 63.5127 61.49C63.5518 61.2281 63.5135 60.9605 63.4027 60.72V60.72Z"
            fill="white"
          />
          <path
            d="M67.1327 52.4C67.0459 52.2595 66.9256 52.1427 66.7827 52.06L40.7627 38.62L26.7627 31.46C26.7627 31.7 26.6827 31.94 26.6727 32.17C26.5593 33.5454 26.8147 34.9262 27.4127 36.17C28.0389 37.4483 28.9445 38.5693 30.0627 39.45C31.3671 40.5119 32.7819 41.4305 34.2827 42.19L63.9827 57.49C64.1349 57.5633 64.306 57.5877 64.4727 57.56C64.6727 57.5118 64.858 57.4157 65.0127 57.28C65.2485 57.0593 65.4566 56.8108 65.6327 56.54C65.8927 56.13 66.1427 55.71 66.3627 55.28C66.5827 54.85 66.7927 54.4 66.9727 53.94C67.0923 53.6382 67.1729 53.3223 67.2127 53C67.2486 52.7966 67.2207 52.587 67.1327 52.4Z"
            fill="#939393"
          />
          <path
            d="M75.0927 90.07C74.9948 89.884 74.8409 89.7335 74.6527 89.64L37.4827 70.47C36.052 69.7753 34.6894 68.9483 33.4127 68C32.5756 67.3907 31.8742 66.6144 31.3527 65.72C30.9639 65.0347 30.8127 64.2401 30.9227 63.46C31.2422 61.7864 32.0332 60.239 33.2027 59C33.3827 58.81 33.5627 58.66 33.7427 58.49L27.4927 55.3C27.2189 55.6148 26.9683 55.949 26.7427 56.3C26.239 56.9705 25.7941 57.6831 25.4127 58.43C24.3809 60.279 23.7441 62.3221 23.5427 64.43C23.416 66.15 23.74 67.8734 24.4827 69.43C25.2941 71.054 26.4471 72.4833 27.8627 73.62C29.5248 74.938 31.3254 76.0714 33.2327 77L71.0927 96.52C71.2793 96.6201 71.4951 96.6519 71.7027 96.61C71.9614 96.5556 72.2001 96.431 72.3927 96.25C72.6977 95.978 72.9636 95.665 73.1827 95.32C73.5214 94.8005 73.8319 94.263 74.1127 93.71C74.4049 93.1552 74.6653 92.5843 74.8927 92C75.0461 91.6215 75.1503 91.225 75.2027 90.82C75.2322 90.5647 75.1943 90.3061 75.0927 90.07V90.07Z"
            fill="white"
          />
          <path
            d="M78.8127 81.74C78.7369 81.597 78.6179 81.4815 78.4727 81.41L50.7627 67.12L38.4127 60.79C38.363 61.0305 38.3329 61.2746 38.3227 61.52C38.2124 62.8942 38.4641 64.2733 39.0527 65.52C39.7111 66.7832 40.6357 67.8887 41.7627 68.76C43.0633 69.8243 44.4787 70.74 45.9827 71.49L75.7627 86.83C75.9095 86.9089 76.0794 86.9337 76.2427 86.9C76.4473 86.8562 76.6369 86.7597 76.7927 86.62C77.0258 86.3967 77.2337 86.1485 77.4127 85.88C77.6727 85.47 77.9227 85.05 78.1427 84.62C78.3722 84.1857 78.5759 83.7383 78.7527 83.28C78.8723 82.9816 78.953 82.669 78.9927 82.35C79.0007 82.1325 78.9375 81.9183 78.8127 81.74Z"
            fill="#939393"
          />
          <path
            d="M83.5327 118.3C83.4348 118.114 83.2809 117.964 83.0927 117.87L45.9227 98.7C44.4898 97.9937 43.127 97.1533 41.8527 96.19C41.0072 95.5853 40.2957 94.8125 39.7627 93.92C39.3754 93.2341 39.221 92.4411 39.3227 91.66C39.4622 90.7799 39.7531 89.9307 40.1827 89.15C40.5443 88.4106 41.0239 87.7351 41.6027 87.15L42.1127 86.68L35.8727 83.48C35.6327 83.77 35.3827 84.08 35.1427 84.4C33.4 86.7954 32.3016 89.5983 31.9527 92.54C31.826 94.26 32.15 95.9834 32.8927 97.54C33.6985 99.1648 34.8525 100.592 36.2727 101.72C37.9314 103.071 39.732 104.238 41.6427 105.2L79.4927 124.73C79.6855 124.822 79.9028 124.85 80.1127 124.81C80.3706 124.759 80.6095 124.638 80.8027 124.46C81.1056 124.182 81.3711 123.866 81.5927 123.52C81.9529 123.032 82.2867 122.524 82.5927 122C82.883 121.448 83.1433 120.88 83.3727 120.3C83.5165 119.915 83.6204 119.516 83.6827 119.11C83.7197 118.831 83.6671 118.547 83.5327 118.3V118.3Z"
            fill="#939393"
          />
          <path
            d="M87.2627 110C87.1832 109.854 87.0612 109.735 86.9127 109.66L65.4327 98.56L46.8627 89.06C46.8627 89.29 46.7927 89.52 46.7727 89.74C46.6612 91.1153 46.9166 92.4956 47.5127 93.74C48.147 95.0074 49.0516 96.1202 50.1627 97C51.4671 98.0619 52.8819 98.9805 54.3827 99.74L84.1227 115.07C84.2695 115.149 84.4394 115.174 84.6027 115.14C84.8051 115.098 84.992 115.001 85.1427 114.86C85.3835 114.648 85.5923 114.402 85.7627 114.13C86.0227 113.72 86.2727 113.3 86.4927 112.87C86.7204 112.431 86.924 111.981 87.1027 111.52C87.2209 111.22 87.3048 110.908 87.3527 110.59C87.3737 110.389 87.3428 110.186 87.2627 110V110Z"
            fill="#939393"
          />
          <path
            d="M54.5227 32.06L17.3427 12.89C15.9098 12.1838 14.547 11.3433 13.2727 10.38C12.4636 9.76187 11.784 8.99055 11.2727 8.11C10.8624 7.43176 10.6835 6.63868 10.7627 5.84999C10.9129 4.97263 11.2033 4.12513 11.6227 3.34C11.9843 2.60066 12.4639 1.92515 13.0427 1.34L13.6727 0.759995L7.42269 -2.44C7.14269 -2.12 6.86269 -1.78 6.58269 -1.44C6.08112 -0.764551 5.63627 -0.0487794 5.25269 0.699997C4.22411 2.54971 3.59074 4.59282 3.39269 6.7C3.26604 8.42004 3.59003 10.1434 4.33269 11.7C5.13853 13.3728 6.31089 14.8425 7.76269 16C9.42144 17.351 11.222 18.5178 13.1327 19.48L50.9627 39C51.1494 39.1001 51.3651 39.1319 51.5727 39.09C51.8307 39.0394 52.0695 38.9183 52.2627 38.74C52.5656 38.4622 52.8311 38.1462 53.0527 37.8C53.3936 37.2818 53.7042 36.7442 53.9827 36.19C54.273 35.6377 54.5333 35.0702 54.7627 34.49C54.9115 34.1066 55.0155 33.7073 55.0727 33.3C55.1118 33.0381 55.0735 32.7705 54.9627 32.53C54.8657 32.3333 54.7126 32.1698 54.5227 32.06V32.06Z"
            fill="white"
          />
          <path
            d="M58.6827 24.17C58.604 24.0267 58.486 23.9087 58.3427 23.83L29.1327 8.77001C28.3427 8.36001 27.6727 7.97001 27.0827 7.60001L18.2927 3.10001C18.2446 3.37805 18.2112 3.65844 18.1927 3.94001C18.0727 5.31469 18.3249 6.69632 18.9227 7.94001C19.5551 9.21993 20.4636 10.3436 21.5827 11.23C22.8768 12.2722 24.278 13.1739 25.7627 13.92L55.5427 29.25C55.6895 29.329 55.8594 29.3537 56.0227 29.32C56.2295 29.267 56.4169 29.156 56.5627 29C56.8034 28.7878 57.0123 28.5419 57.1827 28.27C57.4427 27.86 57.6927 27.44 57.9127 27.01C58.1327 26.58 58.3427 26.12 58.5327 25.66C58.643 25.3586 58.7234 25.0471 58.7727 24.73C58.7926 24.5387 58.7616 24.3455 58.6827 24.17V24.17Z"
            fill="#939393"
          />
          <path
            d="M65.8927 46.18C65.8616 45.9722 65.8929 45.7599 65.9827 45.57L84.1227 7.87999C84.8313 6.45022 85.4135 4.96121 85.8627 3.42999C86.1488 2.45739 86.2372 1.43732 86.1227 0.429991C86.013 -0.347291 85.6634 -1.07095 85.1227 -1.64001C84.4798 -2.25136 83.7397 -2.75152 82.9327 -3.12001C82.1919 -3.48765 81.3995 -3.74054 80.5827 -3.87001C80.3372 -3.85805 80.0914 -3.88162 79.8527 -3.94001L82.9127 -10.26C83.2927 -10.17 83.6827 -10.07 84.0927 -9.95001C84.8972 -9.71827 85.6802 -9.41714 86.4327 -9.05001C88.3751 -8.1854 90.1181 -6.9292 91.5527 -5.36001C92.697 -4.05687 93.4827 -2.47855 93.8327 -0.780008C94.1631 1.00519 94.1017 2.84087 93.6527 4.59999C93.1341 6.67095 92.4001 8.68192 91.4627 10.6L72.9827 48.94C72.8865 49.1297 72.7324 49.2838 72.5427 49.38C72.3054 49.4905 72.0438 49.5387 71.7827 49.52C71.3716 49.4826 70.9678 49.3884 70.5827 49.24C69.9994 49.0328 69.4285 48.7924 68.8727 48.52C68.1927 48.19 67.6427 47.89 67.2227 47.63C66.8561 47.4197 66.5197 47.1606 66.2227 46.86C66.0467 46.6717 65.9317 46.4347 65.8927 46.18V46.18Z"
            fill="white"
          />
          <path
            d="M57.8727 41.65C57.8406 41.4867 57.8653 41.3173 57.9427 41.17L70.8127 14.81L77.8127 0.709991C77.9927 0.889991 78.1627 1.05999 78.3127 1.22999C79.204 2.26196 79.8167 3.50463 80.0927 4.83999C80.3417 6.24576 80.2837 7.68874 79.9227 9.06999C79.5072 10.6973 78.9206 12.2761 78.1727 13.78L63.4327 43.85C63.356 43.9983 63.2331 44.1176 63.0827 44.19C62.8954 44.2771 62.6881 44.3116 62.4827 44.29C62.1603 44.263 61.8436 44.1889 61.5427 44.07C61.0834 43.911 60.6357 43.7206 60.2027 43.5C59.7579 43.2912 59.327 43.054 58.9127 42.79C58.6349 42.6234 58.3824 42.418 58.1627 42.18C58.0137 42.0375 57.9124 41.8523 57.8727 41.65V41.65Z"
            fill="#939393"
          />
          <path
            d="M73.9927 76.61C73.9616 76.4022 73.9929 76.1899 74.0827 76L92.4427 38.42C93.1636 37.0037 93.7527 35.5241 94.2027 34C94.5035 33.0097 94.6021 31.9691 94.4927 30.94C94.383 30.1627 94.0334 29.4391 93.4927 28.87C92.847 28.2596 92.1076 27.7565 91.3027 27.38C90.5681 27.0018 89.7784 26.7419 88.9627 26.61L88.2327 26.54L91.3227 20.24C91.7127 20.33 92.1027 20.43 92.5027 20.55C93.3062 20.793 94.0888 21.1007 94.8427 21.47C96.7819 22.3435 98.5186 23.6103 99.9427 25.19C101.076 26.5005 101.851 28.0816 102.193 29.78C102.513 31.5674 102.442 33.4031 101.983 35.16C101.45 37.2322 100.703 39.2431 99.7527 41.16L81.0627 79.41C80.9666 79.5997 80.8124 79.7538 80.6227 79.85C80.3861 79.9621 80.1232 80.0071 79.8627 79.98C79.4517 79.9426 79.0478 79.8484 78.6627 79.7C77.5 79.2528 76.379 78.704 75.3127 78.06C74.9444 77.8522 74.6077 77.593 74.3127 77.29C74.1434 77.0985 74.0323 76.8625 73.9927 76.61Z"
            fill="white"
          />
          <path
            d="M66.2427 72.24C66.2159 72.0799 66.2404 71.9154 66.3127 71.77L79.8227 43.69L85.8827 31.19C86.0626 31.3533 86.2329 31.5269 86.3927 31.71C87.2917 32.7343 87.9086 33.975 88.1827 35.31C88.4464 36.7099 88.3986 38.1507 88.0427 39.53C87.6359 41.1624 87.0595 42.7477 86.3227 44.26L71.8127 74.41C71.7435 74.5627 71.6233 74.6865 71.4727 74.76C71.284 74.8422 71.0779 74.8766 70.8727 74.86C70.5517 74.8305 70.2357 74.7599 69.9327 74.65C69.4727 74.4833 69.0227 74.2933 68.5827 74.08C68.1381 73.8746 67.7072 73.6408 67.2927 73.38C67.016 73.2115 66.7608 73.01 66.5327 72.78C66.3819 72.6348 66.2804 72.4459 66.2427 72.24Z"
            fill="#939393"
          />
          <path
            d="M84.0827 104.3C84.0553 104.091 84.0901 103.879 84.1827 103.69L102.533 66.1C103.261 64.679 103.854 63.1924 104.303 61.66C104.595 60.6887 104.69 59.6687 104.583 58.66C104.473 57.8827 104.123 57.1591 103.583 56.59C102.315 55.4265 100.745 54.6433 99.0527 54.33L98.3627 54.26L101.453 48C101.813 48.08 102.193 48.18 102.593 48.3C105.432 49.173 107.995 50.7684 110.033 52.93C111.166 54.2443 111.941 55.8287 112.283 57.53C112.603 59.314 112.532 61.1465 112.073 62.9C111.542 64.9727 110.795 66.9838 109.843 68.9L91.1627 107.1C91.0665 107.29 90.9124 107.444 90.7227 107.54C90.4816 107.649 90.2155 107.691 89.9527 107.66C89.5449 107.623 89.1443 107.528 88.7627 107.38C88.1815 107.172 87.6138 106.928 87.0627 106.65C86.4959 106.382 85.945 106.081 85.4127 105.75C85.0461 105.54 84.7097 105.281 84.4127 104.98C84.2406 104.789 84.1262 104.553 84.0827 104.3V104.3Z"
            fill="#939393"
          />
          <path
            d="M76.1227 99.85C76.1042 99.6857 76.1319 99.5195 76.2027 99.37L86.8127 77.65L96.0227 58.94C96.1827 59.1 96.3527 59.26 96.4927 59.43C97.3871 60.4458 98.0037 61.6756 98.2827 63C98.5349 64.405 98.4804 65.848 98.1227 67.23C97.7119 68.8781 97.1252 70.4773 96.3727 72L81.6827 102.05C81.613 102.2 81.4926 102.32 81.3427 102.39C81.1554 102.477 80.948 102.512 80.7427 102.49C80.4203 102.463 80.1036 102.389 79.8027 102.27C79.3434 102.111 78.8957 101.921 78.4627 101.7C78.0179 101.491 77.587 101.254 77.1727 100.99C76.8971 100.82 76.645 100.615 76.4227 100.38C76.27 100.239 76.1651 100.054 76.1227 99.85Z"
            fill="#939393"
          />
          <path
            d="M55.8327 17.8L74.1827 -19.78C74.9069 -21.2029 75.4993 -22.6891 75.9527 -24.22C76.2404 -25.1922 76.3355 -26.2113 76.2327 -27.22C76.1276 -28.0016 75.7775 -28.7298 75.2327 -29.3C73.9678 -30.468 72.3966 -31.2519 70.7027 -31.56L69.8527 -31.65L72.9527 -38C73.3727 -37.9067 73.8027 -37.7967 74.2427 -37.67C75.0495 -37.427 75.8354 -37.1193 76.5927 -36.75C78.5319 -35.868 80.2656 -34.5907 81.6827 -33C82.8149 -31.6876 83.5928 -30.1076 83.9427 -28.41C84.2635 -26.6341 84.2021 -24.8103 83.7627 -23.06C83.24 -20.9862 82.4957 -18.9746 81.5427 -17.06L62.8127 21.21C62.7166 21.3997 62.5624 21.5539 62.3727 21.65C62.1311 21.7575 61.8662 21.8022 61.6027 21.78C61.1949 21.7426 60.7944 21.6484 60.4127 21.5C59.8293 21.2895 59.2583 21.0457 58.7027 20.77C58.1371 20.5022 57.5892 20.1983 57.0627 19.86C56.6961 19.6497 56.3597 19.3906 56.0627 19.09C55.8716 18.9074 55.7423 18.6696 55.6927 18.41C55.6729 18.1969 55.722 17.9831 55.8327 17.8V17.8Z"
            fill="white"
          />
          <path
            d="M47.7627 14C47.7345 13.8356 47.7627 13.6664 47.8427 13.52L62.2627 -16C62.6527 -16.8 62.9627 -17.52 63.2627 -18.17L67.6227 -27C67.8227 -26.81 68.0227 -26.6 68.2127 -26.39C69.0946 -25.3531 69.703 -24.1121 69.9827 -22.78C70.2074 -21.3935 70.1324 -19.975 69.7627 -18.62C69.3517 -16.9913 68.7649 -15.412 68.0127 -13.91L53.3327 16.16C53.2565 16.3111 53.1338 16.4338 52.9827 16.51C52.794 16.5922 52.5879 16.6266 52.3827 16.61C52.0621 16.5723 51.7468 16.4985 51.4427 16.39C50.9849 16.2234 50.5375 16.0297 50.1027 15.81C49.6579 15.6012 49.227 15.3641 48.8127 15.1C48.5374 14.9338 48.2853 14.7321 48.0627 14.5C47.9152 14.3668 47.8108 14.1928 47.7627 14Z"
            fill="#939393"
          />
          <path
            d="M31.6827 32.49C33.4776 32.49 34.9327 31.0349 34.9327 29.24C34.9327 27.4451 33.4776 25.99 31.6827 25.99C29.8878 25.99 28.4327 27.4451 28.4327 29.24C28.4327 31.0349 29.8878 32.49 31.6827 32.49Z"
            fill="white"
          />
          <path
            d="M31.9627 29.06C34.1911 26.176 35.4065 22.6378 35.4207 18.9932C35.4348 15.3487 34.247 11.8011 32.0412 8.89984C29.8353 5.9986 26.7344 3.90555 23.2187 2.94482C19.7031 1.98408 15.9687 2.20924 12.5939 3.58545C9.21913 4.96166 6.39223 7.41213 4.55094 10.5574C2.70965 13.7027 1.95671 17.3673 2.40871 20.9837C2.8607 24.6002 4.49242 27.9667 7.05119 30.5621C9.60996 33.1574 12.953 34.8367 16.5627 35.34"
            stroke="#939393"
            stroke-width="3"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </g>
      </svg>
    );
  }
}
