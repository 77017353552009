import React from 'react'
import {
  WithStyles,
  createStyles,
  Theme,
  withStyles,
  Grid
} from '@material-ui/core'
import _ from 'lodash'
import { CONFIG } from './config'
import DraggableCard from './dragdrop/DraggableCard'
import UndraggableCard from './dragdrop/UndraggableCard'
import { moveCard } from './dragdrop/sorting'
import ButtonStyled from './Button'
import CustomTypography from '../../../store/content/typography/Typography'
import {
  remapAlignmentForLayout,
  setTranslatedTextsInObject,
  addPercent,
  addPx,
  isJSON,
  convertDraftRawToPlainText,
  getImageUrl
} from './utils'
import CollectionCard from '../../../../components/store/collection/gallery/CollectionCard'
import ProductCard from '../../../../components/store/product/gallery/ProductCard'
import TeamstoreCard from '../../../../components/store/teamstore/TeamstoreCard'
import XceedProductCard from '../../../../components/store/content/ProductCard'
import XceedTeamstoreCard from '../../../../components/store/content/TeamstoreCard'
import SingleCard from '../../../pageContent/components/Collection/SingleCard'
import DividerComponent from '../../../pageContent/components/Divider/DividerComponent'
import useContentModule from './hooks/useContentModule'
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../../brikl-config'
import RichText from './RichText'
import TextField from '../../../common/TextField'
import SubscribeCard from '../../../../components/store/content/SubscribeCard'
import LocationCard from '../../../../components/store/content/LocationCard'
import ContactForm from '../../../../components/store/content/form/ContactForm'
import HubspotForm from '../../../../components/store/content/HubspotForm'
import { Link } from 'gatsby'
import Imgix from 'react-imgix'
import Instagram from '../../../pageContent/components/Instagram/Instagram'
import MainBanner from './components/Banner/MainBanner'
import Discovershop from './components/DiscoverShop/Discovershop'
import { RequestQuotation } from '../../../beCreative/step/RequestQuotation'
const styles = (theme: Theme) =>
  createStyles({
    contentColumnLabelShow: {
      height: 20,
      width: 'auto',
      position: 'absolute',
      top: '-1px',
      background: 'green',
      color: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'BiomeStd-Light',
      fontWeight: 'bold',
      fontSize: 10,
      padding: 5,
      zIndex: 15,
      display: 'none',
      cursor: 'pointer'
    },
    input: {
      padding: '0px !important'
    },
    cardInvisible: {
      border: '1px solid transparent'
    },
    cardVisible: {
      border: '1px dashed green'
    },
    labelVisible: {
      display: 'flex'
    }
  })

interface Props extends WithStyles<typeof styles> {
  Template: any
  columnRow: any
  pageId: string
  columnId: string
  rowId: string
  index: number
}

const ContentModuleRow: React.FunctionComponent<Props> = props => {
  const {
    Template,
    columnRow,
    pageId,
    rowId,
    index,
    classes,
    pageContext,
    shopId,
    themeId
  } = props
  const urlPrefix =
    pageContext && pageContext.urlPrefix ? pageContext.urlPrefix : 'en/'
  const {
    isHover,
    isSelected,
    handleMouseEnter,
    handleMouseLeave
  } = useContentModule(
    columnRow.id,
    Template ? Template.state.selectedColRow : null
  )

  let bannerUrl = Template.state.currentLanguage
    ? Template.state.currentLanguage.split('_')[0]
    : 'en'

  const data =
    Template && Template.state.unsavedData && isSelected
      ? { ...columnRow.columnRowDetail, ...Template.state.unsavedData }
      : columnRow.columnRowDetail

  const handleSwitchTab = (data: any): void => {
    const newState = {
      currentTab: 1,
      selectedCol: '',
      selectedColRow: {
        ...data,
        id: columnRow.id,
        sortOrder: columnRow.sortOrder,
        columnId: columnRow.columnId,
        alignment: columnRow.alignment
      },
      selectedElement: columnRow.id,
      selectedRow: '',
      selectedRowObj: ''
    }
    if (!isSelected) {
      newState.unsavedData = null
    }
    Template.setState(newState)
  }

  let detail = _.cloneDeep(data)
  detail = setTranslatedTextsInObject(
    detail,
    [
      'text',
      'label',
      'placeholder',
      'description',
      'title',
      'callToAction',
      'content',
      'termsContent',
      'nameLabel',
      'namePlaceholder',
      'emailLabel',
      'emailPlaceholder',
      'contentLabel',
      'contentPlaceholder',
      'subTitle',
      'buttonText'
    ],
    Template.state.currentLanguage
  )

  let splitMediaUrl = ''
  let embedUrl = ''
  let verticalAlign = ''

  if (detail.type === 'MEDIA') {
    if (detail.url !== null && detail.url.indexOf('watch') !== -1) {
      splitMediaUrl = detail.url.split('=')
    }
  }

  if (detail.type === 'MEDIA') {
    if (detail.url !== null && detail.url.indexOf('embed') !== -1) {
      embedUrl = detail.url.split('/')
    }
  }

  if (detail.verticalAlign && detail.verticalAlign.indexOf('_')) {
    verticalAlign = detail.verticalAlign.split('_')
  }

  if (!detail) return null
  return (
    <Grid
      style={{ zIndex: 999, marginTop: 16, height: 'calc(100% - 16px)' }}
      key={columnRow.id}
      item={true}
      xs={detail.type === 'CARD' ? 12 : 12}
      sm={detail.type === 'CARD' ? 6 : 12}
      md={detail.type === 'CARD' ? 4 : 12}
      lg={detail.type === 'CARD' ? 4 : 12}
      xl={detail.type === 'CARD' ? 4 : 12}
    >
      <Grid
        component={
          isSelected &&
          CONFIG.showRichTextForVariants.indexOf(detail.typoVariant) !== -1
            ? UndraggableCard
            : DraggableCard
        }
        index={index}
        id={columnRow.id}
        dragdrop={{
          dragType: 'columnRow',
          id: columnRow.id,
          index,
          moveCard,
          pageId,
          rowId,
          sortOrder: columnRow.sortOrder
        }}
        justify={remapAlignmentForLayout(columnRow.alignment)}
        alignContent={remapAlignmentForLayout(
          columnRow.verticalAlign
            ? columnRow.verticalAlign.toLowerCase()
            : 'center'
        )} // add verticalAlign here
        container={true}
        className={
          isHover || isSelected ? classes.cardVisible : classes.cardInvisible
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        justify="space-around"
        style={{
          position: 'relative',
          marginTop: '1.5rem',
          marginBottom: '.5rem',
          height: 'calc(100% - 1.5rem - 0.5rem)'
        }}
      >
        <div
          onClick={() => handleSwitchTab(detail)}
          className={
            classes.contentColumnLabelShow +
            ' ' +
            (isHover || isSelected ? classes.labelVisible : '')
          }
          data-cy={detail.type + '-' + columnRow.sortOrder}
        >
          {detail.type} > {columnRow.sortOrder}
        </div>
        {detail.type === 'TEXT' ? (
          CONFIG.showRichTextForVariants.indexOf(detail.typoVariant) === -1 ? (
            <Grid item style={{ width: '100%' }}>
              <CustomTypography
                shopId={shopId}
                variant={detail.typoVariant}
                content={
                  isJSON(detail.newText)
                    ? convertDraftRawToPlainText(detail.newText)
                    : detail.newText
                }
                color={detail.color}
                align={detail.alignment.toLowerCase()}
                fontSize={detail.fontSize}
                fontFamily={detail.fontFamily}
                fontWeight={detail.fontWeight}
              />
            </Grid>
          ) : (
            <Grid container style={{ display: 'block' }}>
              <RichText
                shopId={shopId}
                dataCy="RichText"
                style={{
                  color: detail.color,
                  fontFamily: detail.fontFamily,
                  fontSize: addPx(detail.fontSize),
                  textAlign: detail.alignment.toLowerCase(),
                  fontWeight: detail.fontWeight,
                  textTransform: detail.textTransform,
                  letterSpacing: detail.letterSpacing
                }}
                language={Template.state.currentLanguage}
                raw={detail.newText}
                typoVariant={detail.typoVariant}
                fontSize={detail.fontSize}
                isViewMode={
                  !isSelected ||
                  CONFIG.showRichTextForVariants.indexOf(detail.typoVariant) ===
                    -1
                }
                columnRow={columnRow}
                pageId={pageId}
                Template={Template}
              />
            </Grid>
          )
        ) : null}
        {detail.type === 'BUTTON' ? (
          <Grid
            container
            justify={remapAlignmentForLayout(columnRow.alignment)}
          >
            <ButtonStyled
              data-cy="Button"
              shopId={shopId}
              background={detail.backgroundColor}
              border={
                detail.borderThickness ||
                detail.borderType ||
                detail.borderColor
                  ? detail.borderThickness.toString() +
                    'px ' +
                    detail.borderType +
                    ' ' +
                    detail.borderColor
                  : detail.border
              }
              color={detail.color}
              text={detail.newText}
              fontFamily={detail.font}
              fontSize={addPx(detail.fontSize)}
              padding={
                detail.padding1 === 0 && detail.padding2 === 0
                  ? '0px'
                  : detail.padding1 || detail.padding2
                  ? detail.padding1.toString() +
                    'px ' +
                    detail.padding2.toString() +
                    'px ' +
                    detail.padding1.toString() +
                    'px ' +
                    detail.padding2.toString() +
                    'px'
                  : detail.padding
              }
              borderRadius={addPx(detail.borderRadius)}
              url={detail.url}
              disabled={true}
            />
          </Grid>
        ) : null}
        {detail.type === 'BANNER' ? (
          <MainBanner
            shopId={shopId}
            urlPrefix={bannerUrl}
            imageUrl={detail.imageUrl}
            title={detail.newTitle}
            subTitle={detail.newSubtitle}
            buttonText={detail.newButtontext}
            verticalAlign={detail.verticalAlign}
            horizontalAlign={detail.horizontalAlign}
            titleFontFamily={detail.titleFontFamily}
            subTitleFontFamily={detail.subTitleFontFamily}
            buttonFontFamily={detail.buttonFontFamily}
            titleFontSize={detail.titleFontSize}
            subTitleFontSize={detail.subTitleFontSize}
            titleColor={detail.titleColor}
            titleBackgroundColor={detail.titleBackgroundColor}
            subTitleColor={detail.subTitleColor}
            buttonTextColor={detail.buttonTextColor}
            textAlign={detail.textAlign}
            buttonUrl={detail.buttonUrl}
            objectFit={detail.objectFit}
            height={addPx(detail.height)}
            buttonPadding={
              detail.buttonPadding1 === 0 && detail.buttonPadding2 === 0
                ? '0px'
                : detail.buttonPadding1 || detail.buttonPadding2
                ? detail.buttonPadding1.toString() +
                  'px ' +
                  detail.buttonPadding2.toString() +
                  'px ' +
                  detail.buttonPadding1.toString() +
                  'px ' +
                  detail.buttonPadding2.toString() +
                  'px'
                : detail.buttonPadding
            }
            buttonBackgroundColor={detail.buttonBackgroundColor}
            buttonBorder={
              detail.borderThickness || detail.borderType || detail.borderColor
                ? detail.borderThickness.toString() +
                  'px ' +
                  detail.borderType +
                  ' ' +
                  detail.borderColor
                : detail.buttonBorder
            }
            detail={detail}
            buttonBorderRadius={detail.buttonBorderRadius}
            buttonFontSize={detail.buttonFontSize}
            containerPadding={
              detail.containerPadding1 === 0 && detail.containerPadding2 === 0
                ? '0px'
                : detail.containerPadding1 || detail.containerPadding2
                ? detail.containerPadding1.toString() +
                  'px ' +
                  detail.containerPadding2.toString() +
                  'px ' +
                  detail.containerPadding1.toString() +
                  'px ' +
                  detail.containerPadding2.toString() +
                  'px'
                : detail.containerPadding
            }
            disabled={true}
            copyrightContent={detail.copyrightContent}
            copyrightFontColor={detail.copyrightFontColor}
            copyrightHorizontalAlignment={detail.copyrightHorizontalAlignment}
            copyrightVerticalAlignment={detail.copyrightVerticalAlignment}
            copyrightFontFamily={detail.copyrightFontFamily}
            langCode={Template.state.currentLanguage}
            collection={detail.collection ? detail.collection : {}}
            page={detail.page ? detail.page : {}}
          />
        ) : null}
        {detail.type === 'DISCOVER_CARD' ? (
          <Grid container style={{ width: '100vw' }}>
            <Discovershop
              shopId={shopId}
              title={detail.newTitle}
              subTitle={detail.newSubtitle}
              buttonText={detail.newButtontext}
              buttonUrl={detail.buttonUrl}
              disabled={true}
              urlPrefix={urlPrefix}
            />
          </Grid>
        ) : null}
        {detail.type === 'CARD' ? (
          <SingleCard
            shopId={shopId}
            width={addPercent(detail.width)}
            height={addPx(detail.height)}
            buttonFontSize={addPx(detail.buttonFontSize)}
            descriptionFontSize={addPx(detail.descriptionFontSize)}
            titleFontSize={addPx(detail.titleFontSize)}
            data={detail}
            thumbnailImg={detail.thumbnailImg}
            borderRadius={detail.borderRadius}
            title={detail.newTitle}
            description={detail.newDescription}
            callToAction={detail.newCalltoaction}
            border={
              detail.borderThickness || detail.borderType || detail.borderColor
                ? detail.borderThickness.toString() +
                  'px ' +
                  detail.borderType +
                  ' ' +
                  detail.borderColor
                : detail.border
            }
            titleColor={detail.titleColor}
            titleBackgroundColor={detail.titleBackgroundColor}
            descriptionColor={detail.descriptionColor}
            buttonTextColor={detail.buttonTextColor}
            buttonBorder={
              detail.buttonBorderThickness ||
              detail.buttonBorderType ||
              detail.buttonBorderColor
                ? detail.buttonBorderThickness.toString() +
                  'px ' +
                  detail.buttonBorderType +
                  ' ' +
                  detail.buttonBorderColor
                : detail.buttonBorder
            }
            buttonAlign={detail.buttonAlign}
            titleAlign={detail.titleAlign}
            descriptionAlign={detail.descriptionAlign}
            disabled={true}
            langCode={Template.state.currentLanguage}
            page={detail.page ? detail.page : {}}
            collection={detail.collection ? detail.collection : {}}
            teamstore={detail.teamstore ? detail.teamstore : {}}
            product={detail.product ? detail.product : {}}
            urlPrefix={bannerUrl}
            urlType={detail.urlType ? detail.urlType : {}}
          />
        ) : null}
        {columnRow.columnRowDetail.type === 'HUBSPOT_FORM' ? (
          <HubspotForm
            shopId={shopId}
            portalId={detail.portalId}
            formId={detail.formId}
            cssClass={detail.cssClass}
            target={detail.target}
            disabled={false}
            langCode={Template.state.currentLanguage}
          />
        ) : null}
        {columnRow.columnRowDetail.type === 'REQUEST_QUOTE_FORM' ? (
          <RequestQuotation
            shopId={shopId}
            langCode={Template.state.currentLanguage}
          />
        ) : null}
        {detail.type === 'SUBSCRIBE_CARD' ? (
          <SubscribeCard
            shopId={shopId}
            title={detail.newTitle}
            content={detail.newContent}
            callToAction={detail.newCalltoaction}
            width={addPercent(detail.width)}
            height={addPx(detail.height)}
            label={detail.newLabel}
            placeholder={detail.newPlaceholder}
            termsContent={detail.newTermscontent}
            hasTerms={detail.hasTerms}
            isAccepted={detail.isAccepted}
            hasInput={detail.hasInput}
          />
        ) : null}
        {detail.type === 'LOCATION_CARD' ? (
          <LocationCard
            shopId={shopId}
            url={detail.url}
            shopName={detail.shopName}
            address={detail.address}
            website={detail.website}
            email={detail.email}
            phone={detail.phone}
            openHours={detail.openHours}
            openDays={detail.openDays}
            closeDays={detail.closedDays}
            hasPremiumDealership={detail.hasPremiumDealership}
            dealershipLink={detail.dealershipLink}
          />
        ) : null}
        {detail.type === 'CONTACT_CARD' ? (
          <ContactForm
            langCode={Template.state.currentLanguage}
            shopId={shopId}
            title={detail.newTitle}
            nameLabel={detail.newNamelabel}
            namePlaceholder={detail.newNameplaceholder}
            emailLabel={detail.newEmaillabel}
            emailPlaceholder={detail.newEmailplaceholder}
            contentLabel={detail.newContentlabel}
            contentPlaceholder={detail.newContentplaceholder}
            hasTerms={detail.hasTerms}
          />
        ) : null}
        {detail.type === 'COLLECTION_CARD' ? (
          <Grid
            container={true}
            justify={'center'}
            style={{ maxWidth: 1000, margin: 'auto', padding: 24 }}
          >
            {detail.collections
              ? detail.collections.map((c: any, indexCollection: number) => {
                  const collection = c
                  return (
                    <CollectionCard
                      shopId={shopId}
                      urlPrefix={urlPrefix}
                      langCode={Template.state.currentLanguage}
                      key={indexCollection}
                      collection={collection}
                      numberOfCards={detail.collections.length}
                      buttonText={detail.buttonText}
                    />
                  )
                })
              : null}
          </Grid>
        ) : null}
        {detail.type === 'PAGE_CARD' ? (
          <Grid container={true}>
            {detail.pages
              ? detail.pages.map((p: any, indexPage: number) => {
                  const page = p
                  return null
                  // return <ProductCard langCode={Template.state.currentLanguage} key={indexProduct} Template={Template} product={product} />
                })
              : null}
          </Grid>
        ) : null}
        {detail.type === 'PRODUCT_CARD' ? (
          <Grid
            container={true}
            style={{ maxWidth: 1000, margin: 'auto', padding: 24 }}
          >
            {themeId === 'theme2' && detail.products
              ? detail.products.map((p: any, indexProduct: number) => {
                  const product = p
                  return (
                    <XceedProductCard
                      currencyRates={
                        pageContext && pageContext.shop
                          ? pageContext.shop.currencyRates
                          : null
                      }
                      shopId={shopId}
                      urlPrefix={urlPrefix}
                      langCode={Template.state.currentLanguage}
                      key={indexProduct}
                      Template={Template}
                      product={product}
                      buttonText={detail.buttonText}
                      numberOfCards={detail.products.length}
                      taxSettings={
                        pageContext && pageContext.shop
                          ? pageContext.shop.taxSettings
                          : null
                      }
                    />
                  )
                })
              : detail.products.map((p: any, indexProduct: number) => {
                  const product = p
                  return (
                    <ProductCard
                      defaultCurrency={
                        pageContext && pageContext.shop
                          ? pageContext.shop.defaultCurrency
                          : null
                      }
                      currencyRates={
                        pageContext && pageContext.shop
                          ? pageContext.shop.currencyRates
                          : null
                      }
                      themeId={themeId}
                      shopId={shopId}
                      urlPrefix={urlPrefix}
                      langCode={Template.state.currentLanguage}
                      key={indexProduct}
                      Template={Template}
                      numberOfCards={detail.products.length}
                      product={product}
                      buttonText={detail.buttonText}
                      taxSettings={
                        pageContext && pageContext.shop
                          ? pageContext.shop.taxSettings
                          : null
                      }
                    />
                  )
                })}
          </Grid>
        ) : null}
        {detail.type === 'TEAMSTORE_CARD' ? (
          <Grid container={true}>
            {themeId === 'theme2' && detail.teamstores
              ? detail.teamstores.map((t: any, indexTeamstore: number) => {
                  const teamstore = t
                  return (
                    <XceedTeamstoreCard
                      themeId={themeId}
                      shopId={shopId}
                      urlPrefix={urlPrefix}
                      langCode={Template.state.currentLanguage}
                      key={indexTeamstore}
                      Template={Template}
                      teamstore={teamstore}
                    />
                  )
                })
              : detail.teamstores.map((t: any, indexTeamstore: number) => {
                  const teamstore = t
                  return (
                    <TeamstoreCard
                      themeId={themeId}
                      shopId={shopId}
                      urlPrefix={urlPrefix}
                      langCode={Template.state.currentLanguage}
                      key={indexTeamstore}
                      Template={Template}
                      teamstore={teamstore}
                    />
                  )
                })}
          </Grid>
        ) : null}
        {columnRow.columnRowDetail.type === 'INPUT' ? (
          <TextField
            id="outlined-full-width"
            label={detail.newLabel}
            placeholder={detail.newPlaceholder}
            // fullWidth
            type={detail.inputType}
            disabled={detail.disabled}
            required={detail.required}
          />
        ) : null}

        {columnRow.columnRowDetail.type === 'IMAGE' ? (
          <div style={{ width: '100%', height: '100%' }}>
            <Imgix
              src={
                detail.imageUrl && detail.imageUrl.indexOf('https://') !== -1
                  ? detail.imageUrl.replace(MEDIA_CDN_URL, IMGIX_URL)
                  : `${IMGIX_URL}/${shopId}/media/${detail.imageUrl}`
              }
              imgixParams={{
                auto: 'compress',
                txt: detail.copyrightContent,
                txtlead: 2,
                txtpad: 15,
                txtfont: detail.copyrightFontFamily,
                txtclr: detail.copyrightFontColor,
                txtalign: `${
                  detail.copyrightHorizontalAlignment
                    ? detail.copyrightHorizontalAlignment.toLowerCase()
                    : 'right'
                }, ${
                  detail.copyrightVerticalAlignment
                    ? detail.copyrightVerticalAlignment.toLowerCase()
                    : 'bottom'
                }`
              }}
              htmlAttributes={{
                alt: detail.imageAlt,
                title: detail.imageAlt,
                style: {
                  width: '100%',
                  height: '100%',
                  objectFit: detail.objectFit ? detail.objectFit : 'contain'
                }
              }}
            />
          </div>
        ) : null}

        {columnRow.columnRowDetail.type === 'MEDIA' ? (
          <Grid container justify={remapAlignmentForLayout(detail.alignment)}>
            {detail.url ? (
              <iframe
                data-cy="Media-youtube"
                title="media-iframe"
                style={{
                  height: `${addPx(detail.height)}`,
                  width: `${addPx(detail.width)}`
                }}
                src={
                  detail.url !== null && detail.url.indexOf('watch') !== -1
                    ? `https://www.youtube.com/embed/${splitMediaUrl[1]}`
                    : detail.url !== null && detail.url.indexOf('embed') !== -1
                    ? `https://www.youtube.com/embed/${embedUrl[4]}`
                    : ''
                }
              >
                &nbsp;
              </iframe>
            ) : (
              <img
                src="https://staging-brikl-shop-common-assets.s3-ap-southeast-1.amazonaws.com/pagebuilder/video-placeholder-1280x720-40.jpg"
                style={{
                  height: `${addPx(detail.height)}`,
                  width: `${addPx(detail.width)}`
                }}
              />
            )}
          </Grid>
        ) : null}
        {columnRow.columnRowDetail.type === 'SOCIAL_MEDIA' ? (
          <Grid container={true} justify="center" alignItems="center">
            {/* {columnRow.columnRowDetail.socialMediaType} */}
            {columnRow.columnRowDetail.socialMediaType === 'INSTAGRAM' ? (
              <Instagram shopId={shopId} />
            ) : null}
          </Grid>
        ) : null}
        {columnRow.columnRowDetail.type === 'DIVIDER' ? (
          <DividerComponent
            shopId={shopId}
            width={addPercent(detail.width)}
            data={detail}
            text={detail.newText}
            border={
              detail.borderThickness || detail.borderType || detail.borderColor
                ? detail.borderThickness.toString() +
                  'px ' +
                  detail.borderType +
                  ' ' +
                  detail.borderColor
                : detail.border
            }
            padding={
              detail.paddingDiv1 || detail.paddingDiv2
                ? detail.paddingDiv1.toString() +
                  'px ' +
                  detail.paddingDiv2.toString() +
                  'px ' +
                  detail.paddingDiv1.toString() +
                  'px ' +
                  detail.paddingDiv2.toString() +
                  'px'
                : detail.padding
            }
            textPadding={
              detail.paddingText1 || detail.paddingText2
                ? detail.paddingText1.toString() +
                  'px ' +
                  detail.paddingText2.toString() +
                  'px ' +
                  detail.paddingText1.toString() +
                  'px ' +
                  detail.paddingText2.toString() +
                  'px'
                : detail.padding
            }
          />
        ) : null}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(ContentModuleRow)
