import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Query } from 'react-apollo';
import { GET_PAGE_CONTENT } from '../../gql/admin-queries';
import ContentRow from './ContentRow';
import withDragDropContext from './dragdrop/withDragDropContext';
import _sortBy from 'lodash/sortBy';
// get global styles from shop settings such as fonts and master css settings
import GlobalStyles from '../../../globalStyles';

class ContentIndex extends Component {
  componentDidMount() {
    this.props.Template.ContentIndex = this;
  }

  render() {
    const {
      pageId,
      Template,
      client,
      pageContext,
      themeId,
      shopId
    } = this.props;
    if (!pageId) return null;
    return (
      <div>
        <GlobalStyles />
        <Query query={GET_PAGE_CONTENT} variables={{ id: pageId }}>
          {({ loading, error, data, refetch }) => {
            if (loading) return <CircularProgress />;
            if (error) return <span>{error.message}</span>;

            if (!data.page || !data.page.rows) {
              return null;
            }

            const pageRows = data.page.rows
              ? _sortBy(data.page.rows, 'sortOrder')
              : [];
            this.slugs = data.page.slugs;

            return (
              <div>
                {JSON.stringify(pageContext)}
                {pageRows.length > 0 ? (
                  <div>
                    {pageRows.map((row, index) => (
                      <ContentRow
                        shopId={shopId}
                        refetchRows={refetch}
                        key={row.id}
                        index={index}
                        row={row}
                        pageId={pageId}
                        Template={Template}
                        ContentIndex={this}
                        client={client}
                        pageContext={pageContext}
                        data={data}
                        themeId={themeId}
                      />
                    ))}
                  </div>
                ) : (
                  <div
                    style={{
                      justifyContent: 'center',
                      textAlign: 'center',
                      paddingTop: 10
                    }}
                  >
                    <Typography variant="body1" data-cy="Empty-rows">
                      You don't have any rows on this page yet. Why don't you
                      add one?
                    </Typography>
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}


const withStylesExport = ContentIndex;
export default withDragDropContext(withStylesExport);
