import gql from 'graphql-tag'
import {
  ProductDetailsFragment,
  ProductPreviewFragment,
  ShopSettingFragment,
  ShopDesignStepFragment,
  TextDesignStepFragment,
  LogoDesignStepFragment,
  ColorDesignStepFragment,
  FabricDesignStepFragment,
  CouponFragment,
  ProductAttributeFragment,
  VariantFragment,
  CollectionFragment,
  TeamstoreFragment,
  ContentDetailProductCardFragment,
  ContentDetailCollectionCardFragment,
  ContentDetailTeamstoreCardFragment,
  ContentDetailBannerFragment,
  ContentColumnFragment,
  ContentDetailTextFragment,
  ContentDetailImageFragment,
  ContentDetailCardFragment,
  ContentDetailHubspotFormFragment,
  ContentDetailSubscribeCardFragment,
  ContentDetailContactCardFragment,
  ContentDetailLocationCardFragment,
  ContentDetailButtonFragment,
  ContentDetailInputFieldFragment,
  ContentDetailDividerFragment,
  ContentDetailDiscoverCardFragment
} from './admin-fragments'
import {
  Board,
  DiscountFragment,
  DiscountRuleFragment,
  DiscountRuleObjectFragment,
  ContentPageFragment,
  PageTagFragment,
  PrintingDetailFragment,
  TextFragment,
  PricingDetailFragment,
  ProductOptionValueFragment,
  MenuFragment,
  ThemeFragment,
  ProductDesignTemplateFragment
} from '../../../gql/fragments'

import { AdminBoardItemDetailFragment } from './admin-queries'

export const ADMIN_CREATE_PRODUCT_DESIGN_TEMPLATE = gql`
  mutation createProductDesignTemplate($productId: ID!) {
    createProductDesignTemplate(productId: $productId) {
      ...productDesignTemplateFragment
    }
  }
  ${ProductDesignTemplateFragment}
`
export const ADMIN_UPDATE_PRODUCT_DESIGN_TEMPLATE = gql`
  mutation updateProductDesignTemplate(
    $productId: ID!
    $id: ID!
    $input: ProductDesignTemplateInput!
  ) {
    updateProductDesignTemplate(productId: $productId, id: $id, input: $input) {
      ...productDesignTemplateFragment
    }
  }
  ${ProductDesignTemplateFragment}
`
export const ADMIN_DELETE_PRODUCT_DESIGN_TEMPLATE = gql`
  mutation deleteProductDesignTemplate($productId: ID!, $id: ID!) {
    deleteProductDesignTemplate(productId: $productId, id: $id)
  }
`

export const ADMIN_CREATE_COLLECTION_SALESCHANNEL = gql`
  mutation createCollectionSalesChannel(
    $collectionId: ID!
    $salesChannelId: ID!
  ) {
    createCollectionSalesChannel(
      collectionId: $collectionId
      salesChannelId: $salesChannelId
    ) {
      id
      featured
      sortOrder
      collectionId
      salesChannelId
    }
  }
`
export const ADMIN_UPDATE_COLLECTION_SALESCHANNEL = gql`
  mutation updateCollectionSalesChannel(
    $collectionId: ID!
    $salesChannelId: ID!
    $input: CollectionSalesChannelInput!
  ) {
    updateCollectionSalesChannel(
      collectionId: $collectionId
      salesChannelId: $salesChannelId
      input: $input
    ) {
      id
      featured
      sortOrder
      collectionId
      salesChannelId
    }
  }
`

export const ADMIN_DELETE_COLLECTION = gql`
  mutation deleteCollection($id: ID!) {
    deleteCollection(id: $id)
  }
`
export const ADMIN_DELETE_COLLECTION_SALESCHANNEL = gql`
  mutation deleteCollectionSalesChannel(
    $collectionId: ID!
    $salesChannelId: ID!
  ) {
    deleteCollectionSalesChannel(
      collectionId: $collectionId
      salesChannelId: $salesChannelId
    )
  }
`
export const ADMIN_CREATE_PRODUCT_SALESCHANNEL = gql`
  mutation createProductSalesChannel($productId: ID!, $salesChannelId: ID!) {
    createProductSalesChannel(
      productId: $productId
      salesChannelId: $salesChannelId
    ) {
      id
      featured
      sortOrder
      productId
      salesChannelId
    }
  }
`
export const ADMIN_UPDATE_PRODUCT_SALESCHANNEL = gql`
  mutation updateProductSalesChannel(
    $productId: ID!
    $salesChannelId: ID!
    $input: ProductSalesChannelInput!
  ) {
    updateProductSalesChannel(
      productId: $productId
      salesChannelId: $salesChannelId
      input: $input
    ) {
      id
      featured
      sortOrder
      productId
      salesChannelId
    }
  }
`

export const ADMIN_DELETE_PRODUCT_SALESCHANNEL = gql`
  mutation deleteProductSalesChannel($productId: ID!, $salesChannelId: ID!) {
    deleteProductSalesChannel(
      productId: $productId
      salesChannelId: $salesChannelId
    )
  }
`

export const ADMIN_CREATE_PRODUCT_PACKAGE_SALESCHANNEL = gql`
  mutation createProductPackageSalesChannel(
    $productPackageId: ID!
    $salesChannelId: ID!
  ) {
    createProductPackageSalesChannel(
      productPackageId: $productPackageId
      salesChannelId: $salesChannelId
    ) {
      id
      productPackageId
      salesChannelId
    }
  }
`

export const ADMIN_DELETE_PRODUCT_PACKAGE_SALESCHANNEL = gql`
  mutation deleteProductPackageSalesChannel(
    $productPackageId: ID!
    $salesChannelId: ID!
  ) {
    deleteProductPackageSalesChannel(
      productPackageId: $productPackageId
      salesChannelId: $salesChannelId
    )
  }
`

export const ADMIN_TRIGGER_DEPLOYMENT = gql`
  mutation triggerDeployment($siteId: ID!, $title: String!) {
    triggerDeployment(siteId: $siteId, title: $title) {
      id
      status
      previewUrl
    }
  }
`

export const ADMIN_TRIGGER_PUBLISH = gql`
  mutation triggerPublish($siteId: ID!, $deployId: ID!) {
    triggerPublish(siteId: $siteId, deployId: $deployId) {
      id
      status
      previewUrl
    }
  }
`

export const ADMIN_CREATE_PRODUCT_PACKAGE = gql`
  mutation createProductPackage($shopId: ID!, $status: ProductStatus) {
    createProductPackage(shopId: $shopId, status: $status) {
      id
      productIds
      price {
        roundUp
        currency
        value
        includesTax
        taxPercent
      }
      slugs {
        content
        langCode
      }
      status
      media {
        id
        productId
        variantId
        variantOptionId
        optionValue
        sortOrder
        isThumbnail
        isBanner
        image
      }
      title {
        id
        text {
          langCode
          content
        }
      }
      description {
        id
        text {
          langCode
          content
        }
      }
    }
  }
`
export const ADMIN_UPDATE_PRODUCT_PACKAGE = gql`
  mutation updateProductPackage($id: ID!, $input: ProductPackageInput!) {
    updateProductPackage(id: $id, input: $input) {
      id
      accountingId
      productIds
      products {
        id
        media {
          id
          productId
          variantId
          variantOptionId
          optionValue
          sortOrder
          isThumbnail
          isBanner
          image
        }
        title {
          id
          text {
            langCode
            content
          }
        }
      }
      price {
        roundUp
        currency
        value
        includesTax
        taxPercent
      }
      slugs {
        content
        langCode
      }
      status
      media {
        id
        productId
        variantId
        variantOptionId
        optionValue
        sortOrder
        isThumbnail
        isBanner
        image
      }
      title {
        id
        text {
          langCode
          content
        }
      }
      description {
        id
        text {
          langCode
          content
        }
      }
    }
  }
`

export const ADMIN_CREATE_VARIANT = gql`
  mutation createVariant($shopId: ID!) {
    createVariant(shopId: $shopId) {
      ...variantFragment
    }
  }
  ${VariantFragment}
`
export const ADMIN_CREATE_PRODUCT_ATTRIBUTE = gql`
  mutation createProductAttribute($shopId: ID!) {
    createProductAttribute(shopId: $shopId) {
      ...productAttributeFragment
    }
  }
  ${ProductAttributeFragment}
`
export const ADMIN_CREATE_COLLECTION = gql`
  mutation createCollection($shopId: ID!) {
    createCollection(shopId: $shopId) {
      ...collectionFragment
    }
  }
  ${CollectionFragment}
`

export const ADMIN_CREATE_TEAMSTORE = gql`
  mutation createTeamstore($input: SalesChannelInput) {
    createTeamstore(input: $input) {
      ...teamstoreFragment
    }
  }
  ${TeamstoreFragment}
`
export const ADMIN_UPDATE_TEAMSTORE = gql`
  mutation updateTeamstore($id: ID!, $input: SalesChannelInput) {
    updateTeamstore(id: $id, input: $input) {
      ...teamstoreFragment
    }
  }
  ${TeamstoreFragment}
`

export const ADMIN_CREATE_FABRIC_COMPOSITION = gql`
  mutation AddComposition($shopId: ID!) {
    createFabricComposition(shopId: $shopId) {
      id
      code
      # printableDiscountFragment
      printableColors
      name
      summary
      fabricCost {
        id
        minOrderQuantity
        calculationType
        formula {
          priority
          price {
            roundUp
            currency
            value
            includesTax
            taxPercent
          }
          qty
          sqm
        }
      }
      swatchPreviewUrl
      defaultColorId
      colorIds
      colors {
        id
        name
        colorRGB {
          r
          g
          b
        }
        hex
      }
      fabricTypes {
        id
        name
        fabricConstructions
        fabricContentIds
        fabricContents {
          id
          percent
          textileId
          textile {
            id
            code
            name
            textileMaterial
          }
        }
      }
      weights {
        weight
        weightUnit
      }
    }
  }
`

export const ADMIN_UPDATE_FABRIC = gql`
  mutation fabricUpdate($id: ID!, $input: FabricCompositionInput) {
    updateFabricComposition(id: $id, input: $input) {
      id
      code
      printable
      printableColors
      name
      summary
      fabricCost {
        id
        minOrderQuantity
        calculationType
        formula {
          priority
          price {
            roundUp
            currency
            value
            includesTax
            taxPercent
          }
          qty
          sqm
        }
      }
      swatchPreviewUrl
      defaultColorId
      colorIds
      colors {
        id
        name
        colorRGB {
          r
          g
          b
        }
        hex
      }
      fabricTypes {
        id
        name
        fabricConstructions
        fabricContentIds
        fabricContents {
          id
          percent
          textileId
          textile {
            id
            code
            name
            textileMaterial
          }
        }
      }
      weights {
        weight
        weightUnit
      }
    }
  }
`

export const ADMIN_INVITE_NEW_USER = gql`
  mutation inviteAdminUser($email: String!, $role: ShopAdminUserRole!) {
    inviteAdminUser(email: $email, role: $role) {
      id
      role
      userId
      email
      status
      shopId
      permissions
    }
  }
`

export const ADMIN_UPDATE_PERMISSIONS = gql`
  mutation updateShopAdminUser(
    $userId: ID!
    $role: ShopAdminUserRole!
    $input: ShopPermissionInput!
  ) {
    updateShopAdminUser(userId: $userId, role: $role, input: $input) {
      id
      role
      userId
      email
      status
      shopId
      permissions
    }
  }
`

export const ADMIN_UPDATE_FABRIC_GROUP = gql`
  mutation updateFabricGroup($id: ID!, $input: FabricGroupInput) {
    updateFabricGroup(id: $id, input: $input) {
      id
      name
      shopId
    }
  }
`
export const ADMIN_CREATE_FABRIC_GROUP = gql`
  mutation createFabricGroup($shopId: ID!) {
    createFabricGroup(shopId: $shopId) {
      id
      name
      shopId
    }
  }
`

export const ADMIN_CREATE_PRINTINGTECHNIQUE = gql`
  mutation createPrintingTechnique($shopId: ID!) {
    createPrintingTechnique(shopId: $shopId) {
      ...printingTechniqueDetail
    }
  }
  ${PrintingDetailFragment}
  ${TextFragment}
  ${PricingDetailFragment}
`

export const ADMIN_UPDATE_PRINTINGTECHNIQUE = gql`
  mutation printingUpdate($id: ID!, $input: PrintingTechniqueInput) {
    updatePrintingTechnique(id: $id, input: $input) {
      ...printingTechniqueDetail
    }
  }
  ${PrintingDetailFragment}
  ${TextFragment}
  ${PricingDetailFragment}
`

export const ADMIN_CREATE_COLOR = gql`
  mutation createColor($shopId: ID!) {
    createColor(shopId: $shopId) {
      id
      internalCode
      code
      hex
      internalName
      name
      pantone
    }
  }
`
export const ADMIN_DELETE_COLOR = gql`
  mutation deleteColor($id: ID!) {
    deleteColor(id: $id)
  }
`

export const ADMIN_UPDATE_COLOR = gql`
  mutation colorUpdate($id: ID!, $input: ProductColorInput) {
    updateColor(id: $id, input: $input) {
      id
      internalCode
      code
      hex
      internalName
      name
      pantone
      CMYK {
        C
        M
        Y
        K
      }
    }
  }
`
export const ADMIN_UPDATE_COLLECTION = gql`
  mutation updateCollection($id: ID!, $input: CollectionInput) {
    updateCollection(id: $id, input: $input) {
      ...collectionFragment
    }
  }
  ${CollectionFragment}
`
export const ADMIN_UPDATE_VARIANT = gql`
  mutation updateVariant($id: ID!, $input: VariantInput) {
    updateVariant(id: $id, input: $input) {
      ...variantFragment
    }
  }
  ${VariantFragment}
`
export const ADMIN_UPDATE_PRODUCT_ATTRIBUTE = gql`
  mutation updateProductAttribute($id: ID!, $input: ProductAttributeInput) {
    updateProductAttribute(id: $id, input: $input) {
      ...productAttributeFragment
    }
  }
  ${ProductAttributeFragment}
`

export const ADMIN_COPY_PRODUCT = gql`
  mutation copyProduct($productId: ID!, $input: CopyProductInput!) {
    copyProduct(productId: $productId, input: $input) {
      id
    }
  }
`
export const ADMIN_CREATE_PRODUCT = gql`
  mutation createProduct(
    $shopId: ID!
    $status: ProductStatus!
    $input: ProductCreateInput
  ) {
    createProduct(shopId: $shopId, status: $status, input: $input) {
      ...productDetailsFragment
    }
  }
  ${ProductDetailsFragment}
`
export const ADMIN_UPDATE_PRODUCT = gql`
  mutation updateProduct($id: ID!, $input: ProductInput) {
    updateProduct(id: $id, input: $input) {
      ...productDetailsFragment
    }
  }
  ${ProductDetailsFragment}
`

export const ADMIN_UPDATE_PRODUCT_ELEMENT = gql`
  mutation updateProductElement(
    $productId: ID!
    $createdAt_elementRevision_id: String!
    $input: ProductElementInput
  ) {
    updateProductElement(
      productId: $productId
      createdAt_elementRevision_id: $createdAt_elementRevision_id
      input: $input
    ) {
      id
      createdAt
      productId
      elementRevision
      code
      fullCode
      defaultName
      name
      width
      height
      sqm
      defaultFabricId
      fabricGroupId
      fabricCompositionIds
    }
  }
`

export const ADMIN_UPDATE_SHOP_SETTINGS = gql`
  mutation updateShop($id: ID!, $input: ShopInput) {
    updateShop(id: $id, input: $input) {
      ...shopSettingsFragment
    }
  }
  ${ShopSettingFragment}
`
export const ADMIN_UPDATE_SHOP_DISCOUNT_SETTINGS = gql`
  mutation updateShopDiscountSettings(
    $id: ID!
    $input: ShopDiscountSettingsInput
  ) {
    updateShopDiscountSettings(id: $id, input: $input) {
      ...shopSettingsFragment
    }
  }
  ${ShopSettingFragment}
`
export const ADMIN_UPDATE_SHOP_SHIPPING_SETTINGS = gql`
  mutation updateShopShippingSettings(
    $id: ID!
    $input: ShopShippingSettingsInput
  ) {
    updateShopShippingSettings(id: $id, input: $input) {
      ...shopSettingsFragment
    }
  }
  ${ShopSettingFragment}
`
export const ADMIN_UPDATE_SHOP_NOTIFICATION_SETTINGS = gql`
  mutation updateShopNotificationSettings(
    $id: ID!
    $input: ShopNotificationSettingsInput
  ) {
    updateShopNotificationSettings(id: $id, input: $input) {
      ...shopSettingsFragment
    }
  }
  ${ShopSettingFragment}
`

export const ADMIN_UPDATE_SHOP_DESIGNSTUDIO_SETTINGS = gql`
  mutation updateShopDesignStudioSettings(
    $id: ID!
    $input: ShopDesignStudioSettingsInput
  ) {
    updateShopDesignStudioSettings(id: $id, input: $input) {
      ...shopSettingsFragment
    }
  }
  ${ShopSettingFragment}
`

export const ADMIN_CREATE_DESIGN_STEP = gql`
  mutation createDesignStep($shopId: ID!, $type: BoardItemType!) {
    createDesignStep(shopId: $shopId, type: $type) {
      ...designStepFragment
    }
  }
  ${ShopDesignStepFragment}
`
export const ADMIN_UPDATE_TEXT_DESIGN_STEP = gql`
  mutation updateTextDesignStep($id: ID!, $input: TextDesignStepInput!) {
    updateTextDesignStep(id: $id, input: $input) {
      ...textDesignStepFragment
    }
  }
  ${TextDesignStepFragment}
`
export const ADMIN_UPDATE_COLOR_DESIGN_STEP = gql`
  mutation updateColorDesignStep($id: ID!, $input: ColorDesignStepInput!) {
    updateColorDesignStep(id: $id, input: $input) {
      ...colorDesignStepFragment
    }
  }
  ${ColorDesignStepFragment}
`
export const ADMIN_UPDATE_LOGO_DESIGN_STEP = gql`
  mutation updateLogoDesignStep($id: ID!, $input: LogoDesignStepInput!) {
    updateLogoDesignStep(id: $id, input: $input) {
      ...logoDesignStepFragment
    }
  }
  ${LogoDesignStepFragment}
`
export const ADMIN_UPDATE_FABRIC_DESIGN_STEP = gql`
  mutation updateFabricDesignStep($id: ID!, $input: FabricDesignStepInput!) {
    updateFabricDesignStep(id: $id, input: $input) {
      ...fabricDesignStepFragment
    }
  }
  ${FabricDesignStepFragment}
`

export const ADMIN_UPLOAD_FILE = gql`
  mutation uploadFile(
    $type: FileDestination!
    $fileName: String!
    $prefixVariables: [String]
    $input: FilePrefixInput!
  ) {
    uploadFile(
      type: $type
      fileName: $fileName
      prefixVariables: $prefixVariables
      input: $input
    ) {
      error
      acl
      tagging
      url
      contentType
      cacheControl
    }
  }
`

export const ADMIN_COPY_FILE = gql`
  mutation copyFile(
    $from: String!
    $type: FileDestination!
    $input: FilePrefixInput!
  ) {
    copyFile(from: $from, type: $type, input: $input)
  }
`

export const ADMIN_UPDATE_ORDER_STATUS = gql`
  mutation updateOrderStatus(
    $id: ID!
    $ownedByOrganisationId: ID!
    $status: OrderStatus!
  ) {
    updateOrderStatus(
      id: $id
      ownedByOrganisationId: $ownedByOrganisationId
      status: $status
    ) {
      id
      submittedAt
      createdAt
      shippedAt
      status
      paymentStatus
      ownedByOrganisationId
      total {
        currency
        value
        includesTax
        taxPercent
      }
    }
  }
`
export const ADMIN_UPDATE_ORDER_PAYMENT_STATUS = gql`
  mutation updateOrderPaymentStatus(
    $id: ID!
    $ownedByOrganisationId: ID!
    $paymentStatus: PaymentStatus!
  ) {
    updateOrderPaymentStatus(
      id: $id
      ownedByOrganisationId: $ownedByOrganisationId
      paymentStatus: $paymentStatus
    ) {
      id
      submittedAt
      createdAt
      shippedAt
      status
      paymentStatus
      ownedByOrganisationId
      total {
        currency
        value
        includesTax
        taxPercent
      }
    }
  }
`

export const CONTENT_UPDATE_ROW = gql`
  mutation updateContentRow($id: ID!, $pageId: ID!, $input: RowInput!) {
    updateContentRow(id: $id, pageId: $pageId, input: $input) {
      id
      sortOrder
    }
  }
`
export const ADMIN_UPDATE_CONTENT_PAGE = gql`
  mutation updateContentPage($id: ID!, $input: ContentPageInput!) {
    updateContentPage(id: $id, input: $input) {
      ...contentPageFragment
    }
  }
  ${ContentPageFragment}
`

export const ADMIN_UPDATE_COLLECTION_PRODUCT = gql`
  mutation updateCollectionProduct(
    $input: CollectionProductInput!
    $productId: ID!
    $collectionId: ID!
  ) {
    updateCollectionProduct(
      input: $input
      productId: $productId
      collectionId: $collectionId
    ) {
      featured
      sortOrder
      collectionId
      productId
      product {
        id
      }
    }
  }
`
export const ADMIN_UPDATE_COLLECTION_PRODUCT_PACKAGE = gql`
  mutation updateCollectionProductPackage(
    $input: CollectionProductPackageInput!
    $productPackageId: ID!
    $collectionId: ID!
  ) {
    updateCollectionProductPackage(
      input: $input
      productPackageId: $productPackageId
      collectionId: $collectionId
    ) {
      featured
      sortOrder
      collectionId
      productPackageId
      productPackage {
        id
      }
    }
  }
`

export const ADMIN_CREATE_COLLECTION_RELATION = gql`
  mutation createCollectionRelation($parentId: ID!, $childId: ID!) {
    createCollectionRelation(parentId: $parentId, childId: $childId) {
      sortOrder
      parentId
      childId
    }
  }
`
export const ADMIN_UPDATE_COLLECTION_RELATION = gql`
  mutation updateCollectionRelation(
    $parentId: ID!
    $childId: ID!
    $input: CollectionRelationInput!
  ) {
    updateCollectionRelation(
      parentId: $parentId
      childId: $childId
      input: $input
    ) {
      id
      sortOrder
      parentId
      childId
    }
  }
`

export const ADMIN_DELETE_COLLECTION_RELATION = gql`
  mutation deleteCollectionRelation($parentId: ID!, $childId: ID!) {
    deleteCollectionRelation(parentId: $parentId, childId: $childId)
  }
`
export const ADMIN_CREATE_COLLECTION_PRODUCT = gql`
  mutation createCollectionProduct(
    $shopId: ID!
    $productId: ID!
    $collectionId: ID!
  ) {
    createCollectionProduct(
      shopId: $shopId
      productId: $productId
      collectionId: $collectionId
    ) {
      featured
      sortOrder
      collectionId
      collection {
        products {
          product {
            id
          }
        }
      }
      productId
      product {
        id
      }
    }
  }
`
export const ADMIN_CREATE_COLLECTION_PRODUCT_PACKAGE = gql`
  mutation createCollectionProductPackage(
    $shopId: ID!
    $productPackageId: ID!
    $collectionId: ID!
  ) {
    createCollectionProductPackage(
      shopId: $shopId
      productPackageId: $productPackageId
      collectionId: $collectionId
    ) {
      featured
      sortOrder
      collectionId
      productPackageId
      productPackage {
        id
      }
    }
  }
`

export const ADMIN_DELETE_COLLECTION_PRODUCT_PACKAGE = gql`
  mutation deleteCollectionProductPackage(
    $productPackageId: ID!
    $collectionId: ID!
  ) {
    deleteCollectionProductPackage(
      productPackageId: $productPackageId
      collectionId: $collectionId
    )
  }
`
export const ADMIN_DELETE_COLLECTION_PRODUCT = gql`
  mutation deleteCollectionProduct($productId: ID!, $collectionId: ID!) {
    deleteCollectionProduct(productId: $productId, collectionId: $collectionId)
  }
`

export const ADMIN_CREATE_CONTENT_PAGE_TAG = gql`
  mutation createContentPageTag($shopId: ID!, $pageId: ID!, $tagId: ID!) {
    createContentPageTag(shopId: $shopId, pageId: $pageId, tagId: $tagId) {
      id
      tagId
      pageId
      tag {
        ...pageTagFragment
      }
    }
  }
  ${PageTagFragment}
`
export const ADMIN_CREATE_PAGE_TAG = gql`
  mutation createPageTag($shopId: ID!) {
    createPageTag(shopId: $shopId) {
      ...pageTagFragment
    }
  }
  ${PageTagFragment}
`
export const ADMIN_UPDATE_PAGE_TAG = gql`
  mutation updatePageTag($id: ID!, $input: PageTagInput!) {
    updatePageTag(id: $id, input: $input) {
      ...pageTagFragment
    }
  }
  ${PageTagFragment}
`

export const ADMIN_DELETE_PAGE_TAG = gql`
  mutation deletePageTag($id: ID!) {
    deletePageTag(id: $id)
  }
`

export const ADMIN_DELETE_CONTENT_PAGE_TAG = gql`
  mutation deleteContentPageTag($pageId: ID!, $tagId: ID!) {
    deleteContentPageTag(pageId: $pageId, tagId: $tagId)
  }
`

export const ADMIN_CREATE_PRODUCT_VARIANT = gql`
  mutation createProductVariant($productId: ID!, $variantId: ID!) {
    createProductVariant(productId: $productId, variantId: $variantId) {
      id
      options
    }
  }
`

export const ADMIN_DELETE_PRODUCT_VARIANT = gql`
  mutation deleteProductVariant($productId: ID!, $variantId: ID!) {
    deleteProductVariant(productId: $productId, variantId: $variantId)
  }
`

export const ADMIN_UPDATE_PRODUCT_OPTION_VALUE = gql`
  mutation updateProductOptionValue(
    $productId: ID!
    $optionValues: String!
    $input: ProductOptionValueInput!
  ) {
    updateProductOptionValue(
      productId: $productId
      optionValues: $optionValues
      input: $input
    ) {
      ...productOptionValueFragment
    }
  }
  ${ProductOptionValueFragment}
`
export const ADMIN_UPDATE_PRODUCT_VARIANT = gql`
  mutation updateProductVariant(
    $productId: ID!
    $variantId: ID!
    $input: ProductVariantInput!
  ) {
    updateProductVariant(
      productId: $productId
      variantId: $variantId
      input: $input
    ) {
      id
      options
    }
  }
`
export const ADMIN_CREATE_ASSIGNED_PRODUCT_ATTRIBUTE = gql`
  mutation createAssignedProductAttribute(
    $shopId: ID!
    $productId: ID!
    $productAttributeId: ID!
  ) {
    createAssignedProductAttribute(
      shopId: $shopId
      productId: $productId
      productAttributeId: $productAttributeId
    ) {
      id
    }
  }
`
export const ADMIN_DELETE_ASSIGNED_PRODUCT_ATTRIBUTE = gql`
  mutation deleteAssignedProductAttribute(
    $productId: ID!
    $productAttributeId: ID!
  ) {
    deleteAssignedProductAttribute(
      productId: $productId
      productAttributeId: $productAttributeId
    )
  }
`

export const ADMIN_CREATE_DISCOUNT = gql`
  mutation createDiscount($shopId: ID!, $first: Int) {
    createDiscount(shopId: $shopId) {
      ...discountFragment
    }
  }
  ${DiscountFragment}
`
export const ADMIN_CREATE_DISCOUNT_RULE = gql`
  mutation createDiscountRule($discountId: ID!, $input: DiscountRuleInput!) {
    createDiscountRule(discountId: $discountId, input: $input) {
      ...discountRuleFragment
    }
  }
  ${DiscountRuleFragment}
`
export const ADMIN_CREATE_DISCOUNT_RULE_OBJECT = gql`
  mutation createDiscountRuleObject(
    $discountId: ID!
    $input: DiscountRuleObjectInput!
  ) {
    createDiscountRuleObject(discountId: $discountId, input: $input) {
      ...discountRuleFragment
    }
  }
  ${DiscountRuleFragment}
`
export const ADMIN_UPDATE_DISCOUNT_RULE = gql`
  mutation updateDiscountRule(
    $discountId: ID!
    $id: ID!
    $input: DiscountRuleInput!
  ) {
    updateDiscountRule(discountId: $discountId, id: $id, input: $input) {
      ...discountRuleFragment
    }
  }
  ${DiscountRuleFragment}
`
export const ADMIN_DELETE_DISCOUNT = gql`
  mutation deleteDiscount($id: ID!) {
    deleteDiscount(id: $id)
  }
`
export const ADMIN_DELETE_DISCOUNT_RULE = gql`
  mutation deleteDiscountRule($discountId: ID!, $id: ID!) {
    deleteDiscountRule(discountId: $discountId, id: $id)
  }
`
export const ADMIN_DELETE_DISCOUNT_RULE_OBJECT = gql`
  mutation deleteDiscountRuleObject(
    $discountId: ID!
    $objectId: ID!
    $discountRuleId: ID!
  ) {
    deleteDiscountRuleObject(
      discountId: $discountId
      objectId: $objectId
      discountRuleId: $discountRuleId
    ) {
      ...discountRuleFragment
    }
  }
  ${DiscountRuleFragment}
`
export const ADMIN_UPDATE_DISCOUNT = gql`
  mutation updateDiscount($id: ID!, $input: DiscountInput!, $first: Int) {
    updateDiscount(id: $id, input: $input) {
      ...discountFragment
    }
  }
  ${DiscountFragment}
`

export const ADMIN_GENERATE_COUPONS = gql`
  mutation generateCoupons(
    $discountId: ID!
    $couponPrefix: String
    $couponSuffix: String
    $addAutoIncrement: Boolean
    $maxUsagePerCustomer: Int!
    $maxUsageOverall: Int!
    $numberOfRandomChars: Int!
    $numberOfCoupons: Int!
  ) {
    generateCoupons(
      discountId: $discountId
      couponPrefix: $couponPrefix
      addAutoIncrement: $addAutoIncrement
      couponSuffix: $couponSuffix
      maxUsagePerCustomer: $maxUsagePerCustomer
      maxUsageOverall: $maxUsageOverall
      numberOfRandomChars: $numberOfRandomChars
      numberOfCoupons: $numberOfCoupons
    ) {
      id
      couponCode
    }
  }
`
export const ADMIN_CREATE_COUPON = gql`
  mutation createCoupon(
    $shopId: ID!
    $couponCode: String!
    $input: CouponInput
    $first: Int
  ) {
    createCoupon(shopId: $shopId, couponCode: $couponCode, input: $input) {
      ...couponFragment
    }
  }
  ${CouponFragment}
`
export const ADMIN_UPDATE_COUPON = gql`
  mutation updateCoupon(
    $id: ID!
    $couponCode: String!
    $input: CouponInput!
    $first: Int
  ) {
    updateCoupon(id: $id, couponCode: $couponCode, input: $input) {
      ...couponFragment
    }
  }
  ${CouponFragment}
`

export const ADMIN_UPDATE_INDEX_PAGE = gql`
  mutation adminUpdateIndexPage($id: ID!, $input: ShopContentSettingsInput!) {
    updateShopContentSettings(id: $id, input: $input) {
      id
      contentSettings {
        id
        indexPageId
      }
    }
  }
`

export const ADMIN_UPDATE_SHOP_CONTENT_SETTINGS = gql`
  mutation adminUpdateShopContentSettings(
    $id: ID!
    $input: ShopContentSettingsInput!
  ) {
    updateShopContentSettings(id: $id, input: $input) {
      id
      contentSettings {
        id
        themeId
        indexPageId
        indexPage {
          id
          title {
            id
            text {
              langCode
              content
            }
          }
        }
        cookieNote {
          id
          text {
            langCode
            content
          }
        }
        themeId
        theme {
          ...themeFragment
        }
      }
    }
  }
  ${ThemeFragment}
`

export const ADMIN_UPDATE_CONTENT_THEME = gql`
  mutation updateContentTheme($id: ID!, $input: ContentThemeInput!) {
    updateContentTheme(id: $id, input: $input) {
      id
      ...themeFragment
    }
  }
  ${ThemeFragment}
`

export const CONTENT_CREATE_ROW = gql`
  mutation createContentRow($pageId: ID!, $input: RowInput!) {
    createContentRow(pageId: $pageId, input: $input) {
      id
      sortOrder
      verticalAlign
      columns {
        id
      }
    }
  }
`

export const CONTENT_CREATE_COLUMN = gql`
  mutation createContentColumn(
    $pageId: ID!
    $rowId: ID!
    $input: ColumnInput!
  ) {
    createContentColumn(pageId: $pageId, rowId: $rowId, input: $input) {
      ...contentColumnFragment
      columnRows {
        id
      }
    }
  }
  ${ContentColumnFragment}
`

export const CONTENT_UPDATE_COLUMN = gql`
  mutation updateContentColumn($pageId: ID!, $id: ID!, $input: ColumnInput!) {
    updateContentColumn(pageId: $pageId, id: $id, input: $input) {
      id
      pageId
      sortOrder
      width
      height
      backgroundColor
      padding
      maxWidth
      verticalAlign
    }
  }
`

export const CONTENT_CREATE_COLUMN_ROW = gql`
  mutation createContentColumnRow(
    $input: ColumnRowInput!
    $pageId: ID!
    $columnId: ID!
  ) {
    createContentColumnRow(
      columnId: $columnId
      pageId: $pageId
      input: $input
    ) {
      id
      alignmentType
      alignment
      columns
      sortOrder
      columnId
      verticalAlign
      columnRowDetail {
        id
        type
        ... on ContentDetailDiscoverCard {
          ...contentDetailDiscoverCardFragment
        }
        ... on ContentDetailBanner {
          ...contentDetailBannerFragment
        }
        ... on ContentDetailText {
          ...contentDetailTextFragment
        }
        ... on ContentDetailImage {
          ...contentDetailImageFragment
        }
        ... on ContentDetailMedia {
          url
          width
          height
        }
        ... on ContentDetailCard {
          ...contentDetailCardFragment
        }
        ... on ContentDetailHubspotForm {
          ...contentDetailHubspotFormFragment
        }
        ... on ContentDetailSubscribeCard {
          ...contentDetailSubscribeCardFragment
        }
        ... on ContentDetailContactCard {
          ...contentDetailContactCardFragment
        }
        ... on ContentDetailLocationCard {
          ...contentDetailLocationCardFragment
        }
        ... on ContentDetailButton {
          ...contentDetailButtonFragment
        }
        ... on ContentDetailInputField {
          ...contentDetailInputFieldFragment
        }
        ... on ContentDetailDivider {
          ...contentDetailDividerFragment
        }
        ... on ContentDetailSocialMedia {
          username
          socialMediaType
        }
        ... on ContentDetailCollectionCard {
          ...contentDetailCollectionCardFragment
        }
        ... on ContentDetailProductCard {
          ...contentDetailProductCardFragment
        }
        ... on ContentDetailTeamstoreCard {
          ...contentDetailTeamstoreCardFragment
        }
      }
    }
  }
  ${ContentDetailCollectionCardFragment}
  ${ContentDetailProductCardFragment}
  ${ContentDetailTeamstoreCardFragment}
  ${ContentDetailBannerFragment}
  ${ContentDetailTextFragment}
  ${ContentDetailImageFragment}
  ${ContentDetailCardFragment}
  ${ContentDetailHubspotFormFragment}
  ${ContentDetailSubscribeCardFragment}
  ${ContentDetailContactCardFragment}
  ${ContentDetailLocationCardFragment}
  ${ContentDetailButtonFragment}
  ${ContentDetailInputFieldFragment}
  ${ContentDetailDividerFragment}
  ${ContentDetailDiscoverCardFragment}
`

export const CONTENT_UPDATE_COLUMN_ROW = gql`
  mutation updateContentColumnRow(
    $pageId: ID!
    $id: ID!
    $input: ColumnRowInput!
  ) {
    updateContentColumnRow(pageId: $pageId, id: $id, input: $input) {
      id
      alignment
      alignmentType
      sortOrder
      columnRowDetail {
        id
        type
        ... on ContentDetailDiscoverCard {
          ...contentDetailDiscoverCardFragment
        }
        ... on ContentDetailBanner {
          ...contentDetailBannerFragment
        }
        ... on ContentDetailText {
          ...contentDetailTextFragment
        }
        ... on ContentDetailMedia {
          url
          width
          height
        }
        ... on ContentDetailImage {
          ...contentDetailImageFragment
        }
        ... on ContentDetailCard {
          ...contentDetailCardFragment
        }
        ... on ContentDetailHubspotForm {
          ...contentDetailHubspotFormFragment
        }
        ... on ContentDetailSubscribeCard {
          ...contentDetailSubscribeCardFragment
        }
        ... on ContentDetailContactCard {
          ...contentDetailContactCardFragment
        }
        ... on ContentDetailLocationCard {
          ...contentDetailLocationCardFragment
        }
        ... on ContentDetailButton {
          ...contentDetailButtonFragment
        }
        ... on ContentDetailInputField {
          ...contentDetailInputFieldFragment
        }
        ... on ContentDetailDivider {
          ...contentDetailDividerFragment
        }
        ... on ContentDetailCollectionCard {
          ...contentDetailCollectionCardFragment
        }
        ... on ContentDetailProductCard {
          ...contentDetailProductCardFragment
        }
        ... on ContentDetailTeamstoreCard {
          ...contentDetailTeamstoreCardFragment
        }
      }
    }
  }
  ${ContentDetailCollectionCardFragment}
  ${ContentDetailProductCardFragment}
  ${ContentDetailTeamstoreCardFragment}
  ${ContentDetailBannerFragment}
  ${ContentDetailTextFragment}
  ${ContentDetailImageFragment}
  ${ContentDetailCardFragment}
  ${ContentDetailHubspotFormFragment}
  ${ContentDetailSubscribeCardFragment}
  ${ContentDetailContactCardFragment}
  ${ContentDetailLocationCardFragment}
  ${ContentDetailButtonFragment}
  ${ContentDetailInputFieldFragment}
  ${ContentDetailDividerFragment}
  ${ContentDetailDiscoverCardFragment}
`

export const CONTENT_DELETE_ROW = gql`
  mutation deleteContentRow($id: ID!, $pageId: ID!) {
    deleteContentRow(id: $id, pageId: $pageId)
  }
`

export const CONTENT_DELETE_COLUMN = gql`
  mutation deleteContentColumn($id: ID!, $pageId: ID!) {
    deleteContentColumn(id: $id, pageId: $pageId)
  }
`

export const CONTENT_DELETE_COLUMN_ROW = gql`
  mutation deleteContentColumnRow($id: ID!, $pageId: ID!) {
    deleteContentColumnRow(id: $id, pageId: $pageId)
  }
`

export const CONTENT_CREATE_PAGE = gql`
  mutation createContentPage($input: ContentPageInput!) {
    createContentPage(input: $input) {
      ...contentPageFragment
    }
  }
  ${ContentPageFragment}
`

export const CONTENT_DELETE_PAGE = gql`
  mutation deleteContentPage($id: ID!) {
    deleteContentPage(id: $id)
  }
`

export const ADMIN_CREATE_EXPORT_REPORT_REQUEST = gql`
  mutation createExportReportRequest($input: ReportRequestInput!) {
    createExportReportRequest(input: $input) {
      id
      reportType
      fields
      filter {
        fromTimestamp
        untilTimestamp
        discountId
      }
      createdBy
      createdAt
    }
  }
`

export const CONTENT_CREATE_MODULE = gql`
  mutation createContentModule($input: ContentModuleInput!) {
    createContentModule(input: $input) {
      id
      alignment
      alignmentType
      column
      label
      contentDetail {
        id
        type
        ... on ContentDetailSocialLinks {
          socialLinks {
            externalLink
            icon
          }
        }
        ... on ContentDetailPaymentOptions {
          paymentOptions {
            name
            icon
          }
        }
        ... on ContentDetailShopLogo {
          color
          isAlternative
          isLink
          width
          height
          maxWidth
          maxHeight
        }
      }
    }
  }
`

export const CONTENT_UPDATE_MODULE = gql`
  mutation updateContentModule($id: ID!, $input: ContentModuleInput!) {
    updateContentModule(id: $id, input: $input) {
      alignment
      alignmentType
      column
      label
      contentDetail {
        id
        type
        ... on ContentDetailSocialLinks {
          socialLinks {
            externalLink
            icon
          }
        }
        ... on ContentDetailPaymentOptions {
          paymentOptions {
            name
            icon
          }
        }
        ... on ContentDetailShopLogo {
          color
          isAlternative
          isLink
          width
          height
          maxWidth
          maxHeight
        }
      }
    }
  }
`

export const CONTENT_DELETE_MODULE = gql`
  mutation deleteContentModule($id: ID!) {
    deleteContentModule(id: $id)
  }
`

export const CONTENT_CREATE_MENU_LOCATION = gql`
  mutation createContentMenuLocation($input: ShopContentMenuInput!) {
    createContentMenuLocation(input: $input) {
      ...menuFragment
    }
  }
  ${MenuFragment}
`

export const CONTENT_UPDATE_MENU_LOCATION = gql`
  mutation updateContentMenuLocation($id: ID!, $input: ShopContentMenuInput!) {
    updateContentMenuLocation(id: $id, input: $input) {
      ...menuFragment
    }
  }
  ${MenuFragment}
`

export const CONTENT_DELETE_MENU_LOCATION = gql`
  mutation deleteContentMenuLocation($id: ID!) {
    deleteContentMenuLocation(id: $id)
  }
`

export const ADMIN_DOWNLOAD_WINGS_XML = gql`
  mutation downloadWingsXML($id: ID!) {
    downloadWingsXML(id: $id)
  }
`

export const ADMIN_CREATE_SHIPPING_LABEL = gql`
  mutation createShippingLabel($orderId: ID!, $ownedByOrganisationId: ID!) {
    createShippingLabel(
      orderId: $orderId
      ownedByOrganisationId: $ownedByOrganisationId
    ) {
      id
      orderId
      shippingMethodId
      shippingProviderName
      status
      files {
        id
        type
        url
      }
      trackingNumbers
      order {
        id
        shippingLabels {
          id
          orderId
          shippingMethodId
          shippingProviderName
          status
          files {
            id
            type
            url
          }
          trackingNumbers
        }
      }
    }
  }
`
