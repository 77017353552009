export const NAVIGATION_LINK_INPUTS = {
  alignment: null,
  collectionId: null,
  externalLinks: [],
  linkTitle: {
    id: null,
    text: [],
    __typename: 'Text'
  },
  moduleId: null,
  pageId: null,
  parentId: null,
  productId: null,
  sortOrder: 1,
  styling: null,
  page: null,
  collection: null,
  module: null,
  product: null,
  __typename: 'ContentNavigationLink'
};
