import React from 'react'
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} = require('react-google-maps')

const Maps: any = withScriptjs(
  withGoogleMap((props: any) => (
    <GoogleMap
      options={{
        disableDefaultUI: true
      }}
      defaultZoom={8}
      defaultCenter={{
        lat: props.lat ? props.lat : -34.397,
        lng: props.lng ? props.lng : 150.644
      }}
    >
      {props.isMarkerShown && (
        <Marker
          position={{
            lat: props.lat ? props.lat : -34.397,
            lng: props.lng ? props.lng : 150.644
          }}
        />
      )}
    </GoogleMap>
  ))
)

export default Maps
