import React from 'react'
import { CartModuleType } from '../Types'
import Title from '../Title'
import CartActions from './CartActions'
import CartProductList from './CartProductList'

interface Props {
  moduleType: CartModuleType
}

const CartPageContainer: React.FC<Props> = props => {
  const { moduleType } = props
  switch (moduleType) {
    case 'CART_TITLE':
      return <Title text="Shopping basket" />
    case 'CART_TABLE':
      return <CartProductList />
    case 'CART_ACTIONS':
      return <CartActions />
    default:
      return <h2>{moduleType}</h2>
  }
}

export default CartPageContainer
