import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Imgix from 'react-imgix'

interface Props {
  title?: any
  image?: any
}

const MainBanner: React.FC<Props> = props => {
  const { title, image } = props
  return (
    <Grid
      container={true}
      direction="column"
      justify="center"
      alignItems="center"
      style={{
        position: 'relative',
        width: '100%',
        height: '400px'
      }}
    >
      <Grid
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
          height: '100%'
        }}
        item
        xs={12}
      >
        <Imgix
          htmlAttributes={{
            alt: 'collection-banner',
            style: {
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }
          }}
          src={
            image
              ? image
              : 'https://dev.cdn.mybrikl.com/patrik/media/2ff5b8fa-f2a3-4841-9f60-c8bf43f04674/banner-1050629_1920-W_xHbRqU.jpg?auto=compress&fit=crop'
          }
          imgixParams={{
            auto: 'compress'
          }}
        />
      </Grid>
      <Grid
        style={{
          zIndex: 1,
          alignSelf: 'left'
        }}
        item
      >
        <Typography
          style={{
            color: '#fff',
            padding: '0px 0px 20px 100px'
          }}
          variant="h1"
        >
          {title?.defaultValue || 'Title'}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default MainBanner
