import React, { Component } from 'react';
import { Grid, Typography, Paper, Input, InputLabel } from '@material-ui/core';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { languages } from '../../../../helper/config';
const uuidv4 = require('uuid/v4');

const ProductDescription = ({
  classes,
  languages,
  product,
  productId,
  updateProduct,
  updateMultilangDetail
}) => {
  const descriptionId =
    product.description && product.description.id
      ? product.description.id
      : uuidv4();
  return (
    <Grid
      container
      alignItems="flex-start"
      spacing={1}
      className={classes.container}
    >
      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <Grid
          container
          style={{ padding: '24px 24px 24px 0px' }}
          spacing={1}
          alignItems="center"
        >
          <Grid item>
            <Typography
              data-cy="Product-Description-header"
              variant="subtitle1"
              style={{ marginRight: 16 }}
            >
              Product description
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
        <Paper style={{ padding: 24, borderRadius: 15 }} elevation={0}>
          {languages.map((language, index) => {
            const descriptionText = getTextFromLanguage(
              product.description.text,
              language
            );
            return (
              <Grid
                container
                key={'style-descriptionText-' + index}
                direction="row"
                alignItems="flex-start"
                spacing={1}
                className={classes.container}
              >
                <Grid item xs={10}>
                  <InputLabel>Description</InputLabel>
                  <Input
                    dataCy={'Product-Description-' + language}
                    label="Description"
                    placeholder="Enter description"
                    multiline={true}
                    rowsMax="4"
                    defaultValue={descriptionText}
                    onChange={e => {
                      updateMultilangDetail(
                        productId,
                        'description',
                        e.target.value,
                        descriptionId,
                        language,
                        product
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    style={{
                      height: 38,
                      width: '100%',
                      border: '1px solid #ddd',
                      borderRadius: '3px',
                      marginTop: 24
                    }}
                  >
                    {language}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProductDescription;
