import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography, Switch } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Slider from '@material-ui/core/Slider';
import DeleteImage from './DeleteImage';
import Uploader from './Uploader';
import ColorPicker from './ColorPicker';
import AlignItems from './AlignItems';
import NativeSelect from '@material-ui/core/NativeSelect';

import {
  FONTS,
  COPYRIGHT_IMAGE_VERTICAL_LOCATION,
  COPYRIGHT_IMAGE_HORIZONTAL_LOCATION,
  IMAGE_FIT
} from './config';

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase);

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    border: '1px solid #0000001f'
  },
  input: {
    paddingLeft: 8,
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  },
  slider: {
    padding: '22px 0px'
  }
});

class UpdateImage extends React.Component {
  state = {
    uploading: false
  };

  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,
      fields,
      shopId
    } = this.props;

    return (
      <ExpansionPanel
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
        expanded={expanded === 'panel2'}
        onChange={handleExpandedChange('panel2')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ expanded: classes.expansionPanelExpanded }}
        >
          <Typography
            variant="subtitle1"
            className={classes.typo}
            data-cy="Image-Settings"
          >
            Image
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{ root: classes.expansionPanelDetails }}
        >
          <Divider />
          <List style={{ width: '100%' }}>
            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Image URL
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <Uploader
                    shopId={shopId}
                    image={fields.imageUrl}
                    isCDNField={false}
                    field="imageUrl"
                    handleChangeField={handleChangeField}
                    copyright={fields.copyrightContent ? true : false}
                  />
                </Paper>

                <DeleteImage
                  image={fields.imageUrl}
                  field="imageUrl"
                  handleDelete={handleChangeField}
                />
              </Grid>
            </ListItem>

            <Grid>
              <ListItem>
                <Grid container direction="column">
                  <InputLabel shrink className={classes.FormLabel}>
                    Copyright Content
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <InputBase
                      className={classes.input}
                      placeholder=""
                      value={fields.copyrightContent}
                      onChange={e =>
                        handleChangeField(
                          e.target.value ? e.target.value : null,
                          'copyrightContent'
                        )
                      }
                    />
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel shrink className={classes.FormLabel}>
                    Copyright Content Vertical Alignment
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      native="true"
                      className={classes.input}
                      value={fields.copyrightVerticalAlignment}
                      onChange={e =>
                        handleChangeField(
                          e.target.value ? e.target.value : null,
                          'copyrightVerticalAlignment'
                        )
                      }
                      input={
                        <SelectInput
                          name="font"
                          id="age-customized-native-simple"
                        />
                      }
                    >
                      {COPYRIGHT_IMAGE_VERTICAL_LOCATION.map(verticalAlign => (
                        <option key={verticalAlign} value={verticalAlign}>
                          {verticalAlign}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel shrink className={classes.FormLabel}>
                    Copyright Content Horizontal Alignment
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      native="true"
                      className={classes.input}
                      value={fields.copyrightHorizontalAlignment}
                      onChange={e =>
                        handleChangeField(
                          e.target.value ? e.target.value : null,
                          'copyrightHorizontalAlignment'
                        )
                      }
                      input={
                        <SelectInput
                          name="font"
                          id="age-customized-native-simple"
                        />
                      }
                    >
                      {COPYRIGHT_IMAGE_HORIZONTAL_LOCATION.map(
                        horizontalAlign => (
                          <option key={horizontalAlign} value={horizontalAlign}>
                            {horizontalAlign}
                          </option>
                        )
                      )}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel shrink className={classes.FormLabel}>
                    Copyright Content FontFamily
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      native="true"
                      className={classes.input}
                      value={fields.copyrightFontFamily}
                      onChange={e =>
                        handleChangeField(
                          e.target.value ? e.target.value : null,
                          'copyrightFontFamily'
                        )
                      }
                      input={
                        <SelectInput
                          name="font"
                          id="age-customized-native-simple"
                        />
                      }
                    >
                      {FONTS.map(font => (
                        <option key={font} value={font}>
                          {font}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel shrink className={classes.FormLabel}>
                    Copyright Content Color
                  </InputLabel>
                  <ColorPicker
                    color={fields.copyrightFontColor}
                    fieldName={'copyrightFontColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container direction="column">
                  <InputLabel shrink className={classes.FormLabel}>
                    Image object fit
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      native="true"
                      className={classes.input}
                      value={fields.objectFit}
                      onChange={e =>
                        handleChangeField(
                          e.target.value ? e.target.value : null,
                          'objectFit'
                        )
                      }
                      input={<SelectInput name="font" id="objectFit" />}
                    >
                      {IMAGE_FIT.map((fit, index) => (
                        <option key={index} value={fit}>
                          {fit}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>
            </Grid>
            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Image Description
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    className={classes.input}
                    placeholder=""
                    value={fields.imageAlt}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'imageAlt'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

UpdateImage.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string.isRequired
};

export default withStyles(styles)(UpdateImage);
