import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { OrganisationContext } from '../user/context/OrganisationContext';
import { ApolloConsumer } from 'react-apollo';
import { GET_USER } from '../../gql/queries';
import Auth from '@aws-amplify/auth';
import { Link } from 'gatsby';
import Input from '../common/Input';

const styles = theme => ({
  root: {
    background: '#f1f1f1',
    height: 'calc(100vh - 0px)',
    padding: '25px 15px',
    position: 'relative'
  },
  input: {
    background: '#f4f4f4 !important',
    margin: '16px 0px !important',
    width: '100%',
    padding: '0px !important'
  },
  button: {
    width: '100%',
    minHeight: '45px',
    margin: '24px 0px !important',
    fontSize: '16px'
  },
  textInput: {
    padding: '13px 24px'
  },
  forgotPassword: {
    position: 'absolute',
    bottom: '-16px',
    right: '8px',
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    ':hover': {
      color: '#ffffff !important'
    }
  },
  signup: {
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    texAlign: 'center',
    ':hover': {
      color: '#ffffff !important'
    }
  }
});

require('./admin-aws-config')
class Login extends React.Component {
  static defaultProps = {
    authData: {},
    authState: 'signIn'
    // onAuthStateChange: (next, data) => { console.log(`SignIn:onAuthStateChange(${next}, ${JSON.stringify(data, null, 2)})`); }
  };

  constructor(props) {
    super(props);
    this.checkContact = this.checkContact.bind(this);
    this.state = {
      authData: this.props.authData,
      authState: this.props.authState,
      modalShowing: false,
      loading: false,
      error: null,
      username: '',
      needPasswordChange: false,
      // username:
      //   this.props && this.props.currentUser && this.props.currentUser.email
      //     ? this.props.currentUser.email
      //     : '',
      password: '',
      user: null
    };
  }

  async onPasswordChange() {
    this.setState({ loading: true });
    try {
      if (this.state.newPassword === this.state.confirmNewPassword) {
        Auth.currentAuthenticatedUser()
          .then(user => {
            return Auth.changePassword(
              user,
              this.state.oldPassword,
              this.state.newPassword
            );
          })
          .then(data => console.log(data))
          .catch(err => {
            this.setState({ error: err.message || err, loading: false });
            console.log(err);
          });
      }
    } catch (err) {
      console.error(`Error: ${JSON.stringify(err, null, 2)}`);
      this.setState({ error: err.message, loading: false });
    }
  }

  updateConfirmPassword = e => {
    this.setState({
      confirmNewPassword: e.target.value
    });
  };
  updateNewPassword = e => {
    this.setState({
      newPassword: e.target.value
    });
  };
  updateOldPassword = e => {
    this.setState({
      oldPassword: e.target.value
    });
  };

  checkContact(user, currentOrganisation, client) {
    const { parent, currentUser } = this.props;
    Auth.verifiedContact(user)
      .then(async data => {
        console.log('data', data);
        if (Object.keys(data.verified).length !== 0) {
          try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            if (currentUser && cognitoUser) {
              await currentUser.changeEmail(cognitoUser.attributes.email);
              await currentUser.changeId(cognitoUser.username);
              try {
                window.Sentry.configureScope(scope => {
                  scope.setUser({ id: cognitoUser.username });
                });
              } catch (error) {}
            }
            client.resetStore();
            // TODO should update organisation context here
            navigate(`/`);
            window.location.reload();

            if (parent) {
              parent.setState({ loginModalOpen: false });
            }
          } catch (error) {
            console.error('Login.checkContact.error', error);
          }
        } else {
          navigate(`/admin/verify`);
        }
      })
      .catch(err => {
        console.error(`Error: ${JSON.stringify(err, null, 2)}`);
        this.setState({ error: err.message, loading: false });
      });
  }

  async onSignIn(currentOrganisation, client) {
    this.setState({ loading: true });
    try {
      Auth.signIn(this.state.username, this.state.password)
        .then(async user => {
          console.log(user);
          if (
            user.challengeName === 'SMS_MFA' ||
            user.challengeName === 'SOFTWARE_TOKEN_MFA'
          ) {
            console.log('confirm user with ' + user.challengeName);
            //   confirmSignIn
            navigate(`/admin/confirm`);
          } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            console.log('require new password', user.challengeParam);
            await Auth.completeNewPassword(
              user, // the Cognito User Object
              this.state.password // the new password
            )
              .then(async user => {
                // at this time the user is logged in if no MFA required
                console.log(user);
                await this.checkContact(user, currentOrganisation, client);
              })
              .catch(e => {
                console.log(e);
              });
            //   requireNewPassword
            // navigate(`/admin/newpassword`);
            // await Auth.currentSession()
          } else if (user.challengeName === 'MFA_SETUP') {
            console.log('TOTP setup', user.challengeParam);
            //   TOTPSetup
            navigate(`/admin/totp`);
          } else {
            await this.checkContact(user, currentOrganisation, client);
            //   await this.props.getUser()
          }
        })
        .catch(err => {
          console.log(`Error: ${JSON.stringify(err, null, 2)}`);
          this.setState({ error: err.message, loading: false });
        });
    } catch (err) {
      console.error(`Error: ${JSON.stringify(err, null, 2)}`);
      this.setState({ error: err.message, loading: false });
    }
  }

  updateUsername = e => {
    this.setState({
      username: e.target.value.toLowerCase()
    });
  };
  updatePassword = e => {
    this.setState({
      password: e.target.value
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <OrganisationContext.Consumer>
        {currentOrganisation => {
          return (
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
              className={classes.root}
            >
              <ApolloConsumer>
                {client => {
                  if (this.state.needPasswordChange === true) {
                    return (
                      <Grid
                        container
                        justify="center"
                        direction="column"
                        style={{
                          maxWidth: '350px',
                          padding: 24,
                          marginTop: '-100px'
                        }}
                        spacing={2}
                      >
                        {this.state.error ? (
                          <div>
                            <div>{this.state.error}</div>
                          </div>
                        ) : null}
                        Your password need to be changed
                        <Input
                          background="#fff"
                          data-html2canvas-ignore
                          data-cy="oldPassword"
                          type="password"
                          value={this.state.oldPassword}
                          onChange={val => this.updateOldPassword(val)}
                          placeholder="Current password"
                          classes={{
                            root: classes.input,
                            input: classes.textInput
                          }}
                        />
                        <div style={{ position: 'relative' }}>
                          <Input
                            background="#fff"
                            data-html2canvas-ignore
                            data-cy="newPassword"
                            type="password"
                            value={this.state.newPassword}
                            onChange={val => this.updateNewPassword(val)}
                            placeholder="New password"
                            classes={{
                              root: classes.input,
                              input: classes.textInput
                            }}
                          />
                        </div>
                        <div style={{ position: 'relative' }}>
                          <Input
                            background="#fff"
                            data-html2canvas-ignore
                            data-cy="confirmNewPassword"
                            type="password"
                            value={this.state.confirmNewPassword}
                            onChange={val => this.updateConfirmPassword(val)}
                            placeholder="Confirm password"
                            classes={{
                              root: classes.input,
                              input: classes.textInput
                            }}
                          />
                        </div>
                        {this.state.loading === true ? (
                          <Button
                            className={classes.button}
                            title="Login"
                            disabled={true}
                          >
                            Loading
                          </Button>
                        ) : (
                          <Button
                            data-cy="setNewPassword"
                            title="Set new password"
                            onClick={this.onPasswordChange.bind(this)}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            Set new password
                          </Button>
                        )}
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid
                        container
                        justify="center"
                        direction="column"
                        style={{
                          maxWidth: '350px',
                          padding: 24,
                          marginTop: '-100px'
                        }}
                        spacing={2}
                      >
                        <Grid
                          item
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Typography variant="h1">Admin Login</Typography>
                        </Grid>
                        <Grid
                          item
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          {this.state.error ? (
                            <div>
                              <div>{this.state.error}</div>
                            </div>
                          ) : null}
                        </Grid>
                        <Grid data-cy="login-email" item>
                          <Input
                            background="#fff"
                            data-html2canvas-ignore
                            value={this.state.username}
                            onKeyPress={e => {
                              if (e.keyCode === 13 || e.key === 'Enter') {
                                this.onSignIn(currentOrganisation, client);
                              }
                            }}
                            onChange={val => this.updateUsername(val)}
                            placeholder="Email"
                            label="Email"
                          />
                        </Grid>
                        <Grid
                          data-cy="login-password"
                          item
                          style={{ position: 'relative' }}
                        >
                          <Input
                            data-html2canvas-ignore
                            background="#fff"
                            type="password"
                            value={this.state.password}
                            onKeyPress={e => {
                              if (e.keyCode === 13 || e.key === 'Enter') {
                                this.onSignIn(currentOrganisation, client);
                              }
                            }}
                            onChange={val => this.updatePassword(val)}
                            placeholder="Password"
                            label="Password"
                          />
                          <Link
                            to={`/admin/forgot`}
                            className={classes.forgotPassword}
                          >
                            <Typography variant="body1">
                              forgot password?
                            </Typography>
                          </Link>
                        </Grid>
                        <Grid item>
                          {this.state.loading === true ? (
                            <Button
                              className={classes.button}
                              title="Login"
                              disabled={true}
                            >
                              Loading
                            </Button>
                          ) : (
                            <Button
                              data-cy="login-btn"
                              title="Login"
                              onClick={() =>
                                this.onSignIn(currentOrganisation, client)
                              }
                              variant="contained"
                              color="primary"
                              className={classes.button}
                            >
                              Log in
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    );
                  }
                }}
              </ApolloConsumer>
            </Grid>
          );
        }}
      </OrganisationContext.Consumer>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Login);
