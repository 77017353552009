import React from 'react'
import { Grid, Typography } from '@material-ui/core'

interface Props {
  type: 'header' | 'footer'
}

const PlaceholderText: React.FC<Props> = props => {
  const { type } = props
  return (
    <Grid
      container={true}
      justify="center"
      alignItems="center"
      style={{ height: 120, backgroundColor: 'lightgrey' }}
    >
      <Typography
        style={{
          color: 'black',
          fontSize: 18,
          fontStyle: 'italic',
          fontFamily: 'Poppins'
        }}
      >
        Add menus to display {type}..
      </Typography>
    </Grid>
  )
}

export default PlaceholderText
