import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import FileDrop from 'react-file-drop'
import { ADMIN_UPLOAD_FILE } from '../../../../../gql/admin-mutations'
import { uploadContentMedia, getImageUrl } from '../../../../content/utils'
import { CircularProgress, Grid } from '@material-ui/core'
import { ShopContext } from '../../LanguageContext'

interface Props {
  image: string
  contentId: string
  styleId: string
  fieldName: string
  level: 'root' | 'style'
  handleUpdate: any
}

const UploaderControl: React.FC<Props> = props => {
  const { image, contentId, styleId, fieldName, level, handleUpdate } = props
  const [isUploading, setIsUploading] = useState(false)
  const [img, setImg] = useState(image ? image : null)
  const [uploadFile] = useMutation(ADMIN_UPLOAD_FILE)

  const shopId = useContext(ShopContext)

  return (
    <Grid container={true} direction="column" justify="center">
      <div
        style={{
          border: '1px solid #E5E5E5',
          borderRadius: '3px',
          marginTop: '16px'
        }}
      >
        <FileDrop
          onDrop={async (files: any) => {
            try {
              setIsUploading(true)
              const newFileName = await uploadContentMedia(files, uploadFile)
              setImg(newFileName)
              const value = await getImageUrl(newFileName, shopId)
              handleUpdate(contentId, fieldName, value, level, styleId)
            } catch (error) {
              console.log(error)
            }
            setIsUploading(false)
          }}
        >
          Drop your image here!
        </FileDrop>
      </div>
      {isUploading ? <CircularProgress /> : null}
      <Grid
        container={true}
        wrap="nowrap"
        style={{
          height: '64px',
          width: '100%',
          marginTop: '8px'
        }}
      >
        {img ? (
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              border: '1px solid #E5E5E5',
              borderRadius: '3px'
            }}
            src={getImageUrl(img, shopId)}
            alt={getImageUrl(img, shopId)}
            title={getImageUrl(img, shopId)}
          />
        ) : null}
      </Grid>
    </Grid>
  )
}

export default UploaderControl
