import React, { Component } from 'react';

export default class RightClickIcon extends React.Component {
  render() {
    const {
      width = 80,
      height = 80,
      background,
      padding,
      fill5 = '#c4c4c4'
    } = this.props;
    return (
      <div style={{ width: width, height: height, padding: padding }}>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            .rightClick-1,.rightClick-2,.rightClick-3{fill:none;}.rightClick-1{stroke:#4d4d4d;}.rightClick-1,.rightClick-2,.rightClick-3,.rightClick-4{stroke-miterlimit:10;}.rightClick-1,.rightClick-3,.rightClick-4{stroke-width:2px;}.rightClick-2{stroke:#939393;stroke-width:3px;}.rightClick-3,.rightClick-4{stroke:#000;}.rightClick-4{fill:#fff;}.rightClick-5{fill:${fill5};
        `
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 92.86 132.2"
          style={{ width: '100%', height: '100%' }}
        >
          <defs />
          <title>Asset 2</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <circle class="rightClick-1" cx="68.88" cy="23.98" r="14.7" />
              <circle class="rightClick-2" cx="68.88" cy="23.98" r="22.48" />
              <circle class="rightClick-3" cx="69.31" cy="23.55" r="7.35" />
              <rect
                class="rightClick-4"
                x="1"
                y="14.47"
                width="81.71"
                height="116.73"
                rx="40.85"
              />
              <path
                class="rightClick-5"
                d="M43.3,17.93H42.07V58.66H79.29V56.49C79.29,35.19,63.18,17.93,43.3,17.93Z"
              />
              <polyline
                class="rightClick-3"
                points="42.07 14.47 42.07 58.66 82.71 58.66"
              />
              <rect
                class="rightClick-4"
                x="38.13"
                y="29.44"
                width="8.32"
                height="17.22"
                rx="4.11"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
