import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
const uuidv4 = require('uuid/v4');
const omitDeep = require('omit-deep');
const ProductPackageTitle = ({
  classes,
  languages,
  productPackage,
  productPackageId,
  updateProductPackage
}) => {
  const titleId =
    productPackage.title && productPackage.title.id
      ? productPackage.title.id
      : uuidv4();
  return (
    <Grid container>
      {languages.map((language, index) => {
        var titleText = '';
        try {
          titleText = getTextFromLanguage(productPackage.title.text, language);
        } catch (error) {}
        return (
          <Grid
            container
            key={'style-titleText-' + index}
            direction="row"
            alignItems="flex-end"
            spacing={1}
            className={classes.container}
          >
            <Grid item xs={10}>
              <TextField
                dataCy={'ProductPackage-Title-' + language}
                label="Title"
                placeholder="Enter title"
                multiline={true}
                rowsMax="4"
                value={titleText}
                onBlur={e => {
                  // update product slugs based on title
                  var newSlugs = productPackage.slugs;
                  if (e.target.value) {
                    if (
                      productPackage.slugs == null ||
                      getTextFromLanguage(
                        productPackage.slugs.text,
                        language
                      ) === '' ||
                      getTextFromLanguage(
                        productPackage.slugs.text,
                        language
                      ) === null
                    ) {
                      const cleanSlug = e.target.value
                        .replace(/[^a-z0-9]+/gi, '')
                        .toLowerCase();
                      newSlugs = productPackage.slugs
                        ? productPackage.slugs.map(s => {
                            return {
                              ...s,
                              content:
                                s.langCode === language &&
                                (s.content === '' || s.content === null)
                                  ? cleanSlug
                                  : s.content,
                              __typename: 'SlugLocalised'
                            };
                          })
                        : [
                            {
                              langCode: language,
                              content: cleanSlug,
                              __typename: 'SlugLocalised'
                            }
                          ];
                    }
                  }
                  updateProductPackage({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      updateProductPackage: {
                        ...productPackage,
                        slugs: newSlugs
                      }
                    },
                    variables: {
                      id: productPackageId,
                      input: {
                        slugs: omitDeep(newSlugs, ['__typename'])
                      }
                    }
                  });
                }}
                onChange={e => {
                  // update product slugs based on title
                  var newSlugs = productPackage.slugs;
                  if (e.target.value) {
                    if (
                      productPackage.slugs == null ||
                      getTextFromLanguage(
                        productPackage.slugs.text,
                        language
                      ) === ''
                    ) {
                      const cleanSlug = e.target.value
                        .replace(/[^a-z0-9]+/gi, '')
                        .toLowerCase();
                      newSlugs = productPackage.slugs
                        ? productPackage.slugs.map(s => {
                            return {
                              ...s,
                              content:
                                s.langCode === language &&
                                (s.content === '' || s.content === null)
                                  ? cleanSlug
                                  : s.content
                            };
                          })
                        : [
                            {
                              langCode: language,
                              content: cleanSlug,
                              __typename: 'SlugLocalised'
                            }
                          ];
                    }
                  }
                  updateProductPackage({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      updateProductPackage: {
                        ...productPackage,
                        slugs: newSlugs,
                        title: {
                          id: titleId,
                          text: [
                            ...languages.map((tempLanguage, index) => {
                              return {
                                langCode: tempLanguage,
                                content:
                                  tempLanguage === language
                                    ? e.target.value
                                    : getTextFromLanguage(
                                        productPackage.title.text,
                                        tempLanguage
                                      ),
                                __typename: 'TextContent'
                              };
                            })
                          ],
                          __typename: 'Text'
                        }
                      }
                    },
                    variables: {
                      id: productPackageId,
                      input: {
                        slugs: omitDeep(newSlugs, ['__typename']),
                        title: {
                          id: titleId,
                          text: [
                            ...languages.map((tempLanguage, index) => {
                              return {
                                langCode: tempLanguage,
                                content:
                                  tempLanguage === language
                                    ? e.target.value
                                    : getTextFromLanguage(
                                        productPackage.title.text,
                                        tempLanguage
                                      )
                              };
                            })
                          ]
                        }
                      }
                    }
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  height: 38,
                  width: '100%',
                  border: '1px solid #ddd',
                  borderRadius: '3px'
                }}
              >
                {language}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ProductPackageTitle;
