import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query, Mutation } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import { ADMIN_GET_SHOP_SETTINGS } from '../../gql/admin-queries';
import { ADMIN_CREATE_PRINTINGTECHNIQUE } from '../../gql/admin-mutations';
import { navigate } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {},
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242',
    flex: 1
  }
});

class PrintingList extends Component {
  render() {
    const { shopId, classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Typography
            component="h1"
            className={classes.h1}
            data-cy="Header-Printing-Techniques"
          >
            Printing Techniques
          </Typography>
          <Mutation
            mutation={ADMIN_CREATE_PRINTINGTECHNIQUE}
            variables={{ shopId }}
            update={(cache, { data: { createPrintingTechnique } }) => {
              if (createPrintingTechnique.id) {
                navigate(
                  `/admin/${shopId}/inventory/printing/${
                    createPrintingTechnique.id
                  }`
                );
                const data = cache.readQuery({
                  query: ADMIN_GET_SHOP_SETTINGS,
                  variables: { id: shopId }
                });
                data.shop.printingTechniques.edges.unshift({
                  node: {
                    ...createPrintingTechnique
                  },
                  __typename: 'ShopPrintingTechniqueEdge'
                });
                cache.writeQuery({
                  query: ADMIN_GET_SHOP_SETTINGS,
                  variables: { id: shopId },
                  data
                });
              }
            }}
          >
            {addProduct => (
              <Button
                variant="outlined"
                color="primary"
                onClick={addProduct}
                size="small"
              >
                Add Printing Technique
              </Button>
            )}
          </Mutation>
        </Grid>
        <Grid container>
          <Query query={ADMIN_GET_SHOP_SETTINGS} variables={{ id: shopId }}>
            {({ loading, error, data: { shop } }) => {
              if (loading) return <CircularProgress />;
              if (error) return <div>Error :(</div>;
              return (
                <div style={{ width: '100%' }}>
                  {shop.printingTechniques.edges.length > 0 ? (
                    <Paper style={{ width: '100%' }}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {shop && shop.printingTechniques
                            ? shop.printingTechniques.edges.map(printing => {
                                const prT = printing.node;
                                return (
                                  <TableRow
                                    key={printing.node.id}
                                    component="a"
                                    hover
                                    href={
                                      '/admin/' +
                                      shopId +
                                      '/inventory/printing/' +
                                      prT.id
                                    }
                                    style={{ textDecoration: 'none' }}
                                  >
                                    {/* {JSON.stringify(prT)} */}
                                    <TableCell>
                                      {prT.id &&
                                      prT.customName &&
                                      prT.customName.text &&
                                      prT.customName.text[0]
                                        ? prT.customName.text[0].content +
                                          ' (' +
                                          prT.id +
                                          ')'
                                        : prT.colorOpacity}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            : null}
                        </TableBody>
                      </Table>
                    </Paper>
                  ) : (
                    <Typography component="p" variant="body1">
                      No printing techniques yet
                    </Typography>
                  )}
                </div>
              );
            }}
          </Query>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(PrintingList);
