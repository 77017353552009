import React from 'react'
import _sortBy from 'lodash/sortBy'
import _uniqBy from 'lodash/uniqBy'
import Footer from '../../../../../store/footer/Footer'
import { Grid } from '@material-ui/core'
import PlaceholderText from './PlaceholderText'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../../../../common/Theme'

interface Props {
  data: any
  shop: any
}

const EditorFooter: React.FC<Props> = props => {
  const { data, shop } = props
  let sorted
  if (data) {
    sorted = _sortBy(
      data.filter((o: any) => o.menuLocation === 'FOOTER'),
      'sortOrder'
    )
  }
  if (data && data.length === 0) {
    return <PlaceholderText type="footer" />
  }
  return (
    <Grid
      container={true}
      style={{ width: '100%', position: 'relative', bottom: 0, top: 7 }}
    >
      <MuiThemeProvider theme={theme}>
        {sorted
          ? sorted.map((v: any, i: any) => (
              <Footer
                teamstore={{}}
                key={i}
                menu={{
                  ...v.contentMenu,
                  navigationLinks: _uniqBy(v.contentMenu.navigationLinks, 'id')
                }}
                shop={shop}
                urlPrefix="en/"
                language="en_UK"
              />
            ))
          : null}
      </MuiThemeProvider>
    </Grid>
  )
}

export default EditorFooter
