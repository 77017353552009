import React from 'react';

const LoadingComponent = props => {
  // Handle the loading state
  if (props.isLoading) {
    return <div>Loading...</div>;
  }
  // Handle the error state
  else if (props.error) {
    console.error('Loading component error', props, props.error);
    return <div>Error</div>;
  } else {
    return null;
  }
};
export default LoadingComponent;
