import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import ColorList from './ColorList';
import ColorDetail from './ColorDetail';

class Colors extends Component {
  render() {
    const { colorId, shopId } = this.props;
    return colorId ? (
      <ColorDetail shopId={shopId} colorId={colorId} />
    ) : (
      <Grid>
        <ColorList shopId={shopId} />
      </Grid>
    );
  }
}
export default Colors;
