import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AlignLeft from '@material-ui/icons/FormatAlignLeft';
import AlignRight from '@material-ui/icons/FormatAlignRight';
import AlignCenter from '@material-ui/icons/FormatAlignCenter';
import { Button, Typography } from '@material-ui/core';
import JustifyCenter from './icons/JustifyCenter';
import JustifyFlexEnd from './icons/JustifyFlexEnd';
import JustifyFlexStart from './icons/JustifyFlexStart';
import JustifySpaceBetween from './icons/JustifySpaceBetween';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin:theme.spacing(3),
    width: '100%'
  },
  group: {
    margin: `${theme.spacing}px 0`
  },
  typo: {
    color: '#696969',
    fontSize: '15px',
    marginBottom: '8px'
  }
});

class SelectAlignment extends React.Component {
  render() {
    const { classes, fields, showSpaceBetween, handleChangeField } = this.props;

    const getVariant = position => {
      return position === fields.alignment ? 'outlined' : 'text';
    };

    return (
      <Grid className={classes.root} direction="column" container={true}>
        <Typography variant="subtitle1" className={classes.typo}>
          Horizontal Alignment
        </Typography>
        <Grid container justify="space-between" style={{ marginTop: 8 }}>
          <Grid item xs={3}>
            <Button
              variant={getVariant('left')}
              style={{ boxShadow: 'none', padding: '6px 8px' }}
              color="primary"
              onClick={() => handleChangeField('left', 'alignment')}
            >
              <JustifyFlexStart />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={getVariant('center')}
              style={{ boxShadow: 'none', padding: '6px 8px' }}
              onClick={() => handleChangeField('center', 'alignment')}
              color="primary"
            >
              <JustifyCenter />
            </Button>
          </Grid>
          {showSpaceBetween ? (
            <Grid item xs={3}>
              <Button
                variant={getVariant('space_between')}
                style={{ boxShadow: 'none', padding: '6px 8px' }}
                onClick={() => handleChangeField('space_between', 'alignment')}
                color="primary"
              >
                <JustifySpaceBetween />
              </Button>
            </Grid>
          ) : null}
          <Grid item xs={3}>
            <Button
              variant={getVariant('right')}
              style={{ boxShadow: 'none', padding: '6px 8px' }}
              onClick={() => handleChangeField('right', 'alignment')}
              color="primary"
            >
              <JustifyFlexEnd />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

SelectAlignment.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SelectAlignment);
