import { withStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'
import { navigate } from '@reach/router'
import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { ADMIN_CREATE_PRODUCT } from '../../gql/admin-mutations'
import { ADMIN_SHOP_PRODUCTS } from '../../gql/admin-queries'
const styles = theme => ({
  textField: {
    marginLeft: theme.spacing,
    marginRight: theme.spacing
  }
})

class AddProduct extends Component {
  state = {
    name: ''
  }

  render() {
    const { shopId } = this.props
    return (
      <div>
        <Mutation
          mutation={ADMIN_CREATE_PRODUCT}
          variables={{ shopId, status: 'INACTIVE' }}
          update={(cache, { data: { createProduct } }) => {
            if (createProduct.id) {
              navigate(
                `/admin/${shopId}/inventory/products/${createProduct.id}`
              )
              const data = cache.readQuery({
                query: ADMIN_SHOP_PRODUCTS,
                variables: { id: shopId }
              })
              data.shop.products.edges.unshift({
                node: {
                  ...createProduct
                },
                __typename: 'ShopProductEdge'
              })
              cache.writeQuery({
                query: ADMIN_SHOP_PRODUCTS,
                variables: { id: shopId },
                data
              })
            }
          }}
        >
          {addProduct => (
            <Button
              data-cy="Products-Add-New-Product-btn"
              color="primary"
              onClick={addProduct}
              variant="outlined"
            >
              New Product
            </Button>
          )}
        </Mutation>
      </div>
    )
  }
}

export default withStyles(styles)(AddProduct)
