export const getBreakpoints = (columns: number): any => {
  let xs: any, sm: any, md: any, lg: any, xl: any
  if (columns) {
    switch (columns) {
      case 1:
        xs = 12
        sm = 12
        md = 12
        lg = 12
        xl = 12
        break
      case 2:
        xs = 12
        sm = 12
        md = 6
        lg = 6
        xl = 6
        break
      case 3:
        xs = 12
        sm = 12
        md = 6
        lg = 4
        xl = 4
        break
      case 4:
        xs = 6
        sm = 6
        md = 4
        lg = 3
        xl = 3
        break
      case 5:
        xs = 6
        sm = 6
        md = 4
        lg = 2
        xl = 2
        break
      case 6:
        xs = 6
        sm = 6
        md = 4
        lg = 2
        xl = 2
        break
      default:
        xs = 6
        sm = 6
        md = 4
        lg = 3
        xl = 3
    }
  } else {
    xs = 12
    sm = 6
    md = 4
    lg = 3
    xl = 3
  }

  return {
    xs,
    sm,
    md,
    lg,
    xl,
  }
}
