import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';

const uuidv4 = require('uuid/v4');

const DiscountTitle = ({
  classes,
  discount,
  discountId,
  languages,
  updateDiscount
}) => {
  const titleId =
    discount.title && discount.title.id ? discount.title.id : uuidv4();
  return (
    <Grid container>
      {languages.map((language, index) => {
        var titleText = '';
        try {
          titleText = getTextFromLanguage(discount.title.text, language);
        } catch (error) {}
        return (
          <Grid
            container
            key={'style-titleText-' + index}
            direction="row"
            alignItems="flex-end"
            spacing={1}
            className={classes.container}
          >
            <Grid item xs={10}>
              <TextField
                label="Title"
                placeholder="Enter title"
                multiline={true}
                rowsMax="4"
                value={titleText}
                onChange={e => {
                  updateDiscount({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      updateDiscount: {
                        ...discount,
                        title: {
                          id: titleId,
                          text: [
                            ...languages.map((tempLanguage, index) => {
                              return {
                                langCode: tempLanguage,
                                content:
                                  tempLanguage === language
                                    ? e.target.value
                                    : getTextFromLanguage(
                                        discount.title.text,
                                        tempLanguage
                                      ),
                                __typename: 'TextContent'
                              };
                            })
                          ],
                          __typename: 'Text'
                        }
                      }
                    },
                    variables: {
                      id: discountId,
                      input: {
                        title: {
                          id: titleId,
                          text: [
                            ...languages.map((tempLanguage, index) => {
                              return {
                                langCode: tempLanguage,
                                content:
                                  tempLanguage === language
                                    ? e.target.value
                                    : getTextFromLanguage(
                                        discount.title.text,
                                        tempLanguage
                                      )
                              };
                            })
                          ]
                        }
                      }
                    }
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  height: 38,
                  width: '100%',
                  border: '1px solid #ddd',
                  borderRadius: '3px'
                }}
              >
                <Typography variant="subtitle1">{language}</Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DiscountTitle;
