import produce from 'immer'
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Card } from '@material-ui/core'
import { I18n } from '@aws-amplify/core'
import { navigate } from '@reach/router'
import BoardProductPreview from '../common/BoardProductPreview'
import BoardProductPreviews from '../common/BoardProductPreviews'
import Info from '@material-ui/icons/Info'
import ShopLogo from '../common/ShopLogo'
import _includes from 'lodash/includes'
import { SHOP_ID } from '../../../brikl-config'
import { Mutation } from 'react-apollo'
import { GET_CUSTOMER_BOARDPRODUCTS } from '../../gql/queries'
import { DELETE_BOARD_PRODUCT } from '../../gql/mutations'
import { Localization } from '../../lang/localization'
import { async } from 'q'
const uuidv4 = require('uuid/v4')
const omitDeep = require('omit-deep')
const _cloneDeep = require('lodash/cloneDeep')
const styles = theme => ({
  root: {
    padding: '8px',
    height: '100%'
  },
  designCard: {
    position: 'relative',
    width: '100%',
    borderRadius: '4px',
    padding: '8px',
    background: '#ffffff',
    color: '#000000'
  },
  actionCard: {
    width: '100%',
    marginTop: '16px'
  },
  preview: {
    height: ' 180px',
    width: 'calc(100% + 30px)',
    marginLeft: '-15px',
    marginTop: '-15px',
    background: '#000',
    position: 'relative',
    marginBottom: '35px'
  },
  editHover: {
    position: 'absolute',
    top: '94%',
    left: '0',
    zIndex: '5 !important'
  },
  button: {
    fontSize: '0.7rem',
    padding: 6
  },
  removeBtn: {
    fontSize: '0.7rem',
    color: '#000000',
    padding: '10px 15px',
    minWidth: 'auto',
    maxHeight: 'auto'
  },

  searchButton: {
    position: 'relative',
    minHeight: '45px',
    minWidth: '45px',
    borderRadius: '50%',
    padding: '0px',
    marginTop: '4px',
    color: '#d7d7d7'
  },

  input: {
    marginTop: '8px',
    width: '190px',
    background: '#e5e5e5 !important'
  },
  info: {
    borderRadius: '100%',
    minHeight: '16px',
    minWidth: '16px',
    position: 'absolute',
    top: '12px',
    right: '12px',
    padding: '0px',
    color: '#000',
    background: '#fff',
    display: 'none'
  },
  span: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000'
  },
  bold: {
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '24px'
  }
})
export const omitCartValues = [
  '__typename',
  'salesChannel',
  'productOptionValue',
  'design',
  'boardProduct',
  'product',
  'shop',
  'designPreviewUrl'
]
export const addToCart = (
  boardProductId,
  ownedByOrganisationId,
  type,
  updateCart,
  cartId,
  cart,
  shopId,
  productId,
  designStudioVersion = 'V1',
  salesChannelId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      var oldCartItems = []
      var includesCurrentProduct = false
      if (cart.cartItems) {
        cart.cartItems.forEach(cartItem => {
          if (cartItem.boardProductId === boardProductId) {
            includesCurrentProduct = true
          }
          oldCartItems.push(cartItem)
        })
      }
      // console.log('addToCart.shopId', shopId);
      // console.log('addToCart.boardProductId', boardProductId);
      // console.log('addToCart.oldCartItems', oldCartItems);
      const cartItemSalesChannelId = process.env.GATSBY_BRIKL_SALESCHANNEL_ID
        ? process.env.GATSBY_BRIKL_SALESCHANNEL_ID
        : salesChannelId || 'MYBRIKL'
      if (includesCurrentProduct === false) {
        const newCartItemId = uuidv4()
        await updateCart({
          optimisticResponse: {
            __typename: 'Mutation',
            updateCart: {
              ...cart,
              cartItems: [
                ...oldCartItems,
                {
                  id: newCartItemId,
                  boardProductId,
                  productDesignId: boardProductId,
                  designStudioVersion: designStudioVersion,
                  salesChannelId: cartItemSalesChannelId,
                  shopId: SHOP_ID,
                  orderType: type,
                  productOptionValueId: null,
                  productOptionValues: null,
                  productId: productId,
                  sizeQuantities: [],
                  quantity: designStudioVersion === 'V2' ? 1 : null,
                  __typename: 'CartItem'
                }
              ]
            }
          },
          variables: {
            ownedByOrganisationId,
            id: cartId,
            input: {
              cartItems: [
                ..._cloneDeep(oldCartItems).map(tempCartItem2 =>
                  omitDeep(tempCartItem2, omitCartValues)
                ),
                {
                  id: newCartItemId,
                  boardProductId,
                  productDesignId: boardProductId,
                  salesChannelId: cartItemSalesChannelId,
                  shopId: SHOP_ID,
                  orderType: type,
                  productOptionValueId: null,
                  productOptionValues: null,
                  productId: productId,
                  quantity:
                    designStudioVersion === 'V2'
                      ? SHOP_ID === 'hdfast'
                        ? 10
                        : 1
                      : null,
                  sizeQuantities: [
                    {
                      quantity: type === 'REGUALR' ? 50 : 0,
                      size: 'S'
                    }
                  ]
                }
              ]
            }
          }
        })
        resolve()
      } else {
        resolve()
      }
    } catch (error) {
      console.error(error)
      resolve()
    }
  })
}
class BoardProduct extends React.Component {
  state = {
    anchorEl: null,
    popperOpen: false,
    open: false,
    show: false,
    search: '',
    deleted: []
  }

  render() {
    const {
      classes,
      boardProduct,
      ownedByOrganisationId,
      updateCart,
      cartId,
      cart,
      isInCart,
      isInCartAs,
      canOrder,
      urlPrefix
    } = this.props
    const boardProductDetails = boardProduct.node
    return (
      <Grid
        key={boardProductDetails.id}
        item
        xs={6}
        sm={6}
        md={4}
        lg={3}
        xl={3}
        style={{ padding: '5px', marginBottom: '16px' }}
      >
        <Card data-cy="product-card" className={classes.designCard}>
          <Grid container data-cy="product-preview" className={classes.preview}>
            <Grid item xs={8}>
              <BoardProductPreview
                ownedByOrganisationId={
                  boardProductDetails.ownedByOrganisationId
                }
                boardProductId={boardProductDetails.id}
              />
            </Grid>
            <Grid item xs={4}>
              <BoardProductPreviews
                ownedByOrganisationId={
                  boardProductDetails.ownedByOrganisationId
                }
                boardProductId={boardProductDetails.id}
              />
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.editHover}
            >
              {boardProductDetails.isOrdered ? null : (
                <Button
                  data-cy="edit-btn"
                  variant="contained"
                  color="primary"
                  style={{ padding: '8px 40px' }}
                  onClick={() => {
                    // navigate
                    window.open(
                      `/${urlPrefix}design-now/?s=${boardProductDetails.shopId}&p=${boardProductDetails.baseProductId}&bp=${boardProductDetails.id}`
                    )
                  }}
                >
                  {Localization.get('edit')}
                </Button>
              )}
              {boardProductDetails.isOrdered ? null : (
                <Mutation
                  update={(cache, { data: { deleteBoardProduct } }) => {
                    try {
                      if (deleteBoardProduct === true) {
                        const data = cache.readQuery({
                          query: GET_CUSTOMER_BOARDPRODUCTS,
                          variables: {
                            ownedByOrganisationId:
                              boardProductDetails.ownedByOrganisationId
                          }
                        })
                        const newData = data.customerBoardProducts.edges.filter(
                          myBoardProduct => {
                            if (
                              myBoardProduct.node.id !== boardProductDetails.id
                            ) {
                              return true
                            } else {
                              return false
                            }
                          }
                        )
                        cache.writeQuery({
                          query: GET_CUSTOMER_BOARDPRODUCTS,
                          variables: {
                            ownedByOrganisationId:
                              boardProductDetails.ownedByOrganisationId
                          },
                          data: {
                            ...data,
                            customerBoardProducts: {
                              ...data.customerBoardProducts,
                              edges: newData
                            }
                          }
                        })
                      }
                    } catch (error) {
                      console.error(error)
                    }
                  }}
                  mutation={DELETE_BOARD_PRODUCT}
                >
                  {deleteBoardProduct => (
                    <Button
                      variant="contained"
                      color="warning"
                      style={{
                        marginLeft: '1em'
                      }}
                      onClick={() => {
                        var deleteText = prompt(
                          "Please 'delete' to remove your design",
                          ''
                        )
                        if (deleteText === 'delete') {
                          deleteBoardProduct({
                            variables: {
                              id: boardProductDetails.id,
                              ownedByOrganisationId:
                                boardProductDetails.ownedByOrganisationId
                            }
                          })
                        }
                      }}
                    >
                      {Localization.get('delete')}
                    </Button>
                  )}
                </Mutation>
              )}
            </Grid>
            <Info variant="contained" className={classes.info} />
            <ShopLogo shopId={boardProductDetails.shopId} />
          </Grid>

          <Grid
            container
            direction="column"
            style={{ marginTop: '17px' }}
            data-cy="product-detail"
          >
            <span className={classes.span}>
              <span className={classes.bold}>{I18n.get('Name')}: </span>{' '}
              {boardProductDetails.name}
            </span>
          </Grid>

          <Grid
            container
            direction="column"
            style={{ marginTop: '8px' }}
            data-cy="product-detail"
          >
            <span className={classes.span}>
              {/* TODO load from config */}
              <span className={classes.bold}>
                {I18n.get('MOQ')}: {SHOP_ID === 'bodywearlab' ? '2 pc' : 50}
              </span>{' '}
            </span>
          </Grid>
          <Grid
            container
            direction="column"
            style={{ marginTop: '8px' }}
            data-cy="product-detail"
          >
            <span className={classes.span}>
              {/* TODO load from config */}
              <span className={classes.bold}>
                {I18n.get('Lead time')}:{' '}
                {SHOP_ID === 'bodywearlab' ? '4 weeks' : '35 days'}{' '}
              </span>{' '}
            </span>
          </Grid>

          <Grid
            container
            style={{ marginTop: '8px' }}
            data-cy="product-detail"
            justify="flex-end"
          >
            {boardProductDetails.lastPrice ? (
              <span className={classes.bold} style={{ fontSize: '24px' }}>
                {boardProductDetails.lastPrice.currency}{' '}
                {boardProductDetails.lastPrice.value
                  ? boardProductDetails.lastPrice.value
                  : 0}
              </span>
            ) : (
              <span>{I18n.get('Price')}: Checkout required</span>
            )}
          </Grid>

          {canOrder === true ? (
            <Grid
              container
              className={classes.actionCard}
              justify="space-between"
            >
              <Grid item>
                <Button
                  disabled={isInCart === true}
                  onClick={() => {
                    if (boardProductDetails.isOrdered) {
                      navigate('/order?orderId=' + boardProductDetails.orderId)
                    } else {
                      // add to cart
                      addToCart(
                        boardProductDetails.id,
                        boardProductDetails.ownedByOrganisationId,
                        'REGULAR',
                        updateCart,
                        cartId,
                        cart,
                        boardProductDetails.shopId,
                        boardProductDetails.baseProductId
                      )
                    }
                  }}
                  className={classes.button}
                  data-cy="AddToCard-btn"
                  variant="contained"
                  style={
                    isInCart === true
                      ? {}
                      : {
                          background: '#000000',
                          color: '#ffffff'
                        }
                  }
                >
                  {boardProductDetails.isOrdered
                    ? Localization.get('go_to_order')
                    : isInCart === true && isInCartAs === 'REGULAR'
                    ? Localization.get('already_in_cart')
                    : Localization.get('add_to_cart')}
                </Button>
              </Grid>
            </Grid>
          ) : (
            Localization.get('same_supplier_warning')
          )}
        </Card>
      </Grid>
    )
  }
}
BoardProduct.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BoardProduct)
