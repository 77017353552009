import React, { Component } from 'react';
import { Grid, Typography, Paper, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import _get from 'lodash/get';
import MomentUtils from '@date-io/moment';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider
} from 'material-ui-pickers';
import moment from 'moment';


const styles = {
  root: {
    padding: '2px 8px 2px 4px !important',
    display: 'flex',
    alignItems: 'center',
    minWidth: 100,
    background: '#fff',
    marginTop: '4px !important',
    borderRadius: 4,
    fontWeight: 300,
    border: '1px solid #ddd',
    '&:hover': {
      borderColor: '#ddd !important'
    }
  },
  input: {
    marginLeft: 8,
    width: '100%',
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 300,
    color: '#000'
  }
};

const CouponAvailableUntil = ({
  classes,
  coupon,
  couponId,
  updateCoupon
}) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container  style={{marginTop: 24}}>
        <Typography variant='subtitle1'>Coupon available until:</Typography>
        <Grid
          container
          key={'style-titleText-'}
          direction="row"
          alignItems="flex-end"
          spacing={1}
          className={classes.container}
        >
          <Grid item xs={4}>
          <InputLabel className={classes.label}>Date</InputLabel>
          <Paper className={classes.root} elevation={0}>
            <DatePicker
              value={coupon.availableUntil}
              onChange={e => {
                console.log(JSON.stringify(e));
                console.log(moment(e).valueOf());
                updateCoupon({
                  optimisticResponse: {
                    __typename: 'Mutation',
                    updateCoupon: {
                      ...coupon,
                      availableUntil: moment(e).valueOf()
                    }
                  },
                  variables: {
                    id: couponId,
                    input: {
                      availableUntil: moment(e).valueOf()
                    }
                  }
                });
              }}
            />
            </Paper>
          </Grid>
          <Grid item xs={4}>

          <InputLabel className={classes.label}>Time</InputLabel>
          <Paper className={classes.root} elevation={0}>
            <TimePicker
              value={coupon.availableUntil}
              onChange={e => {
                // console.log(JSON.stringify(e));
                updateCoupon({
                  optimisticResponse: {
                    __typename: 'Mutation',
                    updateCoupon: {
                      ...coupon,
                      availableUntil: moment(e).valueOf()
                    }
                  },
                  variables: {
                    id: couponId,
                    input: {
                      availableUntil: moment(e).valueOf()
                    }
                  }
                });
              }}
            />
            </Paper>
          </Grid>
          <Grid item xs={4}>
          <Typography variant='body1'
          >
            GMT {moment().format('Z')} (your timezone)
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant='subtitle1'
            >
              {new Date(coupon.availableUntil).toGMTString()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};


CouponAvailableUntil.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CouponAvailableUntil);
