import React from 'react'
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core'

import PrimaryHeader from './PrimaryHeader'
import SecondaryHeader from './SecondaryHeader'

const styles = (themeStyle: Theme) => createStyles({})

interface Props extends WithStyles<typeof styles> {
  currentUser: any
  isEditMode?: boolean
  language: string
  menus: any
  shop: any
  urlPrefix: string | null
  slugs: any[]
  teamstore: any
}

const HeaderRender: React.FunctionComponent<Props> = props => {
  const {
    isEditMode,
    menus,
    language,
    urlPrefix,
    shop,
    currentUser,
    slugs,
    teamstore
  } = props

  return (
    <>
      {menus
        ? menus.map((menu: any, index: number) => {
            switch (index) {
              case 0:
                if (menus.length > 1) {
                  return (
                    <SecondaryHeader
                      urlPrefix={urlPrefix}
                      slugs={slugs}
                      currentUser={currentUser}
                      language={language}
                      key={menu.id}
                      contentMenu={menu.contentMenu}
                      elements={menu.contentMenu?.navigationLinks || []}
                      isEditMode={isEditMode}
                      shop={shop}
                    />
                  )
                } else {
                  return (
                    <PrimaryHeader
                      teamstore={teamstore}
                      urlPrefix={urlPrefix}
                      slugs={slugs}
                      currentUser={currentUser}
                      language={language}
                      key={menu.id}
                      contentMenu={menu.contentMenu}
                      elements={menu.contentMenu?.navigationLinks || []}
                      isEditMode={isEditMode}
                      shop={shop}
                      // showHomeMobile={true}
                    />
                  )
                }
              case 1:
                return (
                  <PrimaryHeader
                    teamstore={teamstore}
                    urlPrefix={urlPrefix}
                    slugs={slugs}
                    currentUser={currentUser}
                    language={language}
                    key={menu.id}
                    contentMenu={menu.contentMenu}
                    elements={menu.contentMenu?.navigationLinks || []}
                    shop={shop}
                    isEditMode={isEditMode}
                  />
                )
              default:
                return null
            }
          })
        : null}
    </>
  )
}

export default withStyles(styles, { withTheme: true })(HeaderRender)
