import React, { Component } from 'react'
import { Query, Mutation, Subscription } from 'react-apollo'
import {
  Button,
  Checkbox,
  InputLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  withStyles
} from '@material-ui/core'
import moment from 'moment'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import uuid from 'uuid'

import {
  ADMIN_SHOP_ORDERS,
  ADMIN_SHOP_SALESCHANNELS
} from '../../gql/admin-queries'
import { ADMIN_CREATE_EXPORT_REPORT_REQUEST } from '../../gql/admin-mutations'
import { ADMIN_REPORT_REQUEST_SUBSCRIPTION } from '../../gql/admin-subscriptions'
import { MEDIA_CDN_URL } from '../../../../../brikl-config'
import { REPORT_FIELDS } from '../content/config'
import FieldsSelection from './FieldsSelection'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import MomentUtils from '@date-io/moment'
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider
} from 'material-ui-pickers'
const styles = theme => ({
  root: {
    overflow: 'hidden',
    padding: 24,
    margin: '0 auto'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    marginLeft: 20,
    fontSize: 30,
    padding: 25,
    fontWeight: 'medium',
    color: '#424242'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
})

class ReportTeamstore extends Component {
  state = {
    salesChannelIds: ['MYBRIKL'],
    includeDetail: false,
    reportRequestStatus: null,
    reportRequestId: null,
    testSubscription: false,
    selectedFields: [],
    selectedFieldsJoin: []
  }

  handleFieldSelection(selectedFields: string[]) {
    this.setState({ selectedFields })
  }

  handleFieldJoinSelection(selectedFieldsJoin: string[]) {
    this.setState({ selectedFieldsJoin })
  }

  handleExportBtn(
    createExportReportRequest,
    fileType: 'CSV' | 'XLS',
    salesChannelIds: [string],
    fromTimestamp: number,
    untilTimestamp: number,
    paymentStatus: string
  ) {
    const requestId = uuid.v4()
    this.setState({
      reportRequestId: requestId,
      reportRequestStatus: 'LOADING'
    })
    try {
      createExportReportRequest({
        variables: {
          input: {
            fileType: 'XLS',
            id: requestId,
            reportType: 'REPORT_TEAMSTORE',
            filter: {
              salesChannelIds,
              fromTimestamp: fromTimestamp,
              untilTimestamp: untilTimestamp,
              paymentStatus:
                paymentStatus && paymentStatus !== '' && paymentStatus !== 'All'
                  ? paymentStatus
                  : null
            }
          }
        }
      })
    } catch (error) {
      this.setState({
        reportRequestStatus: null
      })
    }
  }

  render() {
    const { classes, orderId, shopId, defaultLanguage } = this.props
    return (
      <Grid className={classes.root}>
        <Grid
          container={true}
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ marginBottom: '20px' }}
        >
          <Grid item={true} xs={12}>
            {!orderId && (
              <Typography
                style={{
                  fontSize: 28,
                  fontWeight: 'medium',
                  color: '#424242',
                  marginBottom: '20px'
                }}
              >
                Report Teamstore
              </Typography>
            )}
          </Grid>
          <Mutation
            update={(store, { data: { createExportReportRequest } }) => {
              console.log(
                'createExportReportRequest',
                createExportReportRequest
              )
              if (createExportReportRequest.id) {
                // start subscribing here
                // this.setState({
                //   reportRequestId: createExportReportRequest.id
                // });
              }
            }}
            mutation={ADMIN_CREATE_EXPORT_REPORT_REQUEST}
          >
            {createExportReportRequest => (
              <Grid item={true} xs={12}>
                <Paper style={{ padding: 24 }}>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                      <InputLabel>Saleschannel</InputLabel>
                      <Query
                        query={ADMIN_SHOP_SALESCHANNELS}
                        variables={{ id: shopId }}
                      >
                        {({ loading, error, data }) => {
                          let shopSalesChannels = []
                          try {
                            data.shop.salesChannels.edges.forEach(s => {
                              shopSalesChannels.push(
                                <option value={s.node.id}>
                                  {getTextFromLanguage(
                                    s.node.title.text,
                                    defaultLanguage
                                  )}
                                </option>
                              )
                            })
                          } catch (error) {}
                          return (
                            <select
                              onChange={e => {
                                this.setState({
                                  salesChannelIds: e.target.value
                                    ? [e.target.value]
                                    : null
                                })
                              }}
                              value={
                                this.state.salesChannelIds
                                  ? this.state.salesChannelIds[0]
                                  : 'MYBRIKL'
                              }
                            >
                              <option value="MYBRIKL">MYBRIKL</option>
                              {shopSalesChannels}
                            </select>
                          )
                        }}
                      </Query>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <InputLabel>Payment status</InputLabel>
                      <select
                        onChange={e => {
                          this.setState({
                            paymentStatus: e.target.value
                              ? e.target.value
                              : null
                          })
                        }}
                        value={
                          this.state.paymentStatus
                            ? this.state.paymentStatus
                            : null
                        }
                      >
                        <option value={''}>All</option>
                        <option value="PAID">PAID</option>
                        <option value="PENDING">PENDING</option>
                      </select>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Grid container={true} spacing={2}>
                          <Grid item={true} xs={5}>
                            <Grid container={true} spacing={1}>
                              <Grid item={true} xs={12}>
                                <InputLabel>
                                  From{' '}
                                  {this.state.fromTimestamp
                                    ? new Date(
                                        this.state.fromTimestamp
                                      ).toGMTString()
                                    : ' (not set)'}
                                </InputLabel>
                              </Grid>

                              <Grid item={true} xs={6}>
                                <DatePicker
                                  value={this.state.fromTimestamp}
                                  onChange={e => {
                                    this.setState({
                                      fromTimestamp: moment(e).valueOf()
                                    })
                                  }}
                                />
                              </Grid>
                              <Grid item={true} xs={6}>
                                <TimePicker
                                  value={this.state.fromTimestamp}
                                  onChange={e => {
                                    this.setState({
                                      fromTimestamp: moment(e).valueOf()
                                    })
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item={true} xs={5}>
                            <Grid container={true} spacing={1}>
                              <Grid item={true} xs={12}>
                                <InputLabel>
                                  To{' '}
                                  {this.state.untilTimestamp
                                    ? new Date(
                                        this.state.untilTimestamp
                                      ).toGMTString()
                                    : ' (not set)'}
                                </InputLabel>
                              </Grid>
                              <Grid item={true} xs={6}>
                                <DatePicker
                                  value={this.state.untilTimestamp}
                                  onChange={e => {
                                    this.setState({
                                      untilTimestamp: moment(e).valueOf()
                                    })
                                  }}
                                />
                              </Grid>
                              <Grid item={true} xs={6}>
                                <TimePicker
                                  value={this.state.untilTimestamp}
                                  onChange={e => {
                                    this.setState({
                                      untilTimestamp: moment(e).valueOf()
                                    })
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{
                          marginLeft: 'auto'
                        }}
                        onClick={() =>
                          this.handleExportBtn(
                            createExportReportRequest,
                            'XLS',
                            this.state.salesChannelIds,
                            this.state.fromTimestamp,
                            this.state.untilTimestamp,
                            this.state.paymentStatus
                          )
                        }
                        disabled={this.state.reportRequestStatus === 'LOADING'}
                      >
                        {this.state.reportRequestStatus === 'LOADING'
                          ? 'Export in progress...'
                          : 'Export'}
                      </Button>
                      {'  '}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Mutation>
          {this.state.reportRequestId ? (
            <Subscription
              onSubscriptionData={({ subscriptionData }) => {
                try {
                  if (
                    subscriptionData.data.events[0] &&
                    subscriptionData.data.events[0].status === 'SUCCESS'
                  ) {
                    const link = document.createElement('a')
                    link.href = subscriptionData.data.events[0].event.filePath
                    const fileType = link.href.split('.').pop()
                    link.download = 'brikl-teamstore-export.' + fileType
                    console.log('link', link)
                    link.click()
                    this.setState({
                      reportRequestStatus: null
                    })
                  }
                } catch (e) {
                  console.error(e)
                  alert('Your export has failed: Unknown error')
                  this.setState({
                    reportRequestStatus: null
                  })
                }
              }}
              subscription={ADMIN_REPORT_REQUEST_SUBSCRIPTION}
              variables={{
                id: this.state.reportRequestId,
                shopId: shopId
              }}
            />
          ) : null}
        </Grid>
        {!orderId && (
          <Grid container={true}>
            <Grid item={true} xs={12}></Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(ReportTeamstore)
