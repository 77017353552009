import React, { useState, useEffect } from 'react'
import UrlSelect from './UrlSelect'
import { TextField, InputLabel, Grid } from '@material-ui/core'

interface Props {
  handleUpdate: any
  contentId: string
  fieldName: string
  defaultValue: string
  level: 'root' | 'style'
  styleId: string
}

const UrlInput: React.FC<Props> = props => {
  const {
    handleUpdate,
    defaultValue,
    styleId,
    level,
    contentId,
    fieldName
  } = props

  const [selectedUrl, setSelectedUrl] = useState({
    id: '1',
    value: defaultValue ? defaultValue : null,
    type: null
  })

  const handleChange = (e: any) => {
    setSelectedUrl({
      ...selectedUrl,
      value: e.target.value
    })
  }

  useEffect(() => {
    if (selectedUrl.value) {
      handleUpdate(
        contentId,
        fieldName,
        `/${selectedUrl.value}`,
        level,
        styleId
      )
    }
  }, [selectedUrl.value])

  return (
    <Grid key={selectedUrl.id} container={true} spacing={2} direction="row">
      <Grid item={true} xs={12}>
        <UrlSelect
          setSelected={setSelectedUrl}
          selected={selectedUrl}
          type="PRODUCT"
        />
      </Grid>

      <Grid item={true} xs={12}>
        <UrlSelect
          setSelected={setSelectedUrl}
          selected={selectedUrl}
          type="COLLECTION"
        />
      </Grid>

      <Grid item={true} xs={12}>
        <UrlSelect
          setSelected={setSelectedUrl}
          selected={selectedUrl}
          type="TEAMSTORE"
        />
      </Grid>

      <Grid item={true} xs={12}>
        <UrlSelect
          setSelected={setSelectedUrl}
          selected={selectedUrl}
          type="PAGE"
        />
      </Grid>
      <Grid item={true} xs={12}>
        <InputLabel>Selected URL</InputLabel>
        <TextField
          placeholder=""
          defaultValue={selectedUrl.value}
          onChange={handleChange}
          onBlur={(e: any) =>
            handleUpdate(
              contentId,
              fieldName,
              selectedUrl.type ? `/${e.target.value}` : e.target.value,
              level,
              styleId
            )
          }
        />
      </Grid>
    </Grid>
  )
}

export default UrlInput
