import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import { ADMIN_SHOP_COUPONS } from '../../gql/admin-queries';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '../../../common/Input';
import Select from '../../../common/Select';
import TextField from '../../../common/TextField';
import AddCoupon from './AddCoupon';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 0,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242',
    flex: 1
  }
});

class CouponList extends Component {
  render() {
    const { classes, styles, shopId } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          style={{ marginTop: 24 }}
          justify="flex-start"
          alignItems="center"
        >
          <Typography component="h1" className={classes.h1}>
            Coupons
          </Typography>
          {/* <AddCoupon /> */}
        </Grid>
        <br />
        <Query query={ADMIN_SHOP_COUPONS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            if (loading) return <CircularProgress />;
            if (error) return <div>Error :(</div>;
            return (
              <div>
                {shop.coupons.edges.length > 0 ? (
                  <Paper className={classes.root} elevation={0}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Code</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {shop.coupons.edges.map(coupons => {
                          return (
                            <TableRow key={coupons.node.id}>
                              <TableCell component="th" scope="row">
                                {coupons.node.id}
                              </TableCell>
                              <TableCell>{coupons.node.couponCode}</TableCell>
                              <TableCell>
                                <Link
                                  to={
                                    '/admin/' +
                                    shopId +
                                    '/settings/coupons/' +
                                    coupons.node.id
                                  }
                                  style={{ textDecoration: 'none' }}
                                >
                                  <Button>Edit</Button>
                                </Link>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <div>
                    No coupons yet
                    {/* <button>Create one now</button> */}
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withStyles(styles)(CouponList);
