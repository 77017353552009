import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { isJSON } from '../../../admin/components/content/utils';
import RichText from '../../../admin/components/content/RichText';

const styles = theme => ({});

class CustomTypography extends Component {
  newLine(data) {
    // var newData = data.split('\n');
    const match = /\r|\\n/.exec(data);
    // console.log('newLine', data);
    const newData = [];

    if (match) {
      data.split('\\n').map((item, i) => {
        newData.push(<p key={i}>{item}</p>);
      });
    } else {
      return data;
    }

    // console.log(newData);
    return newData;
  }
  static defaultProps = {
    color: 'black'
  };

  renderTypography(variant) {
    switch (variant) {
      case 'h1':
        return (
          <Typography
            style={{
              color: this.props.color,
              padding: this.props.background ? '15px' : '0 0 15px 0',
              textAlign: this.props.align,
              fontSize: this.props.fontSize,
              background: this.props.background,
              fontFamily: this.props.fontFamily,
              fontWeight: this.props.fontWeight,
              width: '100%',
              whiteSpace: 'pre-line'
            }}
            variant="h1"
            data-cy={this.props['data-cy']}
          >
            {this.props.content}
          </Typography>
        );
      case 'h2':
        return (
          <Typography
            style={{
              color: this.props.color,
              padding: this.props.background ? '15px' : '0 0 15px 0',
              textAlign: this.props.align,
              fontSize: this.props.fontSize,
              background: this.props.background,
              fontFamily: this.props.fontFamily,
              fontWeight: this.props.fontWeight,
              width: '100%',
              whiteSpace: 'pre-line'
            }}
            variant="h2"
            data-cy={this.props['data-cy']}
          >
            {this.props.content}
          </Typography>
        );
      case 'h3':
        return (
          <Typography
            style={{
              color: this.props.color,
              fontSize: this.props.fontSize,
              background: this.props.background,
              textAlign: this.props.align,
              fontFamily: this.props.fontFamily,
              fontWeight: this.props.fontWeight,
              width: '100%'
            }}
            variant="h3"
            data-cy={this.props['data-cy']}
          >
            {this.props.content}
          </Typography>
        );
      case 'h4':
        return (
          <Typography
            style={{
              color: this.props.color,
              marginTop: 15,
              marginBottom: 15,
              fontSize: this.props.fontSize,
              background: this.props.background,
              textAlign: this.props.align,
              fontFamily: this.props.fontFamily,
              fontWeight: this.props.fontWeight,
              width: '100%'
            }}
            variant="h4"
            data-cy={this.props['data-cy']}
          >
            {this.props.content}
          </Typography>
        );
      case 'h5':
        return (
          <Typography
            style={{
              color: this.props.color,
              marginBottom: 15,
              whiteSpace: 'pre-wrap',
              textAlign: this.props.align,
              fontSize: this.props.fontSize,
              background: this.props.background,
              fontFamily: this.props.fontFamily,
              fontWeight: this.props.fontWeight,
              width: '100%'
            }}
            variant="h5"
            data-cy={this.props['data-cy']}
          >
            {this.newLine(this.props.content)}
          </Typography>
        );
      case 'p':
        return (
          <Typography
            style={{
              color: this.props.color,
              padding: this.props.background ? '15px' : '0 0 15px 0',
              textAlign: this.props.align,
              fontSize: this.props.fontSize,
              background: this.props.background,
              fontFamily: this.props.fontFamily,
              fontWeight: this.props.fontWeight,
              width: '100%',
              whiteSpace: 'pre-line'
            }}
            variant="body1"
            data-cy={this.props['data-cy']}
          >
            {this.props.content}
          </Typography>
        );
      case 'body1':
        return (
          <Typography
            style={{
              color: this.props.color,
              padding: this.props.background ? '15px' : '0 0 15px 0',
              fontSize: this.props.fontSize,
              background: this.props.background,
              textAlign: this.props.align,
              fontFamily: this.props.fontFamily,
              fontWeight: this.props.fontWeight,
              width: '100%'
            }}
            variant="body1"
          >
            {this.newLine(this.props.content)}
          </Typography>
        );
      default:
        return (
          <Typography
            style={{
              color: this.props.color,
              padding: this.props.background ? '15px' : '0 0 15px 0',
              fontSize: this.props.fontSize,
              background: this.props.background,
              textAlign: this.props.align,
              fontFamily: this.props.fontFamily,
              fontWeight: this.props.fontWeight,
              width: '100%'
            }}
            variant="body1"
          >
            {this.props.content}
          </Typography>
        );
    }
  }

  render() {
    return this.renderTypography(this.props.variant);
  }
}

CustomTypography.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomTypography);
