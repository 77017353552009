import React, { Component } from 'react';
import Button from '../../../common/Button';
import Select from '../../../common/Select';
import Input from '../../../common/Input';
import Grid from '@material-ui/core/Grid/Grid';
import TextField from '../../../common/TextField';
import Typography from '@material-ui/core/Typography';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import omitDeep from 'omit-deep';
const TeamstoreProductCustomizationOption = ({
  classes,
  languages,
  teamstore,
  teamstoreId,
  updateTeamstore,
  targetCategory,
  defaultCurrency,
  teamstoreProductCustomizationOptionGroup,
  teamstoreProductCustomizationOption
}) => {
  const titleId =
    teamstoreProductCustomizationOption.title &&
    teamstoreProductCustomizationOption.title.id
      ? teamstoreProductCustomizationOption.title.id
      : uuidv4();
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Select
            onChange={e => {}}
            value={teamstoreProductCustomizationOption.type}
            label="Personalization type"
          >
            <option value={''}>Choose</option>
            <option value={'TEXT'}>Text</option>
          </Select>
        </Grid>
        <Grid container>
          {languages.map((language, index) => {
            var titleText = '';
            try {
              titleText = getTextFromLanguage(
                teamstoreProductCustomizationOption.title.text,
                language
              );
            } catch (error) {}
            return (
              <Grid
                container
                key={'style-titleText-' + index}
                direction="row"
                alignItems="flex-end"
                spacing={1}
                className={classes.container}
              >
                <Grid item xs={10}>
                  <TextField
                    label="Title"
                    placeholder="Enter title"
                    multiline={true}
                    rowsMax="4"
                    value={titleText}
                    onChange={e => {
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateTeamstore: {
                            ...teamstore,
                            customizationSettings: {
                              ...teamstore.customizationSettings,
                              customizationOptionGroups: teamstore.customizationSettings.customizationOptionGroups.map(
                                cG => {
                                  return cG.id ===
                                    teamstoreProductCustomizationOptionGroup.id
                                    ? {
                                        ...cG,
                                        options: cG.options.map(cGO => {
                                          return cGO.id ===
                                            teamstoreProductCustomizationOption.id
                                            ? {
                                                ...cGO,
                                                title: {
                                                  id: titleId,
                                                  text: [
                                                    ...languages.map(
                                                      (tempLanguage, index) => {
                                                        return {
                                                          langCode: tempLanguage,
                                                          content:
                                                            tempLanguage ===
                                                            language
                                                              ? e.target.value
                                                              : getTextFromLanguage(
                                                                  teamstoreProductCustomizationOption
                                                                    .title.text,
                                                                  tempLanguage
                                                                ),
                                                          __typename:
                                                            'TextContent'
                                                        };
                                                      }
                                                    )
                                                  ],
                                                  __typename: 'Text'
                                                }
                                              }
                                            : cGO;
                                        })
                                      }
                                    : cG;
                                }
                              )
                            }
                          }
                        },
                        variables: {
                          id: teamstoreId,
                          input: {
                            customizationSettings: {
                              customizationOptionGroups: [
                                ..._cloneDeep(
                                  teamstore.customizationSettings
                                    .customizationOptionGroups
                                ).map(cog1 =>
                                  omitDeep(
                                    {
                                      ...cog1,
                                      options: cog1.options.map(cogo1 => {
                                        return cogo1.id ===
                                          teamstoreProductCustomizationOption.id
                                          ? {
                                              ...cogo1,
                                              title: {
                                                id: titleId,
                                                text: [
                                                  ...languages.map(
                                                    (tempLanguage, index) => {
                                                      return {
                                                        langCode: tempLanguage,
                                                        content:
                                                          tempLanguage ===
                                                          language
                                                            ? e.target.value
                                                            : getTextFromLanguage(
                                                                teamstoreProductCustomizationOption
                                                                  .title.text,
                                                                tempLanguage
                                                              ),
                                                        __typename:
                                                          'TextContent'
                                                      };
                                                    }
                                                  )
                                                ],
                                                __typename: 'Text'
                                              }
                                            }
                                          : cogo1;
                                      })
                                    },
                                    ['__typename']
                                  )
                                )
                              ]
                            }
                          }
                        }
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    style={{
                      height: 38,
                      width: '100%',
                      border: '1px solid #ddd',
                      borderRadius: '3px'
                    }}
                  >
                    <Typography variant="body1">{language}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <Input
            onChange={e => {
              updateTeamstore({
                optimisticResponse: {
                  __typename: 'Mutation',
                  updateTeamstore: {
                    ...teamstore,
                    customizationSettings: {
                      ...teamstore.customizationSettings,
                      customizationOptionGroups: teamstore.customizationSettings.customizationOptionGroups.map(
                        cG => {
                          return cG.id ===
                            teamstoreProductCustomizationOptionGroup.id
                            ? {
                                ...cG,
                                options: cG.options.map(cogo1 => {
                                  return cogo1.id ===
                                    teamstoreProductCustomizationOption.id
                                    ? {
                                        ...cogo1,
                                        pricingRules: [
                                          {
                                            ...cogo1.pricingRules[0],
                                            formula: {
                                              ...cogo1.pricingRules[0].formula,
                                              price: {
                                                ...cogo1.pricingRules[0].formula
                                                  .price,
                                                value: parseFloat(
                                                  e.target.value
                                                ),
                                                currency: defaultCurrency
                                              }
                                            }
                                          }
                                        ]
                                      }
                                    : cogo1;
                                })
                              }
                            : cG;
                        }
                      )
                    }
                  }
                },
                variables: {
                  id: teamstoreId,
                  input: {
                    customizationSettings: {
                      customizationOptionGroups: [
                        ..._cloneDeep(
                          teamstore.customizationSettings
                            .customizationOptionGroups
                        ).map(cog1 =>
                          omitDeep(
                            {
                              ...cog1,
                              options: cog1.options.map(cogo1 => {
                                return cogo1.id ===
                                  teamstoreProductCustomizationOption.id
                                  ? {
                                      ...cogo1,
                                      pricingRules: [
                                        {
                                          ...cogo1.pricingRules[0],
                                          formula: {
                                            ...cogo1.pricingRules[0].formula,
                                            price: {
                                              ...cogo1.pricingRules[0].formula
                                                .price,
                                              value: parseFloat(e.target.value)
                                            }
                                          }
                                        }
                                      ]
                                    }
                                  : cogo1;
                              })
                            },
                            ['__typename']
                          )
                        )
                      ]
                    }
                  }
                }
              });
            }}
            value={
              teamstoreProductCustomizationOption.pricingRules[0].formula.price
                .value
            }
            label={'Surcharge in ' + teamstoreProductCustomizationOption.pricingRules[0].formula.price
            .currency}
            placeholder="0"
            id="outlined-secondarycolor"
            margin="normal"
          />
        </Grid>
      </Grid>
    </>
  );
};
export default TeamstoreProductCustomizationOption;
