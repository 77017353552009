import { UPLOAD_FILE } from '../../../gql/mutations'
import client from '../../../gql/apollo-config'
import clientAdmin from '../../admin/gql/admin-apollo-config'
import { func } from 'prop-types'

const axios = require('axios')

let DesignRoot = null

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot
}

export function renderComponent(type) {
  try {
    // statements

    if (type === 'DesignRoot') {
      DesignRoot.setState({ render: true }) // RENDER FOR UPDATE LAYER CONTROL
    } else if (type === 'CONTROL_TOOLS') {
      DesignRoot['LogoStep'].setState({ render: true })
      DesignRoot['TextStep'].setState({ render: true })

      if (
        DesignRoot['MenuStep'] &&
        DesignRoot._ACTIVE_PERSONALIZATION === true
      ) {
        DesignRoot.setState({ render: true })
      }

      if (DesignRoot['PatternStep']) {
        DesignRoot['PatternStep'].setState({ render: true })
      }
      if (DesignRoot['LayerControl']) {
        DesignRoot['LayerControl'].setState({ render: true })
      }
    } else {
      if (DesignRoot[type]) {
        DesignRoot[type].setState({ render: true })
      }
    }
  } catch (e) {
    // statements
    logger.error('tools.renderComponent.error', e)
  }
}

export function uploadFile(option) {
  try {
    // statements

    var file_upload = document.getElementById('file-upload')

    if (file_upload.files[0]) {
      var DATA = file_upload.files[0]
    } else {
      alert('PLEASE SELECT FILE')
      return
    }

    if (option === 'EXTRACT_COLOR') {
      getBase64(DATA).then(data =>
        DesignRoot.canvas_coloring.extractColor(data)
      )
    }
  } catch (e) {
    // statements
    logger.error('tools.uploadFile.error', e)
  }
}

export async function uploadFileWithPresignedUrl(
  myFile,
  fileName,
  ownedByOrganisationId,
  type,
  isAdmin
) {
  const mutationOptions = {
    mutation: UPLOAD_FILE,
    variables: {
      type,
      fileName: fileName,
      prefixVariables: ['ownedByOrganisationId'],
      input: {
        ownedByOrganisationId,
      },
    },
  }
  const signedUrlResult = isAdmin
    ? await clientAdmin.mutate(mutationOptions)
    : await client.mutate(mutationOptions)

  const signedUrl = signedUrlResult.data.uploadFile.url
  const options = {
    headers: {
      'Content-Type': signedUrlResult.data.uploadFile.contentType,
    },
  }
  if (signedUrlResult.data.uploadFile.acl) {
    options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl
  }
  if (signedUrlResult.data.uploadFile.tagging) {
    options.headers['x-amz-tagging'] = signedUrlResult.data.uploadFile.tagging
  }
  const uploadResult = await axios.put(signedUrl, myFile, options)

  // AI files wait 10 seconds to convert them > replace this with a subscription rather than guessing the value
  if (signedUrl.indexOf('.ai') || signedUrl.indexOf('.pdf')) {
    await new Promise(resolve => setTimeout(resolve, 10000))
  }

  if (type === 'PRODUCTDESIGN_LOGO') {
    // LOGO DO BYPASS AWAIT BECAUSE LONG REQUEST
    // SO WE UPDATE REVISION HERE
    // fileName

    fileName = fileName.replace('.ai', '.svg')
    var logoObject = DesignRoot.fabric_function.getObjectByName(fileName)

    if (logoObject) {
      logoObject._USER_DATA.s3Url = signedUrl.substring(
        0,
        signedUrl.indexOf('?')
      )
      // BYPASS TRIGGER UPDATE REVISION
      DesignRoot.fabric_function.updateItem(
        logoObject.id,
        logoObject.name,
        logoObject._USER_DATA.base64,
        0,
        0,
        0,
        0,
        logoObject._USER_DATA,
        'LOGO'
      )
    }
  }

  return signedUrl.substring(0, signedUrl.indexOf('?'))
}

export function getBase64(file) {
  try {
    // statements

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  } catch (e) {
    // statements
    logger.error('tools.getBase64.error', e)
  }
}

export function getSVGBase64(file, type = 'blob') {
  try {
    // statements

    return new Promise((resolve, reject) => {
      if (type === 'document') {
        var s = new XMLSerializer().serializeToString(file.documentElement)
        let base64String = 'data:image/svg+xml;base64,' + window.btoa(s)
        resolve(base64String)
      } else {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = () => {
          const doc = new DOMParser().parseFromString(
            reader.result,
            'image/svg+xml'
          )
          var s = new XMLSerializer().serializeToString(doc.documentElement)
          let base64String = 'data:image/svg+xml;base64,' + window.btoa(s)
          resolve(base64String)
        }
        reader.onerror = error => reject(error)
      }
    })
  } catch (e) {
    // statements
    logger.error('tools.getBase64.error', e)
  }
}

export function uuidv4() {
  // TOBIAS
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

export function getQueryStringValue(key) {
  try {
    if (window !== `undefined`) {
      return decodeURIComponent(
        window.location.search.replace(
          new RegExp(
            '^(?:.*[&\\?]' +
              encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
              '(?:\\=([^&]*))?)?.*$',
            'i'
          ),
          '$1'
        )
      )
    }
  } catch (error) {
    return null
  }
}

export function _wrapTimeToSrc(src) {
  // check if https and NOT a S3 signed url - wrap with a timestamp to fix CORS

  try {
    logger.log('_wrapTimeToSrc', src)

    if (
      src &&
      (src.indexOf('s.cdn') !== -1 ||
        src.indexOf('s.dev') !== -1 ||
        src.indexOf('s.staging') !== -1)
    ) {
      return src
    } else if (src && src.indexOf('https') !== -1) {
      if (src.indexOf('?') !== -1) {
        return src + '&datetime=' + new Date().getTime()
      } else {
        return src + '?datetime=' + new Date().getTime()
      }
    } else {
      return src
    }
  } catch (e) {
    logger.error('tools._wrapTimeToSrc.error', e)
    return src
  }
}

export function _urlToBase64(img) {
  try {
    logger.log('_urlToBase64', img, img.width, img.height)

    var canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    var ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0)
    var dataURL = canvas.toDataURL()
    return dataURL
    // return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  } catch (e) {
    logger.error('tools._urlToBase64.error', e)
  }
}

export async function _get_image_info(base64) {
  return await addImageProcess(base64, '_get_image_info')
}

export async function _get_image_base64(url) {
  return await addImageProcess(_wrapTimeToSrc(url), '_get_image_base64')
}

export function addImageProcess(src, option) {
  try {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.crossOrigin = 'anonymous'
      img.onload = () => {
        if (option === '_get_image_info') {
          var imgResize = src
          if (img.width > 5000) {
            logger.log('PATTERN TOO BIG > RESIZE PERFORM')

            // if ( confirm('Warning: Pattern file too big ') === true ) {

            // }

            imgResize = resize_image(img)
          }

          resolve({
            width: img.width,
            height: img.height,
            imgResize: imgResize,
          })
        } else {
          resolve({
            width: img.width,
            height: img.height,
            base64: _urlToBase64(img),
          })
        }
      }
      img.onerror = reject
      img.src = src
    })
  } catch (e) {
    logger.error('tools.addImageProcess.error', e)
  }
}

export function resize_image(img) {
  try {
    var canvas = document.createElement('canvas')
    var ctx = canvas.getContext('2d')

    var ratio = 1
    if (img.width > 10000) {
      // CANVAS BREAK IF IMG OVER 15K PX
      ratio = 10000 / img.width
    }

    canvas.width = 5000
    canvas.height = canvas.width * (img.height / img.width)

    /// step 1
    var oc = document.createElement('canvas'),
      octx = oc.getContext('2d')

    oc.width = img.width * ratio
    oc.height = img.height * ratio
    octx.drawImage(img, 0, 0, oc.width, oc.height)

    /// step 2
    octx.drawImage(oc, 0, 0, oc.width * ratio, oc.height * ratio)

    ctx.drawImage(
      oc,
      0,
      0,
      oc.width * ratio,
      oc.height * ratio,
      0,
      0,
      canvas.width,
      canvas.height
    )

    var dataURL = canvas.toDataURL('image/png')
    // logger.log('resize_image', dataURL)
    return dataURL
  } catch (e) {
    logger.error('tools.resize_image.error', e)
  }
}

export const colorHexToRgb = hexString => {
  hexString = hexString.replace('#', '')
  if (hexString.length !== 6) {
    return null
  }

  return [
    parseInt(hexString.substring(0, 2), 16),
    parseInt(hexString.substring(2, 4), 16),
    parseInt(hexString.substring(4, 6), 16),
    1,
  ]
}
