import React from 'react';
import Grid from '@material-ui/core/Grid';
import { I18n } from '@aws-amplify/core';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import _get from 'lodash/get';
const popup = {
  color: '#ffffff',
  padding: '15px',
  maxWidth: '310px',
  height: '180px'
};
const printingPreview = {
  height: '135px',
  width: 'auto',
  marginTop: '8px',
  fontSize: '12px'
};
const popupTitles = {
  fontSize: '14px',
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  lineHeight: '150%'
};

class PrintingTooltip extends React.Component {
  state = {
    openImageModal: false
  };
  render() {
    const { printingTechnique } = this.props;
    if (!printingTechnique) return null;
    const printingTechniqueName = _get(
      printingTechnique,
      'customName.text[0].content',
      'N/A'
    );
    const imgSrc =
      printingTechnique.previewImage && printingTechnique.previewImage !== ''
        ? printingTechnique.previewImage
        : `https://d19pewu88v4u4x.cloudfront.net/no-preview.png`;
    return (
      <div>
        <Typography component="div" style={{ color: '#fff' }}>
          <Grid data-cy="print-tech-tooltip" container>
            <Grid data-cy="print-tech-tooltip-name" item xs={12}>
              <span style={popupTitles}>Name:</span> {printingTechniqueName}
            </Grid>
            <br />
            <br />
            <Grid item xs={12}>
              <span style={popupTitles}>{I18n.get('Description')}:</span>{' '}
              {_get(printingTechnique, 'description.text[0].content', 'N/A')}
            </Grid>
            <Grid item xs={12} style={{ marginTop: '16px' }}>
              <span style={popupTitles}>{I18n.get('Component')}:</span>{' '}
              {_get(printingTechnique, 'component.text[0].content', 'N/A')}
            </Grid>
            <Grid item xs={12} style={{ marginTop: '16px' }}>
              <span style={popupTitles}>{I18n.get('Thickness')}:</span>{' '}
              {_get(printingTechnique, 'thickness.text[0].content', 'N/A')}
            </Grid>
            <Grid item xs={12} style={{ marginTop: '16px' }}>
              <span style={popupTitles}>{I18n.get('Washability')}:</span>{' '}
              {_get(printingTechnique, 'washability.text[0].content', 'N/A')}
            </Grid>
            <Grid item xs={5} style={{ marginTop: '16px' }}>
              <span>{I18n.get('Preview')}:</span>
              <img
                onClick={() => this.setState({ openImageModal: true })}
                src={imgSrc}
                alt={printingTechniqueName}
                style={printingPreview}
              />
            </Grid>
          </Grid>
        </Typography>
        {/* prepare enlarge preview */}
        {/* <Modal
          aria-labelledby="printing-tooltip-image"
          aria-describedby="printing-tooltip-image"
          open={this.state.openImageModal}
          onClose={()=>this.setState({openImageModal:false})}
        >
          <div style={{
            width: '400px',
            height: 'auto'
          }}>
            <img
              src={imgSrc}
              alt={printingTechniqueName}
            />
          </div>
        </Modal> */}
      </div>
    );
  }
}
export default PrintingTooltip;
