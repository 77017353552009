import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  MuiThemeProvider,
  Grid,
  Paper,
  Button,
  TextField,
  InputLabel,
  NativeSelect
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ADMIN_UPDATE_CONTENT_THEME } from '../../../gql/admin-mutations'
import Input from '../../../../common/Input'
import { theme } from '../../../../common/Theme'
import client from '../../../gql/admin-apollo-config'
import uuid from 'uuid'
import SimpleSnackbar from '../../content/SimpleSnackbar'

import { FONTS, TEXT_TRANSFORM, FONT_WEIGHT } from '../../content/config'

const styles = theme => ({
  slider: {
    padding: '22px 0px'
  },
  select: {
    border: '1px solid #ced4da',
    borderRadius: 4
  }
})

const WAIT_INTERVAL = 1000

class ThemeButton extends Component {
  state = {
    notification: false
  }

  timer = null

  updateField(fieldName, value) {
    var { variant, shop, themeId } = this.props

    this.value = value
    this.fieldName = fieldName

    const self = this

    clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      client
        .mutate({
          mutation: ADMIN_UPDATE_CONTENT_THEME,
          variables: {
            id: themeId,
            input: {
              button: {
                id: uuid.v4(),
                style: {
                  [self.fieldName]: self.value
                }
              }
            }
          }
        })
        .then(
          result => {
            this.setState({
              notification: true
            })
          },
          error => {
            //error
          }
        )
    }, WAIT_INTERVAL)
  }

  updateType(fieldName, value) {
    var { variant, shop, themeId } = this.props

    this.value = value
    this.fieldName = fieldName

    const self = this

    clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      client
        .mutate({
          mutation: ADMIN_UPDATE_CONTENT_THEME,
          variables: {
            id: themeId,
            input: {
              button: {
                id: uuid.v4(),
                [self.fieldName]: self.value
              }
            }
          }
        })
        .then(
          result => {
            this.setState({
              notification: true
            })
          },
          error => {
            //error
          }
        )
    }, WAIT_INTERVAL)
  }

  render() {
    const { classes, shop, themeId } = this.props

    let themeData = ''
    let buttonBorder = ''

    if (shop.contentSettings !== null && shop.contentSettings.theme !== null) {
      themeData = shop.contentSettings.theme
      if (
        shop.contentSettings.theme.button &&
        shop.contentSettings.theme.button.style
      ) {
        buttonBorder =
          themeData.button.style.borderThickness +
          ' ' +
          'solid' +
          ' ' +
          themeData.button.style.borderColor
      }
    } else {
      themeData = ''
      buttonBorder = ''
    }

    return (
      <Grid key={themeId} item xs={12} style={{ marginTop: 24 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <MuiThemeProvider theme={theme}>
              <Grid
                container
                style={{ padding: '24px 24px 24px 0px' }}
                spacing={1}
                alignItems="center"
              >
                <Button
                  style={{
                    fontSize:
                      themeData.button && themeData.button.style
                        ? themeData.button.style.fontSize
                        : null,
                    fontFamily:
                      themeData.button && themeData.button.style
                        ? themeData.button.style.fontFamily
                        : null,
                    textTransform:
                      themeData.button && themeData.button.style
                        ? themeData.button.style.textTransform
                        : null,
                    fontWeight:
                      themeData.button && themeData.button.style
                        ? themeData.button.style.fontWeight
                        : null,
                    border:
                      themeData.button && themeData.button.style
                        ? buttonBorder
                        : null,
                    backgroundColor:
                      themeData.button && themeData.button.style
                        ? themeData.button.style.backgroundColor
                        : null
                  }}
                  color="primary"
                  variant="contained"
                >
                  Button
                </Button>
              </Grid>
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Paper style={{ padding: 24, borderRadius: 15 }} elevation={0}>
              <Grid container className={classes.container} spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Font Size
                  </InputLabel>
                  <TextField
                    placeholder="45px"
                    defaultValue={
                      themeData.button && themeData.button.style
                        ? themeData.button.style.fontSize
                        : null
                    }
                    onChange={e => this.updateField('fontSize', e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Font Family
                  </InputLabel>
                  <TextField
                    placeholder="Roboto"
                    defaultValue={
                      themeData.button && themeData.button.style
                        ? themeData.button.style.fontFamily
                        : null
                    }
                    onChange={e =>
                      this.updateField('fontFamily', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Text Transform
                  </InputLabel>
                  <NativeSelect
                    classes={{ root: classes.select }}
                    native="true"
                    value={
                      themeData.button && themeData.button.style
                        ? themeData.button.style.textTransform
                        : null
                    }
                    onChange={e =>
                      this.updateField('textTransform', e.target.value)
                    }
                  >
                    {TEXT_TRANSFORM.map(textTransform => (
                      <option key={textTransform} value={textTransform}>
                        {textTransform}
                      </option>
                    ))}
                  </NativeSelect>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Font Weight
                  </InputLabel>
                  <NativeSelect
                    classes={{ root: classes.select }}
                    native="true"
                    value={
                      themeData.button && themeData.button.style
                        ? themeData.button.style.fontWeight
                        : null
                    }
                    onChange={e =>
                      this.updateField('fontWeight', e.target.value)
                    }
                  >
                    {FONT_WEIGHT.map((fontWeight, i) => (
                      <option key={i} value={fontWeight.value}>
                        {fontWeight.label}
                      </option>
                    ))}
                  </NativeSelect>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Border Color
                  </InputLabel>
                  <TextField
                    placeholder="e.g.: #000000"
                    defaultValue={
                      themeData.button && themeData.button.style
                        ? themeData.button.style.borderColor
                        : null
                    }
                    onChange={e =>
                      this.updateField('borderColor', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Border Thickness
                  </InputLabel>
                  <TextField
                    placeholder="e.g.: 1px"
                    defaultValue={
                      themeData.button && themeData.button.style
                        ? themeData.button.style.borderThickness
                        : null
                    }
                    onChange={e =>
                      this.updateField('borderThickness', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Background Color
                  </InputLabel>
                  <TextField
                    placeholder="#000"
                    defaultValue={
                      themeData.button && themeData.button.style
                        ? themeData.button.style.backgroundColor
                        : null
                    }
                    onChange={e =>
                      this.updateField('backgroundColor', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fontFamily-native-helper">
                    Button Type
                  </InputLabel>
                  <TextField
                    placeholder="Normal, outlined or text"
                    defaultValue={
                      themeData.button && themeData.button.type
                        ? themeData.button.type
                        : null
                    }
                    onChange={e => this.updateType('type', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <SimpleSnackbar
          open={this.state.notification}
          message="Theme updated succesfully!"
          verticalAlign="bottom"
          horizontalAlign="right"
        />
      </Grid>
    )
  }
}

ThemeButton.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ThemeButton)
