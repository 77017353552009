import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';

const styles = theme => ({});

class SelectTeamstores extends React.Component {
  state = {
    isChecked: false
  };

  render() {
    const { classes, teamstore, Template, onChange, teamstoreSET } = this.props;

    var title = '';

    var checked = false;

    for (var i = 0; i < teamstoreSET.length; i++) {
      if (teamstoreSET[i] === teamstore.id) {
        checked = true;
        break;
      }
    }

    try {
      title = teamstore.id
      title = teamstore.name
      if(teamstore.title.text[0].content){
        title = getTextFromLanguage(
          _get(teamstore, 'title.text', []),
          Template.state.currentLanguage
        );
      }      
    } catch (error) {}

    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              value={teamstore.id}
              onChange={event => onChange(event.target.value, !checked)}
              checked={checked}
            />
          }
          label={title}
        />
      </div>
    );
  }
}

SelectTeamstores.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(SelectTeamstores);
