import { Slider } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  createStyles,
  withStyles,
  WithStyles,
  InputLabel
} from '@material-ui/core'
import { addPx } from '../../content/utils'

const styles = () => createStyles({})

interface Props extends WithStyles<typeof styles> {
  item: any | null
  open: boolean
  menuStyling: any
  onClose(): void
  onSave(id: string, data: any): void
}

const EditMenuItemStyling: React.FunctionComponent<Props> = props => {
  const { classes, item, open, menuStyling, onClose, onSave } = props

  const emptyData = {}
  const initialData = !item ? emptyData : item
  const [data, setData] = useState(initialData)

  useEffect(() => {
    if (item && item.id !== data.id) {
      setData(item)
    }
    if (!item && data.id) {
      setData(initialData)
    }
  }, [item, data.id, initialData])

  if (!item) {
    return null
  }

  const handleChangeStylingFieldUpdate = (
    value: string | number,
    fieldName: string
  ): void => {
    console.log(value)
    const styling = data.styling || {}
    const newValue = { ...styling, [fieldName]: addPx(value) }
    setData({ ...data, styling: newValue })
  }

  const handleChangeStylingFieldSave = (): void => {
    const styling = data.styling || {}
    onSave(data.id, styling)
  }

  const handleClose = (): void => {
    setData(initialData)
    onClose()
  }

  const handleResetDefault = (): void => {
    const styling = data.styling || {}
    const defaultFontSize = null
    styling.fontSize = defaultFontSize
    setData({ ...data, styling })
    onSave(data.id, styling)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ width: 400 }} id="form-dialog-title">
        Edit styling
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Grid container={true} justify="center" alignItems="center">
          {item.module.contentDetail &&
          item.module.contentDetail.type &&
          item.module.contentDetail.type === 'SHOP_LOGO' ? (
            <>
              <InputLabel style={{ marginBottom: 10 }} shrink={true}>
                Logo width -{' '}
                {data.styling && data.styling.logoWidth
                  ? data.styling.logoWidth
                  : 'default'}
              </InputLabel>
              <Grid container={true} justify="space-between">
                <Slider
                  value={
                    data.styling && data.styling.logoWidth
                      ? parseInt(data.styling.logoWidth, 10)
                      : parseInt(menuStyling.logoWidth, 10) || 150
                  }
                  aria-labelledby="slider-label"
                  step={1}
                  min={0}
                  max={300}
                  onChange={(e: any, value: number) =>
                    handleChangeStylingFieldUpdate(value, 'logoWidth')
                  }
                  onChangeCommitted={handleChangeStylingFieldSave}
                />
              </Grid>
            </>
          ) : (
            <>
              <InputLabel style={{ marginBottom: 10 }} shrink={true}>
                Font size -{' '}
                {data.styling && data.styling.fontSize
                  ? data.styling.fontSize
                  : 'default'}
              </InputLabel>
              <Grid container={true} justify="space-between">
                <Slider
                  value={
                    data.styling && data.styling.fontSize
                      ? parseInt(data.styling.fontSize, 10)
                      : parseInt(menuStyling.fontSize, 10) || 14
                  }
                  aria-labelledby="slider-label"
                  step={1}
                  min={0}
                  max={100}
                  onChange={(e: any, value: number) =>
                    handleChangeStylingFieldUpdate(value, 'fontSize')
                  }
                  onChangeCommitted={handleChangeStylingFieldSave}
                />
              </Grid>
            </>
          )}
          <Grid container={true} justify="center">
            <Button onClick={handleResetDefault}>Reset to default</Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles, { withTheme: true })(EditMenuItemStyling)
