import React from 'react'
import { TextField } from '@material-ui/core'

interface Props {
  styleId: string | null
  contentId: string | null
  level: 'root' | 'style'
  fieldName: string
  defaultValue: string
  handleUpdate: any
}

const TextFieldControl: React.FC<Props> = props => {
  const {
    styleId,
    contentId,
    level,
    fieldName,
    defaultValue,
    handleUpdate
  } = props
  return (
    <TextField
      placeholder=""
      defaultValue={defaultValue ? defaultValue : ''}
      onBlur={e =>
        handleUpdate(contentId, fieldName, e.target.value, level, styleId)
      }
    />
  )
}

export default TextFieldControl
