import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import DiscountList from './DiscountList';
import DiscountDetail from './DiscountDetail';

class Fabrics extends Component {
  render() {
    const { shopId, discountId, shop } = this.props;
    return discountId ? (
      <DiscountDetail
        currencies={shop.currencies}
        languages={shop.languages}
        shopId={shopId}
        discountId={discountId}
        defaultLanguage={shop.defaultLanguage}
      />
    ) : (
      <Grid>
        <DiscountList defaultLanguage={shop.defaultLanguage} shopId={shopId} />
      </Grid>
    );
  }
}
export default Fabrics;
