import React from 'react'
import { Typography, Grid, Button } from '@material-ui/core';
import {Smartphone, DesktopWindows} from '@material-ui/icons'

interface Props {
  Template: any
}


const ResponsiveViewSwitch: React.FunctionComponent<Props> = (props) => {

  const handleSwitch = (view: string) => {
    props.Template.setState({
      viewMode: view
    })
  }

  return (
    <Grid item>
    <Grid container={true} justify="flex-end">
      <Button style={{
        color:'#000',
        cursor: 'pointer',
        textDecoration: props.Template.state.viewMode === 'desktop' ? 'underline' : 'none',
      }} onClick={()=>handleSwitch('desktop')}><DesktopWindows/></Button>
      <Button style={{
        color:'#000',
        cursor: 'pointer',
        textDecoration: props.Template.state.viewMode === 'mobile' ? 'underline' : 'none',
      }} onClick={()=>handleSwitch('mobile')}><Smartphone/></Button>
    </Grid>   
    </Grid> 
  )
}

export default ResponsiveViewSwitch