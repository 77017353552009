import React from 'react'
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { download } from '../../../../../src/helper/download'

interface Props {
    designTemplate: any; 
    latestRevisionData: any;
}

const Elements: React.FunctionComponent<Props> = props => {

    const { designTemplate, latestRevisionData } = props
    let designElements = (latestRevisionData && latestRevisionData.designElements)? latestRevisionData.designElements : []
    if (latestRevisionData && latestRevisionData.designElements) {
        let names: Array<string> = []
        designElements = latestRevisionData.designElements.reduce((unique:Array<any>, item:any) => {
            if (!names.includes(item.baseElement.name)) {
                names.push(item.baseElement.name)
                return [...unique, item]
            } else {
                return unique
            }
        }, [])
    }
    return (
        <>
            <h2>Elements</h2>
            {designElements.map( (element: any, key: number) => (
                <div key={`element${key}`}>
                <p style={{position: 'relative', borderBottom: '1px solid #B23566', paddingLeft: '30px'}} data-testid="element">
                    <span style={
                        {
                            position: 'absolute', 
                            display: 'block', 
                            height: '1em', 
                            width: '20px', 
                            backgroundColor: '#B23566', 
                            top: 0, 
                            left: 0,
                            textAlign: 'center',
                            color: '#FFF'
                        }
                    }>#{key + 1}</span>
                    {element.baseElement.name}
                </p>

                { element.designAttributes? 
                <>
                    { element.countryFlag ? 
                    <>
                        <h2>Flag</h2>
                        <table className="table">
                            <thead>
                                <tr style={{ fontWeight: 'bold' }}>
                                    <th>FlagName</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                    <td style={{width: '40%'}}>
                                        {element.countryFlag}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                    :
                    <>
                        <h2>Aop</h2>
                        <table className="table">
                            <thead>
                                <tr style={{ fontWeight: 'bold' }}>
                                    <th>File</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                    <td style={{width: '40%'}}>
                                        <img src={element.productDesignPrint.src} alt="" style={{maxWidth: '100%'}} data-testid="element-print-img" />
                                        <Button variant="contained" color="primary" onClick={() => {download(element.productDesignPrint.src)}}>
                                            <FontAwesomeIcon icon={faDownload} />
                                        </Button>
                                    </td>
                                    <td style={{ verticalAlign: 'top' }}>
                                        {element.productDesignPrint.src.substring(element.productDesignPrint.src.lastIndexOf('/')+1)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                    }
                </>                
                : 
                <>
                    <h2>Direct SVG Color</h2>
                    <table className="table">
                        <thead>
                            <tr style={{ fontWeight: 'bold' }}>
                                <th>Color</th>
                                <th>Color Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ textAlign: 'center' }}>
                                <td style={{width: '40%', backgroundColor: element.colorHex}}></td>
                                <td>{element.colorHex}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
                }
                </div>
            ))
            }
        </>
    )
}

export default Elements