import React, { useContext } from 'react'
import {
  Grid,
  Typography,
  createStyles,
  WithStyles,
  withStyles,
  Button
} from '@material-ui/core'
import { getTextFromLanguage } from '../../../../../../../helper/languageHelper'
import Imgix from 'react-imgix'
import { Link } from 'gatsby'
import {
  SelectedLanguageContext,
  UrlPrefixContext
} from '../../LanguageContext'

interface Props extends WithStyles<typeof styles> {
  collections: any
}

const styles = () =>
  createStyles({
    card: {
      '&:hover': {
        boxShadow:
          ' 0 3px 6px rgba(140, 140, 140, 0.16), 0 3px 6px rgba(140, 140, 140, 0.23)'
      }
    }
  })

const TeamstoreCollections: React.FC<Props> = props => {
  const { collections, classes } = props

  const selectedLanguage = useContext(SelectedLanguageContext)
  const urlPrefix = useContext(UrlPrefixContext)

  if (!collections)
    return (
      <Grid
        justify="center"
        style={{ padding: 25, textAlign: 'center', fontStyle: 'italic' }}
      >
        <span> Please add collections to display</span>
      </Grid>
    )

  return (
    <Grid justify="center" container={true}>
      {collections.edges.map((collection: any, index: number) => {
        return (
          <Grid
            key={index}
            item={true}
            xs={6}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            style={{
              padding: 8
            }}
          >
            <Link
              to={
                collection?.node?.collection.slugs
                  ? `/${`${urlPrefix}/` +
                      getTextFromLanguage(
                        collection.node.collection.slugs,
                        selectedLanguage
                      )}`
                  : '/'
              }
              style={{
                position: 'relative',
                color: '#000',
                textDecoration: 'none'
              }}
            >
              <Grid
                container={true}
                className={classes.card}
                style={{
                  borderRadius: 4,
                  minHeight: 270
                }}
              >
                <Grid item={true} xs={12} style={{ height: '250px' }}>
                  <Imgix
                    height={270}
                    htmlAttributes={{
                      alt: '',
                      style: {
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block'
                      }
                    }}
                    src={collection?.node?.collection.thumbnail || ''}
                    imgixParams={{
                      auto: 'compress'
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} style={{ padding: '4px 16px' }}>
                  <Typography>
                    {collection?.node?.collection.title.defaultValue || null}
                  </Typography>
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  style={{
                    padding: '4px 16px',
                    textAlign: 'left'
                  }}
                >
                  <Button variant="text">Shop the collection</Button>
                </Grid>
              </Grid>
            </Link>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default withStyles(styles)(TeamstoreCollections)
