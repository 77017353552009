import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Img from '../../content/media/Img'
import Body1 from '../../content/typography/Body1'
import Divider from '@material-ui/core/Divider'
import { graphql, Link } from 'gatsby'
import _get from 'lodash/get'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import Imgix from 'react-imgix'
import Price from '../Price'
// import DummyImage from '../../../../img/DUMMYBANNER.jpg';
import _orderBy from 'lodash/orderBy'
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../../brikl-config'

const styles = theme => ({
  card: {
    padding: '0px',
    width: '100%',
    height: '100%',
    border: '1px solid #ccc',
    zIndex: 10000
  },
  cardHover: {
    width: 'calc(100% - 16px)',
    border: '1px solid #ccc',
    padding: '24px',
    left: '8px',
    zIndex: 10000,
    position: 'absolute',
    background: '#fff',
    borderTop: '0px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      display: 'none !important'
    }
  },
  media: {
    height: 240,
    objectFit: 'contain',
    width: '100%',
    borderBottom: '1px solid #D8D8D8'
  },
  list: {
    padding: '0'
  },
  image: {
    height: '250px',
    objectFit: 'cover'
  },
  popover: {
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '0px'
  }
})

const mobileWidthCol = 12
const smalldesktopWidthCol = 3
const desktopWidthCol = 2

const ProductAttribute = ({
  attributeIcon,
  attributeImage,
  classes,
  langCode,
  productAttribute
}) => {
  return (
    <Grid item xs={12} lg={12} key={productAttribute.attribute.id}>
      <Grid container justify="flex-start" alignItems="center" spacing={2}>
        <Grid item style={{ width: 40 }}>
          {attributeIcon ? (
            <span
              style={{ fontSize: '1.4rem' }}
              className={`icon--product icon--${productAttribute.attribute.icon}`}
            />
          ) : attributeImage && attributeImage.indexOf('undefined') === -1 ? (
            <Imgix
              height={40}
              htmlAttributes={{
                alt: 'Attribute',
                style: {
                  width: '90%',
                  height: '100%',
                  margin: 'auto',
                  objectFit: 'contain'
                }
              }}
              imgixParams={{
                auto: 'compress'
              }}
              src={
                attributeImage
                  ? attributeImage.replace(MEDIA_CDN_URL, IMGIX_URL)
                  : ''
              }
            />
          ) : null}
        </Grid>
        <Grid item style={{ width: 'calc(100% - 40px)' }}>
          <Typography
            variant="body1"
            style={{
              color: '#000',
              fontSize: '11px',
              textTransform: 'capitalize'
            }}
          >
            {getTextFromLanguage(
              _get(productAttribute, 'attribute.title.text', []),
              langCode
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

class ProductCard extends React.Component {
  state = {
    hover: false,
    activeIndex: 0
  }
  hoverOn = event => {
    this.setState({ hover: true })
  }

  hoverOff = () => {
    this.setState({ hover: false })
  }
  render() {
    const {
      classes,
      customUrl,
      langCode,
      product,
      urlPrefix,
      salesChannel,
      buttonText,
      taxSettings,
      defaultCurrency,
      currencyRates,
      numberOfCards,
      isPackage,
      isTemplate
    } = this.props

    let xs, sm, md, lg, xl

    if (numberOfCards) {
      switch (numberOfCards) {
        case 1:
          xs = 12
          sm = 12
          md = 12
          lg = 12
          xl = 12
          break
        case 2:
          xs = 6
          sm = 6
          md = 6
          lg = 6
          xl = 6
          break
        case 3:
          xs = 6
          sm = 6
          md = 6
          lg = 4
          xl = 4
          break
        case 4:
          xs = 6
          sm = 6
          md = 4
          lg = 3
          xl = 3
          break
        default:
          xs = 6
          sm = 6
          md = 4
          lg = 3
          xl = 3
      }
    } else {
      xs = 6
      sm = 6
      md = 4
      lg = 3
      xl = 3
    }

    var title = null
    var button = null
    try {
      title = getTextFromLanguage(_get(product, 'title.text', []), langCode)
      button = getTextFromLanguage(buttonText.text, langCode)
    } catch (error) {}
    var src = null

    try {
      src = _get(product, 'media', []).find(m => m.isThumbnail === true).image
    } catch (error) {}
    var slug = null
    try {
      if (customUrl) {
        slug = customUrl
      } else {
        if (process.env.GATSBY_BRIKL_SALESCHANNEL_ID) {
          slug = `/${urlPrefix}${
            _get(product, 'slugs', []).find(e => e.langCode === langCode)
              ? _get(product, 'slugs', []).find(e => e.langCode === langCode)
                  .content
              : ''
          }`
        } else {
          if (salesChannel) {
            slug = `/${urlPrefix}${
              _get(salesChannel, 'slugs', []).find(e => e.langCode === langCode)
                ? _get(salesChannel, 'slugs', []).find(
                    e => e.langCode === langCode
                  ).content
                : ''
            }/${isPackage === true ? 'package' : 'product'}?p=${
              product.id
            }&custom=${product.isCustomProduct === true ? '1' : '0'}`
          } else {
            if (product && product.slugs) {
              slug = `/${urlPrefix}${
                _get(product, 'slugs', []).find(e => e.langCode === langCode)
                  ? _get(product, 'slugs', []).find(
                      e => e.langCode === langCode
                    ).content
                  : ''
              }`
            }
          }
        }
      }
    } catch (error) {
      console.error('ProductCard.slugs.error', error)
    }

    if (!product) return null

    let attributes = []
    let featuredAttributes = []
    let topFeaturedAttribute
    let secondFeaturedAttribute
    let featuredAttributeTitle = null
    try {
      if (product && product.assignedProductAttributes) {
        featuredAttributes = _orderBy(
          product.assignedProductAttributes.filter(
            a => a.attribute.isFeatured === true
          ),
          'attribute.sortOrder',
          'asc'
        )
        if (featuredAttributes) {
          try {
            topFeaturedAttribute = featuredAttributes[0].attribute.icon ? (
              <Grid item={true} xs={12} style={{ marginBottom: 16 }}>
                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  direction="row"
                >
                  <span
                    style={{ fontSize: '1.2rem', textAlign: 'right' }}
                    className={`icon--product icon--${featuredAttributes[0].attribute.icon}`}
                  />
                </Grid>
              </Grid>
            ) : featuredAttributes[0].attribute.image &&
              featuredAttributes[0].attribute.image.indexOf('undefined') ===
                -1 ? (
              <Grid item={true} xs={12} style={{ marginBottom: 16 }}>
                <Grid
                  container
                  justify="flex-assignedProductAttributes"
                  alignItems="center"
                  direction="row"
                >
                  <span
                    style={{
                      width: 40,
                      height: 26,
                      textAlign: 'center'
                    }}
                  >
                    <Imgix
                      height={40}
                      htmlAttributes={{
                        alt: 'Attribute',
                        style: {
                          width: '90%',
                          margin: 'auto',
                          height: '100%',
                          objectFit: 'contain'
                        }
                      }}
                      imgixParams={{
                        auto: 'compress'
                      }}
                      src={
                        featuredAttributes[0].attribute.image
                          ? featuredAttributes[0].attribute.image.replace(
                              MEDIA_CDN_URL,
                              IMGIX_URL
                            )
                          : ''
                      }
                    />
                  </span>
                </Grid>
              </Grid>
            ) : null
          } catch (error) {}
          try {
            secondFeaturedAttribute = featuredAttributes[1] ? (
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item style={{ width: 40 }}>
                  {featuredAttributes[1].attribute.icon ? (
                    <span
                      style={{ fontSize: '1.4rem', lineHeight: '40px' }}
                      className={`icon--product icon--${featuredAttributes[1].attribute.icon}`}
                    />
                  ) : featuredAttributes[1].attribute.image &&
                    featuredAttributes[1].attribute.image.indexOf(
                      'undefined'
                    ) === -1 ? (
                    <Imgix
                      height={25}
                      htmlAttributes={{
                        alt: 'Attribute',
                        style: {
                          height: 25,
                          maxWidth: 50,
                          objectFit: 'contain',
                          fontSize: '1.4rem'
                        }
                      }}
                      imgixParams={{
                        auto: 'compress'
                      }}
                      src={
                        featuredAttributes[1].attribute.image
                          ? featuredAttributes[1].attribute.image.replace(
                              MEDIA_CDN_URL,
                              IMGIX_URL
                            )
                          : ''
                      }
                    />
                  ) : null}
                  {console.log(featuredAttributes[1].attribute.image)}
                </Grid>
                <Grid item style={{ width: 'calc(100% - 58px)' }}>
                  <Typography
                    variant="body1"
                    style={{
                      color: '#000',
                      fontSize: '12px',
                      textTransform: 'capitalize'
                    }}
                  >
                    {getTextFromLanguage(
                      _get(featuredAttributes[1], 'attribute.title.text', []),
                      langCode
                    )}
                  </Typography>
                </Grid>
              </Grid>
            ) : null
          } catch (error) {
            console.error(error)
          }
        }
        _orderBy(
          product.assignedProductAttributes.filter(
            a => a.attribute.isFeatured !== true
          ),
          'attribute.sortOrder',
          'asc'
        ).forEach((productAttribute, index) => {
          let attributeImage = null
          try {
            if (productAttribute.attribute.isFeaturedAttributeTitle === true) {
              featuredAttributeTitle = getTextFromLanguage(
                _get(productAttribute, 'attribute.title.text', []),
                langCode
              )
            }
          } catch (error) {}
          try {
            attributeImage = productAttribute.attribute.image
          } catch (error) {}
          let attributeIcon = null
          try {
            attributeIcon = productAttribute.attribute.icon
          } catch (error) {}

          if (featuredAttributes.length + index < 3) {
            attributes.push(
              <ProductAttribute
                key={'productAttribute' + index}
                attributeIcon={attributeIcon}
                attributeImage={attributeImage}
                classes={classes}
                langCode={langCode}
                productAttribute={productAttribute}
              />
            )
          }
        })
      }
    } catch (error) {
      console.error('ProductCard.attributes.error', error)
    }

    return (
      <Grid
        item
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        style={{
          position: 'relative',
          padding: '8px'
        }}
      >
        <Card
          className={classes.card}
          onMouseEnter={this.hoverOn}
          onMouseLeave={this.hoverOff}
          elevation={0}
        >
          <Link
            to={slug ? slug : '/'}
            style={{ textDecoration: 'none', color: '#000' }}
          >
            <CardContent style={{ padding: '8px', height: '250px' }}>
              {src ? (
                <Imgix
                  height={250}
                  htmlAttributes={{
                    alt: title,
                    style: {
                      ...(isTemplate && {
                        height: '95%',
                        objectPosition: 'center'
                      }),
                      width: '100%',
                      objectFit: 'contain',
                      display: 'block'
                    }
                  }}
                  imgixParams={{
                    auto: 'compress',
                    ...(isTemplate && {
                      'trim-md': 0,
                      trim: 'auto'
                    })
                  }}
                  src={src ? src.replace(MEDIA_CDN_URL, IMGIX_URL) : ''}
                  alt={title}
                />
              ) : null // <Img src={DummyImage} alt={title} /> //
              }
            </CardContent>

            <CardContent style={{ padding: 24 }}>
              <Typography variant="h5" style={{ marginBottom: '16px' }}>
                <Price
                  salesChannelId={
                    process.env.GATSBY_BRIKL_SALESCHANNEL_ID || salesChannel
                      ? salesChannel.id
                      : 'MYBRIKL'
                  }
                  defaultCurrency={defaultCurrency}
                  productId={product.id}
                  currencyRates={currencyRates}
                  taxSettings={taxSettings}
                  price={product.price}
                  discounts={product.discounts}
                />
              </Typography>

              {title ? (
                <Typography variant="subtitle1" style={{ fontSize: 14 }}>
                  {title}
                </Typography>
              ) : null}

              {featuredAttributeTitle ? (
                <Typography variant="body1" style={{ fontSize: 12 }}>
                  {featuredAttributeTitle}
                </Typography>
              ) : null}

              <span
                style={{
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  width: 70,
                  height: 26
                }}
              >
                {topFeaturedAttribute}
              </span>

              {this && this.state.hover ? (
                <div className={classes.cardHover}>
                  <Divider
                    style={{
                      width: '100%',
                      padding: 0,
                      marginBottom: 16,
                      marginTop: 16
                    }}
                  />

                  {secondFeaturedAttribute}

                  {product &&
                  product.isCustomProduct === true &&
                  attributes &&
                  attributes.length < 1 ? null : (
                    <Grid container style={{ marginBottom: 16 }}>
                      {attributes}
                    </Grid>
                  )}
                  <Grid container justify="center">
                    <Button variant="contained" color="primary">
                      {button ? button : 'ORDER NOW'}
                    </Button>
                  </Grid>
                </div>
              ) : null}
            </CardContent>
          </Link>
        </Card>
      </Grid>
    )
  }
}
ProductCard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProductCard)
