import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { navigate } from '@reach/router'
const logo = require('./brikl-logo.png') as string

interface Props {
  shopId: String
}

const links = []

const NewHeader: React.FC<Props> = props => {
  const { shopId } = props
  return (
    <Grid
      style={{
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12)',
        padding: 10,
        backgroundColor: 'white',
        position: 'sticky'
      }}
      container={true}
      justify="center"
      direction="row"
    >
      <Grid item={true} xs={4}>
        <img
          style={{
            maxHeight: '25px',
            height: '100%',
            objectFit: 'contain',
            position: 'relative',
            left: '176px',
            top: 4
          }}
          src={logo}
        />
      </Grid>

      <Grid style={{ textAlign: 'center' }} item={true} xs={4}>
        {links.map((item, index) => (
          <Button
            key={index}
            onClick={() => navigate(`/admin/${shopId}/${item.link}`)}
            variant="text"
          >
            <Typography style={{ fontFamily: 'Poppins' }}>
              {item.label}
            </Typography>
          </Button>
        ))}
      </Grid>
      <Grid item={true} xs={4}></Grid>
    </Grid>
  )
}

export default NewHeader
