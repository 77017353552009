/**
 * Simple console.log wrapper to have more control
 */
class Logger {
  private _isEnabled: boolean

  constructor() {
    this._isEnabled = true
  }

  public enable = () => {
    this._isEnabled = true
  }
  public disable = () => {
    this._isEnabled = false
  }

  get isEnabled() {
    return this._isEnabled
  }

  public log(message?: any, ...optionalParams: any[]) {
    if (this._isEnabled) {
      console.log(message, ...optionalParams)
    }
  }
  public error(message?: any, ...optionalParams: any[]) {
    if (this._isEnabled) {
      console.error(message, ...optionalParams)
    }
  }
}

/**
 * A temp global for the logger to use instead of console.logs
 */
;(global as any).logger = new Logger()
