import gql from 'graphql-tag'

export const CONTENT_MODEL_STATUS_FIELD_FRAGMENT = gql`
  fragment contentModelStatusFieldFragment on ContentModel {
    id
    status
    defaultPage
    slugs {
      langCode
      content
    }
  }
`

export const CONTENT_MODEL_MODELS_FRAGMENT = gql`
  fragment contentModelModelsFragment on ContentModel {
    latestRevisionNo
    salesChannelId
    slugs {
      langCode
      content
    }
    latestRevision {
      id
      modelId
      name {
        id
        defaultValue
        text {
          langCode
          content
        }
      }
      description {
        id
        defaultValue
        text {
          langCode
          content
        }
      }
      pageTitle {
        id
        text {
          langCode
          content
        }
      }

      headerCustomCode
      footerCustomCode
      openGraphImageSrc
      revisionNo
    }
  }
`
