import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Button from '@material-ui/core/Button';
import { ADMIN_GET_ADMIN_USERS } from '../../gql/admin-queries';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCard from './AccountCard';
import { useQuery } from '@apollo/react-hooks';
import Dialog from '@material-ui/core/Dialog';
import AddAdminUser from './AddAdminUser';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  container: {
    padding: '8px 0px'
  },
}));
const Accounts = ({ shopId }) => {
  const [open, setOpen] = React.useState(false);
  const { data, loading, error } = useQuery(ADMIN_GET_ADMIN_USERS, { variables: { id: shopId } })
  const classes = useStyles();

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  if (loading) return <CircularProgress />;
  if (error) return <h2>{`Error: ${error}`}</h2>;

  let adminUsers = ''

  try {
    adminUsers = data.shop.adminUsers
  } catch (error) {}
  
  return (
    <Grid container className={classes.root}>
      <Grid
        container
        justify="flex-start"
        direction="row"
        alignItems="center"
        style={{ height: 60, marginBottom: 20 }}
      >
        <Typography
          style={{
            fontSize: 28,
            fontWeight: 400,
            color: '#424242'
          }}
        >
          Accounts
        </Typography>
        <div style={{ flex: 1 }} />
        <Button onClick={handleClickOpen} variant="contained" color="primary">
          Add members
        </Button>
      </Grid>
      <Grid container>
        {adminUsers && adminUsers.edges.length > 0 ? adminUsers.edges.map((adminUser, index) => {
          return <AccountCard key={index} adminUser={adminUser} />
        }): <Typography variant="body1">You don't have any accounts :(</Typography>}                   
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <AddAdminUser shopId={shopId} handleClose={handleClose} />
      </Dialog>
    </Grid>
  )
}

export default Accounts