import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_GET_TEXT_DESIGN_STEP } from '../../gql/admin-queries';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import { ADMIN_UPDATE_TEXT_DESIGN_STEP } from '../../gql/admin-mutations';
import _get from 'lodash/get';
class TextStepSettings extends Component {
  state = {};
  render() {
    const classes = {};
    const { stepId, printingTechniques } = this.props;
    return (
      <div className={classes.root}>
        <Query query={ADMIN_GET_TEXT_DESIGN_STEP} variables={{ id: stepId }}>
          {({ loading, error, data: { textDesignStep } }) => {
            if (loading) return <div>Loading...</div>;
            if (error) return <div>Error :(</div>;
            return (
              <div>
                <Mutation
                  mutation={ADMIN_UPDATE_TEXT_DESIGN_STEP}
                  update={(cache, { data: { updateTextDesignStep } }) => {
                    if (updateTextDesignStep.id) {
                      // const data = store.readQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: colorId }
                      // });
                      // data.product = updateColor;
                      // store.writeQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: colorId },
                      //   data
                      // });
                    }
                  }}
                >
                  {(updateTextDesignStep, { data, loading, error }) => {
                    return (
                      <div>
                        <input
                          onChange={e => {
                            updateTextDesignStep({
                              variables: {
                                id: stepId,
                                input: {
                                  stepSortOrder: parseInt(e.target.value, 10)
                                }
                              }
                            });
                          }}
                          placeholder="stepSortOrder"
                          type="text"
                          value={textDesignStep.stepSortOrder}
                          id="internalCstepSortOrderode"
                        />
                        <br />
                        {_get(textDesignStep, 'textPrintingTechniques', []).map(
                          (printingNode1, prIndex) => {
                            return (
                              <div key={'prt' + printingNode1.id}>
                                {printingNode1.id}{' '}
                                <button
                                  onClick={() => {
                                    updateTextDesignStep({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateTextDesignStep: {
                                          ...textDesignStep,
                                          textPrintingTechniqueIds: textDesignStep.textPrintingTechniqueIds
                                            ? textDesignStep.textPrintingTechniqueIds.filter(
                                                tempPRT =>
                                                  tempPRT !== printingNode1.id
                                              )
                                            : []
                                        }
                                      },
                                      variables: {
                                        id: stepId,
                                        input: {
                                          textPrintingTechniqueIds: textDesignStep.textPrintingTechniqueIds
                                            ? textDesignStep.textPrintingTechniqueIds.filter(
                                                tempPRT =>
                                                  tempPRT !== printingNode1.id
                                              )
                                            : []
                                        }
                                      }
                                    });
                                  }}
                                >
                                  Delete
                                </button>
                                {printingNode1.id ===
                                textDesignStep.defaultPrintingTechnique ? (
                                  'Default'
                                ) : (
                                  <button
                                    onClick={() => {
                                      updateTextDesignStep({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateTextDesignStep: {
                                            ...textDesignStep,
                                            defaultPrintingTechnique:
                                              printingNode1.id
                                          }
                                        },
                                        variables: {
                                          id: stepId,
                                          input: {
                                            defaultPrintingTechnique:
                                              printingNode1.id
                                          }
                                        }
                                      });
                                    }}
                                  >
                                    Make default
                                  </button>
                                )}
                              </div>
                            );
                          }
                        )}
                        <div>
                          Printing techniques
                          {printingTechniques.map((printingNode, index) => {
                            const printingT = printingNode.node;
                            if (
                              textDesignStep.textPrintingTechniqueIds &&
                              textDesignStep.textPrintingTechniqueIds.includes(
                                printingT.id
                              )
                            ) {
                              return null;
                            } else {
                              return (
                                <div key={'print-' + printingT.id}>
                                  <button
                                    onClick={() => {
                                      updateTextDesignStep({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateTextDesignStep: {
                                            ...textDesignStep,
                                            textPrintingTechniqueIds: textDesignStep.textPrintingTechniqueIds
                                              ? [
                                                  ...textDesignStep.textPrintingTechniqueIds,
                                                  printingT.id
                                                ]
                                              : [printingT.id]
                                          }
                                        },
                                        variables: {
                                          id: stepId,
                                          input: {
                                            textPrintingTechniqueIds: textDesignStep.textPrintingTechniqueIds
                                              ? [
                                                  ...textDesignStep.textPrintingTechniqueIds,
                                                  printingT.id
                                                ]
                                              : [printingT.id]
                                          }
                                        }
                                      });
                                    }}
                                  >
                                    Add{' '}
                                    {_get(
                                      printingT,
                                      'customName.text[0].content',
                                      ''
                                    )}
                                  </button>
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div>
                          {textDesignStep && textDesignStep.fonts
                            ? textDesignStep.fonts.map((font, fontIndex) => {
                                return (
                                  <div key={'font' + font.id}>
                                    {JSON.stringify(font)}
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    );
                  }}
                </Mutation>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default TextStepSettings;
