import React, { Component } from 'react';
import { Grid, Typography, Paper, Input, InputLabel } from '@material-ui/core';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';

const uuidv4 = require('uuid/v4');
const omitDeep = require('omit-deep');
const ProductTitle = ({
  classes,
  product,
  productId,
  languages,
  updateProductDetail,
  updateMultilangDetail
}) => {
  const titleId =
    product.title && product.title.id ? product.title.id : uuidv4();
  return (
    <Grid
      container
      alignItems="flex-start"
      spacing={1}
      className={classes.container}
      style={{ marginTop: 8 }}
    >
      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <Grid
          container
          style={{ padding: '24px 24px 24px 0px' }}
          spacing={1}
          alignItems="center"
        >
          <Grid item>
            <Typography
              data-cy="Product-Title-header"
              variant="subtitle1"
              style={{ marginRight: 16 }}
            >
              Product title
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
        <Paper style={{ padding: 24, borderRadius: 15 }} elevation={0}>
          {languages.map((language, index) => {
            var titleText = '';
            try {
              titleText = getTextFromLanguage(product.title.text, language);
            } catch (error) {}
            return (
              <Grid
                container
                className={classes.container}
                spacing={1}
                key={'style-titleText-' + index}
                direction="row"
                alignItems="flex-end"
              >
                <Grid item xs={10}>
                  <InputLabel>Title</InputLabel>
                  <Input
                    dataCy={'Product-Title-' + language}
                    label="Title"
                    placeholder="Enter title"
                    multiline={true}
                    rowsMax="4"
                    defaultValue={titleText}
                    onBlur={e => {
                      // update product slugs based on title
                      var newSlugs = product.slugs;
                      if (e.target.value) {
                        if (
                          product.slugs == null ||
                          getTextFromLanguage(product.slugs.text, language) ===
                            '' ||
                          getTextFromLanguage(product.slugs.text, language) ===
                            null
                        ) {
                          const cleanSlug = e.target.value
                            .replace(/[^a-z0-9]+/gi, '')
                            .toLowerCase();
                          newSlugs = product.slugs
                            ? product.slugs.map(s => {
                                return {
                                  ...s,
                                  content:
                                    s.langCode === language &&
                                    (s.content === '' || s.content === null)
                                      ? cleanSlug
                                      : s.content,
                                  __typename: 'SlugLocalised'
                                };
                              })
                            : [
                                {
                                  langCode: language,
                                  content: cleanSlug,
                                  __typename: 'SlugLocalised'
                                }
                              ];
                        }
                      }
                      updateProductDetail(
                        productId,
                        'slugs',
                        omitDeep(newSlugs, ['__typename'])
                      );
                    }}
                    onChange={e => {
                      updateMultilangDetail(
                        productId,
                        'title',
                        e.target.value,
                        titleId,
                        language,
                        product
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    style={{
                      height: 38,
                      width: '100%',
                      border: '1px solid #ddd',
                      borderRadius: '3px'
                    }}
                  >
                    {language}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProductTitle;
