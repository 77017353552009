import React, { useState } from 'react'
import {
  InputLabel,
  MenuItem,
  Select,
  createStyles,
  withStyles,
  WithStyles,
  Grid,
  Typography
} from '@material-ui/core'

import { getTextFromLanguage } from '../../../../../helper/languageHelper'

const styles = () => createStyles({})

interface Props extends WithStyles<typeof styles> {
  data: any
  products: any[]
  onChange(productId: string | null): void
}

const AddMenuLinkProduct: React.FunctionComponent<Props> = props => {
  const { data, products, onChange } = props

  const [selectedProduct, setSelectedProduct] = useState(data.productId)

  const handleChange = (e: any): void => {
    setSelectedProduct(e.target.value)
    onChange(e.target.value)
  }

  return (
    <>
      <InputLabel>Choose a Product to link</InputLabel>
      <Select
        value={selectedProduct}
        onChange={handleChange}
        style={{
          border: '1px solid #eee',
          margin: '8px 0px 16px',
          borderRadius: 4
        }}
        inputProps={{
          id: 'pageId-simple',
          name: 'pageId'
        }}
      >
        <MenuItem value={null}>-</MenuItem>
        {products && products.edges
          ? products.edges.map((product: any) => (
              <MenuItem key={product.node.id} value={product.node.id}>
                {getTextFromLanguage(product.node.title.text, 'en_UK')} (
                {product.node.no})
              </MenuItem>
            ))
          : null}
      </Select>
      <Grid
        container={true}
        alignItems="center"
        style={{ margin: '8px 0px 16px' }}
      >
        <Grid item={true} xs={5} style={{ background: '#eee', height: 2 }}>
          &nbsp;
        </Grid>
        <Grid item={true} xs={2}>
          <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
            Or
          </Typography>
        </Grid>
        <Grid item={true} xs={5} style={{ background: '#eee', height: 2 }}>
          &nbsp;
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(AddMenuLinkProduct)
