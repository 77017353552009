import React, { Component } from 'react';

export default class JustifyFlexStart extends React.Component {
  render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="1.38462" height="18" fill="black" />
        <rect
          x="2.76923"
          y="4.15384"
          width="15.2308"
          height="2.76923"
          fill="black"
        />
        <rect
          x="2.76923"
          y="11.0769"
          width="9.69231"
          height="2.76923"
          fill="black"
        />
      </svg>
    );
  }
}
