import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { getTextFromLanguage } from '../../../../../../helper/languageHelper'
import { SelectedLanguageContext } from '../LanguageContext'
import FlatMuted from './ProductCard/FlatMuted'
import Simple from './ProductCard/Simple'
import Default from './ProductCard/Default'
import Elevated from './ProductCard/Elevated'
import Asos from './ProductCard/Asos'
import { ThemeContext } from '../ThemeContext'
import { PriceContext } from '../PriceContext'

interface Props {
  element: any
  view: any
  dialog: boolean
  urlPrefix: string
}

const Products: React.FC<Props> = props => {
  const { element, dialog, view, urlPrefix } = props

  const theme = useContext(ThemeContext)
  const priceData = useContext(PriceContext)

  if (!element && !element.products) {
    return null
  }

  const selectedLanguage = useContext(SelectedLanguageContext)

  let breakpoint = 3

  if (view && view.mode && view.mode === 'mobile') {
    breakpoint = 12
  } else if (element?.productIds?.length === 1) {
    breakpoint = 8
  } else {
    breakpoint = 3
  }

  if (!dialog) {
    return (
      <>
        {element.productTitle ? (
          <Grid
            style={{ padding: '20px 0px 50px 0px' }}
            container={true}
            justify="center"
          >
            <Typography
              style={{
                fontFamily:
                  theme?.theme?.h1?.style?.fontFamily || 'Roboto, sans-serif'
              }}
              component="h2"
              variant="h1"
            >
              {getTextFromLanguage(
                element.productTitle.text,
                selectedLanguage
              ) || null}
            </Typography>
          </Grid>
        ) : null}
        <Grid justify="center" container={true}>
          {element.products.map((product: any, index: number) => {
            if (element.products.length > 1) {
              return (
                <Grid
                  key={index}
                  item={true}
                  xs={view?.mode ? breakpoint : 12}
                  sm={view?.mode ? breakpoint : 6}
                  md={view?.mode ? breakpoint : 4}
                  lg={view?.mode ? breakpoint : 3}
                  xl={view?.mode ? breakpoint : 3}
                  style={{
                    padding: 8
                  }}
                >
                  {element.variant === 'Default' || !element.variant ? (
                    <Default
                      price={product?.price || null}
                      dialog={dialog}
                      priceData={priceData}
                      productId={product.id}
                      name={
                        getTextFromLanguage(
                          product.title.text,
                          selectedLanguage
                        ) || null
                      }
                      thumbnail={
                        element && element.products && product.media
                          ? product?.media?.[0]?.image
                          : null
                      }
                      slug={
                        product.slugs
                          ? `/${`${urlPrefix}` +
                              getTextFromLanguage(
                                product.slugs,
                                selectedLanguage
                              )}`
                          : null
                      }
                    />
                  ) : element.variant === 'Flat' ? (
                    <FlatMuted
                      price={product?.price || null}
                      priceData={priceData}
                      dialog={dialog}
                      productId={product.id}
                      name={
                        getTextFromLanguage(
                          product.title.text,
                          selectedLanguage
                        ) || null
                      }
                      thumbnail={
                        element && element.products && product.media
                          ? product?.media?.[0]?.image
                          : null
                      }
                      slug={
                        product.slugs
                          ? `/${`${urlPrefix}` +
                              getTextFromLanguage(
                                product.slugs,
                                selectedLanguage
                              )}`
                          : null
                      }
                    />
                  ) : element.variant === 'Simple' ? (
                    <Simple
                      price={product?.price || null}
                      priceData={priceData}
                      dialog={dialog}
                      productId={product.id}
                      name={
                        getTextFromLanguage(
                          product.title.text,
                          selectedLanguage
                        ) || null
                      }
                      thumbnail={
                        element && element.products && product.media
                          ? product?.media?.[0]?.image
                          : null
                      }
                      slug={
                        product.slugs
                          ? `/${`${urlPrefix}` +
                              getTextFromLanguage(
                                product.slugs,
                                selectedLanguage
                              )}`
                          : null
                      }
                    />
                  ) : element.variant === 'Elevated' ? (
                    <Elevated
                      price={product?.price || null}
                      priceData={priceData}
                      dialog={dialog}
                      productId={product.id}
                      name={
                        getTextFromLanguage(
                          product.title.text,
                          selectedLanguage
                        ) || null
                      }
                      thumbnail={
                        element && element.products && product.media
                          ? product?.media?.[0]?.image
                          : null
                      }
                      slug={
                        product.slugs
                          ? `/${`${urlPrefix}` +
                              getTextFromLanguage(
                                product.slugs,
                                selectedLanguage
                              )}`
                          : null
                      }
                    />
                  ) : element.variant === 'Modern' ? (
                    <Asos
                      price={product?.price || null}
                      priceData={priceData}
                      dialog={dialog}
                      productId={product.id}
                      name={
                        getTextFromLanguage(
                          product.title.text,
                          selectedLanguage
                        ) || null
                      }
                      thumbnail={
                        element && element.products && product.media
                          ? product?.media?.[0]?.image
                          : null
                      }
                      slug={
                        product.slugs
                          ? `/${`${urlPrefix}` +
                              getTextFromLanguage(
                                product.slugs,
                                selectedLanguage
                              )}`
                          : null
                      }
                    />
                  ) : null}
                </Grid>
              )
            } else {
              return (
                <Grid
                  style={{
                    height: '300px'
                  }}
                  item={true}
                  xs={12}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                    src={
                      element && element.products && product.media
                        ? product?.media?.[0]?.image
                        : ''
                    }
                  />
                </Grid>
              )
            }
          })}
        </Grid>
      </>
    )
  } else {
    return (
      <>
        {element.productTitle ? (
          <Grid
            style={{ padding: '20px 0px 50px 0px' }}
            container={true}
            justify="center"
          >
            <Typography variant="h1" component="h2">
              {getTextFromLanguage(
                element?.productTitle?.text,
                selectedLanguage
              ) || 'Title'}
            </Typography>
          </Grid>
        ) : null}
        <Grid container={true}>
          {element.productIds.map((id: string, i: number) => {
            if (element.productIds.length > 1) {
              return (
                <Grid
                  key={i}
                  item={true}
                  xs={breakpoint}
                  sm={breakpoint}
                  md={breakpoint}
                  lg={breakpoint}
                  xl={breakpoint}
                  style={{
                    padding: 8
                  }}
                >
                  <Simple
                    currency={null}
                    price={null}
                    name={null}
                    thumbnail={null}
                    slug={null}
                    dialog={dialog}
                  />
                </Grid>
              )
            } else {
              return (
                <Grid
                  key={i}
                  item={true}
                  xs={breakpoint}
                  sm={breakpoint}
                  md={breakpoint}
                  lg={breakpoint}
                  xl={breakpoint}
                  style={{
                    padding: 8
                  }}
                >
                  <Simple
                    currency={null}
                    price={null}
                    name={null}
                    thumbnail={null}
                    slug={null}
                    dialog={dialog}
                  />
                </Grid>
              )
            }
          })}
        </Grid>
      </>
    )
  }
}

export default Products
