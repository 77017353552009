import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query, Mutation } from 'react-apollo';
import {
  ADMIN_GET_PRINTINGTECHNIQUE,
  ADMIN_GET_COLOR,
  ADMIN_SHOP_COLORS
} from '../../gql/admin-queries';
import Button from '@material-ui/core/Button';
import { ADMIN_UPDATE_PRINTINGTECHNIQUE } from '../../gql/admin-mutations';
import _get from 'lodash/get';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '../../../common/Input';
import CircularProgress from '@material-ui/core/CircularProgress';

class PrintingDetail extends Component {
  state = {
    opacity: 0,
    showForm: false,
    showButton: true,
    id: this.props.printingId
  };

  render() {
    const classes = {};
    const { printingId, shopId } = this.props;
    return (
      <Grid
        container
        style={{
          overflow: 'hidden',
          maxWidth: '1000px',
          padding: 24,
          margin: '0 auto',
          width: '100%'
        }}
      >
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ height: 60, marginBottom: 20 }}
        >
          <Typography
            style={{
              fontSize: 28,
              fontWeight: 400,
              color: '#424242'
            }}
          >
            Printing Technique
          </Typography>

          <div style={{ flex: 1 }} />

          <Link
            to={`/admin/${shopId}/inventory/printing`}
            style={{ textDecoration: 'none' }}
          >
            <Button
              style={{ marginLeft: 16 }}
              variant="outlined"
              color="primary"
            >
              Back to printings
            </Button>
          </Link>
        </Grid>

        <Query
          query={ADMIN_GET_PRINTINGTECHNIQUE}
          variables={{ id: printingId }}
        >
          {({ loading, error, data: { printingTechnique } }) => {
            if (loading) return <CircularProgress />;
            if (error) return <div>Error :(</div>;
            return (
              <Grid container>
                <Mutation
                  mutation={ADMIN_UPDATE_PRINTINGTECHNIQUE}
                  update={(cache, { data: { updatePrintingTechnique } }) => {
                    if (updatePrintingTechnique.id) {
                      // const data = store.readQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: printingId }
                      // });
                      // data.product = updatePrintingTechnique;
                      // store.writeQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: printingId },
                      //   data
                      // });
                    }
                  }}
                >
                  {(updatePrintingTechnique, { data, loading, error }) => {
                    return (
                      <Grid container style={{ marginTop: 24 }}>
                        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                          <Grid
                            container
                            style={{ padding: '24px 24px 24px 0px' }}
                            spacing={1}
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography
                                variant="subtitle1"
                                style={{ marginRight: 16 }}
                              >
                                Printing Colors added
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1">
                                remove colors from this printing technique
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                          <Paper style={{ padding: 0 }} elevation={0}>
                            <Grid
                              container
                              style={{ padding: '24px' }}
                              spacing={1}
                              direction=" column"
                            >
                              <Grid item xs={12}>
                                {_get(
                                  printingTechnique,
                                  'customName.text',
                                  []
                                ).map((customNameText, index) => {
                                  return (
                                    <Grid
                                      container
                                      key={'style-customNameText-' + index}
                                      spacing={2}
                                    >
                                      <Grid item xs={10}>
                                        <Input
                                          id="outlined-customName"
                                          label="Title"
                                          value={customNameText.content}
                                          onChange={e => {
                                            updatePrintingTechnique({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updatePrintingTechnique: {
                                                  ...printingTechnique,
                                                  customName: {
                                                    ...printingTechnique.customName,
                                                    text: printingTechnique.customName.text.map(
                                                      (pr1, tempTextIndex1) => {
                                                        if (
                                                          tempTextIndex1 ===
                                                          index
                                                        ) {
                                                          return {
                                                            ...pr1,
                                                            content:
                                                              e.target.value
                                                          };
                                                        } else {
                                                          return pr1;
                                                        }
                                                      }
                                                    )
                                                  }
                                                }
                                              },
                                              variables: {
                                                id: printingId,
                                                input: {
                                                  customName: {
                                                    text: printingTechnique.customName.text.map(
                                                      (pr2, tempTextIndex2) => {
                                                        if (
                                                          tempTextIndex2 ===
                                                          index
                                                        ) {
                                                          return {
                                                            langCode: 'en_UK',
                                                            content:
                                                              e.target.value
                                                          };
                                                        } else {
                                                          return {
                                                            langCode: 'en_UK',
                                                            content: pr2.content
                                                          };
                                                        }
                                                      }
                                                    )
                                                  }
                                                }
                                              }
                                            });
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <Grid
                                          container
                                          alignItems="center"
                                          justify="center"
                                          style={{
                                            height: 36,
                                            width: '100%',
                                            border: '1px solid #ddd',
                                            borderRadius: '3px',
                                            marginTop: 20
                                          }}
                                        >
                                          <Typography variant="body1">
                                            {customNameText.langCode}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                              <Grid item xs={11}>
                                {_get(
                                  printingTechnique,
                                  'description.text',
                                  []
                                ).map((customDescriptionText, dcIndex) => {
                                  // return JSON.stringify(customDescriptionText)
                                  return (
                                    <Grid
                                      container
                                      key={
                                        'style-customDescriptionText-' + dcIndex
                                      }
                                      alignItems="flex-start"
                                    >
                                      <Grid item xs={11}>
                                        <Input
                                          id="outlined-customDescription"
                                          label="Description"
                                          value={customDescriptionText.content}
                                          onChange={e => {
                                            updatePrintingTechnique({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updatePrintingTechnique: {
                                                  ...printingTechnique,
                                                  description: {
                                                    ...printingTechnique.description,
                                                    text: printingTechnique.description.text.map(
                                                      (
                                                        prD,
                                                        tempDCTextIndex
                                                      ) => {
                                                        if (
                                                          tempDCTextIndex ===
                                                          dcIndex
                                                        ) {
                                                          return {
                                                            ...prD,
                                                            content:
                                                              e.target.value
                                                          };
                                                        } else {
                                                          return prD;
                                                        }
                                                      }
                                                    )
                                                  }
                                                }
                                              },
                                              variables: {
                                                id: printingId,
                                                input: {
                                                  description: {
                                                    text: printingTechnique.description.text.map(
                                                      (
                                                        prD2,
                                                        tempDC2TextIndex
                                                      ) => {
                                                        if (
                                                          tempDC2TextIndex ===
                                                          dcIndex
                                                        ) {
                                                          return {
                                                            langCode: 'en_UK',
                                                            content:
                                                              e.target.value
                                                          };
                                                        } else {
                                                          return {
                                                            langCode: 'en_UK',
                                                            content:
                                                              prD2.content
                                                          };
                                                        }
                                                      }
                                                    )
                                                  }
                                                }
                                              }
                                            });
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={1}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <Typography variant="body1">
                                          {customDescriptionText.langCode}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  onChange={e => {
                                    updatePrintingTechnique({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updatePrintingTechnique: {
                                          ...printingTechnique,
                                          colorOpacity: parseFloat(
                                            e.target.checked
                                          )
                                        }
                                      },
                                      variables: {
                                        id: printingId,
                                        input: {
                                          colorOpacity: parseFloat(
                                            e.target.checked
                                          )
                                        }
                                      }
                                    });
                                  }}
                                  placeholder="colorOpacity"
                                  type="text"
                                  label="Color Opacity"
                                  value={printingTechnique.colorOpacity}
                                  id="colorOpacity"
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>

                        <Grid container style={{ marginTop: 24 }}>
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Grid
                              container
                              style={{ padding: '24px 24px 24px 0px' }}
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography
                                  variant="subtitle1"
                                  style={{ marginRight: 16 }}
                                >
                                  Printing Colors added
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="body1">
                                  remove colors from this printing technique
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                            <Paper style={{ padding: 0 }} elevation={0}>
                              <Grid
                                container
                                style={{ padding: '0px' }}
                                spacing={1}
                                direction=" column"
                              >
                                <Grid item xs={12}>
                                  <Table className={classes.table}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            padding: '0px 8px 0px 16px'
                                          }}
                                        >
                                          Color
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            padding: '0px 8px 0px 16px'
                                          }}
                                          align="left"
                                        >
                                          Name
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            padding: '0px 8px 0px 16px'
                                          }}
                                          align="left"
                                        >
                                          Code
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            padding: '0px 8px 0px 16px'
                                          }}
                                          align="left"
                                        >
                                          remove
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {printingTechnique.colorIds
                                        ? printingTechnique.colorIds.map(
                                            colorId => {
                                              // defaultColorId
                                              return (
                                                <Query
                                                  query={ADMIN_GET_COLOR}
                                                  variables={{ id: colorId }}
                                                >
                                                  {({
                                                    loading,
                                                    error,
                                                    data: { color }
                                                  }) => {
                                                    if (loading)
                                                      return (
                                                        <div
                                                          style={{
                                                            display: 'block',
                                                            margin: '1rem 0',
                                                            border:
                                                              '1px solid #777',
                                                            width: '400px'
                                                          }}
                                                        >
                                                          {colorId}
                                                        </div>
                                                      );
                                                    return (
                                                      <TableRow>
                                                        <TableCell
                                                          style={{
                                                            padding:
                                                              '0px 8px 0px 16px'
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              width: '40px',
                                                              height: '40px',
                                                              border:
                                                                '1px solid #333',
                                                              background:
                                                                color.hex
                                                            }}
                                                          />
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            padding:
                                                              '0px 8px 0px 16px'
                                                          }}
                                                        >
                                                          {color.name
                                                            ? color.name
                                                            : color.internalName}
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            padding:
                                                              '0px 8px 0px 16px'
                                                          }}
                                                        >
                                                          {color.code
                                                            ? color.code
                                                            : color.internalCode}
                                                        </TableCell>

                                                        <TableCell
                                                          style={{
                                                            padding:
                                                              '0px 8px 0px 16px'
                                                          }}
                                                        >
                                                          <Button
                                                            onClick={() => {
                                                              updatePrintingTechnique(
                                                                {
                                                                  optimisticResponse: {
                                                                    __typename:
                                                                      'Mutation',
                                                                    updatePrintingTechnique: {
                                                                      ...printingTechnique,
                                                                      colorIds: printingTechnique.colorIds.filter(
                                                                        o =>
                                                                          o !==
                                                                          colorId
                                                                      )
                                                                    }
                                                                  },
                                                                  variables: {
                                                                    id: printingId,
                                                                    input: {
                                                                      colorIds: printingTechnique.colorIds.filter(
                                                                        o =>
                                                                          o !==
                                                                          colorId
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              );
                                                            }}
                                                            variant="text"
                                                          >
                                                            Remove
                                                          </Button>
                                                        </TableCell>
                                                      </TableRow>
                                                    );
                                                  }}
                                                </Query>
                                              );
                                            }
                                          )
                                        : 'No colors yet'}
                                    </TableBody>
                                  </Table>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: 24 }}>
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Grid
                              container
                              style={{ padding: '24px 24px 24px 0px' }}
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography
                                  variant="subtitle1"
                                  style={{ marginRight: 16 }}
                                >
                                  Add printing Colors
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="body1">
                                  Assign colors to your printing technique
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                            <Paper style={{ padding: 0 }} elevation={0}>
                              <Grid
                                container
                                style={{ padding: '0px' }}
                                spacing={1}
                                direction=" column"
                              >
                                <Grid item xs={12}>
                                  <Table className={classes.table}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            padding: '0px 8px 0px 16px'
                                          }}
                                        >
                                          Color
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            padding: '0px 8px 0px 16px'
                                          }}
                                          align="left"
                                        >
                                          Name
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            padding: '0px 8px 0px 16px'
                                          }}
                                          align="left"
                                        >
                                          Code
                                        </TableCell>

                                        <TableCell
                                          style={{
                                            padding: '0px 8px 0px 16px'
                                          }}
                                          align="left"
                                        >
                                          Add to material
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <Query
                                      query={ADMIN_SHOP_COLORS}
                                      variables={{ id: shopId }}
                                    >
                                      {({ loading, error, data }) => {
                                        if (loading)
                                          return <div>Loading...</div>;
                                        if (error) return <div>Error :(</div>;
                                        // return <div>{JSON.stringify(shop)}</div>
                                        if (
                                          data &&
                                          data.shop.colors.edges.length > 0
                                        ) {
                                          return (
                                            <TableBody>
                                              {_get(
                                                data,
                                                'shop.colors.edges',
                                                []
                                              ).map(row => {
                                                const color = row.node;
                                                const colorHTML = (
                                                  <TableRow>
                                                    <TableCell
                                                      scope="row"
                                                      style={{
                                                        padding:
                                                          '0px 8px 0px 16px'
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          width: '40px',
                                                          height: '40px',
                                                          display:
                                                            'inline-block',
                                                          border:
                                                            '1px solid #333',
                                                          background: color.hex
                                                        }}
                                                      />
                                                    </TableCell>

                                                    <TableCell
                                                      style={{
                                                        padding:
                                                          '0px 8px 0px 16px'
                                                      }}
                                                    >
                                                      {color.name
                                                        ? color.name
                                                        : color.internalName}{' '}
                                                    </TableCell>
                                                    <TableCell>
                                                      (
                                                      {color.code
                                                        ? color.code
                                                        : color.internalCode}
                                                      )
                                                    </TableCell>
                                                    <TableCell>
                                                      <Button
                                                        style={{
                                                          display:
                                                            'inline-block'
                                                        }}
                                                        onClick={() => {
                                                          updatePrintingTechnique(
                                                            {
                                                              optimisticResponse: {
                                                                __typename:
                                                                  'Mutation',
                                                                updatePrintingTechnique: {
                                                                  ...printingTechnique,
                                                                  colorIds: printingTechnique.colorIds
                                                                    ? [
                                                                        ...printingTechnique.colorIds,
                                                                        color.id
                                                                      ]
                                                                    : [color.id]
                                                                }
                                                              },
                                                              variables: {
                                                                id: printingId,
                                                                input: {
                                                                  colorIds: printingTechnique.colorIds
                                                                    ? [
                                                                        ...printingTechnique.colorIds,
                                                                        color.id
                                                                      ]
                                                                    : [color.id]
                                                                }
                                                              }
                                                            }
                                                          );
                                                        }}
                                                        key={color.id}
                                                      >
                                                        Add
                                                      </Button>
                                                    </TableCell>
                                                  </TableRow>
                                                );
                                                if (
                                                  printingTechnique.colorIds
                                                ) {
                                                  if (
                                                    !_get(
                                                      printingTechnique,
                                                      'colorIds',
                                                      []
                                                    ).includes(color.id) &&
                                                    color.hex
                                                  ) {
                                                    return colorHTML;
                                                  }
                                                } else {
                                                  return colorHTML;
                                                }
                                              })}
                                            </TableBody>
                                          );
                                        } else {
                                          return <div>No colors</div>;
                                        }
                                      }}
                                    </Query>
                                  </Table>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }}
                </Mutation>
              </Grid>
            );
          }}
        </Query>
      </Grid>
    );
  }
}

export default PrintingDetail;
