import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';

export default class ButtonStyled extends Component {
  render() {
    const {
      classes,
      background,
      borderRadius = '4px',
      border,
      color = '#000000',
      margin,
      padding,
      width,
      height,
      outline = 'none',
      cursor = 'pointer',
      fontWeight = 400,
      fontFamily,
      fontSize,
      textAlign = 'center',
      url,
      textDecoration = 'none',
      disabled,
      urlPrefix,
      type
    } = this.props;

    return (
      <Button
        data-cy={this.props['data-cy']}
        variant="contained"
        disabled={disabled}
        component={Link}
        to={urlPrefix ? `${urlPrefix}/${url}` : `${url}`}
        style={{ ...this.props, border: border, textTransform: 'uppercase' }}
      >
        {this.props.text}
      </Button>
    );
  }
}
