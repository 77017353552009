import React from 'react'
import {
  Grid,
  createStyles,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core'
import Imgix from 'react-imgix'
import { Link } from 'gatsby'
import Price from '../../../../../../store/product/Price'

interface Props extends WithStyles<typeof styles> {
  thumbnail: any
  name: any
  price: any
  slug: any
  priceData: any
  productId: string
  dialog?: boolean
}

const styles = () =>
  createStyles({
    card: {
      '&:hover': {
        boxShadow:
          ' 0 3px 6px rgba(140, 140, 140, 0.16), 0 3px 6px rgba(140, 140, 140, 0.23)'
      }
    }
  })

const Default: React.FC<Props> = props => {
  const {
    thumbnail,
    name,
    price,
    classes,
    slug,
    priceData,
    productId,
    dialog
  } = props

  if (dialog) {
    return (
      <Grid
        container={true}
        className={classes.card}
        style={{
          border: '1px solid #bbb',
          borderRadius: 4,
          height: 400,
          backgroundColor: '#fff',
          margin: '0 auto'
        }}
      >
        <Grid item={true} xs={12} style={{ height: '300px' }}>
          <Imgix
            height={270}
            htmlAttributes={{
              alt: '',
              style: {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                display: 'block'
              }
            }}
            src={
              thumbnail ||
              'https://images.unsplash.com/reserve/LJIZlzHgQ7WPSh5KVTCB_Typewriter.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60'
            }
            imgixParams={{
              auto: 'compress'
            }}
          />
        </Grid>
        <Grid item={true} xs={12} style={{ padding: '4px 16px' }}>
          <Typography>
            <Price
              defaultCurrency={priceData?.defaultCurrency}
              productId={productId}
              currencyRates={priceData?.currencyRates}
              taxSettings={priceData?.taxSettings}
              price={price}
              discounts={priceData?.discounts}
            />
          </Typography>
        </Grid>
        <Grid item={true} xs={12} style={{ padding: '4px 16px' }}>
          <Typography variant="h4" style={{ textTransform: 'lowercase' }}>
            {name?.substring(0, 16) + '...' || `Name cannot be found!`}
          </Typography>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Link
        style={{
          position: 'relative',
          color: '#000',
          textDecoration: 'none'
        }}
        to={slug || ''}
      >
        <Grid
          container={true}
          className={classes.card}
          style={{
            border: '1px solid #bbb',
            borderRadius: 4,
            height: 400,
            backgroundColor: '#fff'
          }}
        >
          <Grid item={true} xs={12} style={{ height: '300px' }}>
            <Imgix
              height={270}
              htmlAttributes={{
                alt: '',
                style: {
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  display: 'block'
                }
              }}
              src={
                thumbnail ||
                'https://images.unsplash.com/reserve/LJIZlzHgQ7WPSh5KVTCB_Typewriter.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60'
              }
              imgixParams={{
                auto: 'compress'
              }}
            />
          </Grid>
          <Grid item={true} xs={12} style={{ padding: '4px 16px' }}>
            <Typography>
              <Price
                defaultCurrency={priceData?.defaultCurrency}
                productId={productId}
                currencyRates={priceData?.currencyRates}
                taxSettings={priceData?.taxSettings}
                price={price}
                discounts={priceData?.discounts}
              />
            </Typography>
          </Grid>
          <Grid item={true} xs={12} style={{ padding: '4px 16px' }}>
            <Typography variant="h4" style={{ textTransform: 'lowercase' }}>
              {name?.substring(0, 16) + '...' || `Name cannot be found!`}
            </Typography>
          </Grid>
        </Grid>
      </Link>
    )
  }
}

export default withStyles(styles)(Default)
