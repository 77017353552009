import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { GET_SHOP_BRAND } from '../../gql/queries';
import { SHOP_LOGO } from '../../helper/config';

class ShopLogo extends Component {
  render() {
    const { shopId } = this.props;
    return (
      <Query
        query={GET_SHOP_BRAND}
        variables={{
          id: shopId
        }}
      >
        {({ loading, error, data }) => {
          var shopLogo = null;
          try {
            shopLogo = data.shop.brandSettings.logo;
          } catch (error) {}
          if (shopLogo) {
            return (
              <img
                src={shopLogo}
                style={{
                  height: '35px',
                  width: '50px',
                  position: 'absolute',
                  objectFit: 'contain',
                  top: '16px',
                  left: '16px'
                }}
                alt="Vendor"
                variant="contained"
                color="primary"
              />
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}
export default ShopLogo;
