import { en_UK, nl_NL, fr_FR, en_US, de_DE } from './localization'

export const vocab = {
  en_UK,
  en_US,
  de_DE,
  nl_NL,
  fr_FR,
  th_TH: {
    // TODO: Fallback to english while is not ready.
    ...en_UK
    // 'My Orders': 'ออเดอร์ของฉัน',
    // 'My Designs': 'ดีไซน์ของฉัน',
    // Fabric: 'ผ้า',
    // Color: 'สี',
    // Text: 'ข้อความ',
    // Logo: 'โลโก้',
    // Label: 'ป้าย',
    // Login: 'เข้าสู่ระบบ',
    // 'Care label': 'ป้ายไซส์',
    // 'Choose your fabric': 'เลือกผ้าของคุณ',
    // Interlock: 'ผ้าตัว',
    // Mesh: 'ผ้าตาข่าย',
    // Woven: 'ผ้าวูเว่น',
    // Cotton: 'ผ้าคอตต้อน',
    // 'Fabric overview': 'สรุปผ้าที่ใช้',
    // Group: 'กลุ่ม',
    // Construction: 'โครงสร้าง',
    // Content: 'ส่วนประกอบ',
    // Weight: 'น้ำหนัก',
    // Measurements: 'ขนาดของผลิตภัณฑ์',
    // 'Measurement table': 'ตารางไซส์',
    // 'Help Center': 'ศูนย์ช่วยเหลือ',
    // 'Need help?': 'ต้องการความช่วยเหลือ?',
    // 'Enter article name': 'กรุณาระบุชื่อผลิตภัณฑ์',
    // Login: 'เข้าสู่ระบบ',
    // 'My cart': 'ตะกร้าสินค้าของคุณ',
    // 'Style number': 'ชื่อผลิตภัณฑ์',
    // 'Color and print overview': 'สรุปสี และ ลายพิมพ์',
    // Element: 'ชื่ิ้นส่วน',
    // Print: 'ลายพิมพ์',
    // 'Color in stock for this fabric': 'สีที่มีอยู่ในสต๊อก',
    // 'Dyed both sides': 'ผ้าย้อม (มีสีสองฝั่ง)',
    // 'Color picker': 'เลือกสี (พิมพ์)',
    // 'Sublimated one side': 'ผ้าพิมพ์ (มีสีฝั่งเดียว)',
    // 'Upload your artwork': 'อัพโหลดภาพของคุณ',
    // 'Drag a file here': 'วางไฟล์ตรงนี้',
    // Or: 'หรือ',
    // 'Select from your computer': 'เลือกไฟล์จากคอมพิวเตอร์',
    // Scale: 'ขนาด',
    // Rotate: 'หมุน',
    // 'Offset X': 'เลื่อน ซ้าย-ขวา',
    // 'Offset Y': 'เลื่อน ขึ้น-ลง',
    // 'Add your own text': 'เพิ่มข้อความของคุณ',
    // 'Enter your text': 'เพิ่มข้อความของคุณ',
    // 'Text overview': 'สรุปข้อความ',
    // Add: 'เพิ่ิ่ม',
    // 'Add your own logo': 'เพิ่มโลโก้ของคุณ',
    // 'Customize your care label': 'สร้างป้ายไซส์ของคุณ',
    // 'Size indication for illustration only':
    //   'หมายเหตุ: ขนาดที่แสดงเป็นตัวอย่างเท่านั้น',
    // 'Select your logo': 'เลือกโลโก้ของคุณ',
    // 'Select your care label': 'เลือกป้ายไซส์ของคุณ',
    // Left: 'มุมมองด้านซ้าย',
    // Right: 'มุุมมองด้านขวา',
    // Side: 'มุมมองด้านข้าง',
    // Front: 'มุมมองด้านหน้า',
    // '3/4': 'มุมมองแบบเอียง',
    // Rotate: 'หมุนอัตโนมัติ',
    // 'Note: Size indication for illustration only':
    //   'หมายเหตุ: ขนาดที่แสดงเป็นตัวอย่างเท่านั้น',
    // Back: 'กลับ',
    // 'No upcharge': 'ไม่มีค่าใช้จ่ายเพิ่ม',
    // 'Select a printing technique to see available colors':
    //   'เลือกเทคนิคการพิมพ์เพื่อดูสีที่มี',
    // 'Choose from our colors': 'เลือกจากสีในคลัง',
    // Remove: 'ลบ',
    // Position: 'ตำแหน่ง',
    // 'Logo overview': 'ภาพรวม โลโก้',
    // 'Printing technique': 'เทคนิคการพิมพ์',
    // 'Select a care label template': 'เลือกรูปแบบของป้ายไซส์',
    // 'Shipping price not included': 'ไม่รวมค่าขนส่ง (ต่างประเทศ)',
    // 'save & continue': 'บันทึก และ ดำเนินการต่อ',
    // 'createte folder': 'สร้างโฟลเดอร์',
    // 'add to cart': 'เพิ่มเข้าตะกร้าสินค้า',
    // Delete: 'ลบ',
    // Review: 'ตรวจสอบ',
    // 'Need sample': 'ต้องการตัวอย่าง',
    // 'Total piece': 'จำนวนทั้งหมด',
    // Collection: 'คอลเลคชั่น',
    // Season: 'ซีซั่น',
    // Discount: 'ลดราคา',
    // 'Cart list minimum quantity: 50 pieces': 'จำนวนขั้นต่ำต่อออเดอร์: 50 ชิ้น',
    // Submitted: 'ออเดอร์ที่สั่ง',
    // 'On Hold': 'ระหว่างพิจารณา',
    // Production: 'ระหว่างการผลิต',
    // Shipped: 'ส่งออกแล้ว',
    // 'Your orders': 'ออเดอร์ของคุณ',
    // Edit: 'แก้ไข',
    // 'Total piece': 'ราคารวม'
  }
}
