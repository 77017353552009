import * as THREE from 'three'
var OrbitControls = require('three-orbit-controls')(THREE)

export function helperSetup(scene, camera, renderer) {
  new OrbitControls(camera, renderer.domElement)

  // const axeHelper = new THREE.AxesHelper(1000)
  // scene.add(axeHelper);
  // var gridHelper = new THREE.GridHelper(100, 10)
  // scene.add( gridHelper );
}

export function lightSetup(scene, camera) {
  const ambient = new THREE.AmbientLight(0xffffff, 0.6)
  scene.add(ambient)

  const spotLightTwo = new THREE.SpotLight(0xffffff, 0.6)
  // camera.add(spotLightTwo);
  spotLightTwo.position.set(0, 500, 500)
  spotLightTwo.angle = Math.PI / 2
  // spotLightTwo.castShadow = true;
  spotLightTwo.penumbra = 0.1
  spotLightTwo.decay = 2
  spotLightTwo.distance = 4000
  spotLightTwo.shadow.mapSize.width = 1000
  spotLightTwo.shadow.mapSize.height = 1000
  camera.add(spotLightTwo)

  const HemisphereLight = new THREE.HemisphereLight(0xffffff, 0xbbbbbb, 0.1)
  scene.add(HemisphereLight)
}

export function demo_model(scene) {
  var geometry, material

  // PLANE

  geometry = new THREE.PlaneBufferGeometry(1000, 1000, 50)
  material = new THREE.MeshPhongMaterial({
    color: 0xcccccc,
    side: THREE.DoubleSide
  })
  var plane = new THREE.Mesh(geometry, material)
  plane.rotation.x = -Math.PI / 2
  plane.receiveShadow = true
  scene.add(plane)

  // PLANE

  // OBJECT

  geometry = new THREE.BoxBufferGeometry(10, 10, 10)
  material = new THREE.MeshPhongMaterial({ color: '#ff0000' })
  const myObject = new THREE.Mesh(geometry, material)
  myObject.castShadow = true
  myObject.position.y = 5
  scene.add(myObject)

  // OBJECT
}
