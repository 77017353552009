import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import { ADMIN_SHOP_FABRICS } from '../../gql/admin-queries';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddFabric from './AddFabric';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%',
    padding: '0'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242',
    flex: 1
  }
});
class FabricList extends Component {
  render() {
    const { classes, styles, shopId } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Typography
            component="h1"
            className={classes.h1}
            data-cy="Header-Materials"
          >
            Materials
          </Typography>

          <AddFabric shopId={shopId} />
        </Grid>
        <Query query={ADMIN_SHOP_FABRICS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            if (loading) return <CircularProgress />;
            if (error) return <div>Error :(</div>;
            return (
              <div>
                {shop.fabrics.edges.length > 0 ? (
                  <Paper>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {shop.fabrics.edges.map(fabrics => {
                          return (
                            <TableRow
                              data-cy={'Material-List-Row-' + fabrics.node.name}
                              key={fabrics.node.id}
                              component="a"
                              href={
                                '/admin/' +
                                shopId +
                                '/inventory/fabrics/' +
                                fabrics.node.id
                              }
                              hover
                              style={{ textDecoration: 'none' }}
                            >
                              <TableCell component="th" scope="row">
                                {fabrics.node.code}
                              </TableCell>
                              <TableCell>{fabrics.node.name}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <div>
                    <Typography variant="body1">No fabrics yet</Typography>
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withStyles(styles)(FabricList);
