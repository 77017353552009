export function scriptLoad(self) {
  var script = document.createElement('script')
  script.src = '/lib/fabric.min.js'

  var callback = function() {
    // do stuff after loaded

    var script2 = document.createElement('script')
    script2.src = '/lib/customiseControls.min.js'

    var callback2 = function() {
      // do stuff after loaded

      logger.log('LOAD ALL', window.fabric)
      self.setState({ scriptReady: true })
    }
    script2.onload = callback2

    document.head.appendChild(script2) //inject where you need it to be
  }
  script.onload = callback

  document.head.appendChild(script) //inject where you need it to be
}

export function scriptLoadByURL(src) {
  // USE ONLY SCRIPT NO INIT

  var script = document.createElement('script')
  script.src = src

  var callback = function() {
    // do stuff after loaded

    logger.log('scriptLoadByURL', window)
  }
  script.onload = callback

  document.head.appendChild(script) //inject where you need it to be
}
