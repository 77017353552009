import React, { useState } from 'react'
import { Rnd } from 'react-rnd'
import { updateControls } from '../Elements/elements'
import UpdateFieldControls from './UpdateFieldControls'
import { ClickAwayListener } from '@material-ui/core'

const style = {
  border: 'solid 1px lightgrey',
  background: '#fff',
  borderRadius: 2,
  zIndex: 10,
  padding: '20px 0px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.15), 0 6px 6px rgba(0,0,0,0.19)'
}

interface Props {
  id: string | null
  field: any
  handleShowUpdatePanel: any
  handleUpdateField(
    fieldId: string,
    fieldName: string,
    value: string | number | null | []
  ): any
}

const UpdateFieldPanel: React.FC<Props> = props => {
  const { handleShowUpdatePanel, handleUpdateField, id, field } = props

  const [position, setPosition] = useState<any>({
    x: 1280,
    y: 6,
    width: 400
  })

  return (
    <ClickAwayListener onClickAway={handleShowUpdatePanel}>
      <Rnd
        style={style}
        bounds="window"
        disableDragging={true}
        enableResizing={{
          bottom: false,
          right: false,
          left: false,
          top: false,
          bottomLeft: false,
          bottomRight: false,
          topLeft: false,
          topRight: false
        }}
        size={{ width: position.width, height: position.height }}
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y })
        }}
      >
        {updateControls.map((controls: any) => {
          return controls.type === 'FIELD' ? (
            <UpdateFieldControls
              field={field}
              controls={controls}
              handleUpdateField={handleUpdateField}
              fieldId={id}
            />
          ) : null
        })}
      </Rnd>
    </ClickAwayListener>
  )
}

export default UpdateFieldPanel
