import React, { useState } from 'react'
import { ChromePicker } from 'react-color'
import { Grid } from '@material-ui/core'

interface Props {
  defaultValue: string
  contentId: string | null
  styleId: string | null
  fieldName: string
  level: 'root' | 'style'
  handleUpdate: any
}

const ColorPicker: React.FC<Props> = props => {
  const {
    handleUpdate,
    defaultValue,
    contentId,
    styleId,
    fieldName,
    level
  } = props
  const [color, setColor] = useState(defaultValue || '#fff')
  const [displayPicker, setDisplayPicker] = useState(false)

  let styles = {
    color: {
      width: '100%',
      height: '20px',
      borderRadius: '2px',
      background: `${color}`
    },
    swatch: {
      padding: '5px',
      background: `${color}`,
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer'
    },
    popover: {
      zIndex: 26,
      position: 'relative',
      top: 30
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    }
  }

  return (
    <Grid
      direction="column"
      container={true}
      style={{ cursor: 'default', padding: 20, zIndex: 25 }}
    >
      <div style={styles.swatch} onClick={() => setDisplayPicker(true)}>
        <div style={styles.color} />
      </div>
      {displayPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={() => setDisplayPicker(false)} />
          <ChromePicker
            color={color}
            onChangeComplete={async (pickerColor: any) => {
              await setColor(pickerColor.hex)
              if (pickerColor.rgb.a === 0) {
                handleUpdate(
                  contentId,
                  fieldName,
                  'transparent',
                  level,
                  styleId
                )
              } else {
                handleUpdate(
                  contentId,
                  fieldName,
                  pickerColor.hex,
                  level,
                  styleId
                )
              }
            }}
          />
        </div>
      ) : null}
    </Grid>
  )
}

export default ColorPicker
