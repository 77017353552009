import gql from 'graphql-tag'
import {
  CONTENT_MODEL_STATUS_FIELD_FRAGMENT,
  CONTENT_MODEL_MODELS_FRAGMENT,
} from './fragments'

export const GET_CONTENT_FIELD = gql`
  query contentField($modelId: ID!, $fieldId: ID!, $revisionNo: Int!) {
    contentField(
      modelId: $modelId
      fieldId: $fieldId
      revisionNo: $revisionNo
    ) {
      id
      modelId
      sortOrder
      revisionNo
      backgroundColor
      maxWidth
      padding
      justify
      alignItems
      columns
    }
  }
`

export const ADMIN_SHOP_TEAMSTORES = gql`
  query adminShopTeamstores($id: ID!) {
    shop(id: $id) {
      id
      salesChannels {
        edges {
          node {
            id
            enabled
            type
            privacyType
            contactPerson
            contactEmail
            contactPhone
            logo
            mainBanner
            availableFrom
            title {
              id
              defaultValue
              text {
                content
                langCode
              }
            }
            availableUntil
            introductionText {
              id
              defaultValue
              text {
                content
                langCode
              }
            }
            brandColor
            collections {
              edges {
                node {
                  collection {
                    id
                    thumbnail
                    accountingId
                    title {
                      id
                      defaultValue
                      text {
                        langCode
                        content
                      }
                    }
                    description {
                      id
                      defaultValue
                      text {
                        langCode
                        content
                      }
                    }
                  }
                }
              }
            }
            products {
              edges {
                node {
                  product {
                    id
                    slugs {
                      langCode
                      content
                    }
                    title {
                      id
                      defaultValue
                      text {
                        langCode
                        content
                      }
                    }
                    slug
                    media {
                      id
                      isThumbnail
                      image
                    }
                    price {
                      roundUp
                      currency
                      value
                    }
                  }
                }
              }
            }
            name
            availableFrom
            availableUntil
            hasPassword
            slugs {
              langCode
              content
            }
          }
        }
      }
    }
  }
`

export const ADMIN_SHOP_COLLECTIONS = gql`
  query shopCollections($id: ID!) {
    shop(id: $id) {
      id
      collections {
        edges {
          node {
            id
            accountingId
            title {
              id
              defaultValue
              text {
                langCode
                content
              }
            }
            description {
              id
              defaultValue
              text {
                langCode
                content
              }
            }
            mainBanner
            products {
              productId
              product {
                id
                slugs {
                  langCode
                  content
                }
                title {
                  id
                  defaultValue
                  text {
                    langCode
                    content
                  }
                }
                slug
                media {
                  id
                  isThumbnail
                  image
                }
                price {
                  roundUp
                  currency
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`

export const ADMIN_GET_CONTENT_MODELS = gql`
  query contentModels($id: ID!, $salesChannelId: ID) {
    shop(id: $id) {
      id
      hasDefaultPages
      defaultPages {
        type
        modules {
          name
          sortOrder
          column
          row
        }
      }
      viewModules
      contentModels(shopId: $id, salesChannelId: $salesChannelId) {
        ...contentModelStatusFieldFragment
        ...contentModelModelsFragment
      }
    }
  }
  ${CONTENT_MODEL_MODELS_FRAGMENT}
  ${CONTENT_MODEL_STATUS_FIELD_FRAGMENT}
`

export const GET_CONTENT_MODEL = gql`
  query getContentModel($id: ID!) {
    contentModel(id: $id) {
      ...contentModelStatusFieldFragment
      slugs {
        langCode
        content
      }
      status
      latestRevision {
        id
        modelId
        name {
          id
          defaultValue
          text {
            langCode
            content
          }
        }
        description {
          id
          defaultValue
          text {
            langCode
            content
          }
        }
        revisionNo
        fields {
          id
          modelId
          sortOrder
          columns
          backgroundColor
          maxWidth
          padding
          justify
          alignItems
          header {
            alignment
            text {
              id
              defaultValue
              text {
                content
                langCode
              }
            }
          }
          contentView {
            id
            type
            fieldId
            type
            column
            padding
            ... on ContentViewModule {
              id
              type
              sortOrder
              moduleType
            }
            ... on ContentViewMaps {
              id
              type
              sortOrder
              latitude
              longitude
              place
            }
            ... on ContentViewForm {
              id
              type
              sortOrder
              formVariant
              portalId
              formId
              target
              cssClass
              formTitle {
                id
                defaultValue
                text {
                  langCode
                  content
                }
              }
            }
            ... on ContentViewImage {
              id
              type
              sortOrder
              contentImage
              imageAlign
              imageTitle {
                id
                defaultValue
                text {
                  langCode
                  content
                }
              }
              imageButton {
                id
                defaultValue
                text {
                  langCode
                  content
                }
              }
              alignContent
              imageButtonUrl
              imageSubTitle {
                id
                defaultValue
                text {
                  langCode
                  content
                }
              }
              contentStyle {
                ... on ContentStyleImage {
                  id
                  contentId
                  activeStyle
                  width
                  height
                  fontFamily
                  objectFit
                  copyrightContent {
                    id
                    defaultValue
                    text {
                      content
                      langCode
                    }
                  }
                  maxWidth
                  maxHeight
                  titleAlign
                  subTitleAlign
                  buttonAlign
                  buttonVariant
                  buttonColor
                  buttonBackgroundColor
                  buttonBorderColor
                  titleColor
                  subTitleColor
                  objectPosition
                }
              }
            }
            ... on ContentViewButton {
              id
              type
              sortOrder
              buttonUrl
              buttonText {
                id
                defaultValue
                text {
                  langCode
                  content
                }
              }
              contentStyle {
                id
                type
                contentId
                activeStyle
                ... on ContentStyleButton {
                  padding
                  fontSize
                  color
                  backgroundColor
                  fontWeight
                  textTransform
                  align
                  variant
                }
              }
            }
            ... on ContentViewText {
              id
              type
              sortOrder
              contentText {
                id
                defaultValue
                text {
                  langCode
                  content
                }
              }
              contentStyle {
                id
                type
                contentId
                activeStyle
                ... on ContentStyleText {
                  fontFamily
                }
              }
            }
            ... on ContentViewCollection {
              id
              type
              sortOrder
              collectionIds
              buttonText {
                id
                defaultValue
                text {
                  langCode
                  content
                }
              }
              collectionTitle {
                id
                defaultValue
                text {
                  langCode
                  content
                }
              }
              cardsPerRow
              collections {
                id
                accountingId
                no
                title {
                  id
                  defaultValue
                  text {
                    langCode
                    content
                  }
                }
                slugs {
                  langCode
                  content
                }
                thumbnail
                mainBanner
              }
              contentStyle {
                id
                type
                contentId
                activeStyle
                ... on ContentStyleCollection {
                  fontFamily
                  buttonAlign
                }
              }
            }
            ... on ContentViewTeamstore {
              id
              type
              sortOrder
              teamstoreIds
              teamstoreTitle {
                id
                defaultValue
                text {
                  content
                  langCode
                }
              }
              teamstores {
                id
                enabled
                name
                type
                privacyType
                contactPerson
                contactEmail
                contactPhone
                logo
                mainBanner
                availableFrom
                slugs {
                  content
                  langCode
                }
                title {
                  id
                  defaultValue
                  text {
                    content
                    langCode
                  }
                }
                availableUntil
                introductionText {
                  id
                  defaultValue
                  text {
                    content
                    langCode
                  }
                }
              }
            }
            ... on ContentViewProduct {
              id
              type
              sortOrder
              productIds
              variant
              productTitle {
                id
                defaultValue
                text {
                  langCode
                  content
                }
              }
              cardsPerRow
              products {
                id
                active
                shopId
                media {
                  sortOrder
                  image
                }
                slugs {
                  content
                  langCode
                }
                title {
                  id
                  defaultValue
                  text {
                    langCode
                    content
                  }
                }
                description {
                  id
                  defaultValue
                  text {
                    langCode
                    content
                  }
                }
                collections {
                  collection {
                    id
                    no
                    slugs {
                      langCode
                      content
                    }
                    title {
                      id
                      defaultValue
                      text {
                        langCode
                        content
                      }
                    }
                  }
                }
                discounts {
                  discount {
                    id
                    canBeCombinedWithOtherDiscounts
                    availableFrom
                    availableUntil
                    enabled
                    hasCoupon
                    discountType
                    discountValue
                    discountCurrency
                    discountRules {
                      id
                      operator
                      discountRuleObjectType
                      discountRuleCondition
                    }
                  }
                }
                price {
                  roundUp
                  currency
                  value
                  includesTax
                  taxPercent
                }
                accountingId
                optionValues {
                  id
                  isEnabled
                  sku
                  inventory
                  barcode
                  sortOrder
                  optionValues
                  variantIds
                  optionValueIds
                  createdAt
                  updatedAt
                  modifiers {
                    id
                    type
                    stringModifier
                    priceChange {
                      currency
                      value
                    }
                  }
                  media {
                    id
                    sortOrder
                    isThumbnail
                    isBanner
                    image
                  }
                }
                variants {
                  id
                  options
                  variant {
                    id
                    title {
                      id
                      defaultValue
                      text {
                        langCode
                        content
                      }
                    }
                    options {
                      id
                      value
                      sortOrder
                      title {
                        id
                        defaultValue
                        text {
                          langCode
                          content
                        }
                      }
                    }
                  }
                }
              }
              contentStyle {
                id
                type
                contentId
                activeStyle
                ... on ContentStyleProduct {
                  fontFamily
                  buttonAlign
                }
              }
            }
          }
        }
      }
    }
  }
  ${CONTENT_MODEL_STATUS_FIELD_FRAGMENT}
`
