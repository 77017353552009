import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '../../../common/Select';
import _cloneDeep from 'lodash/cloneDeep';

import _get from 'lodash/get';
const omitDeep = require('omit-deep');
const styles = {
  root: {}
};
const DiscountRuleType = ({
  classes,
  discountRule,
  discountRuleIndex,
  discount,
  discountId,
  updateDiscountRule,
  deleteDiscountRuleObject
}) => {
  return (
    <Grid item xs={12}>
      <Select
        label="Entity type"
        className={classes.select}
        value={discountRule ? discountRule.discountRuleObjectType : ''}
        onChange={async e => {
          const newObjectType = e.target.value;
          const newRuleCondition =
            newObjectType &&
            (newObjectType === 'CUSTOMER' ||
              newObjectType === 'COLLECTION' ||
              newObjectType === 'PRODUCT' ||
              newObjectType === 'PRODUCTPACKAGE' ||
              newObjectType === 'SALESCHANNEL')
              ? 'IN'
              : 'GT';
          // delete all object discountruleobjects
          if (discountRule && discountRule.discountRuleObjects) {
            let deletePromises = [];
            discountRule.discountRuleObjects.forEach(discountRuleObject => {
              if (discountRuleObject && discountRuleObject.objectId) {
                deletePromises.push(
                  deleteDiscountRuleObject({
                    variables: {
                      discountId: discountId,
                      objectId: discountRuleObject.objectId
                    }
                  })
                );
              }
            });
            await Promise.all(deletePromises);
          }

          updateDiscountRule({
            optimisticResponse: {
              __typename: 'Mutation',
              updateDiscountRule: {
                ...discountRule,
                discountRuleCondition: newRuleCondition,
                discountRuleObjectType: newObjectType
              }
            },
            variables: {
              input: {
                discountRuleCondition: newRuleCondition,
                discountRuleObjectType: newObjectType
              }
            }
          });
        }}
      >
        <option value={''}>Select a type</option>
        <option value={'CART_VALUE'}>Cart total</option>
        {/* <option value={'CART_ITEM_VALUE'}>Cart Item</option> */}
        <option value={'CART_ITEM_QUANTITY'}>Cart Item Quantity</option>
        {/* <option value={'CUSTOMER'}>Customer</option> */}
        <option value={'COLLECTION'}>Collection</option>
        <option value={'PRODUCT_TYPE'}>Product type</option>
        <option value={'PRODUCT'}>Product</option>
        <option value={'PRODUCTPACKAGE'}>Product package</option>
        <option value={'SALESCHANNEL'}>Saleschannel/Teamstore</option>
      </Select>
    </Grid>
  );
};
DiscountRuleType.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DiscountRuleType);
