import React from 'react'
import HeaderRender from '../../../../../store/header/HeaderRender'
import PlaceholderText from './PlaceholderText'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../../../../common/Theme'

interface Props {
  data: any
}

const EditorHeader: React.FC<Props> = props => {
  const { data } = props

  if (data?.shop?.contentSettings?.menus?.length === 0) {
    return <PlaceholderText type="header" />
  }

  return (
    <MuiThemeProvider theme={theme}>
      <HeaderRender
        menus={
          data
            ? data.shop.contentSettings.menus.filter(
                (o: any) => o.menuLocation === 'HEADER'
              )
            : []
        }
        language="en_UK"
        shop={data ? data.shop : {}}
        slugs={[]}
        teamstore={{}}
        urlPrefix="en/"
        currentUser=""
      />
    </MuiThemeProvider>
  )
}

export default EditorHeader
