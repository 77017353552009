import React from 'react'
import { NativeSelect, InputLabel } from '@material-ui/core'

interface Props {
  selected: string | null
  handleSetSelected: any
  salesChannels: any
}

const SetSalesChannel: React.FC<Props> = props => {
  const { selected, handleSetSelected, salesChannels } = props
  return (
    <>
      <InputLabel htmlFor="salesChannel-select-label">Sales Channel</InputLabel>

      <NativeSelect
        onChange={handleSetSelected}
        value={selected || 'MYBRIKL'}
        id="salesChannel-select"
        style={{
          border: '1px solid lightgrey',
          borderRadius: 2,
          marginRight: 60
        }}
      >
        <option>MYBRIKL</option>
        {salesChannels?.edges
          ? salesChannels.edges.map((teamstore: any) => (
              <option value={teamstore.node.id} key={teamstore.node.id}>
                {teamstore.node.title.defaultValue}
              </option>
            ))
          : null}
      </NativeSelect>
    </>
  )
}

export default SetSalesChannel
