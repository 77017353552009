import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Paper } from '@material-ui/core'
import Image3 from '../../../img/3.jpg'
import { getTextFromLanguage } from '../../../helper/languageHelper'
import { Link } from 'gatsby'

const useStyles = makeStyles(theme => ({
  root: {}
}))

class HubspotForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: this.props.portalId,
          formId: this.props.formId,
          cssClass: this.props.cssClass,
          target: '#' + this.props.target
        })
      }
    })
  }
  render() {
    const {
      urlPrefix,
      langCode,
      key,
      Template,
      target,
      portalId,
      formId,
      cssClass
    } = this.props

    return (
      <Grid key={key} container style={{ width: '100%', padding: '2em' }}>
        <Grid
          container
          direction={'row'}
          justify={'center'}
          alignItems={'center'}
        >
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              width: '100%',

              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Grid id={target} role="form"></Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default HubspotForm
