import React from 'react'
import {
  withStyles,
  Grid,
  Theme,
  createStyles,
  WithStyles,
  Paper,
  ListSubheader,
  ListItem,
  List
} from '@material-ui/core'
import { Link } from '@reach/router'

import {
  getLinkText,
  getLinkUrl,
  recursiveGetSubItems,
  prefixSubItems
} from '../../../helper/menuHelper'
import ModuleRender from '../module/ModuleRender'

const styles = (themeStyle: Theme) =>
  createStyles({
    ColumnTitle: {
      fontSize: '16px',
      margin: '4px 0px',
      padding: 0
    },
    linkColor: {
      textDecoration: 'none !important',
      [themeStyle.breakpoints.down('sm')]: {
        color: '#000000 !important',
        fontSize: '12px',
        fontWeight: 300,
        padding: 12,
        textDecoration: 'none'
      }
    },
    list: {
      // fontSize: '12px',
      margin: 0,
      padding: 0,
      textDecoration: 'none'
    }
  })

interface Props extends WithStyles<typeof styles> {
  shop: any
  subElements: any[]
  language: string
  languages?: string[]
  linkColor?: string
  styling: any
  urlPrefix: string
  teamstore: any
}

const MenuItemSub: React.FunctionComponent<Props> = props => {
  const {
    classes,
    language,
    languages,
    linkColor,
    shop,
    styling,
    subElements,
    urlPrefix,
    teamstore
  } = props

  const elementsSecondLevel = subElements.filter(o => o._level === 1)

  return (
    <Paper style={{ borderRadius: '0px' }}>
      <Grid
        container={true}
        justify="space-around"
        direction="column"
        style={{
          maxWidth: '1000px',
          padding: 24,
          margin: 'auto',
          maxHeight: 300
        }}
      >
        {elementsSecondLevel.map((subItem, index) => (
          <List
            key={subItem.id + index + Math.random() * 2000}
            component="nav"
            subheader={
              <ListSubheader
                component="span"
                className={classes.ColumnTitle}
                disableSticky={true}
              >
                {getLinkUrl(subItem, urlPrefix, language) &&
                (getLinkUrl(subItem, urlPrefix, language).indexOf('http') !==
                  -1 ||
                  getLinkUrl(subItem, urlPrefix, language).indexOf('https') !==
                    -1) ? (
                  <a
                    key={index}
                    rel="noopener noreferrer"
                    target={'_blank'}
                    style={{
                      color: '#000',
                      fontSize: styling.fontSize,
                      textDecoration: 'none'
                    }}
                    className={classes.linkColor}
                    href={getLinkUrl(subItem, urlPrefix, language)}
                  >
                    {getLinkText(subItem, language)}
                  </a>
                ) : (
                  <Link
                    to={getLinkUrl(subItem, urlPrefix, language)}
                    style={{
                      color: '#000',
                      fontSize: styling.fontSize,
                      textDecoration: 'none'
                    }}
                    classes={classes.linkColor}
                  >
                    {!subItem.moduleId ? (
                      getLinkText(subItem, language)
                    ) : (
                      <ModuleRender
                        teamstore={teamstore}
                        languages={languages}
                        language={language}
                        shop={shop}
                        contentDetail={subItem.module.contentDetail}
                        urlPrefix={urlPrefix}
                        alignment={subItem.alignment}
                        styling={styling.fontSize}
                        linkColor={linkColor}
                      />
                    )}
                  </Link>
                )}
              </ListSubheader>
            }
          >
            {recursiveGetSubItems(subElements, subItem.id, 2).map(
              subSubItem => (
                <ListItem
                  key={subSubItem.id + Math.random() * 4000}
                  component="a"
                  href={getLinkUrl(subSubItem, urlPrefix, language)}
                  className={classes.list}
                  style={{
                    color: '#000',
                    fontSize: styling.fontSize
                  }}
                >
                  {prefixSubItems(subSubItem._level - 2)}{' '}
                  {getLinkText(subSubItem, language)}
                </ListItem>
              )
            )}
          </List>
        ))}
      </Grid>
    </Paper>
  )
}

export default withStyles(styles, { withTheme: true })(MenuItemSub)
