import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import { navigate } from '@reach/router';
import { ADMIN_SHOP_COLORS } from '../../gql/admin-queries';
import { ADMIN_CREATE_COLOR } from '../../gql/admin-mutations';

class AddColor extends Component {
  render() {
    const { shopId } = this.props;
    return (
      <Mutation
        mutation={ADMIN_CREATE_COLOR}
        variables={{ shopId: shopId }}
        update={(cache, { data: { createColor } }) => {
          if (createColor.id) {
            navigate(`/admin/${shopId}/inventory/colors/${createColor.id}`);
          }
        }}
      >
        {addColor => (
          <Button
            data-cy="color-add-new-btn"
            onClick={addColor}
            variant="outlined"
            color="primary"
            size="small"
          >
            Add new
          </Button>
        )}
      </Mutation>
    );
  }
}

export default AddColor;
