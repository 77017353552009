import React from 'react'
import Select from '../../../common/Select'
import { makeStyles, createStyles, Theme } from '@material-ui/core'

interface Props {
  isEditMode?: boolean
  languages: [string]
  Template?: any
  selectedLanguage?: string
  onSwitch?(language: string): void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '19px !important'
      }
    }
  })
)

const LanguageSwitch: React.FunctionComponent<Props> = props => {
  const { Template, onSwitch, languages, isEditMode, selectedLanguage } = props
  const classes = useStyles()

  const handleSwitch = (e: any) => {
    if (Template) {
      Template.setState({
        currentLanguage: e.target.value
      })
      console.log(e.target.value)
    }
    if (isEditMode) {
      return
    }
    if (onSwitch) {
      onSwitch(e.target.value)
    }
  }

  return (
    <Select
      background="none"
      border="none"
      color="black"
      textTransform="uppercase"
      onChange={handleSwitch}
      value={Template && Template.state ? Template.state.currentLanguage : selectedLanguage}
      dataCy="Switch-Language"
      className={classes.select}
    >
      {languages
        ? languages.map(language => (
            <option key={language} value={language}>
              {language}
            </option>
          ))
        : null}
    </Select>
  )
}

export default LanguageSwitch
