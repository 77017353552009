import React from 'react';
import {
  WithStyles,
  makeStyles,
  withStyles,
  Grid
} from '@material-ui/core';
import _ from 'lodash';

import ContentColumn from './ContentColumn';
import withDragDropContext from './dragdrop/withDragDropContext';
import DraggableCard from './dragdrop/DraggableCard';
import { moveCard } from './dragdrop/sorting';
import useContentModule from './hooks/useContentModule';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles({
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      minWidth: 25,
      width: 25,
      minHeight: 25,
      height: 25,
      zIndex: 9999
    },
    dialogBox: {
      zIndex: 100
    },
    contextMenu: {
      zIndex: 999
    },
    deleteIcon: {
      fontSize: 14
    },
    menuContextButton: {
      minWidth: 25,
      width: 25,
      minHeight: 25,
      height: 25,
      padding: '0px',
      position: 'absolute',
      right: 45,
      top: 8
    },
    menuContextIcon: {
      fontSize: 14
    },
    moduleButtons: {
      minWidth: 135,
      minHeight: 135,
      margin: 5,
      border: '1px lightgrey solid',
      marginBottom: 10,
      borderRadius: 5
    },
    rowLabel: {
      height: 20,
      width: 'auto',
      position: 'absolute',
      top: 0,
      left: 0,
      background: '#B23466',
      color: 'white',
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'BiomeStd-Light',
      fontWeight: 'bold',
      fontSize: 10,
      padding: 5,
      zIndex: 120,
      cursor: 'pointer'
    },
    cardInvisible: {
      border: '1px solid transparent'
    },
    cardVisible: {
      border: '1px dashed #B23466'
    },
    labelVisible: {
      display: 'block'
    }
  });

interface Props extends WithStyles<typeof styles> {
  Template: any;
  pageId: string;
  row: any;
  index: number;
}

const ContentRow: React.FunctionComponent<Props> = props => {
  const {
    row,
    index,
    pageId,
    Template,
    pageContext,
    data,
    themeId,
    shopId
  } = props;
  const {
    isHover,
    isSelected,
    handleMouseEnter,
    handleMouseLeave
  } = useContentModule(row.id, Template.state.selectedRowObj);

  if (!row.id) {
    return null;
  }

  const changeSortOrder = (id, type) => {
    let moveType = type === 'down' ? 1 : -1;

    const rows = _.sortBy(data.page.rows, 'sortOrder');

    const row = rows.find(o => o.id === id);
    console.log(row, rows, moveType);

    const rowSwap = rows.find(o => o.sortOrder === row.sortOrder + moveType);

    moveCard({
      pageId,
      type: 'row',
      drag: {
        id: id,
        sortOrder: row.sortOrder
      },
      hover: {
        id: rowSwap.id,
        sortOrder: rowSwap.sortOrder
      }
    });
  };

  // console.log(data)

  row.columns = _.sortBy(row.columns, 'sortOrder');

  const classes = useStyles();

  const cardVisibility = isHover || isSelected ? classes.cardVisible : classes.cardInvisible;

  return (
    <div style={{ position: 'relative' }}>
      <Grid
        component={DraggableCard}
        index={index}
        id={row.id}
        dragdrop={{
          dragType: 'row',
          id: row.id,
          index,
          moveCard,
          pageId,
          rowId: row.id,
          sortOrder: row.sortOrder
        }}
        container={true}
        justify="center"
        alignItems="flex-start"
        key={row.sortOrder}
        style={{
          padding: 0,
          cursor: 'default',
          minHeight: '60px',
          marginTop: '1.5rem',
          marginBottom: '.5rem',
          marginLeft: 0,
          marginRight: 0
        }}
        className={
          cardVisibility
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div>
          <div
            onClick={() =>
              Template.setState({
                selectedRow: row.id,
                selectedRowPersist: row.id,
                selectedRowObj: row, // TODO: merge in one
                selectedCol: '',
                selectedElement: '',
                selectedColRow: ''
              })
            }
            className={
              classes.rowLabel +
              ' ' +
              (isHover || isSelected ? classes.labelVisible : '')
            }
            // data-cy="CONTAINER-ROW-LABEL"
            data-cy={'CONTAINER-ROW-LABEL-' + row.sortOrder}
          >
            ROW > {row.sortOrder}
          </div>
          {Template.state.selectedRow === row.id ? (
            <div style={{ position: 'absolute', left: '-33px', top: '5px' }}>
              <ButtonGroup
                style={{ flexDirection: 'column' }}
                size="small"
                aria-label="Small outlined button group"
              >
                <IconButton
                  onClick={() =>
                    changeSortOrder(Template.state.selectedRow, 'up')
                  }
                  disabled={
                    row.sortOrder <= 1 || data.page.rows.length <= 1
                      ? true
                      : false
                  }
                >
                  <ArrowUpwardIcon fontSize="small" />
                </IconButton>

                <IconButton
                  onClick={() =>
                    changeSortOrder(Template.state.selectedRow, 'down')
                  }
                  disabled={
                    row.sortOrder === data.page.rows.length ||
                    data.page.rows.length <= 1
                      ? true
                      : false
                  }
                >
                  <ArrowDownwardIcon fontSize="small" />
                </IconButton>
              </ButtonGroup>
            </div>
          ) : null}
        </div>

        {row.columns.map((contentColumn: any, indexCol: number) => {
          return (
            <ContentColumn
              shopId={shopId}
              contentKey={contentColumn.id}
              contentColumn={contentColumn}
              columnCount={row.columns.length}
              Template={Template}
              key={contentColumn.id}
              index={indexCol}
              pageId={pageId}
              rowId={row.id}
              pageContext={pageContext}
              themeId={themeId}
            />
          );
        })}
      </Grid>
    </div>
  );
};

const withStylesExport = ContentRow;
export default withDragDropContext(withStylesExport);
