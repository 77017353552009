import React, { useContext } from 'react'
import { Button as MaterialButton, Grid } from '@material-ui/core'
import { Link } from 'gatsby'
import { ThemeContext } from '../ThemeContext'
import { SelectedLanguageContext } from '../LanguageContext'
import { getTextFromLanguage } from '../../../../../../helper/languageHelper'

interface Props {
  element: any
  urlPrefix: string
}

const Button: React.FC<Props> = props => {
  const { element, urlPrefix } = props

  const theme = useContext(ThemeContext)
  const selectedLanguage = useContext(SelectedLanguageContext)

  let styles = null
  try {
    if (element?.contentStyle?.length > 0) {
      styles = element.contentStyle[0]
    }
  } catch (error) {
    console.log(error)
  }

  return (
    <Grid
      container={true}
      justify={
        element.contentStyle &&
        element.contentStyle[0] &&
        element.contentStyle[0].align
          ? element.contentStyle[0].align
          : 'center'
      }
      style={{ padding: 20 }}
    >
      {element && element?.buttonUrl?.indexOf('http') !== -1 ? (
        <MaterialButton
          style={{
            ...theme?.theme?.button?.style,
            color: styles?.color || 'white',
            border: `${`1px solid ${styles?.borderColor}` ||
              '1px solid white'}`,
            backgroundColor: styles?.backgroundColor || null
          }}
          variant={
            element.contentStyle &&
            element.contentStyle[0] &&
            element.contentStyle[0].variant
              ? element.contentStyle[0].variant
              : 'contained'
          }
          color="primary"
        >
          <a
            style={{ textDecoration: 'none', color: styles?.color || 'white' }}
            href={element?.buttonUrl}
          >
            {getTextFromLanguage(element?.buttonText?.text, selectedLanguage) ||
              'Learn More'}
          </a>
        </MaterialButton>
      ) : (
        <MaterialButton
          style={{
            ...theme?.theme?.button?.style,
            color: styles?.color || 'white',
            border: `${`1px solid ${styles?.borderColor}` ||
              '1px solid white'}`,
            backgroundColor: styles?.backgroundColor || null,
            textDecoration: 'none'
          }}
          component={Link}
          to={
            element.buttonUrl
              ? `/${urlPrefix}${element.buttonUrl}`
              : `/${urlPrefix}`
          }
          variant={
            element.contentStyle &&
            element.contentStyle[0] &&
            element.contentStyle[0].variant
              ? element.contentStyle[0].variant
              : 'contained'
          }
          color="primary"
        >
          {getTextFromLanguage(element?.buttonText?.text, selectedLanguage) ||
            'Learn More'}
        </MaterialButton>
      )}
    </Grid>
  )
}

export default Button
