import React, {useState} from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText, DialogActions, Dialog,
  Select, MenuItem
} from '@material-ui/core'

import {MODULES} from '../../content/config'



const styles = () => createStyles({
})


interface Props extends WithStyles<typeof styles> {
  onSave(type: string, label: string, options?: any): void,
}


const ModuleAdd: React.FunctionComponent<Props> = (props) => {
  const { onSave } = props
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedModule, setSelectedModule] = useState(null)


  const handleChange = (e: any): void => {
    setSelectedModule(e.target.value)
  }


  const handleOpen = (): void => {
    setIsDialogOpen(true)
  }


  const handleClose = (): void => {
    setIsDialogOpen(false)
  }


  const handleSave = (): void => {
    const module = MODULES.find(o => o.id === selectedModule)
    if (module) {
      setIsDialogOpen(false)
      onSave(module.type, module.label, module.options)
    }
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        color="secondary"
      >
        Add new module
      </Button>

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add New Module</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Select
              value={selectedModule}
              onChange={handleChange}
              inputProps={{
                id: 'pageId-simple',
                name: 'pageId'
              }}
              style={{border: '1px solid #eee', margin: '8px 0px 16px', borderRadius: 4}}
            >
              {MODULES ? MODULES.map((module: any) => (
                <MenuItem
                  key={module.id}
                  value={module.id}
                >
                  {module.label}
                </MenuItem>
              )): null}
            </Select>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus={true}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(ModuleAdd)
