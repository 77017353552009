import React from 'react'
import { Snackbar, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

interface Position {
  vertical: 'top' | 'bottom'
  horizontal: 'right' | 'center' | 'left'
}

interface Props {
  open: boolean
  setOpen: any
  message: string
  position?: Position | undefined
  variant?: 'success' | 'warning' | 'error' | 'info'
}

const Notification: React.FC<Props> = props => {
  const { open, setOpen, variant, message, position } = props

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: position?.vertical || 'bottom',
        horizontal: position?.horizontal || 'center'
      }}
      autoHideDuration={2000}
      open={open}
      onClose={handleClose}
    >
      {variant !== 'info' ? (
        <Alert variant="filled" onClose={handleClose} severity={variant}>
          {message}
        </Alert>
      ) : (
        <Alert variant="filled" onClose={handleClose} severity="info">
          <CircularProgress />
        </Alert>
      )}
    </Snackbar>
  )
}

export default Notification
