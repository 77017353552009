import { Link } from 'gatsby';
import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
  Button,
  Grid
} from '@material-ui/core';

const styles = (themeStyle: Theme) =>
  createStyles({
    btn: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'center',
      [themeStyle.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  });

interface Props extends WithStyles<typeof styles> {
  contentDetail: any;
  teamstoreLogo: string;
  defaultLanguage?: string;
  currentLanguage?: string;
}

const TeamstoreLogo: React.FunctionComponent<Props> = props => {
  const {
    classes,
    contentDetail,
    teamstoreLogo,
    defaultLanguage,
    currentLanguage
  } = props;

  const renderImg = () => (
    <img
      src={teamstoreLogo}
      alt={'logo'}
      style={{
        filter: contentDetail.isAlternative ? 'invert(1)' : 'invert(0)',
        height: contentDetail.height || '50px',
        objectFit: 'contain'
      }}
    />
  );

  return (
    <Grid item={true} style={{ marginTop: '0px', marginLeft: '-8px' }}>
      {contentDetail.isLink ? (
        <Button
          component={Link}
          to={
            currentLanguage === defaultLanguage
              ? '/'
              : '/' + currentLanguage.substring(0, 2)
          }
          className={classes.btn}
        >
          {renderImg()}
        </Button>
      ) : (
        renderImg()
      )}
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(TeamstoreLogo);
