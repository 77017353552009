import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import { Slider } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Select from '../../common/Select'
import Input from '../../common/Input'
import {
  Delete,
  Add,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@material-ui/icons'

import * as template_control from '../template_control'
import * as tools from '../tools/tools'
import LayerControl from './LayerControl'
import { appendToFilename } from '../tools/Functions/save'

import blank_img from '../TEMPLATE_ASSET/no-image.png'
import { Localization } from '../../../lang/localization'
import shortid from 'shortid'

import { API_STAGE, MEDIA_CDN_URL } from '~/brikl-config'

let SHOP_ID = tools.getQueryStringValue('s')

const styles = theme => ({
  colorSwatch: {
    background: '#BBBBBB',
    width: 27,
    height: 27,
    border: '3px solid #BBBBBB',
    borderRadius: '100%',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer',
    },
  },
  logoTumbnail: {
    background: '#BBBBBB',
    width: 46,
    height: 46,
    border: '3px solid #BBBBBB',
    borderRadius: '6px',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer',
    },
  },
  slider: {
    padding: '8px 0px',
    width: '100%',
  },
  track: {
    backgroundColor: '#000',
  },
  thumb: {
    backgroundColor: '#000',
  },
  rail: {
    backgroundColor: '#e5e5e5',
  },
})

class LogoStep extends Component {
  constructor(props) {
    super(props)

    this.initState = {
      rotate: 0,
      scale: 1,
      selectedId: null,
      preview_upload: null,
    }
    this.state = this.initState
    this.defaultLogoList = [] // Default logo list

    if (!SHOP_ID) {
      SHOP_ID = this.props.DesignRoot.SHOP_ID
    }
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot
    DesignRoot.LogoStep = this
    DesignRoot.setState({ update: true })
    // PROVIDE COMPONENT TO MAIN
  }

  async uploadLogo(domId, type, option, boundingData, imageLibraryData) {
    try {
      // statements

      var DesignRoot = this.props.DesignRoot

      DesignRoot.Loading.activeLoading(true)

      if (option && option === 'IMAGE_LIBRARY') {
        var base64Info = await tools._get_image_base64(
          imageLibraryData.imageUrl
        )
        logger.log('uploadLogo', option, imageLibraryData, base64Info)

        const gen_filename = imageLibraryData.id

        this.uploadLogoProcess(
          domId,
          base64Info.base64,
          gen_filename,
          type,
          option,
          null,
          boundingData,
          imageLibraryData
        )
      } else {
        var file_upload = document.getElementById(domId)
        if (file_upload.files[0]) {
          var FILE_DATA = file_upload.files[0]
        } else {
          alert('Upload your image')
          return
        }

        var file_name = FILE_DATA.name
        const gen_filename = appendToFilename(
          file_name,
          '-' + shortid.generate()
        )
        var file_size = FILE_DATA.size
        var file_type = FILE_DATA.type

        const acceptTypesArr = file_upload
          ? file_upload
              .getAttribute('accept')
              .split(',')
              .map(elem => elem.trim())
          : []

        if (acceptTypesArr.length > 0 && !acceptTypesArr.includes(file_type)) {
          alert('File type can be jpg, jpeg, png, svg, gif or ai.')
          return
        }

        // let data = self.state.preview_upload

        var base64 = await tools.getBase64(FILE_DATA)

        this.uploadLogoProcess(
          domId,
          base64,
          gen_filename,
          type,
          option,
          FILE_DATA,
          boundingData,
          imageLibraryData
        )
      }
    } catch (e) {
      // statements
      logger.error('LogoStep.uploadLogo.error', e)
    }
  }

  async uploadLogoProcess(
    domId,
    base64,
    gen_filename,
    type,
    option,
    FILE_DATA,
    boundingData,
    imageLibraryData
  ) {
    const DesignRoot = this.props.DesignRoot

    var uuid = tools.uuidv4()

    // ADD TO ZONE LOGIC

    var isBouding = false
    var canvasData = null
    let boundingUserData = {}
    var left = 0
    var top = 0
    if (option && option === 'ADD_TO_ZONE' && type === 'LOGO') {
      // CHECK MAXIMUM PLACEHOLDER HERE

      var maxItem = DesignRoot.fabric_function.get_bounding_limit_item_by_group(
        'LOGO',
        boundingData._ZONE_GROUP
      )

      var numberOfItem = DesignRoot.fabric_function.get_bounding_current_item_by_group(
        'LOGO',
        boundingData._ZONE_GROUP
      )

      if (numberOfItem >= maxItem) {
        logger.log(boundingData)
        alert('Please remove current image before add new one.')
        return
      }

      // CHECK MAXIMUM PLACEHOLDER HERE

      left = boundingData.left
      top = boundingData.top
      isBouding = true
      canvasData = {
        left: left,
        top: top,
        scaleX: boundingData.scaleX,
        scaleY: boundingData.scaleY,
        width: boundingData.width,
        height: boundingData.height,
        angle: parseFloat(boundingData._ZONE_ANGLE),
        // centerPoint: boundingData.getCenterPoint(),
        // boundingId: boundingData.id,
        // boundingGroup: boundingData._ZONE_GROUP
      }
      boundingUserData = {
        centerPoint: boundingData.getCenterPoint(),
        boundingId: boundingData.id,
        boundingGroup: boundingData._ZONE_GROUP,
      }
    }

    // ADD TO ZONE LOGIC

    var imageUrl = ''
    if (
      DesignRoot.localData === true ||
      (option && option === 'IMAGE_LIBRARY')
    ) {
      imageUrl = imageLibraryData.imageUrl
    } else {
      if (
        FILE_DATA.file_type !== 'application/postscript' &&
        FILE_DATA.file_type !== 'application/pdf'
      ) {
        tools.uploadFileWithPresignedUrl(
          FILE_DATA,
          gen_filename,
          DesignRoot.ownedByOrganisationId,
          'PRODUCTDESIGN_LOGO',
          DesignRoot.isDashboard
        )

        //let prefix = `${MEDIA_CDN_URL}/${SHOP_ID}`

        gen_filename = gen_filename.replace('.ai', '.svg')
        imageUrl = imageUrl.replace('.ai', '.svg')

        //imageUrl = `${prefix}/userdata/logo/${DesignRoot.ownedByOrganisationId}/${gen_filename}` // Known URL
        //imageUrl = imageUrl.replace('hydrodynamic', 'bodywearlab')
      } else {
        imageUrl = this.state.s3Url
      }

      document.getElementById(domId).value = null
      this.setState({ preview_upload: null })
    }

    let src = base64

    if (/.*\.svg(?:[\?].*)?$/.test(imageUrl)) {
      let promise = new Promise((resolve, reject) => {
        let interval = setInterval(() => {
          fetch(imageUrl).then(r => {
            if (r.status === 200 || r.status === 304) {
              r.blob().then(fileData => {
                if (fileData) {
                  clearInterval(interval)
                  resolve(fileData)
                }
              })
            }
          })

          // let xhr = new fabric.window.XMLHttpRequest()
          // xhr.onreadystatechange = function() {
          //   if (xhr.readyState === 4 && xhr.statusText === 'OK') {
          //     clearInterval(interval)
          //     resolve(xhr.responseXML)
          //     xhr.onreadystatechange = () => {}
          //   }
          // }

          // xhr.open('GET', imageUrl, true)
          // xhr.send('')
        }, 1000)
      })
      let fileData = await promise // check url is existing
      base64 = await tools.getSVGBase64(fileData)

      //src = imageUrl
      src = base64
    }

    DesignRoot.fabric_function.addItem(
      uuid,
      gen_filename,
      src,
      left,
      top,
      {
        ...boundingUserData,
        filename: gen_filename,
        base64: base64,
        s3Url: imageUrl,
      },
      type,
      null,
      canvasData,
      isBouding
    )

    DesignRoot.Loading.activeLoading(false)
  }

  previewSelected(id) {
    try {
      // statements
      const DesignRoot = this.props.DesignRoot
      const item = DesignRoot.fabric_function.getObjectById(id)

      if (item) {
        return item._USER_DATA.base64
      }
    } catch (e) {
      // statements
      logger.error('LogoStep.previewSelected.error', e)
    }
  }

  async retryUpload() {}
  async previewUpload(domId) {
    try {
      // statements

      logger.log('previewUpload')
      var DesignRoot = this.props.DesignRoot

      var file_upload = document.getElementById(domId)
      if (file_upload.files[0]) {
        var FILE_DATA = file_upload.files[0]
      } else {
        alert('Upload your image')
        return
      }

      var file_name = FILE_DATA.name
      const gen_filename = appendToFilename(file_name, '-' + shortid.generate())
      var file_size = FILE_DATA.size
      var file_type = FILE_DATA.type

      const acceptTypesArr = file_upload
        ? file_upload
            .getAttribute('accept')
            .split(',')
            .map(elem => elem.trim())
        : []

      if (acceptTypesArr.length > 0 && !acceptTypesArr.includes(file_type)) {
        alert('File type can be jpg, jpeg, png, svg, gif or ai.')
        return
      }

      const self = this
      if (
        file_type === 'application/postscript' ||
        file_type === 'application/pdf'
      ) {
        // ai or pdf
        DesignRoot.Loading.activeLoading(true)

        let retUrl = new Promise((resolve, reject) => {
          resolve(
            tools.uploadFileWithPresignedUrl(
              FILE_DATA,
              gen_filename,
              DesignRoot.ownedByOrganisationId,
              'PRODUCTDESIGN_LOGO',
              DesignRoot.isDashboard
            )
          )
        })

        retUrl.then(async data => {
          let svgUrl = data
            .replace(/\.ai$/i, '.svg')
            .replace(/\.pdf$/i, '.svg')
            .replace(/^http.+brikl-ecommerce/i, MEDIA_CDN_URL)

          let tryLoading = new Promise((resolve, reject) => {
            let interval = setInterval(() => {
              fetch(svgUrl).then(r => {
                if (r.status === 200 || r.status === 304) {
                  r.blob().then(fileData => {
                    if (fileData) {
                      clearInterval(interval)
                      resolve(fileData)
                    }
                  })
                }
              })
            }, 2000)
          })

          tryLoading.then(fileData => {
            self.setState({ s3Url: svgUrl })

            DesignRoot.Loading.activeLoading(false)
            tools.getBase64(fileData).then(data => {
              var uuid = tools.uuidv4()
              self.setState({ preview_upload: data })
            })
          })
        })
      } else {
        tools.getBase64(FILE_DATA).then(data => {
          var uuid = tools.uuidv4()
          self.setState({ preview_upload: data })
        })
      }
    } catch (e) {
      // statements
      logger.error('LogoStep.previewUpload.error', e)
    }
  }

  replaceDefaultLogo(src) {
    try {
      var DesignRoot = this.props.DesignRoot
      var activeObject = DesignRoot.canvas.getActiveObject()

      logger.log('replaceDefaultLogo', activeObject)

      if (
        activeObject &&
        activeObject._OBJECT_TYPE === 'LOGO' &&
        activeObject._DEFAULT_ITEM &&
        activeObject._DEFAULT_ITEM === true
      ) {
        var defaultId = activeObject.id
        // DesignRoot.fabric_function.removeItem(defaultId);

        var item = DesignRoot.fabric_function.getObjectById(activeObject.id)
        var image = new Image()
        image.crossOrigin = 'anonymous'
        image.onload = function () {
          if (image.width > image.height) {
            var newScale = item._KEEP_SCALE_W / image.width
          } else {
            var newScale = item._KEEP_SCALE_H / image.height
          }

          // DesignRoot.canvas.discardActiveObject();
          item._element = image

          item.set({
            width: image.width,
            height: image.height,
            scaleX: newScale,
            scaleY: newScale,
          })

          item.setCoords()

          item._USER_DATA.s3Url = image.src
          item._USER_DATA.base64 = image.src

          DesignRoot.canvas.renderAll()
          DesignRoot.fabric_function.updateDimension(defaultId, 'byID')
          DesignRoot.tools.renderComponent('LogoStep')
        }
        image.src = src + '?t=' + new Date().getDay()

        // activeObject._element.src = src + '?t=' + new Date().getDay();
        // DesignRoot.canvas.renderAll();
      }
    } catch (e) {
      // statements
      logger.error('LogoStep.replaceDefaultLogo.error', e)
    }
  }

  SLTDefaultLogo() {
    try {
      // GEN ZONE LIST FROM SVG

      var DesignRoot = this.props.DesignRoot

      if (DesignRoot._ACTIVE_PERSONALIZATION === false) {
        return null
      }

      var boundingSET = DesignRoot.fabric_function.get_bounding_by_type('LOGO')

      logger.log('_SVG_BOUNDING_SET LOGO', boundingSET)

      var zoneList = []
      var lastZone = null

      for (var i = 0; i < boundingSET.length; i++) {
        var _BOUNDING_TYPE = boundingSET[i]._BOUNDING_TYPE
        var _ZONE_NUMBER = boundingSET[i]._ZONE_NUMBER
        var _ZONE_LIMIT_ITEM = boundingSET[i]._ZONE_LIMIT_ITEM
        var _ZONE_GROUP = boundingSET[i]._ZONE_GROUP
        var _ZONE_ANGLE = boundingSET[i]._ZONE_ANGLE
          ? boundingSET[i]._ZONE_ANGLE
          : 0
        var _ZONE_NAME = boundingSET[i]._ZONE_NAME

        if (_ZONE_GROUP !== lastZone) {
          var maxItem = DesignRoot.fabric_function.get_bounding_limit_item_by_group(
            'LOGO',
            _ZONE_GROUP
          )

          var numberOfItem = DesignRoot.fabric_function.get_bounding_current_item_by_group(
            'LOGO',
            _ZONE_GROUP
          )

          var addItem = (
            <Button
              variant="contained"
              style={{
                backgroundColor: '#171717',
                color: '#FFFFFF',
                marginTop: 10,
                marginBottom: 10,
              }}
              component="span"
              onClick={this.uploadLogo.bind(
                this,
                'file-upload',
                'LOGO',
                'ADD_TO_ZONE',
                boundingSET[i]
              )}
            >
              PLACE LOGO TO {_ZONE_NAME}
            </Button>
          )

          if (numberOfItem >= maxItem) {
            addItem = (
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#171717',
                  color: '#FFFFFF',
                  marginTop: 10,
                  marginBottom: 10,
                }}
                component="span"
              >
                ALREADY ADDED TO {_ZONE_NAME}
              </Button>
            )
          }

          var toggleZone = null

          if (true) {
            toggleZone = (
              <Button
                variant="outlined"
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
                component="span"
                onClick={DesignRoot.fabric_function.toggle_bounding_stroke.bind(
                  DesignRoot,
                  'LOGO',
                  _ZONE_GROUP
                )}
              >
                TOGGLE ZONE
              </Button>
            )
          }

          zoneList.push(
            <Grid container justify="center" style={{ margin: 0 }}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {_ZONE_NAME} (Max Item : {maxItem} ({numberOfItem}) )
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {addItem}:{toggleZone}
              </Grid>
            </Grid>
          )

          lastZone = _ZONE_GROUP
        }
      }

      // GEN ZONE LIST FROM SVG

      return (
        <Grid container justify="center" style={{ margin: 0 }}>
          <Divider
            light
            style={{ width: 180, marginTop: 40, marginBottom: 40 }}
          />
          {zoneList}
        </Grid>
      )
    } catch (e) {
      // statements
      logger.error('LogoStep.SLTDefaultLogo.error', e)
    }
  }

  handleTransformChange(type, evt, value) {
    try {
      const { classes, DesignRoot, logoConfiguration, langCode } = this.props

      // logger.log('handleTransformChange', type, value);

      var valueNumber = this.state[type]

      if (value === '+') {
        if (type === 'rotate') {
          valueNumber = parseInt(valueNumber) + 1
        } else if (type === 'scale') {
          valueNumber = parseFloat(valueNumber) + 0.01
        }
      } else if (value === '-') {
        if (type === 'rotate') {
          valueNumber = parseInt(valueNumber) - 1
        } else if (type === 'scale') {
          valueNumber = parseFloat(valueNumber) - 0.01
        }
      } else {
        valueNumber = value
      }

      if (type === 'rotate') {
        valueNumber = valueNumber.toFixed(0)
        valueNumber = parseInt(valueNumber)
      } else if (type === 'scale') {
        valueNumber = valueNumber.toFixed(2)
        valueNumber = parseFloat(valueNumber)
      }

      var activeObject = DesignRoot.canvas.getActiveObject()

      if (activeObject && activeObject._OBJECT_TYPE === 'LOGO') {
        var item = DesignRoot.fabric_function.getObjectById(activeObject.id)

        if (type === 'rotate') {
          item.set({ angle: valueNumber })

          item.canvas.requestRenderAll()
          DesignRoot.fabric_function.mutate_object_modify(item)
          DesignRoot.fabric_function.updateDimension(item.id, 'byID')
        } else if (type === 'scale') {
          item.set({
            scaleX: valueNumber,
            scaleY: valueNumber,
          })

          item.canvas.requestRenderAll()
          DesignRoot.fabric_function.mutate_object_modify(item)
          DesignRoot.fabric_function.updateDimension(item.id, 'byID')
        }
      }

      this.setState({ [type]: valueNumber })
    } catch (e) {
      logger.error('LogoStep.handleTransformChange.error', e)
    }
  }

  default_logo() {
    const { classes, DesignRoot, logoConfiguration, langCode } = this.props
    logger.log('logoConfiguration', logoConfiguration)

    const imageLibrary =
      logoConfiguration && logoConfiguration.imageLibrary
        ? logoConfiguration.imageLibrary
        : []

    var defaultLogoListDom = []

    for (let i = 0; i < imageLibrary.length; i++) {
      const image = imageLibrary[i]

      this.defaultLogoList[image.id] = image // USE FOR MAP SAVE/LOAD
      defaultLogoListDom.push(
        <Grid
          key={'logo' + image.id}
          item
          xs={4}
          style={{
            padding: '8px',
            height: 66,
            border: '1px solid #E5E5E5',
            borderRadius: '4px',
          }}
        >
          <img
            data-cy="logo-step-uploaded-img"
            src={image.imageUrl}
            alt=""
            style={{
              objectFit: 'contain',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
            onClick={this.uploadLogo.bind(
              this,
              null,
              'LOGO',
              'IMAGE_LIBRARY',
              null,
              image
            )}
          />
        </Grid>
      )
    }

    if (!defaultLogoListDom.length) {
      return null
    }

    return (
      <>
        <Grid container justify="center" style={{ margin: 0 }}>
          <Divider
            light
            style={{ width: 180, marginTop: 40, marginBottom: 40 }}
          />

          <Grid item xs={12}>
            <Typography variant="h5" style={{ marginBottom: 24 }}>
              Image Library
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ margin: 0, marginTop: 4, marginBottom: 32 }}
          alignItems="center"
          spacing={1}
        >
          {defaultLogoListDom}
        </Grid>
      </>
    )
  }

  render() {
    const { classes, DesignRoot, logoConfiguration, langCode } = this.props
    const { rotate, scale, preview_upload } = this.state

    var approxWidth = 0
    var approxHeight = 0

    if (DesignRoot.Dimension !== null) {
      approxWidth = DesignRoot.Dimension.state.approxWidth
        ? DesignRoot.Dimension.state.approxWidth
        : 0
      approxHeight = DesignRoot.Dimension.state.approxHeight
        ? DesignRoot.Dimension.state.approxHeight
        : 0
    }

    return (
      <Grid
        container
        style={{
          padding: 24,
          overflowY: 'auto',
          height: '100%',
          textAlign: 'center',
        }}
      >
        <Grid item xs={12}>
          <Grid container style={{ margin: 0 }}>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ textAlign: 'center' }}>
                {Localization.get('beCreative_upload_label')}
              </Typography>
            </Grid>
          </Grid>

          <Grid item style={{ flex: 1 }}>
            <input
              type="file"
              id="file-upload"
              accept="image/png, image/jpeg, application/postscript,application/pdf, image/svg+xml, image/gif"
              style={{ display: 'none' }}
              onChange={this.previewUpload.bind(this, 'file-upload')}
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#171717',
                  color: '#FFFFFF',
                  marginTop: 20,
                }}
                component="span"
              >
                {Localization.get('beCreative_upload_button')}
              </Button>
            </label>
            <Grid item xs={12}>
              <Typography
                subtitle1
                style={{
                  fontSize: 11,
                  color: '#666666',
                  textAlign: 'center',
                  paddingTop: 10,
                }}
              >
                JPG, PNG, SVG, PDF or AI only.
              </Typography>
              <Typography
                subtitle1
                style={{
                  fontSize: 11,
                  color: '#666666',
                  textAlign: 'center',
                  paddingTop: 10,
                }}
              >
                Max. file upload is 20MB.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justify="flex-start"
            alignItems="center"
            spacing={1}
            style={{ margin: 0, marginTop: 16 }}
          >
            <Typography id="label" style={{ marginBottom: 16 }}>
              Rotate {rotate}&deg;
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                <Slider
                  value={rotate}
                  aria-labelledby="label"
                  step={1}
                  min={-360}
                  max={360}
                  onChange={this.handleTransformChange.bind(this, 'rotate')}
                  classes={{
                    track: classes.track,
                    thumb: classes.thumb,
                    rail: classes.rail,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Grid
                  container
                  direction={'column'}
                  data-cy="logo-step-button-rotate"
                >
                  <Button
                    style={{ marginBottom: 2, minWidth: 0 }}
                    variant="outlined"
                    onClick={e => {
                      this.handleTransformChange('rotate', e, '+')
                    }}
                  >
                    <KeyboardArrowUp style={{ fontSize: 14 }} />
                  </Button>
                  <Button
                    style={{ marginBottom: 2, minWidth: 0 }}
                    variant="outlined"
                    onClick={e => {
                      this.handleTransformChange('rotate', e, '-')
                    }}
                  >
                    <KeyboardArrowDown style={{ fontSize: 14 }} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justify="flex-start"
            alignItems="center"
            spacing={1}
            style={{ margin: 0, marginTop: 16 }}
          >
            <Typography id="label" style={{ marginBottom: 8 }}>
              Resize {approxWidth} x {approxHeight} CM
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                <Slider
                  value={scale}
                  aria-labelledby="label"
                  step={0.01}
                  min={0.01}
                  max={5}
                  onChange={this.handleTransformChange.bind(this, 'scale')}
                  classes={{
                    track: classes.track,
                    thumb: classes.thumb,
                    rail: classes.rail,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Grid
                  container
                  direction={'column'}
                  data-cy="logo-step-button-scale"
                >
                  <Button
                    style={{ marginBottom: 2, minWidth: 0 }}
                    variant="outlined"
                    onClick={e => {
                      this.handleTransformChange('scale', e, '+')
                    }}
                  >
                    <KeyboardArrowUp style={{ fontSize: 14 }} />
                  </Button>
                  <Button
                    style={{ marginBottom: 2, minWidth: 0 }}
                    variant="outlined"
                    onClick={e => {
                      this.handleTransformChange('scale', e, '-')
                    }}
                  >
                    <KeyboardArrowDown style={{ fontSize: 14 }} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {preview_upload !== null && (
            <Grid
              container
              style={{ margin: 0, marginTop: 4, marginBottom: 32 }}
              alignItems="center"
              spacing={1}
            >
              <Grid
                item
                xs={4}
                style={{
                  padding: '8px',
                  height: 66,
                  border: '1px solid #E5E5E5',
                  borderRadius: '4px',
                }}
              >
                <img
                  data-cy="logo-step-uploaded-img"
                  src={preview_upload}
                  alt=""
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Grid
                  container
                  style={{ margin: 0, marginTop: 4 }}
                  alignItems="center"
                  justify="center"
                >
                  {DesignRoot._ACTIVE_PERSONALIZATION === false && (
                    <Button
                      style={{
                        backgroundColor: '#171717',
                        color: '#FFFFFF',
                      }}
                      variant="outlined"
                      onClick={this.uploadLogo.bind(
                        this,
                        'file-upload',
                        'LOGO'
                      )}
                    >
                      {Localization.get('beCreative_place_logo')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          {this.default_logo()}

          <Grid container justify="center" style={{ margin: 0 }}>
            <Divider
              light
              style={{ width: 180, marginTop: 40, marginBottom: 40 }}
            />

            <Grid item xs={12}>
              <Typography variant="h5" style={{ marginBottom: 24 }}>
                {Localization.get('beCreative_logo_list')}
              </Typography>
            </Grid>
          </Grid>

          <LayerControl
            type="LOGO"
            logoConfiguration={logoConfiguration}
            DesignRoot={DesignRoot}
            langCode={langCode}
          />
        </Grid>

        {this.SLTDefaultLogo()}
      </Grid>
    )
  }
}

LogoStep.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LogoStep)
