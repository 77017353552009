import React, { Component } from 'react';

export default class ScrollWheelIcon extends React.Component {
  render() {
    const {
      width = 80,
      height = 80,
      background,
      padding,
      fill5 = '#c4c4c4'
    } = this.props;
    return (
      <div style={{ width: width, height: height, padding: padding }}>
        <style
          dangerouslySetInnerHTML={{
            __html: `
                        .scrollWheel-1{fill:#fff;}.scrollWheel-1,.scrollWheel-4{stroke:#000;stroke-miterlimit:10;stroke-width:2px;}.scrollWheel-2{fill:#4d4d4d;}.scrollWheel-3{fill:#939393;}.scrollWheel-4{fill:none;stroke-linecap:round;}
        `
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 83.71 118.73"
          style={{ width: '100%', height: '100%' }}
        >
          <defs />
          <title>Asset 4</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <rect
                class="scrollWheel-1"
                x="1"
                y="1"
                width="81.71"
                height="116.73"
                rx="40.85"
              />
              <rect
                class="scrollWheel-2"
                x="38.8"
                y="15.19"
                width="7.2"
                height="32"
                rx="3.6"
              />
              <rect
                class="scrollWheel-3"
                x="38.8"
                y="3.19"
                width="7.2"
                height="34"
                rx="3.6"
              />
              <line
                class="scrollWheel-4"
                x1="42.5"
                y1="9.19"
                x2="42.5"
                y2="43.19"
              />
              <rect
                class="scrollWheel-1"
                x="38.18"
                y="22.97"
                width="8.32"
                height="17.22"
                rx="4.11"
              />
              <path
                class="scrollWheel-3"
                d="M50.6,18l.15,2.5a.15.15,0,1,1-.3,0v0Z"
              />
              <path
                class="scrollWheel-3"
                d="M50.9,23.8c.08,1.85.18,3.71.24,5.56a.54.54,0,0,1-1.08,0v0c.06-1.85.16-3.71.24-5.56a.31.31,0,0,1,.31-.29A.3.3,0,0,1,50.9,23.8Z"
              />
              <path
                class="scrollWheel-3"
                d="M51.25,32.69l.14,5.56a.79.79,0,0,1-1.58,0v0L50,32.69a.65.65,0,0,1,1.3,0Z"
              />
              <path
                class="scrollWheel-3"
                d="M51.46,41.58l0,2.78v.69c0,.25,0,.52,0,.77a9.75,9.75,0,0,1-.24,1.53.94.94,0,0,1-1.82-.44v0a7,7,0,0,0,.22-1.21,6.13,6.13,0,0,0,0-.62v-.69l0-2.78a.86.86,0,0,1,1.72,0Z"
              />
              <path
                class="scrollWheel-3"
                d="M49.63,50.68A9.05,9.05,0,0,1,44.48,54h0A1,1,0,1,1,44,52h0a7.24,7.24,0,0,0,4.07-2.54,1,1,0,0,1,1.51,1.19Z"
              />
              <path
                class="scrollWheel-3"
                d="M40.77,54a9.08,9.08,0,0,1-5.25-3.13A1,1,0,0,1,37,49.64a7.13,7.13,0,0,0,4.13,2.43,1,1,0,0,1,.8,1.15,1,1,0,0,1-1.16.8h0Z"
              />
              <path
                class="scrollWheel-3"
                d="M33.81,47.59a9.91,9.91,0,0,1-.28-1.52,12.63,12.63,0,0,1,0-1.49l0-2.78a.86.86,0,0,1,1.72,0l0,2.78a10.35,10.35,0,0,0,.06,1.29,6.93,6.93,0,0,0,.26,1.2.94.94,0,0,1-1.8.55Z"
              />
              <path
                class="scrollWheel-3"
                d="M33.61,38.47l.14-5.56a.65.65,0,0,1,1.3,0l.14,5.56a.79.79,0,1,1-1.58,0Z"
              />
              <path
                class="scrollWheel-3"
                d="M33.86,29.58c0-1.85.16-3.7.24-5.55a.3.3,0,1,1,.6,0c.08,1.85.19,3.7.24,5.55a.54.54,0,1,1-1.08,0Z"
              />
              <path
                class="scrollWheel-3"
                d="M34.25,20.69l.15-2.5.15,2.5a.15.15,0,0,1-.3,0Z"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
