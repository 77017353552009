import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  ADMIN_UPDATE_PRODUCT,
  ADMIN_UPLOAD_FILE
} from '../../gql/admin-mutations';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import _sortBy from 'lodash/sortBy';

import { API_STAGE } from '../../../../../brikl-config';
import { I18n } from '@aws-amplify/core';

import Input from '../../../common/Input';
import CircularProgress from '@material-ui/core/CircularProgress';

import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';

import _get from 'lodash/get';
import FileDrop from 'react-file-drop';
import './CollectionFileDrop.css';

const axios = require('axios');
const shortid = require('shortid');
const uuidv4 = require('uuid/v4');
var jwtDecode = require('jwt-decode');

class CollectionMedia extends Component {
  state = {
    isActive: false,
    message: '',
    isBriklSuperAdmin: false,
    open: false,
    value: 'Yes'
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
  uploadCollectionMediaFiles = async (
    files,
    uploadFile,
    collection,
    updateCollection,
    field
  ) => {
    try {
      const {} = this.props;
      this.setState({
        // uploading: files.length.toString()
        uploading: true
      });
      for (var i = 0; i < files.length; i++) {
        const myFile = files[i];
        if (myFile.size < 20000000) {
          const fileName =
            myFile.name.replace(/\.[^/.]+$/, '').replace(/\s/g, '-') +
            '-' +
            shortid.generate() +
            '.' +
            myFile.name.split('.').pop();
          const signedUrlResult = await uploadFile({
            variables: {
              type: 'COLLECTION_MEDIA',
              fileName,
              prefixVariables: ['collectionId'],
              input: {
                collectionId: collection.id
              }
            }
          });
          const signedUrl = signedUrlResult.data.uploadFile.url;
          var options = {
            headers: {
              'Content-Type': signedUrlResult.data.uploadFile.contentType
            }
          };
          if (signedUrlResult.data.uploadFile.acl) {
            options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl;
          }
          if (signedUrlResult.data.uploadFile.tagging) {
            options.headers['x-amz-tagging'] =
              signedUrlResult.data.uploadFile.tagging;
          }
          const uploadResult = await axios.put(signedUrl, myFile, options);
          // refetch();
          this.setState({
            uploading: false
          });

          await updateCollection({
            optimisticResponse: {
              __typename: 'Mutation',
              updateCollection: {
                ...collection,
                [field]: fileName
              }
            },
            variables: {
              id: collection.id,
              input: {
                [field]: fileName
              }
            }
          });
          console.log('uploadResult', uploadResult);
        } else {
          this.setState({
            uploading: false
          });
          alert('Only files up to 20MB supported.');
        }
      }
    } catch (error) {
      console.error('uploaderror', error);
      this.setState({
        uploading: false
      });
    }
  };

  render() {
    const {
      blockEdit,
      classes,
      field,
      collectionId,
      updateCollection,
      collection
    } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Grid
            container
            style={{ padding: '24px 24px 24px 0px' }}
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <Typography
                data-cy={'Collection-' + field + '-header'}
                variant="subtitle1"
                style={{ marginRight: 16 }}
              >
                Collection {field}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Upload collection image for your product
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Paper style={{ padding: 24 }} elevation={0}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                style={{
                  padding: '0px 8px 0px 0px !important'
                }}
              >
                {this.state.uploading === true ? (
                  <span
                    style={{
                      fontSize: '3em'
                    }}
                  >
                    <CircularProgress />
                  </span>
                ) : null}

                <Mutation
                  update={(store, { data: { uploadFile } }) => {}}
                  mutation={ADMIN_UPLOAD_FILE}
                >
                  {uploadFile => (
                    <Grid
                      container
                      style={{
                        border: '1px dashed #E5E5E5',
                        background: '#f5f5f5',
                        padding: 0,
                        borderRadius: '5px'
                      }}
                    >
                      {/* {JSON.stringify(product)} */}
                      <input
                        style={{
                          display: 'none'
                        }}
                        accept=".png,.jpg,.jpeg"
                        id="productMediaUploadInput"
                        type="file"
                        onChange={e => {
                          this.uploadCollectionMediaFiles(
                            e.target.files,
                            uploadFile,
                            collection,
                            updateCollection,
                            field
                          );
                        }}
                      />
                      <Grid
                        item
                        xs={12}
                        data-cy="Collection-media-drag-and-drop-container"
                        style={{
                          padding: '0'
                        }}
                      >
                        <FileDrop
                          onDrop={(files, event) => {
                            console.log('handleDrop!', files, event);
                            this.uploadCollectionMediaFiles(
                              files,
                              uploadFile,
                              collection,
                              updateCollection,
                              field
                            );
                          }}
                        >
                          <Grid container direction="column">
                            <Grid item>
                              <span>
                                {I18n.get('Drag your file here ')}
                                <br />( .png or .jpg )
                              </span>
                            </Grid>
                          </Grid>
                        </FileDrop>
                      </Grid>
                    </Grid>
                  )}
                </Mutation>
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                style={{
                  border: '1px solid #E5E5E5',
                  padding: '8px 8px 4px 8px',
                  borderRadius: '5px'
                }}
              >
                <Grid container alignItems="center" direction="row" spacing={1}>
                  {collection[field] ? (
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      key={'style-' + field}
                      style={{
                        position: 'relative'
                      }}
                    >
                      <div style={{ height: '80px' }}>
                        <img
                          src={collection[field]}
                          alt="Storefront"
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'inline',
                            objectFit: 'contain',
                            borderRadius: '6px',
                            border: '1px solid #ccc'
                          }}
                        />
                        <Button
                          data-cy={'Collection-Delete-' + field + '-btn'}
                          color="secondary"
                          variant="contained"
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            minHeight: 30,
                            minWidth: 30,
                            height: 30,
                            width: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 0,
                            borderRadius: '100%'
                          }}
                          onClick={() => {
                            updateCollection({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                updateCollection: {
                                  ...collection,
                                  [field]: null
                                }
                              },
                              variables: {
                                id: collectionId,
                                input: {
                                  [field]: null
                                }
                              }
                            });
                          }}
                        >
                          <Delete style={{ fontSize: 18 }} />
                        </Button>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default CollectionMedia;
