import React, { useState } from 'react'
import {
  InputLabel,
  MenuItem,
  Select,
  createStyles,
  withStyles,
  WithStyles,
  Grid,
  Typography
} from '@material-ui/core'

import {
  getLinkText,
  recursiveGetSubItems,
  prefixSubItems
} from '../../../../../helper/menuHelper'

const styles = () => createStyles({})

interface Props extends WithStyles<typeof styles> {
  data: any
  navigationLinks: any[]
  pages: any[]
  parentPages: any[]
  onChange(pageId: string | null, parentPageId: string | null): void
  isV2?: boolean
}

const AddMenuLinkPage: React.FunctionComponent<Props> = props => {
  const { data, navigationLinks, pages, parentPages, onChange, isV2 } = props

  const [selectedPage, setSelectedPage] = useState(data.pageId)
  const [selectedParentPage, setSelectedParentPage] = useState(data.parentId)

  const handleChange = (e: any): void => {
    setSelectedPage(e.target.value)
    onChange(e.target.value, selectedParentPage)
  }

  const handleChangeParent = (e: any): void => {
    setSelectedParentPage(e.target.value)
    onChange(selectedPage, e.target.value)
  }

  let allParentOptions = []
  parentPages.forEach(item => {
    allParentOptions.push(item)
    const subItems = recursiveGetSubItems(navigationLinks, item.id, 1)
    allParentOptions = allParentOptions.concat(subItems)
  })

  return (
    <>
      <InputLabel>Add a Submenu of:</InputLabel>
      <Select
        value={selectedParentPage}
        onChange={handleChangeParent}
        style={{
          border: '1px solid #eee',
          margin: '8px 0px 40px',
          borderRadius: 4
        }}
      >
        <MenuItem value={null}>-</MenuItem>
        {allParentOptions.map((item: any) => (
          <MenuItem value={item.id} key={item.id}>
            {prefixSubItems(item._level)} {getLinkText(item)}
          </MenuItem>
        ))}
      </Select>

      <InputLabel>Choose a page to link</InputLabel>
      <Select
        value={selectedPage}
        onChange={handleChange}
        inputProps={{
          id: 'pageId-simple',
          name: 'pageId'
        }}
        style={{
          border: '1px solid #eee',
          margin: '8px 0px 16px',
          borderRadius: 4
        }}
      >
        <MenuItem value={null}>---</MenuItem>
        {!isV2 && pages?.edges
          ? pages.edges.map((page: any) => (
              <MenuItem key={page.node.id} value={page.node.id}>
                {page.node.name}
              </MenuItem>
            ))
          : pages.map(page => (
              <MenuItem key={page.id} value={page.id}>
                {page?.latestRevision?.name?.defaultValue}
              </MenuItem>
            ))}
      </Select>
      <Grid
        container={true}
        alignItems="center"
        style={{ margin: '8px 0px 16px' }}
      >
        <Grid item={true} xs={5} style={{ background: '#eee', height: 2 }}>
          &nbsp;
        </Grid>
        <Grid item={true} xs={2}>
          <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
            Or
          </Typography>
        </Grid>
        <Grid item={true} xs={5} style={{ background: '#eee', height: 2 }}>
          &nbsp;
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(AddMenuLinkPage)
