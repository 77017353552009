import React, { Component } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { secondary } from '../../../admin/AdminTheme';
import { getTextFromLanguage } from '../../../../helper/languageHelper';

class ProductPackageAssignedProducts extends Component {
  state = {
    selectedSalesChannelId: null,
    loading: false
  };
  render() {
    const {
      defaultLanguage,
      productPackage,
      updateProductPackage,
      shopId
    } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography
            data-cy="ProductPackage-Assigned-products-header"
            variant="subtitle1"
          >
            Assigned products
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 8 }}>
          {this.state.loading === true ? (
            <CircularProgress />
          ) : productPackage.products ? (
            productPackage.products.map(assignedProduct =>
              !assignedProduct.id ? null : (
                <Grid
                  container
                  alignItems="center"
                  style={{ padding: '8px 0px' }}
                  spacing={1}
                  onClick={() => {
                    window.open(
                      '/admin/' +
                        shopId +
                        '/inventory/products/' +
                        assignedProduct.id
                    );
                  }}
                >
                  <Grid item xs={1}>
                    <Grid container justify={'center'}>
                      <img
                        style={{
                          width: '20px',
                          height: '20px',
                          objectFit: 'contain'
                        }}
                        src={
                          assignedProduct &&
                          assignedProduct.media &&
                          assignedProduct.media.length &&
                          assignedProduct.media.find(m => m.isThumbnail)
                            ? assignedProduct.media.find(m => m.isThumbnail)
                                .image
                            : ''
                        }
                        alt="Thumb"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: secondary,
                      height: 36,
                      color: '#fff',
                      borderRadius: 3,
                      display: 'flex',
                      alignContent: 'center',
                      padding: 8
                    }}
                  >
                    {assignedProduct.title
                      ? getTextFromLanguage(
                          assignedProduct.title.text,
                          defaultLanguage
                        )
                        ? getTextFromLanguage(
                            assignedProduct.title.text,
                            defaultLanguage
                          )
                        : assignedProduct.name
                      : 'N/A'}
                    ({assignedProduct.no})
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      onClick={async () => {
                        updateProductPackage({
                          optimisticResponse: {
                            __typename: 'Mutation',
                            updateProductPackage: {
                              ...productPackage,
                              products: productPackage.products.filter(p =>
                                p.id === assignedProduct.id ? false : true
                              ),
                              productIds: productPackage.productIds.filter(pI =>
                                pI === assignedProduct.id ? false : true
                              )
                            }
                          },
                          variables: {
                            id: productPackage.id,
                            input: {
                              productIds: productPackage.productIds.filter(
                                tempPI =>
                                  tempPI === assignedProduct.id ? false : true
                              )
                            }
                          }
                        });
                      }}
                      color="primary"
                      variant="contained"
                      style={{ minWidth: 0, width: '100%' }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Grid>
                </Grid>
              )
            )
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

export default ProductPackageAssignedProducts;
