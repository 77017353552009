import React from 'react';
import Grid from '@material-ui/core/Grid';
import MuiInput from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';

class Input extends React.Component {
  render() {
    const {
      disabled = false,
      placeholder,
      label,
      background,
      value,
      onChange,
      onKeyPress,
      onBlur,
      onFocus,
      variant,
      rowsMax,
      fontSize = '14px',
      minWidth = 100,
      type,
      fullWidth,
      defaultValue,
      disableUnderline,
      padding,
      borderRadius,
      maxlength,
      dataCy,
      autoComplete
    } = this.props;

    return (
      <div data-cy={dataCy}>
        <InputLabel
          style={{
            fontSize: fontSize,
            opacity: disabled === true ? 0.3 : 1
          }}
        >
          {label}
        </InputLabel>
        <MuiInput
          inputProps={{
            maxLength: maxlength,
            autoComplete: autoComplete
          }}
          disabled={disabled}
          style={{
            fontSize: fontSize,
            opacity: disabled === true ? 0.3 : 1,
            background: background,
            padding: padding
          }}
          fullWidth={fullWidth}
          value={value ? value : ''}
          placeholder={placeholder ? placeholder : ''}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          onKeyPress={onKeyPress}
          variant={variant}
          rowsMax={rowsMax}
          type={type}
          defaultValue={defaultValue}
        />
      </div>
    );
  }
}

export default Input;
