import React, { useState } from 'react'
import {
  Grid,
  WithStyles,
  createStyles,
  Theme,
  withStyles
} from '@material-ui/core'
import _ from 'lodash'
import ContentColumnRow from './ContentColumnRow'
import { moveCard } from './dragdrop/sorting'
import DraggableCard from './dragdrop/DraggableCard'
import useContentModule from './hooks/useContentModule'
import { addPercent, addPx, remapAlignmentForLayout } from './utils'
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../../brikl-config'

const styles = (theme: Theme) =>
  createStyles({
    moduleLabelShow: {
      height: 20,
      width: 'auto',
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      background: 'skyblue',
      color: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'BiomeStd-Light',
      fontWeight: 'bold',
      fontSize: 10,
      padding: 5,
      zIndex: 15,
      display: 'none',
      cursor: 'pointer'
    },
    moduleContainer: {
      zIndex: 5,
      //minWidth: '33%',
      position: 'relative',
      backgroundColor: 'white',
      flex: 1,
      margin: 0,
      minHeight: '60px',
      cursor: 'default'
    },
    cardInvisible: {
      border: '1px solid white'
    },
    cardVisible: {
      border: '1px dashed skyblue'
    },
    labelVisible: {
      display: 'block'
    },
    mobile: {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100% !important'
      }
    }
  })

interface Props extends WithStyles<typeof styles> {
  Template: any
  contentColumn: any
  pageId: string
  columnId: string
  rowId: string
  index: number
}

const ContentModule: React.FunctionComponent<Props> = props => {
  const {
    classes,
    contentColumn,
    Template,
    index,
    rowId,
    pageId,
    pageContext,
    shopId,
    themeId
  } = props

  let isMobile = false
  try {
    if (typeof navigator !== `undefined`) {
      isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    }
  } catch (error) {}

  if (!Template) return null
  const {
    isHover,
    isSelected,
    handleMouseEnter,
    handleMouseLeave
  } = useContentModule(contentColumn.id, Template.state.selectedCol)

  const getColumnStyle = (data: any) => {
    let imgixParams = `?auto=compress&fit=crop&txt=${encodeURIComponent(
      data.copyrightContent ? data.copyrightContent : ''
    )}&txtlead=2&txtpad=15&txtfont=${encodeURIComponent(
      data.copyrightFontFamily ? data.copyrightFontFamily : ''
    )}&txtclr=${encodeURIComponent(
      data.copyrightFontColor ? data.copyrightFontColor : ''
    )}&txtalign=${encodeURIComponent(
      data.copyrightVerticalAlignment
        ? data.copyrightVerticalAlignment.toLowerCase()
        : ''
    )},${encodeURIComponent(
      data.copyrightHorizontalAlignment
        ? data.copyrightHorizontalAlignment.toLowerCase()
        : ''
    )}`

    const columnStyle = {
      flex:
        data.width && data.width !== '0%'
          ? '0 0 ' + addPercent(data.width)
          : '1 0 0',
      maxWidth: !isMobile
        ? data.maxWidth && data.maxWidth !== '0px'
          ? addPx(data.maxWidth)
          : data.maxWidth === '0px'
          ? '100%'
          : '100%'
        : '1000px',

      width: data.width ? addPercent(data.width) : '100%',
      padding: data.padding || null,
      alignItems: remapAlignmentForLayout(data.verticalAlign),
      marginTop: '1.5rem',
      marginBottom: '.5rem',
      height: data.height ? data.width : '100%'
    }
    return data.backgroundImg && data.backgroundImg !== 'none'
      ? {
          ...columnStyle,
          backgroundImage: `url("${IMGIX_URL}/${shopId}/media/${data.backgroundImg}${imgixParams}")`,
          backgroundPosition: data.backgroundPosition
            ? data.backgroundPosition
            : null,
          backgroundRepeat:
            data.backgroundRepeat && data.backgroundRepeat.indexOf('_')
              ? data.backgroundRepeat.replace('_', '-').toLowerCase()
              : 'no-repeat',
          backgroundSize: data.backgroundSize
            ? data.backgroundSize.toLowerCase()
            : 'cover',
          height: addPx(data.height, '100%'),
          alignItems: remapAlignmentForLayout(data.verticalAlign)
        }
      : {
          ...columnStyle,
          minWidth: isMobile ? '300px' : null,
          backgroundColor: data.backgroundColor
            ? data.backgroundColor
            : 'transparent',
          height: addPx(data.height, '100%'),
          alignItems: remapAlignmentForLayout(data.verticalAlign)
        }
  }

  contentColumn.columnRows = _.sortBy(contentColumn.columnRows, 'sortOrder')
  const data =
    Template.state.unsavedData &&
    Template.state.selectedCol.id === contentColumn.id
      ? { ...contentColumn, ...Template.state.unsavedData }
      : contentColumn

  return (
    <Grid
      component={DraggableCard}
      index={index}
      id={data.id}
      dragdrop={{
        dragType: 'column',
        id: data.id,
        index,
        moveCard,
        pageId,
        rowId,
        sortOrder: data.sortOrder
      }}
      key={data.id}
      container={true}
      alignItems={remapAlignmentForLayout(data.verticalAlign)}
      style={getColumnStyle(data)}
      className={
        classes.moduleContainer +
        ' ' +
        (isHover || isSelected ? classes.cardVisible : classes.cardInvisible)
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        onClick={() =>
          Template.setState({
            currentTab: 1,
            selectedCol: data,
            selectedColRow: '',
            selectedRow: '',
            selectedRowObj: '',
            selectedElement: '',
            unsavedData: null
          })
        }
        className={
          classes.moduleLabelShow +
          ' ' +
          (isHover || isSelected ? classes.labelVisible : '')
        }
        data-cy={'CONTENT-COLUMN-ROW-LABEL-' + data.sortOrder}
      >
        COLUMN > {data.sortOrder}
      </div>
      <Grid
        style={{ width: '100%', height: '100%', display: 'flex' }}
        container={true}
        alignItems="center"
        justify="center"
      >
        {data.columnRows.map((columnRow: any, indexColRow: number) => {
          return (
            <ContentColumnRow
              key={indexColRow}
              columnRow={columnRow}
              Template={Template}
              pageId={pageId}
              rowId={rowId}
              shopId={shopId}
              columnId={data.id}
              index={indexColRow}
              pageContext={pageContext}
              themeId={themeId}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(ContentModule)
