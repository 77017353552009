import React, { Component } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../../common/Select';
import { Link } from 'gatsby';
import { Query, Mutation } from 'react-apollo';
import {
  ADMIN_GET_COLLECTION,
  ADMIN_SHOP_PRODUCTS
} from '../../gql/admin-queries';
import TextField from '../../../common/TextField';
import {
  ADMIN_CREATE_COLLECTION_PRODUCT,
  ADMIN_DELETE_COLLECTION_PRODUCT,
  ADMIN_UPDATE_COLLECTION_PRODUCT
} from '../../gql/admin-mutations';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import AdminSearchProduct from '../common/AdminSearchProduct';
class CollectionProducts extends Component {
  state = {
    selectedProductId: null,
    productSelected: null
  };
  render() {
    const {
      collection,
      defaultLanguage,
      refetch,
      classes,
      shopId
    } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography data-cy="Collection-Products-header" variant="subtitle1">
            Products
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Paper elevation={0} style={{ padding: 24 }}>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid item xs={12}>
                <Mutation
                  mutation={ADMIN_CREATE_COLLECTION_PRODUCT}
                  update={(cache, { data: { createCollectionProduct } }) => {
                    if (createCollectionProduct) {
                      const data = cache.readQuery({
                        query: ADMIN_GET_COLLECTION,
                        variables: { id: collection.id }
                      });
                      data.collection.products.unshift({
                        product: {
                          id: this.state.productSelected.id,
                          __typename: 'Product'
                        },
                        __typename: 'CollectionProduct'
                      });
                      cache.writeQuery({
                        query: ADMIN_GET_COLLECTION,
                        variables: { id: collection.id },
                        data
                      });
                    }
                  }}
                >
                  {(createCollectionProduct, { data, loading, error }) => {
                    return (
                      <AdminSearchProduct
                        text={'Search for product'}
                        classes={classes}
                        onSelect={product => {
                          try {
                            this.setState({
                              productSelected: product,
                              selectedProductId: product.id
                            });
                            createCollectionProduct({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                createCollectionProduct: {
                                  featured: false,
                                  sortOrder: null,
                                  collectionId: collection.id,
                                  product: {
                                    ...product
                                  },
                                  productId: product.id,
                                  __typename: 'CollectionProduct'
                                }
                              },
                              variables: {
                                shopId: shopId,
                                collectionId: collection.id,
                                productId: product.id
                              }
                            });
                          } catch (error) {}
                        }}
                        excludeIds={collection.products.map(tp =>
                          tp && tp.product ? tp.product.id : null
                        )}
                      />
                    );
                  }}
                </Mutation>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: 24 }}>
              {collection.products
                ? _orderBy(collection.products, ['sortOrder'], ['asc']).map(
                    cp => (
                      <Mutation
                        mutation={ADMIN_UPDATE_COLLECTION_PRODUCT}
                        update={(
                          cache,
                          { data: { updateCollectionProduct } }
                        ) => {
                          const data = cache.readQuery({
                            query: ADMIN_GET_COLLECTION,
                            variables: { id: collection.id }
                          });
                          data.collection.products = data.collection.products.map(
                            tempCp => {
                              return tempCp.product.id === cp.product.id
                                ? updateCollectionProduct
                                : tempCp;
                            }
                          );
                          cache.writeQuery({
                            query: ADMIN_GET_COLLECTION,
                            variables: { id: collection.id },
                            data
                          });
                        }}
                      >
                        {(
                          updateCollectionProduct,
                          { data, loading, error }
                        ) => {
                          var src = null;
                          try {
                            src = _get(cp.product, 'media', []).find(
                              m => m && m.isThumbnail === true
                            ).image;
                          } catch (error) {}
                          return (
                            <Grid
                              container
                              alignItems="center"
                              spacing={1}
                              style={{ marginBottom: 8 }}
                              key={'cp-' + cp.product.id}
                              item
                            >
                              {/* {cp.featured === true ? (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  style={{ width: 139 }}
                                  onClick={async () => {
                                    const newFeaturedValue =
                                      cp.featured === true ? false : true;
                                    await updateCollectionProduct({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateCollectionProduct: {
                                          ...cp,
                                          productId: cp.product.id,
                                          collectionId: collection.id,
                                          featured: newFeaturedValue
                                        }
                                      },
                                      variables: {
                                        productId: cp.product.id,
                                        collectionId: collection.id,
                                        input: {
                                          featured: newFeaturedValue
                                        }
                                      }
                                    });
                                    window.location.reload();
                                  }}
                                >
                                  Don't feature
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ width: 139 }}
                                  onClick={async () => {
                                    const newFeaturedValue =
                                      cp.featured === true ? false : true;
                                    await updateCollectionProduct({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateCollectionProduct: {
                                          ...cp,
                                          productId: cp.product.id,
                                          collectionId: collection.id,
                                          featured: newFeaturedValue
                                        }
                                      },
                                      variables: {
                                        productId: cp.product.id,
                                        collectionId: collection.id,
                                        input: {
                                          featured: newFeaturedValue
                                        }
                                      }
                                    });
                                    window.location.reload();
                                  }}
                                >
                                  Set featured
                                </Button>
                              )} */}
                              <Grid item xs={2}>
                                <TextField
                                  style={{ padding: '0px !important' }}
                                  placeholder="Sort order"
                                  value={cp.sortOrder}
                                  onChange={async e => {
                                    updateCollectionProduct({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateCollectionProduct: {
                                          ...cp,
                                          productId: cp.product.id,
                                          collectionId: collection.id,
                                          sortOrder: e.target.value
                                            ? parseFloat(e.target.value)
                                            : null
                                        }
                                      },
                                      variables: {
                                        productId: cp.product.id,
                                        collectionId: collection.id,
                                        input: {
                                          sortOrder: e.target.value
                                            ? parseFloat(e.target.value)
                                            : null
                                        }
                                      }
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <Grid container justify="center">
                                  {src ? (
                                    <img
                                      width={20}
                                      height={20}
                                      style={{
                                        objectFit: 'contain',
                                        margin: '8px',
                                        width: '44px',
                                        height: '36px',
                                        border: '1px solid #eee',
                                        borderRadius: 4
                                      }}
                                      src={src}
                                      alt={'cp.product.id'}
                                    />
                                  ) : (
                                    <div style={{ width: '60px' }} />
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item xs={8}>
                                <a
                                  target="_blank"
                                  href={
                                    '/admin/' +
                                    shopId +
                                    '/inventory/products/' +
                                    cp.product.id
                                  }
                                  style={{
                                    textDecoration: 'none',
                                    color: '#000',
                                    display: 'flex',
                                    flex: 1
                                  }}
                                >
                                  <Grid
                                    container
                                    style={{
                                      background: '#62082b',
                                      height: 36,
                                      borderRadius: 3,
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '8px 8px',
                                      marginRight: 8,
                                      color: '#fff'
                                    }}
                                  >
                                    {cp.product.title
                                      ? getTextFromLanguage(
                                          cp.product.title.text,
                                          defaultLanguage
                                        )
                                      : 'N/A'}
                                    &nbsp;({cp.product.no})
                                  </Grid>
                                </a>
                              </Grid>
                              <Grid item xs={1}>
                                <Mutation
                                  mutation={ADMIN_DELETE_COLLECTION_PRODUCT}
                                  update={(
                                    cache,
                                    { data: { deleteCollectionProduct } }
                                  ) => {
                                    if (deleteCollectionProduct) {
                                      const data = cache.readQuery({
                                        query: ADMIN_GET_COLLECTION,
                                        variables: { id: collection.id }
                                      });
                                      data.collection.products = data.collection.products.filter(
                                        c => c.product.id !== cp.product.id
                                      );
                                      cache.writeQuery({
                                        query: ADMIN_GET_COLLECTION,
                                        variables: { id: collection.id },
                                        data
                                      });
                                    }
                                  }}
                                >
                                  {(
                                    deleteCollectionProduct,
                                    { data, loading, error }
                                  ) => {
                                    return (
                                      <Button
                                        onClick={async () => {
                                          if (cp) {
                                            await deleteCollectionProduct({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                deleteCollectionProduct: true
                                              },
                                              variables: {
                                                productId: cp.product.id,
                                                collectionId: collection.id
                                              }
                                            });
                                          }
                                        }}
                                        color="primary"
                                        variant="contained"
                                        style={{ minWidth: 0, width: '100%' }}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    );
                                  }}
                                </Mutation>
                              </Grid>
                            </Grid>
                          );
                        }}
                      </Mutation>
                    )
                  )
                : null}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default CollectionProducts;
