import { Slider } from '@material-ui/core'
import React, { useState } from 'react'
import {
  Grid,
  createStyles,
  withStyles,
  WithStyles,
  InputLabel,
  Paper
} from '@material-ui/core'

import { addPx } from '../../content/utils'
import ColorPicker from '../../content/ColorPicker'
import SelectAlignment from '../../content/SelectAlignment'
import VerticalAlign from '../../content/VerticalAlign'

const styles = () => createStyles({})

interface Props extends WithStyles<typeof styles> {
  contentMenu: any
  onSave(value: string | number | any, field: string): void
}

const EditMenuSettings: React.FunctionComponent<Props> = props => {
  const { classes, contentMenu, onSave } = props

  const [data, setData] = useState(contentMenu)

  const handleChangeField = (
    value: string | number | any,
    fieldName: string
  ): void => {
    setData({ ...data, [fieldName]: value })
    onSave(value, fieldName)
  }

  const handleChangeStylingFieldUpdate = (
    value: string | number,
    fieldName: string
  ): void => {
    const styling = data.styling || {}
    const newValue = { ...styling, [fieldName]: value ? addPx(value) : null }
    setData({ ...data, styling: newValue })
  }

  const handleChangeStylingFieldSave = (): void => {
    const styling = data.styling || {}
    onSave(styling, 'styling')
  }

  const handleChangeVerticalAlign = (
    value: string,
    fieldName: string
  ): void => {
    const newValue = value.replace('-', '_')
    handleChangeField(newValue, fieldName)
  }

  return (
    <Grid
      container={true}
      justify="space-between"
      alignItems="center"
      style={{ marginTop: 16 }}
    >
      <Grid item={true} xs={5}>
        <Grid container={true} alignItems="center">
          <InputLabel shrink={true}>Link Color</InputLabel>
          <Paper elevation={0}>
            <ColorPicker
              color={data.linkColor}
              fieldName={'linkColor'}
              onChange={handleChangeField}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid item={true} xs={1}>
        <Grid container={true} justify="center">
          <div style={{ width: 1, height: 30, backgroundColor: '#000' }} />
        </Grid>
      </Grid>
      <Grid item={true} xs={5}>
        <Grid container={true} alignItems="center">
          <InputLabel shrink={true}>
            Background
            <br />
            Color
          </InputLabel>
          <Paper elevation={0}>
            <ColorPicker
              color={data.backgroundColor}
              fieldName={'backgroundColor'}
              onChange={handleChangeField}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid
        item={true}
        xs={6}
        style={{ marginTop: '16px', padding: '0 20px 0 0' }}
      >
        <InputLabel style={{ marginBottom: 10 }} shrink={true}>
          Menu height -{' '}
          {data.styling && data.styling.height ? data.styling.height : 'auto'}
        </InputLabel>
        <Grid container={true} justify="space-between">
          <Slider
            // className={styles.slider}
            value={
              data.styling && data.styling.height
                ? parseInt(data.styling.height, 10)
                : null
            }
            aria-labelledby="slider-label"
            step={1}
            min={0}
            max={200}
            onChange={(e: any, value: number) =>
              handleChangeStylingFieldUpdate(value, 'height')
            }
            onChangeCommitted={handleChangeStylingFieldSave}
          />
        </Grid>
      </Grid>
      <Grid
        item={true}
        xs={6}
        style={{ marginTop: '16px', padding: '0 20px 0 0' }}
      >
        <InputLabel style={{ marginBottom: 10 }} shrink={true}>
          Font size -{' '}
          {data.styling && data.styling.fontSize
            ? data.styling.fontSize
            : 'default'}
        </InputLabel>
        <Grid container={true} justify="space-between">
          <Slider
            // className={styles.slider}
            value={
              data.styling && data.styling.fontSize
                ? parseInt(data.styling.fontSize, 10)
                : 14
            }
            aria-labelledby="slider-label"
            step={1}
            min={0}
            max={100}
            onChange={(e: any, value: number) =>
              handleChangeStylingFieldUpdate(value, 'fontSize')
            }
            onChangeCommitted={handleChangeStylingFieldSave}
          />
        </Grid>
      </Grid>
      <Grid item={true} xs={12} style={{ marginTop: '16px' }}>
        <SelectAlignment
          fields={data}
          showSpaceBetween={true}
          handleChangeField={handleChangeField}
        />
        <VerticalAlign
          fields={data}
          fieldName={'verticalAlign'}
          handleChangeField={handleChangeVerticalAlign}
        />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(EditMenuSettings)
