import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import Input from '../../../common/Input';

class Option extends Component {
  render() {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Grid container direction="row" justify="space-between">
            <Grid item xs={7} sm={7} md={5} lg={5} xl={5}>
              <Input
                label="Option"
                placeholder="Enter Option name"
                minWidth="100%"
              />
            </Grid>
            <Grid item xs={2} sm={2} md={3} lg={3} xl={3}>
              <Input label="Price" placeholder="0" minWidth="100%" />
            </Grid>
            <Grid item xs={2} sm={2} md={3} lg={3} xl={3}>
              <Input label="SKU" placeholder="0" minWidth="100%" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justify="space-between">
            <Grid item xs={7} sm={7} md={5} lg={5} xl={5}>
              <Input
                label="Option"
                placeholder="Enter Option name"
                minWidth="100%"
              />
            </Grid>
            <Grid item xs={2} sm={2} md={3} lg={3} xl={3}>
              <Input label="Price" placeholder="0" minWidth="100%" />
            </Grid>
            <Grid item xs={2} sm={2} md={3} lg={3} xl={3}>
              <Input label="SKU" placeholder="0" minWidth="100%" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button color="primary" variant="text" style={{ fontWeight: '400' }}>
            Add option
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default Option;
