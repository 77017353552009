import React, { Component } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Grid } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';

import Auth from '@aws-amplify/auth';

var jwtDecode = require('jwt-decode');

export const drawerWidth = 200;

const styles = theme => ({
  root: {
    width: 'auto'
  },
  linkText: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: 'inherit'
  },
  logo: {
    maxWidth: 150,
    height: '35px',
    marginTop: '24px',
    alignSelf: 'center'
  },
  searchIcon: {
    width: 45,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white'
  },
  inputRoot: {
    color: 'white',
    width: '100%'
  },
  menuButton: {
    marginRight: 20,
    color: '#000000',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  }
});
const Button = props => (
  <MuiButton
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          display: 'block',
          texAlign: 'center',

          borderRadius: isCurrent ? '0px' : '0px',
          fontWeight: isCurrent ? 400 : 300,
          borderBottom: isCurrent
            ? '2px solid #B23566'
            : '2px solid transparent'
        }
      };
    }}
  />
);
class TeamstoreMenu extends Component {
  render() {
    const { classes, teamstoreId, shopId } = this.props;

    var menuContent = (
      <Grid
        item
        style={{
          background: '#ffffff',
          borderRadius: '20px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Grid container direction="row">
          <Button
            classes={{ primary: classes.linkContainer }}
            component={Link}
            to={
              '/admin/' +
              shopId +
              '/saleschannels/teamstores/' +
              teamstoreId +
              '/general'
            }
          >
            General
          </Button>
          <Button
            classes={{ primary: classes.linkContainer }}
            component={Link}
            to={
              '/admin/' +
              shopId +
              '/saleschannels/teamstores/' +
              teamstoreId +
              '/products'
            }
          >
            Products
          </Button>
          <Button
            classes={{ primary: classes.linkContainer }}
            component={Link}
            to={
              '/admin/' +
              shopId +
              '/saleschannels/teamstores/' +
              teamstoreId +
              '/structure'
            }
          >
            Structure
          </Button>
          <Button
            classes={{ primary: classes.linkContainer }}
            component={Link}
            to={
              '/admin/' +
              shopId +
              '/saleschannels/teamstores/' +
              teamstoreId +
              '/personalization'
            }
          >
            Personalization
          </Button>
        </Grid>
      </Grid>
    );
    return <div className={classes.root}>{menuContent}</div>;
  }
}
TeamstoreMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(TeamstoreMenu);
