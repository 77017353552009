import { FONTS } from '../../../content/config'

export const reservedSlugs = [
  'cart',
  'checkout',
  'product',
  'collection',
  'teamstore',
  'Cart',
  'Teamstore',
  'Product',
  'Collection',
  'Checkout',
]

export const PRODUCT_CARD_VARIANTS = [
  'Default',
  'Flat',
  'Simple',
  'Elevated',
  'Modern',
]

export const CONTAINER_PRESETS = [
  {
    label: 'Full width',
    value: '100%',
  },
  {
    label: 'Centralized',
    value: '1000px',
  },
]

export const categories = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Banner',
    value: 'banner',
  },
  {
    label: 'Image',
    value: 'image',
  },
  {
    label: 'Collection',
    value: 'collection',
  },
  {
    label: 'Product',
    value: 'product',
  },
  {
    label: 'Teamstore',
    value: 'teamstore',
  },
  {
    label: 'Maps',
    value: 'maps',
  },
  {
    label: 'Form',
    value: 'form',
  },
]

export const tabs = {
  FORM: {
    numberOfTabs: 1,
    items: {
      0: {
        label: 'Form',
        fields: ['formTitle'],
      },
      1: {
        label: 'Hubspot',
        fields: ['cssClass', 'formId', 'portalId', 'target'],
      },
    },
  },
  IMAGE: {
    numberOfTabs: 4,
    items: {
      0: {
        label: 'Image',
        fields: [
          'contentImage',
          'imageAlign',
          'copyrightContent',
          'copyrightFontColor',
          'width',
          'maxHeight',
          'objectFit',
          'objectPosition',
          'padding',
        ],
      },
      1: {
        label: 'Text',
        fields: [
          'imageTitle',
          'titleAlign',
          'titleColor',
          'imageSubTitle',
          'subTitleAlign',
          'subTitleColor',
        ],
      },
      2: {
        label: 'Button',
        fields: [
          'imageButton',
          'buttonAlign',
          'buttonColor',
          'buttonVariant',
          'buttonBackgroundColor',
          'buttonBorderColor',
        ],
      },
      3: {
        label: 'URL',
        fields: ['imageButtonUrl'],
      },
    },
  },
  BUTTON: {
    numberOfTabs: 3,
    items: {
      0: {
        label: 'Button',
        fields: ['align', 'padding', 'backgroundColor'],
      },
      1: {
        label: 'Text',
        fields: [
          'buttonText',
          'fontFamily',
          'fontSize',
          'color',
          'fontWeight',
          'textTransform',
        ],
      },
      2: {
        label: 'URL',
        fields: ['buttonUrl'],
      },
    },
  },
  PRODUCT: {
    numberOfTabs: 2,
    items: {
      0: { label: 'Products', fields: ['productIds'] },
      1: { label: 'Text', fields: ['productTitle', 'fontFamily'] },
      2: { label: 'Style', fields: ['variant'] },
    },
  },
  COLLECTION: {
    numberOfTabs: 3,
    items: {
      0: { label: 'Collections', fields: ['collectionIds'] },
      1: { label: 'Text', fields: ['collectionTitle', 'fontFamily'] },
      2: { label: 'Button', fields: ['buttonText', 'buttonAlign'] },
    },
  },
  TEAMSTORE: {
    numberOfTabs: 2,
    items: {
      0: { label: 'Teamstores', fields: ['teamstoreIds'] },
      1: { label: 'Title', fields: ['teamstoreTitle'] },
    },
  },
  MAPS: {
    numberOfTabs: 1,
    items: {
      0: { label: 'Location', fields: ['place'] },
    },
  },
}

export const UI = {
  FIELD: [
    {
      name: 'backgroundColor',
      label: 'Background Color',
      UIType: 'color-picker',
      level: 'root',
      helperText: 'Set the background color of the entire row',
    },
  ],
  MAPS: {
    place: {
      label: 'Search place',
      UIType: 'maps-input',
      level: 'root',
      helperText: null,
    },
  },
  IMAGE: {
    contentImage: {
      label: 'Upload Image',
      UIType: 'upload',
      level: 'root',
      helperText: null,
    },
    imageAlign: {
      label: 'Align image',
      UIType: 'select',
      level: 'root',
      options: ['', 'left', 'center', 'right'],
      helperText: null,
    },
    padding: {
      label: 'Element padding',
      UIType: 'input',
      level: 'root',
      helperText: null,
    },
    imageTitle: {
      label: 'Title text (optional)',
      UIType: 'multilang-text',
      level: 'root',
      helperText: null,
    },
    titleAlign: {
      label: 'Align title',
      UIType: 'alignment',
      level: 'style',
      helperText: null,
    },
    imageSubTitle: {
      label: 'Sub-title text (optional)',
      UIType: 'multilang-text',
      level: 'root',
      helperText: null,
    },
    subTitleAlign: {
      label: 'Align sub-title',
      UIType: 'alignment',
      level: 'style',
      helperText: null,
    },
    imageButton: {
      label: 'Button text (optional)',
      UIType: 'multilang-text',
      level: 'root',
      helperText: null,
    },
    buttonAlign: {
      label: 'Align button',
      UIType: 'alignment',
      level: 'style',
      helperText: null,
    },
    imageButtonUrl: {
      label: 'Button url (optional)',
      UIType: 'url-select',
      level: 'root',
      helperText:
        'Select a link from Products, Collections, Teamstores or add your own in the Input below',
    },
    buttonColor: {
      label: 'Button text color',
      UIType: 'color-picker',
      level: 'style',
      helperText: 'Set the color of the button text',
    },
    titleColor: {
      label: 'Title color',
      UIType: 'color-picker',
      level: 'style',
      helperText: 'Set the color of the title',
    },
    subTitleColor: {
      label: 'Sub-title color',
      UIType: 'color-picker',
      level: 'style',
      helperText: 'Set the color of the sub-title',
    },
    buttonVariant: {
      label: 'Button variant',
      UIType: 'select',
      level: 'style',
      options: ['outlined', 'contained', 'link'],
      helperText: {
        type: 'Button',
        fields: [
          {
            variant: 'contained',
            style: 'regular',
            label: 'Contained',
          },
          {
            variant: 'outlined',
            style: 'regular',
            label: 'Outlined',
          },
          {
            variant: 'text',
            style: 'text',
            label: 'Link',
          },
          {
            variant: 'contained',
            style: 'rounded',
            label: 'Rounded',
          },
          {
            variant: 'outlined',
            style: 'rounded',
            label: 'Outlined',
          },
        ],
      },
    },
    buttonBackgroundColor: {
      label: 'Button background color',
      UIType: 'color-picker',
      level: 'style',
      helperText: 'Set the color of the button background',
    },
    buttonBorderColor: {
      label: 'Button border color',
      UIType: 'color-picker',
      level: 'style',
      helperText: 'Set the color of the button border',
    },
    copyrightContent: {
      label: 'Copyright text',
      UIType: 'multilang-text',
      level: 'style',
      helperText: null,
    },
    copyrightFontColor: {
      label: 'Copyright font color',
      UIType: 'color-picker',
      level: 'style',
      helperText: null,
    },
    width: {
      label: 'Width',
      UIType: 'slider',
      level: 'style',
      range: [1, 1000],
      suffix: 'px',
      helperText: null,
    },
    // height: {
    //   label: 'Height',
    //   UIType: 'slider',
    //   level: 'style',
    //   range: [1, 100],
    //   suffix: '%',
    //   helperText: null
    // },
    // maxWidth: {
    //   label: 'Max Width',
    //   UIType: 'slider',
    //   level: 'style',
    //   helperText: null,
    //   range: [1, 100],
    //   suffix: '%'
    // },
    maxHeight: {
      label: 'Max Height',
      UIType: 'slider',
      level: 'style',
      default: '400px',
      helperText: null,
      range: [1, 1000],
      suffix: 'px',
    },
    objectFit: {
      label: 'Object Fit',
      UIType: 'select',
      level: 'style',
      options: ['fill', 'contain', 'cover', 'scale-down', 'none'],
      helperText: {
        type: 'Detailed',
        fields: [
          {
            label: 'Contain',
            description: `The image will be scaled to keep it's aspect ratio and still fit insde the container.
            If the image aspect ratio doesn't match the aspect ratio of the container the image will use blank spaces to fill the container.
          `,
          },
          {
            label: 'Cover',
            description: `The image will be sized to keep it's aspect ratio and still fit inside the container.
            If the image aspect ratio doesn't match the aspect ratio of the container, the image will be clipped to fit.
          `,
          },
          {
            label: 'Fill',
            description: `The image will be sized to fill the container. The entire image will completely fill the container but if the aspect ratio does not match, the image will be stretched to fit.`,
          },
          {
            label: 'None',
            description: `The image will not be resized.`,
          },
          {
            label: 'Scale-Down',
            description: `The image is sized as if none or contain is selected, whichever would result with a smaller image size.`,
          },
        ],
      },
    },
    objectPosition: {
      label: 'Image Position',
      UIType: 'slider',
      level: 'style',
      range: [1, 100],
      suffix: '%',
      helperText: {
        type: 'Detailed',
        fields: [
          {
            label: 'Image Position',
            description: `Moves the image on the Y axis (up or down)
          `,
          },
        ],
      },
    },
  },
  FORM: {
    formTitle: {
      label: 'Form title',
      UIType: 'multilang-text',
      level: 'root',
      helperText: null,
    },
    cssClass: {
      label: 'cssClass',
      UIType: 'input',
      level: 'root',
      helperText: null,
    },
    formId: {
      label: 'formId',
      UIType: 'input',
      level: 'root',
      helperText: null,
    },
    portalId: {
      label: 'portalId',
      UIType: 'input',
      level: 'root',
      helperText: null,
    },
    target: {
      label: 'target',
      UIType: 'input',
      level: 'root',
      helperText: null,
    },
  },
  BUTTON: {
    variant: {
      label: 'Button variant',
      UIType: 'select',
      level: 'style',
      options: ['outlined', 'contained', 'link'],
      helperText: {
        type: 'Button',
        fields: [
          {
            variant: 'contained',
            style: 'regular',
            label: 'Contained',
          },
          {
            variant: 'outlined',
            style: 'regular',
            label: 'Outlined',
          },
          {
            variant: 'text',
            style: 'text',
            label: 'Text',
          },
          {
            variant: 'contained',
            style: 'rounded',
            label: 'Rounded',
          },
          {
            variant: 'outlined',
            style: 'rounded',
            label: 'Rounded Outlined',
          },
        ],
      },
    },
    buttonText: {
      label: 'Button text',
      UIType: 'multilang-text',
      level: 'root',
      helperText: null,
    },
    buttonUrl: {
      label: 'Button url',
      UIType: 'url-select',
      level: 'root',
      helperText:
        'Select a link from Products, Collections, Teamstores or add your own in the Input below',
    },
    align: {
      label: 'Align button',
      UIType: 'alignment',
      level: 'style',
      helperText: null,
    },
    fontFamily: {
      label: 'Font Family',
      UIType: 'input',
      level: 'style',
      helperText: null,
    },
    padding: {
      label: 'Padding',
      UIType: 'input',
      level: 'style',
      helperText:
        'Control the width and height of the button, the first value is height, second is width',
    },
    fontSize: {
      label: 'Font size',
      UIType: 'slider',
      level: 'style',
      helperText: null,
      range: [1, 100],
      suffix: 'px',
    },
    color: {
      label: 'Text color',
      UIType: 'color-picker',
      level: 'style',
      helperText: 'Set the color of the button text',
    },
    fontWeight: {
      label: 'Font weight',
      UIType: 'select',
      level: 'style',
      options: ['300', '400', '500', '600', '700'],
      helperText:
        'Set the boldness of the font. (The higher the number the thicker the font)',
    },
    textTransform: {
      label: 'Text transform',
      UIType: 'select',
      level: 'style',
      options: ['none', 'capitalize', 'uppercase', 'lowercase'],
      helperText: 'Set the style of the button text ( default: none )',
    },
    backgroundColor: {
      label: 'Background color',
      UIType: 'color-picker',
      level: 'style',
      helperText: 'Set the color of the button background',
    },
  },
  PRODUCT: {
    variant: {
      label: 'Card variant',
      UIType: 'select',
      level: 'root',
      options: PRODUCT_CARD_VARIANTS,
      helperText: `Set the look and feel of the product card`,
    },
    productIds: {
      label: 'Select product(s)',
      UIType: 'multi-select',
      level: 'root',
      helperText: null,
    },
    productTitle: {
      label: 'Product grid title',
      UIType: 'multilang-text',
      level: 'root',
      helperText: null,
    },
    fontFamily: {
      label: 'Font Family',
      UIType: 'select',
      level: 'style',
      options: FONTS,
      helperText: null,
    },
    buttonAlign: {
      label: 'Align button',
      UIType: 'alignment',
      level: 'style',
      helperText: null,
    },
  },
  COLLECTION: {
    buttonAlign: {
      label: 'Align button',
      UIType: 'alignment',
      level: 'style',
      helperText: null,
    },
    buttonText: {
      label: 'Button text',
      UIType: 'multilang-text',
      level: 'root',
      helperText: null,
    },
    collectionIds: {
      label: 'Select collection(s)',
      UIType: 'multi-select',
      level: 'root',
      helperText: null,
    },
    collectionTitle: {
      label: 'Collection grid title',
      UIType: 'multilang-text',
      level: 'root',
      helperText: null,
    },
    fontFamily: {
      label: 'Font Family',
      UIType: 'select',
      level: 'style',
      options: FONTS,
      helperText: null,
    },
  },
  TEAMSTORE: {
    teamstoreIds: {
      label: 'Select teamstore(s)',
      UIType: 'multi-select',
      level: 'root',
      helperText: null,
    },
    teamstoreTitle: {
      label: 'Teamstore grid title',
      UIType: 'multilang-text',
      level: 'root',
      helperText: null,
    },
  },
}

export const updateControls = [
  {
    type: 'FIELD',
    fields: [
      {
        name: 'backgroundColor',
        label: 'Background Color',
        UIType: 'color-picker',
        level: 'root',
        helperText: 'Set the background color of the entire row',
      },
      {
        name: 'maxWidth',
        label: 'Max Width',
        UIType: 'input',
        level: 'root',
        helperText: {
          type: 'Detailed',
          fields: [
            {
              label: 'Max Width',
              description: `The max-width property defines the maximum width of an element.
If the content is larger than the maximum width, it will automatically change the height of the element.
If the content is smaller than the maximum width, the max-width property has no effect.
          `,
            },
            {
              label: 'Pixels',
              description: `Pixels are absolute units, which are not defined by the size of the screen on which your website is being viewed. An element with a width of 600px will render at that width no matter on what screen you are viewing it.
          `,
            },
            {
              label: 'Percentage',
              description: `Percentages are a relative unit, which is defined by the size of the screen on which your website is being viewed. Example: If you set an element’s width to 50% it’s width and you view it on a screen that has a size of 1,920x1,080 PX it’s width will be rendered at 960 PX (That’s 50% of 1,920). Logically, if you view the website on a screen with a size of 1024x768 PX the element’s width will be rendered at 512px.
          `,
            },
            {
              label: 'Banner example',
              description: `Normally banners have a size of 1600 x 500 pixels so you can maintain a 16:9 ratio as a good rule of thumb. `,
            },
          ],
        },
      },
      {
        name: 'padding',
        label: 'Padding',
        UIType: 'padding-input',
        level: 'root',
        helperText:
          'Add spacing around the row, first number is top and bottom, second is left and right. E.g.: 20px 10px',
      },
      {
        name: 'justify',
        label: 'Justify (Align columns/items inside section)',
        UIType: 'select',
        level: 'style',
        options: [
          '',
          'flex-start',
          'center',
          'flex-end',
          'space-between',
          'space-around',
          'space-evenly',
        ],
        // helperText: {
        //   type: 'Button',
        //   fields: [
        //     {
        //       variant: 'contained',
        //       style: 'regular',
        //       label: 'Contained'
        //     },
        //     {
        //       variant: 'outlined',
        //       style: 'regular',
        //       label: 'Outlined'
        //     },
        //     {
        //       variant: 'text',
        //       style: 'text',
        //       label: 'Link'
        //     },
        //     {
        //       variant: 'contained',
        //       style: 'rounded',
        //       label: 'Rounded'
        //     },
        //     {
        //       variant: 'outlined',
        //       style: 'rounded',
        //       label: 'Outlined'
        //     }
        //   ]
        // }
      },
      {
        name: 'alignItems',
        label: 'Vertical Alignment of items/columns',
        UIType: 'select',
        level: 'style',
        options: [
          '',
          'flex-start',
          'center',
          'flex-end',
          'stretch',
          'baseline',
        ],
        // helperText: {
        //   type: 'Button',
        //   fields: [
        //     {
        //       variant: 'contained',
        //       style: 'regular',
        //       label: 'Contained'
        //     },
        //     {
        //       variant: 'outlined',
        //       style: 'regular',
        //       label: 'Outlined'
        //     },
        //     {
        //       variant: 'text',
        //       style: 'text',
        //       label: 'Link'
        //     },
        //     {
        //       variant: 'contained',
        //       style: 'rounded',
        //       label: 'Rounded'
        //     },
        //     {
        //       variant: 'outlined',
        //       style: 'rounded',
        //       label: 'Outlined'
        //     }
        //   ]
        // }
      },
      {
        name: 'maxWidth',
        label: 'Presets',
        UIType: 'container-preset',
        level: 'root',
        helperText: {
          type: 'Detailed',
          fields: [
            {
              label: 'Full Width',
              description: `Sets the container to 100%`,
            },
            {
              label: 'Centralized',
              description: `Sets the container to 1000px`,
            },
          ],
        },
      },
      {
        name: 'header',
        label: 'Header',
        UIType: 'multilang-text',
        level: 'root',
        helperText: null,
      },
    ],
  },
]

export const elements = [
  {
    id: 1,
    name: 'image_with_text_2col',
    label: 'Image with text',
    categories: ['image'],
    columns: 2,
    data: {
      contentView: [
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 1,
          sortOrder: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 2,
        },
        {
          type: 'BUTTON',
          buttonText: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Learn more',
              },
            ],
          },
          buttonUrl: '/products',
          sortOrder: 3,
          column: 2,
        },
      ],
    },
  },
  {
    id: 2,
    name: 'banner',
    label: 'Banner with title and button',
    categories: ['banner'],
    columns: 1,
    data: {
      contentView: [
        {
          type: 'IMAGE',
          contentImage:
            'https://dev-cdn-media-brikl.imgix.net/patrik/media/banner-1050629_1920-s5BKuAFj.jpg',
          column: 1,
          sortOrder: 1,
          alignContent: 'center',
          imageAlign: 'center',
          imageTitle: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Welcome to the store',
              },
            ],
          },
          imageButton: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Explore more',
              },
            ],
          },
          imageButtonUrl: '/products',
        },
      ],
    },
  },
  {
    id: 3,
    name: 'image_with_text_2col_mirrored',
    label: 'Image with text (mirrored)',
    categories: ['image'],
    columns: 2,
    data: {
      contentView: [
        {
          type: 'TEXT',
          singleLine: false,
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 1,
          column: 1,
        },
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 2,
          sortOrder: 2,
        },
      ],
    },
  },
  {
    id: 4,
    name: 'paragraph_with_title',
    label: 'Paragraph with title (center)',
    categories: ['text'],
    columns: 1,
    data: {
      contentView: [
        {
          type: 'TEXT',
          singleLine: false,
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 1,
          column: 1,
        },
      ],
    },
  },
  {
    id: 5,
    name: 'product_with_description',
    label: 'Product with description',
    categories: ['product'],
    displayTitle: false,
    columns: 2,
    data: {
      contentView: [
        {
          type: 'PRODUCT',
          column: 1,
          sortOrder: 1,
          variant: 'Default',
          productIds: ['dc6cda8e-0828-4fbf-8ea8-c5231d05bf40'],
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 2,
        },
        {
          type: 'BUTTON',
          buttonText: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Learn more',
              },
            ],
          },
          buttonUrl: null,
          sortOrder: 3,
          column: 2,
        },
      ],
    },
  },
  {
    id: 6,
    name: 'collection_grid',
    label: 'Collection (4x4)',
    categories: ['collection'],
    columns: 1,
    cardsPerRow: 4,
    data: {
      contentView: [
        {
          type: 'COLLECTION',
          column: 1,
          sortOrder: 1,
          displayTitle: true,
          collectionTitle: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Title',
              },
            ],
          },
          collectionIds: ['1', '2', '3', '4'],
        },
      ],
    },
  },
  {
    id: 7,
    name: 'single_image',
    label: 'Image',
    categories: ['banner', 'image'],
    columns: 1,
    data: {
      contentView: [
        {
          type: 'IMAGE',
          contentImage:
            'https://dev-cdn-media-brikl.imgix.net/patrik/media/banner-1050629_1920-s5BKuAFj.jpg',
          column: 1,
          sortOrder: 1,
        },
      ],
    },
  },
  {
    id: 8,
    name: 'product_grid (4x4)',
    label: 'Products',
    categories: ['product'],
    columns: 1,
    cardsPerRow: 4,
    data: {
      contentView: [
        {
          type: 'PRODUCT',
          column: 1,
          sortOrder: 1,
          displayTitle: true,
          variant: 'Default',
          productTitle: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Title',
              },
            ],
          },
          productIds: ['1', '2', '3', '4'],
        },
      ],
    },
  },
  {
    id: 9,
    name: 'collection_with_description',
    label: 'Collection with description',
    categories: ['collection'],
    displayTitle: false,
    columns: 2,
    data: {
      contentView: [
        {
          type: 'COLLECTION',
          column: 1,
          sortOrder: 1,
          collectionIds: ['1'],
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 2,
        },
      ],
    },
  },
  {
    id: 10,
    name: '3 col text',
    label: 'Text split to 3 cols',
    categories: ['text'],
    columns: 3,
    data: {
      contentView: [
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 1,
          column: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 2,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 3,
          column: 3,
        },
      ],
    },
  },
  {
    id: 11,
    name: '2 col text',
    label: 'Text split to 2 cols',
    categories: ['text'],
    columns: 2,
    data: {
      contentView: [
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 1,
          column: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 2,
        },
      ],
    },
  },
  {
    id: 12,
    name: 'maps',
    label: 'Maps with text',
    categories: ['maps'],
    columns: 2,
    data: {
      contentView: [
        {
          type: 'MAPS',
          latitude: 38.685516,
          longitude: -101.073324,
          place: null,
          sortOrder: 1,
          column: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          column: 2,
          sortOrder: 1,
        },
      ],
    },
  },
  {
    id: 13,
    name: 'maps',
    label: 'Default Maps Element',
    categories: ['maps'],
    columns: 1,
    data: {
      contentView: [
        {
          type: 'MAPS',
          latitude: 38.685516,
          longitude: -101.073324,
          place: null,
          sortOrder: 1,
          column: 1,
        },
      ],
    },
  },
  {
    id: 14,
    name: 'banner',
    label: 'Banner with title',
    categories: ['banner'],
    columns: 1,
    data: {
      contentView: [
        {
          type: 'IMAGE',
          contentImage:
            'https://dev-cdn-media-brikl.imgix.net/patrik/media/banner-1050629_1920-s5BKuAFj.jpg',
          column: 1,
          sortOrder: 1,
          alignContent: 'center',
          imageAlign: 'center',
          imageTitle: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Welcome to the store',
              },
            ],
          },
        },
      ],
    },
  },
  {
    id: 15,
    name: 'images',
    label: '2 images',
    categories: ['image'],
    columns: 2,
    data: {
      contentView: [
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 1,
          sortOrder: 1,
          alignContent: 'center',
        },
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 2,
          sortOrder: 1,
          alignContent: 'center',
        },
      ],
    },
  },
  {
    id: 16,
    name: 'images',
    label: '3 images',
    categories: ['image'],
    columns: 3,
    data: {
      contentView: [
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 1,
          sortOrder: 1,
          alignContent: 'center',
        },
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 2,
          sortOrder: 1,
          alignContent: 'center',
        },
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 3,
          sortOrder: 1,
          alignContent: 'center',
        },
      ],
    },
  },
  {
    id: 17,
    name: 'contact_form',
    label: 'Contact Form',
    categories: ['form'],
    columns: 1,
    data: {
      contentView: [
        {
          type: 'FORM',
          column: 1,
          sortOrder: 1,
          formVariant: 'CONTACT',
          formTitle: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Contact Us',
              },
            ],
          },
        },
      ],
    },
  },
  {
    id: 18,
    name: 'hubspot_form',
    label: 'Hubspot Form',
    categories: ['form'],
    columns: 1,
    data: {
      contentView: [
        {
          type: 'FORM',
          column: 1,
          sortOrder: 1,
          formVariant: 'HUBSPOT',
          cssClass: 'hubspot-btn',
          target: 'hubspotForm',
          formTitle: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Contact Us',
              },
            ],
          },
        },
      ],
    },
  },
  {
    id: 19,
    name: 'quote_form',
    label: 'Request Quotation Form',
    categories: ['form'],
    columns: 1,
    data: {
      contentView: [
        {
          type: 'FORM',
          column: 1,
          sortOrder: 1,
          formVariant: 'QUOTE',
          formTitle: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Contact Us',
              },
            ],
          },
        },
      ],
    },
  },
  {
    id: 20,
    name: 'teamstore_cards',
    label: 'Teamstore Cards',
    categories: ['teamstore'],
    columns: 1,
    data: {
      contentView: [
        {
          type: 'TEAMSTORE',
          column: 1,
          sortOrder: 1,
          teamstoreIds: ['1', '2', '3', '4'],
          teamstoreTitle: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Teamstores',
              },
            ],
          },
        },
      ],
    },
  },
  {
    id: 21,
    name: 'banner',
    label: 'Banner with title and button',
    categories: ['banner'],
    columns: 2,
    data: {
      contentView: [
        {
          type: 'IMAGE',
          contentImage:
            'https://dev-cdn-media-brikl.imgix.net/patrik/media/banner-1050629_1920-s5BKuAFj.jpg',
          column: 1,
          sortOrder: 1,
          alignContent: 'center',
          imageButton: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Explore more',
              },
            ],
          },
          imageButtonUrl: '/',
        },
        {
          type: 'IMAGE',
          contentImage:
            'https://dev-cdn-media-brikl.imgix.net/patrik/media/banner-1050629_1920-s5BKuAFj.jpg',
          column: 2,
          sortOrder: 1,
          alignContent: 'center',
          imageButton: {
            text: [
              {
                langCode: 'en_UK',
                content: 'Explore more',
              },
            ],
          },
          imageButtonUrl: '/',
        },
      ],
    },
  },
  {
    id: 22,
    name: 'image_with_text_2col_mirrored',
    label: 'Image with text',
    categories: ['image'],
    columns: 2,
    data: {
      contentView: [
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 1,
          sortOrder: 1,
        },
        {
          type: 'TEXT',
          singleLine: false,
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 1,
          column: 2,
        },
      ],
    },
  },
  {
    id: 23,
    name: '5 col text',
    label: 'Text split to 5 cols',
    categories: ['text'],
    columns: 5,
    data: {
      contentView: [
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 1,
          column: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 2,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 3,
          column: 3,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 3,
          column: 4,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 3,
          column: 5,
        },
      ],
    },
  },
  {
    id: 24,
    name: 'image_with_text_6col',
    label: 'Image with text (3 items)',
    categories: ['image'],
    columns: 6,
    data: {
      contentView: [
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 1,
          sortOrder: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 2,
        },
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 3,
          sortOrder: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 4,
        },
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 5,
          sortOrder: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 6,
        },
      ],
    },
  },
  {
    id: 24,
    name: 'image_with_text_4col_vert',
    label: 'Image with text (4 items)',
    categories: ['image'],
    columns: 4,
    data: {
      contentView: [
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 1,
          sortOrder: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 1,
        },
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 2,
          sortOrder: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 2,
        },
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 3,
          sortOrder: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 3,
        },
        {
          type: 'IMAGE',
          contentImage: null,
          imageAlign: null,
          column: 4,
          sortOrder: 1,
        },
        {
          type: 'TEXT',
          contentText: {
            text: [
              {
                langCode: 'en_UK',
                content: `{"blocks":[{"key":"9pqm5","text":"Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"57d73","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquet felis sed aliquet porta. Etiam a tellus ullamcorper, egestas tortor et, imperdiet augue. Suspendisse semper aliquam augue, vitae laoreet quam semper sed. Aenean vitae velit odio.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
              },
            ],
          },
          sortOrder: 2,
          column: 4,
        },
      ],
    },
  },
]
