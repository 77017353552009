import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  withStyles,
  createStyles, WithStyles, Grid, ListItem
} from '@material-ui/core'

import {getSocialLinkIcon} from '../../../helper/menuHelper'


const styles = () => createStyles({
  ColumnTitle: {
    color: '#fff',
  },
  imgFooterCard: {
    '&:hover': {
      color: '#fff'
    },
    color: '#bbb',
    marginRight: '5px'
  },
  list: {
    '&:hover': {
      color: '#fff'
    },
    color: '#bbb',
    fontWeight: 500
  }
})


interface Props extends WithStyles<typeof styles> {
  data: any[];
  linkColor?: string;
}


const SocialLinks: React.FunctionComponent<Props> = (props) => {
  const { classes, data, linkColor } = props

  return (
    <ListItem className={classes.list} style={{marginTop: '-8px'}} >
      <Grid container={true} direction="column">
        <Grid item={true} xs={12} style={{color: linkColor}}>FOLLOW US</Grid>
        <Grid item={true} xs={12}>
          <Grid
            container={true}
            direction="row"
            style={{ padding: '8px 0px' }}
            justify="flex-start"
            spacing={2}
          >
            {data ? data.map((item: any, index: number) => (
              <Grid item={true} key={index} md={3} style={{height: 32}}>
                <a
                  href={item.externalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{fontSize:'1.05rem', color: linkColor ? linkColor : '#fff' }}
                >
                  {getSocialLinkIcon(item.icon) ? (
                    <FontAwesomeIcon
                      className={classes.imgFooterCard}
                      style={{padding:0}}
                      icon={getSocialLinkIcon(item.icon)}
                    />
                  ) : null}
                </a>
              </Grid>
            )) : null}
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  )
}


export default withStyles(styles, { withTheme: true })(SocialLinks)
