import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  CircularProgress,
  Typography,
  Grid,
  WithStyles,
  withStyles,
  Button,
  createStyles
} from '@material-ui/core'

import GlobalStyles from '../../../globalStyles'
import { ADMIN_GET_LEADS } from '../../gql/admin-queries'

import LeadList from './LeadList'

const styles = () =>
  createStyles({
    cell: {},
    container: {
      padding: '8px 0px'
    }
  })

interface Props extends WithStyles<typeof styles> {
  shopId: string
  shop: any
}

const DesignRoomLeads: React.FunctionComponent<Props> = props => {
  const { shopId, shop } = props

  const { data, loading, error } = useQuery(ADMIN_GET_LEADS, {
    variables: { id: shopId }
  })

  // const [triggerDeployment, mutationResult] = useMutation(
  //   ADMIN_TRIGGER_DEPLOYMENT, {
  //     variables: {
  //       siteId: shop.netlifyConfiguration.siteId,
  //       title: 'Test'
  //     },
  //     refetchQueries: [{
  //       query: ADMIN_GET_DEPLOYMENTS,
  //       variables: {id: shopId}
  //     }]
  //   }
  // );

  return (
    <Grid container={true}>
      <Grid
        container={true}
        style={{ maxWidth: '1000px', margin: 'auto', padding: 24 }}
      >
        <GlobalStyles />

        <Grid item={true} xs={6} style={{ marginTop: 24 }}>
          <Typography variant="h1">Design Leads</Typography>
        </Grid>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <h2>{`Error: ${error?.message}`}</h2>
        ) : (
          <LeadList data={data.shop.leads.edges} shopId={shopId} />
        )}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(DesignRoomLeads)
