// import _cloneDeepWith from 'lodash/cloneDeepWith'
const omitDeep = require('omit-deep')

export const removeKeys = (collection: any, excludeKeys: string): any => {
  return omitDeep(collection, excludeKeys)
  // const newObj = _.cloneDeep(collection)
  // return Object.entries(newObj).reduce((nextInput, [key, value]) => {
  //     const shouldExclude = excludeKeys.includes(key)
  //     if (shouldExclude) return nextInput

  //     if (Array.isArray(value)) {
  //       const arrValue = value
  //       const nextValue = arrValue.map((arrItem) => {
  //         if (typeof arrItem === 'object') {
  //           return removeKeys(arrItem, excludeKeys)
  //         }
  //         return arrItem
  //       })
  //       nextInput[key] = nextValue
  //       return nextInput
  //     } else if (typeof value === 'object') {
  //       nextInput[key] = removeKeys(value, excludeKeys)
  //       return nextInput
  //     }

  //     nextInput[key] = value

  //     return nextInput
  //   }, {})
}
