import React, { Component } from 'react';
var loadingGIF_1 = require('../TEMPLATE_ASSET/loading1.gif');
var loadingGIF_2 = require('../TEMPLATE_ASSET/loading2.gif');
var loadingGIF_3 = require('../TEMPLATE_ASSET/loading3.gif');
var loadingGIF_4 = require('../TEMPLATE_ASSET/loading4.gif');
var loadingTAB = require('../TEMPLATE_ASSET/tab_loading.gif');
var bicycle = require('../TEMPLATE_ASSET/bicycle.gif');

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true
    };
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.Loading = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  activeLoading(option) {
    this.setState({ active: option });
  }

  render() {
    // PROGESS STYLE

    if (this.state.active === true) {
      var hideProgress = 'block';
    } else {
      var hideProgress = 'none';
    }

    const progressCss = {
      position: 'absolute',
      width: '100vw',
      height: 'calc(100vh)',
      background: '#0000009c',
      zIndex: 10001,
      textAlign: 'center',
      display: hideProgress
    };

    var loadIconCss = {
      width: '100px',
      position: 'absolute',
      margin: 'auto',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    };

    if (this.props.position && this.props.position === 'bottom-right') {
      loadIconCss.top = 'unset';
      loadIconCss.left = 'unset';
    }

    var iconLoad = loadingGIF_3; // DEFAULT

    if (this.props.loadStyle && this.props.loadStyle === 'bicycle') {
      iconLoad = bicycle;
    }

    // PROGESS STYLE

    return (
      <div className="loadingProgress" style={progressCss}>
        <div>
          <img src={iconLoad} style={loadIconCss} alt="Loading icon" />
        </div>
      </div>
    );
  }
}

export default Loading;
