import { I18n } from '@aws-amplify/core'

export const Localization = {
  get: function (key: keyof IVocabulary) {
    return I18n.get(key)
  },
}

export interface IVocabulary {
  payment_redirect_note: string
  size_chart: string
  shopping_cart: string
  invalid_cart: string
  invalid_partner_number: string
  parner_number_used: string
  product: string
  quantity: string
  register: string
  tell_us_who_you_are: string
  sport_club: string
  company: string
  private_person: string
  selected_specific: string
  enter_company_name: string
  company_information: string
  enter_sport_club_name: string
  vat_number: string
  example_number: string
  personal_information: string
  first_name: string
  first_name_placeholder: string
  last_name: string
  last_name_placeholder: string
  repeat_email: string
  activate_passport: string
  passport_number: string
  passport_card_number_placeholder: string
  sign_up: string
  price: string
  continue_shopping: string
  checkout_now: string
  total: string
  vat_included: string
  nothing_in_your_cart: string
  validate: string
  vermarc_cart_service_title: string
  vermarc_cart_service_description: string
  vermarc_cart_service_item_1: string
  vermarc_cart_service_item_2: string
  vermarc_cart_service_item_3: string
  vermarc_cart_service_item_4: string
  coupon_code: string
  delivery_prices: string
  bank_account_country: string
  payment_options: string
  select_payment_method: string
  belgium_and_netherlands: string
  benelux: string
  from: string
  within_the_eu: string
  outside_the_eu: string
  learn_more: string
  free: string
  email: string
  password: string
  confirm_password: string
  password_placeholder: string
  enter_email: string
  enter_password: string
  forgot_password: string
  reset_password: string
  password_reset_required: string
  log_in: string
  no_account_yet: string
  user_does_not_exist: string
  premium_dealers_title: string
  premium_dealers_subtitle: string
  premium_dealers_belgium: string
  premium_dealers_netherlands: string
  phone: string
  my_orders: string
  my_designs: string
  fabric: string
  color: string
  text: string
  logo: string
  label: string
  login: string
  care_label: string
  choose_your_fabric: string
  interlock: string
  mesh: string
  woven: string
  cotton: string
  fabric_overview: string
  group: string
  construction: string
  content: string
  weight: string
  measurements: string
  measurement_table: string
  help_center: string
  need_help: string
  enter_article_name: string
  my_cart: string
  style_number: string
  color_and_print_overview: string
  element: string
  print: string
  out_of_stock: string
  color_in_stock_for_this_fabric: string
  dyed_for_both_sides: string
  color_picker: string
  sublimated_one_side: string
  upload_your_artwork: string
  drag_a_file_here: string
  or: string
  select_from_computer: string
  scale: string
  rotate: string
  offset_x: string
  offset_y: string
  add_your_own_text: string
  enter_your_text: string
  text_overview: string
  add: string
  add_your_own_logo: string
  customize_care_label: string
  size_indication: string
  select_your_logo: string
  select_your_care_label: string
  left: string
  right: string
  side: string
  front: string
  three_quarter: string
  size_indication_note: string
  back: string
  upcharge: string
  no_upcharge: string
  select_printing_technique_to_see_color: string
  choose_from_our_colors: string
  remove: string
  position: string
  logo_overview: string
  printing_technique: string
  select_care_label_template: string
  shipping_price_not_included: string
  shipping_cost_calculated_next_step: string
  save_and_continue: string
  create_folder: string
  add_to_cart: string
  delete: string
  review: string
  need_sample: string
  total_piece: string
  collection: string
  season: string
  discount: string
  minimum_quantity_50: string
  submitted: string
  on_hold: string
  production: string
  shipped: string
  your_orders: string
  edit: string
  my_account: string
  phone_number: string
  default_currency: string
  default_language: string
  default_country: string
  vermarc_passport_number: string
  vermarc_passport_number_placeholder: string
  saved_addresses: string
  new_address: string
  save: string
  set_default_shipping: string
  set_default_billing: string
  street_or_building: string
  postcode: string
  city: string
  province: string
  country: string
  country_placeholder: string
  saved_designs: string
  teamwear_quality_lines: string
  es_line_description: string
  sp_line_description: string
  pr_line_description: string
  category_football: string
  category_cycling: string
  category_athletics: string
  filter_by: string
  order_now: string
  design_now: string
  size: string
  product_information: string
  collections: string
  cancel: string
  beCreative_add_to_cart_button: string
  beCreative_add_to_cart_confirm: string
  beCreative_cancel_button: string
  beCreative_reset_button: string
  beCreative_color_fill: string
  beCreative_color_a_zone: string
  beCreative_upload_button: string
  beCreative_upload_label: string
  beCreative_logo_list: string
  beCreative_place_logo: string
  beCreative_text_input_label: string
  beCreative_text_input_placeholder: string
  beCreative_add_text: string
  beCreative_select_fonts: string
  beCreative_transform_label: string
  beCreative_outline_size: string
  beCreative_line_spacing: string
  beCreative_color: string
  beCreative_font_color: string
  beCreative_outline_color: string
  beCreative_instruction_how_to_use: string
  beCreative_instruction_rotate360: string
  beCreative_instruction_rotate_mouse: string
  beCreative_instruction_rotate_touch: string
  beCreative_instruction_zoom: string
  beCreative_instruction_zoom_mouse: string
  beCreative_instruction_zoom_touch: string
  beCreative_instruction_move: string
  beCreative_instruction_move_mouse: string
  beCreative_instruction_move_touch: string
  beCreative_instruction_close_button: string
  beCreative_request_quote_address_club: string
  beCreative_request_quote_design_application: string
  beCreative_request_quote_type_of_products: string
  beCreative_request_quote_athletics: string
  beCreative_request_quote_leisure: string
  beCreative_request_quote_football: string
  beCreative_request_quote_triathlon: string
  beCreative_request_quote_cycling: string
  beCreative_request_quote_type_of_clothing: string
  beCreative_request_quote_jersey_short_sleeve: string
  beCreative_request_quote_jersey_long_sleeve: string
  beCreative_request_quote_mid_season_jacket: string
  beCreative_request_quote_technical_jacket: string
  beCreative_request_quote_windstopper: string
  beCreative_request_quote_rain_jacket: string
  beCreative_request_quote_bib_shorts: string
  beCreative_request_quote_tights: string
  beCreative_request_quote_quality_line: string
  beCreative_request_quote_dont_know_yet: string
  beCreative_request_quote_comments_questions: string
  send: string
  sent: string
  please_fill_all_fields: string
  beCreative_apply_change_text: string
  teamstores: string
  already_in_cart: string
  same_supplier_warning: string
  go_to_order: string
  select_delivery_mode: string
  pay_online_delivery_home: string
  pay_online_delivery_home_express: string
  pay_online_pickup: string
  pay_now: string
  your_card_information: string
  processing_payment: string
  select_address: string
  create_new_address: string
  shipping_address: string
  billing_address: string
  invalid_address: string
  incl_free: string
  cart_not_found: string
  no_payment_available_yet: string
  confirm_payment: string
  credit_card: string
  make_your_choice: string
  you_may_also_like: string
  personalization: string
  i_have_read_and_have_approved_the_privacy_policy: string
  shipping_cost_included: string
  shop_the_collection: string
  same_as_shipping: string
  invoice_summary: string
  sub_total: string
  shipping: string
  cookie_settings: string
  essential_cookies: string
  analytical_cookies: string
  marketing_cookies: string
  active: string
  always_active: string
  cookie_settings_description: string
  essential_cookies_description: string
  analytical_cookies_description: string
  marketing_cookies_description: string
  agree: string
  cookie_notice: string
  firstName_name: string
  lastName_name: string
  company_name: string
  email_name: string
  phone_name: string
  vatNumber_name: string
  address1_name: string
  zip_name: string
  city_name: string
  province_name: string
  countryCode_name: string
  firstName_placeholder: string
  lastName_placeholder: string
  company_placeholder: string
  email_placeholder: string
  phone_placeholder: string
  vatNumber_placeholder: string
  address1_placeholder: string
  zip_placeholder: string
  city_placeholder: string
  province_placeholder: string
  countryCode_placeholder: string
  message: string
}

export const en_UK: IVocabulary = {
  payment_redirect_note:
    'If you are not redirected automatically, please complete payment with the payment provider by clicking the following link:',
  size_chart: 'Size Chart',
  shopping_cart: 'Shopping basket',
  invalid_cart: 'Invalid basket',
  invalid_partner_number: 'Invalid partner number',
  parner_number_used: 'Partner number has been used already',
  product: 'Product',
  quantity: 'Quantity',
  register: 'Register',
  tell_us_who_you_are: 'Tell us who you are',
  sport_club: 'Sport Club',
  company: 'Company',
  private_person: 'Private person',
  selected_specific: `If you've selected sport club or company`,
  enter_company_name: 'Enter company name',
  company_information: 'Company information',
  enter_sport_club_name: 'Enter sport club name',
  vat_number: 'VAT Number',
  example_number: 'e.g. 1234567890',
  personal_information: 'Personal Information',
  first_name: 'First Name',
  first_name_placeholder: 'Your name',
  last_name: 'Last Name',
  last_name_placeholder: 'Your last name',
  repeat_email: 'Repeat your email',
  activate_passport: 'Activate your Benefits Passport:',
  passport_number: 'Loyalty passport number',
  passport_card_number_placeholder: 'Enter Card Number',
  sign_up: 'Sign Up',
  price: 'PRICE',
  continue_shopping: 'Continue Shopping',
  checkout_now: 'Checkout Now >',
  total: 'Total',
  vat_included: '% VAT included',
  nothing_in_your_cart: 'Your shopping basket is empty',
  validate: 'Validate',
  vermarc_cart_service_title: 'OUR SERVICE YOUR ADVANTAGE',
  vermarc_cart_service_description:
    'The ultimate test of sports professionals and the utmost care for our products, are the guarantee of quality of Vermarc. If you have any questions, feel free to fall back on our knowledge and experience. We will be pleased to help you. The vermarc guarantees:',
  vermarc_cart_service_item_1: 'Quality guarantee',
  vermarc_cart_service_item_2: 'Optimal customer service guarantee',
  vermarc_cart_service_item_3: 'Premium dealer network in Benelux',
  vermarc_cart_service_item_4:
    'Not satisfied, exchange guarantee or money back',
  coupon_code: 'Coupon code:',
  delivery_prices: 'DELIVERY PRICES',
  payment_options: 'SECURE PAYMENT',
  bank_account_country: 'Bank account country',
  select_payment_method: 'Select payment method',
  belgium_and_netherlands: 'Belgium, Netherlands and Luxembourg',
  benelux: 'Belgium, Netherlands and Luxembourg',
  from: 'from',
  within_the_eu: 'Within the EU',
  outside_the_eu: 'Outside the EU',
  learn_more: 'Learn more about our terms and conditions.',
  free: 'Free.',
  email: 'Email',
  password: 'Password',
  confirm_password: 'Confirm password',
  password_placeholder:
    'Min. 8 chars, 1 lowercase, 1 uppercase, 1 special char',
  enter_email: 'Enter your email address',
  enter_password: 'Enter your password',
  forgot_password: 'Forgot password',
  reset_password: 'Reset password',
  password_reset_required:
    'Your account requires a password reset. To reset click the button below.',
  log_in: 'LOG IN',
  no_account_yet: `You don't have an account yet? Sign up now!`,
  user_does_not_exist: 'User does not exist!',
  premium_dealers_title: 'PREMIUM DEALERS',
  premium_dealers_subtitle:
    'Vermarc Sport selects its distribution points carefully.',
  premium_dealers_belgium: 'Belgium',
  premium_dealers_netherlands: 'Netherlands',
  phone: 'Phone',
  my_orders: 'My Orders',
  my_designs: 'My Designs',
  fabric: 'Fabric',
  color: 'Color',
  text: 'Text',
  logo: 'Logo',
  label: 'Label',
  login: 'Login',
  care_label: 'Care label',
  choose_your_fabric: 'Choose your fabric',
  interlock: 'Interlock',
  mesh: 'Mesh',
  woven: 'Woven',
  cotton: 'Cotton',
  fabric_overview: 'Fabric overview',
  group: 'Group',
  construction: 'Construction',
  content: 'Content',
  weight: 'Weight',
  measurements: 'Measurements',
  measurement_table: 'Measurement table',
  help_center: 'Help Center',
  need_help: 'Need help?',
  enter_article_name: 'Enter article name',
  my_cart: 'My cart',
  style_number: 'Style number',
  color_and_print_overview: 'Color and print overview',
  element: 'Element',
  print: 'Print',
  color_in_stock_for_this_fabric: 'Color in stock for this fabric',
  out_of_stock: 'Out of stock',
  dyed_for_both_sides: 'Dyed both sides',
  color_picker: 'Color picker',
  sublimated_one_side: 'Sublimated one side',
  upload_your_artwork: 'Upload your artwork',
  drag_a_file_here: 'Drag a file here',
  or: 'Or',
  select_from_computer: 'Select from your computer',
  scale: 'Scale',
  rotate: 'Rotate',
  offset_x: 'Offset X',
  offset_y: 'Offset Y',
  add_your_own_text: 'Add your own text',
  enter_your_text: 'Enter your text',
  text_overview: 'Text overview',
  add: 'Add',
  add_your_own_logo: 'Add your own logo',
  customize_care_label: 'Customize your care label',
  size_indication: 'Size indication for illustration only',
  select_your_logo: 'Select your logo',
  select_your_care_label: 'Select your care label',
  left: 'Left',
  right: 'Right',
  side: 'Side',
  front: 'Front',
  three_quarter: '3/4',
  size_indication_note: 'Note: Size indication for illustration only',
  back: 'Back',
  upcharge: 'Upcharge',
  no_upcharge: 'No upcharge',
  select_printing_technique_to_see_color:
    'Select a printing technique to see available colors',
  choose_from_our_colors: 'Choose from our colors',
  remove: 'Remove',
  position: 'Position',
  logo_overview: 'Logo overview',
  printing_technique: 'Printing technique',
  select_care_label_template: 'Select a care label template',
  shipping_price_not_included: 'Shipping price not included',
  shipping_cost_calculated_next_step:
    'Shipping Price will be calculated in the next step',
  save_and_continue: 'Save & Continue',
  create_folder: 'Create folder',
  add_to_cart: 'Add to cart',
  delete: 'Delete',
  review: 'Review',
  need_sample: 'Need sample',
  total_piece: 'Total piece',
  collection: 'Collection',
  season: 'Season',
  discount: 'Discount',
  minimum_quantity_50: 'Cart list minimum quantity: 50 pieces',
  submitted: 'Submitted',
  on_hold: 'On Hold',
  production: 'Production',
  shipped: 'Shipped',
  your_orders: 'Your orders',
  edit: 'Edit',
  my_account: 'My Account',
  phone_number: 'Phone number',
  default_currency: 'Default currency',
  default_language: 'Default language',
  default_country: 'Default country',
  vermarc_passport_number: 'Vermarc Passport Number',
  vermarc_passport_number_placeholder: 'Enter your passport number',
  saved_addresses: 'Saved Addresses',
  new_address: 'New Address',
  save: 'Save',
  set_default_shipping: 'Set default shipping',
  set_default_billing: 'Set default billing',
  street_or_building: 'Street or Building',
  postcode: 'Postcode',
  city: 'City',
  province: 'Province',
  country: 'Country',
  country_placeholder: 'Select...',
  saved_designs: 'Saved Designs',
  teamwear_quality_lines: 'Teamwear Quality Lines',
  es_line_description:
    'The comfort ES.L line offers a unique Vermarc quality and comfort to meet the technical questions of cyclists of all levels and for the most diverse type of riders.',
  sp_line_description:
    'The premium SP.L line benefits of the immense professional testing and research of Vermarc’s premium collection and gives a unique proposition on performance and comfort.',
  pr_line_description:
    'The elite PR.R line takes more and more innovative, more ergonomic and lighter garments to meet the highest requirements of the professional riders.',
  category_football: 'Football',
  category_cycling: 'Cycling',
  category_athletics: 'Athletics',
  filter_by: 'Filter by',
  order_now: 'Order Now',
  design_now: 'Design Now',
  size: 'Size',
  product_information: 'Product Information',
  collections: 'Collections',
  beCreative_add_to_cart_confirm:
    'You are one step closer to receiving your personalised products!',
  beCreative_add_to_cart_button: 'Add To Basket',
  cancel: 'Cancel',
  beCreative_cancel_button: 'Cancel',
  beCreative_reset_button: 'Reset',
  beCreative_color_fill: 'Colour Fill',
  beCreative_color_a_zone: 'Colour A Zone',
  beCreative_upload_button: 'Upload',
  beCreative_upload_label: 'Upload Image',
  beCreative_logo_list: 'Image List',
  beCreative_place_logo: 'Place Image',
  beCreative_text_input_label: 'Text',
  beCreative_text_input_placeholder: 'Enter text here',
  beCreative_add_text: 'Add text',
  beCreative_apply_change_text: 'Apply Change',
  beCreative_select_fonts: 'Select fonts',
  beCreative_transform_label: 'Transform & Control',
  beCreative_outline_size: 'Outline Size',
  beCreative_line_spacing: 'Line Spacing',
  beCreative_color: 'Colour',
  beCreative_font_color: 'Font Colour',
  beCreative_outline_color: 'Outline Colour',
  beCreative_instruction_how_to_use: 'How to use 3D',
  beCreative_instruction_rotate360: 'Rotate 360',
  beCreative_instruction_rotate_mouse: 'Left click + drag (Mouse)',
  beCreative_instruction_rotate_touch: 'One finger + drag (Touch)',
  beCreative_instruction_zoom: 'Zoom',
  beCreative_instruction_zoom_mouse: 'Scroll the wheel (Mouse)',
  beCreative_instruction_zoom_touch: 'Pinch out (Touch)',
  beCreative_instruction_move: 'Move',
  beCreative_instruction_move_mouse: 'Right click + drag (Mouse)',
  beCreative_instruction_move_touch: 'Two finger + drag (Touch)',
  beCreative_instruction_close_button: 'Close',
  beCreative_request_quote_address_club: 'Address (team/club/company)',
  beCreative_request_quote_design_application:
    'Design application for personalised clothing',
  beCreative_request_quote_type_of_products: 'Type of products',
  beCreative_request_quote_athletics: 'Athletics',
  beCreative_request_quote_leisure: 'Leisure',
  beCreative_request_quote_football: 'Football (soccer)',
  beCreative_request_quote_triathlon: 'Triathlon',
  beCreative_request_quote_cycling: 'Cycling',
  beCreative_request_quote_type_of_clothing:
    'Type of cycling clothing (personalized): Product',
  beCreative_request_quote_jersey_short_sleeve: 'Jersey short sleeve',
  beCreative_request_quote_jersey_long_sleeve: 'Jersey long sleeve',
  beCreative_request_quote_mid_season_jacket: 'Mid season jacket',
  beCreative_request_quote_technical_jacket: 'Technical jacket',
  beCreative_request_quote_windstopper: 'Windstopper / Kaos',
  beCreative_request_quote_rain_jacket: 'Rain jacket',
  beCreative_request_quote_bib_shorts: 'Bib shorts',
  beCreative_request_quote_tights: 'Tights',
  beCreative_request_quote_quality_line:
    'Type of cycling clothing (personalised): Quality line',
  beCreative_request_quote_dont_know_yet: "Don't know yet",
  beCreative_request_quote_comments_questions: 'Comments/Questions',
  send: 'Send',
  sent: 'Sent',
  please_fill_all_fields: 'Please fill all required fields',
  teamstores: 'Team Stores',
  already_in_cart: 'Already in basket',
  same_supplier_warning:
    'You can only add products of the same supplier to the basket',
  go_to_order: 'Go to order',
  select_delivery_mode: 'SELECT DELIVERY MODE',
  pay_online_delivery_home: 'Pay online, delivered to your home',
  pay_online_delivery_home_express:
    'Pay online, delivered to your home (Express)',
  pay_online_pickup: 'Pay online, pick up in store',
  pay_now: 'Pay Now',
  your_card_information: 'Your card information',
  processing_payment: 'Payment processing...',
  select_address: 'Select Address',
  create_new_address: 'Create a new Address',
  shipping_address: 'Shipping Address',
  billing_address: 'Billing Address',
  invalid_address: 'Invalid address. Please select another one.',
  incl_free: 'incl. free',
  cart_not_found: 'Card not found',
  no_payment_available_yet: 'No payment available yet',
  confirm_payment: 'Confirm Payment',
  credit_card: 'Credit card',
  make_your_choice: 'Make your choice',
  you_may_also_like: 'You may also like',
  personalization: 'Personalisation',
  i_have_read_and_have_approved_the_privacy_policy:
    'I HAVE READ AND HAVE APPROVED THE PRIVACY POLICY',
  shipping_cost_included: 'Shipping cost included',
  shop_the_collection: 'View the collection',
  same_as_shipping: 'Same as shipping address',
  invoice_summary: 'Invoice Summary',
  sub_total: 'Sub-Total',
  shipping: 'Shipping',
  cookie_settings: 'Cookie settings',
  essential_cookies: 'Essential Cookies',
  analytical_cookies: 'Analytical Cookies',
  marketing_cookies: 'Marketing Cookies',
  active: 'Active',
  always_active: 'Always active',
  cookie_settings_description:
    'Our website keeps three levels of cookies. You can adjust your preferences at any time. If you want more information about what cookies are and which cookies we collect, please read our cookie policy.',
  essential_cookies_description:
    'Functional cookies are essential cookies that ensure that de website functions properly and that your preferences (e.g. language, region) are saved.',
  analytical_cookies_description: `Analytical cookies allow us to analyse website use and to improve the visitor's experience.`,
  marketing_cookies_description:
    'The aim of marketing cookies is to personalise your experience and to send you relevant content and offers, on this website and other websites.',
  agree: 'Agree',
  cookie_notice:
    'This website uses cookies to improve your surfing experience. By clicking on ‘Agree’, you accept the use of these cookies.',
  firstName_name: 'First Name *',
  lastName_name: 'Last Name *',
  company_name: 'Company name',
  email_name: 'Email *',
  phone_name: 'Phone *',
  vatNumber_name: 'VAT No',
  address1_name: 'Street/Building *',
  zip_name: 'ZIP/Postcode *',
  city_name: 'City *',
  province_name: 'State/Province',
  countryCode_name: 'Country *',
  firstName_placeholder: 'First Name',
  lastName_placeholder: 'Last Name',
  company_placeholder: 'Company Name',
  email_placeholder: 'Email',
  phone_placeholder: 'Phone',
  vatNumber_placeholder: 'VAT No',
  address1_placeholder: 'Street/Building',
  zip_placeholder: 'ZIP/Postcode',
  city_placeholder: 'City',
  province_placeholder: 'State/Province',
  countryCode_placeholder: 'Country',
  message: 'Message',
}

export const nl_NL: IVocabulary = {
  payment_redirect_note:
    'Als u niet wordt doorgestuurd, kan u de betaling voltooien door op de volgende link te klikken:',
  size_chart: 'Maattabel',
  shopping_cart: 'MIJN WINKELMANDJE',
  invalid_cart: 'Ongeldig winkelmandje',
  invalid_partner_number: 'Ongeldig vennootnummer',
  parner_number_used: 'Vennootnummer is reeds gebruikt',
  product: 'Product',
  quantity: 'Aantal',
  register: 'REGISTREER NU',
  tell_us_who_you_are: 'IK BEN EEN',
  sport_club: 'Sportclub',
  company: 'Bedrijf',
  private_person: 'Particulier persoon',
  selected_specific: `Bedrijf / Sportclub / Personeelsnummer`,
  enter_company_name: 'Bedrijf / Sportclub / Personeelsnummer',
  company_information: 'Bedrijfsinformatie',
  enter_sport_club_name: 'Bedrijf / Sportclub / Personeelsnummer',
  vat_number: 'BTW Nummer',
  example_number: 'e.g. 1234567890',
  personal_information: 'Persoonlijke informatie',
  first_name: 'Voornaam',
  first_name_placeholder: 'Voornaam',
  last_name: 'Naam',
  last_name_placeholder: 'Naam',
  repeat_email: 'Herhaal je e-mail',
  activate_passport: 'VOORDELENKAART ACTIVEREN?',
  passport_number: 'KAARTNUMMER:',
  passport_card_number_placeholder: 'Kaartnummer',
  sign_up: 'Registeren',
  price: 'Prijs',
  continue_shopping: 'Verder winkelen',
  checkout_now: 'Bestellen',
  total: 'Totaal',
  vat_included: '% incl. BTW',
  nothing_in_your_cart: 'Uw winkelmandje is leeg',
  validate: 'Korting toevoegen',
  vermarc_cart_service_title: 'ONZE SERVICE, UW VOORDEEL',
  vermarc_cart_service_description:
    'De ultieme testen van sport professionals en de uiterste zorg voor onze producten, zijn de garantie voor kwaliteit van Vermarc. Mocht je vragen hebben, doe gerust een beroep op onze kennis en ervaring. We helpen je graag verder. De Vermarc garanties:',
  vermarc_cart_service_item_1: 'Kwaliteitsgarantie',
  vermarc_cart_service_item_2: 'Optimale klantenservice garantie',
  vermarc_cart_service_item_3: 'Premium dealernetwerk in Benelux',
  vermarc_cart_service_item_4: 'Niet tevreden, ruilgarantie of geld terug',
  coupon_code: 'COUPON CODE TOEVOEGEN:',
  delivery_prices: 'VERZENDKOSTEN',
  payment_options: 'VEILIG BETALEN',
  bank_account_country: 'Bankrekening - Land',
  select_payment_method: 'Selecteer betaalmethode',
  belgium_and_netherlands: 'België, Nederland en Luxemburg',
  benelux: 'België, Nederland en Luxemburg',
  from: 'vanaf',
  within_the_eu: 'Binnen Europa',
  outside_the_eu: 'Buiten Europa',
  learn_more: 'Meer over onze algemene voorwaarden',
  free: 'gratis.',
  email: 'Email',
  password: 'Wachtwoord',
  confirm_password: 'Bevestig Wachtwoord',
  password_placeholder:
    'Min. 8 tekens, 1 kleine letter, 1 grote letter, 1 speciaal teken',
  enter_email: 'Voer je emailadres in',
  enter_password: 'Voer je wachtwoord in',
  forgot_password: 'Wachtwoord vergeten',
  reset_password: 'Wachtwoord opnieuw instellen',
  password_reset_required:
    'Uw account vereist een wachtwoordreset. Om te resetten klik op de onderstaande knop.',
  log_in: 'Aanmelden',
  no_account_yet: `Heb je nog geen account? Registreer nu!`,
  user_does_not_exist: 'Gebruiker bestaat niet',
  premium_dealers_title: 'PREMIUM DEALERS',
  premium_dealers_subtitle:
    'Vermarc Sport selecteert zijn verkooppunten zorgvuldig.',
  premium_dealers_belgium: 'Belgie',
  premium_dealers_netherlands: 'Nederland',
  phone: 'Telefoon',
  my_orders: 'Mijn Bestellingen',
  my_designs: 'Mijn Ontwerpen',
  fabric: 'Stof',
  color: 'Kleur',
  text: 'Tekst',
  logo: 'Logo',
  label: 'Label',
  login: 'AANMELDEN',
  care_label: 'Wasvoorschrift',
  choose_your_fabric: 'Kies je stof',
  interlock: 'Interlock',
  mesh: 'Mesh',
  woven: 'Geweven',
  cotton: 'Katoen',
  fabric_overview: 'Stoffenoverzicht',
  group: 'Groep',
  construction: 'Structuur',
  content: 'Inhoud',
  weight: 'Gewicht',
  measurements: 'Maten',
  measurement_table: 'Matentabel',
  help_center: 'Help Center',
  need_help: 'Hulp nodig?',
  enter_article_name: 'Voer het artikelnaam in',
  my_cart: 'Mijn winkelmandje',
  style_number: 'Productnummer',
  color_and_print_overview: 'Kleur- en printoverzicht',
  element: 'Element',
  print: 'Print',
  color_in_stock_for_this_fabric: 'Kleur op voorraad voor deze stof',
  out_of_stock: 'Out of stock',
  dyed_for_both_sides: 'Gekleurd aan beide kanten',
  color_picker: 'Kleurenkiezer',
  sublimated_one_side: 'Gesublimeerd aan één kant',
  upload_your_artwork: 'Importeer je tekening',
  drag_a_file_here: 'Sleep een bestand',
  or: 'Of',
  select_from_computer: 'Kies op je computer',
  scale: 'Vergroten en verkleinen',
  rotate: 'Draaien',
  offset_x: 'Verschuif horizontaal',
  offset_y: 'Verschuif vertikaal',
  add_your_own_text: 'Voeg je tekst toe',
  enter_your_text: 'Voer je tekst in',
  text_overview: 'Tekstoverzicht',
  add: 'Toevoegen',
  add_your_own_logo: 'Voeg je logo toe',
  customize_care_label: 'Ontwerp het wasvoorschrift',
  size_indication: 'Maten zijn enkel ter illustratie',
  select_your_logo: 'Kies je logo',
  select_your_care_label: 'Kies het wasvoorschrift',
  left: 'Links',
  right: 'Rechts',
  side: 'Zijde',
  front: 'Voor',
  three_quarter: '3/4',
  size_indication_note: 'Maten zijn enkel ter illustratie',
  back: 'Terug',
  upcharge: 'Upcharge',
  no_upcharge: 'Geen upcharge',
  select_printing_technique_to_see_color:
    'Kies een printtechniek om de kleuren te zien',
  choose_from_our_colors: 'Kies uit onze kleuren',
  remove: 'Verwijder',
  position: 'Plaats',
  logo_overview: 'Logo overzicht',
  printing_technique: 'Printtechniek',
  select_care_label_template: 'Kies het wasvoorschrift',
  shipping_price_not_included: 'Verzendkosten niet inbegrepen',
  shipping_cost_calculated_next_step:
    'Verzendingskosten worden berekend in de volgende stap',
  save_and_continue: 'Bewaar en ga verder',
  create_folder: 'Maak een map aan',
  add_to_cart: 'Toevoegen aan winkelmand',
  delete: 'Verwijder',
  review: 'Nakijken',
  need_sample: 'Sample nodig',
  total_piece: 'Totaal aantal stuks',
  collection: 'Collectie',
  season: 'Seizoen',
  discount: 'Korting',
  minimum_quantity_50: 'Mininumhoeveelheid: 50 stuks',
  submitted: 'Besteld',
  on_hold: 'Nakijken',
  production: 'Productie',
  shipped: 'Verzonden',
  your_orders: 'Mijn bestellingen',
  edit: 'Aanpassen',
  my_account: 'Mijn Account',
  phone_number: 'Telefoon',
  default_currency: 'Standaardmunt',
  default_language: 'Standaardtaal',
  default_country: 'Standaardland',
  vermarc_passport_number: 'Vermarc Kaartnummer',
  vermarc_passport_number_placeholder: 'Voer je kaartnummer in',
  saved_addresses: 'Bewaarde adressen',
  new_address: 'Nieuw adres',
  save: 'Bewaar',
  set_default_shipping: 'Kies je standaard verzendingsadres',
  set_default_billing: 'Kies je standaard facturatieadres',
  street_or_building: 'Straat',
  postcode: 'Postcode',
  city: 'Stad',
  province: 'Provincie',
  country: 'Land',
  country_placeholder: 'Kies...',
  saved_designs: 'Bewaarde ontwerpen',
  teamwear_quality_lines: 'Kwaliteitslijnen',
  es_line_description: `De comfort lijn ES.L biedt een unieke Vermarc kwaliteit en comfort en komt tegemoet aan de technische vragen van wielrenners op alle niveaus en voor de meest uiteenlopende type renners.`,
  sp_line_description: `De premium SP.L lijn profiteert mee van de immense professionele testen en research van Vermarc's premium collectie en geeft een unieke propositie op prestatie en comfort.`,
  pr_line_description: `De elite PR.R lijn brengt steeds meer innoverende, meer ergonomische en lichtere kledij die telkens opnieuw tegemoet komt aan de hoogste vereisten van de professionele renners.`,
  category_football: 'Voetbal',
  category_cycling: 'Fietsen',
  category_athletics: 'Atletiek',
  filter_by: 'Filteren op',
  order_now: 'Nu online kopen',
  design_now: 'Nu ontwerpen',
  size: 'Maat',
  product_information: 'PRODUCTINFORMATIE',
  collections: 'Collecties',
  beCreative_add_to_cart_confirm:
    'Weet u zeker dat u dit artikel aan uw winkelwagen wilt toevoegen?',
  beCreative_add_to_cart_button: 'Voeg toe aan winkelmandje',
  cancel: 'Annuleer',
  beCreative_cancel_button: 'Annuleer',
  beCreative_reset_button: 'Reset',
  beCreative_color_fill: 'Kleuren',
  beCreative_color_a_zone: 'Kleur een zone in',
  beCreative_upload_button: 'Opladen',
  beCreative_upload_label: 'Importeer je logo',
  beCreative_logo_list: 'Lijst van logos',
  beCreative_place_logo: 'Plaats je logo',
  beCreative_text_input_label: 'Tekst',
  beCreative_text_input_placeholder: 'Voer je tekst in',
  beCreative_add_text: 'Voeg tekst toe',
  beCreative_apply_change_text: 'Verandering toepassen',
  beCreative_select_fonts: 'Selecteer lettertype',
  beCreative_transform_label: 'Transformeren en beheren',
  beCreative_outline_size: 'Contourgrootte',
  beCreative_line_spacing: 'Letterafstand',
  beCreative_color: 'Kleur',
  beCreative_font_color: 'Kleur lettertype',
  beCreative_outline_color: 'Kleur contour',
  beCreative_instruction_how_to_use: 'Hoe 3D te gebruiken',
  beCreative_instruction_rotate360: 'Draai 360',
  beCreative_instruction_rotate_mouse: 'Linkerklik en versleep (Muis)',
  beCreative_instruction_rotate_touch:
    'Klik en versleep met één vinger (Touch)',
  beCreative_instruction_zoom: 'Zoom',
  beCreative_instruction_zoom_mouse: 'Scrollwiel (Muis)',
  beCreative_instruction_zoom_touch: 'Beweeg twee vingers uit elkaar (Touch)',
  beCreative_instruction_move: 'Verplaats',
  beCreative_instruction_move_mouse: 'Rechterklik en versleep (Muis)',
  beCreative_instruction_move_touch:
    'Klik en versleep met twee vingers (Touch)',
  beCreative_instruction_close_button: 'Sluiten',
  beCreative_request_quote_address_club: 'Adres (club/team/bedrijf)',
  beCreative_request_quote_design_application:
    'Ontwerpaanvraag voor gepersonaliseerde kledij',
  beCreative_request_quote_type_of_products: 'Type producten',
  beCreative_request_quote_athletics: 'Atletiek',
  beCreative_request_quote_leisure: 'Vrije tijd',
  beCreative_request_quote_football: 'Voetbal',
  beCreative_request_quote_triathlon: 'Triatlon',
  beCreative_request_quote_cycling: 'Fietsen',
  beCreative_request_quote_type_of_clothing:
    'Type fietskleding (gepersonaliseerd): Product',
  beCreative_request_quote_jersey_short_sleeve: 'Fietstrui korte mouwen',
  beCreative_request_quote_jersey_long_sleeve: 'Fietstrui lange mouwen',
  beCreative_request_quote_mid_season_jacket: 'Tussenseizoen vest',
  beCreative_request_quote_technical_jacket: 'Technical vest',
  beCreative_request_quote_windstopper: 'Windstopper / Kaos',
  beCreative_request_quote_rain_jacket: 'Regenvest',
  beCreative_request_quote_bib_shorts: 'Korte fietsbroek',
  beCreative_request_quote_tights: 'Lange fietsbroek',
  beCreative_request_quote_quality_line:
    'Type fietskleding (gepersonaliseerd): Designlijn',
  beCreative_request_quote_dont_know_yet: 'Nog geen keuze gemaakt',
  beCreative_request_quote_comments_questions: 'Vragen/Opmerkingen',
  send: 'Verzenden',
  sent: 'Verzonden',
  please_fill_all_fields: 'Gelieve alle velden in te vullen',
  teamstores: 'Club Shops',
  already_in_cart: 'Reeds in winkelmandje',
  same_supplier_warning:
    'U kunt alleen producten van dezelfde leverancier aan het winkelwagentje toevoegen',
  select_address: 'Kies jouw adres',
  create_new_address: 'Maak een nieuw adres aan',
  shipping_address: 'Verzend adres',
  billing_address: 'Facturatie adres',
  invalid_address: 'Ongeldig adres. Selecteer een andere.',
  incl_free: 'incl. gratis',
  cart_not_found: 'Kaart niet gevonden',
  no_payment_available_yet: 'Nog geen betaling beschikbaar',
  confirm_payment: 'Bevestig betaling',
  credit_card: 'Kredietkaart',
  go_to_order: 'Bestel nu',
  select_delivery_mode: 'SELECTEER LEVERINGSMETHODE',
  pay_online_delivery_home: 'Online betalen, thuis geleverd',
  pay_online_delivery_home_express: 'Online betalen, thuis geleverd (Express)',
  pay_online_pickup: 'Online betalen, ophalen in winkel',
  pay_now: 'Nu betalen',
  your_card_information: 'Kaartgegevens',
  processing_payment: 'Verwerken van betaling...',
  make_your_choice: 'Maak uw keuze',
  you_may_also_like: 'Misschien wilt u ook deze producten',
  personalization: 'Personalisatie',
  i_have_read_and_have_approved_the_privacy_policy:
    'IK VERKLAAR DAT IK DE PRIVACY POLICY HEB GELEZEN EN GOEDGEKEURD.',
  shipping_cost_included: 'Verzendkosten inbegrepen',
  shop_the_collection: 'Bekijk de collectie',
  same_as_shipping: 'Hetzelfde als het verzendadres',
  invoice_summary: 'Factuuroverzicht',
  sub_total: 'Subtotaal',
  shipping: 'Verzending',
  cookie_settings: 'Cookie instellingen',
  essential_cookies: 'Essentiële Cookies',
  analytical_cookies: 'Analytische cookies',
  marketing_cookies: 'Marketing cookies',
  active: 'Actief',
  always_active: 'Altijd actief',
  cookie_settings_description:
    'Onze website heeft drie niveaus van cookies. U kunt uw voorkeuren op elk gewenst moment aanpassen. Als u meer informatie wilt over wat cookies zijn en welke cookies we verzamelen, lees dan ons cookiebeleid.',
  essential_cookies_description:
    'Functionele cookies zijn essentiële cookies die ervoor zorgen dat de website naar behoren functioneert en dat uw voorkeuren (bijv. Taal, regio) worden opgeslagen.',
  analytical_cookies_description: `Met analytische cookies kunnen we het gebruik van de website analyseren en de ervaring van de bezoeker verbeteren.`,
  marketing_cookies_description:
    'Het doel van marketingcookies is om uw ervaring te personaliseren en u relevante inhoud en aanbiedingen te sturen, op deze website en andere websites.',
  agree: 'Akkoord',
  cookie_notice:
    'Deze website maakt gebruik van cookies om uw surfervaring te verbeteren. Door op Akkoord te klikken, accepteert u het gebruik van deze cookies.',
  firstName_name: 'Voornaam *',
  lastName_name: 'Achternaam *',
  company_name: 'Bedrijfsnaam',
  email_name: 'Email *',
  phone_name: 'Telefoon *',
  vatNumber_name: 'BTW Nummer',
  address1_name: 'Straat/Huisnummer *',
  zip_name: 'Postcode *',
  city_name: 'Stad *',
  province_name: 'Provincie',
  countryCode_name: 'Land *',
  firstName_placeholder: 'Voornaam',
  lastName_placeholder: 'Achternaam',
  company_placeholder: 'Bedrijfsnaam',
  email_placeholder: 'Email',
  phone_placeholder: 'Telefoon',
  vatNumber_placeholder: 'BTW Nummer',
  address1_placeholder: 'Straat/Huisnummer',
  zip_placeholder: 'Postcode',
  city_placeholder: 'Stad',
  province_placeholder: 'Provincie',
  countryCode_placeholder: 'Land',
  message: 'Bericht',
}

export const fr_FR: IVocabulary = {
  payment_redirect_note:
    'Si vous n’êtes pas redirigé, veuillez effectuer le paiement en cliquant sur le lien suivant:',
  size_chart: 'Tableau des tailles',
  shopping_cart: 'MON PANIER',
  invalid_cart: 'Panier invalide',
  invalid_partner_number: 'Numéro associés invalide',
  parner_number_used: 'Le numéro associés a déjà été utilisé',
  product: 'Produit',
  quantity: 'Quantité',
  register: 'INSCRIVEZ-VOUS MAINTENANT',
  tell_us_who_you_are: 'JE SUIS UN',
  sport_club: 'Club de sport',
  company: 'Société',
  private_person: 'Particulier',
  selected_specific: `Société / Club de sport / Numéro d'Employé`,
  enter_company_name: `Société / Club de sport / Numéro d'Employé`,
  company_information: 'Information d’entreprise',
  enter_sport_club_name: `Société / Club de sport / Numéro d'Employé`,
  vat_number: 'TVA Numéro',
  example_number: 'e.g. 1234567890',
  personal_information: 'Informations personnelles',
  first_name: 'Prénom',
  first_name_placeholder: 'Prénom',
  last_name: 'Nom',
  last_name_placeholder: 'Nom',
  repeat_email: 'Répétez votre email',
  activate_passport: 'ACTIVER CARTE AVANTAGES?',
  passport_number: 'NUMÉRO DE CARTE',
  passport_card_number_placeholder: 'Numéro de carte',
  sign_up: 'Registrer',
  price: 'Prix',
  continue_shopping: 'Continuer vos achats',
  checkout_now: 'Commander',
  total: 'Total',
  vat_included: '% TVA inclus',
  nothing_in_your_cart: `Votre panier d'achat est vide`,
  validate: 'Ajouter ristourne',
  vermarc_cart_service_title: 'NOTRE SERVICE VOTRE AVANTAGE',
  vermarc_cart_service_description:
    'Le test ultime de sportifs professionnels sur le terrain et le plus grand soin pour nos produits, sont la garantie de qualité de Vermarc. Si vous avez des questions, n’hésitez pas à faire appel à notre connaissance et expérience. Nous serons heureux de vous aider. Les garanties Vermarc:',
  vermarc_cart_service_item_1: 'Garantie de qualité',
  vermarc_cart_service_item_2: 'Garantie service clientèle optimale',
  vermarc_cart_service_item_3: 'Réseau de revendeurs premium au Benelux',
  vermarc_cart_service_item_4:
    'Pas satisfait, garantie d’échange ou remboursement',
  coupon_code: 'AJOUTER CODE COUPON',
  delivery_prices: 'FRAIS DE PORT',
  payment_options: 'PAIEMENT SÉCURISÉ',
  bank_account_country: 'Compte bancaire - Pays',
  select_payment_method: 'Sélectionnez le mode de paiement',
  belgium_and_netherlands: 'Belgique, les Pays-Bas et Luxembourg',
  benelux: 'Belgique, les Pays-Bas et Luxembourg',
  from: 'à partir de',
  within_the_eu: 'Dans l’UE',
  outside_the_eu: 'En dehors de l’UE',
  learn_more: 'En savoir plus sur nos conditions.',
  free: 'gratuite.',
  email: 'Email',
  password: 'MOT DE PASSE',
  confirm_password: 'CONFIRMEZ MOT DE PASSE',
  password_placeholder:
    'Min. 8 chars, 1 lowercase, 1 uppercase, 1 special char',
  enter_email: 'Entrez votre adresse email',
  enter_password: 'Entrez votre mot de passe',
  forgot_password: 'Mot de passe oublié',
  reset_password: 'Réinitialiser le mot de passe',
  password_reset_required:
    'Votre compte nécessite une réinitialisation du mot de passe. Pour réinitialiser, cliquez sur le bouton ci-dessous.',
  log_in: 'Connecter',
  no_account_yet: `Vous n'avez pas encore de compte? S'inscrire maintenant!`,
  user_does_not_exist: `L'utilisateur n'existe pas!`,
  premium_dealers_title: 'PREMIUM DEALERS',
  premium_dealers_subtitle:
    'Vermarc Sport choisit ses revendeurs soigneusement.',
  premium_dealers_belgium: 'Belgique',
  premium_dealers_netherlands: 'Pays-Bas',
  phone: 'Téléphone',
  my_orders: 'Mes Commandes',
  my_designs: 'Mes Dessins',
  fabric: 'Le tissue',
  color: 'La coleur',
  text: 'Le texte',
  logo: 'Le logo',
  label: `L'étiquette`,
  login: 'CONNECTER',
  care_label: `L'étiquette`,
  choose_your_fabric: 'Choisissez votre tissu',
  interlock: 'Interlock',
  mesh: 'Mesh',
  woven: 'Tissé',
  cotton: 'Coton',
  fabric_overview: 'Aperçu de tissu',
  group: 'Le groupe',
  construction: 'La construction',
  content: 'Le contenue',
  weight: 'Le poids',
  measurements: 'Les mesures',
  measurement_table: 'La table de mesure',
  help_center: `Besoin d'aide`,
  need_help: `Besoin d'aide?`,
  enter_article_name: `Entrez le nom de l'article`,
  my_cart: 'Mon panier',
  style_number: 'Numéro de style',
  color_and_print_overview: 'Aperçu des couleurs et des impressions',
  element: `L'élément`,
  print: `L'impression`,
  color_in_stock_for_this_fabric: 'Couleur en stock pour ce tissu',
  out_of_stock: 'Out of stock',
  dyed_for_both_sides: 'Teint des deux côtés',
  color_picker: 'Pipette à couleurs',
  sublimated_one_side: 'Sublimé un côté',
  upload_your_artwork: 'Upload your artwork',
  drag_a_file_here: 'Drag a file here',
  or: 'Ou',
  select_from_computer: 'Select from your computer',
  scale: 'Scale',
  rotate: 'Tourner',
  offset_x: 'Déplacer horizontalement',
  offset_y: 'Déplacer verticalement',
  add_your_own_text: 'Ajoutez votre propre texte',
  enter_your_text: 'Entrez votre texte',
  text_overview: 'Aperçu du texte',
  add: 'Ajouter',
  add_your_own_logo: 'Ajoutez votre propre logo',
  customize_care_label: `Personnalisez votre étiquette d'entretien`,
  size_indication: 'Indication de taille pour illustration seulement',
  select_your_logo: 'Sélectionnez votre logo',
  select_your_care_label: `Sélectionnez votre étiquette d'entretien`,
  left: 'Gauche',
  right: 'Droite',
  side: 'Côté',
  front: 'De face',
  three_quarter: '3/4',
  size_indication_note:
    'Remarque: Indication de taille pour illustration seulement',
  back: 'Retour',
  upcharge: 'Supplément',
  no_upcharge: 'Pas de supplément',
  select_printing_technique_to_see_color: `Sélectionnez une technique d'impression pour voir les couleurs disponibles`,
  choose_from_our_colors: 'Choisissez parmi nos couleurs',
  remove: 'Effacer',
  position: 'Placement',
  logo_overview: 'Aperçu du logo',
  printing_technique: `Technique d'impression`,
  select_care_label_template: `Sélectionnez un modèle d'étiquette d'entretien`,
  shipping_price_not_included: `Prix d'expédition non inclus`,
  shipping_cost_calculated_next_step:
    "Calcul des frais de livraison à l'étape suivante",
  save_and_continue: 'Sauvegarder et continuer',
  create_folder: 'Créer le dossier',
  add_to_cart: 'Ajouter au panier',
  delete: 'Effacer',
  review: 'Réviser',
  need_sample: `Besoin d'échantillon`,
  total_piece: 'Pièce totale',
  collection: 'Collection',
  season: 'Saison',
  discount: 'Remise',
  minimum_quantity_50: 'Quantité minimum: 50 pièces',
  submitted: 'Soumis',
  on_hold: 'En attente',
  production: 'Production',
  shipped: 'Expédié',
  your_orders: 'Mes commandes',
  edit: 'Éditer',
  my_account: 'Mon compte',
  phone_number: 'Numéro de téléphone',
  default_currency: 'Devise standard',
  default_language: 'Langue standard',
  default_country: 'Pays standard',
  vermarc_passport_number: 'Numéro de carte',
  vermarc_passport_number_placeholder: 'Entrez votre numéro de carte',
  saved_addresses: 'Adresses sauvegardées',
  new_address: 'Nouvelle adresse',
  save: 'Sauvegarder',
  set_default_shipping: 'Expédition standard',
  set_default_billing: 'Facturation standard',
  street_or_building: 'Rue ou bâtiment',
  postcode: 'Code postal',
  city: 'Ville',
  province: 'Province',
  country: 'Pays',
  country_placeholder: 'Sélectionner...',
  saved_designs: 'Dessins sauvegardés',
  teamwear_quality_lines: 'Lignes De Qualité',
  es_line_description:
    'La ligne confort ES.L offre la qualité et le confort uniques de Vermarc pour répondre aux plus grand nombre de cyclistes de tous les niveaux.',
  sp_line_description:
    'La ligne premium SP.L profite des importants testes et recherches sur la collection élite de Vermarc et donne une proposition unique sur la performance et le confort.',
  pr_line_description:
    'La ligne élite PR.R propose un équipement plus innovateur, plus ergonomique et plus léger pour répondre aux plus hautes exigences des coureurs professionels.',
  category_football: 'Football',
  category_cycling: 'Cyclisme',
  category_athletics: 'Athlétisme',
  filter_by: 'Filtrer par',
  order_now: 'Acheter en ligne maintenant',
  design_now: 'Dessiner',
  size: 'Taille',
  product_information: 'INFORMATION PRODUIT',
  collections: 'Collections',
  beCreative_add_to_cart_confirm:
    'Êtes-vous sûr de vouloir ajouter cet article à votre panier?',
  beCreative_add_to_cart_button: 'Ajouter au panier',
  cancel: 'Annuler',
  beCreative_cancel_button: 'Annuler',
  beCreative_reset_button: 'Réinitialiser',
  beCreative_color_fill: 'Couleurs',
  beCreative_color_a_zone: 'Couleur une zone',
  beCreative_upload_button: 'Télécharger',
  beCreative_upload_label: 'Télécharger un logo',
  beCreative_logo_list: 'Liste de logo',
  beCreative_place_logo: 'Placez un logo',
  beCreative_text_input_label: 'Texte',
  beCreative_text_input_placeholder: 'Entrez le texte ici',
  beCreative_add_text: 'Ajouter du texte',
  beCreative_apply_change_text: 'Appliquer le changement',
  beCreative_select_fonts: 'Sélectionner les polices ',
  beCreative_transform_label: 'Transformer et contrôler',
  beCreative_outline_size: 'Taille du contour',
  beCreative_line_spacing: 'Interligne',
  beCreative_color: 'Couleur',
  beCreative_font_color: 'Couleur de la police',
  beCreative_outline_color: 'Couleur de contour',
  beCreative_instruction_how_to_use: 'Comment utiliser la 3D',
  beCreative_instruction_rotate360: 'Tourner à 360',
  beCreative_instruction_rotate_mouse: 'Left click + drag (Mouse)',
  beCreative_instruction_rotate_touch: 'One finger + drag (Touch)',
  beCreative_instruction_zoom: 'Zoom',
  beCreative_instruction_zoom_mouse: 'Scroll the wheel (Mouse)',
  beCreative_instruction_zoom_touch: 'Pinch out (Touch)',
  beCreative_instruction_move: 'Move',
  beCreative_instruction_move_mouse: 'Right click + drag (Mouse)',
  beCreative_instruction_move_touch: 'Two finger + drag (Touch)',
  beCreative_instruction_close_button: 'Fermer',
  beCreative_request_quote_address_club: 'Adresse (club/enterprise)',
  beCreative_request_quote_design_application:
    'Application de conception de vêtements personnalisés',
  beCreative_request_quote_type_of_products: 'Type de produits',
  beCreative_request_quote_athletics: 'Athlétisme',
  beCreative_request_quote_leisure: 'Loisir',
  beCreative_request_quote_football: 'Football',
  beCreative_request_quote_triathlon: 'Triathlon',
  beCreative_request_quote_cycling: 'Cyclisme',
  beCreative_request_quote_type_of_clothing:
    'Type de vêtement cycliste (personnalisé): Produit',
  beCreative_request_quote_jersey_short_sleeve: 'Maillot manches courtes',
  beCreative_request_quote_jersey_long_sleeve: 'Maillot manches longues',
  beCreative_request_quote_mid_season_jacket: 'Veste mi-saison',
  beCreative_request_quote_technical_jacket: 'Veste technical',
  beCreative_request_quote_windstopper: 'Coupe-vent / Kaos',
  beCreative_request_quote_rain_jacket: 'Imperméable',
  beCreative_request_quote_bib_shorts: 'Cuissard',
  beCreative_request_quote_tights: 'Collants',
  beCreative_request_quote_quality_line:
    'Type de vêtement cycliste (personnalisé): Ligne de qualité',
  beCreative_request_quote_dont_know_yet: 'Ne sais pas encore',
  beCreative_request_quote_comments_questions: 'Commentaires/Questions',
  send: 'Envoyez',
  sent: 'Envoyé',
  please_fill_all_fields: 'Veuillez remplir tous le formulaire',
  teamstores: 'Espace Clubs',
  already_in_cart: 'Déjà dans le panier',
  same_supplier_warning:
    'Vous pouvez uniquement ajouter des produits du même fournisseur au panier.',
  select_address: 'Choisissez votre adresse',
  create_new_address: 'Créer une nouvelle adresse',
  shipping_address: 'Adresse de livraison',
  billing_address: 'Adresse de facturation',
  invalid_address: 'Adresse invalide. Veuillez en choisir un autre.',
  incl_free: 'y compris gratuit',
  cart_not_found: 'Carte non trouvée',
  no_payment_available_yet: 'Aucun paiement disponible pour le moment',
  confirm_payment: 'Confirmer le paiement',
  credit_card: 'Carte de crédit',
  go_to_order: 'Commandez maintenant',
  select_delivery_mode: 'SÉLECTIONNER LE MODE DE LIVRAISON',
  pay_online_delivery_home: 'Payer en ligne, livraison à la maison',
  pay_online_delivery_home_express:
    'Payer en ligne, livraison à la maison (Express)',
  pay_online_pickup: 'Payez en ligne, retirer au magasin',
  pay_now: 'Payez maintenant',
  your_card_information: 'Les informations de votre carte',
  processing_payment: 'Paiement en cours...',
  make_your_choice: 'Faites votre choix',
  you_may_also_like: 'Vous aimerez peut-être aussi',
  personalization: 'Personnalisation',
  i_have_read_and_have_approved_the_privacy_policy:
    'J’AI LU ET ACCEPTE LA POLITIQUE DE CONFIDENTIALITÉ.',
  shipping_cost_included: 'Frais de livraison inclus',
  shop_the_collection: 'Voir toute la collection',
  same_as_shipping: `Même que l'adresse d'expédition`,
  invoice_summary: 'Résumé de la facture',
  sub_total: 'Total partiel',
  shipping: 'Livraison',
  cookie_settings: 'Paramètres de cookie',
  essential_cookies: 'Cookies Essentiels',
  analytical_cookies: 'Cookies analytiques',
  marketing_cookies: 'Cookies de marketing',
  active: 'Activé',
  always_active: 'Toujours activé',
  cookie_settings_description: `Notre site conserve trois niveaux de cookies. Vous pouvez ajuster vos préférences à tout moment. Si vous souhaitez plus d'informations sur les cookies et les cookies que nous collectons, veuillez consulter notre politique en matière de cookies.`,
  essential_cookies_description: `Les cookies fonctionnels sont des cookies essentiels qui garantissent le bon fonctionnement du site Web et l'enregistrement de vos préférences (langue, région, etc.).`,
  analytical_cookies_description: `Les cookies analytiques nous permettent d'analyser l'utilisation du site et d'améliorer l'expérience du visiteur.`,
  marketing_cookies_description: `Les cookies de marketing ont pour objectif de personnaliser votre expérience et de vous envoyer du contenu et des offres pertinents sur ce site et d'autres sites.`,
  agree: 'Agree',
  cookie_notice: `Ce site utilise des cookies pour améliorer votre expérience de navigation. En cliquant sur "Accepter", vous acceptez l'utilisation de ces cookies.`,
  firstName_name: 'Prénom *',
  lastName_name: 'Nom *',
  company_name: `Nom de l'entreprise`,
  email_name: 'Email *',
  phone_name: 'Téléphone *',
  vatNumber_name: 'TVA',
  address1_name: 'Rue / Bâtiment *',
  zip_name: 'Code postal *',
  city_name: 'Ville *',
  province_name: 'Province',
  countryCode_name: 'Pays *',
  firstName_placeholder: 'Prénom',
  lastName_placeholder: 'Nom',
  company_placeholder: `Nom de l'entreprise`,
  email_placeholder: 'Email',
  phone_placeholder: 'Téléphone',
  vatNumber_placeholder: 'TVA',
  address1_placeholder: 'Rue / Bâtiment',
  zip_placeholder: 'Code postal',
  city_placeholder: 'Ville',
  province_placeholder: 'Province',
  countryCode_placeholder: 'Pays',
  message: 'Message',
}

export const en_US: IVocabulary = {
  ...en_UK,
  shopping_cart: 'SHOPPING CART',
  invalid_cart: 'Invalid cart',
  nothing_in_your_cart: 'Your shopping cart is empty',
  beCreative_add_to_cart_confirm:
    'Are you sure you want to add this item to your cart?',
  beCreative_add_to_cart_button: 'Add To Cart',
  beCreative_color_fill: 'Color Fill',
  beCreative_color_a_zone: 'Color A Zone',
  beCreative_color: 'Color',
  beCreative_font_color: 'Font Color',
  beCreative_outline_color: 'Outline Color',
  beCreative_request_quote_design_application:
    'Design application for personalized clothing',
  already_in_cart: 'Already in cart',
  same_supplier_warning:
    'You can only add products of the same supplier to the cart',
  personalization: 'Personalization',
}

export const de_DE: IVocabulary = {
  payment_redirect_note:
    'Falls Sie nicht automatisch weitergeleitet werden, klicken Sie bitte den Link um die Bezahlung abzuschliessen:',
  size_chart: 'Größentabelle',
  shopping_cart: 'Warenkorb',
  invalid_cart: 'Ungültiger Warenkorb',
  invalid_partner_number: 'Ungültige Partnernummer',
  parner_number_used: 'Partnernummer wurde bereits verwendet',
  product: 'Produkt',
  quantity: 'Menge',
  register: 'Registrieren',
  tell_us_who_you_are: 'Sagen Sie uns, wer Sie sind',
  sport_club: 'SPORTVEREIN',
  company: 'Unternehmen',
  private_person: 'Privatperson',
  selected_specific: `Wenn Sie einen Sportverein oder eine Firma ausgewählt haben`,
  enter_company_name: 'Firmennamen eingeben',
  company_information: 'Firmeninformation',
  enter_sport_club_name: 'Geben Sie den Namen des Sportvereins ein',
  vat_number: 'Umsatzsteuer-Identifikationsnummer',
  example_number: 'z.B. 1234567890',
  personal_information: 'Persönliche Angaben',
  first_name: 'Vorname',
  first_name_placeholder: 'Dein Name',
  last_name: 'Nachname',
  last_name_placeholder: 'Ihr Nachname',
  repeat_email: 'Wiederholen Sie Ihre E-Mail',
  activate_passport: 'Aktivieren Sie Ihren Vorteilspass:',
  passport_number: 'Passnummer für Loyalität',
  passport_card_number_placeholder: 'Kartennummer eingeben',
  sign_up: 'Anmelden',
  price: 'Preis',
  continue_shopping: 'Mit dem Einkauf fortfahren',
  checkout_now: 'Zur Kasse >',
  total: 'Gesamt',
  vat_included: '% Inkl. MwSt',
  nothing_in_your_cart: 'Ihr Warenkorb ist leer',
  validate: 'Bestätigen',
  vermarc_cart_service_title: 'Unser Service Ihr Vorteil',
  vermarc_cart_service_description:
    'Der ultimative Test von Sportprofis und die größte Sorgfalt für unsere Produkte sind die Qualitätsgarantie von Vermarc. Bei Fragen können Sie gerne auf unser Wissen und unsere Erfahrung zurückgreifen. Wir helfen Ihnen gerne weiter. Der vermarc garantiert:',
  vermarc_cart_service_item_1: 'Qualitätsgarantie',
  vermarc_cart_service_item_2: 'Optimale Kundendienstgarantie',
  vermarc_cart_service_item_3: 'Premium-Händlernetz in Benelux',
  vermarc_cart_service_item_4:
    'Nicht zufrieden, Umtauschgarantie oder Geld zurück',
  coupon_code: 'Gutscheincode:',
  delivery_prices: 'Lieferpreise',
  payment_options: 'Sichere Bezahlung',
  bank_account_country: 'Land des Bankkontos',
  select_payment_method: 'Wählen Sie die Zahlungsmethode',
  belgium_and_netherlands: 'Belgien, die Niederlande und Luxemburg',
  benelux: 'Belgien, die Niederlande und Luxemburg ab 120 €',
  from: 'ab',
  within_the_eu: 'Innerhalb der EU',
  outside_the_eu: 'Außerhalb der EU',
  learn_more: 'Erfahren Sie mehr über unsere Allgemeinen Geschäftsbedingungen.',
  free: 'Frei.',
  email: 'Email',
  password: 'Passwort',
  confirm_password: 'Passwort bestätigen',
  password_placeholder:
    'Mindest. 8 Zeichen, 1 Kleinbuchstabe, 1 Großbuchstabe, 1 Sonderzeichen',
  enter_email: 'Geben sie ihre E-Mailadresse ein',
  enter_password: 'Geben Sie Ihr Passwort ein',
  forgot_password: 'Passwort vergessen',
  reset_password: 'Passwort zurücksetzen',
  password_reset_required:
    'Ihr Konto erfordert ein Zurücksetzen des Passworts. Zum Zurücksetzen klicken Sie auf die Schaltfläche unten.',
  log_in: 'EINLOGGEN',
  no_account_yet: `Sie haben noch keinen Account? Jetzt registrieren!`,
  user_does_not_exist: 'Benutzer existiert nicht!',
  premium_dealers_title: 'Premium-Händler',
  premium_dealers_subtitle:
    'Vermarc Sport wählt seine Vertriebsstellen sorgfältig aus.',
  premium_dealers_belgium: 'Belgien',
  premium_dealers_netherlands: 'Niederlande',
  phone: 'Telefon',
  my_orders: 'Meine Bestellungen',
  my_designs: 'Meine Designs',
  fabric: 'Stoff',
  color: 'Farbe',
  text: 'Text',
  logo: 'Logo',
  label: 'Etikette',
  login: 'Einloggen',
  care_label: 'Pflegeetikett',
  choose_your_fabric: 'Wählen Sie Ihren Stoff',
  interlock: 'Verriegelung',
  mesh: 'Mesh',
  woven: 'Gewebte',
  cotton: 'Baumwolle',
  fabric_overview: 'Stoffübersicht',
  group: 'Gruppe',
  construction: 'Konstruktion',
  content: 'Inhalt',
  weight: 'Gewicht',
  measurements: 'Messungen',
  measurement_table: 'Maßtabelle',
  help_center: 'Hilfezentrum',
  need_help: 'Brauche Hilfe?',
  enter_article_name: 'Artikelnamen eingeben',
  my_cart: 'Mein Warenkorb',
  style_number: 'Style-Nummer',
  color_and_print_overview: 'Farb- und Druckübersicht',
  element: 'Element',
  print: 'Drucken',
  color_in_stock_for_this_fabric: 'Farbe auf Lager für diesen Stoff',
  out_of_stock: 'Ausverkauft',
  dyed_for_both_sides: 'Beidseitig gefärbt',
  color_picker: 'Farbwähler',
  sublimated_one_side: 'Einseitig sublimiert',
  upload_your_artwork: 'Laden Sie Ihr Kunstwerk hoch',
  drag_a_file_here: 'Ziehen Sie eine Datei hierher',
  or: 'Oder',
  select_from_computer: 'Wählen Sie von Ihrem Computer',
  scale: 'vergrößern',
  rotate: 'Drehen',
  offset_x: 'Horizontal',
  offset_y: 'Vertikal',
  add_your_own_text: 'Fügen Sie Ihren eigenen Text hinzu',
  enter_your_text: 'Geben Sie Ihren Text ein',
  text_overview: 'Textübersicht',
  add: 'Hinzufügen',
  add_your_own_logo: 'Fügen Sie Ihr eigenes Logo hinzu',
  customize_care_label: 'Passen Sie Ihr Pflegeetikett an',
  size_indication: 'Größenangabe nur zur Illustration',
  select_your_logo: 'Wählen Sie Ihr Logo',
  select_your_care_label: 'Wählen Sie Ihr Pflegeetikett',
  left: 'Links',
  right: 'Rechts',
  side: 'Seite',
  front: 'Vorderseite',
  three_quarter: '3/4',
  size_indication_note: 'Hinweis: Größenangabe nur zur Illustration',
  back: 'Zurück',
  upcharge: 'Aufpreis',
  no_upcharge: 'Kein Aufpreis',
  select_printing_technique_to_see_color:
    'Wählen Sie eine Drucktechnik aus, um die verfügbaren Farben anzuzeigen',
  choose_from_our_colors: 'Wählen Sie aus unseren Farben',
  remove: 'Löschen',
  position: 'Position',
  logo_overview: 'Logo-Übersicht',
  printing_technique: 'Drucktechnik',
  select_care_label_template: 'Wählen Sie eine Pflegeetikettenvorlage aus',
  shipping_price_not_included: 'Versandkosten nicht enthalten',
  shipping_cost_calculated_next_step:
    'Der Versandpreis wird im nächsten Schritt berechnet',
  save_and_continue: 'Speichern fortsetzen',
  create_folder: 'Ordner erstellen',
  add_to_cart: 'In den Warenkorb legen',
  delete: 'Löschen',
  review: 'Rezension',
  need_sample: 'Benötigen Sie eine Probe',
  total_piece: 'Gesamtstück',
  collection: 'Sammlung',
  season: 'Jahreszeit',
  discount: 'Rabatt',
  minimum_quantity_50: 'Warenkorb Liste Mindestmenge: 50 Stück',
  submitted: 'Eingereicht',
  on_hold: 'In Wartestellung',
  production: 'Produktion',
  shipped: 'Versand erfolgt',
  your_orders: 'Deine Bestellungen',
  edit: 'Bearbeiten',
  my_account: 'Mein Konto',
  phone_number: 'Telefonnummer',
  default_currency: 'Standardwährung',
  default_language: 'Standardsprache',
  default_country: 'Standardland',
  vermarc_passport_number: 'Vermarc-Passnummer',
  vermarc_passport_number_placeholder: 'Geben Sie Ihre Passnummer ein',
  saved_addresses: 'Gespeicherte Adressen',
  new_address: 'Neue Adresse',
  save: 'speichern',
  set_default_shipping: 'Standardversand festlegen',
  set_default_billing: 'Festlegen der Standardabrechnung',
  street_or_building: 'Straße oder Gebäude',
  postcode: 'Postleitzahl',
  city: 'Stadt',
  province: 'Bundesstaat / Provinz',
  country: 'Land',
  country_placeholder: 'Wählen...',
  saved_designs: 'Gespeicherte Designs',
  teamwear_quality_lines: 'Teamwear Qualitätslinien',
  es_line_description:
    'Die Comfort ES.L-Linie bietet eine einzigartige Vermarc-Qualität und Komfort für die technischen Fragen von Radfahrern aller Niveaus und für die unterschiedlichsten Fahrertypen.',
  sp_line_description:
    'Die Premium-Linie SP.L profitiert von den immensen professionellen Tests und Untersuchungen der Premium-Kollektion von Vermarc und bietet ein einzigartiges Angebot an Leistung und Komfort.',
  pr_line_description:
    'Die Elite PR.R-Linie umfasst immer innovativere, ergonomischere und leichtere Kleidungsstücke, um den höchsten Anforderungen der professionellen Fahrer gerecht zu werden.',
  category_football: 'Fußball',
  category_cycling: 'Radfahren',
  category_athletics: 'Die Leichtathletik',
  filter_by: 'Filtern nach',
  order_now: 'Jetzt bestellen',
  design_now: 'Jetzt gestalten',
  size: 'Größe',
  product_information: 'Produktinformation',
  collections: 'Sammlungen',
  beCreative_add_to_cart_confirm:
    'Möchten Sie diesen Artikel wirklich in Ihren Warenkorb legen?',
  beCreative_add_to_cart_button: 'In den Warenkorb legen',
  cancel: 'Stornieren',
  beCreative_cancel_button: 'Stornieren',
  beCreative_reset_button: 'Zurücksetzen',
  beCreative_color_fill: 'Farbfüllung',
  beCreative_color_a_zone: 'Eine Zone färben',
  beCreative_upload_button: 'Hochladen',
  beCreative_upload_label: 'Logo hochladen',
  beCreative_logo_list: 'Logo-Liste',
  beCreative_place_logo: 'Logo platzieren',
  beCreative_text_input_label: 'Text',
  beCreative_text_input_placeholder: 'Hier Text eingeben',
  beCreative_add_text: 'Text hinzufügen',
  beCreative_apply_change_text: 'Änderung anwenden',
  beCreative_select_fonts: 'Wählen Sie Schriftarten aus',
  beCreative_transform_label: 'Transformieren & Steuern',
  beCreative_outline_size: 'Umrissgröße',
  beCreative_line_spacing: 'Zeilenabstand',
  beCreative_color: 'Farbe',
  beCreative_font_color: 'Schriftfarbe',
  beCreative_outline_color: 'Umrissfarbe',
  beCreative_instruction_how_to_use: 'Wie benutze ich 3D?',
  beCreative_instruction_rotate360: '360 drehen',
  beCreative_instruction_rotate_mouse: 'Linksklick + Ziehen (Maus)',
  beCreative_instruction_rotate_touch: 'Ein Finger + Ziehen (Touch)',
  beCreative_instruction_zoom: 'Zoomen',
  beCreative_instruction_zoom_mouse: 'Scrolle mit dem Rad (Maus)',
  beCreative_instruction_zoom_touch: 'Herausziehen (Touch)',
  beCreative_instruction_move: 'Bewegung',
  beCreative_instruction_move_mouse: 'Rechtsklick + Ziehen (Maus)',
  beCreative_instruction_move_touch: 'Zwei Finger + Ziehen (Touch)',
  beCreative_instruction_close_button: 'Schließen',
  beCreative_request_quote_address_club:
    'Adresse (Mannschaft / Verein / Firma)',
  beCreative_request_quote_design_application:
    'Design-Anwendung für personalisierte Kleidung',
  beCreative_request_quote_type_of_products: 'Art der Produkte',
  beCreative_request_quote_athletics: 'Die Leichtathletik',
  beCreative_request_quote_leisure: 'Freizeit',
  beCreative_request_quote_football: 'Fussball',
  beCreative_request_quote_triathlon: 'Triathlon',
  beCreative_request_quote_cycling: 'Radfahren',
  beCreative_request_quote_type_of_clothing:
    'Art der Fahrradbekleidung (personalisiert): Produkt',
  beCreative_request_quote_jersey_short_sleeve: 'Jersey short sleeve',
  beCreative_request_quote_jersey_long_sleeve: 'Jersey long sleeve',
  beCreative_request_quote_mid_season_jacket: 'Mid season jacket',
  beCreative_request_quote_technical_jacket: 'Technical jacket',
  beCreative_request_quote_windstopper: 'Windstopper / Kaos',
  beCreative_request_quote_rain_jacket: 'Rain jacket',
  beCreative_request_quote_bib_shorts: 'Bib shorts',
  beCreative_request_quote_tights: 'Tights',
  beCreative_request_quote_quality_line:
    'Type of cycling clothing (personalized): Quality line',
  beCreative_request_quote_dont_know_yet: 'Ich weiß es noch nicht',
  beCreative_request_quote_comments_questions: 'Kommentare / Fragen',
  send: 'Senden',
  sent: 'Geschickt',
  please_fill_all_fields: 'Bitte alle notwendigen Felder ausfüllen',
  teamstores: 'Team Stores',
  already_in_cart: 'Bereits im Warenkorb',
  same_supplier_warning:
    'Sie können nur Produkte desselben Lieferanten in den Warenkorb legen',
  go_to_order: 'Zur Bestellung gehen',
  select_delivery_mode: 'LIEFERMODUS WÄHLEN',
  pay_online_delivery_home: 'Online bezahlen, zu Ihnen nach Hause geliefert',
  pay_online_delivery_home_express:
    'Online bezahlen, zu Ihnen nach Hause geliefert (Express)',
  pay_online_pickup: 'Online bezahlen, im Geschäft abholen',
  pay_now: 'Zahl jetzt',
  your_card_information: 'Ihre Karteninformationen',
  processing_payment: 'Zahlungsabwicklung...',
  select_address: 'Adresse auswählen',
  create_new_address: 'Erstellen Sie eine neue Adresse',
  shipping_address: 'Lieferanschrift',
  billing_address: 'Rechnungsadresse',
  invalid_address: 'Ungültige Adresse. Bitte wählen Sie einen anderen aus.',
  incl_free: 'inkl. frei',
  cart_not_found: 'Karte nicht gefunden',
  no_payment_available_yet: 'Noch keine Zahlung möglich',
  confirm_payment: 'Bestätige Zahlung',
  credit_card: 'Kreditkarte',
  make_your_choice: 'Treffen Sie Ihre Wahl',
  you_may_also_like: 'Sie können auch mögen',
  personalization: 'Personalisierung',
  i_have_read_and_have_approved_the_privacy_policy:
    'ICH HABE DIE DATENSCHUTZRICHTLINIE GELESEN UND GENEHMIGT',
  shipping_cost_included: 'Versandkosten inklusive',
  shop_the_collection: 'Sehen Sie sich die Sammlung an',
  same_as_shipping: 'Gleich wie Lieferadresse',
  invoice_summary: 'Rechnungszusammenfassung',
  sub_total: 'Zwischensumme',
  shipping: 'Versand',
  cookie_settings: 'Cookie-Einstellungen',
  essential_cookies: 'Grundlegende Cookies',
  analytical_cookies: 'Analytische Cookies',
  marketing_cookies: 'Marketing-Cookies',
  active: 'Aktiv',
  always_active: 'Immer aktiv',
  cookie_settings_description:
    'Unsere Website enthält drei Ebenen von Cookies. Sie können Ihre Einstellungen jederzeit anpassen. Wenn Sie weitere Informationen darüber wünschen, was Cookies sind und welche Cookies wir sammeln, lesen Sie bitte unsere Cookie-Richtlinien.',
  essential_cookies_description:
    'Funktionscookies sind wichtige Cookies, die sicherstellen, dass die Website ordnungsgemäß funktioniert und Ihre Einstellungen (z. B. Sprache, Region) gespeichert werden.',
  analytical_cookies_description: `Analytical cookies allow us to analyse website use and to improve the visitor's experience.`,
  marketing_cookies_description:
    'Das Ziel von Marketing-Cookies ist es, Ihre Erfahrung zu personalisieren und Ihnen relevante Inhalte und Angebote auf dieser Website und anderen Websites zu senden.',
  agree: 'Zustimmen',
  cookie_notice:
    'Diese Website verwendet Cookies, um Ihr Surferlebnis zu verbessern. Durch Klicken auf "Zustimmen" akzeptieren Sie die Verwendung dieser Cookies.',
  firstName_name: 'Vorname *',
  lastName_name: 'Nachname *',
  company_name: 'Name der Firma',
  email_name: 'Email *',
  phone_name: 'Telefon *',
  vatNumber_name: 'Umsatzsteuernummer',
  address1_name: 'Straße / Gebäude *',
  zip_name: 'PLZ *',
  city_name: 'Stadt *',
  province_name: 'Bundesstaat / Provinz',
  countryCode_name: 'Land *',
  firstName_placeholder: 'Vorname',
  lastName_placeholder: 'Nachname',
  company_placeholder: 'Name der Firma',
  email_placeholder: 'Email',
  phone_placeholder: 'Telefon',
  vatNumber_placeholder: 'Umsatzsteuernummer',
  address1_placeholder: 'Straße / Gebäude',
  zip_placeholder: 'PLZ',
  city_placeholder: 'Stadt',
  province_placeholder: 'Bundesstaat / Provinz',
  countryCode_placeholder: 'Land',
  message: 'Nachricht',
}
