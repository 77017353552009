import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
const uuidv4 = require('uuid/v4');

const PageSlug = ({ classes, languages, page, pageId, updateContentPage }) => {
  return (
    <Grid container>
      {languages.map((language, index) => {
        var slugText = '';
        try {
          slugText = getTextFromLanguage(page.slugs, language);
        } catch (error) {}
        return (
          <Grid
            container
            key={'style-slugText-' + index}
            direction="row"
            alignItems="flex-end"
            spacing={1}
            className={classes.container}
          >
            <Grid item xs={10}>
              <TextField
                label="Slug/URL"
                placeholder="Enter slug"
                multiline={true}
                rowsMax="4"
                value={slugText}
                onChange={e => {
                  updateContentPage({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      updateContentPage: {
                        ...page,
                        slugs: languages.map((tempSlug, index) => {
                          return {
                            langCode: tempSlug,
                            content:
                              tempSlug === language
                                ? e.target.value
                                  ? e.target.value
                                  : null
                                : getTextFromLanguage(page.slugs, tempSlug),
                            __typename: 'SlugLocalised'
                          };
                        })
                      }
                    },
                    variables: {
                      id: pageId,
                      input: {
                        slugs: languages.map((tempSlug, index) => {
                          return {
                            langCode: tempSlug,
                            content:
                              tempSlug === language
                                ? e.target.value
                                  ? e.target.value
                                  : null
                                : getTextFromLanguage(page.slugs, tempSlug)
                          };
                        })
                      }
                    }
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  height: 38,
                  width: '100%',
                  border: '1px solid #ddd',
                  borderRadius: '3px'
                }}
              >
                {language}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PageSlug;
