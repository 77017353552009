import {
  CONTENT_MODEL_STATUS_FIELD_FRAGMENT,
  CONTENT_MODEL_MODELS_FRAGMENT,
} from './fragments'
import gql from 'graphql-tag'

export const ADMIN_UPDATE_CONTENT_MODEL_STATUS = gql`
  mutation updateContentModelStatus($id: ID!, $input: ContentModelInput) {
    updateContentModelStatus(id: $id, input: $input) {
      ...contentModelStatusFieldFragment
    }
  }
  ${CONTENT_MODEL_STATUS_FIELD_FRAGMENT}
`

export const ADMIN_UPDATE_THEME_ID = gql`
  mutation updateShopContentSettings(
    $id: ID!
    $input: ShopContentSettingsInput!
  ) {
    updateShopContentSettings(id: $id, input: $input) {
      id
      contentSettings {
        themeId
      }
    }
  }
`

export const CREATE_LEAD_MUTATION = gql`
  mutation createLead($input: LeadCreateInput!) {
    createLead(input: $input) {
      id
    }
  }
`

export const ADMIN_UPDATE_CONTENT_MODEL = gql`
  mutation updateContentModel(
    $revisionNo: Int!
    $modelId: ID!
    $input: ContentModelRevisionInput
  ) {
    updateContentModel(
      revisionNo: $revisionNo
      modelId: $modelId
      input: $input
    ) {
      id
      name {
        id
        text {
          langCode
          content
        }
      }
      description {
        id
        text {
          langCode
          content
        }
      }
      pageTitle {
        id
        text {
          langCode
          content
        }
      }
      headerCustomCode
      footerCustomCode
      openGraphImageSrc
    }
  }
`

export const UPDATE_HOME_PAGE = gql`
  mutation contentUpdateHomePage($id: ID!, $input: ShopContentSettingsInput!) {
    updateShopContentSettings(id: $id, input: $input) {
      id
      contentSettings {
        id
        homePageId
      }
    }
  }
`

export const ADMIN_UPDATE_CONTENT_VIEW_STYLE = gql`
  mutation updateContentViewStyle(
    $id: ID!
    $contentId: ID!
    $input: ContentStyleInput
  ) {
    updateContentViewStyle(id: $id, contentId: $contentId, input: $input) {
      id
      type
      contentId
      activeStyle
      ... on ContentStyleButton {
        padding
        fontSize
        color
        backgroundColor
        fontWeight
        textTransform
        align
      }
    }
  }
`

export const ADMIN_UPDATE_FIELD_ORDER = gql`
  mutation updateContentFieldSortOrder(
    $modelId: ID!
    $fieldId1: ID!
    $fieldId2: ID!
    $revisionNo: Int!
  ) {
    updateContentFieldSortOrder(
      modelId: $modelId
      fieldId1: $fieldId1
      fieldId2: $fieldId2
      revisionNo: $revisionNo
    )
  }
`

export const ADMIN_CREATE_CONTENT_VIEW = gql`
  mutation createContentViews(
    $modelId: ID!
    $revisionNo: Int!
    $fieldId: ID!
    $contentHeader: ContentHeaderInput
    $input: [ContentViewInput]
  ) {
    createContentViews(
      revisionNo: $revisionNo
      fieldId: $fieldId
      input: $input
      modelId: $modelId
      contentHeader: $contentHeader
    ) {
      id
      type
      fieldId
      type
      column
      ... on ContentViewButton {
        id
        type
        sortOrder
        buttonUrl
        buttonText {
          id
          text {
            content
            langCode
          }
        }
        contentStyle {
          id
          type
          contentId
          activeStyle
          ... on ContentStyleButton {
            padding
            fontSize
            color
            backgroundColor
            fontWeight
            textTransform
          }
        }
      }
      ... on ContentViewText {
        id
        type
        sortOrder
        contentText {
          id
          text {
            content
            langCode
          }
        }
        contentStyle {
          id
          type
          contentId
          activeStyle
          ... on ContentStyleText {
            fontFamily
          }
        }
      }
      ... on ContentViewProduct {
        id
        type
        sortOrder
        productIds
        variant
        products {
          id
          active
          shopId
          media {
            sortOrder
            image
          }
        }
        contentStyle {
          id
          type
          contentId
          activeStyle
          ... on ContentStyleProduct {
            fontFamily
            buttonAlign
          }
        }
      }
    }
  }
`

export const ADMIN_UPDATE_CONTENT_VIEW = gql`
  mutation updateContentView(
    $id: ID!
    $revisionNo: Int!
    $fieldId: ID!
    $input: ContentViewInput!
  ) {
    updateContentView(
      id: $id
      revisionNo: $revisionNo
      fieldId: $fieldId
      input: $input
    ) {
      id
      type
      fieldId
      type
      column
      ... on ContentViewButton {
        id
        type
        sortOrder
        buttonUrl
        buttonText {
          id
          text {
            content
            langCode
          }
        }
        contentStyle {
          id
          type
          contentId
          activeStyle
          ... on ContentStyleButton {
            padding
            fontSize
            color
            backgroundColor
            fontWeight
            textTransform
          }
        }
      }
      ... on ContentViewText {
        id
        type
        sortOrder
        contentText {
          id
          text {
            content
            langCode
          }
        }
        contentStyle {
          id
          type
          contentId
          activeStyle
          ... on ContentStyleText {
            fontFamily
          }
        }
      }
      ... on ContentViewProduct {
        id
        type
        sortOrder
        productIds
        variant
        products {
          id
          active
          shopId
          media {
            sortOrder
            image
          }
        }
        contentStyle {
          id
          type
          contentId
          activeStyle
          ... on ContentStyleProduct {
            fontFamily
            buttonAlign
          }
        }
      }
    }
  }
`

export const ADMIN_CREATE_CONTENT_FIELD = gql`
  mutation createContentField(
    $revisionNo: Int!
    $modelId: ID!
    $input: ContentFieldInput
  ) {
    createContentField(
      revisionNo: $revisionNo
      modelId: $modelId
      input: $input
    ) {
      id
      modelId
      sortOrder
      backgroundColor
      columns
      maxWidth
      padding
      justify
      alignItems
      header {
        alignment
        text {
          id
          defaultValue
          text {
            content
            langCode
          }
        }
      }
      contentView {
        id
        type
        fieldId
        type
        column
        ... on ContentViewModule {
          id
          type
          sortOrder
          moduleType
        }
        ... on ContentViewMaps {
          id
          type
          sortOrder
          latitude
          longitude
          place
        }
        ... on ContentViewForm {
          id
          type
          sortOrder
          formVariant
          portalId
          formId
          target
          cssClass
          formTitle {
            id
            defaultValue
            text {
              langCode
              content
            }
          }
        }
        ... on ContentViewImage {
          id
          type
          sortOrder
          contentImage
          imageAlign
          imageTitle {
            id
            defaultValue
            text {
              langCode
              content
            }
          }
          imageButton {
            id
            defaultValue
            text {
              langCode
              content
            }
          }
          alignContent
          imageButtonUrl
          imageSubTitle {
            id
            defaultValue
            text {
              langCode
              content
            }
          }
          contentStyle {
            ... on ContentStyleImage {
              id
              contentId
              activeStyle
              width
              height
              fontFamily
              objectFit
              copyrightContent {
                id
                defaultValue
                text {
                  content
                  langCode
                }
              }
              maxWidth
              maxHeight
              titleAlign
              subTitleAlign
              buttonAlign
              buttonVariant
              buttonColor
              buttonBackgroundColor
              buttonBorderColor
              titleColor
              subTitleColor
              objectPosition
            }
          }
        }
        ... on ContentViewButton {
          id
          type
          sortOrder
          buttonUrl
          buttonText {
            id
            defaultValue
            text {
              langCode
              content
            }
          }
          contentStyle {
            id
            type
            contentId
            activeStyle
            ... on ContentStyleButton {
              padding
              fontSize
              color
              backgroundColor
              fontWeight
              textTransform
              align
              variant
            }
          }
        }
        ... on ContentViewText {
          id
          type
          sortOrder
          contentText {
            id
            defaultValue
            text {
              langCode
              content
            }
          }
          contentStyle {
            id
            type
            contentId
            activeStyle
            ... on ContentStyleText {
              fontFamily
            }
          }
        }
        ... on ContentViewCollection {
          id
          type
          sortOrder
          collectionIds
          buttonText {
            id
            defaultValue
            text {
              langCode
              content
            }
          }
          collectionTitle {
            id
            defaultValue
            text {
              langCode
              content
            }
          }
          cardsPerRow
          collections {
            id
            accountingId
            no
            title {
              id
              defaultValue
              text {
                langCode
                content
              }
            }
            slugs {
              langCode
              content
            }
            thumbnail
            mainBanner
          }
          contentStyle {
            id
            type
            contentId
            activeStyle
            ... on ContentStyleCollection {
              fontFamily
              buttonAlign
            }
          }
        }
        ... on ContentViewTeamstore {
          id
          type
          sortOrder
          teamstoreIds
          teamstoreTitle {
            id
            defaultValue
            text {
              content
              langCode
            }
          }
          teamstores {
            id
            enabled
            name
            type
            privacyType
            contactPerson
            contactEmail
            contactPhone
            logo
            mainBanner
            availableFrom
            slugs {
              content
              langCode
            }
            title {
              id
              defaultValue
              text {
                content
                langCode
              }
            }
            availableUntil
            introductionText {
              id
              defaultValue
              text {
                content
                langCode
              }
            }
          }
        }
        ... on ContentViewProduct {
          id
          type
          sortOrder
          productIds
          variant
          productTitle {
            id
            defaultValue
            text {
              langCode
              content
            }
          }
          cardsPerRow
          products {
            id
            active
            shopId
            media {
              sortOrder
              image
            }
            slugs {
              content
              langCode
            }
            title {
              id
              defaultValue
              text {
                langCode
                content
              }
            }
            description {
              id
              defaultValue
              text {
                langCode
                content
              }
            }
            collections {
              collection {
                id
                no
                slugs {
                  langCode
                  content
                }
                title {
                  id
                  defaultValue
                  text {
                    langCode
                    content
                  }
                }
              }
            }
            discounts {
              discount {
                id
                canBeCombinedWithOtherDiscounts
                availableFrom
                availableUntil
                enabled
                hasCoupon
                discountType
                discountValue
                discountCurrency
                discountRules {
                  id
                  operator
                  discountRuleObjectType
                  discountRuleCondition
                }
              }
            }
            price {
              roundUp
              currency
              value
              includesTax
              taxPercent
            }
            accountingId
            optionValues {
              id
              isEnabled
              sku
              inventory
              barcode
              sortOrder
              optionValues
              variantIds
              optionValueIds
              createdAt
              updatedAt
              modifiers {
                id
                type
                stringModifier
                priceChange {
                  currency
                  value
                }
              }
              media {
                id
                sortOrder
                isThumbnail
                isBanner
                image
              }
            }
            variants {
              id
              options
              variant {
                id
                title {
                  id
                  defaultValue
                  text {
                    langCode
                    content
                  }
                }
                options {
                  id
                  value
                  sortOrder
                  title {
                    id
                    defaultValue
                    text {
                      langCode
                      content
                    }
                  }
                }
              }
            }
          }
          contentStyle {
            id
            type
            contentId
            activeStyle
            ... on ContentStyleProduct {
              fontFamily
              buttonAlign
            }
          }
        }
      }
    }
  }
`

export const ADMIN_UPDATE_CONTENT_FIELD = gql`
  mutation updateContentField(
    $id: ID!
    $revisionNo: Int!
    $modelId: ID!
    $input: ContentFieldInput
  ) {
    updateContentField(
      id: $id
      modelId: $modelId
      revisionNo: $revisionNo
      input: $input
    ) {
      id
      modelId
      sortOrder
      revisionNo
      backgroundColor
      maxWidth
      padding
      justify
      alignItems
      columns
    }
  }
`

export const ADMIN_DELETE_CONTENT_MODEL = gql`
  mutation deleteContentModel($id: ID!) {
    deleteContentModel(id: $id)
  }
`

export const ADMIN_DELETE_CONTENT_FIELD = gql`
  mutation deleteContentField($revisionNo: Int!, $id: ID!, $modelId: ID!) {
    deleteContentField(revisionNo: $revisionNo, id: $id, modelId: $modelId)
  }
`

export const ADMIN_CREATE_CONTENT_MODEL = gql`
  mutation createContentModel($salesChannelId: ID!, $input: ContentModelInput) {
    createContentModel(salesChannelId: $salesChannelId, input: $input) {
      ...contentModelStatusFieldFragment
      ...contentModelModelsFragment
    }
  }
  ${CONTENT_MODEL_MODELS_FRAGMENT}
  ${CONTENT_MODEL_STATUS_FIELD_FRAGMENT}
`
export const ADMIN_CREATE_DEFAULT_PAGES = gql`
  mutation createDefaultPages($ownedByOrganisationId: ID!) {
    createDefaultPages(ownedByOrganisationId: $ownedByOrganisationId)
  }
`
