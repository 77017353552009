import gql from 'graphql-tag'

export const TEXT_CONTENT_FIELDS_FRAGMENT = gql`
  fragment TextContentFields on Text {
    id
    text {
      content
      langCode
    }
  }
`

export const BOARD_ELEMENT_DESIGN_FIELDS_FRAGMENT = gql`
  fragment BoardElementDesignFields on BoardElementDesign {
    id
    boardProductId
    baseElementId
    baseElementCode
    baseElementName
    baseElementFullCode
    baseElementELM
    customDesignName
    colorId
    colorRGB {
      r
      g
      b
    }
    fillType
    aopName
    aopUrl
    aopTransform {
      aopScale
      aopOffsetX
      aopOffsetY
      aopRotate
    }
    colorType
    selectedFabricId
  }
`

export const PRICING_RULE_FIELDS_FRAGMENT = gql`
  fragment PricingRuleFields on PricingRule {
    id
    linkedType
    minOrderQuantity
    calculationType
    formula {
      priority
      price {
        roundUp
        currency
        value
      }
      qty
      sqm
    }
  }
`
export const PRINTING_TECHNIQUE_FIELDS_FRAGMENT = gql`
  fragment PrintingTechniqueFields on PrintingTechnique {
    id
    procedures
    previewImage
    customName {
      id
      ...TextContentFields
    }
    description {
      id
      ...TextContentFields
    }
    component {
      id
      ...TextContentFields
    }
    thickness {
      id
      ...TextContentFields
    }
    washability {
      id
      ...TextContentFields
    }
    colorOpacity
    colorIds
    colors {
      id
      name
      colorRGB {
        r
        g
        b
      }
      hex
    }
    fixCustomerCost {
      ...PricingRuleFields
    }
    printingCost {
      ...PricingRuleFields
    }
  }
  ${TEXT_CONTENT_FIELDS_FRAGMENT}
  ${PRICING_RULE_FIELDS_FRAGMENT}
`

export const DECAL_ATTRIBUTES_FIELDS_FRAGMENT = gql`
  fragment DecalAttributesFields on DecalAttributes {
    decalName
    objectName
    attachPosition {
      x
      y
      z
    }
    objectType
    orientation {
      x
      y
      z
      _order
    }
    size {
      x
      y
      z
    }
    colorRGB {
      r
      g
      b
    }
  }
`

export const BOARD_ITEM_LOGO_FIELDS_FRAGMENT = gql`
  fragment BoardItemLogoFields on BoardItemLogo {
    baseLogoId
    baseLogo {
      name
      ownedByOrganisationId
    }
    ownedByOrganisationId # TODO this might be temporary
    logoDesign {
      name
      printingTechniqueId
      src
      width
      height
      colorHex
      originalWidth
      originalHeight
      threejsWidth
      threejsHeight
    }
  }
`

export const BOARD_ITEM_TEXT_FIELDS_FRAGMENT = gql`
  fragment BoardItemTextFields on BoardItemText {
    textDesign {
      content
      fontFamily
      colorType
      colorHex
      colorId
      printingTechniqueId
      width
      height
      originalWidth
      originalHeight
      threejsWidth
      threejsHeight
    }
  }
`

export const DECAL_FIELDS_FRAGMENT = gql`
  fragment DecalFields on Decal {
    decalName
    objectName
    attachPosition
    decalRotate
    decalScale
    objectType
    orientation
    size
    rawData
    colorRGB {
      r
      g
      b
    }
  }
`

export const BOARD_PRODUCT_DESIGN_FIELDS_FRAGMENT = gql`
  fragment BoardProductDesignFields on BoardProductDesign {
    boardTexts {
      id
      type
      createdAt
      updatedAt
      ...BoardItemTextFields
      decal {
        ...DecalFields
      }
      decalValues {
        ...DecalAttributesFields
      }
    }
    boardLogos {
      id
      type
      createdAt
      updatedAt
      ...BoardItemLogoFields
      decal {
        ...DecalFields
      }
      decalValues {
        ...DecalAttributesFields
      }
    }
    boardPrints {
      id
      fileName
      src
      preview
      createdAt
      updatedAt
    }
  }
  ${BOARD_ITEM_TEXT_FIELDS_FRAGMENT}
  ${BOARD_ITEM_LOGO_FIELDS_FRAGMENT}
  ${DECAL_ATTRIBUTES_FIELDS_FRAGMENT}
  ${DECAL_FIELDS_FRAGMENT}
`

export const CART_FIELDS_FRAGMENT = gql`
  fragment CartFields on Cart {
    id
    couponCode
    lastAddToCartUrl
    paymentSourceId
    paymentMethod
    userReferences
    shopId
    seasonName
    collectionName
    shippingAddressId
    shippingAddress {
      id
      ownedByOrganisationId
      address1
      city
      company
      vatNumber
      countryCode
      firstName
      lastName
      email
      phone
      province
      zip
      isValidAddress
    }
    billingAddressId
    billingAddress {
      id
      ownedByOrganisationId
      address1
      city
      company
      vatNumber
      countryCode
      firstName
      lastName
      email
      phone
      province
      zip
      isValidAddress
    }
    ownedByOrganisationId
    shopId
    seasonName
    collectionName
    shippingMethodId
    ownedByOrganisationId
    totalCalculated {
      appliedCoupons {
        discountDeduction {
          currency
          value
          includesTax
          taxPercent
        }
        linkedCoupon {
          id
          couponCode
        }
      }
      appliedDiscounts {
        coupon {
          id
          couponCode
        }
        discountDeduction {
          currency
          value
          includesTax
          taxPercent
        }
        linkedDiscount {
          id
          accountingId
          title {
            id
            text {
              langCode
              content
            }
          }
          priority
          enabled
          hasCoupon
          enablesFreeShipping
          canBeCombinedWithOtherDiscounts
          discountType
          productsGetForFree
          freeProducts {
            id
            media {
              id
              sortOrder
              isThumbnail
              isBanner
              image
            }
            title {
              id
              text {
                content
                langCode
              }
            }
          }
          discountValue
          discountCurrency
          availableFrom
          availableUntil
          discountRules {
            id
            discountId
            operator
            discountRuleValue
            discountRuleCurrency
            discountRuleObjects {
              id
              type
              objectId
            }
            discountRuleObjectType
            discountRuleCondition
          }
          internalNote
        }
      }
      cartTotalPrice {
        roundUp
        currency
        value
        includesTax
        taxPercent
      }
      cartTotalinCurrency(currency: $currency) {
        currency
        value
        includesTax
        taxPercent
      }
      cartTotalString
      cartTotalGroups {
        finalTotal {
          currency
          value
          includesTax
          taxPercent
        }
        finalTotalinCurrency(currency: $currency) {
          currency
          value
          includesTax
          taxPercent
        }
        totalPieces
        discountRate
        discountNote
        shippingCost {
          shippingProviderName
          cost {
            currency
            value
            includesTax
            taxPercent
          }
        }
        cartItemTotals {
          id
          qty
          productId
          productOptionValueId
          productOptionValues
          boardProductId
          baseProductId
          fixPrice {
            roundUp
            currency
            value
            includesTax
            taxPercent
          }
          priceNote
          itemTotalBeforeDiscount {
            currency
            value
            includesTax
            taxPercent
          }
          totalBeforeDiscount {
            currency
            value
            includesTax
            taxPercent
          }
          total {
            currency
            value
            includesTax
            taxPercent
          }
        }
      }
    }
    cartItems {
      id
      shopId
      productOptionValueId
      productOptionValues
      productOptionValue {
        id
        sku
        selectedVariants {
          id
          selectedOption {
            id
            title {
              id
              text {
                langCode
                content
              }
            }
          }
          title {
            id
            text {
              langCode
              content
            }
          }
        }
      }
      productId
      quantity
      product {
        id
        title {
          id
          text {
            langCode
            content
          }
        }
        media {
          id
          productId
          variantId
          variantOptionId
          optionValue
          sortOrder
          isThumbnail
          isBanner
          image
        }
      }
      shop {
        tempDiscount {
          orderType
          pricingNote
          leadTimeNote
          leadTimeDays
          minQuantity
          maxQuantity
          multiplier
        }
      }
      boardProductId
      productDesignId
      designStudioVersion
      orderType
      salesChannelTargetCategoryId
      productPackageId
      productPackageProductOptionValues {
        id
        productId
        productOptionValueId
        productOptionValues
      }
      salesChannelGroupId
      customizationOptions {
        id
        customizationGroupId
        options {
          id
          productCustomizationOptionId
          value
        }
      }
      salesChannelId
      boardProduct {
        id
        name
        shopId
        comment
        lastPrice {
          roundUp
          value
          currency
          includesTax
          taxPercent
        }
        baseProduct {
          id
          no
        }
      }
      design {
        elementRevision
        ...BoardProductDesignFields
        elementDesignData {
          ...BoardElementDesignFields
          colorHex
        }
      }
    }
  }
  ${BOARD_ELEMENT_DESIGN_FIELDS_FRAGMENT}
  ${BOARD_PRODUCT_DESIGN_FIELDS_FRAGMENT}
`
