import React, { Component } from 'react';
import {
  Grid,
  Typography,
  Button,
  Paper,
  CircularProgress
} from '@material-ui/core';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Input from '../../../common/Input';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { ADMIN_SHOP_COLLECTIONS } from '../../gql/admin-queries';
import { Query } from 'react-apollo';
import apolloClient from '../../gql/admin-apollo-config';
const uuidv4 = require('uuid/v4');
const omitDeep = require('omit-deep');

class AdminSearchCollection extends Component {
  state = {
    q: '',
    searchCollections: []
  };

  render() {
    const {
      shopId,
      defaultLanguage,
      onSelect,
      excludeIds,
      classes,
      text,
      label,
      width = 'calc(100% - 16px)'
    } = this.props;
    const { q, searchCollections, loading } = this.state;
    var searchResult = [];
    try {
      searchCollections.forEach(pNode => {
        const p = pNode.node;
        if ((p && !excludeIds) || (excludeIds && !excludeIds.includes(p.id))) {
          searchResult.push(
            <Grid item xs={12} onClick={() => onSelect(p)} key={'ps-' + p.id}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start'
                }}
              >
                {p.media ? (
                  <img
                    loading="lazy"
                    src={
                      p.media.find(image => image && image.isThumbnail === true)
                        .image
                    }
                    alt={p.no}
                    style={{
                      width: '40px',
                      height: '100%',
                      objectFit: 'contain',
                      marginRight: '1em'
                    }}
                  />
                ) : null}
                {getTextFromLanguage(_get(p, 'title.text', []), defaultLanguage)
                  ? getTextFromLanguage(
                      _get(p, 'title.text', []),
                      defaultLanguage
                    )
                  : getTextFromLanguage(_get(p, 'title.text', []), 'fr_FR')
                  ? getTextFromLanguage(_get(p, 'title.text', []), 'fr_FR')
                  : getTextFromLanguage(_get(p, 'title.text', []), 'nl_NL')
                  ? getTextFromLanguage(_get(p, 'title.text', []), 'nl_NL')
                  : null}{' '}
                ({p.no})
              </Button>
            </Grid>
          );
        }
      });
    } catch (error) {}

    return (
      <Grid
        container
        alignItems="flex-start"
        spacing={1}
        style={{ position: 'relative' }}
        onMouseLeave={searchResult === null}
      >
        <Query query={ADMIN_SHOP_COLLECTIONS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            if (loading)
              return <CircularProgress className={classes.progress} />;
            if (error) return <div>Error :(</div>;
            const filteredCollections = _get(shop, 'collections.edges', []);
            return (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <Input
                      style={{ background: '#fff' }}
                      label={label}
                      placeholder={text || 'Enter collection name'}
                      value={q}
                      background="#fff"
                      onChange={async e => {
                        this.setState({
                          q: e.target.value
                        });
                        try {
                          if (e.target.value.length > 1) {
                            this.setState({
                              loading: true
                            });
                            const result = filteredCollections.filter(c => {
                              let hasTitle = false;
                              try {
                                hasTitle =
                                  getTextFromLanguage(
                                    c.node.title.text,
                                    defaultLanguage
                                  ) &&
                                  getTextFromLanguage(
                                    c.node.title.text,
                                    defaultLanguage
                                  )
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase());
                              } catch (error) {
                                console.error('error', error);
                              }
                              return hasTitle;
                            });
                            this.setState({
                              loading: false,
                              searchCollections: result
                            });
                          } else {
                            this.setState({
                              loading: false,
                              searchCollections: []
                            });
                          }
                        } catch (error) {
                          console.error(error);
                          this.setState({
                            loading: false
                          });
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </Query>

        {searchResult && searchResult.length === 0 ? null : (
          <div>
            {loading ? (
              'Searching...'
            ) : (
              <Paper
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: '50%',
                  maxHeight: 300,
                  overflowY: 'auto',
                  marginTop: 8,
                  width: width,
                  zIndex: 400,
                  transform: 'translate(-50%, 0px)'
                }}
              >
                <Grid container spacing={2} style={{ padding: 24 }}>
                  {searchResult && searchResult.length > 0
                    ? searchResult
                    : q
                    ? 'No results'
                    : ''}
                </Grid>
              </Paper>
            )}
          </div>
        )}
      </Grid>
    );
  }
}

export default AdminSearchCollection;
