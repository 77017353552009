import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Auth from '@aws-amplify/auth';
import { Link } from 'gatsby';
const styles = theme => ({
  root: {
    background: '#E5E5E5',
    height: 'calc(100vh - 50px)',
    padding: '25px 15px',
    position: 'relative'
  },
  input: {
    background: '#f4f4f4 !important',
    margin: '16px 0px !important',
    width: '300px',
    padding: '0px !important'
  },
  button: {
    width: '300px',
    minHeight: '45px',
    margin: '24px 0px !important'
  },
  textInput: {
    padding: '13px 24px'
  },
  forgotPassword: {
    position: 'absolute',
    bottom: '-4px',
    right: 0,
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    ':hover': {
      color: '#ffffff !important'
    }
  },
  signup: {
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    ':hover': {
      color: '#ffffff !important'
    }
  }
});

class AdminVerify extends React.Component {
  static defaultProps = {
    authData: {},
    authState: 'signIn'
    // onAuthStateChange: (next, data) => { console.log(`SignIn:onAuthStateChange(${next}, ${JSON.stringify(data, null, 2)})`); }
  };

  constructor(props) {
    super(props);
    this.onVerify = this.onVerify.bind(this);
    this.state = {
      authData: this.props.authData,
      authState: this.props.authState,
      modalShowing: false,
      loading: false,
      error: null,
      email: '',
      password: '',
      user: null
    };
  }

  async onVerify() {
    this.setState({ loading: true });
    try {
      //   const email = this.state.email;
      Auth.verifyCurrentUserAttribute('email')
        .then(data => {
          console.log(data);
          //   this.props.currentUser.changeEmail(email);
          navigate(`/admin/confirm`);
        })
        .catch(err => {
          console.error(err);
          this.setState({ error: err.message, loading: false });
        });
    } catch (err) {
      console.error(`Error: ${JSON.stringify(err, null, 2)}`);
      this.setState({ error: err.message, loading: false });
    }
  }

  updateEmail = e => {
    this.setState({
      email: e.target.value
    });
  };

  async componentDidMount() {
    // const {currentUser}=this.props
    const cognitoUser = await Auth.currentAuthenticatedUser();
    if (cognitoUser) {
      this.setState({
        email: cognitoUser.attributes.email
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.root}
      >
        <h3>Verify your account</h3>
        {this.state.error ? (
          <div>
            <div>{this.state.error}</div>
          </div>
        ) : null}
        {/* <Inputs
          data-html2canvas-ignore
          data-cy="email"
          ref="email"
          type="email"
          value={this.state.email}
          onChange={val => this.updateEmail(val)}
          placeholder="Email"
          classes={{ root: classes.input, input: classes.textInput }}
        />         */}
        {this.state.loading === true ? (
          <Button className={classes.button} title="Verify" disabled={true}>
            Loading
          </Button>
        ) : (
          <Button
            data-cy="signUp"
            title="Verify"
            onClick={this.onVerify.bind(this)}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Send verification email
          </Button>
        )}

        <Grid container justify="center">
          <Link to={`/admin/login`} className={classes.signup}>
            Already have an account yet? Login!
          </Link>
        </Grid>
      </Grid>
    );
  }
}

AdminVerify.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminVerify);
