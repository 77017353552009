import React, { Component } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../../common/Select';
import { Link } from 'gatsby';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_SHOP_COLLECTIONS } from '../../gql/admin-queries';
import {
  ADMIN_CREATE_COLLECTION_PRODUCT,
  ADMIN_DELETE_COLLECTION_PRODUCT
} from '../../gql/admin-mutations';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import GlobalStyles from '../../../globalStyles';

class ProductAttributeIcon extends Component {
  state = {
    selectedCollectionId: null
  };
  updateIcon = icon => {
    this.props.updateProductAttribute({
      optimisticResponse: {
        __typename: 'Mutation',
        updateProductAttribute: {
          ...this.props.productAttribute,
          icon: icon
        }
      },
      variables: {
        id: this.props.productAttributeId,
        input: { icon: icon }
      }
    });
  };
  render() {
    const {
      classes,
      productAttribute,
      productAttributeId,
      updateProductAttribute
    } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="subtitle1">Icon</Typography>
          <GlobalStyles />
          <div
            style={{
              width: '100%'
            }}
          >
            <span
              onClick={e => {
                this.updateIcon('back');
              }}
              style={{
                color: `${productAttribute.icon === 'back' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--back`}
            />
            <span
              onClick={e => {
                this.updateIcon('prev');
              }}
              style={{
                color: `${productAttribute.icon === 'prev' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--prev`}
            />
            <span
              onClick={e => {
                this.updateIcon('up');
              }}
              style={{
                color: `${productAttribute.icon === 'up' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--up`}
            />
            <span
              onClick={e => {
                this.updateIcon('down');
              }}
              style={{
                color: `${productAttribute.icon === 'down' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--down`}
            />
            <span
              onClick={e => {
                this.updateIcon('close');
              }}
              style={{
                color: `${productAttribute.icon === 'close' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--close`}
            />
            <span
              onClick={e => {
                this.updateIcon('add');
              }}
              style={{
                color: `${productAttribute.icon === 'add' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--add`}
            />
            <span
              onClick={e => {
                this.updateIcon('fb');
              }}
              style={{
                color: `${productAttribute.icon === 'fb' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--fb`}
            />
            <span
              onClick={e => {
                this.updateIcon('tw');
              }}
              style={{
                color: `${productAttribute.icon === 'tw' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--tw`}
            />
            <span
              onClick={e => {
                this.updateIcon('inst');
              }}
              style={{
                color: `${productAttribute.icon === 'inst' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--inst`}
            />
            <span
              onClick={e => {
                this.updateIcon('yt');
              }}
              style={{
                color: `${productAttribute.icon === 'yt' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--yt`}
            />
            <span
              onClick={e => {
                this.updateIcon('email');
              }}
              style={{
                color: `${productAttribute.icon === 'email' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--email`}
            />
            <span
              onClick={e => {
                this.updateIcon('location');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'location' ? 'green' : '#000'
                }`
              }}
              className={`${classes.feature_icon} icon--product icon--location`}
            />
            <span
              onClick={e => {
                this.updateIcon('doc');
              }}
              style={{
                color: `${productAttribute.icon === 'doc' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--doc`}
            />
            <span
              onClick={e => {
                this.updateIcon('logo');
              }}
              style={{
                color: `${productAttribute.icon === 'logo' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--logo`}
            />
            <span
              onClick={e => {
                this.updateIcon('user');
              }}
              style={{
                color: `${productAttribute.icon === 'user' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--user`}
            />
            <span
              onClick={e => {
                this.updateIcon('cart');
              }}
              style={{
                color: `${productAttribute.icon === 'cart' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--cart`}
            />
            <span
              onClick={e => {
                this.updateIcon('card');
              }}
              style={{
                color: `${productAttribute.icon === 'card' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--card`}
            />
            <span
              onClick={e => {
                this.updateIcon('lock');
              }}
              style={{
                color: `${productAttribute.icon === 'lock' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--lock`}
            />
            <span
              onClick={e => {
                this.updateIcon('search');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'search' ? 'green' : '#000'
                }`
              }}
              className={`${classes.feature_icon} icon--product icon--search`}
            />
            <span
              onClick={e => {
                this.updateIcon('del');
              }}
              style={{
                color: `${productAttribute.icon === 'del' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--del`}
            />
            <span
              onClick={e => {
                this.updateIcon('plus');
              }}
              style={{
                color: `${productAttribute.icon === 'plus' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--plus`}
            />
            <span
              onClick={e => {
                this.updateIcon('info');
              }}
              style={{
                color: `${productAttribute.icon === 'info' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--info`}
            />
            <span
              onClick={e => {
                this.updateIcon('check');
              }}
              style={{
                color: `${productAttribute.icon === 'check' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--check`}
            />
            <span
              onClick={e => {
                this.updateIcon('assignment');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'assignment' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--assignment`}
            />
            <span
              onClick={e => {
                this.updateIcon('checkbox-checked');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'checkbox-checked'
                    ? 'green'
                    : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--checkbox-checked`}
            />
            <span
              onClick={e => {
                this.updateIcon('checkbox-unchecked');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'checkbox-unchecked'
                    ? 'green'
                    : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--checkbox-unchecked`}
            />
            <span
              onClick={e => {
                this.updateIcon('kids');
              }}
              style={{
                color: `${productAttribute.icon === 'kids' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--kids`}
            />
            <span
              onClick={e => {
                this.updateIcon('women');
              }}
              style={{
                color: `${productAttribute.icon === 'women' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--women`}
            />
            <span
              onClick={e => {
                this.updateIcon('man');
              }}
              style={{
                color: `${productAttribute.icon === 'man' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--man`}
            />
            <span
              onClick={e => {
                this.updateIcon('antibacterial');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'antibacterial' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--antibacterial`}
            />
            <span
              onClick={e => {
                this.updateIcon('breathable');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'breathable' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--breathable`}
            />
            <span
              onClick={e => {
                this.updateIcon('comfort-fit');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'comfort-fit' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--comfort-fit`}
            />
            <span
              onClick={e => {
                this.updateIcon('compression');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'compression' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--compression`}
            />
            <span
              onClick={e => {
                this.updateIcon('extreme-weather');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'extreme-weather' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--extreme-weather`}
            />
            <span
              onClick={e => {
                this.updateIcon('flatlock-stitching');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'flatlock-stitching'
                    ? 'green'
                    : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--flatlock-stitching`}
            />
            <span
              onClick={e => {
                this.updateIcon('lightweight');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'lightweight' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--lightweight`}
            />
            <span
              onClick={e => {
                this.updateIcon('racing-fit');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'racing-fit' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--racing-fit`}
            />
            <span
              onClick={e => {
                this.updateIcon('silicone-strip');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'silicone-strip' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--silicone-strip`}
            />
            <span
              onClick={e => {
                this.updateIcon('slim-fit');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'slim-fit' ? 'green' : '#000'
                }`
              }}
              className={`${classes.feature_icon} icon--product icon--slim-fit`}
            />
            <span
              onClick={e => {
                this.updateIcon('visibility');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'visibility' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--visibility`}
            />
            <span
              onClick={e => {
                this.updateIcon('warm');
              }}
              style={{
                color: `${productAttribute.icon === 'warm' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--warm`}
            />
            <span
              onClick={e => {
                this.updateIcon('water-repellent');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'water-repellent' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--water-repellent`}
            />
            <span
              onClick={e => {
                this.updateIcon('waterproof');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'waterproof' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--waterproof`}
            />
            <span
              onClick={e => {
                this.updateIcon('windproof');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'windproof' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--windproof`}
            />
            <span
              onClick={e => {
                this.updateIcon('windtunnel-tested');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'windtunnel-tested'
                    ? 'green'
                    : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--windtunnel-tested`}
            />
            <span
              onClick={e => {
                this.updateIcon('3d-pad');
              }}
              style={{
                color: `${
                  productAttribute.icon === '3d-pad' ? 'green' : '#000'
                }`
              }}
              className={`${classes.feature_icon} icon--product icon--3d-pad`}
            />
            <span
              onClick={e => {
                this.updateIcon('elite-pad');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'elite-pad' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--elite-pad`}
            />
            <span
              onClick={e => {
                this.updateIcon('ld-pad');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'ld-pad' ? 'green' : '#000'
                }`
              }}
              className={`${classes.feature_icon} icon--product icon--ld-pad`}
            />
            <span
              onClick={e => {
                this.updateIcon('prism-pad');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'prism-pad' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--prism-pad`}
            />
            <span
              onClick={e => {
                this.updateIcon('heat-retention');
              }}
              style={{
                color: `${
                  productAttribute.icon === 'heat-retention' ? 'green' : '#000'
                }`
              }}
              className={`${
                classes.feature_icon
              } icon--product icon--heat-retention`}
            />
            <span
              onClick={e => {
                this.updateIcon('prr');
              }}
              style={{
                color: `${productAttribute.icon === 'prr' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--prr`}
            />
            <span
              onClick={e => {
                this.updateIcon('spl');
              }}
              style={{
                color: `${productAttribute.icon === 'spl' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--spl`}
            />
            <span
              onClick={e => {
                this.updateIcon('esl');
              }}
              style={{
                color: `${productAttribute.icon === 'esl' ? 'green' : '#000'}`
              }}
              className={`${classes.feature_icon} icon--product icon--esl`}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default ProductAttributeIcon;
