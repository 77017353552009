import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography, Switch } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';

import { BORDER_TYPES } from './config';

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase);

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    border: '1px solid #0000001f'
  },
  input: {
    paddingLeft: 8,
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  }
});

class UpdateLocationCard extends React.Component {
  state = {
    uploading: false
  };

  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,
      handleChangeTextField,
      fields
    } = this.props;

    return (
      <ExpansionPanel
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
        expanded={expanded === 'panel2'}
        onChange={handleExpandedChange('panel2')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ expanded: classes.expansionPanelExpanded }}
        >
          <Typography
            variant="subtitle1"
            className={classes.typo}
            data-cy="Location-Card-Settings"
          >
            Location Card Settings
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{ root: classes.expansionPanelDetails }}
        >
          <Divider />
          <List>
            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Shop Name
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    className={classes.input}
                    placeholder=""
                    value={fields.shopName}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'shopName'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Maps url
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    className={classes.input}
                    placeholder=""
                    value={fields.url}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'url'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Address
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    className={classes.input}
                    placeholder=""
                    value={fields.address}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'address'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Website
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    className={classes.input}
                    placeholder=""
                    value={fields.website}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'website'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Email
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    className={classes.input}
                    placeholder=""
                    value={fields.email}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'email'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Phone
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    className={classes.input}
                    placeholder=""
                    value={fields.phone}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'phone'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Open Days
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    className={classes.input}
                    placeholder=""
                    value={fields.openDays}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'openDays'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Open Hours
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    className={classes.input}
                    placeholder=""
                    value={fields.openHours}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'openHours'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Closed Days
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    className={classes.input}
                    placeholder=""
                    value={fields.closedDays}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'closedDays'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Add URL Link
                </InputLabel>
                <Switch
                  checked={fields.hasPremiumDealership}
                  onChange={e =>
                    handleChangeField(
                      e.target.checked ? true : false,
                      'hasPremiumDealership'
                    )
                  }
                  value="checkedA"
                />
              </Grid>
            </ListItem>

            {fields.hasPremiumDealership ? (
              <ListItem>
                <Grid container direction="column">
                  <InputLabel shrink className={classes.FormLabel}>
                    URL Name
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <InputBase
                      className={classes.input}
                      placeholder=""
                      value={fields.dealershipLink}
                      onChange={e =>
                        handleChangeField(
                          e.target.value ? e.target.value : null,
                          'dealershipLink'
                        )
                      }
                    />
                  </Paper>
                </Grid>
              </ListItem>
            ) : null}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

UpdateLocationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
};

export default withStyles(styles)(UpdateLocationCard);
