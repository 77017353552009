import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Notification from 'react-notification';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  ADMIN_SHOP_FABRICS,
  ADMIN_GET_PRODUCT,
  ADMIN_GET_ORG_BOARDPRODUCTS
} from '../../gql/admin-queries';
import {
  ADMIN_UPDATE_PRODUCT,
  ADMIN_UPLOAD_FILE
} from '../../gql/admin-mutations';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import _sortBy from 'lodash/sortBy';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { API_STAGE } from '../../../../../brikl-config';
import { I18n } from '@aws-amplify/core';
import Helmet from 'react-helmet';
import Input from '../../../common/Input';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Delete from '@material-ui/icons/Delete';
import Star from '@material-ui/icons/Star';
import CircularProgress from '@material-ui/core/CircularProgress';

import _get from 'lodash/get';
import './ProductFileDrop.css';
import { DEFAULT_CURRENCY } from '../../../../helper/config';
import FileDrop from 'react-file-drop';
const axios = require('axios');
const shortid = require('shortid');
const uuidv4 = require('uuid/v4');
const jwtDecode = require('jwt-decode');
const omitDeep = require('omit-deep');

class ProductDetailVariantOptionMedia extends Component {
  state = {
    isActive: false,
    message: '',
    isBriklSuperAdmin: false,
    open: false,
    value: 'Yes'
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
  uploadProductVariantOptionMediaFiles = async (
    files,
    uploadFile,
    productId,
    updateProductOptionValue,
    productOptionValue
  ) => {
    try {
      const {} = this.props;
      this.setState({
        // uploading: files.length.toString()
        uploading: true
      });
      for (var i = 0; i < files.length; i++) {
        const myFile = files[i];
        if (myFile.size < 20000000) {
          const fileName =
            myFile.name.replace(/\.[^/.]+$/, '').replace(/\s/g, '-') +
            '-' +
            shortid.generate() +
            '.' +
            myFile.name.split('.').pop();
          const newFile = {
            id: uuidv4(),
            productId: productId,
            sortOrder: 100,
            isThumbnail: false,
            isBanner: false,
            image: fileName
          };
          const signedUrlResult = await uploadFile({
            variables: {
              type: 'PRODUCT_MEDIA',
              fileName,
              prefixVariables: ['productId'],
              input: {
                productId: productId
              }
            }
          });
          // console.log('signedUrlResult', signedUrlResult);
          const signedUrl = signedUrlResult.data.uploadFile.url;
          var options = {
            headers: {
              'Content-Type': signedUrlResult.data.uploadFile.contentType
            }
          };
          if (signedUrlResult.data.uploadFile.acl) {
            options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl;
          }
          if (signedUrlResult.data.uploadFile.tagging) {
            options.headers['x-amz-tagging'] =
              signedUrlResult.data.uploadFile.tagging;
          }
          const uploadResult = await axios.put(signedUrl, myFile, options);
          // refetch();
          // console.log('uploadFile', uploadFile);
          this.setState({
            uploading: false
          });

          await updateProductOptionValue({
            optimisticResponse: {
              __typename: 'Mutation',
              updateProductOptionValue: {
                ...productOptionValue,
                media: productOptionValue.media
                  ? [
                      ...productOptionValue.media,
                      {
                        ...newFile,
                        __typename: 'ProductMedia'
                      }
                    ]
                  : [newFile]
              }
            },
            variables: {
              input: {
                id: productOptionValue.id,
                variantIds: this.props.variantIds,
                optionValueIds: this.props.optionValueIds,
                media: productOptionValue.media
                  ? [
                      ...omitDeep(productOptionValue.media, ['__typename']),
                      newFile
                    ]
                  : [newFile]
              }
            }
          });
          console.log('uploadResult', uploadResult);
        } else {
          this.setState({
            uploading: false
          });
          alert('Only files up to 20MB supported.');
        }
      }
    } catch (error) {
      console.error('uploaderror', error);
      this.setState({
        uploading: false
      });
    }
  };

  render() {
    const {
      blockEdit,
      classes,
      productId,
      updateProductOptionValue,
      productOptionValue,
      variantIds,
      optionValueIds
    } = this.props;
    return (
      <Grid container style={{ padding: 16, background: '#FFF' }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                padding: '0px !important'
              }}
            >
              {this.state.uploading === true ? <CircularProgress /> : null}

              <Mutation
                update={(store, { data: { uploadFile } }) => {}}
                mutation={ADMIN_UPLOAD_FILE}
              >
                {uploadFile => (
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      border: '1px dashed #E5E5E5',
                      background: '#f5f5f5',
                      padding: 0,
                      borderRadius: '5px',
                      marginRight: 8,
                      height: '100%',
                      width: '100%'
                    }}
                  >
                    {/* {JSON.stringify(product)} */}
                    <input
                      style={{
                        display: 'none'
                      }}
                      accept=".png,.jpg,.jpeg"
                      id={'productMediaUploadInput' + productOptionValue}
                      type="file"
                      onChange={e => {
                        this.uploadProductVariantOptionMediaFiles(
                          e.target.files,
                          uploadFile,
                          productId,
                          updateProductOptionValue,
                          productOptionValue
                        );
                      }}
                    />
                    <Grid
                      item
                      xs={12}
                      data-cy="product-media-drag-and-drop-container"
                      style={{
                        padding: '0px',
                        height: '100%',
                        width: '100%'
                      }}
                    >
                      <FileDrop
                        onDrop={(files, event) => {
                          console.log('handleDrop!', files, event);
                          this.uploadProductVariantOptionMediaFiles(
                            files,
                            uploadFile,
                            productId,
                            updateProductOptionValue,
                            productOptionValue
                          );
                        }}
                      >
                        <Grid container direction="column">
                          <Grid item>
                            <Typography variant="body1">
                              {I18n.get('Drag your file here ')}
                              <br />( .png or .jpg )
                            </Typography>
                          </Grid>
                        </Grid>
                      </FileDrop>
                    </Grid>
                  </Grid>
                )}
              </Mutation>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                container
                alignItems="center"
                justify="space-between"
                direction="row"
                spacing={1}
                style={{
                  border: '1px solid #E5E5E5',
                  padding: '8px 8px 4px 8px',
                  borderRadius: '5px',
                  width: '100%',
                  margin: '16px 0px 0px',
                  height: 200
                }}
              >
                {productOptionValue.media
                  ? productOptionValue.media
                      .sort((m1, m2) => m1.sortOrder - m2.sortOrder)
                      .map((mediaItem, index) => {
                        return (
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            key={'style-' + index}
                            style={{
                              position: 'relative',
                              minHeight: 80,
                              marginBottom: 16
                            }}
                          >
                            <div style={{ height: 80, position: 'relative' }}>
                              <img
                                src={mediaItem.image}
                                alt="Storefront"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  display: 'inline',
                                  objectFit: 'contain',
                                  borderRadius: '6px',
                                  border: '1px solid #ccc'
                                }}
                              />
                              <Button
                                color="secondary"
                                variant="contained"
                                style={{
                                  position: 'absolute',
                                  top: 60,
                                  right: 0,
                                  minHeight: 30,
                                  minWidth: 30,
                                  height: 30,
                                  width: 30,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  padding: 0,
                                  borderRadius: '100%'
                                }}
                                onClick={() => {
                                  updateProductOptionValue({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateProductOptionValue: {
                                        ...productOptionValue,
                                        media: productOptionValue.media.filter(
                                          (removeStyle1, removePIindex) => {
                                            if (index === removePIindex) {
                                              return false;
                                            } else {
                                              return true;
                                            }
                                          }
                                        )
                                      }
                                    },
                                    variables: {
                                      id: productId,
                                      input: {
                                        media: omitDeep(
                                          productOptionValue.media.filter(
                                            (removeStyle2, removePIindex2) => {
                                              if (index === removePIindex2) {
                                                return false;
                                              } else {
                                                return true;
                                              }
                                            }
                                          ),
                                          ['__typename']
                                        )
                                      }
                                    }
                                  });
                                }}
                              >
                                <Delete style={{ fontSize: 18 }} />
                              </Button>
                              {mediaItem.isThumbnail ? null : (
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  title={'Make thumbnail'}
                                  style={{
                                    position: 'absolute',
                                    top: 60,
                                    right: 40,
                                    minHeight: 30,
                                    minWidth: 30,
                                    height: 30,
                                    width: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 0,
                                    borderRadius: '100%'
                                  }}
                                  onClick={() => {
                                    updateProductOptionValue({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateProductOptionValue: {
                                          ...productOptionValue,
                                          media: productOptionValue.media.map(
                                            (removeStyle1, removePIindex) => {
                                              if (index === removePIindex) {
                                                return {
                                                  ...removeStyle1,
                                                  isThumbnail: true
                                                };
                                              } else {
                                                return {
                                                  ...removeStyle1,
                                                  isThumbnail: false
                                                };
                                              }
                                            }
                                          )
                                        }
                                      },
                                      variables: {
                                        id: productId,
                                        input: {
                                          media: omitDeep(
                                            productOptionValue.media.map(
                                              (
                                                removeStyle2,
                                                removePIindex2
                                              ) => {
                                                if (index === removePIindex2) {
                                                  return {
                                                    ...removeStyle2,
                                                    isThumbnail: true
                                                  };
                                                } else {
                                                  return {
                                                    ...removeStyle2,
                                                    isThumbnail: false
                                                  };
                                                }
                                              }
                                            ),
                                            ['__typename']
                                          )
                                        }
                                      }
                                    });
                                  }}
                                >
                                  <Star style={{ fontSize: 18 }} />
                                </Button>
                              )}

                              {mediaItem.isThumbnail ? (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  style={{
                                    position: 'absolute',
                                    minHeight: 30,
                                    minWidth: 30,
                                    height: 30,
                                    width: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 0,
                                    borderRadius: '100%',
                                    top: 60,
                                    right: 40
                                  }}
                                >
                                  <Star
                                    style={{ fontSize: 18, color: '#FECC00' }}
                                  />
                                </Button>
                              ) : null}
                            </div>
                            <Grid container style={{ marginTop: 16 }}>
                              <Input
                                label="Sort order"
                                placeholder=""
                                value={mediaItem.sortOrder}
                                onChange={e => {
                                  updateProductOptionValue({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateProductOptionValue: {
                                        ...productOptionValue,
                                        media: productOptionValue.media.map(
                                          (removeStyle1, removePIindex) => {
                                            if (index === removePIindex) {
                                              return {
                                                ...removeStyle1,
                                                sortOrder: parseFloat(
                                                  e.target.value
                                                )
                                              };
                                            } else {
                                              return {
                                                ...removeStyle1
                                              };
                                            }
                                          }
                                        )
                                      }
                                    },
                                    variables: {
                                      id: productOptionValue.id,
                                      variantIds: variantIds,
                                      optionValueIds: optionValueIds,
                                      input: {
                                        media: omitDeep(
                                          productOptionValue.media.map(
                                            (removeStyle2, removePIindex2) => {
                                              if (index === removePIindex2) {
                                                return {
                                                  ...removeStyle2,
                                                  sortOrder: parseFloat(
                                                    e.target.value
                                                  )
                                                };
                                              } else {
                                                return {
                                                  ...removeStyle2
                                                };
                                              }
                                            }
                                          ),
                                          ['__typename']
                                        )
                                      }
                                    }
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      })
                  : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ProductDetailVariantOptionMedia;
