import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography, Button } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import NativeSelect from '@material-ui/core/NativeSelect';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import TextField from '../../../common/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Query } from 'react-apollo';
import { GET_PAGES } from '../../gql/admin-queries';
import SelectPage from './SelectPage';

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase);

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  inputRoot: {
    width: '100%'
  },
  input: {
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  },
  slider: {
    padding: '22px 0px'
  }
});

class UpdateDiscoverCard extends React.Component {
  state = {
    pageDialog: false,
    selectedPage: this.props.fields.url
  };

  handlePageOpen = () => {
    this.setState({ pageDialog: true });
  };

  handlePageClose = () => {
    this.setState({ pageDialog: false });
  };

  handleChange = event => {
    this.setState({ selectedPage: event.target.value });
  };

  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,
      handleChangeTextField,
      fields,
      languages,
      Template,
      shopId
    } = this.props;

    return (
      <Grid container>
        <ExpansionPanel
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
          expanded={expanded === 'panel2'}
          onChange={handleExpandedChange('panel2')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ expanded: classes.expansionPanelExpanded }}
          >
            <Typography
              variant="subtitle1"
              className={classes.typo}
              data-cy="Button-Settings"
            >
              Discover Shop Card
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: classes.expansionPanelDetails }}
          >
            <Divider />
            <List classes={{ root: classes.inputRoot }}>
              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Button-text-Label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Title
                  </InputLabel>
                  {languages.map(language => {
                    let text = '';
                    try {
                      text =
                        getTextFromLanguage(fields.title.text, language) || '';
                    } catch (error) {}
                    return (
                      <div key={language}>
                        {language}
                        <Paper className={classes.root} elevation={0}>
                          <InputBase
                            data-cy={'Button-text-' + language}
                            className={classes.input}
                            placeholder=""
                            value={text}
                            onChange={e =>
                              handleChangeTextField(
                                e.target.value ? e.target.value : null,
                                language,
                                'title'
                              )
                            }
                          />
                        </Paper>
                      </div>
                    );
                  })}
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Button-text-Label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Sub-title
                  </InputLabel>
                  {languages.map(language => {
                    let text = '';
                    try {
                      text =
                        getTextFromLanguage(fields.subTitle.text, language) ||
                        '';
                    } catch (error) {}
                    return (
                      <div key={language}>
                        {language}
                        <Paper className={classes.root} elevation={0}>
                          <InputBase
                            data-cy={'Button-text-' + language}
                            className={classes.input}
                            placeholder=""
                            value={text}
                            onChange={e =>
                              handleChangeTextField(
                                e.target.value ? e.target.value : null,
                                language,
                                'subTitle'
                              )
                            }
                          />
                        </Paper>
                      </div>
                    );
                  })}
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Button-text-Label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Button text
                  </InputLabel>
                  {languages.map(language => {
                    let text = '';
                    try {
                      text =
                        getTextFromLanguage(fields.buttonText.text, language) ||
                        '';
                    } catch (error) {}
                    return (
                      <div key={language}>
                        {language}
                        <Paper className={classes.root} elevation={0}>
                          <InputBase
                            data-cy={'Button-text-' + language}
                            className={classes.input}
                            placeholder=""
                            value={text}
                            onChange={e =>
                              handleChangeTextField(
                                e.target.value ? e.target.value : null,
                                language,
                                'buttonText'
                              )
                            }
                          />
                        </Paper>
                      </div>
                    );
                  })}
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Button-Link-Label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Button Link
                  </InputLabel>
                  <Paper
                    data-cy="Button-Add-Link-Input"
                    className={classes.root}
                    elevation={0}
                  >
                    <InputBase
                      className={classes.input}
                      placeholder=""
                      disabled={true}
                      value={
                        this.state.selectedPage
                          ? (fields.buttonUrl = this.state.selectedPage)
                          : null
                      }
                      onChange={e =>
                        handleChangeField(
                          e.target.value ? e.target.value : null,
                          'buttonUrl'
                        )
                      }
                    />
                  </Paper>
                  <Button
                    data-cy="Button-Add-Link-btn"
                    onClick={this.handlePageOpen}
                  >
                    Add Link
                  </Button>
                </Grid>
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Dialog
          open={this.state.pageDialog}
          onClose={this.handlePageClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle data-cy="Button-Link-Select-Page" id="form-dialog-title">
            Select Page
          </DialogTitle>
          <DialogContent style={{ zIndex: 100 }}>
            <Query variables={{ id: shopId }} query={GET_PAGES}>
              {({ loading, error, data }) => {
                if (loading) return 'Loading...';
                if (error) return `Error! ${error.message}`;

                return (
                  <Grid container={true}>
                    {data.shop.pages.edges.map(p => {
                      const page = p.node;

                      return (
                        <SelectPage
                          page={page}
                          handleChange={this.handleChange}
                          Template={Template}
                          selectedPage={this.state.selectedPage}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      );
                    })}
                  </Grid>
                );
              }}
            </Query>
          </DialogContent>
          <DialogActions>
            <Button
              data-cy="Button-Select-Page-Cancel-btn"
              onClick={this.handlePageClose}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              data-cy="Button-Select-Page-Save-btn"
              onClick={this.handlePageClose}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

UpdateDiscoverCard.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
};

export default withStyles(styles)(UpdateDiscoverCard);
