import React from 'react'
import { Button } from '@material-ui/core'

interface Props {
  editing: boolean
  handleSwitchEditing(): void
}

const ToggleEdit: React.FunctionComponent<Props> = props => {
  const { handleSwitchEditing, editing } = props
  return (
    <Button
      style={
        !editing
          ? {
              backgroundColor: '#b23566',
              textTransform: 'uppercase',
              padding: '10px 40px',
              fontSize: 12,
              color: 'white',
              border: '1px solid #b23566',
              fontWeight: 'bold',
              borderRadius: 2,
              fontFamily: 'Poppins'
            }
          : {
              backgroundColor: '#b23566',
              textTransform: 'uppercase',
              padding: '10px 40px',
              fontSize: 12,
              color: 'white',
              fontWeight: 'bold',
              border: '1px solid #b23566',
              marginLeft: 40,
              borderRadius: 2,
              fontFamily: 'Poppins'
            }
      }
      onClick={() => handleSwitchEditing()}
    >
      {editing ? `Pages` : `Editor`}
    </Button>
  )
}

export default ToggleEdit
