import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { Slider } from '@material-ui/core'
import Select from '../../common/Select'
import Input from '../../common/Input'
import {
  Delete,
  Add,
  KeyboardArrowUp,
  KeyboardArrowDown
} from '@material-ui/icons'

import * as template_control from '../template_control'
import * as tools from '../tools/tools'
import ControlTools from './ControlTools'

const styles = theme => ({
  colorSwatch: {
    background: '#BBBBBB',
    width: 27,
    height: 27,
    border: '3px solid #BBBBBB',
    borderRadius: '100%',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  logoTumbnail: {
    background: '#BBBBBB',
    width: 46,
    height: 46,
    border: '3px solid #BBBBBB',
    borderRadius: '6px',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  slider: {
    padding: '8px 0px',
    width: '100%'
  }
})

class PatternStep extends Component {
  constructor(props) {
    super(props)

    this.initState = {
      rotate: 0,
      scale: 1,
      selectedId: null,
      preview_upload: null
    }
    this.state = this.initState
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot
    DesignRoot.PatternStep = this
    DesignRoot.setState({ update: true })
    // PROVIDE COMPONENT TO MAIN
  }

  uploadPattern(type) {
    try {
      // statements

      var DesignRoot = this.props.DesignRoot

      var file_upload = document.getElementById('file-upload-pattern')
      if (file_upload.files[0]) {
        var FILE_DATA = file_upload.files[0]
      } else {
        alert('PLEASE SELECT FILE')
        return
      }

      logger.log('uploadPattern', FILE_DATA)

      var file_name = FILE_DATA.name
      var file_size = FILE_DATA.size
      var file_type = FILE_DATA.type

      DesignRoot.Loading.activeLoading(true)

      // ALREADY HAVE PREVIEW BASE64 THEN USE FROM PREVIEW

      var base64data = this.state.preview_upload
      var uuid = tools.uuidv4()
      var imageUrl = tools.uploadFileWithPresignedUrl(
        FILE_DATA,
        FILE_DATA.name,
        DesignRoot.ownedByOrganisationId,
        'PRODUCTDESIGN_PRINT',
        DesignRoot.isDashboard
      )
      DesignRoot.fabric_function.addItem(
        uuid,
        file_name,
        base64data,
        0,
        0,
        { filename: file_name, base64: base64data, s3Url: imageUrl },
        type
      )
      document.getElementById('file-upload-pattern').value = null
      this.setState({ preview_upload: null })

      DesignRoot.Loading.activeLoading(false)

      // ALREADY HAVE PREVIEW BASE64 THEN USE FROM PREVIEW
    } catch (e) {
      // statements
      logger.error('PatternStep.uploadPattern.error', e)
    }
  }

  remove(id) {
    try {
      // statements
      const DesignRoot = this.props.DesignRoot
      if (id) {
        DesignRoot.fabric_function.removeItem(id)
      }
    } catch (e) {
      // statements
      logger.error('PatternStep.remove.error', e)
    }
  }

  previewSelected(id) {
    try {
      // statements
      const DesignRoot = this.props.DesignRoot
      const item = DesignRoot.fabric_function.getObjectById(id)

      if (item) {
        return item._USER_DATA.base64
      }
    } catch (e) {
      // statements
      logger.error('PatternStep.previewSelected.error', e)
    }
  }

  handleRotate = (event, rotate) => {
    this.setState({ rotate })
  }

  handleScale = (event, scale) => {
    this.setState({ scale })
  }

  patternList() {
    try {
      // statements

      const DesignRoot = this.props.DesignRoot

      if (DesignRoot.canvas === null) {
        return null
      }

      const control_tools_event = DesignRoot.control_tools_event

      var activeObject = DesignRoot.canvas.getActiveObject()
      var activeObjectId = null

      if (activeObject) {
        activeObjectId = activeObject.id
      }

      var itemSET = DesignRoot.fabric_function.getObjectByType('PATTERN')
      var itemDOM = []
      for (var i = 0; i < itemSET.length; i++) {
        var selectionBorder = '1px solid transparent'
        if (activeObjectId === itemSET[i].id) {
          selectionBorder = '1px solid #000'
        }

        itemDOM.push(
          <Grid container style={{ width: '100%', overflowX: 'hidden' }}>
            <Grid
              item
              xs={12}
              style={{
                flex: 1,
                padding: '8px',
                height: 80,
                border: '1px solid #E5E5E5',
                borderRadius: '4px',
                border: selectionBorder
              }}
            >
              <img
                src={this.previewSelected(itemSET[i].id)}
                alt=""
                style={{
                  objectFit: 'contain',
                  height: '100%',
                  width: '100%'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlTools
                selectId={itemSET[i].id}
                lock={itemSET[i].lockMovementX}
                visible={itemSET[i].visible}
                onRemove={control_tools_event.__remove.bind(this)}
                onToggleLock={control_tools_event.__toggleLock.bind(this)}
                onToggleVisible={control_tools_event.__toggleVisible.bind(this)}
                onReCenter={control_tools_event.__reCenterOrigin.bind(this)}
              />
            </Grid>
          </Grid>
        )
      }

      return itemDOM
    } catch (e) {
      // statements
      logger.error('PatternStep.patternList.error', e)
    }
  }

  async previewUpload() {
    try {
      // statements

      logger.log('previewUpload')
      var DesignRoot = this.props.DesignRoot

      var file_upload = document.getElementById('file-upload-pattern')
      if (file_upload.files[0]) {
        var FILE_DATA = file_upload.files[0]
      } else {
        alert('PLEASE SELECT FILE')
        return
      }

      logger.log('previewUpload', FILE_DATA)

      if (FILE_DATA.size > 5000000) {
      }

      var file_name = FILE_DATA.name
      var file_size = FILE_DATA.size
      var file_type = FILE_DATA.type

      DesignRoot.Loading.activeLoading(true)

      const self = this
      tools.getBase64(FILE_DATA).then(async function(data) {
        var uuid = tools.uuidv4()
        var imgInfo = await tools._get_image_info(data)
        logger.log('imgInfo', imgInfo)
        self.setState({ preview_upload: imgInfo.imgResize })

        DesignRoot.Loading.activeLoading(false)
      })
    } catch (e) {
      // statements
      logger.error('PatternStep.previewUpload.error', e)
    }
  }

  handleTransformChange(type, evt, value) {
    const { classes, DesignRoot, logoConfiguration, langCode } = this.props

    // logger.log('handleTransformChange', type, value);

    var valueNumber = this.state[type]

    if (value === '+') {
      if (type === 'rotate') {
        valueNumber = parseInt(valueNumber) + 1
      } else if (type === 'scale') {
        valueNumber = parseFloat(valueNumber) + 0.01
      }
    } else if (value === '-') {
      if (type === 'rotate') {
        valueNumber = parseInt(valueNumber) - 1
      } else if (type === 'scale') {
        valueNumber = parseFloat(valueNumber) - 0.01
      }
    } else {
      valueNumber = value
    }

    if (type === 'rotate') {
      valueNumber = valueNumber.toFixed(0)
      valueNumber = parseInt(valueNumber)
    } else if (type === 'scale') {
      valueNumber = valueNumber.toFixed(2)
      valueNumber = parseFloat(valueNumber)
    }

    var activeObject = DesignRoot.canvas.getActiveObject()

    if (activeObject && activeObject._OBJECT_TYPE === 'PATTERN') {
      var item = DesignRoot.fabric_function.getObjectById(activeObject.id)

      if (type === 'rotate') {
        item.set({ angle: valueNumber })

        item.canvas.requestRenderAll()
        DesignRoot.fabric_function.mutate_object_modify(item)
        DesignRoot.fabric_function.updateDimension(item.id, 'byID')
      } else if (type === 'scale') {
        item.set({
          scaleX: valueNumber,
          scaleY: valueNumber
        })

        item.canvas.requestRenderAll()
        DesignRoot.fabric_function.mutate_object_modify(item)
        DesignRoot.fabric_function.updateDimension(item.id, 'byID')
      }
    }

    this.setState({ [type]: valueNumber })
  }

  render() {
    const { classes, DesignRoot } = this.props
    const { rotate, scale, preview_upload } = this.state

    var approxWidth = 0
    var approxHeight = 0

    if (DesignRoot.Dimension !== null) {
      approxWidth = DesignRoot.Dimension.state.approxWidth
        ? DesignRoot.Dimension.state.approxWidth
        : 0
      approxHeight = DesignRoot.Dimension.state.approxHeight
        ? DesignRoot.Dimension.state.approxHeight
        : 0
    }

    return (
      <Grid
        container
        style={{ padding: 24, overflowY: 'auto', height: '100%' }}
      >
        <Grid item xs={12}>
          <Grid container style={{ margin: 0 }}>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ textAlign: 'center' }}>
                Upload Pattern
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            style={{ margin: 0, marginTop: 4, textAlign: 'center' }}
            alignItems="center"
            justify="center"
            spacing={1}
          >
            <Grid item style={{ flex: 1, padding: '0px 8px 0px 0px' }}>
              <input
                type="file"
                id="file-upload-pattern"
                style={{ display: 'none' }}
                onChange={this.previewUpload.bind(this)}
              />
              <label htmlFor="file-upload-pattern">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#171717',
                    color: '#FFFFFF',
                    marginTop: 20
                  }}
                  component="span"
                >
                  Upload
                </Button>
              </label>
              <Grid item xs={12}>
                <Typography
                  subtitle1
                  style={{
                    fontSize: 11,
                    color: '#666666',
                    textAlign: 'center',
                    paddingTop: 10
                  }}
                >
                  JPG, PNG, SVG or AI only.
                </Typography>
                <Typography
                  subtitle1
                  style={{
                    fontSize: 11,
                    color: '#666666',
                    textAlign: 'center',
                    paddingTop: 10
                  }}
                >
                  Max. file upload is 20MB.
                </Typography>
              </Grid>
            </Grid>

            {/*<Grid
            container
            justify="flex-start"
            alignItems="center"
            spacing="8"
            style={{ margin: 0, marginTop: 16 }}
          >
            <Typography id="label">Rotate</Typography>
            <Slider
              value={rotate}
              aria-labelledby="label"
              min={0}
              max={360}
              step={1}
              onChange={this.handleRotate}
            />
          </Grid>

          <Grid
            container
            justify="flex-start"
            alignItems="center"
            spacing="8"
            style={{ margin: 0, marginTop: 16 }}
          >
            <Typography id="label">Scale</Typography>
            <Slider
              value={scale}
              aria-labelledby="label"
              min={0}
              max={100}
              step={1}
              onChange={this.handleScale}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container style={{ margin: 0, marginTop: 16 }}>
              <Typography variant="subtitle1">Color</Typography>
            </Grid>
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              spacing="8"
              style={{ margin: 0, marginTop: 16 }}
            >
              <Grid item className={classes.colorSwatch} />
            </Grid>
          </Grid>*/}

            <Grid
              container
              justify="flex-start"
              alignItems="center"
              spacing={1}
              style={{ margin: 0, marginTop: 16 }}
            >
              <Typography id="label" style={{ marginBottom: 16 }}>
                Rotate {rotate}&deg;
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={10}>
                  <Slider
                    value={rotate}
                    aria-labelledby="label"
                    step={1}
                    min={-360}
                    max={360}
                    onChange={this.handleTransformChange.bind(this, 'rotate')}
                    classes={{
                      track: classes.track,
                      thumb: classes.thumb,
                      rail: classes.rail
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction={'column'}>
                    <Button
                      style={{ marginBottom: 2, minWidth: 0 }}
                      variant="outlined"
                      onClick={e => {
                        this.handleTransformChange('rotate', e, '+')
                      }}
                    >
                      <KeyboardArrowUp style={{ fontSize: 14 }} />
                    </Button>
                    <Button
                      style={{ marginBottom: 2, minWidth: 0 }}
                      variant="outlined"
                      onClick={e => {
                        this.handleTransformChange('rotate', e, '-')
                      }}
                    >
                      <KeyboardArrowDown style={{ fontSize: 14 }} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justify="flex-start"
              alignItems="center"
              spacing={1}
              style={{ margin: 0, marginTop: 16 }}
            >
              <Typography id="label" style={{ marginBottom: 8 }}>
                Resize {approxWidth} x {approxHeight} CM
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={10}>
                  <Slider
                    value={scale}
                    aria-labelledby="label"
                    step={0.01}
                    min={0.01}
                    max={5}
                    onChange={this.handleTransformChange.bind(this, 'scale')}
                    classes={{
                      track: classes.track,
                      thumb: classes.thumb,
                      rail: classes.rail
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction={'column'}>
                    <Button
                      style={{ marginBottom: 2, minWidth: 0 }}
                      variant="outlined"
                      onClick={e => {
                        this.handleTransformChange('scale', e, '+')
                      }}
                    >
                      <KeyboardArrowUp style={{ fontSize: 14 }} />
                    </Button>
                    <Button
                      style={{ marginBottom: 2, minWidth: 0 }}
                      variant="outlined"
                      onClick={e => {
                        this.handleTransformChange('scale', e, '-')
                      }}
                    >
                      <KeyboardArrowDown style={{ fontSize: 14 }} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justify="center"
              style={{ margin: 0, marginTop: 16, textAlign: 'center' }}
            >
              <Grid container justify="center" style={{ margin: 0 }}>
                <Divider
                  light
                  style={{ width: 180, marginTop: 40, marginBottom: 40 }}
                />
              </Grid>
              <Typography variant="h5">Pattern list</Typography>
            </Grid>

            <Grid
              container
              style={{ margin: 0, marginTop: 10 }}
              alignItems="center"
              justify="center"
              spacing={1}
            >
              {this.patternList()}
            </Grid>

            {preview_upload !== null && (
              <Grid item>
                <img
                  src={preview_upload}
                  alt=""
                  style={{
                    objectFit: 'contain',
                    width: '20%'
                  }}
                />
              </Grid>
            )}

            <Grid item>
              <Button
                style={{ minWidth: 16 }}
                variant="contained"
                style={{
                  backgroundColor: '#171717',
                  color: '#FFFFFF',
                  marginTop: 20
                }}
                onClick={this.uploadPattern.bind(this, 'PATTERN')}
              >
                Add Pattern
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

PatternStep.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PatternStep)
