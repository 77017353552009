import { Button, withStyles, Grid, Typography, Paper } from '@material-ui/core'
import React, { Component } from 'react'
import { Mutation, Subscription } from 'react-apollo'
import uuid from 'uuid'
import { ADMIN_CREATE_EXPORT_REPORT_REQUEST } from '../../gql/admin-mutations'
import { ADMIN_REPORT_REQUEST_SUBSCRIPTION } from '../../gql/admin-subscriptions'
import { MEDIA_CDN_URL } from '../../../../../brikl-config'
import TimeFrameSelection from './TimeFrameSelection'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    padding: 24,
    margin: '0 auto'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    marginLeft: 20,
    fontSize: 30,
    padding: 25,
    fontWeight: 'medium',
    color: '#424242'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
})

class ReportAccounting extends Component {
  state = {
    reportRequestStatus: null,
    reportRequestId: null,
    testSubscription: false,
    from: null,
    to: null
  }
  timeFrame = {
    from: null,
    to: null
  }

  handleTimeFrameSelection(from: number, to: number): void {
    if (from && to) {
      this.timeFrame = { from, to }
    }
  }

  handleExportBtn(createExportReportRequest, fileType: 'XLS') {
    const requestId = uuid.v4()
    this.setState({
      reportRequestId: requestId,
      reportRequestStatus: 'LOADING'
    })
    try {
      createExportReportRequest({
        variables: {
          input: {
            fields: null,
            fileType,
            id: requestId,
            reportType: 'REPORT_ACCOUNTING',
            filter: {
              fromTimestamp: this.timeFrame.from,
              untilTimestamp: this.timeFrame.to
            }
          }
        }
      })
    } catch (error) {
      this.setState({
        reportRequestStatus: null
      })
    }
  }

  render() {
    const { classes, shopId } = this.props
    return (
      <Grid className={classes.root}>
        <Grid
          container={true}
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ marginBottom: '20px' }}
        >
          <Grid item={true} xs={12}>
            <Typography
              style={{
                fontSize: 28,
                fontWeight: 'medium',
                color: '#424242',
                marginBottom: '20px'
              }}
            >
              Report Accounting
            </Typography>
          </Grid>
          <Mutation
            update={(store, { data: { createExportReportRequest } }) => {
              console.log(
                'createExportReportRequest',
                createExportReportRequest
              )
              if (createExportReportRequest.id) {
                // start subscribing here
                // this.setState({
                //   reportRequestId: createExportReportRequest.id
                // });
              }
            }}
            mutation={ADMIN_CREATE_EXPORT_REPORT_REQUEST}
          >
            {createExportReportRequest => (
              <Grid item={true} xs={12}>
                <Paper style={{ padding: 24, paddingTop: 0 }}>
                  <Grid container={true}>
                    <Grid item={true} xs={12}>
                      <TimeFrameSelection
                        onSelect={(from, to) =>
                          this.handleTimeFrameSelection(from, to)
                        }
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Grid
                        container={true}
                        spacing={2}
                        style={{ marginTop: 16 }}
                      >
                        <Grid item={true}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                              this.handleExportBtn(
                                createExportReportRequest,
                                'XLS'
                              )
                            }
                            disabled={
                              this.state.reportRequestStatus === 'LOADING'
                            }
                          >
                            {this.state.reportRequestStatus === 'LOADING'
                              ? 'Export in progress...'
                              : 'Export to XLS'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Mutation>
          {this.state.reportRequestId ? (
            <Subscription
              onSubscriptionData={({ subscriptionData }) => {
                try {
                  if (
                    subscriptionData.data.events[0] &&
                    subscriptionData.data.events[0].status === 'SUCCESS'
                  ) {
                    const link = document.createElement('a')
                    link.href = subscriptionData.data.events[0].event.filePath
                    const fileType = link.href.split('.').pop()
                    link.download = 'brikl-customers-export.' + fileType
                    console.log('link', link)
                    link.click()
                    this.setState({
                      reportRequestStatus: null
                    })
                  }
                } catch (e) {
                  console.error(e)
                  alert('Your export has failed: Unknown error')
                  this.setState({
                    reportRequestStatus: null
                  })
                }
              }}
              subscription={ADMIN_REPORT_REQUEST_SUBSCRIPTION}
              variables={{
                id: this.state.reportRequestId,
                shopId: shopId
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(ReportAccounting)
