import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import Select from '../../common/Select'
import CustomPicker from './CustomPicker.js'

import { CirclePicker } from 'react-color'
import AopStep from './AopStep'
import ColorPicker from './ColorPicker'
import FillStepCirclePicker from './FillStepCirclePicker'
import * as template_control from '../template_control'

import _uniq from 'lodash/uniq'

import { API_STAGE } from '~/brikl-config'

import * as tools from '../tools/tools'
let SHOP_ID = tools.getQueryStringValue('s')

const styles = theme => ({
  colorSwatch: {
    background: '#BBBBBB',
    width: 27,
    height: 27,
    border: '3px solid #BBBBBB',
    borderRadius: '100%',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  printThumbnail: {
    background: '#BBBBBB',
    width: 46,
    height: 46,
    border: '3px solid #BBBBBB',
    borderRadius: '6px',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  slider: {
    padding: '8px 0px',
    width: '100%'
  }
})

var self = null

class FillStep extends Component {
  constructor(props) {
    super(props)

    this._COLOR_REF = []

    this.state = {
      color: '#fff',
      colorELM: '#fff',
      colorSVG: '#fff',
      selectColor: null,
      selectType: 'COLOR'
    }

    if (!SHOP_ID) {
      SHOP_ID = this.props.DesignRoot.SHOP_ID
    }
    self = this
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot
    DesignRoot.FillStep = this
    DesignRoot.setState({ update: true })
    // PROVIDE COMPONENT TO MAIN
  }

  generateFill() {
    try {
      // statements

      const { DesignRoot, productDataSet } = this.props

      const ACTIVE_TEMPLATE_INDEX = productDataSet.ACTIVE_TEMPLATE_INDEX
      const ACTIVE_TEMPLATE_ID = DesignRoot._ACTIVE_TEMPLATE_ID

      const colorData =
        productDataSet.TEMPLATE_SET_BY_ID[ACTIVE_TEMPLATE_ID].colorData

      var selectColor = this.state.selectColor
      var colorDOM = []

      for (var i = 0; i < colorData.length; i++) {
        colorDOM.push(
          <option
            key={'generateFill' + ACTIVE_TEMPLATE_ID + i}
            value={colorData[i].color}
          >
            {colorData[i].title}
          </option>
        )
      }

      if (DesignRoot.Main3D !== null) {
        for (
          var i = 0;
          i < DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray.length;
          i++
        ) {
          try {
            var elmGroup =
              DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray[i]
                .userData.elmGroup

            if (selectColor !== null && selectColor.indexOf(elmGroup) !== -1) {
              selectColor =
                DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray[i]
                  .name + '_ELM_'
            }

            var elmName =
              DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray[i].name

            var baseELM = DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray[
              i
            ].name.split('-')
            if (baseELM && baseELM.length >= 3) {
              elmName = baseELM[2].replace(/([A-Z])/g, ' $1').trim()
            }

            if (
              DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray[
                i
              ].name.indexOf('MatShape') !== -1
            ) {
              elmName = 'Stitch'
            }

            colorDOM.push(
              <option
                key={'generateELM' + ACTIVE_TEMPLATE_ID + i}
                value={
                  DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray[i]
                    .name + '_ELM_'
                }
              >
                {elmName}
              </option>
            )
          } catch (e) {
            logger.error('FillStep.generateFill.colorDOM.error', e)
          }
        }
      }

      logger.log('generateFill selectColor', selectColor)

      if (this.state.selectColor === null) {
        selectColor = colorData[0].color // DEFAULT
      }

      return (
        <Select value={selectColor} onChange={this.selectColorZone.bind(this)}>
          {colorDOM}
        </Select>
      )
    } catch (e) {
      // statements
      logger.error('FillStep.generateFill.error', e)
    }
  }

  selectColorZone(evt) {
    try {
      // statements
      evt.persist()
      logger.log('selectColorZone', evt.target.value)

      const { DesignRoot, productDataSet } = this.props

      if (evt.target.value.indexOf('_ELM_') === -1) {
        template_control.setSelectColorZone(evt.target.value)
        this.setState({ selectColor: evt.target.value, selectType: 'COLOR' })
      } else {
        this.setState({ selectColor: evt.target.value, selectType: 'ELM' })
      }
    } catch (e) {
      // statements
      logger.error('FillStep.selectColorZone.error', e)
    }
  }

  handleChangeColor(color) {
    try {
      // statements

      this.setState({ color: color.hex })

      const { DesignRoot } = this.props

      if (this.state.selectType === 'COLOR') {
        DesignRoot.change_color.changeColorPickerSelected(color.hex)

        // Trig onColorChange
        if (typeof DesignRoot.onColorChange === 'function') {
          let indexColor = DesignRoot.defaultZoneColor.findIndex(
            r => DesignRoot._SELECT_COLOR_ZONE.toLowerCase() === r.toLowerCase()
          )
          if (indexColor > -1) {
            let data = {
              index: indexColor,
              color: color.hex,
              originalZone: DesignRoot.currentTemplateSET.colorData[indexColor]
            }
            DesignRoot.onColorChange(data)
          }
        }
      } else if (this.state.selectType === 'ELM') {
        var selectDirectELMObject = DesignRoot.Main3D.ThreejsFunction.getELMByName(
          this.state.selectColor.replace('_ELM_', '')
        )
        DesignRoot.change_color.changeColorPickerSelectedDirectELM(
          color.hex,
          selectDirectELMObject
        )
      }
    } catch (e) {
      // statements
      logger.error('FillStep.handleChangeColor.error', e)
    }
  }

  handleChangeSVGElementColor(color) {
    try {
      // statements

      //self.setState({ colorSVG: color.hex })

      const { DesignRoot } = self.props

      if (DesignRoot._ACTIVE_COLOR_ZONE === false) {
        if (DesignRoot.Selection.state.selectDirectELM !== null) {
          var selectDirectELMObject = DesignRoot.Main3D.ThreejsFunction.getELMByName(
            DesignRoot.Selection.state.selectDirectELM.replace('_ELM_', '')
          )
          DesignRoot.change_color.changeColorPickerSelectedDirectELM(
            color.colors ? color : color.hex,
            selectDirectELMObject
          )
        } else {
          var selectionObject = DesignRoot.Selection.state.selectionObject

          logger.log('handleChangeSVGElementColor', selectionObject)

          // FILL RELATION

          if (selectionObject._OBJECT_TYPE === 'SVG') {
            var baseElement3DName = selectionObject._USER_DATA.element3DName
            var baseELM = baseElement3DName.split('-')

            if (baseELM[0]) {
              var baseELMName = baseELM[0]
              var allSVG = DesignRoot.fabric_function.getObjectByType('SVG')

              for (var i = 0; i < allSVG.length; i++) {
                var element3DName = allSVG[i]._USER_DATA.element3DName
                if (
                  element3DName &&
                  element3DName.indexOf(baseELMName + '-') !== -1
                ) {
                  DesignRoot.change_color.changeColorPickerSelectedSVGELM(
                    color.colors ? color : color.hex,
                    allSVG[i]
                  )
                }
              }
            } else {
              DesignRoot.change_color.changeColorPickerSelectedSVGELM(
                color.colors ? color : color.hex,
                selectionObject
              )
            }
          } else if (selectionObject._OBJECT_TYPE === 'SVG_COLORZONE') {
            var colorZoneIndex = selectionObject._USER_DATA.colorZoneIndex
            var allSVGColorZone = DesignRoot.fabric_function.getObjectByType(
              'SVG_COLORZONE'
            )

            for (var i = 0; i < allSVGColorZone.length; i++) {
              if (
                allSVGColorZone[i]._USER_DATA.colorZoneIndex === colorZoneIndex
              ) {
                DesignRoot.change_color.changeColorPickerSelectedSVGELM(
                  color.colors ? color : color.hex,
                  allSVGColorZone[i]
                )
              }
            }

            // SAVE COLORZONE TO DB // 1 TIME SAVE PER CHANGE

            DesignRoot.change_color.save_colorzone_svg_to_db(
              colorZoneIndex,
              color.colors ? color : color.hex
            )

            // SAVE COLORZONE TO DB // 1 TIME SAVE PER CHANGE

            // Trig onColorChange
            if (typeof DesignRoot.onColorChange === 'function') {
              let regexp = /COLORZONE\-[0-9]+/
              let matches_array = selectionObject.id.match(regexp)
              let originalZoneId = DesignRoot.productDataSet.productDesign.product.designTemplates
                .filter(
                  r => r.id === DesignRoot.currentTemplateSET.templateId
                )[0]
                .colors.filter(r => r.svgId === matches_array[0])[0].id
              let data = {
                color: color.colors ? color : color.hex,
                originalZone: {
                  id: originalZoneId
                }
              }
              DesignRoot.onColorChange(data)
            }
          }

          // FILL RELATION
        }
      } else {
        var selectionObject = DesignRoot.Selection.state.selectionObject
        DesignRoot.change_color.changeColorPickerSelectedSVGELM(
          color.colors ? color : color.hex,
          selectionObject
        )
      }
    } catch (e) {
      // statements
      logger.error('FillStep.handleChangeSVGElementColor.error', e)
    }
  }

  genDirectELM() {
    try {
      // statements

      const { DesignRoot } = this.props

      if (
        DesignRoot.Selection !== null &&
        DesignRoot.Main3D !== null &&
        DesignRoot.Selection.state.selectDirectELM !== null
      ) {
        return DesignRoot.Selection.state.selectDirectELM
      } else {
        return '- Please select element -'
      }
    } catch (e) {
      // statements
      logger.error('FillStep.genDirectELM.error', e)
    }
  }

  genSVGELM() {
    try {
      // statements

      const { DesignRoot } = this.props

      if (DesignRoot.Selection) {
        logger.log('genSVGELM', DesignRoot.Selection.state)

        if (DesignRoot._ACTIVE_COLOR_ZONE === false) {
          if (DesignRoot.Selection.state.selectDirectELM !== null) {
            return DesignRoot.Selection.state.selectDirectELM + '_ELM_'
          }
        }
      }

      if (
        DesignRoot.Selection !== null &&
        DesignRoot.Main3D !== null &&
        DesignRoot.Selection.state.selection !== null &&
        DesignRoot.Selection.state.selection.indexOf('SVG') !== -1
      ) {
        if (
          DesignRoot.Selection.state.selectionObject !== null &&
          DesignRoot.Selection.state.selectionObject._USER_DATA.element3DName
        ) {
          // RETURN REAL ELM NAME FROM 3D ELM
          logger.log(
            DesignRoot.Selection.state.selectionObject._USER_DATA.element3DName
          )
          return DesignRoot.Selection.state.selectionObject._USER_DATA
            .element3DName
        } else if (
          DesignRoot.Selection.state.selectionObject !== null &&
          DesignRoot.Selection.state.selectionObject._OBJECT_TYPE ===
            'SVG_COLORZONE'
        ) {
          return DesignRoot.Selection.state.selectionObject._USER_DATA.fullID
        } else {
          return DesignRoot.Selection.state.selection
        }
      } else {
        return '- Please select element -'
      }
    } catch (e) {
      // statements
      logger.error('FillStep.genSVGELM.error', e)
    }
  }

  handleResetColor() {
    try {
      // statements

      const { DesignRoot } = this.props

      if (this.state.selectColor === null) {
        return false
      }

      if (this.state.selectType === 'COLOR') {
        DesignRoot.change_color.changeColorPickerSelected(
          this.state.selectColor
        )
        this.setState({ color: this.state.selectColor })

        // Trig onColorChange
        if (typeof DesignRoot.onColorChange === 'function') {
          let indexColor = DesignRoot.defaultZoneColor.findIndex(
            r => DesignRoot._SELECT_COLOR_ZONE.toLowerCase() === r.toLowerCase()
          )
          if (indexColor > -1) {
            let data = {
              index: indexColor,
              color: this.state.selectColor,
              originalZone: DesignRoot.currentTemplateSET.colorData[indexColor]
            }
            DesignRoot.onColorChange(data)
          }
        }
      } else if (this.state.selectType === 'ELM') {
        var selectDirectELMObject = DesignRoot.Main3D.ThreejsFunction.getELMByName(
          this.state.selectColor.replace('_ELM_', '')
        )
        DesignRoot.change_color.changeColorPickerSelectedDirectELM(
          'RESET',
          selectDirectELMObject
        )
        selectDirectELMObject.userData.hasChange = false
        this.setState({ color: '#FFFFFF' })
      }
    } catch (e) {
      // statements
      logger.error('FillStep.handleResetColor.error', e)
    }
  }

  handleResetColorSVG() {
    try {
      // statements

      const { DesignRoot } = this.props
      var selectionObject = DesignRoot.Selection.state.selectionObject

      if (selectionObject._OBJECT_TYPE === 'SVG') {
        var baseElement3DName = selectionObject._USER_DATA.element3DName
        var baseELM = baseElement3DName.split('-')

        if (baseELM[0]) {
          var baseELMName = baseELM[0]
          var allSVG = DesignRoot.fabric_function.getObjectByType('SVG')

          for (var i = 0; i < allSVG.length; i++) {
            var element3DName = allSVG[i]._USER_DATA.element3DName

            if (
              element3DName &&
              element3DName.indexOf(baseELMName + '-') !== -1
            ) {
              DesignRoot.change_color.changeColorPickerSelectedSVGELM(
                'RESET',
                allSVG[i]
              )
            }
          }
        } else {
          DesignRoot.change_color.changeColorPickerSelectedSVGELM(
            'RESET',
            selectionObject
          )
        }
      } else if (selectionObject._OBJECT_TYPE === 'SVG_COLORZONE') {
        var colorZoneIndex = selectionObject._USER_DATA.colorZoneIndex
        var allSVGColorZone = DesignRoot.fabric_function.getObjectByType(
          'SVG_COLORZONE'
        )

        for (var i = 0; i < allSVGColorZone.length; i++) {
          if (allSVGColorZone[i]._USER_DATA.colorZoneIndex === colorZoneIndex) {
            DesignRoot.change_color.changeColorPickerSelectedSVGELM(
              'RESET',
              allSVGColorZone[i]
            )
          }
        }

        // SAVE COLORZONE TO DB // 1 TIME SAVE PER CHANGE

        DesignRoot.change_color.save_colorzone_svg_to_db(
          colorZoneIndex,
          selectionObject._USER_DATA.resetFill
        )

        // SAVE COLORZONE TO DB // 1 TIME SAVE PER CHANGE

        // Trig onColorChange
        if (typeof DesignRoot.onColorChange === 'function') {
          let regexp = /COLORZONE\-[0-9]+/
          let matches_array = selectionObject.id.match(regexp)
          let originalZoneId = DesignRoot.productDataSet.productDesign.product.designTemplates
            .filter(r => r.id === DesignRoot.currentTemplateSET.templateId)[0]
            .colors.filter(r => r.svgId === matches_array[0])[0].id
          let data = {
            color: selectionObject._USER_DATA.resetFill,
            originalZone: {
              id: originalZoneId
            }
          }
          DesignRoot.onColorChange(data)
        }
      }
    } catch (e) {
      // statements
      logger.error('FillStep.handleResetColorSVG.error', e)
    }
  }

  mapAvailableColor(option) {
    const { classes, DesignRoot, productDataSet } = this.props
    const { selectColor, selectType } = this.state

    var SelectionState = null
    if (DesignRoot.Selection) {
      var SelectionState = DesignRoot.Selection.state
    }

    logger.log(
      'mapAvailableColor',
      productDataSet,
      selectColor,
      selectType,
      SelectionState
    )

    // LOCAL TEST
    if (DesignRoot.props.localData === true) {
      return ['#FFFFFF', '#FF0000', '#00FF00', '#0000FF', '#000000']
    } else if (DesignRoot._LOCAL_COLOR === true) {
      return [
        '#FF0000',
        '#be2137',
        '#ff7525',
        '#ffa100',
        '#ffcb00',
        '#fdf900',
        '#00752c',
        '#00e430',
        '#002bff',
        '#00a9ff',
        '#701f85',
        '#873cbe',
        '#c87aff',
        '#cccccc',
        '#f7f7f7',
        '#000000'
      ]
    }

    let returnColors = []
    try {
      var selectedELM = null
      var selected_element_code = null

      if (option && option == 'ALL') {
        selectedELM = 'ALL'
      } else if (DesignRoot._ACTIVE_COLOR_ZONE === false) {
        if (SelectionState.selectDirectELM !== null) {
          var selectedELM = SelectionState.selectDirectELM
        } else if (SelectionState.selectionObject !== null) {
          if (SelectionState.selectionObject._OBJECT_TYPE === 'SVG_COLORZONE') {
            selectedELM = 'ALL'
          } else if (!SelectionState.selectionObject._USER_DATA.element3DName) {
            return []
          } else {
            selectedELM =
              SelectionState.selectionObject._USER_DATA.element3DName
          }
        }

        if (selectedELM === null) {
          return []
        }

        selected_element_code = selectedELM
        var selected_code_split = selectedELM.split('-')
        if (selected_code_split && selected_code_split.length) {
          selected_element_code = selected_code_split[0]
        }
      } else {
        if (selectType === 'ELM') {
          selectedELM = selectColor
          selected_element_code = selectedELM
          var selected_code_split = selectedELM.split('-')
          if (selected_code_split && selected_code_split.length) {
            selected_element_code = selected_code_split[0]
          }
        } else {
          selectedELM = 'ALL'
        }
      }

      logger.log('selected_element_code', selected_element_code, selectedELM)

      var elementsCMS = productDataSet.elements
      for (let i = 0; i < elementsCMS.length; i++) {
        const elementCode = elementsCMS[i].code

        if (
          elementCode.indexOf(selected_element_code + '-') !== -1 ||
          selectedELM === 'ALL'
        ) {
          for (let j = 0; j < elementsCMS[i].fabricCompositions.length; j++) {
            for (
              let k = 0;
              k < elementsCMS[i].fabricCompositions[j].colors.length;
              k++
            ) {
              const color = elementsCMS[i].fabricCompositions[j].colors[k]
              returnColors.push(color.hex.toLowerCase())
              this._COLOR_REF[color.hex.toLowerCase()] = color
            }
          }
        }
      }

      const uniqueColors = returnColors.filter((v, i, a) => a.indexOf(v) === i)

      logger.log('uniqueColors', uniqueColors)

      return uniqueColors
    } catch (error) {
      logger.error('FillStep.mapAvailableColor.error', error)
      return []
    }
  }

  genSVGList() {
    try {
      // statements

      const { classes, DesignRoot } = this.props

      var allSVG = DesignRoot.fabric_function.getObjectByType('SVG')
      var allSVGColorZone = DesignRoot.fabric_function.getObjectByType(
        'SVG_COLORZONE'
      )
      allSVG = allSVG.concat(allSVGColorZone)

      const originallySelected = this.genSVGELM()
      let selected = originallySelected

      var elmListName = []
      var elmList = []
      elmList.push(
        <option key={'genSVGList_null'} value={null}>
          - Please select element -
        </option>
      )
      for (var i = 0; i < allSVG.length; i++) {
        var element3DName = allSVG[i]._USER_DATA.element3DName

        if (
          allSVG[i]._OBJECT_TYPE === 'SVG_COLORZONE' &&
          DesignRoot._COLOR_ZONE_SVG_ACTIVE === true
        ) {
          elmListName.push({
            element3DName: allSVG[i]._USER_DATA.fullID,
            mainELM: allSVG[i]._USER_DATA.colorZoneIndex,
            subELM: allSVG[i]._USER_DATA.colorZoneIndex,
            nameELM: allSVG[i]._USER_DATA.colorZoneIndex
          })
        } else if (DesignRoot._COLOR_ZONE_SVG_ACTIVE === false) {
          if (element3DName) {
            const splitName = element3DName.split('-')
            if (DesignRoot.SHOP_ID === 'bodywearlab') {
              try {
                const elementData = DesignRoot?.productDataSet?.elements?.find(
                  e => {
                    return element3DName.includes(e.code)
                  }
                )
                elmListName.push({
                  element3DName: element3DName,
                  mainELM: splitName[0],
                  subELM: splitName[1],
                  nameELM: elementData.name
                })
              } catch (error) {}
            } else {
              elmListName.push({
                element3DName: element3DName,
                mainELM: splitName[0],
                subELM: splitName[1],
                nameELM: splitName[2]
              })
            }
          }
        }
      }

      var store = [],
        elmListNameUnique = []
      for (var i = 0; i < elmListName.length; i++) {
        if (store[elmListName[i].mainELM]) continue
        store[elmListName[i].mainELM] = true
        elmListNameUnique.push(elmListName[i])
      }

      for (var i = 0; i < elmListNameUnique.length; i++) {
        elmList.push(
          <option
            key={'genSVGList_' + elmListNameUnique[i].element3DName}
            value={elmListNameUnique[i].element3DName}
          >
            {elmListNameUnique[i].nameELM}
          </option>
        )

        if (
          originallySelected !== null &&
          originallySelected.indexOf(elmListNameUnique[i].mainELM + '-') !== -1
        ) {
          selected = elmListNameUnique[i].element3DName
        }
      }

      if (DesignRoot._ACTIVE_COLOR_ZONE === false) {
        // IF NO COLOR ZONE THEN ELM STITCH SHOW HERE

        if (DesignRoot.Main3D !== null) {
          for (
            var i = 0;
            i < DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray.length;
            i++
          ) {
            try {
              let elmGroup =
                DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray[i]
                  .userData.elmGroup

              if (originallySelected.indexOf(elmGroup) !== -1) {
                selected =
                  DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray[i]
                    .name + '_ELM_'
              }

              let elmName =
                DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray[i].name

              if (
                DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray[
                  i
                ].name.indexOf('MatShape') !== -1
              ) {
                elmName = 'Stitch'
              }

              elmList.push(
                <option
                  key={'genSVGList_ELM_' + i}
                  value={
                    DesignRoot.Main3D.storeMappingObjectNoTemplateGroupArray[i]
                      .name + '_ELM_'
                  }
                >
                  {elmName}
                </option>
              )
            } catch (e) {
              logger.error('FillStep.genSVGList.colorDOM.error', e)
            }
          }
        }
      }

      logger.log('selected', selected, originallySelected)

      return (
        <Select value={selected} onChange={this.selectSVGList.bind(this)}>
          {elmList}
        </Select>
      )
    } catch (error) {
      // statements
      logger.error('FillStep.genSVGList.error', error)
    }
  }

  selectSVGList(evt) {
    try {
      // statements

      const { classes, DesignRoot } = this.props

      evt.persist()
      logger.log('selectSVGList', evt.target.value)

      if (evt.target.value !== null) {
        if (
          evt.target.value.indexOf('_ELM_') === -1 &&
          evt.target.value.indexOf('COLORZONE-') === -1
        ) {
          var allSVG = DesignRoot.fabric_function.getObjectByType('SVG')

          for (var i = 0; i < allSVG.length; i++) {
            if (
              allSVG[i]._USER_DATA.element3DName &&
              allSVG[i]._USER_DATA.element3DName === evt.target.value
            ) {
              this.setState({
                selectColor: allSVG[i]._USER_DATA.element3DName
              })
              DesignRoot.Selection.setState({
                selection: allSVG[i].id,
                selectionObject: allSVG[i],
                selectDirectELM: null
              })
            }
          } // end for
        } else if (evt.target.value.indexOf('COLORZONE-') !== -1) {
          var allSVGColorZone = DesignRoot.fabric_function.getObjectByType(
            'SVG_COLORZONE'
          )

          for (var i = 0; i < allSVGColorZone.length; i++) {
            if (
              allSVGColorZone[i]._USER_DATA.colorZoneIndex === evt.target.value
            ) {
              this.setState({
                selectColor: allSVGColorZone[i].id
              })
              DesignRoot.Selection.setState({
                selection: allSVGColorZone[i].id,
                selectionObject: allSVGColorZone[i],
                selectDirectELM: null
              })

              continue
            }
          }
        } else {
          this.setState({
            selectColor: evt.target.value.replace('_ELM_', '')
          })
          DesignRoot.Selection.setState({
            selection: null,
            selectionObject: null,
            selectDirectELM: evt.target.value.replace('_ELM_', '')
          })
        }
      } else {
        DesignRoot.Selection.setState({
          selection: null,
          selectionObject: null,
          selectDirectELM: null
        })
      }
    } catch (error) {
      // statements
      logger.error('FillStep.selectSVGList.error', error)
    }
  }

  normalMapList() {
    try {
      const { classes, DesignRoot, productDataSet } = this.props

      if (DesignRoot.Main3D === null) {
        return
      }

      var normalMapList = []

      for (var i = 1; i < 21; i++) {
        if (i >= 10) {
          var fileName = '0' + i
        } else {
          var fileName = '00' + i
        }

        var fileBase = require('../TEMPLATE_ASSET/NORMAL_MAP/' +
          fileName +
          '.jpg')
        var fileThumb = require('../TEMPLATE_ASSET/NORMAL_MAP/Thumb/' +
          fileName +
          '.jpg')

        // normalMapList.push({
        //   fileBase: fileBase,
        //   fileThumb: fileThumb,
        // });

        normalMapList.push(
          <img
            src={fileThumb}
            onClick={DesignRoot.Main3D.ThreejsFunction.updateNormalMap.bind(
              this,
              fileBase
            )}
          />
        )
      }

      return normalMapList
    } catch (e) {
      logger.error('FillStep.normalMapList.error', e)
    }
  }

  render() {
    const { classes, DesignRoot, productDataSet } = this.props
    const { selectColor, selectType } = this.state

    if (
      DesignRoot._ACTIVE_FILL_STEP === false &&
      DesignRoot._OLD_CZ_AVAILABLE === false
    ) {
      return null
    }

    let colors = this.mapAvailableColor()

    logger.log('COLOR DEBUG', this.state.color, this.state.colorSVG)

    return (
      <Grid
        container
        style={{ padding: 24, height: '100%' }}
        justify="center"
        className={classes.scroll}
      >
        <Grid item xs={12}>
          {DesignRoot._ACTIVE_PERSONALIZATION === false && (
            <Grid container style={{ margin: 0 }} justify="center">
              <Typography variant="h5" style={{ textAlign: 'center' }}>
                Color Fill
              </Typography>
            </Grid>
          )}

          {(DesignRoot._ACTIVE_COLOR_ZONE === true &&
            DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK') ||
          DesignRoot._UI_ONLY === true ? (
            <span>
              <Grid
                container
                justify="center"
                style={{ margin: 0, marginTop: 8 }}
              >
                <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                  Color a zone :{' '}
                </Typography>
                <Grid item xs={12} style={{ padding: 0 }}>
                  {this.generateFill()}
                </Grid>
              </Grid>

              <Grid
                container
                justify="flex-start"
                alignItems="center"
                spacing={1}
                style={{ margin: 0, marginTop: 16 }}
                data-cy="fill-step-color-palette"
              >
                {colors &&
                colors.length > 0 &&
                DesignRoot._COLOR_PALETTE === true ? (
                  <CirclePicker
                    colors={colors}
                    color={this.state.color}
                    triangle="hide"
                    onChangeComplete={this.handleChangeColor.bind(this)}
                  />
                ) : null}

                {DesignRoot._COLOR_PALETTE === true &&
                colors &&
                colors.length <= 0 ? (
                  <Typography
                    variant="subtitle1"
                    style={{ textAlign: 'center' }}
                  >
                    No colors available
                  </Typography>
                ) : null}

                <ColorPicker
                  DesignRoot={DesignRoot}
                  mainFillComponent={this}
                  componentType="FillStep_COLORZONE"
                />
              </Grid>

              <Grid
                container
                style={{ margin: 0, marginTop: 16 }}
                justify="center"
              >
                <Button
                  onClick={this.handleResetColor.bind(this)}
                  variant="contained"
                  style={{ backgroundColor: '#171717', color: '#FFFFFF' }}
                >
                  Reset
                </Button>
              </Grid>
            </span>
          ) : null}

          {DesignRoot._ACTIVE_TEMPLATE_ID === 'BLANK' ||
          DesignRoot._SVG_FILL === true ||
          DesignRoot._UI_ONLY === true ? (
            <span>
              {DesignRoot._ACTIVE_PERSONALIZATION === false && (
                <Grid
                  container
                  style={{ margin: 0, marginTop: 20 }}
                  justify="center"
                >
                  <Grid item xs={12} data-cy="fill-step-color-element-select">
                    <Typography
                      variant="subtitle1"
                      style={{ textAlign: 'center' }}
                    >
                      Color an element : <br />
                      {this.genSVGList()}{' '}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {DesignRoot._ACTIVE_PERSONALIZATION === false &&
                DesignRoot?.Selection?.state?.selectionObject?.id && (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={1}
                    style={{ margin: 0, marginTop: 16, position: 'relative' }}
                    data-cy="fill-step-color-palette"
                  >
                    {DesignRoot._COLOR_PALETTE === true && (
                      <FillStepCirclePicker
                        handleChangeSVGElementColor={
                          this.handleChangeSVGElementColor
                        }
                        colors={colors}
                        enableGradient={
                          DesignRoot.props.shopConfiguration?.capabilities?.featureFlags?.indexOf(
                            'STUDIO_GRADIENT'
                          ) > -1
                        }
                        DesignRoot={DesignRoot}
                        selectionObjectId={
                          DesignRoot?.Selection?.state?.selectionObject?.id
                        }
                      />
                      // <CirclePicker
                      //   colors={colors}
                      //   color={this.state.colorSVG}
                      //   triangle="hide"
                      //   onChange={this.handleChangeSVGElementColor.bind(this)}
                      // />
                    )}

                    <ColorPicker
                      DesignRoot={DesignRoot}
                      mainFillComponent={this}
                      componentType="FillStep_SVG"
                    />
                  </Grid>
                )}

              {DesignRoot._ACTIVE_PERSONALIZATION === false && (
                <Grid
                  container
                  style={{ margin: 0, marginTop: 16 }}
                  justify="center"
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#171717', color: '#FFFFFF' }}
                    onClick={this.handleResetColorSVG.bind(this)}
                  >
                    Reset
                  </Button>
                </Grid>
              )}

              {DesignRoot._SVG_AOP === true && (
                <AopStep DesignRoot={DesignRoot} FillStep={this} />
              )}
            </span>
          ) : null}

          {DesignRoot._NORMAL_MAP === true && (
            <div className="normalMap">{this.normalMapList()}</div>
          )}
        </Grid>
      </Grid>
    )
  }
}

FillStep.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FillStep)
