import React from 'react'
import { API_STAGE, SHOP_ID } from '../../../brikl-config'
import Grid from '@material-ui/core/Grid'
import BoardProductPreview from './BoardProductPreview'
class BoardProductPreviews extends React.Component {
  render() {
    const {
      ownedByOrganisationId,
      boardProductId,
      shopId,
      rotate,
      techpack
    } = this.props
    const imgStyle = {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      margin: '0px'
    }
    const imgContainer = {
      height: techpack ? '100%' : '60px'
    }
    const stage = 'production' //API_STAGE
    const oldPrefix = `https://s3-ap-southeast-1.amazonaws.com/${stage}-brikl-shop-product-design-previews/${boardProductId}`
    const prefix = `https://s3-ap-southeast-1.amazonaws.com/${stage}-brikl-ecommerce/${SHOP_ID ||
      shopId}/userdata/design/${boardProductId}`

    return (
      <Grid container direction={techpack ? 'row' : 'column'}>
        <Grid item xs={techpack ? 6 : 12} style={imgContainer}>
          <BoardProductPreview
            file={'0'}
            boardProductId={boardProductId}
            techpack
            shopId={SHOP_ID || shopId}
          />
        </Grid>
        <Grid item xs={techpack ? 6 : 12} style={imgContainer}>
          <BoardProductPreview
            file={'180'}
            boardProductId={boardProductId}
            techpack
            shopId={SHOP_ID || shopId}
          />
        </Grid>
        <Grid item xs={techpack ? 6 : 12} style={imgContainer}>
          <BoardProductPreview
            file={'90'}
            boardProductId={boardProductId}
            techpack
            shopId={SHOP_ID || shopId}
          />
        </Grid>
        <Grid item xs={techpack ? 6 : 12} style={imgContainer}>
          <BoardProductPreview
            file={'270'}
            boardProductId={boardProductId}
            techpack
            shopId={SHOP_ID || shopId}
          />
        </Grid>
      </Grid>
    )
  }
}
export default BoardProductPreviews
