import React, { useState, useEffect } from 'react'
import { Grid, Button } from '@material-ui/core'
import { navigate } from '@reach/router'
import PagesContainer from './PagesContainer'
import EditorContainer from './EditorContainer'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  ADMIN_GET_CONTENT_THEME,
  ADMIN_GET_CONTENT_MENU
} from '../../../gql/admin-queries'
import { CircularProgress } from '@material-ui/core'
import SetLanguage from './SetLanguage'
import {
  SelectedLanguageContext,
  LanguagesContext,
  UrlPrefixContext,
  ShopContext
} from './LanguageContext'
import { ThemeContext } from './ThemeContext'
import { ADMIN_GET_CONTENT_MODELS, ADMIN_SHOP_TEAMSTORES } from './gql/queries'
import {
  ADMIN_CREATE_CONTENT_MODEL,
  ADMIN_CREATE_DEFAULT_PAGES,
  ADMIN_UPDATE_THEME_ID
} from './gql/mutations'
import NewHeader from './NewHeader/NewHeader'
import { PriceContext } from './PriceContext'
import _ from 'lodash'

interface Props {
  shopId: string
  languages: any
  defaultLanguage: any
  shopUrl: any
  shop: any
}

const MainContainer: React.FunctionComponent<Props> = props => {
  const { shopId, languages, defaultLanguage, shopUrl, shop } = props

  const [priceData, setPriceData] = useState<any>({})
  const [otherPages, setOtherPages] = useState<any>([])
  const [defaultPages, setDefaultPages] = useState<any>([])
  const [selected, setSelected] = useState('')
  const [editing, setEditing] = useState(false)
  const [responsiveView, setResponsiveView] = useState({
    xs: 10,
    mode: 'desktop',
    editing: false
  })
  const [homePageId, setHomePageId] = useState(null)
  const [selectedLanguage, setSelectedLanguage] = useState(
    defaultLanguage ? defaultLanguage : 'en_UK'
  )
  const [selectedSalesChannel, setSelectedSalesChannel] = useState('MYBRIKL')
  const [availableLanguages, setAvailableLanguages] = useState(
    languages ? languages : ['en_UK']
  )
  const [urlPrefix, setUrlPrefix] = useState('en')

  const composeQueries = () => {
    const modelsQuery = useQuery(ADMIN_GET_CONTENT_MODELS, {
      variables: {
        id: shopId,
        salesChannelId: selectedSalesChannel
      }
    })

    const themeQuery = useQuery(ADMIN_GET_CONTENT_THEME, {
      variables: {
        id: shopId
      }
    })

    const homePageQuery = useQuery(ADMIN_GET_CONTENT_MENU, {
      variables: {
        id: shopId
      }
    })

    const salesChannelsQuery = useQuery(ADMIN_SHOP_TEAMSTORES, {
      variables: {
        id: shopId
      }
    })

    return [modelsQuery, themeQuery, homePageQuery, salesChannelsQuery]
  }

  const [
    { loading: modelsLoading, data: modelsData },
    { loading: themeLoading, data: themeData },
    { loading: homeLoading, data: homePageData },
    { loading: salesChannelsLoading, data: salesChannelsData }
  ] = composeQueries()

  const [createContentModel, createContentModelResult] = useMutation(
    ADMIN_CREATE_CONTENT_MODEL,
    {
      refetchQueries: [
        {
          query: ADMIN_GET_CONTENT_MODELS,
          variables: { id: shopId, salesChannelId: selectedSalesChannel }
        }
      ]
    }
  )

  const [updateShopContentSettings] = useMutation(ADMIN_UPDATE_THEME_ID, {
    refetchQueries: [
      { query: ADMIN_GET_CONTENT_THEME, variables: { id: shopId } }
    ]
  })

  const [createDefaultPages, createDefaultPagesResult] = useMutation(
    ADMIN_CREATE_DEFAULT_PAGES,
    {
      variables: {
        ownedByOrganisationId: ''
      },
      refetchQueries: [
        { query: ADMIN_GET_CONTENT_MODELS, variables: { id: shopId } }
      ]
    }
  )

  // set themeId if it's null (TODO: handle this in the backend e.g.: set on shop creation)
  useEffect(() => {
    if (!themeLoading && !themeData?.shop?.contentSettings?.themeId) {
      updateShopContentSettings({
        variables: {
          id: shopId,
          input: {
            contentSettings: {
              themeId: 'theme1'
            }
          }
        }
      })
    }
  }, [!themeLoading, themeData?.shop?.contentSettings?.themeId])

  const handleSwitchEditing = () => {
    setEditing(!editing)
  }

  const handleSetLanguage = (language: string) => {
    setSelectedLanguage(language)
  }

  const handleSetSelected = (id: string) => {
    setSelected(id)
  }

  // Set urlprefix based on selected language (side effect when selected language changes)
  useEffect(() => {
    setUrlPrefix(selectedLanguage.split('_')[0] + '/')
  }, [selectedLanguage])

  // Set default selected page to the first item

  useEffect(() => {
    setDefaultPages(
      modelsData?.shop.contentModels.filter((o: any) => o.defaultPage)
    )
    setOtherPages(
      modelsData?.shop.contentModels.filter((o: any) => !o.defaultPage)
    )
    if (
      modelsData?.shop.hasDefaultPages === false ||
      modelsData?.shop.hasDefaultPages === null
    ) {
      createDefaultPages()
    }
  }, [modelsData])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (otherPages && urlParams?.get('p') !== '') {
      setSelected(urlParams.get('p'))
    } else {
      setSelected(otherPages && otherPages.length > 0 ? otherPages[0].id : '')
    }
  }, [otherPages])

  useEffect(() => {
    setHomePageId(homePageData?.shop?.contentSettings?.homePageId || null)
    setPriceData(
      _.pick(shop, [
        'currencies',
        'currencyRates',
        'defaultCurrency',
        'discounts',
        'taxSettings'
      ])
    )
  }, [homePageData])

  if (modelsLoading) return <CircularProgress />
  if (!modelsData) return null

  return (
    <SelectedLanguageContext.Provider value={selectedLanguage}>
      <LanguagesContext.Provider value={availableLanguages}>
        <UrlPrefixContext.Provider value={urlPrefix}>
          <ShopContext.Provider value={shopId}>
            <ThemeContext.Provider
              value={
                themeData && themeData.shop.contentSettings
                  ? themeData.shop.contentSettings
                  : null
              }
            >
              <PriceContext.Provider value={priceData}>
                <NewHeader shopId={shopId} />
                <Grid style={{ backgroundColor: 'white' }} container={true}>
                  {!editing ? (
                    <Grid
                      style={{ padding: '40px 0px 40px 40px' }}
                      item={true}
                      xs={2}
                    >
                      <Button
                        onClick={() => {
                          navigate(`/admin/${shopId}/inventory/products`)
                        }}
                        style={{
                          backgroundColor: 'white',
                          textTransform: 'uppercase',
                          padding: '10px 40px',
                          fontSize: 12,
                          color: '#B23566',
                          fontWeight: 'bold',
                          border: '1px solid #b23566',
                          borderRadius: 2
                        }}
                      >
                        {`< Back`}
                      </Button>
                      <SetLanguage
                        languages={languages}
                        selectedLanguage={selectedLanguage}
                        handleSetLanguage={handleSetLanguage}
                        editing={editing}
                      />
                      <PagesContainer
                        isCreatingDefaultPages={
                          createDefaultPagesResult.loading
                        }
                        isCreating={createContentModelResult.loading}
                        defaultPages={defaultPages}
                        shopId={shopId}
                        shopUrl={shopUrl}
                        createPage={createContentModel}
                        otherPages={otherPages}
                        selected={selected}
                        handleSetSelected={handleSetSelected}
                        homePageId={homePageId}
                        selectedSalesChannel={selectedSalesChannel}
                        setSelectedSalesChannel={setSelectedSalesChannel}
                        salesChannels={salesChannelsData?.shop?.salesChannels}
                      />
                    </Grid>
                  ) : null}
                  <Grid
                    data-testid="editor"
                    style={
                      !editing
                        ? { padding: '40px 40px 0px 0px' }
                        : { padding: '40px 0px 0px 0px' }
                    }
                    item={true}
                    xs={editing ? 12 : 10}
                  >
                    <EditorContainer
                      handleSwitchEditing={handleSwitchEditing}
                      editing={editing}
                      selected={selected}
                      languages={languages}
                      selectedLanguage={selectedLanguage}
                      handleSetLanguage={handleSetLanguage}
                      shopId={shopId}
                      responsiveView={responsiveView}
                      setResponsiveView={setResponsiveView}
                      shopUrl={shopUrl}
                    />
                  </Grid>
                </Grid>
              </PriceContext.Provider>
            </ThemeContext.Provider>
          </ShopContext.Provider>
        </UrlPrefixContext.Provider>
      </LanguagesContext.Provider>
    </SelectedLanguageContext.Provider>
  )
}

export default MainContainer
