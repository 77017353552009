import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

class Body1 extends React.Component {
  render() {
    return (
      <Typography variant="body1" gutterBottom>
        {this.props.text}
      </Typography>
    );
  }
}

Body1.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Body1);
