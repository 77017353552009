import React, { Component } from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import Auth from '@aws-amplify/auth'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Grid } from '@material-ui/core'
import { primary, secondary } from '../common/Theme'
import Button from '@material-ui/core/Button'
import { SHOP_ID, SHOP_LOGO } from '../../helper/config'
import CartHeader from './cart/CartHeader'
import { UserContext } from '../user/context/UserContext'
import { Link } from 'gatsby'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'

const styles = theme => ({
  appbar: {
    background: SHOP_ID === 'STEDMAN' ? '#fff' : secondary,
    boxShadow: 'none',
    position: 'fixed',
    width: '100%',
    height: '50px',
    top: 0,
    left: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      height: 85,
      alignItems: 'center'
    }
  },
  toolbar2: {
    minHeight: '50px',
    background: SHOP_ID === 'STEDMAN' ? '#fff' : secondary,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  toolbarBodywearlab: {
    minHeight: '50px',
    background: 'white'
  },
  headerNav: {
    color: SHOP_ID === 'gkm' || 'STEDMAN' ? '#ffffff' : '#ffffff',
    minHeight: '50px',
    margin: '0',
    fontSize: '14px',
    textTransform: 'Capitalize',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-flex',
    textDecoration: 'none'
  },
  icon: {
    marginRight: '4px',
    width: '20px'
  },
  logo: {
    height: '34px',
    fill: primary + 'app/!important'
  },
  logoBrikl: {
    height: '42px',
    marginRight: '10px'
  },
  logoBodywearlab: {},
  inputbox: {
    width: '100%',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    padding: '1.5px 16px !important',
    color: '#111111 !important',
    background: '#ffffff',
    fontSize: '14px',
    '&:after': {
      display: 'none'
    },
    '&:before': {
      display: 'none'
    }
  },
  input: {
    padding: '8px',
    color: '#111111'
  },
  searchButton: {
    background: '#ffffff !important',
    minHeight: '35px',
    height: '35px',
    borderBottomLeftRadius: '0px',
    borderTopLeftRadius: '0px',
    position: 'absolute',
    top: '8px',
    left: '100%',
    color: '#afafaf !important',
    ':hover': {
      color: '#111111 !important'
    }
  },
  separator: {
    height: '20px',
    width: '1px',
    background: '#000000',
    marginTop: '19px'
  },
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  },
  toolbarInfo: {
    background: '#000000 !important',
    minHeight: '30px'
  },
  span: {
    color: '#ffffff',
    fontSize: '13px',
    fontWeight: '700'
  },
  responsive: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobile: {
    display: 'none',
    width: '100%',
    height: 60,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      height: 85
    }
  },
  drawerwidth: {
    width: '100vw'
  },
  logo: {
    height: '40px'
  },
  logoButton: {
    '&:hover': {
      background: 'transparent'
    }
  },
  button: {
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: '4px'
  }
})
var store = require('store')
export const logout = async currentUser => {
  await currentUser.signOut()
  await Auth.signOut()
  store.clearAll()

  window.location.reload()
  navigate('/')
}

class ObsHeader extends Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    })
  }

  render() {
    const { classes, currentUser, urlPrefix } = this.props
    return (
      <AppBar position="static" className={classes.appbar}>
        <div className={classes.responsive}>
          <Toolbar className={classes.toolbar2}>
            <Grid container>
              <Grid item xs={12} sm={5} md={5} lg={5} xl={4}>
                <Button
                  data-cy="header-btn-mycard"
                  href={'/' + urlPrefix + 'about-us'}
                  className={classes.headerNav}
                >
                  Our story
                </Button>{' '}
              </Grid>

              <Grid item xs={12} sm={5} md={2} lg={2} xl={4}>
                <Grid container justify="center"></Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={4}>
                <Grid container justify="space-between">
                  <Link
                    data-cy="header-btn-collection"
                    to={'/' + urlPrefix + 'collections'}
                    className={classes.headerNav}
                  >
                    Collection
                  </Link>
                  <Link
                    data-cy="header-btn-mylibrary"
                    className={classes.headerNav}
                    to={'/' + urlPrefix + 'app/designs'}
                  >
                    Saved designs
                  </Link>{' '}
                  <Link
                    data-cy="header-btn-mycart"
                    className={classes.headerNav}
                    to={'/' + urlPrefix + 'app/cart'}
                  >
                    My Cart
                  </Link>{' '}
                  <Link
                    data-cy="header-btn-myorders"
                    className={classes.headerNav}
                    to={'/' + urlPrefix + 'app/orders'}
                  >
                    My orders
                  </Link>
                  {currentUser && currentUser.id ? (
                    <Link
                      onClick={async () => {
                        logout(currentUser)
                      }}
                      data-cy="header-btn-logout"
                      className={classes.headerNav}
                    >
                      Logout
                    </Link>
                  ) : (
                    <Link
                      data-cy="header-btn-login"
                      className={classes.headerNav}
                      to={'/' + urlPrefix + 'app/login'}
                    >
                      Log in
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.mobile}
        >
          <IconButton
            style={{
              minWidth: 60,
              width: 60,
              height: 60,
              minHeight: 60,
              marginRight: '24px'
            }}
            color="inherit"
            aria-label="Menu"
            onClick={this.toggleDrawer('right', true)}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Drawer
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer('right', false)}
        >
          <Grid container className={classes.drawerwidth} justify="flex-end">
            <IconButton
              style={{ minWidth: 60, width: 60, height: 60, minHeight: 60 }}
              onClick={this.toggleDrawer('right', false)}
            >
              <CloseIcon />
            </IconButton>
            <Grid container direction="column">
              <Button className={classes.button} href={'/'}>
                Home
              </Button>
              <Button
                className={classes.button}
                href={'/' + urlPrefix + 'collections'}
              >
                Collection
              </Button>
              <Button
                className={classes.button}
                href={'/' + urlPrefix + 'app/designs'}
              >
                Saved design
              </Button>
              <Button
                className={classes.button}
                href={'/' + urlPrefix + 'app/cart'}
              >
                My Cart
              </Button>
              <Button
                className={classes.button}
                href={'/' + urlPrefix + 'app/orders'}
              >
                My orders
              </Button>
              <Button
                className={classes.button}
                href={'/' + urlPrefix + 'app/designs'}
              >
                My designs
              </Button>
              {currentUser && currentUser.id ? (
                <Button
                  className={classes.button}
                  onClick={async () => {
                    logout(currentUser)
                  }}
                  data-cy="header-btn-logout"
                >
                  Logout
                </Button>
              ) : (
                <Button
                  component={Link}
                  className={classes.button}
                  data-cy="header-btn-login"
                  href={'/' + urlPrefix + 'app/login'}
                >
                  Log in
                </Button>
              )}
            </Grid>
          </Grid>
        </Drawer>
      </AppBar>
    )
  }
}

ObsHeader.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(ObsHeader)
