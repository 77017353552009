import React from 'react'
import ColorPickerControl from './Inputs/ColorPickerControl'

import {
  TextField,
  InputLabel,
  Grid,
  Button,
  NativeSelect,
} from '@material-ui/core'
import HelperTooltip from './Inputs/HelperTooltip'
import { CONTAINER_PRESETS } from '../Elements/elements'

interface Props {
  controls: any
  fieldId: string | null
  field: any
  handleUpdateField(fieldId: string | null, fieldName: string, value: any): any
}

const UpdateFieldControls: React.FC<Props> = props => {
  const { controls, handleUpdateField, fieldId, field } = props
  return (
    <>
      {controls.fields.map((control: any) => {
        if (control.UIType === 'color-picker') {
          return (
            <>
              <ColorPickerControl
                defaultValue={field?.backgroundColor || null}
                handleUpdate={handleUpdateField}
                control={control}
                id={fieldId}
              />
            </>
          )
        } else if (control.UIType === 'input') {
          return (
            <Grid container={true} style={{ padding: 20 }}>
              <InputLabel>Max Width</InputLabel>
              {control.helperText ? (
                <HelperTooltip helperText={control.helperText} />
              ) : null}
              <TextField
                placeholder="Max width"
                defaultValue={field.maxWidth}
                onBlur={e => {
                  if (e.target.value.includes('px')) {
                    handleUpdateField(fieldId, 'maxWidth', e.target.value)
                  } else if (e.target.value.includes('%')) {
                    handleUpdateField(fieldId, 'maxWidth', e.target.value)
                  } else {
                    handleUpdateField(
                      fieldId,
                      'maxWidth',
                      e.target.value + 'px'
                    )
                  }
                }}
              />
            </Grid>
          )
        } else if (control.UIType === 'padding-input') {
          return (
            <Grid container={true} style={{ padding: 20 }}>
              <InputLabel>Padding</InputLabel>
              {control.helperText ? (
                <HelperTooltip helperText={control.helperText} />
              ) : null}
              <TextField
                placeholder="Padding"
                defaultValue={field.padding ? field.padding : null}
                onBlur={e =>
                  handleUpdateField(fieldId, 'padding', e.target.value)
                }
              />
            </Grid>
          )
        } else if (control.UIType === 'container-preset') {
          return (
            <Grid container={true} style={{ padding: 20 }}>
              <InputLabel>Presets</InputLabel>
              {control.helperText ? (
                <HelperTooltip helperText={control.helperText} />
              ) : null}
              {CONTAINER_PRESETS.map((preset: any, index: number) => {
                return (
                  <Grid
                    key={index}
                    container={true}
                    justify="center"
                    style={{ padding: 10 }}
                  >
                    <Button
                      key={index}
                      onClick={() =>
                        handleUpdateField(fieldId, 'maxWidth', preset.value)
                      }
                      variant="outlined"
                      color="primary"
                    >
                      {preset.label}
                    </Button>
                  </Grid>
                )
              })}
            </Grid>
          )
        } else if (control.UIType === 'select') {
          return (
            <Grid container={true} style={{ padding: 20 }}>
              <InputLabel>{control.label}</InputLabel>
              <NativeSelect
                value={field[control.name]}
                onChange={e => {
                  handleUpdateField(fieldId, control.name, e.target.value)
                }}
                style={{ border: '1px solid lightgrey', borderRadius: 2 }}
              >
                {control.options.map((option: string, index: number) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </NativeSelect>
            </Grid>
          )
        } else return null
      })}
    </>
  )
}

export default UpdateFieldControls
