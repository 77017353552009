import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {
  Divider,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import { getTextFromLanguage } from '../../../../helper/languageHelper'

import DeleteImage from './DeleteImage'
import Uploader from './Uploader'
import NativeSelect from '@material-ui/core/NativeSelect'
import Slider from '@material-ui/core/Slider'
import ColorPicker from './ColorPicker'
import AlignItems from './AlignItems'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Query } from 'react-apollo'
import {
  GET_PAGES,
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_SHOP_PRODUCT_PACKAGES,
  ADMIN_SHOP_PRODUCTS,
  ADMIN_SHOP_SALESCHANNELS
} from '../../gql/admin-queries'
import { MEDIA_CDN_URL } from '../../../../../brikl-config'
import SelectPage from './SelectPage'
import SelectProducts from './SelectProducts'
import SelectTeamstore from './SelectTeamstore'
import SelectProductPackage from './SelectProductPackage'
import SelectCollection from './SelectCollection'

import { BORDER_TYPES } from './config'

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase)

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  input: {
    paddingLeft: 8,
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  }
})

class UpdateHubspotForm extends React.Component {
  state = {
    uploading: false,
    pageDialog: false,
    urlType: this.props.fields.urlType,
    selectedProduct: this.props.fields.buttonUrl,
    selectedProductId: this.props.fields.selectedProductId
      ? this.props.fields.selectedProductId
      : '',
    selectedTeamstore: this.props.fields.buttonUrl,
    selectedTeamstoreId: this.props.fields.selectedTeamstoreId
      ? this.props.fields.selectedTeamstoreId
      : '',
    selectedProductPackage: this.props.fields.buttonUrl,
    selectedProductPackageId: this.props.fields.selectedProductPackageId
      ? this.props.fields.selectedProductPackageId
      : '',
    selectedPage: this.props.fields.buttonUrl,
    selectedPageId: this.props.fields.selectedPageId
      ? this.props.fields.selectedPageId
      : '',
    selectedCollection: this.props.fields.buttonUrl,
    selectedCollectionId: this.props.fields.selectedCollectionId
      ? this.props.fields.selectedCollectionId
      : ''
  }

  handlePageOpen = () => {
    this.setState({ pageDialog: true })
  }

  handlePageClose = () => {
    this.setState({ pageDialog: false })
  }

  handleChangeProductPackage = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedTeamstore: null,
      selectedTeamstoreId: null,
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProductPackage: `/${slug}`,
      selectedProductPackageId: event.target.value
    })
    fields.productPackageId = event.target.value
  }

  handleChangePage = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedTeamstore: null,
      selectedTeamstoreId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedPage: `/${slug}`,
      selectedPageId: event.target.value
    })
    fields.selectedPageId = event.target.value
  }
  handleChangeCollection = (event, slug) => {
    const { fields } = this.props

    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedTeamstore: null,
      selectedTeamstoreId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedCollection: `/${slug}`,
      selectedCollectionId: event.target.value
    })
    fields.collectionId = event.target.value
  }
  handleChangeTeamstore = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedTeamstore: `/${slug}`,
      selectedTeamstoreId: event.target.value
    })

    fields.teamstoreId = event.target.value
  }
  handleChangeProduct = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedProduct: `/${slug}`,
      selectedProductId: event.target.value
    })
    fields.productId = event.target.value
  }

  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,      
      fields,
      Template,
      shopId,
      languages
    } = this.props

    return (
      <Grid>
        <ExpansionPanel
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
          expanded={expanded === 'panel2'}
          onChange={handleExpandedChange('panel2')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ expanded: classes.expansionPanelExpanded }}
          >
            <Typography
              variant="subtitle1"
              className={classes.typo}
              data-cy="Card-Settings"
            >
              Hubspot Form Settings
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: classes.expansionPanelDetails }}
          >
            <Divider />
            <List>
              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-portalId-Label"
                  >
                    portalId - {fields.portalId}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <InputBase
                      className={classes.input}
                      placeholder=""
                      value={fields.portalId}
                      onChange={(e: any) =>
                        handleChangeField(e.target.value, 'portalId')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-formId-Label"
                  >
                    formId - {fields.formId}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <InputBase
                      className={classes.input}
                      placeholder=""
                      value={fields.formId}
                      onChange={(e: any) =>
                        handleChangeField(e.target.value, 'formId')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-cssClass-Label"
                  >
                    cssClass - {fields.cssClass}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <InputBase
                      className={classes.input}
                      placeholder=""
                      value={fields.cssClass}
                      onChange={(e: any) =>
                        handleChangeField(e.target.value, 'cssClass')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-target-Label"
                  >
                    target - {fields.target}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <InputBase
                      className={classes.input}
                      placeholder=""
                      value={fields.target}
                      onChange={(e: any) =>
                        handleChangeField(e.target.value, 'target')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    )
  }
}

UpdateHubspotForm.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
}

export default withStyles(styles)(UpdateHubspotForm)
