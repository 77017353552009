import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

// import Dropzone from 'react-dropzone';
import { ADMIN_GET_TEAMSTORE } from '../../gql/admin-queries'
import { ADMIN_UPDATE_TEAMSTORE } from '../../gql/admin-mutations'
import _get from 'lodash/get'
import Input from '../../../common/Input'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '../../../common/TextField'
import './ProductFileDrop.css'
import { I18n } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import TeamstoreMenu from './TeamstoreMenu'
import TeamstoreGeneral from './TeamstoreGeneral'
import TeamstoreProducts from './TeamstoreProducts'
import TeamstoreStructure from './TeamstoreStructure'
import TeamstorePersonalization from './TeamstorePersonalization'
import { slugWithUrlPrefix } from '../content/utils'
import Checkbox from '@material-ui/core/Checkbox'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  }
})

class TeamstoreDetail extends Component {
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  render() {
    const {
      shop,
      classes,
      teamstoreId,
      defaultLanguage,
      defaultCurrency,
      languages,
      shopUrl,
      shopId
    } = this.props
    return (
      <Grid container className={classes.root}>
        <Query query={ADMIN_GET_TEAMSTORE} variables={{ id: teamstoreId }}>
          {({ loading, error, data: { teamstore }, refetch }) => {
            if (loading)
              return <CircularProgress className={classes.progress} />
            // if (error) return <div>Error :(</div>;
            // if (!product) return null;
            // return JSON.stringify(window.location);
            if (!teamstore) return null

            return (
              <Mutation
                variables={{
                  id: teamstoreId
                }}
                update={(store, { data: { updateProduct } }) => {
                  this.setState({
                    isActive: true,
                    message: 'Product updated'
                  })
                }}
                mutation={ADMIN_UPDATE_TEAMSTORE}
              >
                {(updateTeamstore, { data, loading, error }) => {
                  var teamstoreComponent = <div />
                  try {
                    teamstoreComponent =
                      window.location.pathname.indexOf('general') !== -1 ? (
                        <TeamstoreGeneral
                          shop={shop}
                          defaultCurrency={defaultCurrency}
                          defaultLanguage={defaultLanguage}
                          languages={languages}
                          updateTeamstore={updateTeamstore}
                          teamstoreId={teamstoreId}
                          teamstore={teamstore}
                          shopId={shopId}
                        />
                      ) : window.location.pathname.indexOf('products') !==
                        -1 ? (
                        <TeamstoreProducts
                          defaultCurrency={defaultCurrency}
                          defaultLanguage={defaultLanguage}
                          updateTeamstore={updateTeamstore}
                          teamstoreId={teamstoreId}
                          teamstore={teamstore}
                          shopId={shopId}
                        />
                      ) : window.location.pathname.indexOf('structure') !==
                        -1 ? (
                        <TeamstoreStructure
                          defaultCurrency={defaultCurrency}
                          defaultLanguage={defaultLanguage}
                          languages={languages}
                          updateTeamstore={updateTeamstore}
                          teamstoreId={teamstoreId}
                          teamstore={teamstore}
                          shopId={shopId}
                        />
                      ) : window.location.pathname.indexOf(
                          'personalization'
                        ) !== -1 ? (
                        <TeamstorePersonalization
                          defaultCurrency={defaultCurrency}
                          defaultLanguage={defaultLanguage}
                          languages={languages}
                          updateTeamstore={updateTeamstore}
                          teamstoreId={teamstoreId}
                          teamstore={teamstore}
                          shopId={shopId}
                        />
                      ) : (
                        <TeamstoreGeneral
                          defaultCurrency={defaultCurrency}
                          defaultLanguage={defaultLanguage}
                          languages={languages}
                          updateTeamstore={updateTeamstore}
                          teamstoreId={teamstoreId}
                          teamstore={teamstore}
                          shopId={shopId}
                        />
                      )
                  } catch (e) {
                    console.error(e)
                  }
                  return (
                    <div>
                      <Grid
                        container
                        justify="flex-start"
                        direction="row"
                        alignItems="center"
                        style={{
                          height: 60,
                          marginBottom: 20,
                          padding: 24,
                          marginLeft: 24
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 28,
                            fontWeight: 400,
                            color: '#424242',
                            marginRight: 24
                          }}
                        >
                          Team store
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            window.open(
                              `${shopUrl}/preview?type=teamstore&id=${teamstore.id}&language=${defaultLanguage}`
                            )
                          }}
                        >
                          Preview
                        </Button>
                        <Typography
                          component="div"
                          className={classes.buttonCheckbox}
                        >
                          <Checkbox
                            type="checkbox"
                            classes={{
                              root: classes.rootCheckbox,
                              checked: classes.checked
                            }}
                            checked={teamstore.enabled === true ? true : false}
                            onChange={e => {
                              updateTeamstore({
                                optimisticResponse: {
                                  __typename: 'Mutation',
                                  updateProduct: {
                                    ...teamstore,
                                    enabled: e.target.checked
                                  }
                                },
                                variables: {
                                  id: teamstoreId,
                                  input: {
                                    enabled: e.target.checked
                                  }
                                }
                              })
                            }}
                          />
                          ACTIVE
                        </Typography>
                        <div style={{ flex: 1 }} />
                        <TeamstoreMenu
                          shopId={shopId}
                          teamstoreId={teamstoreId}
                        />
                      </Grid>

                      {teamstoreComponent}
                    </div>
                  )
                }}
              </Mutation>
            )
          }}
        </Query>
      </Grid>
    )
  }
}

export default withStyles(styles)(TeamstoreDetail)
