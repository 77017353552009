import React from 'react'
import {
  makeStyles,
  withStyles,
  NativeSelect,
  InputBase
} from '@material-ui/core'

import client from '../../../gql/apollo-config'
import { Query } from 'react-apollo'
import { GET_USER } from '../../../gql/queries'
import { UPDATE_USER_SETTINGS } from '../../../gql/mutations'
import { DEFAULT_CURRENCY } from '../../../helper/config'
import { setCurrency } from '../../../helper/storage'

interface Props extends WithStyles<typeof styles> {
  isEditMode?: boolean
  linkColor: any
}
var store = require('store')
const CurrencySwitchContainer: React.FunctionComponent<Props> = props => {
  const { isEditMode, linkColor, styling, theme } = props

  const BootstrapInput = withStyles(theme => ({
    input: {
      borderRadius: 4,
      color: linkColor,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        color: '#000 !important',
        fontSize: '19px !important',
        textTransform: 'uppercase'
      }
    },
    root: {
      'label + &': {
        marginTop: theme.spacing(3)
      },
      color: '#fff',
      marginRight: 0,
      marginTop: 0,
      paddingTop: 0,
      paddingBottom: 0,
      maxWidth: '100%',
      lineHeight: 'inherit',
      [theme.breakpoints.down('sm')]: {
        textTransform: 'uppercase'
      }
    }
  }))(InputBase)

  const useStyles = makeStyles(theme => ({
    icon: {
      color: linkColor,
      [theme.breakpoints.down('sm')]: {
        color: '#000'
      }
    },
    mobileLink: {
      [theme.breakpoints.down('sm')]: {
        color: '#000 !important',
        fontSize: '19px !important',
        width: 200
      }
    },
    currencyRespoinsive: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        width: '100%',
        height: '61px',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  }))

  const currencies = props.currencies || []
  const handleChange = async (e): Promise<any> => {
    if (isEditMode) {
      return
    }
    const currency = e.target.value
    if (!currency) {
      return
    }
    setCurrency(currency)

    await client.mutate({
      mutation: UPDATE_USER_SETTINGS,
      variables: {
        input: {
          settings: {
            defaultCurrency: currency
          }
        }
      }
    })

    document.location.reload()
  }

  const classes = useStyles()

  return (
    <>
      <Query query={GET_USER}>
        {({ loading, error, data }) => {
          if (loading) return null

          let selectedCurrency = DEFAULT_CURRENCY
          try {
            if (
              data &&
              data.user &&
              data.user.settings &&
              data.user.settings.defaultCurrency
            ) {
              selectedCurrency = data.user.settings.defaultCurrency
            }
          } catch (error) {
            console.error('Failed to set default currency', error)
          }
          return (
            <div className={classes.currencyRespoinsive}>
              <NativeSelect
                value={selectedCurrency}
                input={
                  <BootstrapInput
                    name="age"
                    id="age-customized-native-simple"
                  />
                }
                onChange={handleChange}
                classes={{ icon: classes.icon }}
                className={classes.mobileLink}
                style={{
                  color: linkColor ? linkColor : '#000',
                  fontSize:
                    styling && styling.fontSize ? styling.fontSize : '12px'
                }}
              >
                {currencies.map((currency: any, index: number) => (
                  <option
                    key={index}
                    style={{
                      background: '#fff',
                      color: '#000',
                      fontSize:
                        styling && styling.fontSize ? styling.fontSize : '12px'
                    }}
                    value={currency}
                  >
                    {currency}
                  </option>
                ))}
              </NativeSelect>
            </div>
          )
        }}
      </Query>
    </>
  )
}

export default CurrencySwitchContainer
