import React, { Component } from 'react'
import { SketchPicker } from 'react-color'
import {
  InvertColors,
  InvertColorsOff,
  Palette,
  Lens
} from '@material-ui/icons'
import { Grid, Button } from '@material-ui/core'
import { SketchPickerBase } from '../../common/Theme'

class ColorPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      colorPreview: null,
      colorPicker: [],
      colorPickerActive: true,
      color: '#E81212',
      recentColor: []
    }
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot
    var componentType = this.props.componentType

    if (!DesignRoot.ColorPicker) {
      DesignRoot.ColorPicker = {}
    }

    DesignRoot.ColorPicker[componentType] = this
    DesignRoot.setState({ update: true })
    // PROVIDE COMPONENT TO MAIN
  }

  handleChangeColor(color) {
    try {
      var recentColor = this.state.recentColor

      var index = recentColor.indexOf(color.hex)
      if (index > -1) {
        recentColor.splice(index, 1)
      }
      recentColor.unshift(color.hex)
      recentColor = recentColor.slice(0, 10) // number of recent color

      this.setState({ color: color.hex, recentColor: recentColor })

      var DesignRoot = this.props.DesignRoot
      var componentType = this.props.componentType
      var mainFillComponent = this.props.mainFillComponent

      if (componentType === 'FillStep_COLORZONE') {
        mainFillComponent.handleChangeColor(color)
      } else if (componentType === 'FillStep_SVG') {
        mainFillComponent.handleChangeSVGElementColor(color)
      } else if (componentType === 'TextStep_COLOR') {
        mainFillComponent._handleChangeColor('textColor', color)
      } else if (componentType === 'TextStep_OUTLINECOLOR') {
        mainFillComponent._handleChangeColor('outlineColor', color)
      }
    } catch (e) {
      logger.error('ColorPicker.handleChangeColor.error', e)
    }
  }

  render() {
    const {
      colorPreview,
      colorPicker,
      colorPickerActive,
      color,
      recentColor
    } = this.state
    const { DesignRoot, mainFillComponent, componentType } = this.props

    logger.log('RENDER COLOR PICKER', DesignRoot._COLOR_PICKER)

    if (DesignRoot._COLOR_PICKER === false) {
      return null
    }

    return (
      <div
        className="ColorPicker"
        style={{ paddingTop: '5px', marginTop: '5px' }}
      >
        <div style={{ paddingBottom: '10px' }}>Color Picker</div>

        <SketchPickerBase
          width="100%"
          color={color}
          presetColors={recentColor}
          disableAlpha={true}
          onChangeComplete={this.handleChangeColor.bind(this)}
        />
      </div>
    )
  }
}

export default ColorPicker
