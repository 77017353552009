import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Notification from 'react-notification';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { ADMIN_GET_PRODUCT_PACKAGE } from '../../gql/admin-queries';
import { ADMIN_UPDATE_PRODUCT_PACKAGE } from '../../gql/admin-mutations';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import _sortBy from 'lodash/sortBy';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { API_STAGE } from '../../../../../brikl-config';
import { I18n } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import Helmet from 'react-helmet';
import Input from '../../../common/Input';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BoardProductPreview from '../../../common/BoardProductPreview';
import _get from 'lodash/get';
import ProductElement from './ProductElement';
import './ProductFileDrop.css';

import { navigate } from 'gatsby';
import { OrganisationContext } from '../../../user/context/OrganisationContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomProductDetail from './CustomProductDetail';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import ProductPackageDescription from './ProductPackageDescription';
import ProductPackageTitle from './ProductPackageTitle';
import ProductPackagePrice from './ProductPackagePrice';
import ProductPackageMedia from './ProductPackageMedia';
import ProductPackageSlug from './ProductPackageSlug';
import ProductPackageAccountingId from './ProductPackageAccountingId';
import ProductPackageSalesChannels from './ProductPackageSalesChannels';
import ProductPackageAssignedProducts from './ProductPackageAssignedProducts';
import ProductPackageCollections from './ProductPackageCollections';
import AdminSearchProduct from '../common/AdminSearchProduct';
const axios = require('axios');
const shortid = require('shortid');
const uuidv4 = require('uuid/v4');
var jwtDecode = require('jwt-decode');

const styles = theme => ({
  progress: {},
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    marginTop: '24px !important',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  },
  rootCheckbox: {
    padding: '8px',
    color: '#B23566',
    '&$checked': {
      color: '#B23566'
    }
  },
  checked: {},
  inputCell: {
    width: 230,
    border: 'none'
  }
});

class ProductPackageDetail extends Component {
  state = {
    isActive: false,
    message: '',
    isBriklSuperAdmin: false,
    open: false,
    value: 'Yes'
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      if (session) {
        // console.log('session', session);
        let decoded = jwtDecode(session.idToken.jwtToken);
        if (decoded['cognito:groups'].includes('BRIKLSUPERADMIN') !== -1) {
          this.setState({
            isBriklSuperAdmin: true
          });
        } else {
          this.setState({
            isBriklSuperAdmin: false
          });
        }
      }
    } catch (error) {}
  }
  render() {
    const {} = this.state;

    const {
      classes,
      currencies,
      productPackageId,
      defaultCurrency,
      defaultLanguage,
      languages,
      shopId
    } = this.props;
    var blockEdit = true;
    if (
      process.env.GATSBY_API_STAGE === 'local' ||
      process.env.GATSBY_API_STAGE === 'dev'
    ) {
      blockEdit = false;
    }
    if (this.state.isBriklSuperAdmin === true) {
      blockEdit = false;
    }

    return (
      <div className={classes.root}>
        <Typography>
          <Grid container>
            <Grid item xs={12}>
              <Query
                query={ADMIN_GET_PRODUCT_PACKAGE}
                variables={{ id: productPackageId }}
              >
                {({ loading, error, data: { productPackage }, refetch }) => {
                  if (loading)
                    return <CircularProgress className={classes.progress} />;
                  if (error) return <div>Error :(</div>;
                  if (!productPackage) return null;
                  var productTitle = '';
                  try {
                    productTitle = getTextFromLanguage(
                      productPackage.title.text,
                      defaultLanguage
                    );
                  } catch (error) {}
                  return (
                    <Mutation
                      variables={{
                        id: productPackage.id
                      }}
                      update={(store, { data: { updateProductPackage } }) => {
                        this.setState({
                          isActive: true,
                          message: 'Product updated'
                        });
                      }}
                      mutation={ADMIN_UPDATE_PRODUCT_PACKAGE}
                    >
                      {(updateProductPackage, { data, loading, error }) => {
                        return (
                          <div>
                            <Helmet>
                              <title>{'Product package'}</title>
                            </Helmet>
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              style={{ height: 60, marginBottom: 20 }}
                            >
                              <Typography
                                data-cy="New-ProductPackage-header"
                                style={{
                                  fontSize: 28,
                                  fontWeight: 400,
                                  color: '#424242'
                                }}
                              >
                                {'Product package - ' + productTitle}
                              </Typography>

                              <div style={{ flex: 1 }} />

                              <Grid
                                container
                                style={{ width: 100 }}
                                alignItems="center"
                              >
                                <Checkbox
                                  data-cy="ProductPackage-Active-Checkbox"
                                  type="checkbox"
                                  classes={{
                                    root: classes.rootCheckbox,
                                    checked: classes.checked
                                  }}
                                  checked={
                                    productPackage.status === 'ACTIVE'
                                      ? true
                                      : false
                                  }
                                  onChange={e => {
                                    updateProductPackage({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateProductPackage: {
                                          ...productPackage,
                                          status: e.target.checked
                                            ? 'ACTIVE'
                                            : 'INACTIVE'
                                        }
                                      },
                                      variables: {
                                        id: productPackageId,
                                        input: {
                                          status: e.target.checked
                                            ? 'ACTIVE'
                                            : 'INACTIVE'
                                        }
                                      }
                                    });
                                  }}
                                />
                                Active?
                              </Grid>

                              <Button
                                data-cy="ProductPackage-Back-btn"
                                style={{ marginLeft: 16 }}
                                variant="outlined"
                                color="primary"
                                href={
                                  '/admin/' +
                                  shopId +
                                  '/inventory/productpackages'
                                }
                              >
                                Back to product packages
                              </Button>
                            </Grid>

                            {/*
***************************
Product details starts
***************************
 */}

                            <ProductPackageSalesChannels
                              classes={classes}
                              productPackage={productPackage}
                              shopId={shopId}
                              productPackageId={productPackageId}
                              updateProductPackage={updateProductPackage}
                              defaultLanguage={defaultLanguage}
                            />
                            <br />

                            <Grid container>
                              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      data-cy="ProductPackage-Details-header"
                                      variant="subtitle1"
                                      style={{ marginRight: 16 }}
                                    >
                                      Product details
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="body1">
                                      Provide information on your product
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                <Paper
                                  style={{ padding: 24, borderRadius: 15 }}
                                  elevation={0}
                                >
                                  <Grid
                                    container
                                    className={classes.container}
                                    spacing={1}
                                  >
                                    <Grid item xs={12}>
                                      <Input
                                        dataCy="ProductPackage-Style-Number"
                                        label="Internal ID (style number)"
                                        placeholder="Enter internal ID or style number"
                                        value={productPackage.no}
                                        onChange={e =>
                                          updateProductPackage({
                                            optimisticResponse: {
                                              __typename: 'Mutation',
                                              updateProductPackage: {
                                                ...productPackage,
                                                no: e.target.value
                                              }
                                            },
                                            variables: {
                                              id: productPackageId,
                                              input: { no: e.target.value }
                                            }
                                          })
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <ProductPackageAccountingId
                                    classes={classes}
                                    productPackage={productPackage}
                                    shopId={shopId}
                                    productPackageId={productPackageId}
                                    updateProductPackage={updateProductPackage}
                                    defaultLanguage={defaultLanguage}
                                  />
                                  <ProductPackageTitle
                                    classes={classes}
                                    productPackage={productPackage}
                                    shopId={shopId}
                                    productPackageId={productPackageId}
                                    updateProductPackage={updateProductPackage}
                                    defaultLanguage={defaultLanguage}
                                    languages={languages}
                                  />
                                  <ProductPackageAssignedProducts
                                    classes={classes}
                                    productPackage={productPackage}
                                    shopId={shopId}
                                    productPackageId={productPackageId}
                                    updateProductPackage={updateProductPackage}
                                    defaultLanguage={defaultLanguage}
                                  />
                                  <Grid container style={{ marginTop: 24 }}>
                                    <Typography varian="subtitle1">
                                      Search products to be assigned to this
                                      package:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    style={{
                                      marginTop: 0,
                                      position: 'relative'
                                    }}
                                  >
                                    <AdminSearchProduct
                                      classes={classes}
                                      onSelect={product => {
                                        updateProductPackage({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            updateProductPackage: {
                                              ...productPackage,
                                              products: productPackage.products
                                                ? [
                                                    ...productPackage.products,
                                                    product
                                                  ]
                                                : [product],
                                              productIds: productPackage.productIds
                                                ? [
                                                    ...productPackage.productIds,
                                                    product.id
                                                  ]
                                                : [product.id]
                                            }
                                          },
                                          variables: {
                                            id: productPackage.id,
                                            input: {
                                              productIds: productPackage.productIds
                                                ? [
                                                    ...productPackage.productIds,
                                                    product.id
                                                  ]
                                                : [product.id]
                                            }
                                          }
                                        });
                                      }}
                                      excludeIds={productPackage.productIds}
                                    />
                                  </Grid>
                                </Paper>
                              </Grid>
                            </Grid>
                            <ProductPackagePrice
                              currencies={currencies}
                              defaultCurrency={defaultCurrency}
                              classes={classes}
                              productPackage={productPackage}
                              shopId={shopId}
                              productPackageId={productPackageId}
                              updateProductPackage={updateProductPackage}
                              defaultLanguage={defaultLanguage}
                            />

                            <Grid container style={{ marginTop: 24 }}>
                              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      data-cy="ProductPackage-Slugs-header"
                                      variant="subtitle1"
                                      style={{ marginRight: 16 }}
                                    >
                                      Product Slugs
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="body1" />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                <Paper elevation={0} style={{ padding: 24 }}>
                                  <ProductPackageSlug
                                    classes={classes}
                                    productPackage={productPackage}
                                    shopId={shopId}
                                    productPackageId={productPackageId}
                                    updateProductPackage={updateProductPackage}
                                    defaultLanguage={defaultLanguage}
                                    languages={languages}
                                  />
                                </Paper>
                              </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: 24 }}>
                              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      data-cy="ProductPackage-Description-header"
                                      variant="subtitle1"
                                      style={{ marginRight: 16 }}
                                    >
                                      Product package description
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="body1" />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                <Paper elevation={0} style={{ padding: 24 }}>
                                  <ProductPackageDescription
                                    classes={classes}
                                    productPackage={productPackage}
                                    shopId={shopId}
                                    productPackageId={productPackageId}
                                    updateProductPackage={updateProductPackage}
                                    defaultLanguage={defaultLanguage}
                                    languages={languages}
                                  />
                                </Paper>
                              </Grid>
                            </Grid>

                            {/*
***************************
Product details ends
***************************
*/}

                            <ProductPackageCollections
                              blockEdit={blockEdit}
                              updateProductPackage={updateProductPackage}
                              defaultLanguage={defaultLanguage}
                              productPackageId={productPackageId}
                              productPackage={productPackage}
                              shopId={shopId}
                              classes={classes}
                            />

                            {/*
***************************
Product Images starts
***************************
*/}
                            <ProductPackageMedia
                              blockEdit={blockEdit}
                              updateProductPackage={updateProductPackage}
                              defaultLanguage={defaultLanguage}
                              productPackageId={productPackageId}
                              productPackage={productPackage}
                              shopId={shopId}
                              classes={classes}
                            />

                            {/*
***************************
Product Images ends
***************************
*/}
                          </div>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Query>
            </Grid>
          </Grid>
        </Typography>
      </div>
    );
  }
}

ProductPackageDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductPackageDetail);
