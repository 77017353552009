import React, { useState } from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Toolbar
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import client from "../../../gql/admin-apollo-config";
import { ADMIN_UPDATE_CONTENT_PAGE } from "../../../gql/admin-mutations";
import PageSlugs from "./PageSlugs";

const styles = () => createStyles({});

interface Props extends WithStyles<typeof styles> {
  page: any;
  data: any;
}

const PageSlugsContainer: React.FunctionComponent<Props> = props => {
  const { page, data, languages } = props;

  let slugsData = languages.map(language => {
    return data
      ? data.find(s => s.langCode === language) || {
          langCode: language,
          content: ""
        }
      : { langCode: language, content: "" };
  });

  const [slugs, setSlugs] = useState(slugsData ? slugsData[0] : []);

  const handleChangeData = (value: string, language: string) => {
    slugsData.find(s => s.langCode === language).content = value;
    setSlugs(slugsData);
  };

  const handleSave = async () => {
    slugsData = slugsData.map(o => {
      delete o.__typename;
      return o;
    });
    await client.mutate({
      mutation: ADMIN_UPDATE_CONTENT_PAGE,
      variables: {
        id: page.id,
        input: {
          slugs: slugsData
        }
      }
    });
  };

  return (
    <Toolbar>
      <PageSlugs data={slugsData} onChange={handleChangeData} />
      <Button
        title="Save"
        onClick={handleSave}
        variant="contained"
        color="primary"
      >
        <CheckCircle />
      </Button>
    </Toolbar>
  );
};

export default withStyles(styles, { withTheme: true })(PageSlugsContainer);
