import React, { useState } from 'react'
import { ChromePicker } from 'react-color'
import { createStyles, withStyles, WithStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    color: {
      width: '30px',
      height: '30px',
      borderRadius: '4px'
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '4px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer'
    },
    popover: {
      position: 'absolute',
      zIndex: '10',
      left: '3px'
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    }
  })

interface Props extends WithStyles<typeof styles> {
  color: string
  dataCy?: string
  fieldName: string
  onChange(color: string, fieldName: string): void
}

const ColorPicker: React.FunctionComponent<Props> = props => {
  const { color, classes, onChange, fieldName, dataCy } = props
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  return (
    <div
      style={{
        position: 'relative'
      }}
    >
      <div className={classes.swatch} data-cy={dataCy} onClick={handleClick}>
        <div style={{ background: `${color}` }} className={classes.color} />
      </div>
      {displayColorPicker ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handleClick} />
          <ChromePicker
            color={color ? color : '#ffffff'}
            onChangeComplete={pickerColor => {
              if (pickerColor.rgb.a === 0) {
                onChange('transparent', fieldName)
              } else {
                onChange(pickerColor.hex, fieldName)
              }
            }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(ColorPicker)
