import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_GET_COLOR_DESIGN_STEP } from '../../gql/admin-queries';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import { ADMIN_UPDATE_COLOR_DESIGN_STEP } from '../../gql/admin-mutations';
import TextField from '@material-ui/core/TextField';
var omitDeep = require('omit-deep');
class ColorStepSettings extends Component {
  state = {};
  render() {
    const classes = {};
    const { stepId } = this.props;
    return (
      <div className={classes.root}>
        <Query query={ADMIN_GET_COLOR_DESIGN_STEP} variables={{ id: stepId }}>
          {({ loading, error, data: { colorDesignStep } }) => {
            if (loading) return <div>Loading...</div>;
            if (error) return <div>Error :(</div>;
            return (
              <div>
                <Mutation
                  mutation={ADMIN_UPDATE_COLOR_DESIGN_STEP}
                  update={(cache, { data: { updateColorDesignStep } }) => {
                    if (updateColorDesignStep.id) {
                      // const data = store.readQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: colorId }
                      // });
                      // data.product = updateColor;
                      // store.writeQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: colorId },
                      //   data
                      // });
                    }
                  }}
                >
                  {(updateColorDesignStep, { data, loading, error }) => {
                    return (
                      <div>
                        <input
                          onChange={e => {
                            updateColorDesignStep({
                              variables: {
                                id: stepId,
                                input: {
                                  stepSortOrder: parseInt(e.target.value, 10)
                                }
                              }
                            });
                          }}
                          placeholder="stepSortOrder"
                          type="text"
                          value={colorDesignStep.stepSortOrder}
                          id="internalCstepSortOrderode"
                        />
                        <br />
                        <h3>Print library</h3>
                        {colorDesignStep.printLibrary
                          ? colorDesignStep.printLibrary.map((print, index) => {
                              return (
                                <div key={'style-' + index}>
                                  <TextField
                                    id={'outlined-print-' + index}
                                    label="Image URL"
                                    value={print.src}
                                    onChange={e =>
                                      updateColorDesignStep({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateColorDesignStep: {
                                            ...colorDesignStep,
                                            printLibrary: colorDesignStep.printLibrary
                                              ? colorDesignStep.printLibrary.map(
                                                  (print1, index1) => {
                                                    if (index === index1) {
                                                      return {
                                                        ...print1,
                                                        src: e.target.value
                                                      };
                                                    } else {
                                                      return print1;
                                                    }
                                                  }
                                                )
                                              : [
                                                  {
                                                    src: e.target.value,
                                                    name: e.target.value,
                                                    __typename: 'AlloverPrint'
                                                  }
                                                ]
                                          }
                                        },
                                        variables: {
                                          id: stepId,
                                          input: {
                                            printLibrary: colorDesignStep.printLibrary.map(
                                              (print2, index2) => {
                                                if (index === index2) {
                                                  return {
                                                    name: print2.name,
                                                    src: e.target.value
                                                  };
                                                } else {
                                                  return {
                                                    name: print2.name,
                                                    src: print2.src
                                                  };
                                                }
                                              }
                                            )
                                          }
                                        }
                                      })
                                    }
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused
                                      }
                                    }}
                                    InputProps={{
                                      classes: {
                                        root: classes.cssOutlinedInput,
                                        focused: classes.cssFocused
                                      }
                                    }}
                                  />
                                  <img
                                    alt="AOP"
                                    src={print.src}
                                    style={{
                                      width: '50px',
                                      height: '50px',
                                      display: 'inline',
                                      objectFit: 'contain'
                                    }}
                                  />
                                </div>
                              );
                            })
                          : null}
                        <h3>Add new print image</h3>
                        <TextField
                          id={'outlined-dummystyle-add'}
                          label="New Image URL"
                          value={this.state.newAOP}
                          onChange={e =>
                            this.setState({
                              newAOP: e.target.value
                            })
                          }
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused
                            }
                          }}
                          InputProps={{
                            classes: {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused
                            }
                          }}
                        />
                        <img
                          alt="AOP"
                          src={this.state.newAOP}
                          style={{
                            width: '50px',
                            height: '50px',
                            display: 'inline',
                            objectFit: 'contain'
                          }}
                        />
                        <br />
                        <button
                          onClick={async () => {
                            const newStyle = {
                              name: this.state.newAOP,
                              src: this.state.newAOP
                            };
                            const printsNoTypename =
                              colorDesignStep.printLibrary;
                            await updateColorDesignStep({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                updateColorDesignStep: {
                                  ...colorDesignStep,
                                  printLibrary: colorDesignStep.printLibrary
                                    ? [
                                        ...colorDesignStep.printLibrary,
                                        {
                                          ...newStyle,
                                          __typename: 'AlloverPrint'
                                        }
                                      ]
                                    : [
                                        {
                                          ...newStyle,
                                          __typename: 'AlloverPrint'
                                        }
                                      ]
                                }
                              },
                              variables: {
                                id: stepId,
                                input: {
                                  printLibrary: colorDesignStep.printLibrary
                                    ? [...printsNoTypename, newStyle]
                                    : [newStyle]
                                }
                              }
                            });
                            this.setState({
                              newAOP: ''
                            });
                          }}
                        >
                          Add
                        </button>
                      </div>
                    );
                  }}
                </Mutation>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default ColorStepSettings;
