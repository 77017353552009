import React, { Component } from 'react';

export default class FillIcon extends React.Component {
  render() {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1113 19.2871L10.1113 19.2871C11.3202 16.6059 13.7072 14.1999 16.6336 12.2956C19.552 10.3964 22.9289 9.04775 26.0048 8.43837C29.1297 7.81926 31.7369 8.0018 33.2686 8.89988C33.9979 9.32751 34.4787 9.91148 34.7011 10.684C34.9238 11.4578 34.9133 12.5159 34.4748 13.9296C33.079 16.1119 32.2299 17.8309 31.8625 19.1914C31.4879 20.5789 31.5667 21.8012 32.3507 22.699C33.0775 23.5314 34.2043 23.8253 35.2425 23.9506C36.303 24.0785 37.5408 24.0561 38.7318 24.0346L38.7674 24.034C40.0025 24.0117 41.2108 23.9906 42.3146 24.0981C43.4254 24.2063 44.3186 24.4364 44.9614 24.842C45.5582 25.2185 45.9936 25.7781 46.1615 26.7219C46.3364 27.7053 46.2241 29.1407 45.5793 31.2165C40.9328 40.7187 35.5405 44.313 30.6415 45.313C25.6948 46.3227 21.0031 44.7467 17.6099 43.3395C13.6741 40.9204 10.6384 37.5181 9.15875 33.4582C7.6769 29.3923 7.72373 24.5824 10.1113 19.2871Z"
          fill="white"
          stroke="black"
          stroke-width="2"
        />
        <circle cx="18.5117" cy="21.5871" r="3.5" fill="black" />
        <circle cx="32.5117" cy="36.5871" r="3.5" fill="black" />
        <circle cx="19.0117" cy="32.0871" r="5" fill="black" />
        <circle cx="25.0117" cy="15.0871" r="2" fill="black" />
      </svg>
    );
  }
}
