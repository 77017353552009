export function textGenerateToCanvas(
  content,
  _textColor,
  _fontStyle,
  _outlineColor,
  _outlineSize,
  _lineSpacing,
  order
) {
  try {
    // statements

    logger.log('textGenerateToCanvas', _fontStyle, content, _lineSpacing, order)

    content = ' ' + content + ' '
    var fontSize = 250

    var textColor = _textColor ? _textColor : '#FFFFFF'
    var fontStyle = _fontStyle ? _fontStyle : 'Montserrat'

    var outlineColor = _outlineColor ? _outlineColor : textColor
    var outlineSize = _outlineSize ? _outlineSize * 5 : 0

    // var canvas_text = document.createElement('canvas');

    if (order) {
      var canvas_text = document.querySelector('#textCanvas_' + order)
    } else {
      var canvas_text = document.querySelector('#textCanvas')
    }

    canvas_text.style.background = '#FFFFFF'
    canvas_text.style.letterSpacing = _lineSpacing * 5 + 'px'

    logger.log(canvas_text, canvas_text.style.letterSpacing)

    var context_temp = canvas_text.getContext('2d')
    context_temp.clearRect(0, 0, canvas_text.width, canvas_text.height)
    context_temp.font = 'Bold ' + fontSize + 'px ' + fontStyle

    if (outlineSize !== 0) {
      context_temp.strokeStyle = '#FFFFFF'
      context_temp.lineWidth = outlineSize
      context_temp.strokeText(content, 0, fontSize)
    }

    // context_temp.fillStyle = "rgba(0,0,0,0.95)";
    context_temp.fillStyle = '#FFFFFF'
    context_temp.fillText(content, 0, fontSize)
    var metrics = context_temp.measureText(content)
    logger.log('buildText', metrics)

    canvas_text.width = metrics.width
    canvas_text.height = canvas_text.height * 2
    var context_text = canvas_text.getContext('2d')
    context_text.clearRect(0, 0, canvas_text.width, canvas_text.height)

    // FOR getImageData

    context_text.font = 'Bold ' + fontSize + 'px ' + fontStyle

    if (outlineSize !== 0) {
      context_text.strokeStyle = '#00F'
      context_text.lineWidth = outlineSize
      context_text.strokeText(content, 0, fontSize)
    }

    // context_text.fillStyle = "rgba(0,0,0,0.95)";
    context_text.fillStyle = '#00F'
    context_text.fillText(content, 0, fontSize)

    var imageData = context_text.getImageData(
      0,
      0,
      canvas_text.width,
      canvas_text.height
    )

    var colorCheck = {
      r: 0,
      g: 0,
      b: 255
    }

    var collectDot = []
    var collectXY = []

    for (var i = 0; i < imageData.data.length; i += 4) {
      var imgColor = {
        r: imageData.data[i],
        g: imageData.data[i + 1],
        b: imageData.data[i + 2],
        i: i
      }

      var x = (i / 4) % canvas_text.width
      var y = Math.floor(i / 4 / canvas_text.width)

      // logger.log(imgColor);

      // is this pixel the old rgb?
      if (
        imgColor.r === colorCheck.r &&
        imgColor.g === colorCheck.g &&
        imgColor.b === colorCheck.b
      ) {
        collectXY.push({ x: x, y: y })
        collectDot.push(imgColor)
      }
    }

    var maxX = collectXY[0].x
    var minX = collectXY[0].x
    var maxY = collectXY[0].y
    var minY = collectXY[0].y
    for (i = 0; i < collectXY.length; i++) {
      if (collectXY[i].x > maxX) {
        maxX = collectXY[i].x
      }
      if (collectXY[i].x < minX) {
        minX = collectXY[i].x
      }

      if (collectXY[i].y > maxY) {
        maxY = collectXY[i].y
      }
      if (collectXY[i].y < minY) {
        minY = collectXY[i].y
      }
    }

    // logger.log("MIN X : ", minX);
    // logger.log("MAX X : ", maxX);
    // logger.log("GAP X : ", maxX - minX);

    // logger.log("MIN Y : ", minY);
    // logger.log("MAX Y : ", maxY);
    // logger.log("GAP Y : ", maxY - minY);

    canvas_text.width = maxX - minX
    canvas_text.height = maxY - minY
    context_text.clearRect(0, 0, canvas_text.width, canvas_text.height)
    context_text.font = 'Bold ' + fontSize + 'px ' + fontStyle

    if (outlineSize !== 0) {
      context_text.strokeStyle = outlineColor
      context_text.lineWidth = outlineSize
      context_text.strokeText(content, 0 - minX, fontSize - minY)
    }

    context_text.fillStyle = textColor
    context_text.fillText(content, 0 - minX, fontSize - minY)

    // context_text.strokeStyle = "red";
    // context_text.rect(0, 0, canvas_text.width, canvas_text.height);
    // context_text.stroke();

    // FOR getImageData

    var pngUrl_USE = canvas_text.toDataURL() // PNG is the default

    // logger.log('pngUrl_USE', pngUrl_USE);

    return pngUrl_USE
  } catch (e) {
    // statements
    logger.error('text_function.textGenerateToCanvas.error', e)
  }
}
