import React, { FC } from 'react'
import { Grid, Button, Card } from '@material-ui/core'

const CartActions: FC = () => {
  return (
    <Card style={{ padding: 20 }}>
      <Grid container={true} direction="row" justify="space-between">
        <Grid item={true}>
          <Button variant="outlined">Continue Shopping</Button>
        </Grid>
        <Grid item={true}>
          <Button color="primary" variant="contained">
            Checkout Now >
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CartActions
