import React from "react";
import {
  WithStyles,
  createStyles,
  Theme,
  Divider,
  Typography,
  Grid,
  InputBase,
  withStyles
} from "@material-ui/core";
import { Slider } from "@material-ui/core";

import { CONTENT_UPDATE_COLUMN } from "../../gql/admin-mutations";
import client from "../../gql/admin-apollo-config";
import StylingSaveButton from "./StylingSaveButton";
import DeleteImage from "./DeleteImage";
import Uploader from "./Uploader";
import ColorPicker from "./ColorPicker";
import { addPercent, addPx } from "./utils";
import Input from "../../../common/Input";
import VerticalAlign from "./VerticalAlign";
import NativeSelect from "@material-ui/core/NativeSelect";
import Paper from "@material-ui/core/Paper";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

import {
  FONTS,
  COPYRIGHT_IMAGE_VERTICAL_LOCATION,
  COPYRIGHT_IMAGE_HORIZONTAL_LOCATION,
  BACKGROUND_IMAGE_VERTICAL_LOCATION,
  BACKGROUND_REPEAT,
  BACKGROUND_SIZE
} from "./config";

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: "4px 8px",
    fontSize: 13,
    "&:focus": {
      borderRadius: 4,
      background: "#fff"
    }
  }
}))(InputBase);

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      margin:theme.spacing(3),
      width: "100%"
    },
    root: {
      display: "flex",
      padding: "16px 24px"
    },
    slider: {
      padding: "22px 0px"
    }
  });

interface Props extends WithStyles<typeof styles> {
  pageId: string;
  selectedCol: any;
  onChangeUnsavedData(data: any): void;
}

interface State {
  fields: {
    width: number;
    height: number;
    padding: number;
    backgroundColor: string;
    maxWidth: number;
    verticalAlign: string;
    transparentBg: boolean;
  };
}

class StylingColumn extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      fields: this.getDataFromProps()
    };
  }

  handleChange = name => event => {
    this.setState({
      fields: { ...this.state.fields, [name]: event.target.checked }
    });
  };

  componentWillUnmount() {
    this.handleSave();
  }

  getDataFromProps = () => {
    let splitPosition = this.props.selectedCol.backgroundPosition
      ? this.props.selectedCol.backgroundPosition.split(" ")
      : null;

    return {
      backgroundColor: this.props.selectedCol.backgroundColor,
      height: parseInt(this.props.selectedCol.height, 10) || 0,
      maxWidth: parseInt(this.props.selectedCol.maxWidth, 10) || 0,
      padding: this.props.selectedCol.padding || 0,
      width: parseInt(this.props.selectedCol.width, 10) || 0,
      verticalAlign: this.props.selectedCol.verticalAlign || "center",
      transparentBg:
        this.props.selectedCol.backgroundColor &&
        this.props.selectedCol.backgroundColor === "transparent"
          ? true
          : false
    };
  };

  componentDidUpdate(prevProps: any, _0: any, _1: any): void {
    if (prevProps.selectedCol.id === this.props.selectedCol.id) {
      return;
    }
    const checker = this.props.selectedCol.width || "";
    if (checker !== this.state.fields.width) {
      this.setState({
        fields: this.getDataFromProps()
      });
    }
  }

  handleChangeField = (value: any, field: string): void => {
    if (field === "backgroundColor") {
      this.setTransparent();
    }

    const fields = this.state.fields;
    fields[field] = value;
    this.setState({ fields });
    this.props.onChangeUnsavedData(fields);
  };

  setTransparent() {
    this.setState({
      fields: {
        transparentBg: false
      }
    });
  }

  handleSave = async (): Promise<any> => {
    return client.mutate({
      mutation: CONTENT_UPDATE_COLUMN,
      variables: {
        id: this.props.selectedCol.id,
        input: {
          backgroundColor:
            this.state.fields.transparentBg !== true
              ? this.state.fields.backgroundColor
              : "transparent",
          height: addPx(this.state.fields.height),
          maxWidth: addPx(this.state.fields.maxWidth),
          padding: this.state.fields.padding,
          width: addPercent(this.state.fields.width),
          verticalAlign:
            this.state.fields.verticalAlign &&
            this.state.fields.verticalAlign.indexOf("-")
              ? this.state.fields.verticalAlign.replace("-", "_")
              : "center"
        },
        pageId: this.props.pageId
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Divider />
        <Grid container={true} justify="space-between">
          <VerticalAlign
            fieldName={"verticalAlign"}
            handleChangeField={this.handleChangeField}
          />
        </Grid>
        <Grid
          className={this.props.classes.root}
          direction="column"
          container={true}
        >
          <Typography
            variant="subtitle1"
            className={this.props.classes.typo}
            id="slider-label"
            style={{ marginBottom: 10 }}
          >
            Width - {this.state.fields.width} %
          </Typography>
          <Grid container={true} justify="space-between">
            <Slider
              className={styles.slider}
              value={parseInt(this.state.fields.width, 10)}
              aria-labelledby="slider-label"
              step={1}
              min={0}
              max={100}
              onChange={(e, value) => this.handleChangeField(value, "width")}
            />
          </Grid>
        </Grid>
        <Grid
          className={this.props.classes.root}
          direction="column"
          container={true}
        >
          <Typography
            variant="subtitle1"
            className={this.props.classes.typo}
            id="slider-label2"
            style={{ marginBottom: 10 }}
          >
            Max width - {this.state.fields.maxWidth} px
          </Typography>
          <Grid container={true} justify="space-between">
            <Slider
              className={styles.slider}
              value={parseInt(this.state.fields.maxWidth, 10)}
              aria-labelledby="slider-label2"
              step={5}
              min={0}
              max={1600}
              onChange={(e, value) => this.handleChangeField(value, "maxWidth")}
            />
          </Grid>
        </Grid>
        <Grid
          className={this.props.classes.root}
          direction="column"
          container={true}
        >
          <Typography
            variant="subtitle1"
            className={this.props.classes.typo}
            id="slider-label3"
            style={{ marginBottom: 10 }}
          >
            Height - {this.state.fields.height} px
          </Typography>
          <Grid container={true} justify="space-between">
            <Slider
              className={styles.slider}
              value={parseInt(this.state.fields.height, 10)}
              aria-labelledby="slider-label3"
              step={5}
              min={0}
              max={1000}
              onChange={(e, value) => this.handleChangeField(value, "height")}
            />
          </Grid>
        </Grid>
        <Grid
          className={this.props.classes.root}
          direction="column"
          container={true}
        >
          <Typography variant="subtitle1" className={this.props.classes.typo}>
            Column padding
          </Typography>
          <Grid container={true} justify="space-between">
            <Input
              classes={{
                input: this.props.classes.cssinput
              }}
              placeholder="Insert value here..."
              type="text"
              value={this.state.fields.padding}
              onChange={e => this.handleChangeField(e.target.value, "padding")}
            />
          </Grid>
        </Grid>
        <Grid
          className={this.props.classes.root}
          direction="column"
          container={true}
        >
          <Grid container={true} justify="flex-start" alignItems="center">
            <ColorPicker
              color={
                this.state.fields.transparentBg !== true
                  ? this.state.fields.backgroundColor
                  : "#fff"
              }
              fieldName={"backgroundColor"}
              onChange={this.handleChangeField}
            />
            <Typography
              variant="subtitle1"
              className={this.props.classes.typo}
              style={{ marginLeft: 16 }}
            >
              Background Color
            </Typography>
          </Grid>
        </Grid>

        <Grid
          className={this.props.classes.root}
          direction="column"
          container={true}
        >
          <Grid container={true} justify="flex-start" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.fields.transparentBg}
                  onChange={this.handleChange("transparentBg")}
                  value={this.state.fields.transparentBg}
                />
              }
              label="Transparent background"
            />
          </Grid>
        </Grid>

        <StylingSaveButton onSave={this.handleSave} />
      </>
    );
  }
}

export default withStyles(styles)(StylingColumn);
