import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography, Button } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Slider from '@material-ui/core/Slider';
import ColorPicker from './ColorPicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Query } from 'react-apollo';
import { GET_PAGES, ADMIN_SHOP_COLLECTIONS } from '../../gql/admin-queries';
import SelectPage from './SelectPage';
import SelectCollection from './SelectCollection';
import { getTextFromLanguage } from '../../../../helper/languageHelper';

import { FONTS, BORDER_TYPES, LINK_TYPE } from './config';

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase);

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  input: {
    paddingLeft: 8,
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  },
  slider: {
    padding: '22px 0px'
  }
});

class UpdateButton extends React.Component {
  state = {
    displayColorPicker: false,
    pageDialog: false,
    selectedPage: this.props.fields.url,
    selectedPageId: this.props.fields.selectedPageId
      ? this.props.fields.selectedPageId
      : '',
    selectedCollection: this.props.fields.buttonUrl,
    selectedCollectionId: this.props.fields.selectedCollectionId
      ? this.props.fields.selectedCollectionId
      : '',
    color: {
      hex: '#ffffff'
    }
  };

  handleChangePage = (event, checked, id) => {
    const { fields } = this.props;
    this.setState({
      selectedCollection: null,
      selectedPage: event.target.value,
      selectedPageId: id,
      selectedCollectionId: null
    });
    fields.selectedPageId = id;
  };

  handleChangeCollection = (event, checked, id) => {
    const { fields } = this.props;
    this.setState({
      selectedPage: null,
      selectedCollection: event.target.value,
      selectedCollectionId: id
    });
    fields.collectionId = id;
  };

  handleChangeLink = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handlePageOpen = () => {
    this.setState({ pageDialog: true });
  };

  handlePageClose = () => {
    this.setState({ pageDialog: false });
  };

  handleChange = event => {
    this.setState({ selectedPage: event.target.value });
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,
      handleChangeTextField,
      fields,
      Template,
      shopId,
      languages
    } = this.props;

    return (
      <Grid container>
        <ExpansionPanel
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
          expanded={expanded === 'panel2'}
          onChange={handleExpandedChange('panel2')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ expanded: classes.expansionPanelExpanded }}
          >
            <Typography
              variant="subtitle1"
              className={classes.typo}
              data-cy="Button-Settings"
            >
              Button
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: classes.expansionPanelDetails }}
          >
            <Divider />
            <List>
              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Button-Height-Label"
                  >
                    Height - {fields.padding1}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Button-Height-Slider"
                      className={styles.slider}
                      value={parseInt(fields.padding1)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'padding1')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Button-Width-Label"
                  >
                    Width - {fields.padding2}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Button-Width-Slider"
                      className={styles.slider}
                      value={parseInt(fields.padding2)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'padding2')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Button-text-Label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Button text
                  </InputLabel>
                  {languages.map(language => {
                    let text = '';
                    try {
                      text =
                        getTextFromLanguage(fields.text.text, language) || '';
                    } catch (error) {}
                    return (
                      <div key={language}>
                        {language}
                        <Paper className={classes.root} elevation={0}>
                          <InputBase
                            data-cy={'Button-text-' + language}
                            className={classes.input}
                            placeholder=""
                            value={text}
                            onChange={e =>
                              handleChangeTextField(
                                e.target.value ? e.target.value : null,
                                language,
                                'text'
                              )
                            }
                          />
                        </Paper>
                      </div>
                    );
                  })}
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Button-border-thickness-Label"
                  >
                    Border thickness - {fields.borderThickness}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Button-border-thickness-Slider"
                      className={styles.slider}
                      value={parseInt(fields.borderThickness)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={5}
                      onChange={(e, value) =>
                        handleChangeField(value, 'borderThickness')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Button-border-type-Label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Border type
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      data-cy="Button-border-type-Select"
                      native="true"
                      className={classes.input}
                      value={fields.borderType}
                      onChange={e =>
                        handleChangeField(e.target.value, 'borderType')
                      }
                      input={
                        <SelectInput
                          name="border"
                          id="age-customized-native-simple"
                        />
                      }
                    >
                      {BORDER_TYPES.map(border => (
                        <option key={border} value={border}>
                          {border}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Button-border-color-Label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Border color
                  </InputLabel>
                  <ColorPicker
                    dataCy="Button-border-ColorPicker"
                    color={fields.borderColor}
                    fieldName={'borderColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Button-Border-radius-Label"
                  >
                    Border radius - {fields.borderRadius}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Button-Border-radius-Slider"
                      className={styles.slider}
                      value={parseInt(fields.borderRadius)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'borderRadius')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Button-Text-Color-Label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Text Color
                  </InputLabel>
                  <ColorPicker
                    dataCy="Button-text-ColorPicker"
                    color={fields.color}
                    fieldName={'color'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Button-Background-color-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Background Color
                  </InputLabel>
                  <ColorPicker
                    dataCy="Button-Background-ColorPicker"
                    color={fields.backgroundColor}
                    fieldName={'backgroundColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Button-Font-Label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Font
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      data-cy="Button-Font-Select"
                      native="true"
                      className={classes.input}
                      value={
                        fields.font && fields.font.indexOf('Roboto') !== -1
                          ? 'Roboto'
                          : fields.font
                      }
                      onChange={e => handleChangeField(e.target.value, 'font')}
                      input={
                        <SelectInput
                          name="font"
                          id="age-customized-native-simple"
                        />
                      }
                    >
                      {FONTS.map(font => (
                        <option key={font} value={font}>
                          {font}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Button-font-size-Label"
                  >
                    Font size - {fields.fontSize}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Button-font-size-Slider"
                      className={styles.slider}
                      value={parseInt(fields.fontSize)}
                      aria-labelledby="slider-label2"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'fontSize')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Button-Link-type-Label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Link type
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      data-cy="Button-Link-type-Select"
                      native="true"
                      className={classes.input}
                      value={fields.buttonLinkType}
                      onChange={e =>
                        handleChangeField(e.target.value, 'buttonLinkType')
                      }
                      input={
                        <SelectInput
                          name="border"
                          id="age-customized-native-simple"
                        />
                      }
                    >
                      {LINK_TYPE.map(link => (
                        <option key={link} value={link}>
                          {link}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              {fields.buttonLinkType === 'EXTERNAL' ? (
                <ListItem>
                  <Grid container direction="column">
                    <InputLabel
                      data-cy="Button-External-URL-Label"
                      shrink
                      className={classes.FormLabel}
                    >
                      URL
                    </InputLabel>
                    <Paper className={classes.root} elevation={0}>
                      <InputBase
                        data-cy="Button-External-URL-Input"
                        className={classes.input}
                        placeholder=""
                        value={fields.url}
                        onChange={e =>
                          handleChangeField(
                            e.target.value ? e.target.value : null,
                            'url'
                          )
                        }
                      />
                    </Paper>
                  </Grid>
                </ListItem>
              ) : null}

              {fields.buttonLinkType === 'INTERNAL' ? (
                <ListItem>
                  <Grid container direction="column">
                    <InputLabel
                      data-cy="Button-Link-Label"
                      shrink
                      className={classes.FormLabel}
                    >
                      Link
                    </InputLabel>
                    <Paper
                      data-cy="Button-Add-Link-Input"
                      className={classes.root}
                      elevation={0}
                    >
                      <InputBase
                        className={classes.input}
                        placeholder=""
                        disabled={true}
                        value={
                          this.state.selectedPage
                            ? (fields.url = this.state.selectedPage)
                            : this.state.selectedCollection
                            ? (fields.url = this.state.selectedCollection)
                            : null
                        }
                        onChange={e =>
                          handleChangeField(
                            e.target.value ? e.target.value : null,
                            'url'
                          )
                        }
                      />
                    </Paper>
                    <Button
                      data-cy="Button-Add-Link-btn"
                      onClick={this.handlePageOpen}
                    >
                      Add Link
                    </Button>
                  </Grid>
                </ListItem>
              ) : null}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Dialog
          open={this.state.pageDialog}
          onClose={this.handlePageClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle data-cy="Button-Link-Select-Page" id="form-dialog-title">
            Select Page
          </DialogTitle>
          <DialogContent style={{ zIndex: 100 }}>
            Content pages
            <Query variables={{ id: shopId }} query={GET_PAGES}>
              {({ loading, error, data }) => {
                if (loading) return 'Loading...';
                if (error) return `Error! ${error.message}`;

                return (
                  <Grid container={true}>
                    {data.shop.pages.edges.map(p => {
                      const page = p.node;

                      return (
                        <SelectPage
                          page={page}
                          handleChange={this.handleChangePage}
                          Template={Template}
                          selectedPage={this.state.selectedPage}
                          selectedPageId={this.state.selectedPageId}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      );
                    })}
                  </Grid>
                );
              }}
            </Query>
            Collections
            <Query variables={{ id: shopId }} query={ADMIN_SHOP_COLLECTIONS}>
              {({ loading, error, data }) => {
                if (loading) return 'Loading...';
                if (error) return `Error! ${error.message}`;

                return (
                  <Grid container={true}>
                    {data.shop.collections.edges.map(p => {
                      const collection = p.node;
                      return (
                        <SelectCollection
                          collection={collection}
                          handleChange={this.handleChangeCollection}
                          Template={Template}
                          selectedCollection={this.state.selectedCollection}
                          selectedCollectionId={this.state.selectedCollectionId}
                          selected={this.state.selected}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      );
                    })}
                  </Grid>
                );
              }}
            </Query>
          </DialogContent>
          <DialogActions>
            <Button
              data-cy="Button-Select-Page-Cancel-btn"
              onClick={this.handlePageClose}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              data-cy="Button-Select-Page-Save-btn"
              onClick={this.handlePageClose}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

UpdateButton.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
};

export default withStyles(styles)(UpdateButton);
