import React, { useContext } from 'react'
import { ShopContext, SelectedLanguageContext } from '../../LanguageContext'
import MainBanner from '../MainBanner'
import { useQuery } from '@apollo/react-hooks'
import { ADMIN_SHOP_COLLECTIONS } from '../../gql/queries'
import { Typography, Grid } from '@material-ui/core'
import { getTextFromLanguage } from '../../../../../../../helper/languageHelper'
import CollectionProducts from './CollectionProducts'
import { CollectionModuleType } from '../Types'

interface Props {
  moduleType: CollectionModuleType
}

const CollectionPageContainer: React.FC<Props> = props => {
  const { moduleType } = props
  const shopId = useContext(ShopContext)
  const selectedLanguage = useContext(SelectedLanguageContext)

  const { data, loading } = useQuery(ADMIN_SHOP_COLLECTIONS, {
    variables: {
      id: shopId
    }
  })

  if (loading) return null

  const collection = data?.shop?.collections?.edges[0]?.node || null

  switch (moduleType) {
    case 'COLLECTION_BANNER':
      return (
        <MainBanner title={collection?.title} image={collection?.mainBanner} />
      )
    case 'COLLECTION_DESCRIPTION':
      return (
        <>
          {collection?.description?.text ? (
            <Typography variant="h5">
              {collection?.description?.defaultValue}
            </Typography>
          ) : (
            <Grid
              justify="center"
              style={{ padding: 25, textAlign: 'center', fontStyle: 'italic' }}
            >
              <span>Add a collection description to display</span>
            </Grid>
          )}
        </>
      )
    case 'COLLECTION_CARDS':
      return <CollectionProducts products={collection?.products || null} />
    default:
      return <h2>{moduleType}</h2>
  }
}

export default CollectionPageContainer
