import React, { Component } from 'react'
import {
  Button,
  Grid,
  Paper,
  Typography,
  InputLabel,
  makeStyles
} from '@material-ui/core'

import MomentUtils from '@date-io/moment'
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider
} from 'material-ui-pickers'
import moment from 'moment'
const useStyles = makeStyles({
  root: {
    padding: '2px 8px 2px 0px !important',
    display: 'flex',
    alignItems: 'center',
    minWidth: 100,
    background: '#fff',
    marginTop: '4px !important',
    borderRadius: 4,
    fontWeight: 300
  },
  input: {
    marginLeft: 8,
    width: '100%',
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 300,
    color: '#000'
  }
})
const ProductSellFrom: React.FunctionComponent<Props> = props => {
  const { product, shopId, updateProduct } = props
  const classes = useStyles()
  return (
    <Grid container style={{ marginTop: 24 }}>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <Typography data-cy="Product-Sales-Channels-header" variant="subtitle1">
          Product start sales from
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
        <Paper elevation={0} style={{ padding: 24 }}>
          <Grid container style={{ marginTop: 8 }}>
            <Grid item style={{}}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                {product.sellFrom ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      updateProduct({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateProduct: {
                            ...product,
                            sellFrom: null
                          }
                        },
                        variables: {
                          input: {
                            sellFrom: null
                          }
                        }
                      })
                    }}
                  >
                    Delete date
                  </Button>
                ) : (
                  <Typography variant="subtitle1">
                    No date set - this product is being sold
                  </Typography>
                )}

                <InputLabel className={classes.label}>Date</InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <DatePicker
                    value={product.sellFrom || ''}
                    onChange={e => {
                      updateProduct({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateProduct: {
                            ...product,
                            sellFrom: moment(e).valueOf()
                          }
                        },
                        variables: {
                          input: {
                            sellFrom: moment(e).valueOf()
                          }
                        }
                      })
                    }}
                  />
                </Paper>
                <InputLabel className={classes.label}>Time</InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <TimePicker
                    value={product.sellFrom || ''}
                    onChange={e => {
                      // console.log(JSON.stringify(e));
                      updateProduct({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateProduct: {
                            ...product,
                            sellFrom: moment(e).valueOf()
                          }
                        },
                        variables: {
                          input: {
                            sellFrom: moment(e).valueOf()
                          }
                        }
                      })
                    }}
                  />
                </Paper>
                <Typography variant="body1">
                  GMT {moment().format('Z')} (your timezone)
                </Typography>
                <br />
                <br />
                <Typography variant="body1">
                  {product.sellFrom
                    ? 'Sales starts from: ' +
                      new Date(product.sellFrom).toGMTString()
                    : null}
                </Typography>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ProductSellFrom
