import React, { Component } from 'react'
import { Query, Mutation, Subscription } from 'react-apollo'
import {
  withStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  InputLabel,
  Paper,
  Typography
} from '@material-ui/core'
import uuid from 'uuid'
import {
  ADMIN_SHOP_ORDER_ITEMS,
  ADMIN_SHOP_SALESCHANNELS
} from '../../gql/admin-queries'
import { ADMIN_CREATE_EXPORT_REPORT_REQUEST } from '../../gql/admin-mutations'
import { ADMIN_REPORT_REQUEST_SUBSCRIPTION } from '../../gql/admin-subscriptions'
import moment from 'moment'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import { MEDIA_CDN_URL } from '../../../../../brikl-config'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import { REPORT_FIELDS } from '../content/config'
import FieldsSelection from './FieldsSelection'
import TimeFrameSelection from './TimeFrameSelection'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    padding: 24,
    margin: '0 auto'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    marginLeft: 20,
    fontSize: 30,
    padding: 25,
    fontWeight: 'medium',
    color: '#424242'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
})

class ReportOrders extends Component {
  state = {
    reportRequestStatus: null,
    reportRequestId: null,
    selectedFields: []
  }
  timeFrame = {
    from: null,
    to: null
  }

  handleFieldSelection(selectedFields: string[]) {
    this.setState({ selectedFields })
  }

  handleTimeFrameSelection(from: number, to: number): void {
    if (from && to) {
      this.timeFrame = { from, to }
    }
  }

  handleExportBtn(createExportReportRequest, fileType: 'CSV' | 'XLS') {
    const requestId = uuid.v4()
    this.setState({
      reportRequestId: requestId,
      reportRequestStatus: 'LOADING'
    })
    try {
      createExportReportRequest({
        variables: {
          input: {
            fields: this.state.selectedFields,
            fileType,
            id: requestId,
            reportType: 'REPORT_ORDERITEM',
            filter: {
              salesChannelIds: this.state.salesChannelIds,
              fromTimestamp: this.timeFrame.from,
              untilTimestamp: this.timeFrame.to
            }
          }
        }
      })
    } catch (error) {
      this.setState({
        reportRequestStatus: null
      })
    }
  }

  render() {
    const { classes, orderId, shopId, defaultLanguage } = this.props
    return (
      <Grid className={classes.root}>
        <Grid
          container={true}
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ marginBottom: '20px' }}
        >
          {!orderId && (
            <Typography
              style={{
                fontSize: 28,
                fontWeight: 'medium',
                color: '#424242',
                marginBottom: '20px'
              }}
            >
              Report Order Items
            </Typography>
          )}

          <Grid
            container={true}
            justify="flex-start"
            alignItems="center"
            style={{ marginBottom: '20px' }}
          >
            <Mutation
              update={(store, { data: { createExportReportRequest } }) => {
                console.log(
                  'createExportReportRequest',
                  createExportReportRequest
                )
                if (createExportReportRequest.id) {
                  // start subscribing here
                  // this.setState({
                  //   reportRequestId: createExportReportRequest.id
                  // });
                }
              }}
              mutation={ADMIN_CREATE_EXPORT_REPORT_REQUEST}
            >
              {createExportReportRequest => (
                <Paper style={{ width: '100%', padding: 16 }}>
                  <Grid
                    container={true}
                    spacing={2}
                    alignItems={'flex-end'}
                    justify={'space-between'}
                  >
                    <Grid item={true} xs={12}>
                      <InputLabel>Saleschannel</InputLabel>
                      <Query
                        query={ADMIN_SHOP_SALESCHANNELS}
                        variables={{ id: shopId }}
                      >
                        {({ loading, error, data }) => {
                          let shopSalesChannels = []
                          try {
                            data.shop.salesChannels.edges.forEach(s => {
                              shopSalesChannels.push(
                                <option value={s.node.id}>
                                  {getTextFromLanguage(
                                    s.node.title.text,
                                    defaultLanguage
                                  )}
                                </option>
                              )
                            })
                          } catch (error) {}
                          return (
                            <select
                              onChange={e => {
                                this.setState({
                                  salesChannelIds: e.target.value
                                    ? [e.target.value]
                                    : null
                                })
                              }}
                              value={
                                this.state.salesChannelIds
                                  ? this.state.salesChannelIds[0]
                                  : null
                              }
                            >
                              <option value={null}>All</option>
                              <option value="MYBRIKL">MYBRIKL</option>
                              {shopSalesChannels}
                            </select>
                          )
                        }}
                      </Query>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <FieldsSelection
                        availableFields={REPORT_FIELDS.REPORT_ORDERITEM}
                        selectedFields={this.state.selectedFields}
                        onSelect={sel => this.handleFieldSelection(sel)}
                      />
                      <Grid item={true} xs={12}>
                        <TimeFrameSelection
                          onSelect={(from, to) =>
                            this.handleTimeFrameSelection(from, to)
                          }
                        />
                      </Grid>
                      <Grid
                        container={true}
                        spacing={1}
                        style={{ marginTop: 16 }}
                      >
                        <Grid item={true}>
                          <Button
                            color="primary"
                            variant="outlined"
                            style={{ float: 'right' }}
                            onClick={() =>
                              this.handleExportBtn(
                                createExportReportRequest,
                                'XLS'
                              )
                            }
                            disabled={
                              this.state.reportRequestStatus === 'LOADING'
                            }
                          >
                            {this.state.reportRequestStatus === 'LOADING'
                              ? 'Loading'
                              : 'Export to XLS'}
                          </Button>
                        </Grid>
                        <Grid item={true}>
                          <Button
                            color="primary"
                            variant="outlined"
                            style={{ float: 'right' }}
                            onClick={() =>
                              this.handleExportBtn(
                                createExportReportRequest,
                                'CSV'
                              )
                            }
                            disabled={
                              this.state.reportRequestStatus === 'LOADING'
                            }
                          >
                            {this.state.reportRequestStatus === 'LOADING'
                              ? 'Loading'
                              : 'Export to CSV'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </Mutation>
          </Grid>
          {this.state.reportRequestId ? (
            <Subscription
              onSubscriptionData={({ subscriptionData }) => {
                try {
                  if (
                    subscriptionData.data.events[0] &&
                    subscriptionData.data.events[0].status === 'SUCCESS'
                  ) {
                    const link = document.createElement('a')
                    link.href = subscriptionData.data.events[0].event.filePath
                    const fileType = link.href.split('.').pop()
                    link.download = 'brikl-order-export.' + fileType
                    console.log('link', link)
                    link.click()
                    this.setState({
                      reportRequestStatus: null
                    })
                  }
                } catch (e) {
                  console.error(e)
                  alert('Your export has failed: Unknown error')
                  this.setState({
                    reportRequestStatus: null
                  })
                }
              }}
              subscription={ADMIN_REPORT_REQUEST_SUBSCRIPTION}
              variables={{
                id: this.state.reportRequestId,
                shopId: shopId
              }}
            />
          ) : null}
        </Grid>
        {!orderId && (
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Grid container={true} direction="column">
                <Paper>
                  <Query
                    query={ADMIN_SHOP_ORDER_ITEMS}
                    variables={{ id: shopId, first: 50 }}
                  >
                    {({ loading, error, data }) => {
                      let shopOrders = []
                      try {
                        shopOrders = _sortBy(
                          data.shop.orderItems.edges,
                          'createdAt'
                        )
                      } catch (error) {}
                      return (
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Order ID</TableCell>
                              <TableCell>Saleschannel</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Product</TableCell>
                              <TableCell>Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loading ? (
                              <TableRow key={'order-loading'}>
                                <TableCell component="th" scope="row">
                                  {'Loading...'}
                                </TableCell>
                                <TableCell>{}</TableCell>
                                <TableCell>{}</TableCell>
                                <TableCell>{}</TableCell>
                                <TableCell>{}</TableCell>
                              </TableRow>
                            ) : error ? (
                              'Error'
                            ) : (
                              shopOrders.map(row => {
                                const order = row.node
                                return (
                                  <TableRow
                                    key={order.id}
                                    // hover
                                    // component="a"
                                  >
                                    <TableCell
                                      style={{
                                        maxWidth: 100,
                                        // cursor: 'pointer',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        padding: '4px 16px'
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      {order.orderId}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        maxWidth: 100,
                                        // cursor: 'pointer',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        padding: '4px 16px'
                                      }}
                                    >
                                      {order.salesChannelTitle}
                                    </TableCell>
                                    <TableCell>
                                      {_get(order, 'total.currency', '') +
                                        ' ' +
                                        _get(
                                          order,
                                          'total.value',
                                          0
                                        ).toLocaleString()}
                                    </TableCell>
                                    <TableCell>{order.productTitle}</TableCell>
                                    <TableCell>
                                      {moment
                                        .unix(order.createdAt / 1000)
                                        .format('DD MMM YYYY HH:mm:ss')}
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                            )}
                          </TableBody>
                        </Table>
                      )
                    }}
                  </Query>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(ReportOrders)
