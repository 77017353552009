import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core/Button';
import _get from 'lodash/get';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import _remove from 'lodash/remove';
import { Mutation } from 'react-apollo';
import { ADMIN_UPDATE_SHOP_CONTENT_SETTINGS } from '../../gql/admin-mutations';
import SimpleSnackbar from './SimpleSnackbar';

const styles = theme => ({});

class PageSetHomepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: false
    };
  }
  render() {
    const { classes, pageId, indexPageId, shopId } = this.props;

    return (
      <Mutation
        mutation={ADMIN_UPDATE_SHOP_CONTENT_SETTINGS}
        variables={{ id: shopId }}
        onCompleted={() => {
          this.setState({ notification: true });
        }}
      >
        {updateShopContentSettings => (
          <div>
            <IconButton
              onClick={() =>
                updateShopContentSettings({
                  variables: {
                    input: {
                      contentSettings: {
                        indexPageId: pageId
                      }
                    }
                  }
                })
              }
            >
              <HomeIcon
                style={{
                  color: pageId === indexPageId ? '#B23566' : '#CECCCE'
                }}
              />
            </IconButton>

            <SimpleSnackbar
              open={this.state.notification}
              message="Homepage set succesfully!"
              verticalAlign="bottom"
              horizontalAlign="right"
            />
          </div>
        )}
      </Mutation>
    );
  }
}

PageSetHomepage.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(PageSetHomepage);
