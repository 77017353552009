import React, { Component } from 'react'
import { Typography, Grid, Paper } from '@material-ui/core'
import GlobalStyles from '../../../globalStyles'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ThemeSelection from './ThemeSelection'
import ThemeHeading from './Theme/ThemeHeading'
import ThemeButton from './Theme/ThemeButton'
import ThemeInputField from './Theme/ThemeInputField'
import { Query, Mutation } from 'react-apollo'
import { ADMIN_GET_CONTENT_THEME } from '../../gql/admin-queries'
import { ADMIN_UPDATE_SHOP_CONTENT_SETTINGS } from '../../gql/admin-mutations'

const styles = theme => ({
  container: {
    padding: '8px 0px'
  }
})

class Theme extends Component {
  render() {
    const { classes, product, Template, collection, shopId } = this.props
    return (
      <Query query={ADMIN_GET_CONTENT_THEME} variables={{ id: shopId }}>
        {({ loading, error, data: { shop }, refetch }) => {
          if (loading) return 'Loading...'
          if (error) return `Error! ${error.message}`

          let themeId
          if (shop) {
            themeId = shop.contentSettings.themeId
          }

          return (
            <Mutation
              update={(store, { data: { updateShopContentSettings } }) => {}}
              mutation={ADMIN_UPDATE_SHOP_CONTENT_SETTINGS}
            >
              {(updateShopContentSettings, { data, loading, error }) => {
                return (
                  <Grid
                    container
                    style={{ maxWidth: '1000px', margin: 'auto', padding: 24 }}
                  >
                    <Helmet>
                      <link
                        href="https://fonts.googleapis.com/css?family=Istok+Web:400,700|Kanit:300,400,700|Lato|Montserrat:300,400,700|News+Cycle:400,700|Nunito:300,400,700|Open+Sans:300,400,700|Roboto:300,400,700|Jaldi:400,700|Work+Sans:300,400,700&display=swap&subset=thai"
                        rel="stylesheet"
                      />
                    </Helmet>
                    <GlobalStyles />
                    <Grid item xs={12} style={{ marginTop: 24 }}>
                      <Typography data-cy="Header-Theme" variant="h1">
                        Theme setting
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 24 }}>
                      <Typography variant="body1">
                        Set the feel and look of your texts, buttons, gallery
                        and many more.
                      </Typography>
                      <ThemeSelection themeId={themeId} />
                    </Grid>

                    {/* Heading Settings start */}
                    <ThemeHeading
                      shop={shop}
                      themeId={themeId}
                      variant="h1"
                      title="Heading1"
                    />
                    <ThemeHeading
                      shop={shop}
                      themeId={themeId}
                      variant="h2"
                      title="Heading2"
                    />
                    <ThemeHeading
                      shop={shop}
                      themeId={themeId}
                      variant="h3"
                      title="Heading3"
                    />
                    <ThemeHeading
                      shop={shop}
                      themeId={themeId}
                      variant="h4"
                      title="Heading4"
                    />
                    <ThemeHeading
                      shop={shop}
                      themeId={themeId}
                      variant="h5"
                      title="Heading5"
                    />
                    <ThemeHeading
                      shop={shop}
                      themeId={themeId}
                      variant="body1"
                      title="Paragraph"
                    />
                    {/* Heading Settings end */}

                    <ThemeButton shop={shop} themeId={themeId} />

                    <ThemeInputField shop={shop} themeId={themeId} />

                    {/* <Grid
          container
          style={{ marginTop: 24 }}
          alignItems="center"
          justify="center"
        >
          <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
            <MuiThemeProvider theme={storetheme}>
              <Grid
                container
                style={{ padding: '24px 24px 24px 0px' }}
                spacing={1}
                alignItems="center"
              >
                <ProductCard Template={Template} product={product} />
              </Grid>
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <MuiThemeProvider theme={adminTheme}>
              <Paper style={{ padding: 24, borderRadius: 15 }} elevation={0}>
                <Grid container className={classes.container} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Title</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Font size" placeholder="19px" />
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Font family" placeholder="Roboto" />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Text transform"
                      placeholder="Uppercase, Lowercase, Capitalized"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Font weight"
                      placeholder="light, normal, semi-bold, bold"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Description</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Font size" placeholder="19px" />
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Font family" placeholder="Roboto" />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Text transform"
                      placeholder="Uppercase, Lowercase, Capitalized"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Font weight"
                      placeholder="light, normal, semi-bold, bold"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Button</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Boder color" placeholder="transparent" />
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Boder thickness" placeholder="1px" />
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Background color" placeholder="#000" />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Type"
                      placeholder="Normal, outlined or text"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Card</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Boder color" placeholder="transparent" />
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Boder thickness" placeholder="1px" />
                  </Grid>
                  <Grid item xs={6}>
                    <Input label="Background color" placeholder="#000" />
                  </Grid>
                </Grid>
              </Paper>
            </MuiThemeProvider>
          </Grid>
        </Grid> */}
                  </Grid>
                )
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

Theme.propTypes = {
  classes: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default withStyles(styles)(Theme)
