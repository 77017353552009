import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Query } from 'react-apollo';
import { ADMIN_SHOP_TEAMSTORES } from '../../gql/admin-queries';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import AddTeamstore from './AddTeamstore';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%',
    height: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242'
  }
});

class TeamStore extends Component {
  render() {
    const { classes, shopId, shop } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Grid item style={{ marginRight: 16 }}>
            <Typography
              component="h1"
              className={classes.h1}
              data-cy="Header-Team-Store"
            >
              Team Store
            </Typography>
          </Grid>
          <div style={{ flex: 1 }} />
          <AddTeamstore shopId={shopId} />
        </Grid>

        <div>
          <Paper style={{ overflowX: 'auto', width: '100%' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>Title</TableCell>
                  <TableCell className={classes.cell}>Image</TableCell>
                  <TableCell className={classes.cell}>Url</TableCell>
                  <TableCell className={classes.cell}>Visibility</TableCell>
                  <TableCell className={classes.cell}>Type</TableCell>
                  <TableCell className={classes.cell}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Query query={ADMIN_SHOP_TEAMSTORES} variables={{ id: shopId }}>
                  {({ loading, error, data: { shop } }) => {
                    if (loading)
                      return <CircularProgress className={classes.progress} />;
                    if (error) return <div>Error :(</div>;
                    var teamstores = [];
                    try {
                      teamstores = shop.salesChannels.edges;
                    } catch (error) {}
                    if (teamstores && teamstores.length > 0) {
                      return teamstores.map(teamstore => {
                        return (
                          <TableRow
                            component="a"
                            href={
                              '/admin/' +
                              shopId +
                              '/saleschannels/teamstores/' +
                              teamstore.node.id +
                              '/general'
                            }
                            hover
                            style={{ textDecoration: 'none' }}
                          >
                            <TableCell
                              className={classes.cell}
                              component="th"
                              scope="row"
                            >
                              {teamstore.node.name}
                            </TableCell>
                            <TableCell
                              className={classes.cell}
                              component="th"
                              scope="row"
                            >
                              {teamstore.node.logo &&
                              teamstore.node.logo.indexOf('undefined') ===
                                -1 ? (
                                <img
                                  style={{
                                    maxWidth: '40px',
                                    maxHeight: '40px',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                  }}
                                  src={teamstore.node.logo}
                                  alt={teamstore.node.name}
                                />
                              ) : null}
                            </TableCell>
                            <TableCell
                              className={classes.cell}
                              component="th"
                              scope="row"
                            >
                              {teamstore.node.slugs
                                ? getTextFromLanguage(
                                    teamstore.node.slugs,
                                    shop.defaultLanguage
                                  )
                                : null}
                            </TableCell>
                            <TableCell
                              className={classes.cell}
                              component="th"
                              scope="row"
                            >
                              {teamstore.node.privacyType}
                            </TableCell>
                            <TableCell
                              className={classes.cell}
                              component="th"
                              scope="row"
                            >
                              {teamstore.node.type}
                            </TableCell>
                            <TableCell
                              className={classes.cell}
                              component="th"
                              scope="row"
                            >
                              {teamstore.node.status}
                            </TableCell>
                          </TableRow>
                        );
                      });
                    } else {
                      return null;
                    }
                  }}
                </Query>
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TeamStore);
