import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { OrganisationContext } from '../../../../user/context/OrganisationContext';
import VariantList from './VariantList';
import VariantDetail from './VariantDetail';

class Variants extends Component {
  render() {
    const { shop, shopId } = this.props;
    const defaultLanguage = shop.defaultLanguage;
    return this.props.variantId ? (
      <Grid container>
        <OrganisationContext.Consumer>
          {currentOrganisation => (
            <VariantDetail
              defaultLanguage={defaultLanguage}
              languages={shop.languages}
              currentOrganisation={currentOrganisation}
              shopId={shopId}
              variantId={this.props.variantId}
            />
          )}
        </OrganisationContext.Consumer>
      </Grid>
    ) : (
      <Grid container style={{ width: '100%' }}>
        <VariantList defaultLanguage={defaultLanguage} shopId={shopId} />
      </Grid>
    );
  }
}

export default Variants;
