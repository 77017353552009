import React, { useState, useEffect } from 'react'
import _sortBy from 'lodash/sortBy'
import _flatten from 'lodash/flatten'
import RenderField from './RenderField'
import { Grid, Button } from '@material-ui/core'
import NewFieldButton from './NewFieldButton'
import FieldActions from './FieldActions'
import ElementsDialog from '../Elements/ElementsDialog'
import UpdateFieldPanel from './UpdateFieldPanel'
import { useMutation } from '@apollo/react-hooks'
import {
  ADMIN_UPDATE_FIELD_ORDER,
  ADMIN_DELETE_CONTENT_FIELD,
  ADMIN_UPDATE_CONTENT_FIELD
} from '../gql/mutations'
import { GET_CONTENT_MODEL, GET_CONTENT_FIELD } from '../gql/queries'
import _remove from 'lodash/remove'

interface Props {
  data: any
  view: Object
  editing: boolean
  modelId: string
  salesChannelId?: string
  productDesignId?: string
  ownedByOrganisationId?: string
  urlPrefix: string
}

const EditorBody: React.FC<Props> = props => {
  const {
    data,
    editing,
    modelId,
    view,
    ownedByOrganisationId,
    productDesignId,
    salesChannelId,
    urlPrefix
  } = props
  const [hoverField, setHoverField] = useState<string | null>(null)
  const [newFieldId, setNewFieldId] = useState<string | null>(null)
  const [updatePanel, setUpdatePanel] = useState<any>({
    id: null,
    show: false
  })

  let defaultPage = data?.contentModel?.defaultPage

  const [updateContentFieldSortOrder] = useMutation(ADMIN_UPDATE_FIELD_ORDER, {
    refetchQueries: [{ query: GET_CONTENT_MODEL, variables: { id: modelId } }]
  })

  const [deleteContentField] = useMutation(ADMIN_DELETE_CONTENT_FIELD, {
    refetchQueries: [{ query: GET_CONTENT_MODEL, variables: { id: modelId } }],
    update: (cache, { data: { deleteContentField } }) => {
      const data: any = cache.readQuery({
        query: GET_CONTENT_MODEL,
        variables: { id: modelId }
      })

      const newFields = data.contentModel?.latestRevision?.fields.filter(
        (o: any) => o.id !== updatePanel.id
      )

      setUpdatePanel({ id: null, show: false })

      cache.writeQuery({
        query: GET_CONTENT_MODEL,
        variables: { id: modelId },
        data: {
          contentModel: {
            ...data?.contentModel,
            latestRevision: {
              ...data?.contentModel?.latestRevision,
              fields: newFields
            }
          }
        }
      })
    }
  })

  const [updateContentField] = useMutation(ADMIN_UPDATE_CONTENT_FIELD)

  let sortedFields: any = {}

  if (data) {
    sortedFields = _sortBy(data.contentModel.latestRevision.fields, 'sortOrder')
  }

  const handleSetNewFieldId = (id: string | null) => {
    setNewFieldId(id)
  }

  const handleEditingHover = (id: string | null) => {
    if (editing) {
      setHoverField(id)
    }
  }

  const handleUpdateField = (
    fieldId: string | null,
    fieldName: string,
    value: string | number | null | []
  ) => {
    updateContentField({
      variables: {
        revisionNo: 1,
        id: fieldId,
        modelId: modelId,
        input: {
          [fieldName]: value
        }
      }
    })
  }

  const handleShowUpdatePanel = (id: string | null, show: boolean) => {
    setUpdatePanel({
      id: id,
      show: show
    })
  }

  useEffect(() => {
    if (!editing) {
      handleShowUpdatePanel(null, false)
    }
  }, [editing])

  if (!data) return null

  return (
    <>
      {sortedFields.length > 0 ? (
        sortedFields.map((field: any, index: any) => {
          return (
            <Grid
              key={field.id}
              container={true}
              justify="center"
              onMouseOver={() => handleEditingHover(field.id)}
              onMouseLeave={() => handleEditingHover(null)}
              style={{
                margin: '0px 0px 0px 0px',
                backgroundColor: field.backgroundColor
                  ? field.backgroundColor
                  : 'transparent',
                position: 'relative',
                borderTop:
                  editing && hoverField === field.id ? `1px solid black` : null,
                borderBottom:
                  editing && hoverField === field.id ? `1px solid black` : null,
                borderRadius: 2
              }}
            >
              {field.contentView.length > 0 ? (
                <RenderField
                  isHover={editing && hoverField === field.id}
                  urlPrefix={urlPrefix}
                  defaultPageType={data?.contentModel?.defaultPage}
                  editing={editing}
                  key={index}
                  field={field}
                  view={view}
                  ownedByOrganisationId={ownedByOrganisationId}
                  salesChannelId={salesChannelId}
                  productDesignId={productDesignId}
                />
              ) : (
                <Grid justify="center" container={true} style={{ padding: 40 }}>
                  <Button
                    onClick={() => handleSetNewFieldId(field.id)}
                    variant="outlined"
                    color="primary"
                  >
                    Add Content
                  </Button>
                </Grid>
              )}

              {newFieldId ? (
                <ElementsDialog
                  handleSetNewFieldId={handleSetNewFieldId}
                  modelId={modelId}
                  newFieldId={newFieldId}
                />
              ) : null}
              {editing && field.id === updatePanel.id && updatePanel.show ? (
                <UpdateFieldPanel
                  id={field.id}
                  field={field}
                  handleShowUpdatePanel={handleShowUpdatePanel}
                  handleUpdateField={handleUpdateField}
                />
              ) : null}
              {field.id === hoverField ? (
                <>
                  <NewFieldButton
                    position="bottom"
                    sortOrder={field.sortOrder}
                    modelId={field.modelId}
                    editing={editing}
                    handleSetNewFieldId={handleSetNewFieldId}
                  />
                  <div>
                    <FieldActions
                      defaultPage={defaultPage}
                      handleShowUpdatePanel={handleShowUpdatePanel}
                      fieldId={field.id}
                      modelId={field.modelId}
                      fieldLength={sortedFields.length}
                      index={index}
                      firstElement={index === 0 ? true : false}
                      fieldIds={sortedFields.map((item: any) => item.id)}
                      deleteContentField={deleteContentField}
                      updateContentField={updateContentField}
                      updateContentFieldSortOrder={updateContentFieldSortOrder}
                      updatePanel={setUpdatePanel}
                    />
                  </div>
                </>
              ) : null}
            </Grid>
          )
        })
      ) : (
        <Grid
          container={true}
          style={{
            margin: '0px 0px 15px 0px',
            height: '100%',
            backgroundColor: 'transparent',
            position: 'relative'
          }}
        >
          <NewFieldButton
            position="bottom"
            handleSetNewFieldId={handleSetNewFieldId}
            editing={editing}
            sortOrder={0}
            modelId={modelId}
          />
        </Grid>
      )}
    </>
  )
}

export default EditorBody
