import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import {
  CONTENT_DELETE_COLUMN_ROW,
  CONTENT_UPDATE_COLUMN_ROW
} from '../../gql/admin-mutations';
import { GET_PAGE_CONTENT } from '../../gql/admin-queries';
import _remove from 'lodash/remove';
import _ from 'lodash';
import client from '../../gql/admin-apollo-config';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core';

const styles = theme => ({});

class DeleteColumnRow extends Component {
  render() {
    const { pageId, Template } = this.props;

    return (
      <Mutation
        mutation={CONTENT_DELETE_COLUMN_ROW}
        variables={{ pageId: pageId, id: Template.state.selectedElement }}
        update={(cache, { data: { deleteContentColumnRow } }) => {
          const { page } = cache.readQuery({
            query: GET_PAGE_CONTENT,
            variables: { id: pageId }
          });

          const colRows = page.rows.map(rowData => {
            rowData.columns = rowData.columns.map(colData => {
              _remove(colData.columnRows, function(n) {
                return n.id === Template.state.selectedElement;
              });
              return colData;
            });
            return rowData;
          });

          cache.writeQuery({
            query: GET_PAGE_CONTENT,
            variables: { id: pageId },
            data: {
              page: {
                ...page,
                colRows
              }
            }
          });

          // Update all sortOrders
          const sortOrder = Template.state.selectedColRow.sortOrder;
          page.rows.forEach(rowData => {
            rowData.columns.forEach(colData => {
              if (Template.state.selectedColRow.columnId === colData.id) {
                colData.columnRows = _.sortBy(colData.columnRows, 'sortOrder');
                colData.columnRows.forEach(colRow => {
                  if (sortOrder < colRow.sortOrder) {
                    client.mutate({
                      mutation: CONTENT_UPDATE_COLUMN_ROW,
                      variables: {
                        id: colRow.id,
                        pageId: pageId,
                        input: {
                          sortOrder: colRow.sortOrder - 1
                        }
                      }
                    });
                  }
                });
              }
            });
          });
        }}
        onCompleted={() => {
          Template.setState(
            {
              selectedCol: '',
              selectedRow: '',
              selectedRowObj: '',
              selectedElement: '',
              currentTab: 0,
              updateModule: true
            },
            this.setState({ open: false })
          );
        }}
      >
        {deleteContentColumnRow => (
          <Tooltip title="Delete">
            <IconButton
              data-cy="Delete-Column-Row"
              onClick={deleteContentColumnRow}
              aria-label="Delete"
              style={{ color: 'rgb(225, 0, 80)' }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DeleteColumnRow);
