import React, { useContext } from 'react'
import { TextField } from '@material-ui/core'
import { SelectedLanguageContext } from '../../LanguageContext'
import { getTextFromLanguage } from '../../../../../../../helper/languageHelper'
import uuid from 'uuid'

interface Props {
  styleId: string | null
  contentId: string | null
  level: 'root' | 'style'
  fieldName: string
  defaultValue: any
  handleTextUpdate: any
}

const MultilangTextField: React.FC<Props> = props => {
  const {
    styleId,
    contentId,
    level,
    fieldName,
    defaultValue,
    handleTextUpdate
  } = props

  const selectedLanguage = useContext(SelectedLanguageContext)
  return (
    <TextField
      placeholder=""
      defaultValue={
        defaultValue
          ? getTextFromLanguage(defaultValue.text, selectedLanguage)
          : ''
      }
      onBlur={e =>
        handleTextUpdate(
          contentId,
          fieldName,
          e.target.value,
          level,
          defaultValue
            ? defaultValue
            : {
                id: uuid.v4(),
                text: [{ langCode: selectedLanguage, content: e.target.value }]
              },
          styleId
        )
      }
    />
  )
}

export default MultilangTextField
