import { getQueryStringValue } from '../tools/tools'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles
} from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import { Slider } from '@material-ui/core'
import Select from '../../common/Select'
import Input from '../../common/Input'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  fullWidth: {
    width: '100%',
    justifyContent: 'left'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
})

interface Props {
  classes: any
  DesignRoot: any
}

const Exports: React.FunctionComponent<Props> = props => {
  const { classes, DesignRoot } = props

  const [openLink, setOpenLink] = React.useState(false)

  const handleOpenLink = () => {
    setOpenLink(true)
  }

  const handleCloseLink = () => {
    setOpenLink(false)
  }

  return (
    <Grid
      container
      style={{
        padding: '8px 24px 24px',
        overflowY: 'auto',
        height: 'calc(100vh - 90px)',
        width: '100%'
      }}
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item className={classes.fullWidth}>
        <hr />
      </Grid>

      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.fabric_function.clippingSvg()
          }}
          className={classes.fullWidth}
        >
          Export Pattern SVG
        </Button>
      </Grid>
      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.template_control.triggerExport2DScene('PNG')
          }}
          className={classes.fullWidth}
        >
          Export Pattern PNG
        </Button>
      </Grid>
      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.template_control.triggerExport2DScene('JPG')
          }}
          className={classes.fullWidth}
        >
          Export Pattern JPG
        </Button>
      </Grid>

      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.template_control.triggerExport3DScene()
          }}
          className={classes.fullWidth}
        >
          Export 3D as an image
        </Button>
      </Grid>
      {/* <Grid item className={classes.fullWidth}>
          <Button
            onClick={template_control.toggleRedLine.bind(this)}
            className={classes.fullWidth}
          >
            Show Seam allowance
          </Button>
        </Grid> */}
      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.template_control.toggleLogoOverlay()
          }}
          className={classes.fullWidth}
        >
          Toggle logo layer
        </Button>
      </Grid>

      {/* <Grid item className={classes.fullWidth}>
          <Button className={classes.fullWidth}>Export Techpack</Button>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Button className={classes.fullWidth}>Share preview</Button>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Button className={classes.fullWidth}>Export different Sizes</Button>
        </Grid> */}

      {/* <Grid item className={classes.fullWidth}>
          <Button
            className={classes.fullWidth}
            onClick={template_control.save.bind(this)}
          >
            Save
          </Button>
        </Grid>

        <Grid item className={classes.fullWidth}>
          <hr />
        </Grid>

        <Grid item className={classes.fullWidth}>
          <Button
            className={classes.fullWidth}
            onClick={template_control.view3D.bind(this, 'RESET')}
          >
            Reset view
          </Button>
        </Grid> */}

      <Grid item className={classes.fullWidth}>
        <hr />
      </Grid>

      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.fabric_function.render_quality_option(1)
          }}
          className={classes.fullWidth}
        >
          3D Quality (Normal)
        </Button>
      </Grid>

      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.fabric_function.render_quality_option(2)
          }}
          className={classes.fullWidth}
        >
          3D Quality (2x) (Quality)
        </Button>
      </Grid>

      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.fabric_function.render_quality_option(3)
          }}
          className={classes.fullWidth}
        >
          3D Quality (3x) (Quality)
        </Button>
      </Grid>

      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.fabric_function.render_quality_option(4)
          }}
          className={classes.fullWidth}
        >
          3D Quality (4x) (Quality)
        </Button>
      </Grid>

      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.fabric_function.render_quality_option(5)
          }}
          className={classes.fullWidth}
        >
          3D Quality (5x) (Quality)
        </Button>
      </Grid>

      <Grid item className={classes.fullWidth}>
        <hr />
      </Grid>

      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.fabric_function.render_quality_option(0.5)
          }}
          className={classes.fullWidth}
        >
          3D Quality (0.5x) (Performance)
        </Button>
      </Grid>

      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.fabric_function.render_quality_option(0.2)
          }}
          className={classes.fullWidth}
        >
          3D Quality (0.2x) (Performance)
        </Button>
      </Grid>

      <Grid item className={classes.fullWidth}>
        <hr />
      </Grid>

      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.template_control.readSVGDOM()
          }}
          className={classes.fullWidth}
        >
          Read SVG Dom
        </Button>
      </Grid>

      <Grid item className={classes.fullWidth}>
        <hr />
      </Grid>

      <Grid item className={classes.fullWidth}>
        <Button
          onClick={() => {
            DesignRoot.template_control.gltfToS3()
            handleOpenLink()
            //alert(`${DesignRoot.shop.url}/embeded?pd=${DesignRoot.productDesignId}`)
          }}
          className={classes.fullWidth}
        >
          Get Sharing Link
        </Button>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openLink}
          onClose={handleCloseLink}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={openLink}>
            <div className={classes.paper}>
              <p id="transition-modal-description">
                <TextField
                  id="shared"
                  label="Sharing Link"
                  value={`${DesignRoot.shop.url}/embeded?pd=${DesignRoot.productDesignId}`}
                />
              </p>
              <p id="transition-modal-description">
                <TextField
                  multiline
                  rows="4"
                  id="embeded"
                  label="Embeded"
                  value={`<iframe src="${DesignRoot.shop.url}/embeded?pd=${DesignRoot.productDesignId}" frameborder="0" width="100%" height="100%"></iframe>`}
                />
              </p>
            </div>
          </Fade>
        </Modal>
      </Grid>
    </Grid>
  )
}

Exports.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Exports)
