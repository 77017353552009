import React from 'react'
import { API_STAGE, SHOP_ID } from '../../../brikl-config'
import LazyLoad from 'react-lazyload'
class BoardProductPreview extends React.Component {
  state = {
    countError: 0
  }
  addDefaultSrc = e => {
    if (this.state.countError > 1) {
      e.target.onError = null
      e.target.src = ``
    } else {
      this.setState({
        countError: this.state.countError + 1
      })
      e.target.onError = null
      e.target.src = `https://s3-ap-southeast-1.amazonaws.com/${API_STAGE}-brikl-shop-product-design-previews/${
        this.props.boardProductId
      }/${this.props.file ? this.props.file : '0'}.jpg`
    }
  }
  render() {
    const {
      boardProductId,
      productDesignId,
      style,
      file,
      techpack,
      shopId
    } = this.props
    const stage =
      process.env.GATSBY_API_STAGE === 'local'
        ? 'dev'
        : process.env.GATSBY_API_STAGE
    const prefix = productDesignId
      ? `https://s3-ap-southeast-1.amazonaws.com/${stage}-brikl-ecommerce/${SHOP_ID ||
          shopId}/userdata/design/${productDesignId}`
      : `https://s3-ap-southeast-1.amazonaws.com/${stage}-brikl-ecommerce/${SHOP_ID ||
          shopId}/userdata/design/${boardProductId}`

    return (
      // <LazyLoad height={180} once>
      <div
        style={
          style
            ? style
            : {
                height: techpack ? '100%' : '180x',
                width: '100%'
              }
        }
      >
        <img
          onError={this.addDefaultSrc}
          src={`${prefix}/${file ? file : '0'}.jpg`}
          alt="Preview"
          style={{
            border: '0px solid white',
            width: '100%',
            objectFit: techpack ? 'contain' : 'cover'
          }}
        />
      </div>
      // </LazyLoad>
    )
  }
}
export default BoardProductPreview
