import React, { Component } from 'react';

export default class TouchZoomIcon extends React.Component {
  render() {
    const {
      width = 80,
      height = 80,
      background,
      padding,
      fill5 = '#c4c4c4'
    } = this.props;
    return (
      <div style={{ width: width, height: height, padding: padding }}>
        <style
          dangerouslySetInnerHTML={{
            __html: `
                        .touchZoom-1,.touchZoom-2,.touchZoom-3{fill:none;}.touchZoom-1{stroke:#4d4d4d;}.touchZoom-1,.touchZoom-2,.touchZoom-3,.touchZoom-4{stroke-miterlimit:10;}.touchZoom-1,.touchZoom-3,.touchZoom-4{stroke-width:2px;}.touchZoom-2{stroke:#939393;stroke-width:3px;}.touchZoom-3,.touchZoom-4{stroke:#000;}.touchZoom-4{fill:#fff;}
        `
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 102.37 108.31"
          style={{ width: '100%', height: '100%' }}
        >
          <defs />
          <title>Asset 5</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <circle class="touchZoom-1" cx="34.98" cy="23.98" r="14.7" />
              <circle class="touchZoom-2" cx="34.98" cy="23.98" r="22.48" />
              <circle class="touchZoom-3" cx="35.41" cy="23.55" r="7.35" />
              <circle class="touchZoom-1" cx="23.98" cy="41.98" r="14.7" />
              <circle class="touchZoom-2" cx="23.98" cy="41.98" r="22.48" />
              <circle class="touchZoom-3" cx="24.41" cy="41.55" r="7.35" />
              <path
                class="touchZoom-4"
                d="M100,50.12l-.26-5.95a6.79,6.79,0,0,0-7.07-6.49l-.35,0a6.78,6.78,0,0,0-6.48,7.07l.26,5.95-.49,0-.35-8.16a6.79,6.79,0,0,0-7.08-6.48h-.34a6.79,6.79,0,0,0-6.49,7.08l.36,8.16-.48,0-.41-9.27a6.76,6.76,0,0,0-4-5.91v0L40,22.17a6.78,6.78,0,0,0-9.15,2.89l-.16.3a6.79,6.79,0,0,0,2.89,9.16l18.74,9.76h0s4.66,3,5,7.33l.08,1.82a7.84,7.84,0,0,1-4.1,5.51h0a7.83,7.83,0,0,1-9.35-1.65l-9.34-18a6.78,6.78,0,0,0-9.14-2.91l-.31.16a6.78,6.78,0,0,0-2.9,9.15L44.92,89.45a28.3,28.3,0,0,0,2,3.87l.36.7a7,7,0,0,0,1.38,1.82,29.35,29.35,0,0,0,52.62-17.5h0L100.1,50.11Z"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
