import React, { useState } from 'react'
import { NativeSelect } from '@material-ui/core'

interface Props {
  options: string[]
  defaultValue: string
  level: 'root' | 'style'
  styleId: string
  contentId: string
  fieldName: string
  handleUpdate: any
}

const SelectControl: React.FC<Props> = props => {
  const {
    options,
    defaultValue,
    level,
    styleId,
    contentId,
    fieldName,
    handleUpdate
  } = props
  const [selected, setSelected] = useState(defaultValue ? defaultValue : null)

  const handleChange = (e: any) => {
    setSelected(e.target.value)
    handleUpdate(contentId, fieldName, e.target.value, level, styleId)
  }

  return (
    <NativeSelect
      value={selected}
      onChange={handleChange}
      style={{ border: '1px solid lightgrey', borderRadius: 2 }}
    >
      {options.map((option: string, index: number) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </NativeSelect>
  )
}

export default SelectControl
