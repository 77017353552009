import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { navigate } from '@reach/router'
import { ADMIN_CREATE_TEAMSTORE } from '../../gql/admin-mutations'
import { Mutation } from 'react-apollo'
class AddTeamstore extends Component {
  state = {
    loading: false
  }
  render() {
    return (
      <Mutation
        update={(store, { data: { createTeamstore } }) => {
          if (createTeamstore.id) {
            this.setState({ loading: false })
            navigate(
              '/admin/' +
                this.props.shopId +
                '/saleschannels/teamstores/' +
                createTeamstore.id
            )
          }
        }}
        mutation={ADMIN_CREATE_TEAMSTORE}
      >
        {(createTeamstore, { data, loading, error }) => (
          <Button
            disabled={this.state.loading ? true : false}
            variant="outlined"
            color="primary"
            onClick={() => {
              this.setState({ loading: true })
              try {
                createTeamstore({
                  variables: {
                    input: {
                      hasOutOfStock: false
                    }
                  }
                })
              } catch (error) {
                this.setState({ loading: false })
              }
            }}
            style={{ fontWeight: 400 }}
          >
            {this.state.loading ? 'Creating...' : 'Add team store'}
          </Button>
        )}
      </Mutation>
    )
  }
}

export default AddTeamstore
