import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import _get from 'lodash/get'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import { NativeSelect, InputLabel } from '@material-ui/core'
import AdminSearchProduct from '../common/AdminSearchProduct'
const styles = theme => ({})

class SelectProducts extends React.Component {
  state = {
    isChecked: false
  }

  render() {
    const {
      classes,
      products,
      Template,
      handleChange,
      selectedProduct,
      langCode
    } = this.props

    var url = ''

    try {
      if (langCode === 'en_UK') {
        url = '/' + 'en/' + slug
      } else if (langCode === 'nl_NL') {
        url = '/' + 'nl/' + slug
      } else if (langCode === 'fr_FR') {
        url = '/' + 'fr/' + slug
      }
    } catch (error) {}

    return (
      <div style={{ width: '100%', padding: 10 }}>
        <AdminSearchProduct
          text={'Search for product'}
          classes={classes}
          onSelect={product => {
            console.warn('product', product)
            const productSlug = product.slug
            handleChange(
              {
                target: {
                  value: product.id
                }
              },
              productSlug
            )
          }}
        />
      </div>
    )
  }
}

SelectProducts.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(SelectProducts)
