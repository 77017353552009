import { API_STAGE } from '~/brikl-config'
import * as tools from '../tools/tools'
let SHOP_ID = tools.getQueryStringValue('s')

var noImage = require('../TEMPLATE_ASSET/no-image.png')

export function productDataQuery(self) {
  try {
    // statements

    if (!SHOP_ID) {
      SHOP_ID = self.SHOP_ID
    }

    var productConfig = require('../TEMPLATE_ASSET/' +
      self.productID +
      '/config.json')
    productConfig = productConfig[self.productID]

    logger.log('productConfig', productConfig)

    var OBJ = require('../TEMPLATE_ASSET/' +
      self.productID +
      '/' +
      productConfig.obj)
    var MTL = require('../TEMPLATE_ASSET/' +
      self.productID +
      '/' +
      productConfig.mtl)
    var MTL_PATTERN_PREFIX = productConfig.mtlPatternPrefix

    var redLineSrc = null
    if (productConfig.redLineSrc) {
      redLineSrc = require('../TEMPLATE_ASSET/' +
        self.productID +
        '/' +
        productConfig.redLineSrc)
    }

    var templateSvg = null
    if (productConfig.templateSvg) {
      templateSvg = require('../TEMPLATE_ASSET/' +
        self.productID +
        '/' +
        productConfig.templateSvg)
    }

    if (productConfig.dimension) {
      var dimension = productConfig.dimension
    } else {
      var dimension = {
        baseTemplate: 3000,
        baseSize: 'width',
        pxToCm: 0.5
      } // DEFAULT DIMENSION
    }

    self._PXTOCM = dimension.pxToCm

    // DEFAULT TEMPLATE XCEED

    if (productConfig.defaultTemplateSrc && SHOP_ID === 'xceed') {
      self._DEFAULT_TEMPLATE_SRC = require('../TEMPLATE_ASSET/' +
        self.productID +
        '/' +
        productConfig.defaultTemplateSrc)
    } else {
      self._DEFAULT_TEMPLATE_ACTIVE = false
    }

    // DEFAULT TEMPLATE XCEED

    // var TEMPLATE_LOGO = require('../TEMPLATE_ASSET/'+self.productID+"/"+self.productID+"-template-logo.png");

    // var TEMPLATE_BASE = [];
    // var TEMPLATE_REVIEW = [];
    // var TEMPLATE_COLORZONE = [];
    var TEMPLATE_SET_BY_ID = {}
    var TEMPLATE_SET_DATA = []

    for (var i = 0; i < productConfig.numberOfTemplate; i++) {
      var templateSet = productConfig.templateSet[i]

      var templateSrc = require('../TEMPLATE_ASSET/' +
        self.productID +
        '/' +
        templateSet.templateSrc)
      var templatePreview = require('../TEMPLATE_ASSET/' +
        self.productID +
        '/' +
        templateSet.templatePreview)
      var templateLogo = require('../TEMPLATE_ASSET/' +
        self.productID +
        '/' +
        templateSet.templateLogo)
      var templateColor = templateSet.colorData

      // TEMPLATE_BASE.push(templateSrc);
      // TEMPLATE_REVIEW.push(templatePreview);
      // TEMPLATE_COLORZONE.push(templateColor);

      TEMPLATE_SET_DATA.push({
        templateId: templateSet.templateId,
        templateSrc: templateSrc,
        templatePreview: templatePreview,
        templateLogo: templateLogo,
        colorData: templateColor
      })

      TEMPLATE_SET_BY_ID[templateSet.templateId] = TEMPLATE_SET_DATA[i]
    }

    if (productConfig.numberOfTemplate <= 0) {
      self._ACTIVE_COLOR_ZONE = false
    }

    if (self._SVG_ACTIVE === true) {
      // ADD BLANK TEMPLATE

      TEMPLATE_SET_DATA.push({
        templateId: 'BLANK',
        templatePreview: productConfig.blankTemplatePreviewSrc || noImage
      })

      TEMPLATE_SET_BY_ID['BLANK'] =
        TEMPLATE_SET_DATA[TEMPLATE_SET_DATA.length - 1]

      // ADD BLANK TEMPLATE
    }

    // DEFAULT ITEM CHECK

    if (productConfig.defaultItems && self._DEFAULT_ITEM === true) {
      self._DEFAULT_ITEM_SET = productConfig.defaultItems
    } else {
      self._DEFAULT_ITEM_SET = []
      self._DEFAULT_ITEM = false
    }

    if (productConfig.specialELM && self._SPECIAL_ELM === true) {
      self._SPECIAL_ELM_SET = productConfig.specialELM
    } else {
      self._SPECIAL_ELM_SET = []
      self._SPECIAL_ELM = false
    }

    // DEFAULT ITEM CHECK

    self.productDataSet = {
      ID: productConfig.id,
      productConfig: productConfig,
      OBJ: OBJ,
      MTL: MTL,
      MTL_PATTERN_PREFIX: MTL_PATTERN_PREFIX,
      NO_OF_TEMPLATE: productConfig.numberOfTemplate,
      redLineSrc: redLineSrc,
      templateSvg: templateSvg,
      dimension: dimension,
      _DEFAULT_ITEM_SET: self._DEFAULT_ITEM_SET,
      _SPECIAL_ELM_SET: self._SPECIAL_ELM_SET,
      // ACTIVE_TEMPLATE_INDEX: 0,
      // ACTIVE_TEMPLATE_ID: productConfig.templateSet[0].templateId, // INIT FIRST TEMPLATE // USE self BE DEFAULT
      // TEMPLATE_BASE: TEMPLATE_BASE, // MIGHT REMOVE
      // TEMPLATE_REVIEW: TEMPLATE_REVIEW, // MIGHT REMOVE
      // COLOR_DATA: TEMPLATE_COLORZONE, // MIGHT REMOVE
      TEMPLATE_SET: TEMPLATE_SET_DATA,
      TEMPLATE_SET_BY_ID: TEMPLATE_SET_BY_ID // USE self BE DEFAULT
    }

    logger.log(
      'BRFORE LOAD DEFAULT',
      self._ACTIVE_COLOR_ZONE,
      self.productDataSet
    )

    if (self.templateID === null || self.templateID === 'LOAD') {
      self._ACTIVE_TEMPLATE_ID = self.productDataSet.TEMPLATE_SET[0].templateId

      if (self._ACTIVE_COLOR_ZONE === true) {
        self._SELECT_COLOR_ZONE =
          self.productDataSet.TEMPLATE_SET[0].colorData[0].color
        self._CURRENT_TEMPLATE_SRC =
          self.productDataSet.TEMPLATE_SET[0].templateSrc
        self._CURRENT_LOGO_SRC =
          self.productDataSet.TEMPLATE_SET[0].templateLogo
      }

      self._CURRENT_SEAM_SRC = self.productDataSet.redLineSrc
      self._CURRENT_SVG_SRC = self.productDataSet.templateSvg
    } else {
      self._ACTIVE_TEMPLATE_ID = self.templateID

      if (self._ACTIVE_COLOR_ZONE === true) {
        self._SELECT_COLOR_ZONE =
          self.productDataSet.TEMPLATE_SET_BY_ID[
            self._ACTIVE_TEMPLATE_ID
          ].colorData[0].color
        self._CURRENT_TEMPLATE_SRC =
          self.productDataSet.TEMPLATE_SET_BY_ID[
            self._ACTIVE_TEMPLATE_ID
          ].templateSrc
        self._CURRENT_LOGO_SRC =
          self.productDataSet.TEMPLATE_SET_BY_ID[
            self._ACTIVE_TEMPLATE_ID
          ].templateLogo
      }

      self._CURRENT_SVG_SRC = self.productDataSet.templateSvg
    }

    if (SHOP_ID === 'yuepai') {
      self._STACK = []

      for (var i = 0; i < productConfig.stack.length; i++) {
        var stackSvg = require('../TEMPLATE_ASSET/' +
          self.productID +
          '/' +
          productConfig.stack[i].stackSvg)

        self._STACK.push({
          stackId: productConfig.stack[i].stackId,
          stackSvg: stackSvg,
          legendColor: productConfig.stack[i].legendColor,
          svgCalElm: productConfig.stack[i].svgCalElm,
          svgCalCm: productConfig.stack[i].svgCalCm
        })
      }
    }

    // HANDLE LOAD DATA

    if (self.onLoad === true) {
      var loadData = self.loadData
      logger.log('loadData', loadData)
      if (loadData.id === self.productDataSet.ID) {
        self._LOAD_DATA = loadData

        var id = loadData.id
        var templateId = loadData.templateId
        var mapColor = loadData.mapColor
        var allItem = loadData.allItem
        var logoActive = loadData.logoActive

        if (self._ACTIVE_TEMPLATE_ID !== templateId) {
          self._ACTIVE_TEMPLATE_ID = templateId
          self._SELECT_COLOR_ZONE =
            self.productDataSet.TEMPLATE_SET_BY_ID[
              templateId
            ].colorData[0].color
          self._CURRENT_TEMPLATE_SRC =
            self.productDataSet.TEMPLATE_SET_BY_ID[templateId].templateSrc
          self._CURRENT_LOGO_SRC =
            self.productDataSet.TEMPLATE_SET_BY_ID[templateId].templateLogo
          self._CURRENT_SVG_SRC = self.productDataSet.templateSvg
        }

        if (logoActive && logoActive === true) {
          self._LOGO_TOGGLE = logoActive
        }
      } else {
        self.onLoad = false
      }
    }

    // HANDLE LOAD DATA

    logger.log('PRODUCT CONFIG INIT', self.productDataSet)
  } catch (e) {
    // statements
    logger.error('DesignTemplate.productDataQuery.error', e)
  }
}
