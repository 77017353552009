import client from '../../../gql/admin-apollo-config';
import {
  CONTENT_UPDATE_ROW,
  CONTENT_UPDATE_COLUMN,
  CONTENT_UPDATE_COLUMN_ROW
} from '../../../gql/admin-mutations';
import { GET_PAGE_CONTENT } from '../../../gql/admin-queries';

export async function moveCard(params) {
  if (!params.pageId || !params.type) {
    return;
  }

  const { page } = await client.readQuery({
    query: GET_PAGE_CONTENT,
    variables: { id: params.pageId }
  });
  console.log('*****page', JSON.parse(JSON.stringify(page.rows)));

  if (params.type === 'row') {
    await _updateRow(params, page);
  } else if (params.type === 'column') {
    await _updateColumn(params, page);
  } else if (params.type === 'columnRow') {
    await _updateColumnRow(params, page);
  }
}

async function _updateRow(params, page) {
  await client.mutate({
    mutation: CONTENT_UPDATE_ROW,
    variables: {
      id: params.drag.id,
      pageId: params.pageId,
      input: {
        sortOrder: params.hover.sortOrder
      }
    }
  });

  await client.mutate({
    mutation: CONTENT_UPDATE_ROW,
    variables: {
      id: params.hover.id,
      pageId: params.pageId,
      input: {
        sortOrder: params.drag.sortOrder
      }
    }
  });
}

async function _updateColumn(params, page) {
  await client.mutate({
    mutation: CONTENT_UPDATE_COLUMN,
    variables: {
      id: params.drag.id,
      pageId: params.pageId,
      input: {
        sortOrder: params.hover.sortOrder
      }
    }
  });

  await client.mutate({
    mutation: CONTENT_UPDATE_COLUMN,
    variables: {
      id: params.hover.id,
      pageId: params.pageId,
      input: {
        sortOrder: params.drag.sortOrder
      }
    }
  });
}

async function _updateColumnRow(params, page) {
  await client.mutate({
    mutation: CONTENT_UPDATE_COLUMN_ROW,
    variables: {
      id: params.drag.id,
      pageId: params.pageId,
      input: {
        sortOrder: params.hover.sortOrder
      }
    }
  });

  await client.mutate({
    mutation: CONTENT_UPDATE_COLUMN_ROW,
    variables: {
      id: params.hover.id,
      pageId: params.pageId,
      input: {
        sortOrder: params.drag.sortOrder
      }
    }
  });
}
