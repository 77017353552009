import React from 'react'
import {
  Grid,
  Typography,
  Paper,
  InputLabel,
  TextField,
  Select,
  MenuItem
} from '@material-ui/core'
import { FONTS, TEXT_TRANSFORM, FONT_WEIGHT } from '../../../content/config'

interface Props {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body1'
  themeData: any
  displayText: string
  themeId: string
  handleUpdate: any
}

const ThemeHeading: React.FC<Props> = props => {
  const { variant, themeId, themeData, displayText, handleUpdate } = props
  return (
    <Grid key={themeId} item xs={12} style={{ marginTop: 24 }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Grid
            style={{ padding: 20 }}
            container
            alignItems="center"
            justify="center"
          >
            <Typography
              style={{
                fontSize:
                  themeData[variant] && themeData[variant].style
                    ? themeData[variant].style.fontSize
                    : null,
                fontFamily:
                  themeData[variant] && themeData[variant].style
                    ? themeData[variant].style.fontFamily
                    : null,
                textTransform:
                  themeData[variant] && themeData[variant].style
                    ? themeData[variant].style.textTransform
                    : null,
                fontWeight:
                  themeData[variant] && themeData[variant].style
                    ? themeData[variant].style.fontWeight
                    : null,
                letterSpacing:
                  themeData[variant] && themeData[variant].style
                    ? themeData[variant].style.letterSpacing
                    : null
              }}
              variant={variant}
            >
              {displayText}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Paper style={{ padding: 24, borderRadius: 15 }} elevation={0}>
            <Grid
              style={{
                border: '1px solid lightgrey',
                borderRadius: 7,
                padding: '40px 40px'
              }}
              container
              spacing={2}
            >
              <Grid item xs={6}>
                <InputLabel htmlFor="fontFamily-native-helper">
                  Font Size
                </InputLabel>
                <TextField
                  placeholder="45px"
                  defaultValue={
                    themeData[variant] && themeData[variant].style
                      ? themeData[variant].style.fontSize
                      : null
                  }
                  onBlur={(e: any) =>
                    handleUpdate(
                      'fontSize',
                      e.target.value,
                      variant,
                      'heading',
                      'style'
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="fontFamily-native-helper">
                  Letter Spacing
                </InputLabel>
                <TextField
                  placeholder="normal"
                  defaultValue={
                    themeData[variant] && themeData[variant].style
                      ? themeData[variant].style.letterSpacing
                      : null
                  }
                  onBlur={(e: any) =>
                    handleUpdate(
                      'letterSpacing',
                      e.target.value,
                      variant,
                      'heading',
                      'style'
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="fontFamily-native-helper">
                  Font family
                </InputLabel>
                <Select
                  value={
                    themeData[variant] && themeData[variant].style
                      ? themeData[variant].style.fontFamily
                      : 'Roboto'
                  }
                  onChange={(e: any) =>
                    handleUpdate(
                      'fontFamily',
                      e.target.value,
                      variant,
                      'heading',
                      'style'
                    )
                  }
                >
                  {FONTS.map(font => (
                    <MenuItem key={font} value={font}>
                      {font}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="textTransform-native-helper">
                  Text transform
                </InputLabel>
                <Select
                  value={
                    themeData[variant] && themeData[variant].style
                      ? themeData[variant].style.textTransform
                      : 'none'
                  }
                  onChange={(e: any) =>
                    handleUpdate(
                      'textTransform',
                      e.target.value,
                      variant,
                      'heading',
                      'style'
                    )
                  }
                >
                  {TEXT_TRANSFORM.map(textTransform => (
                    <MenuItem key={textTransform} value={textTransform}>
                      {textTransform}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="textTransform-native-helper">
                  Font weight
                </InputLabel>
                <Select
                  value={
                    themeData[variant] && themeData[variant].style
                      ? themeData[variant].style.fontWeight
                      : 'none'
                  }
                  onChange={(e: any) =>
                    handleUpdate(
                      'fontWeight',
                      e.target.value.toString(),
                      variant,
                      'heading',
                      'style'
                    )
                  }
                >
                  {FONT_WEIGHT.map(fontWeight => (
                    <MenuItem key={fontWeight.tag} value={fontWeight.value}>
                      {fontWeight.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ThemeHeading
