import React from 'react';
import { ExpandMore } from '@material-ui/icons';
import {
  Divider,
  Typography,
  createStyles,
  withStyles,
  WithStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  InputBase,
  InputLabel,
  List,
  ListItem,
  Paper,
  NativeSelect
} from '@material-ui/core';
import { Slider } from '@material-ui/core';

import { getTextFromLanguage } from '../../../../helper/languageHelper';

import { FONTS, TEXT_ALIGN, BORDER_TYPES } from './config';
import ColorPicker from './ColorPicker';

const SelectInput = withStyles(() => ({
  input: {
    flex: 1,
    fontSize: 13,
    '&:focus': {
      background: '#fff',
      borderRadius: 4
    },
    paddingLeft: '4px 8px'
  }
}))(InputBase);

const styles = () =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      border: '1px solid #0000001f'
    },
    input: {
      paddingLeft: 8,
      flex: 1,
      width: '100%',
      fontSize: 13
    },
    FormLabel: {
      fontSize: 18
    },
    expansionPanel: {
      flex: 1,
      boxShadow: 'none',
      '&:before': {
        backgroundColor: '#ff0066',
        display: 'none'
      }
    },
    expansionPanelDetails: {
      padding: '4px'
    },
    expansionPanelExpanded: {
      margin: '0px !important'
    },
    slider: {
      padding: '22px 0px'
    }
  });

interface Props extends WithStyles<typeof styles> {
  Template: any;
  fields: any;
  expanded: string;
  languages: any;
  handleExpandedChange(panel: string): void;
  handleChangeField(value: string | number, fieldName: string): void;
  handleChangeTextField(
    value: string,
    language: string,
    fieldName: string
  ): void;
}

const UpdateDivider: React.FunctionComponent<Props> = props => {
  const {
    classes,
    expanded,
    handleExpandedChange,
    handleChangeTextField,
    handleChangeField,
    languages,
    fields
  } = props;

  return (
    <ExpansionPanel
      classes={{
        root: classes.expansionPanel,
        expanded: classes.expansionPanelExpanded
      }}
      expanded={expanded === 'panel2'}
      onChange={handleExpandedChange('panel2')}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMore />}
        classes={{ expanded: classes.expansionPanelExpanded }}
      >
        <Typography
          variant="subtitle1"
          className={classes.typo}
          data-cy="Divider-Settings"
        >
          Divider Settings
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
        <Divider />
        <List>
          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel
                style={{ marginBottom: 10 }}
                shrink={true}
                className={classes.FormLabel}
              >
                Width - {fields.width} %
              </InputLabel>
              <Grid container={true} justify="space-between">
                <Slider
                  className={styles.slider}
                  value={parseInt(fields.width, 10)}
                  aria-labelledby="slider-label"
                  step={1}
                  min={0}
                  max={100}
                  onChange={(e: any, value: number) =>
                    handleChangeField(value, 'width')
                  }
                />
              </Grid>
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel
                style={{ marginBottom: 10 }}
                shrink={true}
                className={classes.FormLabel}
              >
                Divider height - {fields.borderThickness}
              </InputLabel>
              <Grid container={true} justify="space-between">
                <Slider
                  className={styles.slider}
                  value={parseInt(fields.borderThickness, 10)}
                  aria-labelledby="slider-label"
                  step={1}
                  min={0}
                  max={100}
                  onChange={(e: any, value: number) =>
                    handleChangeField(value, 'borderThickness')
                  }
                />
              </Grid>
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel shrink={true} className={classes.FormLabel}>
                Divider type
              </InputLabel>
              <Paper className={classes.root} elevation={0}>
                <NativeSelect
                  native="true"
                  className={classes.input}
                  value={fields.borderType}
                  onChange={(e: any) =>
                    handleChangeField(e.target.value, 'borderType')
                  }
                  input={
                    <SelectInput
                      name="divider"
                      id="age-customized-native-simple"
                    />
                  }
                >
                  {BORDER_TYPES.map(type => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </NativeSelect>
              </Paper>
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel shrink={true} className={classes.FormLabel}>
                Divider color
              </InputLabel>
              <ColorPicker
                color={fields.borderColor}
                fieldName={'borderColor'}
                onChange={handleChangeField}
              />
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel
                style={{ marginBottom: 10 }}
                shrink={true}
                className={classes.FormLabel}
              >
                Top/bottom padding - {fields.paddingDiv1}
              </InputLabel>
              <Grid container={true} justify="space-between">
                <Slider
                  className={styles.slider}
                  value={parseInt(fields.paddingDiv1, 10)}
                  aria-labelledby="slider-label"
                  step={1}
                  min={0}
                  max={100}
                  onChange={(e: any, value: number) =>
                    handleChangeField(value, 'paddingDiv1')
                  }
                />
              </Grid>
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel
                style={{ marginBottom: 10 }}
                shrink={true}
                className={classes.FormLabel}
              >
                Left/right padding - {fields.paddingDiv2}
              </InputLabel>
              <Grid container={true} justify="space-between">
                <Slider
                  className={styles.slider}
                  value={parseInt(fields.paddingDiv2, 10)}
                  aria-labelledby="slider-label"
                  step={1}
                  min={0}
                  max={100}
                  onChange={(e: any, value: number) =>
                    handleChangeField(value, 'paddingDiv2')
                  }
                />
              </Grid>
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel
                style={{ marginBottom: 10 }}
                shrink={true}
                className={classes.FormLabel}
              >
                Margin - {fields.margin}
              </InputLabel>
              <Grid container={true} justify="space-between">
                <Slider
                  className={styles.slider}
                  value={parseInt(fields.margin, 10)}
                  aria-labelledby="slider-label"
                  step={1}
                  min={0}
                  max={100}
                  onChange={(e: any, value: number) =>
                    handleChangeField(value, 'margin')
                  }
                />
              </Grid>
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel
                style={{ marginBottom: 10 }}
                shrink={true}
                className={classes.FormLabel}
              >
                Top/bottom text padding - {fields.paddingText1}
              </InputLabel>
              <Grid container={true} justify="space-between">
                <Slider
                  className={styles.slider}
                  value={parseInt(fields.paddingText1, 10)}
                  aria-labelledby="slider-label"
                  step={1}
                  min={0}
                  max={100}
                  onChange={(e: any, value: number) =>
                    handleChangeField(value, 'paddingText1')
                  }
                />
              </Grid>
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel
                style={{ marginBottom: 10 }}
                shrink={true}
                className={classes.FormLabel}
              >
                Left/right text padding - {fields.paddingText2}
              </InputLabel>
              <Grid container={true} justify="space-between">
                <Slider
                  className={styles.slider}
                  value={parseInt(fields.paddingText2, 10)}
                  aria-labelledby="slider-label"
                  step={1}
                  min={0}
                  max={100}
                  onChange={(e: any, value: number) =>
                    handleChangeField(value, 'paddingText2')
                  }
                />
              </Grid>
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel shrink={true} className={classes.FormLabel}>
                Text background
              </InputLabel>
              <ColorPicker
                color={fields.textBackground}
                fieldName={'textBackground'}
                onChange={handleChangeField}
              />
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel shrink={true} className={classes.FormLabel}>
                Text align
              </InputLabel>
              <Paper className={classes.root} elevation={0}>
                <NativeSelect
                  native="true"
                  className={classes.input}
                  value={fields.textAlign}
                  onChange={(e: any) =>
                    handleChangeField(e.target.value, 'textAlign')
                  }
                  input={
                    <SelectInput
                      name="align"
                      id="age-customized-native-simple"
                    />
                  }
                >
                  {TEXT_ALIGN.map(align => (
                    <option key={align} value={align}>
                      {align}
                    </option>
                  ))}
                </NativeSelect>
              </Paper>
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel shrink={true} className={classes.FormLabel}>
                Text color
              </InputLabel>
              <ColorPicker
                color={fields.textColor}
                fieldName={'textColor'}
                onChange={handleChangeField}
              />
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel shrink={true} className={classes.FormLabel}>
                Font family
              </InputLabel>
              <Paper className={classes.root} elevation={0}>
                <NativeSelect
                  native="true"
                  className={classes.input}
                  value={fields.fontFamily}
                  onChange={(e: any) =>
                    handleChangeField(e.target.value, 'fontFamily')
                  }
                  input={
                    <SelectInput
                      name="font"
                      id="age-customized-native-simple"
                    />
                  }
                >
                  {FONTS.map(font => (
                    <option key={font} value={font}>
                      {font}
                    </option>
                  ))}
                </NativeSelect>
              </Paper>
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel
                style={{ marginBottom: 10 }}
                shrink={true}
                className={classes.FormLabel}
              >
                Font size - {fields.fontSize}
              </InputLabel>
              <Grid container={true} justify="space-between">
                <Slider
                  className={styles.slider}
                  value={parseInt(fields.fontSize, 10)}
                  aria-labelledby="slider-label"
                  step={1}
                  min={0}
                  max={100}
                  onChange={(e: any, value: number) =>
                    handleChangeField(value, 'fontSize')
                  }
                />
              </Grid>
            </Grid>
          </ListItem>

          <ListItem>
            <Grid container={true} direction="column">
              <InputLabel shrink={true} className={classes.FormLabel}>
                Text
              </InputLabel>
              {languages.map((language: string) => {
                let text = '';
                try {
                  text = getTextFromLanguage(fields.text.text, language) || '';
                } catch (error) {
                  console.log(error);
                }
                return (
                  <div key={language}>
                    {language}
                    <Paper className={classes.root} elevation={0}>
                      <InputBase
                        className={classes.input}
                        placeholder=""
                        value={text}
                        onChange={(e: any) =>
                          handleChangeTextField(
                            e.target.value,
                            language,
                            'text'
                          )
                        }
                      />
                    </Paper>
                  </div>
                );
              })}
            </Grid>
          </ListItem>
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default withStyles(styles, { withTheme: true })(UpdateDivider);
