import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// import Dropzone from 'react-dropzone';
import { ADMIN_GET_SHOP_SETTINGS } from '../../gql/admin-queries';
import { ADMIN_UPDATE_SHOP_SETTINGS } from '../../gql/admin-mutations';
import _get from 'lodash/get';
import Input from '../../../common/Input';
import _cloneDeep from 'lodash/cloneDeep';
import TeamstoreCategoryTitle from './TeamstoreCategoryTitle';
import TeamstoreDivisionTitle from './TeamstoreDivisionTitle';
const omitDeep = require('omit-deep');
const uuid = require('uuid');

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  }
});

class TeamstoreStructure extends Component {
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const {
      classes,
      teamstore,
      teamstoreId,
      updateTeamstore,
      defaultLanguage,
      languages,
      shopId
    } = this.props;
    if (!teamstore) return null;
    return (
      <Grid container className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Activity
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Add activities or disciplines
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                {teamstore.targetCategories
                  ? teamstore.targetCategories.map(
                      (targetCategory, targetCategoryIndex) => {
                        return (
                          <Grid
                            key={
                              'teamstoreTargetCategory' + targetCategoryIndex
                            }
                            item
                            xs={12}
                          >
                            <Input
                              onChange={e => {
                                updateTeamstore({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    ...teamstore,
                                    targetCategories: teamstore.targetCategories.map(
                                      tempTargetCategory => {
                                        return {
                                          ...tempTargetCategory,
                                          name:
                                            tempTargetCategory.id ===
                                            targetCategory.id
                                              ? e.target.value
                                              : tempTargetCategory.name
                                        };
                                      }
                                    )
                                  },
                                  variables: {
                                    input: {
                                      targetCategories: [
                                        ..._cloneDeep(
                                          teamstore.targetCategories
                                        ).map(tc =>
                                          omitDeep(
                                            {
                                              ...tc,
                                              name:
                                                tc.id === targetCategory.id
                                                  ? e.target.value
                                                  : tc.name
                                            },
                                            ['__typename']
                                          )
                                        )
                                      ]
                                    }
                                  }
                                });
                              }}
                              label="Category"
                              placeholder="Category name"
                              margin="normal"
                            />
                            <TeamstoreCategoryTitle
                              classes={classes}
                              targetCategory={targetCategory}
                              defaultLanguage={defaultLanguage}
                              languages={languages}
                              updateTeamstore={updateTeamstore}
                              teamstoreId={teamstoreId}
                              teamstore={teamstore}
                              shopId={shopId}
                            />
                            <Button
                              onClick={() => {
                                updateTeamstore({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    ...teamstore,
                                    targetCategories: teamstore.targetCategories.filter(
                                      tempTargetCategory1 => {
                                        return tempTargetCategory1.id !==
                                          targetCategory.id
                                          ? true
                                          : false;
                                      }
                                    )
                                  },
                                  variables: {
                                    input: {
                                      targetCategories: [
                                        ..._cloneDeep(
                                          teamstore.targetCategories
                                        )
                                          .filter(tempTargetCategory2 => {
                                            return tempTargetCategory2.id !==
                                              targetCategory.id
                                              ? true
                                              : false;
                                          })
                                          .map(tc =>
                                            omitDeep(tc, ['__typename'])
                                          )
                                      ]
                                    }
                                  }
                                });
                              }}
                            >
                              Delete
                            </Button>
                          </Grid>
                        );
                      }
                    )
                  : null}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      const newId = uuid.v4();
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          ...teamstore,
                          targetCategories: teamstore.targetCategories
                            ? [
                                ...teamstore.targetCategories,
                                {
                                  id: newId,
                                  __typename: 'ShopTargetCategory'
                                }
                              ]
                            : [
                                {
                                  id: newId,
                                  __typename: 'ShopTargetCategory'
                                }
                              ]
                        },
                        variables: {
                          input: {
                            targetCategories: teamstore.targetCategories
                              ? [
                                  ..._cloneDeep(teamstore.targetCategories).map(
                                    tc => omitDeep(tc, ['__typename'])
                                  ),
                                  {
                                    id: newId
                                  }
                                ]
                              : [
                                  {
                                    id: newId
                                  }
                                ]
                          }
                        }
                      });
                    }}
                  >
                    Add Activity
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Divisions
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Add divisions, organizations, departments or groups
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                {teamstore.groups
                  ? teamstore.groups.map((group, groupIndex) => {
                      return (
                        <Grid key={'teamstoreGroup' + groupIndex} item xs={12}>
                          <Input
                            onChange={e => {
                              updateTeamstore({
                                optimisticResponse: {
                                  __typename: 'Mutation',
                                  ...teamstore,
                                  groups: teamstore.groups.map(
                                    (tempGroup, tempGroupIndex) => {
                                      return {
                                        ...tempGroup,
                                        name:
                                          tempGroup.id === group.id
                                            ? e.target.value
                                            : tempGroup.name
                                      };
                                    }
                                  )
                                },
                                variables: {
                                  input: {
                                    groups: teamstore.groups.map(
                                      (tempGroup2, tempGroupIndex2) => {
                                        return _cloneDeep(
                                          tempGroup2,
                                          omitDeep(
                                            {
                                              ...tempGroup2,
                                              name:
                                                tempGroup2.id === group.id
                                                  ? e.target.value
                                                  : tempGroup2.name
                                            },
                                            ['__typename']
                                          )
                                        );
                                      }
                                    )
                                  }
                                }
                              });
                            }}
                            label="Division"
                            placeholder="Division"
                            margin="normal"
                          />
                          <TeamstoreDivisionTitle
                            classes={classes}
                            group={group}
                            defaultLanguage={defaultLanguage}
                            languages={languages}
                            updateTeamstore={updateTeamstore}
                            teamstoreId={teamstoreId}
                            teamstore={teamstore}
                            shopId={shopId}
                          />
                          <Button
                            onClick={() => {
                              updateTeamstore({
                                optimisticResponse: {
                                  __typename: 'Mutation',
                                  ...teamstore,
                                  groups: teamstore.groups.filter(
                                    tempGroup1 => {
                                      return tempGroup1.id !== group.id
                                        ? true
                                        : false;
                                    }
                                  )
                                },
                                variables: {
                                  input: {
                                    groups: [
                                      ..._cloneDeep(teamstore.groups)
                                        .filter(tempGroup2 => {
                                          return tempGroup2.id !== group.id
                                            ? true
                                            : false;
                                        })
                                        .map(tc => omitDeep(tc, ['__typename']))
                                    ]
                                  }
                                }
                              });
                            }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      );
                    })
                  : null}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      const newId = uuid.v4();
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          groups: teamstore.groups
                            ? [
                                ...teamstore.groups,
                                {
                                  id: newId
                                }
                              ]
                            : [
                                {
                                  id: newId
                                }
                              ]
                        },
                        variables: {
                          input: {
                            groups: teamstore.groups
                              ? [
                                  ...omitDeep(teamstore.groups, ['__typename']),
                                  {
                                    id: newId
                                  }
                                ]
                              : [
                                  {
                                    id: newId
                                  }
                                ]
                          }
                        }
                      });
                    }}
                  >
                    Add Division
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TeamstoreStructure);
