// https://www.xe.com/symbols.php
export function showCurrencySymbol(currency) {
  switch (currency) {
    case 'THB':
      return '฿'
    case 'EUR':
      return '€'
    case 'USD':
      return '$'
    case 'MXN':
      return '$'
    case 'CAD':
      return '$'
    case 'GBP':
      return '£'
    default:
      return ''
  }
}
