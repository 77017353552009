import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, Input, InputLabel } from '@material-ui/core';
import { ADMIN_UPDATE_PRODUCT } from '../../gql/admin-mutations';
import client from '../../../admin/gql/admin-apollo-config';
import SimpleSnackbar from '../content/SimpleSnackbar';

const ProductDetails = ({
  classes,
  product,
  productId,
  updateProductDetail
}) => {
  const [productData, setProductData] = useState(product);
  const [notification, setNotification] = useState(false);

  return (
    <>
      <Grid container style={{ marginTop: 46 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Grid
            container
            style={{
              padding: '24px 24px 24px 0px'
            }}
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <Typography
                data-cy="Product-Details-header"
                variant="subtitle1"
                style={{ marginRight: 16 }}
              >
                Product details
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Provide information on your product
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Paper
            style={{
              padding: 24,
              borderRadius: 15
            }}
            elevation={0}
          >
            <Grid container className={classes.container} spacing={1}>
              <Grid item xs={12}>
                <InputLabel>Internal ID (style number)</InputLabel>
                <Input
                  dataCy="Product-Style-Number"
                  label="Internal ID (style number)"
                  placeholder="Enter style name/no"
                  defaultValue={productData.no}
                  onChange={e =>
                    updateProductDetail(productId, 'no', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  Accounting ID (Used to identify in your accounting system)
                </InputLabel>
                <Input
                  dataCy="Product-Accounting-ID"
                  label="Accounting ID (Used to identify in your accounting system)"
                  placeholder="Enter accounting reference ID"
                  defaultValue={productData.accountingId}
                  onChange={e => {
                    updateProductDetail(
                      productId,
                      'accountingId',
                      e.target.value
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <SimpleSnackbar
        open={notification}
        message="Product details updated succesfully!"
        verticalAlign="bottom"
        horizontalAlign="right"
      />
    </>
  );
};

export default ProductDetails;
