import React, { Component } from 'react';

export default class AlignItemsFlexStart extends React.Component {
  render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.00195312" width="17.9961" height="1.38432" fill="black" />
        <rect
          x="4.15231"
          y="2.77301"
          width="2.76863"
          height="15.2275"
          fill="black"
        />
        <rect
          x="11.0735"
          y="2.77301"
          width="2.76863"
          height="9.69021"
          fill="black"
        />
      </svg>
    );
  }
}
