import React, { Component } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../../common/Select';
import { Query, Mutation } from 'react-apollo';
import {
  ADMIN_SHOP_PRODUCT_ATTRIBUTES,
  ADMIN_GET_PRODUCT
} from '../../gql/admin-queries';
import {
  ADMIN_CREATE_ASSIGNED_PRODUCT_ATTRIBUTE,
  ADMIN_DELETE_ASSIGNED_PRODUCT_ATTRIBUTE
} from '../../gql/admin-mutations';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import GlobalStyles from '../../../globalStyles';
import _sortBy from 'lodash/sortBy';
import _orderBy from 'lodash/orderBy';
class AssignAttribute extends Component {
  state = {
    selectedAttribute: null
  };
  render() {
    const {
      defaultLanguage,
      product,
      refetch,
      shopId,
      sortedAttributes
    } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography data-cy="Product-Attribute-header" variant="subtitle1">
            Attribute
          </Typography>
        </Grid>
        <Mutation mutation={ADMIN_DELETE_ASSIGNED_PRODUCT_ATTRIBUTE}>
          {(deleteAssignedProductAttribute, { data, loading, error }) => {
            return (
              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                <Paper elevation={0} style={{ padding: 24 }}>
                  {product.assignedProductAttributes
                    ? _orderBy(
                        product.assignedProductAttributes,
                        'attribute.sortOrder',
                        'asc'
                      ).map(pa => (
                        <Grid
                          key={'pa-' + pa.attribute.id}
                          container
                          spacing={1}
                          style={{ paddingRight: 16 }}
                        >
                          <GlobalStyles />
                          <Grid item xs={11}>
                            <div
                              data-cy={
                                'Product-Attribute-Item-' +
                                getTextFromLanguage(
                                  pa.attribute.title.text,
                                  defaultLanguage
                                )
                              }
                              style={{
                                border: '1px solid #E5E5E5',
                                color: '#000000',
                                height: 36,
                                borderRadius: 3,
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0px 8px',
                                marginRight: 8
                              }}
                            >
                              {pa.attribute && pa.attribute.icon ? (
                                <span
                                  style={{}}
                                  className={`icon--product icon--${pa.attribute.icon}`}
                                />
                              ) : null}
                              {pa.attribute.title
                                ? getTextFromLanguage(
                                    pa.attribute.title.text,
                                    defaultLanguage
                                  )
                                : 'N/A'}
                              <span
                                style={{
                                  margin: '0 0 0 auto'
                                }}
                              >
                                {pa.attribute.isFeatured === true
                                  ? 'Featured'
                                  : null}
                              </span>
                            </div>
                          </Grid>
                          <Grid item xs={1}>
                            <Button
                              data-cy={
                                'Product-Attribute-Delete-btn-' +
                                getTextFromLanguage(
                                  pa.attribute.title.text,
                                  defaultLanguage
                                )
                              }
                              onClick={async () => {
                                await deleteAssignedProductAttribute({
                                  variables: {
                                    productId: product.id,
                                    productAttributeId: pa.attribute.id
                                  }
                                });
                                window.location.reload();
                              }}
                              color="primary"
                              variant="contained"
                            >
                              <DeleteIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      ))
                    : null}
                  <Grid
                    container
                    alignItems="flex-end"
                    spacing={1}
                    style={{ marginTop: 16 }}
                  >
                    <Grid item>
                      <Select
                        dataCy="Product-Attribute-Select"
                        onChange={e => {
                          this.setState({
                            selectedAttribute: e.target.value
                          });
                        }}
                        value={this.state.selectedAttribute}
                        label="Attributes"
                      >
                        <option value={null}>Choose</option>
                        {sortedAttributes
                          ? sortedAttributes.map(pa => {
                              let title = '';
                              try {
                                title = getTextFromLanguage(
                                  pa.node.title.text,
                                  defaultLanguage
                                );
                              } catch (error) {}
                              try {
                                title =
                                  title +
                                  ' (' +
                                  getTextFromLanguage(
                                    pa.node.description.text,
                                    defaultLanguage
                                  ).substring(0, 20) +
                                  '...)';
                              } catch (error) {}
                              return (
                                <option value={pa.node.id}>{title}</option>
                              );
                            })
                          : null}
                      </Select>
                    </Grid>
                    <Grid item>
                      <Mutation
                        mutation={ADMIN_CREATE_ASSIGNED_PRODUCT_ATTRIBUTE}
                        update={(
                          cache,
                          { data: { createAssignedProductAttribute } }
                        ) => {
                          // if (createAssignedProductAttribute) {
                          //   const data = cache.readQuery({
                          //     query: ADMIN_GET_PRODUCT,
                          //     variables: { id: product.id }
                          //   });
                          //   if(data.product.assignedProductAttributes){
                          //     data.product.assignedProductAttributes = [
                          //       ...data.product.assignedProductAttributes,
                          //       {
                          //         attribute: {
                          //           id: this.state.selectedAttribute,
                          //           __typename: "ProductAttribute"
                          //         },
                          //         __typename: "AssignedProductAttribute"
                          //       }
                          //     ]
                          //   }
                          //   cache.writeQuery({
                          //     query: ADMIN_GET_PRODUCT,
                          //     variables: { id: product.id },
                          //     data
                          //   });
                          // }
                        }}
                      >
                        {(
                          createAssignedProductAttribute,
                          { data, loading, error }
                        ) => {
                          return (
                            <Button
                              data-cy="Product-Attribute-Assign-btn"
                              onClick={async () => {
                                await createAssignedProductAttribute({
                                  variables: {
                                    shopId: shopId,
                                    productId: product.id,
                                    productAttributeId: this.state
                                      .selectedAttribute
                                  }
                                });
                                window.location.reload();
                              }}
                              variant="contained"
                              color="primary"
                              style={{ fontWeight: 400 }}
                            >
                              Assign Attribute
                            </Button>
                          );
                        }}
                      </Mutation>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          }}
        </Mutation>
      </Grid>
    );
  }
}

export default AssignAttribute;
