import React, { Component } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_GET_COUPON } from '../../gql/admin-queries';
import gql from 'graphql-tag';
import { Button, Typography } from '@material-ui/core';
import { ADMIN_UPDATE_COUPON } from '../../gql/admin-mutations';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DiscountDetail from '../discounts/DiscountDetail';
import DiscountList from '../discounts/DiscountList';
import Input from '../../../common/Input';
import Checkbox from '@material-ui/core/Checkbox';
import CouponTitle from './CouponTitle';
import CouponAvailableUntil from './CouponAvailableUntil';
import CouponAvailableFrom from './CouponAvailableFrom';
const styles = theme => ({
  root: {
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  }
});

class CouponDetail extends Component {
  state = {};
  render() {
    const { couponId, classes, languages, shopId } = this.props;
    return (
      <Query query={ADMIN_GET_COUPON} variables={{ id: couponId, first: 1 }}>
        {({ loading, error, data: { coupon } }) => {
          if (loading) return <div>Loading...</div>;
          if (error) return <div>Error :(</div>;
          return (
            <div className={classes.root}>
              <Grid
                container
                justify="flex-start"
                direction="row"
                alignItems="center"
              >
                <Typography
                  style={{
                    fontSize: 28,
                    fontWeight: 'medium',
                    color: '#424242',
                    flex: 1
                  }}
                >
                  Coupon
                </Typography>
                <Link
                  to={`/admin/${shopId}/settings/discounts/${coupon.discountId}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button variant="outlined" color="primary">
                    Back
                  </Button>
                </Link>
              </Grid>

              <div>
                <Mutation
                  mutation={ADMIN_UPDATE_COUPON}
                  variables={{
                    id: couponId,
                    couponCode: coupon.couponCode
                  }}
                  update={(cache, { data: { updateCoupon } }) => {
                    if (updateCoupon.id) {
                      // const data = store.readQuery({
                      //   query: ADMIN_GET_COUPON,
                      //   variables: { id: couponId }
                      // });
                      // data.product = updateCoupon;
                      // store.writeQuery({
                      //   query: ADMIN_GET_COUPON,
                      //   variables: { id: couponId },
                      //   data
                      // });
                    }
                  }}
                >
                  {(updateCoupon, { data, loading, error }) => {
                    return (
                      <div>
                        <Grid container style={{ padding: 24 }}>
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Grid
                              container
                              style={{ padding: '24px 24px 24px 0px' }}
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle1"
                                  style={{ marginRight: 16 }}
                                >
                                  Coupon
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body1">
                                  Provide coupon information
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                            <Paper elevation={0} style={{ padding: 24 }}>
                              <Grid container spacing={1} direction="column">
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Code: {coupon.couponCode}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    label="Internal ID"
                                    onChange={e => {
                                      updateCoupon({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateCoupon: {
                                            ...coupon,
                                            internalNote: e.target.value
                                          }
                                        },
                                        variables: {
                                          input: {
                                            internalNote: e.target.value
                                          }
                                        }
                                      });
                                    }}
                                    placeholder="Internal Note"
                                    type="text"
                                    value={coupon.internalNote}
                                    id="internalNote"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    label="Accounting ID (Used to identify in your accounting system)"
                                    placeholder="Enter accounting reference ID"
                                    value={coupon.accountingId}
                                    onChange={e =>
                                      updateCoupon({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateCoupon: {
                                            ...coupon,
                                            accountingId: e.target.value
                                          }
                                        },
                                        variables: {
                                          input: {
                                            accountingId: e.target.value
                                          }
                                        }
                                      })
                                    }
                                  />
                                </Grid>
                                <CouponTitle
                                  languages={languages}
                                  classes={classes}
                                  coupon={coupon}
                                  couponId={couponId}
                                  updateCoupon={updateCoupon}
                                />
                                <Grid item xs={12}>
                                  <Input
                                    label={'Maximum usage per customer'}
                                    placeholder="unlimited"
                                    onChange={e => {
                                      updateCoupon({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateCoupon: {
                                            ...coupon,
                                            maxUsagePerCustomer: e.target.value
                                              ? parseInt(e.target.value, 10)
                                              : null
                                          }
                                        },
                                        variables: {
                                          id: couponId,
                                          input: {
                                            maxUsagePerCustomer: e.target.value
                                              ? parseInt(e.target.value, 10)
                                              : null
                                          }
                                        }
                                      });
                                    }}
                                    type="number"
                                    value={coupon.maxUsagePerCustomer}
                                    id="maxUsagePerCustomer"
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <Input
                                    label={
                                      'Total maximum usage (Actual current usage: ' +
                                      coupon.currentUsageOverall +
                                      ')'
                                    }
                                    placeholder="unlimited"
                                    onChange={e => {
                                      updateCoupon({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateCoupon: {
                                            ...coupon,
                                            maxUsageOverall: e.target.value
                                              ? parseInt(e.target.value, 10)
                                              : null
                                          }
                                        },
                                        variables: {
                                          id: couponId,
                                          input: {
                                            maxUsageOverall: e.target.value
                                              ? parseInt(e.target.value, 10)
                                              : null
                                          }
                                        }
                                      });
                                    }}
                                    type="number"
                                    value={coupon.maxUsageOverall}
                                    id="maxUsageOverall"
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <CouponAvailableFrom
                                    classes={classes}
                                    couponId={couponId}
                                    updateCoupon={updateCoupon}
                                    coupon={coupon}
                                  />
                                  <CouponAvailableUntil
                                    classes={classes}
                                    couponId={couponId}
                                    updateCoupon={updateCoupon}
                                    coupon={coupon}
                                  />
                                </Grid>
                                {/*
                        availableFrom: Timestamp
                        availableUntil: Timestam */}
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        <Grid container style={{ padding: 24 }}>
                          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Grid
                              container
                              style={{ padding: '24px 24px 24px 0px' }}
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle1"
                                  style={{ marginRight: 16 }}
                                >
                                  Enabled
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body1">
                                  Enable your coupon
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                            <Paper elevation={0} style={{ padding: 24 }}>
                              <Grid container spacing={1} direction="column">
                                <Grid item xs={12}>
                                  <Checkbox
                                    style={{ width: 'auto' }}
                                    classes={{
                                      root: classes.cssRootInput,
                                      input: classes.cssinput
                                    }}
                                    onChange={e => {
                                      updateCoupon({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateCoupon: {
                                            ...coupon,
                                            enabled: e.target.checked
                                          }
                                        },
                                        variables: {
                                          id: couponId,
                                          input: {
                                            enabled: e.target.checked
                                          }
                                        }
                                      });
                                    }}
                                    placeholder="Enabled"
                                    checked={
                                      coupon.enabled === true ? 'checked' : ''
                                    }
                                    id="enabled"
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>

                        {coupon.discountId ? (
                          <Grid
                            container
                            style={{ marginTop: 24, position: 'relative' }}
                          >
                            <Button
                              style={{
                                position: 'absolute',
                                top: 40,
                                right: 24
                              }}
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                updateCoupon({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateCoupon: {
                                      ...coupon,
                                      discountId: null
                                    }
                                  },
                                  variables: {
                                    id: couponId,
                                    input: {
                                      discountId: null
                                    }
                                  }
                                });
                              }}
                            >
                              Remove from assigned discount
                            </Button>
                            {/* <DiscountDetail
                              isCouponPage={true}
                              discountId={coupon.discountId}
                            /> */}
                          </Grid>
                        ) : (
                          <div>
                            Assign a discount below
                            <DiscountList
                              coupon={coupon}
                              updateCoupon={updateCoupon}
                              couponId={coupon.id}
                            />
                          </div>
                        )}
                      </div>
                    );
                  }}
                </Mutation>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(CouponDetail);
