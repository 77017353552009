import React from 'react'
import { useMutation } from '@apollo/react-hooks';
import {WithStyles, withStyles, createStyles, Button} from '@material-ui/core'
import {ADMIN_TRIGGER_PUBLISH} from '../../gql/admin-mutations'
import {ADMIN_GET_DEPLOYMENTS} from '../../gql/admin-queries'


const styles = () => createStyles({
})


interface Props extends WithStyles<typeof styles> {
  deployId: string
  siteId: string
  shopId: string
}


const DeploymentPublishButton: React.FunctionComponent<Props> = props => {
  const {deployId, siteId, shopId} = props

  const [triggerPublish, mutationResult] = useMutation(
    ADMIN_TRIGGER_PUBLISH, {
      variables: {
        deployId,
        siteId
      },
      refetchQueries: [{
        query: ADMIN_GET_DEPLOYMENTS,
        variables: {id: shopId}
      }]
    }
  );

  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={mutationResult.loading}
      onClick={() => triggerPublish()}
    >Publish</Button>
  )
}

export default withStyles(styles, { withTheme: true })(DeploymentPublishButton)
