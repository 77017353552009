import React, { Component } from 'react';

export default class UploadIcon extends React.Component {
  render() {
    return (
      <svg
        width="35"
        height="26"
        viewBox="0 0 35 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.0968 24.836H5.03947C2.98037 24.836 1.29297 23.1722 1.29297 21.0895V4.7465C1.29297 2.6874 2.95677 1 5.03947 1H30.0909C32.15 1 33.8374 2.6638 33.8374 4.7465V21.0836C33.8433 23.1486 32.1559 24.836 30.0968 24.836Z"
          fill="white"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.2256 24.6C18.4205 20.4818 21.1286 17.8209 21.1286 17.8209L22.8691 19.526L26.4209 11.6731L33.5245 22.4288"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.57698 13.5794C11.9915 13.5794 13.9489 11.622 13.9489 9.20746C13.9489 6.79293 11.9915 4.83556 9.57698 4.83556C7.16244 4.83556 5.20508 6.79293 5.20508 9.20746C5.20508 11.622 7.16244 13.5794 9.57698 13.5794Z"
          fill="white"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
}
