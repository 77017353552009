import React, { Component } from 'react';
import { Grid, Typography, Paper, Input, InputLabel } from '@material-ui/core';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import client from '../../../admin/gql/admin-apollo-config';
import { ADMIN_UPDATE_PRODUCT } from '../../gql/admin-mutations';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
const uuidv4 = require('uuid/v4');

const ProductSlug = ({ classes, product, productId, languages }) => {
  let timer = null;

  const updateSlug = (productId, fieldName, value, language) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      client.mutate({
        mutation: ADMIN_UPDATE_PRODUCT,
        variables: {
          id: productId,
          input: {
            [fieldName]: languages.map((tempSlug, index) => {
              return {
                langCode: tempSlug,
                content:
                  tempSlug === language
                    ? value
                      ? value
                      : null
                    : getTextFromLanguage(product.slugs, tempSlug)
              };
            })
          }
        }
      });
    });
  };

  return (
    <Grid
      container
      alignItems="flex-start"
      spacing={1}
      className={classes.container}
    >
      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <Grid
          container
          style={{ padding: '24px 24px 24px 0px' }}
          spacing={1}
          alignItems="center"
        >
          <Grid item>
            <Typography
              data-cy="Product-Slugs-header"
              variant="subtitle1"
              style={{ marginRight: 16 }}
            >
              Product slugs
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
        <Paper style={{ padding: 24, borderRadius: 15 }} elevation={0}>
          {languages.map((language, index) => {
            var slugText = '';
            try {
              slugText = getTextFromLanguage(product.slugs, language);
            } catch (error) {}
            return (
              <Grid
                container
                key={'style-slugText-' + index}
                direction="row"
                alignItems="flex-end"
                spacing={1}
                className={classes.container}
              >
                <Grid item xs={10}>
                  <InputLabel>Slug/URL</InputLabel>
                  <Input
                    dataCy={'Product-Slug-' + language}
                    label="Slug/URL"
                    placeholder="Enter slug"
                    multiline={true}
                    rowsMax="4"
                    defaultValue={slugText}
                    onChange={e => {
                      updateSlug(productId, 'slugs', e.target.value, language);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    style={{
                      height: 38,
                      width: '100%',
                      border: '1px solid #ddd',
                      borderRadius: '3px'
                    }}
                  >
                    {language}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProductSlug;
