import {
  faCcMastercard,
  faCcVisa,
  faCcDinersClub,
  faCcAmex,
  faCcDiscover,
  faCcJcb
} from '@fortawesome/free-brands-svg-icons'

export const PAYMENT_OPTIONS_LIST = [
  {
    label: 'Mastercard',
    name: 'MASTERCARD',
    icon: faCcMastercard,
    color: '#0a3a82'
  },
  {
    label: 'Bancontact',
    name: 'BANCONTACT',
    color: 'black'
  },
  {
    label: 'Sofort',
    name: 'SOFORT',
    color: 'black'
  },
  {
    label: 'iDEAL',
    name: 'IDEAL',
    color: 'black'
  },
  { label: 'Visa', name: 'VISA', icon: faCcVisa, color: '#0157a2' },
  {
    label: 'Diners Club',
    name: 'DINERSCLUB',
    icon: faCcDinersClub,
    color: '#004A97'
  },
  { label: 'Amex', name: 'AMEX', icon: faCcAmex, color: '#007bc1' },
  { label: 'Discover', name: 'DISCOVER', icon: faCcDiscover, color: '#f68121' },
  { label: 'Jcb', name: 'JCB', icon: faCcJcb, color: '#003A8F' }
]
