import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PrintingList from './PrintingList';
import PrintingDetail from './PrintingDetail';

class Printing extends Component {
  render() {
    const { printingId, shopId } = this.props;
    return printingId ? (
      <PrintingDetail shopId={shopId} printingId={printingId} />
    ) : (
      <Grid>
        <PrintingList shopId={shopId} />
      </Grid>
    );
  }
}
export default Printing;
