import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import { SHOP_ID } from '../../../brikl-config';
import NativeSelect from '@material-ui/core/NativeSelect';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    fontWeight: 300
  },
  input: {
    marginLeft: 8,
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 300,
    color: '#000'
  },
  select: {
    border: 'none',
    background: 'transparent',
    height: '40px',
    fontWeight: 400,
    lineHeight: '16px',
    fontFamily:
      SHOP_ID === 'vermarc'
        ? 'BiomeStd-Light, sans-serif'
        : SHOP_ID === 'bodywearlab'
        ? 'Work Sans, sans-serif'
        : SHOP_ID === 'OBS'
        ? 'news-gothic-std'
        : ['Roboto', '"Helvetica Neue"', 'Arial'],
    fontSize: '12px',
    width: '100%',
    '&:hover': {
      cursor: 'pointer'
    },
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    }
  }
};

class Select extends React.Component {
  render() {
    const {
      classes,
      children,
      placeholder,
      label,
      value,
      onChange,
      variant,
      rowsMax,
      labeColor,
      background = '#fff',
      border = '1px solid #ddd',
      color,
      textTransform,
      style,
      disabled,
      fontSize = '12px',
      width = '100%',
      dataCy,
      options
    } = this.props;

    return (
      <div data-cy={dataCy} style={style ? style : null}>
        <InputLabel className={classes.label} style={{ color: labeColor }}>
          {label}
        </InputLabel>
        <NativeSelect
          disabled={disabled}
          className={classes.select}
          value={value !== undefined ? value : ''}
          placeholder={placeholder ? placeholder : ''}
          onChange={onChange}
          variant={variant}
          // rowsMax={rowsMax}   // not allowed here
          style={{
            color: color,
            textTransform: textTransform,
            fontSize: fontSize,
            border: '1px solid #ced4da',
            borderRadius: 4
          }}
        >
          {children}
          {options
            ? options.map(options => (
                <option key={options.length}>{options}</option>
              ))
            : null}
        </NativeSelect>
      </div>
    );
  }
}

Select.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Select);
