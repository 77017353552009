import React from 'react'
import {Grid, Button, WithStyles, Table, TableCell, TableRow, withStyles, createStyles} from '@material-ui/core'

import DeploymentStatusPolling from './DeploymentStatusPolling'
import DeploymentPublishButton from './DeploymentPublishButton'
import {convertTS} from '../../../../helper/time'


const styles = () => createStyles({
  cell: {},
})


interface Props extends WithStyles<typeof styles> {
  data: any[]
  siteId: string
  shopId: string
}


const DeploymentList: React.FunctionComponent<Props> = props => {
  const {classes, data, siteId, shopId} = props

  return (
    <Grid container={true}>
      <Table>
        <TableRow>
          <TableCell>
            ID
          </TableCell>
          <TableCell>
            Created at
          </TableCell>
          <TableCell>
            Preview
          </TableCell>
          <TableCell>
            Status
          </TableCell>
          <TableCell>
            Published at
          </TableCell>
          <TableCell>
          </TableCell>
        </TableRow>
        {data ? data.map((item: any) => (
          <TableRow
            key={item.node.id}
            style={{ textDecoration: 'none' }}
          >
            <TableCell
              className={classes.cell}
              component="th"
              scope="row"
            >
              {item.node.id}
            </TableCell>
            <TableCell className={classes.cell}>
              {convertTS(item.node.createdAt)}
            </TableCell>
            <TableCell className={classes.cell}>
              {
                item.node.previewUrl ?
                <Button color="primary" variant="contained">
              <a style={{
                color: 'inherit',
                textDecoration: 'none'
              }} href={item.node.previewUrl} target="_blank" rel="noopener noreferrer">Preview</a>
              </Button>
              :'No preview'
              }              
            </TableCell>
            <TableCell className={classes.cell}>
              {item.node.isPollingEnabled ? (
                <DeploymentStatusPolling deployId={item.node.id} siteId={siteId} />
              ) : item.node.status}
            </TableCell>
            <TableCell className={classes.cell}>
              {item.node.publishedAt}
            </TableCell>
            <TableCell className={classes.cell}>
              {item.node.isPublishEnabled ? (
                <DeploymentPublishButton deployId={item.node.id} siteId={siteId} shopId={shopId} />
              ) : null}
            </TableCell>
          </TableRow>
        )) : null }
      </Table>
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(DeploymentList)
