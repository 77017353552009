import React from 'react'
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  Typography,
  NativeSelect,
  InputBase
} from '@material-ui/core'
import { countries } from 'countries-list'

import client from '../../../gql/apollo-config'
import { Query } from 'react-apollo'
import { GET_USER } from '../../../gql/queries'
import { UPDATE_USER_SETTINGS } from '../../../gql/mutations'
import { DEFAULT_COUNTRY } from '../../../helper/config'
import { setCurrency } from '../../../helper/storage'
const BootstrapInput = withStyles(theme => ({
  icon: {
    color: '#fff !important'
  },
  input: {
    borderRadius: 4,
    color: '#fff',
    position: 'relative'
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    color: '#fff',
    marginRight: 8,
    marginTop: 12,
    maxWidth: '100%'
  }
}))(InputBase)

const styles = (themeStyle: Theme) => createStyles({})

interface Props extends WithStyles<typeof styles> {}

const SetCountryContainer: React.FunctionComponent<Props> = props => {
  const {} = props
  const countryList = [
    { country: 'United Kingdom', code: 'GB' },
    { country: 'United States', code: 'US' },
    { country: 'Belgium', code: 'BE' },
    { country: 'Nederland', code: 'NL' },
    { country: 'Germany', code: 'DE' },
    { country: 'France', code: 'FR' }
  ]

  const handleChangeCountry = async (e): Promise<any> => {
    const country = countries[e.target.value]
    if (!country) {
      return
    }

    const currency =
      country.currency.indexOf(',') === -1
        ? country.currency
        : country.currency.split(',')[0]

    setCurrency(currency)
    await client.mutate({
      mutation: UPDATE_USER_SETTINGS,
      variables: {
        input: {
          settings: {
            defaultCountry: e.target.value,
            defaultCurrency: currency
          }
        }
      }
    })

    document.location.reload()
  }

  return (
    <>
      <Typography variant="h4">Country</Typography>

      <Query query={GET_USER}>
        {({ loading, error, data }) => {
          if (loading) return null

          let selectedCountry = DEFAULT_COUNTRY
          try {
            if (
              data &&
              data.user &&
              data.user.settings &&
              data.user.settings.defaultCountry
            ) {
              selectedCountry = data.user.settings.defaultCountry
            }
          } catch (error) {
            console.error('Failed to set default currency', error)
          }
          return (
            <NativeSelect
              value={selectedCountry}
              input={
                <BootstrapInput name="age" id="age-customized-native-simple" />
              }
              onChange={handleChangeCountry}
            >
              {countryList.map((country: any, index: number) => (
                <option
                  key={index}
                  style={{
                    color: 'black'
                  }}
                  value={country.code}
                >
                  {country.country}
                </option>
              ))}
            </NativeSelect>
          )
        }}
      </Query>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(SetCountryContainer)
