import React, { useState } from 'react'
import { Grid, CircularProgress, Button, NativeSelect } from '@material-ui/core'
import PagesTitle from './Pages/PagesTitle'
import PagesList from './Pages/PagesList'
import PageDialog from './Pages/PageDialog'
import DefaultPagesList from './Pages/DefaultPagesList'
import DefaultPagesLoading from './Pages/DefaultPagesLoading'
import { navigate } from '@reach/router'
import SetSalesChannel from './SetSalesChannel'

interface Props {
  otherPages: any
  createPage: any
  selected: string
  shopId: string
  defaultPages: any
  isCreating: boolean
  isCreatingDefaultPages: boolean
  homePageId: string | null | undefined
  setSelectedSalesChannel: any
  salesChannels: any
  selectedSalesChannel: string | null
  handleSetSelected(id: string): void
}

const PagesContainer: React.FunctionComponent<Props> = props => {
  const {
    otherPages,
    createPage,
    selected,
    shopId,
    shopUrl,
    defaultPages,
    handleSetSelected,
    isCreating,
    isCreatingDefaultPages,
    homePageId,
    setSelectedSalesChannel,
    selectedSalesChannel,
    salesChannels
  } = props
  const [dialog, setDialog] = useState(false)

  const handleSetSelectedSalesChannel = (event: any) => {
    setSelectedSalesChannel(event.target.value)
  }

  return (
    <Grid container={true}>
      <SetSalesChannel
        selected={selectedSalesChannel}
        salesChannels={salesChannels}
        handleSetSelected={handleSetSelectedSalesChannel}
      />

      <PagesTitle setDialog={setDialog} title="My Pages" />

      {isCreating ? <CircularProgress size={25} /> : null}
      <PagesList
        shopUrl={shopUrl}
        shopId={shopId}
        selected={selected}
        handleSetSelected={handleSetSelected}
        data={otherPages}
        homePageId={homePageId}
        selectedSalesChannel={selectedSalesChannel}
        salesChannels={salesChannels}
      />

      {/* bring back with v3 */}
      {/* <PagesTitle title="Default Pages" />
      {isCreatingDefaultPages ? (
        <DefaultPagesLoading />
      ) : (
        <DefaultPagesList
          data={defaultPages}
          shopId={shopId}
          selected={selected}
          handleSetSelected={handleSetSelected}
        />
      )} */}

      {dialog ? (
        <PageDialog
          shopUrl={shopUrl}
          createPage={createPage}
          dialog={dialog}
          setDialog={setDialog}
          title="Create new page"
          isUpdate={false}
          selectedSalesChannel={selectedSalesChannel}
          salesChannels={salesChannels}
        />
      ) : null}
      <Button
        style={{
          position: 'absolute',
          bottom: 0,
          margin: 20,
          fontFamily: 'Poppins'
        }}
        color="primary"
        variant="outlined"
        onClick={() => {
          navigate(`/admin/${shopId}/pagebuilder/pages`)
        }}
      >
        Pagebuilder V1
      </Button>
    </Grid>
  )
}

export default PagesContainer
