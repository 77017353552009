import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { Button, Grid } from '@material-ui/core';
import { navigate } from '@reach/router';
import { ADMIN_SHOP_VARIANTS } from '../../../gql/admin-queries';
import { ADMIN_CREATE_VARIANT } from '../../../gql/admin-mutations';
import { withStyles } from '@material-ui/core/styles';
import Input from '../../../../common/Input';
const omitDeep = require('omit-deep');
const uuid = require('uuid');
const styles = theme => ({
  textField: {
    marginLeft: theme.spacing,
    marginRight: theme.spacing
  }
});

class AddVariant extends Component {
  state = {
    optionValue: ''
  };

  render() {
    const { defaultLanguage, updateVariant, variant, variantId } = this.props;
    return (
      <Grid container alignItems="flex-end" spacing={1}>
        <Grid item>
          <Input
            dataCy="Variant-New-Option-Value"
            label="New option value"
            placeholder=""
            value={this.state.optionValue}
            onChange={e => this.setState({ optionValue: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Button
            data-cy="Variant-Add-New-Option"
            color="primary"
            onClick={() => {
              const newVariantOption = {
                id: uuid.v4(),
                value: this.state.optionValue,
                title: {
                  id: uuid.v4(),
                  text: [
                    {
                      content: this.state.optionValue,
                      langCode: defaultLanguage,
                      __typename: 'TextContent'
                    }
                  ],
                  __typename: 'Text'
                },
                __typename: 'VariantOption'
              };
              updateVariant({
                optimisticResponse: {
                  __typename: 'Mutation',
                  updateVariant: {
                    ...variant,
                    options: variant.options
                      ? [...variant.options, newVariantOption]
                      : [newVariantOption]
                  }
                },
                variables: {
                  id: variantId,
                  input: {
                    options: variant.options
                      ? [
                          ...omitDeep(variant.options, ['__typename']),
                          omitDeep(newVariantOption, ['__typename'])
                        ]
                      : [omitDeep(newVariantOption, ['__typename'])]
                  }
                }
              });
            }}
            variant="contained"
          >
            Add
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AddVariant);
