import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_GET_FABRIC_DESIGN_STEP } from '../../gql/admin-queries';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import { ADMIN_UPDATE_FABRIC_DESIGN_STEP } from '../../gql/admin-mutations';

class FabricStepSettings extends Component {
  state = {};
  render() {
    const classes = {};
    const { stepId } = this.props;
    return (
      <div className={classes.root}>
        <Query query={ADMIN_GET_FABRIC_DESIGN_STEP} variables={{ id: stepId }}>
          {({ loading, error, data: { fabricDesignStep } }) => {
            if (loading) return <div>Loading...</div>;
            if (error) return <div>Error :(</div>;
            return (
              <div>
                <Mutation
                  mutation={ADMIN_UPDATE_FABRIC_DESIGN_STEP}
                  update={(cache, { data: { updateFabricDesignStep } }) => {
                    if (updateFabricDesignStep.id) {
                      // const data = store.readQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: colorId }
                      // });
                      // data.product = updateColor;
                      // store.writeQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: colorId },
                      //   data
                      // });
                    }
                  }}
                >
                  {(updateFabricDesignStep, { data, loading, error }) => {
                    return (
                      <div>
                        <input
                          onChange={e => {
                            updateFabricDesignStep({
                              variables: {
                                id: stepId,
                                input: {
                                  stepSortOrder: parseInt(e.target.value, 10)
                                }
                              }
                            });
                          }}
                          placeholder="stepSortOrder"
                          type="text"
                          value={fabricDesignStep.stepSortOrder}
                          id="internalCstepSortOrderode"
                        />
                        <br />
                      </div>
                    );
                  }}
                </Mutation>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default FabricStepSettings;
