import React, { Component } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import AddProductVariant from './AddProductVariant';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '../../../common/Input';
import { Link } from 'gatsby';
import Option from './Option';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import Select from '../../../common/Select';
import { Mutation } from 'react-apollo';
import {
  ADMIN_UPDATE_PRODUCT_VARIANT,
  ADMIN_DELETE_PRODUCT_VARIANT
} from '../../gql/admin-mutations';

class ProductVariantRow extends Component {
  state = {
    variantOptionId: null
  };
  render() {
    const {
      defaultLanguage,
      productVariant,
      variantId,
      productId
    } = this.props;
    if (!productVariant) return null;
    let sortedVariantOptions = null;
    try {
      sortedVariantOptions = productVariant.variant.options.sort(function(
        a,
        b
      ) {
        if (
          getTextFromLanguage(a.title.text, defaultLanguage) <
          getTextFromLanguage(b.title.text, defaultLanguage)
        ) {
          return -1;
        }
        if (
          getTextFromLanguage(a.title.text, defaultLanguage) >
          getTextFromLanguage(b.title.text, defaultLanguage)
        ) {
          return 1;
        }
        return 0;
      });
    } catch (error) {
      console.error('pvo.error', error);
    }
    return (
      <Grid
        container
        spacing={1}
        style={{
          marginBottom: 16,
          borderRadius: '3px'
        }}
      >
        {/* {JSON.stringify(productVariant)} */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container alignItems="flex-end" spacing={1}>
            <Grid
              item
              data-cy={
                'Product-Variant-Item-' +
                getTextFromLanguage(
                  productVariant.variant.title.text,
                  defaultLanguage
                )
              }
              style={{
                marginTop: '1em'
              }}
            >
              {productVariant.variant && productVariant.variant.options ? (
                <Select
                  onChange={e => {
                    this.setState({
                      variantOptionId: e.target.value
                    });
                  }}
                  value={this.state.variantOptionId}
                  label={
                    productVariant.variant && productVariant.variant.title
                      ? getTextFromLanguage(
                          productVariant.variant.title.text,
                          defaultLanguage
                        )
                      : 'N/A'
                  }
                >
                  <option value={''}>Choose</option>
                  {productVariant.variant.options.map(variantOption => {
                    if (
                      productVariant.options &&
                      productVariant.options.includes(variantOption.id)
                    ) {
                      // alert('included')
                    } else {
                      return (
                        <option
                          key={variantOption.id}
                          // data-variant={JSON.stringify(pa.node)}
                          value={variantOption.id}
                        >
                          {getTextFromLanguage(
                            variantOption.title.text,
                            defaultLanguage
                          )}
                        </option>
                      );
                    }
                  })}
                </Select>
              ) : null}
            </Grid>
            <Grid item>
              <Mutation
                mutation={ADMIN_UPDATE_PRODUCT_VARIANT}
                variables={{ productId, variantId }}
              >
                {updateProductVariant => (
                  <Button
                    data-cy={
                      'Product-Variant-Add-btn-' +
                      getTextFromLanguage(
                        productVariant.variant.title.text,
                        defaultLanguage
                      )
                    }
                    onClick={async () => {
                      await updateProductVariant({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateProductVariant: {
                            ...productVariant,
                            options: productVariant.options
                              ? [
                                  ...productVariant.options,
                                  this.state.variantOptionId
                                ]
                              : [this.state.variantOptionId]
                          }
                        },
                        variables: {
                          input: {
                            options: productVariant.options
                              ? [
                                  ...productVariant.options,
                                  this.state.variantOptionId
                                ]
                              : [this.state.variantOptionId]
                          }
                        }
                      });
                      // window.location.reload();
                    }}
                    color="primary"
                    variant="contained"
                  >
                    Add
                  </Button>
                )}
              </Mutation>
            </Grid>
            <Grid item>
              <Grid container>
                {productVariant.options
                  ? productVariant.options.map(selectedOption => {
                      const optionObject = productVariant.variant.options.find(
                        pvo => pvo.id === selectedOption
                      );
                      try {
                        return (
                          <Grid
                            item
                            key={'pvo-' + selectedOption}
                            style={{
                              background: '#62082b',
                              color: '#FFFFFF',
                              height: 36,
                              borderRadius: 3,
                              display: 'flex',
                              alignItems: 'center',
                              padding: '0px 8px',
                              margin: 2
                            }}
                          >
                            {getTextFromLanguage(
                              optionObject.title.text,
                              defaultLanguage
                            )}
                          </Grid>
                        );
                      } catch (error) {}
                    })
                  : null}
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Mutation
                mutation={ADMIN_DELETE_PRODUCT_VARIANT}
                variables={{ productId, variantId }}
              >
                {deleteProductVariant => (
                  <Button
                    data-cy={
                      'Product-Variant-Delete-btn-' +
                      getTextFromLanguage(
                        productVariant.variant.title.text,
                        defaultLanguage
                      )
                    }
                    onClick={async () => {
                      await deleteProductVariant({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          deleteProductVariant: true
                        }
                      });
                      window.location.reload();
                    }}
                    color="primary"
                    variant="contained"
                  >
                    <DeleteIcon />
                  </Button>
                )}
              </Mutation>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ProductVariantRow;
