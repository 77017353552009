import React, { Component } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import Select from '../../../common/Select';
import { Mutation, Query } from 'react-apollo';
import {
  ADMIN_SHOP_VARIANTS,
  ADMIN_GET_PRODUCT
} from '../../gql/admin-queries';
import { ADMIN_CREATE_PRODUCT_VARIANT } from '../../gql/admin-mutations';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
class AddProductVariant extends Component {
  state = {
    variantId: null,
    variant: null
  };
  render() {
    const { defaultLanguage, productId, product, shopId } = this.props;
    return (
      <Mutation
        mutation={ADMIN_CREATE_PRODUCT_VARIANT}
        variables={{ productId }}
        update={(cache, { data: { createProductVariant } }) => {
          if (createProductVariant.id) {
            window.location.reload();
            // const data = cache.readQuery({
            //   query: ADMIN_GET_PRODUCT,
            //   variables: { id: productId }
            // });
            // const newProductVariant = {
            //   id: createProductVariant.id,
            //   __typename: 'ProductVariant',
            //   variant: {
            //     __typename: 'Variant',
            //     ...this.state.variant
            //   }
            // }
            // data.product.variants =
            //   data.product.variants ?
            //   [
            //     ...data.product.variants,
            //     newProductVariant
            //   ]
            //   :
            //   [
            //     newProductVariant
            //   ]
            // cache.writeQuery({
            //   query: ADMIN_GET_PRODUCT,
            //   variables: { id: productId },
            //   data
            // });
          }
        }}
      >
        {createProductVariant => (
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <Query query={ADMIN_SHOP_VARIANTS} variables={{ id: shopId }}>
                {({ loading, error, data: { shop } }) => {
                  return (
                    <Select
                      dataCy="Product-Variant-Select"
                      onChange={e => {
                        // console.log('e.target.options[e.target.selectedIndex].dataset', )
                        this.setState({
                          variantId: e.target.value,
                          variant: JSON.parse(
                            e.target.options[e.target.selectedIndex].dataset
                              .variant
                          )
                        });
                      }}
                      value={this.state.variantId}
                      label="Variants"
                    >
                      <option value={''}>Choose</option>
                      {shop && shop.variants && shop.variants.edges
                        ? shop.variants.edges.map(pa => {
                            if (
                              product &&
                              product.variants &&
                              product.variants.find(
                                pvf =>
                                  pvf &&
                                  pvf.variant &&
                                  pvf.variant.id === pa.node.id
                              ) != null
                            ) {
                            } else {
                              return (
                                <option
                                  data-variant={JSON.stringify(pa.node)}
                                  value={pa.node.id}
                                >
                                  {getTextFromLanguage(
                                    pa.node.title.text,
                                    defaultLanguage
                                  )}
                                </option>
                              );
                            }
                          })
                        : null}
                    </Select>
                  );
                }}
              </Query>
            </Grid>
            <Grid item>
              <Button
                data-cy="Product-Variant-Add-btn"
                onClick={() => {
                  createProductVariant({
                    variables: {
                      variantId: this.state.variantId
                    }
                  });
                }}
                variant="contained"
                color="primary"
                style={{}}
              >
                Add variant
              </Button>
            </Grid>
          </Grid>
        )}
      </Mutation>
    );
  }
}

export default AddProductVariant;
