import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { ADMIN_CREATE_CONTENT_FIELD } from '../gql/mutations'
import { GET_CONTENT_MODEL } from '../gql/queries'
import AddIcon from '@material-ui/icons/Add'
import { IconButton } from '@material-ui/core'

interface Props {
  modelId: string
  sortOrder: number
  editing: boolean
  handleSetNewFieldId(id: string | null): any
  position: 'top' | 'bottom'
}

const NewFieldButton: React.FC<Props> = props => {
  const { modelId, sortOrder, editing, handleSetNewFieldId, position } = props

  if (!editing) {
    return null
  }

  const [createContentField] = useMutation(ADMIN_CREATE_CONTENT_FIELD, {
    refetchQueries: [{ query: GET_CONTENT_MODEL, variables: { id: modelId } }],
    update: (cache, { data: { createContentField } }) => {
      const data: any = cache.readQuery({
        query: GET_CONTENT_MODEL,
        variables: { id: modelId }
      })

      let newArray = []

      if (createContentField.id) {
        newArray.push(
          ...data?.contentModel?.latestRevision?.fields,
          createContentField
        )
        handleSetNewFieldId(createContentField.id)
      }

      cache.writeQuery({
        query: GET_CONTENT_MODEL,
        variables: { id: modelId },
        data: {
          contentModel: {
            ...data?.contentModel,
            latestRevision: {
              ...data?.contentModel?.latestRevision,
              fields: newArray
            }
          }
        }
      })
    },
    onError: () => handleSetNewFieldId(null)
  })

  return (
    <div
      style={
        position === 'bottom'
          ? {
              position: 'absolute',
              textAlign: 'center',
              width: '100%',
              bottom: '-17px',
              zIndex: 2
            }
          : {
              position: 'absolute',
              textAlign: 'center',
              width: '100%',
              top: '-17px',
              zIndex: 2
            }
      }
    >
      <IconButton
        onClick={() => {
          if (position === 'bottom') {
            createContentField({
              variables: {
                modelId: modelId,
                revisionNo: 1,
                input: {
                  columns: 1,
                  isReusable: false,
                  backgroundColor: 'transparent',
                  sortOrder: sortOrder + 1
                }
              }
            })
          }
        }}
        style={{
          border: '1px solid #b23566',
          backgroundColor: '#b23566',
          borderRadius: 2,
          padding: '8px 22px',
          color: 'white',
          cursor: 'pointer',
          boxShadow: '0 10px 20px rgba(0,0,0,0.15), 0 6px 6px rgba(0,0,0,0.19)'
        }}
      >
        <AddIcon />
      </IconButton>
    </div>
  )
}

export default NewFieldButton
