import React, { Component } from 'react';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Input from '../../../common/Input';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { ADMIN_SEARCH_PRODUCT_PACKAGES } from '../../gql/admin-queries';
import { Query } from 'react-apollo';

import apolloClient from '../../gql/admin-apollo-config';
const uuidv4 = require('uuid/v4');
const omitDeep = require('omit-deep');

class AdminSearchProductPackage extends Component {
  state = {
    q: '',
    searchProductPackages: []
  };
  render() {
    const { defaultLanguage, onSelect, excludeIds, classes, text } = this.props;
    const { q, searchProductPackages, loading } = this.state;
    var searchResult = [];
    try {
      searchProductPackages.forEach(p => {
        if (p && (excludeIds && !excludeIds.includes(p.id))) {
          searchResult.push(
            <Grid item xs={12} onClick={() => onSelect(p)} key={'ps-' + p.id}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start'
                }}
              >
                {p.media ? (
                  <img
                    loading="lazy"
                    src={
                      p.media.find(image => image && image.isThumbnail === true)
                        .image
                    }
                    alt={p.no}
                    style={{
                      width: '40px',
                      height: '100%',
                      objectFit: 'contain',
                      marginRight: '1em'
                    }}
                  />
                ) : null}
                {getTextFromLanguage(_get(p, 'title.text', []), defaultLanguage)
                  ? getTextFromLanguage(
                      _get(p, 'title.text', []),
                      defaultLanguage
                    )
                  : getTextFromLanguage(_get(p, 'title.text', []), 'fr_FR')
                  ? getTextFromLanguage(_get(p, 'title.text', []), 'fr_FR')
                  : getTextFromLanguage(_get(p, 'title.text', []), 'nl_NL')
                  ? getTextFromLanguage(_get(p, 'title.text', []), 'nl_NL')
                  : null}
              </Button>
            </Grid>
          );
        }
      });
    } catch (error) {}
    return (
      <Grid
        container
        alignItems="flex-start"
        spacing={1}
        style={{ position: 'relative' }}
        onMouseLeave={searchResult === null}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Input
                placeholder={text || 'Enter package name'}
                value={q}
                onChange={async e => {
                  this.setState({
                    q: e.target.value
                  });
                  try {
                    if (e.target.value.length > 3) {
                      this.setState({
                        loading: true
                      });
                      const result = await apolloClient.query({
                        query: ADMIN_SEARCH_PRODUCT_PACKAGES,
                        variables: {
                          name: e.target.value
                        }
                      });
                      console.log('result', result);
                      this.setState({
                        loading: false,
                        searchProductPackages:
                          result.data.adminSearchProductPackages
                      });
                    }
                  } catch (error) {
                    console.error(e);
                    this.setState({
                      loading: false
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {searchResult && searchResult.length === 0 ? null : (
          <div>
            {loading ? (
              'Searching...'
            ) : (
              <Paper
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: 8,
                  maxHeight: 300,
                  overflowY: 'auto',
                  marginTop: 8,
                  width: '100%',
                  zIndex: 4
                }}
              >
                <Grid container spacing={2} style={{ padding: 24 }}>
                  {searchResult && searchResult.length > 0
                    ? searchResult
                    : q
                    ? 'No results'
                    : ''}
                </Grid>
              </Paper>
            )}
          </div>
        )}
      </Grid>
    );
  }
}

export default AdminSearchProductPackage;
