import React, { Component } from 'react'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Query, Mutation } from 'react-apollo'
import { ADMIN_GET_COLOR } from '../../gql/admin-queries'
import gql from 'graphql-tag'
import Button from '@material-ui/core/Button'
import {
  ADMIN_UPDATE_COLOR,
  ADMIN_DELETE_COLOR
} from '../../gql/admin-mutations'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '../../../common/Input'
import { navigate } from '@reach/router'
const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  }
})

class ColorDetail extends Component {
  state = {
    internalCode: '',
    showForm: false,
    code: '',
    showButton: true,
    id: this.props.colorId
  }
  render() {
    const { colorId, classes, shopId } = this.props
    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
        >
          <Typography
            data-cy="new-color-header"
            style={{
              fontSize: 28,
              fontWeight: 'medium',
              color: '#424242',
              flex: 1
            }}
          >
            Color
          </Typography>
          <Link
            to={`/admin/${shopId}/inventory/colors`}
            style={{ textDecoration: 'none' }}
          >
            <Button data-cy="color-back-btn" variant="outlined" color="primary">
              Back
            </Button>
          </Link>
        </Grid>

        <Query query={ADMIN_GET_COLOR} variables={{ id: colorId }}>
          {({ loading, error, data: { color } }) => {
            if (loading) return <CircularProgress />
            if (error) return <div>Error :(</div>
            return (
              <div>
                <Mutation
                  mutation={ADMIN_UPDATE_COLOR}
                  update={(cache, { data: { updateColor } }) => {
                    if (updateColor.id) {
                      // const data = store.readQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: colorId }
                      // });
                      // data.product = updateColor;
                      // store.writeQuery({
                      //   query: ADMIN_GET_COLOR,
                      //   variables: { id: colorId },
                      //   data
                      // });
                    }
                  }}
                >
                  {(updateColor, { data, loading, error }) => {
                    return (
                      <Grid container style={{ marginTop: 24 }}>
                        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                          <Grid
                            container
                            style={{ padding: '24px 24px 24px 0px' }}
                            spacing={1}
                            alignItems="center"
                          >
                            <Grid item xs={12}>
                              <Typography
                                data-cy="color-details-header"
                                variant="subtitle1"
                                style={{ marginRight: 16 }}
                              >
                                Color details
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                Provide information on your color
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                          <Paper style={{ padding: 24 }} elevation={0}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Input
                                  dataCy="color-style-number"
                                  label="Internal code"
                                  onChange={e => {
                                    updateColor({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateColor: {
                                          ...color,
                                          internalCode: e.target.value
                                        }
                                      },
                                      variables: {
                                        id: colorId,
                                        input: {
                                          internalCode: e.target.value
                                        }
                                      }
                                    })
                                  }}
                                  placeholder="Internal Code"
                                  type="text"
                                  value={color.internalCode}
                                  id="internalCode"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  dataCy="color-code"
                                  label="Code"
                                  onChange={e => {
                                    updateColor({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateColor: {
                                          ...color,
                                          code: e.target.value
                                        }
                                      },
                                      variables: {
                                        id: colorId,
                                        input: {
                                          code: e.target.value
                                        }
                                      }
                                    })
                                  }}
                                  placeholder="Code"
                                  type="text"
                                  value={color.CMYK ? color.code : ''}
                                  id="code"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  dataCy="color-hex"
                                  label="HEX"
                                  onChange={e => {
                                    updateColor({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateColor: {
                                          ...color,
                                          hex: e.target.value
                                        }
                                      },
                                      variables: {
                                        id: colorId,
                                        input: {
                                          hex: e.target.value
                                        }
                                      }
                                    })
                                  }}
                                  placeholder="Hex"
                                  type="text"
                                  value={color.hex}
                                  id="hex"
                                />
                                <span
                                  style={{
                                    width: '10px',
                                    height: '10px',
                                    background: color.hex
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  dataCy="color-name"
                                  label="Name"
                                  onChange={e => {
                                    updateColor({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateColor: {
                                          ...color,
                                          name: e.target.value
                                        }
                                      },
                                      variables: {
                                        id: colorId,
                                        input: {
                                          name: e.target.value
                                        }
                                      }
                                    })
                                  }}
                                  placeholder="Name"
                                  type="text"
                                  value={color.name}
                                  id="name"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  dataCy="color-internal-name"
                                  label="Internal name"
                                  onChange={e => {
                                    updateColor({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateColor: {
                                          ...color,
                                          internalName: e.target.value
                                        }
                                      },
                                      variables: {
                                        id: colorId,
                                        input: {
                                          internalName: e.target.value
                                        }
                                      }
                                    })
                                  }}
                                  placeholder="Internal name"
                                  type="text"
                                  value={color.internalName}
                                  id="internalName"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  dataCy="color-pantone"
                                  label="Pantone"
                                  onChange={e => {
                                    updateColor({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateColor: {
                                          ...color,
                                          pantone: e.target.value
                                        }
                                      },
                                      variables: {
                                        id: colorId,
                                        input: {
                                          pantone: e.target.value
                                        }
                                      }
                                    })
                                  }}
                                  placeholder="Pantone"
                                  type="text"
                                  value={color.pantone}
                                  id="pantone"
                                />
                              </Grid>
                              <h2>CMYK</h2>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Input
                                    dataCy="color-pantone"
                                    label="C"
                                    onChange={e => {
                                      updateColor({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateColor: {
                                            ...color,
                                            CMYK: {
                                              ...color.CMYK,
                                              C: e.target.value
                                            }
                                          }
                                        },
                                        variables: {
                                          id: colorId,
                                          input: {
                                            CMYK: {
                                              C: e.target.value
                                            }
                                          }
                                        }
                                      })
                                    }}
                                    placeholder="C"
                                    type="text"
                                    value={
                                      color.CMYK
                                        ? color.CMYK
                                          ? color.CMYK.C
                                          : ''
                                        : ''
                                    }
                                    id="cmyk-c"
                                  />
                                  <Input
                                    label="Pantone"
                                    onChange={e => {
                                      updateColor({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateColor: {
                                            ...color,
                                            CMYK: {
                                              ...color.CMYK,
                                              M: e.target.value
                                            }
                                          }
                                        },
                                        variables: {
                                          id: colorId,
                                          input: {
                                            CMYK: {
                                              M: e.target.value
                                            }
                                          }
                                        }
                                      })
                                    }}
                                    placeholder="M"
                                    type="text"
                                    value={color.CMYK ? color.CMYK.M : ''}
                                    id="cmyk-m"
                                  />
                                  <Input
                                    label="Y"
                                    onChange={e => {
                                      updateColor({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateColor: {
                                            ...color,
                                            CMYK: {
                                              ...color.CMYK,
                                              Y: e.target.value
                                            }
                                          }
                                        },
                                        variables: {
                                          id: colorId,
                                          input: {
                                            CMYK: {
                                              Y: e.target.value
                                            }
                                          }
                                        }
                                      })
                                    }}
                                    placeholder="Y"
                                    type="text"
                                    value={color.CMYK ? color.CMYK.Y : ''}
                                    id="cmyk-y"
                                  />
                                  <Input
                                    label="K"
                                    onChange={e => {
                                      updateColor({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateColor: {
                                            ...color,
                                            CMYK: {
                                              ...color.CMYK,
                                              K: e.target.value
                                            }
                                          }
                                        },
                                        variables: {
                                          id: colorId,
                                          input: {
                                            CMYK: {
                                              K: e.target.value
                                            }
                                          }
                                        }
                                      })
                                    }}
                                    placeholder="K"
                                    type="text"
                                    value={color.CMYK ? color.CMYK.K : ''}
                                    id="cmyk-k"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    )
                  }}
                </Mutation>
                <Mutation
                  mutation={ADMIN_DELETE_COLOR}
                  update={(cache, { data: { updateColor } }) => {
                    // const data = store.readQuery({
                    //   query: ADMIN_GET_COLOR,
                    //   variables: { id: colorId }
                    // });
                    // data.product = updateColor;
                    // store.writeQuery({
                    //   query: ADMIN_GET_COLOR,
                    //   variables: { id: colorId },
                    //   data
                    // });
                  }}
                >
                  {(deleteColor, { data, loading, error }) => {
                    return (
                      <Button
                        data-cy="color-delete-btn"
                        onClick={async () => {
                          await deleteColor({
                            variables: {
                              id: color.id
                            }
                          })
                          navigate(`/admin/${shopId}/inventory/colors`)
                        }}
                        variant="outlined"
                        color="primary"
                      >
                        Delete
                      </Button>
                    )
                  }}
                </Mutation>
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withStyles(styles)(ColorDetail)
