import React from 'react'
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
  Grid
} from '@material-ui/core'

import MenuItem from './MenuItem'
import ModuleRender from '../module/ModuleRender'
import {
  getElementsFirstLevel,
  getUrlDefaultPrefix,
  recursiveGetSubItems,
  getItemStyling
} from '../../../helper/menuHelper'

const styles = (theme: Theme) =>
  createStyles({
    collectionMobile: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        fontSize: '1.2rem',
        fontWeight: 300,
        justifyContent: 'center',
        padding: 12
      }
    },
    mobileButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  })

interface Props extends WithStyles<typeof styles> {
  currentUser: any
  elements: any[]
  isEditMode?: boolean
  language: string
  languages?: [string]
  linkColor: string
  shop: any
  styling: any
  urlPrefix: string | null
  showHomeMobile?: boolean
  teamstore?: any
  slugs: any[]
}

const Menu: React.FunctionComponent<Props> = props => {
  const {
    classes,
    elements,
    isEditMode,
    languages,
    language,
    linkColor,
    urlPrefix,
    styling,
    shop,
    currentUser,
    slugs,
    teamstore
  } = props

  const elementsFirstLevel = getElementsFirstLevel(elements)

  return (
    <>
      {elementsFirstLevel.map((o, index) => (
        <Grid item={true} key={o.id} className={classes.mobileButton}>
          {!o.moduleId ? (
            <MenuItem
              teamstore={teamstore}
              urlPrefix={urlPrefix ? urlPrefix : getUrlDefaultPrefix()}
              currentUser={currentUser}
              language={language}
              linkColor={linkColor}
              isEditMode={isEditMode}
              item={o}
              shop={shop}
              styling={getItemStyling(styling, o.styling)}
              subElements={recursiveGetSubItems(elements, o.id, 1)}
              index={index}
            />
          ) : (
            <span
              style={{ display: 'inline-flex' }}
              className={classes.mobileButton}
            >
              <ModuleRender
                teamstore={teamstore}
                isEditMode={isEditMode}
                slugs={slugs}
                languages={languages}
                language={language}
                linkColor={linkColor}
                shop={shop}
                contentDetail={o.module ? o.module.contentDetail : null}
                urlPrefix={urlPrefix ? urlPrefix : getUrlDefaultPrefix()}
                alignment={o.alignment}
                styling={getItemStyling(styling, o.styling)}
              />
            </span>
          )}
        </Grid>
      ))}
    </>
  )
}

export default withStyles(styles, { withTheme: true })(Menu)
