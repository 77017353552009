import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons'
import Divider from '@material-ui/core/Divider'
import SliderInput from './SliderInput'
import Select from '../../common/Select'
import Input from '../../common/Input'

import * as template_control from '../template_control'

import Camouflage from '../TEMPLATE_ASSET/AOP/Camouflage.png'
import UV_Grid_Sm from '../TEMPLATE_ASSET/AOP/UV_Grid_Sm.jpg'

import aop1 from '../TEMPLATE_ASSET/AOP/aop (1).jpg'
import aop2 from '../TEMPLATE_ASSET/AOP/aop (1).png'
import aop3 from '../TEMPLATE_ASSET/AOP/aop (2).jpg'
import aop4 from '../TEMPLATE_ASSET/AOP/aop (2).png'
import aop5 from '../TEMPLATE_ASSET/AOP/aop (3).jpg'
import aop6 from '../TEMPLATE_ASSET/AOP/aop (4).jpg'

import * as tools from '../tools/tools'
import flagsList from '../TEMPLATE_ASSET/flags.json'

import { API_STAGE, MEDIA_CDN_URL } from '~/brikl-config'
import {
  appendToFilename,
  createPrintDesignRevisionData
} from '../tools/Functions/save'
import shortid from 'shortid'

let SHOP_ID = tools.getQueryStringValue('s')

const styles = theme => ({
  colorSwatch: {
    background: '#BBBBBB',
    width: 27,
    height: 27,
    border: '3px solid #BBBBBB',
    borderRadius: '100%',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  printThumbnail: {
    background: '#BBBBBB',
    width: 46,
    height: 46,
    border: '3px solid #BBBBBB',
    borderRadius: '6px',
    margin: 2,
    '&:hover': {
      border: '3px solid #FECC00',
      cursor: 'pointer'
    }
  },
  slider: {
    width: '100%'
  },
  btn: {
    minHeight: 12,
    minWidth: 12,
    height: 12,
    width: 12
  },
  track: {
    backgroundColor: '#000'
  },
  thumb: {
    backgroundColor: '#000'
  }
})

class AopStep extends Component {
  constructor(props) {
    super(props)

    this.aopList = []

    this.state = {
      scale: 1,
      width: 0,
      height: 0,
      angle: 0,
      offsetX: 0,
      offsetY: 0,
      repeat: true,
      SPECIAL_ELM: false
    }

    if (!SHOP_ID) {
      SHOP_ID = this.props.DesignRoot.SHOP_ID
    }

    // USE FOR LOAD DEFAULT URL (PLEASE ADD HERE IF HAVE MORE DEFAULT AOP FILE)
    this.defaultAopList = {}

    // USE FOR LOAD DEFAULT URL

    this.default_aop()
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot
    DesignRoot.AopStep = this
    DesignRoot.setState({ update: true })
    // PROVIDE COMPONENT TO MAIN
  }

  handleTransform(type, e) {
    try {
      // statements
      e.persist()
      logger.log('handleTransform', type, e)

      var actualValue = e.target.value

      this.setState({ [type]: actualValue })
      this._transform(type, actualValue)
    } catch (e) {
      // statements
      logger.error('AopStep.handleTransform.error', e)
    }
  }

  async handleChangeSVGElementAOP(
    src,
    FILE_DATA,
    productDesignPrintId,
    designAttributes
  ) {
    try {
      // statements
      const { DesignRoot } = this.props
      var selectionObject = DesignRoot.Selection.state.selectionObject

      if (!selectionObject) {
        alert('Please select element.')
        return
      }

      // Default

      var transform = {
        scaleX: this.state.scale,
        scaleY: this.state.scale,
        angle: this.state.angle,
        repeat: this.state.repeat,
        offsetX: this.state.offsetX,
        offsetY: this.state.offsetY
      }

      // Default

      // AOP Library designAttributes

      if (designAttributes) {
        // designAttributes: {scaleX: 1, scaleY: 2, offsetX: 3, offsetY: 4, __typename: "DesignAttributes"}

        transform.scaleX = designAttributes.scaleX
          ? designAttributes.scaleX
          : transform.scaleX
        transform.scaleY = designAttributes.scaleY
          ? designAttributes.scaleY
          : transform.scaleY
        transform.offsetX = designAttributes.offsetX
          ? designAttributes.offsetX
          : transform.offsetX
        transform.offsetY = designAttributes.offsetY
          ? designAttributes.offsetY
          : transform.offsetY
      }

      // AOP Library designAttributes

      transform.scaleX = 0.2
      transform.scaleY = 0.2

      if (selectionObject._OBJECT_TYPE === 'SVG') {
        var baseElement3DName = selectionObject._USER_DATA.element3DName
        var baseELM = baseElement3DName.split('-')

        if (baseELM[0]) {
          var baseELMName = baseELM[0]
          var allSVG = DesignRoot.fabric_function.getObjectByType('SVG')

          for (var i = 0; i < allSVG.length; i++) {
            var element3DName = allSVG[i]._USER_DATA.element3DName

            if (
              element3DName &&
              element3DName.indexOf(baseELMName + '-') !== -1
            ) {
              DesignRoot.change_color.changeAOPSelectedSVGELM(
                src,
                allSVG[i],
                transform,
                true,
                productDesignPrintId
              )
            }
          }
        } else {
          DesignRoot.change_color.changeAOPSelectedSVGELM(
            src,
            selectionObject,
            transform,
            true,
            productDesignPrintId
          )
        }
      } else if (selectionObject._OBJECT_TYPE === 'SVG_COLORZONE') {
        var colorZoneIndex = selectionObject._USER_DATA.colorZoneIndex
        var allSVGColorZone = DesignRoot.fabric_function.getObjectByType(
          'SVG_COLORZONE'
        )

        for (var i = 0; i < allSVGColorZone.length; i++) {
          if (allSVGColorZone[i]._USER_DATA.colorZoneIndex === colorZoneIndex) {
            DesignRoot.change_color.changeAOPSelectedSVGELM(
              src,
              allSVGColorZone[i],
              transform,
              true,
              productDesignPrintId
            )
          }
        }
      }
      //
      // if (
      //   DesignRoot._S3_AOP_SRC[selectionObject.id] === undefined &&
      //   FILE_DATA !== null
      // ) {
      //   // UPLOAD TO S3 IF USE AOP
      //
      // }
    } catch (e) {
      // statements
      logger.error('AopStep.handleChangeSVGElementAOP.error', e)
    }
  }

  handleSliderChange(type, SAVE, value) {
    try {
      // statements

      var actualValue = value
      var factor = type === 'scale' ? 0.01 : 1

      if (value === '+') {
        actualValue = this.state[type] + factor
      } else if (value === '-') {
        actualValue = this.state[type] - factor
      }

      this.setState({ [type]: actualValue })
      this._transform(type, actualValue, SAVE)
    } catch (e) {
      // statements
      logger.error('AopStep.handleSliderChange.error', e)
    }
  }

  _transform(type, actualValue, SAVE) {
    try {
      logger.log('_transform', type, actualValue)
      // statements
      const { DesignRoot } = this.props
      var selectionObject = DesignRoot.Selection.state.selectionObject

      logger.log('selectionObject', selectionObject)

      if (selectionObject?._USER_DATA?.fillType === 'AOP') {
        if (selectionObject._OBJECT_TYPE === 'SVG') {
          var baseElement3DName = selectionObject._USER_DATA.element3DName
          var baseELM = baseElement3DName.split('-')

          if (baseELM[0]) {
            var baseELMName = baseELM[0]
            var allSVG = DesignRoot.fabric_function.getObjectByType('SVG')

            for (var i = 0; i < allSVG.length; i++) {
              var element3DName = allSVG[i]._USER_DATA.element3DName

              if (
                element3DName &&
                element3DName.indexOf(baseELMName + '-') !== -1
              ) {
                if (
                  allSVG[i] &&
                  allSVG[i]._USER_DATA.fillType &&
                  allSVG[i]._USER_DATA.fillType === 'AOP'
                ) {
                  var img = allSVG[i].fill.img

                  allSVG[i].set({
                    fill: DesignRoot.change_color.updateFillPattern(
                      img,
                      type,
                      actualValue,
                      allSVG[i],
                      null,
                      SAVE
                    )
                  })
                }
              }
            }

            DesignRoot.canvas.requestRenderAll()
          } else {
            var img = selectionObject.fill.img
            selectionObject.set({
              fill: DesignRoot.change_color.updateFillPattern(
                img,
                type,
                actualValue,
                selectionObject,
                null,
                SAVE
              )
            })
            selectionObject.canvas.requestRenderAll()
          }
        } else if (selectionObject._OBJECT_TYPE === 'SVG_COLORZONE') {
          var colorZoneIndex = selectionObject._USER_DATA.colorZoneIndex
          var allSVGColorZone = DesignRoot.fabric_function.getObjectByType(
            'SVG_COLORZONE'
          )

          for (var i = 0; i < allSVGColorZone.length; i++) {
            if (
              allSVGColorZone[i]._USER_DATA.colorZoneIndex === colorZoneIndex
            ) {
              if (
                allSVGColorZone[i] &&
                allSVGColorZone[i]._USER_DATA.fillType &&
                allSVGColorZone[i]._USER_DATA.fillType === 'AOP'
              ) {
                var img = allSVGColorZone[i].fill.img
                allSVGColorZone[i].set({
                  fill: DesignRoot.change_color.updateFillPattern(
                    img,
                    type,
                    actualValue,
                    allSVGColorZone[i],
                    null,
                    SAVE
                  )
                })
              }
            }
          }

          DesignRoot.canvas.renderAll()
        }
      }
    } catch (e) {
      // statements
      logger.error('AopStep._transform.error', e)
    }
  }

  async uploadAOP(domId) {
    try {
      // statements
      var DesignRoot = this.props.DesignRoot

      var file_upload = document.getElementById(domId)
      if (file_upload.files[0]) {
        var FILE_DATA = file_upload.files[0]
      } else {
        alert('PLEASE SELECT FILE')
        return
      }

      var file_name = FILE_DATA.name
      const gen_filename = appendToFilename(file_name, '-' + shortid.generate())
      var file_size = FILE_DATA.size
      var file_type = FILE_DATA.type

      const acceptTypesArr = file_upload
        ? file_upload
            .getAttribute('accept')
            .split(',')
            .map(elem => elem.trim())
        : []

      if (acceptTypesArr.length > 0 && !acceptTypesArr.includes(file_type)) {
        alert('File type can be jpg, jpeg, png, svg, gif or ai.')
        return
      }

      const self = this

      if (
        file_type === 'application/postscript' ||
        file_type === 'application/pdf'
      ) {
        DesignRoot.Loading.activeLoading(true)

        let retUrl = new Promise((resolve, reject) => {
          resolve(
            tools.uploadFileWithPresignedUrl(
              FILE_DATA,
              gen_filename,
              DesignRoot.ownedByOrganisationId,
              'PRODUCTDESIGN_PRINT',
              DesignRoot.isDashboard
            )
          )
        })

        retUrl.then(async data => {
          let svgUrl = data
            .replace(/\.ai$/i, '.svg')
            .replace(/\.pdf$/i, '.svg')
            .replace(/^http.+brikl-ecommerce/i, MEDIA_CDN_URL)

          let tryLoading = new Promise((resolve, reject) => {
            let interval = setInterval(() => {
              fetch(svgUrl).then(r => {
                if (r.status === 200 || r.status === 304) {
                  r.blob().then(fileData => {
                    if (fileData) {
                      clearInterval(interval)
                      resolve(fileData)
                    }
                  })
                }
              })
            }, 2000)
          })

          tryLoading.then(fileData => {
            self.setState({ s3Url: svgUrl })

            var file_name = gen_filename
              .replace(/\.ai$/i, '.svg')
              .replace(/\.pdf$/i, '.svg')
            var file_size = fileData.size
            var file_type = 'image/svg+xml'

            DesignRoot.Loading.activeLoading(false)
            tools.getBase64(fileData).then(async data => {
              const response = await createPrintDesignRevisionData(
                DesignRoot.productDesignId,
                DesignRoot.ownedByOrganisationId,
                1,
                {
                  src: file_name
                },
                DesignRoot.isDashboard
              )
              const productDesignPrintId =
                response.data.createPrintDesignRevision.id

              self.aopList.push({
                file_name: file_name,
                file_size: file_size,
                file_type: file_type,
                FILE_DATA: fileData,
                base64: data,
                productDesignPrintId: productDesignPrintId
              })
              self.setState({ render: true })
            })
          })
        })
      } else {
        tools.getBase64(FILE_DATA).then(async data => {
          tools.uploadFileWithPresignedUrl(
            FILE_DATA,
            gen_filename,
            DesignRoot.ownedByOrganisationId,
            'PRODUCTDESIGN_PRINT',
            DesignRoot.isDashboard
          )

          const response = await createPrintDesignRevisionData(
            DesignRoot.productDesignId,
            DesignRoot.ownedByOrganisationId,
            1,
            {
              src: gen_filename
            },
            DesignRoot.isDashboard
          )
          const productDesignPrintId =
            response.data.createPrintDesignRevision.id

          logger.log('***print upload', productDesignPrintId)
          self.aopList.push({
            file_name: gen_filename,
            file_size: file_size,
            file_type: file_type,
            FILE_DATA: FILE_DATA,
            base64: data,
            productDesignPrintId: productDesignPrintId
          })
          self.setState({ render: true })
        })
      }
    } catch (e) {
      // statements
      logger.error('AopStep.uploadAOP.error', e)
    }
  }

  renderAOPList() {
    try {
      var DesignRoot = this.props.DesignRoot

      if (true) {
        var productDesignPrintId = null
        var selectionObject = DesignRoot.Selection.state.selectionObject
        if (selectionObject?._USER_DATA?.fillType === 'AOP') {
          productDesignPrintId = selectionObject._USER_DATA.productDesignPrintId
        }

        var borderColor = '#fff'
        var domRender = []
        for (var i = 0; i < this.aopList.length; i++) {
          if (productDesignPrintId === this.aopList[i].productDesignPrintId) {
            borderColor = '#f00'
          } else {
            borderColor = '#fff'
          }

          domRender.push(
            <Grid
              item
              xs={4}
              key={'AOP_LIST_' + this.aopList[i].productDesignPrintId}
            >
              <img
                data-cy="aop-step-uploaded-img"
                src={this.aopList[i].base64}
                style={{
                  width: '100%',
                  height: '80px',
                  objectFit: 'contain',
                  margin: '3px',
                  cursor: 'pointer',
                  border: '2px dashed ' + borderColor
                }}
                onClick={this.handleChangeSVGElementAOP.bind(
                  this,
                  this.aopList[i].base64,
                  this.aopList[i].FILE_DATA,
                  this.aopList[i].productDesignPrintId,
                  this.aopList[i].designAttributes
                )}
              />
            </Grid>
          )
        }

        if (DesignRoot.loadData && DesignRoot.loadData.printDesigns) {
          for (var i = 0; i < DesignRoot.loadData.printDesigns.length; i++) {
            if (
              productDesignPrintId === DesignRoot.loadData.printDesigns[i].id
            ) {
              borderColor = '#f00'
            } else {
              borderColor = '#fff'
            }

            domRender.push(
              <Grid
                item
                xs={4}
                key={'AOP_LIST_' + DesignRoot.loadData.printDesigns[i].id}
              >
                <img
                  src={DesignRoot.loadData.printDesigns[i].src}
                  style={{
                    width: '100%',
                    height: '80px',
                    objectFit: 'contain',
                    margin: '3px',
                    border: '2px dashed ' + borderColor,
                    cursor: 'pointer'
                  }}
                  onClick={this.handleChangeSVGElementAOP.bind(
                    this,
                    DesignRoot.loadData.printDesigns[i].src,
                    null,
                    DesignRoot.loadData.printDesigns[i].id
                  )}
                />
              </Grid>
            )
          }
        }

        return domRender
      }
    } catch (e) {
      // statements
      logger.error('AopStep.renderAOPList.error', e)
    }
  }

  specialAOPList() {
    try {
      const { classes, DesignRoot, FillStep } = this.props
      const { SPECIAL_ELM } = this.state

      if (DesignRoot._DEFAULT_ITEM === false) {
        return null
      }

      if (SPECIAL_ELM === false) {
        return (
          <div
            style={{
              textAlign: 'center',
              fontStyle: 'italic',
              paddingTop: '10px'
            }}
          >
            This element not available for custom printing.
          </div>
        )
      }

      console.warn('flagsList', flagsList)

      var flagsDom = []
      for (var i = 0; i < flagsList.length; i++) {
        var flag = '/Flags/' + flagsList[i].imageSrc
        var flagThumb = '/Flags/thumb/' + flagsList[i].imageSrc
        flagsDom.push(
          <Grid item xs={4} key={'flags_' + i}>
            <img
              src={flagThumb}
              style={{ width: '50px', cursor: 'pointer' }}
              onClick={this.handleChangeSVGElementAOP.bind(this, flag, null)}
            />
          </Grid>
        )
      }

      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          style={{ margin: 0, marginTop: 16 }}
        >
          {flagsDom}
        </Grid>
      )
    } catch (e) {
      logger.error('AopStep.renderAOPList.error', e)
    }
  }

  transformAOP() {
    try {
      const { DesignRoot } = this.props

      const {
        scale,
        width,
        height,
        angle,
        offsetX,
        offsetY,
        repeat,
        SPECIAL_ELM
      } = this.state

      // if (scale === 0) {
      //   return null;
      // }

      return (
        <Grid container>
          {DesignRoot._ACTIVE_PRINT_REPEAT === true ? (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ textAlign: 'center', marginTop: 20 }}
              >
                Repeat setting
              </Typography>
            </Grid>
          ) : null}

          {DesignRoot._ACTIVE_PRINT_REPEAT === true ? (
            <Grid container justify="center">
              <Grid item xs={12}>
                <Select
                  value={repeat}
                  onChange={this.handleTransform.bind(this, 'repeat')}
                >
                  <option value={true}>Repeat</option>
                  <option value={false}>Non-repeat</option>
                </Select>
              </Grid>
            </Grid>
          ) : null}

          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
            style={{ margin: 0, marginTop: 16 }}
          >
            <Grid item xs={12} data-cy="aop-step-scale">
              {DesignRoot._ACTIVE_PRINT_SCALE === true ? (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={10}>
                    <SliderInput
                      aria-labelledby="label"
                      title="Scale"
                      value={scale}
                      valueNum={scale}
                      step={0.01}
                      min={0.01}
                      max={5}
                      input={false}
                      onChange={(e, value) =>
                        this.handleSliderChange('scale', false, value)
                      }
                      onChangeCommitted={(e, value) =>
                        this.handleSliderChange('scale', true, value)
                      }
                      valueLabelDisplay="auto"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Grid
                      container
                      direction={'column'}
                      style={{ marginTop: 25 }}
                      data-cy="aop-step-button-scale"
                    >
                      <Button
                        style={{ marginBottom: 2, minWidth: 0 }}
                        variant="outlined"
                        onClick={() =>
                          this.handleSliderChange('scale', true, '+')
                        } //insert scale logic
                      >
                        <KeyboardArrowUp style={{ fontSize: 14 }} />
                      </Button>
                      <Button
                        style={{ marginBottom: 2, minWidth: 0 }}
                        variant="outlined"
                        onClick={() =>
                          this.handleSliderChange('scale', true, '-')
                        } //insert scale logic
                      >
                        <KeyboardArrowDown style={{ fontSize: 14 }} />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>

            <Grid item xs={12} data-cy="aop-step-angle">
              {DesignRoot._ACTIVE_PRINT_ROTATION === true ? (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs={10}>
                    <SliderInput
                      aria-labelledby="angle"
                      title="Rotate"
                      value={angle}
                      valueNum={angle}
                      step={1}
                      min={-180}
                      max={180}
                      input={false}
                      onChange={(e, value) =>
                        this.handleSliderChange('angle', false, value)
                      }
                      onChangeCommitted={(e, value) =>
                        this.handleSliderChange('angle', true, value)
                      }
                      valueLabelDisplay="auto"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Grid
                      container
                      direction={'column'}
                      style={{ marginTop: 25 }}
                      data-cy="aop-step-button-rotate"
                    >
                      <Button
                        style={{ marginBottom: 2, minWidth: 0 }}
                        variant="outlined"
                        onClick={() =>
                          this.handleSliderChange('angle', true, '+')
                        } //insert scale logic
                      >
                        <KeyboardArrowUp style={{ fontSize: 14 }} />
                      </Button>
                      <Button
                        style={{ marginBottom: 2, minWidth: 0 }}
                        variant="outlined"
                        onClick={() =>
                          this.handleSliderChange('angle', true, '-')
                        } //insert scale logic
                      >
                        <KeyboardArrowDown style={{ fontSize: 14 }} />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>

            <Grid item xs={12} data-cy="aop-step-xaxis">
              <SliderInput
                aria-labelledby="label"
                title="Move Horizontally"
                value={offsetX}
                valueNum={offsetX.toFixed(2)}
                step={1}
                min={-1000}
                max={1000}
                input={false}
                onChange={(e, value) =>
                  this.handleSliderChange('offsetX', false, value)
                }
                onChangeCommitted={(e, value) =>
                  this.handleSliderChange('offsetX', true, value)
                }
                valueLabelDisplay="auto"
              />
            </Grid>

            <Grid item xs={12} data-cy="aop-step-yaxis">
              <SliderInput
                title="Move Vertically"
                aria-labelledby="label"
                value={offsetY}
                valueNum={offsetY.toFixed(2)}
                step={1}
                min={-1000}
                max={1000}
                input={false}
                onChange={(e, value) =>
                  this.handleSliderChange('offsetY', false, value)
                }
                onChangeCommitted={(e, value) =>
                  this.handleSliderChange('offsetY', true, value)
                }
                valueLabelDisplay="auto"
              />
            </Grid>
          </Grid>
        </Grid>
      )
    } catch (e) {
      logger.error('AopStep.transformAOP.error', e)
    }
  }

  uploadAOPBTN() {
    try {
      const DesignRoot = this.props.DesignRoot

      if (DesignRoot._SVG_BOUNDING === true) {
        return null
      }

      return (
        <Grid
          item
          style={{ flex: 1, padding: '5px 8px 0px 0px', textAlign: 'center' }}
        >
          <input
            type="file"
            id="file-upload-aop"
            style={{ display: 'none' }}
            accept="image/png, image/jpeg, application/postscript,application/pdf, image/svg+xml, image/gif"
            onChange={this.uploadAOP.bind(this, 'file-upload-aop')}
          />
          <label htmlFor="file-upload-aop">
            <Grid container justify="center">
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#171717',
                    color: '#FFFFFF'
                  }}
                  component="span"
                >
                  Upload
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  subtitle1
                  style={{
                    fontSize: 11,
                    color: '#666666',
                    textAlign: 'center',
                    paddingTop: 10
                  }}
                >
                  JPG, PNG, SVG, PDF or AI only.
                </Typography>
                <Typography
                  subtitle1
                  style={{
                    fontSize: 11,
                    color: '#666666',
                    textAlign: 'center',
                    paddingTop: 10
                  }}
                >
                  Max. file upload is 20MB.
                </Typography>
              </Grid>
            </Grid>
          </label>
        </Grid>
      )
    } catch (e) {
      logger.error('AopStep.uploadAOPBTN.error', e)
    }
  }

  async default_aop() {
    try {
      // DEFAULT AOP

      const { DesignRoot } = this.props

      try {
        logger.log('AOP shopConfiguration', DesignRoot.props.shopConfiguration)

        DesignRoot.props.shopConfiguration?.designStudioSettings?.designSteps
          ?.find(designStep => {
            return designStep.type === 'COLOR'
          })
          ?.printLibrary?.forEach(async printLibraryItem => {
            this.defaultAopList[printLibraryItem.id] = printLibraryItem.imageUrl

            const base64 = await tools._get_image_base64(
              printLibraryItem.imageUrl
            )
            this.aopList.push({
              file_name: printLibraryItem.id,
              file_size: null,
              file_type: null,
              FILE_DATA: null,
              base64: base64?.base64,
              productDesignPrintId: printLibraryItem.id,
              designAttributes: printLibraryItem.designAttributes
            })
          })
      } catch (error) {
        console.error('printLibrary', error)
      }

      // DEFAULT AOP
    } catch (e) {
      logger.error('AopStep.default_aop.error', e)
    }
  }

  render() {
    const { classes, DesignRoot, FillStep } = this.props
    const {
      scale,
      width,
      height,
      angle,
      offsetX,
      offsetY,
      repeat,
      SPECIAL_ELM
    } = this.state

    var printWording = 'Select a print :'
    if (DesignRoot._SVG_BOUNDING === true) {
      printWording = 'Select a flag :'
    }

    var displayAOP = ''
    if (DesignRoot.Selection !== null) {
      var selectionObject = DesignRoot.Selection.state.selectionObject
      if (!selectionObject) {
        // displayAOP = 'none'
      } else if (
        !(
          selectionObject._OBJECT_TYPE === 'SVG' ||
          selectionObject._OBJECT_TYPE === 'SVG_COLORZONE'
        )
      ) {
        // displayAOP = 'none'
      }
    }

    return (
      <div
        className="AopStep"
        style={{ minHeight: 300, paddingBottom: 24, display: displayAOP }}
      >
        {DesignRoot._SVG_BOUNDING === false && (
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ margin: 0 }}
          >
            <Divider
              light
              style={{ width: 180, marginTop: 40, marginBottom: 40 }}
            />
            <Typography variant="h5" style={{ textAlign: 'center' }}>
              Print Fill
            </Typography>
          </Grid>
        )}

        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={1}
          style={{ margin: 0, marginTop: 16 }}
        >
          <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
            {printWording}
          </Typography>

          {DesignRoot._SVG_BOUNDING === true && (
            <Grid
              container
              style={{ margin: 0, marginTop: 20 }}
              justify="center"
            >
              <Grid item xs={12}>
                {FillStep.genSVGList()}{' '}
              </Grid>
            </Grid>
          )}

          <Grid container style={{ padding: '16px 0px' }}>
            {this.renderAOPList()}
          </Grid>
          {this.specialAOPList()}
          {DesignRoot._ACTIVE_PRINT_UPLOAD === true
            ? this.uploadAOPBTN()
            : null}
        </Grid>

        {this.transformAOP()}

        {/*<div>
          WIDTH :
          <input
            type="number"
            step="1"
            value={width}
            onChange={this.handleTransform.bind(this, 'width')}
          />
        </div>

        <div>
          HEIGHT :
          <input
            type="number"
            step="1"
            value={height}
            onChange={this.handleTransform.bind(this, 'height')}
          />
        </div>*/}

        <svg id="svgServe" style={{ display: 'none' }}></svg>
      </div>
    )
  }
}

AopStep.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AopStep)
