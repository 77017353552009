import { ApolloConsumer, Query } from 'react-apollo'
import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import * as material_ui from '../tools/material_ui'
import _get from 'lodash/get'
import { getTextFromLanguage } from '~/src/helper/languageHelper'
import Loadable from 'react-loadable'
import LoadingComponent from '../../common/LoadingComponent'
import ProductCard from '../../store/product/gallery/ProductCard'
import { transformDataForLoad } from '../tools/Functions/load'
import { API_STAGE } from '~/brikl-config'
import * as tools from '../tools/tools'

import { CUSTOM_PRODUCTS_QUERY, PRODUCT_DESIGN_QUERY } from '../graphql/queries'

let SHOP_ID = tools.getQueryStringValue('s')

const DesignTemplate = Loadable({
  loader: () => import('../DesignTemplate'),
  loading: LoadingComponent
})

export function product_list(self) {
  const {
    classes,
    pageContext,
    currentOrganisation,
    categoryTab,
    filterBy
  } = self.props

  let langCode, urlPrefix
  try {
    if (pageContext) {
      langCode = pageContext.langCode
      urlPrefix = pageContext.urlPrefix
    } else {
      langCode = 'en_UK'
      urlPrefix = 'en/'
    }
  } catch (error) {}

  let customProductVariables = {
    id: SHOP_ID
  }
  try {
    if (
      self.props.pageContext.teamstoreData &&
      self.props.pageContext.teamstoreData.id
    ) {
      customProductVariables.salesChannelIds = [
        self.props.pageContext.teamstoreData.id
      ]
    } else {
      customProductVariables.salesChannelIds = ['MYBRIKL']
    }
  } catch (error) {}

  return (
    <Query query={CUSTOM_PRODUCTS_QUERY} variables={customProductVariables}>
      {({ loading, error, data }) => {
        if (error) return <div>Error :(</div>
        var customProducts = []
        try {
          if (data.searchCustomProducts) {
            customProducts = data.searchCustomProducts
          }
        } catch (error) {}
        try {
          if (categoryTab) {
            customProducts = customProducts.filter(p => {
              return p &&
                p.collections &&
                JSON.stringify(p.collections)
                  .toLowerCase()
                  .includes(categoryTab.toLowerCase())
                ? true
                : false
            })
          }
        } catch (error) {}
        try {
          if (filterBy && filterBy !== '') {
            customProducts = customProducts.filter(p => {
              return p &&
                p.collections &&
                JSON.stringify(p.collections)
                  .toLowerCase()
                  .includes(filterBy.toLowerCase())
                ? true
                : false
            })
          }
        } catch (error) {}

        return (
          <Grid
            container
            spacing={2}
            style={{ margin: 'auto', maxWidth: 1000, padding: 24 }}
          >
            {customProducts.length > 0
              ? customProducts.map(product => {
                  // const product = productNode.node;
                  return (
                    <ProductCard
                      defaultCurrency={
                        pageContext && pageContext.shop
                          ? pageContext.shop.defaultCurrency
                          : null
                      }
                      taxSettings={
                        pageContext && pageContext.shop
                          ? pageContext.shop.taxSettings
                          : null
                      }
                      langCode={langCode}
                      urlPrefix={urlPrefix}
                      product={product}
                      buttonText={{
                        text: [
                          {
                            langCode,
                            content: 'Design now'
                          }
                        ]
                      }}
                      customUrl={`/${urlPrefix}new-design/?o=${currentOrganisation.id}&p=${product.id}&v=V2&sc=MYBRIKL`}
                    />
                  )
                })
              : loading
              ? 'Loading...'
              : 'No custom products'}
          </Grid>
        )
      }}
    </Query>
  )
}

export function main_template(self, loadingBG) {
  const { loadData, ready, scriptReady, loading } = self.state
  return (
    <ApolloConsumer>
      {client => (
        <Query
          query={PRODUCT_DESIGN_QUERY}
          variables={{
            shopId: self.props.shopId,
            id: self.props.productDesignId,
            ownedByOrganisationId: self.props.organisationId
              ? self.props.organisationId
              : self.props.ownedByOrganisationId
          }}
          fetchPolicy="no-cache"
        >
          {({ loading, error, data }) => {
            if (loading) return <div className="MainTemplate">{}</div>
            if (error) return <div>Error :(</div>
            let productDesign = null
            let shopConfiguration = null
            let user = null
            let loadDataFromDB = null
            try {
              productDesign = data.getProductDesign
              shopConfiguration = data.shopConfiguration
              user = data.user

              loadDataFromDB = transformDataForLoad(
                self.productID,
                productDesign.latestRevision,
                productDesign.product.designTemplates,
                productDesign.productElements
              )
              self.onLoad = !!(
                loadDataFromDB.templateId ||
                loadDataFromDB.allItem.length > 0 ||
                Object.keys(loadDataFromDB.directElm).length > 0 ||
                Object.keys(loadDataFromDB.mapColor).length > 0
              )

              logger.log(
                'loadDataFromDB',
                self.onLoad,
                loadDataFromDB,
                productDesign
              )
            } catch (error) {}
            return productDesign && productDesign.id ? (
              <DesignTemplate
                langCode={self.props.langCode}
                shopConfiguration={shopConfiguration}
                urlPrefix={self.props.urlPrefix}
                productDesign={productDesign}
                client={client}
                ownedByOrganisationId={self.props.ownedByOrganisationId}
                productDesignId={self.props.productDesignId}
                productID={self.productID}
                onLoad={self.onLoad}
                loadData={loadDataFromDB}
                MainTemplate={self}
                SAVE_ID={self._SAVE_ID}
                templateID={self.templateID}
                localData={self.localData}
                SHOP_ID={self.SHOP_ID}
                shop={self.shop}
                user={user}
              />
            ) : (
              'Design not found'
            )
          }}
        </Query>
      )}
    </ApolloConsumer>
  )
}

export async function productDataQuery(self) {
  try {
    // statements

    const DesignRoot = self

    const apolloClient = DesignRoot.props.client

    const productDesign = DesignRoot.props.productDesign

    logger.log('productDesign', productDesign, DesignRoot.SHOP_ID, DesignRoot)

    // CHECK REQUIRE FILE & INIT DEFAULT FOR BLANK VAR

    try {
      if (productDesign.product.objSrc) {
        logger.log('OBJ CHECKED', productDesign.product.objSrc)
      } else {
        logger.log('OBJ NOT FOUND', productDesign.product.objSrc)
        console.warn('OBJ file missing. Please contact administrator.')
        return false
      }

      if (productDesign.product.mtlSrc) {
        logger.log('MTL CHECKED', productDesign.product.mtlSrc)
      } else {
        logger.log('MTL NOT FOUND', productDesign.product.mtlSrc)
        console.warn('MTL file missing. Please contact administrator.')
        return false
      }

      if (productDesign.product.baseTemplateSrc) {
        logger.log('SVG CHECKED', productDesign.product.baseTemplateSrc)
      } else {
        logger.log('SVG NOT FOUND', productDesign.product.baseTemplateSrc)
        console.warn('SVG file missing. Please contact administrator.')
        return false
      }

      // templateSvg = productDesign.product.baseTemplateSrc;
    } catch (e) {
      logger.error(
        'query.productDataQuery.CHECK REQUIRE FILE & INIT DEFAULT FOR BLANK VAR.error',
        e
      )
    }

    // CHECK REQUIRE FILE & INIT DEFAULT FOR BLANK VAR

    // var productConfig = require('./TEMPLATE_ASSET/' +
    //   DesignRoot.productID +
    //   '/config.json');
    // productConfig = productConfig[DesignRoot.productID];
    const productConfig = {
      id: productDesign.product.id,
      stack:
        DesignRoot.SHOP_ID === 'yuepai'
          ? [
              {
                stackId: 'XL',
                stackSvg: 'STACKS/Print pattern XL-01.svg',
                legendColor: '#f00',
                svgCalElm: 'E4-1',
                svgCalCm: 41.71
              },
              {
                stackId: 'L',
                stackSvg: 'STACKS/Print pattern size L seam allowance.svg',
                legendColor: '#00f',
                svgCalElm: 'E4-1',
                svgCalCm: 39.23
              }
            ]
          : []
    }

    logger.log('productConfig', productConfig)

    const OBJ = `${productDesign.product.objSrc}${
      productDesign.product.hasObjGzipFile === true ? '.gz' : ''
    }`
    // const OBJ = require('../TEMPLATE_ASSET/M11-01.obj');
    const MTL = productDesign.product.mtlSrc
    // var MTL = require('./TEMPLATE_ASSET/' +
    //   DesignRoot.productID +
    //   '/' +
    //   productConfig.mtl);

    const MTL_PATTERN_PREFIX = 'PrintPattern'
    // var MTL_PATTERN_PREFIX = productConfig.mtlPatternPrefix;

    let redLineSrc = null
    // if (productConfig.redLineSrc) {
    //   redLineSrc = require('./TEMPLATE_ASSET/' +
    //     DesignRoot.productID +
    //     '/' +
    //     productConfig.redLineSrc);
    // }
    if (productDesign.product.seamSrc) {
      redLineSrc = productDesign.product.seamSrc
    }

    let templateSvg = null
    // if (productConfig.templateSvg) {
    //   templateSvg = require('./TEMPLATE_ASSET/' +
    //     DesignRoot.productID +
    //     '/' +
    //     productConfig.templateSvg);
    // }
    if (productDesign.product.baseTemplateSrc) {
      templateSvg = productDesign.product.baseTemplateSrc
    }

    // productDesign.product.modelConfiguration.dimension.baseTemplate
    // productDesign.product.modelConfiguration.dimension.baseSize
    // productDesign.product.modelConfiguration.dimension.pxToCm
    // var dimension = productConfig.dimension;

    if (
      productDesign.product.modelConfiguration !== null &&
      productDesign.product.modelConfiguration.dimension
    ) {
      var dimension = productDesign.product.modelConfiguration.dimension
      DesignRoot._PXTOCM = dimension.pxToCm
    } else {
      logger.log('DIMENSION WILL ASSUME')
      var dimension = {
        baseTemplate: 3000,
        baseSize: 'width',
        pxToCm: 0.4
      } // DEFAULT DIMENSION
      DesignRoot._PXTOCM = dimension.pxToCm
    }

    // DEFAULT TEMPLATE XCEED

    if (
      productDesign.product.defaultTemplateSrc !== undefined &&
      DesignRoot._DEFAULT_TEMPLATE_ACTIVE === true
    ) {
      DesignRoot._DEFAULT_TEMPLATE_SRC =
        productDesign.product.defaultTemplateSrc
    } else {
      DesignRoot._DEFAULT_TEMPLATE_ACTIVE = false
    }

    // DEFAULT TEMPLATE XCEED

    logger.log('dimension', dimension)

    // var TEMPLATE_LOGO = require('./TEMPLATE_ASSET/'+DesignRoot.productID+"/"+DesignRoot.productID+"-template-logo.png");

    // var TEMPLATE_BASE = [];
    // var TEMPLATE_REVIEW = [];
    // var TEMPLATE_COLORZONE = [];
    var TEMPLATE_SET_BY_ID = {}
    var TEMPLATE_SET_DATA = []

    // for (var i = 0; i < productConfig.numberOfTemplate; i++) {
    //   var templateSet = productConfig.templateSet[i];

    //   var templateSrc = require('./TEMPLATE_ASSET/' +
    //     DesignRoot.productID +
    //     '/' +
    //     templateSet.templateSrc);
    //   var templatePreview = require('./TEMPLATE_ASSET/' +
    //     DesignRoot.productID +
    //     '/' +
    //     templateSet.templatePreview);
    //   var templateLogo = require('./TEMPLATE_ASSET/' +
    //     DesignRoot.productID +
    //     '/' +
    //     templateSet.templateLogo);
    //   var templateColor = templateSet.colorData;

    //   // TEMPLATE_BASE.push(templateSrc);
    //   // TEMPLATE_REVIEW.push(templatePreview);
    //   // TEMPLATE_COLORZONE.push(templateColor);

    //   TEMPLATE_SET_DATA.push({
    //     templateId: templateSet.templateId,
    //     templateSrc: templateSrc,
    //     templatePreview: templatePreview,
    //     templateLogo: templateLogo,
    //     colorData: templateColor
    //   });

    //   TEMPLATE_SET_BY_ID[templateSet.templateId] = TEMPLATE_SET_DATA[i];
    // }

    // NEW COLORZONE SVG CONFIG

    // if (
    //   DesignRoot._COLOR_ZONE_SVG_ACTIVE === true &&
    //   DesignRoot.productID === '6fa46375-15a2-458a-9371-89d6ef76f954'
    // ) {
    //   // EXAMPLE DATA
    //   var colorZoneSvgTest1 = require('../TEMPLATE_ASSET/colorzone_svg/907-COLORZONE-1.svg')
    //   var colorZoneSvgTest2 = require('../TEMPLATE_ASSET/colorzone_svg/907-COLORZONE-2.svg')
    //   DesignRoot._COLOR_ZONE_SVG.push(colorZoneSvgTest1)
    //   DesignRoot._COLOR_ZONE_SVG.push(colorZoneSvgTest2)

    //   DesignRoot._COLOR_ZONE_SVG_SELECTED = 0 // DEFAULT
    // }

    // NEW COLORZONE SVG CONFIG

    logger.log('TEMPLATE QUERY', productDesign.product.designTemplates)

    let numberOfTemplates = 0

    if (
      productDesign.product.designTemplates &&
      productDesign.product.designTemplates.length &&
      DesignRoot._ACTIVE_COLOR_ZONE === true
    ) {
      for (var i = 0; i < productDesign.product.designTemplates.length; i++) {
        var template = productDesign.product.designTemplates[i]

        if (
          DesignRoot._CONVERT_COLORZONE_TO_VECTOR === false &&
          template.hasColorZoneFile === true
        ) {
          DesignRoot._COLOR_ZONE_SVG.push(template.colorZoneFileSrc)

          TEMPLATE_SET_DATA.push({
            templateType: 'SVG',
            templateId: template.id,
            templateSrc: template.templateFileSrc,
            colorZoneFileSrc: template.colorZoneFileSrc,
            templatePreview: template.previewImageSrc,
            templateLogo: template.logobaseFileSrc,
            index: i,
            hasColorZoneFile: template.hasColorZoneFile
          })
          TEMPLATE_SET_BY_ID[template.id] =
            TEMPLATE_SET_DATA[TEMPLATE_SET_DATA.length - 1]

          numberOfTemplates++
        } // end if
        else if (
          DesignRoot._CONVERT_COLORZONE_TO_VECTOR === true &&
          template.hasColorZoneFile === true &&
          template.templateFileSrc === null &&
          template.colors === null
        ) {
          // SVG FOR CONVERT VECTOR COMBINE
          DesignRoot._COLOR_ZONE_SVG.push(template.colorZoneFileSrc)

          TEMPLATE_SET_DATA.push({
            templateType: 'SVG',
            templateId: template.id,
            templateSrc: template.templateFileSrc,
            colorZoneFileSrc: template.colorZoneFileSrc,
            templatePreview: template.previewImageSrc,
            templateLogo: template.logobaseFileSrc,
            index: i,
            hasColorZoneFile: template.hasColorZoneFile
          })
          TEMPLATE_SET_BY_ID[template.id] =
            TEMPLATE_SET_DATA[TEMPLATE_SET_DATA.length - 1]

          numberOfTemplates++
        } // end if
        else if (
          template.colors !== null ||
          DesignRoot._OLD_CZ_AVAILABLE === false
        ) {
          DesignRoot._COLOR_ZONE_SVG.push(null)

          var colorSET = template.colors !== null ? template.colors : []

          TEMPLATE_SET_DATA.push({
            templateType: 'IMAGE',
            templateId: template.id,
            templateSrc: template.templateFileSrc,
            colorZoneFileSrc: template.colorZoneFileSrc,
            templatePreview: template.previewImageSrc,
            templateLogo: template.logobaseFileSrc,
            hasColorZoneFile: template.hasColorZoneFile,
            colorData: colorSET.map(color => {
              if (color) {
                return {
                  title: color.name,
                  color: color.customColorHex
                    ? color.customColorHex.toLowerCase()
                    : '#000000',
                  coord: {
                    x: color.coordinates.x,
                    y: color.coordinates.y
                  },
                  id: color.id
                }
              }
            })
          })

          TEMPLATE_SET_BY_ID[template.id] =
            TEMPLATE_SET_DATA[TEMPLATE_SET_DATA.length - 1]
          numberOfTemplates++
        } // end else if
      } // end for

      logger.log('TEMPLATE_SET_DATA', TEMPLATE_SET_DATA)
      logger.log('TEMPLATE_SET_BY_ID', TEMPLATE_SET_BY_ID)

      // if (
      //   DesignRoot._CONVERT_COLORZONE_TO_VECTOR === false &&
      //   productDesign.product.designTemplates[0].hasColorZoneFile &&
      //   productDesign.product.designTemplates[0].hasColorZoneFile === true
      // ) {
      //   // NEW COLORZONE SVG CONFIG

      //   DesignRoot._COLOR_ZONE_SVG_ACTIVE = true
      //   DesignRoot._COLOR_ZONE_SVG_SELECTED = 0 // DEFAULT
      //   DesignRoot._ACTIVE_COLOR_ZONE = false
      //   DesignRoot._SVG_FILL = true

      //   for (var i = 0; i < productDesign.product.designTemplates.length; i++) {
      //     DesignRoot._COLOR_ZONE_SVG.push(
      //       productDesign.product.designTemplates[i].colorZoneFileSrc
      //     )

      //     var template = productDesign.product.designTemplates[i]

      //     TEMPLATE_SET_DATA.push({
      //       templateType: 'SVG',
      //       templateId: template.id,
      //       templateSrc: template.templateFileSrc,
      //       colorZoneFileSrc: template.colorZoneFileSrc,
      //       templatePreview: template.previewImageSrc,
      //       templateLogo: template.logobaseFileSrc,
      //       index: i,
      //       hasColorZoneFile: template.hasColorZoneFile
      //     })
      //     TEMPLATE_SET_BY_ID[template.id] = TEMPLATE_SET_DATA[i]
      //   }
      // } else {
      //   numberOfTemplates = productDesign.product.designTemplates.length

      //   productDesign.product.designTemplates.forEach(
      //     (designTemplate, designTemplateIndex) => {
      //       const template = designTemplate
      //       logger.log('template', template)
      //       logger.log('template color', template.colors)

      //       if (template.colors !== null) {
      //         TEMPLATE_SET_DATA.push({
      //           templateType: 'IMAGE',
      //           templateId: template.id,
      //           templateSrc: template.templateFileSrc,
      //           colorZoneFileSrc: template.colorZoneFileSrc,
      //           templatePreview: template.previewImageSrc,
      //           templateLogo: template.logobaseFileSrc,
      //           hasColorZoneFile: template.hasColorZoneFile,
      //           colorData: template.colors.map(color => {
      //             if (color) {
      //               return {
      //                 title: color.name,
      //                 color: color.customColorHex
      //                   ? color.customColorHex.toLowerCase()
      //                   : '#000000',
      //                 coord: {
      //                   x: color.coordinates.x,
      //                   y: color.coordinates.y
      //                 },
      //                 id: color.id
      //               }
      //             }
      //           })
      //         })
      //         TEMPLATE_SET_BY_ID[template.id] =
      //           TEMPLATE_SET_DATA[designTemplateIndex]
      //       } // END CHECK COLORS
      //       else {
      //         console.warn(
      //           '### HAVE TEMPLATE BUT NO COLOR ZONE : TEMPLATE WILL REJECT ###'
      //         )
      //         numberOfTemplates = numberOfTemplates--
      //       }
      //     }
      //   )
      // } // END ELSE
    } else {
      if (
        DesignRoot._ACTIVE_COLOR_ZONE === false &&
        DesignRoot._DEFAULT_TEMPLATE_ACTIVE === true
      ) {
        DesignRoot._BLANK_TEMPLATE_ACTIVE = false
      } else {
        DesignRoot._ACTIVE_COLOR_ZONE = false
        DesignRoot._BLANK_TEMPLATE_ACTIVE = true
      }
    }

    if (
      (DesignRoot._SVG_ACTIVE === true &&
        DesignRoot._ACTIVE_COLOR_ZONE === false) ||
      DesignRoot._BLANK_TEMPLATE_ACTIVE === true ||
      TEMPLATE_SET_DATA.length === 0
    ) {
      // ADD BLANK TEMPLATE

      try {
        // TODO fix DesignRoot
        TEMPLATE_SET_DATA.push({
          templateType: 'BLANK',
          templateId: 'BLANK',
          templatePreview:
            productDesign?.product?.blankTemplatePreviewSrc ||
            require('../TEMPLATE_ASSET/no-image.png')
        })

        TEMPLATE_SET_BY_ID['BLANK'] =
          TEMPLATE_SET_DATA[TEMPLATE_SET_DATA.length - 1]
      } catch (error) {
        logger.error('BLANK TEMPLATE ERROR', error)
      }

      // ADD BLANK TEMPLATE
    }

    DesignRoot.productDataSet = {
      ID: productConfig.id,
      productConfig: productConfig,
      productDesign: productDesign,
      productTitle: productDesign.product.title,
      productDescription: productDesign.product.description,
      OBJ: OBJ,
      MTL: MTL,
      MTL_PATTERN_PREFIX: MTL_PATTERN_PREFIX,
      NO_OF_TEMPLATE: numberOfTemplates,
      redLineSrc: redLineSrc,
      templateSvg: templateSvg,
      dimension: dimension,
      elements: productDesign.productElements,
      // ACTIVE_TEMPLATE_INDEX: 0,
      // ACTIVE_TEMPLATE_ID: productConfig.templateSet[0].templateId, // INIT FIRST TEMPLATE // USE DesignRoot BE DEFAULT
      // TEMPLATE_BASE: TEMPLATE_BASE, // MIGHT REMOVE
      // TEMPLATE_REVIEW: TEMPLATE_REVIEW, // MIGHT REMOVE
      // COLOR_DATA: TEMPLATE_COLORZONE, // MIGHT REMOVE
      TEMPLATE_SET: TEMPLATE_SET_DATA,
      TEMPLATE_SET_BY_ID: TEMPLATE_SET_BY_ID // USE DesignRoot BE DEFAULT
    }

    DesignRoot._CURRENT_SVG_SRC = DesignRoot.productDataSet.templateSvg

    logger.log('TEMPLATE_SET_DATA', TEMPLATE_SET_DATA)
    logger.log('DesignRoot.productDataSet', DesignRoot.productDataSet)

    // CHECK REQUIRE VAR BEFORE GO NEXT
    if (TEMPLATE_SET_DATA.length === 0) {
      logger.log('TEMPLATE_SET_DATA NOT FOUND', TEMPLATE_SET_DATA)
      console.warn('TEMPLATE_SET_DATA missing. Please contact administrator.')
      return false
    }
    // CHECK REQUIRE VAR BEFORE GO NEXT

    // OLD COLOR ZONE

    if (DesignRoot.onLoad === false) {
      logger.log('INITIAL LOAD TEMPLATE 0', TEMPLATE_SET_DATA[0])

      var template = TEMPLATE_SET_DATA[0]

      if (
        template.templateType === 'SVG' &&
        template.hasColorZoneFile === true
      ) {
        DesignRoot._COLOR_ZONE_SVG_ACTIVE = true
        DesignRoot._COLOR_ZONE_SVG_SELECTED = 0 // DEFAULT
        DesignRoot._ACTIVE_COLOR_ZONE = false
        DesignRoot._SVG_FILL = true

        DesignRoot._ACTIVE_TEMPLATE_ID = template.templateId
        DesignRoot._CURRENT_LOGO_SRC = template.templateLogo
        DesignRoot._CURRENT_SEAM_SRC = DesignRoot.productDataSet.redLineSrc
        DesignRoot._CURRENT_SVG_SRC = DesignRoot.productDataSet.templateSvg
        DesignRoot._SVG_AOP = false

        if (DesignRoot._OLD_CZ_AVAILABLE === false) {
          DesignRoot._ACTIVE_FILL_STEP = true
        }
      } else {
        if (DesignRoot._OLD_CZ_AVAILABLE === false) {
          DesignRoot._ACTIVE_FILL_STEP = false
        }

        if (
          DesignRoot.templateID === null ||
          DesignRoot.templateID === 'LOAD'
        ) {
          // NO TEMPLATE ID PROVIDE
          DesignRoot._ACTIVE_TEMPLATE_ID = template.templateId

          if (DesignRoot._ACTIVE_COLOR_ZONE === true) {
            DesignRoot._SELECT_COLOR_ZONE = template.colorData.length
              ? template.colorData[0].color.toLowerCase()
              : null
            DesignRoot._CURRENT_TEMPLATE_SRC = template.templateSrc
            DesignRoot._CURRENT_LOGO_SRC = template.templateLogo
          }

          DesignRoot._CURRENT_SEAM_SRC = DesignRoot.productDataSet.redLineSrc
          DesignRoot._CURRENT_SVG_SRC = DesignRoot.productDataSet.templateSvg
        } else {
          // HAS TEMPLATE ID PROVIDE
          DesignRoot._ACTIVE_TEMPLATE_ID = DesignRoot.templateID
          var template = TEMPLATE_SET_BY_ID[DesignRoot._ACTIVE_TEMPLATE_ID]

          if (DesignRoot._ACTIVE_COLOR_ZONE === true) {
            DesignRoot._SELECT_COLOR_ZONE = template.colorData.length
              ? template.colorData[0].color.toLowerCase()
              : null

            DesignRoot._CURRENT_TEMPLATE_SRC = template.templateSrc
            DesignRoot._CURRENT_LOGO_SRC = template.templateLogo
          }

          DesignRoot._CURRENT_SVG_SRC = DesignRoot.productDataSet.templateSvg
        }
      } // end else
    } // end if onLoad
    else if (DesignRoot.onLoad === true) {
      logger.log('DO LOAD TEMPLATE')
      logger.log('HANDLE LOAD', DesignRoot.onLoad, DesignRoot.loadData)

      var loadData = DesignRoot.loadData
      if (loadData.id === DesignRoot.productDataSet.ID) {
        DesignRoot._LOAD_DATA = loadData

        var id = loadData.id
        var templateId = loadData.templateId
        var mapColor = loadData.mapColor
        var allItem = loadData.allItem
        var logoActive = loadData.logoActive
        var template = DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[templateId]

        logger.log('LOAD TEMPLATE', template)

        if (logoActive && logoActive === true) {
          DesignRoot._LOGO_TOGGLE = logoActive
        }

        // INIT LOAD BLANK TEMPLATE

        if (templateId === null && DesignRoot._BLANK_TEMPLATE_ACTIVE === true) {
          templateId = 'BLANK'
          DesignRoot._ACTIVE_TEMPLATE_ID = templateId
          DesignRoot._ACTIVE_COLOR_ZONE = false
          DesignRoot._COLOR_ZONE_SVG_SELECTED = null
          DesignRoot._SVG_AOP = true
          DesignRoot._MAP_OVERLAY = null
          DesignRoot._COLOR_ZONE_SVG_ACTIVE = false
        } else {
          // CHECK LOAD OLD VS NEW COLOR ZONE TEMPLATE

          var templateType = template.templateType

          var hasColorZoneFile = template.hasColorZoneFile

          if (templateType === 'SVG' && hasColorZoneFile === true) {
            // NEW COLOR ZONE

            for (
              var i = 0;
              i < DesignRoot.productDataSet.TEMPLATE_SET.length;
              i++
            ) {
              if (
                DesignRoot.productDataSet.TEMPLATE_SET[i].templateId ===
                templateId
              ) {
                DesignRoot._COLOR_ZONE_SVG_SELECTED = i
              }
            }

            DesignRoot._ACTIVE_TEMPLATE_ID = templateId
            DesignRoot._CURRENT_LOGO_SRC = template.templateLogo
            DesignRoot._SVG_AOP = false

            DesignRoot._COLOR_ZONE_SVG_ACTIVE = true
            DesignRoot._ACTIVE_COLOR_ZONE = false
            DesignRoot._SVG_FILL = true

            if (DesignRoot._OLD_CZ_AVAILABLE === false) {
              DesignRoot._ACTIVE_FILL_STEP = true
            }
          } else {
            // OLD COLOR ZONE

            if (
              DesignRoot._ACTIVE_TEMPLATE_ID !== templateId &&
              DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK'
            ) {
              DesignRoot._ACTIVE_TEMPLATE_ID = templateId

              if (DesignRoot._ACTIVE_COLOR_ZONE === true) {
                DesignRoot._SELECT_COLOR_ZONE = template.colorData.length
                  ? template.colorData[0].color.toLowerCase()
                  : null
              }

              DesignRoot._CURRENT_TEMPLATE_SRC = template.templateSrc
              DesignRoot._CURRENT_LOGO_SRC = template.templateLogo
              DesignRoot._CURRENT_SVG_SRC =
                DesignRoot.productDataSet.templateSvg

              DesignRoot._ACTIVE_COLOR_ZONE = true
              DesignRoot._COLOR_ZONE_SVG_SELECTED = null
              DesignRoot._COLOR_ZONE_SVG_ACTIVE = false
              DesignRoot._SVG_AOP = false

              if (DesignRoot._OLD_CZ_AVAILABLE === false) {
                DesignRoot._ACTIVE_FILL_STEP = false
              }
            }
          }
        } // end else

        // CHECK LOAD OLD VS NEW COLOR ZONE TEMPLATE
      } else {
        DesignRoot.onLoad = false
      }
    } // end else if onLoad

    // if (DesignRoot._COLOR_ZONE_SVG_ACTIVE === true) {
    //   DesignRoot._COLOR_ZONE_SVG_SELECTED = 0 // DEFAULT TEMPLATE 0
    //   DesignRoot._ACTIVE_TEMPLATE_ID = DesignRoot.productDataSet.TEMPLATE_SET[0].templateId
    //   DesignRoot._CURRENT_LOGO_SRC =
    //     DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[
    //       DesignRoot._ACTIVE_TEMPLATE_ID
    //     ].templateLogo
    //   DesignRoot._CURRENT_SEAM_SRC = DesignRoot.productDataSet.redLineSrc
    //   DesignRoot._CURRENT_SVG_SRC = DesignRoot.productDataSet.templateSvg
    //   DesignRoot._SVG_AOP = false
    // } else {
    //   if (DesignRoot.templateID === null || DesignRoot.templateID === 'LOAD') {
    //     DesignRoot._ACTIVE_TEMPLATE_ID =
    //       DesignRoot.productDataSet.TEMPLATE_SET[0].templateId

    //     if (DesignRoot._ACTIVE_COLOR_ZONE === true) {
    //       DesignRoot._SELECT_COLOR_ZONE = DesignRoot.productDataSet.TEMPLATE_SET[0].colorData[0].color.toLowerCase()
    //       DesignRoot._CURRENT_TEMPLATE_SRC =
    //         DesignRoot.productDataSet.TEMPLATE_SET[0].templateSrc
    //       DesignRoot._CURRENT_LOGO_SRC =
    //         DesignRoot.productDataSet.TEMPLATE_SET[0].templateLogo
    //     }

    //     DesignRoot._CURRENT_SEAM_SRC = DesignRoot.productDataSet.redLineSrc
    //     DesignRoot._CURRENT_SVG_SRC = DesignRoot.productDataSet.templateSvg
    //   } else {
    //     DesignRoot._ACTIVE_TEMPLATE_ID = DesignRoot.templateID

    //     if (DesignRoot._ACTIVE_COLOR_ZONE === true) {
    //       DesignRoot._SELECT_COLOR_ZONE = DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[
    //         DesignRoot._ACTIVE_TEMPLATE_ID
    //       ].colorData[0].color.toLowerCase()

    //       DesignRoot._CURRENT_TEMPLATE_SRC =
    //         DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[
    //           DesignRoot._ACTIVE_TEMPLATE_ID
    //         ].templateSrc
    //       DesignRoot._CURRENT_LOGO_SRC =
    //         DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[
    //           DesignRoot._ACTIVE_TEMPLATE_ID
    //         ].templateLogo
    //     }

    //     DesignRoot._CURRENT_SVG_SRC = DesignRoot.productDataSet.templateSvg
    //   }
    // } // end ELSE

    if (DesignRoot.SHOP_ID === 'yuepai') {
      DesignRoot._STACK = []

      for (var i = 0; i < productConfig.stack.length; i++) {
        var stackSvg = require('../TEMPLATE_ASSET/' +
          'YOGA' +
          '/' +
          productConfig.stack[i].stackSvg)

        DesignRoot._STACK.push({
          stackId: productConfig.stack[i].stackId,
          stackSvg: stackSvg,
          legendColor: productConfig.stack[i].legendColor,
          svgCalElm: productConfig.stack[i].svgCalElm,
          svgCalCm: productConfig.stack[i].svgCalCm
        })
      }
    }

    // HANDLE LOAD DATA

    // logger.log('HANDLE LOAD', DesignRoot.onLoad, DesignRoot.loadData)

    // if (DesignRoot.onLoad === true) {

    //   var loadData = DesignRoot.loadData
    //   if (loadData.id === DesignRoot.productDataSet.ID) {
    //     DesignRoot._LOAD_DATA = loadData

    //     var id = loadData.id
    //     var templateId = loadData.templateId
    //     var mapColor = loadData.mapColor
    //     var allItem = loadData.allItem
    //     var logoActive = loadData.logoActive
    //     var template = DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[templateId];

    //     // INIT LOAD BLANK TEMPLATE

    //     if (templateId === null && DesignRoot._BLANK_TEMPLATE_ACTIVE === true) {
    //       templateId = 'BLANK'
    //       DesignRoot._ACTIVE_TEMPLATE_ID = templateId
    //       DesignRoot._ACTIVE_COLOR_ZONE = false
    //       DesignRoot._COLOR_ZONE_SVG_SELECTED = null
    //       DesignRoot._SVG_AOP = true
    //       DesignRoot._MAP_OVERLAY = null
    //       DesignRoot._COLOR_ZONE_SVG_ACTIVE = false
    //     }

    //     // INIT LOAD BLANK TEMPLATE

    //     if (logoActive && logoActive === true) {
    //       DesignRoot._LOGO_TOGGLE = logoActive
    //     }

    //     // CHECK LOAD OLD VS NEW COLOR ZONE TEMPLATE

    //     var templateType =
    //       DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[templateId].templateType

    //     var hasColorZoneFile =
    //       DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[templateId].hasColorZoneFile

    //     if (templateType === 'SVG' && hasColorZoneFile === true) {
    //       // NEW COLOR ZONE

    //       for (var i = 0; i < DesignRoot.productDataSet.TEMPLATE_SET.length; i++) {
    //         if (DesignRoot.productDataSet.TEMPLATE_SET[i].templateId === templateId) {
    //           DesignRoot._COLOR_ZONE_SVG_SELECTED = i
    //         }
    //       }

    //       DesignRoot._ACTIVE_TEMPLATE_ID = templateId
    //       DesignRoot._CURRENT_LOGO_SRC =
    //         DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[
    //           DesignRoot._ACTIVE_TEMPLATE_ID
    //         ].templateLogo
    //       DesignRoot._SVG_AOP = false

    //       DesignRoot._COLOR_ZONE_SVG_ACTIVE = true
    //       DesignRoot._COLOR_ZONE_SVG_SELECTED = 0 // DEFAULT
    //       DesignRoot._ACTIVE_COLOR_ZONE = false
    //       DesignRoot._SVG_FILL = true

    //       if (DesignRoot._OLD_CZ_AVAILABLE === false) {
    //         DesignRoot._ACTIVE_FILL_STEP = true
    //       }

    //     } else {
    //       // OLD COLOR ZONE

    //       if (
    //         DesignRoot._ACTIVE_TEMPLATE_ID !== templateId &&
    //         DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK'
    //       ) {
    //         DesignRoot._ACTIVE_TEMPLATE_ID = templateId

    //         if (DesignRoot._ACTIVE_COLOR_ZONE === true) {
    //           DesignRoot._SELECT_COLOR_ZONE = DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[
    //             templateId
    //           ].colorData[0].color.toLowerCase()
    //         }

    //         DesignRoot._CURRENT_TEMPLATE_SRC =
    //           DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[templateId].templateSrc
    //         DesignRoot._CURRENT_LOGO_SRC =
    //           DesignRoot.productDataSet.TEMPLATE_SET_BY_ID[templateId].templateLogo
    //         DesignRoot._CURRENT_SVG_SRC = DesignRoot.productDataSet.templateSvg

    //         DesignRoot._ACTIVE_COLOR_ZONE = true
    //         DesignRoot._COLOR_ZONE_SVG_SELECTED = null
    //         DesignRoot._COLOR_ZONE_SVG_ACTIVE = false
    //         DesignRoot._SVG_AOP = false
    //         DesignRoot._ACTIVE_FILL_STEP = false

    //         if (DesignRoot._OLD_CZ_AVAILABLE === false) {
    //           DesignRoot._ACTIVE_FILL_STEP = false
    //         }

    //       }
    //     }

    //     // CHECK LOAD OLD VS NEW COLOR ZONE TEMPLATE
    //   } else {
    //     DesignRoot.onLoad = false
    //   }

    // }

    // HANDLE LOAD DATA

    logger.log(
      '############################################################################'
    )
    logger.log(
      '############################################################################'
    )
    logger.log(
      '############################################################################'
    )
    logger.log('PRODUCT CONFIG INIT', DesignRoot.productDataSet, DesignRoot)
    logger.log('SHOP_ID', DesignRoot.SHOP_ID)
    logger.log('API_STAGE', API_STAGE)
    logger.log('_ACTIVE_COLOR_ZONE', DesignRoot._ACTIVE_COLOR_ZONE)
    logger.log('_SVG_ACTIVE', DesignRoot._SVG_ACTIVE)
    logger.log('_LOGO_TOGGLE', DesignRoot._LOGO_TOGGLE)
    logger.log('_SEAM_TOGGLE', DesignRoot._SEAM_TOGGLE)
    logger.log('_ACTIVE_TEMPLATE_ID', DesignRoot._ACTIVE_TEMPLATE_ID)

    // if (DesignRoot._ACTIVE_TEMPLATE_ID === 'BLANK') {

    //   DesignRoot._BLANK_TEMPLATE_ACTIVE = true;
    // }
    // else {

    //   DesignRoot._BLANK_TEMPLATE_ACTIVE = false;
    // }

    logger.log('_CURRENT_TEMPLATE_SRC', DesignRoot._CURRENT_TEMPLATE_SRC)
    logger.log('_CURRENT_LOGO_SRC', DesignRoot._CURRENT_LOGO_SRC)
    logger.log('_CURRENT_SEAM_SRC', DesignRoot._CURRENT_SEAM_SRC)
    logger.log('_CURRENT_SVG_SRC', DesignRoot._CURRENT_SVG_SRC)
    logger.log('_BLANK_TEMPLATE_ACTIVE', DesignRoot._BLANK_TEMPLATE_ACTIVE)
    logger.log('_DEFAULT_TEMPLATE_ACTIVE', DesignRoot._DEFAULT_TEMPLATE_ACTIVE)
    logger.log('_DEFAULT_TEMPLATE_SRC', DesignRoot._DEFAULT_TEMPLATE_SRC)
    logger.log('_COLOR_ZONE_SVG_ACTIVE', DesignRoot._COLOR_ZONE_SVG_ACTIVE)

    logger.log('_LOAD_DATA', DesignRoot._LOAD_DATA)
    logger.log('_SAVE_ID', DesignRoot._SAVE_ID)
    logger.log(
      '############################################################################'
    )
    logger.log(
      '############################################################################'
    )
    logger.log(
      '############################################################################'
    )

    // DesignRoot.Main3D.init();
  } catch (e) {
    // statements
    logger.error('DesignTemplate.productDataQuery.error', e)
  }
}
