import React, { Component } from 'react';

export default class AlignItemsFlexStart extends React.Component {
  render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.38461"
          y="2.76923"
          width="15.2308"
          height="2.76923"
          fill="black"
        />
        <rect
          x="4.15384"
          y="9.69232"
          width="9.69231"
          height="2.76923"
          fill="black"
        />
        <rect x="8.30768" width="1.38462" height="18" fill="black" />
      </svg>
    );
  }
}
