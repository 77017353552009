import React, { Component } from 'react';

export default class FillIcon extends React.Component {
  render() {
    return (
      <svg
        width="40"
        height="33"
        viewBox="0 0 40 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 13L17 24"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 13L12 24"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.51562 8.63975L12.5 1"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.6582 8.63975L21.5 1"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12.376 1H21.6834" stroke="black" strokeWidth="2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.00001 8.21429C0.684682 8.21429 0.387826 8.36301 0.199039 8.61558C0.0102512 8.86815 -0.0483511 9.19497 0.0409194 9.49739L5.67135 28.5719C5.79684 28.997 6.1872 29.2888 6.63044 29.2888H8.81367H21.2697C20.7641 28.6778 20.3282 28.0071 19.9741 27.2888H8.81367H7.37792L2.33785 10.2143H31.6521L31.0046 12.7139C31.6703 12.8357 32.3136 13.0219 32.9276 13.2659L33.9122 9.46505C33.9897 9.16551 33.9243 8.84692 33.7349 8.60222C33.5455 8.35753 33.2535 8.21429 32.9441 8.21429H1.00001Z"
          fill="black"
        />
        <path
          d="M29.1523 17.028V28.2888"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.5215 22.6584L34.7824 22.6584"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="29.1525"
          cy="22.7733"
          r="9.22671"
          stroke="black"
          strokeWidth="2"
        />
      </svg>
    );
  }
}
