import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Inputs } from '../common/Theme';
import Auth from '@aws-amplify/auth';
const styles = theme => ({
  root: {
    background: '#E5E5E5',
    height: 'calc(100vh - 50px)',
    padding: '25px 15px',
    position: 'relative'
  },
  input: {
    background: '#f4f4f4 !important',
    margin: '16px 0px !important',
    width: '300px',
    padding: '0px !important'
  },
  button: {
    width: '300px',
    minHeight: '45px',
    margin: '24px 0px !important'
  },
  textInput: {
    padding: '13px 24px'
  },
  forgotPassword: {
    position: 'absolute',
    bottom: '-4px',
    right: 0,
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    ':hover': {
      color: '#ffffff !important'
    }
  },
  signup: {
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    ':hover': {
      color: '#ffffff !important'
    }
  }
});

class NewPassword extends React.Component {
  static defaultProps = {
    authData: {},
    authState: 'signIn'
    // onAuthStateChange: (next, data) => { console.log(`SignIn:onAuthStateChange(${next}, ${JSON.stringify(data, null, 2)})`); }
  };

  constructor(props) {
    super(props);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.state = {
      authData: this.props.authData,
      authState: this.props.authState,
      modalShowing: false,
      loading: false,
      error: null,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      user: null
    };
  }

  async onPasswordChange() {
    this.setState({ loading: true });
    try {
      if (this.state.newPassword === this.state.confirmNewPassword) {
        Auth.currentAuthenticatedUser()
          .then(user => {
            return Auth.changePassword(
              user,
              this.state.oldPassword,
              this.state.newPassword
            );
          })
          .then(data => {
            console.log('data', data);
            // navigate(`/admin/login`);
          })
          .catch(err => {
            console.error(err);
            this.setState({ error: err.message || err, loading: false });
          });
      }
    } catch (err) {
      console.error(`Error: ${JSON.stringify(err, null, 2)}`);
      this.setState({ error: err.message, loading: false });
    }
  }

  updateConfirmPassword = e => {
    this.setState({
      confirmNewPassword: e.target.value
    });
  };
  updateNewPassword = e => {
    this.setState({
      newPassword: e.target.value
    });
  };
  updateOldPassword = e => {
    this.setState({
      oldPassword: e.target.value
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.root}
      >
        {this.state.error ? (
          <div>
            <div>{this.state.error}</div>
          </div>
        ) : null}
        Your password need to be changed
        <Inputs
          data-html2canvas-ignore
          data-cy="oldPassword"
          type="password"
          value={this.state.oldPassword}
          onChange={val => this.updateOldPassword(val)}
          placeholder="Current password"
          classes={{ root: classes.input, input: classes.textInput }}
        />
        <div style={{ position: 'relative' }}>
          <Inputs
            data-html2canvas-ignore
            data-cy="newPassword"
            type="password"
            value={this.state.newPassword}
            onChange={val => this.updateNewPassword(val)}
            placeholder="New password"
            classes={{ root: classes.input, input: classes.textInput }}
          />
        </div>
        <div style={{ position: 'relative' }}>
          <Inputs
            data-html2canvas-ignore
            data-cy="confirmNewPassword"
            type="password"
            value={this.state.confirmNewPassword}
            onChange={val => this.updateConfirmPassword(val)}
            placeholder="Confirm password"
            classes={{ root: classes.input, input: classes.textInput }}
          />
        </div>
        {this.state.loading === true ? (
          <Button className={classes.button} title="Login" disabled={true}>
            Loading
          </Button>
        ) : (
          <Button
            data-cy="setNewPassword"
            title="Set new password"
            onClick={this.onPasswordChange.bind(this)}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Set new password
          </Button>
        )}
      </Grid>
    );
  }
}

NewPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NewPassword);
