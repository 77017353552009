import React, { Component } from 'react';
import { API_STAGE } from '../../../../../brikl-config';
import StackLegend from './StackLegend';

class BottomBar extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.BottomBar = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  render() {
    return (
      <div className="BottomBar">
        <StackLegend DesignRoot={this.props.DesignRoot} />
      </div>
    );
  }
}

export default BottomBar;
