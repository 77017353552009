import React from 'react'

interface Props {
  linkColor: any
  cardWidth: string
  cardHeight: string
}

const Ideal: React.FunctionComponent<Props> = props => {
  const { linkColor, cardWidth, cardHeight } = props

  return (
    <svg
      width={cardWidth}
      height={cardHeight}
      viewBox="0 0 157 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Logo-iDeal" transform="translate(-30.000000, -30.000000)">
          <g transform="translate(30.000000, 30.000000)">
            <polygon
              id="Path-2"
              fill="transparent"
              points="3.72558594 4.20703125 3.72558594 133.061523 93.1142578 133.061523 122.901367 124.960938 143.177734 107.383789 153.095703 79.7363281 153.095703 47.7939453 138.263672 22.1298828 112.392578 7.55761719 87.4667969 5.46679688"
            ></polygon>
            <path
              d="M6.37094106,6.36947265 L6.37094106,129.096724 L80.9348376,129.096724 C125.96069,129.096724 150.454257,108.122936 150.454257,67.5762233 C150.454257,25.9081347 123.904427,6.36947265 80.9348376,6.36947265 L6.37094106,6.36947265 Z M80.9348376,0 C146.964825,0 156.824219,42.3420888 156.824219,67.5762233 C156.824219,111.358333 129.874492,135.469134 80.9348376,135.469134 C80.9348376,135.469134 1.59762996,135.469134 0,135.469134 C0,133.865141 0,1.60007731 0,0 C1.59762996,0 80.9348376,0 80.9348376,0 Z"
              id="Fill-4"
              fill="#0A0B09"
            ></path>
            <polygon
              id="Fill-5"
              fill="#0A0B09"
              points="16.6419788 119.338651 40.5114711 119.338651 40.5114711 76.8467844 16.6419788 76.8467844"
            ></polygon>
            <path
              d="M43.3753598,55.4598838 C43.3753598,63.6541005 36.7356998,70.2971868 28.5414831,70.2971868 C20.3511822,70.2971868 13.705159,63.6541005 13.705159,55.4598838 C13.705159,47.2720302 20.3511822,40.6260071 28.5414831,40.6260071 C36.7356998,40.6260071 43.3753598,47.2720302 43.3753598,55.4598838"
              id="Fill-6"
              fill="#0A0B09"
            ></path>
            <path
              d="M52.3732862,48.5485679 C52.3732862,52.3860124 52.3732862,58.560676 52.3732862,62.3966521 C54.4760492,62.3966521 56.6238434,62.3966521 56.6238434,62.3966521 C59.5689842,62.3966521 62.2889687,61.5454639 62.2889687,55.3639477 C62.2889687,49.3229094 59.2620865,48.5485679 56.6238434,48.5485679 C56.6238434,48.5485679 54.4760492,48.5485679 52.3732862,48.5485679 Z M123.279374,41.6607466 L130.154958,41.6607466 C130.154958,41.6607466 130.154958,57.3370011 130.154958,62.3966521 C131.679657,62.3966521 135.867073,62.3966521 140.343765,62.3966521 C137.549381,24.771096 107.950153,16.6419788 81.0547577,16.6419788 L52.3791598,16.6419788 L52.3791598,41.6749412 L56.6238434,41.6749412 C64.3623635,41.6749412 69.170427,46.9225487 69.170427,55.3639477 C69.170427,64.0730868 64.4778784,69.2717473 56.6238434,69.2717473 L52.3791598,69.2717473 L52.3791598,119.361167 L81.0547577,119.361167 C124.784004,119.361167 140.001626,99.0540367 140.539064,69.2717473 L123.279374,69.2717473 L123.279374,41.6607466 Z M104.122009,57.0673032 L109.096492,57.0673032 L106.821925,49.2617256 L106.408812,49.2617256 L104.122009,57.0673032 Z M93.3923376,69.2800682 L101.718711,41.6597676 L111.500768,41.6597676 L119.824695,69.2800682 L112.667175,69.2800682 L111.106745,63.9370141 L102.112735,63.9370141 L100.547899,69.2800682 L93.3923376,69.2800682 Z M90.0903733,62.3966521 L90.0903733,69.2717473 L73.0803131,69.2717473 L73.0803131,41.6710254 L89.541188,41.6710254 L89.541188,48.5436732 C89.541188,48.5436732 83.6832114,48.5436732 79.9568766,48.5436732 C79.9568766,49.4912871 79.9568766,50.5779104 79.9568766,51.7433384 L89.0213709,51.7433384 L89.0213709,58.6140283 L79.9568766,58.6140283 C79.9568766,59.9938441 79.9568766,61.2948552 79.9568766,62.3966521 C83.7908948,62.3966521 90.0903733,62.3966521 90.0903733,62.3966521 Z"
              id="Fill-7"
              fill={linkColor ? linkColor : 'black'}
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Ideal
