import React, { useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  createStyles,
  withStyles,
  WithStyles
} from '@material-ui/core';

import DraggableCard from '../../content/dragdrop/DraggableCard';
import {
  getElementsFirstLevel,
  recursiveGetSubItems
} from '../../../../../helper/menuHelper';
import EditMenuItem from './EditMenuItem';

const styles = () =>
  createStyles({
    box: {
      border: '1px solid black',
      borderRadius: '5px',
      fontSize: '10px',
      margin: '5px 0 5px 0',
      padding: '5px 0 5px 10px',
      ':hover': {
        border: '1px dashed black'
      }
    }
  });

interface Props extends WithStyles<typeof styles> {
  elements: any[];
  alignment: string;
  language: string;
  onAlign(id: string, value: string | null): void;
  onEditOpen(item: any): void;
  onStylingOpen(item: any): void;
  onSave(deletedId: string | null, swapped: any[]): void;
}

const EditMenu: React.FunctionComponent<Props> = props => {
  const {
    classes,
    elements,
    alignment,
    language,
    onAlign,
    onEditOpen,
    onSave,
    onStylingOpen
  } = props;

  const [swappedElements, setSwappedElements] = useState<any[]>([]);

  const elementsFirstLevel = getElementsFirstLevel(elements);

  const handleDelete = (item: any): void => {
    onSave(item.id, []);
  };

  const handleSwap = (params: any): void => {
    const newArr = [params.drag.id, params.hover.id];
    setSwappedElements([...swappedElements, newArr]);
    onSave(null, [newArr]);
  };

  return (
    <Grid container={true} justify="center" alignItems="center">
      <List component="nav" style={{ width: '100%', margin: '30px 0 30px 0' }}>
        {elementsFirstLevel.map((elementNode: any, index: number) => {
          const element = elementNode;
          return (
            <div key={element.id}>
              <ListItem
                component={DraggableCard}
                dragdrop={{
                  dragType: 'menuItem-0',
                  id: element.id,
                  index,
                  moveCard: handleSwap,
                  sortOrder: element.sortOrder
                }}
                className={classes.box}
              >
                <EditMenuItem
                  item={element}
                  language={language}
                  showAlignCenter={
                    alignment === 'left' || alignment === 'right'
                  }
                  showAlignLeft={
                    alignment === 'center' || alignment === 'right'
                  }
                  showAlignRight={
                    alignment === 'center' || alignment === 'left'
                  }
                  onAlign={onAlign}
                  onDelete={handleDelete}
                  onStylingOpen={onStylingOpen}
                  onEditOpen={onEditOpen}
                />
              </ListItem>

              {recursiveGetSubItems(elements, element.id, 1).map(
                (subElement, subIndex) => {
                  return (
                    <div
                      key={subElement.id}
                      style={{
                        borderLeft:
                          subElement._level === 1 ? 'solid 2px black' : 'none',
                        paddingLeft: `${subElement._level * 20}px`
                      }}
                    >
                      <ListItem
                        className={classes.box}
                        component={DraggableCard}
                        dragdrop={{
                          dragType: 'menuItem-' + subElement._level,
                          id: subElement.id,
                          index: subIndex,
                          moveCard: handleSwap,
                          sortOrder: subElement.sortOrder
                        }}
                      >
                        <EditMenuItem
                          item={subElement}
                          language={language}
                          showAlign={false}
                          onAlign={onAlign}
                          onDelete={handleDelete}
                          onStylingOpen={onStylingOpen}
                          onEditOpen={onEditOpen}
                        />
                      </ListItem>
                    </div>
                  );
                }
              )}
            </div>
          );
        })}
      </List>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(EditMenu);
