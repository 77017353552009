import React, { useContext } from 'react'
import { ShopContext } from '../../LanguageContext'
import MainBanner from '../MainBanner'
import { useQuery } from '@apollo/react-hooks'
import { ADMIN_SHOP_TEAMSTORES } from '../../gql/queries'
import TeamstoreInfo from './TeamstoreInfo'
import TeamstoreProducts from './TeamstoreProducts'
import TeamstoreCollections from './TeamstoreCollections'
import { TeamstoreModuleType } from '../Types'

interface Props {
  moduleType: TeamstoreModuleType
}

const TeamstorePageContainer: React.FC<Props> = props => {
  const { moduleType } = props
  const shopId = useContext(ShopContext)

  const { data, loading } = useQuery(ADMIN_SHOP_TEAMSTORES, {
    variables: {
      id: shopId
    }
  })

  if (loading) return null

  const teamstore = data?.shop?.salesChannels?.edges[0]?.node
  const products = data?.shop?.salesChannels?.edges[0]?.node?.products
  const collections = data?.shop?.salesChannels?.edges[0]?.node?.collections
  const contact = {
    name: teamstore?.contactPerson,
    email: teamstore?.contactEmail,
    phone: teamstore?.contactPhone
  }

  switch (moduleType) {
    case 'TEAMSTORE_BANNER':
      return (
        <MainBanner image={teamstore?.mainBanner} title={teamstore?.title} />
      )
    case 'TEAMSTORE_INFO':
      return (
        <TeamstoreInfo
          introduction={teamstore?.introductionText}
          contact={contact}
          dates=""
        />
      )
    case 'TEAMSTORE_COLLECTIONS':
      return <TeamstoreCollections collections={collections || null} />
    case 'TEAMSTORE_PRODUCTS':
      return <TeamstoreProducts products={products || null} />
    default:
      return <h2>{moduleType}</h2>
  }
}

export default TeamstorePageContainer
