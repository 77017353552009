import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { OrganisationContext } from '../../../user/context/OrganisationContext';

import ProductAttributeList from './ProductAttributeList';
import ProductAttributeDetail from './ProductAttributeDetail';

class Attributes extends Component {
  render() {
    const { shopId, shop } = this.props;
    const defaultLanguage = shop.defaultLanguage;
    return this.props.productAttributeId ? (
      <Grid container>
        <OrganisationContext.Consumer>
          {currentOrganisation => (
            <ProductAttributeDetail
              languages={shop.languages}
              currentOrganisation={currentOrganisation}
              defaultLanguage={defaultLanguage}
              shopId={shopId}
              productAttributeId={this.props.productAttributeId}
            />
          )}
        </OrganisationContext.Consumer>
      </Grid>
    ) : (
      <Grid container style={{ width: '100%' }}>
        <ProductAttributeList
          defaultLanguage={defaultLanguage}
          shopId={shopId}
        />
      </Grid>
    );
  }
}

export default Attributes;
