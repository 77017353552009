import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import { navigate } from '@reach/router';
import { ADMIN_SHOP_PRODUCT_PACKAGES } from '../../gql/admin-queries';
import { ADMIN_CREATE_PRODUCT_PACKAGE } from '../../gql/admin-mutations';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing,
    marginRight: theme.spacing
  }
});

class AddProduct extends Component {
  state = {
    name: ''
  };

  render() {
    const { shopId } = this.props;
    return (
      <div>
        <Mutation
          mutation={ADMIN_CREATE_PRODUCT_PACKAGE}
          variables={{ shopId, status: 'ACTIVE' }}
          update={(cache, { data: { createProductPackage } }) => {
            if (createProductPackage.id) {
              navigate(
                `/admin/${shopId}/inventory/productpackages/${createProductPackage.id}`
              );
              const data = cache.readQuery({
                query: ADMIN_SHOP_PRODUCT_PACKAGES,
                variables: { id: shopId }
              });
              data.shop.products.edges.unshift({
                node: {
                  ...createProductPackage
                },
                __typename: 'ShopProductPackageEdge'
              });
              cache.writeQuery({
                query: ADMIN_SHOP_PRODUCT_PACKAGES,
                variables: { id: shopId },
                data
              });
            }
          }}
        >
          {addProduct => (
            <Button
              data-cy="ProductPackage-Add-New-btn"
              color="primary"
              onClick={addProduct}
              variant="outlined"
            >
              New Product package
            </Button>
          )}
        </Mutation>
      </div>
    );
  }
}

export default withStyles(styles)(AddProduct);
