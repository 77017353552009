import React from 'react';
import {
  Grid,
  Paper,
  Checkbox,
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  Typography
} from '@material-ui/core';
import { removeKeys } from '../../../../helper/removeKeys';

import client from '../../gql/admin-apollo-config';
import { ADMIN_UPDATE_SHOP_SETTINGS } from '../../gql/admin-mutations';
import { PAYMENT_OPTIONS_LIST } from '../../../../helper/paymentOptionsList';

const styles = (themeStyle: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {
  shop: any;
}

const PaymentOptionsSettings: React.FunctionComponent<Props> = props => {
  const { shop } = props;

  const handleChange = async (
    isChecked: boolean,
    paymentOptionName: any
  ): Promise<any> => {
    let paymentOptions =
      shop && shop.paymentOptions ? _.cloneDeep(shop.paymentOptions) : [];

    if (isChecked && !paymentOptions.find(p => p.name === paymentOptionName)) {
      paymentOptions.push({
        icon: paymentOptionName,
        name: paymentOptionName
      });
    }
    if (
      !isChecked &&
      !!paymentOptions.find(p => p.name === paymentOptionName)
    ) {
      paymentOptions = paymentOptions.filter(o => o.name !== paymentOptionName);
    }

    paymentOptions = removeKeys(paymentOptions, '__typename');

    await client.mutate({
      mutation: ADMIN_UPDATE_SHOP_SETTINGS,
      optimisticResponse: {
        __typename: 'Mutation',
        updateShop: {
          ...shop,
          paymentOptions: paymentOptions.map((o: any) => {
            return {
              ...o,
              __typename: 'PaymentOption'
            };
          })
        }
      },
      variables: {
        id: shop.id,
        input: {
          paymentOptions: removeKeys(paymentOptions, '__typename')
        }
      }
    });
  };

  return (
    <Grid container={true} style={{ marginTop: 24 }}>
      <Grid item={true} xs={12} sm={12} md={4} lg={3} xl={3}>
        <Grid
          container={true}
          style={{ padding: '24px 24px 24px 0px' }}
          spacing={1}
          alignItems="center"
        >
          <Grid item={true} xs={12}>
            <Typography variant="subtitle1" style={{ marginRight: 16 }}>
              Payment Options
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body1" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} sm={12} md={8} lg={9} xl={9}>
        <Paper elevation={0} style={{ padding: 24 }}>
          <Grid direction="row" container={true} spacing={3}>
            {PAYMENT_OPTIONS_LIST.map(option => (
              <Grid item>
                <Checkbox
                  key={option.name}
                  checked={
                    shop.paymentOptions &&
                    !!shop.paymentOptions.find(
                      (p: any) => p.name === option.name
                    )
                  }
                  onChange={(e: any) =>
                    handleChange(e.target.checked, option.name)
                  }
                  value={option.name}
                />
                {option.label}
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(PaymentOptionsSettings);
