import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_SHOP_PRODUCTS,
  ADMIN_SHOP_TEAMSTORES
} from '../../../../../gql/admin-queries'
import {
  CircularProgress,
  NativeSelect,
  Chip,
  Grid,
  Typography
} from '@material-ui/core'
import { ShopContext } from '../../LanguageContext'
interface Props {
  type: string
  handleUpdate: any
  contentId: string
  fieldName: string
  defaultValue: string
  level: 'root' | 'style'
  styleId: string
  isUrl?: boolean
}

const ListSelect: React.FC<Props> = props => {
  const {
    type,
    handleUpdate,
    defaultValue,
    styleId,
    level,
    contentId,
    fieldName,
    isUrl
  } = props
  const [ids, setIds] = useState([])
  const [items, setItems] = useState([])

  const shopId = useContext(ShopContext)

  const queries = {
    PRODUCT: { query: ADMIN_SHOP_PRODUCTS, res: 'products' },
    COLLECTION: { query: ADMIN_SHOP_COLLECTIONS, res: 'collections' },
    TEAMSTORE: { query: ADMIN_SHOP_TEAMSTORES, res: 'salesChannels' }
  }
  const { data, loading } = useQuery(queries[type].query, {
    variables: {
      id: shopId
    }
  })

  useEffect(() => {
    if (data) {
      const selectedIds = data.shop[queries[type].res].edges.filter(
        (e: any) => {
          return defaultValue.includes(e.node.id)
        }
      )
      setIds(selectedIds.map((e: any) => e.node))
      setItems(data.shop[queries[type].res].edges.map((e: any) => e.node))
    }
  }, [data])

  useEffect(() => {
    if (ids.length > 0) {
      const newArray = ids.map((e: any) => e.id)
      handleUpdate(contentId, fieldName, newArray, level)
      setItems(
        data.shop[queries[type].res].edges
          .filter((o: any) => !newArray.includes(o.node.id))
          .map((o: any) => o.node)
      )
    }
  }, [ids])

  const handleAddItem = (event: any) => {
    const item: any = data.shop[queries[type].res].edges.find(
      (o: any) => o.node.id === event.target.value
    )
    setIds([...ids, item.node])
  }

  const handleDelete = (data: any) => () => {
    const chipArray = ids.filter((item: any) => item.id !== data.id)
    setIds(chipArray)
  }

  if (!data) return null
  if (loading) return <CircularProgress />

  return (
    <Grid container={true} direction="row" spacing={4}>
      <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
        <NativeSelect
          id="multiselect"
          value={ids ? ids[0]?.id : null}
          onChange={handleAddItem}
          style={{ border: '1px solid lightgrey', borderRadius: 2 }}
        >
          {isUrl ? (
            <option style={{ textTransform: 'capitalize' }} value="" disabled>
              {type}
            </option>
          ) : null}
          <option>Select {type}</option>
          {items.map((item: any) => (
            <option
              style={{ position: 'relative', left: 0 }}
              key={item.id}
              value={item.id}
            >
              {item?.title?.defaultValue}
            </option>
          ))}
        </NativeSelect>
      </Grid>

      <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
        <Typography style={{ marginBottom: 10 }}>
          Selected {type.toLowerCase()}(s)
        </Typography>
        {ids
          ? ids.map((item: any, index: number) => (
              <Chip
                key={item.id}
                label={item?.title?.defaultValue}
                style={{ margin: 2 }}
                onDelete={handleDelete(item)}
              />
            ))
          : null}
      </Grid>
    </Grid>
  )
}

export default ListSelect
