import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Notification from 'react-notification'
import { Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import {
  ADMIN_SHOP_FABRICS,
  ADMIN_GET_PRODUCT,
  ADMIN_GET_ORG_BOARDPRODUCTS
} from '../../gql/admin-queries'
import {
  ADMIN_UPDATE_PRODUCT,
  ADMIN_UPLOAD_FILE,
  ADMIN_UPLOAD_PRODUCTMODEL,
  ADMIN_CREATE_PRODUCT_DESIGN_TEMPLATE
} from '../../gql/admin-mutations'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import _sortBy from 'lodash/sortBy'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { API_STAGE, shopId } from '../../../../../brikl-config'
import { I18n } from '@aws-amplify/core'
import Helmet from 'react-helmet'
import Input from '../../../common/Input'
import Select from '../../../common/Select'
import BoardProductPreview from '../../../common/BoardProductPreview'
import _get from 'lodash/get'
import ProductElement from './ProductElement'
import ProductDesignTemplate from './ProductDesignTemplate'
import './ProductFileDrop.css'
import { navigate } from 'gatsby'
import { OrganisationContext } from '../../../user/context/OrganisationContext'
import CircularProgress from '@material-ui/core/CircularProgress'
import FileDrop from 'react-file-drop'

const axios = require('axios')
const shortid = require('shortid')
const uuidv4 = require('uuid/v4')
var jwtDecode = require('jwt-decode')

class CustomProductDetail extends Component {
  state = {
    uploading: false
  }

  uploadS3 = async (
    myFile,
    uploadFile,
    uploadVars,
    product,
    isImage = false
  ) => {
    //---------------Validations
    if (myFile.size > 300 * 1000 * 1000) {
      return { success: false, msg: 'Only files up to 300MB supported.' }
    }

    if (isImage) {
      const image = await new Promise((resolve, reject) => {
        var fr = new FileReader()
        fr.onload = function() {
          // file is loaded
          var img = new Image()

          img.src = fr.result // is the data URL because called with readAsDataURL
          resolve(img)
        }

        fr.onabort = function() {
          reject(null)
        }

        fr.readAsDataURL(myFile)
      })

      if (image && image.width) {
        if (image.width > 6000 || image.height > 6000) {
          return {
            success: false,
            msg: 'The image dimension should not more than 6000px'
          }
        }
      } else {
        return { success: false, msg: 'This is not an image.' }
      }
    }

    //---------------End Validations

    const variables = {
      type: 'PRODUCT_BASE_TEMPLATE',
      fileName: 'productDefaultTemplate.png',
      prefixVariables: ['productId', 'revision'],
      input: {
        productId: product.id,
        revision: product.lastElementRevision,
        elementRevision: product.lastElementRevision,
        designStudioVersion: product.designStudioVersion
      },
      ...uploadVars
    }

    const signedUrlResult = await uploadFile({
      variables
    })
    console.log('signedUrlResult', signedUrlResult)
    const signedUrl = signedUrlResult.data.uploadFile.url
    var options = {
      headers: {
        'Content-Type': signedUrlResult.data.uploadFile.contentType
      }
    }
    if (signedUrlResult.data.uploadFile.acl) {
      options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl
    }
    if (signedUrlResult.data.uploadFile.tagging) {
      options.headers['x-amz-tagging'] = signedUrlResult.data.uploadFile.tagging
    }
    const uploadResult = await axios.put(signedUrl, myFile, options)

    return { success: true, msg: 'Upload successfully.' }
  }

  uploadProductDefaultTemplateFiles = async (
    files,
    uploadFile,
    product,
    refetch
  ) => {
    const {} = this.props
    this.setState({
      uploading: true
    })
    for (var i = 0; i < files.length; i++) {
      const uploadResult = await this.uploadS3(
        files[i],
        uploadFile,
        {
          type: 'PRODUCT_BASE_TEMPLATE',
          fileName: 'productDefaultTemplate.png'
        },
        product,
        true
      )
      if (!uploadResult.success) {
        this.setState({
          uploading: false
        })
        alert(uploadResult.msg)
        break
      }
    }
    refetch()
    this.setState({
      uploading: false
    })
  }
  uploadProductTemplateFiles = async (files, uploadFile, product, refetch) => {
    const {} = this.props
    this.setState({
      uploading: true
    })
    for (var i = 0; i < files.length; i++) {
      const uploadResult = await this.uploadS3(
        files[i],
        uploadFile,
        {
          type: 'PRODUCT_BASE_TEMPLATE',
          fileName: 'productBaseTemplate.svg'
        },
        product,
        false
      )
      if (!uploadResult.success) {
        this.setState({
          uploading: false
        })
        alert(uploadResult.msg)
        break
      }
    }
    refetch()
    this.setState({
      uploading: false
    })
  }
  uploadProductSeamFiles = async (files, uploadFile, product, refetch) => {
    const {} = this.props
    this.setState({
      uploading: true
    })
    for (var i = 0; i < files.length; i++) {
      const uploadResult = await this.uploadS3(
        files[i],
        uploadFile,
        {
          type: 'PRODUCT_SEAM',
          fileName: 'productSeam.png'
        },
        product,
        true
      )
      if (!uploadResult.success) {
        this.setState({
          uploading: false
        })
        alert(uploadResult.msg)
        break
      }
    }
    refetch()
    this.setState({
      uploading: false
    })
  }
  uploadProductMaterialFiles = async (files, uploadFile, product, refetch) => {
    const {} = this.props
    this.setState({
      uploading: true
    })
    for (var i = 0; i < files.length; i++) {
      const uploadResult = await this.uploadS3(
        files[i],
        uploadFile,
        {
          type: 'PRODUCT_MATERIAL',
          fileName: 'product.mtl'
        },
        product,
        false
      )
      if (!uploadResult.success) {
        this.setState({
          uploading: false
        })
        alert(uploadResult.msg)
        break
      }
    }
    refetch()
    this.setState({
      uploading: false
    })
  }
  uploadProductModelGLTFFiles = async (files, uploadFile, product, refetch) => {
    const {} = this.props
    this.setState({
      uploading: true
    })
    for (var i = 0; i < files.length; i++) {
      const uploadResult = await this.uploadS3(
        files[i],
        uploadFile,
        {
          type: 'PRODUCT_MODEL',
          fileName: 'product.gltf'
        },
        product,
        false
      )
      if (!uploadResult.success) {
        this.setState({
          uploading: false
        })
        alert(uploadResult.msg)
        break
      } else {
        alert('Done. Make sure you upload the mtl file as a next step')
      }
    }
    refetch()
    this.setState({
      uploading: false
    })
  }
  uploadProductModelFiles = async (files, uploadFile, product, refetch) => {
    const {} = this.props
    this.setState({
      uploading: true
    })
    for (var i = 0; i < files.length; i++) {
      const uploadResult = await this.uploadS3(
        files[i],
        uploadFile,
        {
          type: 'PRODUCT_MODEL',
          fileName: 'product.obj',
          input: {
            productId: product.id,
            revision: product.lastElementRevision + 1,
            elementRevision: product.lastElementRevision + 1,
            designStudioVersion: product.designStudioVersion
          }
        },
        product,
        false
      )
      if (!uploadResult.success) {
        this.setState({
          uploading: false
        })
        alert(uploadResult.msg)
        break
      } else {
        alert('Done. Make sure you upload the mtl file as a next step')
      }
    }
    refetch()
    this.setState({
      uploading: false
    })
  }
  render() {
    const {
      blockEdit,
      classes,
      defaultCurrency,
      product,
      productId,
      currentOrganisation,
      refetch,
      updateProduct,
      state,
      isBriklUser,
      shopId
    } = this.props
    return (
      <div>
        {/* 
***************************
Measurement Tables starts
***************************
*/}

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            Measurement table
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: '0px 8px 0px 16px'
                      }}
                    >
                      Element
                    </TableCell>
                    <TableCell
                      style={{
                        padding: '0px 8px 0px 16px'
                      }}
                      align="left"
                    >
                      XS
                    </TableCell>
                    <TableCell
                      style={{
                        padding: '0px 8px 0px 16px'
                      }}
                      align="left"
                    >
                      S
                    </TableCell>
                    <TableCell
                      style={{
                        padding: '0px 8px 0px 16px'
                      }}
                      align="left"
                    >
                      M
                    </TableCell>
                    <TableCell
                      style={{
                        padding: '0px 8px 0px 16px'
                      }}
                      align="left"
                    >
                      L
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_get(product, 'measurementTable.measurements', []).map(
                    (measurementPoint, index) => {
                      return (
                        <TableRow>
                          {_get(measurementPoint, 'description.text', []).map(
                            (DescriptionText, DescriptionTextIndex) => {
                              return (
                                <TableCell
                                  key={
                                    'style-DescriptionText-' +
                                    DescriptionTextIndex
                                  }
                                  component="th"
                                  scope="row"
                                  style={{
                                    padding: '0px 8px 0px 16px'
                                  }}
                                >
                                  <Input
                                    placeholder="Enter element"
                                    minWidth={230}
                                    borderColor="transparent"
                                    fontSize="13px"
                                    id={
                                      'outlined-DescriptionText-' +
                                      DescriptionTextIndex
                                    }
                                    value={DescriptionText.content}
                                    onChange={e =>
                                      updateProduct({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateProduct: {
                                            ...product,
                                            measurementTable: {
                                              ...product.measurementTable,
                                              measurements: product.measurementTable.measurements.map(
                                                (mPoint, sizeUnitsIndex2) => {
                                                  if (
                                                    index === sizeUnitsIndex2
                                                  ) {
                                                    return {
                                                      ...mPoint,
                                                      description: {
                                                        ...mPoint.description,
                                                        text: measurementPoint.description.text.map(
                                                          (
                                                            tempDescriptionText,
                                                            tempDescriptionTextIndex
                                                          ) => {
                                                            if (
                                                              DescriptionTextIndex ===
                                                              tempDescriptionTextIndex
                                                            ) {
                                                              return {
                                                                ...tempDescriptionText,
                                                                content:
                                                                  e.target.value
                                                              }
                                                            } else {
                                                              return tempDescriptionText
                                                            }
                                                          }
                                                        )
                                                      }
                                                    }
                                                  } else {
                                                    return mPoint
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        },
                                        variables: {
                                          id: productId,
                                          input: {
                                            measurementTable: {
                                              measurements: product.measurementTable.measurements.map(
                                                (
                                                  mPoint,
                                                  DescriptionTextIndex3
                                                ) => {
                                                  if (
                                                    index ===
                                                    DescriptionTextIndex3
                                                  ) {
                                                    return {
                                                      elementId:
                                                        mPoint.elementId,
                                                      description: {
                                                        text: measurementPoint.description.text.map(
                                                          (
                                                            tempDescriptionText,
                                                            tempDescriptionTextIndex
                                                          ) => {
                                                            if (
                                                              DescriptionTextIndex ===
                                                              tempDescriptionTextIndex
                                                            ) {
                                                              return {
                                                                langCode:
                                                                  tempDescriptionText.langCode,
                                                                content:
                                                                  e.target.value
                                                              }
                                                            } else {
                                                              return {
                                                                langCode:
                                                                  tempDescriptionText.langCode,
                                                                content:
                                                                  tempDescriptionText.content
                                                              }
                                                            }
                                                          }
                                                        )
                                                      },
                                                      part: {
                                                        text: mPoint.description.text.map(
                                                          ({
                                                            __typename,
                                                            ...keepAttrs
                                                          }) => keepAttrs
                                                        )
                                                      },
                                                      sizeUnits: measurementPoint.sizeUnits.map(
                                                        ({
                                                          __typename,
                                                          ...keepAttrs
                                                        }) => keepAttrs
                                                      )
                                                    }
                                                  } else {
                                                    return {
                                                      elementId:
                                                        mPoint.elementId,
                                                      part: {
                                                        text: mPoint.part.text.map(
                                                          ({
                                                            __typename,
                                                            ...keepAttrs
                                                          }) => keepAttrs
                                                        )
                                                      },
                                                      description: {
                                                        text: mPoint.description.text.map(
                                                          ({
                                                            __typename,
                                                            ...keepAttrs
                                                          }) => keepAttrs
                                                        )
                                                      },
                                                      sizeUnits: mPoint.sizeUnits.map(
                                                        ({
                                                          __typename,
                                                          ...keepAttrs
                                                        }) => keepAttrs
                                                      )
                                                    }
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        }
                                      })
                                    }
                                  />
                                </TableCell>
                              )
                            }
                          )}
                          {_get(measurementPoint, 'sizeUnits', []).map(
                            (sizeUnit, sizeUnitsIndex) => {
                              return (
                                <TableCell
                                  align="right"
                                  key={'style-' + sizeUnitsIndex}
                                  style={{
                                    padding: '0px 8px 0px 16px'
                                  }}
                                >
                                  <Input
                                    id={'outlined-content-' + sizeUnitsIndex}
                                    minWidth={30}
                                    borderColor="transparent"
                                    border="none"
                                    fontSize="13px"
                                    value={sizeUnit.content}
                                    onChange={e =>
                                      updateProduct({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateProduct: {
                                            ...product,
                                            measurementTable: {
                                              ...product.measurementTable,
                                              measurements: product.measurementTable.measurements.map(
                                                (mPoint, sizeUnitsIndex2) => {
                                                  if (
                                                    index === sizeUnitsIndex2
                                                  ) {
                                                    return {
                                                      ...mPoint,
                                                      sizeUnits: measurementPoint.sizeUnits.map(
                                                        (
                                                          tempSizeUnit,
                                                          tempSizeUnitIndex
                                                        ) => {
                                                          if (
                                                            sizeUnitsIndex ===
                                                            tempSizeUnitIndex
                                                          ) {
                                                            return {
                                                              ...tempSizeUnit,
                                                              value: parseFloat(
                                                                e.target.value
                                                              )
                                                            }
                                                          } else {
                                                            return tempSizeUnit
                                                          }
                                                        }
                                                      )
                                                    }
                                                  } else {
                                                    return mPoint
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        },
                                        variables: {
                                          id: productId,
                                          input: {
                                            measurementTable: {
                                              measurements: product.measurementTable.measurements.map(
                                                (mPoint, sizeUnitsIndex3) => {
                                                  if (
                                                    index === sizeUnitsIndex3
                                                  ) {
                                                    return {
                                                      elementId:
                                                        mPoint.elementId,
                                                      part: {
                                                        text: mPoint.part.text.map(
                                                          ({
                                                            __typename,
                                                            ...keepAttrs
                                                          }) => keepAttrs
                                                        )
                                                      },
                                                      description: {
                                                        text: mPoint.description.text.map(
                                                          ({
                                                            __typename,
                                                            ...keepAttrs
                                                          }) => keepAttrs
                                                        )
                                                      },
                                                      sizeUnits: measurementPoint.sizeUnits.map(
                                                        (
                                                          tempSizeUnit,
                                                          tempSizeUnitIndex
                                                        ) => {
                                                          if (
                                                            sizeUnitsIndex ===
                                                            tempSizeUnitIndex
                                                          ) {
                                                            return {
                                                              size:
                                                                tempSizeUnit.size,
                                                              value: parseFloat(
                                                                e.target.value
                                                              ),
                                                              content:
                                                                tempSizeUnit.content,
                                                              length:
                                                                tempSizeUnit.length
                                                            }
                                                          } else {
                                                            return {
                                                              size:
                                                                tempSizeUnit.size,
                                                              value:
                                                                tempSizeUnit.value,
                                                              content:
                                                                tempSizeUnit.content,
                                                              length:
                                                                tempSizeUnit.length
                                                            }
                                                          }
                                                        }
                                                      )
                                                    }
                                                  } else {
                                                    return {
                                                      elementId:
                                                        mPoint.elementId,
                                                      part: {
                                                        text: mPoint.part.text.map(
                                                          ({
                                                            __typename,
                                                            ...keepAttrs
                                                          }) => keepAttrs
                                                        )
                                                      },
                                                      description: {
                                                        text: mPoint.description.text.map(
                                                          ({
                                                            __typename,
                                                            ...keepAttrs
                                                          }) => keepAttrs
                                                        )
                                                      },
                                                      sizeUnits: mPoint.sizeUnits.map(
                                                        ({
                                                          __typename,
                                                          ...keepAttrs
                                                        }) => keepAttrs
                                                      )
                                                    }
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        }
                                      })
                                    }
                                  />
                                </TableCell>
                              )
                            }
                          )}
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>

        {/* 
***************************
Measurement Tables Ends
***************************
*/}

        {/* 
***************************
Elements starts
***************************
*/}

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <h3>Elements (Revision: {product.lastElementRevision})</h3>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper style={{ padding: 24 }} elevation={0}>
              <Query query={ADMIN_SHOP_FABRICS} variables={{ id: shopId }}>
                {({ loading, error, data: { shop } }) => {
                  if (loading)
                    return <CircularProgress className={classes.progress} />
                  if (error) return <div>Error :(</div>
                  return (
                    <Grid container>
                      {_get(product, 'latestElements', []).map(
                        (element, index) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              style={{ marginBottom: '32px' }}
                              key={'style-element-' + index}
                            >
                              <ProductElement
                                productElement={element}
                                fabrics={_get(shop, 'fabrics.edges', [])}
                                fabricGroups={_get(
                                  shop,
                                  'fabricGroups.edges',
                                  []
                                )}
                                elementId={element.id}
                                productId={productId}
                                elementRevision={element.elementRevision}
                                code={element.code}
                              />
                            </Grid>
                          )
                        }
                      )}
                    </Grid>
                  )
                }}
              </Query>
            </Paper>
          </Grid>
        </Grid>

        {/* 
***************************
Elements  ends
***************************
*/}

        {/* 
***************************
Templates  starts
***************************
*/}

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <h3>Finished designs</h3>
            <h4>Select from your library</h4>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper style={{ padding: '0px 24px 24px' }} elevation={0}>
              <Grid container>
                {/* <TextField
                                  id="outlined-newFinishedDesgin"
                                  label="Enter design ID to add new finished design"
                                  value={this.state.newFinishedDesgin || ''}
                                  onChange={e =>
                                    this.setState({
                                      newFinishedDesgin: e.target.value
                                    })
                                  }
                                  margin="normal"
                                  
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.cssLabel,
                                      focused: classes.cssFocused
                                    }
                                  }}
                                  InputProps={{
                                    classes: {
                                      root: classes.cssOutlinedInput,
                                      focused: classes.cssFocused
                                    }
                                  }}
                                /> */}
                <h4>Finished design</h4>
                <Grid
                  item
                  xs={12}
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    marginBottom: 24
                  }}
                >
                  <Grid container direction="row">
                    <Query
                      query={ADMIN_GET_ORG_BOARDPRODUCTS}
                      skip={true}
                      variables={{
                        ownedByOrganisationId: currentOrganisation
                          ? currentOrganisation.id
                          : null
                      }}
                    >
                      {({ loading, error, data }) => {
                        if (error) return <div>Error :(</div>
                        var customerBoardProducts = []
                        try {
                          if (data.customerBoardProducts.edges) {
                            customerBoardProducts = _sortBy(
                              data.customerBoardProducts.edges,
                              function(el) {
                                return el.node.createdAt
                              }
                            )
                              .reverse()
                              .filter(
                                myBoardProduct =>
                                  myBoardProduct.node.baseProductId ===
                                  product.id
                              )
                          }
                        } catch (error) {}
                        console.log(
                          'customerBoardProducts',
                          customerBoardProducts
                        )
                        return customerBoardProducts.map(boardProduct => (
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            md={4}
                            lg={3}
                            xl={3}
                            style={{
                              position: 'relative',
                              height: ' 180px',
                              display: 'inline-block',
                              borderRadius: '5px'
                            }}
                          >
                            <BoardProductPreview
                              ownedByOrganisationId={
                                boardProduct.node.ownedByOrganisationId
                              }
                              boardProductId={boardProduct.node.id}
                            />

                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                fontSize: '12px'
                              }}
                              onClick={e => {
                                this.addNewFinishedDesign(
                                  boardProduct,
                                  product,
                                  updateProduct,
                                  productId,
                                  process.env.GATSBY_BRIKL_SALESCHANNEL_ID
                                    ? process.env.GATSBY_BRIKL_SALESCHANNEL_ID
                                    : 'MYBRIKL'
                                )
                              }}
                            >
                              Add
                            </Button>
                          </Grid>
                        ))
                      }}
                    </Query>
                  </Grid>
                </Grid>
                <h4>Published</h4>
                <Grid
                  container
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    minHeight: '182px',
                    height: 'auto'
                  }}
                >
                  {product.finishedDesigns && product.finishedDesigns.designs
                    ? product.finishedDesigns.designs.map(finishedDesign => {
                        return (
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            md={4}
                            lg={3}
                            xl={3}
                            style={{
                              position: 'relative',
                              height: ' 180px',
                              display: 'inline-block',
                              borderRadius: '5px'
                            }}
                          >
                            <img
                              style={{
                                width: '100%',
                                height: ' 180px',
                                display: 'block',
                                objectFit: 'cover'
                              }}
                              src={`https://s3-ap-southeast-1.amazonaws.com/${API_STAGE}-brikl-shop-product-design-previews/${finishedDesign.boardProduct.id}/0.jpg`}
                              alt="Preview"
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                fontSize: '12px'
                              }}
                              onClick={e => {
                                this.removeFinishedDesign(
                                  finishedDesign.boardProduct.id,
                                  product,
                                  updateProduct,
                                  finishedDesign.salesChannelType
                                )
                              }}
                            >
                              Remove
                            </Button>
                          </Grid>
                        )
                      })
                    : null}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {/* 
***************************
Templates  ends
***************************
*/}

        {/* 
***************************
Config  starts
***************************
*/}

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <h3>Oject configuration</h3>
            <h4>Brikl access only</h4>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper style={{ padding: 24 }} elevation={0}>
              <h3 style={{ display: 'none' }}>Attach configuration</h3>
              <Grid>
                <Input
                  style={{ display: 'none' }}
                  disabled={blockEdit}
                  id="outlined-modelRatio"
                  label="manualAttatchObject"
                  value={_get(product, 'manualAttatchObject', '')}
                  onChange={e =>
                    updateProduct({
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateProduct: {
                          ...product,
                          manualAttatchObject: e.target.value
                        }
                      },
                      variables: {
                        id: productId,
                        input: {
                          manualAttatchObject: e.target.value
                        }
                      }
                    })
                  }
                  margin="normal"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused
                    }
                  }}
                />
              </Grid>
              <Grid>
                <Input
                  style={{ display: 'none' }}
                  disabled={blockEdit}
                  id="outlined-modelRatio"
                  label="manualAttatchPosition.x"
                  value={_get(product, 'manualAttatchPosition.x', 0)}
                  onChange={e =>
                    updateProduct({
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateProduct: {
                          ...product,
                          manualAttatchPosition: product.manualAttatchPosition
                            ? {
                                ...product.manualAttatchPosition,
                                x: parseFloat(e.target.value)
                              }
                            : {
                                x: parseFloat(e.target.value)
                              }
                        }
                      },
                      variables: {
                        id: productId,
                        input: {
                          manualAttatchPosition: {
                            x: parseFloat(e.target.value)
                          }
                        }
                      }
                    })
                  }
                  margin="normal"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused
                    }
                  }}
                />
              </Grid>
              <h3>Model configuration</h3>
              <Grid>
                <Input
                  style={{ display: 'none' }}
                  disabled={blockEdit}
                  id="outlined-modelRatio"
                  label="Model ratio"
                  value={_get(product, 'modelConfiguration.modelRatio', 0)}
                  onChange={e =>
                    updateProduct({
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateProduct: {
                          ...product,
                          modelConfiguration: product.modelConfiguration
                            ? {
                                ...product.modelConfiguration,
                                modelRatio: parseFloat(e.target.value)
                              }
                            : {
                                modelRatio: parseFloat(e.target.value)
                              }
                        }
                      },
                      variables: {
                        id: productId,
                        input: {
                          modelConfiguration: {
                            modelRatio: parseFloat(e.target.value)
                          }
                        }
                      }
                    })
                  }
                  margin="normal"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused
                    }
                  }}
                />
                <Input
                  style={{ display: 'none' }}
                  disabled={blockEdit}
                  id="outlined-modelTextureRatio"
                  label="Model texture ratio"
                  value={_get(
                    product,
                    'modelConfiguration.modelTextureRatio',
                    0
                  )}
                  onChange={e =>
                    updateProduct({
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateProduct: {
                          ...product,
                          modelConfiguration: {
                            ...product.modelConfiguration,
                            modelTextureRatio: parseFloat(e.target.value)
                          }
                        }
                      },
                      variables: {
                        id: productId,
                        input: {
                          modelConfiguration: {
                            modelTextureRatio: parseFloat(e.target.value)
                          }
                        }
                      }
                    })
                  }
                  margin="normal"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused
                    }
                  }}
                />
                <Input
                  style={{ display: 'none' }}
                  disabled={blockEdit}
                  id="outlined-modelSide"
                  label="Model size"
                  value={_get(product, 'modelConfiguration.modelSide', 0)}
                  onChange={e =>
                    updateProduct({
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateProduct: {
                          ...product,
                          modelConfiguration: {
                            ...product.modelConfiguration,
                            modelSide: parseInt(e.target.value, 10)
                          }
                        }
                      },
                      variables: {
                        id: productId,
                        input: {
                          modelConfiguration: {
                            modelSide: parseInt(e.target.value, 10)
                          }
                        }
                      }
                    })
                  }
                  margin="normal"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused
                    }
                  }}
                />
                <Input
                  style={{ display: 'none' }}
                  disabled={blockEdit}
                  id="outlined-modelRotationX"
                  label="Model rotation X"
                  value={_get(product, 'modelConfiguration.modelRotation.x', 0)}
                  onChange={e =>
                    updateProduct({
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateProduct: {
                          ...product,
                          modelConfiguration: {
                            ...product.modelConfiguration,
                            modelRotation:
                              product.modelConfiguration &&
                              product.modelConfiguration.modelRotation
                                ? {
                                    ...product.modelConfiguration.modelRotation,
                                    x: parseFloat(e.target.value)
                                  }
                                : {
                                    x: parseFloat(e.target.value)
                                  }
                          }
                        }
                      },
                      variables: {
                        id: productId,
                        input: {
                          modelConfiguration: {
                            modelRotation: {
                              x: parseFloat(e.target.value)
                            }
                          }
                        }
                      }
                    })
                  }
                  margin="normal"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused
                    }
                  }}
                />
                <Input
                  style={{ display: 'none' }}
                  disabled={blockEdit}
                  id="outlined-modelRotationY"
                  label="Model rotation Y"
                  value={_get(product, 'modelConfiguration.modelRotation.y', 0)}
                  onChange={e =>
                    updateProduct({
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateProduct: {
                          ...product,
                          modelConfiguration: {
                            ...product.modelConfiguration,
                            modelRotation: {
                              ...product.modelConfiguration.modelRotation,
                              y: parseFloat(e.target.value)
                            }
                          }
                        }
                      },
                      variables: {
                        id: productId,
                        input: {
                          modelConfiguration: {
                            modelRotation: {
                              y: parseFloat(e.target.value)
                            }
                          }
                        }
                      }
                    })
                  }
                  margin="normal"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused
                    }
                  }}
                />
                <Grid item xs={3} sm={2} md={2} lg={2} xl={1}>
                  <Input
                    label="model rotation"
                    placeholder="model rotation"
                    classes={{
                      root: classes.cssRootInput,
                      input: classes.cssinput
                    }}
                    disabled={blockEdit}
                    id="outlined-modelRotationZ"
                    value={_get(
                      product,
                      'modelConfiguration.modelRotation.z',
                      0
                    )}
                    onChange={e =>
                      updateProduct({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateProduct: {
                            ...product,
                            modelConfiguration: {
                              ...product.modelConfiguration,
                              modelRotation: {
                                ...product.modelConfiguration.modelRotation,
                                z: parseFloat(e.target.value)
                              }
                            }
                          }
                        },
                        variables: {
                          id: productId,
                          input: {
                            modelConfiguration: {
                              modelRotation: {
                                z: parseFloat(e.target.value)
                              }
                            }
                          }
                        }
                      })
                    }
                    margin="normal"
                  />
                </Grid>
                {process.env.GATSBY_API_STAGE === 'local' ||
                process.env.GATSBY_API_STAGE === 'dev' ? (
                  <div
                    style={{
                      position: 'relative',
                      right: 0,
                      width: '200px'
                    }}
                    style={{ display: 'none' }}
                  >
                    {/* <h4>Preview</h4> */}
                    {/* <MTLModel
                      width={100}
                      height={100}
                      enableZoom={false}
                      position={{ x: 0, y: 0, z: 0 }}
                      rotation={_get(
                        product,
                        'modelConfiguration.modelRotation',
                        {}
                      )}
                      // texPath="./src/lib/model/"
                      mtl={product.mtlSrc}
                      src={product.objSrc}
                    /> */}
                  </div>
                ) : null}

                <p>
                  Provide real size measurement of the garment element to
                  calculate the accuracy of the 3D render.
                  <br /> ( Real life to 3D ratio )
                </p>
                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12} sm={12} md={7} lg={5} xl={3}>
                    <Input
                      label="Element name for real size reference (
                                          e.g Front panel )"
                      classes={{
                        root: classes.cssRootInput,
                        input: classes.cssinput
                      }}
                      disabled={blockEdit}
                      id="outlined-actualWidthELM"
                      value={_get(
                        product,
                        'modelConfiguration.actualWidthELM',
                        ''
                      )}
                      onChange={e =>
                        updateProduct({
                          optimisticResponse: {
                            __typename: 'Mutation',
                            updateProduct: {
                              ...product,
                              modelConfiguration: {
                                ...product.modelConfiguration,
                                actualWidthELM: e.target.value
                              }
                            }
                          },
                          variables: {
                            id: productId,
                            input: {
                              modelConfiguration: {
                                actualWidthELM: e.target.value
                              }
                            }
                          }
                        })
                      }
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Input
                      label="Element width in cm"
                      classes={{
                        root: classes.cssRootInput,
                        input: classes.cssinput
                      }}
                      disabled={blockEdit}
                      id="outlined-actualWidthCm"
                      value={_get(
                        product,
                        'modelConfiguration.actualWidthCm',
                        ''
                      )}
                      onChange={e =>
                        updateProduct({
                          optimisticResponse: {
                            __typename: 'Mutation',
                            updateProduct: {
                              ...product,
                              modelConfiguration: {
                                ...product.modelConfiguration,
                                actualWidthCm: parseFloat(e.target.value)
                              }
                            }
                          },
                          variables: {
                            id: productId,
                            input: {
                              modelConfiguration: {
                                actualWidthCm: parseFloat(e.target.value)
                              }
                            }
                          }
                        })
                      }
                      margin="normal"
                    />
                    <Input
                      label="Element height in cm"
                      classes={{
                        root: classes.cssRootInput,
                        input: classes.cssinput
                      }}
                      disabled={blockEdit}
                      id="outlined-actualHeightCm"
                      value={_get(
                        product,
                        'modelConfiguration.actualHeightCm',
                        ''
                      )}
                      onChange={e =>
                        updateProduct({
                          optimisticResponse: {
                            __typename: 'Mutation',
                            updateProduct: {
                              ...product,
                              modelConfiguration: {
                                ...product.modelConfiguration,
                                actualHeightCm: parseFloat(e.target.value)
                              }
                            }
                          },
                          variables: {
                            id: productId,
                            input: {
                              modelConfiguration: {
                                actualHeightCm: parseFloat(e.target.value)
                              }
                            }
                          }
                        })
                      }
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                {/* V2 Studio */}
                <h3>V2 Studio settings</h3>
                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12} sm={12} md={7} lg={5} xl={3}>
                    <Input
                      label="Base template size in px"
                      classes={{
                        root: classes.cssRootInput,
                        input: classes.cssinput
                      }}
                      disabled={blockEdit}
                      id="outlined-baseTemplate"
                      value={_get(
                        product,
                        'modelConfiguration.dimension.baseTemplate',
                        ''
                      )}
                      onChange={e =>
                        updateProduct({
                          optimisticResponse: {
                            __typename: 'Mutation',
                            updateProduct: {
                              ...product,
                              modelConfiguration: {
                                ...product.modelConfiguration,
                                dimension:
                                  product.modelConfiguration &&
                                  product.modelConfiguration.dimension
                                    ? {
                                        ...product.modelConfiguration.dimension,
                                        baseTemplate: e.target.value
                                          ? parseFloat(e.target.value)
                                          : 0
                                      }
                                    : {
                                        baseTemplate: e.target.value
                                          ? parseFloat(e.target.value)
                                          : 0
                                      }
                              }
                            }
                          },
                          variables: {
                            id: productId,
                            input: {
                              modelConfiguration: {
                                dimension: {
                                  baseTemplate: e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0
                                }
                              }
                            }
                          }
                        })
                      }
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Select
                      label="Base size reference (width/height)"
                      className={classes.select}
                      value={_get(
                        product,
                        'modelConfiguration.dimension.baseSize',
                        ''
                      )}
                      onChange={e =>
                        updateProduct({
                          optimisticResponse: {
                            __typename: 'Mutation',
                            updateProduct: {
                              ...product,
                              modelConfiguration: {
                                ...product.modelConfiguration,
                                dimension:
                                  product.modelConfiguration &&
                                  product.modelConfiguration.dimension
                                    ? {
                                        ...product.modelConfiguration.dimension,
                                        baseSize: e.target.value
                                      }
                                    : {
                                        baseSize: e.target.value
                                      }
                              }
                            }
                          },
                          variables: {
                            id: productId,
                            input: {
                              modelConfiguration: {
                                dimension: {
                                  baseSize: e.target.value
                                }
                              }
                            }
                          }
                        })
                      }
                    >
                      <option value={''} />
                      <option value={'WIDTH'}>Width</option>
                      <option value={'HEIGHT'}>Height</option>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Input
                      label="Pixel to cm conversion"
                      classes={{
                        root: classes.cssRootInput,
                        input: classes.cssinput
                      }}
                      disabled={blockEdit}
                      id="outlined-actualHeightCm"
                      value={_get(
                        product,
                        'modelConfiguration.dimension.pxToCm',
                        ''
                      )}
                      onChange={e =>
                        updateProduct({
                          optimisticResponse: {
                            __typename: 'Mutation',
                            updateProduct: {
                              ...product,
                              modelConfiguration: {
                                ...product.modelConfiguration,
                                dimension:
                                  product.modelConfiguration &&
                                  product.modelConfiguration.dimension
                                    ? {
                                        ...product.modelConfiguration.dimension,
                                        pxToCm: e.target.value
                                          ? parseFloat(e.target.value)
                                          : 0
                                      }
                                    : {
                                        pxToCm: e.target.value
                                          ? parseFloat(e.target.value)
                                          : 0
                                      }
                              }
                            }
                          },
                          variables: {
                            id: productId,
                            input: {
                              modelConfiguration: {
                                dimension: {
                                  pxToCm: e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0
                                }
                              }
                            }
                          }
                        })
                      }
                      margin="normal"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {/* 
***************************
Config  ends
***************************
*/}

        {/* 
***************************
Upload OBJ & MTL starts
***************************
*/}

        <Grid
          container
          style={{
            marginTop: 24,
            display: isBriklUser === true ? 'flex' : 'none'
          }}
        >
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Upload OBJ 3D Model
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">Brikl access only</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper style={{ padding: 24 }} elevation={0}>
              <Grid>
                <Select
                  label="Design studio version"
                  className={classes.select}
                  value={product.designStudioVersion}
                  onChange={e =>
                    updateProduct({
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateProduct: {
                          ...product,
                          designStudioVersion: e.target.value
                        }
                      },
                      variables: {
                        id: productId,
                        input: {
                          designStudioVersion: e.target.value
                        }
                      }
                    })
                  }
                >
                  <option value={'V1'}>Version 1</option>
                  <option value={'V2'}>Version 2</option>
                </Select>
              </Grid>
              {this.state.uploading === true ? (
                <span
                  style={{
                    fontSize: '3em'
                  }}
                >
                  Uploading...
                </span>
              ) : null}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <h3>
                    Upload OBJ 3D Model
                    <Tooltip
                      className="sssd"
                      title="File size should not be more than 300MB."
                      placement="right-start"
                    >
                      <InfoIcon
                        color="primary"
                        style={{ fontSize: 15, marginLeft: 5 }}
                      />
                    </Tooltip>
                  </h3>
                  {product.hasObjFile === true ? '✔ Uploaded' : '✘ No file yet'}
                  <Mutation
                    update={(store, { data: { uploadFile } }) => {}}
                    mutation={ADMIN_UPLOAD_FILE}
                  >
                    {uploadFile => (
                      <Grid>
                        <input
                          style={{
                            display: 'none'
                          }}
                          accept=".obj"
                          id="productModelUploadInput"
                          type="file"
                          onChange={e => {
                            this.uploadProductModelFiles(
                              e.target.files,
                              uploadFile,
                              product,
                              refetch
                            )
                          }}
                        />
                        <Grid
                          data-cy="product-model-drag-and-drop-container"
                          style={{
                            border: '1px solid #ccc',
                            margin: '.5rem 0',
                            borderRadius: '4px',
                            padding: '24px',
                            background: '#f4f4f4'
                          }}
                        >
                          <FileDrop
                            onDrop={(files, event) => {
                              console.log('handleDrop!', files, event)
                              this.uploadProductModelFiles(
                                files,
                                uploadFile,
                                product,
                                refetch
                              )
                            }}
                          >
                            <Grid container spacing={1} direction="column">
                              <Grid item>
                                <span>
                                  {I18n.get('Drag your file here ')}
                                  .obj
                                </span>
                              </Grid>
                              <Grid item>{I18n.get('or')}</Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    document
                                      .getElementById('productModelUploadInput')
                                      .click()
                                  }}
                                  aria-label="Upload"
                                >
                                  {I18n.get('Upload')}
                                </Button>
                              </Grid>
                            </Grid>
                          </FileDrop>
                        </Grid>
                      </Grid>
                    )}
                  </Mutation>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <h3>
                    Upload product MTL
                    <Tooltip
                      className="sssd"
                      title="File size should not be more than 300MB."
                      placement="right-start"
                    >
                      <InfoIcon
                        color="primary"
                        style={{ fontSize: 15, marginLeft: 5 }}
                      />
                    </Tooltip>
                  </h3>
                  {product.hasMtlFile === true ? '✔ Uploaded' : '✘ No file yet'}
                  <Mutation
                    update={(store, { data: { uploadFile } }) => {
                      console.log('uploadFile', uploadFile)
                      this.setState({
                        uploading: false
                      })
                      alert('Done')
                    }}
                    mutation={ADMIN_UPLOAD_FILE}
                  >
                    {uploadFile => (
                      <Grid>
                        <input
                          style={{
                            display: 'none'
                          }}
                          accept=".mtl"
                          id="productMaterialUploadInput"
                          type="file"
                          onChange={e => {
                            this.uploadProductMaterialFiles(
                              e.target.files,
                              uploadFile,
                              product,
                              refetch
                            )
                          }}
                        />
                        <div
                          data-cy="product-material-drag-and-drop-container"
                          style={{
                            border: '1px solid #ccc',
                            margin: '.5rem 0',
                            borderRadius: '4px',
                            padding: '24px',
                            background: '#f4f4f4'
                          }}
                        >
                          <FileDrop
                            onDrop={(files, event) => {
                              console.log('handleDrop!', files, event)
                              this.uploadProductMaterialFiles(
                                files,
                                uploadFile,
                                product,
                                refetch
                              )
                            }}
                          >
                            <Grid container spacing={1} direction="column">
                              <Grid item>
                                <span>
                                  {I18n.get('Drag your file here ')}
                                  .mtl
                                </span>
                              </Grid>
                              <Grid item>{I18n.get('or')}</Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    document
                                      .getElementById(
                                        'productMaterialUploadInput'
                                      )
                                      .click()
                                  }}
                                  aria-label="Upload"
                                >
                                  {I18n.get('Upload')}
                                </Button>
                              </Grid>
                            </Grid>
                          </FileDrop>
                        </div>
                      </Grid>
                    )}
                  </Mutation>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <h3>
                    Upload product seam PNG
                    <Tooltip
                      className="sssd"
                      title="File size should not be more than 300MB and dimension not wider than 6000px"
                      placement="right-start"
                    >
                      <InfoIcon
                        color="primary"
                        style={{ fontSize: 15, marginLeft: 5 }}
                      />
                    </Tooltip>
                  </h3>
                  {product.hasSeamFile === true
                    ? '✔ Uploaded'
                    : '✘ No file yet'}
                  <Mutation
                    update={(store, { data: { uploadFile } }) => {
                      console.log('uploadFile', uploadFile)
                      this.setState({
                        uploading: false
                      })
                      alert('Done')
                    }}
                    mutation={ADMIN_UPLOAD_FILE}
                  >
                    {uploadFile => (
                      <Grid>
                        <input
                          style={{
                            display: 'none'
                          }}
                          accept=".png"
                          id="productSeamUploadInput"
                          type="file"
                          onChange={e => {
                            this.uploadProductSeamFiles(
                              e.target.files,
                              uploadFile,
                              product,
                              refetch
                            )
                          }}
                        />
                        <div
                          data-cy="product-seam-drag-and-drop-container"
                          style={{
                            border: '1px solid #ccc',
                            margin: '.5rem 0',
                            borderRadius: '4px',
                            padding: '24px',
                            background: '#f4f4f4'
                          }}
                        >
                          <FileDrop
                            onDrop={(files, event) => {
                              console.log('handleDrop!', files, event)
                              this.uploadProductSeamFiles(
                                files,
                                uploadFile,
                                product,
                                refetch
                              )
                            }}
                          >
                            <Grid container spacing={1} direction="column">
                              <Grid item>
                                <span>
                                  {I18n.get('Drag your file here ')}
                                  .png
                                </span>
                              </Grid>
                              <Grid item>{I18n.get('or')}</Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    document
                                      .getElementById('productSeamUploadInput')
                                      .click()
                                  }}
                                  aria-label="Upload"
                                >
                                  {I18n.get('Upload')}
                                </Button>
                              </Grid>
                            </Grid>
                          </FileDrop>
                        </div>
                      </Grid>
                    )}
                  </Mutation>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <h3>
                    Upload base template SVG
                    <Tooltip
                      className="sssd"
                      title="File size should not be more than 300MB."
                      placement="right-start"
                    >
                      <InfoIcon
                        color="primary"
                        style={{ fontSize: 15, marginLeft: 5 }}
                      />
                    </Tooltip>
                  </h3>
                  {product.hasBaseTemplateFile === true
                    ? '✔ Uploaded'
                    : '✘ No file yet'}
                  <Mutation
                    update={(store, { data: { uploadFile } }) => {
                      console.log('uploadFile', uploadFile)
                      this.setState({
                        uploading: false
                      })
                      alert('Done')
                    }}
                    mutation={ADMIN_UPLOAD_FILE}
                  >
                    {uploadFile => (
                      <Grid>
                        <input
                          style={{
                            display: 'none'
                          }}
                          accept=".svg"
                          id="productBaseTemplateUploadInput"
                          type="file"
                          onChange={e => {
                            this.uploadProductTemplateFiles(
                              e.target.files,
                              uploadFile,
                              product,
                              refetch
                            )
                          }}
                        />
                        <div
                          data-cy="product-template-drag-and-drop-container"
                          style={{
                            border: '1px solid #ccc',
                            margin: '.5rem 0',
                            borderRadius: '4px',
                            padding: '24px',
                            background: '#f4f4f4'
                          }}
                        >
                          <FileDrop
                            onDrop={(files, event) => {
                              console.log('handleDrop!', files, event)
                              this.uploadProductTemplateFiles(
                                files,
                                uploadFile,
                                product,
                                refetch
                              )
                            }}
                          >
                            <Grid container spacing={1} direction="column">
                              <Grid item>
                                <span>
                                  {I18n.get('Drag your file here ')}
                                  .svg
                                </span>
                              </Grid>
                              <Grid item>{I18n.get('or')}</Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    document
                                      .getElementById(
                                        'productBaseTemplateUploadInput'
                                      )
                                      .click()
                                  }}
                                  aria-label="Upload"
                                >
                                  {I18n.get('Upload')}
                                </Button>
                              </Grid>
                            </Grid>
                          </FileDrop>
                        </div>
                      </Grid>
                    )}
                  </Mutation>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <h3>
                    Upload default template PNG
                    <Tooltip
                      className="sssd"
                      title="File size should not be more than 300MB and dimension not wider than 6000px"
                      placement="right-start"
                    >
                      <InfoIcon
                        color="primary"
                        style={{ fontSize: 15, marginLeft: 5 }}
                      />
                    </Tooltip>
                  </h3>
                  {product.hasDefaultTemplateFile === true
                    ? '✔ Uploaded'
                    : '✘ No file yet'}
                  <Mutation
                    update={(store, { data: { uploadFile } }) => {
                      console.log('uploadFile', uploadFile)
                      this.setState({
                        uploading: false
                      })
                      alert('Done')
                    }}
                    mutation={ADMIN_UPLOAD_FILE}
                  >
                    {uploadFile => (
                      <Grid>
                        <input
                          style={{
                            display: 'none'
                          }}
                          accept=".png"
                          id="productDefaultTemplateUploadInput"
                          type="file"
                          onChange={e => {
                            this.uploadProductDefaultTemplateFiles(
                              e.target.files,
                              uploadFile,
                              product,
                              refetch
                            )
                          }}
                        />
                        <div
                          data-cy="product-default-template-drag-and-drop-container"
                          style={{
                            border: '1px solid #ccc',
                            margin: '.5rem 0',
                            borderRadius: '4px',
                            padding: '24px',
                            background: '#f4f4f4'
                          }}
                        >
                          <FileDrop
                            onDrop={(files, event) => {
                              console.log('handleDrop!', files, event)
                              this.uploadProductDefaultTemplateFiles(
                                files,
                                uploadFile,
                                product,
                                refetch
                              )
                            }}
                          >
                            <Grid container spacing={1} direction="column">
                              <Grid item>
                                <span>
                                  {I18n.get('Drag your file here ')}
                                  .png
                                </span>
                              </Grid>
                              <Grid item>{I18n.get('or')}</Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    document
                                      .getElementById(
                                        'productDefaultTemplateUploadInput'
                                      )
                                      .click()
                                  }}
                                  aria-label="Upload"
                                >
                                  {I18n.get('Upload')}
                                </Button>
                              </Grid>
                            </Grid>
                          </FileDrop>
                        </div>
                      </Grid>
                    )}
                  </Mutation>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <h3>
                    Upload GLTF 3D Model
                    <Tooltip
                      className="sssd"
                      title="File size should not be more than 300MB."
                      placement="right-start"
                    >
                      <InfoIcon
                        color="primary"
                        style={{ fontSize: 15, marginLeft: 5 }}
                      />
                    </Tooltip>
                  </h3>
                  {product.hasGltfFile === true
                    ? '✔ Uploaded'
                    : '✘ No file yet'}
                  <Mutation
                    update={(store, { data: { uploadFile } }) => {}}
                    mutation={ADMIN_UPLOAD_FILE}
                  >
                    {uploadFile => (
                      <Grid>
                        <input
                          style={{
                            display: 'none'
                          }}
                          accept=".gltf"
                          id="productModelGLTFUploadInput"
                          type="file"
                          onChange={e => {
                            this.uploadProductModelGLTFFiles(
                              e.target.files,
                              uploadFile,
                              product,
                              refetch
                            )
                          }}
                        />
                        <Grid
                          data-cy="product-model-gltf-drag-and-drop-container"
                          style={{
                            border: '1px solid #ccc',
                            margin: '.5rem 0',
                            borderRadius: '4px',
                            padding: '24px',
                            background: '#f4f4f4'
                          }}
                        >
                          <FileDrop
                            onDrop={(files, event) => {
                              console.log('handleDrop!', files, event)
                              this.uploadProductModelGLTFFiles(
                                files,
                                uploadFile,
                                product,
                                refetch
                              )
                            }}
                          >
                            <Grid container spacing={1} direction="column">
                              <Grid item>
                                <span>
                                  {I18n.get('Drag your file here ')}
                                  .gltf
                                </span>
                              </Grid>
                              <Grid item>{I18n.get('or')}</Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    document
                                      .getElementById(
                                        'productModelGLTFUploadInput'
                                      )
                                      .click()
                                  }}
                                  aria-label="Upload"
                                >
                                  {I18n.get('Upload')}
                                </Button>
                              </Grid>
                            </Grid>
                          </FileDrop>
                        </Grid>
                      </Grid>
                    )}
                  </Mutation>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {/* Design templates */}
        <Mutation
          update={(store, { data: { createProductDesignTemplate } }) => {
            if (createProductDesignTemplate && createProductDesignTemplate.id) {
              const data = store.readQuery({
                query: ADMIN_GET_PRODUCT,
                variables: { id: product.id }
              })
              data.product.designTemplates.unshift({
                ...createProductDesignTemplate
              })
              store.writeQuery({
                query: ADMIN_GET_PRODUCT,
                variables: { id: product.id },
                data
              })
            }
          }}
          variables={{
            productId: product.id
          }}
          mutation={ADMIN_CREATE_PRODUCT_DESIGN_TEMPLATE}
        >
          {(createProductDesignTemplate, { data, loading, error }) => {
            return (
              <Button
                onClick={async () => {
                  createProductDesignTemplate()
                }}
              >
                Add design template
              </Button>
            )
          }}
        </Mutation>
        {product.designTemplates
          ? product.designTemplates.map(pdt => {
              return (
                <ProductDesignTemplate
                  key={pdt.id}
                  classes={classes}
                  product={product}
                  productDesignTemplate={{
                    ...pdt,
                    lastTemplateRevision: pdt.lastTemplateRevision
                      ? pdt.lastTemplateRevision
                      : 1
                  }}
                  templateId={pdt.id}
                  updateProduct={updateProduct}
                  productId={product.id}
                  refetch={refetch}
                  shopId={shopId}
                  uploadS3={this.uploadS3}
                />
              )
            })
          : null}
      </div>
    )
  }
}
export default CustomProductDetail
