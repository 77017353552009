import React from 'react'
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

interface Props {
  dialog: boolean
  modelId: string
  fieldId: string
  setDialog: any
  deleteContentField(variables: any): any
}

const ConfirmDeleteField: React.FC<Props> = props => {
  const { dialog, setDialog, modelId, deleteContentField, fieldId } = props
  return (
    <Dialog open={dialog}>
      <DialogTitle style={{ fontFamily: 'Poppins' }} id="delete-confirm">
        {'Delete Container'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{ fontFamily: 'Poppins' }}
          id="alert-dialog-description"
        >
          Are you sure you want to delete this container? This cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setDialog(false)}
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            await deleteContentField({
              variables: {
                revisionNo: 1,
                id: fieldId,
                modelId: modelId
              }
            })

            setDialog(false)
          }}
          color="primary"
          variant="contained"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDeleteField
