import React from 'react'
import { Button } from '@material-ui/core'

interface Props {
  contentId: any
  elementType: String
  setSpace: any
  handleShowUpdatePanel(id: string | null, show: boolean): any
}

const ContentActions: React.FC<Props> = props => {
  const { contentId, handleShowUpdatePanel, elementType, setSpace } = props

  // ((windowHeight - pageY) - {{ heightOfPanel }}) >= 0  -> check if item can fit

  return (
    <Button
      onClick={event => {
        try {
          if (typeof window !== `undefined`) {
            const pageY = event.pageY
            const windowHeight = window.innerHeight

            if (windowHeight - pageY - 400 >= 0 === true) {
              setSpace(true)
            } else {
              setSpace(false)
            }
          }
        } catch (error) {
          console.log(error)
        }

        handleShowUpdatePanel(contentId, true)
      }}
      variant="contained"
      style={{
        position: 'absolute',
        backgroundColor: '#b23566',
        textTransform: 'uppercase',
        padding: '10px 40px',
        fontSize: 12,
        margin: 10,
        color: 'white',
        border: '1px solid #b23566',
        fontWeight: 'bold',
        zIndex: 20,
        fontFamily: 'Poppins'
      }}
    >{`Edit ${elementType}`}</Button>
  )
}

export default ContentActions
