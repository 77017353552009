import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography, Switch } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Slider from '@material-ui/core/Slider';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  input: {
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  },
  slider: {
    padding: '22px 0px'
  }
});

class UpdateMedia extends React.Component {
  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,
      fields
    } = this.props;

    return (
      <ExpansionPanel
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
        expanded={expanded === 'panel2'}
        onChange={handleExpandedChange('panel2')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ expanded: classes.expansionPanelExpanded }}
        >
          <Typography
            variant="subtitle1"
            className={classes.typo}
            data-cy="Media-Settings"
          >
            Media
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{ root: classes.expansionPanelDetails }}
        >
          <Divider />
          <List>
            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="Media-youtube-url-Label"
                  shrink
                  className={classes.FormLabel}
                >
                  YouTube Video URL
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    data-cy="Media-youtube-url-Input"
                    className={classes.input}
                    placeholder=""
                    value={fields.url}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'url'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="Media-Width-Label"
                  style={{ marginBottom: 10 }}
                  shrink
                  className={classes.FormLabel}
                >
                  Width - {fields.width}
                </InputLabel>
                <Grid container={true} justify="space-between">
                  <Slider
                    data-cy="Media-Width-Slider"
                    className={styles.slider}
                    value={parseInt(fields.width)}
                    aria-labelledby="slider-label"
                    step={1}
                    min={640}
                    max={1000}
                    onChange={(e, value) => handleChangeField(value, 'width')}
                  />
                </Grid>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="Media-Height-Label"
                  style={{ marginBottom: 10 }}
                  shrink
                  className={classes.FormLabel}
                >
                  Height - {fields.height}
                </InputLabel>
                <Grid container={true} justify="space-between">
                  <Slider
                    data-cy="Media-Height-Slider"
                    className={styles.slider}
                    value={parseInt(fields.height)}
                    aria-labelledby="slider-label"
                    step={1}
                    min={390}
                    max={1000}
                    onChange={(e, value) => handleChangeField(value, 'height')}
                  />
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

UpdateMedia.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
};

export default withStyles(styles)(UpdateMedia);
