import React, { useState } from 'react'
import { Grid, IconButton, ButtonGroup, Button } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'
import ConfirmDeleteField from './ConfirmDeleteField'

interface Props {
  fieldId: any
  modelId: any
  fieldLength: any
  firstElement: boolean
  fieldIds: any
  index: number
  defaultPage: any
  updatePanel: any
  handleShowUpdatePanel(
    id: string | null,
    show: boolean,
    type: 'field' | 'content' | null
  ): any
  updateContentField(variables: any): any
  deleteContentField(variables: any): any
  updateContentFieldSortOrder(variables: any): any
}

const Actions: React.FC<Props> = props => {
  const {
    fieldId,
    fieldLength,
    modelId,
    firstElement,
    fieldIds,
    index,
    handleShowUpdatePanel,
    deleteContentField,
    updateContentFieldSortOrder,
    updatePanel
  } = props

  const [open, setOpen] = useState(false)

  const getIds = (direction: 'up' | 'down', id: string) => {
    const index = fieldIds.indexOf(id)
    const index2 = direction === 'up' ? index - 1 : index + 1

    const id2 = fieldIds[index2]

    updateContentFieldSortOrder({
      variables: {
        modelId: modelId,
        fieldId1: id,
        fieldId2: id2,
        revisionNo: 1
      }
    })
  }

  return (
    <Grid
      style={{
        position: 'absolute',
        right: 10,
        top: 0,
        zIndex: 2,
        padding: '0px 25px'
      }}
      direction="row"
      container={true}
      justify="flex-end"
    >
      <Button
        onClick={() => handleShowUpdatePanel(fieldId, true, 'field')}
        variant="contained"
        style={{
          position: 'absolute',
          backgroundColor: '#b23566',
          padding: '10px 40px',
          fontSize: 12,
          margin: 10,
          color: 'white',
          border: '1px solid #b23566',
          fontWeight: 'bold',
          zIndex: 20,
          textOverflow: 'ellipsis',
          fontFamily: 'Poppins'
        }}
      >
        Edit Container
      </Button>
      <ButtonGroup
        style={{
          padding: '5px 32px',
          position: 'absolute',
          top: 60,
          right: 15,
          border: '1px solid #b23566',
          borderRadius: 2,
          backgroundColor: '#b23566',
          boxShadow: '0 10px 20px rgba(0,0,0,0.15), 0 6px 6px rgba(0,0,0,0.19)'
        }}
        variant="outlined"
        size="small"
        aria-label="small contained button group"
      >
        <IconButton
          style={{ borderRadius: 0, color: 'white' }}
          size="small"
          disabled={fieldLength < 2 || fieldLength === index + 1 ? true : false}
          onClick={() => getIds('down', fieldId)}
        >
          <ExpandMoreIcon />
        </IconButton>
        <IconButton
          style={{ borderRadius: 0, color: 'white' }}
          size="small"
          disabled={firstElement || fieldLength < 2 ? true : false}
          onClick={() => getIds('up', fieldId)}
        >
          <ExpandLessIcon />
        </IconButton>
        <IconButton
          style={{ borderRadius: 0, color: 'white' }}
          size="small"
          disabled={false}
          onClick={async () => {
            await updatePanel({ id: fieldId })
            setOpen(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
      </ButtonGroup>
      {open ? (
        <ConfirmDeleteField
          dialog={open}
          setDialog={setOpen}
          modelId={modelId}
          fieldId={fieldId}
          deleteContentField={deleteContentField}
        />
      ) : null}
    </Grid>
  )
}

export default Actions
