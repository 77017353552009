import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import FabricList from './FabricList';
import FabricDetail from './FabricDetail';

class Fabrics extends Component {
  render() {
    const { shopId, fabricId } = this.props;
    return fabricId ? (
      <FabricDetail shopId={shopId} fabricId={fabricId} />
    ) : (
      <Grid>
        <FabricList shopId={shopId} />
      </Grid>
    );
  }
}
export default Fabrics;
