import gql from 'graphql-tag'
import { Board, DiscountFragment, LeadFragment } from './fragments'
import { BoardItemDetailFragment } from './queries'

export const VERIFY_TEAMSTORE_PASSWORD = gql`
  mutation verifyTeamstorePassword($id: ID!, $password: String!) {
    verifyTeamstorePassword(id: $id, password: $password)
  }
`
export const SEND_CONTACT_FORM = gql`
  mutation sendContactForm(
    $name: String!
    $message: String!
    $email: String!
    $url: String
    $userAgent: String
    $langCode: LanguageCode
  ) {
    sendContactForm(
      name: $name
      message: $message
      email: $email
      url: $url
      userAgent: $userAgent
      langCode: $langCode
    )
  }
`
export const UPLOAD_FILE = gql`
  mutation uploadFile(
    $type: FileDestination!
    $fileName: String!
    $prefixVariables: [String]
    $input: FilePrefixInput!
  ) {
    uploadFile(
      type: $type
      fileName: $fileName
      prefixVariables: $prefixVariables
      input: $input
    ) {
      error
      acl
      tagging
      url
      contentType
      cacheControl
    }
  }
`

export const DELETE_ADDRESS = gql`
  mutation deleteAddress($id: ID!, $ownedByOrganisationId: ID!) {
    deleteAddress(id: $id, ownedByOrganisationId: $ownedByOrganisationId)
  }
`

export const CREATE_TEXT_DESIGN = gql`
  mutation createTextDesign(
    $ownedByOrganisationId: ID!
    $boardProductId: ID!
    $input: BoardItemTextInput
    $shopId: ID!
  ) {
    createTextDesign(
      ownedByOrganisationId: $ownedByOrganisationId
      boardProductId: $boardProductId
      input: $input
      shopId: $shopId
    ) {
      id
      boardProductId
      type
      createdAt
      updatedAt
      ...textFields
      decal {
        ...decalAttr
      }
      decalValues {
        ...decalValues
      }
    }
  }
  ${Board.fragments.decalAttr}
  ${Board.fragments.textFields}
  ${Board.fragments.decalValues}
`
export const UPDATE_TEXT_DESIGN = gql`
  mutation updateTextDesign(
    $id: ID!
    $boardProductId: ID!
    $input: BoardItemTextInput
  ) {
    updateTextDesign(id: $id, boardProductId: $boardProductId, input: $input) {
      id
      boardProductId
      type
      createdAt
      updatedAt
      ...textFields
      decal {
        ...decalAttr
      }
      decalValues {
        ...decalValues
      }
    }
  }
  ${Board.fragments.decalAttr}
  ${Board.fragments.textFields}
  ${Board.fragments.decalValues}
`
export const UPDATE_BOARD_ELEMENT_DESIGN = gql`
  mutation updateBoardElementDesign(
    $id: [ID!]!
    $boardProductId: ID!
    $input: BoardElementDesignInput
  ) {
    updateBoardElementDesign(
      id: $id
      boardProductId: $boardProductId
      input: $input
    ) {
      ...elementDesignDataDetails
    }
  }
  ${Board.fragments.elementDesignDataDetails}
`

export const DELETE_TEXT_DESIGN = gql`
  mutation deleteTextDesign($boardProductId: ID!, $id: ID!) {
    deleteTextDesign(boardProductId: $boardProductId, id: $id)
  }
`
export const CREATE_PRINT_DESIGN = gql`
  mutation createPrintDesign(
    $ownedByOrganisationId: ID!
    $boardProductId: ID!
    $fileName: String!
    $input: BoardItemPrintInput
    $shopId: ID!
  ) {
    createPrintDesign(
      ownedByOrganisationId: $ownedByOrganisationId
      boardProductId: $boardProductId
      fileName: $fileName
      input: $input
      shopId: $shopId
    ) {
      id
      ownedByOrganisationId
      boardProductId
      fileName
      src
      preview
      createdAt
      updatedAt
    }
  }
`
export const CREATE_LOGO_DESIGN = gql`
  mutation createLogoDesign(
    $ownedByOrganisationId: ID!
    $boardProductId: ID!
    $fileName: String!
    $input: BoardItemLogoInput
    $shopId: ID!
  ) {
    createLogoDesign(
      ownedByOrganisationId: $ownedByOrganisationId
      boardProductId: $boardProductId
      fileName: $fileName
      input: $input
      shopId: $shopId
    ) {
      id
      boardProductId
      ownedByOrganisationId
      type
      createdAt
      updatedAt
      ...logoFields
      decal {
        ...decalAttr
      }
      decalValues {
        ...decalValues
      }
    }
  }
  ${Board.fragments.decalAttr}
  ${Board.fragments.logoFields}
  ${Board.fragments.decalValues}
`
export const UPDATE_LOGO_DESIGN = gql`
  mutation updateLogoDesign(
    $id: ID!
    $boardProductId: ID!
    $input: BoardItemLogoInput
  ) {
    updateLogoDesign(id: $id, boardProductId: $boardProductId, input: $input) {
      id
      boardProductId
      ownedByOrganisationId
      type
      createdAt
      updatedAt
      ...logoFields
      decal {
        ...decalAttr
      }
      decalValues {
        ...decalValues
      }
    }
  }
  ${Board.fragments.decalAttr}
  ${Board.fragments.logoFields}
  ${Board.fragments.decalValues}
`
export const DELETE_LOGO_DESIGN = gql`
  mutation deleteLogoDesign($boardProductId: ID!, $id: ID!) {
    deleteLogoDesign(boardProductId: $boardProductId, id: $id)
  }
`
// export const CREATE_BOARDPRODUCT_COMMENT = gql`
//   mutation createBoardProductComment(
//     $boardProductId: ID!
//     $input: CommentInput!
//     $shopId: ID!
//   ) {
//     createBoardProductComment(
//       boardProductId: $boardProductId
//       input: $input
//       shopId: $shopId
//     ) {
//       id
//       boardProductId
//       content
//       createdAt
//       createdBy
//     }
//   }
// `;
export const CREATE_ORDER = gql`
  mutation createOrder(
    $input: OrderCreateInput!
    $ownedByOrganisationId: ID!
    $shopId: ID!
  ) {
    createOrder(
      input: $input
      shopId: $shopId
      ownedByOrganisationId: $ownedByOrganisationId
    ) {
      id
    }
  }
`
export const CartFragment = gql`
  fragment cartFragment on Cart {
    id
    couponCode
    lastAddToCartUrl
    paymentSourceId
    paymentMethod
    userReferences
    shopId
    seasonName
    collectionName
    shippingAddressId
    shippingAddress {
      id
      ownedByOrganisationId
      address1
      city
      company
      vatNumber
      countryCode
      firstName
      lastName
      email
      phone
      province
      zip
      isValidAddress
    }
    billingAddressId
    billingAddress {
      id
      ownedByOrganisationId
      address1
      city
      company
      vatNumber
      countryCode
      firstName
      lastName
      email
      phone
      province
      zip
      isValidAddress
    }
    ownedByOrganisationId
    shopId
    seasonName
    collectionName
    shippingMethodId
    ownedByOrganisationId
    totalCalculated {
      appliedCoupons {
        discountDeduction {
          currency
          value
          includesTax
          taxPercent
        }
        linkedCoupon {
          id
          couponCode
        }
      }
      appliedDiscounts {
        coupon {
          id
          couponCode
        }
        discountDeduction {
          currency
          value
          includesTax
          taxPercent
        }
        linkedDiscount {
          id
          accountingId
          title {
            id
            text {
              langCode
              content
            }
          }
          priority
          enabled
          hasCoupon
          enablesFreeShipping
          canBeCombinedWithOtherDiscounts
          discountType
          productsGetForFree
          freeProducts {
            id
            media {
              id
              sortOrder
              isThumbnail
              isBanner
              image
            }
            title {
              id
              text {
                content
                langCode
              }
            }
          }
          discountValue
          discountCurrency
          availableFrom
          availableUntil
          discountRules {
            id
            discountId
            operator
            discountRuleValue
            discountRuleCurrency
            discountRuleObjects {
              id
              type
              objectId
            }
            discountRuleObjectType
            discountRuleCondition
          }
          internalNote
        }
      }
      cartTotalPrice {
        roundUp
        currency
        value
        includesTax
        taxPercent
      }
      cartTotalinCurrency(currency: $currency) {
        currency
        value
        includesTax
        taxPercent
      }
      cartTotalString
      cartTotalGroups {
        finalTotal {
          currency
          value
          includesTax
          taxPercent
        }
        finalTotalinCurrency(currency: $currency) {
          currency
          value
          includesTax
          taxPercent
        }
        totalPieces
        discountRate
        discountNote
        shippingCost {
          shippingProviderName
          cost {
            currency
            value
            includesTax
            taxPercent
          }
        }
        cartItemTotals {
          id
          qty
          productId
          productOptionValueId
          productOptionValues
          boardProductId
          baseProductId
          fixPrice {
            roundUp
            currency
            value
            includesTax
            taxPercent
          }
          priceNote
          itemTotalBeforeDiscount {
            currency
            value
            includesTax
            taxPercent
          }
          totalBeforeDiscount {
            currency
            value
            includesTax
            taxPercent
          }
          total {
            currency
            value
            includesTax
            taxPercent
          }
        }
      }
    }
    cartItems {
      id
      shopId
      productOptionValueId
      productOptionValues
      productOptionValue {
        id
        sku
        selectedVariants {
          id
          selectedOption {
            id
            title {
              id
              text {
                langCode
                content
              }
            }
          }
          title {
            id
            text {
              langCode
              content
            }
          }
        }
      }
      productId
      quantity
      product {
        id
        title {
          id
          text {
            langCode
            content
          }
        }
        media {
          id
          productId
          variantId
          variantOptionId
          optionValue
          sortOrder
          isThumbnail
          isBanner
          image
        }
      }
      shop {
        tempDiscount {
          orderType
          pricingNote
          leadTimeNote
          leadTimeDays
          minQuantity
          maxQuantity
          multiplier
        }
      }
      boardProductId
      productDesignId
      designStudioVersion
      orderType
      salesChannelTargetCategoryId
      productPackageId
      productPackageProductOptionValues {
        id
        productId
        productOptionValueId
        productOptionValues
      }
      salesChannelGroupId
      customizationOptions {
        id
        customizationGroupId
        options {
          id
          productCustomizationOptionId
          value
        }
      }
      salesChannelId
      boardProduct {
        id
        name
        shopId
        comment
        lastPrice {
          roundUp
          value
          currency
          includesTax
          taxPercent
        }
        baseProduct {
          id
          no
        }
      }
      design {
        elementRevision
        ...boardItemDetails
        elementDesignData {
          ...elementDesignDataDetails
          colorHex
        }
      }
    }
  }
  ${BoardItemDetailFragment}
  ${Board.fragments.elementDesignDataDetails}
`
export const ADD_TO_CART = gql`
  mutation addToCart(
    $id: ID!
    $ownedByOrganisationId: ID!
    $input: CartUpdateInput!
    $currency: Currency
  ) {
    addToCart(
      id: $id
      ownedByOrganisationId: $ownedByOrganisationId
      input: $input
      currency: $currency
    )
  }
`
export const REMOVE_COUPONCODE = gql`
  mutation removeCouponCode($id: ID!, $ownedByOrganisationId: ID!) {
    removeCouponCode(id: $id, ownedByOrganisationId: $ownedByOrganisationId)
  }
`

export const UPDATE_CART = gql`
  mutation updateCart(
    $id: ID!
    $ownedByOrganisationId: ID!
    $input: CartUpdateInput!
    $currency: Currency
  ) {
    updateCart(
      id: $id
      ownedByOrganisationId: $ownedByOrganisationId
      input: $input
      currency: $currency
    ) {
      ...cartFragment
    }
  }
  ${CartFragment}
`

export const CREATE_ADDRESS = gql`
  mutation createAddress($ownedByOrganisationId: ID!, $shopId: ID!) {
    createAddress(
      ownedByOrganisationId: $ownedByOrganisationId
      shopId: $shopId
    ) {
      id
      ownedByOrganisationId
      address1
      city
      company
      vatNumber
      countryCode
      firstName
      lastName
      email
      phone
      province
      zip
      isValidAddress
    }
  }
`
export const UPDATE_ADDRESS = gql`
  mutation updateAddress(
    $id: ID!
    $ownedByOrganisationId: ID!
    $input: AddressInput!
  ) {
    updateAddress(
      id: $id
      ownedByOrganisationId: $ownedByOrganisationId
      input: $input
    ) {
      id
      ownedByOrganisationId
      address1
      city
      company
      vatNumber
      countryCode
      firstName
      lastName
      email
      phone
      province
      zip
      isValidAddress
    }
  }
`

export const CREATE_BOARD_FOLDER = gql`
  mutation createBoardFolder(
    $name: String!
    $ownedByOrganisationId: ID!
    $parentId: ID!
    $shopId: ID!
  ) {
    createBoardFolder(
      name: $name
      ownedByOrganisationId: $ownedByOrganisationId
      parentId: $parentId
      shopId: $shopId
    ) {
      id
      name
      ownedByOrganisationId
      parentId
    }
  }
`
export const CREATE_PRODUCT_DESIGN = gql`
  mutation createProductDesign(
    $designStudioVersion: DesignStudioVersion!
    $salesChannelId: ID!
    $baseProductId: ID!
    $ownedByOrganisationId: ID!
    $copyFrom: ID
  ) {
    createProductDesign(
      designStudioVersion: $designStudioVersion
      salesChannelId: $salesChannelId
      baseProductId: $baseProductId
      ownedByOrganisationId: $ownedByOrganisationId
      copyFrom: $copyFrom
    ) {
      id
      ownedByOrganisationId
    }
  }
`
export const CREATE_BOARD_PRODUCT = gql`
  mutation createBoardProduct(
    $designStudioVersion: DesignStudioVersion!
    $salesChannelId: ID!
    $shopId: ID!
    $baseProductId: ID!
    $ownedByOrganisationId: ID!
    $copyFrom: ID
  ) {
    createBoardProduct(
      designStudioVersion: $designStudioVersion
      salesChannelId: $salesChannelId
      shopId: $shopId
      baseProductId: $baseProductId
      ownedByOrganisationId: $ownedByOrganisationId
      copyFrom: $copyFrom
    ) {
      id
      ownedByOrganisationId
    }
  }
`
export const UPDATE_BOARD_PRODUCT = gql`
  mutation updateBoardProduct(
    $id: ID!
    $ownedByOrganisationId: ID!
    $input: BoardProductInput!
  ) {
    updateBoardProduct(
      id: $id
      ownedByOrganisationId: $ownedByOrganisationId
      input: $input
    ) {
      id
      name
      comment
      ownedByOrganisationId
      backgroundColorHex
      decalOrder {
        sequence
        boardItemId
      }
      createdAt
      updatedAt
      baseProductId
    }
  }
`
export const UPDATE_USER_SETTINGS = gql`
  mutation updateUserSettings($input: UserInput!) {
    updateUserSettings(input: $input) {
      id
      email
      firstName
      lastName
      phone
      acceptedCookieConsent
      statisticsConsent
      marketingConsent
      numberOfSessions
      settings {
        defaultLanguage
        defaultCountry
        defaultCurrency
        defaultWeightUnit
        defaultLengthUnit
        defaultShippingAddressId
        defaultBillingAddressId
      }
      organisationMemberships {
        id
        organisationId
        userId
        role
      }
    }
  }
`
export const SET_ORDER_PAID = gql`
  mutation setOrderPaid(
    $ownedByOrganisationId: ID!
    $cartId: ID!
    $source: String!
    $clientSecret: String!
  ) {
    setOrderPaid(
      ownedByOrganisationId: $ownedByOrganisationId
      cartId: $cartId
      source: $source
      clientSecret: $clientSecret
    )
  }
`
export const UPDATE_ORGANISATION_SETTINGS = gql`
  mutation updateOrganisation($id: ID!, $input: OrganisationInput!) {
    updateOrganisation(id: $id, input: $input) {
      id
      name
      vatNumber
      organisationType
      defaultShippingAddressId
      defaultBillingAddressId
    }
  }
`
export const DELETE_BOARD_PRODUCT = gql`
  mutation deleteBoardProduct($id: ID!, $ownedByOrganisationId: ID!) {
    deleteBoardProduct(id: $id, ownedByOrganisationId: $ownedByOrganisationId)
  }
`

export const UPDATE_LAST_BOARDPRODUCTPRICE = gql`
  mutation updateLastBoardProductPrice(
    $id: ID!
    $ownedByOrganisationId: ID!
    $lastPrice: PriceInput!
  ) {
    updateLastBoardProductPrice(
      id: $id
      ownedByOrganisationId: $ownedByOrganisationId
      lastPrice: $lastPrice
    ) {
      id
      lastPrice {
        roundUp
        value
        currency
        includesTax
        taxPercent
      }
    }
  }
`

export const UPDATE_BOARD_PRODUCT_LABEL = gql`
  mutation updateBoardProductLabel(
    $boardProductId: ID!
    $careLabelId: ID
    $careLabelLogoId: ID
  ) {
    updateBoardProductLabel(
      boardProductId: $boardProductId
      careLabelId: $careLabelId
      careLabelLogoId: $careLabelLogoId
    ) {
      id
      boardProductId
      careLabelId
      careLabelLogoId
    }
  }
`

export const CREATE_LEAD = gql`
  mutation createLead($input: LeadCreateInput!) {
    createLead(input: $input) {
      ...leadFragment
    }
  }
  ${LeadFragment}
`
export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent(
    $cartId: ID!
    $ownedByOrganisationId: ID!
    $currency: Currency!
    $paymentMethod: PaymentMethod!
    $paymentToken: String
    $paymentGateway: PaymentGateway
  ) {
    createPaymentIntent(
      cartId: $cartId
      ownedByOrganisationId: $ownedByOrganisationId
      currency: $currency
      paymentMethod: $paymentMethod
      paymentToken: $paymentToken
      paymentGateway: $paymentGateway
    )
  }
`

export const SET_CART_VALUES = gql`
  mutation setCartValues(
    $ownedByOrganisationId: ID!
    $userReferences: [String]
    $email: String
    $firstName: String
    $lastName: String
    $address1: String
    $city: String
    $zip: String
    $phone: String
    $countryCode: CountryCode
  ) {
    setCartValues(
      ownedByOrganisationId: $ownedByOrganisationId
      userReferences: $userReferences
      email: $email
      firstName: $firstName
      lastName: $lastName
      address1: $address1
      city: $city
      zip: $zip
      phone: $phone
      countryCode: $countryCode
    )
  }
`

export const VALIDATE_USERREFERENCES = gql`
  mutation validateUserReferences($ownedByOrganisationId: ID!, $cartId: ID!) {
    validateUserReferences(
      ownedByOrganisationId: $ownedByOrganisationId
      cartId: $cartId
    )
  }
`
