import Auth from '@aws-amplify/auth'
import Cache from '@aws-amplify/cache'
import { setGuestId } from './setGuestAccount'
import { getGuestOrg } from './storage'

Cache.configure()
export async function getBearer(shopId) {
  return new Promise(async (resolve, reject) => {
    try {
      if (Cache.getItem('federatedInfo')) {
        if (Cache.getItem('federatedInfo').expires_at < new Date().getTime()) {
          // console.warn(
          //     'googleTokenExpired-1',
          //     Cache.getItem('federatedInfo')
          // )
          const token = await Auth.currentUserCredentials()
          // console.warn(
          //     'googleTokenExpired-2',
          //     token
          // )
          resolve('Bearer ' + Cache.getItem('federatedInfo').token)
        } else {
          resolve('Bearer ' + Cache.getItem('federatedInfo').token)
        }
      } else {
        try {
          // TODO improve below to avoid cognito requests before api calls
          // const currentUserCredentials = await Amplify.Auth.currentUserCredentials()
          // console.log('currentUserCredentials', currentUserCredentials);
          // const currentToken = currentSession.getIdToken().getJwtToken()
          // var decodedToken = jwtDecode(currentToken);
          // console.log('decoded', decodedToken);
          // console.log('currentSession', currentSession)
          // alert('Exp:' + decodedToken.exp * 1000)
          // 1543244923000
          // 1543241810987
          // alert('Now:' + new Date().getTime())
          // expiry date minus 10 minutes
          // if ((decodedToken.exp * 1000) - 600000 < new Date().getTime()) {
          const session = await Auth.currentSession()
          resolve('Bearer ' + session.getIdToken().getJwtToken())
          // } else {
          // resolve('Bearer ' + currentToken);
          // }
        } catch (error) {
          const token = await Auth.currentUserCredentials()
          if (Cache.getItem('federatedInfo')) {
            resolve('Bearer ' + Cache.getItem('federatedInfo').token)
          } else {
            const x1 = await getGuestOrg(shopId)

            if (x1) {
              resolve(x1)
            } else {
              const newOrg1 = await setGuestId()
              resolve(newOrg1)
            }
          }
        }
      }
    } catch (error) {
      // if (window.location.pathname.indexOf('board') !== -1) {
      // window.open( window.location.hostname.indexOf('localhost')!==-1 ? 'http://localhost:8080' : 'https://'+window.location.hostname )
      // }
      const x = await getGuestOrg(shopId)
      if (x) {
        resolve(x)
      } else {
        const newOrg = await setGuestId()
        resolve(newOrg)
      }
    }
  })

  // if( Cache.getItem('federatedInfo') ){
  //     const token = Cache.getItem('federatedInfo').token
  //     // const currentUserCredentials = await Amplify.Auth.currentUserCredentials()
  //     // console.log(
  //     //     'currentUserCredentials',
  //     //     currentUserCredentials
  //     // )

  //     return `Bearer ${
  //         // TODO use this instead > currentUserCredentials
  //         token
  //     }`
  // }else{
  //     // const token =  async () => (await Amplify.Auth.currentSession()).getIdToken().getJwtToken()
  //     const session = await Amplify.Auth.currentSession()
  //     const token = session.getIdToken().getJwtToken()
  //     return `Bearer ${ token }`
  //     // Amplify.Auth.currentSession().then((session)=>{
  //     //     const token = session.getIdToken().getJwtToken()
  //     //     return `Bearer ${
  //     //         // TODO use this instead > currentUserCredentials
  //     //         token
  //     //     }`
  //     // })
  //     // const session = Amplify.Auth.currentSession()
  //     // const token = session.getIdToken().getJwtToken()

  // }
  // (Amplify.Auth.currentSession()).getIdToken().getJwtToken()
  // await (Amplify.Auth.currentSession()).idToken.jwtToken
}

// complicated version
// try {
//     var token = null
//     var idp = null

//     if(
//       Cache.getItem('federatedInfo') &&
//       Cache.getItem('federatedInfo').token
//     ){
//       token = Cache.getItem('federatedInfo').token
//       idp = 'google-oauth2'
//       return {
//         token: token,
//         idp: idp
//       }
//       // alert('set '+idp+' token from cache > '+token)
//     }else{

//       Amplify.Auth.currentSession().then((session)=>{

//         if(session){
//           token = session.idToken.jwtToken
//           idp = 'cognito';
//           return {
//             token: token,
//             idp: idp
//           }
//           // alert('set '+idp+' token from session > '+token)
//         }else{
//           return {
//             token: token,
//             idp: idp
//           }
//         }

//       }).catch((sessionerror)=>{

//         // alert('Error 1 sending API request'+sessionerror)
//         // console.error(sessionerror)

//         Amplify.Auth.currentCredentials().then((credentials)=>{
//           // console.log('credentials', credentials)
//           if(
//             credentials &&
//             credentials.webIdentityCredentials &&
//             credentials.webIdentityCredentials.params &&
//             credentials.webIdentityCredentials.params.Logins &&
//             'accounts.google.com' in credentials.webIdentityCredentials.params.Logins
//           ){
//             token = credentials.webIdentityCredentials.params.Logins['accounts.google.com'];
//             idp = 'google-oauth2';
//             return {
//               token: token,
//               idp: idp
//             }
//             // alert('set '+idp+' token from credentials > '+token)
//           }

//         }).catch((credentialerror)=>{
//           // alert('Error 2 sending API request'+credentialerror)
//           console.error(credentialerror)
//           return {
//             token: token,
//             idp: idp
//           }
//         })

//       })

//     }
//   } catch (error) {
//     // alert('Error 0 sending API request '+error)
//     console.error(error)
//     return {
//       token: token,
//       idp: idp
//     }
//   }
