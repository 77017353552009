import { useState, useEffect } from 'react';

const useContentModule = (id: string, selectedElement: any) => {
  useEffect(() => {
    if (id && selectedElement && id === selectedElement.id) {
      if (!isSelected) {
        setIsSelected(true);
      }
    } else {
      if (isSelected) {
        setIsSelected(false);
      }
    }
  });

  const [isSelected, setIsSelected] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return {
    handleMouseEnter,
    handleMouseLeave,
    isHover,
    isSelected
  };
};

export default useContentModule;
