import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { OrganisationContext } from '../../../user/context/OrganisationContext';
import ProductPackagesList from './ProductPackagesList';
import ProductPackageDetail from './ProductPackageDetail';

class ProductPackages extends Component {
  render() {
    const { shopId, shop } = this.props;
    return this.props.productPackageId ? (
      <Grid container>
        <OrganisationContext.Consumer>
          {currentOrganisation => (
            <ProductPackageDetail
              currencies={shop.currencies}
              defaultCurrency={shop.defaultCurrency}
              languages={shop.languages}
              defaultLanguage={shop.defaultLanguage}
              currentOrganisation={currentOrganisation}
              shopId={shopId}
              productPackageId={this.props.productPackageId}
            />
          )}
        </OrganisationContext.Consumer>
      </Grid>
    ) : (
      <Grid container style={{ width: '100%' }}>
        <ProductPackagesList
          defaultLanguage={shop.defaultLanguage}
          shopId={shopId}
        />
      </Grid>
    );
  }
}

export default ProductPackages;
