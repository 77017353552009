import React, {useState} from 'react'
import {InputLabel, MenuItem, Select, createStyles, withStyles, WithStyles} from '@material-ui/core'


const styles = () => createStyles({
})


interface Props extends WithStyles<typeof styles> {
  data: any,
  modules: any[],
  onChange(moduleId: string|null): void
}


const AddMenuLinkModule: React.FunctionComponent<Props> = (props) => {
  const { data, modules, onChange } = props

  const [selectedModule, setSelectedModule] = useState(data.moduleId)


  const handleChange = (e: any): void => {
    setSelectedModule(e.target.value)
    onChange(e.target.value)
  }


  return (
    <>
      <InputLabel>Module</InputLabel>
      <Select
        value={selectedModule}
        onChange={handleChange}
        inputProps={{
          id: 'pageId-simple',
          name: 'pageId'
        }}
        style={{border: '1px solid #eee', margin: '8px 0px 16px', borderRadius: 4}}
      >
        <MenuItem
          value={null}
        >
          -
        </MenuItem>
        {modules && modules.edges ? modules.edges.filter(o => o.node.contentDetail && o.node.contentDetail.type && o.node.label).map((module: any) => (
          <MenuItem
            key={module.node.id}
            value={module.node.id}
          >
            {module.node.contentDetail ? (module.node.label || module.node.contentDetail.type) : null}
          </MenuItem>
        )): null}
      </Select>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(AddMenuLinkModule)
