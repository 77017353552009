import React, { Component } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Query, Mutation } from 'react-apollo';
import {
  ADMIN_GET_DISCOUNT_RULE,
  ADMIN_GET_DISCOUNT,
  ADMIN_SHOP_CUSTOMERS,
  ADMIN_SHOP_COLLECTIONS
} from '../../gql/admin-queries';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import {
  ADMIN_UPDATE_DISCOUNT,
  ADMIN_CREATE_DISCOUNT_RULE_OBJECT,
  ADMIN_DELETE_DISCOUNT_RULE_OBJECT
} from '../../gql/admin-mutations';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';
import uuid from 'uuid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '../../../common/Input';
import Select from '../../../common/Select';
import DiscountRuleDelete from './DiscountRuleDelete';
import DiscountRuleOperator from './DiscountRuleOperator';
import DiscountRuleType from './DiscountRuleType';
import DiscountRuleCondition from './DiscountRuleCondition';
import DiscountRuleValue from './DiscountRuleValue';
import {
  ADMIN_CREATE_DISCOUNT_RULE,
  ADMIN_UPDATE_DISCOUNT_RULE
} from '../../gql/admin-mutations';

const styles = {
  root: {}
};
class DiscountRuleItem extends Component {
  state = {};
  render() {
    const {
      classes,
      defaultLanguage,
      currencies,
      discountId,
      discountRuleId,
      discountRuleIndex,
      updateDiscountRule,
      shopId
    } = this.props;
    return (
      <Query
        query={ADMIN_GET_DISCOUNT_RULE}
        variables={{ id: discountRuleId, discountId }}
      >
        {({ data }) =>
          data && data.discountRule ? (
            <Mutation
              mutation={ADMIN_UPDATE_DISCOUNT_RULE}
              variables={{
                discountId,
                id: discountRuleId
              }}
              update={(cache, { data: { updateDiscountRule } }) => {}}
            >
              {updateDiscountRule => {
                const discountRule = data.discountRule;
                return (
                  <Grid
                    style={{
                      marginBottom: 25,
                      paddingBottom: 20,
                      borderBottom: '1px solid #eee'
                    }}
                    container
                    spacing={1}
                  >
                    <DiscountRuleDelete
                      classes={classes}
                      discountId={discountId}
                      discountRule={discountRule}
                      discountRuleIndex={discountRuleIndex}
                      updateDiscountRule={updateDiscountRule}
                    />
                    {/* <DiscountRuleOperator
            classes={classes}
            discountId={discountId}
            discountRule={discountRule}
            discountRuleIndex={discountRuleIndex}
            updateDiscountRule={updateDiscountRule}
        /> */}
                    <Mutation
                      mutation={ADMIN_DELETE_DISCOUNT_RULE_OBJECT}
                      update={(
                        cache,
                        { data: { deleteDiscountRuleObject } }
                      ) => {}}
                    >
                      {deleteDiscountRuleObject => {
                        return (
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <DiscountRuleType
                                classes={classes}
                                discountId={discountId}
                                discountRule={discountRule}
                                discountRuleIndex={discountRuleIndex}
                                updateDiscountRule={updateDiscountRule}
                                deleteDiscountRuleObject={
                                  deleteDiscountRuleObject
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DiscountRuleCondition
                                classes={classes}
                                discountId={discountId}
                                discountRule={discountRule}
                                discountRuleIndex={discountRuleIndex}
                                updateDiscountRule={updateDiscountRule}
                              />
                            </Grid>

                            <Mutation
                              mutation={ADMIN_CREATE_DISCOUNT_RULE_OBJECT}
                              update={(
                                cache,
                                { data: { createDiscountRuleObject } }
                              ) => {
                                if (createDiscountRuleObject.objectId) {
                                  const data = cache.readQuery({
                                    query: ADMIN_GET_DISCOUNT_RULE,
                                    variables: {
                                      id: discountRuleId,
                                      discountId: discountId
                                    }
                                  });
                                  data.discountRule = {
                                    ...data.discountRule,
                                    discountObjects: data.discountRule
                                      .discountObjects
                                      ? [
                                          ...data.discountRule.discountObjects,
                                          createDiscountRuleObject
                                        ]
                                      : [createDiscountRuleObject]
                                  };

                                  cache.writeQuery({
                                    query: ADMIN_GET_DISCOUNT_RULE,
                                    variables: {
                                      id: discountRuleId,
                                      discountId: discountId
                                    },
                                    data
                                  });
                                }
                              }}
                            >
                              {createDiscountRuleObject => {
                                return (
                                  <DiscountRuleValue
                                    shopId={shopId}
                                    defaultLanguage={defaultLanguage}
                                    currencies={currencies}
                                    classes={classes}
                                    discountId={discountId}
                                    discountRule={discountRule}
                                    discountRuleIndex={discountRuleIndex}
                                    updateDiscountRule={updateDiscountRule}
                                    createDiscountRuleObject={
                                      createDiscountRuleObject
                                    }
                                    deleteDiscountRuleObject={
                                      deleteDiscountRuleObject
                                    }
                                  />
                                );
                              }}
                            </Mutation>
                          </Grid>
                        );
                      }}
                    </Mutation>
                  </Grid>
                );
              }}
            </Mutation>
          ) : (
            <>No discount rule</>
          )
        }
      </Query>
    );
  }
}
DiscountRuleItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DiscountRuleItem);
