import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Popover from '@material-ui/core/Popover'
import { Subtitle1 } from '../../content/typography/Subtitle'
import Img from '../content/media/Img'
import Body1 from '../content/typography/Body1'
import { graphql, Link } from 'gatsby'
import _get from 'lodash/get'
import { getTextFromLanguage } from '../../../helper/languageHelper'
import { showCurrencySymbol } from '../../../helper/currency'
import DummyImage from '../../../img/DUMMYBANNER.jpg'

const styles = theme => ({
  card: {
    minWidth: '100%',
    padding: '0px',
    width: '100%',
    height: '100%',
    border: '1px solid #ccc',
    '&:hover': {
      cursor: 'pointer',
      // borderBottomLeftRadius: '0px',
      // borderBottomRightRadius: '0px',
      // border: '1px solid #bbb',
      // borderBottom: '1px solid transparent',
      padding: '0px',
    },
  },
  media: {
    height: 240,
    objectFit: 'contain',
    width: '100%',
    borderBottom: '1px solid #D8D8D8',
  },
  list: {
    padding: '0',
  },
  popover: {
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '0px',
  },
  paper: {
    padding: theme?.spacing || 0,
  },
})

class TeamstoreCard extends React.Component {
  state = {
    hover: false,
    activeIndex: 0,
  }
  hoverOn = event => {
    this.setState({ hover: true })
  }

  hoverOff = () => {
    this.setState({ hover: false })
  }
  render() {
    const {
      classes,
      langCode,
      teamstore,
      urlPrefix,
      desktopWidthCol,
      mobileWidthCol,
    } = this.props
    var title = null
    try {
      title = teamstore.name
      if (teamstore.title.text[0].content) {
        title = getTextFromLanguage(_get(teamstore, 'title.text', []), langCode)
      }
    } catch (error) {}
    var src = null
    try {
      src = teamstore.logo
    } catch (error) {}
    var slug = null
    try {
      if (teamstore && teamstore.slugs) {
        slug = `/${urlPrefix}${
          _get(teamstore, 'slugs', []).find(e => e.langCode === langCode)
            .content
        }`
      }
    } catch (error) {
      console.error('TeamstoreCard.slugs.error', error)
    }
    return (
      <Grid
        item
        xs={mobileWidthCol || 12}
        sm={mobileWidthCol || 12}
        md={desktopWidthCol || 12}
        lg={desktopWidthCol || 12}
        xl={desktopWidthCol || 12}
        style={{
          position: 'relative',
          padding: '8px',
        }}
        spacing={0}
      >
        <Card
          className={classes.card}
          onMouseEnter={this.hoverOn}
          onMouseLeave={this.hoverOff}
          elevation={0}
        >
          <Link
            to={slug}
            style={{
              textDecoration: 'none',
              color: '#000',
              textAlign: 'center',
            }}
          >
            <CardContent style={{ height: 250, padding: '0px' }}>
              {src !== null ? (
                <Img height={250} src={src} alt={title} />
              ) : (
                <Img height={250} src={DummyImage} alt={title} />
              )}
            </CardContent>

            <CardContent>
              <Typography variant="subtitle1">
                {title === null ? 'Title' : title}
              </Typography>
            </CardContent>
          </Link>
        </Card>
      </Grid>
    )
  }
}
TeamstoreCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TeamstoreCard)
