import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
const Address = ({ address }) => {
  return address ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 180
      }}
    >
      <Typography variant="subtitle1">
        Email: <span style={{ fontWeight: 300 }}>{address.email}</span>
      </Typography>
      <Typography variant="subtitle1">
        Name:{' '}
        <span style={{ fontWeight: 300 }}>
          {address.firstName} {address.lastName}
        </span>
      </Typography>
      <Typography variant="subtitle1">
        Company: <span style={{ fontWeight: 300 }}>{address.company}</span>
      </Typography>
      <Typography variant="subtitle1">
        VAT No: <span style={{ fontWeight: 300 }}>{address.vatNumber}</span>
      </Typography>
      <Typography variant="subtitle1">
        Phone: <span style={{ fontWeight: 300 }}>{address.phone}</span>
      </Typography>
      <Typography variant="subtitle1">
        Address 1: <span style={{ fontWeight: 300 }}>{address.address1}</span>
      </Typography>
      <Typography variant="subtitle1">
        ZIP/Postcode: <span style={{ fontWeight: 300 }}>{address.zip}</span>
      </Typography>
      <Typography variant="subtitle1">
        City: <span style={{ fontWeight: 300 }}>{address.city}</span>
      </Typography>
      <Typography variant="subtitle1">
        Country: <span style={{ fontWeight: 300 }}>{address.country}</span>
      </Typography>
    </div>
  ) : null;
};
export default Address;
