import React, { Component } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../../common/Select';
import { Link } from 'gatsby';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_SHOP_PRODUCT_ATTRIBUTES } from '../../gql/admin-queries';
import {
  ADMIN_CREATE_ASSIGNED_PRODUCT_ATTRIBUTE,
  ADMIN_DELETE_ASSIGNED_PRODUCT_ATTRIBUTE
} from '../../gql/admin-mutations';

import { getTextFromLanguage } from '../../../../helper/languageHelper';
import Input from '../../../common/Input';

class ProductPackagePrice extends Component {
  state = {};
  render() {
    const {
      currencies,
      defaultCurrency,
      productPackage,
      updateProductPackage
    } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography data-cy="ProductPackage-Price-header" variant="subtitle1">
            Price
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Paper elevation={0} style={{ padding: 24 }}>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid item>
                <Input
                  dataCy="ProductPackage-Price-Input"
                  label="Price"
                  placeholder="Enter price"
                  value={productPackage.price ? productPackage.price.value : ''}
                  onChange={e => {
                    const newPrice = {
                      currency: defaultCurrency,
                      value: parseFloat(e.target.value)
                    };
                    updateProductPackage({
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateProductPackage: {
                          ...productPackage,
                          price: {
                            ...newPrice,
                            __typename: 'Price'
                          }
                        }
                      },
                      variables: {
                        id: productPackage.id,
                        input: {
                          price: newPrice
                        }
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item>
                {defaultCurrency}
                {/* <Select
                  onChange={e => {
                    const newPrice = {
                      currency: e.target.value,
                      value:
                        productPackage.price && productPackage.price.value
                          ? productPackage.price.value
                          : 0
                    };
                    updateProductPackage({
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateProductPackage: {
                          ...productPackage,
                          price: {
                            ...newPrice,
                            __typename: 'Price'
                          }
                        }
                      },
                      variables: {
                        id: productPackage.id,
                        input: {
                          price: newPrice
                        }
                      }
                    });
                  }}
                  value={
                    productPackage.price ? productPackage.price.currency : ''
                  }
                  label="Price"
                >
                  <option value={''}>...</option>
                  {currencies.map(cur => (
                    <option value={cur}>{cur}</option>
                  ))}
                </Select> */}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default ProductPackagePrice;
