import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import DesignroomDesignsList from './DesignroomDesignsList';

class DesignroomProducts extends Component {
  render() {
    const { shopId } = this.props;
    return (
      <Grid>
        <DesignroomDesignsList shopId={shopId} />
      </Grid>
    );
  }
}
export default DesignroomProducts;
