import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// import Dropzone from 'react-dropzone';
import {
  ADMIN_GET_SHOP_SETTINGS,
  ADMIN_GET_TEAMSTORE
} from '../../gql/admin-queries';
import {
  ADMIN_CREATE_COLLECTION_SALESCHANNEL,
  ADMIN_CREATE_PRODUCT_SALESCHANNEL,
  ADMIN_CREATE_PRODUCT_PACKAGE_SALESCHANNEL
} from '../../gql/admin-mutations';
import _get from 'lodash/get';
import Input from '../../../common/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '../../../common/TextField';
import './ProductFileDrop.css';
import { ADMIN_SHOP_PRODUCTS } from '../../gql/admin-queries';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import TeamstoreAssignedProducts from './TeamstoreAssignedProducts';
import TeamstoreAssignedProductPackages from './TeamstoreAssignedProductPackages';
import TeamstoreAssignedCollections from './TeamstoreAssignedCollections';
import AdminSearchProduct from '../common/AdminSearchProduct';
import AdminSearchProductPackage from '../common/AdminSearchProductPackage';
import AdminSearchCollection from '../common/AdminSearchCollection';
const styles = theme => ({
  root: {
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  },
  cell: {
    padding: '4px 4px 4px 8px !important'
  }
});

class TeamstoreProducts extends Component {
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  state = {
    productSelected: null,
    productPackageSelected: null
  };

  render() {
    const {
      classes,
      teamstore,
      teamstoreId,
      updateTeamstore,
      defaultLanguage,
      languages,
      shopId
    } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Collections
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  These are the collections assigned for this particular team
                  store
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 0, padding: 24 }}>
              <Grid container>
                <Grid
                  container
                  style={{ marginTop: '-24px', marginBottom: '8px' }}
                >
                  <Mutation
                    mutation={ADMIN_CREATE_COLLECTION_SALESCHANNEL}
                    update={(
                      cache,
                      { data: { createCollectionSalesChannel } }
                    ) => {
                      if (createCollectionSalesChannel) {
                        const data = cache.readQuery({
                          query: ADMIN_GET_TEAMSTORE,
                          variables: { id: teamstore.id }
                        });
                        data.teamstore.collections.edges.unshift({
                          collectionId:
                            createCollectionSalesChannel.collectionId,
                          salesChannelId: teamstoreId,
                          collection: {
                            ...this.state.collectionSelected,
                            __typename: 'Collection'
                          },
                          __typename: 'CollectionSalesChannel'
                        });
                        cache.writeQuery({
                          query: ADMIN_GET_TEAMSTORE,
                          variables: { id: teamstore.id },
                          data
                        });
                      }
                    }}
                  >
                    {(
                      createCollectionSalesChannel,
                      { data, loading, error }
                    ) => {
                      return (
                        <AdminSearchCollection
                          defaultLanguage={defaultLanguage}
                          classes={classes}
                          shopId={shopId}
                          onSelect={collection => {
                            createCollectionSalesChannel({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                createCollectionSalesChannel: {
                                  salesChannelId: teamstoreId,
                                  collectionId: collection.id,
                                  __typename: 'CollectionSalesChannel'
                                }
                              },
                              variables: {
                                shopId: shopId,
                                collectionId: collection.id,
                                salesChannelId: teamstoreId
                              }
                            });
                            this.setState({
                              collectionSelected: collection
                            });
                          }}
                          excludeIds={teamstore.collections.edges.map(tp => {
                            try {
                              return tp.node.collection.id;
                            } catch (error) {
                              return null;
                            }
                          })}
                        />
                      );
                    }}
                  </Mutation>
                </Grid>
                <TeamstoreAssignedCollections
                  defaultLanguage={defaultLanguage}
                  languages={languages}
                  classes={classes}
                  teamstore={teamstore}
                  teamstoreId={teamstoreId}
                  shopId={shopId}
                />
              </Grid>
            </Paper>
          </Grid>

          <Grid
            style={{
              marginTop: '2em'
            }}
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Products
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  These are the product assigned for this particular team store
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              marginTop: '2em'
            }}
            item
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
          >
            <Paper elevation={0} style={{ padding: 0, padding: 24 }}>
              <Grid container>
                <Grid
                  container
                  style={{ marginTop: '-24px', marginBottom: '8px' }}
                >
                  <Mutation
                    mutation={ADMIN_CREATE_PRODUCT_SALESCHANNEL}
                    update={(
                      cache,
                      { data: { createProductSalesChannel } }
                    ) => {
                      if (createProductSalesChannel) {
                        const data = cache.readQuery({
                          query: ADMIN_GET_TEAMSTORE,
                          variables: { id: teamstore.id }
                        });
                        data.teamstore.products.edges.unshift({
                          productId: createProductSalesChannel.productId,
                          salesChannelId: teamstoreId,
                          product: {
                            ...this.state.productSelected,
                            __typename: 'Product'
                          },
                          __typename: 'ProductSalesChannel'
                        });
                        cache.writeQuery({
                          query: ADMIN_GET_TEAMSTORE,
                          variables: { id: teamstore.id },
                          data
                        });
                      }
                    }}
                  >
                    {(createProductSalesChannel, { data, loading, error }) => {
                      return (
                        <AdminSearchProduct
                          classes={classes}
                          shopId={shopId}
                          onSelect={product => {
                            createProductSalesChannel({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                createProductSalesChannel: {
                                  salesChannelId: teamstoreId,
                                  productId: product.id,
                                  __typename: 'ProductSalesChannel'
                                }
                              },
                              variables: {
                                shopId: shopId,
                                productId: product.id,
                                salesChannelId: teamstoreId
                              }
                            });
                            this.setState({
                              productSelected: product
                            });
                          }}
                          excludeIds={teamstore.products.edges.map(
                            tp => tp.node.product.id
                          )}
                        />
                      );
                    }}
                  </Mutation>
                </Grid>
                <TeamstoreAssignedProducts
                  defaultLanguage={defaultLanguage}
                  languages={languages}
                  classes={classes}
                  teamstore={teamstore}
                  teamstoreId={teamstoreId}
                  shopId={shopId}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid
            style={{
              marginTop: '2em'
            }}
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Product packages
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  These are the product packages assigned for this particular
                  team store
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              marginTop: '2em'
            }}
            item
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
          >
            <Paper elevation={0} style={{ padding: 0 }}>
              <Grid container>
                <Grid container style={{ marginTop: 0 }}>
                  <Mutation
                    mutation={ADMIN_CREATE_PRODUCT_PACKAGE_SALESCHANNEL}
                    update={(
                      cache,
                      { data: { createProductPackageSalesChannel } }
                    ) => {
                      if (createProductPackageSalesChannel) {
                        const data = cache.readQuery({
                          query: ADMIN_GET_TEAMSTORE,
                          variables: { id: teamstore.id }
                        });
                        data.teamstore.productPackages.edges.unshift({
                          __typename: 'SalesChannelProductPackageEdge',
                          node: {
                            productPackageId:
                              createProductPackageSalesChannel.productPackageId,
                            salesChannelId: teamstoreId,
                            productPackage: {
                              ...this.state.productPackageSelected,
                              __typename: 'ProductPackage'
                            },
                            __typename: 'ProductPackageSalesChannel'
                          }
                        });
                        cache.writeQuery({
                          query: ADMIN_GET_TEAMSTORE,
                          variables: { id: teamstore.id },
                          data
                        });
                      }
                    }}
                  >
                    {(
                      createProductPackageSalesChannel,
                      { data, loading, error }
                    ) => {
                      return (
                        <AdminSearchProductPackage
                          classes={classes}
                          shopId={shopId}
                          onSelect={productPackage => {
                            createProductPackageSalesChannel({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                createProductPackageSalesChannel: {
                                  salesChannelId: teamstoreId,
                                  productPackageId: productPackage.id,
                                  __typename: 'ProductPackageSalesChannel'
                                }
                              },
                              variables: {
                                shopId: shopId,
                                productPackageId: productPackage.id,
                                salesChannelId: teamstoreId
                              }
                            });
                            this.setState({
                              productPackageSelected: productPackage
                            });
                          }}
                          excludeIds={teamstore.productPackages.edges.map(
                            tp => tp.node.productPackage.id
                          )}
                        />
                      );
                    }}
                  </Mutation>
                </Grid>
                <TeamstoreAssignedProductPackages
                  classes={classes}
                  teamstore={teamstore}
                  teamstoreId={teamstoreId}
                  shopId={shopId}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TeamstoreProducts);
