import React, { useContext } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import Imgix from 'react-imgix'
import { getTextFromLanguage } from '../../../../../../helper/languageHelper'
import { SelectedLanguageContext } from '../LanguageContext'
import { Link } from 'gatsby'
import { ThemeContext } from '../ThemeContext'

interface Props {
  element: any
  dialog: boolean
  urlPrefix: string
  isMobile: boolean
}

const Banner: React.FC<Props> = props => {
  const { element, dialog, urlPrefix, isMobile } = props

  const selectedLanguage = useContext(SelectedLanguageContext)

  const theme = useContext(ThemeContext)

  let styles = null
  try {
    if (element?.contentStyle?.length > 0) {
      styles = element.contentStyle[0]
    }
  } catch (error) {
    console.log(error)
  }

  return (
    <Grid
      container={true}
      direction="column"
      justify="center"
      alignItems="center"
      style={{
        position: 'relative',
        width: '100%',
        height: isMobile ? '20vh' : styles?.maxHeight || '550px',
      }}
    >
      {element?.contentImage ? (
        <Grid
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0,
            height: '100%',
            width: '100%',
          }}
          item={true}
          xs={12}
        >
          <Imgix
            htmlAttributes={{
              alt: '',
              style: {
                width: '100%',
                height: '100%',
                objectFit: styles?.objectFit
                  ? styles?.objectFit
                  : isMobile
                  ? 'contain'
                  : 'cover',
                objectPosition: `50% ${styles?.objectPosition || '50%'}`,
              },
            }}
            src={element?.contentImage || null}
            imgixParams={{
              auto: 'compress',
              ...(styles &&
                styles?.copyrightContent &&
                getTextFromLanguage(
                  styles?.copyrightContent?.text,
                  selectedLanguage
                ) && {
                  txt: getTextFromLanguage(
                    styles?.copyrightContent?.text,
                    selectedLanguage
                  ),
                }),
              txtlead: 2,
              txtpad: 15,
              txtfont: styles?.copyrightFontFamily || 'Arial',
              txtclr: styles?.copyrightFontColor || '#fff',
              txtalign: `${
                styles && styles.copyrightHorizontalAlignment
                  ? styles.copyrightHorizontalAlignment.toLowerCase()
                  : 'right'
              }, ${
                styles && styles.copyrightVerticalAlignment
                  ? styles.copyrightVerticalAlignment.toLowerCase()
                  : 'bottom'
              }`,
            }}
          />
        </Grid>
      ) : null}
      {element?.imageTitle &&
      getTextFromLanguage(element?.imageTitle?.text, selectedLanguage) ? (
        <Grid
          style={
            !dialog
              ? {
                  zIndex: 1,
                  alignSelf: styles?.titleAlign || 'center',
                }
              : {
                  zIndex: 1,
                  alignSelf: styles?.titleAlign || 'center',
                }
          }
          item
        >
          <Typography
            style={{
              color: styles?.titleColor || '#fff',
              padding:
                styles?.titleAlign && styles?.titleAlign === 'flex-start'
                  ? '0px 0px 20px 100px'
                  : 20,
            }}
            component="h2"
            variant="h1"
          >
            {getTextFromLanguage(element?.imageTitle?.text, selectedLanguage)}
          </Typography>
        </Grid>
      ) : null}
      {element?.imageSubTitle &&
      getTextFromLanguage(element?.imageSubTitle?.text, selectedLanguage) ? (
        <Grid
          style={
            !dialog
              ? {
                  zIndex: 1,
                  alignSelf: styles?.subTitleAlign || 'center',
                }
              : {
                  zIndex: 1,
                  alignSelf: styles?.subTitleAlign || 'center',
                }
          }
          item
        >
          <Typography
            style={{
              color: styles?.subTitleColor || '#fff',
              padding:
                styles?.titleAlign && styles?.titleAlign === 'flex-start'
                  ? '0px 0px 20px 100px'
                  : 20,
            }}
            variant="h4"
          >
            {getTextFromLanguage(
              element?.imageSubTitle?.text,
              selectedLanguage
            )}
          </Typography>
        </Grid>
      ) : null}
      {element?.imageButton ? (
        <Grid
          style={
            !dialog
              ? {
                  alignSelf: styles?.buttonAlign || 'center',
                  padding:
                    styles?.titleAlign && styles?.titleAlign === 'flex-start'
                      ? '0px 0px 20px 100px'
                      : 20,
                }
              : {
                  alignSelf: styles?.buttonAlign || 'center',
                }
          }
          item
        >
          {element?.imageButton &&
          element?.imageButton?.text &&
          getTextFromLanguage(element?.imageButton?.text, selectedLanguage) ? (
            <>
              {element?.imageButton &&
              element?.imageButtonUrl?.indexOf('http') !== -1 ? (
                <Button
                  style={{
                    ...theme?.theme?.button?.style,
                    color: styles?.buttonColor || 'white',
                    border: `${
                      `1px solid ${styles?.buttonBorderColor}` ||
                      '1px solid white'
                    }`,
                    backgroundColor: styles?.buttonBackgroundColor || null,
                  }}
                  variant={styles?.buttonVariant || 'outlined'}
                >
                  <a
                    style={{
                      textDecoration: 'none',
                      color: styles?.buttonColor || 'white',
                    }}
                    href={element?.imageButtonUrl}
                  >
                    {getTextFromLanguage(
                      element?.imageButton?.text,
                      selectedLanguage
                    )}
                  </a>
                </Button>
              ) : (
                <Button
                  component={Link}
                  to={
                    element?.imageButtonUrl
                      ? `/${urlPrefix}${element?.imageButtonUrl || ''}`
                      : `/${urlPrefix}`
                  }
                  style={{
                    ...theme?.theme?.button?.style,
                    color: styles?.buttonColor || 'white',
                    border: `${
                      `1px solid ${styles?.buttonBorderColor}` ||
                      '1px solid white'
                    }`,
                    backgroundColor: styles?.buttonBackgroundColor || null,
                  }}
                  variant={styles?.buttonVariant || 'outlined'}
                >
                  {getTextFromLanguage(
                    element?.imageButton?.text,
                    selectedLanguage
                  )}
                </Button>
              )}
            </>
          ) : null}
        </Grid>
      ) : null}
    </Grid>
  )
}

export default Banner
