import React, { useContext } from 'react'
import {
  Grid,
  Typography,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core'
import { Link } from 'gatsby'
import Imgix from 'react-imgix'
import { getTextFromLanguage } from '../../../../../../../helper/languageHelper'
import {
  SelectedLanguageContext,
  UrlPrefixContext
} from '../../LanguageContext'

interface Props extends WithStyles<typeof styles> {
  products: any
}

const styles = () =>
  createStyles({
    card: {
      '&:hover': {
        boxShadow:
          ' 0 3px 6px rgba(140, 140, 140, 0.16), 0 3px 6px rgba(140, 140, 140, 0.23)'
      }
    }
  })

const TeamstoreProducts: React.FC<Props> = props => {
  const { products, classes } = props

  const selectedLanguage = useContext(SelectedLanguageContext)
  const urlPrefix = useContext(UrlPrefixContext)

  if (!products)
    return (
      <Grid
        justify="center"
        style={{ padding: 25, textAlign: 'center', fontStyle: 'italic' }}
      >
        <span> Please add products to display</span>
      </Grid>
    )

  return (
    <Grid justify="center" container={true}>
      {products.edges.map((product: any, index: number) => {
        if (products.edges.length > 1) {
          return (
            <Grid
              key={index}
              item={true}
              xs={6}
              sm={6}
              md={4}
              lg={3}
              xl={3}
              style={{
                padding: 8
              }}
            >
              <Link
                to={
                  product?.node?.product?.slugs
                    ? `/${`${urlPrefix}/` +
                        getTextFromLanguage(
                          product?.node?.product?.slugs,
                          selectedLanguage
                        )}`
                    : '/'
                }
                style={{
                  position: 'relative',
                  color: '#000',
                  textDecoration: 'none'
                }}
              >
                <Grid
                  container={true}
                  className={classes.card}
                  style={{
                    border: '1px solid #bbb',
                    borderRadius: 4,
                    height: 400,
                    backgroundColor: '#fff'
                  }}
                >
                  <Grid item={true} xs={12} style={{ height: '300px' }}>
                    <Imgix
                      height={270}
                      htmlAttributes={{
                        alt: '',
                        style: {
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          display: 'block'
                        }
                      }}
                      src={product?.node?.product?.media?.[0]?.image || ''}
                      imgixParams={{
                        auto: 'compress'
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={12} style={{ padding: '4px 16px' }}>
                    <Typography>
                      {product?.node?.product?.price?.value || null}{' '}
                      {product?.node?.product?.price?.currency || null}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} style={{ padding: '4px 16px' }}>
                    <Typography
                      variant="h4"
                      style={{ textTransform: 'lowercase' }}
                    >
                      {product?.node?.product?.title
                        ? product?.node.product.title.defaultValue
                        : null}
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
          )
        } else {
          return (
            <Grid
              style={{
                height: '300px'
              }}
              item={true}
              xs={12}
            >
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain'
                }}
                src={product?.node?.product?.media?.[0]?.image || ''}
              />
            </Grid>
          )
        }
      })}
    </Grid>
  )
}

export default withStyles(styles)(TeamstoreProducts)
