import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _sortBy from 'lodash/sortBy';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _uniqWith from 'lodash/uniqWith';
import BoardProductPreview from '../common/BoardProductPreview';
import PrintingTechnique from './PrintingTechnique';
import Color from './Color';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import label1 from '../steps/label/CareLabel-v1.svg';
import label2 from '../steps/label/CareLabel-v2.svg';
import label3 from '../steps/label/CareLabel-v3.svg';
import label4 from '../steps/label/CareLabel-v4.svg';
import label5 from '../steps/label/CareLabel-v5.svg';
import label6 from '../steps/label/CareLabel-v6.svg';

import label1_no_border from '../steps/label/CareLabel-v1-no-border.svg';
import label2_no_border from '../steps/label/CareLabel-v2-no-border.svg';
import label3_no_border from '../steps/label/CareLabel-v3-no-border.svg';
import label4_no_border from '../steps/label/CareLabel-v4-no-border.svg';
import label5_no_border from '../steps/label/CareLabel-v5-no-border.svg';
import label6_no_border from '../steps/label/CareLabel-v6-no-border.svg';

import ReactSVG from 'react-svg';

import _get from 'lodash/get';
import _find from 'lodash/find';
import { Typography } from '@material-ui/core';
import { SHOP_ID } from '../../../brikl-config';

const styles = theme => ({
  root: {
    padding: '15px 35px',
    background: '#fff',
    marginBottom: '16px'
  },
  headline: {
    color: '#000'
  },
  listItem: {
    color: '#000',
    marginRight: '15px',
    minWidth: '75px'
  },
  previewContainer: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center'
  },
  preview: {
    background: '#fff',
    height: '300px',
    width: '300px'
  },
  label: {
    background: '#fff',
    widht: '200px',
    height: 'auto'
  },
  order: {
    padding: '10px',
    minWidth: '80px',
    maxWidth: '80px',
    borderBottom: '1px solid #e5e5e5'
  },
  table: {
    padding: '0px 15px',
    color: '#000'
  },
  tableCell: {
    padding: '8px',
    color: '#000',
    lineHeight: 1.75
  },
  note: {
    padding: '15px'
  },
  logoContainer: {
    padding: '15px'
  },
  labelContainer: {
    padding: '15px'
  },
  orderContainer: {
    padding: '15px',
    display: 'flex'
  },
  careLabel: {
    width: '200px',
    height: 'auto'
  }
});

class TechpackReview extends React.Component {
  constructor(props) {
    super(props);

    this.labelMap = {};
    this.labelMap['CL-1'] = label1;
    this.labelMap['CL-2'] = label2;
    this.labelMap['CL-3'] = label3;
    this.labelMap['CL-4'] = label4;
    this.labelMap['CL-5'] = label5;
    this.labelMap['CL-6'] = label6;

    this.labelMap_Build = {};
    this.labelMap_Build['CL-1'] = label1_no_border;
    this.labelMap_Build['CL-2'] = label2_no_border;
    this.labelMap_Build['CL-3'] = label3_no_border;
    this.labelMap_Build['CL-4'] = label4_no_border;
    this.labelMap_Build['CL-5'] = label5_no_border;
    this.labelMap_Build['CL-6'] = label6_no_border;

    this.labelMap_Dimension = {};
    this.labelMap_Dimension['CL-1'] = { width: 113.39, height: 56.69, y: 7.5 };
    this.labelMap_Dimension['CL-2'] = { width: 112.2, height: 56.3, y: 7.5 };
    this.labelMap_Dimension['CL-3'] = { width: 114.5, height: 57.7, y: 61.8 };
    this.labelMap_Dimension['CL-4'] = { width: 141.4, height: 56.2, y: 8.6 };
    this.labelMap_Dimension['CL-5'] = { width: 246, height: 93, y: 1 };
    this.labelMap_Dimension['CL-6'] = { width: 181, height: 80, y: 22 };

    this.labelBase64 = null;
  }

  componentDidMount() {
    const { classes, cart, cartItem, isSupplier } = this.props;

    console.log('cartItem', cartItem);

    if (
      cartItem &&
      cartItem.design.careLabelId !== null &&
      cartItem.design.boardLogos.length
    ) {
      // cartItem.design.careLabelLogoId
      // cartItem.design.boardLogos

      const logo = _find(cartItem.design.boardLogos, {
        id: cartItem.design.careLabelLogoId
      });

      // console.log('logo label', logo);
      if (logo) {
        this.buildLabel(logo.logoDesign.src, cartItem.design.careLabelId);
      }
    }
  }

  buildLabel(logoSrc, careLabelId) {
    // console.log('buildLabel', logoSrc, careLabelId);

    try {
      var self = this;
      var labelLOGO = new window.Image();
      labelLOGO.crossOrigin = 'anonymous';
      labelLOGO.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = labelLOGO.width;
        canvas.height = labelLOGO.height;
        var ctx = canvas.getContext('2d');
        // ctx.drawImage(labelLOGO, 0, 0);

        var buffer = document.createElement('canvas');
        buffer.width = labelLOGO.width;
        buffer.height = labelLOGO.height;
        var bx = buffer.getContext('2d');

        bx.fillStyle = '#000000'; // COLOR TINT BASE COLOR IS WHITE THEN USE THREEJS COLOR FOR CHANGE
        bx.fillRect(0, 0, buffer.width, buffer.height);
        // destination atop makes a result with an alpha channel identical to fg, but with all pixels retaining their original color *as far as I can tell*
        bx.globalCompositeOperation = 'destination-atop';
        bx.drawImage(labelLOGO, 0, 0);
        // to tint the image, draw it first
        ctx.drawImage(labelLOGO, 0, 0);
        ctx.drawImage(buffer, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        var svg = document.getElementById(careLabelId);
        var serializer = new XMLSerializer();
        var str = serializer.serializeToString(svg);
        console.log('svg', careLabelId, str);

        var defaultSVG_Width = this.labelMap_Dimension[careLabelId].width;
        var defaultSVG_Height = this.labelMap_Dimension[careLabelId].height;
        var y = this.labelMap_Dimension[careLabelId].y;

        if (labelLOGO.height >= labelLOGO.width) {
          var h_factor = labelLOGO.height / defaultSVG_Height;
          var widthSVG = labelLOGO.width / h_factor;
          var halfWidthSVG = (widthSVG / 2) * -1;
          var heightSVG = defaultSVG_Height;
        } else {
          var w_factor = labelLOGO.width / defaultSVG_Width;
          var heightSVG = labelLOGO.height / w_factor;
          var halfWidthSVG = (defaultSVG_Width / 2) * -1;
          var widthSVG = defaultSVG_Width;
        }

        var replaceData =
          '<image xlink:href="' +
          dataURL +
          '" width="' +
          widthSVG +
          '" height="' +
          heightSVG +
          '" x="50%" y="' +
          y +
          '" transform="translate(' +
          halfWidthSVG +
          ', 0)" />';

        str = str.replace('display: none;', '');
        str = str.replace(/<[^>]*div[^>]*>/g, '');
        str = str.replace(/<text[^>]*>(.*?)<\/text>/, replaceData);

        var base64 = window.btoa(str);
        self.labelBase64 = 'data:image/svg+xml;base64,' + base64;
        self.setState({ update: true });
      }; // END onload

      labelLOGO.src = logoSrc;
    } catch (error) {
      console.error('TechpackReview.error', error);
    }
  }

  labelPreview() {
    const { classes, cart, cartItem, isSupplier } = this.props;

    if (this.labelBase64 !== null) {
      return (
        <img
          className={classes.careLabel}
          src={this.labelBase64}
          alt="Care label"
        />
      );
    } else if (cartItem && cartItem.design.careLabelId !== null) {
      return (
        <div>
          <img
            className={classes.careLabel}
            src={this.labelMap[cartItem.design.careLabelId]}
            alt="Care label"
          />
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { classes, cart, cartItem, isSupplier, urlPrefix } = this.props;
    var sizeList = [];
    var logos = [];
    sizeList.push(
      <TableHead key="table-head-size">
        <TableRow>
          <TableCell className={classes.tableCell}>XS</TableCell>
          <TableCell className={classes.tableCell}>S</TableCell>
          <TableCell className={classes.tableCell}>M</TableCell>
          <TableCell className={classes.tableCell}>L</TableCell>
          <TableCell className={classes.tableCell}>XL</TableCell>
        </TableRow>
      </TableHead>
    );

    var texts = [];

    var elementList = [];

    if (cartItem) {
      var itemTotals = '';
      try {
        cart.totalCalculated.cartTotalGroups.forEach(totalGroup => {
          var hasItem = totalGroup.cartItemTotals.find(o => {
            return o.boardProductId === cartItem.boardProductId;
          });
          if (hasItem && hasItem.finalTotal) {
            itemTotals = hasItem;
          }
        });
      } catch (error) {
        console.error('TechpackReview.error', error);
      }

      // console.log('TechPackReview.itemTotals', itemTotals);
      var countTotalQty = 0;
      if (cartItem.sizeQuantities) {
        cartItem.sizeQuantities.forEach(s => {
          countTotalQty = countTotalQty + s.quantity;
        });
        sizeList.push(
          <TableBody key="tablebody-size">
            <TableRow>
              <TableCell data-cy="xs-quantity" className={classes.tableCell}>
                {cartItem.sizeQuantities.find(o => o.size === 'XS')
                  ? cartItem.sizeQuantities.find(o => o.size === 'XS').quantity
                  : 0}
              </TableCell>
              <TableCell data-cy="s-quantity" className={classes.tableCell}>
                {cartItem.sizeQuantities.find(o => o.size === 'S')
                  ? cartItem.sizeQuantities.find(o => o.size === 'S').quantity
                  : 0}
              </TableCell>
              <TableCell data-cy="m-quantity" className={classes.tableCell}>
                {cartItem.sizeQuantities.find(o => o.size === 'M')
                  ? cartItem.sizeQuantities.find(o => o.size === 'M').quantity
                  : 0}
              </TableCell>
              <TableCell data-cy="l-quantity" className={classes.tableCell}>
                {cartItem.sizeQuantities.find(o => o.size === 'L')
                  ? cartItem.sizeQuantities.find(o => o.size === 'L').quantity
                  : 0}
              </TableCell>
              <TableCell data-cy="xl-quantity" className={classes.tableCell}>
                {cartItem.sizeQuantities.find(o => o.size === 'XL')
                  ? cartItem.sizeQuantities.find(o => o.size === 'XL').quantity
                  : 0}
              </TableCell>
            </TableRow>
          </TableBody>
        );
      } else {
        console.error('Missing sizeQuantities');
        return null;
      }
      if (countTotalQty === 0) {
        console.error('Total qty is 0');
        return null;
      }

      const uniqueElementList = _uniqWith(
        cartItem.design.elementDesignData,
        function(arrVal, othVal) {
          return (
            arrVal.baseElementCode.split('-')[0] ===
            othVal.baseElementCode.split('-')[0]
          );
        }
      );
      _sortBy(uniqueElementList, [
        'baseElementName',
        'baseElementCode'
      ]).forEach(n => {
        var typeName = '';
        var fillType = n.fillType;
        var aopUrl = '';
        var downloadUrl = '';
        var color = n.colorHex ? n.colorHex : null;
        // eslint-disable-next-line
        var aop = 'None selected';
        if (n.colorType === 'custom') {
          typeName = 'Color (Sublimation)';
          try {
            typeName = n.colorRGB
              ? typeName +
                ` rgb(${(n.colorRGB.r * 255).toFixed(0)},${(
                  n.colorRGB.g * 255
                ).toFixed(0)},${(n.colorRGB.b * 255).toFixed(0)})`
              : '';
          } catch (error) {}
        }
        if (n.colorType === 'stock') {
          typeName = 'Color (Dyed)';
        }
        if (fillType === 'AOP') {
          typeName = 'Print (Sublimation)';
          color = 'rgba(255,255,255,0)';
          aop = n.aopName;
          aopUrl = `url(${n.aopUrl})`;
          downloadUrl = n.aopUrl;
        }
        var fabricContent = '';
        if (
          n.selectedFabric &&
          n.selectedFabric.fabricTypes &&
          n.selectedFabric.fabricTypes[0]
        ) {
          _sortBy(n.selectedFabric.fabricTypes[0].fabricContents, ['percent'])
            .reverse()
            .forEach(fabric => {
              const myFabricContentLong =
                fabric.percent +
                '% ' +
                (fabric.textile ? fabric.textile.textileMaterial : 'N/A');
              fabricContent =
                fabricContent === ''
                  ? myFabricContentLong
                  : fabricContent + ' ' + myFabricContentLong;
            });
        }
        try {
          elementList.push(
            <TableRow key={'el-' + n.id}>
              <TableCell className={classes.tableCell}>
                {n.baseElementName}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {n.baseElementELM}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {color ? (
                  <span
                    style={{
                      width: '30px',
                      height: '30px',
                      display: 'block',
                      border: '1px solid #eee',
                      backgroundColor: color,
                      backgroundImage: aopUrl,
                      backgroundSize: 'contain'
                    }}
                  />
                ) : (
                  'N/A'
                )}
                {isSupplier === true && aopUrl ? (
                  <a
                    style={{
                      color: '#000',
                      display: 'block'
                    }}
                    href={downloadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                ) : null}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {typeName}
                {n.colorType === 'stock' && n.colorId ? (
                  <Color colorId={n.colorId} />
                ) : null}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {n.selectedFabric ? n.selectedFabric.name : 'N/A'}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {fabricContent}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {n.selectedFabric &&
                n.selectedFabric.weights &&
                n.selectedFabric.weights[0]
                  ? n.selectedFabric.weights[0].weight +
                    n.selectedFabric.weights[0].weightUnit
                  : 'N/A'}
              </TableCell>
            </TableRow>
          );
        } catch (error) {}
      });

      _get(cartItem, 'design.boardLogos', []).forEach(logo => {
        try {
          logos.push(
            <div key={'logol' + logo.id}>
              <List className={classes.list} component="nav">
                <ListItem>
                  <span className={classes.listItem}>Name</span>
                  <span className={classes.listItem}>
                    {logo.logoDesign.name}
                  </span>
                </ListItem>
                <ListItem>
                  <span className={classes.listItem}>Width</span>
                  <span className={classes.listItem}>
                    {logo.logoDesign.width + ' cm'}
                  </span>
                </ListItem>
                <ListItem>
                  <span className={classes.listItem}>Height</span>
                  <span className={classes.listItem}>
                    {logo.logoDesign.height + ' cm'}
                  </span>
                </ListItem>
                <ListItem>
                  <span className={classes.listItem}>Color:</span>
                  <span className={classes.listItem}>
                    {logo.logoDesign.colorHex}
                    <span
                      style={{
                        width: '30px',
                        height: '30px',
                        display: 'block',
                        border: '1px solid #eee',
                        backgroundColor: logo.logoDesign.colorHex,
                        backgroundSize: 'contain'
                      }}
                    />
                  </span>
                </ListItem>
                <ListItem>
                  <span className={classes.listItem}>
                    <PrintingTechnique
                      printingTechniqueId={logo.logoDesign.printingTechniqueId}
                    />
                  </span>
                </ListItem>
              </List>
              <br />
              {isSupplier === true ? (
                <a
                  style={{
                    color: '#000',
                    display: 'block',
                    margin: '.5em'
                  }}
                  href={logo.logoDesign.src}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </a>
              ) : null}
              <br />
              <img
                style={{ width: '250px', height: 'auto', objectFit: 'cover' }}
                key={logo.id}
                src={logo.logoDesign.src}
                alt={logo.logoDesign.name}
              />
            </div>
          );
        } catch (error) {
          console.error('TechpackReview.error', error);
        }
      });
      _get(cartItem, 'design.boardTexts', []).forEach(text => {
        try {
          texts.push(
            <div>
              <List className={classes.list} component="nav">
                <ListItem>
                  <span className={classes.listItem}>Text</span>
                  <span className={classes.listItem}>
                    {text.textDesign.content}
                  </span>
                </ListItem>
                <ListItem>
                  <span className={classes.listItem}>Color</span>
                  <span className={classes.listItem}>
                    {text.textDesign.colorHex}
                  </span>
                </ListItem>
                <ListItem>
                  <span className={classes.listItem}>Font</span>
                  <span className={classes.listItem}>
                    {text.textDesign.fontFamily}
                  </span>
                </ListItem>
                <ListItem>
                  <span className={classes.listItem}>Width</span>
                  <span className={classes.listItem}>
                    {text.textDesign.width + ' cm'}
                  </span>
                </ListItem>
                <ListItem>
                  <span className={classes.listItem}>Height</span>
                  <span className={classes.listItem}>
                    {text.textDesign.height + ' cm'}
                  </span>
                </ListItem>
                <ListItem>
                  <span className={classes.listItem}>
                    <PrintingTechnique
                      printingTechniqueId={text.textDesign.printingTechniqueId}
                    />
                  </span>
                </ListItem>
              </List>
            </div>
          );
        } catch (error) {
          console.error('TechpackReview.error', error);
        }
      });
    } else {
      sizeList.push(
        <TableRow key={Math.random(1000).toString()}>
          <TableCell className={classes.tableCell} />
          <TableCell className={classes.tableCell} />
          <TableCell className={classes.tableCell} />
          <TableCell className={classes.tableCell} />
          <TableCell className={classes.tableCell} />
          <TableCell className={classes.tableCell} />
          <TableCell className={classes.tableCell} />
        </TableRow>
      );
    }

    return (
      <Paper className={classes.root} elevation={4}>
        {isSupplier === true ? (
          <Button
            style={{
              border: '1px solid',
              margin: '1em'
            }}
            variant="primary"
            onClick={() => {
              try {
                window.open(
                  `/${urlPrefix}/?s=${SHOP_ID}&p=${
                    cartItem.boardProduct.baseProduct.id
                  }&bp=${cartItem.boardProductId}&mode=view`
                );
              } catch (error) {}
            }}
          >
            View design
          </Button>
        ) : null}
        <Grid container justify={'space-between'} alignItems={'baseline'}>
          <Grid item>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.headline}
              data-cy="style-info"
            >
              Style:{' '}
              {cartItem && cartItem.boardProduct
                ? cartItem.boardProduct.baseProduct.no
                : ''}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.headline}
              data-cy="article-info"
            >
              Article:{' '}
              {cartItem && cartItem.boardProduct
                ? cartItem.boardProduct.name
                : ''}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.headline}
              data-cy="collection-info"
            >
              Collection: {cart ? cart.collectionName : ''}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" component="p">
              {' '}
              <span className={classes.headline} data-cy="season-info">
                {' '}
                Season: {cart ? cart.seasonName : ''}
              </span>
            </Typography>
          </Grid>
          <Grid item>
            {/* TODO */}
            {/* <Button>Remove article</Button> */}
          </Grid>
        </Grid>

        <Grid container direction={'row'} spacing={5}>
          <Grid
            data-cy="product-preview-img"
            item
            xs={4}
            className={classes.previewContainer}
          >
            <div className={classes.preview}>
              {cartItem ? (
                <BoardProductPreview
                  ownedByOrganisationId={cart.ownedByOrganisationId}
                  boardProductId={cartItem.boardProductId}
                />
              ) : null}
            </div>
          </Grid>
          <Grid item xs={4} className={classes.note}>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.headline}
            >
              Note:
            </Typography>
            <div data-cy="techpack-comment">
              {cartItem && cartItem.boardProduct
                ? cartItem.boardProduct.comment
                : ''}
            </div>
          </Grid>
          <Grid item xs={4} className={classes.orderContainer}>
            <Grid container>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.headline}
              >
                Order
              </Typography>
              <Grid item xs={12}>
                <Table data-cy="order-size-table">{sizeList}</Table>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.table}>
            <Typography variant="subtitle1" component="p">
              Bill of material
            </Typography>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Part name</TableCell>
                  <TableCell className={classes.tableCell}>Part#</TableCell>
                  <TableCell className={classes.tableCell}>Thumbnail</TableCell>
                  <TableCell className={classes.tableCell}>Type</TableCell>
                  <TableCell className={classes.tableCell}>
                    Fabric Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>Content</TableCell>
                  <TableCell className={classes.tableCell}>Weight</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{elementList}</TableBody>
            </Table>
          </Grid>

          <Grid item xs={4} className={classes.logoContainer}>
            <h3 className={classes.headline}>Logo</h3>
            <div className={classes.label}>{logos}</div>
          </Grid>
          <Grid item xs={4} className={classes.logoContainer}>
            <h3 className={classes.headline}>Text</h3>
            <div className={classes.label}>{texts}</div>
          </Grid>

          <Grid item xs={4} className={classes.labelContainer}>
            <h3 className={classes.headline}>Care Label</h3>
            <div className={classes.label}>
              {this.labelPreview()}

              <ReactSVG
                id={'CL-1'}
                src={this.labelMap_Build['CL-1']}
                style={{ display: 'none' }}
              />
              <ReactSVG
                id={'CL-2'}
                src={this.labelMap_Build['CL-2']}
                style={{ display: 'none' }}
              />
              <ReactSVG
                id={'CL-3'}
                src={this.labelMap_Build['CL-3']}
                style={{ display: 'none' }}
              />
              <ReactSVG
                id={'CL-4'}
                src={this.labelMap_Build['CL-4']}
                style={{ display: 'none' }}
              />
              <ReactSVG
                id={'CL-5'}
                src={this.labelMap_Build['CL-5']}
                style={{ display: 'none' }}
              />
              <ReactSVG
                id={'CL-6'}
                src={this.labelMap_Build['CL-6']}
                style={{ display: 'none' }}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

TechpackReview.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TechpackReview);
