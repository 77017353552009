import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Notification from 'react-notification';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { ADMIN_GET_PRODUCT_ATTRIBUTE } from '../../gql/admin-queries';
import { ADMIN_UPDATE_PRODUCT_ATTRIBUTE } from '../../gql/admin-mutations';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import _sortBy from 'lodash/sortBy';

import { API_STAGE } from '../../../../../brikl-config';
import Helmet from 'react-helmet';
import Input from '../../../common/Input';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import Checkbox from '@material-ui/core/Checkbox';
import _get from 'lodash/get';
import './ProductFileDrop.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProductAttributeTitle from './ProductAttributeTitle';
import ProductAttributeDescription from './ProductAttributeDescription';
import ProductAttributeMedia from './ProductAttributeMedia';
import ProductAttributeIcon from './ProductAttributeIcon';
const styles = theme => ({
  progress: {},
  feature_icon: {
    width: 'auto',
    float: 'left',
    margin: '.5em',
    display: 'block',
    cursor: 'pointer'
  },
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    marginTop: '24px !important',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  },
  rootCheckbox: {
    padding: '8px',
    color: '#B23566',
    '&$checked': {
      color: '#B23566'
    }
  },
  checked: {},
  inputCell: {
    width: 230,
    border: 'none'
  }
});

class ProductAttributeDetail extends Component {
  state = {
    isActive: false,
    message: '',
    isBriklSuperAdmin: false,
    open: false,
    value: 'Yes'
  };

  handleChangeRadio = event => {
    this.setState({ value: event.target.value });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const {
      classes,
      defaultLanguage,
      languages,
      productAttributeId,
      shopId
    } = this.props;

    if (shopId === 'vermarc') {
      require('../../../store/content/static/fonts/Vermarc-Fonts.css');
      require('../../../store/content/static/fonts/Icons.css');
    }

    return (
      <div className={classes.root}>
        <Typography>
          <Grid container>
            <Grid item xs={12}>
              <Query
                query={ADMIN_GET_PRODUCT_ATTRIBUTE}
                variables={{ id: productAttributeId }}
              >
                {({ loading, error, data: { productAttribute }, refetch }) => {
                  if (loading)
                    return <CircularProgress className={classes.progress} />;
                  if (error) return <div>Error :(</div>;
                  return (
                    <Mutation
                      update={(store, { data: { updateProductAttribute } }) => {
                        this.setState({
                          isActive: true,
                          message: 'Product attribute updated'
                        });
                      }}
                      mutation={ADMIN_UPDATE_PRODUCT_ATTRIBUTE}
                    >
                      {(updateProductAttribute, { data, loading, error }) => {
                        return (
                          <div>
                            <Helmet>
                              <title>
                                {'Product attribute - ' + productAttribute.no}
                              </title>
                            </Helmet>
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              style={{ height: 60, marginBottom: 20 }}
                            >
                              <Typography
                                data-cy="New-Attribute-header"
                                style={{
                                  fontSize: 28,
                                  fontWeight: 400,
                                  color: '#424242'
                                }}
                              >
                                {'Product attribute - ' + productAttribute.no}
                              </Typography>

                              <div style={{ flex: 1 }} />

                              <Button
                                data-cy="Attribute-Back-btn"
                                style={{ marginLeft: 16 }}
                                variant="outlined"
                                color="primary"
                                href={
                                  '/admin/' + shopId + '/inventory/attributes'
                                }
                              >
                                Back to product attribute
                              </Button>
                            </Grid>

                            {/*
***************************
Product details starts
***************************
 */}

                            <Grid container>
                              <Grid xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      data-cy="Attribute-Details-header"
                                      variant="subtitle1"
                                      style={{ marginRight: 16 }}
                                    >
                                      Product attribute details
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="body1">
                                      Provide information on your product
                                      attribute
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                <Paper
                                  style={{ padding: 24, borderRadius: 15 }}
                                  elevation={0}
                                >
                                  <Grid
                                    container
                                    spacing={1}
                                    className={classes.container}
                                  >
                                    <Grid item xs={12}>
                                      <Input
                                        dataCy="Attribute-Style-Number"
                                        label="Internal ID"
                                        placeholder="Enter your intern ID"
                                        value={productAttribute.no}
                                        onChange={e =>
                                          updateProductAttribute({
                                            optimisticResponse: {
                                              __typename: 'Mutation',
                                              updateProductAttribute: {
                                                ...productAttribute,
                                                no: e.target.value
                                              }
                                            },
                                            variables: {
                                              id: productAttributeId,
                                              input: { no: e.target.value }
                                            }
                                          })
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Input
                                        dataCy="Attribute-Sort-Order"
                                        label="Sort order"
                                        placeholder="Enter your sort order"
                                        type="number"
                                        value={productAttribute.sortOrder}
                                        onChange={e =>
                                          updateProductAttribute({
                                            optimisticResponse: {
                                              __typename: 'Mutation',
                                              updateProductAttribute: {
                                                ...productAttribute,
                                                sortOrder: e.target.value
                                                  ? parseInt(e.target.value)
                                                  : 0
                                              }
                                            },
                                            variables: {
                                              id: productAttributeId,
                                              input: {
                                                sortOrder: e.target.value
                                                  ? parseInt(e.target.value)
                                                  : 0
                                              }
                                            }
                                          })
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Checkbox
                                        data-cy="Attribute-Is-Feature-Checkbox"
                                        type="checkbox"
                                        value={productAttribute.isFeatured}
                                        checked={
                                          productAttribute.isFeatured
                                            ? true
                                            : false
                                        }
                                        onChange={e =>
                                          updateProductAttribute({
                                            optimisticResponse: {
                                              __typename: 'Mutation',
                                              updateProductAttribute: {
                                                ...productAttribute,
                                                isFeatured: e.target.checked
                                              }
                                            },
                                            variables: {
                                              id: productAttributeId,
                                              input: {
                                                isFeatured: e.target.checked
                                              }
                                            }
                                          })
                                        }
                                      />
                                      Is featured?
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Checkbox
                                        data-cy="Attribute-Feature-Title-Checkbox"
                                        type="checkbox"
                                        value={
                                          productAttribute.isFeaturedAttributeTitle
                                        }
                                        checked={
                                          productAttribute.isFeaturedAttributeTitle
                                            ? true
                                            : false
                                        }
                                        onChange={e =>
                                          updateProductAttribute({
                                            optimisticResponse: {
                                              __typename: 'Mutation',
                                              updateProductAttribute: {
                                                ...productAttribute,
                                                isFeaturedAttributeTitle:
                                                  e.target.checked
                                              }
                                            },
                                            variables: {
                                              id: productAttributeId,
                                              input: {
                                                isFeaturedAttributeTitle:
                                                  e.target.checked
                                              }
                                            }
                                          })
                                        }
                                      />
                                      Feature attribute title?{' '}
                                      <span
                                        style={{
                                          fontSize: '80%'
                                        }}
                                      >
                                        The attribute title will be featured
                                        below the product title on product cards
                                        and in the cart list
                                      </span>
                                    </Grid>
                                  </Grid>
                                  <ProductAttributeTitle
                                    defaultLanguage={defaultLanguage}
                                    languages={languages}
                                    classes={classes}
                                    productAttribute={productAttribute}
                                    productAttributeId={productAttributeId}
                                    updateProductAttribute={
                                      updateProductAttribute
                                    }
                                  />
                                  <ProductAttributeDescription
                                    languages={languages}
                                    defaultLanguage={defaultLanguage}
                                    classes={classes}
                                    productAttribute={productAttribute}
                                    productAttributeId={productAttributeId}
                                    updateProductAttribute={
                                      updateProductAttribute
                                    }
                                  />
                                  {shopId === 'vermarc' ? (
                                    <ProductAttributeIcon
                                      classes={classes}
                                      productAttribute={productAttribute}
                                      productAttributeId={productAttributeId}
                                      updateProductAttribute={
                                        updateProductAttribute
                                      }
                                    />
                                  ) : null}
                                  <ProductAttributeMedia
                                    classes={classes}
                                    productAttribute={productAttribute}
                                    productAttributeId={productAttributeId}
                                    updateProductAttribute={
                                      updateProductAttribute
                                    }
                                  />
                                </Paper>
                              </Grid>
                            </Grid>
                          </div>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Query>
            </Grid>
          </Grid>
        </Typography>
      </div>
    );
  }
}

ProductAttributeDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductAttributeDetail);
