import React from 'react'
import {
  Grid,
  Button,
  WithStyles,
  Table,
  TableCell,
  TableRow,
  withStyles,
  createStyles
} from '@material-ui/core'

import { convertTS } from '../../../../helper/time'
import { navigate } from '@reach/router'

const styles = () =>
  createStyles({
    cell: {}
  })

interface Props extends WithStyles<typeof styles> {
  data: any[]
  shopId: string
}

const LeadList: React.FunctionComponent<Props> = props => {
  const { classes, data } = props
  return (
    <Grid container={true}>
      <Table>
        <TableRow>
          <TableCell>Created at</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
        {data
          ? data.map((item: any) => (
              <TableRow
                onClick={() => {
                  navigate(window.location.href + '/' + item.node.id)
                }}
                key={item.node.id}
                style={{ textDecoration: 'none', cursor: 'pointer' }}
              >
                <TableCell className={classes.cell} component="th" scope="row">
                  {convertTS(item.node.createdAt)}
                </TableCell>
                <TableCell className={classes.cell} component="th" scope="row">
                  {item.node.firstName} {item.node.lastName}
                </TableCell>
                <TableCell className={classes.cell}>
                  {item.node.status}
                </TableCell>
              </TableRow>
            ))
          : null}
      </Table>
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(LeadList)
