import React, { useState } from 'react'
import { Rnd } from 'react-rnd'
import { UI, tabs } from '../Elements/elements'
import RenderControl from './RenderControl'
import {
  ClickAwayListener,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from '@material-ui/core'
import UpdatePanelClose from './UpdatePanelClose'

const style = {
  border: 'solid 1px lightgrey',
  background: '#fff',
  borderRadius: 2,
  zIndex: 100,
  boxShadow: '0 10px 20px rgba(0,0,0,0.15), 0 6px 6px rgba(0,0,0,0.19)',
  overflow: 'auto',
}

interface Props {
  elementType: string
  element: any
  modelId: string | null
  hasSpace: boolean
  setNotify: any
  handleUpdate: any
  handleShowUpdatePanel: any
  handleTextUpdate: any
  handleUpdateMapsContent: any
}

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  )
}

const UpdateContentPanel: React.FC<Props> = props => {
  const {
    handleShowUpdatePanel,
    handleUpdate,
    elementType,
    element,
    modelId,
    handleTextUpdate,
    handleUpdateMapsContent,
    hasSpace,
    setNotify,
  } = props

  const [state, setState] = useState(0)
  const [position, setPosition] = useState<any>({
    x: 0,
    y: hasSpace ? 0 : 0 - 450,
    width: 650,
    height: 450,
  })

  const handleChangeIndex = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setState(newValue)
  }

  const getElementData = (element: any, elementType: string, key: string) => {
    if (UI[elementType][key].level === 'root') {
      return element[key]
    } else if (UI[elementType][key].level === 'style') {
      return element.contentStyle[0][key]
    }
  }

  return (
    <ClickAwayListener onClickAway={handleShowUpdatePanel}>
      <Rnd
        style={style}
        bounds="window"
        disableDragging={true}
        enableResizing={{
          bottom: false,
          right: false,
          left: false,
          top: false,
          bottomLeft: false,
          bottomRight: false,
          topLeft: false,
          topRight: false,
        }}
        size={{
          width: position.width,
          height: position.height,
        }}
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y })
        }}
      >
        <AppBar
          style={{
            backgroundColor: 'white',
            height: '60px',
            boxShadow: '0 10px 20px rgba(0,0,0,0.08)',
          }}
          position="sticky"
        >
          <Tabs
            value={state}
            onChange={handleChangeIndex}
            variant="fullWidth"
            indicatorColor="primary"
            TabIndicatorProps={{
              color: 'transparent',
            }}
            style={{ position: 'relative' }}
          >
            {Object.keys(tabs[elementType].items).map((tab: any, i: number) => {
              return (
                <Tab
                  style={{ height: '60px' }}
                  label={tabs[elementType].items[i].label}
                />
              )
            })}
          </Tabs>
        </AppBar>

        {Object.keys(tabs[elementType].items).map((index: any, i: number) => (
          <TabPanel value={state} index={i}>
            {tabs[elementType].items[i].fields.map((e: any) => {
              let styleId =
                element && element.contentStyle
                  ? element?.contentStyle[0]?.id
                  : null
              return (
                <>
                  <RenderControl
                    value={getElementData(element, elementType, e)}
                    ui={UI[elementType][e]}
                    fieldName={e}
                    elementType={elementType}
                    modelId={modelId}
                    handleUpdate={handleUpdate}
                    contentId={element.id}
                    styleId={styleId}
                    handleTextUpdate={handleTextUpdate}
                    handleUpdateMapsContent={handleUpdateMapsContent}
                  />
                </>
              )
            })}
          </TabPanel>
        ))}
        <AppBar
          position="sticky"
          style={{
            bottom: 0,
            backgroundColor: 'transparent',
            padding: '10px 20px',
          }}
        >
          <UpdatePanelClose
            setNotify={setNotify}
            handleShowUpdatePanel={handleShowUpdatePanel}
          />
        </AppBar>
      </Rnd>
    </ClickAwayListener>
  )
}

export default UpdateContentPanel
