import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { ADMIN_UPDATE_SHOP_CONTENT_SETTINGS } from '../../../admin/gql/admin-mutations';
import { Mutation } from 'react-apollo';
const uuidv4 = require('uuid/v4');
const omitDeep = require('omit-deep');

const CookieNote = ({ classes, cookieNote, languages, shop, shopId }) => {
  const titleId = cookieNote.id ? cookieNote.id : uuidv4();
  return (
    <Mutation
      mutation={ADMIN_UPDATE_SHOP_CONTENT_SETTINGS}
      variables={{ id: shopId }}
    >
      {updateShopContentSettings => (
        <Grid container style={{ padding: 12 }}>
          <Typography variant="subtitle1">Cookie Note</Typography>
          {languages.map((language, index) => {
            var cookieNoteText = '';
            try {
              cookieNoteText = getTextFromLanguage(cookieNote.text, language);
            } catch (error) {}
            return (
              <Grid
                container
                key={'style-cookieNoteText-' + index}
                direction="row"
                alignItems="flex-end"
                spacing={1}
              >
                <Grid item xs={10}>
                  <TextField
                    placeholder="Enter your Cookie note"
                    multiline={true}
                    rowsMax="4"
                    value={cookieNoteText}
                    onChange={e => {
                      updateShopContentSettings({
                        // optimisticResponse: {
                        //   __typename: 'Mutation',
                        //   updateShopContentSettings: {
                        //     ...shop,
                        //     contentSettings: {
                        //         ...shop.contentSettings,
                        //         cookieNote: {
                        //             id: titleId,
                        //             text: [
                        //               ...languages.map((tempLanguage, index) => {
                        //                 return {
                        //                   langCode: tempLanguage,
                        //                   content:
                        //                     tempLanguage === language
                        //                       ? e.target.value
                        //                       : getTextFromLanguage(
                        //                           cookieNote.text,
                        //                           tempLanguage
                        //                         ),
                        //                   __typename: 'TextContent'
                        //                 };
                        //               })
                        //             ],
                        //             __typename: 'Text'
                        //           }
                        //     }

                        //   }
                        // },
                        variables: {
                          input: {
                            contentSettings: {
                              cookieNote: {
                                id: titleId,
                                text: [
                                  ...languages.map((tempLanguage, index) => {
                                    return {
                                      langCode: tempLanguage,
                                      content:
                                        tempLanguage === language
                                          ? e.target.value
                                          : getTextFromLanguage(
                                              cookieNote.text,
                                              tempLanguage
                                            )
                                    };
                                  })
                                ]
                              }
                            }
                          }
                        }
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    style={{
                      height: 38,
                      width: '100%',
                      border: '1px solid #ddd',
                      borderRadius: '3px'
                    }}
                  >
                    <Typography variant="body1">{language}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Mutation>
  );
};

export default CookieNote;
