import { makeStyles } from '@material-ui/core'
import React, { Component, useState } from 'react'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Localization } from '../../../lang/localization'

const style = {
  menu: {
    paddingTop: 14,
    width: 80,
    padding: '0px 10px',
    boxShadow: 'none',
    background: '#FFFFFF !important',
    height: '100vh',
    borderLeft: '1px solid #E5E5E5',
    position: 'absolute',
    right: 0,
    zIndex: 0
  },
  tabsIndicator: {
    background: 'transparent'
  },
  tabs: {
    margin: 4,
    minWidth: 50,
    minHeight: 50,
    width: 50,
    height: 50,
    maxWidth: 50,
    maxHeight: 50,
    padding: 0,
    background: '#FFFFFF',
    color: '#000000',
    borderRadius: '100%',
    border: '0px',
    fontSize: '12px',
    '&:hover': {
      color: '#FFFFFF',
      // background: primary.toLowerCase() === '#ffffff' ? '#999999' : primary,
      border: '2px solid #f2f2f2',
      background: '#f2f2f2'
    },
    '&$tabSelected': {
      color: '#FFFFFF',
      // background: primary.toLowerCase() === '#ffffff' ? '#999999' : primary,
      border: '2px solid #f2f2f2',
      background: '#f2f2f2'
    },
    '&:focus': {
      color: '#FFFFFF',
      // background: primary.toLowerCase() === '#ffffff' ? '#999999' : primary,
      border: '2px solid #f2f2f2',
      background: '#f2f2f2'
    }
  },
  tabSelected: {
    color: '#FFFFFF',
    // background: primary.toLowerCase() === '#ffffff' ? '#999999' : primary,
    border: '2px solid #f2f2f2',
    background: '#f2f2f2'
  },
  tab: {
    padding: '0px !important'
  },
  tabsShow: {
    display: 'block'
  },
  tabsHide: {
    display: 'none'
  },
  tabsRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: 60,
    background: 'transparent',
    height: 'calc(100vh - 200px)'
  },
  rootTab: {
    background: 'transparent',
    height: 'calc(100vh - 200px)'
  },
  cartButton: {
    minWidth: 50,
    width: 50,
    height: 50,
    minWidth: 50,
    padding: 4,
    borderRadius: '100%',
    marginBottom: 16
  }
}
const styles = theme => style
const useStyles = makeStyles(style)

export const RequestQuotation = ({
  open,
  close,
  createLead,
  ownedByOrganisationId,
  salesChannelId,
  productDesignId,
  langCode,
  shopId
}) => {
  logger.log(ownedByOrganisationId, salesChannelId, productDesignId)
  const classes = useStyles()
  const [lead, setLeadValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phone: '',
    team: '',
    type_of_products: [],
    type_of_clothing: [],
    quality_line: '',
    quantity: '',
    comments: '',
    sending: false
  })
  const updateField = e => {
    setLeadValues({
      ...lead,
      [e.target.name]: e.target.value
    })
  }
  return (
    <>
      <div style={{ padding: 24 }}>
        <DialogContent>
          <DialogContentText style={{ color: '#000' }}>
            <Typography variant={'h3'} component="h2">
              {Localization.get('beCreative_request_quote_design_application')}
            </Typography>
            <>
              <TextField
                required
                inputProps={{
                  'data-testid': 'requestquotation-firstname'
                }}
                id="rq-firstName"
                label={Localization.get('first_name')}
                defaultValue={lead.firstName}
                margin="normal"
                name="firstName"
                onChange={updateField}
              />

              <TextField
                required
                inputProps={{
                  'data-testid': 'requestquotation-lastname'
                }}
                label={Localization.get('last_name')}
                defaultValue={lead.lastName}
                name="lastName"
                margin="normal"
                onChange={updateField}
              />
              <TextField
                required
                label={Localization.get('email')}
                defaultValue={lead.email}
                name="email"
                margin="normal"
                onChange={updateField}
              />
              <TextField
                required
                label={Localization.get('repeat_email')}
                defaultValue={lead.confirmEmail}
                name="confirmEmail"
                margin="normal"
                onChange={updateField}
              />
              <TextField
                required
                label={Localization.get('phone')}
                defaultValue={lead.phone}
                name="phone"
                margin="normal"
                onChange={updateField}
              />

              {shopId === 'vermarc' ? (
                <>
                  <TextField
                    required
                    label={Localization.get(
                      'beCreative_request_quote_address_club'
                    )}
                    defaultValue={lead.team}
                    name="team"
                    margin="normal"
                    onChange={updateField}
                  />
                  <FormControl fullWidth component="div">
                    <FormLabel component="div">
                      {Localization.get(
                        'beCreative_request_quote_type_of_products'
                      )}
                      :*
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_products.includes(
                              'Athletics'
                            )}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_products']: e.target.checked
                                  ? [...lead.type_of_products, 'Athletics']
                                  : lead.type_of_products.filter(
                                      x => x !== 'Athletics'
                                    )
                              })
                            }}
                            value="Athletics"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_athletics'
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_products.includes('Leisure')}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_products']: e.target.checked
                                  ? [...lead.type_of_products, 'Leisure']
                                  : lead.type_of_products.filter(
                                      x => x !== 'Leisure'
                                    )
                              })
                            }}
                            value="Leisure"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_leisure'
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_products.includes('Football')}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_products']: e.target.checked
                                  ? [...lead.type_of_products, 'Football']
                                  : lead.type_of_products.filter(
                                      x => x !== 'Football'
                                    )
                              })
                            }}
                            value="Football"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_football'
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_products.includes(
                              'Triathlon'
                            )}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_products']: e.target.checked
                                  ? [...lead.type_of_products, 'Triathlon']
                                  : lead.type_of_products.filter(
                                      x => x !== 'Triathlon'
                                    )
                              })
                            }}
                            value="Triathlon"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_triathlon'
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_products.includes('Cycling')}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_products']: e.target.checked
                                  ? [...lead.type_of_products, 'Cycling']
                                  : lead.type_of_products.filter(
                                      x => x !== 'Cycling'
                                    )
                              })
                            }}
                            value="Cycling"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_cycling'
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl component="div">
                    <FormLabel component="div">
                      {Localization.get(
                        'beCreative_request_quote_type_of_clothing'
                      )}
                      :
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_clothing.includes(
                              'Jersey short sleeve'
                            )}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_clothing']: e.target.checked
                                  ? [
                                      ...lead.type_of_clothing,
                                      'Jersey short sleeve'
                                    ]
                                  : lead.type_of_clothing.filter(
                                      x => x !== 'Jersey short sleeve'
                                    )
                              })
                            }}
                            value="Jersey short sleeve"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_jersey_short_sleeve'
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_clothing.includes(
                              'Jersey long sleeve'
                            )}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_clothing']: e.target.checked
                                  ? [
                                      ...lead.type_of_clothing,
                                      'Jersey long sleeve'
                                    ]
                                  : lead.type_of_clothing.filter(
                                      x => x !== 'Jersey long sleeve'
                                    )
                              })
                            }}
                            value="Jersey long sleeve"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_jersey_long_sleeve'
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_clothing.includes(
                              'Mid season jacket'
                            )}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_clothing']: e.target.checked
                                  ? [
                                      ...lead.type_of_clothing,
                                      'Mid season jacket'
                                    ]
                                  : lead.type_of_clothing.filter(
                                      x => x !== 'Mid season jacket'
                                    )
                              })
                            }}
                            value="Mid season jacket"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_mid_season_jacket'
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_clothing.includes(
                              'Technical jacket'
                            )}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_clothing']: e.target.checked
                                  ? [
                                      ...lead.type_of_clothing,
                                      'Technical jacket'
                                    ]
                                  : lead.type_of_clothing.filter(
                                      x => x !== 'Technical jacket'
                                    )
                              })
                            }}
                            value="Technical jacket"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_technical_jacket'
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_clothing.includes(
                              'Windstopper / Kaos'
                            )}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_clothing']: e.target.checked
                                  ? [
                                      ...lead.type_of_clothing,
                                      'Windstopper / Kaos'
                                    ]
                                  : lead.type_of_clothing.filter(
                                      x => x !== 'Windstopper / Kaos'
                                    )
                              })
                            }}
                            value="Windstopper / Kaos"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_windstopper'
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_clothing.includes(
                              'Rain jacket'
                            )}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_clothing']: e.target.checked
                                  ? [...lead.type_of_clothing, 'Rain jacket']
                                  : lead.type_of_clothing.filter(
                                      x => x !== 'Rain jacket'
                                    )
                              })
                            }}
                            value="Rain jacket"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_rain_jacket'
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_clothing.includes(
                              'Bib shorts'
                            )}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_clothing']: e.target.checked
                                  ? [...lead.type_of_clothing, 'Bib shorts']
                                  : lead.type_of_clothing.filter(
                                      x => x !== 'Bib shorts'
                                    )
                              })
                            }}
                            value="Bib shorts"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_bib_shorts'
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lead.type_of_clothing.includes('Tights')}
                            onChange={e => {
                              setLeadValues({
                                ...lead,
                                ['type_of_clothing']: e.target.checked
                                  ? [...lead.type_of_clothing, 'Tights']
                                  : lead.type_of_clothing.filter(
                                      x => x !== 'Tights'
                                    )
                              })
                            }}
                            value="Tights"
                          />
                        }
                        label={Localization.get(
                          'beCreative_request_quote_tights'
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="rp-quality-line">
                      {Localization.get(
                        'beCreative_request_quote_quality_line'
                      )}
                    </InputLabel>
                    <Select
                      name="quality_line"
                      labelId="quality_linel"
                      id="quality_line"
                      value={lead.quality_line}
                      onChange={updateField}
                    >
                      <MenuItem value={'PR.R'}>PR.R</MenuItem>
                      <MenuItem value={'SP.L'}>SP.L</MenuItem>
                      <MenuItem value={'ES.L'}>ES.L</MenuItem>
                      <MenuItem value={"Don't know yet"}>
                        {Localization.get(
                          'beCreative_request_quote_dont_know_yet'
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </>
              ) : null}

              <FormControl fullWidth>
                <InputLabel htmlFor="rp-qty">
                  {Localization.get('quantity')}*
                </InputLabel>
                <Input
                  inputProps={{
                    min: 1
                  }}
                  required
                  id="rp-qty"
                  type="number"
                  name="quantity"
                  value={lead.quantity}
                  onChange={updateField}
                />
              </FormControl>
              <TextField
                id="rq-comments"
                label={Localization.get(
                  'beCreative_request_quote_comments_questions'
                )}
                multiline
                rows="4"
                defaultValue={lead.comments}
                margin="normal"
                variant="outlined"
                name="comments"
                onChange={updateField}
              />
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            data-testid="requestquotation-btn"
            disabled={lead.sending ? true : false}
            onClick={async () => {
              const emailValid = lead.email.match(
                /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
              )
              if (
                (shopId !== 'vermarc' &&
                  lead.firstName !== '' &&
                  lead.lastName !== '' &&
                  lead.email !== '' &&
                  emailValid &&
                  lead.confirmEmail !== '' &&
                  lead.email === lead.confirmEmail &&
                  lead.phone !== '' &&
                  lead.quantity !== '' &&
                  lead.quantity > 0) ||
                (shopId === 'vermarc' &&
                  lead.firstName !== '' &&
                  lead.lastName !== '' &&
                  lead.email !== '' &&
                  emailValid &&
                  lead.confirmEmail !== '' &&
                  lead.email === lead.confirmEmail &&
                  lead.phone !== '' &&
                  lead.team !== '' &&
                  lead.type_of_products.length > 0 &&
                  lead.quantity !== '' &&
                  lead.quantity > 0)
              ) {
                try {
                  setLeadValues({
                    ...lead,
                    sending: true
                  })
                  await createLead({
                    variables: {
                      input: {
                        firstName: lead.firstName,
                        lastName: lead.lastName,
                        email: lead.email,
                        phone: lead.phone,
                        langCode: langCode,
                        ownedByOrganisationId: ownedByOrganisationId,
                        salesChannelId: salesChannelId || 'MYBRIKL',
                        status: 'CREATED',
                        productDesignIds: productDesignId
                          ? [productDesignId]
                          : [],

                        tempKeyValues: [
                          {
                            key: 'team',
                            value: lead.team
                          },
                          {
                            key: 'type_of_products',
                            value: lead.type_of_products.join(',')
                          },
                          {
                            key: 'type_of_clothing',
                            value: lead.type_of_clothing.join(',')
                          },
                          {
                            key: 'quality_line',
                            value: lead.quality_line
                          },
                          {
                            key: 'quantity',
                            value: lead.quantity !== '' ? lead.quantity : null
                          },
                          {
                            key: 'comments',
                            value: lead.comments !== '' ? lead.comments : null
                          }
                        ]
                      }
                    }
                  })
                  alert(Localization.get('sent'))
                  setLeadValues({
                    ...lead,
                    sending: false
                  })
                } catch (error) {
                  logger.error(error)
                  alert('Failed to send')
                  setLeadValues({
                    ...lead,
                    sending: false
                  })
                }
              } else {
                alert(Localization.get('please_fill_all_fields'))
              }
            }}
          >
            {lead.sending ? '...' : Localization.get('send')}
          </Button>
        </DialogActions>
      </div>
    </>
  )
}
