import React from 'react'
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
  IconButton,
  InputBase
} from '@material-ui/core'
import { theme } from '../../common/Theme'
import { navigate } from 'gatsby'
import SearchIcon from '@material-ui/icons/Search'
import { SHOP_ID } from '../../../../brikl-config'

const styles = (themeStyle: Theme) =>
  createStyles({
    desktop: {
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    iconButton: {
      padding: 10
    }
  })

interface Props extends WithStyles<typeof styles> {
  urlPrefix?: string
  linkColor?: string
  styling?: string
}

const SearchInput: React.FunctionComponent<Props> = props => {
  const { classes, urlPrefix, linkColor, styling } = props

  const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      borderRadius: 0,
      position: 'relative',
      backgroundColor: 'transparent',
      border: 'none',
      color: linkColor ? linkColor : '#000',
      fontSize: styling && styling.fontSize ? styling.fontSize : 12,
      textTransform: 'uppercase',
      fontWeight: 400,
      fontFamily: styling && styling.family ? styling.family : null,
      width: 'auto',
      padding: '5px 12px 5px 8px',
      height: 20,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily:
        SHOP_ID === 'vermarc'
          ? 'BiomeStd-Light, sans-serif'
          : SHOP_ID === 'bodywearlab'
          ? 'Work Sans, sans-serif'
          : SHOP_ID === 'OBS'
          ? 'news-gothic-std'
          : ['Roboto', '"Helvetica Neue"', 'Arial', 'Jaldi']
    }
  }))(InputBase)

  return (
    <div
      className={classes.desktop}
      style={{
        marginTop: '0px',
        display: 'flex',
        borderBottom: linkColor ? `1px solid ${linkColor}` : '#e5e5e5',
        alignItems: 'center',
        paddingRight: 8,
        marginLeft: 8,
        marginRight: 8
      }}
    >
      <BootstrapInput
        onKeyPress={e => {
          if (e.keyCode === 13 || e.key === 'Enter') {
            navigate(
              `/${urlPrefix}search/?q=${e.target.value ? e.target.value : ''}`
            )
          }
        }}
        placeholder="Search"
      />
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        style={{ padding: 3, marginBottom: 2, color: linkColor }}
      >
        <SearchIcon />
      </IconButton>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(SearchInput)
