import React from 'react'
import AdminMain from '../components/admin/AdminMain'

const AdminRoot = () => {
  return process.env.GATSBY_BRIKL_IS_DASHBOARD === 'yes' ? (
    <AdminMain />
  ) : (
    <div>Empty</div>
  )
}

export default AdminRoot
