import React, { useRef } from 'react'
import moment from 'moment'
import ColorZones from './ColorZones'
import Elements from './Elements'
import Logo from './Logo'
import Texts from './Texts'
import BoardProductPreviews from '../../../common/BoardProductPreviews'
import { useQuery } from '@apollo/react-hooks'
import { ELEMENTS } from '../../../../gql/queries'
import ReactToPrint from 'react-to-print'
import Button from '@material-ui/core/Button'

interface Props {
  order: any
  orderId: string
  orderType: string
  address: any
  title: string
  item: any
}

const Techpack: React.FunctionComponent<Props> = props => {
  const { order, orderId, orderType, address, title, item } = props

  const componentRef = useRef()
  const { data, loading } = useQuery(ELEMENTS, {
    variables: {
      id: item.productDesignId,
      ownedByOrganisationId: order.ownedByOrganisationId
    }
  })
  if (loading) return null

  const elements = data
  let designTemplate
  try {
    designTemplate = elements.getProductDesign.product.designTemplates.find(
      (c: any) =>
        c.id ===
        elements.getProductDesign.latestRevision.selectedDesignTemplateId
    )
  } catch (error) {
    console.log('designTemplate.error', error)
  }

  return (
    <>
      <ReactToPrint
        trigger={() => <button>Print order details</button>}
        content={() => componentRef.current}
      />
      <div ref={componentRef}>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @media print {
            @page { margin: 0; }
            body { margin: 1.6cm; }
            button { display: none !important }
            .pb-after { page-break-after: always; }
          }
          div{
            font-family: open sans
          }
          table {
            border-spacing: 0px;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid #E5E5E5;
            padding: 8px;
          }
          .header{
            width: 100%;
            padding-bottom: 24px;
          }
          .section{
            width: 100%;
            margin-top: 24px
          }
          .paper{
            background: #ffffff;
            padding: 24px;
            width: 100%;
          }
          .col-left{
            width: 50%;
            float: left;
          }
          .col-right{
            width: 50%;
            float: right;
            text-align: right;
          }
          .table{
            width: 100%
          }
          .viewsContainer{
            max-width: 100%;
            width: 100%;
            height: auto;
            border: 1px solid #e5e5e5;
          }
          .image{
            width: 100%;
            height: '100%',
            object-fit: contain;
            transform: rotate(90deg);
            background: #eee;
          }
        `
          }}
        />

        <div className="paper">
          <div className="header">
            <div className="col-left">
              <h4 data-testid="order-id">Order ID: {orderId}</h4>
            </div>
            <div className="col-right">
              <h4>Order Type: {orderType} </h4>
            </div>
          </div>

          <div className="section orderID">
            <table className="table">
              <thead>
                <tr style={{ fontWeight: 'bold' }}>
                  <th>Date issued</th>
                  <th>Customer</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <td>
                    {moment
                      .unix(order.submittedAt / 1000)
                      .format('DD MMM YYYY HH:mm:ss')}
                  </td>
                  <td>N/A</td>
                </tr>
                <tr style={{ fontWeight: 'bold' }}>
                  <th colSpan={4}>Address</th>
                </tr>
                <tr style={{ textAlign: 'center' }}>
                  <td colSpan={4} data-testid="address-text">
                    {address?.firstName} {address?.lastName}
                    <br />
                    {address?.company} {address?.vatNumber}
                    <br />
                    {address?.address1}
                    <br />
                    {address?.zip} {address?.city} {address?.country}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="section garmentDetails">
            <h2>Garment Details</h2>
            <table className="table">
              <thead>
                <tr style={{ fontWeight: 'bold' }}>
                  <th>Style</th>
                  <th>Style Number</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <td>{item.product.id}</td>
                  <td>{title}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* data for customization options */}

          {/* {item.customizationOptions.map(options => (
          <span>{options.value}</span>
        ))} */}

          {/* <div className="section billOfMaterial">
          <h2>List of Materials</h2>
          <table className="table">
            <tr>
              <th>Element</th>
              <th>Fill</th>
              <th>Fabric Name</th>
              <th>Fabric Content</th>
              <th>Weight</th>
            </tr>
            <tr style={{ textAlign: 'center' }}>
              <td>TBD</td>
              <td>TBD</td>
              <td>TBD</td>
              <td>TBD</td>
              <td>TBD</td>
            </tr>
          </table>
        </div> */}

          <div className="section elements">
            <Elements
              designTemplate={designTemplate}
              latestRevisionData={elements.getProductDesign.latestRevision}
            />
          </div>

          <div className="section colorZones">
            <ColorZones
              designTemplate={designTemplate}
              latestRevisionData={elements.getProductDesign.latestRevision}
            />
          </div>

          <div className="section logo">
            <Logo
              designTemplate={designTemplate}
              latestRevisionData={elements.getProductDesign.latestRevision}
            />
          </div>

          <div className="section text">
            <Texts
              designTemplate={designTemplate}
              latestRevisionData={elements.getProductDesign.latestRevision}
            />
          </div>

          <div className="section text">
            <h2>Variant/Quantity</h2>
            <table className="table">
              <thead>
                <tr style={{ fontWeight: 'bold' }}>
                  <th>Variant</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <td>{item.productOptionValueTitle}</td>
                  <td>{item.quantity}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* <div className="section visualDescription">
            <h2>Description</h2>
            <table className="table">
              <tr>
                <th>Comments</th>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <td>TBD</td>
              </tr>
            </table>
          </div> */}

          <div className="section visualDescription">
            <h2>Visual</h2>
            <div className="viewsContainer">
              <BoardProductPreviews
                shopId={item.shopId}
                ownedByOrganisationId={order.ownedByOrganisationId}
                boardProductId={item.productDesignId}
                techpack
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Techpack
