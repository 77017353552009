import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Delete, ExpandLess, ExpandMore } from '@material-ui/icons';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Input from '../../common/Input';
import Button from '@material-ui/core/Button';
import { primary } from '../../admin/AdminTheme';
import FillStep from './FillStep';
import TextStep from './TextStep';
import LogoStep from './LogoStep';

const styles = adminTheme => ({
  layerMenu: {
    width: 180,
    height: '100vh',
    float: 'left',
    background: '#ffffff',
    borderRight: '1px solid #E5E5E5'
  },
  linkContainer: {
    padding: 0,
    color: '#000'
  },
  linkText: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: 300,
    borderTop: '1px solid #E5E5E5',
    borderBottom: '1px solid #E5E5E5'
  },
  icon: {
    color: '#BBB',
    fontSize: '16px',
    '&:hover': {
      color: primary
    }
  },
  iconButton: {
    minWidth: 25,
    minHeight: 25,
    width: 25,
    height: 25,
    borderRadius: '100%',
    background: 'none',
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

class Layer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.layerMenu}>
        <Grid
          container
          style={{ padding: '24px 24px 16px 24px' }}
          justify="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1">Layer</Typography>
        </Grid>
        <List>
          <ListItem classes={{ root: classes.linkContainer }}>
            <Grid
              container
              style={{
                marginLeft: '0px'
              }}
              className={classes.linkText}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={7}>
                <Typography
                  variant="body1"
                  style={{ margin: '4px 0px 4px 4px' }}
                >
                  <Input
                    value="myImage1"
                    width="100%"
                    borderColor="transparent"
                    fontSize="12px"
                  />
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Button className={classes.iconButton}>
                  <Delete className={classes.icon} />
                </Button>
                <Button className={classes.iconButton}>
                  <ExpandMore className={classes.icon} />
                </Button>
                <Button className={classes.iconButton}>
                  <ExpandLess className={classes.icon} />
                </Button>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </div>
    );
  }
}

Layer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Layer);
