import React, { Component } from 'react'
import { Query } from 'react-apollo'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Paper from '@material-ui/core/Paper'

import Typography from '@material-ui/core/Typography'
import { ADMIN_SHOP_CUSTOMERS, ADMIN_GET_ORDER } from '../../gql/admin-queries'
import { navigate } from '@reach/router'
// import CustomerDetail from './CustomerDetail';
import moment from 'moment'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
const styles = theme => ({
  root: {
    overflowX: 'auto'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    marginLeft: 20,
    fontSize: 30,
    padding: 25,
    fontWeight: 'medium',
    color: '#424242'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
})

class Customers extends Component {
  state = {
    expanded: null,
    showOrders: true
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  render() {
    const { classes, customerId, shopId } = this.props
    return (
      <Grid
        container
        style={{ padding: '24px', margin: '0 auto', maxWidth: '1000px' }}
      >
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          {!customerId && (
            <Typography
              style={{
                fontSize: 28,
                fontWeight: 'medium',
                color: '#424242'
              }}
              data-cy="Header-Customers"
            >
              Customers
            </Typography>
          )}
        </Grid>
        {!customerId && (
          <Grid container>
            <Grid item xs={12}>
              <Grid container direction="column">
                <Paper className={classes.root}>
                  <Query
                    query={ADMIN_SHOP_CUSTOMERS}
                    variables={{ id: shopId }}
                  >
                    {({ loading, error, data }) => {
                      var shopCustomers = []
                      try {
                        shopCustomers = _sortBy(
                          data.shop.customers.edges,
                          'createdAt'
                        ).reverse()
                      } catch (error) {}
                      return (
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Emails</TableCell>
                              <TableCell>Signed up</TableCell>
                              <TableCell>Number of orders</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loading ? (
                              <TableRow key={'customer-loading'}>
                                <TableCell component="th" scope="row">
                                  {'Loading...'}
                                </TableCell>
                                <TableCell>{}</TableCell>
                              </TableRow>
                            ) : error ? (
                              'Error'
                            ) : (
                              shopCustomers.map(row => {
                                const customer = row.node
                                return (
                                  <TableRow key={customer.id}>
                                    {/* <TableCell
                                      onClick={
                                        () => null
                                        // navigate('/admin/customers/' + customer.id)
                                      }
                                      style={
                                        {
                                          // cursor: 'pointer'
                                        }
                                      }
                                      component="th"
                                      scope="row"
                                    >
                                      {customer.id}
                                    </TableCell> */}
                                    <TableCell>
                                      {customer.lastOrderEmail}
                                    </TableCell>
                                    <TableCell>
                                      {moment
                                        .unix(customer.createdAt / 1000)
                                        .format('DD MMM YYYY')}
                                    </TableCell>
                                    <TableCell>
                                      {customer.numberOfOrders}
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                            )}
                          </TableBody>
                        </Table>
                      )
                    }}
                  </Query>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* {customerId ? (
          <Grid container>
            <Grid item xs={12}>
              <Grid container direction="column">
                <Paper className={classes.root}>
                  <Query
                    query={ADMIN_GET_ORDER}
                    variables={{ id: customerId, currency: 'THB' }}
                  >
                    {({ loading, error, data: { order } }) =>
                      <CustomerDetail order={order} />
                    }
                  </Query>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        ) : null} */}
      </Grid>
    )
  }
}

export default withStyles(styles)(Customers)
