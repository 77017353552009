import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Delete from '@material-ui/icons/Delete';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import _cloneDeep from 'lodash/cloneDeep';
const uuidv4 = require('uuid/v4');
const omitDeep = require('omit-deep');

const ProductDetailVariantOptionModifier = ({
  classes,
  productPrice,
  productOptionValue,
  updateProductOptionValue,
  variantIds,
  optionValueIds
}) => {
  var finalPrice = productPrice.value;
  return (
    <Grid container>
      {productOptionValue.modifiers.map((modifier, modifierIndex) => {
        if (modifier.type === 'PRICE_DECREMENT') {
          finalPrice = finalPrice - modifier.priceChange.value;
        }
        if (modifier.type === 'PRICE_INCREMENT') {
          finalPrice = finalPrice + modifier.priceChange.value;
        }
        if (modifier.type === 'PRICE_OVERRIDE') {
          finalPrice = modifier.priceChange.value;
        }
        return (
          <Grid
            container
            key={'style-titleText-' + modifierIndex}
            direction="row"
            alignItems="flex-end"
            justify="space-between"
            spacing={1}
            className={classes.container}
          >
            <Grid item style={{ width: 100 }}>
              {/* <Select
                    onChange={e => {
                        updateProductOptionValue({
                            optimisticResponse: {
                                __typename: 'Mutation',
                                updateProductOptionValue: {
                                  ...productOptionValue,
                                  modifiers: productOptionValue.modifiers.map((tempModifier00, tempModifierIndex2) => {
                                          return {
                                            ...tempModifier00,
                                            type:  e.target.value
                                          };
                                        })

                                }
                              },
                            variables: {
                                input: {
                                    id: productOptionValue.id,
                                    modifiers: _cloneDeep(productOptionValue.modifiers).map((tempModifier0, tempModifierIndex0) => {
                                            return omitDeep({
                                                ...tempModifier0,
                                                type:  e.target.value
                                            }, ['__typename'])
                                        })
                                }
                                }
                            })
                    }}
                    value={modifier.type}
                    label="Modify"
                >
                    <option value={''}>Choose</option>
                    <option value={'PRICE_DECREMENT'}>Decrease base price</option>
                    <option value={'PRICE_INCREMENT'}>Increase base price</option>
                    <option value={'PRICE_OVERRIDE'}>Override base price</option>
                </Select>                 */}
              <TextField
                // label="Price"
                placeholder="0"
                value={modifier.priceChange ? modifier.priceChange.value : 0}
                onChange={e => {
                  updateProductOptionValue({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      updateProductOptionValue: {
                        ...productOptionValue,
                        variantIds: variantIds,
                        optionValueIds: optionValueIds,
                        modifiers: productOptionValue.modifiers.map(
                          (tempModifier2, tempModifierIndex2) => {
                            return {
                              ...tempModifier2,
                              priceChange: {
                                __typename: 'Price',
                                currency: productPrice.currency,
                                value:
                                  modifierIndex === tempModifierIndex2
                                    ? e.target.value
                                      ? parseFloat(e.target.value)
                                      : 0
                                    : tempModifier2.priceChange.value
                              }
                            };
                          }
                        )
                      }
                    },
                    variables: {
                      input: {
                        id: productOptionValue.id,
                        variantIds: variantIds,
                        optionValueIds: optionValueIds,
                        modifiers: _cloneDeep(productOptionValue.modifiers).map(
                          (tempModifier2, tempModifierIndex2) => {
                            return omitDeep(
                              {
                                ...tempModifier2,
                                priceChange: {
                                  currency: productPrice.currency,
                                  value:
                                    modifierIndex === tempModifierIndex2
                                      ? e.target.value
                                        ? parseFloat(e.target.value)
                                        : 0
                                      : tempModifier2.priceChange.value
                                }
                              },
                              ['__typename']
                            );
                          }
                        )
                      }
                    }
                  });
                }}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  updateProductOptionValue({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      updateProductOptionValue: {
                        ...productOptionValue,
                        variantIds: variantIds,
                        optionValueIds: optionValueIds,
                        modifiers: null
                      }
                    },
                    variables: {
                      input: {
                        id: productOptionValue.id,
                        variantIds: variantIds,
                        optionValueIds: optionValueIds,
                        modifiers: null
                      }
                    }
                  });
                }}
                style={{ minWidth: 30, minHeight: 30 }}
              >
                <Delete />
              </Button>
            </Grid>
          </Grid>
        );
      })}
      {/* = {finalPrice} {productPrice.currency} */}
    </Grid>
  );
};

export default ProductDetailVariantOptionModifier;
