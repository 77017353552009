import React from 'react'
import { Grid, InputLabel } from '@material-ui/core'
import SliderControl from './Inputs/SliderControl'
import SelectControl from './Inputs/SelectControl'
import UploaderControl from './Inputs/UploaderControl'
import TextFieldControl from './Inputs/TextFieldControl'
import ListSelect from './Inputs/ListSelect'
import ColorPicker from './Inputs/ColorPicker'
import HelperTooltip from './Inputs/HelperTooltip'
import AlignControl from './Inputs/AlignControl'
import MultilangTextField from './Inputs/MultilangTextField'
import UrlInput from './Inputs/UrlInput'
import MapsInput from './Inputs/MapsInput'

interface Props {
  ui: any
  value: any
  modelId: string | null
  fieldName: string
  handleUpdate: any
  contentId: string
  styleId: string
  elementType: string
  handleTextUpdate: any
  handleUpdateMapsContent: any
}

const RenderControl: React.FC<Props> = props => {
  const {
    elementType,
    ui,
    value,
    fieldName,
    handleUpdate,
    styleId,
    contentId,
    handleTextUpdate,
    handleUpdateMapsContent
  } = props

  return (
    <Grid
      style={{ padding: 20, zIndex: 100 }}
      container={true}
      direction="column"
    >
      <Grid container={true} direction="row">
        <InputLabel>{`${ui.label} ${
          ui.suffix ? `(${ui.suffix})` : ''
        }`}</InputLabel>
        {ui.helperText ? <HelperTooltip helperText={ui.helperText} /> : null}
      </Grid>
      {ui.UIType === 'slider' ? (
        <SliderControl
          level={ui.level}
          defaultValue={value}
          fieldName={fieldName}
          styleId={styleId}
          contentId={contentId}
          handleUpdate={handleUpdate}
          range={ui.range ? ui.range : []}
          suffix={ui.suffix}
        />
      ) : ui.UIType === 'select' ? (
        <SelectControl
          defaultValue={value}
          level={ui.level}
          fieldName={fieldName}
          handleUpdate={handleUpdate}
          styleId={styleId}
          contentId={contentId}
          options={ui.options ? ui.options : []}
        />
      ) : ui.UIType === 'upload' ? (
        <UploaderControl
          level={ui.level}
          fieldName={fieldName}
          handleUpdate={handleUpdate}
          image={value}
          styleId={styleId}
          contentId={contentId}
        />
      ) : ui.UIType === 'input' ? (
        <TextFieldControl
          level={ui.level}
          defaultValue={value}
          fieldName={fieldName}
          handleUpdate={handleUpdate}
          styleId={styleId}
          contentId={contentId}
        />
      ) : ui.UIType === 'multi-select' ? (
        <ListSelect
          level={ui.level}
          defaultValue={value}
          fieldName={fieldName}
          styleId={styleId}
          contentId={contentId}
          handleUpdate={handleUpdate}
          type={elementType}
        />
      ) : ui.UIType === 'color-picker' ? (
        <ColorPicker
          level={ui.level}
          defaultValue={value}
          fieldName={fieldName}
          styleId={styleId}
          contentId={contentId}
          handleUpdate={handleUpdate}
        />
      ) : ui.UIType === 'alignment' ? (
        <AlignControl
          level={ui.level}
          defaultValue={value}
          fieldName={fieldName}
          styleId={styleId}
          contentId={contentId}
          handleUpdate={handleUpdate}
        />
      ) : ui.UIType === 'multilang-text' ? (
        <MultilangTextField
          level={ui.level}
          defaultValue={value}
          fieldName={fieldName}
          styleId={styleId}
          contentId={contentId}
          handleTextUpdate={handleTextUpdate}
        />
      ) : ui.UIType === 'url-select' ? (
        <>
          <UrlInput
            level={ui.level}
            defaultValue={value}
            fieldName={fieldName}
            styleId={styleId}
            contentId={contentId}
            handleUpdate={handleUpdate}
          />
        </>
      ) : ui.UIType === 'maps-input' ? (
        <MapsInput
          defaultValue={value}
          contentId={contentId}
          handleUpdateMapsContent={handleUpdateMapsContent}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAyQeBJLKkHLpIsVaNlOp7ISDRxS8wzW1s&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
        />
      ) : null}
    </Grid>
  )
}

export default RenderControl
