import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { ADMIN_GET_SHOP_SETTINGS } from '../../gql/admin-queries';
import { ADMIN_UPDATE_SHOP_NOTIFICATION_SETTINGS } from '../../gql/admin-mutations';
import _get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '../../../common/TextField';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  }
});

class ShippingSettings extends Component {
  render() {
    const { classes, shopId } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ height: 60, marginBottom: 20 }}
        >
          <Typography
            style={{
              fontSize: 28,
              fontWeight: 400,
              color: '#424242'
            }}
          >
            Notification
          </Typography>
          <div style={{ flex: 1 }} />
        </Grid>
        Coming soon
        <Grid
          style={{
            display: 'none'
          }}
          container
        >
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Create notification
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Provide your own personalized content
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Query query={ADMIN_GET_SHOP_SETTINGS} variables={{ id: shopId }}>
                {({ loading, error, data: { shop } }) => {
                  if (loading) return <CircularProgress />;
                  if (error) return <div>Error :(</div>;
                  return (
                    <Mutation
                      update={(
                        store,
                        { data: { updateShopNotificationSettings } }
                      ) => {}}
                      mutation={ADMIN_UPDATE_SHOP_NOTIFICATION_SETTINGS}
                    >
                      {(
                        updateShopNotificationSettings,
                        { data, loading, error }
                      ) => {
                        return (
                          <Grid item xs={12}>
                            <Grid container>
                              {shop.notificationSettings
                                ? _get(
                                    shop,
                                    'notificationSettings.notifications'
                                  ).map((notification, index) => (
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        key={'notification-' + index}
                                      >
                                        <TextField
                                          multiline={true}
                                          rowsMax="4"
                                          label="Fabric description"
                                          label="Fabric description"
                                          onChange={e => {
                                            updateShopNotificationSettings({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updateShopNotificationSettings: {
                                                  ...shop,
                                                  notificationSettings: {
                                                    ...shop.notificationSettings,
                                                    notifications: shop.notificationSettings.notifications.map(
                                                      (d, tempIndex) => {
                                                        if (
                                                          tempIndex !== index
                                                        ) {
                                                          return d;
                                                        } else {
                                                          return {
                                                            ...d,
                                                            emailSubject:
                                                              e.target.value
                                                          };
                                                        }
                                                      }
                                                    )
                                                  }
                                                }
                                              },
                                              variables: {
                                                id: shopId,
                                                input: {
                                                  // notificationSettings: {
                                                  //     shippingFrom: {
                                                  //         legalName: e.target.value
                                                  //     }
                                                  // }
                                                }
                                              }
                                            });
                                          }}
                                          label="Email subject"
                                          value={_get(
                                            shop,
                                            'notification.emailSubject',
                                            ''
                                          )}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: 24 }}
                                      >
                                        <Typography style={{ color: '#000' }}>
                                          Receiver
                                        </Typography>

                                        {notification.webhookReceiver ? (
                                          _get(
                                            notification,
                                            'webhookReceiver',
                                            []
                                          ).map((receiver, receiverIndex) => {
                                            return (
                                              <Grid
                                                container
                                                style={{
                                                  border: '1px solid #ddd',
                                                  borderRadius: '5px',
                                                  padding: '8px 16px',
                                                  textOverflow: 'ellipsis',
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden'
                                                }}
                                                key={
                                                  'n-' +
                                                  index +
                                                  '-r-' +
                                                  receiverIndex
                                                }
                                              >
                                                {JSON.stringify(receiver)}
                                              </Grid>
                                            );
                                          })
                                        ) : (
                                          <Button
                                            onClick={() => {
                                              // updateShopNotificationSettings({
                                              //     optimisticResponse: {
                                              //         __typename: 'Mutation',
                                              //         updateShopNotificationSettings: {
                                              //         ...shop,
                                              //         notificationSettings:
                                              //         shop.notificationSettings ?
                                              //         {
                                              //             ...shop.notificationSettings,
                                              //             notifications: [
                                              //                 ...shop.notificationSettings,
                                              //                 {
                                              //                     emailBody: "TBD"
                                              //                 }
                                              //             ]
                                              //         }
                                              //         :
                                              //         {
                                              //             __typename: 'ShopNotificationSettings',
                                              //             notifications: [{
                                              //                 __typename: 'ShopEventNotification',
                                              //                 emailBody: "TBD"
                                              //             }]
                                              //         }
                                              //         }
                                              //     },
                                              //     variables: {
                                              //         id: shopId,
                                              //         input: {
                                              //             notificationSettings: {
                                              //                 notifications: [{
                                              //                     emailBody: "TBD"
                                              //                 }]
                                              //             }
                                              //         }
                                              //     }
                                              // });
                                            }}
                                          >
                                            Add
                                          </Button>
                                        )}
                                      </Grid>
                                    </Grid>
                                  ))
                                : null}
                              <Grid
                                container
                                direction="column"
                                style={{ marginTop: 24 }}
                                spacing={1}
                              >
                                <Grid item>
                                  <Typography variant="body1">
                                    Add new notification
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      updateShopNotificationSettings({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateShopNotificationSettings: {
                                            ...shop,
                                            notificationSettings: shop.notificationSettings
                                              ? {
                                                  ...shop.notificationSettings,
                                                  notifications: [
                                                    ...shop.notificationSettings,
                                                    {
                                                      emailBody: 'TBD'
                                                    }
                                                  ]
                                                }
                                              : {
                                                  __typename:
                                                    'ShopNotificationSettings',
                                                  notifications: [
                                                    {
                                                      __typename:
                                                        'ShopEventNotification',
                                                      emailBody: 'TBD'
                                                    }
                                                  ]
                                                }
                                          }
                                        },
                                        variables: {
                                          id: shopId,
                                          input: {
                                            notificationSettings: {
                                              notifications: [
                                                {
                                                  emailBody: 'TBD'
                                                }
                                              ]
                                            }
                                          }
                                        }
                                      });
                                    }}
                                  >
                                    Add
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Query>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ShippingSettings);
