import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { convertToRaw } from 'draft-js'

interface Props {
  contentId: any
  element: any
  handleTextUpdate: any
  editorState: any
}

const SaveText: React.FC<Props> = props => {
  const { contentId, element, handleTextUpdate, editorState } = props

  return (
    <Button
      size="small"
      variant="outlined"
      color="primary"
      style={{
        fontWeight: 'bold',
        position: 'relative',
        zIndex: 200,
        left: 25,
        bottom: 4
      }}
      onClick={async () => {
        const content = editorState.getCurrentContent()
        const data = convertToRaw(content)

        await handleTextUpdate(
          contentId,
          'contentText',
          JSON.stringify(data),
          'root',
          element.contentText
        )
      }}
    >
      Save
    </Button>
  )
}

export default SaveText
