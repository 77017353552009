import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

interface Props {
  title: string
  setDialog?: any
}

const PagesTitle: React.FunctionComponent<Props> = props => {
  const { title, setDialog } = props
  return (
    <Grid
      style={{ paddingTop: 50 }}
      direction="row"
      justify="center"
      alignItems="center"
      container={true}
    >
      <Grid item={true} xs>
        <Typography
          style={
            setDialog
              ? {
                  fontSize: '1.25rem',
                  letterSpacing: '1px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontFamily: 'Poppins'
                }
              : {
                  fontSize: '1.25rem',
                  letterSpacing: '1px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  position: 'relative',
                  fontFamily: 'Poppins',
                  right: 4
                }
          }
          variant="h1"
        >
          {title}
        </Typography>
      </Grid>
      {setDialog ? (
        <Grid item={true} xs>
          <Button onClick={() => setDialog(true)}>
            <AddIcon />
          </Button>
        </Grid>
      ) : (
        <Grid item={true} xs={5} />
      )}
    </Grid>
  )
}

export default PagesTitle
