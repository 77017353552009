import React, { Component } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import Input from '../../../common/Input';
import Select from '../../../common/Select';
import _cloneDeep from 'lodash/cloneDeep';
import omitDeep from 'omit-deep';
class ShippingRate extends Component {
  render() {
    const {
      shop,
      shopId,
      defaultCurrency,
      shippingRate,
      shippingZone,
      updateShopShippingSettings
    } = this.props;
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid
          container
          justify="space-between"
          style={{ marginTop: 24 }}
          alignItems="center"
        >
          <Grid item xs={3}>
            <Select label="Rate type" labeColor="#969696">
              <option value={'PRICE'}>Flat rate</option>
              {/* <option>Flat rate</option> */}
              {/* <option>Flat rate</option> */}

              {/* <option>Flat rate</option>
              <option>Exact rate</option>
              <option>Flat rate + Free shipping</option>
              <option>Exact rate + Free shipping</option> */}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Input
              value={shippingRate.rate.value}
              onChange={e => {
                updateShopShippingSettings({
                  optimisticResponse: {
                    __typename: 'Mutation',
                    updateShopShippingSettings: {
                      ...shop,
                      shippingSettings: {
                        ...shop.shippingSettings,
                        shippingZones: shop.shippingSettings.shippingZones.map(
                          tempShippingZone => {
                            return tempShippingZone &&
                              shippingZone.id === tempShippingZone.id
                              ? {
                                  ...tempShippingZone,
                                  shippingRates: tempShippingZone.shippingRates.map(
                                    tempShippingRate1 => {
                                      return {
                                        ...tempShippingRate1,
                                        rate: {
                                          ...tempShippingRate1.rate,
                                          value:
                                            tempShippingRate1.id ===
                                            shippingRate.id
                                              ? e.target.value
                                                ? parseFloat(e.target.value)
                                                : null
                                              : tempShippingRate1.rate.value
                                        }
                                      };
                                    }
                                  )
                                }
                              : tempShippingZone;
                          }
                        )
                      }
                    }
                  },
                  variables: {
                    id: shopId,
                    input: {
                      shippingSettings: {
                        shippingZones: _cloneDeep(
                          shop.shippingSettings.shippingZones
                        ).map(tempShippingZone1 =>
                          omitDeep(
                            tempShippingZone1.id === shippingZone.id
                              ? {
                                  ...tempShippingZone1,
                                  shippingRates: tempShippingZone1.shippingRates.map(
                                    tempShippingRate1 => {
                                      return {
                                        ...tempShippingRate1,
                                        rate: {
                                          ...tempShippingRate1.rate,
                                          value:
                                            tempShippingRate1.id ===
                                            shippingRate.id
                                              ? e.target.value
                                                ? parseFloat(e.target.value)
                                                : null
                                              : tempShippingRate1.rate.value
                                        }
                                      };
                                    }
                                  )
                                }
                              : tempShippingZone1,
                            ['__typename']
                          )
                        )
                      }
                    }
                  }
                });
              }}
              label={'Flat rate in ' + defaultCurrency}
              placeholder="0"
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              value={
                shippingRate.minimumTotal
                  ? shippingRate.minimumTotal.value
                  : null
              }
              onChange={e => {
                updateShopShippingSettings({
                  optimisticResponse: {
                    __typename: 'Mutation',
                    updateShopShippingSettings: {
                      ...shop,
                      shippingSettings: {
                        ...shop.shippingSettings,
                        shippingZones: shop.shippingSettings.shippingZones.map(
                          tempShippingZone => {
                            return tempShippingZone &&
                              shippingZone.id === tempShippingZone.id
                              ? {
                                  ...tempShippingZone,
                                  shippingRates: tempShippingZone.shippingRates.map(
                                    tempShippingRate1 => {
                                      return {
                                        ...tempShippingRate1,
                                        minimumTotal:
                                          tempShippingRate1.id ===
                                          shippingRate.id
                                            ? e.target.value
                                              ? {
                                                  ...tempShippingRate1.minimumTotal,
                                                  value: parseFloat(
                                                    e.target.value
                                                  )
                                                }
                                              : null
                                            : tempShippingRate1.minimumTotal
                                      };
                                    }
                                  )
                                }
                              : tempShippingZone;
                          }
                        )
                      }
                    }
                  },
                  variables: {
                    id: shopId,
                    input: {
                      shippingSettings: {
                        shippingZones: _cloneDeep(
                          shop.shippingSettings.shippingZones
                        ).map(tempShippingZone1 =>
                          omitDeep(
                            tempShippingZone1.id === shippingZone.id
                              ? {
                                  ...tempShippingZone1,
                                  shippingRates: tempShippingZone1.shippingRates.map(
                                    tempShippingRate1 => {
                                      return {
                                        ...tempShippingRate1,
                                        minimumTotal:
                                          tempShippingRate1.id ===
                                          shippingRate.id
                                            ? e.target.value
                                              ? {
                                                  ...tempShippingRate1.minimumTotal,
                                                  value: parseFloat(
                                                    e.target.value
                                                  )
                                                }
                                              : null
                                            : tempShippingRate1.minimumTotal
                                      };
                                    }
                                  )
                                }
                              : tempShippingZone1,
                            ['__typename']
                          )
                        )
                      }
                    }
                  }
                });
              }}
              label={'Min. order total in ' + defaultCurrency}
              placeholder="0"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ShippingRate;
