import _cloneDeep from 'lodash/cloneDeep'

var DesignRoot = null
var canvasMarker = null
var stackList = []
var triggerSize = false

var svgCalBase = null
var svgCalElm = null
var svgCalCm = null
var ratioScale = null

var size_use = null

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot
}

export function clipingMask(size) {
  try {
    // statements

    var clipArray = []

    if (size === 'ORIGINAL') {
      var allStack = DesignRoot.fabric_function.getObjectByType('SVG')

      for (var i = 0; i < allStack.length; i++) {
        var clipPath = new window.fabric.Polygon(
          _cloneDeep(allStack[i].points),
          {
            left: allStack[i].left,
            top: allStack[i].top,
            scaleX: allStack[i].scaleX,
            scaleY: allStack[i].scaleY
          }
        )

        clipArray.push(clipPath)
      }
    } else {
      var allStack = DesignRoot.fabric_function.getObjectByType('STACK')

      for (var i = 0; i < allStack.length; i++) {
        if (allStack[i]._USER_DATA.size === size) {
          var clipPath = new window.fabric.Polygon(
            _cloneDeep(allStack[i].points),
            {
              left: allStack[i].left,
              top: allStack[i].top,
              scaleX: allStack[i].scaleX,
              scaleY: allStack[i].scaleY
            }
          )

          clipArray.push(clipPath)
        }
      }
    }

    var clipPath = new window.fabric.Group(clipArray)

    DesignRoot.canvas.clipPath = clipPath
    DesignRoot.canvas.renderAll()
  } catch (e) {
    // statements
    logger.error('marker_layout_control.clipingMask.error', e)
  }
}

export function cliping(size) {
  try {
    // statements

    DesignRoot.Loading.setState({ active: true })

    stackList = []

    DesignRoot.canvas.setZoom(1)

    // var svgList = DesignRoot.fabric_function.getObjectByType('SVG');

    size_use = size

    if (size !== 'ORIGINAL') {
      var allStack = DesignRoot.fabric_function.getObjectByType('STACK')

      for (var i = 0; i < allStack.length; i++) {
        allStack[i].visible = false

        if (allStack[i]._USER_DATA.size === size) {
          stackList.push(allStack[i])

          // RECORD REAL DIMENTION

          svgCalElm = allStack[i]._USER_DATA.svgCalElm
          svgCalCm = allStack[i]._USER_DATA.svgCalCm

          if (allStack[i].id.indexOf(svgCalElm) !== -1) {
            svgCalBase = allStack[i]
          }

          // RECORD REAL DIMENTION
        }
      }

      // RECORD REAL DIMENTION

      // 1 cm = 37.795276 px; 1 px = 0.026458 cm

      var calPx = svgCalBase.width * svgCalBase.scaleX
      var realPx = svgCalCm * 37.795276
      ratioScale = realPx / calPx

      logger.log(
        'GET REAL DIMENSION DATA',
        svgCalBase,
        svgCalElm,
        svgCalCm,
        calPx,
        realPx,
        ratioScale
      )

      // RECORD REAL DIMENTION

      // CLONE DESIGN

      logger.log('stackList', stackList)

      toggleElementById('template', false)

      for (var i = 0; i < stackList.length; i++) {
        var index = stackList[i]._USER_DATA.index
        var svgBase = DesignRoot.fabric_function.getObjectById('SVG-' + index)
        logger.log('stackList', stackList[i], svgBase)

        if (svgBase._USER_DATA.hasChange === true) {
          if (svgBase._USER_DATA.fillType === 'AOP') {
            stackList[i]._USER_DATA.transform = svgBase._USER_DATA.transform
            stackList[i].set({
              fill: DesignRoot.change_color.updateFillPattern(
                svgBase.fill.img,
                null,
                null,
                stackList[i],
                svgBase._USER_DATA.transform
              )
            })
          } else {
            stackList[i].set({
              fill: _cloneDeep(svgBase.fill)
            })
          }
        }

        stackList[i].set({
          strokeWidth: 2,
          strokeDashArray: null,
          stroke: '#ccc'
        })

        svgBase.visible = false
      } // END for

      DesignRoot.canvas.renderAll()

      // CLONE DESIGN
    } // END ORIGINAL
    else {
      var allSvg = DesignRoot.fabric_function.getObjectByType('SVG')

      svgCalElm = DesignRoot.productDataSet.dimension.svgCalElm
      svgCalCm = DesignRoot.productDataSet.dimension.svgCalCm

      for (var i = 0; i < allSvg.length; i++) {
        allSvg[i].visible = false

        stackList.push(allSvg[i])

        // RECORD REAL DIMENTION

        if (allSvg[i].id.indexOf(svgCalElm) !== -1) {
          svgCalBase = allSvg[i]
        }

        // RECORD REAL DIMENTION
      } // END for

      // RECORD REAL DIMENTION

      // 1 cm = 37.795276 px; 1 px = 0.026458 cm

      var calPx = svgCalBase.width * svgCalBase.scaleX
      var realPx = svgCalCm * 37.795276
      ratioScale = realPx / calPx

      logger.log(
        'GET REAL DIMENSION DATA',
        svgCalBase,
        svgCalElm,
        svgCalCm,
        calPx,
        realPx,
        ratioScale
      )

      // RECORD REAL DIMENTION

      // toggleElementById('template', false);
      DesignRoot.canvas.renderAll()
    } // END ELSE

    setTimeout(() => {
      readyToCliping(DesignRoot.canvas)
    }, 5000)

    // buildCanvasMarker();
    // addMarker(clippingSet);
  } catch (e) {
    // statements
    logger.error('marker_layout_control.cliping.error', e)
  }
}

export function readyToCliping(canvas) {
  try {
    // statements

    var clippingSet = []
    for (var i = 0; i < stackList.length; i++) {
      stackList[i].visible = true

      var clipPath = new window.fabric.Polygon(
        _cloneDeep(stackList[i].points),
        {
          left: stackList[i].left,
          top: stackList[i].top,
          scaleX: stackList[i].scaleX,
          scaleY: stackList[i].scaleY
        }
      )
      DesignRoot.canvas.clipPath = clipPath

      var clipData = canvas.toDataURL({
        format: 'png',
        left: stackList[i].left,
        top: stackList[i].top,
        width: stackList[i].width * stackList[i].scaleX,
        height: stackList[i].height * stackList[i].scaleX
      })

      clippingSet.push({
        id: stackList[i].id,
        clipData: clipData,
        stackData: stackList[i]
      })

      stackList[i].visible = false
    }

    DesignRoot.canvas.clipPath = null
    logger.log('cliping')
    logger.log(clippingSet)
    buildCanvasMarker()
    buildMarker(clippingSet)

    // TOGGLE BACK ALL HIDDEN

    toggleElementById('template', true)
    var svgList = DesignRoot.fabric_function.getObjectByType('SVG')

    for (var i = 0; i < svgList.length; i++) {
      svgList[i].visible = true
    }

    if (size_use !== 'ORIGINAL') {
      for (var i = 0; i < stackList.length; i++) {
        stackList[i].set({
          fill: 'transparent',
          strokeWidth: 2,
          strokeDashArray: [10, 10],
          stroke: stackList[i]._USER_DATA.legendColor
        })
      }
    }

    DesignRoot.canvas.setZoom(DesignRoot._CANVAS_SIZE)
    DesignRoot.Loading.setState({ active: false })

    // TOGGLE BACK ALL HIDDEN
  } catch (e) {
    // statements
    logger.error('marker_layout_control.readyToCliping.error', e)
  }
}

export function toggleElementById(id, visible) {
  try {
    // statements

    var item = DesignRoot.fabric_function.getObjectById(id)

    if (item !== null) {
      item.visible = visible
      DesignRoot.canvas.renderAll()
    }
  } catch (e) {
    // statements
    logger.error('marker_layout_control.toggleElementById.error', e)
  }
}

export function buildCanvasMarker() {
  try {
    // statements

    if (canvasMarker !== null) {
      var allItem = []
      for (var i = 0; i < canvasMarker.getObjects().length; i++) {
        allItem.push(canvasMarker.getObjects()[i])
      }

      for (var i = 0; i < allItem.length; i++) {
        canvasMarker.remove(allItem[i])
      }

      canvasMarker.renderAll()
    } else {
      canvasMarker = new window.fabric.Canvas('canvasMarker', {
        width: DesignRoot.canvas.width,
        height: DesignRoot.canvas.height,
        preserveObjectStacking: true
      })

      // canvasMarker.selection = false;

      canvasMarker.on({
        'after:render': canvasMarkerRender
      })

      canvasMarker.on('mouse:wheel', function(opt) {
        if (opt.e.ctrlKey === true) {
          opt.e.preventDefault()
          opt.e.stopPropagation()

          logger.log('mouse:wheel', opt.e, opt.e.deltaY, canvasMarker)

          var delta = opt.e.deltaY
          var zoom = canvasMarker.getZoom()

          if (delta < 0) {
            // ZOMM IN

            zoom += 0.01
          } else if (delta > 0) {
            // ZOOM OUT

            zoom -= 0.01
          }

          canvasMarker.setZoom(zoom)

          var templateBase = DesignRoot.fabric_function.getObjectById(
            'template'
          )

          canvasMarker.setDimensions({
            width: templateBase.width * zoom,
            height: templateBase.height * zoom
          })
        }
      })
    }

    var templateBase = DesignRoot.fabric_function.getObjectById('template')
    canvasMarker.setZoom(DesignRoot._CANVAS_SIZE)
    canvasMarker.setDimensions({
      width: templateBase.width * DesignRoot._CANVAS_SIZE,
      height: templateBase.height * DesignRoot._CANVAS_SIZE
    })
  } catch (e) {
    // statements
    logger.error('marker_layout_control.buildCanvasMarker.error', e)
  }
}

export function buildMarker(clippingSet) {
  try {
    // statements

    for (var i = 0; i < clippingSet.length; i++) {
      addMarker(
        clippingSet[i].stackData.id,
        clippingSet[i].clipData,
        clippingSet[i].stackData
      )
      // addMarkerSVG(
      //   clippingSet[i].stackData.id,
      //   clippingSet[i].clipData,
      //   clippingSet[i].stackData
      // );
    }
  } catch (e) {
    // statements
    logger.error('marker_layout_control.buildMarker.error', e)
  }
}

export function addMarker(id, src, stackData) {
  try {
    // statements

    var image = document.createElement('img')
    image.src = src
    window.fabric.Image.fromURL(image.src, function(img) {
      img.set({
        id: id,
        left: stackData.left,
        top: stackData.top
      })

      DesignRoot.fabric_function.assignCustomiseCornerIcons(
        img,
        'MARKER_LAYOUT'
      )
      canvasMarker.add(img)
      canvasMarker.renderAll()
    })
  } catch (e) {
    // statements
    logger.error('marker_layout_control.addMarker.error', e)
  }
}

export function addMarkerSVG(id, src, stackData) {
  try {
    // statements
    var polygon = new window.fabric.Polygon(_cloneDeep(stackData.points), {
      left: stackData.left,
      top: stackData.top,
      scaleX: stackData.scaleX,
      scaleY: stackData.scaleY,
      fill: _cloneDeep(stackData.fill)
    })

    DesignRoot.fabric_function.assignCustomiseCornerIcons(
      polygon,
      'MARKER_LAYOUT'
    )
    canvasMarker.add(polygon)
    canvasMarker.renderAll()
  } catch (e) {
    // statements
    logger.error('marker_layout_control.addMarkerSVG.error', e)
  }
}

export function clearCanvasMarker() {
  canvasMarker = null
}

export function canvasMarkerRender() {
  try {
    // statements
    if (triggerSize === true) {
      exportToTIFF()
      triggerSize = false
    }
  } catch (e) {
    // statements
    logger.error('marker_layout_control.canvasMarkerRender.error', e)
  }
}

export function exportCanvasMarker() {
  try {
    // statements
    if (canvasMarker !== null) {
      logger.log('export2DSceneTIFF', canvasMarker)

      // REAL SIZE IN CM

      // DesignRoot.productDataSet.dimension;

      // REAL SIZE IN CM

      canvasMarker.setZoom(ratioScale)
      var templateBase = DesignRoot.fabric_function.getObjectById('template')
      canvasMarker.setDimensions({
        width: templateBase.width * ratioScale,
        height: templateBase.height * ratioScale
      })

      triggerSize = true
    }
  } catch (e) {
    // statements
    logger.error('marker_layout_control.exportCanvasMarker.error', e)
  }
}

export function exportToTIFF() {
  try {
    window.CanvasToTIFF.toBlob(canvasMarker, function(blob) {
      // blob object can be converted to an objectURL and then
      // set as source for images, or as download target:
      var url = URL.createObjectURL(blob)
      logger.log(blob)

      var link = document.createElement('a')
      link.download = 'MARKER_EXPORT.TIFF'
      link.href = url

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      logger.log(link)

      var templateBase = DesignRoot.fabric_function.getObjectById('template')
      canvasMarker.setZoom(DesignRoot._CANVAS_SIZE)
      canvasMarker.setDimensions({
        width: templateBase.width * DesignRoot._CANVAS_SIZE,
        height: templateBase.height * DesignRoot._CANVAS_SIZE
      })
    })
  } catch (e) {
    logger.error('marker_layout_control.exportToTIFF.error', e)
    return
  }
}
