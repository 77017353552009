import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Grid, withStyles } from '@material-ui/core';

import AlignItemsCenter from './icons/AlignItemsCenter';
import AlignItemsFlexEnd from './icons/AlignItemsFlexEnd';
import AlignItemsFlexStart from './icons/AlignItemsFlexStart';

const styles = theme => ({
  root: {
    display: 'flex',
    padding: '16px 0px',
    marginTop: 24
  },
  formControl: {
    width: '100%'
  },
  group: {
    margin: `${theme.spacing}px 0`
  },
  typo: {
    color: '#696969',
    fontSize: '15px',
    marginBottom: '8px',
    position: 'relative',
    left: 15
  },
  icon: {
    margin: 5,
    fontSize: 32
  }
});

class VerticalAlign extends React.Component {
  render() {
    const { classes, fields, fieldName, handleChangeField } = this.props;

    const getVariant = position => {
      if (!fields) {
        return 'text';
      }
      return position === fields[fieldName] ? 'outlined' : 'text';
    };

    return (
      <Grid className={classes.root} direction="column" container={true}>
        <Typography variant="subtitle1" className={classes.typo}>
          Vertical Alignment
        </Typography>
        <Grid container justify="space-between" style={{ marginTop: 8 }}>
          <Grid item xs={3}>
            <Button
              variant={getVariant('flex_start')}
              style={{ boxShadow: 'none' }}
              color="primary"
              onClick={() => handleChangeField('flex-start', fieldName)}
            >
              <AlignItemsFlexStart />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={getVariant('center')}
              style={{ boxShadow: 'none' }}
              onClick={() => handleChangeField('center', fieldName)}
              color="primary"
            >
              <AlignItemsCenter />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={getVariant('flex_end')}
              style={{ boxShadow: 'none' }}
              onClick={() => handleChangeField('flex-end', fieldName)}
              color="primary"
            >
              <AlignItemsFlexEnd />
            </Button>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Grid>
    );
  }
}

VerticalAlign.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(VerticalAlign);
