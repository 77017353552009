import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { primary } from '../../admin/AdminTheme'
import Exports from './Exports'

import Selection from '../tools/Selection'
import LayoutStep from './LayoutStep'
import FillStep from './FillStep'
import TextStep from './TextStep'
import LogoStep from './LogoStep'
import PatternStep from './PatternStep'
import MarkerLayout from './MarkerLayout'
import LayerControl from './LayerControl'
import * as template_control from '../template_control'

import * as tools from '../tools/tools'
let SHOP_ID = tools.getQueryStringValue('s')

function TabContainer(props) {
  return (
    <Grid
      container
      style={{
        background: '#FFFFFF',
        height: '100vh',
        width: 300,
        float: 'right',
        borderLeft: '1px solid #E5E5E5',
        overflowY: 'auto'
      }}
    >
      {props.children}
    </Grid>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const styles = adminTheme => ({
  menu: {
    paddingTop: 14,
    width: 60,
    boxShadow: 'none',
    background: '#FFFFFF !important',
    float: 'right',
    height: '100vh',
    borderLeft: '1px solid #E5E5E5'
  },
  tabsIndicator: {
    background: 'transparent'
  },
  tabs: {
    margin: 4,
    minWidth: '50px',
    minHeight: '50px',
    width: '50px',
    height: '50px',
    padding: 0,
    background: '#FFFFFF',
    color: '#000000',
    borderRadius: '100%',
    border: '0px',
    fontSize: '12px',
    '&:hover': {
      color: '#FFFFFF !important',
      background: primary
    },
    '&$tabSelected': {
      color: '#FFFFFF',
      background: primary
    },
    '&:focus': {
      color: '#FFFFFF',
      background: primary
    }
  },
  tabSelected: {
    color: '#FFFFFF',
    background: primary
  },
  tab: {
    padding: '0px !important'
  },
  tabsShow: {
    display: 'block'
  },
  tabsHide: {
    display: 'none'
  },
  tabsRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: 60
  }
})

class Menu extends Component {
  constructor(props) {
    super(props)

    if (!SHOP_ID) {
      SHOP_ID = this.props.DesignRoot.SHOP_ID
    }
  }

  state = {
    value: 'Layout'
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot
    DesignRoot.MenuDesignRoomStep = this
    DesignRoot.setState({ update: true })
    // PROVIDE COMPONENT TO MAIN
  }

  handleChange = (event, value) => {
    logger.log('handleChange', event, value)

    if (value === this.state.value) {
      value = '-'
    }

    this.setState({ value })

    const { classes, DesignRoot } = this.props

    if (value === '-') {
      DesignRoot.setState({ dynamicPlane: '' })
    } else {
      DesignRoot.setState({ dynamicPlane: 'dynamicPlane' })
    }
  }

  handleTabsToggle(tabName) {
    const { value } = this.state

    if (tabName === value) {
      return 'block'
    } else {
      return 'none'
    }
  }

  render() {
    const { classes, DesignRoot } = this.props
    const { value } = this.state

    var configTab = {
      FillStep: true
    }

    if (
      DesignRoot._ACTIVE_FILL_STEP === false &&
      DesignRoot._OLD_CZ_AVAILABLE === false
    ) {
      configTab.FillStep = false
    }

    return (
      <div className="MenuDesignRoom">
        <AppBar position="static" className={classes.menu}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            classes={{
              indicator: classes.tabsIndicator,
              flexContainer: classes.tabsRoot
            }}
          >
            <Tab
              value="Layout"
              label="Layout"
              classes={{
                root: classes.tabs,
                wrapper: classes.tab,
                selected: classes.tabSelected
              }}
            />

            {configTab.FillStep === true && (
              <Tab
                value="Fill"
                label="Fill"
                classes={{
                  root: classes.tabs,
                  wrapper: classes.tab,
                  selected: classes.tabSelected
                }}
              />
            )}

            <Tab
              value="Logo"
              label="Image"
              classes={{
                root: classes.tabs,
                wrapper: classes.tab,
                selected: classes.tabSelected
              }}
            />
            <Tab
              value="Pattern"
              label="Pattern"
              classes={{
                root: classes.tabs,
                wrapper: classes.tab,
                selected: classes.tabSelected
              }}
            />
            <Tab
              value="Text"
              label="Text"
              classes={{
                root: classes.tabs,
                wrapper: classes.tab,
                selected: classes.tabSelected
              }}
            />
            <Tab
              value="List"
              label="List"
              classes={{
                root: classes.tabs,
                wrapper: classes.tab,
                selected: classes.tabSelected
              }}
            />
            <Tab
              value="Export"
              label="Export"
              classes={{
                root: classes.tabs,
                wrapper: classes.tab,
                selected: classes.tabSelected
              }}
            />

            {/* {SHOP_ID === 'yuepai' && (
              <Tab
                value="ML"
                label="ML"
                classes={{
                  root: classes.tabs,
                  wrapper: classes.tab,
                  selected: classes.tabSelected
                }}
              />
            )} */}
          </Tabs>
        </AppBar>
        <span style={{ display: this.handleTabsToggle('Layout') }}>
          <TabContainer>
            <Grid container>
              <LayoutStep
                DesignRoot={DesignRoot}
                productDataSet={DesignRoot.productDataSet}
              />
            </Grid>
          </TabContainer>
        </span>
        <span style={{ display: this.handleTabsToggle('Fill') }}>
          <TabContainer>
            <FillStep
              DesignRoot={DesignRoot}
              productDataSet={DesignRoot.productDataSet}
            />
          </TabContainer>
        </span>
        <span style={{ display: this.handleTabsToggle('Logo') }}>
          <TabContainer>
            <LogoStep
              DesignRoot={DesignRoot}
              productDataSet={DesignRoot.productDataSet}
            />
          </TabContainer>
        </span>
        <span style={{ display: this.handleTabsToggle('Pattern') }}>
          <TabContainer>
            <PatternStep
              DesignRoot={DesignRoot}
              productDataSet={DesignRoot.productDataSet}
            />
          </TabContainer>
        </span>

        <span style={{ display: this.handleTabsToggle('Text') }}>
          <TabContainer>
            <TextStep
              DesignRoot={DesignRoot}
              productDataSet={DesignRoot.productDataSet}
            />
          </TabContainer>
        </span>

        <span style={{ display: this.handleTabsToggle('List') }}>
          <TabContainer>
            <LayerControl
              DesignRoot={DesignRoot}
              productDataSet={DesignRoot.productDataSet}
            />
          </TabContainer>
        </span>

        <span style={{ display: this.handleTabsToggle('Export') }}>
          <TabContainer>
            {SHOP_ID === 'yuepai' && (
              <MarkerLayout
                DesignRoot={DesignRoot}
                productDataSet={DesignRoot.productDataSet}
              />
            )}
            <Exports
              DesignRoot={DesignRoot}
              productDataSet={DesignRoot.productDataSet}
            />
          </TabContainer>
        </span>

        {/* {SHOP_ID === 'yuepai' && (
          <span style={{ display: this.handleTabsToggle('ML') }}>
            <TabContainer>
              <MarkerLayout
                DesignRoot={DesignRoot}
                productDataSet={DesignRoot.productDataSet}
              />
            </TabContainer>
          </span>
        )} */}
      </div>
    )
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Menu)
