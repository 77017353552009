import React, { Component } from 'react'
import { Link } from '@reach/router'
import { Query, Mutation } from 'react-apollo'
import Grid from '@material-ui/core/Grid'
import { ADMIN_SHOP_COUPONS } from '../../gql/admin-queries'
import { ADMIN_UPDATE_COUPON } from '../../gql/admin-mutations'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

import Typography from '@material-ui/core/Typography'
// import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 0,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%',
    borderTop: '1px solid #E5E5E5',
    marginRight: 4,
    marginLeft: 4
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242',
    flex: 1
  }
})
class DiscountCoupons extends Component {
  render() {
    const { classes, coupons, shopId } = this.props
    return (
      <div className={classes.root}>
        <Grid
          container
          style={{ padding: 24 }}
          justify="flex-start"
          alignItems="center"
        >
          <Typography component="subtitle1">
            Assigned Coupons (shows a max. of 50)
          </Typography>
        </Grid>
        <div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>max./Customer</TableCell>
                <TableCell>max./Total</TableCell>
                <TableCell>Used Total</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <Mutation
              mutation={ADMIN_UPDATE_COUPON}
              update={(cache, { data: { updateCoupon } }) => {}}
            >
              {(updateCoupon, { data, loading, error }) => {
                return (
                  <TableBody>
                    {coupons && coupons.length > 0 ? (
                      coupons.map(couponNode => {
                        const coupon = couponNode.node
                        return (
                          <TableRow key={coupon.id} hover>
                            <TableCell component="th" scope="row">
                              {coupon.couponCode}
                            </TableCell>
                            <TableCell>{coupon.maxUsagePerCustomer}</TableCell>
                            <TableCell>{coupon.maxUsageOverall}</TableCell>
                            <TableCell>
                              {coupon.currentUsageOverall
                                ? coupon.currentUsageOverall
                                : 0}
                            </TableCell>
                            <TableCell>
                              {coupon.enabled === true ? 'Enabled' : 'Disabled'}
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  const newStatus = !coupon.enabled
                                  updateCoupon({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateCoupon: {
                                        ...coupon,
                                        enabled: newStatus
                                      }
                                    },
                                    variables: {
                                      first: 1,
                                      id: coupon.id,
                                      couponCode: coupon.couponCode,
                                      input: {
                                        enabled: newStatus
                                      }
                                    }
                                  })
                                }}
                              >
                                {coupon.enabled === true ? 'Disable' : 'Enable'}
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    ) : (
                      <TableRow hover>
                        <TableCell colspan="3">No coupons yet</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )
              }}
            </Mutation>
          </Table>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(DiscountCoupons)
