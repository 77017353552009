import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query, Mutation } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import { ADMIN_SHOP_FABRIC_GROUPS } from '../../gql/admin-queries';
import {
  ADMIN_UPDATE_FABRIC_GROUP,
  ADMIN_CREATE_FABRIC_GROUP
} from '../../gql/admin-mutations';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { shopId } from '../../../../helper/config';
import Input from '../../../common/Input';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242',
    flex: 1
  }
});
class FabricGroupList extends Component {
  render() {
    const { classes, style, shopId } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Typography
            component="h1"
            className={classes.h1}
            data-cy="Header-Material-groups"
          >
            Material groups
          </Typography>
          <Mutation
            update={(cache, { data: { createFabricGroup } }) => {
              if (createFabricGroup.id) {
                const data = cache.readQuery({
                  query: ADMIN_SHOP_FABRIC_GROUPS,
                  variables: { id: shopId }
                });
                cache.writeQuery({
                  query: ADMIN_SHOP_FABRIC_GROUPS,
                  variables: { id: shopId },
                  data: {
                    ...data,
                    shop: {
                      ...data.shop,
                      fabricGroups: {
                        ...data.shop.fabricGroups,
                        edges: [
                          {
                            cursor: 'X',
                            node: createFabricGroup,
                            __typename: 'ShopFabricGroupEdge'
                          },
                          ...data.shop.fabricGroups.edges
                        ]
                      }
                    }
                  }
                });
              }
            }}
            mutation={ADMIN_CREATE_FABRIC_GROUP}
          >
            {(createFabricGroup, { data, loading, error }) => {
              return (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    createFabricGroup({
                      variables: {
                        shopId: shopId
                      }
                    });
                  }}
                >
                  Add material group
                </Button>
              );
            }}
          </Mutation>
        </Grid>
        <Query query={ADMIN_SHOP_FABRIC_GROUPS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            if (loading) return <CircularProgress />;
            if (error) return <div>Error :(</div>;
            return (
              <div>
                {shop.fabricGroups.edges.length > 0 ? (
                  <Paper>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {shop.fabricGroups.edges.map(fabricGroups => {
                          if (fabricGroups.node.shopId === shopId) {
                            return (
                              <TableRow key={fabricGroups.node.id}>
                                <Mutation mutation={ADMIN_UPDATE_FABRIC_GROUP}>
                                  {(
                                    updateFabricGroup,
                                    { data, loading, error }
                                  ) => {
                                    return (
                                      <TableCell>
                                        <Input
                                          placeholder="Enter material group here"
                                          borderColor="transparent"
                                          value={fabricGroups.node.name}
                                          onChange={e => {
                                            updateFabricGroup({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updateFabricGroup: {
                                                  ...fabricGroups.node,
                                                  name: e.target.value
                                                }
                                              },
                                              variables: {
                                                id: fabricGroups.node.id,
                                                input: {
                                                  name: e.target.value
                                                }
                                              }
                                            });
                                          }}
                                        />
                                      </TableCell>
                                    );
                                  }}
                                </Mutation>
                              </TableRow>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <Typography component="p" variant="body1">
                    No fabric groups yet
                  </Typography>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withStyles(styles)(FabricGroupList);
