import React, { Component } from 'react';

export default class AlignItemsFlexStart extends React.Component {
  render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="1.38462"
          height="18"
          transform="matrix(-1 0 0 1 18 0)"
          fill="black"
        />
        <rect
          width="15.2308"
          height="2.76923"
          transform="matrix(-1 0 0 1 15.2307 4.15509)"
          fill="black"
        />
        <rect
          width="9.69231"
          height="2.76923"
          transform="matrix(-1 0 0 1 15.2307 12.4617)"
          fill="black"
        />
      </svg>
    );
  }
}
