import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { Query, Mutation } from 'react-apollo'
import { ADMIN_GET_COLLECTION } from '../../gql/admin-queries'
import { ADMIN_UPDATE_COLLECTION } from '../../gql/admin-mutations'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import _sortBy from 'lodash/sortBy'

import Helmet from 'react-helmet'
import Input from '../../../common/Input'

import _get from 'lodash/get'
import './CollectionFileDrop.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import { slugWithUrlPrefix } from '../content/utils'

import CollectionTitle from './CollectionTitle'
import CollectionDescription from './CollectionDescription'
import CollectionMedia from './CollectionMedia'
import CollectionSlug from './CollectionSlug'
import CollectionProducts from './CollectionProducts'
import CollectionParent from './CollectionParent'
import CollectionChilds from './CollectionChilds'
import CollectionProductPackages from './CollectionProductPackages'
import DeleteCollection from './DeleteCollection'
const styles = theme => ({
  progress: {},
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    marginTop: '24px !important',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  },
  rootCheckbox: {
    padding: '8px',
    color: '#B23566',
    '&$checked': {
      color: '#B23566'
    }
  },
  checked: {},
  inputCell: {
    width: 230,
    border: 'none'
  }
})

class CollectionDetail extends Component {
  state = {
    isActive: false,
    message: '',
    isBriklSuperAdmin: false,
    open: false,
    value: 'Yes'
  }

  handleChangeRadio = event => {
    this.setState({ value: event.target.value })
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  }

  render() {
    const {
      classes,
      defaultLanguage,
      collectionId,
      languages,
      shopId,
      shopUrl
    } = this.props
    return (
      <div className={classes.root}>
        <Typography>
          <Grid container>
            <Grid item xs={12}>
              <Query
                query={ADMIN_GET_COLLECTION}
                variables={{ id: collectionId }}
              >
                {({ loading, error, data: { collection }, refetch }) => {
                  if (loading)
                    return <CircularProgress className={classes.progress} />
                  if (error) return <div>Error :(</div>
                  return (
                    <Mutation
                      update={(store, { data: { updateCollection } }) => {
                        this.setState({
                          isActive: true,
                          message: 'Collection updated'
                        })
                      }}
                      mutation={ADMIN_UPDATE_COLLECTION}
                    >
                      {(updateCollection, { data, loading, error }) => {
                        return (
                          <div>
                            <Helmet>
                              <title>{'Collection - ' + collection.id}</title>
                            </Helmet>
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              style={{ height: 60, marginBottom: 20 }}
                            >
                              <Typography
                                data-cy="New-Collection-header"
                                style={{
                                  fontSize: 28,
                                  fontWeight: 400,
                                  color: '#424242'
                                }}
                              >
                                {'Collection - ' + collection.no}
                              </Typography>

                              <div style={{ flex: 1 }} />
                              <Button
                                data-cy="Collection-Preview-btn"
                                style={{ marginLeft: 16 }}
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  window.open(
                                    `${shopUrl}/preview?type=collection&id=${collection.id}&language=${defaultLanguage}`
                                  )
                                }}
                              >
                                Preview
                              </Button>
                              <Button
                                data-cy="Collection-Back-btn"
                                style={{ marginLeft: 16 }}
                                variant="outlined"
                                color="primary"
                                href={
                                  '/admin/' + shopId + '/inventory/collections'
                                }
                              >
                                Back to collections
                              </Button>
                            </Grid>

                            {/*
***************************
Collection details starts
***************************
 */}

                            <Grid container>
                              <Grid xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      data-cy="Collection-Details-header"
                                      variant="subtitle1"
                                      style={{ marginRight: 16 }}
                                    >
                                      Collection details
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="body1">
                                      Provide information on your collection
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                <Paper
                                  style={{ padding: 24, borderRadius: 15 }}
                                  elevation={0}
                                >
                                  <Grid
                                    container
                                    spacing={1}
                                    className={classes.container}
                                  >
                                    <Grid item xs={12}>
                                      <Input
                                        dataCy="Collection-Style-Number"
                                        label="Internal ID"
                                        placeholder="Enter your intern ID"
                                        value={collection.no}
                                        onChange={e =>
                                          updateCollection({
                                            optimisticResponse: {
                                              __typename: 'Mutation',
                                              updateCollection: {
                                                ...collection,
                                                no: e.target.value
                                              }
                                            },
                                            variables: {
                                              id: collectionId,
                                              input: { no: e.target.value }
                                            }
                                          })
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <CollectionTitle
                                    languages={languages}
                                    defaultLanguage={defaultLanguage}
                                    classes={classes}
                                    collection={collection}
                                    collectionId={collectionId}
                                    updateCollection={updateCollection}
                                    shopId={shopId}
                                  />
                                  <CollectionDescription
                                    languages={languages}
                                    defaultLanguage={defaultLanguage}
                                    classes={classes}
                                    collection={collection}
                                    collectionId={collectionId}
                                    updateCollection={updateCollection}
                                    shopId={shopId}
                                  />
                                  <CollectionSlug
                                    languages={languages}
                                    defaultLanguage={defaultLanguage}
                                    classes={classes}
                                    collection={collection}
                                    collectionId={collectionId}
                                    updateCollection={updateCollection}
                                    shopId={shopId}
                                  />
                                </Paper>
                              </Grid>

                              <CollectionParent
                                languages={languages}
                                defaultLanguage={defaultLanguage}
                                updateCollection={updateCollection}
                                collectionId={collectionId}
                                collection={collection}
                                classes={classes}
                                shopId={shopId}
                              />
                              <CollectionProducts
                                shopId={shopId}
                                languages={languages}
                                defaultLanguage={defaultLanguage}
                                updateCollection={updateCollection}
                                collectionId={collectionId}
                                collection={collection}
                                classes={classes}
                              />
                              <CollectionProductPackages
                                shopId={shopId}
                                languages={languages}
                                defaultLanguage={defaultLanguage}
                                updateCollection={updateCollection}
                                collectionId={collectionId}
                                collection={collection}
                                classes={classes}
                              />
                              <CollectionChilds
                                languages={languages}
                                defaultLanguage={defaultLanguage}
                                updateCollection={updateCollection}
                                collectionId={collectionId}
                                collection={collection}
                                classes={classes}
                                shopId={shopId}
                              />

                              <CollectionMedia
                                languages={languages}
                                defaultLanguage={defaultLanguage}
                                field={'thumbnail'}
                                updateCollection={updateCollection}
                                collectionId={collectionId}
                                collection={collection}
                                classes={classes}
                                shopId={shopId}
                              />
                              <CollectionMedia
                                languages={languages}
                                defaultLanguage={defaultLanguage}
                                field={'mainBanner'}
                                updateCollection={updateCollection}
                                collectionId={collectionId}
                                collection={collection}
                                classes={classes}
                                shopId={shopId}
                              />
                            </Grid>
                            <hr />
                            <Grid
                              style={{
                                marginTop: '5em',
                                marginBottom: '2em'
                              }}
                              container
                            >
                              <Grid item xs={3}>
                                <h2>Danger Zone</h2>
                              </Grid>
                              <Grid item xs={9}>
                                <DeleteCollection
                                  collectionId={collectionId}
                                  shopId={shopId}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        )
                      }}
                    </Mutation>
                  )
                }}
              </Query>
            </Grid>
          </Grid>
        </Typography>
      </div>
    )
  }
}

CollectionDetail.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CollectionDetail)
