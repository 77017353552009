import React, { useContext } from 'react'
import {
  Grid,
  Paper,
  CircularProgress,
  Button,
  Typography
} from '@material-ui/core'
import ToggleEdit from './Editor/ToggleEdit'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  ADMIN_GET_CONTENT_MENU,
  ADMIN_GET_CONTENT_THEME
} from '../../../gql/admin-queries'
import { ADMIN_UPDATE_CONTENT_THEME } from '../../../gql/admin-mutations'
import EditorFooter from './Editor/EditorFooter'
import EditorHeader from './Editor/EditorHeader'
import EditorBody from './Editor/EditorBody'
import SetLanguage from './SetLanguage'
import ToggleStatus from './Editor/ToggleStatus'
import OpenInNew from '@material-ui/icons/OpenInNew'
import ResponsiveSwitch from './ResponsiveSwitch'
import ThemeContainer from './ThemeContainer'
import { ThemeContext } from './ThemeContext'
import { GET_CONTENT_MODEL } from './gql/queries'
import { UrlPrefixContext } from './LanguageContext'
interface Props {
  selected: string
  editing: boolean
  languages: any
  selectedLanguage: string
  shopId: string
  shopUrl: any
  responsiveView: any
  setResponsiveView: any
  handleSetLanguage: any
  handleSwitchEditing(): void
}

const EditorContainer: React.FunctionComponent<Props> = props => {
  const {
    selected,
    handleSwitchEditing,
    editing,
    languages,
    selectedLanguage,
    handleSetLanguage,
    responsiveView,
    setResponsiveView,
    shopId,
    shopUrl
  } = props

  if (!selected) return null

  const themeData = useContext(ThemeContext)
  const urlPrefix = useContext(UrlPrefixContext)

  const [updateContentTheme] = useMutation(ADMIN_UPDATE_CONTENT_THEME, {
    refetchQueries: [
      { query: ADMIN_GET_CONTENT_THEME, variables: { id: shopId } }
    ]
  })

  const composeQuery = () => {
    const modelQuery = useQuery(GET_CONTENT_MODEL, {
      variables: {
        id: selected
      }
    })

    const menuQuery = useQuery(ADMIN_GET_CONTENT_MENU, {
      variables: {
        id: shopId
      }
    })

    return [modelQuery, menuQuery]
  }

  const [
    { loading: modelLoading, data: modelData },
    { loading: menuLoading, data: menuData }
  ] = composeQuery()

  if (!modelData)
    return (
      <Grid container={true}>
        <ToggleEdit
          editing={editing}
          handleSwitchEditing={handleSwitchEditing}
        />
        <Grid style={{ paddingTop: 20 }} container={true}>
          <Paper
            elevation={5}
            style={{ width: '100%', height: 'calc(100vh - 123px)' }}
          ></Paper>
        </Grid>
      </Grid>
    )

  return (
    <Grid container={true}>
      <ToggleEdit editing={editing} handleSwitchEditing={handleSwitchEditing} />
      <ThemeContainer
        updateTheme={updateContentTheme}
        themeData={themeData ? themeData : null}
      />
      {editing ? (
        <SetLanguage
          languages={languages}
          selectedLanguage={selectedLanguage}
          handleSetLanguage={handleSetLanguage}
          editing={editing}
        />
      ) : null}
      <ResponsiveSwitch
        setResponsiveView={setResponsiveView}
        responsiveView={responsiveView}
      />
      <div
        style={{
          position: 'absolute',
          padding: '0px 20px',
          zIndex: 100,
          right: 20
        }}
      >
        <ToggleStatus
          shopId={shopId}
          modelId={modelData.contentModel.id}
          defaultStatus={modelData.contentModel.status}
        />
        <Button
          onClick={() => {
            try {
              window.open(
                `${shopUrl}/preview?type=pageV2&id=${selected}&language=${selectedLanguage}`
              )
            } catch (error) {
              console.error('Open preview error', error)
            }
          }}
          style={{
            margin: '0px 20px',
            color: '#b23566',
            backgroundColor: 'white',
            border: '1px solid #b23566',
            boxShadow: 'none',
            fontFamily: 'Poppins'
          }}
          color="primary"
          variant="contained"
          startIcon={<OpenInNew />}
          aria-label="Open preview"
        >
          Preview
        </Button>
      </div>
      <Grid
        style={{
          paddingTop: 20
        }}
        container={true}
        justify="center"
      >
        <Grid
          style={
            modelData.contentModel.id
              ? {
                  width: '100%',
                  height: 'calc(100vh - 161px)',
                  overflowY: 'auto',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: 2,
                  borderTopRightRadius: 2,
                  transition: 'max-width .3s, flex-basis .3s',
                  boxShadow:
                    '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
                }
              : {
                  width: '100%',
                  height: 'calc(100vh - 161px)',
                  position: 'relative',
                  top: 41,
                  overflowY: 'auto',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: 2,
                  borderTopRightRadius: 2,
                  transition: 'max-width .5s',
                  boxShadow:
                    '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
                }
          }
          item={true}
          xs={12}
        >
          {modelLoading ? (
            <Grid
              container={true}
              direction="column"
              style={{
                paddingTop: 355,
                textAlign: 'center',
                alignContent: 'center'
              }}
              justify="center"
            >
              <CircularProgress
                style={{ position: 'relative', left: 25, margin: 25 }}
                size={80}
              />
              <Typography variant="h5" style={{ margin: 25 }}>
                Loading Page...
              </Typography>
            </Grid>
          ) : menuData && modelData && modelData.contentModel.id ? (
            <Paper
              style={{
                position: 'relative',
                margin: '0 auto',
                width: responsiveView.mode === 'mobile' ? '380px' : '100%'
              }}
            >
              <EditorHeader data={menuData ? menuData : {}} />
              <div style={{ minHeight: 'calc(100vh - 306px)' }}>
                <EditorBody
                  urlPrefix={urlPrefix}
                  editing={editing}
                  data={modelData ? modelData : {}}
                  modelId={modelData ? modelData.contentModel.id : ''}
                  view={responsiveView}
                />
              </div>
              <EditorFooter
                shop={menuData?.shop || {}}
                data={menuData ? menuData?.shop?.contentSettings?.menus : []}
              />
            </Paper>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditorContainer
