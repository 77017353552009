import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core/Button';
import { navigate } from '@reach/router';
import _get from 'lodash/get';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import _remove from 'lodash/remove';
import { Mutation } from 'react-apollo';
import { CONTENT_DELETE_PAGE } from '../../gql/admin-mutations';
import { GET_PAGES } from '../../gql/admin-queries';
import { normalizeTextTitle } from './utils';

const styles = theme => ({});

class PageSettings extends React.Component {
  state = {
    anchorEl: null
  };

  handleMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, pageId, pages, pageArray, Template, shopId } = this.props;

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    let PageName;
    PageName = normalizeTextTitle(pages.node.name);

    return (
      <div>
        <IconButton
          data-cy={'Options-btn-' + PageName}
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenuClick}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleMenuClose}
        >
          <MenuItem
            data-cy={'Options-Page-settings-' + PageName}
            onClick={() => {
              navigate(
                '/admin/' + shopId + '/pagebuilder/pages/' + pageId + '/meta'
              );
            }}
          >
            Page settings
          </MenuItem>
          <Mutation
            mutation={CONTENT_DELETE_PAGE}
            variables={{ id: pages.node.id }}
            update={(cache, { data: { deleteContentPage } }) => {
              if (deleteContentPage) {
                const { shop } = cache.readQuery({
                  query: GET_PAGES,
                  variables: { id: shopId }
                });

                const newData = shop;
                newData.pages.edges = newData.pages.edges.filter(
                  o => o.node.id !== pages.node.id
                );

                cache.writeQuery({
                  query: GET_PAGES,
                  variables: { id: shopId },
                  data: {
                    shop: newData
                  }
                });
              }
            }}
            onCompleted={() => Template.setState({ update: true })}
          >
            {deleteContentPage => (
              <MenuItem
                data-cy={'Options-Delete-' + PageName}
                onClick={() => {
                  deleteContentPage();
                }}
              >
                Delete
              </MenuItem>

              // use pages.node.name for dynamic ID
            )}
          </Mutation>
        </Menu>
      </div>
    );
  }
}

PageSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(PageSettings);
