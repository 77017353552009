import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { OrganisationContext } from '../../../user/context/OrganisationContext';
import PageTagList from './PageTagList';
import PageTagDetail from './PageTagDetail';

class PageTags extends Component {
  render() {
    const { shop, shopId } = this.props;
    return this.props.tagId ? (
      <Grid container>
        <OrganisationContext.Consumer>
          {currentOrganisation => (
            <PageTagDetail
              defaultLanguage={shop.defaultLanguage}
              languages={shop.languages}
              currentOrganisation={currentOrganisation}
              shopId={shopId}
              tagId={this.props.tagId}
            />
          )}
        </OrganisationContext.Consumer>
      </Grid>
    ) : (
      <Grid container style={{ width: '100%' }}>
        <PageTagList
          defaultLanguage={shop.defaultLanguage}
          languages={shop.languages}
          shopId={shopId}
        />
      </Grid>
    );
  }
}

export default PageTags;
