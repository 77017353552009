import bg_1 from '../TEMPLATE_ASSET/BG/bg (1).jpg'
import bg_10 from '../TEMPLATE_ASSET/BG/bg (10).jpg'
import bg_11 from '../TEMPLATE_ASSET/BG/bg (11).jpg'
import bg_12 from '../TEMPLATE_ASSET/BG/bg (12).jpg'
import bg_13 from '../TEMPLATE_ASSET/BG/bg (13).jpg'
import bg_2 from '../TEMPLATE_ASSET/BG/bg (2).jpg'
import bg_3 from '../TEMPLATE_ASSET/BG/bg (3).jpg'
import bg_4 from '../TEMPLATE_ASSET/BG/bg (4).jpg'
import bg_5 from '../TEMPLATE_ASSET/BG/bg (5).jpg'
import bg_6 from '../TEMPLATE_ASSET/BG/bg (6).jpg'
import bg_7 from '../TEMPLATE_ASSET/BG/bg (7).jpg'
import bg_8 from '../TEMPLATE_ASSET/BG/bg (8).jpg'
import bg_9 from '../TEMPLATE_ASSET/BG/bg (9).jpg'
import brikl from '../TEMPLATE_ASSET/BG/brikl.png'

const BG_SET = [
  bg_1,
  bg_2,
  bg_3,
  bg_4,
  bg_5,
  bg_6,
  bg_7,
  bg_8,
  bg_9,
  bg_10,
  bg_11,
  bg_12,
  bg_13
]

export function getBackground(shopId: string) {
  if (shopId === 'vermarc') {
    const bgRandom = Math.floor(Math.random() * BG_SET.length)
    return BG_SET[bgRandom]
  } else {
    return brikl
  }
}
