import React from 'react'
import { Grid, Button } from '@material-ui/core'

interface Props {
  setNotify: any
  handleShowUpdatePanel(id: string | null, show: boolean): any
}

const UpdatePanelClose: React.FC<Props> = props => {
  const { handleShowUpdatePanel, setNotify } = props
  return (
    <Grid
      style={{ marginTop: 5, marginRight: 5 }}
      container={true}
      direction="row"
      justify="flex-end"
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setNotify(true)
          handleShowUpdatePanel(null, false)
        }}
      >
        Save
      </Button>
    </Grid>
  )
}

export default UpdatePanelClose
