import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/react-hooks';
import { ADMIN_INVITE_NEW_USER } from '../../gql/admin-mutations';
import { ADMIN_GET_ADMIN_USERS } from '../../gql/admin-queries';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const AddAdminUser = ({ handleClose, shopId }) => {
	const [email, setEmail] = useState(null)
	const [errorMsg, setErrorMsg] = useState('')

	const [inviteAdminUser, { data, loading }] = useMutation(
		ADMIN_INVITE_NEW_USER, { 
			variables: { email: email, role: 'OWNER' },
			update: (cache, data) => {
				const oldData = cache.readQuery({
					query: ADMIN_GET_ADMIN_USERS,
					variables: { id: shopId }
				})

				if(data && data.data.inviteAdminUser && data.data.inviteAdminUser.id){
					oldData.shop.adminUsers.edges.push({
						node: data.data.inviteAdminUser,
						__typename: 'ShopAdminUserEdge'
					})

					cache.writeQuery({
						query: ADMIN_GET_ADMIN_USERS,
						variables: { id: shopId },
						data: oldData
					})
				}
			}
		}
	);

	if (loading) return (
		<div style={{ padding: 50 }}>
			<CircularProgress />
		</div>
	);

	return (
		<>
			<DialogTitle id="form-dialog-title">Invite new user</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Email Address"
						type="email"
						fullWidth
						onChange={e => setEmail(e.target.value)}
					/>
					{data && data.inviteAdminUser === null ? <p style={{ color: 'red'}}>{errorMsg}</p> : null}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button 
						disabled={!email ? true : false}
						onClick={async () => {
							const newUser = await inviteAdminUser();
							if(newUser && newUser.data.inviteAdminUser && newUser.data.inviteAdminUser.id){
							  handleClose();
							}else{
							 setErrorMsg('This user already exists!')
							 setEmail(null)
							}
						}} 
						color="primary"
					>
						Invite
					</Button>
				</DialogActions>
			</>
	)
}

export default AddAdminUser