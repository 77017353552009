import React, { Component } from 'react'
import {
  Grid,
  Typography,
  Button,
  Paper,
  Select,
  InputLabel,
  MenuItem
} from '@material-ui/core'
import _sortBy from 'lodash/sortBy'
import TextField from '../../../common/TextField'
import Input from '../../../common/Input'
import _get from 'lodash/get'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import {
  ADMIN_SEARCH_ORDER,
  ADMIN_SHOP_SALESCHANNELS
} from '../../gql/admin-queries'
import { Query } from 'react-apollo'
import apolloClient from '../../gql/admin-apollo-config'
import { countries as countryList } from '../../../../helper/countryList'
import moment from 'moment'
import { MuiSelect } from '../../AdminTheme'
import { MultipleSelect } from 'react-select-material-ui'
const suggestions = _sortBy(countryList, ['name']).map(c => c.name)

class AdminSearchOrders extends Component {
  state = {
    q: '',
    countryCode: null,
    loading: false,
    status: null,
    searchOrders: [],
    salesChannelIds: []
  }

  render() {
    const {
      defaultLanguage,
      onResult,
      onSelect,
      excludeIds,
      classes,
      text,
      label,
      shopId,
      width = 'calc(100% - 16px)'
    } = this.props
    const { q, loading } = this.state

    return (
      <Grid
        container
        alignItems="flex-start"
        spacing={1}
        style={{ position: 'relative' }}
      >
        <Query query={ADMIN_SHOP_SALESCHANNELS} variables={{ id: shopId }}>
          {({ data, loading }) => {
            if (loading) return null
            const myBrikl = {
              node: {
                id: 'MYBRIKL',
                title: {
                  id: 'MYBRIKLTITLE',
                  text: [
                    {
                      langCode: defaultLanguage,
                      content: 'MYBRIKL'
                    }
                  ]
                }
              }
            }
            let salesChannels = [myBrikl]
            try {
              salesChannels = [myBrikl, ...data.shop.salesChannels.edges]
            } catch (error) {}
            let salesChannelOptions = []
            try {
              salesChannels.forEach(salesChannel => {
                if (
                  salesChannel &&
                  salesChannel.node &&
                  salesChannel.node.title.text &&
                  getTextFromLanguage(
                    salesChannel.node.title.text,
                    defaultLanguage
                  )
                ) {
                  salesChannelOptions.push(
                    getTextFromLanguage(
                      salesChannel.node.title.text,
                      defaultLanguage
                    )
                  )
                }
              })
            } catch (error) {}

            return (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={2}>
                    <InputLabel shrink>Order no/email</InputLabel>
                    <Input
                      style={{ background: '#fff', marginTop: 0 }}
                      label={label}
                      placeholder={text || 'Type an order number or email'}
                      value={q}
                      background="#fff"
                      onChange={async e => {
                        this.setState({
                          q: e.target.value
                        })
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel shrink>Status</InputLabel>
                    <Select
                      style={{
                        marginTop: 0
                      }}
                      value={this.state.status ? this.state.status : 'ALL'}
                      onChange={async e => {
                        this.setState({
                          status: e.target.value
                        })
                      }}
                    >
                      <option
                        style={{
                          fontFamily: 'Roboto',
                          cursor: 'pointer',
                          lineHeight: '18px',
                          fontSize: '14px'
                        }}
                        value={'ALL'}
                      >
                        ALL
                      </option>
                      <option
                        style={{
                          fontFamily: 'Roboto',
                          cursor: 'pointer',
                          lineHeight: '18px',
                          fontSize: '14px'
                        }}
                        value={'SUBMITTED'}
                      >
                        SUBMITTED
                      </option>
                      <option
                        style={{
                          fontFamily: 'Roboto',
                          cursor: 'pointer',
                          lineHeight: '18px',
                          fontSize: '14px'
                        }}
                        value={'SHIPPED'}
                      >
                        SHIPPED
                      </option>
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel shrink>Shipping country</InputLabel>
                    <div
                      style={{
                        borderRadius: '5px',
                        border: '1px solid #ced4da',
                        padding: '1px 4px 1px 8px'
                      }}
                    >
                      <MuiSelect
                        SelectProps={{}}
                        style={{
                          background: 'white',
                          borderRadius: '2px'
                        }}
                        value={
                          this.state.countryCode &&
                          countryList.find(
                            c => c.code === this.state.countryCode
                          )
                            ? countryList.find(
                                c => c.code === this.state.countryCode
                              ).name
                            : null
                        }
                        options={suggestions}
                        onChange={selectedValue => {
                          const value = countryList.find(
                            c => c.name === selectedValue
                          ).code
                          this.setState({
                            countryCode: value
                          })
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel shrink>Saleschannel</InputLabel>
                    <div
                      style={{
                        borderRadius: '5px',
                        border: '1px solid #ced4da',
                        padding: '1px 4px 1px 8px'
                      }}
                    >
                      <MultipleSelect
                        values={this.state.salesChannelIds}
                        options={salesChannelOptions}
                        onChange={(values, valueOjects) => {
                          this.setState({
                            salesChannelIds: [
                              ...values.map(x => {
                                return salesChannels.find(
                                  y =>
                                    y &&
                                    x ===
                                      getTextFromLanguage(
                                        y.node.title.text,
                                        defaultLanguage
                                      )
                                )
                                  ? getTextFromLanguage(
                                      salesChannels.find(
                                        y =>
                                          y &&
                                          x ===
                                            getTextFromLanguage(
                                              y.node.title.text,
                                              defaultLanguage
                                            )
                                      ).node.title.text,
                                      defaultLanguage
                                    )
                                  : null
                              })
                            ]
                          })
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      style={{
                        marginRight: '1em'
                      }}
                      onClick={async () => {
                        try {
                          this.setState({
                            loading: true
                          })
                          const result = await apolloClient.query({
                            query: ADMIN_SEARCH_ORDER,
                            variables: {
                              salesChannelIds: this.state.salesChannelIds.map(
                                x => {
                                  console.log('x', x)
                                  return salesChannels.find(
                                    y =>
                                      x ===
                                      getTextFromLanguage(
                                        y.node.title.text,
                                        defaultLanguage
                                      )
                                  )
                                    ? salesChannels.find(
                                        y =>
                                          x ===
                                          getTextFromLanguage(
                                            y.node.title.text,
                                            defaultLanguage
                                          )
                                      ).node.id
                                    : null
                                }
                              ),
                              countryCode:
                                this.state.countryCode !== ''
                                  ? this.state.countryCode
                                  : null,
                              no: this.state.q !== '' ? this.state.q : null,
                              status:
                                this.state.status !== 'ALL' &&
                                this.state.status !== ''
                                  ? this.state.status
                                  : null
                            }
                            // fetchPolicy: 'network-only'
                          })
                          onResult(
                            result.data.adminSearchOrders.map(x => {
                              return { node: x }
                            })
                          )
                          this.setState({
                            loading: false
                            // searchOrders: result.data.adminSearchOrders
                          })
                        } catch (error) {
                          console.error('error', error)
                          this.setState({
                            loading: false
                          })
                        }
                      }}
                    >
                      {this.state.loading ? 'Searching...' : 'Search'}
                    </Button>

                    <Button
                      variant={'outlined'}
                      onClick={() => {
                        this.setState({
                          countryCode: null,
                          no: null,
                          status: null,
                          searchOrders: [],
                          salesChannelIds: []
                        })
                        onResult(null)
                      }}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )
          }}
        </Query>
      </Grid>
    )
  }
}

export default AdminSearchOrders
