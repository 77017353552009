import React, { Component } from 'react';
import { Grid, Typography, Button, Paper } from '@material-ui/core';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Input from '../../../common/Input';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { ADMIN_SEARCH_PRODUCT } from '../../gql/admin-queries';
import { Query } from 'react-apollo';
import apolloClient from '../../gql/admin-apollo-config';
import Imgix from 'react-imgix';
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../../brikl-config';
const uuidv4 = require('uuid/v4');
const omitDeep = require('omit-deep');

class AdminSearchProduct extends Component {
  state = {
    q: '',
    searchProducts: []
  };

  render() {
    const {
      defaultLanguage,
      onSelect,
      excludeIds,
      classes,
      text,
      label,
      width = 'calc(100% - 16px)'
    } = this.props;
    const { q, searchProducts, loading } = this.state;
    var searchResult = [];
    try {
      searchProducts.forEach(p => {
        if ((p && !excludeIds) || (excludeIds && !excludeIds.includes(p.id))) {
          let title = '';
          try {
            title = getTextFromLanguage(
              _get(p, 'title.text', []),
              defaultLanguage
            )
              ? getTextFromLanguage(_get(p, 'title.text', []), defaultLanguage)
              : getTextFromLanguage(_get(p, 'title.text', []), 'fr_FR')
              ? getTextFromLanguage(_get(p, 'title.text', []), 'fr_FR')
              : getTextFromLanguage(_get(p, 'title.text', []), 'nl_NL')
              ? getTextFromLanguage(_get(p, 'title.text', []), 'nl_NL')
              : null;
          } catch (error) {}
          searchResult.push(
            <Grid item xs={12} onClick={() => onSelect(p)} key={'ps-' + p.id}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start'
                }}
              >
                {p.media &&
                p.media.find(image => image && image.isThumbnail === true) ? (
                  <Imgix
                    height={40}
                    width={40}
                    htmlAttributes={{
                      loading: 'lazy',
                      alt: '',
                      style: {
                        width: '40px',
                        height: '100%',
                        objectFit: 'contain',
                        marginRight: '1em'
                      }
                    }}
                    src={p.media
                      .find(image => image && image.isThumbnail === true)
                      .image.replace(MEDIA_CDN_URL, IMGIX_URL)}
                    imgixParams={{
                      auto: 'compress'
                    }}
                  />
                ) : null}
                {title} ({p.no})
              </Button>
            </Grid>
          );
        }
      });
    } catch (error) {}

    return (
      <Grid
        container
        alignItems="flex-start"
        spacing={1}
        style={{ position: 'relative' }}
        onMouseLeave={searchResult === null}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Input
                style={{ background: '#fff' }}
                label={label}
                placeholder={text || 'Enter product name'}
                value={q}
                background="#fff"
                onChange={async e => {
                  this.setState({
                    q: e.target.value
                  });
                  try {
                    if (e.target.value.length > 3) {
                      this.setState({
                        loading: true
                      });
                      const result = await apolloClient.query({
                        query: ADMIN_SEARCH_PRODUCT,
                        variables: {
                          name: e.target.value
                        }
                      });
                      console.log('result', result);
                      this.setState({
                        loading: false,
                        searchProducts: result.data.adminSearchProducts
                      });
                    } else {
                      this.setState({
                        loading: false,
                        searchProducts: []
                      });
                    }
                  } catch (error) {
                    console.error(e);
                    this.setState({
                      loading: false
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {searchResult && searchResult.length === 0 ? null : (
          <div>
            {loading ? (
              'Searching...'
            ) : (
              <Paper
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: '50%',
                  maxHeight: 300,
                  overflowY: 'auto',
                  marginTop: 8,
                  width: width,
                  zIndex: 400,
                  transform: 'translate(-50%, 0px)'
                }}
              >
                <Grid container spacing={2} style={{ padding: 24 }}>
                  {searchResult && searchResult.length > 0
                    ? searchResult
                    : q
                    ? 'No results'
                    : ''}
                </Grid>
              </Paper>
            )}
          </div>
        )}
      </Grid>
    );
  }
}

export default AdminSearchProduct;
