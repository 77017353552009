import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    background: '#E5E5E5',
    height: 'calc(100vh - 50px)',
    padding: '25px 15px',
    position: 'relative'
  },
  input: {
    background: '#f4f4f4 !important',
    margin: '16px 0px !important',
    width: '300px',
    padding: '0px !important'
  },
  button: {
    width: '300px',
    minHeight: '45px',
    margin: '24px 0px !important'
  },
  textInput: {
    padding: '13px 24px'
  },
  forgotPassword: {
    position: 'absolute',
    bottom: '-4px',
    right: 0,
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    ':hover': {
      color: '#ffffff !important'
    }
  },
  signup: {
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    ':hover': {
      color: '#ffffff !important'
    }
  }
});

class AdminTOTP extends React.Component {
  static defaultProps = {
    authData: {},
    authState: 'signIn'
    // onAuthStateChange: (next, data) => { console.log(`SignIn:onAuthStateChange(${next}, ${JSON.stringify(data, null, 2)})`); }
  };

  constructor(props) {
    super(props);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.state = {
      authData: this.props.authData,
      authState: this.props.authState,
      modalShowing: false,
      loading: false,
      error: null,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      user: null
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.root}
      >
        {this.state.error ? (
          <div>
            <div>{this.state.error}</div>
          </div>
        ) : null}
        TBD
      </Grid>
    );
  }
}

AdminTOTP.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminTOTP);
