import React, { Component } from 'react';

export default class TouchRotateIcon extends React.Component {
  render() {
    const {
      width = 80,
      height = 80,
      background,
      padding,
      fill5 = '#c4c4c4'
    } = this.props;
    return (
      <div style={{ width: width, height: height, padding: padding }}>
        <style
          dangerouslySetInnerHTML={{
            __html: `
                        .touchRotate-1,.touchRotate-2,.touchRotate-4{fill:none;}.touchRotate-1{stroke:#4d4d4d;}.touchRotate-1,.touchRotate-2,.touchRotate-4,.touchRotate-5{stroke-miterlimit:10;}.touchRotate-1,.touchRotate-2,.touchRotate-5{stroke-width:2px;}.touchRotate-2,.touchRotate-5{stroke:#000;}.touchRotate-3{fill:#939393;}.touchRotate-4{stroke:#939393;stroke-linecap:round;stroke-width:3px;}.touchRotate-5{fill:#fff;}
        `
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 75.21 128.65"
          style={{ width: '100%', height: '100%' }}
        >
          <defs />
          <title>Asset 6</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <circle class="touchRotate-1" cx="22.86" cy="22.48" r="14.7" />
              <circle class="touchRotate-2" cx="23.3" cy="22.04" r="7.35" />
              <path
                class="touchRotate-3"
                d="M39.36,12.67a18.77,18.77,0,0,0-2.55-3.89,1.37,1.37,0,0,1,2-1.82A21.56,21.56,0,0,1,42,11.26h0a1.5,1.5,0,0,1-2.62,1.47A.25.25,0,0,0,39.36,12.67Z"
              />
              <path
                class="touchRotate-3"
                d="M34.13,6.17a19.78,19.78,0,0,0-4-2.49,1.14,1.14,0,0,1,.91-2.08,22,22,0,0,1,4.62,2.56,1.26,1.26,0,1,1-1.48,2.05Z"
              />
              <path
                class="touchRotate-3"
                d="M26.58,2.41a20.29,20.29,0,0,0-4.73-.59.9.9,0,0,1,0-1.79A21.21,21.21,0,0,1,27,.4a1,1,0,1,1-.39,2Z"
              />
              <path
                class="touchRotate-3"
                d="M18,2.17a20.27,20.27,0,0,0-4.59,1.46.66.66,0,0,1-.88-.32.67.67,0,0,1,.3-.87A21.53,21.53,0,0,1,17.7.62a.81.81,0,0,1,1,.58.81.81,0,0,1-.58,1Z"
              />
              <path
                class="touchRotate-3"
                d="M10.09,5.58a20.79,20.79,0,0,0-3.6,3.29.43.43,0,0,1-.6.05.43.43,0,0,1-.06-.59A21.62,21.62,0,0,1,9.41,4.69a.57.57,0,0,1,.79.09.56.56,0,0,1-.09.78Z"
              />
              <path
                class="touchRotate-3"
                d="M4.24,12.08a20.33,20.33,0,0,0-1.91,4.54.19.19,0,0,1-.24.14A.19.19,0,0,1,2,16.53a20.75,20.75,0,0,1,1.71-4.75.32.32,0,0,1,.43-.15.33.33,0,0,1,.14.44Z"
              />
              <path
                class="touchRotate-3"
                d="M1.62,20.53a22.16,22.16,0,0,0,0,3.36,21,21,0,0,1-.16-3.37.09.09,0,0,1,.09-.09.11.11,0,0,1,.08.09Z"
              />
              <polyline
                class="touchRotate-4"
                points="44.1 8.14 42.86 15.6 36.29 13.13"
              />
              <path
                class="touchRotate-3"
                d="M6.24,32.14A19.12,19.12,0,0,0,8.79,36a1.37,1.37,0,0,1-2,1.83,22.26,22.26,0,0,1-3.16-4.3h0A1.5,1.5,0,0,1,6.2,32.07Z"
              />
              <path
                class="touchRotate-3"
                d="M11.47,38.64a19.78,19.78,0,0,0,4,2.49,1.14,1.14,0,0,1-.91,2.08,21.67,21.67,0,0,1-4.61-2.56,1.26,1.26,0,0,1,1.47-2Z"
              />
              <path
                class="touchRotate-3"
                d="M19,42.4a20.14,20.14,0,0,0,4.72.59.9.9,0,0,1,0,1.79,21.75,21.75,0,0,1-5.21-.37,1,1,0,0,1,.39-2Z"
              />
              <path
                class="touchRotate-3"
                d="M27.57,42.63a19.6,19.6,0,0,0,4.59-1.45.65.65,0,0,1,.88.32.67.67,0,0,1-.29.87,21.9,21.9,0,0,1-4.84,1.82.8.8,0,0,1-.38-1.55Z"
              />
              <path
                class="touchRotate-3"
                d="M35.52,39.23a20.37,20.37,0,0,0,3.59-3.29.43.43,0,0,1,.66.54,21.62,21.62,0,0,1-3.58,3.64.56.56,0,0,1-.78-.09.55.55,0,0,1,.08-.78Z"
              />
              <path
                class="touchRotate-3"
                d="M41.37,32.73a20.65,20.65,0,0,0,1.9-4.55.2.2,0,0,1,.24-.13.2.2,0,0,1,.14.23A21.23,21.23,0,0,1,41.94,33a.33.33,0,0,1-.44.15.33.33,0,0,1-.14-.44Z"
              />
              <path
                class="touchRotate-3"
                d="M44,24.28a19.84,19.84,0,0,0,0-3.36,21,21,0,0,1,.17,3.37.08.08,0,0,1-.09.08.09.09,0,0,1-.09-.09Z"
              />
              <polyline
                class="touchRotate-4"
                points="1.5 36.66 2.74 29.21 9.31 31.68"
              />
              <path
                class="touchRotate-5"
                d="M74.21,72.51a6.46,6.46,0,0,0-12.3-2.22h-.46l-.2-1.62A7.27,7.27,0,0,0,46.8,70.29h-.34l-.14-3.46a7.28,7.28,0,0,0-14.54.59l.12,2.87h-.69V30.05A7.09,7.09,0,0,0,24.12,23h-.36a7.09,7.09,0,0,0-7.1,7.09l.2,69.1a28.5,28.5,0,0,0,57,0Z"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
