import React, { useState } from 'react'
import Button from '@material-ui/core/Button'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import _get from 'lodash/get'
import moment from 'moment'
const OrderList = ({
  loading,
  error,
  classes,
  shopId,
  shopOrders,
  fetchMore,
  endCursor
}) => {
  const [loadingMore, setLoadingMore] = useState(false)
  return (
    <Paper style={{ overflowX: 'auto', width: '100%' }}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Order No</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Saleschannel</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Payment status</TableCell>
            <TableCell>Ship To</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading || error ? (
            <TableRow key={'order-loading'}>
              <TableCell component="th" scope="row">
                {error ? 'Error' : 'Loading...'}
              </TableCell>
              <TableCell>{}</TableCell>
              <TableCell>{}</TableCell>
              <TableCell>{}</TableCell>
              <TableCell>{}</TableCell>
              <TableCell>{}</TableCell>
              <TableCell>{}</TableCell>
              <TableCell>{}</TableCell>
              <TableCell>{}</TableCell>
            </TableRow>
          ) : (
            shopOrders.map(row => {
              const order = row.node
              let salesChannelTitles = ''
              try {
                order.salesChannelTitles.forEach(e => {
                  if (e) {
                    salesChannelTitles = e + ' ' + salesChannelTitles
                  }
                })
              } catch (error) {}
              return (
                <TableRow
                  key={order.id}
                  hover
                  style={{
                    cursor: 'pointer'
                  }}
                  component="a"
                  onClick={() =>
                    window.open(
                      '/admin/' + shopId + '/orders/' + order.id,
                      '_self'
                    )
                  }
                >
                  <TableCell
                    style={{
                      maxWidth: 100,
                      cursor: 'pointer',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      padding: '4px 16px'
                    }}
                    component="th"
                    scope="row"
                  >
                    {order.no || order.id}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: 100,
                      cursor: 'pointer',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      padding: '4px 16px'
                    }}
                  >
                    {order.shippingAddress?.firstName
                      ? order.shippingAddress.firstName +
                        ' ' +
                        order.shippingAddress.lastName
                      : order.billingAddress?.firstName
                      ? order.billingAddress.firstName +
                        ' ' +
                        order.billingAddress.lastName
                      : ''}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: 100,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      padding: '4px 16px'
                    }}
                  >
                    {order.notifyEmailTo}
                  </TableCell>
                  <TableCell>
                    {_get(order, 'total.currency', '') +
                      ' ' +
                      _get(order, 'total.value', 0)
                        .toFixed(2)
                        .toLocaleString()}
                  </TableCell>
                  <TableCell>{salesChannelTitles}</TableCell>
                  <TableCell>{order.status}</TableCell>
                  <TableCell>{order.paymentStatus}</TableCell>
                  <TableCell>
                    {order.shippingAddress?.countryCode
                      ? order.shippingAddress.countryCode
                      : ''}
                  </TableCell>
                  <TableCell>
                    {moment
                      .unix(order.submittedAt / 1000)
                      .format('DD MMM YYYY HH:mm:ss')}
                  </TableCell>
                </TableRow>
              )
            })
          )}
        </TableBody>
      </Table>
      <Button
        disabled={loadingMore}
        style={{
          display: 'block',
          margin: '2em auto'
        }}
        color="primary"
        variant="outlined"
        onClick={async () => {
          setLoadingMore(true)
          try {
            await fetchMore({
              variables: {
                id: shopId,
                after: endCursor
              },
              updateQuery: (previousData, { fetchMoreResult }) => {
                if (!fetchMoreResult) return previousData

                const result = {
                  shop: {
                    ...previousData.shop,
                    orders: {
                      __typename: previousData.shop.orders.__typename,
                      edges: [
                        ...previousData.shop.orders.edges,
                        ...fetchMoreResult.shop.orders.edges
                      ],
                      pageInfo: fetchMoreResult.shop.orders.pageInfo
                    }
                  }
                }

                return result
              }
            })
            setLoadingMore(false)
          } catch (error) {
            setLoadingMore(false)
          }
        }}
      >
        {loadingMore ? '...' : 'Load More'}
      </Button>
    </Paper>
  )
}
export default OrderList
