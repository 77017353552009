import React, { Component } from 'react'
import { Query, Mutation, Subscription } from 'react-apollo'
import {
  Button,
  Checkbox,
  InputLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  withStyles
} from '@material-ui/core'
import moment from 'moment'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import uuid from 'uuid'

import { ADMIN_SHOP_ORDERS } from '../../gql/admin-queries'
import { ADMIN_CREATE_EXPORT_REPORT_REQUEST } from '../../gql/admin-mutations'
import { ADMIN_REPORT_REQUEST_SUBSCRIPTION } from '../../gql/admin-subscriptions'
import { MEDIA_CDN_URL } from '../../../../../brikl-config'
import { REPORT_FIELDS } from '../content/config'
import FieldsSelection from './FieldsSelection'
import TimeFrameSelection from './TimeFrameSelection'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    padding: 24,
    margin: '0 auto'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    marginLeft: 20,
    fontSize: 30,
    padding: 25,
    fontWeight: 'medium',
    color: '#424242'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
})

class ReportOrders extends Component {
  state = {
    includeDetail: false,
    reportRequestStatus: null,
    reportRequestId: null,
    testSubscription: false,
    selectedFields: [],
    selectedFieldsJoin: []
  }
  timeFrame = {
    from: null,
    to: null
  }

  handleFieldSelection(selectedFields: string[]) {
    this.setState({ selectedFields })
  }

  handleFieldJoinSelection(selectedFieldsJoin: string[]) {
    this.setState({ selectedFieldsJoin })
  }

  handleTimeFrameSelection(from: number, to: number): void {
    if (from && to) {
      this.timeFrame = { from, to }
    }
  }

  handleExportBtn(createExportReportRequest, fileType: 'CSV' | 'XLS') {
    const requestId = uuid.v4()
    this.setState({
      reportRequestId: requestId,
      reportRequestStatus: 'LOADING'
    })
    try {
      createExportReportRequest({
        variables: {
          input: {
            fields: this.state.selectedFields,
            fieldsJoin: this.state.includeDetail
              ? this.state.selectedFieldsJoin
              : null,
            fileType,
            id: requestId,
            reportType: this.state.includeDetail
              ? 'REPORT_ORDERS_DETAIL'
              : 'REPORT_ORDERS',
            filter: {
              fromTimestamp: this.timeFrame.from,
              untilTimestamp: this.timeFrame.to
            }
          }
        }
      })
    } catch (error) {
      this.setState({
        reportRequestStatus: null
      })
    }
  }

  render() {
    const { classes, orderId, shopId } = this.props
    return (
      <Grid className={classes.root}>
        <Grid
          container={true}
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ marginBottom: '20px' }}
        >
          <Grid item={true} xs={12}>
            {!orderId && (
              <Typography
                style={{
                  fontSize: 28,
                  fontWeight: 'medium',
                  color: '#424242',
                  marginBottom: '20px'
                }}
              >
                Report Orders
              </Typography>
            )}
          </Grid>
          <Mutation
            update={(store, { data: { createExportReportRequest } }) => {
              console.log(
                'createExportReportRequest',
                createExportReportRequest
              )
              if (createExportReportRequest.id) {
                // start subscribing here
                // this.setState({
                //   reportRequestId: createExportReportRequest.id
                // });
              }
            }}
            mutation={ADMIN_CREATE_EXPORT_REPORT_REQUEST}
          >
            {createExportReportRequest => (
              <Grid item={true} xs={12}>
                <Paper style={{ padding: 24, paddingTop: 0 }}>
                  <Grid container={true}>
                    <Grid item={true} xs={12}>
                      <FieldsSelection
                        availableFields={REPORT_FIELDS.REPORT_ORDERS}
                        selectedFields={this.state.selectedFields}
                        onSelect={sel => this.handleFieldSelection(sel)}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      {this.state.includeDetail ? (
                        <FieldsSelection
                          availableFields={REPORT_FIELDS.REPORT_ORDERS_DETAIL}
                          selectedFields={this.state.selectedFieldsJoin}
                          onSelect={sel => this.handleFieldJoinSelection(sel)}
                        />
                      ) : null}
                    </Grid>
                    <Grid item={true} xs={12}>
                      <TimeFrameSelection
                        onSelect={(from, to) =>
                          this.handleTimeFrameSelection(from, to)
                        }
                      />
                    </Grid>
                    <Grid item={true} xs={12} style={{ marginTop: 16 }}>
                      <Grid container={true} spacing={1}>
                        <Grid item={true}>
                          <Button
                            color="primary"
                            variant="outlined"
                            style={{
                              marginLeft: 'auto'
                            }}
                            onClick={() =>
                              this.handleExportBtn(
                                createExportReportRequest,
                                'CSV'
                              )
                            }
                            disabled={
                              this.state.reportRequestStatus === 'LOADING'
                            }
                          >
                            {this.state.reportRequestStatus === 'LOADING'
                              ? 'Export in progress...'
                              : 'Export to CSV'}
                          </Button>
                        </Grid>
                        <Grid item={true}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                              this.handleExportBtn(
                                createExportReportRequest,
                                'XLS'
                              )
                            }
                            disabled={
                              this.state.reportRequestStatus === 'LOADING'
                            }
                          >
                            {this.state.reportRequestStatus === 'LOADING'
                              ? 'Export in progress...'
                              : 'Export to XLS'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {'  '}
                    <Grid
                      container={true}
                      alignItems="center"
                      style={{ marginTop: 16 }}
                    >
                      <Checkbox
                        checked={this.state.includeDetail}
                        onChange={e => {
                          this.setState({
                            includeDetail: e.target.checked
                          })
                        }}
                      />
                      <InputLabel>include order items</InputLabel>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Mutation>
          {this.state.reportRequestId ? (
            <Subscription
              onSubscriptionData={({ subscriptionData }) => {
                try {
                  if (
                    subscriptionData.data.events[0] &&
                    subscriptionData.data.events[0].status === 'SUCCESS'
                  ) {
                    const link = document.createElement('a')
                    link.href = subscriptionData.data.events[0].event.filePath
                    const fileType = link.href.split('.').pop()
                    link.download = 'brikl-order-export.' + fileType
                    console.log('link', link)
                    link.click()
                    this.setState({
                      reportRequestStatus: null
                    })
                  }
                } catch (e) {
                  console.error(e)
                  alert('Your export has failed: Unknown error')
                  this.setState({
                    reportRequestStatus: null
                  })
                }
              }}
              subscription={ADMIN_REPORT_REQUEST_SUBSCRIPTION}
              variables={{
                id: this.state.reportRequestId,
                shopId: shopId
              }}
            />
          ) : null}
        </Grid>
        {!orderId && (
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Grid container={true} direction="column">
                <Paper>
                  <Query query={ADMIN_SHOP_ORDERS} variables={{ id: shopId }}>
                    {({ loading, error, data }) => {
                      let shopOrders = []
                      try {
                        shopOrders = _sortBy(
                          data.shop.orders.edges,
                          'createdAt'
                        ).reverse()
                      } catch (error) {}
                      return (
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Order No</TableCell>
                              <TableCell>Customer ID</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loading ? (
                              <TableRow key={'order-loading'}>
                                <TableCell component="th" scope="row">
                                  {'Loading...'}
                                </TableCell>
                                <TableCell>{}</TableCell>
                                <TableCell>{}</TableCell>
                                <TableCell>{}</TableCell>
                                <TableCell>{}</TableCell>
                              </TableRow>
                            ) : error ? (
                              'Error'
                            ) : (
                              shopOrders.map(row => {
                                const order = row.node
                                return (
                                  <TableRow
                                    key={order.no}
                                    // hover
                                    // component="a"
                                  >
                                    <TableCell
                                      style={{
                                        maxWidth: 100,
                                        // cursor: 'pointer',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        padding: '4px 16px'
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      {order.no}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        maxWidth: 100,
                                        // cursor: 'pointer',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        padding: '4px 16px'
                                      }}
                                    >
                                      {order.ownedByOrganisationId}
                                    </TableCell>
                                    <TableCell>
                                      {_get(order, 'total.currency', '') +
                                        ' ' +
                                        _get(
                                          order,
                                          'total.value',
                                          0
                                        ).toLocaleString()}
                                    </TableCell>
                                    <TableCell>{order.status}</TableCell>
                                    <TableCell>
                                      {moment
                                        .unix(order.submittedAt / 1000)
                                        .format('DD MMM YYYY')}
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                            )}
                          </TableBody>
                        </Table>
                      )
                    }}
                  </Query>
                </Paper>
              </Grid>
              {/* <br/>
              <Button onClick={()=>{
                    this.setState({
                      testSubscription: !this.state.testSubscription
                    })
                  }}>Test Subscription</Button>
                  <br/>
                  {
                    this.state.testSubscription === true ?
                    <Subscription
                      subscription={ADMIN_REPORT_REQUEST_SUBSCRIPTION}
                      variables={{ 
                        id: Math.random().toString(),
                        shopId: shopId
                      }}
                    >
                      {({ data, loading }) => {
                        return (
                          <>
                          <div>data:<br/>{JSON.stringify(data)}</div>
                          <div>loading:<br/>{JSON.stringify(loading)}</div>
                          </>
                        )
                      }
                      }
                    </Subscription>
                    :'No subscription'
                  } */}
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(ReportOrders)
