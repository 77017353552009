import React, { useState } from 'react'
import { Grid, Card, Typography, IconButton } from '@material-ui/core'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { Link } from 'gatsby'
import Price from '../../../../../../store/product/Price'
import Imgix from 'react-imgix'

interface Props {
  thumbnail: any
  name: any
  price: any
  slug: any
  priceData?: any
  productId?: string
  dialog?: boolean
}

const Asos: React.FC<Props> = props => {
  const { thumbnail, name, price, slug, priceData, productId, dialog } = props
  // const [fav, setFav] = useState(false)

  return (
    <div>
      <Card
        style={{
          maxWidth: '250px',
          height: '350px',
          maxHeight: '400px',
          boxShadow: 'none',
          backgroundColor: 'white',
          padding: 0,
          margin: '0 auto'
        }}
      >
        <Grid
          container={true}
          direction="column"
          justify="space-between"
          style={{ height: '100%' }}
        >
          <Grid item={true}>
            <Grid
              direction="column"
              container={true}
              justify="space-between"
              style={{ position: 'relative' }}
            >
              {/* <IconButton
                onClick={() => setFav(!fav)}
                style={{
                  position: 'absolute',
                  bottom: 8,
                  left: 170,
                  backgroundColor: 'rgba(255, 255, 255, 0.8);',
                  border: '1px solid lightgrey',
                  padding: 8
                }}
              >
                {fav ? (
                  <FavoriteIcon style={{ color: '#b23566' }} />
                ) : (
                  <FavoriteBorderIcon style={{ color: '#b23566' }} />
                )}
              </IconButton> */}
              {thumbnail ? (
                <Imgix
                  height={400}
                  htmlAttributes={{
                    alt: '',
                    style: {
                      width: '100%',
                      height: '220px',
                      objectFit: 'cover'
                    }
                  }}
                  src={thumbnail || null}
                  imgixParams={{
                    auto: 'compress'
                  }}
                />
              ) : (
                <img
                  src={
                    'https://images.unsplash.com/reserve/LJIZlzHgQ7WPSh5KVTCB_Typewriter.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60'
                  }
                  style={{ objectFit: 'cover', width: '100%', height: '220px' }}
                />
              )}
            </Grid>
          </Grid>

          <Grid item={true} style={{ height: '130px', padding: 16 }}>
            {dialog ? (
              <Grid
                direction="column"
                container={true}
                justify="flex-end"
                style={{ paddingTop: 10 }}
              >
                <Grid item={true}>
                  <Typography>
                    {name?.substring(0, 16) + '...' || `Name cannot be found!`}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography style={{ fontWeight: 'bold', paddingTop: 10 }}>
                    <Price
                      defaultCurrency={priceData?.defaultCurrency}
                      productId={productId}
                      currencyRates={priceData?.currencyRates}
                      taxSettings={priceData?.taxSettings}
                      price={price}
                      discounts={priceData?.discounts}
                    />
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Link
                style={{
                  position: 'relative',
                  color: '#000',
                  textDecoration: 'none'
                }}
                to={slug || ''}
              >
                <Grid
                  direction="column"
                  container={true}
                  justify="flex-end"
                  style={{ paddingTop: 10 }}
                >
                  <Grid item={true}>
                    <Typography>
                      {name?.substring(0, 16) + '...' ||
                        `Name cannot be found!`}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography style={{ fontWeight: 'bold', paddingTop: 10 }}>
                      <Price
                        defaultCurrency={priceData?.defaultCurrency}
                        productId={productId}
                        currencyRates={priceData?.currencyRates}
                        taxSettings={priceData?.taxSettings}
                        price={price}
                        discounts={priceData?.discounts}
                      />
                    </Typography>
                  </Grid>
                </Grid>{' '}
              </Link>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default Asos
