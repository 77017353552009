import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Checkbox from '@material-ui/core/Checkbox'
// import Dropzone from 'react-dropzone';
import { ADMIN_GET_SHOP_SETTINGS } from '../../gql/admin-queries'
import { ADMIN_UPDATE_SHOP_SETTINGS } from '../../gql/admin-mutations'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import Input from '../../../common/Input'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '../../../common/TextField'
import './ProductFileDrop.css'
import { I18n } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import TeamstoreSlugs from './TeamstoreSlugs'
import TeamstoreTitle from './TeamstoreTitle'
import TeamstoreIntroductionText from './TeamstoreIntroductionText'
import TeamstoreLogo from './TeamstoreLogo'
import TeamstoreMainbanner from './TeamstoreMainbanner'
import TeamstoreAvailableFrom from './TeamstoreAvailableFrom'
import TeamstoreAvailableUntil from './TeamstoreAvailableUntil'
import Menu from '../pagebuilder/Menu'

import _sortBy from 'lodash/sortBy'

import Select from '../../../common/Select'
import { countries } from '../../../../helper/countryList'
import uuid from 'uuid'
import { MultipleSelect } from 'react-select-material-ui'
import omitDeep from 'omit-deep'
import TeamstoreShippingZone from './TeamstoreShippingZone'

const suggestions = [
  'World',
  'EU',
  ..._sortBy(countries, ['name']).map(c => c.name)
]

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  container: {
    padding: '8px 10px'
  },
  label: {
    fontSize: '14px'
  }
})

class TeamstoreGeneral extends Component {
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }
  state = {
    showPassword: false
  }

  render() {
    const {
      shop,
      classes,
      teamstore,
      teamstoreId,
      updateTeamstore,
      defaultCurrency,
      languages,
      defaultLanguage,
      shopId,
      modules
    } = this.props
    const { showPassword } = this.state
    return (
      <Grid container className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Store details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Provide general information about the teamstore
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Input
                    onChange={e => {
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateTeamstore: {
                            ...teamstore,
                            name: e.target.value ? e.target.value : null
                          }
                        },
                        variables: {
                          id: teamstoreId,
                          input: {
                            name: e.target.value ? e.target.value : null
                          }
                        }
                      })
                    }}
                    label="Internal ID"
                    placeholder="Store name"
                    margin="normal"
                    value={teamstore.name}
                  />
                </Grid>
                <TeamstoreTitle
                  languages={languages}
                  classes={classes}
                  teamstore={teamstore}
                  teamstoreId={teamstoreId}
                  updateTeamstore={updateTeamstore}
                />
                <TeamstoreIntroductionText
                  languages={languages}
                  classes={classes}
                  teamstore={teamstore}
                  teamstoreId={teamstoreId}
                  updateTeamstore={updateTeamstore}
                />
                <TeamstoreLogo
                  classes={classes}
                  teamstore={teamstore}
                  teamstoreId={teamstoreId}
                  updateTeamstore={updateTeamstore}
                />
                <TeamstoreMainbanner
                  classes={classes}
                  teamstore={teamstore}
                  teamstoreId={teamstoreId}
                  updateTeamstore={updateTeamstore}
                />
                <TeamstoreSlugs
                  languages={languages}
                  classes={classes}
                  teamstore={teamstore}
                  teamstoreId={teamstoreId}
                  updateTeamstore={updateTeamstore}
                />
                {/* <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={1}>
                      <Typography>https://</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        placeholder="Enter shop url"
                        id="outlined-logo"
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>.brikl.com</Typography>
                    </Grid>
                  </Grid>
                </Grid> */}

                {/* <Grid item xs={12}>
                  <TextField
                    label="Introduction of the team shop"
                    placeholder="Introduction of the team shop"
                    multiline={true}
                    rowsMax="4"
                    id="outlined-secondarycolor"
                    margin="normal"
                  />
                </Grid> */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Security settings
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Set a password to access the teamstore
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Input
                    label={
                      <span>
                        Password{' '}
                        <span
                          style={{ marginLeft: 'calc(100% - 100px)' }}
                          onClick={() =>
                            this.setState({ showPassword: !showPassword })
                          }
                        >
                          {showPassword ? 'Hide' : 'Show'}
                        </span>
                      </span>
                    }
                    placeholder="Enter password"
                    id="outlined-logo"
                    margin="normal"
                    type={showPassword ? 'text' : 'password'}
                    value={teamstore.password}
                    onChange={e => {
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateTeamstore: {
                            ...teamstore,
                            password: e.target.value ? e.target.value : null
                          }
                        },
                        variables: {
                          id: teamstoreId,
                          input: {
                            password: e.target.value ? e.target.value : null
                          }
                        }
                      })
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Customer service
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Team store user will use this information to contact your
                  customer
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Input
                    label="Contact Person"
                    placeholder="Contact person full name"
                    id="outlined-maincolor"
                    margin="normal"
                    value={teamstore.contactPerson}
                    onChange={e => {
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateTeamstore: {
                            ...teamstore,
                            contactPerson: e.target.value
                              ? e.target.value
                              : null
                          }
                        },
                        variables: {
                          id: teamstoreId,
                          input: {
                            contactPerson: e.target.value
                              ? e.target.value
                              : null
                          }
                        }
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="Email"
                    placeholder="Email"
                    id="outlined-secondarycolor"
                    margin="normal"
                    value={teamstore.contactEmail}
                    onChange={e => {
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateTeamstore: {
                            ...teamstore,
                            contactEmail: e.target.value ? e.target.value : null
                          }
                        },
                        variables: {
                          id: teamstoreId,
                          input: {
                            contactEmail: e.target.value ? e.target.value : null
                          }
                        }
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="Phone number"
                    placeholder="Phone number"
                    id="outlined-secondarycolor"
                    margin="normal"
                    value={teamstore.contactPhone}
                    onChange={e => {
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateTeamstore: {
                            ...teamstore,
                            contactPhone: e.target.value ? e.target.value : null
                          }
                        },
                        variables: {
                          id: teamstoreId,
                          input: {
                            contactPhone: e.target.value ? e.target.value : null
                          }
                        }
                      })
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Branding
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Set the color of your team store
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Input
                    label="Brand color"
                    placeholder="#FFFFFF"
                    margin="normal"
                    value={teamstore.brandColor}
                    onChange={e => {
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateTeamstore: {
                            ...teamstore,
                            brandColor: e.target.value ? e.target.value : null
                          }
                        },
                        variables: {
                          id: teamstoreId,
                          input: {
                            brandColor: e.target.value ? e.target.value : null
                          }
                        }
                      })
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Integration settings
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Integrate the team store in your general store
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <RadioGroup value="" onChange="">
                    <Grid container>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={
                                teamstore.type === 'EXTERNAL_TEAMSTORE'
                                  ? true
                                  : false
                              }
                              onChange={(e, checked) => {
                                updateTeamstore({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateTeamstore: {
                                      ...teamstore,
                                      type: checked
                                        ? 'EXTERNAL_TEAMSTORE'
                                        : 'INTERNAL_TEAMSTORE'
                                    }
                                  },
                                  variables: {
                                    id: teamstoreId,
                                    input: {
                                      type: checked
                                        ? 'EXTERNAL_TEAMSTORE'
                                        : 'INTERNAL_TEAMSTORE'
                                    }
                                  }
                                })
                              }}
                            />
                          }
                          label="External Shop"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={
                                teamstore.type === 'INTERNAL_TEAMSTORE'
                                  ? true
                                  : false
                              }
                              onChange={(e, checked) => {
                                updateTeamstore({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateTeamstore: {
                                      ...teamstore,
                                      type: checked
                                        ? 'INTERNAL_TEAMSTORE'
                                        : 'EXTERNAL_TEAMSTORE'
                                    }
                                  },
                                  variables: {
                                    id: teamstoreId,
                                    input: {
                                      type: checked
                                        ? 'INTERNAL_TEAMSTORE'
                                        : 'EXTERNAL_TEAMSTORE'
                                    }
                                  }
                                })
                              }}
                            />
                          }
                          label="Internal Shop"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    ** External stores are not integrated in your general store.
                    Items from the external store cannot be combined in the same
                    cart with items from the general store.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Privacy settings
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Keep your team store private or make it public
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <RadioGroup value="" onChange="">
                    <Grid container>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={
                                teamstore.privacyType === 'PRIVATE'
                                  ? true
                                  : false
                              }
                              onChange={(e, checked) => {
                                updateTeamstore({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateTeamstore: {
                                      ...teamstore,
                                      privacyType: checked
                                        ? 'PRIVATE'
                                        : 'PUBLIC'
                                    }
                                  },
                                  variables: {
                                    id: teamstoreId,
                                    input: {
                                      privacyType: checked
                                        ? 'PRIVATE'
                                        : 'PUBLIC'
                                    }
                                  }
                                })
                              }}
                            />
                          }
                          label="Private Store"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={
                                teamstore.privacyType === 'PUBLIC'
                                  ? true
                                  : false
                              }
                              onChange={(e, checked) => {
                                updateTeamstore({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateTeamstore: {
                                      ...teamstore,
                                      privacyType: checked
                                        ? 'PUBLIC'
                                        : 'PRIVATE'
                                    }
                                  },
                                  variables: {
                                    id: teamstoreId,
                                    input: {
                                      privacyType: checked
                                        ? 'PUBLIC'
                                        : 'PRIVATE'
                                    }
                                  }
                                })
                              }}
                            />
                          }
                          label="Public Store"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Opening & closing
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Set start andend date for your team store
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <TeamstoreAvailableFrom
                  classes={classes}
                  teamstore={teamstore}
                  teamstoreId={teamstoreId}
                  updateTeamstore={updateTeamstore}
                />
                <TeamstoreAvailableUntil
                  classes={classes}
                  teamstore={teamstore}
                  teamstoreId={teamstoreId}
                  updateTeamstore={updateTeamstore}
                />
                {/* <Grid item xs={6}>
                  <Input
                    label="Start Time"
                    placeholder="7:00 AM"
                    id="outlined-secondarycolor"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="End Time"
                    placeholder="9:00 PM"
                    id="outlined-secondarycolor"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="Start Date"
                    placeholder="01/01/2021"
                    id="outlined-secondarycolor"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="End Date"
                    placeholder="02/01/2021"
                    id="outlined-secondarycolor"
                    margin="normal"
                  />
                </Grid> */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Has out of stock?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  If inventory is below 1 it will show product or product
                  variant as out of stock
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Checkbox
                    margin="normal"
                    checked={teamstore.hasOutOfStock !== true ? false : true}
                    onChange={e => {
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateTeamstore: {
                            ...teamstore,
                            hasOutOfStock: e.target.checked
                          }
                        },
                        variables: {
                          id: teamstoreId,
                          input: {
                            hasOutOfStock: e.target.checked
                          }
                        }
                      })
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Shipping zones
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Set shipping zones for teamstores - on mixed carts the highest
                  shipping cost is applied i.e. if teamstore shipping cost is
                  higher it is applied instead of the main shop shipping cost
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      let newShippingZone = {
                        __typename: 'ShippingZone',
                        id: uuid.v4(),
                        name: 'Zone name',
                        applyToAllCountries: false,
                        countryCodes: [],
                        regionCodes: [],
                        shippingRates: [
                          {
                            __typename: 'ShippingRate',
                            id: uuid.v4(),
                            priority: 0,
                            type: 'PRICE',
                            minimumTotal: {
                              __typename: 'Price',
                              value: 0,
                              currency: defaultCurrency
                            },
                            minimumWeight: 0,
                            weightUnit: null,
                            rate: {
                              __typename: 'Price',
                              value: 0,
                              currency: defaultCurrency
                            }
                          }
                        ]
                      }
                      const shippingZoneCopy = _cloneDeep(newShippingZone)
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateTeamstore: {
                            ...teamstore,
                            shippingSettings: teamstore.shippingSettings
                              ? {
                                  ...teamstore.shippingSettings,
                                  __typename: 'ShopShippingSettings',
                                  shippingZones: teamstore.shippingSettings
                                    .shippingZones
                                    ? [
                                        ...teamstore.shippingSettings
                                          .shippingZones,
                                        newShippingZone
                                      ]
                                    : [newShippingZone]
                                }
                              : {
                                  shippingZones: [newShippingZone],
                                  __typename: 'ShopShippingSettings'
                                }
                          }
                        },
                        variables: {
                          input: {
                            shippingSettings: {
                              shippingZones: teamstore.shippingSettings
                                ? teamstore.shippingSettings.shippingZones
                                  ? [
                                      ..._cloneDeep(
                                        teamstore.shippingSettings.shippingZones
                                      ).map(tempShippingZone => {
                                        return omitDeep(tempShippingZone, [
                                          '__typename'
                                        ])
                                      }),
                                      omitDeep(shippingZoneCopy, ['__typename'])
                                    ]
                                  : [omitDeep(shippingZoneCopy, ['__typename'])]
                                : [omitDeep(shippingZoneCopy, ['__typename'])]
                            }
                          }
                        }
                      })
                    }}
                    variant="text"
                    color="primary"
                    style={{ fontWeight: 400 }}
                  >
                    Add shipping zone
                  </Button>
                  <>
                    {teamstore &&
                    teamstore.shippingSettings &&
                    teamstore.shippingSettings.shippingZones
                      ? teamstore.shippingSettings.shippingZones.map(
                          (shippingZone, index) => {
                            return (
                              <div
                                style={{
                                  border: '1px solid #eee',
                                  padding: '1.5em',
                                  margin: '1em 0'
                                }}
                                key={'sz' + index}
                              >
                                <TeamstoreShippingZone
                                  countries={countries}
                                  defaultCurrency={defaultCurrency}
                                  key={shippingZone.id}
                                  shippingZone={shippingZone}
                                  updateTeamstore={updateTeamstore}
                                  teamstore={teamstore}
                                  suggestions={suggestions}
                                />
                              </div>
                            )
                          }
                        )
                      : 'No shipping zones yet'}
                  </>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Purchase Limit
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  The maximum quantity a single customer can purchase
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Input
                    label="Number of items"
                    placeholder="50, 100 or perhaps 1000"
                    margin="normal"
                    value={teamstore.purchaseLimitPerCustomer}
                    onChange={e => {
                      updateTeamstore({
                        optimisticResponse: {
                          __typename: 'Mutation',
                          updateTeamstore: {
                            ...teamstore,
                            purchaseLimitPerCustomer: e.target.value
                              ? parseFloat(e.target.value)
                              : null
                          }
                        },
                        variables: {
                          id: teamstoreId,
                          input: {
                            purchaseLimitPerCustomer: e.target.value
                              ? parseFloat(e.target.value)
                              : null
                          }
                        }
                      })
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Menu
            shop={shop}
            languages={languages}
            defaultLanguage={defaultLanguage}
            shopId={shopId}
            teamstoreId={teamstoreId}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(TeamstoreGeneral)
