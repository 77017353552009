import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import {
  Grid,
  Paper,
  Typography,
  Button,
  Input,
  InputLabel,
  Checkbox
} from '@material-ui/core'
import AddProductVariant from './AddProductVariant'
import DeleteIcon from '@material-ui/icons/Delete'
import CloudUpload from '@material-ui/icons/CloudUpload'
import Edit from '@material-ui/icons/Edit'
// import Input from '../../../common/Input';
import { Link } from 'gatsby'
import Option from './Option'
import ProductVariantRow from './ProductVariantRow'
import ProductVariantOption from './ProductVariantOption'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import { GET_PRODUCT_OPTION_VALUE } from '../../gql/admin-queries'
import { ADMIN_UPDATE_PRODUCT_OPTION_VALUE } from '../../gql/admin-mutations'
import { Mutation, Query } from 'react-apollo'
import CircularProgress from '@material-ui/core/CircularProgress'
import ProductDetailVariantOptionModifier from './ProductDetailVariantOptionModifier'
import ProductDetailVariantOptionMedia from './ProductDetailVariantOptionMedia'
import Modal from '@material-ui/core/Modal'
import client from '../../../admin/gql/admin-apollo-config'

const uuid = require('uuid')

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

const styles = theme => ({
  progress: {},
  feature_icon: {
    width: 'auto',
    float: 'left',
    margin: '.5em',
    display: 'block',
    cursor: 'pointer'
  },
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: '#FFFFFF!important',
    boxShadow: theme.shadows[5],
    padding: 0,
    outline: 'none',
    borderRadius: '6px'
  }
})

const WAIT_INTERVAL = 1500

class ProductDetailVariantOption extends Component {
  state = {
    open: false
  }

  timer = null

  updateProductOption = (
    productId,
    variantIds,
    optionValueIds,
    id,
    fieldName,
    value
  ) => {
    const { optionValues } = this.props
    clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      client.mutate({
        mutation: ADMIN_UPDATE_PRODUCT_OPTION_VALUE,
        variables: {
          productId: productId,
          optionValues: optionValues,
          input: {
            variantIds: variantIds,
            optionValueIds: optionValueIds,
            id: id,
            [fieldName]:
              fieldName === 'inventory' && value
                ? parseFloat(value)
                : fieldName === 'sku' || fieldName === 'isEnabled'
                ? value
                : 0
          }
        }
      })
    }, WAIT_INTERVAL)
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const {
      productId,
      combinationIndex,
      optionValues,
      classes,
      combinationString,
      product,
      variantIds,
      optionValueIds
    } = this.props

    return (
      <Grid
        style={{
          margin: '5px 0'
        }}
        container
        key={'cb' + combinationIndex}
      >
        <Query
          query={GET_PRODUCT_OPTION_VALUE}
          variables={{ productId: productId, optionValues: optionValues }}
        >
          {({ loading, error, data, refetch }) => {
            var productOptionValue = {}
            if (loading)
              return <CircularProgress className={classes.progress} />
            if (error) {
              console.error('GET_PRODUCT_OPTION_VALUE.error', error)
              // return <div>Error :(</div>;
            }
            try {
              if (data.productOptionValue) {
                productOptionValue = data.productOptionValue
              }
            } catch (error) {
              console.error('GET_PRODUCT_OPTION_VALUE.error', error)
            }
            if (productOptionValue.id) {
            } else {
              productOptionValue.id = uuid.v4()
            }
            return (
              <Mutation
                mutation={ADMIN_UPDATE_PRODUCT_OPTION_VALUE}
                variables={{ productId, optionValues: optionValues }}
                update={(cache, { data: { updateProductOptionValue } }) => {
                  const oldData = cache.readQuery({
                    query: GET_PRODUCT_OPTION_VALUE,
                    variables: {
                      productId: productId,
                      optionValues: optionValues
                    }
                  })
                  oldData.productOptionValue = updateProductOptionValue
                  cache.writeQuery({
                    query: GET_PRODUCT_OPTION_VALUE,
                    variables: {
                      productId: productId,
                      optionValues: optionValues
                    },
                    data: oldData
                  })
                }}
              >
                {updateProductOptionValue => (
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      style={{ paddingLeft: 16 }}
                    >
                      {combinationString}
                      <span
                        style={{
                          display: 'none'
                        }}
                      >
                        {productOptionValue.id}
                        {optionValues}
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{ paddingLeft: 4 }}
                    >
                      <Grid container justify="center" alignItems="center">
                        <Grid item xs={12}>
                          <InputLabel>Enter SKU ID</InputLabel>
                          <Input
                            placeholder="Enter SKU ID"
                            defaultValue={productOptionValue.sku}
                            onChange={e =>
                              this.updateProductOption(
                                productId,
                                variantIds,
                                optionValueIds,
                                productOptionValue.id,
                                'sku',
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      style={{ paddingLeft: 16 }}
                    >
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                      >
                        {productOptionValue.modifiers &&
                        productOptionValue.modifiers.length > 0 ? (
                          <ProductDetailVariantOptionModifier
                            variantIds={variantIds}
                            optionValueIds={optionValueIds}
                            classes={classes}
                            productOptionValue={productOptionValue}
                            updateProductOptionValue={updateProductOptionValue}
                            productPrice={product.price}
                          />
                        ) : (
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              {product.price
                                ? product.price.value +
                                  ' ' +
                                  product.price.currency
                                : ''}
                            </Grid>
                            <Grid item>
                              <Button
                                onClick={() => {
                                  const newModifier = uuid.v4()
                                  updateProductOptionValue({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateProductOptionValue: {
                                        ...productOptionValue,
                                        modifiers: [
                                          {
                                            id: newModifier,
                                            variantIds: variantIds,
                                            optionValueIds: optionValueIds,
                                            type: 'PRICE_OVERRIDE',
                                            stringModifier: null,
                                            priceChange: {
                                              value: 0,
                                              currency: product.price.currency,
                                              __typename: 'Price'
                                            },
                                            __typename: 'ProductModifier'
                                          }
                                        ]
                                      }
                                    },
                                    variables: {
                                      input: {
                                        id: productOptionValue.id,
                                        variantIds: variantIds,
                                        optionValueIds: optionValueIds,
                                        modifiers: [
                                          {
                                            id: newModifier,
                                            type: 'PRICE_OVERRIDE',
                                            stringModifier: null,
                                            priceChange: {
                                              value: 0,
                                              currency: product.price.currency
                                            }
                                          }
                                        ]
                                      }
                                    }
                                  })
                                }}
                                style={{ minWidth: 30, minHeight: 30 }}
                              >
                                <Edit />
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      style={{
                        paddingLeft: 16,
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Button onClick={this.handleOpen}>
                        <CloudUpload />
                      </Button>
                      <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.open}
                        onClose={this.handleClose}
                      >
                        <div style={getModalStyle()} className={classes.paper}>
                          <ProductDetailVariantOptionMedia
                            productId={productId}
                            variantIds={variantIds}
                            optionValueIds={optionValueIds}
                            classes={classes}
                            productOptionValue={productOptionValue}
                            updateProductOptionValue={updateProductOptionValue}
                          />
                        </div>
                      </Modal>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{ paddingLeft: 0 }}
                    >
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                      >
                        <Input
                          placeholder="Inventory"
                          defaultValue={productOptionValue.inventory}
                          onChange={e =>
                            this.updateProductOption(
                              productId,
                              variantIds,
                              optionValueIds,
                              productOptionValue.id,
                              'inventory',
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      style={{ paddingLeft: 0 }}
                    >
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                      >
                        <Checkbox
                          placeholder="Enabled"
                          checked={productOptionValue.isEnabled}
                          onChange={e =>
                            this.updateProductOption(
                              productId,
                              variantIds,
                              optionValueIds,
                              productOptionValue.id,
                              'isEnabled',
                              e.target.checked
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Mutation>
            )
          }}
        </Query>
      </Grid>
    )
  }
}
export default withStyles(styles)(ProductDetailVariantOption)
