import React, { useContext } from 'react'
import ContactForm from '../../../../../store/content/form/ContactForm'
import HubspotForm from '../../../../../store/content/HubspotForm'
import { Grid, Typography } from '@material-ui/core'
import { SelectedLanguageContext, ShopContext } from '../LanguageContext'
import { RequestQuotation } from '../../../../../beCreative/step/RequestQuotation'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_LEAD_MUTATION } from '../gql/mutations'
import { Localization } from '../../../../../../lang/localization'

interface Props {
  formType: 'QUOTE' | 'CONTACT' | 'HUBSPOT'
  formTitle: any
  portalId: any
  formId: any
  cssClass: any
  target: any
  isDialog: boolean
  salesChannelId?: string
  productDesignId?: string
  ownedByOrganisationId?: string
}

const Form: React.FC<Props> = props => {
  const {
    formType,
    formTitle,
    portalId,
    formId,
    cssClass,
    target,
    isDialog,
    salesChannelId,
    productDesignId,
    ownedByOrganisationId
  } = props

  const shopId = useContext(ShopContext)
  const langCode = useContext(SelectedLanguageContext)

  const [createLead] = useMutation(CREATE_LEAD_MUTATION)

  if (formType === 'HUBSPOT' && isDialog) {
    return (
      <Grid
        style={{ padding: 30 }}
        container={true}
        direction="row"
        justify="center"
      >
        <Typography style={{ textAlign: 'center' }} variant="h1">
          HubSpot Form
        </Typography>
      </Grid>
    )
  } else if (formType === 'QUOTE' && isDialog) {
    return (
      <Grid
        style={{ padding: 30 }}
        container={true}
        direction="row"
        justify="center"
      >
        <Typography style={{ textAlign: 'center' }} variant="h1">
          Quotation Form
        </Typography>
      </Grid>
    )
  }

  switch (formType) {
    case 'CONTACT':
      return (
        <ContactForm
          langCode={langCode}
          shopId={shopId}
          title={formTitle}
          nameLabel={Localization.get('first_name_placeholder')}
          namePlaceholder={''}
          emailLabel={Localization.get('email')}
          emailPlaceholder={''}
          contentLabel={Localization.get('message')}
          contentPlaceholder={'...'}
          hasTerms={false}
        />
      )
    case 'HUBSPOT':
      if (!portalId || !formId) {
        return (
          <Grid
            style={{ padding: 30 }}
            container={true}
            direction="row"
            justify="center"
          >
            <Typography
              style={{ textAlign: 'center', fontSize: 20 }}
              variant="body1"
            >
              Please enter a formId and a portalId to display your Hubspot form!
            </Typography>
          </Grid>
        )
      } else {
        return (
          <HubspotForm
            portalId={portalId}
            formId={formId}
            cssClass={cssClass}
            target={target}
          />
        )
      }
    case 'QUOTE':
      return (
        <RequestQuotation
          shopId={shopId}
          langCode={langCode}
          ownedByOrganisationId={ownedByOrganisationId}
          salesChannelId={salesChannelId}
          productDesignId={productDesignId}
          createLead={createLead}
        />
      )
    default:
      return null
  }
}

export default Form
