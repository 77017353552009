import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import { ADMIN_SHOP_DISCOUNTS } from '../../gql/admin-queries';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { navigate } from '@reach/router';
import AddDiscount from './AddDiscount';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242',
    flex: 1
  }
});
class DiscountList extends Component {
  render() {
    const {
      classes,
      styles,
      defaultLanguage,
      updateCoupon,
      couponId,
      coupon,
      shopId
    } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          style={{ marginTop: 24 }}
          justify="flex-start"
          alignItems="center"
        >
          <Typography component="h1" className={classes.h1}>
            Discounts
          </Typography>
          {/* SEARCH FOR COUPONS HERE */}

          <AddDiscount shopId={shopId} />
        </Grid>
        <br />
        <Query query={ADMIN_SHOP_DISCOUNTS} variables={{ id: shopId }}>
          {({ loading, error, data }) => {
            if (loading) return <CircularProgress />;
            if (error) return <div>Error :(</div>;
            let discounts = [];
            try {
              discounts = data.shop.discounts.edges;
            } catch (error) {}
            return (
              <div>
                {discounts.length > 0 ? (
                  <Paper>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Title</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Coupons?</TableCell>
                          <TableCell>Start</TableCell>
                          <TableCell>End</TableCell>
                          {couponId ? <TableCell /> : null}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {discounts.map(discounts => {
                          return (
                            <TableRow
                              key={discounts.node.id}
                              hover
                              component="div"
                              style={{ textDecoration: 'none' }}
                              onClick={() =>
                                navigate(
                                  '/admin/' +
                                    shopId +
                                    '/settings/discounts/' +
                                    discounts.node.id
                                )
                              }
                            >
                              <TableCell component="th" scope="row">
                                {getTextFromLanguage(
                                  _get(discounts.node, 'title.text', []),
                                  defaultLanguage
                                )}
                              </TableCell>
                              <TableCell>
                                {discounts.node.discountType}
                              </TableCell>
                              <TableCell>
                                {discounts.node.enabled === true
                                  ? 'Enabled'
                                  : 'Disabled'}
                              </TableCell>
                              <TableCell>
                                {discounts.node.hasCoupon === true
                                  ? 'Yes'
                                  : 'No'}
                              </TableCell>
                              <TableCell>
                                {discounts.node.availableFrom
                                  ? new Date(
                                      discounts.node.availableFrom
                                    ).toGMTString()
                                  : null}
                              </TableCell>
                              <TableCell>
                                {discounts.node.availableUntil
                                  ? new Date(
                                      discounts.node.availableUntil
                                    ).toGMTString()
                                  : null}
                              </TableCell>
                              {couponId ? (
                                <TableCell>
                                  <Button
                                    onClick={e => {
                                      e.preventDefault();
                                      updateCoupon({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateCoupon: {
                                            ...coupon,
                                            discountId: discounts.node.id
                                          }
                                        },
                                        variables: {
                                          input: {
                                            discountId: discounts.node.id
                                          }
                                        }
                                      });
                                    }}
                                  >
                                    Link to coupon
                                  </Button>
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <div>
                    No discounts yet
                    {/* <button>Create one now</button> */}
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withStyles(styles)(DiscountList);
