import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core'
import React from 'react'

const styles = () => createStyles({
})

interface Props extends WithStyles<typeof styles> {
  data: any,
  text: string,
  width: string | number | null,
  border: any,
  padding: any,
  textPadding: any
}


const DividerComponent: React.FunctionComponent<Props> = (props) => {
  const {data, text, width, border, padding, textPadding} = props
  const fontSize = data.fontSize ? parseInt(data.fontSize, 10) : 30

  return (
    <div style={{
      borderBottom: border,
      height: (fontSize / 2) + 'px',
      margin: data.margin || '0px',
      padding: padding || '0px',
      textAlign: data.textAlign || 'center',
      width: width || '100%'
    }}>
      <span style={{
        backgroundColor: data.textBackground,
        color: data.textColor || 'inherit',
        display: 'inline-block',
        fontFamily: data.fontFamily,
        fontSize: fontSize + 'px',
        padding: textPadding
      }}>{text}</span>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(DividerComponent)