import React from 'react'
import { CheckoutModuleType } from '../Types'

interface Props {
  moduleType: CheckoutModuleType
}

const CheckoutPageContainer: React.FC<Props> = props => {
  const { moduleType } = props
  switch (moduleType) {
    default:
      return null
  }
}

export default CheckoutPageContainer
