import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { ADMIN_GET_VARIANT } from '../../../gql/admin-queries'
import { ADMIN_UPDATE_VARIANT } from '../../../gql/admin-mutations'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { I18n } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import Helmet from 'react-helmet'
import Input from '../../../../common/Input'
import TextField from '../../../../common/TextField'
import Select from '../../../../common/Select'
import Checkbox from '@material-ui/core/Checkbox'
import _get from 'lodash/get'
import CircularProgress from '@material-ui/core/CircularProgress'
import VariantTitle from './VariantTitle'
import VariantOptions from './VariantOptions'
const styles = theme => ({
  progress: {},
  feature_icon: {
    width: 'auto',
    float: 'left',
    margin: '.5em',
    display: 'block',
    cursor: 'pointer'
  },
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    marginTop: '24px !important',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  },
  rootCheckbox: {
    padding: '8px',
    color: '#B23566',
    '&$checked': {
      color: '#B23566'
    }
  },
  checked: {},
  inputCell: {
    width: 230,
    border: 'none'
  }
})

class VariantDetail extends Component {
  state = {
    isActive: false,
    message: '',
    isBriklSuperAdmin: false,
    open: false,
    value: 'Yes'
  }

  handleChangeRadio = event => {
    this.setState({ value: event.target.value })
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  }

  render() {
    const {
      classes,
      languages,
      variantId,
      defaultLanguage,
      shopId
    } = this.props

    return (
      <div className={classes.root}>
        <Typography>
          <Grid container>
            <Grid item xs={12}>
              <Query query={ADMIN_GET_VARIANT} variables={{ id: variantId }}>
                {({ loading, error, data: { variant }, refetch }) => {
                  if (loading || !variant)
                    return <CircularProgress className={classes.progress} />
                  if (error) return <div>Error :(</div>
                  return (
                    <Mutation
                      update={(store, { data: { updateVariant } }) => {
                        this.setState({
                          isActive: true,
                          message: 'Variant updated'
                        })
                      }}
                      mutation={ADMIN_UPDATE_VARIANT}
                    >
                      {(updateVariant, { data, loading, error }) => {
                        return (
                          <div>
                            <Helmet>
                              <title>{'Variant - ' + variant.id}</title>
                            </Helmet>
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              style={{ height: 60, marginBottom: 20 }}
                            >
                              <Typography
                                data-cy="New-Variant-header"
                                style={{
                                  fontSize: 28,
                                  fontWeight: 400,
                                  color: '#424242'
                                }}
                              >
                                {'Variant details'}
                              </Typography>

                              <div style={{ flex: 1 }} />

                              <Button
                                data-cy="Variant-Back-btn"
                                style={{ marginLeft: 16 }}
                                variant="outlined"
                                color="primary"
                                href={
                                  '/admin/' + shopId + '/inventory/variants'
                                }
                              >
                                Back to variant
                              </Button>
                            </Grid>

                            {/*
***************************
Product details starts
***************************
 */}

                            <Grid container>
                              <Grid xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      data-cy="Variant-Details-header"
                                      variant="subtitle1"
                                      style={{ marginRight: 16 }}
                                    >
                                      Variant details
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="body1">
                                      Provide information on your variant
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                <Paper
                                  style={{ padding: 24, borderRadius: 15 }}
                                  elevation={0}
                                >
                                  <Grid
                                    container
                                    spacing={1}
                                    className={classes.container}
                                  >
                                    {/* <Grid item xs={12}>
                                      <Input
                                        label="Internal ID"
                                        placeholder="Enter your intern ID"
                                        value={variant.no}
                                        onChange={e =>
                                          updateVariant({
                                            optimisticResponse: {
                                              __typename: 'Mutation',
                                              updateVariant: {
                                                ...variant,
                                                no: e.target.value
                                              }
                                            },
                                            variables: {
                                              id: variantId,
                                              input: { no: e.target.value }
                                            }
                                          })
                                        }
                                      />
                                    </Grid> */}
                                  </Grid>
                                  <VariantTitle
                                    defaultLanguage={defaultLanguage}
                                    languages={languages}
                                    classes={classes}
                                    variant={variant}
                                    variantId={variantId}
                                    updateVariant={updateVariant}
                                  />
                                  <h2>Options</h2>
                                  <VariantOptions
                                    defaultLanguage={defaultLanguage}
                                    languages={languages}
                                    classes={classes}
                                    variant={variant}
                                    variantId={variantId}
                                    updateVariant={updateVariant}
                                  />
                                </Paper>
                              </Grid>
                            </Grid>
                          </div>
                        )
                      }}
                    </Mutation>
                  )
                }}
              </Query>
            </Grid>
          </Grid>
        </Typography>
      </div>
    )
  }
}

VariantDetail.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VariantDetail)
