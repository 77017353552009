import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AlignLeft from '@material-ui/icons/FormatAlignLeft';
import AlignRight from '@material-ui/icons/FormatAlignRight';
import AlignCenter from '@material-ui/icons/FormatAlignCenter';
import { Button, Typography } from '@material-ui/core';
import JustifyCenter from '../../../../img/JustifyCenter.svg';
import JustifyFlexEnd from '../../../../img/JustifyFlexEnd.svg';
import JustifyFlexStart from '../../../../img/JustifyFlexStart.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    padding: '16px 24px'
  },
  formControl: {
    margin:theme.spacing(3),
    width: '100%'
  },
  group: {
    margin: `${theme.spacing}px 0`
  },
  typo: {
    color: '#696969',
    fontSize: '15px',
    marginBottom: '8px'
  }
});

class AlignItems extends React.Component {
  render() {
    const { classes, fieldName, handleChangeField } = this.props;

    return (
      <Grid className={classes.root} direction="column" container={true}>
        <Grid container justify="space-between" data-cy="Align-Container">
          <Grid item>
            <Button
              //variant={getVariant('left')}
              data-cy={'Left-Align-' + fieldName}
              style={{ boxShadow: 'none', minWidth: 40, width: 40 }}
              color="primary"
              onClick={() => handleChangeField('flex-start', fieldName)}
            >
              <img src={JustifyFlexStart} alt="" />
            </Button>
          </Grid>
          <Grid item>
            <Button
              //variant={getVariant('center')}
              data-cy={'Center-Align-' + fieldName}
              style={{ boxShadow: 'none', minWidth: 40, width: 40 }}
              onClick={() => handleChangeField('center', fieldName)}
              color="primary"
            >
              <img src={JustifyCenter} alt="" />
            </Button>
          </Grid>
          <Grid item>
            <Button
              //variant={getVariant('right')}
              data-cy={'Right-Align-' + fieldName}
              style={{ boxShadow: 'none', minWidth: 40, width: 40 }}
              onClick={() => handleChangeField('flex-end', fieldName)}
              color="primary"
            >
              <img src={JustifyFlexEnd} alt="" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

AlignItems.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AlignItems);
