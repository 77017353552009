import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { Close } from '@material-ui/icons'
import _sortBy from 'lodash/sortBy'
import { getTextFromLanguage } from './languageHelper'
import { SHOP_DEFAULT_LANGUAGE, SHOP_LANGUAGE_WITHOUT_PREFIX } from './config'
import { removeKeys } from './removeKeys'
import _cloneDeep from 'lodash/cloneDeep'

export const getUrlDefaultPrefix = (): string => {
  if (SHOP_DEFAULT_LANGUAGE === SHOP_LANGUAGE_WITHOUT_PREFIX) {
    return ''
  } else {
    return SHOP_DEFAULT_LANGUAGE.substring(0, 2) + '/'
  }
}

const getUrl = (
  o: any,
  field: string,
  urlPrefix: string,
  langCode: string = 'en_UK'
): string | null => {
  if (o[field]) {
    const path = getTextFromLanguage(o[field], langCode)
    if (path) {
      if (path.indexOf('http://') !== -1 || path.indexOf('https://') !== -1) {
        return path
      } else {
        return '/' + urlPrefix + path
      }
    }
  }
  return null
}
export const getLinkInternalNo = (obj: any): string => {
  let internalNo = ''
  if (internalNo === '' && obj.pageId && obj.page) {
    internalNo = ''
  } else if (
    internalNo === '' &&
    obj.collectionId &&
    obj.collection &&
    obj.collection.title
  ) {
    internalNo = obj.collection.no || ''
  } else if (
    internalNo === '' &&
    obj.productId &&
    obj.product &&
    obj.product.no
  ) {
    internalNo = obj.product.no || ''
  } else if (
    internalNo === '' &&
    obj.moduleId &&
    obj.module &&
    obj.module.contentDetail
  ) {
    internalNo = ''
  }
  return internalNo !== '' ? `(${internalNo})` : ''
}
export const isEmail = (value: string): boolean => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(value).toLowerCase())
}
export const getLinkText = (
  obj: any,
  langCode: string = 'en_UK',
  editMode: boolean = false
): string => {
  let linkText = getTextFromLanguage(obj.linkTitle.text, langCode) || ''
  if (linkText === '' && obj.pageId && obj.page) {
    linkText = obj.page.name
  } else if (
    linkText === '' &&
    obj.collectionId &&
    obj.collection &&
    obj.collection.title
  ) {
    linkText = getTextFromLanguage(obj.collection.title.text, langCode) || ''
  } else if (
    linkText === '' &&
    obj.productId &&
    obj.product &&
    obj.product.title
  ) {
    linkText = getTextFromLanguage(obj.product.title.text, langCode) || ''
  } else if (
    editMode &&
    linkText === '' &&
    obj.moduleId &&
    obj.module &&
    obj.module.contentDetail
  ) {
    linkText = obj.module.label
      ? `[ ${obj.module.label} ]`
      : obj.module.contentDetail.type
  }
  return linkText
}

export const getLinkUrl = (
  obj: any,
  urlPrefix: string,
  langCode: string = 'en_UK'
): string => {
  let linkUrl = getUrl(obj, 'externalLinks', urlPrefix, langCode) || ''
  if (linkUrl === '' && obj.pageId && obj.page && obj.page.slugs) {
    linkUrl = getUrl(obj.page, 'slugs', urlPrefix, langCode) || ''
  } else if (
    linkUrl === '' &&
    obj.collectionId &&
    obj.collection &&
    obj.collection.slugs
  ) {
    linkUrl = getUrl(obj.collection, 'slugs', urlPrefix, langCode) || ''
  } else if (
    linkUrl === '' &&
    obj.productId &&
    obj.product &&
    obj.product.slugs
  ) {
    linkUrl = getUrl(obj.product, 'slugs', urlPrefix, langCode) || ''
  }
  return linkUrl
}

export const getElementsFirstLevel = (elements: any[]): any[] => {
  const elementsFirstLevel = elements.filter(o => !o.parentId)
  return _sortBy(elementsFirstLevel, 'sortOrder')
}

export const getElementsSubmenuPages = (elements: any[], id: string): any[] => {
  const filteredElements = elements.filter(
    o => o.parentId === id && !o.collectionId && !o.productId
  )
  return _sortBy(filteredElements, 'sortOrder')
}

export const getElementsSubmenuCollections = (
  elements: any[],
  id: string
): any[] => {
  const filteredElements = elements.filter(
    o => o.parentId === id && o.collectionId && !o.productId
  )
  return _sortBy(filteredElements, 'sortOrder')
}

export const getElementsSubmenuProducts = (
  elements: any[],
  id: string
): any[] => {
  const filteredElements = elements.filter(
    o => o.parentId === id && !o.collectionId && o.productId
  )
  return _sortBy(filteredElements, 'sortOrder')
}

export const getAllElementsSubmenu = (elements: any[], id: string): any[] => {
  const pages = getElementsSubmenuPages(elements, id)
  const collections = getElementsSubmenuCollections(elements, id)
  const products = getElementsSubmenuProducts(elements, id)
  const allSubItems = pages.concat(collections)
  return allSubItems.concat(products)
}

export const recalculateSorts = (elements: any[]): any[] => {
  const sortedElementsFirstLevel = getElementsFirstLevel(elements)
  const resortedElementsFirstLevel = sortedElementsFirstLevel.map(
    (item: any, index: number) => {
      item.sortOrder = index + 1
      return item
    }
  )

  const resortedSubElementsArr = []
  const resortedSubElements = []
  sortedElementsFirstLevel.forEach(item => {
    const allElementsSubmenu = recursiveGetSubItems(elements, item.id, 1)
    const sortOrderByLevel = {}
    resortedSubElementsArr.push(
      allElementsSubmenu.map((subitem: any) => {
        if (!sortOrderByLevel[subitem._level]) {
          sortOrderByLevel[subitem._level] = 0
        }
        sortOrderByLevel[subitem._level] = sortOrderByLevel[subitem._level] + 1
        subitem.sortOrder = sortOrderByLevel[subitem._level]
        return subitem
      })
    )
  })

  resortedSubElementsArr.forEach(elementArr => {
    if (elementArr && elementArr.length > 0) {
      elementArr.forEach((element: any) => {
        resortedSubElements.push(element)
      })
    }
  })

  return resortedElementsFirstLevel.concat(resortedSubElements)
}

export const recursiveGetSubItems = (
  elements: any[],
  id: string,
  level: number
): any[] => {
  let all = []
  const items = getAllElementsSubmenu(elements, id)
  if (items && items.length > 0) {
    items.forEach(item => {
      item._level = level
      all.push(item)
      const subItems = recursiveGetSubItems(elements, item.id, level + 1)
      all = all.concat(subItems)
    })
  }
  return all
}

export const getAllSubItemsByLevel = (elements: any[], id: string): any[] => {
  const all = recursiveGetSubItems(elements, id, 1)
  const results = []
  all.forEach(item => {
    if (!results[item._level]) {
      results[item._level] = []
    }
    results[item._level].push(item)
  })
  return results
}

export const prefixSubItems = (level: number): string => {
  let response = ''
  for (let i = 0; i < level; i++) {
    response += '-'
  }
  return response
}

export const getSocialLinkIcon = (icon: string) => {
  switch (icon) {
    case 'FACEBOOK':
      return faFacebookF
    case 'INSTAGRAM':
      return faInstagram
    case 'TWITTER':
      return faTwitter
    case 'YOUTUBE':
      return faYoutube
    default:
      return Close
  }
}

export const removeKeysBeforeMutation = data => {
  data = removeKeys(data, '__typename')
  data = removeKeys(data, 'collection')
  data = removeKeys(data, 'page')
  data = removeKeys(data, 'product')
  data = removeKeys(data, 'module')
  data = removeKeys(data, 'modules')
  data = removeKeys(data, 'businessAddress')
  data = removeKeys(data, '_level')
  return data
}

export const getStyleAlignmentItem = (
  alignment?: string,
  isModule: boolean = false
): any => {
  if (!alignment) {
    return {}
  }
  return {
    left:
      alignment === 'center'
        ? isModule
          ? '50%'
          : 'inherit'
        : alignment === 'left'
        ? '0px'
        : 'auto',
    position: alignment ? (isModule ? 'absolute' : 'static') : 'static',
    right: alignment === 'right' ? '5px' : 'auto',
    top: alignment ? (isModule ? '50%' : '50%') : 'auto',
    transform: isModule
      ? alignment === 'center'
        ? 'translate(-50%, -50%)'
        : 'translate(0%, -50%)'
      : 'inherit'
  }
}

export const getItemStyling = (menuStyling: any, itemStyling: any): any => {
  const newStyling = _cloneDeep(menuStyling) || {}
  for (const key in itemStyling) {
    if (itemStyling[key]) {
      newStyling[key] = itemStyling[key]
    }
  }
  return newStyling
}
