import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import _get from 'lodash/get'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import { NativeSelect, InputLabel } from '@material-ui/core'

const styles = theme => ({})

class SelectTeamstore extends React.Component {
  state = {
    isChecked: false
  }

  render() {
    const {
      classes,
      teamstores,
      Template,
      handleChange,
      selectedTeamstore,
      selectedTeamstoreId,
      langCode
    } = this.props

    var url = ''

    try {
      if (langCode === 'en_UK') {
        url = '/' + 'en/' + slug
      } else if (langCode === 'nl_NL') {
        url = '/' + 'nl/' + slug
      } else if (langCode === 'fr_FR') {
        url = '/' + 'fr/' + slug
      }
    } catch (error) {}

    return (
      <div style={{ width: '100%', padding: 10 }}>
        <NativeSelect
          style={{
            border: '1px solid #ced4da',
            borderRadius: 4
          }}
          native="true"
          defaultValue={selectedTeamstoreId}
          onChange={event => {
            const teamstore = teamstores.edges.find(
              n => n.node.id === event.target.value
            )
            const teamstoreSlug = getTextFromLanguage(
              teamstore.node.slugs,
              langCode
            )
            handleChange(event, teamstoreSlug)
          }}
        >
          {teamstores.edges.map(teamstoresNode => (
            <option key={teamstoresNode.node.id} value={teamstoresNode.node.id}>
              {getTextFromLanguage(teamstoresNode.node.title.text, langCode)}
            </option>
          ))}
        </NativeSelect>
      </div>
    )
  }
}

SelectTeamstore.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(SelectTeamstore)
