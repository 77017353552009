import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {
  Divider,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import NativeSelect from '@material-ui/core/NativeSelect'
import Slider from '@material-ui/core/Slider'
import ColorPicker from './ColorPicker'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Query } from 'react-apollo'
import {
  GET_PAGES,
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_SHOP_PRODUCT_PACKAGES,
  ADMIN_SHOP_PRODUCTS,
  ADMIN_SHOP_SALESCHANNELS
} from '../../gql/admin-queries'
import SelectCollection from './SelectCollection'
import SelectPage from './SelectPage'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import Uploader from './Uploader'
import DeleteImage from './DeleteImage'
import { MEDIA_CDN_URL } from '../../../../../brikl-config'
import AlignItems from './AlignItems'
import VerticalAlign from './VerticalAlign'
import TextField from '../../../common/TextField'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'

import {
  FONTS,
  BORDER_TYPES,
  LINK_TYPE,
  COPYRIGHT_IMAGE_HORIZONTAL_LOCATION,
  COPYRIGHT_IMAGE_VERTICAL_LOCATION,
  TEXT_ALIGN,
  IMAGE_FIT
} from './config'
import SelectProducts from './SelectProducts'
import SelectTeamstore from './SelectTeamstore'
import SelectProductPackage from './SelectProductPackage'

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase)

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  input: {
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  },
  slider: {
    padding: '22px 0px'
  }
})

class UpdateBanner extends React.Component {
  state = {
    customLink: false,
    displayColorPicker: false,
    pageDialog: false,
    urlType: this.props.fields.urlType,
    selectedProduct: this.props.fields.buttonUrl,
    selectedProductId: this.props.fields.selectedProductId
      ? this.props.fields.selectedProductId
      : '',
    selectedTeamstore: this.props.fields.buttonUrl,
    selectedTeamstoreId: this.props.fields.selectedTeamstoreId
      ? this.props.fields.selectedTeamstoreId
      : '',
    selectedProductPackage: this.props.fields.buttonUrl,
    selectedProductPackageId: this.props.fields.selectedProductPackageId
      ? this.props.fields.selectedProductPackageId
      : '',
    selectedPage: this.props.fields.buttonUrl,
    selectedPageId: this.props.fields.selectedPageId
      ? this.props.fields.selectedPageId
      : '',
    selectedCollection: this.props.fields.buttonUrl,
    selectedCollectionId: this.props.fields.selectedCollectionId
      ? this.props.fields.selectedCollectionId
      : '',
    color: {
      hex: '#ffffff',
      rgb: {
        a: 1,
        b: 250,
        g: 250,
        r: 250
      }
    }
  }

  handlePageOpen = () => {
    this.setState({ pageDialog: true })
  }

  handlePageClose = () => {
    this.setState({ pageDialog: false })
  }

  handleChangeProductPackage = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedTeamstore: null,
      selectedTeamstoreId: null,
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProductPackage: `/${slug}`,
      selectedProductPackageId: event.target.value
    })
    fields.productPackageId = event.target.value
  }

  handleChangePage = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedTeamstore: null,
      selectedTeamstoreId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedPage: `/${slug}`,
      selectedPageId: event.target.value
    })
    fields.selectedPageId = event.target.value
  }
  handleChangeCollection = (event, slug) => {
    const { fields } = this.props

    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedTeamstore: null,
      selectedTeamstoreId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedCollection: `/${slug}`,
      selectedCollectionId: event.target.value
    })
    fields.collectionId = event.target.value
  }
  handleChangeTeamstore = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedTeamstore: `/${slug}`,
      selectedTeamstoreId: event.target.value
    })

    fields.teamstoreId = event.target.value
  }
  handleChangeProduct = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedProduct: `/${slug}`,
      selectedProductId: event.target.value
    })
    fields.productId = event.target.value
  }

  handleChangeLink = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,
      handleChangeTextField,
      fields,
      Template,
      shopId,
      languages
    } = this.props

    let imgUrl = ''
    let newUrl

    if (fields && fields.imageUrl) {
      if (fields.imageUrl !== null) {
        imgUrl = fields.imageUrl.split('/')
        newUrl = imgUrl.pop()
      }
    }

    let { urlType } = this.state
    if (!urlType) {
      urlType = this.state.selectedPage
        ? 'PAGE'
        : this.state.selectedProduct
        ? 'PRODUCT'
        : this.state.selectedProductPackage
        ? 'PRODUCT_PACKAGE'
        : this.state.selectedTeamstore
        ? 'SALESCHANNEL'
        : this.state.selectedCollection
        ? 'COLLECTION'
        : null
    }

    return (
      <Grid container>
        <ExpansionPanel
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
          expanded={expanded === 'panel2'}
          onChange={handleExpandedChange('panel2')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ expanded: classes.expansionPanelExpanded }}
          >
            <Typography
              variant="subtitle1"
              className={classes.typo}
              data-cy="banner-settings"
            >
              Banner
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: classes.expansionPanelDetails }}
          >
            <Divider />
            <List>
              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-thumbnail-image-label"
                  >
                    Banner Image
                  </InputLabel>
                  <Paper elevation={0}>
                    <Uploader
                      shopId={shopId}
                      dataCy="banner-image-upload"
                      image={
                        fields.imageUrl !== null
                          ? `${MEDIA_CDN_URL}/${shopId}/media/${newUrl}`
                          : fields.imageUrl
                      }
                      isCDNField={true}
                      field="imageUrl"
                      handleChangeField={handleChangeField}
                    />
                  </Paper>

                  <DeleteImage
                    image={fields.imageUrl}
                    field="imageUrl"
                    handleDelete={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <Grid>
                <ListItem>
                  <Grid container direction="column">
                    <InputLabel
                      data-cy="banner-copyright-content-label"
                      shrink
                      className={classes.FormLabel}
                    >
                      Copyright Content
                    </InputLabel>
                    <Paper className={classes.root} elevation={0}>
                      <InputBase
                        data-cy="banner-copyright-content-input"
                        className={classes.input}
                        placeholder=""
                        value={fields.copyrightContent}
                        onChange={e =>
                          handleChangeField(
                            e.target.value ? e.target.value : null,
                            'copyrightContent'
                          )
                        }
                      />
                    </Paper>
                  </Grid>
                </ListItem>

                <ListItem>
                  <Grid container direction="column">
                    <InputLabel
                      style={{ marginBottom: 10 }}
                      shrink
                      className={classes.FormLabel}
                      data-cy="Card-Height-Label"
                    >
                      Height - {fields.height}
                    </InputLabel>
                    <Grid container={true} justify="space-between">
                      <Slider
                        className={styles.slider}
                        value={parseInt(fields.height, 10)}
                        aria-labelledby="slider-label3"
                        step={5}
                        min={0}
                        max={1000}
                        onChange={(e, value) =>
                          handleChangeField(value, 'height')
                        }
                      />
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem>
                  <Grid container direction="column">
                    <InputLabel
                      data-cy="banner-copyright-content-vertical-alignment-label"
                      shrink
                      className={classes.FormLabel}
                    >
                      Copyright Content Vertical Alignment
                    </InputLabel>
                    <Paper className={classes.root} elevation={0}>
                      <NativeSelect
                        data-cy="banner-copyright-content-vertical-alignment-select"
                        native="true"
                        className={classes.input}
                        value={fields.copyrightVerticalAlignment}
                        onChange={e =>
                          handleChangeField(
                            e.target.value ? e.target.value : null,
                            'copyrightVerticalAlignment'
                          )
                        }
                        input={
                          <SelectInput
                            name="font"
                            id="copyrightVerticalAlign"
                          />
                        }
                      >
                        {COPYRIGHT_IMAGE_VERTICAL_LOCATION.map(
                          copyrightVertical => (
                            <option
                              key={copyrightVertical}
                              value={copyrightVertical}
                            >
                              {copyrightVertical}
                            </option>
                          )
                        )}
                      </NativeSelect>
                    </Paper>
                  </Grid>
                </ListItem>

                <ListItem>
                  <Grid container direction="column">
                    <InputLabel
                      data-cy="banner-copyright-content-horizontal-alignment-label"
                      shrink
                      className={classes.FormLabel}
                    >
                      Copyright Content Horizontal Alignment
                    </InputLabel>
                    <Paper className={classes.root} elevation={0}>
                      <NativeSelect
                        data-cy="banner-copyright-content-horizontal-alignment-select"
                        native="true"
                        className={classes.input}
                        value={fields.copyrightHorizontalAlignment}
                        onChange={e =>
                          handleChangeField(
                            e.target.value ? e.target.value : null,
                            'copyrightHorizontalAlignment'
                          )
                        }
                        input={
                          <SelectInput
                            name="font"
                            id="copyrightHorizontalAlign"
                          />
                        }
                      >
                        {COPYRIGHT_IMAGE_HORIZONTAL_LOCATION.map(
                          copyrightHorizontal => (
                            <option
                              key={copyrightHorizontal}
                              value={copyrightHorizontal}
                            >
                              {copyrightHorizontal}
                            </option>
                          )
                        )}
                      </NativeSelect>
                    </Paper>
                  </Grid>
                </ListItem>

                <ListItem>
                  <Grid container direction="column">
                    <InputLabel
                      data-cy="banner-copyright-content-fontfamily-label"
                      shrink
                      className={classes.FormLabel}
                    >
                      Copyright Content FontFamily
                    </InputLabel>
                    <Paper className={classes.root} elevation={0}>
                      <NativeSelect
                        data-cy="banner-copyright-content-fontfamily-select"
                        native="true"
                        className={classes.input}
                        value={fields.copyrightFontFamily}
                        onChange={e =>
                          handleChangeField(
                            e.target.value ? e.target.value : null,
                            'copyrightFontFamily'
                          )
                        }
                        input={
                          <SelectInput name="font" id="copyrightFontFamily" />
                        }
                      >
                        {FONTS.map(fontFamily => (
                          <option key={fontFamily} value={fontFamily}>
                            {fontFamily}
                          </option>
                        ))}
                      </NativeSelect>
                    </Paper>
                  </Grid>
                </ListItem>

                <ListItem>
                  <Grid container direction="column">
                    <InputLabel
                      data-cy="banner-copyright-content-color-label"
                      shrink
                      className={classes.FormLabel}
                    >
                      Copyright Content Color
                    </InputLabel>
                    <ColorPicker
                      dataCy="banner-copyright-content-colorpicker"
                      color={fields.copyrightFontColor}
                      fieldName={'copyrightFontColor'}
                      onChange={handleChangeField}
                    />
                  </Grid>
                </ListItem>
              </Grid>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-image-object-fit-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Image object fit
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      data-cy="banner-image-object-fit-select"
                      native="true"
                      className={classes.input}
                      value={fields.objectFit}
                      onChange={e =>
                        handleChangeField(
                          e.target.value ? e.target.value : null,
                          'objectFit'
                        )
                      }
                      input={<SelectInput name="font" id="objectFit" />}
                    >
                      {IMAGE_FIT.map((fit, index) => (
                        <option key={index} value={fit}>
                          {fit}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-horizontal-align-label"
                  >
                    Horizontal Align
                  </InputLabel>
                  <AlignItems
                    fieldName={'horizontalAlign'}
                    handleChangeField={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-vertical-align-label"
                  >
                    Vertical Align
                  </InputLabel>
                  <VerticalAlign
                    fieldName={'verticalAlign'}
                    handleChangeField={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-text-align-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Text Align
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      data-cy="banner-text-align-select"
                      native="true"
                      className={classes.input}
                      value={fields.textAlign ? fields.textAlign : 'center'}
                      onChange={e =>
                        handleChangeField(e.target.value, 'textAlign')
                      }
                      input={
                        <SelectInput
                          name="textAlign"
                          id="age-customized-native-simple"
                        />
                      }
                    >
                      {TEXT_ALIGN.map(textAlign => (
                        <option key={textAlign} value={textAlign}>
                          {textAlign}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-top-bottom-padding-label"
                  >
                    Top/Bottom Padding - {fields.containerPadding1}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="banner-top-bottom-padding-slider"
                      className={styles.slider}
                      value={parseInt(fields.containerPadding1)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'containerPadding1')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-left-right-padding-label"
                  >
                    Left/Right Padding - {fields.containerPadding2}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="banner-left-right-padding-slider"
                      className={styles.slider}
                      value={parseInt(fields.containerPadding2)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={300}
                      onChange={(e, value) =>
                        handleChangeField(value, 'containerPadding2')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-title-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Title
                  </InputLabel>
                  {languages.map(language => {
                    let text = ''
                    try {
                      text =
                        getTextFromLanguage(fields.title.text, language) || ''
                    } catch (error) {}
                    return (
                      <div key={language}>
                        {language}
                        <Paper className={classes.root} elevation={0}>
                          <TextField
                            dataCy={'banner-title-' + language}
                            className={classes.input}
                            multiline={true}
                            placeholder=""
                            value={text}
                            onChange={e =>
                              handleChangeTextField(
                                e.target.value ? e.target.value : null,
                                language,
                                'title'
                              )
                            }
                          />
                        </Paper>
                      </div>
                    )
                  })}
                  <Button
                    data-cy="banner-delete-title-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      languages.map(language => {
                        let text = ''
                        try {
                          text =
                            getTextFromLanguage(fields.title.text, language) ||
                            ''
                        } catch (error) {}

                        handleChangeTextField(null, language, 'title')
                      })
                    }}
                  >
                    Delete Title
                  </Button>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-title-font-size-label"
                  >
                    Title font size - {fields.titleFontSize}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="banner-title-font-size-slider"
                      className={styles.slider}
                      value={parseInt(fields.titleFontSize)}
                      aria-labelledby="slider-label2"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'titleFontSize')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-title-font-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Title Font
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      data-cy="banner-title-font-select"
                      native="true"
                      className={classes.input}
                      value={
                        fields.titleFontFamily &&
                        fields.titleFontFamily.indexOf('Roboto') !== -1
                          ? 'Roboto'
                          : fields.titleFontFamily
                      }
                      onChange={e =>
                        handleChangeField(e.target.value, 'titleFontFamily')
                      }
                      input={
                        <SelectInput
                          name="titleFontFamily"
                          id="age-customized-native-simple"
                        />
                      }
                    >
                      {FONTS.map(titleFontFamily => (
                        <option key={titleFontFamily} value={titleFontFamily}>
                          {titleFontFamily}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-title-color-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Title Color
                  </InputLabel>
                  <ColorPicker
                    dataCy="banner-title-colorpicker"
                    color={fields.titleColor}
                    fieldName={'titleColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-title-background-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Title Background
                  </InputLabel>
                  <ColorPicker
                    datacy="banner-title-background-colorpicker"
                    color={fields.titleBackgroundColor}
                    fieldName={'titleBackgroundColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-sub-title-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Sub-title
                  </InputLabel>
                  {languages.map(language => {
                    let text = ''
                    try {
                      text =
                        getTextFromLanguage(fields.subTitle.text, language) ||
                        ''
                    } catch (error) {}
                    return (
                      <div key={language}>
                        {language}
                        <Paper className={classes.root} elevation={0}>
                          <TextField
                            dataCy={'banner-sub-title-' + language}
                            className={classes.input}
                            placeholder=""
                            value={text}
                            multiline={true}
                            onChange={e =>
                              handleChangeTextField(
                                e.target.value ? e.target.value : null,
                                language,
                                'subTitle'
                              )
                            }
                          />
                        </Paper>
                      </div>
                    )
                  })}
                  <Button
                    data-cy="banner-delete-sub-title-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      languages.map(language => {
                        let text = ''
                        try {
                          text =
                            getTextFromLanguage(
                              fields.subTitle.text,
                              language
                            ) || ''
                        } catch (error) {}

                        handleChangeTextField(null, language, 'subTitle')
                      })
                    }}
                  >
                    Delete Sub-title
                  </Button>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-sub-title-font-size-label"
                  >
                    Sub-title font size - {fields.subTitleFontSize}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="banner-sub-title-font-size-slider"
                      className={styles.slider}
                      value={parseInt(fields.subTitleFontSize)}
                      aria-labelledby="slider-label2"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'subTitleFontSize')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-sub-title-font-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Sub-title Font
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      data-cy="banner-sub-title-font-select"
                      native="true"
                      className={classes.input}
                      value={
                        fields.subTitleFontFamily &&
                        fields.subTitleFontFamily.indexOf('Roboto') !== -1
                          ? 'Roboto'
                          : fields.subTitleFontFamily
                      }
                      onChange={e =>
                        handleChangeField(e.target.value, 'subTitleFontFamily')
                      }
                      input={
                        <SelectInput name="subTitleFontFamily" id="subTitle" />
                      }
                    >
                      {FONTS.map(subTitleFontFamily => (
                        <option
                          key={subTitleFontFamily}
                          value={subTitleFontFamily}
                        >
                          {subTitleFontFamily}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-sub-title-color-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Sub-title Color
                  </InputLabel>
                  <ColorPicker
                    dataCy="banner-sub-title-colorpicker"
                    color={fields.subTitleColor}
                    fieldName={'subTitleColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-button-text-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Button text
                  </InputLabel>
                  {languages.map(language => {
                    let text = ''
                    try {
                      text =
                        getTextFromLanguage(fields.buttonText.text, language) ||
                        ''
                    } catch (error) {}
                    return (
                      <div key={language}>
                        {language}
                        <Paper className={classes.root} elevation={0}>
                          <InputBase
                            data-cy={'banner-button-text-' + language}
                            className={classes.input}
                            placeholder=""
                            value={text}
                            onChange={e =>
                              handleChangeTextField(
                                e.target.value ? e.target.value : null,
                                language,
                                'buttonText'
                              )
                            }
                          />
                        </Paper>
                      </div>
                    )
                  })}
                  <Button
                    data-cy="banner-delete-button-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      languages.map(language => {
                        let text = ''
                        try {
                          text =
                            getTextFromLanguage(
                              fields.buttonText.text,
                              language
                            ) || ''
                        } catch (error) {}

                        handleChangeTextField(null, language, 'buttonText')
                      })
                    }}
                  >
                    Delete Button
                  </Button>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-button-height-label"
                  >
                    Button Height - {fields.buttonPadding1}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="banner-button-height-slider"
                      className={styles.slider}
                      value={parseInt(fields.buttonPadding1)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'buttonPadding1')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-button-width-label"
                  >
                    Button Width - {fields.buttonPadding2}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="banner-button-width-slider"
                      className={styles.slider}
                      value={parseInt(fields.buttonPadding2)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'buttonPadding2')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-button-font-size-label"
                  >
                    Button font size - {fields.buttonFontSize}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="banner-button-font-size-slider"
                      className={styles.slider}
                      value={parseInt(fields.buttonFontSize)}
                      aria-labelledby="slider-label2"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'buttonFontSize')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-button-font-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Button Font
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      data-cy="banner-button-font-select"
                      native="true"
                      className={classes.input}
                      value={
                        fields.buttonFontFamily &&
                        fields.buttonFontFamily.indexOf('Roboto') !== -1
                          ? 'Roboto'
                          : fields.buttonFontFamily
                      }
                      onChange={e =>
                        handleChangeField(e.target.value, 'buttonFontFamily')
                      }
                      input={
                        <SelectInput name="buttonFontFamily" id="subTitle" />
                      }
                    >
                      {FONTS.map(buttonFontFamily => (
                        <option key={buttonFontFamily} value={buttonFontFamily}>
                          {buttonFontFamily}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-button-background-color-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Button background color
                  </InputLabel>
                  <ColorPicker
                    dataCy="banner-button-background-colorpicker"
                    color={fields.buttonBackgroundColor}
                    fieldName={'buttonBackgroundColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-button-text-color-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Button text color
                  </InputLabel>
                  <ColorPicker
                    dataCy="banner-button-text-colorpicker"
                    color={fields.buttonTextColor}
                    fieldName={'buttonTextColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-button-border-thickness-label"
                  >
                    Button border thickness - {fields.borderThickness}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="banner-button-border-thickness-slider"
                      className={styles.slider}
                      value={parseInt(fields.borderThickness)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={5}
                      onChange={(e, value) =>
                        handleChangeField(value, 'borderThickness')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-button-border-type-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Button Border type
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      data-cy="banner-button-border-type-select"
                      native="true"
                      className={classes.input}
                      value={fields.borderType}
                      onChange={e =>
                        handleChangeField(e.target.value, 'borderType')
                      }
                      input={
                        <SelectInput
                          name="border"
                          id="age-customized-native-simple"
                        />
                      }
                    >
                      {BORDER_TYPES.map(border => (
                        <option key={border} value={border}>
                          {border}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-button-border-color-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Button Border color
                  </InputLabel>
                  <ColorPicker
                    dataCy="banner-button-border-colorpicker"
                    color={fields.borderColor}
                    fieldName={'borderColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="banner-button-border-radius-label"
                  >
                    Button Border radius - {fields.buttonBorderRadius}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="banner-button-border-radius-slider"
                      className={styles.slider}
                      value={parseInt(fields.buttonBorderRadius)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={30}
                      onChange={(e, value) =>
                        handleChangeField(value, 'buttonBorderRadius')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-link-to-design-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Link to Design Your Own
                  </InputLabel>
                  <Checkbox
                    data-cy="banner-link-to-design-checkbox"
                    checked={fields.linkBannerDesign}
                    onChange={e =>
                      handleChangeField(e.target.checked, 'linkBannerDesign')
                    }
                    value={fields.linkBannerDesign}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-link-to-custom-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Custom Link?
                  </InputLabel>
                  <Checkbox
                    data-cy="banner-custom-link"
                    checked={fields.linkBannerDesign}
                    onChange={e =>
                      this.setState({ customLink: e.target.checked })
                    }
                    value={'customLink'}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="banner-link-label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Link
                  </InputLabel>

                  <Paper
                    data-cy="banner-Add-Link-Input"
                    className={classes.root}
                    elevation={0}
                  >
                    <InputBase
                      className={classes.input}
                      placeholder="Click on add a link"
                      disabled={this.state.customLink ? false : true}
                      value={
                        this.state.selectedPage
                          ? (fields.buttonUrl = this.state.selectedPage)
                          : this.state.selectedCollection
                          ? (fields.buttonUrl = this.state.selectedCollection)
                          : this.state.selectedProduct
                          ? (fields.buttonUrl = this.state.selectedProduct)
                          : this.state.selectedProductPackage
                          ? (fields.buttonUrl = this.state.selectedProductPackage)
                          : this.state.selectedTeamstore
                          ? (fields.buttonUrl = this.state.selectedTeamstore)
                          : fields.linkBannerDesign
                          ? (fields.buttonUrl = '/design-your-own/')
                          : null
                      }
                      onChange={e =>
                        handleChangeField(
                          e.target.value ? e.target.value : null,
                          'buttonUrl'
                        )
                      }
                    />
                  </Paper>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    data-cy="banner-Add-Link-btn"
                    onClick={this.handlePageOpen}
                  >
                    Add Link
                  </Button>
                </Grid>
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Dialog
          open={this.state.pageDialog}
          onClose={this.handlePageClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle data-cy="banner-Link-Select-Page" id="form-dialog-title">
            Add Link
          </DialogTitle>
          <DialogContent
            style={{
              zIndex: 100,
              minWidth: '600px',
              minHeight: '50vh'
            }}
          >
            Type of link
            <Grid container={true}>
              <NativeSelect
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: 4
                }}
                native="true"
                value={urlType}
                onChange={e => {
                  this.setState({
                    urlType: e.target.value,
                    selectedPage:
                      e.target.value === 'PAGE' ? '/your-page' : null,
                    selectedProduct:
                      e.target.value === 'PRODUCT' ? '/your-product' : null,
                    selectedProductPackage:
                      e.target.value === 'PRODUCT_PACKAGE'
                        ? 'productPackage'
                        : null,
                    selectedTeamstore:
                      e.target.value === 'SALESCHANNEL'
                        ? '/your-teamstore'
                        : null,
                    selectedCollection:
                      e.target.value === 'COLLECTION'
                        ? '/your-collection'
                        : null
                  })
                  handleChangeField(
                    e.target.value ? e.target.value : null,
                    'urlType'
                  )
                }}
              >
                <option value={'PAGE'}>Content Page</option>
                <option value={'PRODUCT'}>Product</option>
                <option value={'PRODUCT_PACKAGE'}>Product Package</option>
                <option value={'COLLECTION'}>Collection</option>
                <option value={'SALESCHANNEL'}>Teamstore</option>
              </NativeSelect>
            </Grid>
            <br />
            {urlType === 'PAGE' ? (
              <>
                Content Pages
                <Query variables={{ id: shopId }} query={GET_PAGES}>
                  {({ loading, error, data }) => {
                    if (loading) return <CircularProgress />
                    if (error) return `Error! ${error.message}`

                    return (
                      <Grid container={true}>
                        <SelectPage
                          pages={data.shop.pages}
                          handleChange={this.handleChangePage}
                          Template={Template}
                          selectedPage={this.state.selectedPage}
                          selectedPageId={this.state.selectedPageId}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      </Grid>
                    )
                  }}
                </Query>
              </>
            ) : null}
            {urlType === 'PRODUCT_PACKAGE' ? (
              <>
                Product Packages
                <Query
                  variables={{ id: shopId }}
                  query={ADMIN_SHOP_PRODUCT_PACKAGES}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <CircularProgress />
                    if (error) return `Error! ${error.message}`

                    return (
                      <Grid container={true}>
                        <SelectProductPackage
                          packages={data.shop.productPackages}
                          handleChange={this.handleChangeProductPackage}
                          Template={Template}
                          selectedProductPackage={
                            this.state.selectedProductPackage
                          }
                          selectedProductPackageId={
                            this.state.selectedProductPackageId
                          }
                          selected={this.state.selected}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      </Grid>
                    )
                  }}
                </Query>
              </>
            ) : null}
            {urlType === 'SALESCHANNEL' ? (
              <>
                Teamstores
                <Query
                  variables={{ id: shopId }}
                  query={ADMIN_SHOP_SALESCHANNELS}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <CircularProgress />
                    if (error) return `Error! ${error.message}`

                    return (
                      <Grid container={true}>
                        <SelectTeamstore
                          teamstores={data.shop.salesChannels}
                          handleChange={this.handleChangeTeamstore}
                          Template={Template}
                          selectedTeamstore={this.state.selectedTeamstore}
                          selectedTeamstoreId={this.state.selectedTeamstoreId}
                          selected={this.state.selected}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      </Grid>
                    )
                  }}
                </Query>
              </>
            ) : null}
            {urlType === 'COLLECTION' ? (
              <>
                Collections
                <Query
                  variables={{ id: shopId }}
                  query={ADMIN_SHOP_COLLECTIONS}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <CircularProgress />
                    if (error) return `Error! ${error.message}`

                    return (
                      <Grid container={true}>
                        <SelectCollection
                          collections={data.shop.collections}
                          handleChange={this.handleChangeCollection}
                          Template={Template}
                          selectedCollection={this.state.selectedCollection}
                          selectedCollectionId={this.state.selectedCollectionId}
                          selected={this.state.selected}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      </Grid>
                    )
                  }}
                </Query>
              </>
            ) : null}
            {urlType === 'PRODUCT' ? (
              <>
                Products
                <Query variables={{ id: shopId }} query={ADMIN_SHOP_PRODUCTS}>
                  {({ loading, error, data }) => {
                    if (loading) return <CircularProgress />
                    if (error) return `Error! ${error.message}`

                    return (
                      <Grid container={true}>
                        {this.state.selectedProduct}
                        <SelectProducts
                          products={data.shop.products}
                          handleChange={this.handleChangeCollection}
                          Template={Template}
                          selectedProduct={this.state.selectedProduct}
                          selectedProductId={this.state.selectedProductId}
                          selected={this.state.selected}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      </Grid>
                    )
                  }}
                </Query>
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              data-cy="banner-Select-Page-Cancel-btn"
              onClick={this.handlePageClose}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              data-cy="banner-Select-Page-Save-btn"
              onClick={this.handlePageClose}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    )
  }
}

UpdateBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
}

export default withStyles(styles)(UpdateBanner)
