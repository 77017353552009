import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
const uuidv4 = require('uuid/v4');
const omitDeep = require('omit-deep');
const ProductPackageAccountingId = ({
  classes,
  productPackage,
  productPackageId,
  updateProductPackage
}) => {
  return (
    <Grid container>
      <Grid
        container
        key={'productPackage-accountingId'}
        direction="row"
        alignItems="flex-end"
        spacing={1}
        className={classes.container}
      >
        <Grid item xs={12}>
          <TextField
            dataCy="ProductPackage-Accounting-ID"
            label="Accounting ID (Used to identify in your accounting system)"
            placeholder="Enter accounting reference ID"
            multiline={true}
            rowsMax="4"
            value={productPackage.accountingId}
            onChange={e => {
              updateProductPackage({
                optimisticResponse: {
                  __typename: 'Mutation',
                  updateProductPackage: {
                    ...productPackage,
                    accountingId: e.target.value ? e.target.value : null
                  }
                },
                variables: {
                  id: productPackageId,
                  input: {
                    accountingId: e.target.value ? e.target.value : null
                  }
                }
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductPackageAccountingId;
