import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { Smartphone, DesktopWindows } from '@material-ui/icons'

interface Props {
  responsiveView: any
  setResponsiveView: any
}

const ResponsiveSwitch: React.FC<Props> = props => {
  const { responsiveView, setResponsiveView } = props

  const handleSwitch = (view: number, mode: string) => {
    setResponsiveView({
      mode: mode,
      xs: view
    })
  }

  return (
    <Grid
      item
      style={{
        position: 'relative',
        left: '22%',
        border: '1px solid #b23566',
        backgroundColor: 'white',
        borderRadius: 2
      }}
    >
      <Grid container={true} justify="flex-end">
        <Button
          style={
            responsiveView.mode === 'desktop'
              ? {
                  color: 'white',
                  backgroundColor: '#b23566',
                  cursor: 'pointer',
                  padding: 11,
                  borderRadius: 0
                }
              : {
                  color: '#000',
                  cursor: 'pointer',
                  padding: 11
                }
          }
          onClick={() => handleSwitch(10, 'desktop')}
        >
          <DesktopWindows
            style={
              responsiveView.mode === 'desktop'
                ? { color: 'white' }
                : { color: '#b23566' }
            }
          />
        </Button>
        <Button
          style={
            responsiveView.mode === 'mobile'
              ? {
                  color: 'white',
                  backgroundColor: '#b23566',
                  cursor: 'pointer',
                  padding: 11,
                  borderRadius: 0
                }
              : {
                  color: '#000',
                  cursor: 'pointer',
                  padding: 11
                }
          }
          onClick={() => handleSwitch(4, 'mobile')}
        >
          <Smartphone
            style={
              responsiveView.mode === 'mobile'
                ? { color: 'white' }
                : { color: '#b23566' }
            }
          />
        </Button>
      </Grid>
    </Grid>
  )
}

export default ResponsiveSwitch
