import React, { useState, useContext } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Toolbar,
  AppBar,
  IconButton
} from '@material-ui/core'
import ThemeHeading from './Theme/ThemeHeading'
import ThemeButton from './Theme/ThemeButton'
import uuid from 'uuid'
import { TransitionProps } from '@material-ui/core/transitions'
import CloseIcon from '@material-ui/icons/Close'
import { navigate } from '@reach/router'
import { ShopContext } from './LanguageContext'

interface Props {
  themeData: any
  updateTheme: any
}

const ThemeContainer: React.FC<Props> = props => {
  const { themeData, updateTheme } = props
  const [open, setOpen] = useState(false)
  const shopId = useContext(ShopContext)

  const Transition = React.forwardRef<unknown, TransitionProps>(
    function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />
    }
  )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleUpdate = (
    fieldName: string,
    value: any,
    variant: string,
    element: 'button' | 'input' | 'label' | 'heading',
    level: 'root' | 'style'
  ) => {
    if (variant === 'body1') {
      variant = 'p'
    }

    let inputFields =
      level === 'root'
        ? { [fieldName]: value }
        : { style: { [fieldName]: value } }

    updateTheme({
      variables: {
        id: themeData?.themeId || 'theme1',
        input: {
          [element === 'heading' ? variant : element]: {
            id: uuid.v4(),
            ...inputFields
          }
        }
      }
    })
  }

  return (
    <Grid item style={{ marginLeft: 10, zIndex: 300 }}>
      <Button
        style={{
          backgroundColor: 'white',
          textTransform: 'uppercase',
          padding: '12px 40px',
          fontSize: 12,
          color: '#B23566',
          border: '1px solid #b23566',
          fontWeight: 'bold',
          fontFamily: 'Poppins'
        }}
        onClick={() => handleClickOpen()}
      >
        Theme
      </Button>
      <Button
        style={{
          backgroundColor: 'white',
          textTransform: 'uppercase',
          padding: '12px 40px',
          fontSize: 12,
          color: '#B23566',
          border: '1px solid #b23566',
          fontWeight: 'bold',
          marginLeft: 10,
          fontFamily: 'Poppins'
        }}
        onClick={() => {
          navigate(`/admin/${shopId}/editor/menus`)
        }}
      >
        Menus
      </Button>
      {open ? (
        <Dialog maxWidth="xl" open={open} onClose={handleClose}>
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle id="theme-dialog">Theme settings</DialogTitle>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <ThemeHeading
              themeId={themeData?.themeId || 'theme1'}
              themeData={themeData.theme}
              displayText="Heading 1"
              variant="h1"
              handleUpdate={handleUpdate}
            />
            <ThemeHeading
              themeId={themeData?.themeId || 'theme1'}
              themeData={themeData.theme}
              displayText="Heading 2"
              variant="h2"
              handleUpdate={handleUpdate}
            />
            <ThemeHeading
              themeId={themeData?.themeId || 'theme1'}
              themeData={themeData.theme}
              displayText="Heading 3"
              variant="h3"
              handleUpdate={handleUpdate}
            />
            <ThemeHeading
              themeId={themeData?.themeId || 'theme1'}
              themeData={themeData.theme}
              displayText="Heading 4"
              variant="h4"
              handleUpdate={handleUpdate}
            />
            <ThemeHeading
              themeId={themeData?.themeId || 'theme1'}
              themeData={themeData.theme}
              displayText="Heading 5"
              variant="h5"
              handleUpdate={handleUpdate}
            />
            <ThemeHeading
              themeId={themeData?.themeId || 'theme1'}
              themeData={themeData.theme}
              displayText="Paragraph"
              variant="body1"
              handleUpdate={handleUpdate}
            />
            <ThemeButton
              themeId={themeData?.themeId || 'theme1'}
              themeData={themeData.theme}
              handleUpdate={handleUpdate}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </Grid>
  )
}

export default ThemeContainer
