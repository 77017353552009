import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
const uuidv4 = require('uuid/v4');
const omitDeep = require('omit-deep');
const PageTitle = ({ classes, languages, page, pageId, updateContentPage }) => {
  const titleId = page.title && page.title.id ? page.title.id : uuidv4();
  return (
    <Grid container>
      {languages.map((language, index) => {
        var titleText = '';
        try {
          titleText = getTextFromLanguage(page.title.text, language);
        } catch (error) {}
        return (
          <Grid
            container
            key={'style-titleText-' + index}
            direction="row"
            alignItems="flex-end"
            spacing={1}
            className={classes.container}
          >
            <Grid item xs={10}>
              <TextField
                label="Title"
                placeholder="Enter title"
                multiline={true}
                rowsMax="4"
                value={titleText}
                onChange={e => {
                  updateContentPage({
                    // optimisticResponse: {
                    //   __typename: 'Mutation',
                    //   updateContentPage: {
                    //     ...page,
                    //     title: {
                    //       id: titleId,
                    //       text: [
                    //         ...languages.map((tempLanguage, index) => {
                    //           return {
                    //             langCode: tempLanguage,
                    //             content:
                    //               tempLanguage === language
                    //                 ? e.target.value
                    //                 : getTextFromLanguage(
                    //                     page.title.text,
                    //                     tempLanguage
                    //                   ),
                    //             __typename: 'TextContent'
                    //           };
                    //         })
                    //       ],
                    //       __typename: 'Text'
                    //     }
                    //   }
                    // },
                    variables: {
                      id: pageId,
                      input: {
                        title: {
                          id: titleId,
                          text: [
                            ...languages.map((tempLanguage, index) => {
                              return {
                                langCode: tempLanguage,
                                content:
                                  tempLanguage === language
                                    ? e.target.value
                                    : getTextFromLanguage(
                                        page.title.text,
                                        tempLanguage
                                      )
                              };
                            })
                          ]
                        }
                      }
                    }
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  height: 38,
                  width: '100%',
                  border: '1px solid #ddd',
                  borderRadius: '3px'
                }}
              >
                {language}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PageTitle;
