import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { buildUrl } from 'react-instafeed';
import { SHOP_ID } from '../../../../../brikl-config';
require('isomorphic-fetch');

const styles = theme => ({
  appBar: {
    position: 'relative'
  },
  icon: {
    marginRight:theme.spacing(2)
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`
  },
  heroButtons: {
    marginTop:theme.spacing(4)
  },
  layout: {
    width: 'auto',
    marginLeft:theme.spacing(3),
    marginRight:theme.spacing(3),
    [theme.breakpoints.up(1100 +theme.spacing(6))]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  cardGrid: {
    padding: `${theme.spacing(8)}px 0`
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    height: 325,
    backgroundPosition: 'inherit'
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding:theme.spacing(6)
  }
});

class Instagram extends Component {
  async componentDidMount() {
    const instaResult = fetch(
      buildUrl({
        accessToken: process.env.GATSBY_INSTAGRAM_TOKEN, // TODO replace with oauth flow
        clientId: 'ec9dfd19f3cd4388af0e264282a1e094',
        get: 'user', // popular, user
        // locationId: null,
        resolution: 'standard_resolution', // thumbnail, low_resolution, standard_resolution
        sortBy: 'most-recent', // none, least-commented, least-liked, least-recent, most-commented, most-liked, most-recent, random
        // tagName: null,
        userId: SHOP_ID === 'vermarc' ? 'vermarcsport' : null
      })
    );
    console.log('instaResult', instaResult);
  }
  render() {
    const { classes, instagram } = this.props;

    return (
      <Grid item>
        <Typography
          variant="h2"
          component="h2"
          style={{
            paddingTop: 45,
            paddingBottom: 0,
            paddingLeft: 50,
            paddingRight: 50
          }}
        >
          Instagram Feed
        </Typography>
        <Grid style={{ marginBottom: 10, padding: 50 }} container spacing={2}>
          {instagram &&
            instagram.edges &&
            instagram.edges.map(node => {
              return (
                <Grid item xs={4}>
                  <Card key={node.node.id} className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={node.node.thumbnails[3].src}
                      title={node.node.caption}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="body1" component="p">
                        {node.node.caption}
                      </Typography>
                      <Typography style={{ fontWeight: 600 }}>
                        Likes: {node.node.likes}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    );
  }
}

Instagram.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withStyles(styles)(Instagram);
