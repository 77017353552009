import React from 'react'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { download } from '../../../../../src/helper/download'

interface Props {
  designTemplate: any
  latestRevisionData: any
}

const Logo: React.FunctionComponent<Props> = props => {
  const { designTemplate, latestRevisionData } = props

  const pxToCm = latestRevisionData.pxToCm ? latestRevisionData.pxToCm : 1

  return (
    <>
      <h2>Logo</h2>

      <table className="table">
        <thead>
          <tr style={{ fontWeight: 'bold' }}>
            <th>Name</th>
            <th>Width</th>
            <th>Height</th>
            <th>Color</th>
            <th>Printing</th>
            <th>Logo image</th>
          </tr>
        </thead>
        <tbody>
          {latestRevisionData && latestRevisionData.logoDesigns
            ? latestRevisionData.logoDesigns.map((logo: any) => (
                <tr key={logo.id} style={{ textAlign: 'center' }}>
                  <td>{logo.logoDesign.name}</td>
                  <td>
                    {(
                      logo.logoDesign.designAttributes.scaleX *
                      logo.logoDesign.designAttributes.width *
                      pxToCm
                    ).toFixed(2)}{' '}
                    {pxToCm == 1 ? 'px' : 'cm'}
                  </td>
                  <td>
                    {(
                      logo.logoDesign.designAttributes.scaleY *
                      logo.logoDesign.designAttributes.height *
                      pxToCm
                    ).toFixed(2)}{' '}
                    {pxToCm == 1 ? 'px' : 'cm'}
                  </td>
                  <td>
                    {logo.logoDesign.colorHex ? logo.logoDesign.colorHex : '-'}
                  </td>
                  <td>-</td>
                  <td>
                    <img
                      src={logo.logoDesign.src}
                      style={{ maxWidth: '40px' }}
                      data-testid="logo-img"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        download(logo.logoDesign.src)
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </>
  )
}

export default Logo
