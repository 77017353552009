import React from 'react'
import { Card, Grid, InputLabel, TextField, Button } from '@material-ui/core'

const CardCouponCode: React.FC = () => {
  return (
    <Card style={{ marginBottom: 15 }}>
      <Grid
        container
        alignItems="flex-end"
        style={{
          lineHeight: '2em',
          width: 'auto',
          padding: 20
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
          <InputLabel>Coupon code:</InputLabel>
          <TextField value={''} />
        </Grid>
        <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ width: '100%' }}
          >
            Validate
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CardCouponCode
