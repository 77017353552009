import React from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ADMIN_UPDATE_SHOP_CONTENT_SETTINGS } from '../../gql/admin-mutations';
import { useMutation } from '@apollo/react-hooks';

const styles = theme => ({
  select: {
    border: '1px solid black',
    borderRadius: 4
  }
});

const ThemeSelection = ({ themeId, shopId }) => {
  const [updateShopContentSettings, { error, data }] = useMutation(
    ADMIN_UPDATE_SHOP_CONTENT_SETTINGS,
    {
      variables: { id: shopId }
    }
  );

  return (
    <NativeSelect
      classes={styles.select}
      native="true"
      value={themeId}
      onChange={e =>
        updateShopContentSettings({
          variables: {
            id: shopId,
            input: {
              contentSettings: {
                themeId: e.target.value
              }
            }
          }
        })
      }
    >
      <option key="1" value="theme1">
        Theme 1
      </option>
      <option key="2" value="theme2">
        Theme 2
      </option>
    </NativeSelect>
  );
};

ThemeSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export default withStyles(styles)(ThemeSelection);
