import React, { Component } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import Input from '../../../common/Input';

import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../../common/Select';
import { secondary } from '../../../admin/AdminTheme';
import { Link } from 'gatsby';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_GET_TEAMSTORE } from '../../gql/admin-queries';
import {
  ADMIN_DELETE_COLLECTION_SALESCHANNEL,
  ADMIN_UPDATE_COLLECTION_SALESCHANNEL
} from '../../gql/admin-mutations';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { defaultLanguage } from '../../../../helper/config';
import _orderBy from 'lodash/orderBy';
class TeamstoreAssignedCollections extends Component {
  state = {
    selectedSalesChannelId: null,
    loading: false
  };
  render() {
    const { teamstoreId, teamstore, defaultLanguage, shopId } = this.props;
    return (
      <Mutation
        mutation={ADMIN_UPDATE_COLLECTION_SALESCHANNEL}
        update={(cache, { data: { updateCollectionSalesChannel } }) => {
          if (updateCollectionSalesChannel) {
            const data = cache.readQuery({
              query: ADMIN_GET_TEAMSTORE,
              variables: { id: teamstore.id }
            });
            data.teamstore.collections.edges = data.teamstore.collections.edges.map(
              c =>
                c.node.collection.id === pc.node.collection.id
                  ? {
                      ...c,
                      updateCollectionSalesChannel
                    }
                  : c
            );
            cache.writeQuery({
              query: ADMIN_GET_TEAMSTORE,
              variables: { id: teamstore.id },
              data
            });
          }
        }}
      >
        {(updateCollectionSalesChannel, { data, loading, error }) => {
          return (
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper elevation={0}>
                  <Grid container>
                    {this.state.loading === true ? (
                      <CircularProgress />
                    ) : teamstore.collections.edges ? (
                      _orderBy(
                        teamstore.collections.edges,
                        ['node.sortOrder'],
                        ['asc']
                      ).map(pc =>
                        !pc.node.collection && !pc.node.collection.id ? null : (
                          <Grid
                            key={'tpc' + pc.node.collection.id}
                            container
                            alignItems={'center'}
                            spacing={1}
                          >
                            <Grid
                              item
                              xs={2}
                              style={{
                                cursor: 'pointer',
                                padding: '8px 0px',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Input
                                placeholder="Sort order"
                                id="outlined-logo"
                                margin="normal"
                                type={'text'}
                                value={pc.node.sortOrder}
                                onChange={e => {
                                  updateCollectionSalesChannel({
                                    optimisticResponse: {
                                      __typename: 'Mutation',
                                      updateCollectionSalesChannel: {
                                        ...pc.node,
                                        sortOrder: e.target.value
                                          ? parseFloat(e.target.value)
                                          : null
                                      }
                                    },
                                    variables: {
                                      collectionId: pc.node.collection.id,
                                      salesChannelId: pc.node.salesChannelId,
                                      input: {
                                        sortOrder: e.target.value
                                          ? parseFloat(e.target.value)
                                          : null
                                      }
                                    }
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <img
                                onClick={() => {
                                  window.open(
                                    '/admin/' +
                                      shopId +
                                      '/inventory/collections/' +
                                      pc.node.collection.id
                                  );
                                }}
                                style={{
                                  marginRight: '8px',
                                  marginLeft: '8px',
                                  float: 'left',
                                  width: '28px',
                                  height: '28px',
                                  objectFit: 'contain'
                                }}
                                src={
                                  pc.node.collection.media &&
                                  pc.node.collection.media.find(
                                    m => m && m.isThumbnail === true
                                  )
                                    ? pc.node.collection.media.find(
                                        m => m && m.isThumbnail === true
                                      ).image
                                    : null
                                }
                                alt={''}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              style={{
                                cursor: 'pointer',
                                width: '100%',
                                border: '1px solid',
                                backgroundColor: secondary,
                                color: '#fff',
                                borderRadius: 3,
                                display: 'flex',
                                alignContent: 'center',
                                padding: 4,
                                position: 'relative'
                              }}
                            >
                              <Typography
                                onClick={() => {
                                  window.open(
                                    '/admin/' +
                                      shopId +
                                      '/inventory/collections/' +
                                      pc.node.collection.id
                                  );
                                }}
                                style={{ padding: 4 }}
                              >
                                {pc.node.collection.title
                                  ? getTextFromLanguage(
                                      pc.node.collection.title.text,
                                      defaultLanguage
                                    )
                                    ? getTextFromLanguage(
                                        pc.node.collection.title.text,
                                        defaultLanguage
                                      )
                                    : getTextFromLanguage(
                                        pc.node.collection.title.text,
                                        'fr_FR'
                                      )
                                    ? getTextFromLanguage(
                                        pc.node.collection.title.text,
                                        'fr_FR'
                                      )
                                    : getTextFromLanguage(
                                        pc.node.collection.title.text,
                                        'nl_NL'
                                      )
                                    ? getTextFromLanguage(
                                        pc.node.collection.title.text,
                                        'nl_NL'
                                      )
                                    : pc.node.collection.name
                                    ? pc.node.collection.name
                                    : pc.node.collectionId
                                  : 'N/A'}{' '}
                                ({pc.node.collection.no})
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Mutation
                                mutation={ADMIN_DELETE_COLLECTION_SALESCHANNEL}
                                update={(
                                  cache,
                                  { data: { deleteCollectionSalesChannel } }
                                ) => {
                                  if (deleteCollectionSalesChannel) {
                                    const data = cache.readQuery({
                                      query: ADMIN_GET_TEAMSTORE,
                                      variables: { id: teamstore.id }
                                    });
                                    data.teamstore.collections.edges = data.teamstore.collections.edges.filter(
                                      c =>
                                        c.node.collection.id !==
                                        pc.node.collection.id
                                    );
                                    cache.writeQuery({
                                      query: ADMIN_GET_TEAMSTORE,
                                      variables: { id: teamstore.id },
                                      data
                                    });
                                  }
                                }}
                              >
                                {(
                                  deleteCollectionSalesChannel,
                                  { data, loading, error }
                                ) => {
                                  return (
                                    <Button
                                      onClick={async () => {
                                        if (pc) {
                                          await deleteCollectionSalesChannel({
                                            optimisticResponse: {
                                              __typename: 'Mutation',
                                              deleteCollectionSalesChannel: true
                                            },
                                            variables: {
                                              collectionId:
                                                pc.node.collection.id,
                                              salesChannelId: teamstore.id
                                            }
                                          });
                                        }
                                      }}
                                      color="primary"
                                      variant="contained"
                                      style={{ minWidth: 0, width: '100%' }}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  );
                                }}
                              </Mutation>
                            </Grid>
                          </Grid>
                        )
                      )
                    ) : null}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          );
        }}
      </Mutation>
    );
  }
}

export default TeamstoreAssignedCollections;
