import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import { ADMIN_SHOP_COLORS, ADMIN_GET_PRODUCT } from '../../gql/admin-queries'
import {
  ADMIN_UPLOAD_FILE,
  ADMIN_UPDATE_PRODUCT_DESIGN_TEMPLATE,
  ADMIN_DELETE_PRODUCT_DESIGN_TEMPLATE
} from '../../gql/admin-mutations'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import _sortBy from 'lodash/sortBy'
import { I18n } from '@aws-amplify/core'
import Input from '../../../common/Input'

import _get from 'lodash/get'
import './ProductFileDrop.css'
import _each from 'lodash/each'
import _cloneDeep from 'lodash/cloneDeep'
import FileDrop from 'react-file-drop'

import axios from 'axios'

const uuidv4 = require('uuid/v4')
const omitDeep = require('omit-deep')

function getModalStyle() {
  return {
    top: '50px',
    left: 0,
    position: 'fixed',
    right: 0,
    height: '80vh',
    width: '100%',
    margin: '0 auto',
    maxWidth: '1000px',
    overflow: 'auto'
  }
}
class ProductDesignTemplate extends Component {
  state = {}

  uploadProductDesignTemplateColorzoneFiles = async (
    files,
    uploadFile,
    product,
    refetch,
    productDesignTemplateId,
    lastTemplateRevision
  ) => {
    try {
      const {} = this.props
      this.setState({
        // uploading: files.length.toString()
        uploading: true
      })
      for (var i = 0; i < files.length; i++) {
        const myFile = files[i]
        if (myFile.size < 300000000) {
          const signedUrlResult = await uploadFile({
            variables: {
              type: 'PRODUCT_DESIGN_TEMPLATE_PREVIEW',
              fileName: 'colorzone.svg',
              prefixVariables: [
                'productId',
                'productDesignTemplateId',
                'templateRevision'
              ],
              input: {
                productId: product.id,
                productDesignTemplateId: productDesignTemplateId,
                templateRevision: lastTemplateRevision,
                designStudioVersion: product.designStudioVersion
              }
            }
          })
          console.log('signedUrlResult', signedUrlResult)
          const signedUrl = signedUrlResult.data.uploadFile.url
          var options = {
            headers: {
              'Content-Type': signedUrlResult.data.uploadFile.contentType
            }
          }
          if (signedUrlResult.data.uploadFile.acl) {
            options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl
          }
          if (signedUrlResult.data.uploadFile.tagging) {
            options.headers['x-amz-tagging'] =
              signedUrlResult.data.uploadFile.tagging
          }
          const uploadResult = await axios.put(signedUrl, myFile, options)
          refetch()
          console.log('uploadFile', uploadFile)
          this.setState({
            uploading: false
          })
        } else {
          this.setState({
            uploading: false
          })
          alert('Only files up to 300MB supported.')
        }
      }
    } catch (error) {
      console.error('uploaderror', error)
      this.setState({
        uploading: false
      })
    }
  }

  uploadProductDesignTemplatePreviewFiles = async (
    files,
    uploadFile,
    product,
    refetch,
    productDesignTemplateId,
    lastTemplateRevision
  ) => {
    const { uploadS3 } = this.props
    this.setState({
      uploading: true
    })
    for (var i = 0; i < files.length; i++) {
      const uploadResult = await uploadS3(
        files[i],
        uploadFile,
        {
          type: 'PRODUCT_DESIGN_TEMPLATE_PREVIEW',
          fileName: 'preview.png',
          prefixVariables: [
            'productId',
            'productDesignTemplateId',
            'templateRevision'
          ],
          input: {
            productId: product.id,
            productDesignTemplateId: productDesignTemplateId,
            templateRevision: lastTemplateRevision,
            designStudioVersion: product.designStudioVersion
          }
        },
        product,
        true
      )
      if (!uploadResult.success) {
        this.setState({
          uploading: false
        })
        alert(uploadResult.msg)
        break
      }
    }
    refetch()
    this.setState({
      uploading: false
    })
  }

  uploadProductDesignTemplateLogoFiles = async (
    files,
    uploadFile,
    product,
    refetch,
    productDesignTemplateId,
    lastTemplateRevision
  ) => {
    const { uploadS3 } = this.props
    this.setState({
      uploading: true
    })
    for (var i = 0; i < files.length; i++) {
      const uploadResult = await uploadS3(
        files[i],
        uploadFile,
        {
          type: 'PRODUCT_DESIGN_TEMPLATE_LOGOBASE',
          fileName: 'logobase.png',
          prefixVariables: [
            'productId',
            'productDesignTemplateId',
            'templateRevision'
          ],
          input: {
            productId: product.id,
            productDesignTemplateId: productDesignTemplateId,
            templateRevision: lastTemplateRevision,
            designStudioVersion: product.designStudioVersion
          }
        },
        product,
        true
      )
      if (!uploadResult.success) {
        this.setState({
          uploading: false
        })
        alert(uploadResult.msg)
        break
      }
    }
    refetch()
    this.setState({
      uploading: false
    })
  }

  uploadProductDesignTemplateFiles = async (
    files,
    uploadFile,
    product,
    refetch,
    productDesignTemplateId,
    lastTemplateRevision
  ) => {
    const { uploadS3 } = this.props
    this.setState({
      uploading: true
    })
    for (var i = 0; i < files.length; i++) {
      const uploadResult = await uploadS3(
        files[i],
        uploadFile,
        {
          type: 'PRODUCT_DESIGN_TEMPLATE_FILE',
          fileName: 'template.png',
          prefixVariables: [
            'productId',
            'productDesignTemplateId',
            'templateRevision'
          ],
          input: {
            productId: product.id,
            productDesignTemplateId: productDesignTemplateId,
            templateRevision: lastTemplateRevision,
            designStudioVersion: product.designStudioVersion
          }
        },
        product,
        true
      )
      if (!uploadResult.success) {
        this.setState({
          uploading: false
        })
        alert(uploadResult.msg)
        break
      }
    }
    refetch()
    this.setState({
      uploading: false
    })
  }

  render() {
    const {
      blockEdit,
      classes,
      productId,
      updateProduct,
      product,
      refetch,
      templateId,
      shopId,
      productDesignTemplate
    } = this.props

    return (
      <Mutation
        update={(store, { data: { updateProductDesignTemplate } }) => {}}
        variables={{
          productId: product.id,
          id: templateId
        }}
        mutation={ADMIN_UPDATE_PRODUCT_DESIGN_TEMPLATE}
      >
        {(updateProductDesignTemplate, { data, loading, error }) => {
          return (
            <Grid
              container
              style={{
                marginTop: 24
              }}
            >
              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                <Grid
                  container
                  style={{ padding: '24px 24px 24px 0px' }}
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                      Upload your design templates
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1"></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                <Paper style={{ padding: 24 }} elevation={0}>
                  Revision: {productDesignTemplate.lastTemplateRevision}
                  <Mutation
                    update={(
                      store,
                      { data: { deleteProductDesignTemplate } }
                    ) => {
                      if (deleteProductDesignTemplate) {
                        const data = store.readQuery({
                          query: ADMIN_GET_PRODUCT,
                          variables: { id: product.id }
                        })
                        data.product.designTemplates = data.product.designTemplates.filter(
                          d => d.id !== productDesignTemplate.id
                        )
                        store.writeQuery({
                          query: ADMIN_GET_PRODUCT,
                          variables: { id: product.id },
                          data
                        })
                      }
                    }}
                    variables={{
                      id: productDesignTemplate.id,
                      productId: product.id
                    }}
                    mutation={ADMIN_DELETE_PRODUCT_DESIGN_TEMPLATE}
                  >
                    {(
                      deleteProductDesignTemplate,
                      { data, loading, error }
                    ) => {
                      return (
                        <Button
                          color="primary"
                          style={{
                            float: 'right'
                          }}
                          onClick={() => {
                            deleteProductDesignTemplate()
                          }}
                        >
                          Delete
                        </Button>
                      )
                    }}
                  </Mutation>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Input
                        label="Template No"
                        classes={{
                          root: classes.cssRootInput,
                          input: classes.cssinput
                        }}
                        disabled={blockEdit}
                        id="outlined-actualHeightCm"
                        value={_get(productDesignTemplate, 'no', '')}
                        onChange={e =>
                          updateProductDesignTemplate({
                            optimisticResponse: {
                              __typename: 'Mutation',
                              updateProductDesignTemplate: {
                                ...productDesignTemplate,
                                no: e.target.value
                              }
                            },
                            variables: {
                              input: {
                                lastTemplateRevision:
                                  productDesignTemplate.lastTemplateRevision,
                                no: e.target.value
                              }
                            }
                          })
                        }
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Query
                        query={ADMIN_SHOP_COLORS}
                        variables={{ id: shopId }}
                      >
                        {({ loading, error, data: { shop } }) => {
                          let shopColors = []
                          try {
                            shopColors = shop.colors.edges.map(c => c.node)
                          } catch (error) {}
                          return (
                            <div>
                              <h3>Color zones</h3>
                              <Button
                                onClick={() => {
                                  updateProductDesignTemplate({
                                    variables: {
                                      input: {
                                        colors: productDesignTemplate.colors
                                          ? _cloneDeep([
                                              ...productDesignTemplate.colors,
                                              {
                                                id: uuidv4(),
                                                coordinates: {
                                                  x: 0,
                                                  y: 0
                                                }
                                              }
                                            ]).map(tempColor0 =>
                                              omitDeep(tempColor0, [
                                                '__typename'
                                              ])
                                            )
                                          : [
                                              {
                                                id: uuidv4(),
                                                coordinates: {
                                                  x: 0,
                                                  y: 0
                                                }
                                              }
                                            ]
                                      }
                                    }
                                  })
                                }}
                              >
                                + Add a color zone
                              </Button>

                              {productDesignTemplate.colors
                                ? productDesignTemplate.colors.map(tempPdtc => {
                                    const pdtc = omitDeep(tempPdtc, 'color')
                                    return (
                                      <div
                                        style={{
                                          padding: '1em',
                                          border: '1px solid #eee'
                                        }}
                                        key={pdtc.id}
                                      >
                                        <Input
                                          label="Zone name"
                                          classes={{
                                            root: classes.cssRootInput,
                                            input: classes.cssinput
                                          }}
                                          disabled={blockEdit}
                                          id="outlined-actualHeightCm"
                                          value={_get(pdtc, 'name', '')}
                                          onChange={e =>
                                            updateProductDesignTemplate({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updateProductDesignTemplate: {
                                                  ...productDesignTemplate,
                                                  colors: productDesignTemplate.colors.map(
                                                    tempColor000 => {
                                                      return {
                                                        ...tempColor000,
                                                        name:
                                                          tempColor000.id ===
                                                          pdtc.id
                                                            ? e.target.value
                                                            : tempColor000.name
                                                      }
                                                    }
                                                  )
                                                }
                                              },
                                              variables: {
                                                input: {
                                                  colors: _cloneDeep(
                                                    productDesignTemplate.colors
                                                  ).map(tempColor001 =>
                                                    omitDeep(
                                                      {
                                                        ...tempColor001,
                                                        name:
                                                          tempColor001.id ===
                                                          pdtc.id
                                                            ? e.target.value
                                                            : tempColor001.name
                                                      },
                                                      ['__typename']
                                                    )
                                                  )
                                                }
                                              }
                                            })
                                          }
                                          margin="normal"
                                        />
                                        {/* <Select
                                    label="Color"
                                    className={classes.select}
                                    value={_get(
                                      pdtc,
                                      'colorId',
                                      ''
                                    )}
                                    onChange={e =>
                                      updateProductDesignTemplate({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateProductDesignTemplate: {
                                            ...productDesignTemplate,                                
                                            colors: 
                                              productDesignTemplate.colors.map(tempColor00 => {
                                                return {
                                                  ...tempColor00,
                                                  colorId: tempColor00.id === pdtc.id ? e.target.value : tempColor00.colorId
                                                }                                                 
                                              })
                                          }
                                        },
                                        variables: {                              
                                          input: {                                            
                                            colors: 
                                              _cloneDeep(productDesignTemplate.colors).map(tempColor01 =>
                                                omitDeep({
                                                  ...tempColor01,
                                                  colorId: tempColor01.id === pdtc.id ? e.target.value : tempColor01.colorId
                                                }, ['__typename']) 
                                              )                                              
                                          }
                                        }
                                      })
                                    }
                                  >
                                    <option value={''}>Select a color</option>
                                    {
                                      shopColors.map(color => {                                        
                                        return (
                                        <option key={color.id} value={color.id}>{color.name} ({color.hex} - {color.pantone})</option> 
                                        )
                                      })
                                    }
                                  </Select>                             */}
                                        <Input
                                          label="Color Hex"
                                          classes={{
                                            root: classes.cssRootInput,
                                            input: classes.cssinput
                                          }}
                                          disabled={blockEdit}
                                          id="outlined-colorhex"
                                          value={_get(
                                            pdtc,
                                            'customColorHex',
                                            ''
                                          )}
                                          onChange={e =>
                                            updateProductDesignTemplate({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updateProductDesignTemplate: {
                                                  ...productDesignTemplate,
                                                  colors: productDesignTemplate.colors.map(
                                                    tempColor0 => {
                                                      return {
                                                        ...tempColor0,
                                                        customColorHex:
                                                          tempColor0.id ===
                                                          pdtc.id
                                                            ? e.target.value
                                                            : tempColor0.customColorHex
                                                      }
                                                    }
                                                  )
                                                }
                                              },
                                              variables: {
                                                input: {
                                                  colors: _cloneDeep(
                                                    productDesignTemplate.colors
                                                  ).map(tempColor =>
                                                    omitDeep(
                                                      {
                                                        ...tempColor,
                                                        customColorHex:
                                                          tempColor.id ===
                                                          pdtc.id
                                                            ? e.target.value
                                                            : tempColor.customColorHex
                                                      },
                                                      ['__typename', 'color']
                                                    )
                                                  )
                                                }
                                              }
                                            })
                                          }
                                          margin="normal"
                                        />
                                        <Input
                                          label="Coordinate X"
                                          classes={{
                                            root: classes.cssRootInput,
                                            input: classes.cssinput
                                          }}
                                          disabled={blockEdit}
                                          id="outlined-actualHeightCm"
                                          value={_get(
                                            pdtc,
                                            'coordinates.x',
                                            ''
                                          )}
                                          onChange={e =>
                                            updateProductDesignTemplate({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updateProductDesignTemplate: {
                                                  ...productDesignTemplate,
                                                  colors: productDesignTemplate.colors.map(
                                                    tempColor0 => {
                                                      return {
                                                        ...tempColor0,
                                                        coordinates: {
                                                          ...tempColor0.coordinates,
                                                          x:
                                                            tempColor0.id ===
                                                            pdtc.id
                                                              ? parseFloat(
                                                                  e.target.value
                                                                )
                                                              : tempColor0
                                                                  .coordinates.x
                                                        }
                                                      }
                                                    }
                                                  )
                                                }
                                              },
                                              variables: {
                                                input: {
                                                  colors: _cloneDeep(
                                                    productDesignTemplate.colors
                                                  ).map(tempColor =>
                                                    omitDeep(
                                                      {
                                                        ...tempColor,
                                                        coordinates: {
                                                          y:
                                                            tempColor
                                                              .coordinates.y,
                                                          x:
                                                            tempColor.id ===
                                                            pdtc.id
                                                              ? parseFloat(
                                                                  e.target.value
                                                                )
                                                              : tempColor
                                                                  .coordinates.x
                                                        }
                                                      },
                                                      ['__typename']
                                                    )
                                                  )
                                                }
                                              }
                                            })
                                          }
                                          margin="normal"
                                        />
                                        <Input
                                          label="Coordinate Y"
                                          classes={{
                                            root: classes.cssRootInput,
                                            input: classes.cssinput
                                          }}
                                          disabled={blockEdit}
                                          id="outlined-actualHeightCm"
                                          value={_get(
                                            pdtc,
                                            'coordinates.y',
                                            ''
                                          )}
                                          onChange={e =>
                                            updateProductDesignTemplate({
                                              optimisticResponse: {
                                                __typename: 'Mutation',
                                                updateProductDesignTemplate: {
                                                  ...productDesignTemplate,
                                                  colors: productDesignTemplate.colors.map(
                                                    tempColor0 => {
                                                      return {
                                                        ...tempColor0,
                                                        coordinates: {
                                                          ...tempColor0.coordinates,
                                                          y:
                                                            tempColor0.id ===
                                                            pdtc.id
                                                              ? parseFloat(
                                                                  e.target.value
                                                                )
                                                              : tempColor0
                                                                  .coordinates.y
                                                        }
                                                      }
                                                    }
                                                  )
                                                }
                                              },
                                              variables: {
                                                input: {
                                                  colors: _cloneDeep(
                                                    productDesignTemplate.colors
                                                  ).map(tempColor =>
                                                    omitDeep(
                                                      {
                                                        ...tempColor,
                                                        coordinates: {
                                                          x:
                                                            tempColor
                                                              .coordinates.x,
                                                          y:
                                                            tempColor.id ===
                                                            pdtc.id
                                                              ? parseFloat(
                                                                  e.target.value
                                                                )
                                                              : tempColor
                                                                  .coordinates.y
                                                        }
                                                      },
                                                      ['__typename']
                                                    )
                                                  )
                                                }
                                              }
                                            })
                                          }
                                          margin="normal"
                                        />
                                      </div>
                                    )
                                  })
                                : null}
                            </div>
                          )
                        }}
                      </Query>
                    </Grid>
                    {this.state.uploading === true ? (
                      <span
                        style={{
                          fontSize: '3em'
                        }}
                      >
                        Uploading...
                      </span>
                    ) : null}

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <h3>
                        Upload design template (Color base)
                        <Tooltip
                          className="sssd"
                          title="File size should not be more than 300MB and dimension not wider than 6000px"
                          placement="right-start"
                        >
                          <InfoIcon
                            color="primary"
                            style={{ fontSize: 15, marginLeft: 5 }}
                          />
                        </Tooltip>
                      </h3>
                      {/* {productDesignTemplate.templateFileSrc} */}
                      <img
                        style={{
                          width: '100px',
                          height: 'auto'
                        }}
                        src={productDesignTemplate.templateFileSrc}
                        alt={'Not found'}
                      />
                      <Mutation
                        update={(store, { data: { uploadFile } }) => {}}
                        mutation={ADMIN_UPLOAD_FILE}
                      >
                        {uploadFile => (
                          <Grid>
                            <input
                              style={{
                                display: 'none'
                              }}
                              accept=".png"
                              id="productDesignTemplateUploadInput"
                              type="file"
                              onChange={e => {
                                this.uploadProductDesignTemplateFiles(
                                  e.target.files,
                                  uploadFile,
                                  product,
                                  refetch,
                                  templateId,
                                  productDesignTemplate.lastTemplateRevision
                                )
                              }}
                            />
                            <Grid
                              data-cy="product-designtemplate-drag-and-drop-container"
                              style={{
                                border: '1px solid #ccc',
                                margin: '.5rem 0',
                                borderRadius: '4px',
                                padding: '24px',
                                background: '#f4f4f4'
                              }}
                            >
                              <FileDrop
                                onDrop={(files, event) => {
                                  console.log('handleDrop!', files, event)
                                  this.uploadProductDesignTemplateFiles(
                                    files,
                                    uploadFile,
                                    product,
                                    refetch,
                                    templateId,
                                    productDesignTemplate.lastTemplateRevision
                                  )
                                }}
                              >
                                <Grid container spacing={1} direction="column">
                                  <Grid item>
                                    <span>
                                      {I18n.get('Drag your file here ')}
                                      .png
                                    </span>
                                  </Grid>
                                  <Grid item>{I18n.get('or')}</Grid>
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        document
                                          .getElementById(
                                            'productDesignTemplateUploadInput'
                                          )
                                          .click()
                                      }}
                                      aria-label="Upload"
                                    >
                                      {I18n.get('Upload')}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </FileDrop>
                            </Grid>
                          </Grid>
                        )}
                      </Mutation>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <h3>
                        Upload design template (Logo base)
                        <Tooltip
                          className="sssd"
                          title="File size should not be more than 300MB and dimension not wider than 6000px"
                          placement="right-start"
                        >
                          <InfoIcon
                            color="primary"
                            style={{ fontSize: 15, marginLeft: 5 }}
                          />
                        </Tooltip>
                      </h3>
                      {/* {productDesignTemplate.logobaseFileSrc} */}
                      <img
                        style={{
                          width: '100px',
                          height: 'auto'
                        }}
                        src={productDesignTemplate.logobaseFileSrc}
                        alt={'Not found'}
                      />
                      <Mutation
                        update={(store, { data: { uploadFile } }) => {
                          console.log('uploadFile', uploadFile)
                          this.setState({
                            uploading: false
                          })
                          alert('Done')
                        }}
                        mutation={ADMIN_UPLOAD_FILE}
                      >
                        {uploadFile => (
                          <Grid>
                            <input
                              style={{
                                display: 'none'
                              }}
                              accept=".png"
                              id="productDesignTemplateLogoUploadInput"
                              type="file"
                              onChange={e => {
                                this.uploadProductDesignTemplateLogoFiles(
                                  e.target.files,
                                  uploadFile,
                                  product,
                                  refetch,
                                  templateId,
                                  productDesignTemplate.lastTemplateRevision
                                )
                              }}
                            />
                            <div
                              data-cy="product-designtemplatelogo-drag-and-drop-container"
                              style={{
                                border: '1px solid #ccc',
                                margin: '.5rem 0',
                                borderRadius: '4px',
                                padding: '24px',
                                background: '#f4f4f4'
                              }}
                            >
                              <FileDrop
                                onDrop={(files, event) => {
                                  console.log('handleDrop!', files, event)
                                  this.uploadProductDesignTemplateLogoFiles(
                                    files,
                                    uploadFile,
                                    product,
                                    refetch,
                                    templateId,
                                    productDesignTemplate.lastTemplateRevision
                                  )
                                }}
                              >
                                <Grid container spacing={1} direction="column">
                                  <Grid item>
                                    <span>
                                      {I18n.get('Drag your file here ')}
                                      .png
                                    </span>
                                  </Grid>
                                  <Grid item>{I18n.get('or')}</Grid>
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        document
                                          .getElementById(
                                            'productDesignTemplateLogoUploadInput'
                                          )
                                          .click()
                                      }}
                                      aria-label="Upload"
                                    >
                                      {I18n.get('Upload')}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </FileDrop>
                            </div>
                          </Grid>
                        )}
                      </Mutation>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <h3>
                        Upload template preview image
                        <Tooltip
                          className="sssd"
                          title="File size should not be more than 300MB and dimension not wider than 6000px"
                          placement="right-start"
                        >
                          <InfoIcon
                            color="primary"
                            style={{ fontSize: 15, marginLeft: 5 }}
                          />
                        </Tooltip>
                      </h3>
                      {/* {productDesignTemplate.previewImageSrc} */}
                      <img
                        style={{
                          width: '100px',
                          height: 'auto'
                        }}
                        src={productDesignTemplate.previewImageSrc}
                        alt={'Not found'}
                      />
                      <Mutation
                        update={(store, { data: { uploadFile } }) => {
                          console.log('uploadFile', uploadFile)
                          this.setState({
                            uploading: false
                          })
                          alert('Done')
                        }}
                        mutation={ADMIN_UPLOAD_FILE}
                      >
                        {uploadFile => (
                          <Grid>
                            <input
                              style={{
                                display: 'none'
                              }}
                              accept=".png"
                              id="productDesignTemplatePreviewUploadInput"
                              type="file"
                              onChange={e => {
                                this.uploadProductDesignTemplatePreviewFiles(
                                  e.target.files,
                                  uploadFile,
                                  product,
                                  refetch,
                                  templateId,
                                  productDesignTemplate.lastTemplateRevision
                                )
                              }}
                            />
                            <div
                              data-cy="product-designtemplatepreview-drag-and-drop-container"
                              style={{
                                border: '1px solid #ccc',
                                margin: '.5rem 0',
                                borderRadius: '4px',
                                padding: '24px',
                                background: '#f4f4f4'
                              }}
                            >
                              <FileDrop
                                onDrop={(files, event) => {
                                  console.log('handleDrop!', files, event)
                                  this.uploadProductDesignTemplatePreviewFiles(
                                    files,
                                    uploadFile,
                                    product,
                                    refetch,
                                    templateId,
                                    productDesignTemplate.lastTemplateRevision
                                  )
                                }}
                              >
                                <Grid container spacing={1} direction="column">
                                  <Grid item>
                                    <span>
                                      {I18n.get('Drag your file here ')}
                                      .png
                                    </span>
                                  </Grid>
                                  <Grid item>{I18n.get('or')}</Grid>
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        document
                                          .getElementById(
                                            'productDesignTemplatePreviewUploadInput'
                                          )
                                          .click()
                                      }}
                                      aria-label="Upload"
                                    >
                                      {I18n.get('Upload')}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </FileDrop>
                            </div>
                          </Grid>
                        )}
                      </Mutation>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <h3>Upload colorzone SVG</h3>
                      {/* {productDesignTemplate.colorZoneFileSrc} */}
                      <img
                        style={{
                          width: '100px',
                          height: 'auto'
                        }}
                        src={productDesignTemplate.colorZoneFileSrc}
                        alt={'Not found'}
                      />
                      <Mutation
                        update={(store, { data: { uploadFile } }) => {
                          console.log('uploadFile', uploadFile)
                          this.setState({
                            uploading: false
                          })
                          alert('Done')
                        }}
                        mutation={ADMIN_UPLOAD_FILE}
                      >
                        {uploadFile => (
                          <Grid>
                            <input
                              style={{
                                display: 'none'
                              }}
                              accept=".svg"
                              id="productDesignTemplateColorzoneUploadInput"
                              type="file"
                              onChange={e => {
                                this.uploadProductDesignTemplateColorzoneFiles(
                                  e.target.files,
                                  uploadFile,
                                  product,
                                  refetch,
                                  templateId,
                                  productDesignTemplate.lastTemplateRevision
                                )
                              }}
                            />
                            <div
                              data-cy="product-designtemplatecolorzone-drag-and-drop-container"
                              style={{
                                border: '1px solid #ccc',
                                margin: '.5rem 0',
                                borderRadius: '4px',
                                padding: '24px',
                                background: '#f4f4f4'
                              }}
                            >
                              <FileDrop
                                onDrop={(files, event) => {
                                  console.log('handleDrop!', files, event)
                                  this.uploadProductDesignTemplateColorzoneFiles(
                                    files,
                                    uploadFile,
                                    product,
                                    refetch,
                                    templateId,
                                    productDesignTemplate.lastTemplateRevision
                                  )
                                }}
                              >
                                <Grid container spacing={1} direction="column">
                                  <Grid item>
                                    <span>
                                      {I18n.get('Drag your file here ')}
                                      .svg
                                    </span>
                                  </Grid>
                                  <Grid item>{I18n.get('or')}</Grid>
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        document
                                          .getElementById(
                                            'productDesignTemplateColorzoneUploadInput'
                                          )
                                          .click()
                                      }}
                                      aria-label="Upload"
                                    >
                                      {I18n.get('Upload')}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </FileDrop>
                            </div>
                          </Grid>
                        )}
                      </Mutation>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )
        }}
      </Mutation>
    )
  }
}

export default ProductDesignTemplate
