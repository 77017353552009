import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, Checkbox } from '@material-ui/core';
import { navigate } from '@reach/router';
import { ADMIN_GET_DISCOUNT } from '../../gql/admin-queries';
import {
  ADMIN_CREATE_COUPON,
  ADMIN_GENERATE_COUPONS
} from '../../gql/admin-mutations';
import Input from '../../../common/Input';

import _uniqBy from 'lodash/uniqBy';

const styles = theme => ({
  rootCheckbox: {
    padding: '3px 8px',
    color: '#B23566',
    height: 30,
    width: 30,
    '&$checked': {
      color: '#B23566'
    }
  }
});

class AddCoupon extends Component {
  state = {
    addAutoIncrement: true,
    loading: false,
    couponCode: '',
    couponSuffix: '',
    numberOfRandomChars: 0,
    generate: false,
    numberOfCoupons: 1,
    maxUsagePerCustomer: 1,
    maxUsageOverall: 1
  };
  makeRandomUpperCaseString = length => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result.toUpperCase();
  };
  render() {
    const { classes, discount, shopId } = this.props;
    let numberOfCoupons = 1;
    let numberOfRandomChars = 0;
    let randomChars1 = '';
    let randomChars2 = '';
    try {
      numberOfCoupons = parseInt(this.state.numberOfCoupons, 10);
      numberOfRandomChars = parseInt(this.state.numberOfRandomChars, 10);
    } catch (error) {
      console.error('error', error);
    }
    try {
      randomChars1 = this.makeRandomUpperCaseString(numberOfRandomChars);

      randomChars2 = this.makeRandomUpperCaseString(numberOfRandomChars);
    } catch (error) {
      console.error('error', error);
    }

    return (
      <Mutation
        mutation={ADMIN_GENERATE_COUPONS}
        update={(cache, { data: { generateCoupons } }) => {
          if (generateCoupons) {
            if (discount && discount.id) {
              const data = cache.readQuery({
                query: ADMIN_GET_DISCOUNT,
                variables: { id: discount.id, first: 50 }
              });
              cache.writeQuery({
                query: ADMIN_GET_DISCOUNT,
                variables: { id: discount.id, first: 50 },
                data: {
                  ...data,
                  discount: {
                    ...data.discount,
                    coupons: data.discount.coupons
                      ? _uniqBy(
                          [...data.discount.coupons, ...generateCoupons],
                          'couponCode'
                        )
                      : _uniqBy([...generateCoupons], 'couponCode')
                  }
                }
              });
            } else {
              navigate(
                `/admin/${shopId}/settings/coupons/${generateCoupons[0].id}`
              );
            }
          }
        }}
      >
        {generateCoupons => (
          <Grid
            container
            spacing={1}
            alignItems="flex-end"
            justify="flex-start"
          >
            <Grid item xs={3}>
              <Input
                background="#fff"
                label="Prefix"
                onChange={e => {
                  this.setState({
                    couponPrefix: e.target.value
                  });
                }}
                value={this.state.couponPrefix}
                placeholder={'Prefix'}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                label="Suffix"
                background="#fff"
                onChange={e => {
                  this.setState({
                    couponSuffix: e.target.value ? e.target.value : ''
                  });
                }}
                value={this.state.couponSuffix}
                placeholder={'Suffix'}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                label="Random characters"
                type="number"
                background="#fff"
                onChange={e => {
                  this.setState({
                    numberOfRandomChars: e.target.value ? e.target.value : 0
                  });
                }}
                value={this.state.numberOfRandomChars}
                placeholder={'Length random characters'}
              />
            </Grid>

            <Grid item xs={3}>
              <Input
                background="#fff"
                label="Number of codes"
                onChange={e => {
                  this.setState({
                    numberOfCoupons: e.target.value
                  });
                }}
                type="number"
                value={this.state.numberOfCoupons}
                placeholder={'Number of coupons'}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                background="#fff"
                label="Max. per customer"
                onChange={e => {
                  this.setState({
                    maxUsagePerCustomer: e.target.value
                  });
                }}
                type="number"
                value={this.state.maxUsagePerCustomer}
                placeholder={'Number of coupons'}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                background="#fff"
                label="Max. overall"
                onChange={e => {
                  this.setState({
                    maxUsageOverall: e.target.value
                  });
                }}
                type="number"
                value={this.state.maxUsageOverall}
                placeholder={'Number of coupons'}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid
              item
              xs={3}
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <Typography variant="body1" align="right">
                <label for="add-autoincrement">Add auto-incremental no.</label>
                <Checkbox
                  id="add-autoincrement"
                  onChange={e => {
                    this.setState({
                      addAutoIncrement: e.target.checked
                    });
                  }}
                  classes={{
                    root: classes.rootCheckbox
                  }}
                  type="checkbox"
                  checked={
                    this.state.addAutoIncrement === true ? 'checked' : ''
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 24 }}>
              <Grid container justify="flex-end">
                <Button
                  disabled={this.state.loading === true ? true : false}
                  color="primary"
                  onClick={async () => {
                    try {
                      if (numberOfCoupons > 0 && numberOfCoupons < 9001) {
                        this.setState({
                          loading: true
                        });
                        if (numberOfCoupons > 5) {
                          alert('Generating coupons takes up to 30 seconds');
                        }
                        await generateCoupons({
                          variables: {
                            first: 50,
                            addAutoIncrement: this.state.addAutoIncrement,
                            maxUsagePerCustomer: parseInt(
                              this.state.maxUsagePerCustomer,
                              10
                            ),
                            maxUsageOverall: parseInt(
                              this.state.maxUsageOverall,
                              10
                            ),
                            couponPrefix: this.state.couponPrefix
                              ? this.state.couponPrefix
                              : '',
                            couponSuffix: this.state.couponSuffix
                              ? this.state.couponSuffix
                              : '',
                            numberOfRandomChars: numberOfRandomChars,
                            numberOfCoupons: numberOfCoupons,
                            discountId:
                              discount && discount.id ? discount.id : null
                          }
                        });
                        alert('Generated');
                        window.location.reload()
                      } else {
                        if (numberOfCoupons > 2000) {
                          // TODO make a mutation with array instead to avoid this
                          alert('Limited to 2000 coupons at a time');
                        }
                      }
                    } catch (error) {
                      alert('Failed to generate');
                      window.location.reload();
                    }
                    this.setState({
                      loading: false
                    });
                  }}
                  variant="contained"
                  size="small"
                >
                  {this.state.loading === true ? 'Generating...' : 'Generate'}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">
                Sample codes:
                <br />
                {this.state.couponPrefix}
                {randomChars1}
                {this.state.addAutoIncrement === true ? 1 : ''}
                {this.state.couponSuffix}
                <br />
                {this.state.couponPrefix}
                {randomChars2}
                {this.state.addAutoIncrement === true ? 2 : ''}
                {this.state.couponSuffix}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles)(AddCoupon);
