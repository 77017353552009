import * as THREE from 'three'

import { MTLLoader, OBJLoader } from 'three-obj-mtl-loader'
import OBJExporter from 'three-obj-exporter'
import GLTFExporter from 'three-gltf-exporter'
import GLTFLoader from 'three-gltf-loader'
import _uniq from 'lodash/uniq'
import { API_STAGE } from '../../../../brikl-config'
import transparent from '../TEMPLATE_ASSET/transparent.png'
import cotton_normal_map from '../TEMPLATE_ASSET/NORMAL_MAP/polyester.jpg'
import JSZip from 'jszip'
import pathLib from 'path'
import urlLib from 'url'

import * as tools from '../tools/tools'

try {
  if (window !== `undefined`) {
    if (!window.THREE) window.THREE = THREE
  }
} catch (error) {}

try {
  if (window !== `undefined`) {
    const fpsmeter = require('fpsmeter')
    if (!window.THREE) window.THREE = THREE
  }
} catch (error) {}

var OrbitControls = require('three-orbit-controls')(THREE)
let objLoader = new OBJLoader()
let mtlLoader = new MTLLoader()
objLoader.crossOrigin = 'anonymous'
mtlLoader.crossOrigin = 'anonymous'

var DesignRoot = null
var Main3D = null
var appType = null

export function initComponent(_Main3D) {
  Main3D = _Main3D
  DesignRoot = Main3D.props.DesignRoot
  DesignRoot.Main3D = Main3D
}

export function declareVar() {
  try {
    // statements

    logger.log(
      'declareVar',
      document.querySelector(Main3D.getBounding).offsetHeight,
      document.querySelector(Main3D.getBounding).offsetWidth
    )

    Main3D.THREEJS_WIDTH = document.querySelector(
      Main3D.getBounding
    ).offsetWidth
    Main3D.THREEJS_HEIGHT = document.querySelector(
      Main3D.getBounding
    ).offsetHeight

    Main3D.stitchURL = '/STITCH'

    Main3D.container = null
    Main3D.camera = null
    Main3D.scene = null
    Main3D.renderer = null
    Main3D.renderer_virtual = null
    Main3D.controls = null

    Main3D.main_object = null

    Main3D.manager = null
    Main3D.hoverCanvas = null
    Main3D.texture_front = new THREE.Texture()
    Main3D.textureLoader = null

    Main3D.raycaster = new THREE.Raycaster()
    Main3D.mouse = new THREE.Vector2()
    Main3D.canvasXY = document.createElement('canvas')

    Main3D.patternMaterialName = []
    Main3D.collectUrl = []
    Main3D.selectionObjectELM = null
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.declareVar.error', e)
  }
}

export function threejsINIT() {
  try {
    // statements

    Main3D.container = document.getElementById('threejs_container')
    Main3D.camera = new THREE.PerspectiveCamera(
      15,
      Main3D.THREEJS_WIDTH / Main3D.THREEJS_HEIGHT,
      0.1,
      2000
    )

    // SIZE FOR YOGA

    Main3D.camera.position.z = 2 // 1.5 for crop

    Main3D.scene = new THREE.Scene()
    // Main3D.scene.background = new THREE.WebGLRenderer({ alpha: true });
    // Main3D.scene.background = new THREE.Color(0xf5f5f5);
    Main3D.scene.background = new THREE.Color('rgba(245, 245, 245)')

    Main3D.renderer = new THREE.WebGLRenderer({
      antialias: DesignRoot._ANTIALIAS,
      preserveDrawingBuffer: true,
      alpha: true
    })

    Main3D.renderer.setClearColor(0xf5f5f5, 1) //default
    Main3D.renderer.setPixelRatio(window.devicePixelRatio)
    Main3D.renderer.setSize(Main3D.THREEJS_WIDTH, Main3D.THREEJS_HEIGHT)
    Main3D.renderer.domElement.id = 'THREEJS'
    Main3D.renderer.domElement.style.backgroundColor = '#f5f5f5'
    Main3D.container.appendChild(Main3D.renderer.domElement)

    logger.log(
      'getMaxAnisotropy',
      Main3D.renderer.capabilities.getMaxAnisotropy()
    )

    // RENDERER VIRTUAL
    Main3D.renderer_virtual = new THREE.WebGLRenderer({
      antialias: DesignRoot._ANTIALIAS,
      preserveDrawingBuffer: true,
      alpha: true
    })
    Main3D.renderer_virtual.setClearColor(0xf5f5f5, 0) //default
    Main3D.renderer_virtual.setPixelRatio(window.devicePixelRatio)
    Main3D.renderer_virtual.setSize(Main3D.THREEJS_WIDTH, Main3D.THREEJS_HEIGHT)
    Main3D.renderer_virtual.domElement.id = 'THREEJS_VIRTUAL'
    // Main3D.renderer_virtual.domElement.style.display = 'none';
    // Main3D.container.appendChild(Main3D.renderer_virtual);
    // RENDERER VIRTUAL

    Main3D.storeMappingChild = []
    Main3D.storeMappingMaterialName = []
    Main3D.storeMappingObject = []
    Main3D.storeMappingObjectGroup = []
    Main3D.storeMappingObjectGroupArray = []
    Main3D.storeMappingObjectNoTemplate = []
    Main3D.storeMappingNoTemplateMaterialName = []
    Main3D.storeMappingObjectNoTemplateGroup = []
    Main3D.storeMappingObjectNoTemplateGroupArray = []

    // CONTROL POS

    Main3D.lastX = 0
    Main3D.lastY = 0

    // CONTROL POS

    Main3D.onControl = false
    Main3D.directELM = null

    Main3D.meter = null

    Main3D.export3D = false
    Main3D.export3DTransparent = false

    Main3D.normalMapTexture = null

    if (API_STAGE === 'staging' || DesignRoot._DEV_TOOL === true) {
      Main3D.meter = new window.FPSMeter({
        smoothing: 1,
        graph: 1,
        heat: 0,
        decimals: 0,
        maxFps: 60,
        theme: 'dark',
        position: 'fixed',
        top: '-1',
        left: '0',
        bottom: '5px',
        right: '-1',
        padding: '0',
        margin: '0 0 0 0'
      })
    }

    Main3D.scene.add(Main3D.camera)

    lightSetup()
    helperSetup()
    toolsSetup()

    loadModel()

    eventSetup()

    start()
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.threejsINIT.error', e)
  }
}

export function start() {
  try {
    // statements
    if (!Main3D.frameId) {
      Main3D.frameId = requestAnimationFrame(animate.bind(Main3D))
    }
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.start.error', e)
  }
}

export function stop() {
  try {
    // statements
    cancelAnimationFrame(Main3D.frameId)
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.stop.error', e)
  }
}

export function animate() {
  try {
    // statements
    renderScene()
    Main3D.frameId = window.requestAnimationFrame(animate.bind(Main3D))
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.animate.error', e)
  }
}

export function renderScene() {
  try {
    // statements

    if (Main3D.capture3D === true) {
      // CHECK CAPTURE SET LOOP
      let view = '0'

      if (Main3D.captureSET['0'] === null) {
        view = '0'
      } else if (Main3D.captureSET['180'] === null) {
        view = '180'
      } else if (Main3D.captureSET['90'] === null) {
        view = '90'
      } else if (Main3D.captureSET['270'] === null) {
        view = '270'
      }

      // CHECK CAPTURE SET LOOP

      Main3D.scene.background = new THREE.Color(0xffffff)
      Main3D.captureDirection = view
      DesignRoot.control_view.changeView(view)
      Main3D.renderer_virtual.render(Main3D.scene, Main3D.camera)
      DesignRoot.template_control.makeCapture()

      if (
        Main3D.captureSET['0'] !== null &&
        Main3D.captureSET['180'] !== null &&
        Main3D.captureSET['90'] !== null &&
        Main3D.captureSET['270'] !== null
      ) {
        // logger.log(' Main3D.captureSET', Main3D.captureSET);

        Main3D.camera.position.set(
          Main3D.currentView.x,
          Main3D.currentView.y,
          Main3D.currentView.z
        )

        Main3D.controls.target.x = Main3D.currentPan.x
        Main3D.controls.target.y = Main3D.currentPan.y
        Main3D.controls.target.z = Main3D.currentPan.z

        Main3D.controls.update()
        Main3D.scene.background = null
        Main3D.capture3D = false

        // STATEMENT HERE AFTER AL CAPTURE
      }
    } else {
      if (Main3D.export3D === true) {
        Main3D.scene.background = new THREE.Color(0xffffff)
        Main3D.renderer.render(Main3D.scene, Main3D.camera)
        DesignRoot.template_control.export3DScene()
        Main3D.scene.background = new THREE.Color('rgba(245, 245, 245)')
        Main3D.export3D = false
      } else if (Main3D.export3DTransparent === true) {
        Main3D.renderer_virtual.render(Main3D.scene, Main3D.camera)
        DesignRoot.template_control.export3DScene('transparent')
        Main3D.export3DTransparent = false
      } else {
        // Main3D.renderer_virtual.render(Main3D.scene, Main3D.camera);
        Main3D.renderer.render(Main3D.scene, Main3D.camera)
      }
    }

    if (Main3D.meter !== null) {
      Main3D.meter.tick()
    }

    if (Main3D.controls.autoRotate === true) {
      Main3D.controls.update()
    }
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.renderScene.error', e)
  }
}

export function eventSetup() {
  try {
    // statements

    window.addEventListener('resize', Main3D.onWindowResize.bind(Main3D), false)
    document
      .querySelector('#THREEJS')
      .addEventListener('click', onDocumentClick, false)

    document
      .querySelector('#THREEJS')
      .addEventListener('mousemove', onDocumentMouseMove, false)
    document
      .querySelector('#THREEJS')
      .addEventListener('mousedown', onDocumentMouseDown, false)
    document
      .querySelector('#THREEJS')
      .addEventListener('mouseup', onDocumentMouseUp, false)
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.eventSetup.error', e)
  }
}

export function lightSetup() {
  try {
    // statements

    const ambient = new THREE.AmbientLight(0xffffff, 0.6)
    Main3D.scene.add(ambient)

    const spotLightTwo = new THREE.SpotLight(0xffffff, 0.6)
    // camera.add(spotLightTwo);
    spotLightTwo.position.set(0, 500, 500)
    spotLightTwo.angle = Math.PI / 2
    // spotLightTwo.castShadow = true;
    spotLightTwo.penumbra = 0.1
    spotLightTwo.decay = 2
    spotLightTwo.distance = 4000
    spotLightTwo.shadow.mapSize.width = 1000
    spotLightTwo.shadow.mapSize.height = 1000
    Main3D.camera.add(spotLightTwo)
    Main3D.scene.add(Main3D.camera)

    const HemisphereLight = new THREE.HemisphereLight(0xffffff, 0xbbbbbb, 0.1)
    Main3D.scene.add(HemisphereLight)
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.lightSetup.error', e)
  }
}

export function helperSetup() {
  try {
    // statements

    Main3D.controls = new OrbitControls(
      Main3D.camera,
      Main3D.renderer.domElement
    )

    Main3D.controls.enableKeys = false
    Main3D.controls.autoRotate = false
    Main3D.controls.autoRotateSpeed = 5

    Main3D.controls.addEventListener(
      'change',
      onControlsChange.bind(this),
      false
    )
    Main3D.controls.addEventListener('end', onControlsEnd.bind(this), false)

    const axeHelper = new THREE.AxesHelper(10)
    // Main3D.scene.add(axeHelper);
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.helperSetup.error', e)
  }
}

export function toolsSetup() {
  try {
    // statements

    Main3D.manager = new THREE.LoadingManager()
    Main3D.textureLoader = new THREE.TextureLoader(Main3D.manager) // USE FOR LOAD ANY TEXTURE

    Main3D.normalMapTexture = new THREE.TextureLoader().load(cotton_normal_map)
    Main3D.normalMapTexture.wrapS = Main3D.normalMapTexture.wrapT =
      THREE.RepeatWrapping

    Main3D.manager.onStart = function(url, itemsLoaded, itemsTotal) {
      logger.log('LoadingManager')
      logger.log(
        'Started loading texture file: ' +
          url +
          '.\nLoaded ' +
          itemsLoaded +
          ' of ' +
          itemsTotal +
          ' files.'
      )
    }

    Main3D.manager.onLoad = function() {
      logger.log('Loading texture complete!')
    }

    Main3D.manager.onProgress = function(url, itemsLoaded, itemsTotal) {
      logger.log(
        'Loading texture file: ' +
          url +
          '.\nLoaded ' +
          itemsLoaded +
          ' of ' +
          itemsTotal +
          ' files.'
      )
    }
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.toolsSetup.error', e)
  }
}

export function loadModel() {
  try {
    // statements

    logger.log('Main3D', Main3D)
    const { productDataSet } = Main3D.props
    const model_obj = productDataSet.OBJ
    const model_mtl = productDataSet.MTL
    const MTL_PATTERN_PREFIX = productDataSet.MTL_PATTERN_PREFIX

    if (
      DesignRoot._ACTIVE_COLOR_ZONE === true &&
      DesignRoot._ACTIVE_TEMPLATE_ID !== 'BLANK'
    ) {
      var local_pattern =
        productDataSet.TEMPLATE_SET_BY_ID[DesignRoot._ACTIVE_TEMPLATE_ID]
          .templateSrc
    } else {
      var local_pattern = transparent
    }

    var patternNameCheck = MTL_PATTERN_PREFIX
    var stitchRoot = Main3D.stitchURL
    var hasPatternPrefix = false

    mtlLoader.load(model_mtl, materials => {
      logger.log('materials', materials)

      // GET PATTERN MATERIAL

      // EXAMPLE RPED-Wider-Basic_Offset_48741
      let materialsInfoArr = []

      for (var key in materials.materialsInfo) {
        if (materials.materialsInfo.hasOwnProperty(key)) {
          var materialsInfo = materials.materialsInfo[key]

          if (materialsInfo['map_bump']) {
            if (materialsInfo['map_bump'].indexOf('cdn.') === -1) {
              var urlSplit = materialsInfo['map_bump'].split('/')
              var pureUrl = urlSplit[urlSplit.length - 1]
              materialsInfo['map_bump'] = stitchRoot + '/' + pureUrl
              // collectUrl[pureUrl] =  stitchRoot+"/"+pureUrl;
              // Main3D.collectUrl.push(materialsInfo['map_bump']);
            }
          }

          if (materialsInfo['map_ka']) {
            // GET MATERAIL PATTERN NAME
            if (materialsInfo['map_ka'].indexOf(patternNameCheck) !== -1) {
              hasPatternPrefix = true
              Main3D.patternMaterialName.push(key)
              materialsInfo['map_ka'] = local_pattern
            } else if (materialsInfo['map_ka'].indexOf('cdn.') === -1) {
              var urlSplit = materialsInfo['map_ka'].split('/')
              var pureUrl = urlSplit[urlSplit.length - 1]
              pureUrl = regexStitchName(pureUrl)
              materialsInfo['map_ka'] = stitchRoot + '/' + pureUrl
            }
          }

          if (materialsInfo['map_kd']) {
            // GET MATERAIL PATTERN NAME
            if (materialsInfo['map_kd'].indexOf(patternNameCheck) !== -1) {
              hasPatternPrefix = true
              Main3D.patternMaterialName.push(key)
              materialsInfo['map_kd'] = local_pattern
            } else if (materialsInfo['map_kd'].indexOf('cdn.') === -1) {
              var urlSplit = materialsInfo['map_kd'].split('/')
              var pureUrl = urlSplit[urlSplit.length - 1]
              pureUrl = regexStitchName(pureUrl)
              materialsInfo['map_kd'] = stitchRoot + '/' + pureUrl
            }
          }
        }
        materialsInfoArr.push(materialsInfo)
      }

      if (hasPatternPrefix === false) {
        // NO PATTERN PREFIX THEN IT CAN'T RENDER FROM CANVAS TO MODEL
        alert(
          'WARNING : NO PRINT PATTERN IN MODEL. PLEASE CONTACT ADMINISTRATOR.'
        )
      }

      Main3D.patternMaterialName = _uniq(Main3D.patternMaterialName)
      Main3D.collectUrl = _uniq(Main3D.collectUrl)

      logger.log('Main3D.patternMaterialName', Main3D.patternMaterialName)

      // GET PATTERN MATERIAL

      materials.preload()
      objLoader.setMaterials(materials)

      objLoader.load(
        model_obj,
        object => {
          logger.log('object', object, object.children)
          loadModelProcess(object)
        },
        xhr => {
          // called while loading is progressing
          logger.log(`${(xhr.loaded / xhr.total) * 100}% loaded`)
        }
      )
    })
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.loadModel.error', e)
  }
}

export function loadModelProcess(object) {
  try {
    // statements

    var boxScale = new THREE.Box3().setFromObject(object)

    var xLength = boxScale.max.x - boxScale.min.x
    var yLength = boxScale.max.y - boxScale.min.y

    logger.log('boxScale', boxScale, xLength, yLength)

    if (xLength > yLength) {
      var modelScale = 0.35 / xLength
    } else {
      var modelScale = 0.35 / yLength
    }

    logger.log(modelScale)

    for (var c = 0; c < object.children.length; c++) {
      var child = object.children[c]

      var checkMapMaterial = false

      if (child.material.constructor === Array) {
        for (var i = 0; i < child.material.length; i++) {
          if (
            Main3D.patternMaterialName.indexOf(child.material[i].name) !== -1
          ) {
            // child.material[i].map = mapOverlay;
            Main3D.storeMappingChild.push(child.material[i])
            var materialName = child.material[i].name
            Main3D.storeMappingMaterialName[materialName] = child.material[i]
            child.material[i].map.image = document.getElementById(
              DesignRoot.canvasMainDom
            )
            checkMapMaterial = true
            // logger.log('map array', child);
            child.material[i].transparent = true
            child.material[i].needsUpdate = true
            additionalMap(child.material[i])
            normalMap(child.material[i])
          } else if (child.material[i].map && child.material[i].map.image) {
            child.material[i].transparent = true
            // child.material[i].color.set('#FFF');
            child.material[i].userData.defaultColor = child.material[
              i
            ].color.getHexString()
            child.material[i].needsUpdate = true
            additionalMap(child.material[i])
            normalMap(child.material[i])
            var materialName = child.material[i].name
            Main3D.storeMappingNoTemplateMaterialName[materialName] =
              child.material[i]
          } else {
            child.material[i].userData.defaultColor = child.material[
              i
            ].color.getHexString()
            child.material[i].needsUpdate = true
            child.material[i].transparent = true
            additionalMap(child.material[i])
            normalMap(child.material[i], 'MAP')
          }

          // child.material[i].map = mapOverlay;
          // logger.log('map array', child);
        }

        // var insideMat = child.material[1].clone();
        // insideMat.color.set('#969696');
        // insideMat.emissive.set("#d1d1d1");
        // insideMat.emissiveIntensity = 0.5;
        // insideMat.shininess = 0;
        // insideMat.lights = false;
        // insideMat.opacity = 0.2;
        // child.material[1] = insideMat;
      } else if (
        Main3D.patternMaterialName.indexOf(child.material.name) !== -1
      ) {
        // child.material.map = mapOverlay;
        // logger.log('map', child);
        Main3D.storeMappingChild.push(child.material)
        var materialName = child.material.name
        Main3D.storeMappingMaterialName[materialName] = child.material
        child.material.map.image = document.getElementById(
          DesignRoot.canvasMainDom
        )
        checkMapMaterial = true
        child.material.transparent = true
        child.material.needsUpdate = true
        additionalMap(child.material)
        normalMap(child.material)
      } else if (child.material.map && child.material.map.image) {
        child.material.transparent = true
        // child.material.color.set('#FFF');
        child.material.userData.defaultColor = child.material.color.getHexString()
        child.material.needsUpdate = true
        additionalMap(child.material)
        normalMap(child.material)
        var materialName = child.material.name
        Main3D.storeMappingNoTemplateMaterialName[materialName] = child.material
      } else {
        child.material.userData.defaultColor = child.material.color.getHexString()
        child.material.transparent = true
        child.material.needsUpdate = true
        additionalMap(child.material)
        normalMap(child.material, 'MAP')
      }

      child.scale.set(modelScale, modelScale, modelScale)

      if (checkMapMaterial === true) {
        Main3D.storeMappingObject.push(child)
      } else {
        child.userData.hasChange = false
        Main3D.storeMappingObjectNoTemplate.push(child)
      }
    }

    var box = new THREE.Box3().setFromObject(object)
    box.getCenter(object.position) // Main3D re-sets the object position
    object.position.multiplyScalar(-1)

    Main3D.main_object = object
    Main3D.scene.add(Main3D.main_object)

    logger.log('Main3D.storeMappingChild', Main3D.storeMappingChild)
    logger.log('Main3D.storeMappingObject', Main3D.storeMappingObject)
    logger.log(
      'Main3D.storeMappingObjectNoTemplate',
      Main3D.storeMappingObjectNoTemplate
    )
    logger.log(
      'Main3D.storeMappingMaterialName',
      Main3D.storeMappingMaterialName
    )

    logger.log(
      'Main3D.storeMappingNoTemplateMaterialName',
      Main3D.storeMappingNoTemplateMaterialName
    )

    // ADJUST STITCH

    // if ( DesignRoot.Main3D.storeMappingNoTemplateMaterialName !== null ) {

    //   for (var key in DesignRoot.Main3D.storeMappingNoTemplateMaterialName) {
    //     if (DesignRoot.Main3D.storeMappingNoTemplateMaterialName.hasOwnProperty(key)) {

    //         DesignRoot.Main3D.storeMappingNoTemplateMaterialName[key].map.repeat.set( 0.7, 1 );
    //     }
    //   }
    // }

    // ADJUST STITCH

    buildGroupELM()

    if (DesignRoot.onLoad === true) {
      // LOAD DIRECT COLOR

      logger.log(
        'LOAD DIRECT COLOR IN',
        DesignRoot.onLoad,
        DesignRoot.loadData.directElm,
        DesignRoot.loadData
      )

      for (var i = 0; i < Main3D.storeMappingObjectNoTemplate.length; i++) {
        var child = Main3D.storeMappingObjectNoTemplate[i]

        if (
          DesignRoot.loadData.directElm &&
          DesignRoot.loadData.directElm[child.name] &&
          DesignRoot.loadData.directElm[child.name] !== undefined &&
          DesignRoot.loadData.directElm[child.name].color !== null
        ) {
          logger.log('FILL', DesignRoot.loadData.directElm[child.name])

          DesignRoot.change_color.changeColorPickerSelectedDirectELM(
            DesignRoot.loadData.directElm[child.name].color,
            child,
            DesignRoot.loadData.directElm[child.name].id
          )
        }
      }
    }

    // AFTER LOAD MODEL FINISH

    initComponent(Main3D)
    DesignRoot.design_template.canvascolor_fabricjs_init()

    // AFTER LOAD MODEL FINISH
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.loadModelProcess.error', e)
  }
}

export function floorPowerOfTwo(value) {
  return Math.pow(2, Math.floor(Math.log(value) / Math.LN2))
}

var _canvas
export function makePowerOfTwoSquare(image) {
  if (
    image instanceof HTMLImageElement ||
    image instanceof HTMLCanvasElement ||
    image instanceof ImageBitmap
  ) {
    if (_canvas === undefined)
      _canvas = document.createElementNS(
        'http://www.w3.org/1999/xhtml',
        'canvas'
      )

    // _canvas.width = floorPowerOfTwo(image.width)
    // _canvas.height = floorPowerOfTwo(image.height)

    // var context = _canvas.getContext('2d')
    // context.drawImage(image, 0, 0, _canvas.width, _canvas.height)

    // console.log(_canvas, _canvas.toDataURL())

    // Create our primary canvas and fill it with the pattern
    const _canvasRepeat = document.createElement('canvas')
    var ratio = Math.round(image.width / image.height)
    _canvasRepeat.width = image.width
    _canvasRepeat.height = image.height * ratio
    const ctx = _canvasRepeat.getContext('2d')
    const pattern = ctx.createPattern(image, 'repeat')
    ctx.fillStyle = pattern
    ctx.fillRect(0, 0, _canvasRepeat.width, _canvasRepeat.height)

    // console.warn( 'THREE.WebGLRenderer: image is not power of two (' + image.width + 'x' + image.height + '). Resized to ' + _canvas.width + 'x' + _canvas.height, image, _canvas.toDataURL() );
    // console.log('makePowerOfTwoSquare', canvas, canvas.toDataURL())

    return _canvasRepeat
  }

  return image
}

var repeat = '1.0'
var intensity = '0.5'

var repeatSET = {}
var intensitySET = {}

export async function normalMap(material, option) {
  try {
    // statements

    var normalMapActive = false

    // wait for DesignRoot._ARTBOARD_DIMENSION
    let promise = new Promise((resolve, reject) => {
      let round = 0
      let interval = setInterval(() => {
        if (
          DesignRoot._ARTBOARD_DIMENSION &&
          DesignRoot._ARTBOARD_DIMENSION.width > 1
        ) {
          clearInterval(interval)
          resolve(true)
        }
        if (round > 20) {
          clearInterval(interval)
          reject(false)
        }
        round++
      }, 200)
    })

    await promise
    /////////////////////////////////////////

    if (material.bumpMap !== null) {
      if (DesignRoot._NORMAL_MAP_ACTIVE === true) {
        normalMapActive = true
        material.normalMap = material.bumpMap

        logger.log(
          'normalMap',
          material
          // material.normalMap.image,
          // material.bumpMap.image
        )

        if (
          material.normalMap.image !== undefined &&
          material.normalMap.image.src
        ) {
          var normalMap_SRC = material.normalMap.image.src

          var NRM_INTENSITY = normalMap_SRC.match(/(NRM_\d*.\d*)/g)
          var CHECK_REPEAT = normalMap_SRC.match(/(R\d*.\d*)/g) // CHECK REPEAT 1

          logger.log(
            'INTENSITY MAP',
            NRM_INTENSITY,
            CHECK_REPEAT,
            normalMap_SRC,
            material.normalMap
          )

          if (NRM_INTENSITY !== null) {
            intensity = NRM_INTENSITY[0].replace('NRM_', '').replace('.', '')
            intensity = parseInt(intensity)
            intensity += 15 // FORCE SIZE FACTOR
            // intensity = intensity / 100
            intensity = '0.' + intensity
          }

          logger.log('_ARTBOARD_DIMENSION', DesignRoot._ARTBOARD_DIMENSION)

          logger.log(
            'floorPowerOfTwo',
            floorPowerOfTwo(DesignRoot._ARTBOARD_DIMENSION.width)
          )
          logger.log(
            'floorPowerOfTwo',
            floorPowerOfTwo(DesignRoot._ARTBOARD_DIMENSION.height)
          )

          // CALCULATE POWER OF 2 TO FIND REPEAT

          var pattern_pw2_w = floorPowerOfTwo(
            DesignRoot._ARTBOARD_DIMENSION.width
          )
          var pattern_pw2_h = floorPowerOfTwo(
            DesignRoot._ARTBOARD_DIMENSION.height
          )

          var nm_pw2_w = floorPowerOfTwo(material.normalMap.image.width)
          var nm_pw2_h = floorPowerOfTwo(material.normalMap.image.height)

          var factor_repeat = 2

          if (nm_pw2_w !== nm_pw2_h) {
            // IF IMAGE NOT SQUARE SO GENERATE CANVAS THAT FIT POWER OF 2

            material.normalMap.image = makePowerOfTwoSquare(
              material.normalMap.image
            )

            factor_repeat = 1.1
          } else if (nm_pw2_w === pattern_pw2_w || nm_pw2_h === pattern_pw2_h) {
            factor_repeat = 1
          }

          var r = pattern_pw2_w / nm_pw2_w
          repeat = (r * r) / factor_repeat // FORCE SIZE FACTOR

          CHECK_REPEAT = CHECK_REPEAT[0].replace('R', '')
          CHECK_REPEAT = parseInt(CHECK_REPEAT)

          if (CHECK_REPEAT === 1) {
            repeat = 1
          }

          // CALCULATE POWER OF 2 TO FIND REPEAT

          // if (normalMap_SRC.indexOf('Aero_') !== -1) {
          //   repeat = '20.0'
          //   intensity = '0.35'
          // }

          repeatSET[material.name] = repeat
          intensitySET[material.name] = intensity

          logger.log(
            'REPEAT MAP',
            repeat,
            pattern_pw2_w,
            pattern_pw2_h,
            nm_pw2_w,
            nm_pw2_h
          )
        }
      }

      // var normalScale = new THREE.Vector2();
      // normalScale.x = 0.1;
      // normalScale.y = 0.1;
      // material.normalScale = normalScale;

      if (option && option === 'MAP') {
        // material.bumpScale = 0.5;
      }

      material.bumpMap = null
      material.needsUpdate = true
    } else {
      material.bumpMap = null
      material.needsUpdate = true
    }

    // TEST NORMAL MAP

    if (DesignRoot._NORMAL_MAP === true || normalMapActive === true) {
      material.onBeforeCompile = function(shader, x) {
        logger.log('onBeforeCompile', shader, x)

        // materialShader = shader;

        var R = 1
        var IN = 0.5

        if (repeatSET[this.name]) {
          R = repeatSET[this.name]
        }

        if (intensitySET[this.name]) {
          IN = intensitySET[this.name]
        }

        logger.log('REPEAT INTENSITY COMPILE', R, IN, repeatSET, intensitySET)

        shader.uniforms.R = { value: parseFloat(R) }
        shader.uniforms.IN = { value: parseFloat(IN) }

        shader.fragmentShader = shader.fragmentShader.replace(
          '#include <normalmap_pars_fragment>',
          [
            `#ifdef USE_NORMALMAP
              uniform sampler2D normalMap;
              uniform vec2 normalScale;
              uniform float R;
              uniform float IN;
              vec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm ) {
                vec3 q0 = vec3( dFdx( eye_pos.x ), dFdx( eye_pos.y ), dFdx( eye_pos.z ) );
                vec3 q1 = vec3( dFdy( eye_pos.x ), dFdy( eye_pos.y ), dFdy( eye_pos.z ) );
                vec2 st0 = dFdx( vUv.st );
                vec2 st1 = dFdy( vUv.st );
                float scale = sign( st1.t * st0.s - st0.t * st1.s );
                vec3 S = normalize( ( q0 * st1.t - q1 * st0.t ) * scale );
                vec3 T = normalize( ( - q0 * st1.s + q1 * st0.s ) * scale );
                vec3 N = normalize( surf_norm );
                mat3 tsn = mat3( S, T, N );
                vec3 mapN = texture2D( normalMap, vUv * R ).xyz * 2.0 - 1.0; // REPEAT NORMAL MAP
                mapN.xy *= (normalScale * IN); // NORMAL MAP INTENSITY
                mapN.xy *= ( float( gl_FrontFacing ) * 2.0 - 1.0 );
                return normalize( tsn * mapN );
              }
            #endif`
          ].join('\n')
        )

        // this.userData.R = R
        // this.userData.IN = IN
        // this.userData.fragmentShader = shader.fragmentShader
      }

      // material.normalMap = Main3D.normalMapTexture
    }

    // TEST NORMAL MAP
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.normalMap.error', e)
  }
}

export function additionalMap(material) {
  try {
    // statements

    if (material.map && Main3D.renderer.capabilities.getMaxAnisotropy()) {
      material.map.anisotropy =
        Main3D.renderer.capabilities.getMaxAnisotropy() / 2
    }

    if (
      DesignRoot.SHOP_ID === 'bodywearlab' ||
      DesignRoot.SHOP_ID === 'ionac' ||
      DesignRoot.SHOP_ID === '35ce823f-3aa7-4467-95c8-a451bcfcdf51'
    ) {
      material.side = THREE.DoubleSide
    } else if (DesignRoot.SHOP_ID === 'demo') {
      material.color.set('#ffffff')
    }
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.additionalMap.error', e)
  }
}

export function onControlsChange(event) {
  Main3D.onControl = true
}

export function onControlsEnd(event) {
  Main3D.onControl = false
}

export function onDocumentClick(event) {
  // logger.log('onDocumentClick', onControl);
  checkIntersect('click', event)
}

export function onDocumentMouseMove(event) {
  // if ( DesignRoot.mouseDown === true ) {

  // Main3D.controls.enabled = false;
  //   }
  //   else {

  //     Main3D.controls.enabled = true;
  //   }

  // Main3D.controls.enabled = false;

  // logger.log('mouse move', event);
  // var hoverCanvas = event.target.id;

  // if ( hoverCanvas === 'THREEJS' ) {

  //   Main3D.controls.enabled = true;
  //   checkIntersect('mousemove', event);
  // }
  // else {

  //   Main3D.controls.enabled = false;
  // }

  if (Main3D.onControl === false) {
    checkIntersect('mousemove', event)
  }
}

export function onDocumentMouseDown(event) {
  checkIntersect('mousedown', event)
}

export function onDocumentMouseUp(event) {
  DesignRoot.mouseDown = false
  checkIntersect('mouseup', event)
}

export function checkIntersect(option, event) {
  try {
    // statements

    // logger.log('checkIntersect', option);

    // document.body.style.cursor = 'default';

    if (DesignRoot._DISPATCH_EVENT === false) {
      return
    }

    // GET MOUSE POSITION

    var rect = Main3D.renderer.domElement.getBoundingClientRect()

    // Main3D.mouse.x =
    //   ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
    // Main3D.mouse.y =
    //   -((event.clientY - rect.top + 0) / (rect.bottom - rect.top + 0)) * 2 + 1;

    Main3D.mouse.x = (event.offsetX / Main3D.THREEJS_WIDTH) * 2 - 1
    Main3D.mouse.y = -(event.offsetY / Main3D.THREEJS_HEIGHT) * 2 + 1

    // GET MOUSE POSITION

    Main3D.raycaster.setFromCamera(Main3D.mouse, Main3D.camera)

    var intersects = Main3D.raycaster.intersectObjects(
      Main3D.main_object.children,
      true
    )

    var intersectsOBJ = null

    // logger.log("checkIntersect", intersects, rect, Main3D.mouse);

    if (intersects.length) {
      if (DesignRoot.bucketStatus === true) {
        document.body.style.cursor = "url('TEMPLATE/bucket.png') 18 20, auto"
      } else {
        // document.body.style.cursor = 'crosshair';
      }

      intersectsOBJ = intersects[0]
    } else if (option === 'mousemove') {
      // NO INTERSECT
      // DesignRoot.svg_control.svg_border_default_set()
    }

    if (intersectsOBJ !== null) {
      // CHECK OUTBOUND HOVER

      // DEBUG UV IN ELM
      // intersectsOBJ.uv.x = 0.5135080218315125;
      // intersectsOBJ.uv.y = -0.8589289784431458;
      // DEBUG UV IN ELM

      if (option === 'click' || option === 'mousedown') {
        // GET ELM NAME EVERY CLICK
        Main3D.selectionObjectELM = intersectsOBJ.object
        logger.log('intersectsOBJ', intersectsOBJ, intersects)
      }

      var checkHoverBounce = false
      for (var i = 0; i < Main3D.storeMappingObject.length; i++) {
        if (intersectsOBJ.object.name === Main3D.storeMappingObject[i].name) {
          checkHoverBounce = true
        }
      }

      if (checkHoverBounce === false) {
        // CHECK AREA OUT OF CANVAS (STITCH)
        var pixelX = Main3D.lastX
        var pixelY = Main3D.lastY
      } else {
        var pixel = calculateUV(intersectsOBJ.uv)
        var pixelX = pixel.x
        var pixelY = pixel.y
      }

      // CHECK OUTBOUND HOVER
      // logger.log('pixelXY FIRST (', pixelX, ',', pixelY, ')', intersectsOBJ.uv);

      Main3D.lastX = pixelX
      Main3D.lastY = pixelY

      if (option === 'click') {
        logger.log('intersectsOBJ', intersectsOBJ)

        if (checkOutOfTemplateElement(intersectsOBJ.object)) {
          Main3D.directELM = intersectsOBJ.object
          DesignRoot.Selection.setState({
            selectDirectELM: Main3D.directELM.name,
            selectDirectELMObject: Main3D.directELM
          })

          if (DesignRoot._ACTIVE_COLOR_ZONE === true) {
            DesignRoot.FillStep.setState({
              selectColor: Main3D.directELM.name + '_ELM_',
              selectType: 'ELM',
              color: DesignRoot.change_color.returnCurrentColor(
                Main3D.directELM,
                'ELM'
              )
            })
          } else {
            DesignRoot.FillStep.setState({
              colorSVG: DesignRoot.change_color.returnCurrentColor(
                Main3D.directELM,
                'ELM'
              )
            })
          }

          return false
        } else {
          Main3D.directELM = null
          DesignRoot.Selection.setState({
            selectDirectELM: null,
            selectDirectELMObject: null
          })
        }

        logger.log('pixelXY (', pixelX, ',', pixelY, ')', intersectsOBJ.uv)

        if (DesignRoot._ACTIVE_DRAW_XY === true) {
          // drawCanvasXY(pixelX, pixelY, option, event);
        }

        // DesignRoot.fabric_function.drawUVCoord(pixelX, pixelY, option, event);
      } // END option === 'click'

      // [0.5, 0, 0, 0.5, 0, 0]
      // [0.5, 0, 0, 0.5, 11, 0]

      if (option === 'mousemove') {
        DesignRoot.fabric_function.drawUVCoord(pixelX, pixelY, option, event)

        var rect = document
          .querySelector('.upper-canvas')
          .getBoundingClientRect()
        // logger.log('getBoundingClientRect', rect);

        // CONTROL ClientRect

        if (DesignRoot.isDashboard) {
          if (rect.top < 0) {
            DesignRoot._GAP_Y = rect.top
          } else {
            DesignRoot._GAP_Y = rect.top
          }

          if (rect.left < 0) {
            DesignRoot._GAP_X = rect.left
          } else {
            DesignRoot._GAP_X = rect.left
          }
        }

        if (DesignRoot.canvas.viewportTransform) {
          DesignRoot._GAP_X += DesignRoot.canvas.viewportTransform[4]
          DesignRoot._GAP_Y += DesignRoot.canvas.viewportTransform[5]
        }

        // CONTROL ClientRect

        if (DesignRoot.mouseDown === true) {
          logger.log('targetObjectHover', DesignRoot.targetObjectHover)

          if (
            DesignRoot.targetObjectHover !== null &&
            (DesignRoot.targetObjectHover.id === 'template' ||
              DesignRoot.targetObjectHover._OBJECT_TYPE === 'SVG' ||
              DesignRoot.targetObjectHover._OBJECT_TYPE === 'SVG_COLORZONE' ||
              DesignRoot.targetObjectHover._OBJECT_TYPE === 'SVG_LOGOZONE' ||
              DesignRoot.targetObjectHover._OBJECT_TYPE === 'SVG_AOP' ||
              (DesignRoot.targetObjectHover._OBJECT_TYPE === 'LOGO' &&
                DesignRoot.targetObjectHover.lockMovementX === true) ||
              (DesignRoot.targetObjectHover._OBJECT_TYPE === 'TEXT' &&
                DesignRoot.targetObjectHover.lockMovementX === true) ||
              (DesignRoot.targetObjectHover._OBJECT_TYPE === 'PATTERN' &&
                DesignRoot.targetObjectHover.lockMovementX === true) ||
              DesignRoot.targetObjectHover._DEFAULT_ITEM) &&
            DesignRoot.onAddLogo === false
          ) {
            Main3D.controls.enabled = true
            return false
          } else {
            Main3D.controls.enabled = false
          }

          logger.log('targetControl', DesignRoot.targetControl)

          if (
            DesignRoot.targetControl !== null &&
            DesignRoot.targetControl !== 0 &&
            DesignRoot.targetControl !== undefined
          ) {
            logger.log('virtual mousedown', event)
            var evt = new MouseEvent('mousedown', {
              clientX:
                DesignRoot.targetObject.oCoords[DesignRoot.targetControl].x +
                DesignRoot._GAP_X -
                DesignRoot.canvas.viewportTransform[4],
              clientY:
                DesignRoot.targetObject.oCoords[DesignRoot.targetControl].y +
                DesignRoot._GAP_Y -
                DesignRoot.canvas.viewportTransform[5],
              bubbles: true
            })
          } else if (DesignRoot.targetObject !== null) {
            logger.log('virtual mousedown', event)
            logger.log('targetObjectPos', DesignRoot.targetObjectPos)
            var evt = new MouseEvent('mousedown', {
              clientX: DesignRoot.targetObjectPos.left,
              clientY: DesignRoot.targetObjectPos.top,
              bubbles: true
            })
          } else {
            logger.log('virtual mousedown', event)
            var evt = new MouseEvent('mousedown', {
              clientX: pixelX * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_X,
              clientY: pixelY * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_Y,
              bubbles: true
            })
          }

          document.querySelector('.upper-canvas').dispatchEvent(evt)

          var evt = new MouseEvent('mousemove', {
            clientX: pixelX * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_X,
            clientY: pixelY * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_Y,
            bubbles: true
          })
          document.querySelector('.upper-canvas').dispatchEvent(evt)

          logger.log('virtual mouseup', event)
          var evt = new MouseEvent('mouseup', {
            bubbles: true
          })
          document.querySelector('.upper-canvas').dispatchEvent(evt)
        } // END (DesignRoot.mouseDown === true)
        else {
          // MOUSE MOVE ONLY
          // logger.log('MOUSE MOVE ONLY');

          var evt = new MouseEvent('mouseup', {
            bubbles: true
          })
          document.querySelector('#THREEJS').dispatchEvent(evt)

          var evt = new MouseEvent('mousemove', {
            clientX: pixelX * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_X,
            clientY: pixelY * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_Y,
            bubbles: true
          })
          document.querySelector('.upper-canvas').dispatchEvent(evt)
        } // END // MOUSE MOVE ONLY
      } // END (option === 'mousemove')
      else if (option === 'mousedown') {
        logger.log('virtual mousedown 1', event)
        var evt = new MouseEvent('mousedown', {
          clientX: pixelX * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_X,
          clientY: pixelY * DesignRoot._CANVAS_SIZE + DesignRoot._GAP_Y
          // bubbles: true,
          // cancelable: true,
          // buttons: 1,
          // view: window,
          // composed: false
        })

        // DesignRoot.canvas.upperCanvasEl.dispatchEvent(evt);
        document.querySelector('.upper-canvas').dispatchEvent(evt)

        DesignRoot.mouseDown = true

        // DesignRoot.fabric_function.updateTexture()
      } // END (option === 'mousedown')
    } // END  (intersectsOBJ !== null)
    else {
      // NO INTERSECT
      // mouseLeave();
      // DesignRoot.mouseDown = false;

      if (DesignRoot.mouseDown === false) {
        Main3D.controls.enabled = true
      }

      if (
        document.body.style.cursor !== 'default' &&
        DesignRoot._DEV_TOOL === false
      ) {
        document.body.style.cursor = 'default'
      }
    }
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.checkIntersect.error', e)
  }
}

export function checkOutOfTemplateElement(object) {
  try {
    // statements

    var check = false
    for (var i = 0; i < Main3D.storeMappingObjectNoTemplate.length; i++) {
      if (Main3D.storeMappingObjectNoTemplate[i].name === object.name) {
        check = true
      }
    }

    logger.log(
      'checkOutOfTemplateElement',
      check,
      object,
      Main3D.storeMappingObjectNoTemplate
    )

    return check
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.checkOutOfTemplateElement.error', e)
  }
}

export function drawCanvasXY(X, Y, option, event) {
  try {
    // statements

    if (
      DesignRoot._ACTIVE_COLOR_ZONE === false ||
      DesignRoot._OLD_CZ_AVAILABLE === false
    ) {
      return
    }

    // canvasXY
    var context = Main3D.canvasXY.getContext('2d')
    Main3D.canvasXY.width = DesignRoot._ARTBOARD_DIMENSION.width
    Main3D.canvasXY.height = DesignRoot._ARTBOARD_DIMENSION.height
    context.clearRect(0, 0, Main3D.canvasXY.width, Main3D.canvasXY.height)
    context.drawImage(
      DesignRoot._ARTBOARD_COLORZONE_IMAGE,
      0,
      0,
      DesignRoot._ARTBOARD_DIMENSION.width,
      DesignRoot._ARTBOARD_DIMENSION.height
    )

    var getColorDot = context.getImageData(X, Y, 1, 1)
    var getColorDotCheck = context.getImageData(1, 1, 1, 1)

    logger.log('getColorDotCheck 1 1', getColorDotCheck)
    logger.log('getColorDot', getColorDot)

    var colorHOVER = DesignRoot.canvas_coloring.rgbToHex(
      getColorDot.data[0],
      getColorDot.data[1],
      getColorDot.data[2]
    )

    logger.log('colorHOVER', colorHOVER)

    if (option === 'click') {
      DesignRoot.FillStep.setState({
        selectColor: colorHOVER,
        selectType: 'COLOR',
        color: DesignRoot.change_color.returnCurrentColor(colorHOVER, 'COLOR')
      })
      DesignRoot.template_control.setSelectColorZone(colorHOVER)
    }

    // DEBUG POSITION

    // context.beginPath();
    // context.rect(X, Y, 20, 20); // POINTER
    // context.strokeStyle = "red";
    // context.stroke();

    // logger.log(Main3D.canvasXY.toDataURL('image/png'))

    // DEBUG POSITION
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.drawCanvasXY.error', e)
  }
}

export function getELMByName(name) {
  try {
    // statements

    for (var i = 0; i < Main3D.storeMappingObjectNoTemplate.length; i++) {
      if (Main3D.storeMappingObjectNoTemplate[i].name === name) {
        Main3D.directELM = Main3D.storeMappingObjectNoTemplate[i]
        break
      }
    }

    return Main3D.directELM
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.getELMByName.error', e)
  }
}

export function getOneELMByName(name) {
  try {
    // statements

    var elm = null

    for (var i = 0; i < Main3D.main_object.children.length; i++) {
      if (Main3D.main_object.children[i].name === name) {
        elm = Main3D.main_object.children[i]
        break
      }
    }

    logger.log('getOneELMByName', elm)

    return elm
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.getELMByName.error', e)
  }
}

export function calculateUV(uv) {
  try {
    var baseWidth = DesignRoot._ARTBOARD_DIMENSION.width
    var baseHeight = DesignRoot._ARTBOARD_DIMENSION.height

    var pixelX = Math.round(uv.x * baseWidth)

    if (uv.y > 1) {
      var pixelY = Math.round(
        (uv.y - Math.round(uv.y)) * baseHeight - baseHeight
      ) // Fix pixelY
    } else {
      var pixelY = Math.round(uv.y * baseHeight - baseHeight) // Fix pixelY
    }

    // logger.log("pixelY AFTER", pixelY, uv, DesignRoot._ARTBOARD_DIMENSION);

    // // Fix pixelY
    while (pixelY <= -0.1) {
      pixelY = pixelY * -1
    }
    while (pixelX <= -0.1) {
      pixelX = pixelX * -1
      pixelX = baseWidth - pixelX
    }
    // If texture have repeat setting
    while (pixelY > baseHeight) {
      pixelY = pixelY - baseHeight
    }
    while (pixelX > baseWidth) {
      pixelX = pixelX - baseWidth
    }

    // logger.log('pixelX', pixelX);
    // logger.log('pixelY', pixelY);

    return { x: pixelX, y: pixelY }
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.calculateUV.error', e)
  }
}

export function regexStitchName(fileName) {
  try {
    if (
      fileName.indexOf('RPEDButtonHole_L500_S0_T1500') !== -1 ||
      fileName.indexOf('RPEDZigzag_L200_S0_T3000') !== -1
    ) {
      return fileName
    } else {
      return fileName.replace(/(_\d+)/g, '')
    }
  } catch (e) {
    // statements
    logger.error('ThreejsFunction.regexStitchName.error', e)
  }
}

export function buildGroupELM() {
  try {
    logger.log('buildGroupELM')

    if (Main3D.storeMappingObjectNoTemplate.length) {
      for (var i = 0; i < Main3D.storeMappingObjectNoTemplate.length; i++) {
        var fullname = Main3D.storeMappingObjectNoTemplate[i].name

        if (fullname.indexOf('MatShape') !== -1) {
          var key = 'MatShape'
        } else {
          var split = fullname.split('-')
          var key = split[0]
        }

        logger.log(key)
        Main3D.storeMappingObjectNoTemplate[i].userData.elmGroup = key

        if (Main3D.storeMappingObjectNoTemplateGroup[key] !== undefined) {
          Main3D.storeMappingObjectNoTemplateGroup[key].push(
            Main3D.storeMappingObjectNoTemplate[i]
          )
        } else {
          Main3D.storeMappingObjectNoTemplateGroup[key] = []
          Main3D.storeMappingObjectNoTemplateGroup[key].push(
            Main3D.storeMappingObjectNoTemplate[i]
          )
          Main3D.storeMappingObjectNoTemplateGroupArray.push(
            Main3D.storeMappingObjectNoTemplate[i]
          )
        }
      }

      logger.log(Main3D.storeMappingObjectNoTemplateGroup)
    }

    if (Main3D.storeMappingObject.length) {
      for (var i = 0; i < Main3D.storeMappingObject.length; i++) {
        var fullname = Main3D.storeMappingObject[i].name

        if (fullname.indexOf('MatShape') !== -1) {
          var key = 'MatShape'
        } else {
          var split = fullname.split('-')
          var key = split[0]
        }

        logger.log(key)
        Main3D.storeMappingObject[i].userData.elmGroup = key

        if (Main3D.storeMappingObjectGroup[key] !== undefined) {
          Main3D.storeMappingObjectGroup[key].push(Main3D.storeMappingObject[i])
        } else {
          Main3D.storeMappingObjectGroup[key] = []
          Main3D.storeMappingObjectGroup[key].push(Main3D.storeMappingObject[i])
          Main3D.storeMappingObjectGroupArray.push(Main3D.storeMappingObject[i])
        }
      }

      logger.log(Main3D.storeMappingObjectGroup)
    }
  } catch (e) {
    logger.error('ThreejsFunction.buildGroupELM.error', e)
  }
}

export function updateNormalMap(normalMapFile) {
  try {
    for (var i = 0; i < Main3D.storeMappingChild.length; i++) {
      Main3D.storeMappingChild[i].onBeforeCompile = function(shader) {
        logger.log('onBeforeCompile', shader)

        // materialShader = shader;

        shader.fragmentShader = shader.fragmentShader.replace(
          '#include <normalmap_pars_fragment>',
          [
            `#ifdef USE_NORMALMAP
              uniform sampler2D normalMap;
              uniform vec2 normalScale;
              vec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm ) {
                vec3 q0 = vec3( dFdx( eye_pos.x ), dFdx( eye_pos.y ), dFdx( eye_pos.z ) );
                vec3 q1 = vec3( dFdy( eye_pos.x ), dFdy( eye_pos.y ), dFdy( eye_pos.z ) );
                vec2 st0 = dFdx( vUv.st );
                vec2 st1 = dFdy( vUv.st );
                float scale = sign( st1.t * st0.s - st0.t * st1.s );
                vec3 S = normalize( ( q0 * st1.t - q1 * st0.t ) * scale );
                vec3 T = normalize( ( - q0 * st1.s + q1 * st0.s ) * scale );
                vec3 N = normalize( surf_norm );
                mat3 tsn = mat3( S, T, N );
                vec3 mapN = texture2D( normalMap, vUv * 50.0 ).xyz * 2.0 - 1.0; // REPEAT NORMAL MAP
                mapN.xy *= (normalScale * 4.0); // NORMAL MAP INTENSITY
                mapN.xy *= ( float( gl_FrontFacing ) * 2.0 - 1.0 );
                return normalize( tsn * mapN );
              }
            #endif`
          ].join('\n')
        )
      }

      Main3D.normalMapTexture = new THREE.TextureLoader().load(normalMapFile)
      Main3D.normalMapTexture.wrapS = Main3D.normalMapTexture.wrapT =
        THREE.RepeatWrapping

      Main3D.storeMappingChild[i].normalMap = Main3D.normalMapTexture
      Main3D.storeMappingChild[i].needsUpdate = true
    }
  } catch (e) {
    logger.error('ThreejsFunction.updateNormalMap.error', e)
  }
}

export async function generateObjZipFile(canvasContent) {
  try {
    const printPatternNameArr = Main3D.patternMaterialName
    let materialsInfo = Main3D.materialsInfo

    //var exporter = new OBJExporter();
    const objResponse = await fetch(Main3D.props.productDataSet.OBJ)
    let objText = await objResponse.text()
    objText = objText.replace(/^mtllib .*$/m, 'mtllib product.mtl')
    //var resultObj = exporter.parse( Main3D.main_object );

    var zip = new JSZip()
    zip.file('printPattern.png', canvasContent, { base64: true })
    zip.file('product.obj', objText)

    let materialInfoArr = Object.entries(materialsInfo)

    materialInfoArr = await Promise.all(
      materialInfoArr.map(async (item, key) => {
        let materialInfo = item[1]
        if (!printPatternNameArr.includes(materialInfo.name)) {
          const mapKaUrl = urlLib.parse(materialInfo.map_ka)
          const mapKdUrl = urlLib.parse(materialInfo.map_kd)
          let filenameKa = mapKaUrl.pathname.replace(/^.*[\\\/]/, '')
          let filenameKd = mapKdUrl.pathname.replace(/^.*[\\\/]/, '')
          let extnameKa = pathLib.extname(filenameKa)
          let extnameKd = pathLib.extname(filenameKd)
          if (extnameKa !== '') {
            const responseKa = await fetch(mapKaUrl.pathname)
            const blobKa = await responseKa.blob()
            zip.file(filenameKa, blobKa)
            materialInfo.map_ka = filenameKa
          }

          if (extnameKd !== '') {
            if (materialInfo.map_ka !== materialInfo.map_kd) {
              const responseKd = await fetch(mapKaUrl.pathname)
              const blobKd = await responseKd.blob()
              zip.file(filenameKd, blobKd)
            }
            materialInfo.map_kd = filenameKd
          }
        } else {
          materialInfo.map_ka = 'PrintPattern.png'
          materialInfo.map_kd = 'PrintPattern.png'
        }

        return materialInfo
      })
    )

    zip.file('product.mtl', generateMtlFile(materialInfoArr))

    return await zip.generateAsync({ type: 'blob' })
  } catch (e) {
    // statements
    console.error('ThreejsFunction.generateZip.error', e)
    return null
  }
}

export function generateMtlFile(mtlInfo) {
  const mapKeys = {
    name: 'newmtl',
    ka: 'Ka',
    kd: 'Kd',
    ks: 'Ks',
    ns: 'Ns',
    illum: 'illum',
    d: 'd',
    map_ka: 'map_Ka',
    map_kd: 'map_Kd'
  }
  try {
    let textFile = ''

    mtlInfo.map(item => {
      Object.keys(mapKeys).map(mapKey => {
        if (['ka', 'kd', 'ks'].includes(mapKey)) {
          textFile += `${mapKeys[mapKey]} ${item[mapKey]
            .map(float => {
              return parseFloat(float).toFixed(6)
            })
            .join(' ')}\n`
        } else if (mapKey == 'ns') {
          textFile += `${mapKeys[mapKey]} ${parseFloat(item[mapKey]).toFixed(
            6
          )}\n`
        } else {
          textFile += `${mapKeys[mapKey]} ${item[mapKey]}\n`
        }
      })
    })

    return textFile
  } catch (e) {
    // statements
    console.error('ThreejsFunction.getMtlFile.error', e)
    return null
  }
}

export function generateGltfFile() {
  try {
    return new Promise(function(resolve, reject) {
      var exporter = new GLTFExporter()
      // Parse the input and generate the glTF output
      exporter.parse(Main3D.main_object, function(gltf) {
        resolve(JSON.stringify(gltf))
        //downloadJSON( gltf );
      })
    }).then(function(result) {
      return result
    })
  } catch (e) {
    // statements
    console.error('ThreejsFunction.generateGltf.error', e)
    return null
  }
}
