import { Link } from 'gatsby'
import React from 'react'
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
  Button,
  Grid
} from '@material-ui/core'

const styles = (themeStyle: Theme) =>
  createStyles({
    btn: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      minWidth: 50,
      height: 50,
      [themeStyle.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  })

interface Props extends WithStyles<typeof styles> {
  contentDetail: any
  shopLogo: string
}

const ShopLogo: React.FunctionComponent<Props> = props => {
  const {
    classes,
    contentDetail,
    shopLogo,
    defaultLanguage,
    currentLanguage,
    teamstoreLogo,
    teamstoreUrl,
    urlPrefix,
    styling
  } = props

  let logoWidth
  try {
    if (contentDetail && contentDetail.type === 'SHOP_LOGO') {
      logoWidth = styling.logoWidth
    }
  } catch (error) {}

  const renderImg = logo => (
    <img
      src={logo}
      alt={'logo'}
      style={{
        filter: contentDetail.isAlternative ? 'invert(1)' : 'invert(0)',
        paddingRight: teamstoreLogo ? '10px' : null,
        width: '100%',
        height: '100%',
        maxHeight: '50px',
        padding: 4,
        objectFit: 'contain'
      }}
    />
  )

  return (
    <Grid
      item={true}
      style={{
        marginTop: '0px',
        marginLeft: '-8px',
        display: teamstoreLogo ? 'flex' : null
      }}
    >
      {contentDetail.isLink ? (
        <Button
          component={Link}
          disableRipple={true}
          style={{
            background: 'none',
            maxWidth: logoWidth ? logoWidth : '150px'
          }}
          to={`${
            teamstoreUrl
              ? '/' + urlPrefix + '/' + teamstoreUrl
              : currentLanguage === defaultLanguage
              ? '/'
              : '/' + currentLanguage.substring(0, 2)
          }`}
          className={classes.btn}
        >
          {renderImg(shopLogo)}
          {teamstoreLogo ? renderImg(teamstoreLogo) : null}
        </Button>
      ) : (
        renderImg(shopLogo)
      )}
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(ShopLogo)
