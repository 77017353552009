import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query } from 'react-apollo';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ADMIN_SHOP_VARIANTS } from '../../../gql/admin-queries';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import _get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTextFromLanguage } from '../../../../../helper/languageHelper';
import GlobalStyles from '../../../../globalStyles';
import AddVariant from './AddVariant';

const styles = theme => ({
  progress: {},
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%',
    height: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242'
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  cell: {
    padding: '16px 16px !important'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  }
});

class VariantList extends Component {
  state = {
    filter: ''
  };
  render() {
    const { classes, defaultLanguage, shopId, styles } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Grid item style={{ marginRight: 16 }}>
            <Typography
              component="h1"
              className={classes.h1}
              data-cy="Header-Variants"
            >
              Variants
            </Typography>
            <GlobalStyles />
          </Grid>
          <Grid item style={{ padding: '0px', marginRight: 16 }}>
            <Paper className={classes.paper} />
          </Grid>
          <div style={{ flex: 1 }} />
          <AddVariant shopId={shopId} />
        </Grid>
        <Query query={ADMIN_SHOP_VARIANTS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            if (loading)
              return <CircularProgress className={classes.progress} />;
            if (error) return <div>Error :(</div>;
            const filteredVariants = _get(shop, 'variants.edges', []);

            return (
              <div>
                {filteredVariants.length > 0 ? (
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.cell}>
                            Internal Number
                          </TableCell>
                          <TableCell className={classes.cell}>Title</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredVariants.map(variant => {
                          return (
                            <TableRow
                              data-cy={
                                'Variant-List-Row-' +
                                getTextFromLanguage(
                                  _get(variant.node, 'title.text', []),
                                  defaultLanguage
                                )
                              }
                              key={variant.node.id}
                              component="a"
                              href={
                                '/admin/' +
                                shopId +
                                '/inventory/variants/' +
                                variant.node.id
                              }
                              hover
                              style={{ textDecoration: 'none' }}
                            >
                              <TableCell className={classes.cell}>
                                {variant.node.no}
                              </TableCell>

                              <TableCell className={classes.cell}>
                                {getTextFromLanguage(
                                  _get(variant.node, 'title.text', []),
                                  defaultLanguage
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <div>No variants yet</div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withStyles(styles)(VariantList);
