import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Paper } from '@material-ui/core'
import Image3 from '../../../img/3.jpg'
import { getTextFromLanguage } from '../../../helper/languageHelper'
import { Link } from 'gatsby'
import _get from 'lodash/get'
import Imgix from 'react-imgix'
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../brikl-config'

const useStyles = makeStyles(theme => ({
  root: {}
}))

const XceedTeamstoreCard = ({
  urlPrefix,
  langCode,
  key,
  Template,
  teamstore
}) => {
  const classes = useStyles()
  const [hover, setHover] = useState(false)

  var title = null
  try {
    title = teamstore.name
    if (teamstore.title.text[0].content) {
      title = getTextFromLanguage(_get(teamstore, 'title.text', []), langCode)
    }
  } catch (error) {}
  let src = null
  try {
    src = teamstore.logo
  } catch (error) {}
  let slug = null
  try {
    if (teamstore && teamstore.type === 'EXTERNAL_TEAMSTORE') {
      slug =
        teamstore.id === '372107d4-d535-4381-98fb-23b4ac9d7a1e'
          ? 'https://www.superleagueshop.com/'
          : null
    } else {
      if (
        teamstore?.slugs &&
        _get(teamstore, 'slugs', []).find(e => e.langCode === langCode)
      ) {
        slug = `/${urlPrefix}${
          _get(teamstore, 'slugs', []).find(e => e.langCode === langCode)
            .content
        }`
      }
    }
  } catch (error) {
    console.error('TeamstoreCard.slugs.error', error)
  }
  if (slug === null) return null

  return (
    <Grid key={key} container style={{ width: '100%' }}>
      <a
        href={slug}
        style={{ textDecoration: 'none', color: '#000', width: '100%' }}
      >
        <Paper style={{ padding: 24, width: '100%' }}>
          <Grid
            container
            direction={'row'}
            justify={'center'}
            alignItems={'center'}
          >
            <Grid
              item
              xs={12}
              sm={5}
              style={{
                width: '100%',
                height: 100,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Imgix
                height={100}
                htmlAttributes={{
                  alt: title,
                  style: {
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                  }
                }}
                imgixParams={{
                  auto: 'compress'
                }}
                src={src ? src.replace(MEDIA_CDN_URL, IMGIX_URL) : ''}
                alt={title}
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid
                container
                justify={'center'}
                alignItems={'center'}
                style={{ height: 100 }}
              >
                <Typography variant={'subtitle1'}>
                  {title ? title : 'The Teamstore Name'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </a>
    </Grid>
  )
}

export default XceedTeamstoreCard
