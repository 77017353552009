import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import HelpOutline from '@material-ui/icons/HelpOutline'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ApolloConsumer, Mutation } from 'react-apollo'
import { SHOP_DEFAULT_CURRENCY } from '../../../helper/config'
import { Localization } from '../../../lang/localization'
import { addToCart } from '../../home/BoardProduct'
import {
  CREATE_LEAD_MUTATION,
  UPDATE_CART_MUTATION,
} from '../graphql/mutations'
import { GET_CUSTOMER_CART_DETAIL_QUERY } from '../graphql/queries'
import CartIcon from '../tools/Components/icons/CartIcon'
import FabricIcon from '../tools/Components/icons/FabricIcon'
import FillIcon from '../tools/Components/icons/FillIcon'
import LayoutIcon from '../tools/Components/icons/LayoutIcon'
import LeftClick from '../tools/Components/icons/LeftClick'
import RightClick from '../tools/Components/icons/RightClick'
import ScrollWheel from '../tools/Components/icons/ScrollWheel'
import TextIcon from '../tools/Components/icons/TextIcon'
import TouchPan from '../tools/Components/icons/TouchPan'
import TouchRotate from '../tools/Components/icons/TouchRotate'
import TouchZoom from '../tools/Components/icons/TouchZoom'
import UploadIcon from '../tools/Components/icons/UploadIcon'
import FabricStep from './FabricStep'
import FillStep from './FillStep'
import LayoutStep from './LayoutStep'
import LogoStep from './LogoStep'
import PersonalizationStep from './PersonalizationStep'
import { RequestQuotation } from './RequestQuotation'
import TextStep from './TextStep'
import '../logger'

function TabContainer(props) {
  return (
    <Grid
      key={'tab-' + props.key}
      container
      style={{
        background: '#FFFFFF',
        height: '100vh',
        width: 340,
        borderLeft: '1px solid #E5E5E5',
        overflowY: 'auto',
        position: 'absolute',
        right: '80px',
        zIndex: 0,
      }}
    >
      {props.children}
    </Grid>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
const style = {
  menu: {
    paddingTop: 14,
    width: 80,
    padding: '0px 10px',
    boxShadow: 'none',
    background: '#FFFFFF !important',
    height: '100vh',
    borderLeft: '1px solid #E5E5E5',
    position: 'absolute',
    right: 0,
    zIndex: 0,
  },
  tabsIndicator: {
    background: 'transparent',
  },
  tabs: {
    margin: 4,
    minWidth: 50,
    minHeight: 50,
    width: 50,
    height: 50,
    maxWidth: 50,
    maxHeight: 50,
    padding: 0,
    background: '#FFFFFF',
    color: '#000000',
    borderRadius: '100%',
    border: '0px',
    fontSize: '12px',
    '&:hover': {
      color: '#FFFFFF',
      // background: primary.toLowerCase() === '#ffffff' ? '#999999' : primary,
      border: '2px solid #f2f2f2',
      background: '#f2f2f2',
    },
    '&$tabSelected': {
      color: '#FFFFFF',
      // background: primary.toLowerCase() === '#ffffff' ? '#999999' : primary,
      border: '2px solid #f2f2f2',
      background: '#f2f2f2',
    },
    '&:focus': {
      color: '#FFFFFF',
      // background: primary.toLowerCase() === '#ffffff' ? '#999999' : primary,
      border: '2px solid #f2f2f2',
      background: '#f2f2f2',
    },
  },
  tabSelected: {
    color: '#FFFFFF',
    // background: primary.toLowerCase() === '#ffffff' ? '#999999' : primary,
    border: '2px solid #f2f2f2',
    background: '#f2f2f2',
  },
  tab: {
    padding: '0px !important',
  },
  tabsShow: {
    display: 'block',
  },
  tabsHide: {
    display: 'none',
  },
  tabsRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: 60,
    background: 'transparent',
    height: 'calc(100vh - 200px)',
  },
  rootTab: {
    background: 'transparent',
    height: 'calc(100vh - 200px)',
  },
  cartButton: {
    minWidth: 50,
    width: 50,
    height: 50,
    minWidth: 50,
    padding: 4,
    borderRadius: '100%',
    marginBottom: 16,
  },
}
const styles = () => style

class MenuStep extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: 'Layout',
      addToCartinProgress: false,
      openDialog: false,
      open: true,
    }

    if (
      (this.props.DesignRoot.productDataSet &&
        this.props.DesignRoot.productDataSet.TEMPLATE_SET.length < 2) ||
      (this.props.DesignRoot &&
        this.props.DesignRoot._ACTIVE_TEMPLATE_STEP === false)
    ) {
      this.state.value = 'Fill'
    }

    if (
      this.props.DesignRoot._ACTIVE_PERSONALIZATION === true &&
      this.props.DesignRoot.productDataSet?.TEMPLATE_SET.length < 2
    ) {
      this.state.value = 'Personalization'
    }
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot
    DesignRoot.MenuStep = this
    DesignRoot.PersonalizationStep = PersonalizationStep
    DesignRoot.setState({ update: true })
    // PROVIDE COMPONENT TO MAIN
  }

  handleClickOpenCartDialog = () => {
    this.setState({ openDialog: true })
  }

  handleCloseCartDialog = () => {
    this.setState({ openDialog: false })
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleChange = (event, value) => {
    logger.log('handleChange', event, value)

    this.setState({ value })

    const { classes, DesignRoot } = this.props

    if (value === '-') {
      DesignRoot.setState({ dynamicPlane: '' })
    } else {
      DesignRoot.setState({ dynamicPlane: 'dynamicPlane' })
    }
  }

  handleTabsToggle(tabName) {
    const { value } = this.state

    if (tabName === value) {
      return 'block'
    } else {
      return 'none'
    }
  }

  render() {
    const {
      classes,
      DesignRoot,
      langCode,
      shopConfiguration,
      user,
    } = this.props
    const { value, open, openDialog } = this.state

    logger.log('shopConfiguration', shopConfiguration)

    let showTemplates = true
    try {
      if (DesignRoot.productDataSet.TEMPLATE_SET.length < 2) {
        showTemplates = false
      }
    } catch (error) {}
    try {
      if (DesignRoot._ACTIVE_TEMPLATE_STEP === false) showTemplates = false
    } catch (error) {}

    let hasFabricStep = false
    try {
      shopConfiguration.designStudioSettings.designSteps.forEach(designStep => {
        if (designStep.type === 'FABRIC') hasFabricStep = true
      })
    } catch (error) {}
    let logoConfiguration, textConfiguration
    try {
      logoConfiguration = shopConfiguration.designStudioSettings.designSteps.find(
        step => step.type === 'LOGO'
      )
      textConfiguration = shopConfiguration.designStudioSettings.designSteps.find(
        step => step.type === 'TEXT'
      )
    } catch (error) {}
    try {
      if (
        DesignRoot.SHOP_ID === 'bodywearlab' ||
        DesignRoot.SHOP_ID === 'OBS' ||
        DesignRoot._FABRIC_STEP === false
      ) {
        hasFabricStep = false
      }
    } catch (error) {}

    return (
      <ApolloConsumer>
        {apolloClient => (
          <div className="MenuStep">
            <AppBar position="static" className={classes.menu}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                classes={{
                  indicator: classes.tabsIndicator,
                  flexContainer: classes.tabsRoot,
                  root: classes.rootTab,
                }}
              >
                {showTemplates ? (
                  <Tab
                    value="Layout"
                    icon={<LayoutIcon />}
                    data-cy="layout-step-btn"
                    classes={{
                      root: classes.tabs,
                      wrapper: classes.tab,
                      selected: classes.tabSelected,
                    }}
                  />
                ) : null}
                {hasFabricStep ? (
                  <Tab
                    value="Material"
                    icon={
                      <FabricIcon
                        style={{ width: '100%', objectFit: 'contain' }}
                      />
                    }
                    data-cy="material-step-btn"
                    classes={{
                      root: classes.tabs,
                      wrapper: classes.tab,
                      selected: classes.tabSelected,
                    }}
                  />
                ) : null}

                {DesignRoot._ACTIVE_FILL_STEP === true && (
                  <Tab
                    value="Fill"
                    icon={<FillIcon />}
                    data-cy="fill-step-btn"
                    classes={{
                      root: classes.tabs,
                      wrapper: classes.tab,
                      selected: classes.tabSelected,
                    }}
                  />
                )}

                {DesignRoot._ACTIVE_LOGO_STEP === true && (
                  <Tab
                    value="Logo"
                    icon={<UploadIcon />}
                    data-cy="upload-step-btn"
                    classes={{
                      root: classes.tabs,
                      wrapper: classes.tab,
                      selected: classes.tabSelected,
                    }}
                  />
                )}

                {DesignRoot._ACTIVE_TEXT_STEP === true && (
                  <Tab
                    value="Text"
                    icon={
                      <TextIcon
                        style={{ width: '100%', objectFit: 'contain' }}
                      />
                    }
                    data-cy="text-step-btn"
                    classes={{
                      root: classes.tabs,
                      wrapper: classes.tab,
                      selected: classes.tabSelected,
                    }}
                  />
                )}

                {DesignRoot._ACTIVE_PERSONALIZATION === true && (
                  <Tab
                    value="Personalization"
                    icon={<EditIcon style={{ color: '#000' }} />}
                    data-cy="personal-step-btn"
                    classes={{
                      root: classes.tabs,
                      wrapper: classes.tab,
                      selected: classes.tabSelected,
                    }}
                  />
                )}

                <div style={{ display: 'flex', flex: 1 }} />
                {DesignRoot.SHOP_ID === 'vermarc' ||
                DesignRoot.SHOP_ID === 'b0298146-8da9-4c2a-bfdf-54e3987d09d1' ||
                DesignRoot.SHOP_ID === 'bcf' ? (
                  <Tooltip title="Quotation" placement="left">
                    <Mutation mutation={CREATE_LEAD_MUTATION}>
                      {createLead => (
                        <>
                          <Button
                            onClick={() => {
                              DesignRoot.template_control.capture4view()
                              this.setState({
                                openDialog: true,
                              })
                            }}
                            className={classes.cartButton}
                          >
                            <CartIcon />
                          </Button>
                          {this.state.openDialog ? (
                            <Dialog
                              open={this.state.openDialog}
                              onClose={this.handleCloseCartDialog}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <RequestQuotation
                                shopId={DesignRoot.SHOP_ID}
                                productDesignId={
                                  DesignRoot.props.productDesignId
                                }
                                langCode={langCode}
                                ownedByOrganisationId={
                                  DesignRoot.ownedByOrganisationId
                                }
                                salesChannelId={
                                  DesignRoot.props.productDesign
                                    ?.salesChannelId || 'MYBRIKL'
                                }
                                createLead={createLead}
                                open={this.state.openDialog}
                                close={this.handleCloseCartDialog}
                              />
                            </Dialog>
                          ) : null}
                        </>
                      )}
                    </Mutation>
                  </Tooltip>
                ) : (
                  <Tooltip title="CHECKOUT" placement="left">
                    <Mutation mutation={UPDATE_CART_MUTATION}>
                      {updateCart => (
                        <div>
                          <Button
                            onClick={this.handleClickOpenCartDialog}
                            disabled={
                              this.state.loading === true ? true : false
                            }
                            className={classes.cartButton}
                          >
                            {this.state.addToCartinProgress === true ? (
                              <CircularProgress style={{ color: '#000' }} />
                            ) : (
                              <CartIcon />
                            )}
                          </Button>
                          <Dialog
                            open={this.state.openDialog}
                            onClose={this.handleCloseCartDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <div style={{ padding: 24 }}>
                              <DialogContent>
                                <DialogContentText style={{ color: '#000' }}>
                                  {Localization.get(
                                    'beCreative_add_to_cart_confirm'
                                  )}
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={this.handleCloseCartDialog}
                                  variant="text"
                                >
                                  {Localization.get('beCreative_cancel_button')}
                                </Button>
                                <Button
                                  disabled={
                                    this.state.addToCartinProgress === true
                                      ? true
                                      : false
                                  }
                                  onClick={this.handleCloseCartDialog}
                                  variant="outlined"
                                  autoFocus
                                  onClick={async () => {
                                    this.setState({ addToCartinProgress: true })
                                    try {
                                      await DesignRoot.template_control.capture4view()
                                    } catch (error) {
                                      logger.log('error', error)
                                    }
                                    // add to cart
                                    const currency = SHOP_DEFAULT_CURRENCY
                                    const ownedByOrganisationId =
                                      DesignRoot.props.ownedByOrganisationId
                                    const productDesignId =
                                      DesignRoot.props.productDesignId
                                    const productId =
                                      DesignRoot.props.productId ||
                                      DesignRoot.props.productID
                                    const urlPrefix = DesignRoot.props.urlPrefix
                                    try {
                                      const articleName = 'No name'
                                      if (articleName && articleName !== '') {
                                        const cartData = await apolloClient.query(
                                          {
                                            query: GET_CUSTOMER_CART_DETAIL_QUERY,
                                            variables: {
                                              currency,
                                              id: ownedByOrganisationId,
                                            },
                                          }
                                        )
                                        logger.log('cartData', cartData)
                                        if (
                                          cartData &&
                                          cartData.data &&
                                          cartData.data.customerCart &&
                                          cartData.data.customerCart.cart
                                        ) {
                                          await addToCart(
                                            productDesignId,
                                            ownedByOrganisationId,
                                            'REGULAR',
                                            updateCart,
                                            cartData.data.customerCart.cart.id,
                                            cartData.data.customerCart.cart,
                                            cartData.data.customerCart.cart
                                              .shopId,
                                            productId,
                                            'V2',
                                            DesignRoot.props.productDesign
                                              .salesChannelId
                                          )
                                          navigate(`/${urlPrefix}app/cart`, {
                                            replace: true,
                                          })
                                        } else {
                                          const cartDataNetwork = await apolloClient.query(
                                            {
                                              query: GET_CUSTOMER_CART_DETAIL_QUERY,
                                              variables: {
                                                currency,
                                                id: ownedByOrganisationId,
                                              },
                                              fetchPolicy: 'network-only',
                                            }
                                          )
                                          if (
                                            cartDataNetwork.data.customerCart
                                              .cart
                                          ) {
                                            await addToCart(
                                              productDesignId,
                                              ownedByOrganisationId,
                                              'REGULAR',
                                              updateCart,
                                              cartDataNetwork.data.customerCart
                                                .cart.id,
                                              cartDataNetwork.data.customerCart
                                                .cart,
                                              cartData.data.customerCart.cart
                                                .shopId,
                                              productId,
                                              'V2'
                                            )

                                            navigate(`/${urlPrefix}app/cart`, {
                                              replace: true,
                                            })
                                          }
                                        }

                                        //capture 4 views
                                        // DesignBoard.captureSET = [];
                                        // DesignBoard.captureActive = true;
                                      } else {
                                        alert('Please provide an article name')
                                        // document.getElementById('boardproduct-name').focus();
                                        this.setState({
                                          addToCartinProgress: false,
                                        })
                                      }
                                    } catch (error) {
                                      logger.error('Add to cart error:', error)
                                      this.setState({
                                        addToCartinProgress: false,
                                      })
                                    }
                                  }}
                                >
                                  {this.state.addToCartinProgress === true
                                    ? '...'
                                    : Localization.get(
                                        'beCreative_add_to_cart_button'
                                      )}
                                </Button>
                              </DialogActions>
                            </div>
                          </Dialog>
                        </div>
                      )}
                    </Mutation>
                  </Tooltip>
                )}
                <Button
                  onClick={this.handleClickOpen}
                  className={classes.cartButton}
                >
                  <HelpOutline />
                </Button>
                <Dialog
                  open={
                    user && user.numberOfSessions > 2 ? false : this.state.open
                  }
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <div
                    style={{
                      padding: '5px 24px 16px 24px',
                      maxHeight: '70vh',
                      overflowX: ' auto',
                      position: 'relative',
                    }}
                  >
                    <DialogContent>
                      <DialogContentText>
                        <Button
                          data-cy="close-popup-btn"
                          style={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                            padding: '2px',
                            minWidth: '40px',
                          }}
                          onClick={this.handleClose}
                          variant="outlined"
                        >
                          x
                        </Button>
                        <Grid
                          container
                          justify={'center'}
                          spacing={1}
                          style={{ color: '#000' }}
                        >
                          <Grid item xs={12} style={{ paddingBottom: 5 }}>
                            <Typography
                              variant={'h3'}
                              style={{ textAlign: 'center' }}
                            >
                              {Localization.get(
                                'beCreative_instruction_how_to_use'
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: 8,
                            }}
                          >
                            <Typography
                              variant={'h3'}
                              style={{
                                textAlign: 'center',
                                background: '#000',
                                color: '#fff',
                                padding: 8,
                                maxWidth: 200,
                              }}
                            >
                              {Localization.get(
                                'beCreative_instruction_rotate360'
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid
                              container
                              justify={'center'}
                              alignItems={'center'}
                            >
                              <LeftClick padding={8} height={60} width={60} />
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid
                              container
                              justify={'center'}
                              alignItems={'center'}
                            >
                              <TouchRotate padding={8} height={60} width={60} />
                            </Grid>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'center' }}>
                            {Localization.get(
                              'beCreative_instruction_rotate_mouse'
                            )}
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'center' }}>
                            {Localization.get(
                              'beCreative_instruction_rotate_touch'
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: 8,
                            }}
                          >
                            <Typography
                              variant={'h3'}
                              style={{
                                textAlign: 'center',
                                background: '#000',
                                color: '#fff',
                                padding: 8,
                                maxWidth: 200,
                              }}
                            >
                              {Localization.get('beCreative_instruction_zoom')}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid
                              container
                              justify={'center'}
                              alignItems={'center'}
                            >
                              <ScrollWheel padding={8} height={60} width={60} />
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid
                              container
                              justify={'center'}
                              alignItems={'center'}
                            >
                              <TouchZoom padding={8} height={60} width={60} />
                            </Grid>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'center' }}>
                            {Localization.get(
                              'beCreative_instruction_zoom_mouse'
                            )}
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'center' }}>
                            {Localization.get(
                              'beCreative_instruction_zoom_touch'
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: 8,
                            }}
                          >
                            <Typography
                              variant={'h3'}
                              style={{
                                textAlign: 'center',
                                background: '#000',
                                color: '#fff',
                                padding: 8,
                                maxWidth: 200,
                              }}
                            >
                              {Localization.get('beCreative_instruction_move')}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid
                              container
                              justify={'center'}
                              alignItems={'center'}
                            >
                              <RightClick padding={8} height={60} width={60} />
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid
                              container
                              justify={'center'}
                              alignItems={'center'}
                            >
                              <TouchPan padding={8} height={60} width={60} />
                            </Grid>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'center' }}>
                            {Localization.get(
                              'beCreative_instruction_move_mouse'
                            )}
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'center' }}>
                            {Localization.get(
                              'beCreative_instruction_move_touch'
                            )}
                          </Grid>
                        </Grid>
                      </DialogContentText>
                    </DialogContent>
                  </div>
                </Dialog>
              </Tabs>
            </AppBar>
            {showTemplates ? (
              <span style={{ display: this.handleTabsToggle('Layout') }}>
                <TabContainer key="Layout">
                  <Grid container>
                    <LayoutStep
                      DesignRoot={DesignRoot}
                      productDataSet={DesignRoot.productDataSet}
                    />
                  </Grid>
                </TabContainer>
              </span>
            ) : null}

            {hasFabricStep ? (
              <span style={{ display: this.handleTabsToggle('Material') }}>
                <TabContainer key="Material">
                  <FabricStep
                    DesignRoot={DesignRoot}
                    productDataSet={DesignRoot.productDataSet}
                  />
                </TabContainer>
              </span>
            ) : null}

            <span style={{ display: this.handleTabsToggle('Fill') }}>
              <TabContainer key="Fill">
                <FillStep
                  DesignRoot={DesignRoot}
                  productDataSet={DesignRoot.productDataSet}
                />
              </TabContainer>
            </span>
            <span style={{ display: this.handleTabsToggle('Logo') }}>
              <TabContainer key="Logo">
                <LogoStep
                  langCode={langCode}
                  logoConfiguration={logoConfiguration}
                  DesignRoot={DesignRoot}
                  productDataSet={DesignRoot.productDataSet}
                />
              </TabContainer>
            </span>
            <span style={{ display: this.handleTabsToggle('Text') }}>
              <TabContainer key="Text">
                <TextStep
                  langCode={langCode}
                  textConfiguration={textConfiguration}
                  DesignRoot={DesignRoot}
                  productDataSet={DesignRoot.productDataSet}
                />
              </TabContainer>
            </span>
            <span style={{ display: this.handleTabsToggle('Personalization') }}>
              <TabContainer key="Personalization">
                <PersonalizationStep
                  langCode={langCode}
                  textConfiguration={textConfiguration}
                  DesignRoot={DesignRoot}
                  productDataSet={DesignRoot.productDataSet}
                />
              </TabContainer>
            </span>
          </div>
        )}
      </ApolloConsumer>
    )
  }
}

MenuStep.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MenuStep)
