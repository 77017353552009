import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Imgix from 'react-imgix';
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../../brikl-config';

const styles = theme => ({});

class Img extends React.Component {
  render() {
    const { src, alt, width, height } = this.props;
    return src && src.indexOf(MEDIA_CDN_URL) !== -1 ? (
      <Imgix
        height={height ? height : null}
        width={width ? width : null}
        htmlAttributes={{
          alt: alt,
          style: {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }
        }}
        src={src.replace(MEDIA_CDN_URL, IMGIX_URL)}
        imgixParams={{
          auto: 'compress'
        }}
      />
    ) : (
      <img
        src={src}
        alt={alt}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />
    );
  }
}

Img.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Img);
