import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _get from 'lodash/get'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import { NativeSelect } from '@material-ui/core'
import { SingleSelect } from 'react-select-material-ui'
const styles = theme => ({})

class SelectCollection extends React.Component {
  state = {
    isChecked: false,
    selectedCollectionId: ''
  }

  render() {
    const {
      classes,
      collection,
      collections,
      Template,
      handleChange,
      selectedCollection,
      selectedCollectionId,
      langCode
    } = this.props
    var checked = false
    var slug = ''
    var url = ''

    let splitPage = ''
    splitPage = selectedCollection && selectedCollection.split('/')

    try {
      slug = collection.slugs.find(s => s.langCode === langCode).content
    } catch (error) {}

    // TODO fix this
    try {
      if (langCode === 'en_UK') {
        url = '/' + 'en/' + slug
      } else if (langCode === 'nl_NL') {
        url = '/' + 'nl/' + slug
      } else if (langCode === 'fr_FR') {
        url = '/' + 'fr/' + slug
      }
    } catch (error) {}
    let collectionTitle = 'N/A'
    try {
      collectionTitle = collection.title.text.find(t => t.langCode === langCode)
        .content
    } catch (error) {}
    const currentCollection = collections
      ? collections.edges.find(
          n =>
            getTextFromLanguage(n.node.title.text, langCode) ===
              selectedCollection || n.node.id === selectedCollectionId
        )
      : null

    return (
      <div style={{ width: '100%', padding: 10 }}>
        <SingleSelect
          SelectProps={{}}
          style={{
            border: '1px solid #ced4da',
            borderRadius: 4
          }}
          options={[
            ...collections.edges
              .filter(
                c => c && getTextFromLanguage(c.node.title.text, langCode)
              )
              .map(collectionNode =>
                collectionNode.node.title
                  ? getTextFromLanguage(
                      collectionNode.node.title.text,
                      langCode
                    )
                  : collectionNode.node.id
              )
          ]}
          value={
            currentCollection
              ? getTextFromLanguage(currentCollection.node.title.text, langCode)
              : null
          }
          onChange={value => {
            const collection = collections.edges.find(
              n => getTextFromLanguage(n.node.title.text, langCode) === value
            )
            if (collection) {
              const collectionSlug = getTextFromLanguage(
                collection.node.slugs,
                langCode
              )
              handleChange(
                {
                  target: {
                    value: collection.node.id
                  }
                },
                collectionSlug
              )
            }
          }}
        />
      </div>
    )
  }
}

SelectCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(SelectCollection)
