import React from 'react';

import { CustomPicker } from 'react-color';
import { CirclePicker } from 'react-color';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

export const MyPicker = ({ hex, onChange }) => {
  const styles = {
    input: {
      width: 200,
      marginTop: 20,
      background: 'transparent',
      color: '#FFFFFF',
      textAlign: 'center',
      textTransform: 'uppercase',
      position: 'relative',
      border: 'none',
      fontSize: 14
    },
    inputWhiteText: {
      width: 200,
      marginTop: 20,
      background: 'transparent',
      color: '#000',
      textAlign: 'center',
      textTransform: 'uppercase',
      position: 'relative',
      border: 'none',
      fontSize: 14
    },
    inputWhite: {
      width: 200,
      height: 60,
      marginBottom: '20px',
      position: 'absolute',
      borderRadius: 5,
      border: '1px solid #e5e5e5'
    },
    swatch: {
      width: 200,
      height: 60,
      background: hex,
      marginBottom: '20px',
      position: 'absolute',
      borderRadius: 5
    },
    circles: {
      marginTop: 40
    }
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <Grid container direction="column">
          {hex === '#ffffff' ? (
            <Grid item>
              <div style={styles.inputWhite} />
              <Typography
                style={styles.inputWhiteText}
                variant="body1"
                onChange={onChange}
              >
                {hex}
              </Typography>
            </Grid>
          ) : (
            <Grid item>
              <div style={styles.swatch} />
              <Grid item>
                <Typography
                  style={styles.input}
                  variant="body1"
                  onChange={onChange}
                >
                  {hex}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid item>
            <div style={styles.circles}>
              <CirclePicker width="225px" onChange={onChange} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CustomPicker(MyPicker);
