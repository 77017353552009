export function logSentry(error, tags) {
  if (window && window.Sentry) {
    try {
      window.Sentry.captureException(error, {
        tags: tags
          ? {
              ...tags
            }
          : {}
      });
    } catch (error) {
      console.error('Failed to send to Sentry');
    }
  }
}
