import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Notification from 'react-notification';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  ADMIN_SHOP_FABRICS,
  ADMIN_GET_PRODUCT,
  ADMIN_GET_ORG_BOARDPRODUCTS
} from '../../gql/admin-queries';
import {
  ADMIN_UPDATE_PRODUCT,
  ADMIN_UPLOAD_FILE,
  ADMIN_COPY_FILE
} from '../../gql/admin-mutations';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import _sortBy from 'lodash/sortBy';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { API_STAGE } from '../../../../../brikl-config';
import { I18n } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import Helmet from 'react-helmet';
import Input from '../../../common/Input';
import TextField from '../../../common/TextField';
import Select from '../../../common/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Delete from '@material-ui/icons/Delete';
import Star from '@material-ui/icons/Star';

import _get from 'lodash/get';
import './ProductFileDrop.css';
import { DEFAULT_CURRENCY } from '../../../../helper/config';
import { CircularProgress } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import MediaList from '../media/MediaList';
import _each from 'lodash/each';
import _cloneDeep from 'lodash/cloneDeep';
import FileDrop from 'react-file-drop';
const axios = require('axios');
const shortid = require('shortid');
const uuidv4 = require('uuid/v4');
const jwtDecode = require('jwt-decode');
const omitDeep = require('omit-deep');

function getModalStyle() {
  return {
    top: '50px',
    left: 0,
    position: 'fixed',
    right: 0,
    height: '80vh',
    width: '100%',
    margin: '0 auto',
    maxWidth: '1000px',
    overflow: 'auto'
  };
}
class ProductPackageMedia extends Component {
  state = {
    isActive: false,
    message: '',
    isBriklSuperAdmin: false,
    open: false,
    value: 'Yes',
    showMediaRepository: false,
    filterByName: '',
    multiSelected: []
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
  addNewMediaFiles = async (newFiles, index) => {
    return new Promise(async (resolve, reject) => {
      const productPackage = this.props.productPackage;
      const newMediaFiles = newFiles.map((f, fileIndex) => {
        return {
          id: uuidv4(),
          productId: productPackage.id,
          sortOrder:
            productPackage.media &&
            productPackage.media.length > 0 &&
            productPackage.media[productPackage.media.length - 1] &&
            productPackage.media[productPackage.media.length - 1].sortOrder
              ? parseInt(
                  productPackage.media[productPackage.media.length - 1]
                    .sortOrder
                ) +
                100 * (fileIndex + 1)
              : 100,
          isThumbnail:
            productPackage.media == null ||
            (productPackage.media &&
              productPackage.media.find(m => m && m.isThumbnail) == null)
              ? true
              : false,
          isBanner: false,
          image: f,
          __typename: 'ProductMedia'
        };
      });
      console.log('newMediaFiles', newMediaFiles);
      await this.props.updateProductPackage({
        optimisticResponse: {
          __typename: 'Mutation',
          updateProductPackage: {
            ...productPackage,
            media: productPackage.media
              ? [...productPackage.media, ...newMediaFiles]
              : newMediaFiles
          }
        },
        variables: {
          id: productPackage.id,
          input: {
            media: productPackage.media
              ? _cloneDeep([...productPackage.media, ...newMediaFiles]).map(m =>
                  m && m.image ? omitDeep(m, ['__typename']) : null
                )
              : omitDeep(newMediaFiles, ['__typename'])
          }
        }
      });
      resolve();
    });
  };
  urlify = fileName => {
    fileName = decodeURI(fileName);
    var from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
    var to = 'aaaaaeeeeeiiiiooooouuuunc------';
    _each(from, function(character, i) {
      fileName = fileName.replace(
        new RegExp(from.charAt(i), 'g'),
        to.charAt(i)
      );
    });
    return fileName
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace('%', '') // Replace % with nothing
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/&/g, '-y-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-'); // Replace multiple - with single -
  };
  uploadProductPackageMediaFiles = async (
    files,
    uploadFile,
    productPackage,
    updateProductPackage
  ) => {
    try {
      const {} = this.props;
      this.setState({
        // uploading: files.length.toString()
        uploading: true
      });
      for (var i = 0; i < files.length; i++) {
        const myFile = files[i];
        if (myFile.size < 20000000) {
          const fileName =
            this.urlify(myFile.name) +
            '-' +
            shortid.generate() +
            '.' +
            myFile.name.split('.').pop();

          const signedUrlResult = await uploadFile({
            variables: {
              type: 'PRODUCT_MEDIA',
              fileName,
              prefixVariables: ['productId'],
              input: {
                productId: productPackage.id
              }
            }
          });
          // console.log('signedUrlResult', signedUrlResult);
          const signedUrl = signedUrlResult.data.uploadFile.url;
          var options = {
            headers: {
              'Content-Type': signedUrlResult.data.uploadFile.contentType
            }
          };
          if (signedUrlResult.data.uploadFile.acl) {
            options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl;
          }
          if (signedUrlResult.data.uploadFile.tagging) {
            options.headers['x-amz-tagging'] =
              signedUrlResult.data.uploadFile.tagging;
          }
          const uploadResult = await axios.put(signedUrl, myFile, options);
          // refetch();
          // console.log('uploadFile', uploadFile);

          this.addNewMediaFiles([fileName], i);

          console.log('uploadResult', uploadResult);
        } else {
          alert('Only files up to 20MB supported.');
        }
      }
      this.setState({
        uploading: false
      });
    } catch (error) {
      console.error('uploaderror', error);
      this.setState({
        uploading: false
      });
    }
  };

  render() {
    const {
      blockEdit,
      classes,
      productPackageId,
      updateProductPackage,
      productPackage,
      shopId
    } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Grid
            container
            style={{ padding: '24px 24px 24px 0px' }}
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <Typography
                data-cy="ProductPackage-Images-header"
                variant="subtitle1"
                style={{ marginRight: 16 }}
              >
                Product package Images
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Upload product images of your product packages
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Paper style={{ padding: 24 }} elevation={0}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                style={{
                  padding: '0px 8px 0px 0px !important'
                }}
              >
                {this.state.uploading === true ? <CircularProgress /> : null}
                <Button
                  data-cy="ProductPackage-Select-Media-btn"
                  onClick={() => this.setState({ showMediaRepository: true })}
                >
                  Select from media
                </Button>
                <br />- or -
                <Mutation
                  update={(store, { data: { uploadFile } }) => {}}
                  mutation={ADMIN_UPLOAD_FILE}
                >
                  {uploadFile => (
                    <Grid
                      container
                      style={{
                        border: '1px dashed #E5E5E5',
                        background: '#f5f5f5',
                        padding: 0,
                        borderRadius: '5px'
                      }}
                    >
                      {/* {JSON.stringify(product)} */}
                      <input
                        style={{
                          display: 'none'
                        }}
                        accept=".png,.jpg,.jpeg"
                        id="productMediaUploadInput"
                        type="file"
                        onChange={e => {
                          this.uploadProductPackageMediaFiles(
                            e.target.files,
                            uploadFile,
                            productPackage,
                            updateProductPackage
                          );
                        }}
                      />
                      <Grid
                        item
                        xs={12}
                        data-cy="ProductPackage-media-drag-and-drop-container"
                        style={{
                          padding: '0'
                        }}
                      >
                        <FileDrop
                          onDrop={(files, event) => {
                            console.log('handleDrop!', files, event);
                            this.uploadProductPackageMediaFiles(
                              files,
                              uploadFile,
                              productPackage,
                              updateProductPackage
                            );
                          }}
                        >
                          <Grid container direction="column">
                            <Grid item>
                              <span>
                                {I18n.get('Drag your file here ')}
                                <br />( .png or .jpg )
                              </span>
                            </Grid>
                          </Grid>
                        </FileDrop>
                      </Grid>
                    </Grid>
                  )}
                </Mutation>
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                style={{
                  border: '1px solid #E5E5E5',
                  padding: '8px 8px 4px 8px',
                  borderRadius: '5px'
                }}
              >
                <Grid container alignItems="center" direction="row" spacing={1}>
                  {productPackage.media
                    ? productPackage.media
                        .sort((m1, m2) => m1.sortOrder - m2.sortOrder)
                        .map((mediaItem, index) => {
                          return (
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              key={'style-' + index}
                              style={{
                                position: 'relative',
                                minHeight: 80,
                                marginBottom: 16
                              }}
                            >
                              <div style={{ height: 80, position: 'relative' }}>
                                <img
                                  src={mediaItem.image}
                                  alt="Storefront"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'inline',
                                    objectFit: 'contain',
                                    borderRadius: '6px',
                                    border: '1px solid #ccc'
                                  }}
                                />
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  style={{
                                    position: 'absolute',
                                    top: 60,
                                    right: 0,
                                    minHeight: 30,
                                    minWidth: 30,
                                    height: 30,
                                    width: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 0,
                                    borderRadius: '100%'
                                  }}
                                  onClick={() => {
                                    updateProductPackage({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateProductPackage: {
                                          ...productPackage,
                                          media: productPackage.media.filter(
                                            (removeStyle1, removePIindex) => {
                                              if (index === removePIindex) {
                                                return false;
                                              } else {
                                                return true;
                                              }
                                            }
                                          )
                                        }
                                      },
                                      variables: {
                                        id: productPackageId,
                                        input: {
                                          media: omitDeep(
                                            productPackage.media.filter(
                                              (
                                                removeStyle2,
                                                removePIindex2
                                              ) => {
                                                if (index === removePIindex2) {
                                                  return false;
                                                } else {
                                                  return true;
                                                }
                                              }
                                            ),
                                            ['__typename']
                                          )
                                        }
                                      }
                                    });
                                  }}
                                >
                                  <Delete style={{ fontSize: 18 }} />
                                </Button>
                                {
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    title={
                                      mediaItem.isThumbnail
                                        ? 'Is thumbnail'
                                        : 'Make thumbnail'
                                    }
                                    style={{
                                      position: 'absolute',
                                      top: 60,
                                      right: 40,
                                      minHeight: 30,
                                      minWidth: 30,
                                      height: 30,
                                      width: 30,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: 0,
                                      borderRadius: '100%'
                                    }}
                                    onClick={() => {
                                      updateProductPackage({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateProductPackage: {
                                            ...productPackage,
                                            media: productPackage.media.map(
                                              (removeStyle1, removePIindex) => {
                                                return {
                                                  ...removeStyle1,
                                                  isThumbnail:
                                                    mediaItem.id ===
                                                    removeStyle1.id
                                                      ? !mediaItem.isThumbnail
                                                      : removeStyle1.isThumbnail
                                                };
                                              }
                                            )
                                          }
                                        },
                                        variables: {
                                          id: productPackageId,
                                          input: {
                                            media: omitDeep(
                                              productPackage.media.map(
                                                (
                                                  removeStyle2,
                                                  removePIindex2
                                                ) => {
                                                  return {
                                                    ...removeStyle2,
                                                    isThumbnail:
                                                      mediaItem.id ===
                                                      removeStyle2.id
                                                        ? !mediaItem.isThumbnail
                                                        : removeStyle2.isThumbnail
                                                  };
                                                }
                                              ),
                                              ['__typename']
                                            )
                                          }
                                        }
                                      });
                                    }}
                                  >
                                    <Star
                                      style={{
                                        fontSize: 18,
                                        color:
                                          mediaItem.isThumbnail === true
                                            ? 'yellow'
                                            : 'white'
                                      }}
                                    />
                                  </Button>
                                }
                              </div>

                              <Grid container style={{ marginTop: 16 }}>
                                <Input
                                  label="Sort order"
                                  placeholder=""
                                  value={mediaItem.sortOrder}
                                  onChange={e => {
                                    updateProductPackage({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        updateProductPackage: {
                                          ...productPackage,
                                          media: productPackage.media.map(
                                            (removeStyle1, removePIindex) => {
                                              if (index === removePIindex) {
                                                return {
                                                  ...removeStyle1,
                                                  sortOrder: parseFloat(
                                                    e.target.value
                                                  )
                                                };
                                              } else {
                                                return {
                                                  ...removeStyle1
                                                };
                                              }
                                            }
                                          )
                                        }
                                      },
                                      variables: {
                                        id: productPackageId,
                                        input: {
                                          media: omitDeep(
                                            productPackage.media.map(
                                              (
                                                removeStyle2,
                                                removePIindex2
                                              ) => {
                                                if (index === removePIindex2) {
                                                  return {
                                                    ...removeStyle2,
                                                    sortOrder: parseFloat(
                                                      e.target.value
                                                    )
                                                  };
                                                } else {
                                                  return {
                                                    ...removeStyle2
                                                  };
                                                }
                                              }
                                            ),
                                            ['__typename']
                                          )
                                        }
                                      }
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          );
                        })
                    : null}
                </Grid>
                {this.state.showMediaRepository === true ? (
                  <Modal
                    style={{
                      display: this.state.uploading ? 'none' : 'block'
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.showMediaRepository}
                    onClose={() =>
                      this.setState({ showMediaRepository: false })
                    }
                  >
                    <Mutation
                      update={(store, { data: { copyFile } }) => {}}
                      mutation={ADMIN_COPY_FILE}
                    >
                      {copyFile => (
                        <div style={getModalStyle()} className={classes.paper}>
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              width: '100%',
                              background: 'white',
                              padding: '1em',
                              borderBottom: '1px solid'
                            }}
                          >
                            <input
                              placeholder="Search by filename"
                              value={this.state.filterByName}
                              onChange={e =>
                                this.setState({ filterByName: e.target.value })
                              }
                            />
                            &nbsp;&nbsp;&nbsp;
                            <span
                              onClick={() =>
                                this.setState({ viewMode: 'GALLERY' })
                              }
                            >
                              Gallery view
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            <span
                              onClick={() =>
                                this.setState({ viewMode: 'LIST' })
                              }
                            >
                              List view
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            <button
                              style={{
                                position: 'fixed',
                                left: 200,
                                top: 15,
                                padding: '5px',
                                cursor: 'pointer',
                                background: 'black',
                                color: 'white',
                                border: 'none',
                                outline: 'none'
                              }}
                              onClick={async () => {
                                this.setState({
                                  uploading: true
                                });
                                var copiedFiles = [];
                                this.state.multiSelected.forEach(element => {
                                  copiedFiles.push(
                                    new Promise(async (resolve, reject) => {
                                      const newFile = await copyFile({
                                        variables: {
                                          from: element,
                                          input: {
                                            productId: productPackage.id
                                          },
                                          type: 'PRODUCT_MEDIA'
                                        }
                                      });
                                      resolve(newFile.data.copyFile);
                                    })
                                  );
                                });
                                const newFiles = await Promise.all(copiedFiles);
                                console.log('newFiles', newFiles);
                                this.addNewMediaFiles(newFiles);

                                this.setState({
                                  uploading: false,
                                  showMediaRepository: false
                                });
                              }}
                            >
                              Add {this.state.multiSelected.length} selected
                            </button>
                          </div>

                          <div
                            style={{
                              marginTop: '4em'
                            }}
                          >
                            <MediaList
                              shopId={shopId}
                              viewMode={this.state.viewMode}
                              loading={this.state.loading}
                              filterByName={this.state.filterByName}
                              onMultiSelect={(file, checked) => {
                                this.setState({
                                  multiSelected: checked
                                    ? [
                                        ...this.state.multiSelected,
                                        file.fileName
                                      ]
                                    : this.state.multiSelected.filter(
                                        f => f !== file.fileName
                                      )
                                });
                              }}
                              selected={this.state.multiSelected}
                              onSelect={async fileName => {
                                try {
                                  const selectedFile = fileName;
                                  this.setState({
                                    uploading: true
                                  });
                                  const newFileName = await copyFile({
                                    variables: {
                                      from: selectedFile,
                                      input: {
                                        productId: productPackage.id
                                      },
                                      type: 'PRODUCT_MEDIA'
                                    }
                                  });
                                  if (
                                    newFileName &&
                                    newFileName.data &&
                                    newFileName.data.copyFile
                                  ) {
                                    this.addNewMediaFiles([
                                      newFileName.data.copyFile
                                    ]);
                                    this.setState({
                                      uploading: false,
                                      showMediaRepository: false
                                    });
                                  } else {
                                    alert('Failed to copy file');
                                  }
                                } catch (error) {
                                  this.setState({
                                    uploading: false
                                  });
                                  alert('Failed to copy file');
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Mutation>
                  </Modal>
                ) : null}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default ProductPackageMedia;
