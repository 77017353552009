import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import { navigate } from '@reach/router';
import { ADMIN_SHOP_PAGE_TAGS } from '../../gql/admin-queries';
import { ADMIN_CREATE_PAGE_TAG } from '../../gql/admin-mutations';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing,
    marginRight: theme.spacing
  }
});

class AddPageTag extends Component {
  state = {
    name: ''
  };

  render() {
    const { shopId } = this.props;
    return (
      <div>
        <Mutation
          mutation={ADMIN_CREATE_PAGE_TAG}
          variables={{ shopId }}
          update={(cache, { data: { createPageTag } }) => {
            if (createPageTag.id) {
              navigate(`/admin/${shopId}/pagebuilder/tags/${createPageTag.id}`);
              const data = cache.readQuery({
                query: ADMIN_SHOP_PAGE_TAGS,
                variables: { id: shopId }
              });
              data.shop.collections.edges.unshift({
                node: {
                  ...createPageTag
                },
                __typename: 'ShopCollectionEdge'
              });
              cache.writeQuery({
                query: ADMIN_SHOP_PAGE_TAGS,
                variables: { id: shopId },
                data
              });
            }
          }}
        >
          {addPageTag => (
            <Button color="primary" onClick={addPageTag} variant="outlined">
              New page tag
            </Button>
          )}
        </Mutation>
      </div>
    );
  }
}

export default withStyles(styles)(AddPageTag);
