import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import HomeIcon from '@material-ui/icons/Home'
import { Grid, IconButton } from '@material-ui/core'
import { useMutation } from '@apollo/react-hooks'
import PageDialog from './PageDialog'
import {
  ADMIN_UPDATE_CONTENT_MODEL,
  ADMIN_DELETE_CONTENT_MODEL,
  UPDATE_HOME_PAGE
} from '../gql/mutations'
import { ADMIN_GET_CONTENT_MENU } from '../../../../gql/admin-queries'
import { ADMIN_GET_CONTENT_MODELS } from '../gql/queries'
import ConfirmPageDelete from './ConfirmPageDelete'

interface Props {
  isHover: boolean
  pageId: string
  name: string
  description: string
  slugs: any
  shopId: string
  selectedSalesChannel: string | null
  salesChannels: any
  homePageId: string | null | undefined
  shopUrl: string
}

const PagesOps: React.FunctionComponent<Props> = props => {
  const {
    pageId,
    name,
    description,
    slugs,
    shopId,
    homePageId,
    salesChannels,
    selectedSalesChannel,
    shopUrl,
    isHover
  } = props

  const [dialog, setDialog] = useState(false)
  const [deletePage, setDeletePage] = useState(false)

  const [updateContentModel] = useMutation(ADMIN_UPDATE_CONTENT_MODEL, {
    refetchQueries: [
      { query: ADMIN_GET_CONTENT_MODELS, variables: { id: shopId } }
    ]
  })
  const [updateShopContentSettings] = useMutation(UPDATE_HOME_PAGE, {
    refetchQueries: [
      { query: ADMIN_GET_CONTENT_MENU, variables: { id: shopId } }
    ]
  })
  const [deleteContentModel] = useMutation(ADMIN_DELETE_CONTENT_MODEL, {
    refetchQueries: [
      {
        query: ADMIN_GET_CONTENT_MODELS,
        variables: { id: shopId, salesChannelId: selectedSalesChannel }
      }
    ]
  })
  return (
    <Grid container={true} direction="row">
      <Grid item={true}>
        {isHover ? (
          <IconButton onClick={() => setDialog(true)} size="small">
            <EditIcon />
          </IconButton>
        ) : null}
      </Grid>
      <Grid item={true}>
        {isHover ? (
          <IconButton onClick={() => setDeletePage(true)} size="small">
            <DeleteIcon />
          </IconButton>
        ) : null}
      </Grid>
      <Grid item={true}>
        {pageId === homePageId ? (
          <HomeIcon style={{ color: '#b23566' }} />
        ) : isHover ? (
          <IconButton
            style={{ color: pageId === homePageId ? '#b23566' : 'grey' }}
            onClick={() =>
              updateShopContentSettings({
                variables: {
                  id: shopId,
                  input: {
                    contentSettings: {
                      homePageId: pageId
                    }
                  }
                }
              })
            }
            size="small"
          >
            <HomeIcon />
          </IconButton>
        ) : null}
      </Grid>
      {deletePage ? (
        <ConfirmPageDelete
          dialog={deletePage}
          setDialog={setDeletePage}
          pageId={pageId}
          deleteContentModel={deleteContentModel}
        />
      ) : null}
      {open ? (
        <PageDialog
          shopUrl={shopUrl}
          dialog={dialog}
          title="Update Page"
          setDialog={setDialog}
          isUpdate={true}
          updatePage={updateContentModel}
          modelValues={{ name, description, slugs }}
          modelId={pageId}
          selectedSalesChannel={selectedSalesChannel}
          salesChannels={salesChannels}
        />
      ) : null}
    </Grid>
  )
}

export default PagesOps
