import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../../common/TextField';
import Select from '../../../../common/Select';
import _get from 'lodash/get';
import { getTextFromLanguage } from '../../../../../helper/languageHelper';
import _cloneDeep from 'lodash/cloneDeep';
const uuidv4 = require('uuid/v4');
const omitDeep = require('omit-deep');

const VariantOptionTitle = ({
  classes,
  languages,
  valueOption,
  variant,
  variantId,
  updateVariant
}) => {
  const titleId =
    valueOption.title && valueOption.title.id ? valueOption.title.id : uuidv4();
  return (
    <Grid container>
      {languages.map((language, index) => {
        var titleText = '';
        try {
          titleText = getTextFromLanguage(valueOption.title.text, language);
        } catch (error) {}
        return (
          <Grid
            container
            key={'style-titleText-' + index}
            direction="row"
            alignItems="flex-end"
            spacing={1}
            className={classes.container}
          >
            <Grid item xs={10}>
              <TextField
                dataCy={'Variant-Option-Title-' + language}
                label="Title"
                placeholder="Enter title"
                multiline={true}
                rowsMax="4"
                value={titleText}
                onChange={e => {
                  const newOptions = variant.options.map(
                    (tempVariantOption1, tempIndex1) => {
                      return {
                        ...tempVariantOption1,
                        title: {
                          ...tempVariantOption1.title,
                          text: languages.map((tempLanguage, index) => {
                            return {
                              langCode: tempLanguage,
                              content:
                                tempLanguage === language &&
                                valueOption.id === tempVariantOption1.id
                                  ? e.target.value
                                  : getTextFromLanguage(
                                      tempVariantOption1.title.text,
                                      tempLanguage
                                    ),
                              __typename: 'TextContent'
                            };
                          })
                        }
                      };
                    }
                  );

                  updateVariant({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      updateVariant: {
                        ...variant,
                        options: newOptions
                      }
                    },
                    variables: {
                      id: variantId,
                      input: {
                        options: _cloneDeep(newOptions).map(
                          (tempVariantOption, tempIndex) => {
                            return omitDeep(tempVariantOption, ['__typename']);
                          }
                        )
                      }
                    }
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{
                  height: 38,
                  width: '100%',
                  border: '1px solid #ddd',
                  borderRadius: '3px'
                }}
              >
                {language}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default VariantOptionTitle;
