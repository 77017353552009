import React, { Component } from 'react';

export default class AlignItemsFlexStart extends React.Component {
  render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="1.38462" height="18" fill="black" />
        <rect x="16.6154" width="1.38462" height="18" fill="black" />
        <path
          d="M7.61539 4.15384H10.3846V13.8461H7.61539V4.15384Z"
          fill="black"
        />
      </svg>
    );
  }
}
