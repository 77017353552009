import MomentUtils from '@date-io/moment'
import React, { useEffect, useState } from 'react'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'

interface Props {
  from?: number
  to?: number
  onSelect(from: number, to: number): void
}

const TimeFrameSelection: React.FunctionComponent<Props> = props => {
  const { from, to, onSelect } = props
  const defaultFrom = from ? moment.unix(from) : moment().startOf('month')
  const defaultTo = to ? moment.unix(to) : moment().endOf('month')

  const [fromVal, setFromVal] = useState(defaultFrom)
  const [toVal, setToVal] = useState(defaultTo)

  useEffect(() => {
    onSelect(fromVal.valueOf(), toVal.valueOf())
  }, [fromVal, toVal, onSelect])

  const handleChange = async (field: 'from' | 'to', e: any): Promise<void> => {
    if (field === 'from') {
      setFromVal(e)
    } else if (field === 'to') {
      setToVal(e)
    }
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Typography
        variant="subtitle1"
        style={{ marginTop: 24, marginBottom: 10 }}
      >
        Select a time frame
      </Typography>
      <Grid container={true}>
        <Grid item={true} xs={3}>
          <DatePicker
            data-testid="time-frame-from"
            value={fromVal}
            onChange={e => handleChange('from', e)}
          />
        </Grid>
        <Grid item={true} xs={3}>
          <DatePicker
            data-testid="time-frame-to"
            value={toVal}
            onChange={e => handleChange('to', e)}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default TimeFrameSelection
