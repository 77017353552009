import React from 'react'
import {createStyles, withStyles, WithStyles, TableCell, Button, Table, TableBody, TableRow, Paper} from '@material-ui/core'
import {Delete} from '@material-ui/icons'

import ModuleRender from '../../../../store/module/ModuleRender'
import ModuleSettingsLogo from './ModuleSettingsLogo'



const styles = () => createStyles({
   component: {
    border: '1px solid #0000001f',
    width: 'calc(100% - 88px)',
    borderRadius: '6px',
    margin: '8px 0px',
    background: '#FFFFFF'
  },
  ListItemText: { position: 'relative' },
  typo: {
    color: '#000000'
  },
  button: {
    color: '#B23566',
    border: '1px solid #B23566',
    position: 'absolute',
    top: '0%',
    right: 0,
    marginTop: '-64px'
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45
  },
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%',
    height: '100%'
  },
  cell: {
    padding: '0px 16px !important'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  }
})


interface Props extends WithStyles<typeof styles> {
  data: any[],
  shop: any,
  onDelete(id: string): void,
  onSaveSettings(id: string, contentDetail: any): void
}


const ModuleList: React.FunctionComponent<Props> = (props) => {
  const { classes, data, shop, onDelete, onSaveSettings } = props

  console.log(data)

  return (
    <Paper elevation={0}>
      <Table className={classes.table}>
        <TableBody>
          {data.map(module => (
            <TableRow key={module.node.if}>
              <TableCell className={classes.cell}>
                {module.node.label || module.node.contentDetail.type}
              </TableCell>
              <TableCell className={classes.cell}>
                {(module.node.contentDetail.type === 'SHOP_LOGO') ? (
                  <ModuleSettingsLogo data={module.node} onSave={onSaveSettings} />
                ) : null}
              </TableCell>
              <TableCell className={classes.cell}>
                <Button onClick={() => onDelete(module.node.id)}>
                  <Delete />
                </Button>
              </TableCell>
              <TableCell className={classes.cell}>
                {/*<ModuleRender*/}
                {/*  shop={shop}*/}
                {/*  contentDetail={module.node.contentDetail}*/}
                {/*  styling={{*/}
                {/*    color: 'black'*/}
                {/*  }}*/}
                {/*  urlPrefix={''}*/}
                {/*/>*/}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default withStyles(styles, { withTheme: true })(ModuleList)
