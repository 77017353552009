import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { Link } from 'gatsby'
import Price from '../../../../../../store/product/Price'
import Imgix from 'react-imgix'
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../../../../../brikl-config'

interface Props {
  thumbnail: any
  name: any
  price: any
  slug: any
  priceData?: any
  productId?: string
  dialog?: boolean
}

const Simple: React.FC<Props> = props => {
  const { thumbnail, name, price, slug, priceData, productId, dialog } = props

  if (dialog) {
    return (
      <Card
        style={{
          maxWidth: '250px',
          maxHeight: '340px',
          boxShadow: 'none',
          padding: '20px',
          backgroundColor: 'transparent',
          margin: '0 auto'
        }}
      >
        <Imgix
          height={340}
          htmlAttributes={{
            alt: '',
            style: {
              width: '100%',
              height: '250px',
              objectFit: 'cover'
            }
          }}
          src={
            thumbnail ||
            'https://images.unsplash.com/reserve/LJIZlzHgQ7WPSh5KVTCB_Typewriter.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60'
          }
          imgixParams={{
            auto: 'compress'
          }}
        />
        <Grid container justify="space-between" style={{ paddingTop: '5px' }}>
          <Grid item>
            <Typography style={{ fontSize: '14px' }}>
              {name || `Product title`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontWeight: 'bold', fontSize: '14px' }}>
              <Price
                defaultCurrency={priceData?.defaultCurrency}
                productId={productId}
                currencyRates={priceData?.currencyRates}
                taxSettings={priceData?.taxSettings}
                price={price}
                discounts={priceData?.discounts}
              />
            </Typography>
          </Grid>
        </Grid>
      </Card>
    )
  } else {
    return (
      <Link
        style={{
          position: 'relative',
          color: '#000',
          textDecoration: 'none'
        }}
        to={slug || ''}
      >
        <Card
          style={{
            maxWidth: '250px',
            maxHeight: '340px',
            boxShadow: 'none',
            padding: '20px',
            backgroundColor: 'transparent'
          }}
        >
          <Imgix
            height={340}
            htmlAttributes={{
              alt: name,
              style: {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                display: 'block'
              }
            }}
            imgixParams={{
              auto: 'compress'
            }}
            src={
              thumbnail
                ? thumbnail.replace(MEDIA_CDN_URL, IMGIX_URL)
                : 'https://images.unsplash.com/reserve/LJIZlzHgQ7WPSh5KVTCB_Typewriter.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60'
            }
            alt={name}
          />

          <Grid container justify="space-between" style={{ paddingTop: '5px' }}>
            <Grid item>
              <Typography style={{ fontSize: '14px' }}>
                {name?.substring(0, 16) + '...' || `Name cannot be found!`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontWeight: 'bold', fontSize: '14px' }}>
                <Price
                  defaultCurrency={priceData?.defaultCurrency}
                  productId={productId}
                  currencyRates={priceData?.currencyRates}
                  taxSettings={priceData?.taxSettings}
                  price={price}
                  discounts={priceData?.discounts}
                />
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Link>
    )
  }
}

export default Simple
