import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import { Notes, Inputs } from '../common/Theme';
import { Link } from 'gatsby';
import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
const styles = theme => ({
  root: {
    background: '#E5E5E5',
    height: 'calc(100vh - 50px)',
    padding: '25px 15px',
    position: 'relative'
  },
  input: {
    background: '#f4f4f4 !important',
    margin: '16px 0px !important',
    width: '300px',
    padding: '0px !important'
  },
  button: {
    width: '300px',
    minHeight: '45px',
    margin: '24px 0px !important'
  },
  textInput: {
    padding: '13px 24px'
  },
  forgotPassword: {
    position: 'absolute',
    bottom: '-4px',
    right: 0,
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    ':hover': {
      color: '#ffffff !important'
    }
  },
  signup: {
    fontSize: '12px',
    color: '#606060',
    textDecoration: 'none',
    ':hover': {
      color: '#ffffff !important'
    }
  }
});

class Confirm extends React.Component {
  static defaultProps = {
    authData: {},
    authState: 'signIn'
    // onAuthStateChange: (next, data) => { console.log(`SignIn:onAuthStateChange(${next}, ${JSON.stringify(data, null, 2)})`); }
  };

  constructor(props) {
    super(props);
    this.onConfirm = this.onConfirm.bind(this);
    this.state = {
      authData: this.props.authData,
      authState: this.props.authState,
      modalShowing: false,
      loading: false,
      error: null,
      email: this.props.currentUser.email || '',
      password: '',
      user: null
    };
  }

  parse_query_string(query) {
    var vars = query.split('&');
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      // If first entry with this name
      if (typeof query_string[pair[0]] === 'undefined') {
        query_string[pair[0]] = decodeURIComponent(pair[1]);
        // If second entry with this name
      } else if (typeof query_string[pair[0]] === 'string') {
        var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
        query_string[pair[0]] = arr;
        // If third or later entry with this name
      } else {
        query_string[pair[0]].push(decodeURIComponent(pair[1]));
      }
    }
    return query_string;
  }

  async componentDidMount() {
    try {
      var urlparams = this.parse_query_string(
        window.location.search.substring(1)
      );
      console.log('urlparams', urlparams);
      if (urlparams && urlparams.email && urlparams.code) {
        this.setState({
          loading: true,
          email: urlparams.email,
          code: urlparams.code
        });
        Amplify.Auth.confirmSignUp(urlparams.email, urlparams.code)
          .then(data => {
            this.setState({
              loading: false
            });
            this.props.currentUser.changeEmail(urlparams.email);
            navigate(`/admin/login`);
          })
          .catch(err => {
            console.log(err);
            this.setState({
              loading: false,
              error: err.message || err
            });
          });
      } else {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        if (
          cognitoUser &&
          cognitoUser.attributes &&
          cognitoUser.attributes.email
        ) {
          this.setState({
            email: cognitoUser.attributes.email
          });
        }
      }
    } catch (error) {
      console.error('Confirm.componentDidMount.error', error);
      this.setState({
        loading: false,
        error: error.message || error
      });
    }
  }

  async onConfirm() {
    this.setState({ loading: true });
    try {
      const email = this.state.email;
      const code = this.state.code;
      Auth.confirmSignUp(email, code)
        .then(data => {
          console.log(data);
          navigate(`/admin/login`);
        })
        .catch(err => {
          console.error(err);
          this.setState({ error: err.message, loading: false });
        });
    } catch (err) {
      console.error(`Error: ${JSON.stringify(err, null, 2)}`);
      this.setState({ error: err.message, loading: false });
    }
  }
  resend = () => {
    // Auth.resendSignUp(username)
  };

  updateEmail = e => {
    this.setState({
      email: e.target.value
    });
  };
  updateCode = e => {
    this.setState({
      code: e.target.value
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.root}
      >
        {this.state.error ? (
          <div>
            <div>{this.state.error}</div>
          </div>
        ) : null}
        <Inputs
          data-html2canvas-ignore
          data-cy="email"
          ref="email"
          type="email"
          value={this.state.email}
          onChange={val => this.updateEmail(val)}
          placeholder="Email"
          classes={{ root: classes.input, input: classes.textInput }}
        />
        <div style={{ position: 'relative' }}>
          <Inputs
            data-html2canvas-ignore
            data-cy="code"
            ref="code"
            type="text"
            value={this.state.code}
            onChange={val => this.updateCode(val)}
            placeholder="Confirmation code sent to your email"
            classes={{ root: classes.input, input: classes.textInput }}
          />
        </div>
        {this.state.loading === true ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={true}
          >
            Loading
          </Button>
        ) : (
          <Button
            data-cy="confirmEmail"
            title="Confirm email"
            onClick={this.onConfirm.bind(this)}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Confirm email
          </Button>
        )}

        <Grid container justify="center">
          <Link to={`/admin/login`} className={classes.signup}>
            Already have an account? Login!
          </Link>
        </Grid>
      </Grid>
    );
  }
}

Confirm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Confirm);
