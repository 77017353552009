import React, { Component } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../../common/Select';
import { Link } from 'gatsby';
import { Query, Mutation } from 'react-apollo';
import {
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_GET_PRODUCT_PACKAGE
} from '../../gql/admin-queries';
import {
  ADMIN_CREATE_COLLECTION_PRODUCT_PACKAGE,
  ADMIN_DELETE_COLLECTION_PRODUCT_PACKAGE
} from '../../gql/admin-mutations';
import { getTextFromLanguage } from '../../../../helper/languageHelper';

class ProductPackageCollections extends Component {
  state = {
    selectedCollectionId: null,
    loading: false
  };
  render() {
    const { productPackage, refetch, shopId, defaultLanguage } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography
            data-cy="ProductPackage-Collections-header"
            variant="subtitle1"
          >
            Collections
          </Typography>
        </Grid>
        <Query query={ADMIN_SHOP_COLLECTIONS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            var sortedCollections = null;
            try {
              sortedCollections = shop.collections.edges.sort(function(a, b) {
                if (
                  getTextFromLanguage(a.node.title.text, defaultLanguage) <
                  getTextFromLanguage(b.node.title.text, defaultLanguage)
                ) {
                  return -1;
                }
                if (
                  getTextFromLanguage(a.node.title.text, defaultLanguage) >
                  getTextFromLanguage(b.node.title.text, defaultLanguage)
                ) {
                  return 1;
                }
                return 0;
              });
            } catch (error) {
              console.error('pvo.error', error);
            }
            return (
              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                <Paper elevation={0} style={{ padding: 24 }}>
                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item>
                      <Select
                        dataCy="ProductPackage-Collections-Select"
                        onChange={e => {
                          this.setState({
                            selectedCollectionId: e.target.value
                          });
                        }}
                        value={this.state.selectedCollectionId}
                        label="Collections"
                      >
                        <option value={''}>Choose</option>
                        {sortedCollections
                          ? sortedCollections.map(pa => {
                              if (pa && pa.node && pa.node.title) {
                                return (
                                  <option value={pa.node.id}>
                                    {getTextFromLanguage(
                                      pa.node.title.text,
                                      defaultLanguage
                                    )}
                                  </option>
                                );
                              } else {
                                return null;
                              }
                            })
                          : null}
                      </Select>
                    </Grid>
                    <Grid item>
                      <Mutation
                        mutation={ADMIN_CREATE_COLLECTION_PRODUCT_PACKAGE}
                        update={(
                          cache,
                          { data: { createCollectionProductPackage } }
                        ) => {
                          if (createCollectionProductPackage) {
                            const data = cache.readQuery({
                              query: ADMIN_GET_PRODUCT_PACKAGE,
                              variables: { id: productPackage.id }
                            });
                            const newCollection = shop.collections.edges.find(
                              c => {
                                return (
                                  c.node.id === this.state.selectedCollectionId
                                );
                              }
                            );
                            // console.log('newCollection', newCollection);
                            data.productPackage.collections.unshift({
                              collection: {
                                ...newCollection.node
                              },
                              __typename: 'ProductPackageCollection'
                            });
                            cache.writeQuery({
                              query: ADMIN_GET_PRODUCT_PACKAGE,
                              variables: { id: productPackage.id },
                              data
                            });
                          }
                        }}
                      >
                        {(
                          createCollectionProductPackage,
                          { data, loading, error }
                        ) => {
                          return (
                            <Button
                              data-cy="ProductPackage-Collections-Assign-btn"
                              onClick={async () => {
                                await createCollectionProductPackage({
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    createCollectionProductPackage: {
                                      collectionId: this.state
                                        .selectedCollectionId,
                                      productPackage: {
                                        ...productPackage
                                      },
                                      productPackageId: productPackage.id,
                                      __typename: 'CollectionProductPackage'
                                    }
                                  },
                                  variables: {
                                    shopId: shopId,
                                    productPackageId: productPackage.id,
                                    collectionId: this.state
                                      .selectedCollectionId
                                  }
                                });
                              }}
                              variant="contained"
                              color="primary"
                              style={{ fontWeight: 400 }}
                            >
                              Assign collection
                            </Button>
                          );
                        }}
                      </Mutation>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 8 }}>
                    {this.state.loading === true ? (
                      <CircularProgress />
                    ) : productPackage.collections ? (
                      productPackage.collections.map(pc => (
                        <Grid
                          container
                          alignItems="center"
                          style={{ padding: '8px 0px' }}
                        >
                          <Grid
                            item
                            data-cy={
                              'ProductPackage-Collection-Item-' +
                              pc.collection.no
                            }
                            style={{
                              border: '1px solid #B23566',
                              height: 36,
                              borderRadius: 3,
                              display: 'flex',
                              alignContent: 'center',
                              padding: 8,
                              marginRight: 8
                            }}
                          >
                            {pc.collection.title
                              ? getTextFromLanguage(
                                  pc.collection.title.text,
                                  defaultLanguage
                                )
                              : 'N/A'}
                          </Grid>
                          <Grid item>
                            <Mutation
                              mutation={ADMIN_DELETE_COLLECTION_PRODUCT_PACKAGE}
                              update={(
                                cache,
                                { data: { deleteCollectionProductPackage } }
                              ) => {
                                if (deleteCollectionProductPackage) {
                                  const data = cache.readQuery({
                                    query: ADMIN_GET_PRODUCT_PACKAGE,
                                    variables: { id: productPackage.id }
                                  });
                                  data.productPackage.collections = data.productPackage.collections.filter(
                                    c => c.collection.id !== pc.collection.id
                                  );
                                  cache.writeQuery({
                                    query: ADMIN_GET_PRODUCT_PACKAGE,
                                    variables: { id: productPackage.id },
                                    data
                                  });
                                }
                              }}
                            >
                              {(
                                deleteCollectionProductPackage,
                                { data, loading, error }
                              ) => {
                                return (
                                  <Button
                                    data-cy={
                                      'ProductPackage-Collection-Delete-btn-' +
                                      pc.collection.no
                                    }
                                    onClick={async () => {
                                      if (pc) {
                                        await deleteCollectionProductPackage({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            deleteCollectionProductPackage: true
                                          },
                                          variables: {
                                            productPackageId: productPackage.id,
                                            collectionId: pc.collection.id
                                          }
                                        });
                                      }
                                    }}
                                    color="primary"
                                    variant="contained"
                                  >
                                    <DeleteIcon style={{ fontSize: '20' }} />
                                  </Button>
                                );
                              }}
                            </Mutation>
                          </Grid>
                        </Grid>
                      ))
                    ) : null}
                  </Grid>
                </Paper>
              </Grid>
            );
          }}
        </Query>
      </Grid>
    );
  }
}

export default ProductPackageCollections;
