import React, { useEffect, useState, useRef } from 'react'
import * as THREE from 'three'
import { helperSetup, lightSetup } from './util_3D/utils'
import { setUpModel, remapUV } from './util_3D/model'

interface Props {
  OBJ: string
  MTL: string
  BASE64: string
  WIDTH: number
  HEIGHT: number
  style: Object
}

const Component3D: React.FunctionComponent<Props> = props => {
  const { OBJ, MTL, BASE64, WIDTH, HEIGHT, style } = props

  const [mainObject, setMainObject] = useState(null)
  const [materialUVGroup, setMaterialUVGroup] = useState([])

  const render3D = useRef<HTMLDivElement>()

  let camera: { position: { z: number; y: number }; add: (arg0: any) => void },
    scene: { add: (arg0: any) => void },
    renderer: {
      shadowMap: { enabled: boolean }
      setSize: (arg0: any, arg1: any) => void
      domElement: any
      render: (arg0: { add: (arg0: any) => void }, arg1: any) => void
    } // GLOBAL PARAMETER (COMMON)

  useEffect(() => {
    console.log('Component3D useEffect', props)
    setup3D() // SCENE,CAMERA, RENDERER
    setUpModel(scene, OBJ, MTL, setMaterialUVGroup, setMainObject)
  }, [])

  useEffect(() => {
    if (BASE64 && materialUVGroup.length && mainObject !== null) {
      remapUV(BASE64, materialUVGroup, mainObject)
    }
  }, [BASE64, mainObject, materialUVGroup])

  const setup3D = () => {
    console.log('setup3D')

    if (render3D.current) {
      var container = render3D.current

      let canvasW = WIDTH
        ? WIDTH
        : container.parentElement
        ? container.parentElement?.offsetWidth
        : 1
      let canvasH = HEIGHT
        ? HEIGHT
        : container.parentElement
        ? container.parentElement?.offsetHeight
        : 1
      camera = new THREE.PerspectiveCamera(15, canvasW / canvasH, 0.1, 2000)
      camera.position.z = 1.5

      scene = new THREE.Scene()
      scene.add(camera)

      renderer = new THREE.WebGLRenderer({
        antialias: true,
        // preserveDrawingBuffer: true,
        alpha: true
      })
      // renderer.shadowMap.enabled = true
      renderer.setSize(canvasW, canvasH)
      container.appendChild(renderer.domElement)

      helperSetup(scene, camera, renderer)
      lightSetup(scene, camera)
      animate()
    }
  }

  const animate = () => {
    renderer.render(scene, camera)
    window.requestAnimationFrame(() => animate()) // RUN FRAME BY FRAME
  }

  return (
    <>
      <div ref={render3D} id="render3D" style={style}></div>
    </>
  )
} // END Component

export default Component3D
