import React from 'react'
import {EditorState, RichUtils} from 'draft-js'
import {createStyles, withStyles, WithStyles, Toolbar, IconButton }from '@material-ui/core'
import {FormatBold, FormatItalic, FormatUnderlined, FormatListBulleted, FormatListNumbered} from '@material-ui/icons'


const styles = () => createStyles({
  toolbar: {
    '& button': {
      padding: '2px'
    },
    backgroundColor: '#f2f2f2',
    marginBottom: '15px',
    marginTop: '15px',
    minHeight: '0',
    padding: '0'
  }
})

interface Props extends WithStyles<typeof styles> {
  editorState: EditorState,
  setEditorState(state: EditorState): void
}


const RichTextToolbar: React.FunctionComponent<Props> = (props) => {
  const { editorState, setEditorState, classes } = props


  const handleBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
  }


  const handleItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
  }


  const handleUnderlineClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
  }


  const handleUnorderedListClick = () => {
    setEditorState(RichUtils.toggleBlockType(editorState, 'unordered-list-item'))
  }


  const handleOrderedListClick = () => {
    setEditorState(RichUtils.toggleBlockType(editorState, 'ordered-list-item'))
  }


  const getSelectedBlockType = () => {
    return RichUtils.getCurrentBlockType(editorState)
  }


  return (
    <Toolbar
      className={classes.toolbar}
      data-testid="richtext-toolbar"
    >
      <IconButton
        onClick={handleBoldClick}
      >
        <FormatBold />
      </IconButton>
      <IconButton
        onClick={handleItalicClick}
      >
        <FormatItalic />
      </IconButton>
      <IconButton
        onClick={handleUnderlineClick}
      >
        <FormatUnderlined />
      </IconButton>
      <IconButton
        onClick={handleUnorderedListClick}
        color={getSelectedBlockType() === 'unordered-list-item' ? 'primary' : 'default'}
      >
        <FormatListBulleted />
      </IconButton>
      <IconButton
        onClick={handleOrderedListClick}
        color={getSelectedBlockType() === 'ordered-list-item' ? 'primary' : 'default'}
      >
        <FormatListNumbered />
      </IconButton>
    </Toolbar>
  )
}

export default withStyles(styles, { withTheme: true })(RichTextToolbar)