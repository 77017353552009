import gql from 'graphql-tag'
import { Board } from '../../../gql/fragments'
import { BoardItemDetailFragment } from '../../../gql/queries'
import {
  ProductPreviewFragment,
  ProductDetailsFragment,
  ShopSettingFragment,
  FabricDesignStepFragment,
  ColorDesignStepFragment,
  TextDesignStepFragment,
  LogoDesignStepFragment,
  CouponFragment,
  ProductAttributeFragment,
  VariantFragment,
  CollectionFragment,
  ContentDetailCollectionCardFragment,
  ContentDetailProductCardFragment,
  ContentDetailTeamstoreCardFragment,
  ContentDetailBannerFragment,
  ContentColumnFragment,
  ContentDetailTextFragment,
  ContentDetailImageFragment,
  ContentDetailCardFragment,
  ContentDetailHubspotFormFragment,
  ContentDetailSubscribeCardFragment,
  ContentDetailContactCardFragment,
  ContentDetailLocationCardFragment,
  ContentDetailButtonFragment,
  ContentDetailInputFieldFragment,
  ContentDetailDividerFragment,
  ContentDetailDiscoverCardFragment,
  AdminLeadFragment
} from './admin-fragments'
import {
  DiscountFragment,
  DiscountRuleFragment,
  PrintingDetailFragment,
  TextFragment,
  PricingDetailFragment,
  ProductOptionValueFragment,
  MenuFragment,
  ThemeFragment,
  ContentPageFragment,
  PageTagFragment
} from '../../../gql/fragments'

export const ADMIN_GET_TEAMSTORE = gql`
  query teamstore($id: ID!) {
    teamstore(id: $id) {
      id
      enabled
      hasOutOfStock
      type
      privacyType
      name
      logo
      mainBanner
      brandColor
      availableFrom
      availableUntil
      contactPerson
      contactEmail
      contactPhone
      hasPassword
      hasPersonalization
      password
      purchaseLimitPerCustomer
      title {
        id
        defaultValue
        text {
          langCode
          content
        }
      }
      introductionText {
        id
        text {
          langCode
          content
        }
      }

      slugs {
        langCode
        content
      }
      groups {
        id
        name
        title {
          id
          defaultValue
          text {
            langCode
            content
          }
        }
      }
      targetCategories {
        id
        name
        title {
          id
          defaultValue
          text {
            langCode
            content
          }
        }
      }
      customizationSettings {
        customizationOptionGroups {
          id
          options {
            id
            type
            title {
              id
              text {
                langCode
                content
              }
            }
            pricingRules {
              ...pricingDetail
            }
          }
        }
      }
      shippingSettings {
        id
        shippingZones {
          id
          name
          applyToAllCountries
          countryCodes
          regionCodes
          shippingRates {
            id
            priority
            type
            minimumTotal {
              currency
              value
            }
            minimumWeight
            weightUnit
            rate {
              currency
              value
            }
          }
        }
      }
      productPackages {
        edges {
          node {
            sortOrder
            featured
            productPackageId
            salesChannelId
            productPackage {
              id
              no
              media {
                id
                productId
                variantId
                variantOptionId
                optionValue
                sortOrder
                isThumbnail
                isBanner
                image
              }
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
          }
        }
      }
      products {
        edges {
          node {
            id
            featured
            sortOrder
            productId
            salesChannelId
            product {
              id
              no
              media {
                id
                productId
                variantId
                variantOptionId
                optionValue
                sortOrder
                isThumbnail
                isBanner
                image
              }
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
          }
        }
      }
      collections {
        edges {
          node {
            id
            featured
            sortOrder
            collectionId
            salesChannelId
            collection {
              id
              no
              thumbnail
              slugs {
                langCode
                content
              }
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
          }
        }
      }
    }
  }
  ${PricingDetailFragment}
`

export const ADMIN_GET_ADMIN_USERS = gql`
  query adminGetAdminUsers($id: ID!) {
    shop(id: $id) {
      id
      adminUsers {
        edges {
          node {
            id
            userId
            shopId
            email
            role
            permissions
            status
          }
        }
      }
    }
  }
`

export const ADMIN_GET_PAGEBUILDER_VERSION = gql`
  query shop($id: ID!) {
    shop(id: $id) {
      id
      pagebuilderVersion
    }
  }
`

export const NETLIFY_DEPLOYMENT_STATUS = gql`
  query netlifyDeploymentStatus($siteId: ID!, $deployId: ID!) {
    netlifyDeploymentStatus(siteId: $siteId, deployId: $deployId) {
      id
      status
      previewUrl
    }
  }
`

export const GET_PRODUCT_OPTION_VALUE = gql`
  query productOptionValue($productId: ID!, $optionValues: String!) {
    productOptionValue(productId: $productId, optionValues: $optionValues) {
      ...productOptionValueFragment
    }
  }
  ${ProductOptionValueFragment}
`
export const GET_ADMIN_USER = gql`
  query getAdminUser {
    user {
      id
      email
      settings {
        defaultLanguage
        defaultCurrency
        defaultWeightUnit
        defaultLengthUnit
      }
      organisationMemberships {
        id
        organisationId
        userId
        role
      }
    }
  }
`

export const ADMIN_GET_ORG_BOARDPRODUCTS = gql`
  query customerBoardProducts($ownedByOrganisationId: ID!) {
    customerBoardProducts(ownedByOrganisationId: $ownedByOrganisationId) {
      edges {
        node {
          id
          shopId
          ownedByOrganisationId
          baseProductId
          name
          updatedAt
          createdAt
        }
      }
    }
  }
`
export const ADMIN_GET_LEAD = gql`
  query adminGetLead($id: ID!) {
    lead(id: $id) {
      ...adminLeadFragment
    }
  }
  ${AdminLeadFragment}
`
export const ADMIN_GET_LEADS = gql`
  query adminGetLeads($id: ID!) {
    shop(id: $id) {
      id
      leads(first: 100, reverse: true) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            createdAt
            status
            firstName
            lastName
          }
        }
      }
    }
  }
`
export const ADMIN_GET_DEPLOYMENTS = gql`
  query adminGetDeployments($id: ID!) {
    shop(id: $id) {
      id
      deployments(first: 5, reverse: true) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            createdAt
            finishedAt
            status
            triggeredBy
            publishedAt
            previewUrl
            isPollingEnabled
            isPublishEnabled
          }
        }
      }
    }
  }
`

export const ADMIN_SHOP_SALESCHANNELS = gql`
  query adminShopSalesChannels($id: ID!) {
    shop(id: $id) {
      id
      salesChannels {
        edges {
          node {
            id
            enabled
            type
            privacyType
            logo
            name
            slugs {
              langCode
              content
            }
            title {
              id
              text {
                content
                langCode
              }
            }
          }
        }
      }
    }
  }
`

export const ADMIN_SHOP_MEDIA = gql`
  query adminShopMedia($id: ID!) {
    shop(id: $id) {
      id
      media {
        edges {
          node {
            id
            fileName
          }
        }
      }
    }
  }
`
export const ADMIN_SHOP_TEAMSTORES = gql`
  query adminShopTeamstores($id: ID!) {
    shop(id: $id) {
      id
      salesChannels {
        edges {
          node {
            id
            enabled
            type
            privacyType
            logo
            name
            availableFrom
            availableUntil
            hasPassword
            title {
              id
              defaultValue
              text {
                content
                langCode
              }
            }
            slugs {
              langCode
              content
            }
          }
        }
      }
    }
  }
`
export const ADMIN_SHOP_FABRICS = gql`
  query adminShopFabrics($id: ID!) {
    shop(id: $id) {
      id
      fabrics(shopId: $id) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
            code
            printable
            printableColors
            summary
          }
        }
      }
      fabricGroups(shopId: $id) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
            shopId
          }
        }
      }
    }
  }
`
export const ADMIN_SHOP_DISCOUNTS = gql`
  query adminShopDiscounts($id: ID!) {
    shop(id: $id) {
      id
      discounts(shopId: $id) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            enabled
            hasCoupon
            title {
              id
              text {
                langCode
                content
              }
            }
            discountType
            availableFrom
            availableUntil
          }
        }
      }
    }
  }
`
export const ADMIN_SHOP_COUPONS = gql`
  query adminShopCoupons($id: ID!, $first: Int) {
    shop(id: $id) {
      id
      coupons(shopId: $id) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ...couponFragment
          }
        }
      }
    }
  }
  ${CouponFragment}
`

export const ADMIN_SHOP_FABRIC_GROUPS = gql`
  query adminShopFabricGroups($id: ID!) {
    shop(id: $id) {
      id
      fabricGroups(shopId: $id) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
            shopId
          }
        }
      }
    }
  }
`

export const ADMIN_SHOP_COLORS = gql`
  query shopColor($id: ID!) {
    shop(id: $id) {
      id
      colors(shopId: $id) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            internalCode
            code
            hex
            internalName
            name
            pantone
          }
        }
      }
    }
  }
`

export const ADMIN_SHOP_VARIANTS = gql`
  query shopVariants($id: ID!) {
    shop(id: $id) {
      id
      variants(shopId: $id) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ...variantFragment
          }
        }
      }
    }
  }
  ${VariantFragment}
`

export const ADMIN_GET_PAGE_TAG = gql`
  query pageTag($id: ID!) {
    pageTag(id: $id) {
      ...pageTagFragment
    }
  }
  ${PageTagFragment}
`
export const ADMIN_SHOP_PAGE_TAGS = gql`
  query shopPageTags($id: ID!) {
    shop(id: $id) {
      id
      pageTags(shopId: $id) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ...pageTagFragment
          }
        }
      }
    }
  }
  ${PageTagFragment}
`
export const ADMIN_SHOP_PRODUCT_ATTRIBUTES = gql`
  query shopProductAttributes($id: ID!) {
    shop(id: $id) {
      id
      productAttributes(shopId: $id) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ...productAttributeFragment
          }
        }
      }
    }
  }
  ${ProductAttributeFragment}
`
export const ADMIN_SHOP_COLLECTIONS = gql`
  query shopCollections($id: ID!) {
    shop(id: $id) {
      id
      collections {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            accountingId
            no
            title {
              id
              defaultValue
              text {
                langCode
                content
              }
            }
            slugs {
              langCode
              content
            }
            thumbnail
            mainBanner
          }
        }
      }
    }
  }
`

export const ADMIN_SHOP_CUSTOM_PRODUCTS = gql`
  query adminSearchCustomProducts($name: String, $collectionIds: [ID]) {
    adminSearchCustomProducts(name: $name, collectionIds: $collectionIds) {
      ...productPreviewFragment
    }
  }
  ${ProductPreviewFragment}
`

export const ADMIN_SHOP_CUSTOM_PRODUCT_DESIGNS = gql`
  query customerProductDesigns($ownedByOrganisationId: ID!) {
    customerProductDesigns(ownedByOrganisationId: $ownedByOrganisationId) {
      edges {
        node {
          id
          shopId
          createdAt
          updatedAt
          ownedByOrganisationId
          product {
            id
            designStudioVersion
          }
          latestRevision {
            id
            revisionName
          }
        }
      }
    }
  }
`

export const ADMIN_SHOP_PRODUCT_PACKAGES = gql`
  query shopProductPackages($id: ID!) {
    shop(id: $id) {
      id
      productPackages(shopId: $id) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            accountingId
            status
            productIds
            price {
              roundUp
              currency
              value
            }
            slugs {
              content
              langCode
            }
            media {
              id
              productId
              variantId
              variantOptionId
              optionValue
              sortOrder
              isThumbnail
              isBanner
              image
            }
            title {
              id
              text {
                langCode
                content
              }
            }
            description {
              id
              text {
                langCode
                content
              }
            }
          }
        }
      }
    }
  }
`
export const ADMIN_SHOP_PRODUCTS = gql`
  query shopProducts(
    $id: ID!
    $first: Int
    $after: String
    $filter: ProductFilter
  ) {
    shop(id: $id) {
      id
      products(shopId: $id, first: $first, after: $after, filter: $filter) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ...productPreviewFragment
          }
        }
      }
    }
  }
  ${ProductPreviewFragment}
`
export const ADMIN_GET_COLLECTION = gql`
  query collection($id: ID!) {
    collection(id: $id) {
      ...collectionFragment
    }
  }
  ${CollectionFragment}
`
export const ADMIN_GET_VARIANT = gql`
  query variant($id: ID!) {
    variant(id: $id) {
      ...variantFragment
    }
  }
  ${VariantFragment}
`
export const ADMIN_GET_PRODUCT_ATTRIBUTE = gql`
  query productAttribute($id: ID!) {
    productAttribute(id: $id) {
      ...productAttributeFragment
    }
  }
  ${ProductAttributeFragment}
`

export const ADMIN_SEARCH_PRODUCT_PACKAGES = gql`
  query adminSearchProductPackages($name: String!) {
    adminSearchProductPackages(name: $name) {
      id
      no
      accountingId
      title {
        id
        text {
          langCode
          content
        }
      }
      media {
        id
        sortOrder
        isThumbnail
        isBanner
        image
      }
    }
  }
`
export const ADMIN_SEARCH_ORDER = gql`
  query adminSearchOrders(
    $no: String
    $status: OrderStatus
    $countryCode: CountryCode
    $salesChannelIds: [ID]
  ) {
    adminSearchOrders(
      no: $no
      status: $status
      countryCode: $countryCode
      salesChannelIds: $salesChannelIds
    ) {
      id
      no
      shopId
      ownedByOrganisationId
      notifyEmailTo
      cartId
      name
      submittedAt
      shippedAt
      dueAt
      total {
        currency
        value
        includesTax
        taxPercent
      }
      shippingAddress {
        id
        firstName
        lastName
        countryCode
      }
      billingAddress {
        id
        firstName
        lastName
        countryCode
      }
      status
      paymentStatus
      createdAt
      updateAt
      salesChannelTitles
    }
  }
`
export const ADMIN_SEARCH_PRODUCT = gql`
  query adminSearchProducts($name: String!) {
    adminSearchProducts(name: $name) {
      id
      shopId
      name
      active
      categoryName
      slug
      no
      accountingId
      dummyStyles
      isCustomProduct
      hasPersonalization
      inventory
      sku
      title {
        id
        text {
          langCode
          content
        }
      }
      media {
        id
        productId
        variantId
        variantOptionId
        optionValue
        sortOrder
        isThumbnail
        isBanner
        image
      }
    }
  }
`

export const ADMIN_GET_PRODUCT_PACKAGE = gql`
  query productPackage($id: ID!) {
    productPackage(id: $id) {
      id
      accountingId
      productIds
      products {
        id
        no
        media {
          id
          productId
          variantId
          variantOptionId
          optionValue
          sortOrder
          isThumbnail
          isBanner
          image
        }
        title {
          id
          text {
            langCode
            content
          }
        }
        assignedProductAttributes {
          attribute {
            id
            title {
              id
              text {
                langCode
                content
              }
            }
            description {
              id
              text {
                langCode
                content
              }
            }
            no
            sortOrder
            isFeatured
            isFeaturedAttributeTitle
            image
            icon
          }
        }
        variants {
          id
          options
          variant {
            id
            title {
              id
              text {
                langCode
                content
              }
            }
            options {
              id
              value
              sortOrder
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
          }
        }
        optionValues {
          id
          isEnabled
          sku
          inventory
          barcode
          sortOrder
          optionValues
          variantIds
          optionValueIds
          createdAt
          updatedAt
          modifiers {
            id
            type
            stringModifier
            priceChange {
              currency
              value
            }
          }
          media {
            id
            sortOrder
            isThumbnail
            isBanner
            image
          }
        }
      }
      price {
        roundUp
        currency
        value
        includesTax
        taxPercent
      }
      accountingId
      slugs {
        content
        langCode
      }
      status
      media {
        id
        productId
        variantId
        variantOptionId
        optionValue
        sortOrder
        isThumbnail
        isBanner
        image
      }
      title {
        id
        text {
          langCode
          content
        }
      }
      description {
        id
        text {
          langCode
          content
        }
      }
      salesChannels {
        productPackageId
        salesChannelId
        salesChannel {
          id
          name
          title {
            id
            text {
              langCode
              content
            }
          }
        }
      }
      collections {
        collection {
          id
          no
          slugs {
            langCode
            content
          }
          title {
            id
            text {
              langCode
              content
            }
          }
        }
      }
      discounts {
        discount {
          id
          canBeCombinedWithOtherDiscounts
          availableFrom
          availableUntil
          enabled
          hasCoupon
          discountType
          discountValue
          discountCurrency
          discountRules {
            id
            operator
            discountRuleObjectType
            discountRuleCondition
          }
        }
      }
    }
  }
`
export const ADMIN_GET_PRODUCT = gql`
  query product($id: ID!) {
    product(id: $id) {
      ...productDetailsFragment
    }
  }
  ${ProductDetailsFragment}
`

export const ADMIN_GET_PRODUCT_ELEMENT = gql`
  query productElement($productId: ID!, $revision_code_id: String!) {
    productElement(productId: $productId, revision_code_id: $revision_code_id) {
      id
      createdAt
      productId
      elementRevision
      code
      fullCode
      defaultName
      name
      width
      height
      sqm
      defaultFabricId
      fabricGroupId
      fabricCompositionIds
    }
  }
`

export const ADMIN_GET_COLOR = gql`
  query color($id: ID!) {
    color(id: $id) {
      id
      internalCode
      code
      hex
      internalName
      name
      pantone
      CMYK {
        C
        M
        Y
        K
      }
    }
  }
`

export const ADMIN_GET_PRINTINGTECHNIQUE = gql`
  query printingTechnique($id: ID!) {
    printingTechnique(id: $id) {
      ...printingTechniqueDetail
    }
  }
  ${TextFragment}
  ${PricingDetailFragment}
  ${PrintingDetailFragment}
`

export const ADMIN_GET_FABRIC = gql`
  query fabricComposition($id: ID!) {
    fabricComposition(id: $id) {
      id
      code
      printable
      printableColors
      name
      summary
      fabricCost {
        id
        minOrderQuantity
        calculationType
        formula {
          priority
          price {
            roundUp
            currency
            value
            includesTax
            taxPercent
          }
          qty
          sqm
        }
      }
      swatchPreviewUrl
      defaultColorId
      colorIds
      colors {
        id
        name
        colorRGB {
          r
          g
          b
        }
        hex
      }
      fabricTypes {
        id
        name
        fabricConstructions
        fabricContentIds
        fabricContents {
          id
          percent
          textileId
          textile {
            id
            code
            name
            textileMaterial
          }
        }
      }
      weights {
        weight
        weightUnit
      }
    }
  }
`

export const AdminBoardItemDetailFragment = BoardItemDetailFragment

export const ADMIN_GET_ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      no
      shopId
      submittedAt
      createdAt
      shippedAt
      status
      ownedByOrganisationId
      paymentProvider
      paymentIntentId
      paymentStatus
      paymentProviderStatus
      paymentToken
      langCode
      total {
        currency
        value
        includesTax
        taxPercent
      }
      shippingCost {
        value
        currency
      }
      shippingProviderName
      shippingAddress {
        firstName
        lastName
        company
        vatNumber
        phone
        email
        address1
        address2
        city
        zip
        countryCode
        country
      }
      shippingMethodId
      shippingLabels {
        id
        shippingMethodId
        shippingProviderName
        status
        files {
          id
          type
          url
        }
        trackingNumbers
      }
      billingAddress {
        firstName
        lastName
        company
        vatNumber
        phone
        email
        address1
        address2
        city
        zip
        countryCode
        country
      }
      appliedCoupons {
        discountDeduction {
          currency
          value
          includesTax
          taxPercent
        }
        linkedCoupon {
          id
          couponCode
        }
      }
      appliedDiscounts {
        coupon {
          id
          couponCode
        }
        discountDeduction {
          currency
          value
          includesTax
          taxPercent
        }
        linkedDiscount {
          id
          accountingId
          title {
            id
            text {
              langCode
              content
            }
          }
          priority
          enabled
          hasCoupon
          enablesFreeShipping
          canBeCombinedWithOtherDiscounts
          discountType
          productsGetForFree
          freeProducts {
            id
            media {
              id
              sortOrder
              isThumbnail
              isBanner
              image
            }
            title {
              id
              text {
                content
                langCode
              }
            }
          }
          discountValue
          discountCurrency
          availableFrom
          availableUntil
          discountRules {
            id
            discountId
            operator
            discountRuleValue
            discountRuleCurrency
            discountRuleObjects {
              id
              type
              objectId
            }
            discountRuleObjectType
            discountRuleCondition
          }
          internalNote
        }
      }
      orderItems {
        id
        ownedByOrganisationId
        shopId
        quantity
        orderType
        productDesignId
        salesChannelId
        salesChannel {
          id
          customizationSettings {
            customizationOptionGroups {
              id
              logic
              title {
                id
                text {
                  langCode
                  content
                }
              }
              options {
                id
                type
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                pricingRules {
                  id
                  linkedType
                  minOrderQuantity
                  calculationType
                  formula {
                    priority
                    price {
                      roundUp
                      currency
                      value
                    }
                    qty
                    sqm
                  }
                }
              }
            }
          }
        }
        salesChannelTitle
        salesChannelGroupId
        salesChannelGroupTitle
        salesChannelTargetCategoryId
        salesChannelTargetCategoryTitle
        productOptionAccountingId
        productAccountingId
        productTitle
        productAttributeTitle
        productOptionValueTitle
        productOptionValueId
        productOptionValue {
          id
          sku
          selectedVariants {
            id
            selectedOption {
              id
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
            title {
              id
              text {
                langCode
                content
              }
            }
          }
        }
        product {
          id
          no
          media {
            image
            isThumbnail
            variantOptionId
          }
          title {
            id
            text {
              langCode
              content
            }
          }
          customizationSettings {
            customizationOptionGroups {
              id
              logic
              title {
                id
                text {
                  langCode
                  content
                }
              }
              options {
                id
                type
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                pricingRules {
                  id
                  linkedType
                  minOrderQuantity
                  calculationType
                  formula {
                    priority
                    price {
                      roundUp
                      currency
                      value
                    }
                    qty
                    sqm
                  }
                }
              }
            }
          }
        }
        productPackageId
        productPackageProductOptionValues {
          id
          productId
          productTitle
          productOptionValueId
          productOptionValueTitle
          productOptionValues
        }
        appliedCoupons {
          discountDeduction {
            currency
            value
            includesTax
            taxPercent
          }
          linkedCoupon {
            id
            couponCode
          }
        }
        appliedDiscounts {
          coupon {
            id
            couponCode
          }
          discountDeduction {
            currency
            value
            includesTax
            taxPercent
          }
          linkedDiscount {
            id
            accountingId
            title {
              id
              text {
                langCode
                content
              }
            }
            priority
            enabled
            hasCoupon
            enablesFreeShipping
            canBeCombinedWithOtherDiscounts
            discountType
            productsGetForFree
            freeProducts {
              id
              media {
                id
                sortOrder
                isThumbnail
                isBanner
                image
              }
              title {
                id
                text {
                  content
                  langCode
                }
              }
            }
            discountValue
            discountCurrency
            availableFrom
            availableUntil
            discountRules {
              id
              discountId
              operator
              discountRuleValue
              discountRuleCurrency
              discountRuleObjects {
                id
                type
                objectId
              }
              discountRuleObjectType
              discountRuleCondition
            }
            internalNote
          }
        }
        customizationPrice {
          roundUp
          currency
          value
        }
        customizationOptions {
          id
          customizationGroupId
          options {
            id
            productCustomizationOptionId
            value
          }
        }
      }
      history {
        id
        createdAt
        createdBy
        log
        updateEventType
        orderStatus
        errorMessage
      }
    }
  }
`
export const ADMIN_SHOP_ORDER_ITEMS = gql`
  query adminShopOrderItems($id: ID!, $first: Int, $after: String) {
    shop(id: $id) {
      id
      orderItems(first: $first, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            orderId
            createdAt
            productTitle
            productAttributeTitle
            salesChannelTitle
            productDesignId
            quantity
            total {
              currency
              value
            }
          }
        }
      }
    }
  }
`
export const ADMIN_SHOP_ORDERS = gql`
  query adminShopOrders($id: ID!, $first: Int, $after: String) {
    shop(id: $id) {
      id
      orders(first: $first, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            no
            shopId
            ownedByOrganisationId
            notifyEmailTo
            cartId
            name
            submittedAt
            shippedAt
            dueAt
            total {
              currency
              value
              includesTax
              taxPercent
            }
            billingAddress {
              id
              countryCode
              email
              firstName
              lastName
            }
            shippingAddress {
              id
              countryCode
              email
              firstName
              lastName
            }
            status
            paymentStatus
            createdAt
            updateAt
            salesChannelTitles
          }
        }
      }
    }
  }
`

export const ADMIN_SHOP_CUSTOMERS = gql`
  query adminShopCustomers($id: ID!) {
    shop(id: $id) {
      id
      customers {
        edges {
          node {
            id
            createdAt
            numberOfOrders
            lastOrderEmail
          }
        }
      }
    }
  }
`
export const ADMIN_SHOP_USERS = gql`
  query adminShopUsers($id: ID!) {
    shop(id: $id) {
      id
      users {
        edges {
          node {
            id
            createdAt
          }
        }
      }
    }
  }
`
// @Deprecated
// export const ADMIN_GET_USER = gql`
//   query shopUser($id: ID!) {
//     shopUser(id: $id) {
//       id
//       createdAt
//       email
//     }
//   }
// `;
export const ADMIN_GET_SHOP_SETTINGS = gql`
  query adminGetShopSettings($id: ID!) {
    shop(id: $id) {
      id
      ...shopSettingsFragment
    }
  }
  ${ShopSettingFragment}
`

export const ADMIN_GET_TEXT_DESIGN_STEP = gql`
  query textDesignStep($id: ID!) {
    textDesignStep(id: $id) {
      ...textDesignStepFragment
      id
    }
  }
  ${TextDesignStepFragment}
`
export const ADMIN_GET_FABRIC_DESIGN_STEP = gql`
  query fabricDesignStep($id: ID!) {
    fabricDesignStep(id: $id) {
      ...fabricDesignStepFragment
    }
  }
  ${FabricDesignStepFragment}
`
export const ADMIN_GET_COLOR_DESIGN_STEP = gql`
  query colorDesignStep($id: ID!) {
    colorDesignStep(id: $id) {
      ...colorDesignStepFragment
    }
  }
  ${ColorDesignStepFragment}
`

export const ADMIN_GET_DISCOUNT_RULE = gql`
  query discountRule($discountId: ID!, $id: ID!) {
    discountRule(discountId: $discountId, id: $id) {
      ...discountRuleFragment
    }
  }
  ${DiscountRuleFragment}
`
export const ADMIN_GET_DISCOUNT = gql`
  query discount($id: ID!, $first: Int) {
    discount(id: $id) {
      ...discountFragment
    }
  }
  ${DiscountFragment}
`

export const ADMIN_GET_COUPON = gql`
  query coupon($id: ID!, $first: Int) {
    coupon(id: $id) {
      ...couponFragment
    }
  }
  ${CouponFragment}
`

export const ADMIN_GET_LOGO_DESIGN_STEP = gql`
  query logoDesignStep($id: ID!) {
    logoDesignStep(id: $id) {
      ...logoDesignStepFragment
    }
  }
  ${LogoDesignStepFragment}
`

export const ADMIN_GET_CONTENT_THEME = gql`
  query adminGetContentTheme($id: ID!) {
    shop(id: $id) {
      id
      contentSettings {
        id
        themeId
        indexPageId
        indexPage {
          id
          title {
            id
            text {
              langCode
              content
            }
          }
        }
        cookieNote {
          id
          text {
            langCode
            content
          }
        }
        themeId
        theme {
          ...themeFragment
        }
      }
    }
  }
  ${ThemeFragment}
`

export const ADMIN_GET_INDEX_PAGE = gql`
  query adminGetIndexPage($id: ID!) {
    shop(id: $id) {
      id
      contentSettings {
        id
        indexPageId
        indexPage {
          id
          title {
            id
            text {
              langCode
              content
            }
          }
          description {
            id
            text {
              langCode
              content
            }
          }
          slugs {
            langCode
            content
          }
        }
      }
    }
  }
`
export const ADMIN_GET_TEAMSTORE_MENU = gql`
  query adminGetTeamstoreContentMenu($id: ID!) {
    teamstore(id: $id) {
      id
      logo
      menus {
        ...menuFragment
      }
    }
  }
  ${MenuFragment}
`
export const ADMIN_GET_CONTENT_MENU = gql`
  query adminGetContentMenu($id: ID!) {
    shop(id: $id) {
      id
      currencies
      defaultCurrency
      languages
      defaultLanguage
      brandSettings {
        logo
        mainColor
        secondaryColor
      }
      contentSettings {
        id
        indexPageId
        homePageId
        cookieNote {
          id
          text {
            langCode
            content
          }
        }
        menus {
          ...menuFragment
        }
      }
      customerContactEmail
      shippingSettings {
        shippingFrom {
          company
          phone
          address1
          address2
          city
          zip
          countryCode
          country
        }
      }
      businessAddress {
        address1
        city
        company
        countryCode
        country
        firstName
        lastName
        email
        phone
        province
        zip
      }
      socialLinks {
        externalLink
        icon
      }
      paymentOptions {
        name
        icon
      }
      modules {
        edges {
          node {
            id
            label
            contentDetail {
              id
              type
              ... on ContentDetailSocialLinks {
                socialLinks {
                  externalLink
                  icon
                }
              }
              ... on ContentDetailPaymentOptions {
                paymentOptions {
                  name
                  icon
                }
              }
              ... on ContentDetailShopLogo {
                color
                isAlternative
                isLink
                width
                height
                maxWidth
                maxHeight
              }
            }
          }
        }
      }
    }
  }
  ${MenuFragment}
`

export const GET_PAGES = gql`
  query getPages($id: ID!) {
    shop(id: $id) {
      id
      pages(shopId: $id) {
        edges {
          node {
            id
            name
            slugs {
              langCode
              content
            }
            status
            tags {
              id
              tagId
              pageId
              tag {
                id
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                slugs {
                  langCode
                  content
                }
              }
            }
          }
        }
      }
    }
  }
`

// @Deprecated
// export const GET_MODULE = gql`
//   query getModule($id: ID!, $pageId: ID!) {
//     module(id: $id, pageId: $pageId) {
//       id
//       pageId
//       column
//       gridType
//       alignmentType
//       sortOrder
//       type
//       alignment
//     }
//   }
// `;

export const GET_ROW = gql`
  query row($id: ID!, $pageId: ID!) {
    row(id: $id, pageId: $pageId) {
      id
      # columns @Deprecated: Need subfields
    }
  }
`

export const GET_CONTENT_MENU_LOCATION = gql`
  query getContentMenuLocation($id: ID!) {
    contentMenuLocation(id: $id) {
      ...menuFragment
    }
  }
  ${MenuFragment}
`
export const GET_PAGE_CONTENT = gql`
  query getPageContent($id: ID!) {
    page(id: $id) {
      ...contentPageFragment
      theme {
        pageBranding {
          primaryColor
          secondaryColor
          primaryColorType
          secondaryColorType
        }
        layoutStyles {
          fontFamily
        }
      }
      rows {
        id
        sortOrder
        verticalAlign
        columns {
          ...contentColumnFragment
          columnRows {
            id
            columnId
            alignmentType
            alignment
            sortOrder
            # columns @Deprecated: Need subfields
            verticalAlign
            columnRowDetail {
              id
              type
              ... on ContentDetailDiscoverCard {
                ...contentDetailDiscoverCardFragment
              }
              ... on ContentDetailText {
                ...contentDetailTextFragment
              }
              ... on ContentDetailBanner {
                ...contentDetailBannerFragment
              }
              ... on ContentDetailImage {
                ...contentDetailImageFragment
              }
              ... on ContentDetailCard {
                ...contentDetailCardFragment
              }
              ... on ContentDetailHubspotForm {
                ...contentDetailHubspotFormFragment
              }
              ... on ContentDetailSubscribeCard {
                ...contentDetailSubscribeCardFragment
              }
              ... on ContentDetailContactCard {
                ...contentDetailContactCardFragment
              }
              ... on ContentDetailLocationCard {
                ...contentDetailLocationCardFragment
              }
              ... on ContentDetailMedia {
                url
                width
                height
              }
              ... on ContentDetailSocialMedia {
                username
                socialMediaType
              }
              ... on ContentDetailButton {
                ...contentDetailButtonFragment
              }
              ... on ContentDetailInputField {
                ...contentDetailInputFieldFragment
              }
              ... on ContentDetailDivider {
                ...contentDetailDividerFragment
              }
              ... on ContentDetailCollectionCard {
                ...contentDetailCollectionCardFragment
              }
              ... on ContentDetailProductCard {
                ...contentDetailProductCardFragment
              }
              ... on ContentDetailTeamstoreCard {
                ...contentDetailTeamstoreCardFragment
              }
            }
          }
        }
      }
    }
  }
  ${ContentPageFragment}
  ${ContentDetailCollectionCardFragment}
  ${ContentDetailProductCardFragment}
  ${ContentDetailTeamstoreCardFragment}
  ${ContentDetailBannerFragment}
  ${ContentColumnFragment}
  ${ContentDetailTextFragment}
  ${ContentDetailImageFragment}
  ${ContentDetailCardFragment}
  ${ContentDetailHubspotFormFragment}
  ${ContentDetailSubscribeCardFragment}
  ${ContentDetailContactCardFragment}
  ${ContentDetailLocationCardFragment}
  ${ContentDetailButtonFragment}
  ${ContentDetailInputFieldFragment}
  ${ContentDetailDividerFragment}
  ${ContentDetailDiscoverCardFragment}
`
