import React from 'react'
import {
  Tooltip,
  Icon,
  Zoom,
  Typography,
  Grid,
  Button
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
  helperText: any
}

const useStyles = makeStyles({
  tooltip: {
    background: 'transparent',
    position: 'relative',
    left: -200,
    top: 50
  }
})

const CustomTooltip = React.forwardRef(function CustomTooltip<Props>(
  props: any,
  ref: any
) {
  const { helperText } = props
  return (
    <div
      style={{
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 600,
        width: 500,
        border: '1px solid #b23566',
        borderRadius: 2,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12)'
      }}
      {...props}
      ref={ref}
    >
      <div style={{ padding: 10 }}>
        {helperText && helperText.type === 'Detailed' ? (
          helperText.fields.map((item: any, index: number) => {
            return (
              <Grid
                style={{ padding: 5 }}
                direction="column"
                key={index}
                container={true}
              >
                <Grid direction="row" container={true}>
                  <Typography
                    style={{
                      color: '#b23566',
                      fontWeight: 'bold',
                      padding: 5
                    }}
                    variant="h5"
                  >
                    {item.label}
                  </Typography>{' '}
                </Grid>

                <Typography style={{ padding: 5 }} variant="body1">
                  {item.description}
                </Typography>
              </Grid>
            )
          })
        ) : helperText.type === 'Button' ? (
          <Grid style={{ padding: 10 }} container={true}>
            {helperText.fields.map((item: any, index: number) => {
              return (
                <Grid style={{ padding: 10 }} item={true} key={index} xs={4}>
                  <Button
                    style={
                      item.style === 'rounded'
                        ? {
                            borderRadius: 24
                          }
                        : {}
                    }
                    color="primary"
                    variant={item.variant}
                  >
                    {item.label}
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        ) : null}
      </div>
    </div>
  )
})

const HelperTooltip: React.FC<Props> = props => {
  const { helperText } = props
  const classes = useStyles()
  if (!helperText) return null
  return (
    <div style={{ position: 'relative', bottom: 5, left: 5 }}>
      {typeof helperText === 'object' ? (
        <Tooltip
          classes={classes}
          title={
            <>
              <CustomTooltip helperText={helperText} />
            </>
          }
        >
          <Icon color="primary" aria-label={`Tooltip-custom`}>
            <HelpIcon fontSize="small" />
          </Icon>
        </Tooltip>
      ) : (
        <Tooltip
          placement="right"
          TransitionComponent={Zoom}
          title={helperText}
        >
          <Icon color="primary" aria-label={`Tooltip-${helperText}`}>
            <HelpIcon fontSize="small" />
          </Icon>
        </Tooltip>
      )}
    </div>
  )
}

export default HelperTooltip
