import React from 'react'
import CollectionPageContainer from './Collection/CollectionPageContainer'
import TeamstorePageContainer from './Teamstore/TeamstorePageContainer'
import {
  ModuleType,
  CollectionModuleType,
  TeamstoreModuleType,
  DefaultPageType,
  CartModuleType,
  CheckoutModuleType
} from './Types'
import CartPageContainer from './Cart/CartPageContainer'
import CheckoutPageContainer from './Checkout/CheckoutPageContainer'

interface Props {
  moduleType: ModuleType
  defaultPageType: DefaultPageType
}

const RenderModules: React.FC<Props> = props => {
  const { moduleType, defaultPageType } = props

  switch (defaultPageType) {
    case 'COLLECTION':
      return (
        <CollectionPageContainer
          moduleType={moduleType as CollectionModuleType}
        />
      )
    case 'TEAMSTORE':
      return (
        <TeamstorePageContainer
          moduleType={moduleType as TeamstoreModuleType}
        />
      )
    case 'CART':
      return <CartPageContainer moduleType={moduleType as CartModuleType} />
    case 'CHECKOUT':
      return (
        <CheckoutPageContainer moduleType={moduleType as CheckoutModuleType} />
      )
    default:
      return null
  }
}

export default RenderModules
