import React, { Component } from 'react'
import * as tools from './tools/tools'
import * as bg_control from './tools/bg_control'
import * as handle_script from './tools/handle_script'
import { Button, Grid, Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import { ThreeDRotation, ArrowLeft } from '@material-ui/icons'
import * as query from './mutation_query/query'
import Loading from './tools/Loading'
import DesignTemplate from './DesignTemplate'
import { navigate } from '@reach/router'

class MainTemplate extends Component {
  // FOR LOAD DATA OR QUERY BEFORE EXE ON DESIGN TEMPLATE

  constructor(props) {
    super(props)
    this.state = {
      ready: false,
      scriptReady: false,
      loading: true,
      loadData: null,
      loadingStatus: 'LOADING'
    }
    this.SHOP_ID = tools.getQueryStringValue('s')
    this.bgUse = bg_control.getBackground(this.SHOP_ID)

    this.productID = props.proID
    this.productDesignId = props.productDesignId
    this.ownedByOrganisationId = props.ownedByOrganisationId
    this.shop = props.shop

    if (props.templateID !== null) {
      this.templateID = props.templateID
    } else {
      this.templateID = null
    }

    if (props.loadID !== null) {
      this.loadID = props.loadID
      this.onLoad = true
      this._SAVE_ID = this.loadID
    } else {
      this.state.ready = true
      this.onLoad = false
      this._SAVE_ID = null
    }

    this.localData = tools.getQueryStringValue('localData')
    this.uiOnly = tools.getQueryStringValue('uiOnly')
    this.SHOP_ID = this.SHOP_ID.toLowerCase()

    logger.log('MainTemplate SHOP_ID', this.SHOP_ID)

    if (this.localData === 'true') {
      this.localData = true
    } else {
      this.localData = false
    }

    if (this.uiOnly === 'true') {
      this.uiOnly = true
    } else {
      this.uiOnly = false
    }

    logger.log('localData', this.localData, 'uiOnly', this.uiOnly)

    // var self = this;
    // this.bgInterval = setInterval(function(){ self.bgUse = self.bgSET[Math.floor(Math.random() * 13)]; self.setState({update: true}); }, 15000);
  }

  componentDidMount() {
    handle_script.scriptLoad(this)
    handle_script.scriptLoadByURL('/lib/canvastotiff.min.js')

    let isMobile = false
    try {
      if (typeof navigator !== `undefined`) {
        isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      }
    } catch (error) {}

    if (isMobile) {
      alert('Sorry, this feature cannot be used on mobile.')
    }
  }

  render() {
    const { loadData, ready, scriptReady, loading } = this.state

    var bgStyle = {
      backgroundImage: "url('" + this.bgUse + "') ",
      backgroundColor: '#FFFFFF',
      backgroundSize: this.props.shopId === 'vermarc' ? 'cover' : '200px'
    }

    var loadingBG = null
    if (loading === true) {
      loadingBG = (
        <div className="loadingMainTemplate" style={bgStyle}>
          <Loading DesignRoot={this} position="bottom-right" />
        </div>
      )
    }

    if (ready === true && scriptReady === true) {
      return (
        <div className="MainTemplate" style={{ position: 'relative' }}>
          {
            // loadingBG
          }

          {this.localData === true ? (
            <div>
              <DesignTemplate
                shopId={this.props.shopId}
                urlPrefix={this.props.urlPrefix}
                productID={this.productID}
                onLoad={this.onLoad}
                loadData={loadData}
                MainTemplate={this}
                SAVE_ID={this._SAVE_ID}
                templateID={this.templateID}
                localData={this.localData}
                uiOnly={this.uiOnly}
                SHOP_ID={this.SHOP_ID}
                shop={this.shop}
                user={this.props.user}
              />
            </div>
          ) : (
            <div>{query.main_template(this, loadingBG)}</div>
          )}
          {process.env.GATSBY_BRIKL_IS_DASHBOARD === 'yes' ? null : (
            <div style={{ position: 'absolute', bottom: 24, left: 24 }}>
              <ThreeDRotation style={{ fontSize: 40 }} />
            </div>
          )}
          {process.env.GATSBY_BRIKL_IS_DASHBOARD === 'yes' ? null : (
            <Button
              component={Link}
              to={'/'}
              style={{ position: 'absolute', top: 24, left: 24 }}
            >
              <ArrowLeft style={{ marginRight: 8 }} />
              Back to shop
            </Button>
          )}
        </div>
      )
    } // END IF ready
    else {
      return (
        <Grid
          container
          style={{
            height: '100vh',
            width: '100vw'
          }}
          alignItems="center"
        >
          {
            // loadingBG
          }
          <Typography>Design Studio is loading...</Typography>
        </Grid>
      )
    }
  }
}

export default MainTemplate
