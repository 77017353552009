import gql from 'graphql-tag'
import { CART_FIELDS_FRAGMENT } from './fragments'

export const UPDATE_CART_MUTATION = gql`
  mutation updateCart(
    $id: ID!
    $ownedByOrganisationId: ID!
    $input: CartUpdateInput!
    $currency: Currency
  ) {
    updateCart(
      id: $id
      ownedByOrganisationId: $ownedByOrganisationId
      input: $input
      currency: $currency
    ) {
      ...CartFields
    }
  }
  ${CART_FIELDS_FRAGMENT}
`

export const CREATE_LEAD_MUTATION = gql`
  mutation createLead($input: LeadCreateInput!) {
    createLead(input: $input) {
      id
    }
  }
`

export const UPLOAD_FILE_MUTATION = gql`
  mutation uploadFile(
    $type: FileDestination!
    $fileName: String!
    $prefixVariables: [String]
    $input: FilePrefixInput!
  ) {
    uploadFile(
      type: $type
      fileName: $fileName
      prefixVariables: $prefixVariables
      input: $input
    ) {
      error
      acl
      tagging
      url
      contentType
      cacheControl
    }
  }
`

export const UPDATE_PRODUCT_DESIGN_REVISION_MUTATION = gql`
  mutation updateProductDesignRevision(
    $productDesignId: ID!
    $revisionNo: Int!
    $input: ProductDesignRevisionInput
  ) {
    updateProductDesignRevision(
      productDesignId: $productDesignId
      revisionNo: $revisionNo
      input: $input
    ) {
      id
      revisionNo
      revisionName
      backgroundColorHex
      selectedDesignTemplateId
      zoneColors {
        colorHex
        colorId
        zoneId
        color {
          id
          internalCode
          code
          hex
          internalName
          name
        }
      }
    }
  }
`

export const CREATE_TEXT_DESIGN_REVISION_MUTATION = gql`
  mutation createTextDesignRevision(
    $productDesignId: ID!
    $revisionNo: Int!
    $input: ProductDesignTextInput
  ) {
    createTextDesignRevision(
      productDesignId: $productDesignId
      revisionNo: $revisionNo
      input: $input
    ) {
      id
      isVisible
      textDesign {
        content
        fontFamily
        colorHex
        colorId
        width
        height
        lineSpacing
        outlineSize
        outlineHex
        orderSeq
        defaultItem
        boundingId
        boundingGroup
        designAttributes {
          top
          left
          width
          height
          scaleX
          scaleY
          angle
          flipX
          flipY
          lock
        }
      }
    }
  }
`

export const UPDATE_TEXT_DESIGN_REVISION_MUTATION = gql`
  mutation updateTextDesignRevision(
    $id: ID!
    $productDesignId: ID!
    $revisionNo: Int!
    $input: ProductDesignTextInput
  ) {
    updateTextDesignRevision(
      id: $id
      productDesignId: $productDesignId
      revisionNo: $revisionNo
      input: $input
    ) {
      id
      isVisible
      textDesign {
        content
        fontFamily
        colorHex
        colorId
        width
        height
        lineSpacing
        outlineSize
        outlineHex
        orderSeq
        defaultItem
        boundingId
        boundingGroup
        designAttributes {
          top
          left
          width
          height
          scaleX
          scaleY
          angle
          flipX
          flipY
          lock
        }
      }
    }
  }
`

export const DELETE_TEXT_DESIGN_REVISION_MUTATION = gql`
  mutation deleteTextDesignRevision(
    $productDesignId: ID!
    $revisionNo: Int!
    $id: ID!
  ) {
    deleteTextDesignRevision(
      productDesignId: $productDesignId
      revisionNo: $revisionNo
      id: $id
    )
  }
`

export const CREATE_LOGO_DESIGN_REVISION_MUTATION = gql`
  mutation createLogoDesignRevision(
    $productDesignId: ID!
    $ownedByOrganisationId: ID!
    $revisionNo: Int!
    $input: ProductDesignLogoInput
  ) {
    createLogoDesignRevision(
      productDesignId: $productDesignId
      ownedByOrganisationId: $ownedByOrganisationId
      revisionNo: $revisionNo
      input: $input
    ) {
      id
      isVisible
      logoDesign {
        name
        src
        width
        height
        orderSeq
        defaultItem
        boundingId
        boundingGroup
        designAttributes {
          top
          left
          width
          height
          scaleX
          scaleY
          angle
          flipX
          flipY
          lock
        }
      }
    }
  }
`

export const UPDATE_LOGO_DESIGN_REVISION_MUTATION = gql`
  mutation updateLogoDesignRevision(
    $id: ID!
    $productDesignId: ID!
    $ownedByOrganisationId: ID!
    $revisionNo: Int!
    $input: ProductDesignLogoInput
  ) {
    updateLogoDesignRevision(
      id: $id
      productDesignId: $productDesignId
      ownedByOrganisationId: $ownedByOrganisationId
      revisionNo: $revisionNo
      input: $input
    ) {
      id
      isVisible
      logoDesign {
        name
        src
        width
        height
        orderSeq
        defaultItem
        boundingId
        boundingGroup
        designAttributes {
          top
          left
          width
          height
          scaleX
          scaleY
          angle
          flipX
          flipY
          lock
        }
      }
    }
  }
`

export const DELETE_LOGO_DESIGN_REVISION_MUTATION = gql`
  mutation deleteLogoDesignRevision(
    $productDesignId: ID!
    $ownedByOrganisationId: ID!
    $revisionNo: Int!
    $id: ID!
  ) {
    deleteLogoDesignRevision(
      productDesignId: $productDesignId
      ownedByOrganisationId: $ownedByOrganisationId
      revisionNo: $revisionNo
      id: $id
    )
  }
`

export const CREATE_PRINT_DESIGN_REVISION_MUTATION = gql`
  mutation createPrintDesignRevision(
    $productDesignId: ID!
    $ownedByOrganisationId: ID!
    $revisionNo: Int!
    $input: ProductDesignPrintInput
  ) {
    createPrintDesignRevision(
      productDesignId: $productDesignId
      ownedByOrganisationId: $ownedByOrganisationId
      revisionNo: $revisionNo
      input: $input
    ) {
      id
      name
      src
    }
  }
`

export const UPDATE_PRINT_DESIGN_REVISION_MUTATION = gql`
  mutation updatePrintDesignRevision(
    $id: ID!
    $productDesignId: ID!
    $ownedByOrganisationId: ID!
    $revisionNo: Int!
    $input: ProductDesignPrintInput
  ) {
    updatePrintDesignRevision(
      id: $id
      productDesignId: $productDesignId
      ownedByOrganisationId: $ownedByOrganisationId
      revisionNo: $revisionNo
      input: $input
    ) {
      id
      name
      src
    }
  }
`

export const DELETE_PRINT_DESIGN_REVISION_MUTATION = gql`
  mutation deletePrintDesignRevision(
    $productDesignId: ID!
    $ownedByOrganisationId: ID!
    $revisionNo: Int!
    $id: ID!
  ) {
    deletePrintDesignRevision(
      productDesignId: $productDesignId
      ownedByOrganisationId: $ownedByOrganisationId
      revisionNo: $revisionNo
      id: $id
    )
  }
`

export const CREATE_ELEMENT_DESIGN_REVISION_MUTATION = gql`
  mutation createElementDesignRevision(
    $productDesignId: ID!
    $revisionNo: Int!
    $input: ProductDesignElementInput
  ) {
    createElementDesignRevision(
      productDesignId: $productDesignId
      revisionNo: $revisionNo
      input: $input
    ) {
      id
      colorHex
      colorId
      baseElement {
        name
      }
      productDesignPrintId
      productDesignPrint {
        id
        name
        src
      }
      countryFlag
      designAttributes {
        scaleX
        scaleY
        angle
        offsetX
        offsetY
        repeat
      }
    }
  }
`

export const UPDATE_ELEMENT_DESIGN_REVISION_MUTATION = gql`
  mutation updateElementDesignRevision(
    $id: ID!
    $productDesignId: ID!
    $revisionNo: Int!
    $input: ProductDesignElementInput
  ) {
    updateElementDesignRevision(
      id: $id
      productDesignId: $productDesignId
      revisionNo: $revisionNo
      input: $input
    ) {
      id
      colorHex
      colorId
      baseElement {
        name
      }
      productDesignPrintId
      productDesignPrint {
        id
        name
        src
      }
      countryFlag
      designAttributes {
        scaleX
        scaleY
        angle
        offsetX
        offsetY
        repeat
      }
    }
  }
`
