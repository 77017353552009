import React from 'react';
import {
  InputLabel,
  createStyles,
  withStyles,
  WithStyles,
  Typography,
  Grid
} from '@material-ui/core';
import Input from '../../../../common/Input';
import { getTextFromLanguage } from '../../../../../helper/languageHelper';

const styles = () => createStyles({});

interface Props extends WithStyles<typeof styles> {
  data: any;
  languages: [string];
  onChange(value: string | null, language: string, fieldName: string): void;
}

const AddMenuLinkFields: React.FunctionComponent<Props> = props => {
  const { data, languages, onChange } = props;

  return (
    <>
      {languages.map((language: string) => (
        <div key={language} style={{ margin: '8px 0px 32px' }}>
          <Typography variant="subtitle1">{language}</Typography> <br />
          <InputLabel>Link title</InputLabel>
          <Grid container={true} style={{ margin: '8px 0px 16px' }}>
            <Grid item={true} xs={12}>
              <Input
                label=""
                placeholder=""
                onChange={e => onChange(e.target.value, language, 'linkTitle')}
                value={
                  data.linkTitle
                    ? getTextFromLanguage(data.linkTitle.text, language)
                    : ''
                }
              />
            </Grid>
          </Grid>
          <InputLabel>External link</InputLabel>
          <Grid container={true} style={{ margin: '8px 0px 16px' }}>
            <Grid item={true} xs={12}>
              <Input
                label=""
                placeholder=""
                onChange={e =>
                  onChange(e.target.value, language, 'externalLinks')
                }
                value={
                  data.externalLinks
                    ? getTextFromLanguage(data.externalLinks, language)
                    : ''
                }
              />
            </Grid>
          </Grid>
        </div>
      ))}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(AddMenuLinkFields);
