import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Styling from './Styling';

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    flex: 1,
    height: 'calc(100vh - 66px)',
    marginTop: 66,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 50,
    fontSize: '15px',
    flex: 1,
    background: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      'Roboto',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol'
    ].join(','),
    '&:hover': {
      color: '#B23566',
      background: '#fff',
      opacity: 1
    },
    '&$tabSelected': {
      color: '#B23566',
      background: '#fff',
      fontWeight: theme.typography.fontWeightMedium
    },
    '&:focus': {
      color: '#B23566',
      background: '#fff'
    }
  },
  tabsIndicator: {
    display: 'none'
  },
  tabSelected: { color: '#40a9ff', background: '#fff' },
  typography: {
    padding:theme.spacing(3)
  },
  tabsRoot: {
    background: '#fff'
  },
  paper: {
    boxShadow: 'none'
  }
});

class TemplateTabs extends React.Component {
  state = {
    selectedPage: this.props.selectedPage
  };

  handleChange = (event, value) => {
    const { Template } = this.props;
    Template.setState({ currentTab: value });
  };

  handleChangeIndex = index => {
    const { Template } = this.props;
    Template.setState({ currentTab: index });
  };

  render() {
    const { classes, pageId, Template, shopId, languages } = this.props;
    return (
      <div className={classes.root}>
        <Tabs
          value={Template.state.currentTab || 0}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          classes={{
            root: classes.tabsRoot,

            indicator: classes.tabsIndicator
          }}
        >
          <Tab
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Styling"
          />
        </Tabs>

        <TabContainer>
          <Styling
            languages={languages}
            Template={Template}
            pageId={pageId}
            shopId={shopId}
          />
        </TabContainer>
      </div>
    );
  }
}

TemplateTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(TemplateTabs);
