import React, { useState, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  CircularProgress
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { ADMIN_UPDATE_CONTENT_MODEL_STATUS } from '../gql/mutations'

interface Props {
  shopId: string
  modelId: string
  defaultStatus: 'DRAFT' | 'PUBLISHED'
}

const options = ['DRAFT', 'PUBLISHED']

const ToggleStatus: React.FC<Props> = props => {
  const { defaultStatus, modelId } = props
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const [
    updateContentModelStatus,
    updateContentModelStatusResult
  ] = useMutation(ADMIN_UPDATE_CONTENT_MODEL_STATUS)

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: string
  ) => {
    updateContentModelStatus({
      variables: {
        id: modelId,
        input: {
          status: option
        }
      }
    })
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup
        disabled={updateContentModelStatusResult.loading}
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          variant="contained"
          style={{
            color: '#b23566',
            backgroundColor: 'white',
            border: '1px solid #b23566',
            boxShadow: 'none',
            cursor: 'default',
            fontFamily: 'Poppins'
          }}
        >
          {updateContentModelStatusResult.loading ? (
            <CircularProgress size={25} />
          ) : (
            defaultStatus
          )}
        </Button>
        <Button
          variant="contained"
          style={{
            color: '#b23566',
            backgroundColor: 'white',
            border: '1px solid #b23566',
            boxShadow: 'none',
            fontFamily: 'Poppins'
          }}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={event => handleMenuItemClick(event, option)}
                      style={{ fontFamily: 'Poppins' }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default ToggleStatus
