import gql from 'graphql-tag'
import {
  BOARD_ELEMENT_DESIGN_FIELDS_FRAGMENT,
  BOARD_PRODUCT_DESIGN_FIELDS_FRAGMENT,
  PRINTING_TECHNIQUE_FIELDS_FRAGMENT
} from './fragments'

export const PRODUCT_DESIGN_QUERY = gql`
  query getProductDesign($shopId: ID!, $id: ID!, $ownedByOrganisationId: ID!) {
    user {
      id
      email
      firstName
      lastName
      numberOfSessions
    }
    shopConfiguration(id: $shopId) {
      name
      capabilities {
        featureFlags
      }
      designStudioSettings {
        designSteps {
          stepSortOrder
          type
          ... on LabelStep {
            defaultCareLabelId
          }
          ... on FabricStep {
            showTooltip
          }
          ... on ColorStep {
            defaultPrintingTechnique
            colorPrintingTechniques {
              ...PrintingTechniqueFields
            }
            printLibrary {
              id
              name
              preview
              src
              imageUrl
              designAttributes {
                scaleX
                scaleY
                offsetX
                offsetY
              }
            }
          }
          ... on TextStep {
            fonts {
              id
              name
              weights
              source
            }
            defaultPrintingTechnique
            textPrintingTechniques {
              ...PrintingTechniqueFields
            }
          }
          ... on LogoStep {
            maxLogos
            defaultPrintingTechnique
            imageLibrary {
              id
              name
              imageUrl
            }
            logoPrintingTechniques {
              ...PrintingTechniqueFields
            }
          }
        }
      }
    }
    getProductDesign(id: $id, ownedByOrganisationId: $ownedByOrganisationId) {
      id
      modelRevision
      name
      ownedByOrganisationId
      salesChannelId
      productElements {
        id
        elementRevision
        code
        fullCode
        defaultName
        name
        fabricCompositions {
          id
          name
          colors {
            id
            name
            code
            hex
            CMYK {
              C
              M
              Y
              K
            }
          }
        }
        fabricGroupId
        fabricGroup {
          id
          name
        }
      }
      product {
        id
        title {
          id
          text {
            content
            langCode
          }
        }
        description {
          id
          text {
            content
            langCode
          }
        }
        lastElementRevision
        gltfSrc
        objSrc
        hasObjGzipFile
        mtlSrc
        seamSrc
        baseTemplateSrc
        defaultTemplateSrc
        blankTemplatePreviewSrc
        modelConfiguration {
          dimension {
            baseTemplate
            baseSize
            pxToCm
          }
        }
        placeholders {
          id
          svgId
          elementRevision
          defaultName
          angle
          isEnabled
          title {
            id
            text {
              langCode
              content
            }
          }
          maximumItems
          hasFreePositioning
          canChangeDefault
          allowedTypes
          assignedColorZoneIds
          elementId
          tempDefaultValue
        }
        designTemplates {
          id
          lastTemplateRevision
          templateFileSrc
          logobaseFileSrc
          previewImageSrc
          colorZoneFileSrc
          hasColorZoneFile
          colors {
            id
            svgId
            name
            coordinates {
              x
              y
            }
            colorId
            customColorHex
            color {
              id
              hex
              code
              name
            }
            templateColorRelations {
              id
              parentZoneId

              childZoneId
              childProduct {
                id
                shopId
                lastElementRevision
              }
            }
          }
          relatedTemplateColorZones {
            id
            parentZoneId
            childZoneId
            childProduct {
              id
              shopId
              lastElementRevision
              objSrc
              mtlSrc
            }
            childDesignTemplate {
              id
              templateFileSrc
              hasColorZoneFile
              colorZoneFileSrc
              colors {
                id
                svgId
                customColorHex
              }
            }
            parentDesignTemplateId
            parentDesignTemplate {
              id
              templateFileSrc
              hasColorZoneFile
              colorZoneFileSrc
              colors {
                id
                svgId
                customColorHex
              }
            }
          }
        }
      }
      latestRevisionNo
      latestRevision {
        id
        revisionNo
        revisionName
        backgroundColorHex
        selectedDesignTemplateId
        pxToCm
        createdAt
        updatedAt
        zoneColors {
          colorHex
          colorId
          color {
            id
            internalCode
            code
            hex
            internalName
            name
          }
          gradientType
          gradientDirection
          gradientColors {
            colorHex
            colorId
            color {
              id
              internalCode
              code
              hex
              internalName
              name
            }
            colorPercentPosition
          }
          zoneId
        }
        textDesigns {
          id
          isVisible
          textDesign {
            content
            fontFamily
            colorHex
            colorId
            width
            height
            lineSpacing
            outlineSize
            outlineHex
            orderSeq
            defaultItem
            boundingId
            boundingGroup
            designAttributes {
              top
              left
              width
              height
              scaleX
              scaleY
              angle
              flipX
              flipY
              lock
            }
          }
        }
        logoDesigns {
          id
          isVisible
          logoDesign {
            name
            src
            orderSeq
            defaultItem
            boundingId
            boundingGroup
            designAttributes {
              top
              left
              width
              height
              scaleX
              scaleY
              angle
              flipX
              flipY
              lock
            }
          }
        }
        designElements {
          id
          colorHex
          colorId
          baseElement {
            name
          }
          revisionNo
          productDesignId
          productDesignPrintId
          productDesignPrint {
            id
            name
            src
          }
          countryFlag
          designAttributes {
            scaleX
            scaleY
            angle
            offsetX
            offsetY
            repeat
          }
        }
        printDesigns {
          id
          name
          src
        }
      }
    }
  }
  ${PRINTING_TECHNIQUE_FIELDS_FRAGMENT}
`

export const CUSTOM_PRODUCTS_QUERY = gql`
  query searchCustomProducts(
    $name: String
    $collectionIds: [ID]
    $salesChannelIds: [ID]
  ) {
    searchCustomProducts(
      name: $name
      collectionIds: $collectionIds
      salesChannelIds: $salesChannelIds
    ) {
      id
      no
      slugs {
        langCode
        content
      }
      media {
        id
        productId
        variantId
        variantOptionId
        optionValue
        image
        isThumbnail
      }
      title {
        id
        text {
          langCode
          content
        }
      }
      collections {
        collection {
          id
          no
          slugs {
            langCode
            content
          }
          title {
            id
            text {
              langCode
              content
            }
          }
        }
      }
      assignedProductAttributes {
        attribute {
          id
          title {
            id
            text {
              langCode
              content
            }
          }
          description {
            id
            text {
              langCode
              content
            }
          }
          no
          sortOrder
          isFeatured
          isFeaturedAttributeTitle
          image
          icon
        }
      }
    }
  }
`

export const DESIGN_PATTERN_QUERY = gql`
  query getProductDesignPattern($id: ID!, $ownedByOrganisationId: ID!) {
    getProductDesign(id: $id, ownedByOrganisationId: $ownedByOrganisationId) {
      id
      patternSrc
    }
  }
`

export const GET_CUSTOMER_CART_DETAIL_QUERY = gql`
  query getCustomerCartDetail($id: ID!, $currency: Currency) {
    customerCart(id: $id, currency: $currency) {
      id
      cart {
        id
        couponCode
        lastAddToCartUrl
        paymentSourceId
        paymentMethod
        userReferences
        shopId
        seasonName
        collectionName
        shippingAddressId
        billingAddressId
        ownedByOrganisationId
        shopId
        seasonName
        collectionName
        shippingMethodId
        ownedByOrganisationId
        totalCalculated {
          appliedCoupons {
            discountDeduction {
              currency
              value
              includesTax
              taxPercent
            }
            linkedCoupon {
              id
              couponCode
            }
          }
          appliedDiscounts {
            coupon {
              id
              couponCode
            }
            discountDeduction {
              currency
              value
              includesTax
              taxPercent
            }
            linkedDiscount {
              id
              accountingId
              title {
                id
                text {
                  langCode
                  content
                }
              }
              priority
              enabled
              hasCoupon
              enablesFreeShipping
              canBeCombinedWithOtherDiscounts
              discountType
              productsGetForFree
              freeProducts {
                id
                media {
                  id
                  sortOrder
                  isThumbnail
                  isBanner
                  image
                }
                title {
                  id
                  text {
                    content
                    langCode
                  }
                }
              }
              discountValue
              discountCurrency
              availableFrom
              availableUntil
              discountRules {
                id
                discountId
                operator
                discountRuleValue
                discountRuleCurrency
                discountRuleObjects {
                  id
                  type
                  objectId
                }
                discountRuleObjectType
                discountRuleCondition
              }
              internalNote
            }
          }
          cartTotalPrice {
            roundUp
            currency
            value
            includesTax
            taxPercent
          }
          cartTotalinCurrency(currency: $currency) {
            currency
            value
            includesTax
            taxPercent
          }
          cartTotalString
          cartTotalGroups {
            finalTotal {
              currency
              value
              includesTax
              taxPercent
            }
            finalTotalinCurrency(currency: $currency) {
              currency
              value
              includesTax
              taxPercent
            }
            totalPieces
            discountRate
            discountNote
            shippingCost {
              shippingProviderName
              cost {
                currency
                value
                includesTax
                taxPercent
              }
            }
            cartItemTotals {
              id
              qty
              productId
              productOptionValueId
              productOptionValues
              boardProductId
              productDesignId
              baseProductId
              salesChannelId

              fixPrice {
                roundUp
                currency
                value
                includesTax
                taxPercent
              }
              priceNote
              itemTotalBeforeDiscount {
                currency
                value
                includesTax
                taxPercent
              }
              totalBeforeDiscount {
                currency
                value
                includesTax
                taxPercent
              }
              total {
                currency
                value
                includesTax
                taxPercent
              }
              appliedCoupons {
                discountDeduction {
                  currency
                  value
                  includesTax
                  taxPercent
                }
                linkedCoupon {
                  id
                  couponCode
                }
              }
              appliedDiscounts {
                coupon {
                  id
                  couponCode
                }
                discountDeduction {
                  currency
                  value
                  includesTax
                  taxPercent
                }
                discountQuantity
                linkedDiscount {
                  id
                  accountingId
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                  priority
                  enabled
                  hasCoupon
                  enablesFreeShipping
                  canBeCombinedWithOtherDiscounts
                  discountType
                  productsGetForFree
                  freeProducts {
                    id
                    media {
                      id
                      sortOrder
                      isThumbnail
                      isBanner
                      image
                    }
                    title {
                      id
                      text {
                        content
                        langCode
                      }
                    }
                  }
                  discountValue
                  discountCurrency
                  availableFrom
                  availableUntil
                  discountRules {
                    id
                    discountId
                    operator
                    discountRuleValue
                    discountRuleCurrency
                    discountRuleObjects {
                      id
                      type
                      objectId
                    }
                    discountRuleObjectType
                    discountRuleCondition
                  }
                  internalNote
                }
              }
            }
          }
        }
        cartItems {
          id
          productOptionValueId
          productOptionValues
          productOptionValue {
            id
            sku
            selectedVariants {
              id
              selectedOption {
                id
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
              }
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
          }
          productId
          quantity
          product {
            id
            isCustomProduct
            sellFrom
            hasPersonalization
            price {
              roundUp
              currency
              value
              includesTax
              taxPercent
            }
            title {
              id
              text {
                langCode
                content
              }
            }
            media {
              id
              productId
              variantId
              variantOptionId
              optionValue
              sortOrder
              isThumbnail
              isBanner
              image
            }
            variants {
              id
              options
              variant {
                id
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                options {
                  id
                  value
                  sortOrder
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                }
              }
            }
            optionValues {
              id
              isEnabled
              sku
              inventory
              barcode
              sortOrder
              optionValues
              variantIds
              optionValueIds
              createdAt
              updatedAt
              modifiers {
                id
                type
                stringModifier
                priceChange {
                  currency
                  value
                }
              }
              media {
                id
                sortOrder
                isThumbnail
                isBanner
                image
              }
            }
            assignedProductAttributes {
              id
              attribute {
                id
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                description {
                  id
                  text {
                    langCode
                    content
                  }
                }
                no
                image
                icon
                sortOrder
                isFeatured
                isFeaturedAttributeTitle
              }
            }
            customizationSettings {
              customizationOptionGroups {
                id
                logic
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
                options {
                  id
                  type
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                  pricingRules {
                    id
                    linkedType
                    minOrderQuantity
                    calculationType
                    formula {
                      priority
                      price {
                        roundUp
                        currency
                        value
                      }
                      qty
                      sqm
                    }
                  }
                }
              }
            }
          }
          shopId
          boardProductId
          productDesignId
          designStudioVersion
          salesChannelId
          salesChannel {
            id
            hasOutOfStock
            customizationSettings {
              customizationOptionGroups {
                id
                options {
                  id
                  type
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                  pricingRules {
                    id
                    linkedType
                    minOrderQuantity
                    calculationType
                    formula {
                      priority
                      price {
                        roundUp
                        currency
                        value
                      }
                      qty
                      sqm
                    }
                  }
                }
              }
            }
          }
          productPackageId
          productPackageProductOptionValues {
            id
            productId
            product {
              id
              title {
                id
                text {
                  langCode
                  content
                }
              }
            }
            productOptionValueId
            productOptionValues
            productOptionValue {
              id
              sku
              selectedVariants {
                id
                selectedOption {
                  id
                  title {
                    id
                    text {
                      langCode
                      content
                    }
                  }
                }
                title {
                  id
                  text {
                    langCode
                    content
                  }
                }
              }
            }
          }
          orderType
          salesChannelTargetCategoryId
          salesChannelGroupId
          customizationOptions {
            id
            customizationGroupId
            options {
              id
              productCustomizationOptionId
              value
            }
          }
          boardProduct {
            id
            name
            shopId
            comment
            baseProduct {
              id
              no
            }
          }
          sizeQuantities {
            quantity
            size
          }
          design {
            elementRevision
            ...BoardProductDesignFields
            elementDesignData {
              ...BoardElementDesignFields
              colorHex
            }
          }
        }
      }
    }
  }
  ${BOARD_PRODUCT_DESIGN_FIELDS_FRAGMENT}
  ${BOARD_ELEMENT_DESIGN_FIELDS_FRAGMENT}
`
