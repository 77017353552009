import React, { useState } from 'react'
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
  Button
} from '@material-ui/core'
import { Link } from '@reach/router'

import {
  getLinkText,
  getLinkUrl,
  getStyleAlignmentItem
} from '../../../helper/menuHelper'
import MenuItemSub from './MenuItemSub'

const styles = (themeStyle: Theme) =>
  createStyles({
    btn: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      [themeStyle.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    collectionDesktop: {
      '&:hover': {
        background: '#FFFFFF'
      },
      borderRadius: '0px',
      display: 'block',
      height: 50,
      [themeStyle.breakpoints.down('sm')]: {
        background: '#000',
        display: 'none'
      }
    },
    collectionMobile: {
      display: 'none',
      [themeStyle.breakpoints.down('sm')]: {
        color: '#000000',
        display: 'block',
        fontSize: '1.2rem',
        fontWeight: 300,
        padding: 12
      }
    },
    desktop: {
      display: 'block',
      [themeStyle.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    linkColor: {
      // fontSize: '12px !important',
      [themeStyle.breakpoints.down('sm')]: {
        color: '#000000 !important',
        fontSize: '1.2rem !important',
        fontWeight: 300,
        padding: 12,
        width: '100%',
        height: 62,
        textAlign: 'center'
      }
    },
    mobile: {
      [themeStyle.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    mobileLinks: {
      [themeStyle.breakpoints.down('sm')]: {
        width: '100%',
        height: 62,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  })

interface Props extends WithStyles<typeof styles> {
  currentUser: any
  item: any
  index: number
  isEditMode?: boolean
  language: string
  languages?: [string]
  linkColor: string
  shop: any
  styling: any
  subElements: any[]
  urlPrefix: string
  teamstore: any
}

const MenuItem: React.FunctionComponent<Props> = props => {
  const {
    classes,
    item,
    index,
    language,
    languages,
    isEditMode,
    shop,
    subElements,
    linkColor,
    styling,
    urlPrefix,
    currentUser,
    teamstore
  } = props
  const [isVisible, setIsVisible] = useState(false)

  const handleShow = () => {
    if (isEditMode) {
      return
    }
    setIsVisible(true)
  }

  const handleHide = () => {
    if (isEditMode) {
      return
    }
    setIsVisible(false)
  }

  // TODO handle this better instead of link text check we should have standard links/pages for those
  try {
    if (
      currentUser &&
      currentUser.id &&
      getLinkText(item, language) &&
      (getLinkText(item, language)
        .toLowerCase()
        .indexOf('login') !== -1 ||
        getLinkText(item, language)
          .toLowerCase()
          .indexOf('register') !== -1 ||
        getLinkText(item, language)
          .toLowerCase()
          .indexOf('signup') !== -1)
    ) {
      return null
    }
  } catch (e) {
    console.error('Error hiding user links', e)
  }

  return (
    <div
      onMouseLeave={handleHide}
      className={classes.mobileLinks}
      data-testid="menu-item"
    >
      <div style={{ position: 'relative' }} className={classes.mobileLinks}>
        {getLinkUrl(item, urlPrefix, language) &&
        (getLinkUrl(item, urlPrefix, language).indexOf('http') !== -1 ||
          getLinkUrl(item, urlPrefix, language).indexOf('https') !== -1) ? (
          <Button
            variant="text"
            className={classes.linkColor}
            style={{
              ...getStyleAlignmentItem(item.alignment),
              color: linkColor || 'inherit',
              fontSize: styling.fontSize,
              padding: '17px 7px',
              textDecoration: 'none !important',
              borderRadius: 0,
              lineHeight: '30px',
              textDecoration: 'none'
            }}
          >
            <a
              key={index}
              rel="noopener noreferrer"
              target={'_blank'}
              href={getLinkUrl(item, urlPrefix, language)}
              style={{
                textDecoration: 'none',
                color: linkColor || 'inherit'
              }}
              onMouseEnter={handleShow}
            >
              {getLinkText(item, language)}
            </a>
          </Button>
        ) : (
          <Button
            key={index}
            component={Link}
            variant="text"
            style={{
              ...getStyleAlignmentItem(item.alignment),
              color: linkColor || 'inherit',
              fontSize: styling.fontSize,
              padding: '14px 7px',
              textDecoration: 'none !important',
              borderRadius: 0
            }}
            className={classes.linkColor}
            to={getLinkUrl(item, urlPrefix, language)}
            onMouseEnter={handleShow}
          >
            {getLinkText(item, language)}
          </Button>
        )}
      </div>
      {subElements && subElements.length > 0 ? (
        <div
          style={{
            display: isVisible ? 'block' : 'none',
            height: 'auto',
            left: '0px',
            position: 'absolute',
            textDecoration: 'none',
            top: '99%',
            width: '100vw',
            zIndex: 1000
          }}
          onMouseLeave={handleHide}
          data-testid="menu-itemsub-container"
        >
          <div className={classes.mobile}>
            <MenuItemSub
              teamstore={teamstore}
              shop={shop}
              languages={languages}
              subElements={subElements}
              urlPrefix={urlPrefix}
              language={language}
              styling={styling}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(MenuItem)
