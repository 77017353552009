import React from 'react';
import {
  Grid,
  Paper,
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  Typography
} from '@material-ui/core';
import _ from 'lodash';
import { removeKeys } from '../../../../helper/removeKeys';

import Input from '../../../common/Input';
import client from '../../gql/admin-apollo-config';
import { ADMIN_UPDATE_SHOP_SETTINGS } from '../../gql/admin-mutations';

const styles = (themeStyle: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {
  shop: any;
}

const SocialLinksSettings: React.FunctionComponent<Props> = props => {
  const { shop } = props;

  const getVal = (icon: string): string => {
    if (!shop || !shop.socialLinks) {
      return '';
    }
    const val = shop.socialLinks.find(o => o.icon === icon);
    return val ? val.externalLink : '';
  };

  const handleChange = async (
    externalLink: string,
    icon: string
  ): Promise<any> => {
    let socialLinks =
      shop && shop.socialLinks ? _.cloneDeep(shop.socialLinks) : [];

    const soc = socialLinks.find(o => o.icon === icon);
    if (externalLink !== '') {
      if (soc) {
        soc.externalLink = externalLink;
      } else {
        socialLinks.push({ icon, externalLink });
      }
    } else {
      if (soc) {
        socialLinks = socialLinks.filter(o => o.icon !== icon);
      }
    }

    await client.mutate({
      mutation: ADMIN_UPDATE_SHOP_SETTINGS,
      optimisticResponse: {
        __typename: 'Mutation',
        updateShop: {
          ...shop,
          socialLinks: socialLinks.map(o => {
            return {
              ...o,
              __typename: 'SocialLink'
            }
          })
        }
      },
      variables: {
        id: shop.id,
        input: {
          socialLinks: removeKeys(socialLinks, '__typename')
        }
      }
    });
  };

  return (
    <Grid container={true} style={{ marginTop: 24 }}>
      <Grid item={true} xs={12} sm={12} md={4} lg={3} xl={3}>
        <Grid
          container={true}
          style={{ padding: '24px 24px 24px 0px' }}
          spacing={1}
          alignItems="center"
        >
          <Grid item={true} xs={12}>
            <Typography variant="subtitle1" style={{ marginRight: 16 }}>
              Social Links
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body1" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} sm={12} md={8} lg={9} xl={9}>
        <Paper elevation={0} style={{ padding: 24 }}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Input
                label="Instagram"
                placeholder="Enter the URL"
                value={getVal('INSTAGRAM')}
                id="outlined-logo"
                margin="normal"
                onChange={e => handleChange(e.target.value, 'INSTAGRAM')}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Input
                label="Facebook"
                placeholder="Enter the URL"
                value={getVal('FACEBOOK')}
                id="outlined-logo"
                margin="normal"
                onChange={e => handleChange(e.target.value, 'FACEBOOK')}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Input
                label="Twitter"
                placeholder="Enter the URL"
                value={getVal('TWITTER')}
                id="outlined-logo"
                margin="normal"
                onChange={e => handleChange(e.target.value, 'TWITTER')}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Input
                label="Youtube"
                placeholder="Enter the URL"
                value={getVal('YOUTUBE')}
                id="outlined-logo"
                margin="normal"
                onChange={e => handleChange(e.target.value, 'YOUTUBE')}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(SocialLinksSettings);
