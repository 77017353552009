import React, { Component } from 'react';

export default class TouchPanIcon extends React.Component {
  render() {
    const {
      width = 80,
      height = 80,
      background,
      padding,
      fill5 = '#c4c4c4'
    } = this.props;
    return (
      <div style={{ width: width, height: height, padding: padding }}>
        <style
          dangerouslySetInnerHTML={{
            __html: `
                        .touchPan-1,.touchPan-2,.touchPan-3{fill:none;}.touchPan-1,.touchPan-3{stroke:#4d4d4d;}.touchPan-1,.touchPan-2,.touchPan-3,.touchPan-5{stroke-miterlimit:10;}.touchPan-1,.touchPan-2,.touchPan-5{stroke-width:2px;}.touchPan-2,.touchPan-5{stroke:#000;}.touchPan-3{stroke-width:3px;}.touchPan-4{fill:#4d4d4d;}.touchPan-5{fill:#fff;}
        `
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 68.5 128.14"
          style={{ width: '100%', height: '100%' }}
        >
          <defs />
          <title>Asset 7</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <circle class="touchPan-1" cx="22.58" cy="22.51" r="13.74" />
              <circle class="touchPan-2" cx="22.17" cy="22.11" r="6.87" />
              <path class="touchPan-3" d="M43.59,22.51a21,21,0,0,0-42,0" />
              <path
                class="touchPan-4"
                d="M3,22.94l-.19,5a1.31,1.31,0,0,1-2.62,0l-.19-5a1.5,1.5,0,1,1,3-.11Z"
              />
              <path
                class="touchPan-4"
                d="M2.7,30.94l-.19,5a1,1,0,0,1-2,0l-.19-5a1.21,1.21,0,0,1,1.16-1.25A1.2,1.2,0,0,1,2.7,30.85Z"
              />
              <path
                class="touchPan-4"
                d="M2.4,38.94l-.19,5a.7.7,0,0,1-.74.68.7.7,0,0,1-.68-.68l-.19-5a.9.9,0,0,1,1.8-.07Z"
              />
              <path
                class="touchPan-4"
                d="M2.1,46.94l-.19,5a.41.41,0,0,1-.82,0l-.19-5a.6.6,0,0,1,.58-.62.59.59,0,0,1,.62.57Z"
              />
              <path
                class="touchPan-4"
                d="M1.8,54.94l-.19,5a.11.11,0,0,1-.11.11.12.12,0,0,1-.11-.11l-.19-5a.32.32,0,0,1,.29-.31.3.3,0,0,1,.31.29Z"
              />
              <path
                class="touchPan-4"
                d="M45.06,20.81l-.24,5A1.26,1.26,0,0,1,43.5,27a1.28,1.28,0,0,1-1.21-1.2l-.23-5a1.5,1.5,0,1,1,3-.15Z"
              />
              <path
                class="touchPan-4"
                d="M44.68,28.81l-.24,5a.88.88,0,0,1-.92.84.89.89,0,0,1-.85-.84l-.23-5a1.12,1.12,0,1,1,2.24-.11Z"
              />
              <path
                class="touchPan-4"
                d="M44.3,36.81l-.23,5a.52.52,0,0,1-.54.48.51.51,0,0,1-.48-.48l-.24-5a.75.75,0,0,1,1.49-.07Z"
              />
              <path
                class="touchPan-4"
                d="M43.93,44.81l-.24,5a.13.13,0,0,1-.14.12.12.12,0,0,1-.12-.12l-.24-5a.37.37,0,0,1,.35-.39.38.38,0,0,1,.39.35Z"
              />
              <path
                class="touchPan-5"
                d="M60.23,63.23a6.77,6.77,0,0,0-5.6,3.88h-.49l-.2-1.69a7.61,7.61,0,0,0-15.12,1.69h-.36l0-50.26A7.25,7.25,0,0,0,31,9.75h-.38a7.25,7.25,0,0,0-7.39,7.1l0,50.26h-.72V25a7.42,7.42,0,0,0-7.42-7.42h-.38A7.42,7.42,0,0,0,7.29,25l.21,72.3a29.82,29.82,0,0,0,59.64,0l.36-27.88A6.76,6.76,0,0,0,60.23,63.23Z"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
