import React from 'react'
import { setOrg } from '../../../helper/storage'

export const OrganisationContext = React.createContext()

class OrganisationProvider extends React.Component {
  constructor(props) {
    super(props)
    this.changeId = id => {
      // console.log('OrganisationProvider.changeId', id);
      try {
        this.setState(state => {
          return {
            id
          }
        })
      } catch (error) {}
    }
    this.changeCartId = id => {
      // console.log('OrganisationProvider.changeId', id);
      try {
        this.setState(state => {
          return {
            cartId: id
          }
        })
      } catch (error) {}
    }
    this.changeAddressId = addressId => {
      this.setState(state => {
        return {
          addressId: addressId
        }
      })
    }
    this.state = {
      id: null,
      cartId: null,
      addressId: null,
      changeId: this.changeId,
      changeCartId: this.changeCartId,
      changeAddressId: this.changeAddressId
    }
  }

  render() {
    // console.log('OrganisationContext.this.state', this.state);
    return (
      <OrganisationContext.Provider value={this.state}>
        {this.props.children}
      </OrganisationContext.Provider>
    )
  }
}
export default OrganisationProvider
