import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, createStyles, withStyles, WithStyles} from '@material-ui/core'


const styles = () => createStyles({
})


interface Props extends WithStyles<typeof styles> {
  open: boolean,
  onClose(): void,
  onSave(): void
}


const DeleteMenu: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, onSave } = props


  const handleSave = (): void => {
    onSave()
    onClose()
  }


  const handleClose = (): void => {
    onClose()
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{width: 400}} id="form-dialog-title">Delete Menu</DialogTitle>
      <DialogContent  style={{width: 400}}>
        Are you going to delete this menu. Are you sure?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="secondary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles, { withTheme: true })(DeleteMenu)
