import React, { useState } from 'react'
import { FormControlLabel, Checkbox, Typography, Grid } from '@material-ui/core'
import { unCamelCase } from '../../../../helper/string'

interface Props {
  availableFields: string[]
  selectedFields: string[]
  onSelect(selectedFields: string[]): any
}

const FieldsSelection: React.FunctionComponent<Props> = props => {
  const { availableFields, selectedFields, onSelect } = props
  const [selected, setSelected] = useState(selectedFields || [])

  const handleChange = (field: string): void => {
    const newSelected =
      selected.indexOf(field) === -1
        ? [...selected, field]
        : selected.filter(f => f !== field)
    setSelected(newSelected)
    onSelect(newSelected)
  }

  return (
    <div>
      <Typography variant="subtitle1" style={{ marginTop: 24 }}>
        Select fields for exporting
      </Typography>
      <Grid container={true} style={{ marginTop: 16 }}>
        {availableFields.sort().map(field => (
          <Grid item={true} xs={6} sm={6} md={4} lg={3} xl={3} key={field}>
            <FormControlLabel
              control={<Checkbox value="checked" />}
              label={unCamelCase(field)}
              onChange={() => handleChange(field)}
              data-testid={'field-checkbox-' + field}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default FieldsSelection
