import React from 'react';
import { Grid, Typography, Input, InputLabel, Paper } from '@material-ui/core';

const ProductCarelabelPosition = ({
  classes,
  product,
  productId,
  updateProductDetail
}) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <Grid
          container
          style={{
            padding: '24px 24px 24px 0px'
          }}
          spacing={1}
          alignItems="center"
        >
          <Grid item>
            <Typography
              data-cy="Care-label-position-header"
              variant="subtitle1"
              style={{ marginRight: 16 }}
            >
              Care label position
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
        <Paper
          style={{
            padding: 24,
            borderRadius: 15
          }}
          elevation={0}
        >
          <Grid container>
            <Grid xs={12}>
              <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12}>
                  <InputLabel>Care label position</InputLabel>
                  <Input
                    dataCy="Care-label-Input"
                    placeholder="Enter care label position"
                    multiline={true}
                    rowsMax="4"
                    defaultValue={product.labelPosition}
                    onChange={e =>
                      updateProductDetail(
                        productId,
                        'labelPosition',
                        e.target.value
                      )
                    }
                    margin="normal"
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProductCarelabelPosition;
