import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import {
  CONTENT_DELETE_ROW,
  CONTENT_UPDATE_ROW
} from '../../gql/admin-mutations';
import { GET_PAGE_CONTENT } from '../../gql/admin-queries';
import _remove from 'lodash/remove';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core';
import client from '../../gql/admin-apollo-config';
import _ from 'lodash';

const styles = theme => ({});

class DeleteRow extends Component {
  render() {
    const { pageId, Template } = this.props;

    return (
      <Mutation
        mutation={CONTENT_DELETE_ROW}
        variables={{ pageId: pageId, id: Template.state.selectedRow }}
        update={(cache, { data: { deleteRow } }) => {
          const { page } = cache.readQuery({
            query: GET_PAGE_CONTENT,
            variables: { id: pageId }
          });

          const newData = page;
          newData.rows = newData.rows.filter(
            o => o.id !== Template.state.selectedRow
          );

          cache.writeQuery({
            query: GET_PAGE_CONTENT,
            data: { page: newData },
            variables: { id: Template.state.selectedRow }
          });

          // Update all sortOrders
          const sortOrder = Template.state.selectedRowObj.sortOrder;
          page.rows = _.sortBy(page.rows, 'sortOrder');
          page.rows.forEach(row => {
            if (sortOrder < row.sortOrder) {
              client.mutate({
                mutation: CONTENT_UPDATE_ROW,
                variables: {
                  id: row.id,
                  pageId: pageId,
                  input: {
                    sortOrder: row.sortOrder - 1
                  }
                }
              });
            }
          });
        }}
        onCompleted={() =>
          Template.setState({ selectedRow: '', selectedRowObj: '' })
        }
      >
        {deleteRow => (
          <Tooltip title="Delete">
            <IconButton
              data-cy="Delete-Content"
              onClick={deleteRow}
              aria-label="Delete"
              style={{ color: 'rgb(225, 0, 80)' }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DeleteRow);
