import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ApolloConsumer, Query } from 'react-apollo';
import { GET_COLOR } from '../../gql/queries';

const styles = theme => ({});

class Color extends Component {
  state = {};
  render() {
    const { classes, colorId } = this.props;
    return (
      <div>
        <ApolloConsumer>
          {client => {
            return (
              <Query
                query={GET_COLOR}
                variables={{
                  id: colorId
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return null;
                  if (error) return `Error!: ${error}`;
                  return (
                    <div>
                      {data ? data.color.name : ''}
                      {/* {data ? data.color.hex : ''} */}{' '}
                      {data
                        ? `rgb(${(data.color.colorRGB.r * 255).toFixed(0)},${(
                            data.color.colorRGB.g * 255
                          ).toFixed(0)},${(data.color.colorRGB.b * 255).toFixed(
                            0
                          )}}`
                        : null}
                    </div>
                  );
                }}
              </Query>
            );
          }}
        </ApolloConsumer>
      </div>
    );
  }
}

Color.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Color);
