import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CouponList from './CouponList';
import CouponDetail from './CouponDetail';

class Fabrics extends Component {
  render() {
    const { shopId, shop, couponId, languages, defaultLanguage } = this.props;
    return couponId ? (
      <CouponDetail
        languages={languages}
        defaultLanguage={defaultLanguage}
        shopId={shopId}
        couponId={couponId}
      />
    ) : (
      <Grid>
        <CouponList defaultLanguage={defaultLanguage} shopId={shopId} />
        {/* <AddFabric shopId={shopId} /> */}
      </Grid>
    );
  }
}
export default Fabrics;
