import './logger'
import React, { Component } from 'react'

import Main3D from './3D/Main3D'
import AutoDesign from './3D/autoDesign'
import Loading from './tools/Loading'
import Dimension from './step/Dimension'
import FontLoad from './tools/FontLoad'
import MenuStep from './step/MenuStep'
import MenuDesignRoomStep from './step/MenuDesignRoomStep'
import BottomBar from './tools/Components/BottomBar'
import MarkerLayoutPlane from './tools/Components/MarkerLayoutPlane'
import Selection from './tools/Selection'
import ViewControl from './tools/Components/ViewControl'
import { API_STAGE } from '../../../brikl-config'

// MAIN CALL
import * as design_template from './tools/Functions/design_template'
// MAIN CALL

import * as template_control from './template_control'
import * as canvas_coloring from './tools/canvas_coloring'
import * as change_color from './tools/change_color'
import * as tools from './tools/tools'
import * as fabric_function from './FABRIC/fabric_function'
import * as svg_control from './FABRIC/svg_control'
import * as marker_layout_control from './FABRIC/marker_layout_control'
import * as keyboard_event from './tools/Functions/keyboard_event'
import * as control_tools_event from './tools/Functions/control_tools_event'
import * as save_load from './tools/Functions/save_load'
import * as control_view from './tools/Functions/control_view'

import './myCss.css'
import { Grid, Button } from '@material-ui/core'

import SplitterLayout from 'react-splitter-layout'

import * as query from './mutation_query/query'
import * as local from './mutation_query/local'

import transparent from './TEMPLATE_ASSET/transparent.png'

var svgRaw = null

class DesignTemplate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dynamicPlane: 'dynamicPlane',
      hidden3D: '',
      activeML: false,
      reloadSVG: true,
      updateSVGDom: false
    }
    this.saveInterval = null
    this.SHOP_ID = this.props.SHOP_ID
    this.shop = this.props.shop

    logger.log('process.env', process.env.GATSBY_BRIKL_SALESCHANNEL_ID)

    this.design_template = design_template
    design_template.initComponent(this)
    design_template.declare_var(props)

    // INIT PRODUCT DATA
    if (this.props.localData === true) {
      local.productDataQuery(this)
    } else {
      query.productDataQuery(this)
    }

    this.prepareMixColorzoneSVG()
  }

  componentDidMount() {
    // AFTER 1ST RENDER

    // CONTROL FABRIC
    design_template.control_fabric_threejs()
    // CONTROL FABRIC

    fabric_function.initFabric()
    design_template.function_init_call()

    if (this.isSaveEnabled) {
      const saveIntervalTime = API_STAGE === 'PRODUCTION' ? 1 : 5
      this.template_control.capture4view()
      this.saveInterval = setInterval(
        this.template_control.capture4view,
        60000 * saveIntervalTime
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.saveInterval)
    if (this.isSaveEnabled) {
      this.template_control.capture4view()
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    design_template.function_init_call()
    this.prepareMixColorzoneSVG()
  }

  splitDrag(size) {
    try {
      if (this.Main3D !== null) {
        this.Main3D.onWindowResize()
      }
    } catch (e) {
      logger.error('DesignTemplate.splitDrag.error', e)
    }
  }

  componentDidUpdate() {
    this.splitDrag()
  }

  prepareMixColorzoneSVG() {
    logger.log('prepareMixColorzoneSVG', this)

    if (this.productDataSet) {
      var templateData = this.productDataSet.TEMPLATE_SET_BY_ID[
        this._ACTIVE_TEMPLATE_ID
      ]

      logger.log('productID', this.productID)
      logger.log('_ACTIVE_TEMPLATE_ID', this._ACTIVE_TEMPLATE_ID)
      logger.log('templateData', templateData)

      // CHECK IF MIX COLORZONE
      if (
        templateData.hasColorZoneFile === true &&
        templateData.templateSrc !== null
      ) {
        this.RAW_SVG = true
        var colorZoneFileSrc = this.productDataSet.TEMPLATE_SET_BY_ID[
          this._ACTIVE_TEMPLATE_ID
        ].colorZoneFileSrc

        logger.log(
          colorZoneFileSrc,
          this.productDataSet.TEMPLATE_SET_BY_ID[this._ACTIVE_TEMPLATE_ID]
        )

        if (this.state.reloadSVG === true) {
          this.Loading?.activeLoading(true)
          new window.fabric.util.request(colorZoneFileSrc, {
            method: 'get',
            onComplete: r => {
              console.log('RETURN SVG REQUEST', r)
              // svgRaw = r.responseText
              var newSvg = document.getElementById('SVGDOM')

              if (newSvg) {
                newSvg.innerHTML = r.responseText
                  .replace(/<!DOCTYPE[\s\S]*?(\[[\s\S]*\])*?>/i, '')
                  .replace('<svg', '<svg id="SVGDOM2"')
              }

              svgRaw = r.responseText.replace(
                /<!DOCTYPE[\s\S]*?(\[[\s\S]*\])*?>/i,
                ''
              )

              this.setState({ updateSVGDom: true, reloadSVG: false })
              this.Loading?.activeLoading(false)
            }
          })

          this.setState({ reloadSVG: false })
        }
      } else {
        svgRaw = null
        this.RAW_SVG = false
      }
    } else {
      svgRaw = null
      this.RAW_SVG = false
    }
  }

  render() {
    const { dynamicPlane, hidden3D, activeML } = this.state

    logger.log('DesignTemplate', this.productID) // proID

    var show2D = 'none'
    if (this._SHOW_2D === true) {
      show2D = 'block'
    }

    // SHOP CONDITION

    var planeSize = { height: '100vh', position: 'relative' }
    var bottomTool = null
    var markerLayoutPlane = null
    var hideMenu = 'block'

    if (this.props.shopId === 'yuepai') {
      planeSize = { height: 'calc(100vh - 50px)' }
      bottomTool = <BottomBar DesignRoot={this} />

      if (activeML === true) {
        markerLayoutPlane = <MarkerLayoutPlane DesignRoot={this} />
        var hideMenu = 'none'
      }
    }

    // SHOP CONDITION

    if (this._APP_TYPE !== '2d-3d') {
      return (
        <div className="DesignTemplate" style={{ display: 'flex' }}>
          <Loading DesignRoot={this} position="center" />
          <div
            style={{
              position: 'absolute',
              zIndex: -1000000,
              top: 0,
              width: 1,
              heigh: 1,
              overflow: 'hidden'
            }}
          >
            <canvas id="canvasEditor" width="1" height="1" />
          </div>
          <div
            style={{
              position: 'relative',
              width: 'calc(100vw - 420px)',
              height: '100vh'
            }}
          >
            <Dimension DesignRoot={this} />
            <Main3D DesignRoot={this} productDataSet={this.productDataSet} />
            {this.props.shopConfiguration?.capabilities?.featureFlags?.includes(
              'AUTO_DESIGN'
            ) ? (
              <AutoDesign
                DesignRoot={this}
                productDesign={this.props.productDesign}
                designTemplate={
                  this.currentTemplateSET
                    ? this.currentTemplateSET
                    : this.loadData
                }
              />
            ) : null}
          </div>
          <MenuStep
            user={this.props.user}
            langCode={this.props.langCode}
            shopConfiguration={this.props.shopConfiguration}
            DesignRoot={this}
          />
          <FontLoad DesignRoot={this} />
          <Selection DesignRoot={this} productDataSet={this.productDataSet} />
        </div>
      )
    } else {
      return (
        <div className="DesignTemplate">
          <Loading DesignRoot={this} position="center" />
          <ViewControl DesignRoot={this} />
          <span style={{ display: hideMenu }}>
            <MenuDesignRoomStep DesignRoot={this} />
          </span>
          <FontLoad DesignRoot={this} />

          {markerLayoutPlane}

          <div className={dynamicPlane + ' ' + hidden3D}>
            <SplitterLayout
              primaryIndex={1}
              onSecondaryPaneSizeChange={this.splitDrag.bind(this)}
              secondaryInitialSize={60}
              percentage={true}
              style={planeSize}
            >
              <div style={planeSize} id="PLANE_2D">
                <canvas
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%'
                  }}
                  width="1"
                  height="1"
                  id="canvasEditor"
                />
              </div>

              <div id="PLANE_3D" style={planeSize}>
                <Main3D
                  DesignRoot={this}
                  productDataSet={this.productDataSet}
                />
                <Dimension DesignRoot={this} />
              </div>
            </SplitterLayout>
          </div>

          {bottomTool}

          <div id="canvasRenderDom">
            <canvas id="canvasRender" width="1" height="1" />
            <canvas id="canvasExport" width="1" height="1" />
          </div>

          <Selection DesignRoot={this} productDataSet={this.productDataSet} />

          {svgRaw !== null && (
            <div
              id="SVGDOM"
              style={{ position: 'absolute', top: '-1000000px' }}
            ></div>
          )}
        </div>
      )
    }
  }
}

export default DesignTemplate
