import React, { Component } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../../common/Select';
import { Link } from 'gatsby';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_GET_TEAMSTORE } from '../../gql/admin-queries';
import { ADMIN_DELETE_PRODUCT_PACKAGE_SALESCHANNEL } from '../../gql/admin-mutations';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { secondary } from '../../../admin/AdminTheme';

class TeamstoreAssignedProductPackages extends Component {
  state = {
    selectedSalesChannelId: null,
    loading: false
  };
  render() {
    const { teamstoreId, teamstore, defaultLanguage, shopId } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper elevation={0} style={{ padding: ' 0px 24px 24px' }}>
            <Grid container>
              {this.state.loading === true ? (
                <CircularProgress />
              ) : teamstore.productPackages.edges ? (
                teamstore.productPackages.edges.map(pNode => {
                  const pc = pNode.node;
                  return !pc.productPackage && !pc.productPackage.id ? null : (
                    <Grid key={'tcpp' + pc.productPackage.id} item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        style={{
                          padding: '8px 0px',
                          marginRight: '12px',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          window.open(
                            '/admin/' +
                              shopId +
                              '/inventory/productpackages/' +
                              pc.productPackage.id
                          );
                        }}
                      >
                        <Grid item xs={1}>
                          <Grid container justify={'center'}>
                            <img
                              style={{
                                marginRight: '8px',
                                float: 'left',
                                width: '20px',
                                height: '20px',
                                objectFit: 'contain'
                              }}
                              src={
                                pc.productPackage.media &&
                                pc.productPackage.media.find(
                                  m => m && m.isThumbnail === true
                                )
                                  ? pc.productPackage.media.find(
                                      m => m && m.isThumbnail === true
                                    ).image
                                  : null
                              }
                              alt={''}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          style={{
                            cursor: 'pointer',
                            width: '100%',
                            border: '1px solid',
                            backgroundColor: secondary,
                            color: '#fff',
                            height: 36,
                            borderRadius: 3,
                            display: 'flex',
                            alignContent: 'center',
                            padding: 4,
                            position: 'relative'
                          }}
                        >
                          <Typography style={{ padding: 4, marginLeft: 8 }}>
                            {pc.productPackage.title
                              ? getTextFromLanguage(
                                  pc.productPackage.title.text,
                                  defaultLanguage
                                )
                                ? getTextFromLanguage(
                                    pc.productPackage.title.text,
                                    defaultLanguage
                                  )
                                : getTextFromLanguage(
                                    pc.productPackage.title.text,
                                    'fr_FR'
                                  )
                                ? getTextFromLanguage(
                                    pc.productPackage.title.text,
                                    'fr_FR'
                                  )
                                : getTextFromLanguage(
                                    pc.productPackage.title.text,
                                    'nl_NL'
                                  )
                                ? getTextFromLanguage(
                                    pc.productPackage.title.text,
                                    'nl_NL'
                                  )
                                : pc.productPackage.name
                                ? pc.productPackage.name
                                : pc.productPackageId
                              : 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Mutation
                            mutation={ADMIN_DELETE_PRODUCT_PACKAGE_SALESCHANNEL}
                            update={(
                              cache,
                              { data: { deleteProductPackageSalesChannel } }
                            ) => {
                              if (deleteProductPackageSalesChannel) {
                                const data = cache.readQuery({
                                  query: ADMIN_GET_TEAMSTORE,
                                  variables: { id: teamstore.id }
                                });
                                data.teamstore.productPackages.edges = data.teamstore.productPackages.edges.filter(
                                  c =>
                                    c.node.productPackage.id !==
                                    pc.productPackage.id
                                );
                                cache.writeQuery({
                                  query: ADMIN_GET_TEAMSTORE,
                                  variables: { id: teamstore.id },
                                  data
                                });
                              }
                            }}
                          >
                            {(
                              deleteProductPackageSalesChannel,
                              { data, loading, error }
                            ) => {
                              return (
                                <Button
                                  onClick={async () => {
                                    if (pc) {
                                      await deleteProductPackageSalesChannel({
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          deleteProductPackageSalesChannel: true
                                        },
                                        variables: {
                                          productPackageId:
                                            pc.productPackage.id,
                                          salesChannelId: teamstore.id
                                        }
                                      });
                                    }
                                  }}
                                  color="primary"
                                  variant="contained"
                                  style={{ minWidth: 0, width: '100%' }}
                                >
                                  <DeleteIcon />
                                </Button>
                              );
                            }}
                          </Mutation>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })
              ) : null}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default TeamstoreAssignedProductPackages;
