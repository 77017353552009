import React, { Component } from 'react';
import { API_STAGE } from '../../../../../brikl-config';

class ViewControl extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot;
    DesignRoot.ViewControl = this;
    DesignRoot.setState({ update: true });
    // PROVIDE COMPONENT TO MAIN
  }

  render() {
    return <div className="ViewControl" />;
  }
}

export default ViewControl;
