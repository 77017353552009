import React, { Component } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../../common/Select';
import { Link } from 'gatsby';
import { Query, Mutation } from 'react-apollo';
import {
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_GET_COLLECTION
} from '../../gql/admin-queries';
import {
  ADMIN_CREATE_COLLECTION_RELATION,
  ADMIN_DELETE_COLLECTION_RELATION
} from '../../gql/admin-mutations';
import { getTextFromLanguage } from '../../../../helper/languageHelper';

class CollectionParent extends Component {
  state = {
    selectedCollectionId: null,
    loading: false
  };
  render() {
    const { collection, shopId, defaultLanguage } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography data-cy="Collection-Parent-header" variant="subtitle1">
            Parent collection
          </Typography>
        </Grid>
        <Query query={ADMIN_SHOP_COLLECTIONS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            let sortedCollections = null;
            try {
              sortedCollections = shop.collections.edges.sort(function(a, b) {
                if (
                  getTextFromLanguage(a.node.title.text, defaultLanguage) <
                  getTextFromLanguage(b.node.title.text, defaultLanguage)
                ) {
                  return -1;
                }
                if (
                  getTextFromLanguage(a.node.title.text, defaultLanguage) >
                  getTextFromLanguage(b.node.title.text, defaultLanguage)
                ) {
                  return 1;
                }
                return 0;
              });
            } catch (error) {
              console.error('pvo.error', error);
            }
            return (
              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                <Paper elevation={0} style={{ padding: 24 }}>
                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item />
                    <Grid item>
                      <Mutation
                        mutation={ADMIN_CREATE_COLLECTION_RELATION}
                        update={(
                          cache,
                          { data: { createCollectionRelation } }
                        ) => {
                          if (createCollectionRelation) {
                            const data = cache.readQuery({
                              query: ADMIN_GET_COLLECTION,
                              variables: { id: collection.id }
                            });
                            data.collection.parentCollections = data.collection
                              .parentCollections
                              ? [
                                  ...data.collection.parentCollections,
                                  {
                                    parentId: createCollectionRelation.parentId,
                                    childId: createCollectionRelation.childId,
                                    __typename: 'CollectionRelation'
                                  }
                                ]
                              : [
                                  {
                                    parentId: createCollectionRelation.parentId,
                                    childId: createCollectionRelation.childId,
                                    __typename: 'CollectionRelation'
                                  }
                                ];
                            {
                            }
                            cache.writeQuery({
                              query: ADMIN_GET_COLLECTION,
                              variables: { id: collection.id },
                              data
                            });
                          }
                        }}
                      >
                        {(
                          createCollectionRelation,
                          { data, loading, error }
                        ) => {
                          return (
                            <>
                              <Grid container>
                                <Select
                                  dataCy="Collection-Parent-Select"
                                  onChange={async e => {
                                    if (e.target.value !== '') {
                                      this.setState({
                                        selectedCollectionId: e.target.value
                                      });
                                    }
                                  }}
                                  value={this.state.selectedCollectionId}
                                  label="Collections"
                                >
                                  <option value={''}>No parent</option>
                                  {sortedCollections
                                    ? sortedCollections.map(pa => {
                                        if (pa && pa.node && pa.node.title) {
                                          return (
                                            <option value={pa.node.id}>
                                              {getTextFromLanguage(
                                                pa.node.title.text,
                                                defaultLanguage
                                              )}{' '}
                                              ({pa.node.no})
                                            </option>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })
                                    : null}
                                </Select>
                                <Button
                                  data-cy="Collection-Parent-Assign-btn"
                                  onClick={() => {
                                    createCollectionRelation({
                                      optimisticResponse: {
                                        __typename: 'Mutation',
                                        createCollectionRelation: {
                                          parentId: this.state
                                            .selectedCollectionId,
                                          childId: collection.id,
                                          __typename: 'CollectionRelation'
                                        }
                                      },
                                      variables: {
                                        parentId: this.state
                                          .selectedCollectionId,
                                        childId: collection.id
                                      }
                                    });
                                  }}
                                >
                                  Assign
                                </Button>
                              </Grid>
                              {collection.parentCollections
                                ? collection.parentCollections.map(
                                    parentCollection => {
                                      if (!sortedCollections) return null;
                                      let title = null;
                                      try {
                                        const co = sortedCollections.find(
                                          c =>
                                            c.node.id ===
                                            parentCollection.parentId
                                        );
                                        title = co.node.title.text;
                                      } catch (error) {}
                                      return (
                                        <>
                                          <span
                                            data-cy={
                                              'Collection-Parent-Item-' +
                                              getTextFromLanguage(
                                                title,
                                                defaultLanguage
                                              )
                                            }
                                          >
                                            {title
                                              ? getTextFromLanguage(
                                                  title,
                                                  defaultLanguage
                                                )
                                              : 'N/A'}
                                          </span>
                                          <Mutation
                                            mutation={
                                              ADMIN_DELETE_COLLECTION_RELATION
                                            }
                                            update={(
                                              cache,
                                              {
                                                data: {
                                                  deleteCollectionRelation
                                                }
                                              }
                                            ) => {
                                              if (deleteCollectionRelation) {
                                                const data = cache.readQuery({
                                                  query: ADMIN_GET_COLLECTION,
                                                  variables: {
                                                    id: collection.id
                                                  }
                                                });
                                                data.collection.parentCollections = data.collection.parentCollections.filter(
                                                  c =>
                                                    c.parentId !==
                                                    parentCollection.parentId
                                                );
                                                cache.writeQuery({
                                                  query: ADMIN_GET_COLLECTION,
                                                  variables: {
                                                    id: collection.id
                                                  },
                                                  data
                                                });
                                              }
                                            }}
                                          >
                                            {(
                                              deleteCollectionRelation,
                                              { data, loading, error }
                                            ) => {
                                              return (
                                                <Button
                                                  data-cy={
                                                    'Collection-Parent-Delete-btn-' +
                                                    getTextFromLanguage(
                                                      title,
                                                      defaultLanguage
                                                    )
                                                  }
                                                  onClick={() => {
                                                    deleteCollectionRelation({
                                                      variables: {
                                                        parentId:
                                                          parentCollection.parentId,
                                                        childId: collection.id
                                                      }
                                                    });
                                                  }}
                                                >
                                                  <DeleteIcon />
                                                </Button>
                                              );
                                            }}
                                          </Mutation>
                                        </>
                                      );
                                    }
                                  )
                                : null}
                            </>
                          );
                        }}
                      </Mutation>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          }}
        </Query>
      </Grid>
    );
  }
}

export default CollectionParent;
