import React from 'react'
import { createStyles, withStyles, WithStyles, Typography } from '@material-ui/core'
import Input from '../../../../common/Input'


const styles = () => createStyles({})

interface Props extends WithStyles<typeof styles> {
  data: any,
  onChange(value: string, language: string): void
}


const PageSlugs: React.FunctionComponent<Props> = (props) => {
  const { data, onChange } = props
  if (!data) {
    return null
  }

  return (
    <>
      {data.map((langContent, index) => {
        return (
          <div key={index}>
            <Typography variant="body1">{langContent.langCode}: </Typography>
            <Input
              value={langContent.content}
              onChange={e => onChange(e.target.value, langContent.langCode)}
            />
          </div>
        )
      })}
    </>
  )

}

export default withStyles(styles, { withTheme: true })(PageSlugs)