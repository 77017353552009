import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { Mutation } from 'react-apollo';
import { navigate } from '@reach/router';
import { Button } from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ADMIN_DELETE_PAGE_TAG } from '../../gql/admin-mutations';
import { ADMIN_SHOP_PAGE_TAGS } from '../../gql/admin-queries';
// import SimpleSnackbar from '../content/SimpleSnackbar'

const styles = theme => ({});

class PageTagSettings extends Component {
  state = {
    anchorEl: null
  };

  handleMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, tagId, shopId } = this.props;

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenuClick}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              navigate('/admin' + shopId + '//pagebuilder/tags/' + tagId);
            }}
          >
            Tag settings
          </MenuItem>
          <Mutation
            mutation={ADMIN_DELETE_PAGE_TAG}
            variables={{ id: tagId }}
            update={(cache, { data: { deletePageTag } }) => {
              const { shop } = cache.readQuery({
                query: ADMIN_SHOP_PAGE_TAGS,
                variables: { id: shopId }
              });

              const newData = shop;
              newData.pageTags.edges = newData.pageTags.edges.filter(
                o => o.node.id !== tagId
              );

              cache.writeQuery({
                query: ADMIN_SHOP_PAGE_TAGS,
                data: { shop: newData },
                variables: { id: shopId }
              });
            }}
          >
            {deletePageTag => (
              <MenuItem onClick={deletePageTag}>Delete</MenuItem>
            )}
          </Mutation>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PageTagSettings);
