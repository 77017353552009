import React, { Component } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../../common/Select';
import { Link } from 'gatsby';
import { Query, Mutation } from 'react-apollo';
import {
  GET_PAGE_CONTENT,
  ADMIN_SHOP_PAGE_TAGS
} from '../../gql/admin-queries';
import {
  ADMIN_CREATE_CONTENT_PAGE_TAG,
  ADMIN_DELETE_CONTENT_PAGE_TAG
} from '../../gql/admin-mutations';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
const uuid = require('uuid');

class PageTagAssignment extends Component {
  state = {
    selectedTagId: null
  };
  render() {
    const { defaultLanguage, page, shopId } = this.props;
    return (
      <Grid item xs={12} style={{ marginTop: 8 }}>
        <Query query={ADMIN_SHOP_PAGE_TAGS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            return (
              <Grid container>
                <Grid container alignItems="flex-end" spacing={1}>
                  <Grid item>
                    <Select
                      onChange={e => {
                        this.setState({
                          selectedTagId: e.target.value
                        });
                      }}
                      value={this.state.selectedTagId}
                      label="Page tags"
                    >
                      <option value={''}>Choose</option>
                      {shop && shop.pageTags && shop.pageTags.edges
                        ? shop.pageTags.edges.map(pageTag => {
                            if (pageTag && pageTag.node && pageTag.node.title) {
                              return (
                                <option
                                  key={'o-p' + pageTag.node.id}
                                  value={pageTag.node.id}
                                >
                                  {getTextFromLanguage(
                                    pageTag.node.title.text,
                                    defaultLanguage
                                  )}
                                </option>
                              );
                            } else {
                              return null;
                            }
                          })
                        : null}
                    </Select>
                  </Grid>
                  <Grid item>
                    <Mutation
                      mutation={ADMIN_CREATE_CONTENT_PAGE_TAG}
                      update={(cache, { data: { createContentPageTag } }) => {
                        if (createContentPageTag) {
                          // const data = cache.readQuery({
                          //   query: GET_PAGE_CONTENT,
                          //   variables: { id: page.id }
                          // });
                          // const newTag = shop.tags.edges.find(c => {
                          //   return c.node.id === this.state.selectedTagId;
                          // });
                          // // console.log('newTag', newTag);
                          // data.page.tags.unshift({
                          //   tag: {
                          //     ...newTag.node
                          //   },
                          //   __typename: 'ContentPageTag'
                          // });
                          // cache.writeQuery({
                          //   query: GET_PAGE_CONTENT,
                          //   variables: { id: page.id },
                          //   data
                          // });
                        }
                      }}
                    >
                      {(createContentPageTag, { data, loading, error }) => {
                        return (
                          <Button
                            onClick={async () => {
                              const newTag = shop.pageTags.edges.find(c => {
                                return c.node.id === this.state.selectedTagId;
                              });
                              await createContentPageTag({
                                optimisticResponse: {
                                  __typename: 'Mutation',
                                  createContentPageTag: {
                                    id: uuid.v4(),
                                    pageId: page.id,
                                    tag: {
                                      ...newTag.node
                                    },
                                    tagId: this.state.selectedTagId,
                                    __typename: 'ContentPageTag'
                                  }
                                },
                                variables: {
                                  shopId: shopId,
                                  pageId: page.id,
                                  tagId: this.state.selectedTagId
                                }
                              });
                              window.location.reload();
                            }}
                            variant="contained"
                            color="primary"
                            style={{ fontWeight: 400 }}
                          >
                            Assign tag
                          </Button>
                        );
                      }}
                    </Mutation>
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: 24 }}>
                  {page.tags
                    ? page.tags.map(cp => (
                        <Grid
                          container
                          alignItems="center"
                          spacing={1}
                          style={{ marginBottom: 8 }}
                          key={'cp-' + cp.tag.id}
                          item
                        >
                          <a
                            target="_blank"
                            href={
                              '/admin/' +
                              shopId +
                              '/inventory/products/' +
                              cp.tag.id
                            }
                            style={{ textDecoration: 'none', color: '#000' }}
                          >
                            <Grid
                              item
                              style={{
                                border: '1px solid #B23566',
                                height: 36,
                                borderRadius: 3,
                                display: 'flex',
                                alignItems: 'center',
                                padding: 'px 8px',
                                marginRight: 8
                              }}
                            >
                              {cp.tag.title
                                ? getTextFromLanguage(
                                    cp.tag.title.text,
                                    defaultLanguage
                                  )
                                : 'N/A'}
                            </Grid>
                          </a>
                          <Grid item>
                            <Mutation
                              mutation={ADMIN_DELETE_CONTENT_PAGE_TAG}
                              update={(
                                cache,
                                { data: { deleteContentPageTag } }
                              ) => {
                                if (deleteContentPageTag) {
                                  const data = cache.readQuery({
                                    query: GET_PAGE_CONTENT,
                                    variables: { id: page.id }
                                  });
                                  data.page.tags = data.page.tags.filter(
                                    c => c.tag.id !== cp.tag.id
                                  );
                                  cache.writeQuery({
                                    query: GET_PAGE_CONTENT,
                                    variables: { id: page.id },
                                    data
                                  });
                                }
                              }}
                            >
                              {(
                                deleteContentPageTag,
                                { data, loading, error }
                              ) => {
                                return (
                                  <Button
                                    onClick={async () => {
                                      if (cp) {
                                        await deleteContentPageTag({
                                          optimisticResponse: {
                                            __typename: 'Mutation',
                                            deleteContentPageTag: true
                                          },
                                          variables: {
                                            tagId: cp.tag.id,
                                            pageId: page.id
                                          }
                                        });
                                      }
                                    }}
                                    color="primary"
                                    variant="contained"
                                  >
                                    <DeleteIcon />
                                  </Button>
                                );
                              }}
                            </Mutation>
                          </Grid>
                        </Grid>
                      ))
                    : null}
                </Grid>
              </Grid>
            );
          }}
        </Query>
      </Grid>
    );
  }
}

export default PageTagAssignment;
