import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ApolloConsumer, Query } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import PrintingTooltip from '../steps/PrintingTooltip';
import { GET_PRINTINGTECHNIQUE } from '../../gql/queries';
import _get from 'lodash/get';
const styles = theme => ({
  lightTooltip: {
    background: '#000',
    opacity: '1',
    padding: '16px',
    fontSize: '11px',
    borderRadius: '4px'
  }
});

class PrintingTechnique extends Component {
  state = {};
  render() {
    const { classes, printingTechniqueId } = this.props;
    return (
      <div>
        <ApolloConsumer>
          {client => {
            return (
              <Query
                query={GET_PRINTINGTECHNIQUE}
                variables={{
                  id: printingTechniqueId
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return null;
                  if (error) return `Error!: ${error}`;
                  return (
                    <Grid container>
                      <Grid
                        item
                        xs={10}
                        style={{
                          fontSize: '13px'
                        }}
                      >
                        {_get(
                          data,
                          'printingTechnique.customName.text[0].content',
                          'N/A'
                        )}
                      </Grid>
                      <Grid
                        data-cy="info-icon"
                        item
                        xs={2}
                        style={{
                          padding: '0px'
                        }}
                      >
                        <Tooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <PrintingTooltip
                                printingTechnique={data.printingTechnique}
                              />
                            </React.Fragment>
                          }
                          classes={{
                            tooltip: classes.lightTooltip,
                            popper: classes.lightTooltip
                          }}
                          PopperProps={{
                            popperOptions: {
                              modifiers: {
                                arrow: {
                                  enabled: Boolean(this.state.arrowRef),
                                  element: this.state.arrowRef
                                }
                              }
                            }
                          }}
                        >
                          <InfoIcon
                            data-cy="info-icon"
                            style={{
                              height: '14px',
                              marginRight: '4px'
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  );
                }}
              </Query>
            );
          }}
        </ApolloConsumer>
      </div>
    );
  }
}

PrintingTechnique.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PrintingTechnique);
