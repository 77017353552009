import React from 'react'
import { useQuery } from '@apollo/react-hooks';
import {CircularProgress, WithStyles, withStyles, createStyles} from '@material-ui/core'
import {NETLIFY_DEPLOYMENT_STATUS} from '../../gql/admin-queries'


const styles = () => createStyles({
})


interface Props extends WithStyles<typeof styles> {
  deployId: string
  siteId: string
}


const DeploymentStatusPolling: React.FunctionComponent<Props> = props => {
  const {deployId, siteId} = props

  const { data, loading } = useQuery(NETLIFY_DEPLOYMENT_STATUS, {
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
    variables: { siteId, deployId }
  })
  console.log('***', data)

  return (
    <>{loading ? <CircularProgress /> : data?.netlifyDeploymentStatus?.status}</>
  )
}

export default withStyles(styles, { withTheme: true })(DeploymentStatusPolling)
