import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Grid, Typography, Divider } from '@material-ui/core'
import PersonalizationContainer from './PersonalizationContainer'
import Select from '../../common/Select'
import flagsList from '../TEMPLATE_ASSET/flags.json'
import { MuiSelect } from '../../common/Theme'
import { CirclePicker } from 'react-color'
import { getTextFromLanguage } from '../../../helper/languageHelper'

const useStyles = makeStyles((theme: Theme) => createStyles({}))

function list_placeholder(DesignRoot) {
  try {
    logger.log(
      'list_placeholder',
      DesignRoot,
      DesignRoot.onLoad,
      DesignRoot._LOAD_DATA
    )

    var boundingSET = DesignRoot.fabric_function.get_bounding_by_type('ALL')

    logger.log('boundingSET', boundingSET)

    var placeholderList = []
    for (var i = 0; i < boundingSET.length; ++i) {
      var _BOUNDING_TYPE = boundingSET[i]._BOUNDING_TYPE

      if (_BOUNDING_TYPE !== 'FLAG') {
        var _ZONE_NUMBER = boundingSET[i]._ZONE_NUMBER
        var _ZONE_LIMIT_ITEM = boundingSET[i]._ZONE_LIMIT_ITEM
        var _ZONE_GROUP = boundingSET[i]._ZONE_GROUP
        var _ZONE_ANGLE = boundingSET[i]._ZONE_ANGLE
          ? boundingSET[i]._ZONE_ANGLE
          : 0
        var _ZONE_NAME = boundingSET[i]._ZONE_NAME

        var logoPreview = DesignRoot.fabric_function.get_item_in_placeholder(
          boundingSET[i].id,
          'LOGO',
          'BASE64'
        )
        var currentLogo = DesignRoot.fabric_function.get_item_in_placeholder(
          boundingSET[i].id,
          'LOGO',
          'ITEM'
        )
        var currentText = DesignRoot.fabric_function.get_item_in_placeholder(
          boundingSET[i].id,
          'TEXT',
          'ITEM'
        )

        var placeHolderText = null

        logger.log('PersonalizationContainer', currentLogo, currentText)

        var active = 'logo'
        if (_BOUNDING_TYPE === 'BOTH') {
          if (currentText !== null && currentText.visible === true) {
            active = 'text'
          }
        } else if (_BOUNDING_TYPE === 'TEXT') {
          active = 'text'

          if (currentText !== null && currentText.visible === false) {
            active = null
          }
        } else if (_BOUNDING_TYPE === 'LOGO') {
          if (currentLogo !== null && currentLogo.visible === false) {
            active = null
          }
        }

        try {
          const placeholderData = DesignRoot?.props?.productDesign?.product?.placeholders?.find(
            p => {
              return boundingSET[i].id.indexOf(p.svgId) !== -1 ? true : false
            }
          )
          if (placeholderData) {
            _ZONE_NAME = getTextFromLanguage(
              placeholderData.title.text,
              DesignRoot.props.langCode
            )
            placeHolderText = placeholderData.tempDefaultValue
          }
        } catch (error) {}

        placeholderList.push(
          <PersonalizationContainer
            key={'pp' + i}
            radioActive={active}
            title={_ZONE_NAME}
            inputLabel="Enter text then select a color"
            inputPlaceholder="Enter your text here"
            DesignRoot={DesignRoot}
            boundingData={boundingSET[i]}
            BOUNDING_TYPE={_BOUNDING_TYPE}
            logoPreview={logoPreview}
            currentLogo={currentLogo}
            currentText={currentText}
            placeHolderText={placeHolderText}
          />
        )
      }
    }

    return placeholderList
  } catch (e) {
    logger.error('PersonalizationStep.list_placeholder.error', e)
  }
}

const list_flag = () => {
  try {
    var flagsDom = []
    var flagsOption = []

    flagsOption.push('- Select Flag -')

    for (var i = 0; i < flagsList.length; i++) {
      var flag = '/Flags/' + flagsList[i].imageSrc
      var flagThumb = '/Flags/thumb/' + flagsList[i].imageSrc
      // flagsDom.push(
      //   <Grid item xs={4} key={'flags_' + i}>
      //     <img
      //       src={flagThumb}
      //       style={{ width: '50px', cursor: 'pointer' }}
      //       onClick={this.handleChangeSVGElementAOP.bind(this, flag, null)}
      //     />
      //   </Grid>
      // );

      flagsOption.push(flagsList[i].countryCode)
    }

    return flagsOption
  } catch (e) {
    logger.error('PersonalizationStep.list_flag.error', e)
  }
}

const changeFlagPrint = (value, props) => {
  try {
    logger.log('changeFlagPrint', value)

    const { DesignRoot } = props
    var flagName = value

    if (flagName && flagName === '- Select Flag -') {
      // RESET FLAG

      var allFlagPlaceholder = DesignRoot.fabric_function.get_flag_placeholder()

      if (allFlagPlaceholder.length) {
        logger.log('RESET FLAG AOP')

        for (var i = 0; i < allFlagPlaceholder.length; i++) {
          DesignRoot.change_color.changeColorPickerSelectedSVGELM(
            'RESET',
            allFlagPlaceholder[i]
          )
        }
      } else {
        alert('No flag placeholder exist.')
      }
    } else if (flagName && flagName !== '- Select Flag -') {
      var use_flag_src = null
      for (var i = 0; i < flagsList.length; i++) {
        var flag = '/Flags/' + flagsList[i].imageSrc
        var flagThumb = '/Flags/thumb/' + flagsList[i].imageSrc
        var countryCode = flagsList[i].countryCode

        if (countryCode === flagName) {
          use_flag_src = flag
        }
      } // end for

      if (use_flag_src !== null) {
        // APPLY FLAG TO FLAG PLACEHOLDER

        var allFlagPlaceholder = DesignRoot.fabric_function.get_flag_placeholder()

        if (allFlagPlaceholder.length) {
          logger.log('APPLY FLAG AOP')

          for (var i = 0; i < allFlagPlaceholder.length; i++) {
            DesignRoot.change_color.changeAOPSelectedSVGELM(
              use_flag_src,
              allFlagPlaceholder[i],
              null,
              true,
              null
            )
          }
        } else {
          alert('No flag placeholder exist.')
        }

        // APPLY FLAG TO FLAG PLACEHOLDER
      }
    }
  } catch (e) {
    logger.error('PersonalizationStep.changeFlagPrint.error', e)
  }
}

export default function PersonalizationStep(props) {
  const classes = useStyles()
  const { DesignRoot } = props

  logger.log('PersonalizationStep', props)

  const all_flag = list_flag()

  var colors = []

  if (DesignRoot.FillStep !== null) {
    if (colors.length <= 0) {
      if (DesignRoot.SHOP_ID === 'xceed') {
        colors = [
          '#00A0DA',
          '#009F50',
          '#DD0031',
          '#8E9091',
          '#FFE01E',
          '#FB7FB5',
          '#FFFFFF',
        ]
      } else {
        colors = DesignRoot.FillStep.mapAvailableColor()
      }
    }

    var colorzoneList = null
    if (DesignRoot._ACTIVE_COLOR_ZONE === true) {
      if (
        DesignRoot.defaultZoneColor &&
        DesignRoot.defaultZoneColor.length > 1
      ) {
        colorzoneList = DesignRoot.FillStep.generateFill()
      }
    } else if (
      DesignRoot._BLANK_TEMPLATE_ACTIVE === true ||
      DesignRoot._COLOR_ZONE_SVG_ACTIVE === true
    ) {
      colorzoneList = DesignRoot.FillStep.genSVGList()
    }
  }

  return (
    <div
      style={{
        overflowY: 'auto',
        height: '100%',
        overflowX: 'hidden',
        width: '100%',
      }}
    >
      <Grid
        container={true}
        style={{
          padding: 0,
        }}
        direction={'row'}
        alignItems={'flex-start'}
      >
        <Grid item={true} xs={12} style={{ padding: 24 }}>
          <Typography variant={'h3'} style={{ fontSize: '24px' }}>
            {DesignRoot.productName}
          </Typography>
        </Grid>

        {DesignRoot._SHOW_AOP_FLAG ? (
          <Grid container={true} direction={'column'}>
            <Grid container={true} style={{ padding: 24 }}>
              <Grid item={true} xs={12}>
                <Typography variant={'subtitle1'}>Pick Your Flag</Typography>
              </Grid>
              <Grid
                item={true}
                xs={12}
                style={{ marginTop: 16, zIndex: 100 }}
                data-cy="personalize-step-flag-select"
              >
                <div
                  style={{
                    width: '100%',
                    border: '1px solid #e5e5e5',
                    padding: '0px 2px 0px 8px',
                  }}
                >
                  <MuiSelect
                    placeholder="Choose a flag"
                    options={all_flag}
                    onChange={e => changeFlagPrint(e, props)}
                  />
                </div>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        ) : null}

        {colorzoneList && (
          <Grid container={true} style={{ padding: 24 }}>
            <Grid item={true} xs={12} style={{ marginBottom: 16 }}>
              <Typography variant={'subtitle1'}>Select a color</Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: 0 }}>
              {colorzoneList}
            </Grid>
            <Grid
              item={true}
              xs={12}
              style={{ background: '#f2f2f2', padding: '8px' }}
              data-cy="personalize-step-color-picker"
            >
              <CirclePicker
                colors={colors}
                triangle="hide"
                onChangeComplete={color => {
                  if (DesignRoot.FillStep !== null) {
                    if (DesignRoot._ACTIVE_COLOR_ZONE === true) {
                      DesignRoot.FillStep.handleChangeColor(color)
                    } else {
                      DesignRoot.FillStep.handleChangeSVGElementColor(color)
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        )}

        <Grid item={true} xs={12} data-cy="personalize-step-list">
          {!DesignRoot.onLoad ? list_placeholder(DesignRoot) : null}
        </Grid>
      </Grid>
    </div>
  )
}
