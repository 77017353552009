import React from 'react'
import {
  Button,
  Grid,
  ListItemText,
  createStyles,
  withStyles,
  WithStyles
} from '@material-ui/core'
import {
  Clear,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  Edit,
  Colorize
} from '@material-ui/icons'

import {
  getLinkText,
  getLinkInternalNo
} from '../../../../../helper/menuHelper'

const styles = () =>
  createStyles({
    deleteBtn: {
      margin: '0',
      minWidth: 35,
      padding: '2px'
    },
    item: {
      fontSize: '14px'
    },
    itemFlex: {
      fontSize: '14px',
      flex: '1'
    }
  })

interface Props extends WithStyles<typeof styles> {
  item: any
  showAlignCenter?: boolean
  showAlignLeft?: boolean
  showAlignRight?: boolean
  language: string
  onAlign(id: string, value: string | null): void
  onDelete(id: string): void
  onStylingOpen(item: any): void
  onEditOpen(item: any): void
}

const EditMenuItem: React.FunctionComponent<Props> = props => {
  const {
    classes,
    language,
    item,
    showAlignCenter,
    showAlignLeft,
    showAlignRight,
    onAlign,
    onDelete,
    onStylingOpen,
    onEditOpen
  } = props

  const icons = {
    center: <FormatAlignCenter />,
    left: <FormatAlignLeft />,
    right: <FormatAlignRight />
  }

  const renderButton = (align: string) => (
    <Button
      variant={item.alignment === align ? 'contained' : 'text'}
      onClick={() => onAlign(item.id, !item.alignment ? align : null)}
      className={classes.deleteBtn}
      color="primary"
    >
      {icons[align]}
    </Button>
  )

  return (
    <ListItemText>
      <Grid justify="flex-end" container={true} alignItems="center">
        <Grid item={true} className={classes.itemFlex}>
          <span>
            {getLinkText(item, language, true)} {getLinkInternalNo(item)}
          </span>
        </Grid>
        <Grid item={true} className={classes.item}>
          {showAlignLeft ? renderButton('left') : null}
          {showAlignCenter ? renderButton('center') : null}
          {showAlignRight ? renderButton('right') : null}
        </Grid>
        <Grid item={true} className={classes.item}>
          <Button
            onClick={() => onStylingOpen(item)}
            className={classes.deleteBtn}
          >
            <Colorize />
          </Button>
        </Grid>
        <Grid item={true} className={classes.item}>
          <Button
            onClick={() => onEditOpen(item)}
            className={classes.deleteBtn}
          >
            <Edit />
          </Button>
        </Grid>
        <Grid
          item={true}
          className={classes.item}
          style={{ marginLeft: 8, marginRight: 8 }}
        >
          <Button onClick={() => onDelete(item)} className={classes.deleteBtn}>
            <Clear />
          </Button>
        </Grid>
      </Grid>
    </ListItemText>
  )
}

export default withStyles(styles, { withTheme: true })(EditMenuItem)
