import React from 'react'
import { Button, Grid } from '@material-ui/core'

interface Props {
  onSave(): void
}

const StylingSaveButton: React.FunctionComponent<Props> = (props) => {
  return (
    <Grid style={{ marginBottom: 15 }} container={true} justify="center">
      <Button
        data-cy="Save-Styling-btn"
        variant="contained"
        color="primary"
        onClick={props.onSave}
      >
        Save
      </Button>
    </Grid>
  )
}

export default StylingSaveButton
