import React, { Component } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Input,
  InputLabel
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../../common/Select';
import { Link } from 'gatsby';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_SHOP_PRODUCT_ATTRIBUTES } from '../../gql/admin-queries';
import {
  ADMIN_CREATE_ASSIGNED_PRODUCT_ATTRIBUTE,
  ADMIN_DELETE_ASSIGNED_PRODUCT_ATTRIBUTE
} from '../../gql/admin-mutations';

import { getTextFromLanguage } from '../../../../helper/languageHelper';

class ProductInventory extends Component {
  state = {};
  render() {
    const { product, updateProductDetail, productId } = this.props;
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography data-cy="Product-Inventory-header" variant="subtitle1">
            Inventory
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Paper elevation={0} style={{ padding: 24 }}>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid item>
                <InputLabel>Inventory in stock</InputLabel>
                <Input
                  dataCy="Product-Inventory-Input"
                  label="Inventory in stock"
                  placeholder="0"
                  defaultValue={product.inventory ? product.inventory : 0}
                  onChange={e => {
                    updateProductDetail(
                      productId,
                      'inventory',
                      e.target.value ? parseFloat(e.target.value) : 0
                    );
                  }}
                />
              </Grid>
              <Grid item>
                <InputLabel>SKU</InputLabel>
                <Input
                  dataCy="Product-SKU-Input"
                  label="SKU"
                  placeholder=""
                  defaultValue={product.sku ? product.sku : ''}
                  onChange={e => {
                    updateProductDetail(productId, 'sku', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default ProductInventory;
