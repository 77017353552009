import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Divider, Grid } from '@material-ui/core'
import _ from 'lodash'

import SelectAlignment from './SelectAlignment'
import UpdateText from './UpdateText'
import UpdateButton from './UpdateButton'
import UpdateTypography from './UpdateTypography'
import UpdateInput from './UpdateInput'
import UpdateMedia from './UpdateMedia'
import StylingSaveButton from './StylingSaveButton'
import client from '../../gql/admin-apollo-config'
import { CONTENT_UPDATE_COLUMN_ROW } from '../../gql/admin-mutations'
import { ADMIN_GET_CONTENT_THEME } from '../../gql/admin-queries'
import {
  remapAlignmentForData,
  cleanTextInputField,
  normalizeTextData,
  saveDataOnLeave,
  addPercent,
  addPx
} from './utils'
import UpdateImage from './UpdateImage'
import UpdateSocialMedia from './UpdateSocialMedia'
import UpdateCard from './UpdateCard'
import UpdateDivider from './UpdateDivider'
import UpdateTeamstoreCard from './UpdateTeamstoreCard'
import UpdateSubscribeCard from './UpdateSubscribeCard'
import UpdateLocationCard from './UpdateLocationCard'
import UpdateContactCard from './UpdateContactCard'
import UpdateBanner from './UpdateBanner'
import UpdateDiscoverCard from './UpdateDiscoverCard'
import UpdateHubspotForm from './UpdateHubspotForm'
import UpdateRequestForm from './UpdateRequestForm'
import { CONFIG, TYPO_VARIANTS } from './config'

const styles = theme => ({})

class StylingColumnRow extends React.Component {
  constructor(props) {
    super(props)

    const { selectedColRow } = this.props
    this.state = {
      fields: this.getInitialFieldsValues(selectedColRow),
      expanded: null,
      colorPicker: false,
      localUnsaved: null,
      themeData: null
    }
  }

  componentWillUnmount() {
    const { selectedColRow } = this.props

    if (this.state.localUnsaved) {
      const fields = this.createFieldsForSave(this.state.localUnsaved)

      const editing = saveDataOnLeave(
        {
          ...fields,
          alignment: remapAlignmentForData(this.state.fields.alignment)
        },
        selectedColRow
      )

      if (!editing) {
        return false
      }

      if (
        window.confirm(
          `Warning: all unsaved changes will be lost. Would you like to save them now?`
        )
      ) {
        this.handleSave()
      } else {
        this.setState({
          localUnsaved: null
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedColRow } = this.props

    if (
      prevProps.selectedColRow.id === selectedColRow.id &&
      prevProps.selectedColRow.counter === selectedColRow.counter &&
      prevProps.selectedColRow.typoVariant === selectedColRow.typoVariant
    ) {
      return
    }
    this.setState({
      fields: this.getInitialFieldsValues(selectedColRow)
    })
  }

  UNSAFE_componentWillUpdate(prevProps, prevState) {
    const { selectedColRow } = this.props

    if (prevProps.selectedColRow.id === selectedColRow.id) {
      return
    }

    if (this.state.localUnsaved) {
      const fields = this.createFieldsForSave(
        _.cloneDeep(this.state.localUnsaved)
      )

      const editing = saveDataOnLeave(
        {
          ...fields,
          alignment: remapAlignmentForData(this.state.fields.alignment)
        },
        selectedColRow
      )

      if (!editing) {
        this.setState({
          localUnsaved: null
        })
        return false
      }

      if (
        window.confirm(
          `Warning: all unsaved changes will be lost. Would you like to save them now?`
        )
      ) {
        this.handleSave()
      } else {
        this.setState({
          localUnsaved: null
        })
      }
    }
  }

  getInitialFieldsValues = selectedColRow => {
    if (selectedColRow.type === 'BUTTON') {
      let splitPadding = selectedColRow.padding
        ? selectedColRow.padding.split(' ')
        : null
      let splitBorder = selectedColRow.border
        ? selectedColRow.border.split(' ')
        : '1px solid #fff'

      return {
        alignment: selectedColRow.alignment || 'center',
        padding1: parseInt(splitPadding[0]) || 0,
        padding2: parseInt(splitPadding[1]) || 0,
        text: selectedColRow.text,
        fontFamily: `'${selectedColRow.fontFamily}', 'sans-serif'`,
        fontSize: parseInt(selectedColRow.fontSize) || 25,
        color: selectedColRow.color || null,
        backgroundColor: selectedColRow.backgroundColor || null,
        border: selectedColRow.border || null,
        borderThickness: parseInt(splitBorder[0]) || 0,
        borderType: splitBorder[1],
        borderColor: splitBorder[2],
        borderRadius: parseInt(selectedColRow.borderRadius) || 7,
        url: selectedColRow.url || null,
        buttonLinkType: selectedColRow.buttonLinkType
      }
    }

    if (selectedColRow.type === 'DISCOVER_CARD') {
      return {
        subTitle: selectedColRow.subTitle,
        title: selectedColRow.title,
        buttonText: selectedColRow.buttonText,
        buttonUrl: selectedColRow.buttonUrl || null
      }
    }

    if (selectedColRow.type === 'TEXT') {
      let newFontFamily = selectedColRow.fontFamily.indexOf(',')
        ? selectedColRow.fontFamily.split(',')[0]
        : null

      return {
        alignment: selectedColRow.alignment || 'center',
        text: selectedColRow.text
          ? normalizeTextData(selectedColRow.text, selectedColRow.typoVariant)
          : null,
        typoVariant: selectedColRow.typoVariant,
        color: selectedColRow.color || '#000000',
        fontSize: selectedColRow.fontSize || null,
        fontFamily: newFontFamily,
        fontWeight: selectedColRow.fontWeight || null,
        textTransform: selectedColRow.textTransform || null,
        letterSpacing: selectedColRow.letterSpacing || null
      }
    }

    if (selectedColRow.type === 'BANNER') {
      let splitBannerButtonBorder = selectedColRow.buttonBorder
        ? selectedColRow.buttonBorder.split(' ')
        : '1px solid #fff'
      let splitButtonPadding = selectedColRow.buttonPadding
        ? selectedColRow.buttonPadding.split(' ')
        : null
      let splitContainerPadding = selectedColRow.containerPadding
        ? selectedColRow.containerPadding.split(' ')
        : null

      return {
        buttonFontSize: parseInt(selectedColRow.buttonFontSize) || 0,
        titleFontSize: parseInt(selectedColRow.titleFontSize) || 0,
        buttonBorder: selectedColRow.buttonBorder || null,
        buttonPadding1: splitButtonPadding
          ? parseInt(splitButtonPadding[0])
          : 0,
        buttonPadding2: splitButtonPadding
          ? parseInt(splitButtonPadding[1])
          : 0,
        containerPadding1: splitContainerPadding
          ? parseInt(splitContainerPadding[0])
          : 0,
        containerPadding2: splitContainerPadding
          ? parseInt(splitContainerPadding[1])
          : 0,
        buttonBorderRadius: selectedColRow.buttonBorderRadius || null,
        objectFit: selectedColRow.objectFit || null,
        verticalAlign: selectedColRow.verticalAlign || null,
        horizontalAlign: selectedColRow.horizontalAlign || null,
        subTitle: selectedColRow.subTitle,
        title: selectedColRow.title,
        buttonText: selectedColRow.buttonText,
        titleFontFamily: selectedColRow.titleFontFamily || null,
        titleColor: selectedColRow.titleColor || null,
        titleBackgroundColor: selectedColRow.titleBackgroundColor || null,
        subTitleColor: selectedColRow.subTitleColor || null,
        subTitleFontFamily: selectedColRow.subTitleFontFamily || null,
        subTitleFontSize: selectedColRow.subTitleFontSize || null,
        buttonFontFamily: selectedColRow.buttonFontFamily || null,
        height: parseInt(selectedColRow.height) || 0,
        buttonFontColor: selectedColRow.buttonFontColor || null,
        buttonBackgroundColor: selectedColRow.buttonBackgroundColor || null,
        buttonBorder: selectedColRow.buttonBorder || null,
        borderThickness: parseInt(splitBannerButtonBorder[0]) || 0,
        borderType: splitBannerButtonBorder[1],
        borderColor: splitBannerButtonBorder[2],
        copyrightFontColor: selectedColRow.copyrightFontColor || null,
        copyrightFontFamily: selectedColRow.copyrightFontFamily || null,
        copyrightHorizontalAlignment: selectedColRow.copyrightHorizontalAlignment
          ? selectedColRow.copyrightHorizontalAlignment.toLowerCase()
          : null,
        copyrightVerticalAlignment: selectedColRow.copyrightVerticalAlignment
          ? selectedColRow.copyrightVerticalAlignment.toLowerCase()
          : null,
        copyrightContent: selectedColRow.copyrightContent || null,
        imageUrl: selectedColRow.imageUrl || null,
        urlType: selectedColRow.urlType || null,
        buttonUrl: !selectedColRow.linkBannerDesign
          ? selectedColRow.buttonUrl
          : '/en/design-your-own',
        textAlign: selectedColRow.textAlign || null,
        linkBannerDesign: selectedColRow.linkBannerDesign || false,
        collectionId: selectedColRow.collectionId || null,
        selectedCollectionId: selectedColRow.collectionId || null,
        selectedPageId: selectedColRow.selectedPageId || null,
        productId: selectedColRow.productId || null,
        teamstoreId: selectedColRow.teamstoreId || null,
        selectedTeamstoreId: selectedColRow.teamstoreId || null,
        productPackageId: selectedColRow.productPackageId || null,
        selectedProductPackageId: selectedColRow.productPackageId || null
      }
    }

    if (selectedColRow.type === 'DIVIDER') {
      let splitPaddingDiv = selectedColRow.padding.split(' ')
      let splitPaddingText = selectedColRow.textPadding.split(' ')
      let splitBorder = selectedColRow.border.split(' ')

      return {
        paddingDiv1: parseInt(splitPaddingDiv[0]) || 0,
        paddingDiv2: parseInt(splitPaddingDiv[1]) || 0,
        paddingText1: parseInt(splitPaddingText[0]) || 0,
        paddingText2: parseInt(splitPaddingText[1]) || 0,
        text: selectedColRow.text,
        font: `'${selectedColRow.font}', 'sans-serif'` || null,
        fontSize: parseInt(selectedColRow.fontSize) || 25,
        color: selectedColRow.color || null,
        textBackground: selectedColRow.textBackground || null,
        textAlign: selectedColRow.textAlign || null,
        textColor: selectedColRow.textColor || null,
        fontFamily: selectedColRow.fontFamily || null,
        margin: parseInt(selectedColRow.margin) || 0,
        border: selectedColRow.border || null,
        width: parseInt(selectedColRow.width) || 0,
        borderThickness: parseInt(splitBorder[0]) || 1,
        borderType: splitBorder[1],
        borderColor: splitBorder[2]
      }
    }
    if (selectedColRow.type === 'HUBSPOT_FORM') {
      return {
        portalId: selectedColRow.portalId || null,
        formId: selectedColRow.formId || null,
        cssClass: selectedColRow.cssClass || null,
        target: selectedColRow.target || null
      }
    }
    if (selectedColRow.type === 'REQUEST_QUOTE_FORM') {
      return {}
    }
    if (selectedColRow.type === 'CARD') {
      let cardBorder =
        selectedColRow.border !== null ? selectedColRow.border.split(' ') : null
      let cardButtonBorder =
        selectedColRow.buttonBorder !== null
          ? selectedColRow.buttonBorder.split(' ')
          : null

      return {
        width: parseInt(selectedColRow.width) || 0,
        height: parseInt(selectedColRow.height) || 0,
        thumbnailImg: selectedColRow.thumbnailImg || null,
        title: selectedColRow.title,
        description: selectedColRow.description || null,
        callToAction: selectedColRow.callToAction,
        border: selectedColRow.border || null,
        borderThickness: parseInt(cardBorder[0]) || 0,
        borderType: cardBorder[1],
        borderColor: cardBorder[2],
        borderRadius: parseInt(selectedColRow.borderRadius) || 0,
        shadow: selectedColRow.shadow || null,
        backgroundColor: selectedColRow.backgroundColor || null,
        link: selectedColRow.link || null,
        buttonFontSize: parseInt(selectedColRow.buttonFontSize) || 0,
        titleFontSize: parseInt(selectedColRow.titleFontSize) || 0,
        descriptionFontSize: parseInt(selectedColRow.descriptionFontSize) || 0,
        buttonTextColor: selectedColRow.buttonTextColor,
        titleColor: selectedColRow.titleColor,
        titleBackgroundColor: selectedColRow.titleBackgroundColor,
        descriptionColor: selectedColRow.descriptionColor,
        buttonBorder: selectedColRow.buttonBorder || null,
        buttonBorderThickness: parseInt(cardButtonBorder[0]) || 0,
        buttonBorderType: cardButtonBorder[1],
        buttonBorderColor: cardButtonBorder[2],
        buttonAlign: selectedColRow.buttonAlign,
        titleAlign: selectedColRow.titleAlign,
        descriptionAlign: selectedColRow.descriptionAlign,
        selectedPageId: selectedColRow.selectedPageId || null,
        collectionId: selectedColRow.collectionId || null,
        productId: selectedColRow.productId || null,
        teamstoreId: selectedColRow.teamstoreId || null,
        productPackageId: selectedColRow.productPackageId || null
      }
    }

    return {
      label: selectedColRow.label,
      placeholder: selectedColRow.placeholder,
      inputType: selectedColRow.inputType || null,
      disabled: selectedColRow.disabled,
      required: selectedColRow.required,
      buttonText: selectedColRow.buttonText,
      text: selectedColRow.text
        ? normalizeTextData(selectedColRow.text, selectedColRow.typoVariant)
        : null,
      typoVariant: selectedColRow.typoVariant,
      color: selectedColRow.color || null,
      fontSize: parseInt(selectedColRow.fontSize) || 45,
      fontFamily: `'${selectedColRow.fontFamily}', 'sans-serif'` || null,
      fontWeight: parseInt(selectedColRow.fontWeight) || 100,
      alignment: selectedColRow.alignment || 'center',
      imageUrl: selectedColRow.imageUrl || null,
      width: parseInt(selectedColRow.width) || 0,
      height: parseInt(selectedColRow.height) || 0,
      backgroundColor: selectedColRow.backgroundColor || null,
      url: selectedColRow.url || null,
      socialMediaType: selectedColRow.socialMediaType || null,
      maxWidth: parseInt(selectedColRow.maxWidth) || 0,
      maxHeight: parseInt(selectedColRow.maxHeight) || 0,
      username: selectedColRow.username || null,
      thumbnailImg: selectedColRow.thumbnailImg || null,
      title: selectedColRow.title,
      description: selectedColRow.description || null,
      callToAction: selectedColRow.callToAction,
      font: selectedColRow.font || null,
      padding: selectedColRow.padding || null,
      border: selectedColRow.border || null,
      borderRadius: parseInt(selectedColRow.borderRadius) || 7,
      shadow: selectedColRow.shadow || null,
      link: selectedColRow.link || null,
      textAlign: selectedColRow.textAlign || null,
      textPadding: selectedColRow.textPadding || null,
      textBackground: selectedColRow.textBackground || null,
      textColor: selectedColRow.textColor || null,
      margin: selectedColRow.margin || null,
      buttonFontSize: parseInt(selectedColRow.buttonFontSize) || 0,
      titleFontSize: parseInt(selectedColRow.titleFontSize) || 0,
      descriptionFontSize: parseInt(selectedColRow.descriptionFontSize) || 0,
      cardsToShow: selectedColRow.cardsToShow,
      content: selectedColRow.content,
      hasTerms: selectedColRow.hasTerms,
      shopName: selectedColRow.shopName,
      email: selectedColRow.email,
      address: selectedColRow.address,
      website: selectedColRow.website,
      phone: selectedColRow.phone,
      openDays: selectedColRow.openDays,
      openHours: selectedColRow.openHours,
      closedDays: selectedColRow.closedDays,
      dealershipLink: selectedColRow.dealershipLink,
      hasPremiumDealership: selectedColRow.hasPremiumDealership,
      nameLabel: selectedColRow.nameLabel,
      namePlaceholder: selectedColRow.namePlaceholder,
      emailLabel: selectedColRow.emailLabel,
      emailPlaceholder: selectedColRow.emailPlaceholder,
      contentLabel: selectedColRow.contentLabel,
      contentPlaceholder: selectedColRow.contentPlaceholder,
      imageAlt: selectedColRow.imageAlt,
      copyrightFontColor: selectedColRow.copyrightFontColor || null,
      copyrightFontFamily: selectedColRow.copyrightFontFamily || null,
      copyrightHorizontalAlignment: selectedColRow.copyrightHorizontalAlignment
        ? selectedColRow.copyrightHorizontalAlignment.toLowerCase()
        : null,
      copyrightVerticalAlignment: selectedColRow.copyrightVerticalAlignment
        ? selectedColRow.copyrightVerticalAlignment.toLowerCase()
        : null,
      copyrightContent: selectedColRow.copyrightContent || null,
      textTransform: selectedColRow.textTransform || null,
      letterSpacing: parseInt(selectedColRow.letterSpacing) || 0
    }
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  async UNSAFE_componentWillMount() {
    const theme = await client.query({
      query: ADMIN_GET_CONTENT_THEME,
      variables: {
        id: this.props.shopId
      }
    })

    if (
      theme.data.shop.contentSettings &&
      theme.data.shop.contentSettings.theme
    ) {
      this.setState({ themeData: theme.data.shop.contentSettings.theme })
    }
  }

  handleChangeField = (value, field) => {
    const { Template } = this.props
    const fields = this.state.fields

    if (
      field === 'typoVariant' &&
      this.state.themeData &&
      this.state.themeData[value]
    ) {
      const typoVariant = this.state.themeData[value].style

      Template.setState({
        selectedColRow: {
          ...Template.state.selectedColRow,
          fontSize: typoVariant.fontSize ? addPx(typoVariant.fontSize) : '45px',
          fontFamily: typoVariant.fontFamily
            ? typoVariant.fontFamily
            : 'Roboto',
          fontWeight: typoVariant.fontWeight ? typoVariant.fontWeight : 300,
          letterSpacing: typoVariant.letterSpacing
            ? typoVariant.letterSpacing
            : '0px',
          textTransform: typoVariant.textTransform
            ? typoVariant.textTransform
            : 'none',
          typoVariant: value
        }
      })
    }

    fields[field] = value
    this.setState({ fields: fields, localUnsaved: fields })
    this.props.onChangeUnsavedData(fields)
  }

  handleChangeTextField = (value, language, fieldName) => {
    const fields = this.state.fields
    const t = fields[fieldName]
    t.text = fields[fieldName].text.map(text => {
      return {
        ...text,
        content: language === text.langCode ? value : text.content
      }
    })
    if (!fields[fieldName].text.find(e => e.langCode === language)) {
      t.text.push({
        content: value,
        langCode: language
      })
    }
    this.handleChangeField(t, fieldName)
  }

  createFieldsForSave = data => {
    const { selectedColRow } = this.props

    const colRow = _.cloneDeep(selectedColRow)

    let fields = {}

    if (selectedColRow.type === 'INPUT') {
      colRow.label = cleanTextInputField(data.label)
      colRow.placeholder = cleanTextInputField(data.placeholder)

      fields = {
        disabled: data.disabled,
        inputType: data.inputType,
        label: colRow.label,
        placeholder: colRow.placeholder,
        required: data.required
      }
    } else if (selectedColRow.type === 'TEXT') {
      colRow.text = cleanTextInputField(data.text)
      let verticalAlign = ''

      if (data.verticalAlign && data.verticalAlign.indexOf('-')) {
        verticalAlign = data.verticalAlign.replace('-', '_')
      } else {
        verticalAlign = data.verticalAlign
      }

      fields = {
        color: data.color,
        text: colRow.text,
        typoVariant: data.typoVariant,
        fontSize: addPx(data.fontSize),
        fontFamily: `'${data.fontFamily}', 'sans-serif'`,
        fontWeight: data.fontWeight?.toString(),
        verticalAlign: verticalAlign,
        textTransform: data.textTransform,
        letterSpacing: addPx(data.letterSpacing)
      }
    } else if (selectedColRow.type === 'IMAGE') {
      fields = {
        imageUrl: data.imageUrl,
        width: addPercent(data.width),
        height: addPx(data.height),
        objectFit: data.objectFit,
        maxWidth:
          data.maxWidth === 0
            ? addPercent(data.maxWidth)
            : addPx(data.maxWidth),
        maxHeight: addPx(data.maxHeight),
        imageAlt: data.imageAlt,
        copyrightVerticalAlignment: data.copyrightVerticalAlignment
          ? data.copyrightVerticalAlignment.toUpperCase()
          : null,
        copyrightHorizontalAlignment: data.copyrightHorizontalAlignment
          ? data.copyrightHorizontalAlignment.toUpperCase()
          : null,
        copyrightFontFamily: data.copyrightFontFamily,
        copyrightFontColor: data.copyrightFontColor,
        copyrightContent: data.copyrightContent
      }
    } else if (selectedColRow.type === 'BUTTON') {
      colRow.text = cleanTextInputField(data.text)

      fields = {
        text: colRow.text,
        color: data.color,
        backgroundColor: data.backgroundColor,
        fontFamily: `'${data.fontFamily}', 'sans-serif'`,
        fontSize: addPx(data.fontSize),
        padding:
          addPx(data.padding1) +
          ' ' +
          addPx(data.padding2) +
          ' ' +
          addPx(data.padding1) +
          ' ' +
          addPx(data.padding2),
        border:
          addPx(data.borderThickness) +
          ' ' +
          data.borderType +
          ' ' +
          data.borderColor,
        borderRadius: addPx(data.borderRadius),
        url: data.url,
        buttonLinkType: data.buttonLinkType
      }
    } else if (selectedColRow.type === 'MEDIA') {
      console.log(data.url.split('/'))
      let videoLink =
        data.url && data.url.indexOf('watch') !== -1
          ? data.url.split('=')[1]
          : data.url.indexOf('embed') !== -1
          ? data.url.split('/')[4]
          : null
      console.log(videoLink)

      fields = {
        url: `https://www.youtube.com/embed/${videoLink}`,
        width: data.width === '0' ? null : addPx(data.width),
        height: data.height === '0' ? null : addPx(data.height)
      }
    } else if (selectedColRow.type === 'SOCIAL_MEDIA') {
      fields = {
        socialMediaType: data.socialMediaType,
        username: data.username
      }
    } else if (selectedColRow.type === 'HUBSPOT_FORM') {
      fields = {
        portalId: data.portalId,
        formId: data.formId,
        cssClass: data.cssClass,
        target: data.target
      }
    } else if (selectedColRow.type === 'REQUEST_QUOTE_FORM') {
      fields = {}
    } else if (selectedColRow.type === 'CARD') {
      colRow.description = cleanTextInputField(data.description)
      colRow.title = cleanTextInputField(data.title)
      colRow.callToAction = cleanTextInputField(data.callToAction)

      let imgUrl = ''
      let fileName

      if (data.thumbnailImg) {
        imgUrl = data.thumbnailImg.split('/')
        fileName = imgUrl.pop()
      } else {
        fileName = null
      }

      fields = {
        width: addPercent(data.width),
        height: addPx(data.height),
        thumbnailImg: fileName,
        title: colRow.title,
        description: colRow.description,
        callToAction: colRow.callToAction,
        border:
          addPx(data.borderThickness) +
          ' ' +
          data.borderType +
          ' ' +
          data.borderColor,
        borderRadius: addPx(data.borderRadius),
        shadow: data.shadow,
        backgroundColor: data.backgroundColor,
        link: data.link,
        buttonFontSize: addPx(data.buttonFontSize),
        titleFontSize: addPx(data.titleFontSize),
        descriptionFontSize: addPx(data.descriptionFontSize),
        buttonTextColor: data.buttonTextColor,
        descriptionColor: data.descriptionColor,
        titleColor: data.titleColor,
        titleBackgroundColor: data.titleBackgroundColor,
        buttonBorder:
          addPx(data.buttonBorderThickness) +
          ' ' +
          data.buttonBorderType +
          ' ' +
          data.buttonBorderColor,
        buttonAlign: data.buttonAlign,
        titleAlign: data.titleAlign,
        descriptionAlign: data.descriptionAlign,
        pageId: data.pageId,
        selectedPageId: data.selectedPageId,
        collectionId: data.collectionId,
        productId: data.productId,
        teamstoreId: data.teamstoreId,
        productPackageId: data.productPackageId,
        urlType: data.urlType
      }
    } else if (selectedColRow.type === 'BANNER') {
      colRow.subTitle = cleanTextInputField(data.subTitle)
      colRow.title = cleanTextInputField(data.title)
      colRow.buttonText = cleanTextInputField(data.buttonText)

      let imgUrl = ''
      let fileName
      let horizontalAlign = ''

      if (data.imageUrl) {
        imgUrl = data.imageUrl.split('/')
        fileName = imgUrl.pop()
      } else {
        fileName = null
      }

      if (data.horizontalAlign && data.horizontalAlign.indexOf('-')) {
        horizontalAlign = data.horizontalAlign.replace('-', '_')
      } else {
        horizontalAlign = data.horizontalAlign
      }

      fields = {
        imageUrl: fileName,
        title: colRow.title,
        subTitle: colRow.subTitle,
        buttonText: colRow.buttonText,
        buttonBorder:
          addPx(data.borderThickness) +
          ' ' +
          data.borderType +
          ' ' +
          data.borderColor,
        buttonBorderRadius: addPx(data.buttonBorderRadius),
        objectFit: data.objectFit,
        buttonBackgroundColor: data.buttonBackgroundColor,
        buttonFontSize: addPx(data.buttonFontSize),
        titleFontSize: addPx(data.titleFontSize),
        subTitleFontSize: addPx(data.subTitleFontSize),
        buttonTextColor: data.buttonTextColor,
        subTitleColor: data.subTitleColor,
        titleColor: data.titleColor,
        titleBackgroundColor: data.titleBackgroundColor,
        titleFontFamily: `'${data.titleFontFamily}', 'sans-serif'`,
        subTitleFontFamily: `'${data.subTitleFontFamily}', 'sans-serif'`,
        buttonFontFamily: `'${data.buttonFontFamily}', 'sans-serif'`,
        copyrightVerticalAlignment: data.copyrightVerticalAlignment
          ? data.copyrightVerticalAlignment.toUpperCase()
          : null,
        copyrightHorizontalAlignment: data.copyrightHorizontalAlignment
          ? data.copyrightHorizontalAlignment.toUpperCase()
          : null,
        copyrightFontFamily: data.copyrightFontFamily,
        copyrightFontColor: data.copyrightFontColor,
        copyrightContent: data.copyrightContent,
        horizontalAlign: horizontalAlign,
        verticalAlign: data.verticalAlign,
        height: addPx(data.height),
        buttonUrl: data.buttonUrl,
        urlType: data.urlType,
        textAlign: data.textAlign,
        selectedPageId: data.selectedPageId,
        collectionId: data.collectionId,
        productId: data.productId,
        teamstoreId: data.teamstoreId,
        productPackageId: data.productPackageId,
        buttonPadding:
          addPx(data.buttonPadding1) +
          ' ' +
          addPx(data.buttonPadding2) +
          ' ' +
          addPx(data.buttonPadding1) +
          ' ' +
          addPx(data.buttonPadding2),
        containerPadding:
          addPx(data.containerPadding1) +
          ' ' +
          addPx(data.containerPadding2) +
          ' ' +
          addPx(data.containerPadding1) +
          ' ' +
          addPx(data.containerPadding2)
      }
    } else if (selectedColRow.type === 'DISCOVER_CARD') {
      colRow.subTitle = cleanTextInputField(data.subTitle)
      colRow.title = cleanTextInputField(data.title)
      colRow.buttonText = cleanTextInputField(data.buttonText)

      fields = {
        title: colRow.title,
        subTitle: colRow.subTitle,
        buttonText: colRow.buttonText,
        buttonUrl: data.buttonUrl
      }
    } else if (selectedColRow.type === 'COLLECTION_CARD') {
      colRow.buttonText = cleanTextInputField(data.buttonText)

      fields = {
        buttonText: colRow.buttonText
      }
    } else if (selectedColRow.type === 'PRODUCT_CARD') {
      colRow.buttonText = cleanTextInputField(data.buttonText)

      fields = {
        buttonText: colRow.buttonText
      }
    } else if (selectedColRow.type === 'SUBSCRIBE_CARD') {
      colRow.content = cleanTextInputField(data.content)
      colRow.title = cleanTextInputField(data.title)
      colRow.callToAction = cleanTextInputField(data.callToAction)
      colRow.placeholder = cleanTextInputField(data.placeholder)
      colRow.label = cleanTextInputField(data.label)

      fields = {
        width: addPercent(data.width),
        height: addPx(data.height),
        title: colRow.title,
        content: colRow.content,
        callToAction: colRow.callToAction,
        hasTerms: data.hasTerms,
        placeholder: colRow.placeholder,
        label: colRow.label
      }
    } else if (selectedColRow.type === 'LOCATION_CARD') {
      fields = {
        url: data.url,
        hasPremiumDealership: data.hasPremiumDealership,
        shopName: data.shopName,
        address: data.address,
        email: data.email,
        phone: data.phone,
        website: data.website,
        openDays: data.openDays,
        openHours: data.openHours,
        closedDays: data.closedDays,
        dealershipLink: data.dealershipLink
      }
    } else if (selectedColRow.type === 'CONTACT_CARD') {
      colRow.title = cleanTextInputField(data.title)
      colRow.contentLabel = cleanTextInputField(data.contentLabel)
      colRow.contentPlaceholder = cleanTextInputField(data.contentPlaceholder)
      colRow.nameLabel = cleanTextInputField(data.nameLabel)
      colRow.namePlaceholder = cleanTextInputField(data.namePlaceholder)
      colRow.emailLabel = cleanTextInputField(data.emailLabel)
      colRow.emailPlaceholder = cleanTextInputField(data.emailPlaceholder)

      fields = {
        title: colRow.title,
        hasTerms: data.hasTerms,
        nameLabel: colRow.nameLabel,
        namePlaceholder: colRow.namePlaceholder,
        emailLabel: colRow.emailLabel,
        emailPlaceholder: colRow.emailPlaceholder,
        contentLabel: colRow.contentLabel,
        contentPlaceholder: colRow.contentPlaceholder
      }
    } else if (selectedColRow.type === 'DIVIDER') {
      colRow.text = cleanTextInputField(data.text)

      fields = {
        border:
          addPx(data.borderThickness) +
          ' ' +
          data.borderType +
          ' ' +
          data.borderColor,
        fontFamily: `'${data.fontFamily}', 'sans-serif'`,
        fontSize: addPx(data.fontSize),
        margin: addPx(data.margin),
        padding:
          addPx(data.paddingDiv1) +
          ' ' +
          addPx(data.paddingDiv2) +
          ' ' +
          addPx(data.paddingDiv1) +
          ' ' +
          addPx(data.paddingDiv2),
        text: colRow.text,
        textAlign: data.textAlign,
        textBackground: data.textBackground,
        textColor: data.textColor,
        textPadding:
          addPx(data.paddingText1) +
          ' ' +
          addPx(data.paddingText2) +
          ' ' +
          addPx(data.paddingText1) +
          ' ' +
          addPx(data.paddingText2),
        width: addPercent(data.width)
      }
    } else if (selectedColRow.type === 'TEAMSTORE_CARD') {
      fields = {
        cardsToShow: data.cardsToShow
      }
    }
    return fields
  }

  handleSave = async () => {
    const { pageId, selectedColRow } = this.props

    let fields = this.createFieldsForSave(this.state.fields)

    fields = {
      ...fields,
      alignment: remapAlignmentForData(this.state.fields.alignment)
    }

    for (const key in fields) {
      if (fields[key] === '') {
        fields[key] = null
      }
    }

    await client.mutate({
      mutation: CONTENT_UPDATE_COLUMN_ROW,
      variables: {
        id: selectedColRow.id,
        input: {
          alignment: remapAlignmentForData(this.state.fields.alignment),
          columnRowDetail: {
            ...fields,
            id: selectedColRow.id
          }
        },
        pageId: pageId
      }
    })

    this.setState({
      localUnsaved: null
    })
  }

  render() {
    const { selectedColRow, Template, shopId, languages } = this.props
    const { expanded, colorPicker } = this.state

    return (
      <Fragment>
        <Divider />
        {selectedColRow.type !== 'BANNER' ? (
          <Grid container style={{ padding: '16px 24px' }}>
            <SelectAlignment
              fields={this.state.fields}
              languages={languages}
              handleChangeField={this.handleChangeField}
            />
          </Grid>
        ) : null}
        <Divider />

        {selectedColRow.type === 'TEXT' ? (
          <Fragment>
            <UpdateTypography
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              fields={this.state.fields}
              languages={languages}
              colorPicker={colorPicker}
              themeData={this.state.themeData}
            />
            {CONFIG.showRichTextForVariants.indexOf(
              this.state.fields.typoVariant
            ) === -1 ? (
              <Fragment>
                <Divider />
                <UpdateText
                  fields={this.state.fields}
                  languages={languages}
                  handleChangeTextField={this.handleChangeTextField}
                  handleChangeField={this.handleChangeField}
                />
              </Fragment>
            ) : null}
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'COLLECTION_CARD' ? (
          <Fragment>
            <UpdateText
              fields={this.state.fields}
              languages={languages}
              handleChangeTextField={this.handleChangeTextField}
              handleChangeField={this.handleChangeField}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'PRODUCT_CARD' ? (
          <Fragment>
            <UpdateText
              fields={this.state.fields}
              languages={languages}
              handleChangeTextField={this.handleChangeTextField}
              handleChangeField={this.handleChangeField}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'INPUT' ? (
          <Fragment>
            <UpdateInput
              fields={this.state.fields}
              languages={languages}
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeTextField={this.handleChangeTextField}
              handleChangeField={this.handleChangeField}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'BUTTON' ? (
          <Fragment>
            <UpdateButton
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              fields={this.state.fields}
              languages={languages}
              handleChangeTextField={this.handleChangeTextField}
              Template={Template}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'MEDIA' ? (
          <Fragment>
            <UpdateMedia
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              fields={this.state.fields}
              languages={languages}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'DISCOVER_CARD' ? (
          <Fragment>
            <UpdateDiscoverCard
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              handleChangeTextField={this.handleChangeTextField}
              fields={this.state.fields}
              languages={languages}
              Template={Template}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'IMAGE' ? (
          <Fragment>
            <UpdateImage
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              fields={this.state.fields}
              languages={languages}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'CARD' ? (
          <Fragment>
            <UpdateCard
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeTextField={this.handleChangeTextField}
              handleChangeField={this.handleChangeField}
              fields={this.state.fields}
              languages={languages}
              Template={Template}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'HUBSPOT_FORM' ? (
          <Fragment>
            <UpdateHubspotForm
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeTextField={this.handleChangeTextField}
              handleChangeField={this.handleChangeField}
              fields={this.state.fields}
              languages={languages}
              Template={Template}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'REQUEST_QUOTE_FORM' ? (
          <Fragment>
            <UpdateRequestForm
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeTextField={this.handleChangeTextField}
              handleChangeField={this.handleChangeField}
              fields={this.state.fields}
              languages={languages}
              Template={Template}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'SOCIAL_MEDIA' ? (
          <Fragment>
            <UpdateSocialMedia
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              fields={this.state.fields}
              languages={languages}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'DIVIDER' ? (
          <Fragment>
            <UpdateDivider
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              handleChangeTextField={this.handleChangeTextField}
              fields={this.state.fields}
              languages={languages}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'TEAMSTORE_CARD' ? (
          <Fragment>
            <UpdateTeamstoreCard
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              handleChangeTextField={this.handleChangeTextField}
              fields={this.state.fields}
              languages={languages}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'SUBSCRIBE_CARD' ? (
          <Fragment>
            <UpdateSubscribeCard
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              handleChangeTextField={this.handleChangeTextField}
              fields={this.state.fields}
              languages={languages}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'LOCATION_CARD' ? (
          <Fragment>
            <UpdateLocationCard
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              handleChangeTextField={this.handleChangeTextField}
              fields={this.state.fields}
              languages={languages}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'CONTACT_CARD' ? (
          <Fragment>
            <UpdateContactCard
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              handleChangeTextField={this.handleChangeTextField}
              fields={this.state.fields}
              languages={languages}
            />
            <Divider />
          </Fragment>
        ) : null}

        {selectedColRow.type === 'BANNER' ? (
          <Fragment>
            <UpdateBanner
              expanded={expanded}
              shopId={shopId}
              handleExpandedChange={this.handleChange}
              handleChangeField={this.handleChangeField}
              handleChangeTextField={this.handleChangeTextField}
              fields={this.state.fields}
              languages={languages}
              Template={Template}
            />
            <Divider />
          </Fragment>
        ) : null}

        <br />

        <StylingSaveButton onSave={this.handleSave} />
      </Fragment>
    )
  }
}

StylingColumnRow.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(StylingColumnRow)
