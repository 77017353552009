import swal from 'sweetalert'
import axios from 'axios'
import apolloClient from '../../gql/apollo-config'
import clientAdmin from '../admin/gql/admin-apollo-config'
import _cloneDeep from 'lodash/cloneDeep'
import { save } from './tools/Functions/save_load'
import * as tools from './tools/tools'
import GLTFExporter from 'three-gltf-exporter'

// Module queries
import { ApolloConsumer } from 'react-apollo'
import { UPLOAD_FILE_MUTATION } from './graphql/mutations'
import { DESIGN_PATTERN_QUERY } from './graphql/queries'

var DesignRoot = null

let SHOP_ID = tools.getQueryStringValue('s')
export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot
  if (!SHOP_ID) {
    SHOP_ID = DesignRoot.props.SHOP_ID
  }
}

export function changeActiveTemplate(template_id, no, src) {
  try {
    // statements

    if (DesignRoot._ACTIVE_TEMPLATE_ID === template_id) {
      return
    }

    var productDataSet = DesignRoot.productDataSet
    DesignRoot._ACTIVE_TEMPLATE_ID = template_id

    var templateData =
      productDataSet.TEMPLATE_SET_BY_ID[DesignRoot._ACTIVE_TEMPLATE_ID]
    DesignRoot._COLOR_ZONE_SVG_RECORD = []

    if (template_id === 'BLANK') {
      DesignRoot._ACTIVE_COLOR_ZONE = false
      DesignRoot._BLANK_TEMPLATE_ACTIVE = true
      DesignRoot._MAP_OVERLAY = null
      DesignRoot._COLOR_ZONE_SVG_ACTIVE = false
      DesignRoot._SVG_AOP = true

      if (DesignRoot._OLD_CZ_AVAILABLE === false) {
        DesignRoot._ACTIVE_FILL_STEP = true
      }

      logger.log('changeActiveTemplate', templateData, 'BLANK')
    } else if (
      templateData.templateType === 'SVG' &&
      templateData.hasColorZoneFile === true
    ) {
      DesignRoot._COLOR_ZONE_SVG_ACTIVE = true
      DesignRoot._COLOR_ZONE_SVG_SELECTED = templateData.index
      DesignRoot._ACTIVE_COLOR_ZONE = false
      DesignRoot._SVG_FILL = true
      DesignRoot._MAP_OVERLAY = null
      DesignRoot._BLANK_TEMPLATE_ACTIVE = false
      DesignRoot._SVG_AOP = false

      if (DesignRoot._OLD_CZ_AVAILABLE === false) {
        DesignRoot._ACTIVE_FILL_STEP = true
      }

      logger.log('changeActiveTemplate', templateData, 'SVG')
    } else {
      DesignRoot._ACTIVE_COLOR_ZONE = true
      DesignRoot._BLANK_TEMPLATE_ACTIVE = false
      DesignRoot._COLOR_ZONE_SVG_ACTIVE = false
      DesignRoot._SVG_AOP = false

      if (DesignRoot._OLD_CZ_AVAILABLE === false) {
        DesignRoot._ACTIVE_FILL_STEP = false
      }

      logger.log('changeActiveTemplate', templateData, 'COLORZONE')
    }

    DesignRoot.Loading.activeLoading(true)
    DesignRoot.design_template.canvascolor_fabricjs_init()
    DesignRoot.svg_control.load_svg_colorzone()
    DesignRoot.Selection.setState(DesignRoot.Selection.initSelection)
    DesignRoot.tools.renderComponent('FillStep')
    DesignRoot.tools.renderComponent('DesignRoot')
    DesignRoot.zoneColors = null
    DesignRoot.setState({ reloadSVG: true })

    DesignRoot._TRIGGER_RENDER = true

    if (DesignRoot.isSaveEnabled) {
      logger.log('save changeActiveTemplate')
      save()
    }
  } catch (e) {
    // statements
    logger.error('template_control.changeActiveTemplate.error', e)
  }
}

export function setSelectColorZone(colorZone) {
  try {
    // statements

    // if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
    //   logger.log('FORCE COLOR ZONE', DesignRoot._SELECT_COLOR_ZONE)
    //   return
    // }

    logger.log('setSelectColorZone', colorZone)

    DesignRoot._SELECT_COLOR_ZONE = colorZone

    DesignRoot.Main3D.directELM = null
    DesignRoot.Selection.setState({
      selectDirectELM: null,
      selectDirectELMObject: null
    })

    if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
      if (DesignRoot.MenuStep) {
        DesignRoot.MenuStep.setState({ update: true })
      }
    }
  } catch (e) {
    // statements
    logger.error('template_control.setSelectColorZone.error', e)
  }
}

export function selectionUpdateState(selectionObject) {
  try {
    // statements

    if (selectionObject === null) {
      clearSelection()
    } else if (selectionObject._OBJECT_TYPE) {
      if (selectionObject._OBJECT_TYPE === 'TEXT') {
        DesignRoot.TextStep.setState({
          selectTextId: selectionObject.id,
          content: selectionObject._USER_DATA.content,
          fontStyle: selectionObject._USER_DATA.fontStyle,
          textColor: selectionObject._USER_DATA.textColor,
          orderSeq: selectionObject._USER_DATA.orderSeq,
          defaultItem: selectionObject._USER_DATA.defaultItem,
          boundingId: selectionObject._USER_DATA.boundingId,
          boundingGroup: selectionObject._USER_DATA.boundingGroup,
          outlineColor: selectionObject._USER_DATA.outlineColor,
          outlineSize: selectionObject._USER_DATA.outlineSize,
          lineSpacing: selectionObject._USER_DATA.lineSpacing,
          triggerDidUpdate: false,
          scale: selectionObject.scaleX.toFixed(2),
          rotate: selectionObject.angle.toFixed(0)
        })

        DesignRoot.fabric_function.updateDimension(selectionObject.id, 'byID')
      } else if (selectionObject._OBJECT_TYPE === 'LOGO') {
        DesignRoot.LogoStep.setState({
          selectedId: selectionObject.id,
          filename: selectionObject._USER_DATA.filename,
          base64: selectionObject._USER_DATA.base64,
          scale: selectionObject.scaleX.toFixed(2),
          rotate: selectionObject.angle.toFixed(0)
        })
        DesignRoot.fabric_function.updateDimension(selectionObject.id, 'byID')
      } else if (selectionObject._OBJECT_TYPE === 'PATTERN') {
        DesignRoot.PatternStep.setState({
          selectedId: selectionObject.id,
          filename: selectionObject._USER_DATA.filename,
          base64: selectionObject._USER_DATA.base64
        })
        DesignRoot.fabric_function.updateDimension(selectionObject.id, 'byID')
      } else if (selectionObject._OBJECT_TYPE === 'SVG_COLORZONE') {
        logger.log('SELECT SVG_COLORZONE')

        if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
          if (DesignRoot.MenuStep) {
            DesignRoot.MenuStep.setState({ update: true })
          }
        }
      } else if (selectionObject._OBJECT_TYPE === 'SVG') {
        clearSelection()

        var _SPECIAL_ELM = false
        if (
          selectionObject._SPECIAL_ELM &&
          selectionObject._SPECIAL_ELM === true
        ) {
          _SPECIAL_ELM = true
        }

        if (DesignRoot.AopStep !== null) {
          DesignRoot.AopStep.setState({ SPECIAL_ELM: _SPECIAL_ELM })
        }

        // CHECK DIMENSION

        // var w = selectionObject.width * selectionObject.scaleX;
        // var h = selectionObject.height * selectionObject.scaleX;

        // logger.log('CHECK DIMENSION', w, h);

        // // COMPARE CM

        // var w_cm = 60.70;
        // var h_cm = 5.13;

        // // 1 cm = 37.795276 px; 1 px = 0.026458 cm
        // var w_px = w_cm * 37.795276;
        // var h_px = h_cm * 37.795276;

        // var w_diff = w_px-w;
        // var h_diff = h_px-h;

        // var ref = w_cm / w;

        // logger.log('PX DIMENSION', w_px, h_px);
        // logger.log('DIFF DIMENSION', w_diff, h_diff);
        // logger.log('DIFF SCALE DIMENSION', w_diff / 3000);
        // logger.log('1 PX IS', ref);
        // logger.log('PROVE CM ', ref * w, ref * h);

        // COMPARE CM

        // CHECK DIMENSION

        // UPDATE TRANSFORM

        if (selectionObject._USER_DATA.fillType === 'AOP') {
          var transform = selectionObject._USER_DATA.transform

          if (DesignRoot.AopStep !== null) {
            DesignRoot.AopStep.setState({
              scale: transform.scaleX,
              angle: transform.angle,
              offsetX: transform.offsetX,
              offsetY: transform.offsetY,
              width: transform.scaleX * selectionObject.fill.img.width,
              height: transform.scaleX * selectionObject.fill.img.height
            })
          }

          DesignRoot.fabric_function.updateDimension(selectionObject.id, 'byID')
        } else if (selectionObject._USER_DATA.fillType === 'COLOR') {
          DesignRoot.FillStep.setState({
            colorSVG: selectionObject._USER_DATA.color
              ? selectionObject._USER_DATA.color
              : '#ffffff'
          })
        }

        // UPDATE TRANSFORM

        if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
          if (DesignRoot.MenuStep) {
            DesignRoot.MenuStep.setState({ update: true })
          }
        }
      } else {
        clearSelection()
      }
    } else {
      clearSelection()
    }
  } catch (e) {
    // statements
    logger.error(
      'template_control.selectionUpdateState.error',
      e,
      selectionObject
    )
  }
}

export function clearSelection() {
  try {
    logger.log('clearSelection ', DesignRoot)

    if (DesignRoot.TextStep === null) {
      logger.log('PLEASE REFRESH')

      if (
        window.confirm('Auto build fail. Please refresh the page.') === true
      ) {
        window.location.reload()
      }

      return
    } else {
      DesignRoot.TextStep.setState(DesignRoot.TextStep.initState)
      DesignRoot.LogoStep.setState(DesignRoot.LogoStep.initState)

      if (DesignRoot.AopStep !== null) {
        DesignRoot.AopStep.setState({ scale: 1 })
      }

      if (DesignRoot.PatternStep !== null) {
        DesignRoot.PatternStep.setState(DesignRoot.PatternStep.initState)
      }

      DesignRoot.FillStep.setState({ colorSVG: '#FFFFFF' })
      DesignRoot.Dimension.setState({
        approxWidth: null,
        approxHeight: null,
        rotation: null
      })
    }
  } catch (e) {
    // statements
    logger.error('template_control.clearSelection.error', e)
  }
}

// DEBUG

export function logDebug(prefix) {
  logger.log('logDebug', prefix, DesignRoot[prefix])
}

export function triggerExport3DScene() {
  // DesignRoot.Main3D.export3D = true; // Fix for trigger white bg

  DesignRoot.Main3D.renderer_virtual.render(
    DesignRoot.Main3D.scene,
    DesignRoot.Main3D.camera
  )
  DesignRoot.Main3D.export3DTransparent = true // Fix for trigger white bg
}

export function triggerExport3DSceneTransparent() {
  DesignRoot.Main3D.renderer_virtual.render(
    DesignRoot.Main3D.scene,
    DesignRoot.Main3D.camera
  )
  DesignRoot.Main3D.export3DTransparent = true // Fix for trigger white bg
}

export function export3DScene(option) {
  logger.log('export3DScene')
  var Main3D = DesignRoot.Main3D

  try {
    var strDownloadMime = 'image/octet-stream'
    var strMime = 'image/png'

    var imgData = Main3D.renderer_virtual.domElement.toDataURL(strMime)

    logger.log('imgData', imgData)

    if (option && option === 'RETURN') {
      return imgData
    } else {
      var link = document.createElement('a')
      link.download = '3D_MODEL_EXPORT.png'
      link.href = imgData

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } catch (e) {
    logger.error('template_control.export3DScene.error', e)
    return
  }
}

export function triggerExport2DScene(type) {
  // HIDE ZONE

  DesignRoot.fabric_function.hide_all_bounding_stroke('LOGO')
  DesignRoot.fabric_function.hide_all_bounding_stroke('TEXT')
  DesignRoot.fabric_function.hide_all_bounding_stroke('BOTH')

  // HIDE ZONE

  DesignRoot._TRIGGER_EXPORT_TYPE = type
  DesignRoot._TRIGGER_EXPORT = true
}

export function export2DScene(option) {
  logger.log('export2DScene', option, DesignRoot._TRIGGER_EXPORT_TYPE)

  if (DesignRoot.isDashboard) {
    var canvasExport = DesignRoot.canvas
    var currentCanvasSize =
      DesignRoot._CANVAS_RENDER.width * DesignRoot._CANVAS_SIZE
    var multiplier = 3000 / currentCanvasSize
  } else {
    var canvasExport = DesignRoot.canvas
    var multiplier = 1
  }

  try {
    if (
      DesignRoot._TRIGGER_EXPORT_TYPE &&
      DesignRoot._TRIGGER_EXPORT_TYPE === 'JPG'
    ) {
      var imgData = canvasExport.toDataURL({
        // enableRetinaScaling: true,
        format: 'jpeg',
        multiplier: multiplier
      })
      var fileName = '2D_MODEL_EXPORT.jpg'
    } else {
      var imgData = canvasExport.toDataURL({
        // enableRetinaScaling: true,
        multiplier: multiplier
      })
      var fileName = '2D_MODEL_EXPORT.png'
    }

    if (option && option === 'RETURN') {
      return imgData
    } else {
      // logger.log(imgData);

      var link = document.createElement('a')
      link.download = fileName
      link.href = imgData

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } catch (e) {
    logger.error('template_control.export2DScene.error', e)
    return
  }
}

export function toggleLogoOverlay() {
  try {
    // statements

    DesignRoot._LOGO_TOGGLE = !DesignRoot._LOGO_TOGGLE

    if (DesignRoot._COLOR_ZONE_SVG_ACTIVE === false) {
      if (DesignRoot._CURRENT_LOGO_SRC === null) {
        swal({
          icon: 'info',
          text: 'Logo not found'
        })
      } else {
        logger.log(DesignRoot._CURRENT_LOGO_SRC)
        DesignRoot.canvas_coloring.updateTexture() // OLD COLORZONE CALL
      }
    } else {
      DesignRoot.svg_control.toggle_svg_logo()
    }
  } catch (e) {
    // statements
    logger.error('template_control.toggleLogoOverlay.error', e)
  }
}

export function toggleRedLine() {
  try {
    // statements

    if (DesignRoot._CURRENT_SEAM_SRC === null) {
      swal({
        icon: 'info',
        text: 'Seam allowance not found'
      })
    } else {
      logger.log(DesignRoot._CURRENT_LOGO_SRC)

      DesignRoot._SEAM_TOGGLE = !DesignRoot._SEAM_TOGGLE
      DesignRoot.canvas_coloring.updateTexture()
    }
  } catch (e) {
    // statements
    logger.error('template_control.toggleRedLine.error', e)
  }
}

export function renderTexture() {
  try {
    // statements

    var storeMappingChild = DesignRoot.Main3D.storeMappingChild
    if (storeMappingChild.length) {
      for (var i = 0; i < storeMappingChild.length; i++) {
        // storeMappingChild[i].map.image = document.getElementById('example');
        storeMappingChild[i].map.needsUpdate = true
      }
    }
  } catch (e) {
    // statements
    logger.error('template_control.renderTexture.error', e)
  }
}

export function toggle2DScene() {
  try {
    // statements

    DesignRoot._SHOW_2D = !DesignRoot._SHOW_2D
    DesignRoot.tools.renderComponent('DesignRoot')
  } catch (e) {
    // statements
    logger.error('template_control.toggle2DScene.error', e)
  }
}

export function view3D(option) {
  try {
    // statements

    if (option === 'RESET') {
      DesignRoot.Main3D.controls.reset()
    }
  } catch (e) {
    // statements
    logger.error('template_control.view3D.error', e)
  }
}

const dataURLtoFile = (dataurl, filename) => {
  try {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  } catch (error) {
    logger.error('datURLtoFile.error', error)
    return null
  }
}

export async function uploadFile(fileName, imgData) {
  try {
    const optionsMutation = {
      mutation: UPLOAD_FILE_MUTATION,
      variables: {
        type: 'DESIGN_PREVIEW',
        fileName: fileName,
        prefixVariables: ['productDesignId'],
        input: {
          ownedByOrganisationId: DesignRoot.props.ownedByOrganisationId,
          productDesignId: DesignRoot.props.productDesignId
        }
      }
    }
    const signedUrlResult = DesignRoot.isDashboard
      ? await clientAdmin.mutate(optionsMutation)
      : await apolloClient.mutate(optionsMutation)
    const signedUrl = signedUrlResult.data.uploadFile.url
    let options = {
      headers: {
        'Content-Type': signedUrlResult.data.uploadFile.contentType
      }
    }
    if (signedUrlResult.data.uploadFile.acl) {
      options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl
    }
    if (signedUrlResult.data.uploadFile.tagging) {
      options.headers['x-amz-tagging'] = signedUrlResult.data.uploadFile.tagging
    }
    axios.put(signedUrl, dataURLtoFile(imgData, fileName), options)
  } catch (error) {
    logger.error('uploadFile.error')
  }
}

export function capture4view() {
  logger.log('capture4view')

  if (DesignRoot.canvas) {
    DesignRoot.canvas.discardActiveObject()
    DesignRoot.canvas.renderAll()
  }

  DesignRoot.svg_control.svg_border_default_set(true)

  return new Promise((resolve, reject) => {
    try {
      DesignRoot.Main3D.captureDirection = null
      DesignRoot.Main3D.captureSET = {
        '0': null,
        '90': null,
        '180': null,
        '270': null
      }

      DesignRoot.Main3D.currentView = _cloneDeep(
        DesignRoot.Main3D.camera.position
      )
      DesignRoot.Main3D.currentPan = _cloneDeep(
        DesignRoot.Main3D.controls.target
      )
      DesignRoot.Main3D.capture3D = true

      // for (var key in DesignRoot.Main3D.captureSET) {
      //   DesignRoot.Main3D.captureDirection = key;
      //   DesignRoot.control_view.changeView(key);
      // }
      resolve()
    } catch (error) {
      logger.error('template_control.capture4view.error', error)
      resolve()
    }
  })
}

export function makeCapture() {
  try {
    logger.log(
      'makeCapture',
      DesignRoot.Main3D.captureDirection,
      DesignRoot.Main3D.captureSET
    )

    const strDownloadMime = 'image/octet-stream'
    const strMime = 'image/jpeg'
    const imgData = DesignRoot.Main3D.renderer_virtual.domElement.toDataURL(
      strMime
    )
    // logger.log('imgData', imgData);

    if (DesignRoot.Main3D.captureDirection !== null) {
      DesignRoot.Main3D.captureSET[DesignRoot.Main3D.captureDirection] = imgData
    }

    const timestamp = new Date().getTime()

    capturePrintPattern(DesignRoot)

    if (DesignRoot.Main3D.captureDirection === '0') {
      try {
        this.uploadFile('latest.jpg', imgData)
      } catch (error) {
        logger.error('template_control.makeCapture.upload.error', error)
      }
    }
    try {
      this.uploadFile(DesignRoot.Main3D.captureDirection + '.jpg', imgData)
      this.uploadFile(
        timestamp + '-' + DesignRoot.Main3D.captureDirection + '.jpg',
        imgData
      )
    } catch (error) {
      logger.error('template_control.makeCapture.uploadFile.error', error)
    }
  } catch (e) {
    logger.error('template_control.makeCapture.error', e)
  }
}

const capturePrintPattern = async DesignRoot => {
  let printPatternLatest = document.getElementById(DesignRoot.canvasMainDom)
  if (DesignRoot._APP_TYPE !== '2d-3d' && printPatternLatest) {
    let interval = setInterval(() => {
      let printPattern = document.getElementById(DesignRoot.canvasMainDom)
      printPattern = printPatternLatest = printPattern
        ? printPattern
        : printPatternLatest
      if (printPattern.getAttribute('width') > 1) {
        let printPatternData = printPattern.toDataURL()
        try {
          uploadFile('printPattern.jpg', printPatternData)
        } catch (error) {
          logger.error('template_control.makeCapture.upload.error', error)
        }
        clearInterval(interval)
      }
    }, 1000)
  }
}

export function export2DSceneSVG(type) {
  try {
    if (DesignRoot.isDashboard) {
      logger.log('export2DSceneSVG', DesignRoot._CANVAS_RENDER)
      var svg = DesignRoot._CANVAS_RENDER.toSVG({
        width: DesignRoot.svgW,
        height: DesignRoot.svgH,
        suppressPreamble: true
      })
    } else {
      logger.log('export2DSceneSVG', DesignRoot.canvas)
      var svg = DesignRoot.canvas.toSVG({
        width: DesignRoot.svgW,
        height: DesignRoot.svgH,
        suppressPreamble: true
      })
    }

    svgToS3(svg)
    logger.log(svg)

    //convert svg source to URI data scheme.
    // var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);
    //set url value to a element's href attribute.

    // var link = document.createElement('a');
    // link.download = 'PATTERN_EXPORT.SVG';
    // link.href = url;

    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // logger.log(link);

    // let w = window.open('','_blank');
    // w.document.write('<a href="'+link+'" download="PATTERN_EXPORT.SVG">DOWNLOAD</a>');
    // w.focus();

    // CLEAN SVG_AOP

    var allSvgAop = DesignRoot.fabric_function.getObjectByType('SVG_AOP')

    for (var i = 0; i < allSvgAop.length; i++) {
      DesignRoot.canvas.remove(allSvgAop[i])
    }
    DesignRoot.canvas.requestRenderAll()

    if (DesignRoot._CANVAS_RENDER) {
      for (var i = 0; i < DesignRoot._CANVAS_RENDER._objects.length; i++) {
        if (
          DesignRoot._CANVAS_RENDER._objects[i]._OBJECT_TYPE &&
          DesignRoot._CANVAS_RENDER._objects[i]._OBJECT_TYPE ===
            'SVG_DASHED_BORDER'
        ) {
          DesignRoot._CANVAS_RENDER._objects[i].visible = false
        }
      }
    }

    // CLEAN SVG_AOP

    // var fileName = '2D_MODEL_EXPORT.svg';
    // let w = window.open('')
    // w.document.write(DesignRoot._CANVAS_RENDER.toSVG());
  } catch (e) {
    logger.error('template_control.export2DSceneSVG.error', e)
  }
}

export async function svgToS3(svgData) {
  // var svgBlob = new Blob([svgData], {type: "image/svg+xml;charset=utf-8"});

  // logger.log("svgBlob", svgBlob);

  // S3 Process here

  try {
    var fileName = 'PATTERN_EXPORT.svg'

    const optionsMutation = {
      mutation: UPLOAD_FILE_MUTATION,
      variables: {
        type: 'DESIGN_PREVIEW',
        fileName: fileName,
        prefixVariables: ['productDesignId'],
        input: {
          ownedByOrganisationId: DesignRoot.props.ownedByOrganisationId,
          productDesignId: DesignRoot.props.productDesignId
        }
      }
    }
    const signedUrlResult = DesignRoot.isDashboard
      ? await clientAdmin.mutate(optionsMutation)
      : await apolloClient.mutate(optionsMutation)

    const signedUrl = signedUrlResult.data.uploadFile.url
    let options = {
      headers: {
        'Content-Type': signedUrlResult.data.uploadFile.contentType
      }
    }
    if (signedUrlResult.data.uploadFile.acl) {
      options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl
    }
    if (signedUrlResult.data.uploadFile.tagging) {
      options.headers['x-amz-tagging'] = signedUrlResult.data.uploadFile.tagging
    }
    await axios.put(signedUrl, svgData, options)

    logger.log('svgToS3', signedUrlResult, options)
    logger.log(signedUrl)

    var onlyUrl = signedUrl.split('?')
    logger.log('onlyUrl', onlyUrl[0])

    // const patternData = await clientAdmin.query({
    //   query: DESIGN_PATTERN_QUERY,
    //   variables: {
    //     ownedByOrganisationId: DesignRoot.props.ownedByOrganisationId,
    //     id: DesignRoot.props.productDesignId
    //   }
    // })
    // const patternLink = patternData.data.getProductDesign.patternSrc

    const patternLink = onlyUrl[0]

    var link = document.createElement('a')
    link.download = patternLink
    link.href = patternLink
    link.target = '_blank'
    document.body.appendChild(link)

    DesignRoot.Loading.activeLoading(false)

    swal({
      icon: 'info',
      text:
        'If the application is not being downloaded automatically. Follow these few steps: Right click on the image and select save as.',
      button: 'Gotcha!'
    }).then(() => {
      link.click()
      document.body.removeChild(link)
      logger.log(link)
    })
  } catch (error) {
    logger.error('svgToS3.error')
  }

  // S3 Process here
}

export async function gltfToS3() {
  try {
    const getGltfData = () =>
      new Promise(function(resolve, reject) {
        var exporter = new GLTFExporter()
        // Parse the input and generate the glTF output
        exporter.parse(DesignRoot.Main3D.main_object, function(gltf) {
          resolve(JSON.stringify(gltf))
        })
      })

    const gltfData = await getGltfData()

    var fileName = 'GLTF_EXPORT.gltf'

    const optionsMutation = {
      mutation: UPLOAD_FILE_MUTATION,
      variables: {
        type: 'DESIGN_PREVIEW',
        fileName: fileName,
        prefixVariables: ['productDesignId'],
        input: {
          ownedByOrganisationId: DesignRoot.props.ownedByOrganisationId,
          productDesignId: DesignRoot.props.productDesignId
        }
      }
    }
    const signedUrlResult =
      DesignRoot._APP_TYPE === '2d-3d'
        ? await clientAdmin.mutate(optionsMutation)
        : await apolloClient.mutate(optionsMutation)

    const signedUrl = signedUrlResult.data.uploadFile.url
    let options = {
      headers: {
        'Content-Type': signedUrlResult.data.uploadFile.contentType
      }
    }
    if (signedUrlResult.data.uploadFile.acl) {
      options.headers['x-amz-acl'] = signedUrlResult.data.uploadFile.acl
    }
    if (signedUrlResult.data.uploadFile.tagging) {
      options.headers['x-amz-tagging'] = signedUrlResult.data.uploadFile.tagging
    }
    await axios.put(signedUrl, gltfData, options)

    console.log('gltfToS3', signedUrlResult, options)
    console.log(signedUrl)

    var onlyUrl = signedUrl.split('?')
    console.log('onlyUrl', onlyUrl[0])
  } catch (error) {
    console.error('gltfToS3.error')
  }

  // S3 Process here
}

export function readSVGDOM(mapColorZoneToSVG) {
  // 93b7a648-6a45-4d61-8127-d163833fa7d9 = Z1919904

  var mySVG = document.getElementById('SVGDOM2')
  console.log('productID', DesignRoot.productID)
  console.log('_ACTIVE_TEMPLATE_ID', DesignRoot._ACTIVE_TEMPLATE_ID)
  console.log('RAW_SVG', DesignRoot.RAW_SVG)
  console.log(
    '_CONVERT_COLORZONE_TO_VECTOR',
    DesignRoot._CONVERT_COLORZONE_TO_VECTOR
  )

  if (!mapColorZoneToSVG) {
    mapColorZoneToSVG = []
    for (var i = 0; i < DesignRoot.currentChangeColor.length; i++) {
      mapColorZoneToSVG['COLORZONE-' + (i + 1)] =
        DesignRoot.currentChangeColor[i]
    }
  }

  logger.log('mapColorZoneToSVG', mapColorZoneToSVG)

  console.log('mapColorZoneToSVG', mapColorZoneToSVG)
  console.log('readSVGDOM', mySVG)

  var svgDoc = mySVG
  console.log('SVG contentDocument Loaded!', svgDoc)

  var data_group = svgDoc.querySelectorAll('g')
  console.log(data_group)

  var colorZoneDom = []
  for (var i = 0; i < data_group.length; i++) {
    if (data_group[i].id.indexOf('COLORZONE-') !== -1) {
      var zoneId = data_group[i].id.match(/([COLORZONE-]+[\d]+)/g)[0]
      var zoneSplit = zoneId.split('-')
      var colorzone = zoneSplit[0] + '-' + zoneSplit[1]
      var zone = zoneSplit[1]

      console.log(zoneId, colorzone, zone)

      colorZoneDom.push({
        data: data_group[i],
        zone: zone,
        zoneId: zoneId
      })
    }
  }

  console.log('colorZoneDom', colorZoneDom)

  // MAP ZONE

  for (var i = 0; i < colorZoneDom.length; i++) {
    if (mapColorZoneToSVG[colorZoneDom[i].zoneId]) {
      console.log(
        'DO MAP COLOR ZONE',
        mapColorZoneToSVG[colorZoneDom[i].zoneId],
        colorZoneDom[i].zoneId
      )

      var color = mapColorZoneToSVG[colorZoneDom[i].zoneId]

      for (var j = 0; j < colorZoneDom[i].data.children.length; j++) {
        colorZoneDom[i].data.children[j].style.fill = color
      }
    }
  }

  // MAP ZONE

  //remove from SVG DOM
  var data_tmp = svgDoc.querySelectorAll('[data-tmp-svgdom]')
  // logger.log('data_tmp', data_tmp)
  data_tmp.forEach(node => {
    node.parentNode.removeChild(node)
  })

  // GET LOGO TEXT
  DesignRoot._CANVAS_EXPORT.clear()
  for (var i = 0; i < DesignRoot.canvas.getObjects().length; i++) {
    var item = DesignRoot.canvas.getObjects()[i]
    if (
      item._OBJECT_TYPE &&
      (item._OBJECT_TYPE === 'LOGO' || item._OBJECT_TYPE === 'TEXT')
    ) {
      var cloneItem = window.fabric.util.object.clone(item)
      DesignRoot._CANVAS_EXPORT.add(cloneItem)

      // add to SVG DOM
      var group = document.createElementNS('http://www.w3.org/2000/svg', 'g')
      group.setAttribute('data-tmp-svgdom', true)
      group.innerHTML = item.toClipPathSVG()
      // logger.log('group', group)
      svgDoc.appendChild(group)
    }
  }
  DesignRoot._CANVAS_EXPORT.requestRenderAll()
  // GET LOGO TEXT

  var serializer = new XMLSerializer()
  var str = serializer.serializeToString(svgDoc)

  svgToS3(str)
}
