import _sortBy from 'lodash/sortBy';

import React, { Component } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import Select from '../../../common/Select';
import { countries } from '../../../../helper/countryList';
import uuid from 'uuid';
import { MultipleSelect } from 'react-select-material-ui';
import omitDeep from 'omit-deep';
import ShippingZone from './ShippingZone';

const suggestions = [
  'World',
  'EU',
  ..._sortBy(countries, ['name']).map(c => c.name)
];

class ShippingZones extends Component {
  state = {
    countryCodes: []
  };
  render() {
    const {
      defaultCurrency,
      updateShopShippingSettings,
      shop,
      shopId
    } = this.props;
    return (
      <>
        {shop && shop.shippingSettings && shop.shippingSettings.shippingZones
          ? shop.shippingSettings.shippingZones.map((shippingZone, index) => {
              return (
                <div
                  style={{
                    border: '1px solid #eee',
                    padding: '1.5em',
                    margin: '1em 0'
                  }}
                  key={'sz' + index}
                >
                  <ShippingZone
                    countries={countries}
                    defaultCurrency={defaultCurrency}
                    key={shippingZone.id}
                    shippingZone={shippingZone}
                    updateShopShippingSettings={updateShopShippingSettings}
                    shop={shop}
                    shopId={shopId}
                    suggestions={suggestions}
                  />
                </div>
              );
            })
          : 'No shipping zones yet'}
      </>
    );
  }
}

export default ShippingZones;
