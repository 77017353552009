import React, { useImperativeHandle, useRef } from 'react';
const style = {
  marginBottom: '.5rem',
  marginTop: '1.5rem',
  cursor: 'move'
};

const UndraggableCard = React.forwardRef((props, ref) => {
  const {} = props;
  const elementRef = useRef(null);
  let styleComp = Object.assign(props.style, style);
  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current
  }));
  return (
    <div ref={elementRef} {...props} style={styleComp}>
      {props.children}
    </div>
  );
});
export default UndraggableCard;
