import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import _sortBy from 'lodash/sortBy'
import _flatten from 'lodash/flatten'
import { getBreakpoints } from '../helpers/getBreakpoints'
import RenderElement from './RenderElement'
import { getTextFromLanguage } from '../../../../../../helper/languageHelper'
import { DefaultPageType } from '../Modules/Types'

interface Props {
  field: any
  editing: boolean
  view: any
  defaultPageType: DefaultPageType | null
  salesChannelId?: string
  productDesignId?: string
  ownedByOrganisationId?: string
  urlPrefix: string
  isHover: boolean
}

const RenderField: React.FC<Props> = props => {
  const {
    field,
    editing,
    defaultPageType,
    view,
    ownedByOrganisationId,
    salesChannelId,
    productDesignId,
    urlPrefix,
    isHover,
  } = props
  const [hoverElement, setHoverElement] = useState<string | null>(null)

  let elements: any = []

  if (field) {
    elements = _sortBy(field.contentView, 'sortOrder')
  }

  const columnsArray = []
  for (let i = 0; i < field.columns; i++) {
    columnsArray.push(i + 1)
  }

  const breakpoints = getBreakpoints(field.columns)

  return (
    <Grid
      style={{
        maxWidth: field.maxWidth ? field.maxWidth : '1000px',
        padding: field.padding ? field.padding : 0,
      }}
      justify={field.justify ? field.justify : 'center'}
      alignItems={field.alignItems ? field.alignItems : 'center'}
      container={true}
    >
      {field.header ? (
        <Grid
          style={{ padding: 50 }}
          container={true}
          justify={field.header.alignment}
        >
          <Typography variant="h1">
            {getTextFromLanguage(field.header.text.text, 'en_UK')}
          </Typography>
        </Grid>
      ) : null}
      {columnsArray.map((c: number) => {
        return (
          <Grid
            key={c}
            item={true}
            xs={
              field.columns >= 2 && view.mode === 'mobile' ? 12 : breakpoints.xs
            }
            sm={
              field.columns >= 2 && view.mode === 'mobile' ? 12 : breakpoints.sm
            }
            md={
              field.columns >= 2 && view.mode === 'mobile' ? 12 : breakpoints.md
            }
            lg={
              field.columns >= 2 && view.mode === 'mobile' ? 12 : breakpoints.lg
            }
            xl={
              field.columns >= 2 && view.mode === 'mobile' ? 12 : breakpoints.xl
            }
          >
            {elements
              .filter((o: any) => o.column === c)
              .map((e: any, i: any) => (
                <Grid
                  style={
                    editing && hoverElement === e.id
                      ? {
                          border: '1px solid #b23566',
                          borderRadius: 2,
                          boxShadow: 'inset: 0px 0px 0px 10px #f00',
                          WebkitBoxShadow: 'inset: 0px 0px 0px 10px #f00',
                          padding: e?.padding || 0,
                        }
                      : {
                          padding: e?.padding || 0,
                        }
                  }
                  onMouseEnter={() => setHoverElement(e.id)}
                  onMouseLeave={() => setHoverElement(null)}
                  key={i}
                  item={true}
                >
                  <RenderElement
                    isHover={isHover}
                    urlPrefix={urlPrefix}
                    defaultPageType={defaultPageType}
                    modelId={field.modelId}
                    editing={editing}
                    element={e}
                    hoverElement={hoverElement}
                    dialog={false}
                    view={view}
                    ownedByOrganisationId={ownedByOrganisationId}
                    salesChannelId={salesChannelId}
                    productDesignId={productDesignId}
                  />
                </Grid>
              ))}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default RenderField
