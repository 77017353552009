import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  withStyles,
  createStyles,
  WithStyles,
  Grid,
  Card,
  Typography,
  Divider
} from '@material-ui/core'
import { Localization } from '../../../lang/localization'
import { PAYMENT_OPTIONS_LIST } from '../../../helper/paymentOptionsList'
import Bancontact from '../NewCart/icon-Bancontact'
import Sofort from '../NewCart/icon-Sofort'
import Ideal from '../NewCart/icon-Ideal'

const styles = () =>
  createStyles({
    cardContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imgFooterCard: {
      margin: 'auto',
      fontWeight: 300,
      textAlign: 'center'
    }
  })

interface Props extends WithStyles<typeof styles> {
  data: any[]
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  isFooter?: boolean
  linkColor?: string
}

const PaymentOptions: React.FunctionComponent<Props> = props => {
  const { classes, size, data, isFooter, linkColor } = props
  if (!data || data.length === 0) {
    return null
  }

  const getIcon = (name: string) => {
    const paymentOption = PAYMENT_OPTIONS_LIST.find(o => o.name === name)
    return paymentOption ? paymentOption.icon : null
  }

  return (
    <Grid item={true} xs={12} sm={12} md={12} lg={size || 4} xl={size || 4}>
      <Grid container={true} spacing={2}>
        <Grid item={true}>
          <Card
            elevation={isFooter ? 0 : 1}
            style={{
              padding: 24,
              width: '100%',
              backgroundColor: isFooter ? 'transparent' : '#ffffff'
            }}
          >
            <Grid container={true} justify="flex-start">
              <Grid item={true} xs={12}>
                <Typography
                  variant="h3"
                  align="center"
                  style={{ color: linkColor ? linkColor : '#000' }}
                >
                  {Localization.get('payment_options')}
                </Typography>
              </Grid>

              <Divider style={{ margin: 8, background: 'transparent' }} />
              <Grid item={true} xs={12}>
                <Grid container={true} justify="flex-start">
                  {data
                    ? data.map((item: any, index: number) =>
                        getIcon(item.name) ? (
                          <Grid
                            key={'paymo' + index}
                            item={true}
                            xs={3}
                            className={classes.cardContainer}
                          >
                            <FontAwesomeIcon
                              data-testid={'paymentoption-icon'}
                              className={classes.imgFooterCard}
                              size="2x"
                              color={isFooter ? linkColor : '#000'}
                              icon={getIcon(item.name)}
                            />
                          </Grid>
                        ) : item.name === 'BANCONTACT' ? (
                          <Grid
                            key={'paymo' + index}
                            item={true}
                            xs={3}
                            className={classes.cardContainer}
                          >
                            <Bancontact
                              cardWidth={isFooter ? '30' : '36'}
                              cardHeight={isFooter ? '23' : '28'}
                              linkColor={isFooter ? linkColor : ''}
                            />
                          </Grid>
                        ) : item.name === 'IDEAL' ? (
                          <Grid
                            key={'paymo' + index}
                            item={true}
                            xs={3}
                            className={classes.cardContainer}
                          >
                            <Ideal
                              cardWidth={isFooter ? '30' : '36'}
                              cardHeight={isFooter ? '23' : '28'}
                              linkColor={isFooter ? linkColor : ''}
                            />
                          </Grid>
                        ) : item.name === 'SOFORT' ? (
                          <Grid
                            key={'paymo' + index}
                            item={true}
                            xs={3}
                            className={classes.cardContainer}
                          >
                            <Sofort
                              cardWidth={isFooter ? '55' : '65'}
                              cardHeight={isFooter ? '38' : '48'}
                              linkColor={isFooter ? linkColor : ''}
                            />
                          </Grid>
                        ) : null
                      )
                    : null}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(PaymentOptions)
