import React from 'react'
import {createStyles, withStyles, WithStyles, Button} from '@material-ui/core'


const styles = () => createStyles({
})

interface Props extends WithStyles<typeof styles> {
  disabled: boolean,
  onSave(): void
}


const RichTextSave: React.FunctionComponent<Props> = (props) => {
  return (
    <Button
      data-cy="RichText-Save-btn"
      disabled={props.disabled}
      variant="contained"
      color="primary"
      onClick={props.onSave}
      style={{
        marginTop: '20px'
      }}
      data-testid="richtext-save-btn"
    >
      Save
    </Button>
  )
}

export default withStyles(styles, { withTheme: true })(RichTextSave)