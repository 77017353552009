import React from 'react'
import {
  Toolbar,
  Grid,
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core'
import { remapAlignmentForLayout } from '../../admin/components/content/utils'
import Menu from './Menu'

interface Props extends WithStyles<typeof styles> {
  contentMenu: any
  currentUser: any
  elements: any[]
  isEditMode?: boolean
  language: string
  languages?: [string]
  urlPrefix: string | null
  showHomeMobile?: boolean
  shop: any
  teamstore: any
  slugs: any[]
}

const styles = (themeStyle: Theme) =>
  createStyles({
    direction: {
      flexDirection: 'row',
      [themeStyle.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    responsive: {
      height: 'auto',
      width: '100%',
      minHeight: 50,
      [themeStyle.breakpoints.down('sm')]: {
        height: 'auto',
        width: '100%',
        background: 'transparent !important'
      }
    },
    btn: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      [themeStyle.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    desktop: {
      display: 'block',
      [themeStyle.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  })

const PrimaryHeader: React.FunctionComponent<Props> = props => {
  const {
    classes,
    contentMenu,
    elements,
    isEditMode,
    language,
    languages,
    urlPrefix,
    shop,
    showHomeMobile,
    currentUser,
    slugs,
    teamstore
  } = props
  if (!contentMenu) return null
  return (
    <Toolbar
      className={classes.responsive}
      style={{
        backgroundColor: contentMenu.backgroundColor,
        height:
          contentMenu.styling && contentMenu.styling.height
            ? contentMenu.styling.height
            : 'auto'
      }}
      data-testid="primary-header"
    >
      <Grid
        container={true}
        justify={remapAlignmentForLayout(contentMenu.alignment)}
        alignItems={remapAlignmentForLayout(contentMenu.verticalAlign)}
        className={classes.direction}
        style={{
          maxWidth:
            remapAlignmentForLayout(contentMenu.alignment) === 'flex-end'
              ? '100%'
              : shop.id === 'vermarc'
              ? '1000px'
              : '',
          margin: 'auto'
        }}
      >
        <Menu
          teamstore={teamstore}
          slugs={slugs}
          currentUser={currentUser}
          linkColor={contentMenu.linkColor}
          styling={contentMenu.styling || {}}
          language={language}
          languages={languages}
          urlPrefix={urlPrefix}
          isEditMode={isEditMode}
          elements={elements}
          shop={shop}
          showHomeMobile={showHomeMobile}
        />
      </Grid>
    </Toolbar>
  )
}

export default withStyles(styles, { withTheme: true })(PrimaryHeader)
