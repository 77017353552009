import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import _get from 'lodash/get'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import { normalizeTextTitle } from './utils'
import { NativeSelect, InputLabel } from '@material-ui/core'
import { SingleSelect } from 'react-select-material-ui'

const styles = theme => ({})

class SelectPage extends React.Component {
  state = {
    isChecked: false,
    selectedPageId: ''
  }

  render() {
    const {
      classes,
      page,
      Template,
      handleChange,
      selectedPage,
      selectedPageId,
      langCode,
      pages
    } = this.props
    var checked = false
    var slug = ''
    var url = ''

    let splitPage = ''
    splitPage = selectedPage && selectedPage.split('/')

    try {
      slug = page.slugs.find(s => s.langCode === langCode).content
    } catch (error) {}

    // TODO fix this
    try {
      if (langCode === 'en_UK') {
        url = '/' + 'en/' + slug
      } else if (langCode === 'nl_NL') {
        url = '/' + 'nl/' + slug
      } else if (langCode === 'fr_FR') {
        url = '/' + 'fr/' + slug
      }
    } catch (error) {}
    const currentPage = pages
      ? pages.edges.find(n => n.node.id === selectedPageId)
      : null
    return (
      <div style={{ width: '100%', padding: 10 }}>
        <SingleSelect
          SelectProps={{}}
          style={{
            border: '1px solid #ced4da',
            borderRadius: 4
          }}
          options={pages.edges.map(pageNode => pageNode.node.name)}
          value={currentPage ? currentPage.node.name : null}
          onChange={value => {
            const page = pages.edges.find(n => n.node.name === value)
            const pageSlug = getTextFromLanguage(page.node.slugs, langCode)
            handleChange(
              {
                target: {
                  value: page.node.id
                }
              },
              pageSlug
            )
          }}
        />
      </div>
    )
  }
}

SelectPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(SelectPage)
