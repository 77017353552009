import { API_STAGE } from '../../../../../brikl-config'
import * as tools from '../tools'

var DesignRoot = null

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot
}

export function initKeyEvent() {
  document.addEventListener('keydown', onKeydown, { passive: false })
  document.addEventListener('wheel', onMouseWheel, { passive: false })
}

function onKeydown(e) {
  logger.log('onKeydown', e)

  try {
    // statements

    if (DesignRoot !== null) {
      const control_tools_event = DesignRoot.control_tools_event

      if (e.code === 'Delete') {
        e.preventDefault()
        control_tools_event.__remove()
      } else if ((e.ctrlKey === true || e.metaKey) && e.altKey === false) {
        if (e.code === 'KeyS' && API_STAGE !== 'PRODUCTION') {
          e.preventDefault()
          DesignRoot.save_load.save()
        } else if (e.code === 'BracketRight') {
          e.preventDefault()
          control_tools_event.__moveTo('moveUp')
        } else if (e.code === 'BracketLeft') {
          e.preventDefault()
          control_tools_event.__moveTo('moveDown')
        } else if (e.code === 'Comma') {
          e.preventDefault()
          control_tools_event.__toggleVisible()
        } else if (e.code === 'Slash') {
          e.preventDefault()
          control_tools_event.__toggleLock()
        } else if (e.code === 'KeyX') {
          e.preventDefault()
          control_tools_event.__mirror('x')
        } else if (e.code === 'KeyY') {
          e.preventDefault()
          control_tools_event.__mirror('y')
        } else if (e.code === 'KeyD') {
          e.preventDefault()
          control_tools_event.__duplicate()
        } else if (e.code === 'KeyP') {
          e.preventDefault()
          control_tools_event.__rePosition()
        } else if (e.code === 'KeyR') {
          e.preventDefault()
          control_tools_event.__reCenterOrigin()
        } else if (e.code === 'ArrowLeft' && DesignRoot.isDashboard) {
          e.preventDefault()
          control_tools_event.__toggle2D3D('hidden2D')
        } else if (e.code === 'ArrowRight' && DesignRoot.isDashboard) {
          e.preventDefault()
          control_tools_event.__toggle2D3D('hidden3D')
        }

        // Forward : Ctrl + ]
        // backward : Ctrl + [
        // show hide : Ctrl + ,
        // lock unlock : Ctrl + /
        // flip x : Ctrl + x
        // flip y : Ctrl + y
        // duplicate : Ctrl + d
        // 3D View : Ctrl + ArrowLeft
        // 2D View : Ctrl + ArrowRight
      } // END ELSE IF ctrlKey
      else if (e.code === 'Escape' && DesignRoot.isDashboard) {
        if (DesignRoot.MenuDesignRoomStep.state.value !== '-') {
          DesignRoot.MenuDesignRoomStep.setState({ value: '-' })
          DesignRoot.setState({ dynamicPlane: '' })
        }
      } else if (
        e.code === 'ArrowUp' ||
        e.code === 'ArrowDown' ||
        e.code === 'ArrowLeft' ||
        e.code === 'ArrowRight'
      ) {
        control_tools_event.__arrowControl(e.code, e)
      } else if (e.altKey === true && (e.ctrlKey === true || e.metaKey)) {
        // DEV TOOL

        if (e.code === 'Numpad1') {
          e.preventDefault()
          logger.log(DesignRoot)
        } else if (e.code === 'KeyC') {
          e.preventDefault()
          DesignRoot.template_control.capture4view()
        } else if (e.code === 'KeyE') {
          e.preventDefault()
          DesignRoot.fabric_function.clippingSvg()
        } else if (e.code === 'KeyD') {
          e.preventDefault()
          DesignRoot.template_control.export2DSceneTIFF('PNG')
        } else if (e.code === 'KeyT') {
          e.preventDefault()
          DesignRoot.Main3D.renderer_virtual.render(
            DesignRoot.Main3D.scene,
            DesignRoot.Main3D.camera
          )
          DesignRoot.Main3D.export3DTransparent = true
        } else if (e.code === 'KeyR') {
          e.preventDefault()
          DesignRoot.Main3D.controls.reset()
        } else if (e.code === 'KeyP') {
          e.preventDefault()
          logger.log('camera.position', DesignRoot.Main3D.camera.position)
          logger.log('controls.target', DesignRoot.Main3D.controls.target)
        } else if (e.code === 'Numpad8') {
          // BACK
          e.preventDefault()
          DesignRoot.control_view.changeView('180')
        } else if (e.code === 'Numpad2') {
          // FRONT
          e.preventDefault()
          DesignRoot.control_view.changeView('0')
        } else if (e.code === 'Numpad4') {
          // LEFT
          e.preventDefault()
          DesignRoot.control_view.changeView('90')
        } else if (e.code === 'Numpad6') {
          // RIGHT
          e.preventDefault()
          DesignRoot.control_view.changeView('270')
        } else if (e.code === 'KeyA') {
          e.preventDefault()
          DesignRoot.Main3D.controls.autoRotate = !DesignRoot.Main3D.controls
            .autoRotate
        } else if (e.code === 'KeyX') {
          e.preventDefault()
          DesignRoot.fabric_function.clippingSvg()
        }
      } // END // DEV TOOL
      else if (e.altKey === true) {
        if (e.code === 'Numpad1') {
          e.preventDefault()
          DesignRoot.fabric_function.render_quality_option(1)
        } else if (e.code === 'Numpad2') {
          e.preventDefault()
          DesignRoot.fabric_function.render_quality_option(2)
        } else if (e.code === 'Numpad3') {
          e.preventDefault()
          DesignRoot.fabric_function.render_quality_option(3)
        } else if (e.code === 'KeyQ') {
          e.preventDefault()

          let scaleAdjust = prompt('Adjust quality (Int or float only)')

          if (isNaN(scaleAdjust)) {
            alert('Must input numbers')
          } else {
            DesignRoot.fabric_function.render_quality_option(scaleAdjust)
          }
        }
      }
    } // END IF DesignRoot
  } catch (e) {
    // statements
    logger.error('keyboard_event.onKeydown.error', e)
  }
}

export function onMouseWheel(e) {
  try {
    if (DesignRoot.isDashboard) {
      logger.log('onMouseWheel', e)

      if (e.altKey === true) {
        e.preventDefault()
        e.stopPropagation()

        logger.log('mouse:wheel', e, e.deltaY, DesignRoot.canvas)

        var delta = e.deltaY
        var zoom = DesignRoot.canvas.getZoom()

        if (delta < 0) {
          // ZOMM IN

          zoom += 0.01
        } else if (delta > 0) {
          // ZOOM OUT

          zoom -= 0.01
        }

        DesignRoot._CANVAS_SIZE = zoom
        DesignRoot.canvas.setZoom(zoom)

        DesignRoot.canvas.setDimensions({
          width: DesignRoot._CANVAS_RENDER.width * DesignRoot._CANVAS_SIZE,
          height: DesignRoot._CANVAS_RENDER.height * DesignRoot._CANVAS_SIZE
        })
        DesignRoot._TRIGGER_RENDER = true
        DesignRoot.fabric_function.renderCanvas()
      } else if (e.ctrlKey === true) {
        e.preventDefault()
        e.stopPropagation()

        var PLANE_2D = document.querySelector('#PLANE_2D')

        if (e.deltaY > 0) PLANE_2D.scrollLeft += 50
        else PLANE_2D.scrollLeft -= 50
      }
    }
  } catch (e) {
    // statements
    logger.error('keyboard_event.onMouseWheel.error', e)
  }
}
