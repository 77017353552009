import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import { ADMIN_GET_SHOP_SETTINGS } from '../../gql/admin-queries';
import { ADMIN_CREATE_DESIGN_STEP } from '../../gql/admin-mutations';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _cloneDeep from 'lodash/cloneDeep';
import { countries } from '../../../../helper/countryList';
import TextStepSettings from './TextStepSettings';
import LogoStepSettings from './LogoStepSettings';
import FabricStepSettings from './FabricStepSettings';
import ColorStepSettings from './ColorStepSettings';
var omitDeep = require('omit-deep');
const styles = theme => ({
  root: { overflowX: 'auto' },
  paper: {
    position: 'absolute',
    width:theme.spacing(50),
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    padding:theme.spacing(4)
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    marginTop: '24px !important',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  }
});

const steps = [
  {
    value: 'Fabric',
    label: 'Fabric'
  },
  {
    value: 'Text',
    label: 'Text'
  },
  {
    value: 'Printing',
    label: 'Printing'
  },
  {
    value: 'Payment',
    label: 'Payment'
  }
];

class DesignStudioSettings extends Component {
  state = {
    newDesignStep: 'TEXT',
    step: 'Fabric'
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { classes, shopId } = this.props;
    return <div>Coming soon</div>;
    return (
      <Grid container style={{ padding: '60px 24px' }}>
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
        >
          <Typography
            style={{
              fontSize: 28,
              padding: 25,
              fontWeight: 'medium',
              color: '#424242'
            }}
          >
            Design studio
          </Typography>
        </Grid>
        <Query query={ADMIN_GET_SHOP_SETTINGS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            if (loading) return <div>Loading...</div>;
            if (error) return <div>Error :(</div>;
            const printingTechniques = _get(
              shop,
              'printingTechniques.edges',
              []
            );
            return (
              <Mutation
                update={(store, { data: { createDesignStep } }) => {
                  const data = store.readQuery({
                    query: ADMIN_GET_SHOP_SETTINGS,
                    variables: { id: shopId }
                  });
                  store.writeQuery({
                    query: ADMIN_GET_SHOP_SETTINGS,
                    variables: { id: shopId },
                    data: {
                      ...data,
                      shop: {
                        ...data.shop,
                        designStudioSettings: {
                          ...data.shop.designStudioSettings,
                          designSteps: [
                            ...data.shop.designStudioSettings.designSteps,
                            createDesignStep
                          ]
                        }
                      }
                    }
                  });
                }}
                mutation={ADMIN_CREATE_DESIGN_STEP}
              >
                {(createDesignStep, { data, loading, error }) => {
                  return (
                    <Grid item xs={12}>
                      <h3>Design steps</h3>

                      {shop.designStudioSettings
                        ? _sortBy(
                            _get(shop, 'designStudioSettings.designSteps', []),
                            ['stepSortOrder']
                          ).map((step, stepIndex) => {
                            switch (_get(step, 'type', '')) {
                              case 'PLACEMENTPRINT':
                                return <div>'PLACEMENTPRINT'</div>;
                              case 'LOGO':
                                return (
                                  <div>
                                    Logo:
                                    <LogoStepSettings
                                      printingTechniques={printingTechniques}
                                      stepId={step.id}
                                    />
                                  </div>
                                );
                              case 'TEXT':
                                return (
                                  <div>
                                    Text:
                                    <TextStepSettings
                                      printingTechniques={printingTechniques}
                                      stepId={step.id}
                                    />
                                  </div>
                                );
                              case 'ALLOVERPRINT':
                                return <div>'ALLOVERPRINT'</div>;
                              case 'LABEL':
                                return <div>'LABEL'</div>;
                              case 'FABRIC':
                                return (
                                  <div>
                                    Fabric:
                                    <FabricStepSettings
                                      printingTechniques={printingTechniques}
                                      stepId={step.id}
                                    />
                                  </div>
                                );
                              case 'COLOR':
                                return (
                                  <div>
                                    Color:
                                    <ColorStepSettings
                                      printingTechniques={printingTechniques}
                                      stepId={step.id}
                                    />
                                  </div>
                                );
                              case 'APPLIQUE':
                                return <div>'APPLIQUE'</div>;
                              case 'COMMENT':
                                return <div>'COMMENT'</div>;
                              case 'PLAIN':
                                return <div>'PLAIN'</div>;

                              default:
                                return null;
                            }
                          })
                        : null}
                      <br />
                      <br />
                      <div>
                        <select
                          value={this.state.newDesignStep || 'TEXT'}
                          onChange={e => {
                            this.setState({
                              newDesignStep: e.target.value
                            });
                          }}
                        >
                          <option value="PLACEMENTPRINT">PLACEMENTPRINT</option>
                          <option value="LOGO">LOGO</option>
                          <option value="TEXT">TEXT</option>
                          <option value="ALLOVERPRINT">ALLOVERPRINT</option>
                          <option value="LABEL">LABEL</option>
                          <option value="FABRIC">FABRIC</option>
                          <option value="COLOR">COLOR</option>
                          <option value="APPLIQUE">APPLIQUE</option>
                          <option value="COMMENT">COMMENT</option>
                          <option value="PLAIN">PLAIN</option>
                        </select>
                        {'  '}
                        <button
                          onClick={() => {
                            createDesignStep({
                              optimisticResponse: {
                                __typename: 'Mutation',
                                createDesignStep: {
                                  __typename: 'ShopDesignStep',
                                  type: this.state.newDesignStep
                                }
                              },
                              variables: {
                                shopId: shopId,
                                type: this.state.newDesignStep
                              }
                            });
                          }}
                        >
                          Add new design step
                        </button>
                      </div>
                    </Grid>
                  );
                }}
              </Mutation>
            );
          }}
        </Query>
      </Grid>
    );
  }
}

export default withStyles(styles)(DesignStudioSettings);
