import React, { useContext } from 'react'
import { getTextFromLanguage } from '../../../../../../../helper/languageHelper'
import { NativeSelect } from '@material-ui/core'
import {
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_SHOP_PRODUCTS,
  ADMIN_SHOP_TEAMSTORES
} from '../../../../../gql/admin-queries'
import { useQuery } from '@apollo/react-hooks'
import { ShopContext, SelectedLanguageContext } from '../../LanguageContext'
import { ADMIN_GET_CONTENT_MODELS } from '../../gql/queries'

interface Props {
  type: 'PRODUCT' | 'COLLECTION' | 'TEAMSTORE' | 'PAGE'
  selected: any
  setSelected: any
}

const UrlSelect: React.FC<Props> = props => {
  const { type, selected, setSelected } = props

  const shopId = useContext(ShopContext)
  const selectedLanguage = useContext(SelectedLanguageContext)

  const queries = {
    PRODUCT: { query: ADMIN_SHOP_PRODUCTS, res: 'products' },
    COLLECTION: { query: ADMIN_SHOP_COLLECTIONS, res: 'collections' },
    TEAMSTORE: { query: ADMIN_SHOP_TEAMSTORES, res: 'salesChannels' },
    PAGE: { query: ADMIN_GET_CONTENT_MODELS, res: 'contentModels' }
  }

  const { data, loading } = useQuery(queries[type].query, {
    variables:
      type === 'PAGE'
        ? { id: shopId, salesChannelId: 'MYBRIKL' }
        : { id: shopId }
  })
  if (loading) return <span>Loading..</span>
  if (!data) return null

  return (
    <>
      <NativeSelect
        value={selected.type === type ? selected.value : type}
        onChange={(e: any) => {
          setSelected({ value: e.target.value, type: type, id: e.target.value })
        }}
        style={{ border: '1px solid lightgrey', borderRadius: 2 }}
        name="age"
      >
        <option value={type}>{type}</option>
        {type !== 'PAGE'
          ? data?.shop[queries[type]?.res]?.edges?.map((item: any) => (
              <option
                key={item.node.id}
                value={getTextFromLanguage(item?.node?.slugs, selectedLanguage)}
              >
                {getTextFromLanguage(item.node.slugs, selectedLanguage)}
              </option>
            ))
          : data?.shop[queries[type]?.res].map((item: any) => (
              <option
                key={item.id}
                value={getTextFromLanguage(item?.slugs, selectedLanguage)}
              >
                {getTextFromLanguage(item.slugs, selectedLanguage)}
              </option>
            ))}
      </NativeSelect>
    </>
  )
}

export default UrlSelect
