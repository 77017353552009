import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Paper, Button, Checkbox } from '@material-ui/core';
import Input from '../../common/Input';
import { SHOP_ID, API_STAGE } from '../../../../brikl-config';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Localization } from '../../../lang/localization';
const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 24
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10
  },
  appbar: {
    background: '#000',
    color: '#fff'
  },
  maxWidth: {
    maxWidth: '1140px',
    width: '100%',
    margin: 'auto'
  },
  left: {
    marginRight: '12px',
    width: 'calc(100% - 12px)'
  },
  right: {
    marginLeft: '12px',
    width: 'calc(100% - 12px)'
  },
  shopcard: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100% !important',
      width: '100% !important'
    }
  }
});

class SubscribeCard extends React.Component {
  state = {
    status: null
  };
  render() {
    const {
      title,
      content,
      callToAction,
      url,
      label,
      placeholder,
      width,
      height,
      termsContent,
      hasTerms,
      isAccepted,
      hasInput,
      classes
    } = this.props;
    return (
      <MailchimpSubscribe
        url={
          SHOP_ID === 'vermarc' && API_STAGE === 'production'
            ? 'https://vermarcsport.us20.list-manage.com/subscribe/post?u=c796d751bb3c3984170324d2e&id=a3c7a1d38f'
            : 'https://brikl.us15.list-manage.com/subscribe/post?u=e8eddf03585b900643395634b&id=fe89831396'
        }
        render={({ subscribe, status, message }) =>
          status === 'success' ? (
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.shopcard}
              style={{
                border: '1px solid #E5E5E5',
                height: 'auto',
                padding: '2em 0',
                background: '#fff',
                borderRadius: 0
              }}
            >
              Subscribed. Thank you.
            </Grid>
          ) : (
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.shopcard}
              style={{
                border: '1px solid #E5E5E5',
                height: 'auto',
                padding: '2em 0',
                background: '#fff',
                borderRadius: 0
              }}
            >
              <Grid
                container
                direction="column"
                style={{ maxWidth: '100%', width: '100%', padding: '0px 16px' }}
                spacing={3}
                className={classes.shopcard}
              >
                {/* {status === 'sending' && (
                <div style={{ color: 'blue' }}>sending...</div>
              )} */}
                {status === 'error' && (
                  <div
                    style={{ color: 'red' }}
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                )}
                {title === '' ? null : (
                  <Grid item xs={12}>
                    <Typography data-cy="subscribe-card-title" variant="h4">
                      {title}
                    </Typography>
                  </Grid>
                )}

                {content === '' ? null : (
                  <Grid item xs={12}>
                    <Typography
                      data-cy="subscribe-card-content"
                      variant="body1"
                    >
                      {content}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container alignItems="flex-end" spacing={2}>
                    {SHOP_ID === 'vermarc' ? (
                      <>
                        <Grid item xs={12}>
                          <Input
                            autoComplete="none"
                            onChange={e =>
                              this.setState({ EMAIL: e.target.value })
                            }
                            value={this.state.EMAIL}
                            label={'Email'}
                            placeholder={''}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Input
                            autoComplete="none"
                            onChange={e =>
                              this.setState({ FNAME: e.target.value })
                            }
                            value={this.state.FNAME}
                            label={'First Name'}
                            placeholder={''}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Input
                            autoComplete="none"
                            onChange={e =>
                              this.setState({ LNAME: e.target.value })
                            }
                            value={this.state.LNAME}
                            label={'Last Name'}
                            placeholder={''}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">Interest</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginLeft: '8px'
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.wantsCycling}
                                onChange={e => {
                                  this.setState({
                                    wantsCycling: e.target.checked
                                  });
                                }}
                                style={{
                                  color: '#3f3f3f',
                                  padding: '0px',
                                  marginRight: 8
                                }}
                              />
                            }
                            label="Cycling"
                          />
                          <br />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.wantsAthletics}
                                onChange={e => {
                                  this.setState({
                                    wantsAthletics: e.target.checked
                                  });
                                }}
                                style={{
                                  color: '#3f3f3f',
                                  padding: '0px',
                                  marginRight: 8
                                }}
                              />
                            }
                            label="Athletics"
                          />
                          <br />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.wantsFootball}
                                onChange={e => {
                                  this.setState({
                                    wantsFootball: e.target.checked
                                  });
                                }}
                                style={{
                                  color: '#3f3f3f',
                                  padding: '0px',
                                  marginRight: 8
                                }}
                              />
                            }
                            label="Football"
                          />
                        </Grid>
                      </>
                    ) : hasInput ? (
                      <Grid item xs={8}>
                        <Input
                          autoComplete="none"
                          dataCy="subscribe-card-input"
                          label={label}
                          placeholder={placeholder}
                          fullWidth
                        />
                      </Grid>
                    ) : null}
                    <hr />
                    {hasTerms ? (
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Checkbox
                            checked={this.state.termsIsAccepted}
                            onChange={e =>
                              this.setState({
                                termsIsAccepted: e.target.checked
                              })
                            }
                            style={{
                              color: '#3f3f3f',
                              padding: '0px',
                              marginRight: 8
                            }}
                          />
                          <Typography
                            data-cy="subscribe-card-add-privacy-policy"
                            variant="body1"
                            style={{ fontSize: '14px' }}
                          >
                            <a
                              target="_blank"
                              href={SHOP_ID === 'vermarc' ? '/en/privacy' : ''}
                              style={{ color: 'blue' }}
                            >
                              {Localization.get(
                                'i_have_read_and_have_approved_the_privacy_policy'
                              )}
                            </a>
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : null}

                    {callToAction === '' ? null : (
                      <Grid item xs={4}>
                        <Button
                          data-cy="subscribe-card-button-text"
                          disabled={status === 'sending' ? true : false}
                          onClick={() => {
                            if (SHOP_ID === 'vermarc') {
                              if (
                                this.state.EMAIL &&
                                this.state.EMAIL !== '' &&
                                this.state.EMAIL.indexOf('@') !== -1 &&
                                this.state.termsIsAccepted === true
                              ) {
                                let mailChimpObject = {
                                  EMAIL: this.state.EMAIL,
                                  FNAME: this.state.FNAME,
                                  LNAME: this.state.LNAME
                                };
                                if (this.state.wantsCycling === true) {
                                  if (API_STAGE === 'production') {
                                    mailChimpObject['group[3173][1]'] = 1;
                                  } else {
                                    mailChimpObject['group[7011][1]'] = 1;
                                  }
                                }
                                if (this.state.wantsAthletics === true) {
                                  if (API_STAGE === 'production') {
                                    mailChimpObject['group[3173][2]'] = 1;
                                  } else {
                                    mailChimpObject['group[7011][2]'] = 1;
                                  }
                                }
                                if (this.state.wantsFootball === true) {
                                  if (API_STAGE === 'production') {
                                    mailChimpObject['group[3173][4]'] = 1;
                                  } else {
                                    mailChimpObject['group[7011][4]'] = 1;
                                  }
                                }
                                subscribe(mailChimpObject);
                              } else {
                                alert(
                                  'Please fill all fields and accept the terms'
                                );
                              }
                            } else {
                              alert('Configurate Mailchimp');
                            }
                          }}
                          target="_blank"
                          variant="outlined"
                        >
                          {status === 'sending' ? 'Sending...' : callToAction}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        }
      />
    );
  }
}
SubscribeCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SubscribeCard);
