import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { ADMIN_SHOP_MEDIA } from '../../gql/admin-queries';
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../../brikl-config';
import { Grid, TextField, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import LazyLoad from 'react-lazyload';
import Imgix from 'react-imgix';
import _uniqBy from 'lodash/uniqBy';
const styles = theme => ({
  progress: {},
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%',
    height: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242'
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  cell: {
    padding: '0px 16px !important'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  mediaItem: {
    height: '150px',
    width: '100%',
    border: '1px solid #eee',
    borderRadius: 5,
    cursor: 'pointer',
    padding: '.5em',
    '&:hover': {
      opacity: 0.8,
      color: '#FFFFFF'
    }
  },
  list: {
    '&:hover': {
      backgroundColor: '#eee'
    }
  }
});

const MediaList = ({
  classes,
  showDownload,
  onSelect,
  filterByName,
  viewMode,
  selected,
  shopId,
  onMultiSelect
}) => {
  return (
    <Query
      query={ADMIN_SHOP_MEDIA}
      variables={{
        id: shopId
      }}
    >
      {({ loading, error, data: { shop } }) => {
        if (loading) return <CircularProgress className={classes.progress} />;
        if (error) return <div>Error :(</div>;
        const filteredMedia = _uniqBy(
          _get(shop, 'media.edges', []).filter(m => {
            try {
              return m.node.fileName.toLowerCase().includes(filterByName)
                ? true
                : false;
            } catch (error) {
              return false;
            }
          }),
          function(e) {
            return e.node.fileName;
          }
        );
        return (
          <div>
            {filteredMedia.length > 0 ? (
              <Paper>
                <Grid container justify="space-around">
                  {filteredMedia
                    .sort(
                      (fm1, fm2) =>
                        fm1.node.fileName.substring(
                          fm1.node.fileName.lastIndexOf('/') + 1
                        ) -
                        fm2.node.fileName.substring(
                          fm2.node.fileName.lastIndexOf('/') + 1
                        )
                    )
                    .map(m => {
                      const fileName = m.node.fileName.substring(
                        m.node.fileName.lastIndexOf('/') + 1
                      );
                      const image = (
                        <Imgix
                          height={150}
                          htmlAttributes={{
                            loading: 'lazy',
                            title: m.node.fileName,
                            style: {
                              objectFit: 'contain',
                              width: '100%',
                              height: '100%'
                            }
                          }}
                          src={(
                            MEDIA_CDN_URL +
                            `/${shopId + '/media/' + m.node.fileName}`
                          ).replace(MEDIA_CDN_URL, IMGIX_URL)}
                          imgixParams={{
                            auto: 'compress'
                          }}
                        />
                      );
                      const download =
                        showDownload === true ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              margin: '10px auto',
                              display: 'block',
                              width: '100%',
                              textAlign: 'center'
                            }}
                            href={
                              MEDIA_CDN_URL +
                              `/${shopId + '/media/' + m.node.fileName}`
                            }
                            download
                          >
                            Download
                          </Button>
                        ) : null;
                      if (viewMode === 'LIST') {
                        return (
                          <Grid
                            xs={12}
                            key={m.node.id}
                            item
                            className={classes.list}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: 20,
                              left:
                                selected && selected.includes(m.node.fileName)
                                  ? 30
                                  : 'inherit',
                              position:
                                selected && selected.includes(m.node.fileName)
                                  ? 'fixed'
                                  : 'inherit',
                              top:
                                selected && selected.includes(m.node.fileName)
                                  ? selected.indexOf(m.node.fileName) * 80 + 50
                                  : 'inherit',
                              borderBottom: '1px solid #CCCCCC',
                              height: 80,
                              width: '100%'
                            }}
                          >
                            <Grid container alignItems="center">
                              <Grid item>
                                <input
                                  style={{
                                    margin: '1em'
                                  }}
                                  onChange={e => {
                                    onMultiSelect(m.node, e.target.checked);
                                  }}
                                  type="checkbox"
                                  checked={
                                    selected
                                      ? selected.includes(m.node.fileName)
                                      : ''
                                  }
                                />
                              </Grid>

                              <Grid item>
                                <div
                                  style={{
                                    width: '60px',
                                    height: '60px',
                                    margin: '1em'
                                  }}
                                >
                                  {image}
                                </div>
                              </Grid>

                              <Grid item xs={8}>
                                <Typography variant="body1">
                                  {fileName}
                                </Typography>
                              </Grid>

                              <Grid item style={{ marginLeft: 'auto' }}>
                                <div>{download}</div>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid
                            xs={3}
                            key={m.node.id}
                            item
                            style={{
                              background:
                                selected && selected.includes(m.node.fileName)
                                  ? 'white'
                                  : 'inherit',
                              left:
                                selected && selected.includes(m.node.fileName)
                                  ? 30
                                  : 'inherit',
                              position:
                                selected && selected.includes(m.node.fileName)
                                  ? 'fixed'
                                  : 'inherit',
                              top:
                                selected && selected.includes(m.node.fileName)
                                  ? selected.indexOf(m.node.fileName) * 150 + 50
                                  : 'inherit',
                              border: '1px solid #CCCCCC',
                              borderRadius: 5,
                              padding: 10,
                              margin: 30
                            }}
                          >
                            {/* <LazyLoad height={100}> */}

                            <Grid container direction="row" alignItems="center">
                              <Grid item xs={2}>
                                <input
                                  style={{
                                    margin: '1em'
                                  }}
                                  onChange={e => {
                                    onMultiSelect(m.node, e.target.checked);
                                  }}
                                  type="checkbox"
                                  checked={
                                    selected
                                      ? selected.includes(m.node.fileName)
                                      : ''
                                  }
                                />
                              </Grid>

                              <Grid item xs={10}>
                                <Typography variant="body1">
                                  {fileName.substring(0, 20)}...
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <div
                                  className={classes.mediaItem}
                                  onClick={() => {
                                    try {
                                      onSelect(fileName);
                                    } catch (error) {}
                                  }}
                                >
                                  {image}
                                </div>
                              </Grid>

                              {/* </LazyLoad> */}
                              <Grid item xs={12}>
                                {download}
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </Paper>
            ) : (
              <div>No media yet</div>
            )}
          </div>
        );
      }}
    </Query>
  );
};

export default withStyles(styles)(MediaList);
