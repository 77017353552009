import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography, Switch } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import Slider from '@material-ui/core/Slider';

import { BORDER_TYPES } from './config';

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase);

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  input: {
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  }
});

class UpdateSubscribeCard extends React.Component {
  state = {
    uploading: false
  };

  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,
      handleChangeTextField,
      fields,
      languages
    } = this.props;

    return (
      <ExpansionPanel
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
        expanded={expanded === 'panel2'}
        onChange={handleExpandedChange('panel2')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ expanded: classes.expansionPanelExpanded }}
        >
          <Typography
            variant="subtitle1"
            className={classes.typo}
            data-cy="subscribe-card-settings"
          >
            Subscribe Card Settings
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{ root: classes.expansionPanelDetails }}
        >
          <Divider />
          <List>
            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="subscribe-card-width-label"
                  style={{ marginBottom: 10 }}
                  shrink
                  className={classes.FormLabel}
                >
                  Width - {fields.width} %
                </InputLabel>
                <Grid container={true} justify="space-between">
                  <Slider
                    data-cy="subscribe-card-width-slider"
                    className={styles.slider}
                    value={parseInt(fields.width)}
                    aria-labelledby="slider-label"
                    step={1}
                    min={0}
                    max={100}
                    onChange={(e, value) => handleChangeField(value, 'width')}
                  />
                </Grid>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="subscribe-card-height-label"
                  style={{ marginBottom: 10 }}
                  shrink
                  className={classes.FormLabel}
                >
                  Height - {fields.height}
                </InputLabel>
                <Grid container={true} justify="space-between">
                  <Slider
                    data-cy="subscribe-card-height-slider"
                    className={styles.slider}
                    value={parseInt(fields.height)}
                    aria-labelledby="slider-label"
                    step={1}
                    min={0}
                    max={1000}
                    onChange={(e, value) => handleChangeField(value, 'height')}
                  />
                </Grid>
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="subscribe-card-title-label"
                  shrink
                  className={classes.FormLabel}
                >
                  Title
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(fields.title.text, language) || '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          data-cy={'subscribe-card-title-' + language}
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'title'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="subscribe-card-content-label"
                  shrink
                  className={classes.FormLabel}
                >
                  Content
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(fields.content.text, language) || '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          data-cy={'subscribe-card-content-' + language}
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'content'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="subscribe-card-button-text-label"
                  shrink
                  className={classes.FormLabel}
                >
                  Button text
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(fields.callToAction.text, language) ||
                      '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          data-cy={'subscribe-card-button-text-' + language}
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'callToAction'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="subscribe-card-label-label"
                  shrink
                  className={classes.FormLabel}
                >
                  Label
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(fields.label.text, language) || '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          data-cy={'subscribe-card-label-' + language}
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'label'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="subscribe-card-placeholder-label"
                  shrink
                  className={classes.FormLabel}
                >
                  Placeholder
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(fields.placeholder.text, language) ||
                      '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          data-cy={'subscribe-card-placeholder-' + language}
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'placeholder'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel
                  data-cy="subscribe-card-add-privacy-policy-label"
                  shrink
                  className={classes.FormLabel}
                >
                  Add Privacy Policy?
                </InputLabel>
                <Switch
                  data-cy="subscribe-card-add-privacy-policy-switch"
                  checked={fields.hasTerms}
                  onChange={e =>
                    handleChangeField(
                      e.target.checked ? true : false,
                      'hasTerms'
                    )
                  }
                  value="checkedA"
                />
              </Grid>
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

UpdateSubscribeCard.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
};

export default withStyles(styles)(UpdateSubscribeCard);
