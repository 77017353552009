import React from 'react';

export const UserContext = React.createContext();

class UserProvider extends React.Component {
  constructor(props) {
    super(props);
    this.signOut = () => {
      this.setState(() => {
        return {
          id: null,
          email: null,
          country: 'BE',
          defaultCurrency: 'THB',
          defaultLanguage: 'en'
        };
      });
    };
    this.changeId = id => {
      this.setState(state => {
        return {
          id
        };
      });
    };
    this.changeCurrency = currency => {
      this.setState(state => {
        return {
          defaultCurrency: currency
        };
      });
    };
    this.changeCountry = country => {
      this.setState(state => {
        return {
          country: country
        };
      });
    };
    this.changeEmail = email => {
      // console.log('UserProvider.changeEmail.email', email)
      this.setState(state => {
        return {
          email
        };
      });
    };

    this.state = {
      id: null,
      email: null,
      country: null,
      changeCountry: this.changeCountry,
      changeId: this.changeId,
      changeEmail: this.changeEmail,
      signOut: this.signOut
    };
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
