import React, { Component } from 'react'
import { Link } from '@reach/router'
import { Query } from 'react-apollo'
import Grid from '@material-ui/core/Grid'
import { ADMIN_SHOP_CUSTOM_PRODUCTS } from '../../gql/admin-queries'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CREATE_PRODUCT_DESIGN } from '../../../../gql/mutations'
import client from '../../gql/admin-apollo-config'
import { OrganisationContext } from '../../../user/context/OrganisationContext'
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography'
// import Button from '@material-ui/core/Button';
import _get from 'lodash/get'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import Imgix from 'react-imgix'
import { IMGIX_URL, MEDIA_CDN_URL } from '~/brikl-config'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%',
    padding: '0'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242',
    flex: 1
  }
})
class DesignroomProductList extends Component {
  render() {
    const { classes, defaultLanguage, shopId, styles } = this.props
    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Typography
            component="h1"
            className={classes.h1}
            data-cy="Header-Designroom-Products"
          >
            Designroom - Products
          </Typography>
        </Grid>
        <Query query={ADMIN_SHOP_CUSTOM_PRODUCTS} variables={{ id: shopId }}>
          {({ loading, error, data }) => {
            if (loading) return <CircularProgress />
            if (error) return <div>Error :(</div>
            var customProducts = []
            try {
              customProducts = data.adminSearchCustomProducts
            } catch (error) {}
            return (
              <div>
                {customProducts.length > 0 ? (
                  <Paper>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Title</TableCell>
                          <TableCell>Thumbnail</TableCell>
                          <TableCell>Name</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {customProducts.map(product => {
                          let thumbnail
                          try {
                            thumbnail = product.media
                              .find(mf => mf && mf.isThumbnail === true)
                              .image.replace(MEDIA_CDN_URL, IMGIX_URL)
                          } catch (error) {}
                          return (
                            <TableRow
                              onClick={async () => {
                                const createProductDesignData = await client.mutate(
                                  {
                                    mutation: CREATE_PRODUCT_DESIGN,
                                    variables: {
                                      designStudioVersion: 'V2',
                                      salesChannelId: 'MYBRIKL',
                                      shopId: shopId,
                                      baseProductId: product.id,
                                      // copyFrom: copyFrom,
                                      ownedByOrganisationId: shopId + '-ADMIN'
                                    }
                                  }
                                )

                                window.open(
                                  `/admin/${shopId}/designroom/room/?s=${shopId}&p=${
                                    product.id
                                  }&pd=${
                                    createProductDesignData.data
                                      .createProductDesign.id
                                  }&apptype=2d-3d&o=${shopId + '-ADMIN'}`
                                )
                              }}
                              key={product.id}
                              component="div"
                              hover
                              style={{
                                textDecoration: 'none',
                                cursor: 'pointer'
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {getTextFromLanguage(
                                  _get(product, 'title.text', []),
                                  defaultLanguage
                                )}
                              </TableCell>
                              <TableCell>
                                {thumbnail ? (
                                  <Imgix
                                    height={50}
                                    width={50}
                                    htmlAttributes={{
                                      alt: '',
                                      style: {
                                        width: '50px',
                                        height: '50px',
                                        objectFit: 'contain'
                                      }
                                    }}
                                    src={thumbnail}
                                    imgixParams={{
                                      auto: 'compress'
                                    }}
                                  />
                                ) : null}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {product.no}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <div>No products yet</div>
                )}
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withStyles(styles)(DesignroomProductList)
