import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TeamstoreProductCustomizationOption from './TeamstoreProductCustomizationOption';
import _cloneDeep from 'lodash/cloneDeep';
import omitDeep from 'omit-deep';
import uuid from 'uuid';
const TeamstoreProductCustomizationOptionGroup = ({
  classes,
  languages,
  defaultLanguage,
  teamstore,
  teamstoreId,
  updateTeamstore,
  targetCategory,
  teamstoreProductCustomizationOptionGroup,
  defaultCurrency
}) => {
  return (
    <div
      style={{
        padding: '1em',
        margin: '1em 0',
        border: '1px solid #ccc'
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          const newOption = {
            __typename: 'ProductCustomizationOption',
            id: uuid.v4(),
            type: 'TEXT',
            title: {
              id: uuid.v4(),
              text: [
                {
                  langCode: defaultLanguage,
                  content: 'Name'
                }
              ]
            },
            pricingRules: [
              {
                __typename: 'PricingRule',
                id: uuid.v4(),
                formula: {
                  __typename: 'PricingRuleFormula',
                  priority: 0,
                  price: {
                    currency: defaultCurrency,
                    value: parseFloat(1.0)
                  }
                },
                linkedType: 'TEXT',
                calculationType: 'PERPRODUCT'
              }
            ]
          };
          updateTeamstore({
            optimisticResponse: {
              __typename: 'Mutation',
              ...teamstore,
              customizationSettings: {
                ...teamstore.customizationSettings,
                customizationOptionGroups: teamstore.customizationSettings.customizationOptionGroups.map(
                  tempCustomizationOptionGroup1 => {
                    return {
                      ...tempCustomizationOptionGroup1,
                      options:
                        tempCustomizationOptionGroup1.id ===
                        teamstoreProductCustomizationOptionGroup.id
                          ? [
                              ...tempCustomizationOptionGroup1.options,
                              newOption
                            ]
                          : tempCustomizationOptionGroup1.options
                    };
                  }
                )
              }
            },
            variables: {
              input: {
                customizationSettings: {
                  customizationOptionGroups: _cloneDeep(
                    teamstore.customizationSettings.customizationOptionGroups
                  ).map(tc =>
                    omitDeep(
                      {
                        ...tc,
                        options:
                          tc.id === teamstoreProductCustomizationOptionGroup.id
                            ? [...tc.options, newOption]
                            : tc.options
                      },
                      ['__typename']
                    )
                  )
                }
              }
            }
          });
        }}
      >
        Add option
      </Button>
      <p>
        Each group can be assigned to one person e.g. 1 person = 1 name, 1
        number, etc.
      </p>
      {teamstoreProductCustomizationOptionGroup.options.map(
        (teamstoreProductCustomizationOption, index) => {
          return (
            <div
              key={'tpc-' + index}
              style={{
                padding: '1em',
                margin: '1em 0',
                border: '1px solid #ccc'
              }}
            >
              <TeamstoreProductCustomizationOption
                classes={classes}
                languages={languages}
                defaultCurrency={defaultCurrency}
                teamstore={teamstore}
                teamstoreId={teamstoreId}
                updateTeamstore={updateTeamstore}
                teamstoreProductCustomizationOptionGroup={
                  teamstoreProductCustomizationOptionGroup
                }
                teamstoreProductCustomizationOption={
                  teamstoreProductCustomizationOption
                }
              />
              <Button
                style={{
                  margin: '0 0 0 auto',
                  display: 'block'
                }}
                onClick={() => {
                  updateTeamstore({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      ...teamstore,
                      customizationSettings: {
                        ...teamstore.customizationSettings,
                        customizationOptionGroups: teamstore.customizationSettings.customizationOptionGroups.map(
                          tempCustomizationOptionGroup1 => {
                            return {
                              ...tempCustomizationOptionGroup1,
                              options:
                                tempCustomizationOptionGroup1.id ===
                                teamstoreProductCustomizationOptionGroup.id
                                  ? tempCustomizationOptionGroup1.options.filter(
                                      tempCustomizationOption11 => {
                                        return tempCustomizationOption11.id !==
                                          teamstoreProductCustomizationOption.id
                                          ? true
                                          : false;
                                      }
                                    )
                                  : tempCustomizationOptionGroup1.options
                            }
                              ? true
                              : false;
                          }
                        )
                      }
                    },
                    variables: {
                      input: {
                        customizationSettings: {
                          customizationOptionGroups: [
                            ..._cloneDeep(
                              teamstore.customizationSettings
                                .customizationOptionGroups
                            ).map(tempCustomizationOptionGroup1 => {
                              return omitDeep(
                                {
                                  ...tempCustomizationOptionGroup1,
                                  options:
                                    tempCustomizationOptionGroup1.id ===
                                    teamstoreProductCustomizationOptionGroup.id
                                      ? tempCustomizationOptionGroup1.options.filter(
                                          tempCustomizationOption11 => {
                                            return tempCustomizationOption11.id !==
                                              teamstoreProductCustomizationOption.id
                                              ? true
                                              : false;
                                          }
                                        )
                                      : tempCustomizationOptionGroup1.options
                                },
                                ['__typename']
                              );
                            })
                          ]
                        }
                      }
                    }
                  });
                }}
              >
                Delete option
              </Button>
            </div>
          );
        }
      )}
      <Button
        style={{
          margin: '0 0 0 auto',
          display: 'block'
        }}
        onClick={() => {
          updateTeamstore({
            optimisticResponse: {
              __typename: 'Mutation',
              updateTeamstore: {
                ...teamstore,
                settings: teamstore.settings,
                pages: teamstore.pages,
                parentShop: teamstore.parentShop,
                shop: teamstore.shop,
                customizationSettings: {
                  ...teamstore.customizationSettings,
                  customizationOptionGroups: teamstore.customizationSettings.customizationOptionGroups.filter(
                    tempCustomizationOptionGroup1 => {
                      return tempCustomizationOptionGroup1.id !==
                        teamstoreProductCustomizationOptionGroup.id
                        ? true
                        : false;
                    }
                  )
                }
              }
            },
            variables: {
              input: {
                customizationSettings: {
                  customizationOptionGroups: [
                    ..._cloneDeep(
                      teamstore.customizationSettings.customizationOptionGroups
                    )
                      .filter(tempCustomizationOptionGroup2 => {
                        return tempCustomizationOptionGroup2.id !==
                          teamstoreProductCustomizationOptionGroup.id
                          ? true
                          : false;
                      })
                      .map(tc => omitDeep(tc, ['__typename']))
                  ]
                }
              }
            }
          });
        }}
      >
        Delete group
      </Button>
    </div>
  );
};
export default TeamstoreProductCustomizationOptionGroup;
