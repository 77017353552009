import React, { Component } from 'react'
import { API_STAGE } from '../../../../brikl-config'
import Select from '../../common/Select'
import { Grid, Typography, Button } from '@material-ui/core'

class MarkerLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectSize: null
    }
  }

  componentDidMount() {
    // AFTER 1ST RENDER
    // PROVIDE COMPONENT TO MAIN
    var DesignRoot = this.props.DesignRoot
    DesignRoot.MarkerLayout = this
    DesignRoot.setState({ update: true })
    // PROVIDE COMPONENT TO MAIN
  }

  buildMarkerLayout() {
    try {
      // statements
      var DesignRoot = this.props.DesignRoot

      if (this.state.selectSize !== null) {
        // BUILD MARKER

        DesignRoot.marker_layout_control.cliping(this.state.selectSize)

        // BUILD MARKER

        DesignRoot.setState({ activeML: true })
      }
    } catch (e) {
      // statements
      logger.error('MarkerLayout.buildMarkerLayout.error', e)
    }
  }

  clipingMask() {
    try {
      // statements
      var DesignRoot = this.props.DesignRoot

      if (this.state.selectSize !== null) {
        DesignRoot.marker_layout_control.clipingMask(this.state.selectSize)
        // DesignRoot.setState({ activeML: true });
      }
    } catch (e) {
      // statements
      logger.error('MarkerLayout.clipingMask.error', e)
    }
  }

  clipingMaskCancel() {
    var DesignRoot = this.props.DesignRoot
    DesignRoot.canvas.clipPath = null
    DesignRoot.canvas.renderAll()
  }

  SelectMarkerLayout() {
    try {
      // statements
      var DesignRoot = this.props.DesignRoot

      var legendList = []

      legendList.push(<option value={null}>- Select size -</option>)
      legendList.push(<option value={'ORIGINAL'}>ORIGINAL</option>)

      for (var i = 0; i < DesignRoot._STACK.length; i++) {
        legendList.push(
          <option value={DesignRoot._STACK[i].stackId}>
            {DesignRoot._STACK[i].stackId}
          </option>
        )
      }

      return (
        <Select
          value={this.state.selectSize}
          onChange={e => {
            this.setState({ selectSize: e.target.value })
          }}
        >
          {legendList}
        </Select>
      )
    } catch (e) {
      // statements
      logger.error('MarkerLayout.SelectMarkerLayout.error', e)
    }
  }

  render() {
    return (
      <div className="MarkerLayout">
        <Grid container style={{ padding: '24px 24px 0px', height: '100%' }}>
          <Grid item xs={12}>
            <Grid container style={{ margin: 0 }}>
              <Typography variant="subtitle1">
                Select marker layout size
              </Typography>
            </Grid>
            <Grid container style={{ margin: 0, marginTop: 8 }}>
              <Grid item xs={12} style={{ padding: 0 }}>
                {this.SelectMarkerLayout()}
              </Grid>
              <Grid item xs={12} style={{ padding: 0, marginTop: 8 }}>
                <Button
                  style={{ minWidth: 16 }}
                  variant="contained"
                  color="primary"
                  onClick={this.buildMarkerLayout.bind(this)}
                >
                  Proceed
                </Button>
              </Grid>
              <Grid item xs={12} style={{ padding: 0, marginTop: 8 }}>
                <Button
                  style={{ minWidth: 16 }}
                  variant="contained"
                  color="primary"
                  onClick={this.clipingMask.bind(this)}
                >
                  Cliping Mask
                </Button>
              </Grid>
              <Grid item xs={12} style={{ padding: 0, marginTop: 8 }}>
                <Button
                  style={{ minWidth: 16 }}
                  variant="contained"
                  color="primary"
                  onClick={this.clipingMaskCancel.bind(this)}
                >
                  Cancel Cliping Mask
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default MarkerLayout
