import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Select from '../../../common/Select'
import client from '../../gql/admin-apollo-config'
import _get from 'lodash/get'
import Input from '../../../common/Input'
import _cloneDeep from 'lodash/cloneDeep'
import Delete from '@material-ui/icons/Delete'
import {
  ADMIN_GET_COLLECTION,
  ADMIN_SHOP_CUSTOMERS,
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_GET_TEAMSTORE,
  ADMIN_GET_PRODUCT,
  ADMIN_GET_PRODUCT_PACKAGE,
  ADMIN_SHOP_SALESCHANNELS
} from '../../gql/admin-queries'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import { Query } from 'react-apollo'
import AdminSearchProduct from '../common/AdminSearchProduct'
import AdminSearchProductPackage from '../common/AdminSearchProductPackage'
const omitDeep = require('omit-deep')

const CollectionPreview = ({ defaultLanguage, id }) => {
  return (
    <Query query={ADMIN_GET_COLLECTION} variables={{ id }}>
      {({ data }) => {
        try {
          return getTextFromLanguage(
            data.collection.title.text,
            defaultLanguage
          )
            ? `${getTextFromLanguage(
                data.collection.title.text,
                defaultLanguage
              )} (${data.collection.no})`
            : 'N/A'
        } catch (error) {
          return id
        }
      }}
    </Query>
  )
}

const ProductPreview = ({ defaultLanguage, id }) => {
  return (
    <Query query={ADMIN_GET_PRODUCT} variables={{ id }}>
      {({ data }) => {
        try {
          return getTextFromLanguage(data.product.title.text, defaultLanguage)
            ? `${getTextFromLanguage(
                data.product.title.text,
                defaultLanguage
              )} (${data.product.no})`
            : 'N/A'
        } catch (error) {
          return id
        }
      }}
    </Query>
  )
}

const ProductPackagePreview = ({ defaultLanguage, id }) => {
  return (
    <Query query={ADMIN_GET_PRODUCT_PACKAGE} variables={{ id }}>
      {({ data }) => {
        try {
          return getTextFromLanguage(
            data.productPackage.title.text,
            defaultLanguage
          )
            ? getTextFromLanguage(
                data.productPackage.title.text,
                defaultLanguage
              )
            : 'N/A'
        } catch (error) {
          return id
        }
      }}
    </Query>
  )
}

const SalesChannelPreview = ({ defaultLanguage, id }) => {
  return (
    <Query query={ADMIN_GET_TEAMSTORE} variables={{ id }}>
      {({ data }) => {
        try {
          return id === 'MYBRIKL'
            ? 'MYBRIKL'
            : getTextFromLanguage(data.teamstore.title.text, defaultLanguage)
            ? getTextFromLanguage(data.teamstore.title.text, defaultLanguage)
            : 'N/A'
        } catch (error) {
          return id
        }
      }}
    </Query>
  )
}

const styles = {
  root: {}
}
class DiscountRuleValue extends Component {
  state = {
    results: null
  }
  updateDiscountValueOrCurrency = (value, key) => {
    const {
      classes,
      discountId,
      defaultLanguage,
      currencies,
      discount,
      discountRule,
      discountRuleIndex,
      updateDiscountRule
    } = this.props
    updateDiscountRule({
      optimisticResponse: {
        __typename: 'Mutation',
        updateDiscountRule: {
          ...discountRule,
          [key]: value
        }
      },
      variables: {
        input: {
          [key]: value
        }
      }
    })
  }

  render() {
    const {
      classes,
      currencies,
      defaultLanguage,
      discountId,
      discount,
      discountRule,
      discountRuleIndex,
      updateDiscountRule,
      shopId,
      createDiscountRuleObject,
      deleteDiscountRuleObject
    } = this.props
    var items = []
    try {
      switch (discountRule.discountRuleObjectType) {
        case 'CUSTOMER':
          items = [
            <div key={'customer-item'}>{discountRule.discountRuleObjects}</div>
          ]
          break

        case 'COLLECTION':
          discountRule.discountRuleObjects.forEach(object => {
            const id = object.objectId
            if (!this.state[id]) {
              items.push(
                <div
                  style={{
                    display: 'inline-block',
                    marginRight: '1em',
                    marginBottom: '1em'
                  }}
                  key={'dro' + id}
                >
                  <CollectionPreview defaultLanguage={defaultLanguage} id={id}>
                    {id}
                  </CollectionPreview>
                  <Delete
                    onClick={() => {
                      deleteDiscountRuleObject({
                        variables: {
                          discountRuleId: discountRule.id,
                          discountId: discountId,
                          objectId: id
                        }
                      })
                      this.setState({
                        [id]: true
                      })
                    }}
                    style={{
                      cursor: 'pointer',
                      fontSize: '1em',
                      lineHeight: '1em'
                    }}
                  />
                </div>
              )
            }
          })
          break

        case 'PRODUCTPACKAGE':
          discountRule.discountRuleObjects.forEach(
            (productPackageObject, index) => {
              const id = productPackageObject.objectId
              if (!this.state[id]) {
                items.push(
                  <div
                    style={{
                      display: 'inline-block',
                      marginRight: '1em',
                      marginBottom: '1em'
                    }}
                    key={'pro-dro' + id + index}
                  >
                    <ProductPackagePreview
                      defaultLanguage={defaultLanguage}
                      id={id}
                      key={'pr-dro' + id}
                    >
                      {id}
                    </ProductPackagePreview>
                    <Delete
                      onClick={() => {
                        deleteDiscountRuleObject({
                          variables: {
                            discountRuleId: discountRule.id,
                            discountId: discountId,
                            objectId: id
                          }
                        })
                        this.setState({
                          [id]: true
                        })
                      }}
                      style={{
                        cursor: 'pointer',
                        fontSize: '1em',
                        lineHeight: '1em'
                      }}
                    />
                  </div>
                )
              }
            }
          )
          break
        case 'PRODUCT_TYPE':
          discountRule.discountRuleObjects.forEach(
            (productTypeObject, index) => {
              const id = productTypeObject.objectId
              if (!this.state[id]) {
                items.push(
                  <div
                    style={{
                      display: 'inline-block',
                      marginRight: '1em',
                      marginBottom: '1em'
                    }}
                    key={'prot-dro' + id + index}
                  >
                    {id}

                    <Delete
                      onClick={() => {
                        deleteDiscountRuleObject({
                          variables: {
                            discountRuleId: discountRule.id,
                            discountId: discountId,
                            objectId: id
                          }
                        })
                        this.setState({
                          [id]: true
                        })
                      }}
                      style={{
                        cursor: 'pointer',
                        fontSize: '1em',
                        lineHeight: '1em'
                      }}
                    />
                  </div>
                )
              }
            }
          )
          break
        case 'PRODUCT':
          discountRule.discountRuleObjects.forEach((object, index) => {
            const id = object.objectId
            if (!this.state[id]) {
              items.push(
                <div
                  style={{
                    display: 'inline-block',
                    marginRight: '1em',
                    marginBottom: '1em'
                  }}
                  key={'pro-dro' + id + index}
                >
                  <ProductPreview
                    defaultLanguage={defaultLanguage}
                    id={id}
                    key={'pr-dro' + id}
                  >
                    {id}
                  </ProductPreview>
                  <Delete
                    onClick={() => {
                      deleteDiscountRuleObject({
                        variables: {
                          discountRuleId: discountRule.id,
                          discountId: discountId,
                          objectId: id
                        }
                      })
                      this.setState({
                        [id]: true
                      })
                    }}
                    style={{
                      cursor: 'pointer',
                      fontSize: '1em',
                      lineHeight: '1em'
                    }}
                  />
                </div>
              )
            }
          })
          break

        case 'SALESCHANNEL':
          discountRule.discountRuleObjects.forEach(object => {
            const id = object.objectId
            if (!this.state[id]) {
              items.push(
                <div
                  style={{
                    display: 'inline-block',
                    marginRight: '1em',
                    marginBottom: '1em'
                  }}
                  key={'sal-dro' + id}
                >
                  <SalesChannelPreview
                    defaultLanguage={defaultLanguage}
                    id={id}
                    key={'dro' + id}
                  >
                    {id}
                  </SalesChannelPreview>
                  <Delete
                    onClick={() => {
                      deleteDiscountRuleObject({
                        variables: {
                          discountRuleId: discountRule.id,
                          discountId: discountId,
                          objectId: id
                        }
                      })
                      this.setState({
                        [id]: true
                      })
                    }}
                    style={{
                      cursor: 'pointer',
                      fontSize: '1em',
                      lineHeight: '1em'
                    }}
                  />
                </div>
              )
            }
          })
          break

        default:
          break
      }
    } catch (error) {
      console.error('e', error)
    }
    return (
      <Grid item xs={12}>
        {discountRule &&
        (discountRule.discountRuleObjectType === 'CART_VALUE' ||
          discountRule.discountRuleObjectType === 'CART_ITEM_QUANTITY') ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                label={
                  discountRule.discountRuleObjectType === 'CART_VALUE'
                    ? 'Value'
                    : discountRule.discountRuleObjectType ===
                      'CART_ITEM_QUANTITY'
                    ? 'Quantity'
                    : 'Search'
                }
                placeholder="0"
                value={
                  discountRule.discountRuleValue
                    ? discountRule.discountRuleValue
                    : ''
                }
                onChange={e => {
                  this.updateDiscountValueOrCurrency(
                    e.target.value ? parseFloat(e.target.value) : 0,
                    'discountRuleValue'
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                style={{
                  display:
                    discountRule &&
                    discountRule.discountRuleObjectType === 'CART_VALUE'
                      ? 'block'
                      : 'none'
                }}
                onChange={e => {
                  this.updateDiscountValueOrCurrency(
                    e.target.value,
                    'discountRuleCurrency'
                  )
                }}
                value={discountRule ? discountRule.discountRuleCurrency : ''}
                label="Currency"
              >
                <option value={''}>...</option>
                {currencies.map(cur => (
                  <option key={'cur-' + cur} value={cur}>
                    {cur}
                  </option>
                ))}
              </Select>
            </Grid>
          </Grid>
        ) : discountRule.discountRuleObjectType === 'COLLECTION' ? (
          <Grid item xs={12}>
            {items}
            <Query query={ADMIN_SHOP_COLLECTIONS} variables={{ id: shopId }}>
              {({ data, loading }) => {
                if (loading) return 'Loading collections...'

                return (
                  <Select
                    onChange={e => {
                      if (e.target.value) {
                        createDiscountRuleObject({
                          optimisticResponse: {
                            __typename: 'Mutation',
                            createDiscountRuleObject: {
                              ...discountRule,
                              discountRuleObjects: [
                                ...discountRule.discountRuleObjects,
                                {
                                  __typename: 'DiscountRuleObject',
                                  objectId: e.target.value
                                }
                              ]
                            }
                          },
                          variables: {
                            discountId: discountId,
                            input: {
                              type: 'COLLECTION',
                              discountRuleId: discountRule.id,
                              objectId: e.target.value
                            }
                          }
                        })
                      }
                    }}
                  >
                    <option value={null} key={'cnull'} />
                    {data &&
                    data.shop &&
                    data.shop.collections &&
                    data.shop.collections.edges
                      ? data.shop.collections.edges
                          .filter(c => {
                            return discountRule.discountRuleObjects &&
                              discountRule.discountRuleObjects.indexOf(
                                c.node.id
                              ) !== -1
                              ? false
                              : true
                          })
                          .sort((a, b) => {
                            try {
                              if (
                                getTextFromLanguage(
                                  a.node.title.text,
                                  defaultLanguage
                                ) <
                                getTextFromLanguage(
                                  b.node.title.text,
                                  defaultLanguage
                                )
                              ) {
                                return -1
                              }
                              if (
                                getTextFromLanguage(
                                  a.node.title.text,
                                  defaultLanguage
                                ) >
                                getTextFromLanguage(
                                  b.node.title.text,
                                  defaultLanguage
                                )
                              ) {
                                return 1
                              }
                              return 0
                            } catch (error) {
                              return 0
                            }
                          })
                          .map(c => {
                            return (
                              <option value={c.node.id} key={c.node.id}>
                                {getTextFromLanguage(
                                  c.node.title.text,
                                  defaultLanguage
                                )}{' '}
                                {`(${c.node.no})`}
                              </option>
                            )
                          })
                      : null}
                  </Select>
                )
              }}
            </Query>
          </Grid>
        ) : discountRule.discountRuleObjectType === 'PRODUCT_TYPE' ? (
          <Grid item xs={12}>
            {items}
            <Select
              onChange={e => {
                if (e.target.value) {
                  createDiscountRuleObject({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      createDiscountRuleObject: {
                        ...discountRule,
                        discountRuleObjects: [
                          ...discountRule.discountRuleObjects,
                          {
                            __typename: 'DiscountRuleObject',
                            objectId: e.target.value
                          }
                        ]
                      }
                    },
                    variables: {
                      discountId: discountId,
                      input: {
                        type: 'PRODUCT_TYPE',
                        discountRuleId: discountRule.id,
                        objectId: e.target.value
                      }
                    }
                  })
                }
              }}
            >
              <option value={null} key={'cnull'} />
              <option value={'STOCK'} key={'cstock'}>
                Stock
              </option>
              <option value={'CUSTOM'} key={'ccustom'}>
                Custom
              </option>
            </Select>
          </Grid>
        ) : discountRule.discountRuleObjectType === 'SALESCHANNEL' ? (
          <Grid item xs={12}>
            {items}
            <Query query={ADMIN_SHOP_SALESCHANNELS} variables={{ id: shopId }}>
              {({ data, loading }) => {
                if (loading) return null

                return (
                  <Select
                    onChange={e => {
                      if (e.target.value) {
                        createDiscountRuleObject({
                          optimisticResponse: {
                            __typename: 'Mutation',
                            createDiscountRuleObject: {
                              ...discountRule,
                              discountRuleObjects: [
                                ...discountRule.discountRuleObjects,
                                {
                                  __typename: 'DiscountRuleObject',
                                  objectId: e.target.value
                                }
                              ]
                            }
                          },
                          variables: {
                            discountId: discountId,
                            input: {
                              type: 'SALESCHANNEL',
                              discountRuleId: discountRule.id,
                              objectId: e.target.value
                            }
                          }
                        })
                      }
                    }}
                  >
                    <option value={null} key={'snull'} />
                    <option value={'MYBRIKL'} key={'smybrikl'}>
                      MYBRIKL
                    </option>
                    {data &&
                    data.shop &&
                    data.shop.salesChannels &&
                    data.shop.salesChannels.edges
                      ? data.shop.salesChannels.edges
                          .filter(c => {
                            return discountRule.discountRuleObjects &&
                              discountRule.discountRuleObjects.indexOf(
                                c.node.id
                              ) !== -1
                              ? false
                              : true
                          })
                          .sort((a, b) => {
                            try {
                              if (
                                getTextFromLanguage(
                                  a.node.title.text,
                                  defaultLanguage
                                ) <
                                getTextFromLanguage(
                                  b.node.title.text,
                                  defaultLanguage
                                )
                              ) {
                                return -1
                              }
                              if (
                                getTextFromLanguage(
                                  a.node.title.text,
                                  defaultLanguage
                                ) >
                                getTextFromLanguage(
                                  b.node.title.text,
                                  defaultLanguage
                                )
                              ) {
                                return 1
                              }
                              return 0
                            } catch (error) {
                              return 0
                            }
                          })
                          .map(c => {
                            return (
                              <option value={c.node.id} key={c.node.id}>
                                {getTextFromLanguage(
                                  c.node.title.text,
                                  defaultLanguage
                                )}
                              </option>
                            )
                          })
                      : null}
                  </Select>
                )
              }}
            </Query>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {items}
            {discountRule &&
            discountRule.discountRuleObjectType === 'PRODUCT' ? (
              <AdminSearchProduct
                classes={classes}
                onSelect={product => {
                  createDiscountRuleObject({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      createDiscountRuleObject: {
                        ...discountRule,
                        discountRuleObjects: [
                          ...discountRule.discountRuleObjects,
                          {
                            __typename: 'DiscountRuleObject',
                            objectId: product.id
                          }
                        ]
                      }
                    },
                    variables: {
                      discountId: discountId,
                      input: {
                        type: 'PRODUCT',
                        discountRuleId: discountRule.id,
                        objectId: product.id
                      }
                    }
                  })
                }}
                excludeIds={
                  discountRule.discountRuleObjects
                    ? discountRule.discountRuleObjects.map(x => x.objectId)
                    : []
                }
              />
            ) : discountRule &&
              discountRule.discountRuleObjectType === 'PRODUCTPACKAGE' ? (
              <AdminSearchProductPackage
                classes={classes}
                onSelect={productPackage => {
                  createDiscountRuleObject({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      createDiscountRuleObject: {
                        ...discountRule,
                        discountRuleObjects: [
                          ...discountRule.discountRuleObjects,
                          {
                            __typename: 'DiscountRuleObject',
                            objectId: productPackage.id
                          }
                        ]
                      }
                    },
                    variables: {
                      discountId: discountId,
                      input: {
                        type: 'PRODUCTPACKAGE',
                        discountRuleId: discountRule.id,
                        objectId: productPackage.id
                      }
                    }
                  })
                }}
                excludeIds={
                  discountRule.discountRuleObjects
                    ? discountRule.discountRuleObjects.map(x => x.objectId)
                    : []
                }
              />
            ) : (
              <Input
                label={'Search'}
                placeholder=""
                value={this.state.ruleSearch}
                onChange={async e => {
                  const value = e.target.value
                  this.setState({
                    ruleSearch: value
                  })
                  try {
                    switch (discountRule.discountRuleObjectType) {
                      case 'CUSTOMER':
                        const shopCustomers = await client.query({
                          variables: {
                            id: shopId
                          },
                          query: ADMIN_SHOP_CUSTOMERS
                        })
                        this.setState({
                          results: shopCustomers.data.shop.customers.edges
                        })
                        // autocomplete here
                        break

                      default:
                        break
                    }
                  } catch (error) {}
                }}
              />
            )}

            {this.state.results ? (
              this.state.results.map(r =>
                discountRule.discountRuleObjects &&
                discountRule.discountRuleObjects.includes(r.node.id) ? null : (
                  <div
                    style={{
                      cursor: 'pointer',
                      margin: '1em auto'
                    }}
                    key={'ro-' + r.node.id}
                    onClick={() => {
                      // createDiscountRuleObject({
                      // optimisticResponse: {
                      //   __typename: 'Mutation',
                      //   createDiscountRuleObject: {
                      //     ...discountRule,
                      //     discountRuleObjects: [
                      //       ...discountRule.discountRuleObjects,
                      //       {
                      //         __typename: 'DiscountRuleObject',
                      //         objectId: productPackage.id
                      //       }
                      //     ]
                      //   }
                      // },
                      //   variables: {
                      //     discountId: discountId,
                      //     input: {
                      //       type: 'PRODUCTPACKAGE',
                      //       discountRuleId: discountRule.id,
                      //       objectId: productPackage.id
                      //     }
                      //   }
                      // });
                    }}
                  >
                    {r.node.title
                      ? getTextFromLanguage(r.node.title.text, defaultLanguage)
                      : ''}
                  </div>
                )
              )
            ) : (
              <></>
            )}
          </Grid>
        )}
      </Grid>
    )
  }
}
DiscountRuleValue.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DiscountRuleValue)
