import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// import Dropzone from 'react-dropzone';
import { ADMIN_GET_SHOP_SETTINGS } from '../../gql/admin-queries';
import { ADMIN_UPDATE_SHOP_SETTINGS } from '../../gql/admin-mutations';
import _get from 'lodash/get';
import Input from '../../../common/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '../../../common/TextField';
import './ProductFileDrop.css';
import { I18n } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import uuid from 'uuid';
import _cloneDeep from 'lodash/cloneDeep';
import omitDeep from 'omit-deep';
import TeamstoreProductCustomizationOptionGroup from './TeamstoreProductCustomizationOptionGroup';
const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  }
});

class TeamstorePersonalization extends Component {
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  state = {
    personalize: true,
    personalizations: 1
  };

  render() {
    const {
      classes,
      teamstore,
      teamstoreId,
      updateTeamstore,
      defaultCurrency,
      defaultLanguage,
      languages
    } = this.props;
    const personalizationList = [];
    try {
      if (
        teamstore.customizationSettings &&
        teamstore.customizationSettings.customizationOptionGroups
      ) {
        teamstore.customizationSettings.customizationOptionGroups.forEach(
          (pco, index) => {
            personalizationList.push(
              <div
                key={'pco-' + index + pco.id}
                style={{
                  width: '100%'
                }}
              >
                <TeamstoreProductCustomizationOptionGroup
                  classes={classes}
                  teamstoreProductCustomizationOptionGroup={pco}
                  teamstore={teamstore}
                  teamstoreId={teamstoreId}
                  defaultLanguage={defaultLanguage}
                  updateTeamstore={updateTeamstore}
                  languages={languages}
                  defaultCurrency={defaultCurrency}
                />
              </div>
            );
          }
        );
      }
    } catch (error) {
      console.error('error', error);
    }
    return (
      <Grid container className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Personalization
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <RadioGroup
                        style={{
                          flexDirection: 'row'
                        }}
                        aria-label=""
                        name="hasPersonalization"
                        value={this.state.personalize ? 'yes' : 'no'}
                        onChange={e => {
                          this.setState({
                            personalize: !this.state.personalize
                          });
                        }}
                      >
                        <FormControlLabel
                          value={'yes'}
                          control={<Radio />}
                          label="Yes, I want to let my customer personalize their products."
                        />
                        <FormControlLabel
                          value={'no'}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                  <Input
                    label="Password"
                    placeholder="Enter password"
                    id="outlined-logo"
                    margin="normal"
                  />
                </Grid> */}

                {personalizationList}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const newGroup = {
                      __typename: 'ProductCustomizationOptionGroup',
                      id: uuid.v4(),
                      options: [
                        {
                          __typename: 'ProductCustomizationOption',
                          id: uuid.v4(),
                          type: 'TEXT',
                          title: {
                            __typename: 'Text',
                            id: uuid.v4(),
                            text: [
                              {
                                __typename: 'TextContent',
                                langCode: defaultLanguage,
                                content: 'Name'
                              }
                            ]
                          },
                          pricingRules: [
                            {
                              __typename: 'PricingRule',
                              id: uuid.v4(),
                              formula: {
                                __typename: 'PricingRuleFormula',
                                priority: 0,
                                qty: null,
                                sqm: null,
                                price: {
                                  __typename: 'Price',
                                  currency: defaultCurrency,
                                  value: parseFloat(1.0)
                                }
                              },
                              minOrderQuantity: null,
                              linkedType: 'TEXT',
                              calculationType: 'PERPRODUCT'
                            }
                          ]
                        }
                      ]
                    };
                    updateTeamstore({
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateTeamstore: {
                          ...teamstore,
                          settings: teamstore.settings,
                          pages: teamstore.pages,
                          parentShop: teamstore.parentShop,
                          shop: teamstore.shop,
                          customizationSettings:
                            teamstore.customizationSettings &&
                            teamstore.customizationSettings
                              .customizationOptionGroups
                              ? {
                                  ...teamstore.customizationSettings,
                                  customizationOptionGroups: [
                                    ...teamstore.customizationSettings
                                      .customizationOptionGroups,
                                    newGroup
                                  ]
                                }
                              : {
                                  customizationOptionGroups: [newGroup]
                                }
                        }
                      },
                      variables: {
                        input: {
                          customizationSettings: {
                            customizationOptionGroups: [
                              ..._cloneDeep(
                                teamstore.customizationSettings &&
                                  teamstore.customizationSettings
                                    .customizationOptionGroups
                                  ? [
                                      ...teamstore.customizationSettings
                                        .customizationOptionGroups,
                                      newGroup
                                    ]
                                  : [newGroup]
                              ).map(tc => omitDeep(tc, ['__typename']))
                            ]
                          }
                        }
                      }
                    });
                  }}
                >
                  Add personalization group
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TeamstorePersonalization);
