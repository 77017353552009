import { navigate } from '@reach/router';
import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query } from 'react-apollo';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ADMIN_SHOP_COLLECTIONS } from '../../gql/admin-queries';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import _get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import Imgix from 'react-imgix';

import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../../brikl-config';
import _orderBy from 'lodash/orderBy';
import AddCollection from './AddCollection';

const styles = theme => ({
  progress: {},
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%',
    height: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242'
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  cell: {
    padding: '16px 16px !important'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  }
});

class CollectionList extends Component {
  state = {
    filter: '',
    showActive: 'INACTIVE'
  };
  render() {
    const { classes, defaultLanguage, shopId, styles } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Grid item style={{ marginRight: 16 }}>
            <Typography
              component="h1"
              className={classes.h1}
              data-cy="Header-Collections"
            >
              Collections
            </Typography>
          </Grid>
          <Grid item style={{ padding: '0px', marginRight: 16 }}>
            <Paper className={classes.paper}>
              {/* <InputBase
                classes={{
                  root: classes.cssRootInput,
                  input: classes.cssinput
                }}
                margin="normal"
                placeholder="Filter by name"
                value={this.state.filter}
                onChange={e => this.setState({ filter: e.target.value })}
              /> */}
            </Paper>
          </Grid>
          <div style={{ flex: 1 }} />
          <AddCollection shopId={shopId} />
        </Grid>
        <Query query={ADMIN_SHOP_COLLECTIONS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            if (loading)
              return <CircularProgress className={classes.progress} />;
            if (error) return <div>Error :(</div>;
            const filteredCollections = _get(shop, 'collections.edges', []);
            return (
              <div>
                {filteredCollections.length > 0 ? (
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.cell}>
                            Internal ID
                          </TableCell>
                          <TableCell className={classes.cell}>Title</TableCell>
                          <TableCell className={classes.cell}>
                            Thumbnail
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredCollections
                          .sort((a, b) => {
                            try {
                              if (
                                getTextFromLanguage(
                                  a.node.title.text,
                                  defaultLanguage
                                ) <
                                getTextFromLanguage(
                                  b.node.title.text,
                                  defaultLanguage
                                )
                              ) {
                                return -1;
                              }
                              if (
                                getTextFromLanguage(
                                  a.node.title.text,
                                  defaultLanguage
                                ) >
                                getTextFromLanguage(
                                  b.node.title.text,
                                  defaultLanguage
                                )
                              ) {
                                return 1;
                              }
                              return 0;
                            } catch (error) {
                              return false;
                            }
                          })
                          .map(collection => {
                            return (
                              <TableRow
                                data-cy={
                                  'Collection-List-Row-' + collection.node.no
                                }
                                key={collection.node.id}
                                component="a"
                                onClick={() =>
                                  navigate(
                                    '/admin/' +
                                      shopId +
                                      '/inventory/collections/' +
                                      collection.node.id
                                  )
                                }
                                hover
                                style={{ textDecoration: 'none' }}
                              >
                                <TableCell
                                  className={classes.cell}
                                  component="th"
                                  scope="row"
                                >
                                  {collection.node.no
                                    ? collection.node.no
                                    : 'Undefined'}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {getTextFromLanguage(
                                    _get(collection.node, 'title.text', []),
                                    defaultLanguage
                                  )}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {collection.node.thumbnail ? (
                                    <Imgix
                                      height={50}
                                      width={50}
                                      htmlAttributes={{
                                        alt: '',
                                        style: {
                                          width: '50px',
                                          height: '50px',
                                          objectFit: 'contain',
                                          marginTop: '-16px',
                                          marginBottom: '-16px'
                                        }
                                      }}
                                      src={collection.node.thumbnail.replace(
                                        MEDIA_CDN_URL,
                                        IMGIX_URL
                                      )}
                                      imgixParams={{
                                        auto: 'compress'
                                      }}
                                    />
                                  ) : // <img
                                  //   loading="lazy"
                                  //   src={collection.node.thumbnail}
                                  //   alt={''}
                                  //   style={{
                                  //     width: '50px',
                                  //     height: '50px',
                                  //     objectFit: 'contain',
                                  //     marginTop: '-16px',
                                  //     marginBottom: '-16px'
                                  //   }}
                                  // />
                                  null}
                                </TableCell>
                                {/* <TableCell className={classes.cell}>

                              <TableCell className={classes.cell}>
                                <Link
                                  to={
                                    '/admin/'+shopId+'/inventory/products/' +
                                    collection.node.id
                                  }
                                  style={{
                                    textDecoration: 'none',
                                    color: '#000'
                                  }}
                                >
                                  <Button>Edit</Button>

                              </TableCell> */}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <div>
                    No collections yet
                    {/* <button>Create one now</button> */}
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withStyles(styles)(CollectionList);
