import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Query } from 'react-apollo';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ADMIN_SHOP_PAGE_TAGS } from '../../gql/admin-queries';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTextFromLanguage } from '../../../../helper/languageHelper';
import AddPageTag from './AddPageTag';
import PageTagSettings from './PageTagSettings';

const styles = theme => ({
  progress: {},
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  table: {
    width: '100%',
    height: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242'
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  cell: {
    padding: '0px 16px !important'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  }
});

class PageTagList extends Component {
  state = {
    filter: ''
  };
  render() {
    const { classes, defaultLanguage, shopId, styles } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ height: '60px', marginBottom: '20px' }}
        >
          <Grid item style={{ marginRight: 16 }}>
            <Typography
              data-cy="Header-Page-tags"
              component="h1"
              className={classes.h1}
            >
              Page tags
            </Typography>
          </Grid>
          {/* <Grid item style={{ padding: '0px', marginRight: 16 }}>
            <Paper className={classes.paper}>
              <InputBase
                classes={{
                  root: classes.cssRootInput,
                  input: classes.cssinput
                }}
                margin="normal"
                placeholder="Filter by name"
                value={this.state.filter}
                onChange={e => this.setState({ filter: e.target.value })}
              />
            </Paper>
          </Grid> */}
          <div style={{ flex: 1 }} />
          <AddPageTag shopId={shopId} />
        </Grid>
        <Query query={ADMIN_SHOP_PAGE_TAGS} variables={{ id: shopId }}>
          {({ loading, error, data: { shop } }) => {
            if (loading)
              return <CircularProgress className={classes.progress} />;
            if (error) return <div>Error :(</div>;
            const filteredContentPageTags = _get(shop, 'pageTags.edges', []);

            return (
              <div>
                {filteredContentPageTags.length > 0 ? (
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.cell}>Title</TableCell>
                          <TableCell className={classes.cell}>
                            Thumbnail
                          </TableCell>
                          <TableCell className={classes.cell}>
                            Options
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredContentPageTags.map(pageTag => {
                          return (
                            <TableRow
                              key={pageTag.node.id}
                              hover
                              style={{ textDecoration: 'none' }}
                            >
                              <TableCell
                                component="a"
                                style={{ textDecoration: 'none' }}
                                href={
                                  '/admin/' +
                                  shopId +
                                  '/pagebuilder/tags/' +
                                  pageTag.node.id
                                }
                                className={classes.cell}
                              >
                                {getTextFromLanguage(
                                  _get(pageTag.node, 'title.text', []),
                                  defaultLanguage
                                )}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {pageTag.node.thumbnail ? (
                                  <img
                                    src={pageTag.node.thumbnail}
                                    alt={''}
                                    style={{
                                      width: '50px',
                                      height: '50px',
                                      objectFit: 'contain'
                                    }}
                                  />
                                ) : null}
                              </TableCell>
                              <TableCell>
                                <PageTagSettings tagId={pageTag.node.id} />
                              </TableCell>
                              {/* <TableCell className={classes.cell}>
                                
                              <TableCell className={classes.cell}>
                                <Link
                                  to={
                                    '/admin/inventory/products/' +
                                    collection.node.id
                                  }
                                  style={{
                                    textDecoration: 'none',
                                    color: '#000'
                                  }}
                                >
                                  <Button>Edit</Button>
                                
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <div>
                    No page tags yet
                    {/* <button>Create one now</button> */}
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withStyles(styles)(PageTagList);
