import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import _get from 'lodash/get'
import { getTextFromLanguage } from '../../../../helper/languageHelper'
import { NativeSelect, InputLabel } from '@material-ui/core'

const styles = theme => ({})

class SelectProductPackage extends React.Component {
  state = {
    isChecked: false
  }

  render() {
    const {
      classes,
      packages,
      Template,
      handleChange,
      selectedProductPackage,
      selectedProductPackageId,
      langCode
    } = this.props

    var url = ''

    try {
      if (langCode === 'en_UK') {
        url = '/' + 'en/' + slug
      } else if (langCode === 'nl_NL') {
        url = '/' + 'nl/' + slug
      } else if (langCode === 'fr_FR') {
        url = '/' + 'fr/' + slug
      }
    } catch (error) {}

    return (
      <div style={{ width: '100%', padding: 10 }}>
        <NativeSelect
          style={{
            border: '1px solid #ced4da',
            borderRadius: 4
          }}
          native="true"
          defaultValue={selectedProductPackageId}
          onChange={event => {
            const productPackage = packages.edges.find(
              n => n.node.id === event.target.value
            )
            const packageSlug = getTextFromLanguage(
              productPackage.node.slugs,
              langCode
            )
            handleChange(event, packageSlug)
          }}
        >
          {packages.edges.map(packageNode => (
            <option key={packageNode.node.id} value={packageNode.node.id}>
              {getTextFromLanguage(packageNode.node.title.text, langCode)}
            </option>
          ))}
        </NativeSelect>
      </div>
    )
  }
}

SelectProductPackage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(SelectProductPackage)
