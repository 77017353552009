import React from 'react'
import { Grid } from '@material-ui/core'
import CardCouponCode from './CartCouponCode'
import CartHeader from './CartHeader'

const CartProductList: React.FC = () => {
  return (
    <Grid style={{ marginBottom: 15 }} container={true}>
      <Grid item={true} xs={12}>
        <div>
          <CartHeader />
          <CardCouponCode />
        </div>
      </Grid>
    </Grid>
  )
}

export default CartProductList
