import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {
  Divider,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import { getTextFromLanguage } from '../../../../helper/languageHelper'

import DeleteImage from './DeleteImage'
import Uploader from './Uploader'
import NativeSelect from '@material-ui/core/NativeSelect'
import Slider from '@material-ui/core/Slider'
import ColorPicker from './ColorPicker'
import AlignItems from './AlignItems'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Query } from 'react-apollo'
import {
  GET_PAGES,
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_SHOP_PRODUCT_PACKAGES,
  ADMIN_SHOP_PRODUCTS,
  ADMIN_SHOP_SALESCHANNELS
} from '../../gql/admin-queries'
import { MEDIA_CDN_URL } from '../../../../../brikl-config'
import SelectPage from './SelectPage'
import SelectProducts from './SelectProducts'
import SelectTeamstore from './SelectTeamstore'
import SelectProductPackage from './SelectProductPackage'
import SelectCollection from './SelectCollection'

import { BORDER_TYPES } from './config'

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase)

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  input: {
    paddingLeft: 8,
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  }
})

class UpdateCard extends React.Component {
  state = {
    uploading: false,
    pageDialog: false,
    urlType: this.props.fields.urlType,
    selectedProduct: this.props.fields.buttonUrl,
    selectedProductId: this.props.fields.selectedProductId
      ? this.props.fields.selectedProductId
      : '',
    selectedTeamstore: this.props.fields.buttonUrl,
    selectedTeamstoreId: this.props.fields.selectedTeamstoreId
      ? this.props.fields.selectedTeamstoreId
      : '',
    selectedProductPackage: this.props.fields.buttonUrl,
    selectedProductPackageId: this.props.fields.selectedProductPackageId
      ? this.props.fields.selectedProductPackageId
      : '',
    selectedPage: this.props.fields.buttonUrl,
    selectedPageId: this.props.fields.selectedPageId
      ? this.props.fields.selectedPageId
      : '',
    selectedCollection: this.props.fields.buttonUrl,
    selectedCollectionId: this.props.fields.selectedCollectionId
      ? this.props.fields.selectedCollectionId
      : ''
  }

  handlePageOpen = () => {
    this.setState({ pageDialog: true })
  }

  handlePageClose = () => {
    this.setState({ pageDialog: false })
  }

  handleChangeProductPackage = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedTeamstore: null,
      selectedTeamstoreId: null,
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProductPackage: `/${slug}`,
      selectedProductPackageId: event.target.value
    })
    fields.productPackageId = event.target.value
  }

  handleChangePage = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedTeamstore: null,
      selectedTeamstoreId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedPage: `/${slug}`,
      selectedPageId: event.target.value
    })
    fields.selectedPageId = event.target.value
  }
  handleChangeCollection = (event, slug) => {
    const { fields } = this.props

    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedTeamstore: null,
      selectedTeamstoreId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedCollection: `/${slug}`,
      selectedCollectionId: event.target.value
    })
    fields.collectionId = event.target.value
  }
  handleChangeTeamstore = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProduct: null,
      selectedProductId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedTeamstore: `/${slug}`,
      selectedTeamstoreId: event.target.value
    })

    fields.teamstoreId = event.target.value
  }
  handleChangeProduct = (event, slug) => {
    const { fields } = this.props
    this.setState({
      selectedPage: null,
      selectedPageId: null,
      selectedCollection: null,
      selectedCollectionId: null,
      selectedProductPackage: null,
      selectedProductPackageId: null,
      selectedProduct: `/${slug}`,
      selectedProductId: event.target.value
    })
    fields.productId = event.target.value
  }

  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,
      handleChangeTextField,
      fields,
      Template,
      shopId,
      languages
    } = this.props

    let imgUrl = ''
    let newUrl

    if (fields && fields.thumbnailImg) {
      if (fields.thumbnailImg !== null) {
        imgUrl = fields.thumbnailImg.split('/')
        newUrl = imgUrl.pop()
      }
    }

    let { urlType } = this.state
    if (!urlType) {
      urlType = this.state.selectedPage
        ? 'PAGE'
        : this.state.selectedProduct
        ? 'PRODUCT'
        : this.state.selectedProductPackage
        ? 'PRODUCT_PACKAGE'
        : this.state.selectedTeamstore
        ? 'SALESCHANNEL'
        : this.state.selectedCollection
        ? 'COLLECTION'
        : null
    }

    return (
      <Grid>
        <ExpansionPanel
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
          expanded={expanded === 'panel2'}
          onChange={handleExpandedChange('panel2')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ expanded: classes.expansionPanelExpanded }}
          >
            <Typography
              variant="subtitle1"
              className={classes.typo}
              data-cy="Card-Settings"
            >
              Card Settings
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: classes.expansionPanelDetails }}
          >
            <Divider />
            <List>
              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Width-Label"
                  >
                    Width - {fields.width} %
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Card-Width-Slider"
                      className={styles.slider}
                      value={parseInt(fields.width)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) => handleChangeField(value, 'width')}
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Height-Label"
                  >
                    Height - {fields.height}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Card-Height-Slider"
                      className={styles.slider}
                      value={parseInt(
                        fields.height === 0
                          ? (fields.height = 428)
                          : fields.height
                      )}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={1000}
                      onChange={(e, value) =>
                        handleChangeField(value, 'height')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Thumbnail-Image-Label"
                  >
                    Thumbnail Image
                  </InputLabel>
                  <Paper elevation={0}>
                    <Uploader
                      shopId={shopId}
                      dataCy="Card-Image-Upload"
                      image={
                        fields.thumbnailImg !== null
                          ? `${MEDIA_CDN_URL}/${shopId}/media/${newUrl}`
                          : fields.thumbnailImg
                      }
                      isCDNField={true}
                      field="thumbnailImg"
                      handleChangeField={handleChangeField}
                    />
                  </Paper>

                  <DeleteImage
                    image={fields.thumbnailImg}
                    field="thumbnailImg"
                    handleDelete={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Title-Label"
                  >
                    Title
                  </InputLabel>
                  {languages.map(language => {
                    let text = ''
                    try {
                      text =
                        getTextFromLanguage(fields.title.text, language) || ''
                    } catch (error) {}
                    return (
                      <div key={language}>
                        {language}
                        <Paper className={classes.root} elevation={0}>
                          <InputBase
                            // data-cy={'CONTAINER-ROW-LABEL-' + row.sortOrder}
                            data-cy={'Card-title-' + language}
                            className={classes.input}
                            placeholder=""
                            value={text}
                            onChange={e =>
                              handleChangeTextField(
                                e.target.value ? e.target.value : null,
                                language,
                                'title'
                              )
                            }
                          />
                        </Paper>
                      </div>
                    )
                  })}

                  <Button
                    data-cy="Card-Delete-Title-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      languages.map(language => {
                        let text = ''
                        try {
                          text =
                            getTextFromLanguage(fields.title.text, language) ||
                            ''
                        } catch (error) {}

                        handleChangeTextField(null, language, 'title')
                      })
                    }}
                  >
                    Delete Title
                  </Button>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Title-color-Label"
                  >
                    Title color
                  </InputLabel>
                  <ColorPicker
                    dataCy="Card-Title-ColorPicker"
                    color={fields.titleColor}
                    fieldName={'titleColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Align-title-Label"
                  >
                    Align title
                  </InputLabel>
                  <AlignItems
                    fieldName={'titleAlign'}
                    handleChangeField={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Description-Label"
                  >
                    Description
                  </InputLabel>
                  {languages.map(language => {
                    let text = ''
                    try {
                      text =
                        getTextFromLanguage(
                          fields.description.text,
                          language
                        ) || ''
                    } catch (error) {}
                    return (
                      <div key={language}>
                        {language}
                        <Paper className={classes.root} elevation={0}>
                          <InputBase
                            data-cy={'Card-description-' + language}
                            className={classes.input}
                            placeholder=""
                            value={text}
                            onChange={e =>
                              handleChangeTextField(
                                e.target.value ? e.target.value : null,
                                language,
                                'description'
                              )
                            }
                          />
                        </Paper>
                      </div>
                    )
                  })}
                  <Button
                    data-cy="Card-Delete-Description-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      languages.map(language => {
                        let text = ''
                        try {
                          text =
                            getTextFromLanguage(
                              fields.description.text,
                              language
                            ) || ''
                        } catch (error) {}

                        handleChangeTextField(null, language, 'description')
                      })
                    }}
                  >
                    Delete Description
                  </Button>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Description-color-Label"
                  >
                    Description color
                  </InputLabel>
                  <ColorPicker
                    dataCy="Card-Description-ColorPicker"
                    color={fields.descriptionColor}
                    fieldName={'descriptionColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Align-description-Label"
                  >
                    Align description
                  </InputLabel>
                  <AlignItems
                    fieldName={'descriptionAlign'}
                    handleChangeField={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel shrink className={classes.FormLabel}>
                    Button text
                  </InputLabel>
                  {languages.map(language => {
                    let text = ''
                    try {
                      text =
                        getTextFromLanguage(
                          fields.callToAction.text,
                          language
                        ) || ''
                    } catch (error) {}
                    return (
                      <div key={language}>
                        {language}
                        <Paper className={classes.root} elevation={0}>
                          <InputBase
                            data-cy={'Card-Button-text-' + language}
                            className={classes.input}
                            placeholder=""
                            value={text}
                            onChange={e =>
                              handleChangeTextField(
                                e.target.value ? e.target.value : null,
                                language,
                                'callToAction'
                              )
                            }
                          />
                        </Paper>
                      </div>
                    )
                  })}
                  <Button
                    data-cy="Card-Delete-Button-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      languages.map(language => {
                        let text = ''
                        try {
                          text =
                            getTextFromLanguage(
                              fields.callToAction.text,
                              language
                            ) || ''
                        } catch (error) {}

                        handleChangeTextField(null, language, 'callToAction')
                      })
                    }}
                  >
                    Delete Button
                  </Button>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Button-text-Label"
                  >
                    Button text color
                  </InputLabel>
                  <ColorPicker
                    dataCy="Card-Button-text-ColorPicker"
                    color={fields.buttonTextColor}
                    fieldName={'buttonTextColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Align-button-Label"
                  >
                    Align button
                  </InputLabel>
                  <AlignItems
                    fieldName={'buttonAlign'}
                    handleChangeField={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Button-border-thickness-Label"
                  >
                    Button border thickness - {fields.buttonBorderThickness}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Card-Button-border-thickness-Slider"
                      className={styles.slider}
                      value={parseInt(fields.buttonBorderThickness)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={5}
                      onChange={(e, value) =>
                        handleChangeField(value, 'buttonBorderThickness')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Button-border-type-Label"
                  >
                    Button border type
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      data-cy="Card-Button-border-type-Select"
                      native="true"
                      className={classes.input}
                      value={fields.buttonBorderType}
                      onChange={e =>
                        handleChangeField(e.target.value, 'buttonBorderType')
                      }
                      input={
                        <SelectInput
                          name="buttonBorderType"
                          id="age-customized-native-simple"
                        />
                      }
                    >
                      {BORDER_TYPES.map(buttonBorderType => (
                        <option key={buttonBorderType} value={buttonBorderType}>
                          {buttonBorderType}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Button-border-color-Label"
                  >
                    Button border color
                  </InputLabel>
                  <ColorPicker
                    dataCy="Card-Button-border-ColorPicker"
                    color={fields.buttonBorderColor}
                    fieldName={'buttonBorderColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Button-font-size-Label"
                  >
                    Button font size - {fields.buttonFontSize}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Card-Button-font-size-Slider"
                      className={styles.slider}
                      value={parseInt(fields.buttonFontSize)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'buttonFontSize')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Title-font-size-Label"
                  >
                    Title font size - {fields.titleFontSize}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Card-Title-font-size-Slider"
                      className={styles.slider}
                      value={parseInt(fields.titleFontSize)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'titleFontSize')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Description-font-size-Label"
                  >
                    Description font size - {fields.descriptionFontSize}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Card-Description-font-size-Slider"
                      className={styles.slider}
                      value={parseInt(fields.descriptionFontSize)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'descriptionFontSize')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Border-thickness-Label"
                  >
                    Border thickness - {fields.borderThickness}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Card-Border-thickness-Slider"
                      className={styles.slider}
                      value={parseInt(fields.borderThickness)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={5}
                      onChange={(e, value) =>
                        handleChangeField(value, 'borderThickness')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Border-type-Label"
                  >
                    Border type
                  </InputLabel>
                  <Paper className={classes.root} elevation={0}>
                    <NativeSelect
                      data-cy="Card-Border-type-Select"
                      native="true"
                      className={classes.input}
                      value={fields.borderType}
                      onChange={e =>
                        handleChangeField(e.target.value, 'borderType')
                      }
                      input={
                        <SelectInput
                          name="borderType"
                          id="age-customized-native-simple"
                        />
                      }
                    >
                      {BORDER_TYPES.map(borderType => (
                        <option key={borderType} value={borderType}>
                          {borderType}
                        </option>
                      ))}
                    </NativeSelect>
                  </Paper>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Border-color-Label"
                  >
                    Border color
                  </InputLabel>
                  <ColorPicker
                    dataCy="Card-Border-ColorPicker"
                    color={fields.borderColor}
                    fieldName={'borderColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    style={{ marginBottom: 10 }}
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Border-radius-Label"
                  >
                    Border radius - {fields.borderRadius}
                  </InputLabel>
                  <Grid container={true} justify="space-between">
                    <Slider
                      data-cy="Card-Border-radius-Slider"
                      className={styles.slider}
                      value={parseInt(fields.borderRadius)}
                      aria-labelledby="slider-label"
                      step={1}
                      min={0}
                      max={100}
                      onChange={(e, value) =>
                        handleChangeField(value, 'borderRadius')
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    shrink
                    className={classes.FormLabel}
                    data-cy="Card-Background-color-label"
                  >
                    Background color
                  </InputLabel>
                  <ColorPicker
                    dataCy="Card-Background-ColorPicker"
                    color={fields.backgroundColor}
                    fieldName={'backgroundColor'}
                    onChange={handleChangeField}
                  />
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container direction="column">
                  <InputLabel
                    data-cy="Card-Link-Label"
                    shrink
                    className={classes.FormLabel}
                  >
                    Link
                  </InputLabel>
                  <Paper
                    data-cy="Card-Add-Link-Input"
                    className={classes.root}
                    elevation={0}
                  >
                    <InputBase
                      className={classes.input}
                      placeholder=""
                      disabled={true}
                      value={
                        this.state.selectedPage
                          ? (fields.link = this.state.selectedPage)
                          : this.state.selectedCollection
                          ? (fields.link = this.state.selectedCollection)
                          : this.state.selectedProduct
                          ? (fields.link = this.state.selectedProduct)
                          : this.state.selectedProductPackage
                          ? (fields.link = this.state.selectedProductPackage)
                          : this.state.selectedTeamstore
                          ? (fields.link = this.state.selectedTeamstore)
                          : null
                      }
                      onChange={e =>
                        handleChangeField(
                          e.target.value ? e.target.value : null,
                          'link'
                        )
                      }
                    />
                  </Paper>
                  <Button
                    data-cy="Card-Add-Link-btn"
                    onClick={this.handlePageOpen}
                  >
                    Add Link
                  </Button>
                </Grid>
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Dialog
          open={this.state.pageDialog}
          onClose={this.handlePageClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle data-cy="Card-Link-Select-Page" id="form-dialog-title">
            Add Link
          </DialogTitle>
          <DialogContent
            style={{ zIndex: 100, minWidth: '600px', minHeight: '50vh' }}
          >
            Type of link
            <Grid container={true}>
              <NativeSelect
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: 4
                }}
                native="true"
                value={urlType}
                onChange={e => {
                  this.setState({
                    urlType: e.target.value,
                    selectedPage:
                      e.target.value === 'PAGE' ? '/your-page' : null,
                    selectedProduct:
                      e.target.value === 'PRODUCT' ? '/your-product' : null,
                    selectedProductPackage:
                      e.target.value === 'PRODUCT_PACKAGE'
                        ? 'productPackage'
                        : null,
                    selectedTeamstore:
                      e.target.value === 'SALESCHANNEL'
                        ? '/your-teamstore'
                        : null,
                    selectedCollection:
                      e.target.value === 'COLLECTION'
                        ? '/your-collection'
                        : null
                  })
                  handleChangeField(
                    e.target.value ? e.target.value : null,
                    'urlType'
                  )
                }}
              >
                <option value={'PAGE'}>Content Page</option>
                <option value={'PRODUCT'}>Product</option>
                <option value={'PRODUCT_PACKAGE'}>Product Package</option>
                <option value={'COLLECTION'}>Collection</option>
                <option value={'SALESCHANNEL'}>Teamstore</option>
              </NativeSelect>
            </Grid>
            <br />
            {urlType === 'PAGE' ? (
              <>
                Content Pages
                <Query variables={{ id: shopId }} query={GET_PAGES}>
                  {({ loading, error, data }) => {
                    if (loading) return <CircularProgress />
                    if (error) return `Error! ${error.message}`

                    return (
                      <Grid container={true}>
                        <SelectPage
                          pages={data.shop.pages}
                          handleChange={this.handleChangePage}
                          Template={Template}
                          selectedPage={this.state.selectedPage}
                          selectedPageId={this.state.selectedPageId}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      </Grid>
                    )
                  }}
                </Query>
              </>
            ) : null}
            {urlType === 'PRODUCT_PACKAGE' ? (
              <>
                Product Packages
                <Query
                  variables={{ id: shopId }}
                  query={ADMIN_SHOP_PRODUCT_PACKAGES}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <CircularProgress />
                    if (error) return `Error! ${error.message}`

                    return (
                      <Grid container={true}>
                        <SelectProductPackage
                          packages={data.shop.productPackages}
                          handleChange={this.handleChangeProductPackage}
                          Template={Template}
                          selectedProductPackage={
                            this.state.selectedProductPackage
                          }
                          selectedProductPackageId={
                            this.state.selectedProductPackageId
                          }
                          selected={this.state.selected}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      </Grid>
                    )
                  }}
                </Query>
              </>
            ) : null}
            {urlType === 'SALESCHANNEL' ? (
              <>
                Teamstores
                <Query
                  variables={{ id: shopId }}
                  query={ADMIN_SHOP_SALESCHANNELS}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <CircularProgress />
                    if (error) return `Error! ${error.message}`

                    return (
                      <Grid container={true}>
                        <SelectTeamstore
                          teamstores={data.shop.salesChannels}
                          handleChange={this.handleChangeTeamstore}
                          Template={Template}
                          selectedTeamstore={this.state.selectedTeamstore}
                          selectedTeamstoreId={this.state.selectedTeamstoreId}
                          selected={this.state.selected}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      </Grid>
                    )
                  }}
                </Query>
              </>
            ) : null}
            {urlType === 'COLLECTION' ? (
              <>
                Collections
                <Query
                  variables={{ id: shopId }}
                  query={ADMIN_SHOP_COLLECTIONS}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <CircularProgress />
                    if (error) return `Error! ${error.message}`

                    return (
                      <Grid container={true}>
                        <SelectCollection
                          collections={data.shop.collections}
                          handleChange={this.handleChangeCollection}
                          Template={Template}
                          selectedCollection={this.state.selectedCollection}
                          selectedCollectionId={this.state.selectedCollectionId}
                          selected={this.state.selected}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      </Grid>
                    )
                  }}
                </Query>
              </>
            ) : null}
            {urlType === 'PRODUCT' ? (
              <>
                Products
                <Query variables={{ id: shopId }} query={ADMIN_SHOP_PRODUCTS}>
                  {({ loading, error, data }) => {
                    if (loading) return <CircularProgress />
                    if (error) return `Error! ${error.message}`

                    return (
                      <Grid container={true}>
                        <SelectProducts
                          products={data.shop.products}
                          handleChange={this.handleChangeProduct}
                          Template={Template}
                          selectedProduct={this.state.selectedProduct}
                          selectedProductId={this.state.selectedProductId}
                          selected={this.state.selected}
                          pageDialog={this.state.pageDialog}
                          langCode={Template.state.currentLanguage}
                        />
                      </Grid>
                    )
                  }}
                </Query>
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              data-cy="Card-Select-Page-Cancel-btn"
              onClick={this.handlePageClose}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              data-cy="Card-Select-Page-Save-btn"
              onClick={this.handlePageClose}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    )
  }
}

UpdateCard.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
}

export default withStyles(styles)(UpdateCard)
