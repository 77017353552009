import React, { Component } from 'react';

export default class LeftClickIcon extends React.Component {
  render() {
    const {
      width = 80,
      height = 80,
      background,
      padding,
      fill5 = '#c4c4c4'
    } = this.props;
    return (
      <div style={{ width: width, height: height, padding: padding }}>
        <style
          dangerouslySetInnerHTML={{
            __html: `
                        .leftClick-1,.leftClick-2,.leftClick-3{fill:none;}.leftClick-1{stroke:#4d4d4d;}.leftClick-1,.leftClick-2,.leftClick-3,.leftClick-4{stroke-miterlimit:10;}.leftClick-1,.leftClick-3,.leftClick-4{stroke-width:2px;}.leftClick-2{stroke:#939393;stroke-width:3px;}.leftClick-3,.leftClick-4{stroke:#000;}.leftClick-4{fill:#fff;}.leftClick-5{fill:${fill5};}
        `
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 92.86 132.2"
          style={{ width: '100%', height: '100%' }}
        >
          <defs />
          <title>Asset 3</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <circle class="leftClick-1" cx="23.98" cy="23.98" r="14.7" />
              <circle class="leftClick-2" cx="23.98" cy="23.98" r="22.48" />
              <circle class="leftClick-3" cx="23.55" cy="23.55" r="7.35" />
              <rect
                class="leftClick-4"
                x="10.15"
                y="14.47"
                width="81.71"
                height="116.73"
                rx="40.85"
                transform="translate(102 145.67) rotate(-180)"
              />
              <path
                class="leftClick-5"
                d="M49.56,17.93h1.23V58.66H13.57V56.49C13.57,35.19,29.68,17.93,49.56,17.93Z"
              />
              <polyline
                class="leftClick-3"
                points="50.78 14.47 50.78 58.66 10.15 58.66"
              />
              <rect
                class="leftClick-4"
                x="46.41"
                y="29.44"
                width="8.32"
                height="17.22"
                rx="4.11"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
