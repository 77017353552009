import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography, Switch } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { getTextFromLanguage } from '../../../../helper/languageHelper';

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase);

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  input: {
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  }
});

class UpdateContactCard extends React.Component {
  state = {
    uploading: false
  };

  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,
      handleChangeTextField,
      fields,
      languages
    } = this.props;

    return (
      <ExpansionPanel
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
        expanded={expanded === 'panel2'}
        onChange={handleExpandedChange('panel2')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ expanded: classes.expansionPanelExpanded }}
        >
          <Typography
            variant="subtitle1"
            className={classes.typo}
            data-cy="Contact-Card-Settings"
          >
            Card Settings
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{ root: classes.expansionPanelDetails }}
        >
          <Divider />
          <List>
            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Title
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(fields.title.text, language) || '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'title'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Name Input Label
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(fields.nameLabel.text, language) ||
                      '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'nameLabel'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Name Input Placeholder
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(
                        fields.namePlaceholder.text,
                        language
                      ) || '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'namePlaceholder'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Email Input Label
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(fields.emailLabel.text, language) ||
                      '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'emailLabel'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Email Input Placeholder
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(
                        fields.emailPlaceholder.text,
                        language
                      ) || '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'emailPlaceholder'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Message Input Label
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(fields.contentLabel.text, language) ||
                      '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'contentLabel'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Message Input Placeholder
                </InputLabel>
                {languages.map(language => {
                  let text = '';
                  try {
                    text =
                      getTextFromLanguage(
                        fields.contentPlaceholder.text,
                        language
                      ) || '';
                  } catch (error) {}
                  return (
                    <div key={language}>
                      {language}
                      <Paper className={classes.root} elevation={0}>
                        <InputBase
                          className={classes.input}
                          placeholder=""
                          value={text}
                          onChange={e =>
                            handleChangeTextField(
                              e.target.value ? e.target.value : null,
                              language,
                              'contentPlaceholder'
                            )
                          }
                        />
                      </Paper>
                    </div>
                  );
                })}
              </Grid>
            </ListItem>

            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Add Privacy Policy?
                </InputLabel>
                <Switch
                  checked={fields.hasTerms}
                  onChange={e =>
                    handleChangeField(
                      e.target.checked ? true : false,
                      'hasTerms'
                    )
                  }
                  value="checkedA"
                />
              </Grid>
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

UpdateContactCard.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
};

export default withStyles(styles)(UpdateContactCard);
