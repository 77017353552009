import { withStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import _MaterialTable, {
  MaterialTableProps,
  MTableToolbar
} from 'material-table'
import * as React from 'react'
// import { ArrowDown } from '@brikl/icons'

type TableProps<RowData extends object> = MaterialTableProps<RowData> & {
  searchable?: boolean
  elevation?: number
  backgroundTransparent?: boolean
}

const Toolbar = withStyles({
  root: {
    padding: 0,
    minHeight: 0,
    paddingBottom: 10
  }
})(MTableToolbar)

const TableColors = {
  header: {
    backgroundColor: '#555555',
    color: 'white'
  }
}

export function MaterialTable<RowData extends object>(
  props: TableProps<RowData>
) {
  const { backgroundTransparent, ...rest } = props

  return (
    <_MaterialTable
      icons={{
        Search: React.forwardRef((props, ref) => (
          <SearchIcon {...props} ref={ref} />
        )),
        ResetSearch: React.forwardRef((props, ref) => (
          <ClearIcon {...props} ref={ref} />
        )),
        SortArrow: React.forwardRef((props, ref) => null)
      }}
      components={{
        Container: ({ style, ...containerProps }) => {
          if (props.backgroundTransparent) {
            style.background = 'transparent'
          }

          return (
            <Paper
              {...containerProps}
              elevation={props.elevation}
              style={style}
            />
          )
        },
        Toolbar: props => {
          return <Toolbar {...props} />
        }
      }}
      options={{
        searchFieldAlignment: 'left',
        searchFieldStyle: {
          background: 'white',
          padding: '5px 12px',
          borderRadius: '5px',
          marginLeft: '0px'
        },
        search: props.searchable,
        draggable: false,
        paging: false,
        showTitle: false,
        toolbar: props.searchable,
        headerStyle: TableColors.header
      }}
      {...rest}
    />
  )
}

MaterialTable.defaultProps = {
  searchable: false,
  elevation: 0,
  backgroundTransparent: true
}
