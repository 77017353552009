import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  PERMISSIONS,
  PERMISSION_OPERATION,
  PERMISSION_SKIP_DELETE
} from '../content/config';
import { ADMIN_UPDATE_PERMISSIONS } from '../../gql/admin-mutations';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#B23566',
    fontWeight: 400
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  avatar: {
    margin: 10
  },
  table: {
    minWidth: 650
  }
}));

const AccountCard = ({ adminUser }) => {
  const classes = useStyles();

  let values = [];

  const getDataFromQuery = () => {
    let permissionData = {};
    try {
      PERMISSIONS.forEach(permission => {
        PERMISSION_OPERATION.forEach(ops => {
          const permissionOps = `${permission}_${ops}`;
          if (!adminUser.node.permissions) {
            // TODO: return empty array from API instead
            adminUser.node.permissions = [];
          }

          if (adminUser.node.permissions.indexOf(permissionOps) !== -1) {
            permissionData[permissionOps] = true;
          } else {
            permissionData[permissionOps] = false;
          }
        });
      });
    } catch (error) {
      console.error('getDataFromQuery', error);
    }

    return permissionData;
  };

  const showSelectAll = operation => {
    let isChecked = true;
    PERMISSIONS.forEach(permission => {
      if (!data[`${permission}_${operation}`]) {
        isChecked = false;
      }
    });
    return isChecked;
  };

  const [data, setData] = useState(getDataFromQuery());
  const [initial, setInitial] = useState(false);
  const [select, setSelect] = useState({
    READ: showSelectAll('READ'),
    CREATE: showSelectAll('CREATE'),
    DELETE: showSelectAll('DELETE'),
    UPDATE: showSelectAll('UPDATE')
  });
  const [updateShopAdminUser] = useMutation(ADMIN_UPDATE_PERMISSIONS, {
    variables: {
      userId: adminUser.node.userId,
      role: adminUser.node.role,
      input: {
        permissions: values
      }
    }
  });

  useEffect(() => {
    getValues();
    if (initial) {
      updateShopAdminUser();
    }
  }, [data, select]);

  const handleCheck = (name, value) => {
    setData({ ...data, [name]: value });
    setInitial(true);
  };

  const getValues = () => {
    for (const key in data) {
      if (data[key]) {
        values.push(key);
      }
    }
  };

  const selectAll = (operation, opsValue) => {
    const newData = data;
    PERMISSIONS.forEach(permission => {
      if (
        operation === 'DELETE' &&
        PERMISSION_SKIP_DELETE.indexOf(permission) !== -1
      ) {
        console.log('');
      } else {
        newData[`${permission}_${operation}`] = opsValue;
      }
    });
    setData({ ...data, ...newData });
    setSelect({
      ...select,
      [operation]: opsValue
    });
    setInitial(true);
  };

  return (
    <Grid key={adminUser.node.id} container alignItems="center" spacing={2}>
      <ExpansionPanel style={{ flex: 1, marginBottom: 20 }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Avatar className={classes.avatar}>
            <PersonIcon />
          </Avatar>
          <Grid justify="center" container direction="column">
            <Typography className={classes.heading}>
              {adminUser.node.email}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {adminUser.node.status}
            </Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={3}>
            <Table>
              <TableBody>
                <TableRow key={'selectAll'}>
                  <TableCell />
                  {PERMISSION_OPERATION.map((ops, index) => (
                    <TableCell align="center">
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            checked={select[ops]}
                            onChange={() => selectAll(ops, !select[ops])}
                            value={ops}
                          />
                        }
                        label={ops}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                {PERMISSIONS.map((permission, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        borderBottom: permission === 'REPORT' ? 'none' : ''
                      }}
                    >
                      {permission}
                    </TableCell>
                    {PERMISSION_OPERATION.map((ops, index) => (
                      <TableCell
                        align="center"
                        style={{
                          borderBottom: permission === 'REPORT' ? 'none' : ''
                        }}
                      >
                        {ops === 'DELETE' &&
                        PERMISSION_SKIP_DELETE.indexOf(permission) !==
                          -1 ? null : (
                          <Checkbox
                            checked={data[`${permission}_${ops}`] || false}
                            onChange={() =>
                              handleCheck(
                                `${permission}_${ops}`,
                                !data[`${permission}_${ops}`]
                              )
                            }
                            value={`${permission}_${ops}`}
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  );
};

export default AccountCard;
