import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { getTextFromLanguage } from '../../../../helper/languageHelper';
import { languages } from '../../../../helper/config';
import { isJSON, convertDraftRawToPlainText } from './utils';
import TextField from '../../../common/TextField';

const styles = theme => ({
  root: {
    display: 'flex',
    padding: '16px 24px'
  },
  formControl: {
    width: '100%'
  },
  group: {
    margin: `${theme.spacing}px 0`
  },
  typo: {
    color: '#696969',
    fontSize: '15px',
    marginBottom: '8px'
  }
});

class UpdateText extends React.Component {
  render() {
    const { classes, fields, handleChangeTextField, languages } = this.props;

    return (
      <Grid className={classes.root} direction="column" container={true}>
        <Typography variant="subtitle1" className={classes.typo}>
          Button text
        </Typography>
        <Grid container justify="space-between" spacing={2}>
          {languages.map(language => {
            let text = '';
            try {
              text =
                getTextFromLanguage(fields.buttonText.text, language) || '';
            } catch (error) {}
            return (
              <Grid item style={{ width: '100%' }} key={language}>
                {language}
                <TextField
                  multiline
                  rowsMax="10"
                  classes={{
                    input: classes.cssinput
                  }}
                  placeholder="Insert text here..."
                  type="text"
                  value={isJSON(text) ? convertDraftRawToPlainText(text) : text}
                  onChange={e =>
                    handleChangeTextField(
                      e.target.value ? e.target.value : null,
                      language,
                      'buttonText'
                    )
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

UpdateText.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UpdateText);
