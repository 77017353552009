import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Notification from 'react-notification';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { ADMIN_GET_PAGE_TAG } from '../../gql/admin-queries';
import { ADMIN_UPDATE_PAGE_TAG } from '../../gql/admin-mutations';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import _sortBy from 'lodash/sortBy';

import { API_STAGE } from '../../../../../brikl-config';
import Helmet from 'react-helmet';
import Input from '../../../common/Input';

import _get from 'lodash/get';
// import './CollectionFileDrop.css';
import CircularProgress from '@material-ui/core/CircularProgress';

import PageTagTitle from './PageTagTitle';
// import PageTagMedia from './PageTagMedia';
import PageTagSlug from './PageTagSlug';
// // import PageTagPages from './PageTagPages';
const styles = theme => ({
  progress: {},
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    marginTop: '24px !important',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  },
  paperSelect: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: 'none',
    width: '100%',
    background: 'transparent',
    padding: '6px 16px',
    height: '42px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  container: {
    padding: '8px 0px'
  },
  label: {
    fontSize: '14px'
  },
  rootCheckbox: {
    padding: '8px',
    color: '#B23566',
    '&$checked': {
      color: '#B23566'
    }
  },
  checked: {},
  inputCell: {
    width: 230,
    border: 'none'
  }
});

class PageTagDetail extends Component {
  state = {};

  render() {
    const { classes, tagId, languages } = this.props;

    return (
      <div className={classes.root}>
        <Typography>
          <Grid container>
            <Grid item xs={12}>
              <Query query={ADMIN_GET_PAGE_TAG} variables={{ id: tagId }}>
                {({ loading, error, data: { pageTag }, refetch }) => {
                  if (loading)
                    return <CircularProgress className={classes.progress} />;
                  if (error) return <div>Error :(</div>;
                  return (
                    <Mutation
                      update={(store, { data: { updatePageTag } }) => {
                        this.setState({
                          isActive: true,
                          message: 'Page tag updated'
                        });
                      }}
                      mutation={ADMIN_UPDATE_PAGE_TAG}
                    >
                      {(updatePageTag, { data, loading, error }) => {
                        return (
                          <div>
                            <Helmet>
                              <title>{'Page tag - ' + pageTag.id}</title>
                            </Helmet>
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              style={{ height: 60, marginBottom: 20 }}
                            >
                              <Typography
                                style={{
                                  fontSize: 28,
                                  fontWeight: 400,
                                  color: '#424242'
                                }}
                              >
                                {'Page tag - ' + pageTag.id}
                              </Typography>

                              <div style={{ flex: 1 }} />

                              <Button
                                style={{ marginLeft: 16 }}
                                variant="outlined"
                                color="primary"
                                href={'/admin/' + shopId + '/pagebuilder/tags'}
                              >
                                Back to page tags
                              </Button>
                            </Grid>

                            <Grid container>
                              <Grid xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Grid
                                  container
                                  style={{ padding: '24px 24px 24px 0px' }}
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      variant="subtitle1"
                                      style={{ marginRight: 16 }}
                                    >
                                      Page tag details
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="body1">
                                      Provide information on your tag
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                <Paper
                                  style={{ padding: 24, borderRadius: 15 }}
                                  elevation={0}
                                >
                                  <PageTagTitle
                                    languages={languages}
                                    classes={classes}
                                    pageTag={pageTag}
                                    tagId={tagId}
                                    updatePageTag={updatePageTag}
                                  />
                                  {/*
                                  <CollectionSlug
                                    classes={classes}
                                    collection={collection}
                                    collectionId={collectionId}
                                    updatePageTag={updatePageTag}
                                  />
                                  <CollectionMedia
                                    field={'thumbnail'}
                                    updatePageTag={updatePageTag}
                                    collectionId={collectionId}
                                    collection={collection}
                                    classes={classes}
                                  />
                                  <CollectionMedia
                                    field={'mainBanner'}
                                    updatePageTag={updatePageTag}
                                    collectionId={collectionId}
                                    collection={collection}
                                    classes={classes}
                                  /> */}
                                </Paper>
                              </Grid>
                              {/* <CollectionProducts
                                updatePageTag={updatePageTag}
                                collectionId={collectionId}
                                collection={collection}
                                classes={classes}
                              /> */}
                            </Grid>
                          </div>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Query>
            </Grid>
          </Grid>
        </Typography>
      </div>
    );
  }
}

PageTagDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PageTagDetail);
