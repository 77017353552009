// FOR CONTROL TOOL OF TEXT LOGO PATTERN

import { API_STAGE } from '../../../../../brikl-config'
import * as tools from '../tools'

var DesignRoot = null

export function initComponent(_DesignRoot) {
  DesignRoot = _DesignRoot
}

export function _get_action_object(option, id) {
  // option = "BY_ID, BY_SELECTION || NULL SO BY_SELECTION"

  try {
    if (DesignRoot !== null) {
      if (option && option === 'BY_ID') {
        const selectionObject = DesignRoot.fabric_function.getObjectById(id)
        return selectionObject
      } else {
        const selectionObject = DesignRoot.Selection.state.selectionObject
        return selectionObject
      }

      // GET BY CANVAS ACTIVE OBJECT

      // return canvas.getActiveObject();

      // GET BY CANVAS ACTIVE OBJECT
    } else {
      return null
    }
  } catch (e) {
    // statements
    logger.error('control_tools_event._get_action_object.error', e)
  }
}

export function __remove(selectionId) {
  // receive id or null will query object by default

  try {
    // statements

    if (selectionId) {
      var selectionObject = _get_action_object('BY_ID', selectionId)
    } else {
      var selectionObject = _get_action_object()
    }

    if (
      selectionObject !== null &&
      selectionObject._OBJECT_TYPE === 'TEXT' &&
      window.confirm('Are you sure you want to remove this item ?') === true
    ) {
      DesignRoot.fabric_function.removeItem(selectionObject.id)
      DesignRoot.Selection.setState({
        selection: null,
        selectionObject: null
      })
      DesignRoot.tools.renderComponent('TextStep')
      DesignRoot.tools.renderComponent('LayerControl')
      DesignRoot.TextStep.setState({ triggerDidUpdate: true })

      if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
        DesignRoot.tools.renderComponent('DesignRoot')
      }

      DesignRoot._TRIGGER_RENDER = true
    } else if (
      selectionObject !== null &&
      selectionObject._OBJECT_TYPE === 'LOGO' &&
      window.confirm('Are you sure you want to remove this item ?') === true
    ) {
      DesignRoot.fabric_function.removeItem(selectionObject.id)
      DesignRoot.Selection.setState({
        selection: null,
        selectionObject: null
      })
      DesignRoot.tools.renderComponent('LogoStep')
      DesignRoot.tools.renderComponent('LayerControl')

      if (DesignRoot._ACTIVE_PERSONALIZATION === true) {
        DesignRoot.tools.renderComponent('DesignRoot')
      }

      DesignRoot._TRIGGER_RENDER = true
    } else if (
      selectionObject !== null &&
      selectionObject._OBJECT_TYPE === 'PATTERN' &&
      window.confirm('Are you sure you want to remove this item ?') === true
    ) {
      DesignRoot.fabric_function.removeItem(selectionObject.id)
      DesignRoot.Selection.setState({
        selection: null,
        selectionObject: null
      })
      DesignRoot.tools.renderComponent('PatternStep')
      DesignRoot.tools.renderComponent('LayerControl')
      DesignRoot._TRIGGER_RENDER = true
    }
  } catch (e) {
    // statements
    logger.error('control_tools_event._remove.error', e)
  }
}

export function __mirror(axis, id) {
  try {
    // statements

    logger.log('__mirror', axis, id)

    if (id) {
      var selectionObject = _get_action_object('BY_ID', id)
    } else {
      var selectionObject = _get_action_object()
    }

    if (selectionObject !== null) {
      if (selectionObject._OBJECT_TYPE === 'TEXT') {
        DesignRoot.fabric_function.updateItem(
          selectionObject.id,
          selectionObject._USER_DATA.content,
          selectionObject._USER_DATA.base64,
          0,
          0,
          axis === 'x',
          axis === 'y',
          selectionObject._USER_DATA,
          'TEXT'
        )
      } else if (selectionObject._OBJECT_TYPE === 'LOGO') {
        DesignRoot.fabric_function.updateItem(
          selectionObject.id,
          selectionObject._USER_DATA.filename,
          selectionObject._USER_DATA.base64,
          0,
          0,
          axis === 'x',
          axis === 'y',
          {
            ...selectionObject._USER_DATA,
            filename: selectionObject._USER_DATA.filename,
            base64: selectionObject._USER_DATA.base64,
            s3Url: selectionObject._USER_DATA.s3Url
          },
          'LOGO'
        )
      }

      DesignRoot._TRIGGER_RENDER = true
    } // END selectionObject !== null
  } catch (e) {
    // statements
    logger.error('control_tools_event.mirror.error', e)
  }
}

export function __duplicate(id) {
  try {
    // statements

    logger.log('__duplicate', id)

    if (id) {
      var selectionObject = _get_action_object('BY_ID', id)
    } else {
      var selectionObject = _get_action_object()
    }

    if (selectionObject !== null) {
      var canvasData = {
        width: selectionObject.width,
        height: selectionObject.height,
        scaleX: selectionObject.scaleX,
        scaleY: selectionObject.scaleY
      }

      if (selectionObject._OBJECT_TYPE === 'TEXT') {
        DesignRoot.fabric_function.addItem(
          tools.uuidv4(),
          selectionObject._USER_DATA.content,
          selectionObject._USER_DATA.base64,
          0,
          0,
          selectionObject._USER_DATA,
          'TEXT',
          'DUPLICATE',
          canvasData
        )
        DesignRoot.TextStep.setState({ triggerDidUpdate: true })
      } else if (selectionObject._OBJECT_TYPE === 'LOGO') {
        const uuid = tools.uuidv4()
        DesignRoot.fabric_function.addItem(
          uuid,
          selectionObject._USER_DATA.filename,
          selectionObject._USER_DATA.base64 || selectionObject._USER_DATA.s3Url,
          0,
          0,
          {
            ...selectionObject._USER_DATA,
            filename: selectionObject._USER_DATA.filename,
            base64: selectionObject._USER_DATA.base64,
            s3Url: selectionObject._USER_DATA.s3Url
          },
          'LOGO',
          'DUPLICATE',
          canvasData
        )
      }
    } // END selectionObject !== null
  } catch (e) {
    // statements
    logger.error('control_tools_event.__duplicate.error', e)
  }
}

export function __rePosition(id) {
  try {
    // statements

    logger.log('__rePosition', id)

    if (id) {
      var selectionObject = _get_action_object('BY_ID', id)
    } else {
      var selectionObject = _get_action_object()
    }

    if (selectionObject !== null) {
      if (
        selectionObject._OBJECT_TYPE === 'TEXT' ||
        selectionObject._OBJECT_TYPE === 'LOGO'
      ) {
        DesignRoot.fabric_function.rePosition(selectionObject.id)
      }

      DesignRoot._TRIGGER_RENDER = true
    } // END selectionObject !== null
  } catch (e) {
    // statements
    logger.error('control_tools_event.__rePosition.error', e)
  }
}

export function __moveTo(direction, id) {
  try {
    // statements

    logger.log('__moveTo', direction, id)

    if (id) {
      var selectionObject = _get_action_object('BY_ID', id)
    } else {
      var selectionObject = _get_action_object()
    }

    if (selectionObject !== null) {
      if (
        selectionObject._OBJECT_TYPE === 'TEXT' ||
        selectionObject._OBJECT_TYPE === 'LOGO'
      ) {
        DesignRoot.fabric_function.moveTo(selectionObject, direction)
      }

      DesignRoot._TRIGGER_RENDER = true
    } // END selectionObject !== null
  } catch (e) {
    // statements
    logger.error('control_tools_event.__moveTo.error', e)
  }
}

export function __toggleLock(id) {
  try {
    // statements

    logger.log('__toggleLock', id)

    if (id) {
      var selectionObject = _get_action_object('BY_ID', id)
    } else {
      var selectionObject = _get_action_object()
    }

    if (selectionObject !== null) {
      if (
        selectionObject._OBJECT_TYPE === 'TEXT' ||
        selectionObject._OBJECT_TYPE === 'LOGO' ||
        selectionObject._OBJECT_TYPE === 'PATTERN'
      ) {
        DesignRoot.fabric_function.toggleLock(selectionObject)
      }

      DesignRoot._TRIGGER_RENDER = true
    } // END selectionObject !== null
  } catch (e) {
    // statements
    logger.error('control_tools_event.__toggleLock.error', e)
  }
}

export function __toggleVisible(id) {
  try {
    // statements

    logger.log('__toggleVisible', id)

    if (id) {
      var selectionObject = _get_action_object('BY_ID', id)
    } else {
      var selectionObject = _get_action_object()
    }

    if (selectionObject !== null) {
      if (
        selectionObject._OBJECT_TYPE === 'TEXT' ||
        selectionObject._OBJECT_TYPE === 'LOGO' ||
        selectionObject._OBJECT_TYPE === 'PATTERN'
      ) {
        DesignRoot.fabric_function.toggleVisible(selectionObject)
      }

      DesignRoot._TRIGGER_RENDER = true
    } // END selectionObject !== null
  } catch (e) {
    // statements
    logger.error('control_tools_event.__toggleVisible.error', e)
  }
}

export function __reCenterOrigin(id) {
  try {
    // statements

    logger.log('__reCenterOrigin', id)

    if (id) {
      var selectionObject = _get_action_object('BY_ID', id)
    } else {
      var selectionObject = _get_action_object()
    }

    if (selectionObject !== null) {
      if (selectionObject._OBJECT_TYPE === 'PATTERN') {
        selectionObject.set({
          left: 0,
          top: 0
        })

        DesignRoot.canvas.requestRenderAll()
      }

      DesignRoot._TRIGGER_RENDER = true
    } // END selectionObject !== null
  } catch (e) {
    // statements
    logger.error('control_tools_event.__reCenterOrigin.error', e)
  }
}

export function test(id) {
  try {
    // statements

    logger.log('test', id)

    if (id) {
      var selectionObject = _get_action_object('BY_ID', id)
    } else {
      var selectionObject = _get_action_object()
    }

    if (selectionObject !== null) {
      if (selectionObject._OBJECT_TYPE === 'TEXT') {
      } else if (selectionObject._OBJECT_TYPE === 'LOGO') {
      }
    } // END selectionObject !== null
  } catch (e) {
    // statements
    logger.error('control_tools_event.test.error', e)
  }
}

export function __toggle2D3D(type) {
  try {
    // statements

    if (DesignRoot.state.hidden3D === type) {
      DesignRoot.setState({ hidden3D: '' })
    } else {
      DesignRoot.setState({ hidden3D: type })
    }
  } catch (e) {
    // statements
    logger.error('control_tools_event.__toggle2D3D.error', e)
  }
}

export function __arrowControl(option, e) {
  try {
    var activeObject = DesignRoot.canvas.getActiveObject()

    logger.log('__arrowControl', option, activeObject)

    if (
      activeObject &&
      activeObject._OBJECT_TYPE &&
      (activeObject._OBJECT_TYPE === 'TEXT' ||
        activeObject._OBJECT_TYPE === 'LOGO' ||
        activeObject._OBJECT_TYPE === 'PATTERN')
    ) {
      e.preventDefault()
      var item = DesignRoot.fabric_function.getObjectById(activeObject.id)

      var STEP = 1
      if (e.shiftKey === true) {
        STEP = 5
      }

      switch (option) {
        case 'ArrowUp':
          item.top = item.top - STEP
          item.setCoords()
          break
        case 'ArrowDown':
          item.top = item.top + STEP
          item.setCoords()
          break
        case 'ArrowLeft':
          item.left = item.left - STEP
          item.setCoords()
          break
        case 'ArrowRight':
          item.left = item.left + STEP
          item.setCoords()
          break
        default:
        // code block
      }

      DesignRoot._TRIGGER_RENDER = true
      DesignRoot.canvas.renderAll()
    }
  } catch (e) {
    // statements
    logger.error('control_tools_event.__arrowControl.error', e)
  }
}
