import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { ADMIN_GET_SHOP_SETTINGS } from '../../gql/admin-queries';
import { ADMIN_UPDATE_SHOP_SHIPPING_SETTINGS } from '../../gql/admin-mutations';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import { countries } from '../../../../helper/countryList';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '../../../common/Input';
import Select from '../../../common/Select';

import PackageCard from './PackageCard';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%'
  }
});

class IntegrationsSettings extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid
          container
          justify="flex-start"
          direction="row"
          alignItems="center"
          style={{ height: 60, marginBottom: 20 }}
        >
          <Typography
            style={{
              fontSize: 28,
              fontWeight: 400,
              color: '#424242'
            }}
          >
            Integrations
          </Typography>
          <div style={{ flex: 1 }} />
        </Grid>

        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Analytics
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Input
                    label="Google Analytics ID"
                    placeholder="UA-XXXXXXXXX"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 24 }}>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <Grid
              container
              style={{ padding: '24px 24px 24px 0px' }}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                  Social Media
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Paper elevation={0} style={{ padding: 24 }}>
              <Grid container spacing={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Input
                      label="Instagram username"
                      placeholder="myusername"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(IntegrationsSettings);
