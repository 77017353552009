import React, { Component } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Auth from '@aws-amplify/auth';

var jwtDecode = require('jwt-decode');

export const drawerWidth = 200;

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '180px',
    paddingTop: '0px',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)'
  },
  linkContainer: {
    color: '#000000',
    padding: 0
  },
  linkText: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: 300
  },
  logo: {
    maxWidth: 150,
    height: '35px',
    marginTop: '24px',
    alignSelf: 'center'
  },
  searchIcon: {
    width: 45,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white'
  },
  inputRoot: {
    color: 'white',
    width: '100%'
  },
  menuButton: {
    marginRight: 20,
    color: '#000000',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  }
});
const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          width: '100%',
          height: '100%',
          display: 'block',
          textDecoration: 'none',
          color: '#000'
          // textShadow: isCurrent ? "1px #eee" : "none"
        }
      };
    }}
  />
);
class ShopMenu extends Component {
  state = {
    isBriklSuperAdmin: true,
    open: true
  };

  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      if (session) {
        // console.log('session', session);
        var decoded = jwtDecode(session.idToken.jwtToken);
        if (decoded['cognito:groups'].includes('BRIKLSUPERADMIN') !== -1) {
          this.setState({
            isBriklSuperAdmin: true
          });
        }
      }
    } catch (error) {}
  }
  render() {
    const { classes, theme, shopId } = this.props;
    const { isBriklSuperAdmin, open } = this.state;
    var menuContent = (
      <div
        style={{
          background: '#ffffff',
          height: '100vh',
          overflowY: 'auto',
          width: '100%'
        }}
      >
        <List>
          <ListItem classes={{ primary: classes.linkContainer }} button>
            <NavLink
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + 'reports/orders'}
            >
              <ListItemText
                classes={{ primary: classes.linkText }}
                primary="Orders"
              />
            </NavLink>
          </ListItem>
          <ListItem classes={{ primary: classes.linkContainer }} button>
            <NavLink
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + 'reports/customers'}
            >
              <ListItemText
                classes={{ primary: classes.linkText }}
                primary="Customers"
              />
            </NavLink>
          </ListItem>
          <ListItem classes={{ primary: classes.linkContainer }} button>
            <NavLink
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + 'reports/users'}
            >
              <ListItemText
                classes={{ primary: classes.linkText }}
                primary="Users"
              />
            </NavLink>
          </ListItem>
          <ListItem classes={{ primary: classes.linkContainer }} button>
            <NavLink
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + 'reports/discount'}
            >
              <ListItemText
                classes={{ primary: classes.linkText }}
                primary="Discounts"
              />
            </NavLink>
          </ListItem>
        </List>
      </div>
    );

    return <div className={classes.root}>{menuContent}</div>;
  }
}
ShopMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ShopMenu);
