import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import _get from 'lodash/get';
import MediaList from './MediaList';

const styles = theme => ({
  progress: {},
  root: {
    overflow: 'hidden',
    maxWidth: '1000px',
    padding: 24,
    margin: '0 auto',
    width: '100%',
    flex: 1
  },
  table: {
    width: '100%',
    height: '100%'
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#555555'
  },
  h1: {
    fontSize: 30,
    fontWeight: 'medium',
    color: '#424242'
  },
  cssLabel: {
    fontSize: '20px',
    '&$cssFocused': {
      color: '#DADADA'
    }
  },
  cssOutlinedInput: {
    border: '1px solid #DADADA',
    padding: '8px 16px',
    borderRadius: '4px',
    '&:before': {
      borderBottomColor: 'transparent !important'
    },
    '&:after': {
      borderBottomColor: 'transparent !important'
    }
  },
  cell: {
    padding: '0px 16px !important'
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #CCCCCC',
    background: '#f4f4f4',
    padding: '6px 6px 6px 16px'
  },
  cssinput: {
    width: '100%'
  },
  cssRootInput: {
    fontSize: '13px',
    width: '100%'
  }
});

class Media extends Component {
  state = {
    filterByName: '',
    viewMode: 'GALLERY'
  };
  render() {
    const { classes, shopId, styles } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{
            width: '100%',
            maxWidth: '1000px',
            margin: 'auto',
            padding: 24
          }}
          spacing={2}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ width: '100%', maxWidth: '1000px', margin: 'auto' }}
          >
            <Grid item xs={6}>
              <Typography
                data-cy="Header-Media"
                component="h1"
                className={classes.h1}
              >
                Media
              </Typography>
            </Grid>

            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                onClick={() => this.setState({ viewMode: 'GALLERY' })}
              >
                Gallery view
              </Button>

              <Button
                style={{ marginLeft: 10 }}
                variant={'outlined'}
                color={'primary'}
                onClick={() => this.setState({ viewMode: 'LIST' })}
              >
                List view
              </Button>
            </Grid>
          </Grid>

          {/* <Grid item style={{ padding: '0px', marginRight: 16 }}>
            <Paper className={classes.paper}>
              <InputBase
                classes={{
                  root: classes.cssRootInput,
                  input: classes.cssinput
                }}
                margin="normal"
                placeholder="Filter by name"
                value={this.state.filter}
                onChange={e => this.setState({ filter: e.target.value })}
              />
            </Paper>
          </Grid> */}

          <Grid item xs={12}>
            <InputBase
              placeholder="Search by filename"
              value={this.state.filterByName}
              onChange={e => this.setState({ filterByName: e.target.value })}
            />
          </Grid>

          <Grid item xs={12}>
            <MediaList
              shopId={shopId}
              viewMode={this.state.viewMode}
              showDownload={true}
              filterByName={this.state.filterByName}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Media);
