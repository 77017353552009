import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import { OrganisationContext } from '../../../user/context/OrganisationContext';
import CollectionList from './CollectionList';
import CollectionDetail from './CollectionDetail';

class Collections extends Component {
  render() {
    const { shopId, shop, shopUrl } = this.props;
    return this.props.collectionId ? (
      <Grid container>
        <OrganisationContext.Consumer>
          {currentOrganisation => (
            <CollectionDetail
              shopUrl={shopUrl}
              defaultLanguage={shop.defaultLanguage}
              currentOrganisation={currentOrganisation}
              shopId={shopId}
              languages={shop.languages}
              collectionId={this.props.collectionId}
            />
          )}
        </OrganisationContext.Consumer>
      </Grid>
    ) : (
      <Grid container style={{ width: '100%' }}>
        <CollectionList
          defaultLanguage={shop.defaultLanguage}
          shopId={shopId}
        />
      </Grid>
    );
  }
}

export default Collections;
