import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {
  Divider,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import { getTextFromLanguage } from '../../../../helper/languageHelper'

import DeleteImage from './DeleteImage'
import Uploader from './Uploader'
import NativeSelect from '@material-ui/core/NativeSelect'
import Slider from '@material-ui/core/Slider'
import ColorPicker from './ColorPicker'
import AlignItems from './AlignItems'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Query } from 'react-apollo'
import {
  GET_PAGES,
  ADMIN_SHOP_COLLECTIONS,
  ADMIN_SHOP_PRODUCT_PACKAGES,
  ADMIN_SHOP_PRODUCTS,
  ADMIN_SHOP_SALESCHANNELS
} from '../../gql/admin-queries'
import { MEDIA_CDN_URL } from '../../../../../brikl-config'
import SelectPage from './SelectPage'
import SelectProducts from './SelectProducts'
import SelectTeamstore from './SelectTeamstore'
import SelectProductPackage from './SelectProductPackage'
import SelectCollection from './SelectCollection'

import { BORDER_TYPES } from './config'

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase)

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  input: {
    paddingLeft: 8,
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  }
})

class UpdateRequestForm extends React.Component {
  state = {
    uploading: false,
    pageDialog: false,
    urlType: this.props.fields.urlType
  }

  handlePageOpen = () => {
    this.setState({ pageDialog: true })
  }

  handlePageClose = () => {
    this.setState({ pageDialog: false })
  }

  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,
      fields,
      Template,
      shopId,
      languages
    } = this.props

    return (
      <Grid>
        <ExpansionPanel
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
          expanded={expanded === 'panel2'}
          onChange={handleExpandedChange('panel2')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ expanded: classes.expansionPanelExpanded }}
          >
            <Typography
              variant="subtitle1"
              className={classes.typo}
              data-cy="Card-Settings"
            >
              Request Quote Form Settings
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{ root: classes.expansionPanelDetails }}
          >
            <Divider />
            <List></List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    )
  }
}

UpdateRequestForm.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
}

export default withStyles(styles)(UpdateRequestForm)
