import React, { Component } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List, Button } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Auth from '@aws-amplify/auth';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';

var jwtDecode = require('jwt-decode');

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    overflowX: 'hidden',
    float: 'left'
  },
  linkContainer: {
    padding: 0,
    color: '#000'
  },
  linkText: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: 300
  },
  logo: {
    maxWidth: 150,
    height: '35px',
    marginTop: '24px',
    alignSelf: 'center'
  },
  searchIcon: {
    width: 45,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white'
  },
  inputRoot: {
    color: 'white',
    width: '100%'
  },
  menuButton: {
    marginRight: 20,
    color: '#000000',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  containerMenu: {
    background: '#ffffff',
    height: '100vh',
    overflowY: 'auto',
    width: '180px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  containerMenuMobile: {
    background: '#ffffff',
    height: '100vh',
    overflowY: 'auto',
    width: '0px',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 4,
    display: 'none',
    borderRight: '1px solid #E5E5E5',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '180px'
    }
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  buttonCloseDrawer: {
    position: 'fixed',
    top: 50,
    left: 158,
    zIndex: 10000,
    background: '#FFF',
    '&:hover': {
      background: '#FFF'
    },
    border: '1px solid #E5E5E5',
    borderRadius: '100%',
    minWidth: 40,
    minHeight: 40
  }
});

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          width: '100%',
          height: '100%',
          display: 'block',
          textDecoration: 'none',
          color: '#000'
          // textShadow: isCurrent ? "1px #eee" : "none"
        }
      };
    }}
  />
);
class InventoryMenu extends Component {
  state = {
    isBriklSuperAdmin: true,
    open: true,
    show: false
  };

  handleClick = () => {
    this.setState(state => ({ show: !state.show }));
  };

  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      if (session) {
        // console.log('session', session);
        var decoded = jwtDecode(session.idToken.jwtToken);
        if (decoded['cognito:groups'].includes('BRIKLSUPERADMIN') !== -1) {
          this.setState({
            isBriklSuperAdmin: true
          });
        }
      }
    } catch (error) {}
  }
  render() {
    const { classes, theme, shopId } = this.props;
    const { isBriklSuperAdmin, open, show } = this.state;
    var menuContent = (
      <div>
        <div className={classes.containerMenu}>
          <List style={{ width: '180px' }}>
            <NavLink
              data-cy="Products"
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + '/inventory/products'}
            >
              <ListItem classes={{ primary: classes.linkContainer }} button>
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Products"
                />
              </ListItem>
            </NavLink>
            <NavLink
              data-cy="Product-Packages"
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + '/inventory/productpackages'}
            >
              <ListItem classes={{ primary: classes.linkContainer }} button>
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Product Packages"
                />
              </ListItem>
            </NavLink>
            <NavLink
              data-cy="Collections"
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + '/inventory/collections'}
            >
              <ListItem classes={{ primary: classes.linkContainer }} button>
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Collections"
                />
              </ListItem>
            </NavLink>
            <NavLink
              data-cy="Attributes"
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + '/inventory/attributes'}
            >
              <ListItem classes={{ primary: classes.linkContainer }} button>
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Attributes"
                />
              </ListItem>
            </NavLink>
            <NavLink
              data-cy="Variants"
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + '/inventory/variants'}
            >
              <ListItem classes={{ primary: classes.linkContainer }} button>
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Variants"
                />
              </ListItem>
            </NavLink>
            <hr />
            <ListItem
              classes={{ primary: classes.linkContainer }}
              data-cy="Custom-products-only"
            >
              <ListItemText
                style={{
                  marginLeft: '-8px'
                }}
                classes={{ primary: classes.linkText }}
                primary="Custom products only:"
              />
            </ListItem>
            <NavLink
              data-cy="Materials"
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + '/inventory/fabrics'}
            >
              <ListItem classes={{ primary: classes.linkContainer }} button>
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Materials"
                />
              </ListItem>
            </NavLink>
            <NavLink
              data-cy="Material-groups"
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + '/inventory/material-groups'}
            >
              <ListItem classes={{ primary: classes.linkContainer }} button>
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Material groups"
                />
              </ListItem>
            </NavLink>
            <NavLink
              data-cy="Colors"
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + '/inventory/colors'}
            >
              <ListItem classes={{ primary: classes.linkContainer }} button>
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Colors"
                />
              </ListItem>
            </NavLink>
            <NavLink
              data-cy="Printing-techniques"
              style={{ textDecoration: 'none' }}
              to={'/admin/' + shopId + '/inventory/printing'}
            >
              <ListItem classes={{ primary: classes.linkContainer }} button>
                <ListItemText
                  classes={{ primary: classes.linkText }}
                  primary="Printing techniques"
                />
              </ListItem>
            </NavLink>
          </List>
        </div>

        <div className={classes.mobile}>
          <Button
            variant="contained"
            style={{
              position: 'fixed',
              top: 70,
              left: 13,
              margin: 'auto',
              minWidth: 35,
              minHeight: 35,
              width: 35,
              height: 35,
              borderRadius: '100%',
              backgroundColor: '#fff',
              border: '1px solid #E5E5E%'
            }}
            onClick={this.handleClick}
          >
            <ChevronRight />
          </Button>
          {show ? (
            <div className={classes.containerMenuMobile}>
              <List style={{ width: '180px', position: 'relative' }}>
                <Button
                  className={classes.buttonCloseDrawer}
                  onClick={this.handleClick}
                >
                  <ChevronLeft />
                </Button>
                <NavLink
                  style={{ textDecoration: 'none' }}
                  onClick={this.handleClick}
                  to={'/admin/' + shopId + '/inventory/products'}
                >
                  <ListItem classes={{ primary: classes.linkContainer }} button>
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Products"
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={{ textDecoration: 'none' }}
                  onClick={this.handleClick}
                  to={'/admin/' + shopId + '/inventory/productpackages'}
                >
                  <ListItem classes={{ primary: classes.linkContainer }} button>
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Product Packages"
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={{ textDecoration: 'none' }}
                  onClick={this.handleClick}
                  to={'/admin/' + shopId + '/inventory/collections'}
                >
                  <ListItem classes={{ primary: classes.linkContainer }} button>
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Collections"
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={{ textDecoration: 'none' }}
                  onClick={this.handleClick}
                  to={'/admin/' + shopId + '/inventory/attributes'}
                >
                  <ListItem classes={{ primary: classes.linkContainer }} button>
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Attributes"
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={{ textDecoration: 'none' }}
                  onClick={this.handleClick}
                  to={'/admin/' + shopId + '/inventory/variants'}
                >
                  <ListItem classes={{ primary: classes.linkContainer }} button>
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Variants"
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={{ textDecoration: 'none' }}
                  onClick={this.handleClick}
                  to={'/admin/' + shopId + '/inventory/fabrics'}
                >
                  <ListItem classes={{ primary: classes.linkContainer }} button>
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Materials"
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={{ textDecoration: 'none' }}
                  onClick={this.handleClick}
                  to={'/admin/' + shopId + '/inventory/material-groups'}
                >
                  <ListItem classes={{ primary: classes.linkContainer }} button>
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Material groups"
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={{ textDecoration: 'none' }}
                  onClick={this.handleClick}
                  to={'/admin/' + shopId + '/inventory/colors'}
                >
                  <ListItem classes={{ primary: classes.linkContainer }} button>
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Colors"
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={{ textDecoration: 'none' }}
                  onClick={this.handleClick}
                  to={'/admin/' + shopId + '/inventory/printing'}
                >
                  <ListItem classes={{ primary: classes.linkContainer }} button>
                    <ListItemText
                      classes={{ primary: classes.linkText }}
                      primary="Printing techniques"
                    />
                  </ListItem>
                </NavLink>
              </List>
            </div>
          ) : null}
        </div>
      </div>
    );

    return <div className={classes.root}>{menuContent}</div>;
  }
}
InventoryMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InventoryMenu);
