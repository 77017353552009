import React, { useState, useContext, useEffect } from 'react'
import RichText from '../RichText/RichText'
import PlaceholderImg from '../Elements/PlaceholderImg'
import ContentActions from './ContentActions'
import UpdateContentPanel from './UpdateContentPanel'
import { useMutation } from '@apollo/react-hooks'
import Banner from '../Elements/Banner'
import Products from '../Elements/Products'
import Collections from '../Elements/Collections'
import Button from '../Elements/Button'
import { getTextFromLanguage } from '../../../../../../helper/languageHelper'
import { removeKeys } from '../../../../../../helper/removeKeys'
import { SelectedLanguageContext } from '../LanguageContext'
import {
  ADMIN_UPDATE_CONTENT_VIEW,
  ADMIN_UPDATE_CONTENT_VIEW_STYLE,
} from '../gql/mutations'
import { GET_CONTENT_MODEL } from '../gql/queries'
import RenderModules from '../Modules/RenderModules'
import Maps from '../Elements/Maps'
import { DefaultPageType } from '../Modules/Types'
import Notification from '../Notifications/Notification'
import { Grid } from '@material-ui/core'
import Form from '../Elements/Form'
import Teamstores from '../Elements/Teamstores'

interface Props {
  element: any
  editing: boolean
  elementSelect?: boolean
  modelId: string | null
  hoverElement: string | null
  dialog: boolean
  view?: any
  defaultPageType?: DefaultPageType
  salesChannelId?: string
  productDesignId?: string
  ownedByOrganisationId?: string
  urlPrefix: string
}
const RenderElement: React.FC<Props> = props => {
  const {
    element,
    editing,
    elementSelect,
    modelId,
    hoverElement,
    dialog,
    view,
    defaultPageType,
    salesChannelId,
    productDesignId,
    ownedByOrganisationId,
    urlPrefix,
    isHover,
  } = props
  const [updatePanel, setUpdatePanel] = useState({
    id: null,
    show: false,
  })

  const [hasSpace, setHasSpace] = useState(true)

  const [notify, setNotify] = useState(false)

  const [updateContentView] = useMutation(ADMIN_UPDATE_CONTENT_VIEW, {
    refetchQueries: [{ query: GET_CONTENT_MODEL, variables: { id: modelId } }],
    onCompleted: () => setNotify(true),
  })

  const [updateContentViewStyle] = useMutation(
    ADMIN_UPDATE_CONTENT_VIEW_STYLE,
    {
      refetchQueries: [
        { query: GET_CONTENT_MODEL, variables: { id: modelId } },
      ],
      onCompleted: () => setNotify(true),
    }
  )

  const handleShowUpdatePanel = (id: any, show: boolean) => {
    setUpdatePanel({
      id: id,
      show: show,
    })
  }

  const selectedLanguage = useContext(SelectedLanguageContext)

  const handleTextUpdate = (
    contentId: string | null,
    fieldName: string,
    value: any,
    level: 'root' | 'style',
    prevValue: any,
    styleId?: string | null
  ) => {
    let t = prevValue
    t.text = prevValue.text.map((text: any) => {
      return {
        ...text,
        content: selectedLanguage === text.langCode ? value : text.content,
      }
    })
    if (!prevValue.text.find((e: any) => e.langCode === selectedLanguage)) {
      t.text.push({
        content: value,
        langCode: selectedLanguage,
      })
    }
    t = removeKeys(t, '__typename')
    t = removeKeys(t, 'defaultValue')

    if (level === 'root') {
      updateView(contentId, fieldName, t)
    } else {
      updateStyle(contentId, fieldName, t, styleId)
    }
  }

  const handleUpdateMapsContent = (
    contentId: string | null,
    lat: any,
    lng: any,
    place: any
  ) => {
    updateContentView({
      variables: {
        id: contentId,
        fieldId: element.fieldId,
        revisionNo: 1,
        input: {
          place: place,
          longitude: lng,
          latitude: lat,
          type: element.type,
        },
      },
    })
  }

  const handleUpdateContent = (
    contentId: string | null,
    fieldName: string,
    value: any,
    level: 'root' | 'style',
    styleId?: string | null
  ) => {
    if (level === 'root') {
      updateView(contentId, fieldName, value)
    } else {
      updateStyle(contentId, fieldName, value, styleId)
    }
  }

  const updateView = (
    contentId: string | null,
    fieldName: string,
    value: any
  ) => {
    updateContentView({
      variables: {
        id: contentId,
        fieldId: element.fieldId,
        revisionNo: 1,
        input: {
          [fieldName]: value,
          type: element.type,
        },
      },
    })
  }

  const updateStyle = (
    contentId: string | null,
    fieldName: string,
    value: any,
    styleId?: string | null
  ) => {
    updateContentViewStyle({
      variables: {
        id: styleId,
        contentId: contentId,
        input: {
          [fieldName]: value,
          type: element.type,
        },
      },
    })
  }

  let isMobile = false
  try {
    if (typeof navigator !== `undefined`) {
      isMobile =
        /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ||
        window.innerWidth < 600
    }
  } catch (error) {}

  return (
    <>
      {element.id === hoverElement &&
      element.type !== 'TEXT' &&
      editing &&
      !defaultPageType ? (
        <ContentActions
          handleShowUpdatePanel={handleShowUpdatePanel}
          contentId={element.id}
          elementType={element.type}
          setSpace={setHasSpace}
        />
      ) : null}
      {editing && element.id === updatePanel.id && updatePanel.show ? (
        <UpdateContentPanel
          element={element}
          elementType={element.type}
          handleShowUpdatePanel={handleShowUpdatePanel}
          handleUpdate={handleUpdateContent}
          handleTextUpdate={handleTextUpdate}
          handleUpdateMapsContent={handleUpdateMapsContent}
          modelId={modelId}
          hasSpace={hasSpace}
          setNotify={setNotify}
        />
      ) : null}
      {element.type === 'PRODUCT' ? (
        <Products
          urlPrefix={urlPrefix}
          view={view}
          element={element}
          dialog={dialog}
        />
      ) : element.type === 'COLLECTION' ? (
        <Collections
          urlPrefix={urlPrefix}
          view={view}
          element={element}
          dialog={dialog}
        />
      ) : element.type === 'TEXT' ? (
        !editing && !elementSelect ? (
          <RichText
            isPreview={true}
            text={getTextFromLanguage(
              element.contentText.text,
              selectedLanguage
            )}
          />
        ) : !editing && elementSelect ? (
          <div style={{ padding: 20 }}>
            <RichText
              isPreview={true}
              text={getTextFromLanguage(
                element.contentText.text,
                selectedLanguage
              )}
            />
          </div>
        ) : (
          <div style={{ padding: '0px 20px' }}>
            <RichText
              isHover={isHover}
              contentId={element.id ? element.id : ''}
              text={getTextFromLanguage(
                element.contentText.text,
                selectedLanguage
              )}
              handleTextUpdate={handleTextUpdate}
              element={element}
            />
          </div>
        )
      ) : element.type === 'BUTTON' ? (
        <Button urlPrefix={urlPrefix} element={element} />
      ) : element.type === 'IMAGE' ? (
        !element.contentImage ||
        element.contentImage.indexOf('undefined') !== -1 ? (
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <PlaceholderImg />
            </Grid>
          </Grid>
        ) : element.imageTitle ||
          element.imageSubTitle ||
          element.imageButton ? (
          <Banner
            isMobile={isMobile}
            urlPrefix={urlPrefix}
            dialog={dialog}
            element={element}
          />
        ) : (
          <Grid style={{ textAlign: 'center' }} container={true}>
            <Grid
              item={true}
              xs={12}
              style={{
                textAlign: element.imageAlign || 'center',
              }}
            >
              <img
                style={{
                  padding: 0,
                  objectFit:
                    element && element.contentStyle
                      ? element.contentStyle[0].objectFit
                      : 'contain',
                  textAlign: 'center',
                  width: '100%',
                  maxHeight:
                    element && element.contentStyle
                      ? element.contentStyle[0].maxHeight
                      : '400px',
                  maxWidth:
                    element && element.contentStyle
                      ? element.contentStyle[0].width
                      : '100%',
                  // objectPosition: `50% ${element?.contentStyle[0]
                  //   ?.objectPosition || '50%'}`
                }}
                src={element.contentImage}
              />
            </Grid>
          </Grid>
        )
      ) : element.type === 'MODULE' ? (
        <RenderModules
          defaultPageType={defaultPageType}
          moduleType={element?.moduleType}
        />
      ) : element.type === 'MAPS' ? (
        <div style={{ padding: 20 }}>
          <Maps
            lat={element?.latitude}
            lng={element?.longitude}
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAyQeBJLKkHLpIsVaNlOp7ISDRxS8wzW1s&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      ) : element.type === 'FORM' ? (
        <Form
          formTitle={getTextFromLanguage(
            element?.formTitle?.text,
            selectedLanguage
          )}
          formType={element?.formVariant}
          portalId={element?.portalId}
          cssClass={element?.cssClass}
          formId={element?.formId}
          target={element?.target}
          isDialog={dialog}
          salesChannelId={salesChannelId}
          productDesignId={productDesignId}
          ownedByOrganisationId={ownedByOrganisationId}
        />
      ) : element.type === 'TEAMSTORE' ? (
        <Teamstores view={view} element={element} dialog={dialog} />
      ) : null}

      {/* Success notification */}
      {notify ? (
        <Notification
          variant={'success'}
          open={notify}
          setOpen={setNotify}
          message="Saved"
        />
      ) : null}
    </>
  )
}

export default RenderElement
