import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  CircularProgress,
  Typography,
  Grid,
  WithStyles,
  withStyles,
  createStyles,
  Button
} from '@material-ui/core'
import { convertTS } from '../../../../helper/time'
import BoardProductPreviews from '../../../common/BoardProductPreviews'
import Techpack from '../techpack/Techpack'

import { ADMIN_GET_LEAD } from '../../gql/admin-queries'

const styles = () =>
  createStyles({
    root: {
      overflow: 'hidden',
      maxWidth: '1000px',
      margin: '0 auto',
      width: '100%'
    },
    table: {
      width: '100%',
      padding: '0'
    }
  })

interface Props extends WithStyles<typeof styles> {
  leadId: string
  shopId: string
}

interface DesignProps {
  ownedByOrganisationId: string
  shopId: string
  productDesign: {
    id: string
    product: {
      id: string
    }
  }
}

const ProductDesign: React.FunctionComponent<DesignProps> = props => {
  const { productDesign, shopId, ownedByOrganisationId } = props
  if (!ownedByOrganisationId) return null
  return (
    <>
      <Button
        color="primary"
        variant={'contained'}
        style={{}}
        onClick={() => {
          window.open(
            `/admin/${shopId}/designroom/room/?s=${shopId}&p=${productDesign.product?.id}&pd=${productDesign.id}&apptype=2d-3d&o=${ownedByOrganisationId}&view=true`
          )
        }}
      >
        View on Design Room
      </Button>
      <br />
      <br />
      <Techpack
        orderId={null}
        orderType="CUSTOM"
        order={{
          ownedByOrganisationId
        }}
        item={{
          shopId,
          product: productDesign.product,
          productDesignId: productDesign.id
        }}
      />
    </>
  )
}

const LeadDetail: React.FunctionComponent<Props> = props => {
  const { classes, leadId, shopId } = props
  const { data, loading, error } = useQuery(ADMIN_GET_LEAD, {
    variables: { id: leadId }
  })

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ padding: 28 }}
      >
        <Grid xs={12}>
          <Typography variant="h4" component="h2">
            Lead Detail
          </Typography>
          {loading ? <CircularProgress /> : null}
          <Typography component="p">
            <strong>ID:</strong> {data?.lead?.id}
          </Typography>
          <Typography component="p">
            <strong>Created:</strong> {convertTS(data?.lead?.createdAt)}
          </Typography>
          <Typography component="p">
            <strong>Status:</strong> {data?.lead?.status}
          </Typography>
          <Typography component="p">
            <strong>Name:</strong> {data?.lead?.firstName}{' '}
            {data?.lead?.lastName}
          </Typography>
          <Typography component="p">
            <strong>Email:</strong> {data?.lead?.email}
          </Typography>
          <Typography component="p">
            <strong>Phone:</strong> {data?.lead?.phone}
          </Typography>
          {data?.lead?.tempKeyValues.map(value => (
            <div key={value.key}>
              <Typography component="p">
                <strong>{value.key}:</strong> {value.value}
              </Typography>
            </div>
          ))}
          <Typography component="p">
            <strong>Designs:</strong>
          </Typography>
          {data?.lead?.productDesigns?.length > 0 ? (
            data?.lead?.productDesigns.map(productDesign => (
              <div key={productDesign.id}>
                <ProductDesign
                  shopId={shopId}
                  ownedByOrganisationId={data?.lead?.ownedByOrganisationId}
                  productDesign={productDesign}
                />
              </div>
            ))
          ) : (
            <Typography component="p">No designs</Typography>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(LeadDetail)
