import React, { Component } from 'react';
import { Grid, Typography, Paper, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _sortBy from 'lodash/sortBy';
import TextField from '../../../common/TextField';
import _get from 'lodash/get';
import MomentUtils from '@date-io/moment';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider
} from 'material-ui-pickers';
import moment from 'moment';

const styles = {
  root: {
    padding: '2px 8px 2px 0px !important',
    display: 'flex',
    alignItems: 'center',
    minWidth: 100,
    background: '#fff',
    marginTop: '4px !important',
    borderRadius: 4,
    fontWeight: 300
  },
  input: {
    marginLeft: 8,
    width: '100%',
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 300,
    color: '#000'
  }
};

const TeamstoreAvailableFrom = ({
  classes,
  teamstore,
  teamstoreId,
  updateTeamstore
}) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container style={{ padding: '24px 24px 0px' }}>
        <Typography variant="subtitle1">Available from:</Typography>
        <Grid
          container
          key={'style-titleText-'}
          direction="row"
          alignItems="flex-end"
          spacing={1}
          className={classes.container}
        >
          <Grid item xs={4} style={{ marginLeft: '-8px' }}>
            <InputLabel className={classes.label}>Date</InputLabel>
            <Paper className={classes.root} elevation={0}>
              <DatePicker
                value={teamstore.availableFrom}
                onChange={e => {
                  console.log(JSON.stringify(e));
                  console.log(moment(e).valueOf());
                  updateTeamstore({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      updateTeamstore: {
                        ...teamstore,
                        availableFrom: moment(e).valueOf()
                      }
                    },
                    variables: {
                      id: teamstoreId,
                      input: {
                        availableFrom: moment(e).valueOf()
                      }
                    }
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <InputLabel className={classes.label}>Time</InputLabel>
            <Paper className={classes.root} elevation={0}>
              <TimePicker
                value={teamstore.availableFrom}
                onChange={e => {
                  // console.log(JSON.stringify(e));
                  updateTeamstore({
                    optimisticResponse: {
                      __typename: 'Mutation',
                      updateTeamstore: {
                        ...teamstore,
                        availableFrom: moment(e).valueOf()
                      }
                    },
                    variables: {
                      id: teamstoreId,
                      input: {
                        availableFrom: moment(e).valueOf()
                      }
                    }
                  });
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">
              GMT {moment().format('Z')} (your timezone)
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="body1">
              {teamstore.availableFrom
                ? new Date(teamstore.availableFrom).toGMTString()
                : null}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

TeamstoreAvailableFrom.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TeamstoreAvailableFrom);
