import _sortBy from 'lodash/sortBy';
import Input from '../../../common/Input';

import React, { Component } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

import uuid from 'uuid';
import { MultipleSelect } from 'react-select-material-ui';
import omitDeep from 'omit-deep';
import cloneDeep from 'lodash/cloneDeep';
import ShippingRate from './ShippingRate';
class ShippingZone extends Component {
  state = {
    countryCodes: []
  };
  render() {
    const {
      countries,
      defaultCurrency,
      updateShopShippingSettings,
      shop,
      shopId,
      suggestions,
      shippingZone
    } = this.props;
    if (!shippingZone) return null;
    return (
      <>
        <Input
          value={shippingZone.name}
          onChange={e => {
            updateShopShippingSettings({
              optimisticResponse: {
                __typename: 'Mutation',
                updateShopShippingSettings: {
                  ...shop,
                  shippingSettings: {
                    ...shop.shippingSettings,
                    shippingZones: shop.shippingSettings.shippingZones.map(
                      tempShippingZone => {
                        return {
                          ...tempShippingZone,
                          name:
                            tempShippingZone.id === shippingZone.id
                              ? e.target.value
                              : tempShippingZone.name
                        };
                      }
                    )
                  }
                }
              },
              variables: {
                id: shopId,
                input: {
                  shippingSettings: {
                    shippingZones: cloneDeep(
                      shop.shippingSettings.shippingZones
                    ).map(tempShippingZone1 => {
                      return omitDeep(
                        {
                          ...tempShippingZone1,
                          name:
                            tempShippingZone1.id === shippingZone.id
                              ? e.target.value
                              : tempShippingZone1.name
                        },
                        ['__typename']
                      );
                    })
                  }
                }
              }
            });
          }}
        />
        <Typography
          style={{
            cursor: 'pointer',
            margin: '1em 0',
            display: 'block'
          }}
          variant="body1"
          for={'allc-' + shippingZone.id}
        >
          Apply to all countries?
          <input
            id={'allc-' + shippingZone.id}
            onChange={e => {
              updateShopShippingSettings({
                optimisticResponse: {
                  __typename: 'Mutation',
                  updateShopShippingSettings: {
                    ...shop,
                    shippingSettings: {
                      ...shop.shippingSettings,
                      shippingZones: shop.shippingSettings.shippingZones.map(
                        tempShippingZone => {
                          return tempShippingZone.id === shippingZone.id
                            ? {
                                ...tempShippingZone,
                                applyToAllCountries: e.target.checked,
                                countryCodes: e.target.checked
                                  ? []
                                  : shippingZone.countryCodes
                              }
                            : tempShippingZone;
                        }
                      )
                    }
                  }
                },
                variables: {
                  id: shopId,
                  input: {
                    shippingSettings: {
                      shippingZones: cloneDeep(
                        shop.shippingSettings.shippingZones
                      ).map(tempShippingZone1 => {
                        return omitDeep(
                          tempShippingZone1.id === shippingZone.id
                            ? {
                                ...tempShippingZone1,
                                applyToAllCountries: e.target.checked,
                                countryCodes: e.target.checked
                                  ? []
                                  : shippingZone.countryCodes
                              }
                            : tempShippingZone1,
                          ['__typename']
                        );
                      })
                    }
                  }
                }
              });
            }}
            type="checkbox"
            checked={shippingZone.applyToAllCountries === true ? 'checked' : ''}
          />
        </Typography>
        <MultipleSelect
          label="Select a zone"
          labeColor="#969696"
          values={
            shippingZone.applyToAllCountries === true
              ? []
              : shippingZone.countryCodes.map(code => {
                  return [
                    {
                      name: 'EU',
                      code: 'EUROPEAN_UNION'
                    },
                    ...countries
                  ].find(c => c.code === code).name;
                })
          }
          options={suggestions}
          onChange={(values, valueOjects) => {
            const newValues =
              values.includes('World') === true
                ? ['World']
                : values.map(value => {
                    return value === 'EU'
                      ? 'EUROPEAN_UNION'
                      : countries.find(c => c.name === value).code;
                  });
            updateShopShippingSettings({
              optimisticResponse: {
                __typename: 'Mutation',
                updateShopShippingSettings: {
                  ...shop,
                  shippingSettings: {
                    ...shop.shippingSettings,
                    shippingZones: shop.shippingSettings.shippingZones.map(
                      tempShippingZone => {
                        return tempShippingZone.id === shippingZone.id
                          ? {
                              ...tempShippingZone,
                              applyToAllCountries: values.includes('World'),
                              countryCodes: newValues
                            }
                          : tempShippingZone;
                      }
                    )
                  }
                }
              },
              variables: {
                id: shopId,
                input: {
                  shippingSettings: {
                    shippingZones: cloneDeep(
                      shop.shippingSettings.shippingZones
                    ).map(tempShippingZone1 => {
                      return omitDeep(
                        tempShippingZone1.id === shippingZone.id
                          ? {
                              ...tempShippingZone1,
                              countryCodes: newValues,
                              applyToAllCountries: values.includes('World')
                            }
                          : tempShippingZone1,
                        ['__typename']
                      );
                    })
                  }
                }
              }
            });
          }}
        />
        <Button
          style={{
            display: 'block',
            margin: '0 auto 0 0'
          }}
          onClick={() => {
            const newShippingRate = {
              __typename: 'ShippingRate',
              id: uuid.v4(),
              priority: 0,
              type: 'PRICE',
              // minimumTotal: {
              //   __typename: 'Price',
              //   value: 0,
              //   currency: defaultCurrency
              // },
              // minimumWeight: 0,
              // weightUnit: 'g',
              rate: {
                __typename: 'Price',
                value: 0,
                currency: defaultCurrency
              }
            };
            updateShopShippingSettings({
              optimisticResponse: {
                __typename: 'Mutation',
                updateShopShippingSettings: {
                  ...shop,
                  shippingSettings: {
                    ...shop.shippingSettings,
                    shippingZones: shop.shippingSettings.shippingZones.map(
                      tempShippingZone => {
                        return shippingZone.id === tempShippingZone.id
                          ? {
                              ...tempShippingZone,
                              shippingRates: tempShippingZone.shippingRates
                                ? [
                                    ...tempShippingZone.shippingRates,
                                    newShippingRate
                                  ]
                                : [newShippingRate]
                            }
                          : tempShippingZone;
                      }
                    )
                  }
                }
              },
              variables: {
                id: shopId,
                input: {
                  shippingSettings: {
                    shippingZones: cloneDeep(
                      shop.shippingSettings.shippingZones
                    ).map(tempShippingZone1 => {
                      return omitDeep(
                        shippingZone.id === tempShippingZone1.id
                          ? {
                              ...tempShippingZone1,
                              shippingRates: tempShippingZone1.shippingRates
                                ? [
                                    ...tempShippingZone1.shippingRates,
                                    newShippingRate
                                  ]
                                : [newShippingRate]
                            }
                          : tempShippingZone1,
                        ['__typename']
                      );
                    })
                  }
                }
              }
            });
          }}
          variant="contained"
          color="primary"
          style={{ marginTop: 16 }}
        >
          Add rate
        </Button>
        {shippingZone.shippingRates
          ? shippingZone.shippingRates.map((shippingRate, index) => {
              return (
                <div
                  style={{
                    border: '1px solid #eee',
                    padding: '1.5em',
                    margin: '1em 0'
                  }}
                  key={'sr' + shippingZone.id + index}
                >
                  <ShippingRate
                    shop={shop}
                    shopId={shopId}
                    defaultCurrency={defaultCurrency}
                    shippingZone={shippingZone}
                    shippingRate={shippingRate}
                    updateShopShippingSettings={updateShopShippingSettings}
                  />
                </div>
              );
            })
          : null}
        <Button
          variant="contained"
          color="primary"
          style={{
            display: 'block',
            margin: '2.5em 0 0 auto'
          }}
          onClick={() => {
            updateShopShippingSettings({
              optimisticResponse: {
                __typename: 'Mutation',
                updateShopShippingSettings: {
                  ...shop,
                  shippingSettings: {
                    ...shop.shippingSettings,
                    shippingZones: shop.shippingSettings.shippingZones.filter(
                      tempShippingZone => {
                        return shippingZone.id !== tempShippingZone.id;
                      }
                    )
                  }
                }
              },
              variables: {
                id: shopId,
                input: {
                  shippingSettings: {
                    shippingZones: cloneDeep(
                      shop.shippingSettings.shippingZones
                    )
                      .filter(tempShippingZone0 => {
                        return shippingZone.id !== tempShippingZone0.id;
                      })
                      .map(tempShippingZone1 =>
                        omitDeep(tempShippingZone1, ['__typename'])
                      )
                  }
                }
              }
            });
          }}
        >
          Delete zone
        </Button>
      </>
    );
  }
}

export default ShippingZone;
