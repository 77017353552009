import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/react-hooks';
import { ADMIN_DELETE_COLLECTION } from '../../gql/admin-mutations';
import { ADMIN_SHOP_COLLECTIONS } from '../../gql/admin-queries';
import { navigate } from '@reach/router';
const DeleteCollection = ({ collectionId, shopId }) => {
  const [deleteCollection, { data }] = useMutation(ADMIN_DELETE_COLLECTION, {
    update(cache, { data: { addTodo } }) {
      const { shop } = cache.readQuery({
        query: ADMIN_SHOP_COLLECTIONS,
        variables: { id: shopId }
      });
      cache.writeQuery({
        query: ADMIN_SHOP_COLLECTIONS,
        variables: { id: shopId },
        data: {
          shop: {
            ...shop,
            collections: {
              ...shop.collections,
              edges: shop.collections.edges.filter(
                x => x.node.id !== collectionId
              )
            }
          }
        }
      });
    }
  });
  return (
    <Grid container>
      <Grid item xs={10}>
        <Button
          style={{
            background: '#a42222',
            color: 'white'
          }}
          onClick={async () => {
            if (window.confirm('Delete?')) {
              await deleteCollection({
                variables: {
                  id: collectionId
                }
              });
              navigate('/admin/' + shopId + '/inventory/collections/');
            }
          }}
        >
          Delete Collection
        </Button>
      </Grid>
    </Grid>
  );
};

export default DeleteCollection;
