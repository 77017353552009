import { SHOP_ID } from './config'
import shortid from 'shortid'
import { GET_USER } from '../gql/queries'
import { setGuestOrg } from '../helper/storage'

export async function setGuestId(currentOrganisation, refetch) {
  return new Promise(async (resolve, reject) => {
    try {
      const GUESTORG = SHOP_ID + '-GUESTORG-' + shortid.generate()
      if (currentOrganisation && currentOrganisation.id) {
      } else {
        setGuestOrg(GUESTORG)
      }
      resolve(GUESTORG)
    } catch (error) {
      console.error('setGuestId.error', error)
      resolve(null)
    }
  })
}
