import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { Grid, Paper, Typography, Button } from '@material-ui/core'
import AddProductVariant from './AddProductVariant'
import DeleteIcon from '@material-ui/icons/Delete'
import Input from '../../../common/Input'
import { Link } from 'gatsby'
import Option from './Option'
import ProductVariantRow from './ProductVariantRow'
import ProductVariantOption from './ProductVariantOption'
import _cloneDeep from 'lodash/cloneDeep'
import { getTextFromLanguage } from '../../../../helper/languageHelper'

import { GET_PRODUCT_OPTION_VALUE } from '../../gql/admin-queries'
import { ADMIN_UPDATE_PRODUCT_OPTION_VALUE } from '../../gql/admin-mutations'
import { Mutation, Query } from 'react-apollo'
import CircularProgress from '@material-ui/core/CircularProgress'
import ProductDetailVariantOption from './ProductDetailVariantOption'
const styles = theme => ({
  progress: {},
  feature_icon: {
    width: 'auto',
    float: 'left',
    margin: '.5em',
    display: 'block',
    cursor: 'pointer'
  }
})
class ProductDetailVariant extends Component {
  allPossibleCases(array, result, index) {
    if (!result) {
      result = []
      index = 0
      array = array.map(function(element) {
        return element.push ? element : [element]
      })
    }
    if (index < array.length) {
      array[index].forEach(function(element) {
        var a = array.slice(0)
        a.splice(index, 1, [element])
        this.allPossibleCases(a, result, index + 1)
      })
    } else {
      result.push(array.join(' '))
    }

    return result
  }
  combinations(array) {
    if (!array.length) {
      return []
    }

    try {
      // wrap non-array values
      // e.g. ['x',['y','z']] becomes [['x'],['y','z']]
      array = array.map(function(item) {
        return item instanceof Array ? item : [item]
      })

      // internal recursive function
      function combine(list) {
        var prefixes, combinations

        if (list.length === 1) {
          return list[0]
        }

        prefixes = list[0]
        combinations = combine(list.slice(1)) // recurse

        // produce a flat list of each of the current
        // set of values prepended to each combination
        // of the remaining sets.
        return prefixes.reduce(function(memo, prefix) {
          return memo.concat(
            combinations.map(function(combination) {
              return [prefix].concat(combination)
            })
          )
        }, [])
      }

      return combine(array)
    } catch (error) {
      return []
    }
  }
  render() {
    const { productId, product, classes, defaultLanguage, shopId } = this.props
    var productVariantOptions = []
    var availableOptions = []
    var optionTitles = {}
    var optionVariants = {}
    try {
      product.variants.forEach((variant, index) => {
        if (variant.options && variant.options.length > 0) {
          availableOptions[index] = variant.options
          variant.variant.options.forEach(option => {
            optionVariants[option.id] = variant.variant.id
            optionTitles[option.id] = getTextFromLanguage(
              option.title.text,
              defaultLanguage
            )
          })
        }
      })
      const allCombinations = this.combinations(_cloneDeep(availableOptions))
      allCombinations.forEach((combination, combinationIndex) => {
        var combinationString = ''
        var optionValues = ''
        var variantIds = []
        var optionValueIds = []

        if (typeof combination === 'string') {
          variantIds = [optionVariants[combination]]
          optionValueIds = [combination]
          combinationString =
            combinationString === ''
              ? optionTitles[combination]
              : combinationString + ' + ' + optionTitles[combination]
          productVariantOptions.push(
            <ProductDetailVariantOption
              shopId={shopId}
              defaultLanguage={defaultLanguage}
              variantIds={variantIds}
              optionValueIds={optionValueIds}
              product={product}
              productId={productId}
              combinationIndex={combinationIndex}
              optionValues={combination}
              classes={classes}
              combinationString={combinationString}
            />
          )
        } else {
          combination.forEach(combinationOption => {
            variantIds.push(optionVariants[combinationOption])
            optionValueIds.push(combinationOption)
            combinationString =
              combinationString === ''
                ? optionTitles[combinationOption]
                : combinationString + ' + ' + optionTitles[combinationOption]
          })
          variantIds = variantIds.sort()
          optionValueIds = optionValueIds.sort()
          optionValues = optionValueIds.join('#')
          productVariantOptions.push(
            <ProductDetailVariantOption
              shopId={shopId}
              defaultLanguage={defaultLanguage}
              variantIds={variantIds}
              optionValueIds={optionValueIds}
              product={product}
              productId={productId}
              combinationIndex={combinationIndex}
              optionValues={optionValues}
              classes={classes}
              combinationString={combinationString}
            />
          )
        }
      })
    } catch (error) {
      console.error('productVariantOptions.error', error)
    }
    return (
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography data-cy="Product-Variant-header" variant="subtitle1">
            Variant
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Paper elevation={0} style={{ padding: 24 }}>
            {/* {JSON.stringify(product.variants)} */}
            <Grid container>
              {product.variants
                ? product.variants.map((pV, index) => {
                    return (
                      <Grid key={'pv-' + index} item xs={12}>
                        <ProductVariantRow
                          shopId={shopId}
                          defaultLanguage={defaultLanguage}
                          variantId={pV.variant.id}
                          productVariant={pV}
                          product={product}
                          productId={productId}
                        />
                      </Grid>
                    )
                  })
                : null}
            </Grid>

            <Grid container style={{ margin: '2em 0' }}>
              <AddProductVariant
                shopId={shopId}
                defaultLanguage={defaultLanguage}
                product={product}
                productId={productId}
              />
            </Grid>

            <Grid container>
              <Grid item xs={12} style={{ padding: '8px 0px' }}>
                <Typography variant="subtitle1">
                  SKU and Price modifier
                </Typography>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ background: '#eee', borderRadius: 3, padding: 8 }}
                  >
                    <Typography variant="body1">Options</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ background: '#eee', borderRadius: 3, padding: 8 }}
                  >
                    <Typography variant="body1">SKU</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ background: '#eee', borderRadius: 3, padding: 8 }}
                  >
                    <Typography variant="body1">Price</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ background: '#eee', borderRadius: 3, padding: 8 }}
                  >
                    <Typography variant="body1">Thumb</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ background: '#eee', borderRadius: 3, padding: 8 }}
                  >
                    <Typography variant="body1">Inventory</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ background: '#eee', borderRadius: 3, padding: 8 }}
                  >
                    <Typography variant="body1">Active</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {productVariantOptions}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(styles)(ProductDetailVariant)
