import React, { Component } from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import AddProductVariant from './AddProductVariant';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '../../../common/Input';
import { Link } from 'gatsby';
import Option from './Option';
import { getTextFromLanguage } from '../../../../helper/languageHelper';

class ProductVariantOption extends Component {
  render() {
    const { productVariant, defaultLanguage } = this.props;
    if (!productVariant) return null;

    return (
      <Grid container spacing={2}>
        {/* {JSON.stringify(productVariant)} */}
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Grid container alignItems="flex-end" spacing={4}>
            <Grid item style={{ minWidth: 40 }}>
              <Button
                component={Link}
                color="primary"
                style={{ padding: 2, minWidth: '100%' }}
              >
                <DeleteIcon />
              </Button>
            </Grid>
            <Grid item style={{ width: 'calc(100% - 40px)' }}>
              {productVariant &&
              productVariant.variant &&
              productVariant.variant.title
                ? getTextFromLanguage(
                    productVariant.variant.title.text,
                    defaultLanguage
                  )
                : 'N/A'}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          {/* <ProducOption /> */}
        </Grid>
      </Grid>
    );
  }
}

export default ProductVariantOption;
