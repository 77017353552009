import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'

import { getTextFromLanguage } from '../../../helper/languageHelper'
import { showCurrencySymbol } from '../../../helper/currency'
import _get from 'lodash/get'
import { Link } from 'gatsby'
import Price from '../product/Price'
import Imgix from 'react-imgix'
import { MEDIA_CDN_URL, IMGIX_URL } from '../../../../brikl-config'
import { Localization } from '../../../lang/localization'
import _orderBy from 'lodash/orderBy'

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    color: '#000',
    textDecoration: 'none',
    letterSpacing: 'normal',
    textTransform: 'normal',
    height: '100%',
    '&:hover': {
      boxShadow:
        ' 0 3px 6px rgba(140, 140, 140, 0.16), 0 3px 6px rgba(140, 140, 140, 0.23)'
    },
    [theme.breakpoints.down('sm')]: {
      border: '1px solid #e5e5e5'
    }
  },
  cardPadding: {
    padding: 12,
    [theme.breakpoints.down('sm')]: {
      padding: 8
    },
    [theme.breakpoints.down('xs')]: {
      padding: 4
    }
  }
}))

const XceedProductCard = ({
  customUrl,
  urlPrefix,
  langCode,
  key,
  Template,
  salesChannel,
  product,
  buttonText,
  taxSettings,
  isPackage,
  defaultCurrency,
  currencyRates
}) => {
  const classes = useStyles()
  var title = null
  var button = null
  try {
    title = getTextFromLanguage(_get(product, 'title.text', []), langCode)
    button = getTextFromLanguage(buttonText.text, langCode)
  } catch (error) {}
  var src = null
  try {
    src = _get(product, 'media', []).find(m => m.isThumbnail === true).image
  } catch (error) {}
  var slug = null
  try {
    if (customUrl) {
      slug = customUrl
    } else {
      if (process.env.GATSBY_BRIKL_SALESCHANNEL_ID) {
        slug = `/${urlPrefix}${
          _get(product, 'slugs', []).find(e => e.langCode === langCode)
            ? _get(product, 'slugs', []).find(e => e.langCode === langCode)
                .content
            : ''
        }`
      } else {
        if (salesChannel) {
          slug = `/${urlPrefix}${
            _get(salesChannel, 'slugs', []).find(e => e.langCode === langCode)
              ? _get(salesChannel, 'slugs', []).find(
                  e => e.langCode === langCode
                ).content
              : ''
          }/${isPackage === true ? 'package' : 'product'}?p=${
            product.id
          }&custom=${product.isCustomProduct === true ? '1' : '0'}`
        } else {
          if (product && product.slugs) {
            slug = `/${urlPrefix}${
              _get(product, 'slugs', []).find(e => e.langCode === langCode)
                ? _get(product, 'slugs', []).find(e => e.langCode === langCode)
                    .content
                : ''
            }`
          }
        }
      }
    }
  } catch (error) {
    console.error('ProductCard.slugs.error', error)
  }
  let price = product.price

  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Grid
      key={key}
      item
      xs={6}
      sm={4}
      md={3}
      lg={3}
      xl={3}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      className={classes.cardPadding}
    >
      <Link
        to={slug ? slug : ''}
        style={{ position: 'relative', color: '#000', textDecoration: 'none' }}
        className={classes.card}
      >
        {open === true ? (
          <div>
            <Grid
              container
              style={{
                position: 'absolute',
                with: '100%',
                height: 270,
                background: '#fff',
                top: 0,
                left: 0,
                opacity: '0.8',
                filter: 'blur(4px),'
              }}
            />
            <Grid
              container
              justify={'center'}
              alignItems={'center'}
              style={{
                position: 'absolute',
                with: '100%',
                height: 270,
                top: 0,
                left: 0,
                zIndex: 2,
                padding: 24
              }}
            >
              <Button variant="contained" color="primary">
                {product && product.isCustomProduct === true
                  ? `${Localization.get('design_now')}`
                  : `${Localization.get('order_now')}`}
              </Button>
            </Grid>
          </div>
        ) : null}
        <Grid container style={{ with: '100%', height: 270 }}>
          <Imgix
            height={270}
            htmlAttributes={{
              alt: title,
              style: {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                display: 'block'
              }
            }}
            imgixParams={{
              auto: 'compress'
            }}
            src={src ? src.replace(MEDIA_CDN_URL, IMGIX_URL) : ''}
            alt={title}
          />
        </Grid>
        <Grid container style={{ padding: '8px 16px 16px' }}>
          <Grid
            item
            xs={12}
            style={{ borderTop: '1px solid #e5e5e5', paddingTop: 16 }}
          >
            <Typography
              variant={'subtitle1'}
              style={{
                letterSpacing: 'normal',
                fontSize: 14,
                color: '#000',
                textDecoration: 'none'
              }}
            >
              {title ? title : ''}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant={'h6'}
              style={{
                letterSpacing: 'normal',
                fontSize: 18,
                color: '#000',
                textDecoration: 'none'
              }}
            >
              <Price
                defaultCurrency={defaultCurrency}
                currencyRates={currencyRates}
                defaultCurrency={defaultCurrency}
                price={price}
                productId={product.id}
                taxSettings={taxSettings}
              />
            </Typography>
          </Grid>
        </Grid>
      </Link>
    </Grid>
  )
}

export default XceedProductCard
