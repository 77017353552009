import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Paper, Button } from '@material-ui/core';
import { Localization } from '../../../lang/localization';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 24
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10
  },
  appbar: {
    background: '#000',
    color: '#fff'
  },
  maxWidth: {
    maxWidth: '1140px',
    width: '100%',
    margin: 'auto'
  },
  left: {
    marginRight: '12px',
    width: 'calc(100% - 12px)'
  },
  right: {
    marginLeft: '12px',
    width: 'calc(100% - 12px)'
  }
});

class LocationCard extends React.Component {
  render() {
    const {
      url,
      shopName,
      address,
      website,
      email,
      phone,
      openDays,
      openHours,
      closeDays,
      dealershipLink,
      hasPremiumDealership,
      langCode
    } = this.props;

    return (
      <Grid container alignItems="center" style={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ height: '100%' }}
        >
          <Paper
            elevation={0}
            style={{ height: '100%', border: '1px solid #E5E5E5' }}
          >
            {/* TODO: Convert normal Maps URLs to Embed URLs */}
            <Grid container style={{ height: '100%' }}>
              <Grid item xs={6}>
                {url && url.indexOf('https://www.google.com/maps') !== -1 ? (
                  <iframe
                    src={url}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: '0px'
                    }}
                  />
                ) : (
                  <Grid container alignItems="center" justify="center">
                    <Typography variant="h2">Incorrect URL :(</Typography>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  height: '100%'
                }}
              >
                <Grid
                  container
                  wrap="nowrap"
                  direction="column"
                  alignItems="flex-start"
                  spacing={3}
                  style={{ padding: '64px 24px', height: '100%' }}
                >
                  {shopName ? (
                    <Grid item xs={12}>
                      <Typography variant="h4">{shopName}</Typography>
                    </Grid>
                  ) : null}

                  {address ? (
                    <Grid item xs={12}>
                      <Typography variant="body1">{address}</Typography>
                    </Grid>
                  ) : null}

                  {website ? (
                    <Grid item xs={12}>
                      <Button href={website} target="_blank" variant="outlined">
                        Go to website
                      </Button>
                    </Grid>
                  ) : null}

                  {email ? (
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 400, marginBottom: '-24px' }}
                      >
                        Email:
                      </Typography>
                      <br />
                      <Typography
                        style={{ wordWrap: 'break-word' }}
                        variant="body1"
                      >
                        {email}
                      </Typography>
                    </Grid>
                  ) : null}

                  {phone ? (
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 400, marginBottom: '-24px' }}
                      >
                        {Localization.get('phone')}
                      </Typography>
                      <br />
                      <Typography variant="body1">{phone}</Typography>
                    </Grid>
                  ) : null}

                  {openDays ? (
                    <Grid item xs={12} style={{ marginBottom: '-24px' }}>
                      <Typography variant="body1">{openDays}:</Typography>
                    </Grid>
                  ) : null}

                  {openHours ? (
                    <Grid item xs={12}>
                      <Typography variant="body1">{openHours}</Typography>
                    </Grid>
                  ) : null}

                  {closeDays ? (
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 400, marginBottom: '-24px' }}
                      >
                        Closed:
                      </Typography>
                      <br />
                      <Typography variant="body1">{closeDays}</Typography>
                    </Grid>
                  ) : null}

                  {hasPremiumDealership ? (
                    <Grid item xs={12}>
                      <a href="/premium-dealer" style={{ color: '#356998' }}>
                        <Typography variant="body1">
                          {dealershipLink}
                        </Typography>
                      </a>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
LocationCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LocationCard);
