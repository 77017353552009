import React, { useContext } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { SelectedLanguageContext } from '../../LanguageContext'
import { getTextFromLanguage } from '../../../../../../../helper/languageHelper'

interface Props {
  introduction: any
  dates: any
  contact: any
}

const TeamstoreInfo: React.FC<Props> = props => {
  const { introduction, dates, contact } = props
  const selectedLanguage = useContext(SelectedLanguageContext)

  return (
    <Grid container={true} direction="row">
      {introduction?.text ? (
        <Typography style={{ marginBottom: 15 }} variant="body1">
          {introduction?.defaultValue}
        </Typography>
      ) : (
        <Grid
          justify="center"
          style={{ textAlign: 'center', padding: '25px 0px' }}
        >
          Introduction text
        </Grid>
      )}
      <Grid style={{ marginBottom: 5 }} container={true} direction="row">
        <Grid item={true}>
          <Typography
            style={{ fontWeight: 'bold', marginRight: 10 }}
            variant="body1"
          >
            Contact Person:{' '}
          </Typography>
        </Grid>
        <Grid item={true}>
          <Typography variant="body1">
            {contact?.name || 'John Smith'}
          </Typography>
        </Grid>
      </Grid>

      <Grid style={{ marginBottom: 5 }} container={true} direction="row">
        <Grid item={true}>
          <Typography
            style={{ fontWeight: 'bold', marginRight: 10 }}
            variant="body1"
          >
            Email:{' '}
          </Typography>
        </Grid>
        <Grid item={true}>
          <Typography variant="body1">
            {contact?.email || 'example@email.com'}
          </Typography>
        </Grid>
      </Grid>

      <Grid style={{ marginBottom: 5 }} container={true} direction="row">
        <Grid item={true}>
          <Typography
            style={{ fontWeight: 'bold', marginRight: 10 }}
            variant="body1"
          >
            Phone:{' '}
          </Typography>
        </Grid>
        <Grid item={true}>
          <Typography variant="body1">
            {contact?.phone || 'Phone No.'}
          </Typography>
        </Grid>
      </Grid>

      {/* TODO - Add dates */}
    </Grid>
  )
}

export default TeamstoreInfo
