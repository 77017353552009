import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import NativeSelect from '@material-ui/core/NativeSelect';

import { SOCIAL_MEDIA_TYPES } from './config';

const SelectInput = withStyles(theme => ({
  input: {
    flex: 1,
    paddingLeft: '4px 8px',
    fontSize: 13,
    '&:focus': {
      borderRadius: 4,
      background: '#fff'
    }
  }
}))(InputBase);

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    border: '1px solid #0000001f'
  },
  input: {
    paddingLeft: 8,
    flex: 1,
    width: '100%',
    fontSize: 13
  },
  FormLabel: {
    fontSize: 18
  },
  expansionPanel: {
    flex: 1,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: '#ff0066',
      display: 'none'
    }
  },
  expansionPanelDetails: {
    padding: '4px'
  },
  expansionPanelExpanded: {
    margin: '0px !important'
  },
  typo: {
    color: '#696969',
    fontSize: '15px'
  }
});

class UpdateSocialMedia extends React.Component {
  render() {
    const {
      classes,
      expanded,
      handleExpandedChange,
      handleChangeField,
      fields
    } = this.props;

    return (
      <ExpansionPanel
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
        expanded={expanded === 'panel2'}
        onChange={handleExpandedChange('panel2')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ expanded: classes.expansionPanelExpanded }}
        >
          <Typography
            variant="subtitle1"
            className={classes.typo}
            data-cy="Social-Media-Settings"
          >
            Social Media Settings
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{ root: classes.expansionPanelDetails }}
        >
          <Divider />
          <List>
            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Social Media Platform
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <NativeSelect
                    native="true"
                    value={fields.socialMediaType}
                    className={classes.input}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'socialMediaType'
                      )
                    }
                    input={
                      <SelectInput
                        name="socialMediaType"
                        id="age-customized-native-simple"
                      />
                    }
                  >
                    {SOCIAL_MEDIA_TYPES.map(type => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </NativeSelect>
                </Paper>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container direction="column">
                <InputLabel shrink className={classes.FormLabel}>
                  Username
                </InputLabel>
                <Paper className={classes.root} elevation={0}>
                  <InputBase
                    className={classes.input}
                    placeholder=""
                    value={fields.username}
                    onChange={e =>
                      handleChangeField(
                        e.target.value ? e.target.value : null,
                        'username'
                      )
                    }
                  />
                </Paper>
              </Grid>
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

UpdateSocialMedia.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.string
};

export default withStyles(styles)(UpdateSocialMedia);
